import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/acciones.resolver";
import advancedFilter from "js/components/TablaAccion/advancedFilter.js";
import { withRouter } from "react-router-dom";
import {
  editarAccion,
  viewAction,
  activarAccion,
  finalizarAccion,
  exportAction
} from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { columnas, misColumnas } from "js/components/TablaAccion/columns";

const defaultHeader = {
  title: "Acciones",
  new: {
    title: "Nuevo",
    perms: [Roles.ACCION_W]
  },
  queryStringMatcher: {
    idsProgramas: undefined
  }
};

const header = {
  title: "Acciones",
  queryStringMatcher: {
    idsProgramas: undefined
  }
};

const defaultSort = { order: "desc", orderBy: "nombre" };

class Tabla extends PureComponent {
  render() {
    let inBackOffice = true;
    let columnData = null;

    if (window.location.pathname.indexOf("admin") === -1) {
      inBackOffice = false;
    }

    if (inBackOffice) {
      columnData = columnas;
    } else {
      columnData = misColumnas;
    }

    const actionsCustom = [];
    if (inBackOffice) {
      const edit = params => ({
        ...editarAccion({
          ...params,
          history: this.props.history,
          perms: [Roles.ACCION_W]
        })
      });
      actionsCustom.push(edit);
      const view = params => ({
        ...viewAction({ ...params, history: this.props.history })
      });
      actionsCustom.push(view);
      const activar = params => ({
        ...activarAccion({
          ...params,
          history: this.props.history,
          perms: [Roles.ACCION_W],
          openNotification: this.props.openNotification
        })
      });
      actionsCustom.push(activar);
      const finalizar = params => ({
        ...finalizarAccion({
          ...params,
          history: this.props.history,
          perms: [Roles.ACCION_W],
          openNotification: this.props.openNotification
        })
      });
      actionsCustom.push(finalizar);
    } else {
      const exportQueryString = idAccion => {
        return `idAccion=${idAccion}`;
      };

      const exportButton = params => ({
        ...exportAction({
          ...params,
          history: this.props.history,
          perms: [Roles.ACCION_R],
          tooltip: "Exportar participaciones",
          label: "Exportar participaciones",
          path: "accionParticipaciones",
          queryString: exportQueryString
        })
      });
      actionsCustom.push(exportButton);
    }

    return (
      <Table
        storage={
          this.props.match.params.id ? "FiltroAccPrograma" : "FiltroAcciones"
        }
        query={AppQraphQL.queries.getPaginaAcciones}
        defaultHeader={inBackOffice ? defaultHeader : header}
        advancedFilter={advancedFilter}
        actionsCustom={actionsCustom}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getAcciones}
      />
    );
  }
}

export default withRouter(Tabla);
