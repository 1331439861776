import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Fade from "@material-ui/core/Fade";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { _t } from "js/utils/TranslationService";

class Notification extends PureComponent {
  render() {
    const {
      open = false,
      classes,
      className = "",
      mensaje = "",
      autoHideDuration = 3000,
      handleClose = () => undefined,
      position = {
        vertical: "top",
        horizontal: "right"
      }
    } = this.props;
    return (
      <Snackbar
        TransitionComponent={Fade}
        autoHideDuration={autoHideDuration}
        anchorOrigin={position}
        open={open}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
      >
        <SnackbarContent
          className={classes.notification}
          aria-describedby="client-snackbar"
          message={<span className={className}>{_t(mensaje)}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
    );
  }
}

Notification.propTypes = {
  classes: PropTypes.object
};

Notification.defaultProps = {};

export default Notification;
