import React from "react";
import DialogCustom from "js/components/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";

const Dialog = props => {
  const { okAction, cancelAction } = props;
  return (
    <DialogCustom
      open={true}
      title="Borrar reportes"
      content={
        <DialogContentText>
          Se eliminarán los reportes. ¿Estás seguro que deseas continuar?
        </DialogContentText>
      }
      cancelAction={cancelAction}
      cancelText="No"
      okAction={okAction}
      okText="Sí"
    />
  );
};

export default Dialog;
