export default theme => ({
  wrapper: {
    fontSize: 14
  },
  cantidad: {
    marginLeft: 5,
    color: theme.palette.grey[500]
  },
  selected: {
    fontWeight: 900,
    color: theme.palette.primary.main,
    fontSize: 16
  },
  inset: {
    "&:first-child": { paddingLeft: 15 },
    paddingLeft: 15
  }
});
