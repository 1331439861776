import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
// import {
//   createMuiTheme,
//   ThemeProvider,
//   withStyles,
//   makeStyles
// } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import UserInfo from "js/components/Header/UserInfo";
import Header from "js/components/Header";
import Footer from "js/components/Footer";
import Paper from "@material-ui/core/Paper";
import { _t } from "js/utils/TranslationService";
import AuthService from "js/utils/AuthService";
import { ApolloConsumer } from "react-apollo";
import IdiomasList from "js/components/Shared/IdiomasList";
import { cx, css } from "emotion";
import { USER_ROLES as Roles } from "js/models/User";
import { openNewWindow, openNewTab, changeTab, trackLinkClick } from "js/utils";
import UsuarioGraphQL from "js/graphql/resolvers/usuario.resolver";
import ApiTokenGraphQL from "js/graphql/resolvers/apiToken.resolver";

const getOnClick = ({ target, link }, openLogic, client) => {
  if (
    (link && link.indexOf("<token>") !== -1) ||
    (target === "_blank" || target === "new")
  ) {
    return {
      onClick: e => e.preventDefault() || openLogic(link, target),
      target: ""
    };
  } else {
    return {
      // chequear js/utils para ver que sirve esta función
      onClick: e => trackLinkClick(link, client, "menu-mobile")
    };
  }
};

class BurgerLayout extends React.Component {
  state = {
    open: false
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  async openLinkWithToken(link, target) {
    const { client } = this.props;
    // chequear js/utils para ver que sirve esta función
    await trackLinkClick(link, client, "menu-mobile");
    const idPrograma = parseInt(AuthService.getIdPrograma());
    const idUsuario = parseInt(AuthService.getIdUsuario());

    let finalLink = "";
    if (link && link.indexOf("<token>") !== -1) {
      // TODO: Buscar la forma de hacer esta comprobación genérica
      if (
        link.indexOf("prode") !== -1 ||
        link.indexOf("penca") !== -1 ||
        link.indexOf("lipy") !== -1
      ) {
        await client
          .query({
            query: ApiTokenGraphQL.queries.getApiTokenTerceroUser,
            variables: {
              apiTokenTerceroLike: {
                idPrograma,
                idUsuario
              }
            }
          })
          .then(res => {
            if (res.data.getApiTokenTerceroUser)
              finalLink = link.replace(
                "<token>",
                res.data.getApiTokenTerceroUser.token
              );
          })
          .catch(error => {
            this.props.openNotification(error.message);
          });
      } else {
        const { data } = await client.mutate({
          mutation: UsuarioGraphQL.mutations.generarTokenParaUsoExterno,
          variables: {
            idPrograma: idPrograma
          }
        });
        if (data.generarTokenParaUsoExterno) {
          const token = data.generarTokenParaUsoExterno;
          finalLink = link.replace("<token>", token);
        }
      }
    } else {
      finalLink = link;
    }

    // Abrir con token en otra pestaña
    if (finalLink && target === "_blank") {
      openNewTab(finalLink);
    }

    // Abrir con token en otra ventana
    if (finalLink && target === "new") {
      openNewWindow(finalLink);
    }

    // Abrir con token en la misma pestaña
    if (finalLink && target !== "_blank" && target !== "new") {
      changeTab(finalLink);
    }
  }

  render() {
    const {
      component: Component,
      programa,
      classes,
      history,
      container = false,
      client
    } = this.props;
    const navbar = programa.template.header.menu.navbar;
    const header = css(programa.template.header.css);
    const { items, css: navbarCss } = navbar;
    let navbarStyle = Object.assign({}, navbarCss);
    const headerBackgroundColor = header.backgroundColor;
    delete navbarStyle.backgroundColor;
    //navbarStyle[" > a:hover"].color += "!important";
    const isLogged = !!AuthService.getIdUsuario();

    const moduloPuntos = programa.modulos
      ? programa.modulos.find(
          modulo =>
            modulo.nombreGrupo === "Gestión Puntos" &&
            modulo.estado === "ACTIVO"
        )
      : true;

    const HasPerms = AuthService.hasPerms([Roles.MIS_DATOS_MOVIMIENTOS_R]);

    const { open } = this.state;

    const isActive = link => (match, location) => {
      if (match) return true;
      if (link.includes("?")) {
        const { pathname, search } = location;
        const splitted = link.split("?");
        return pathname === splitted[0] && search === `?${splitted[1]}`;
      }
      return location.pathname === link;
    };

    const drawer = (
      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={this.handleDrawerClose}
        classes={{
          paper: cx(classes.drawerPaper, css(navbarCss))
        }}
      >
        <div className={classes.drawerHeader}>
          <UserInfo burger />
          <IconButton onClick={this.handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {items.map(item => {
            const perms = (item.perms || []).map(r => Roles[r]);
            if (perms.length && !AuthService.hasPerms(perms)) return null;
            return (
              <ListItem
                button
                onClick={this.handleDrawerClose}
                key={item.texto}
                className={classes.listItem}
              >
                {/^(https|http|mailto)?:(\/\/)?/.test(item.link) ? (
                  <a
                    href={item.link}
                    key={`${item.link}-index`}
                    className={classes.link}
                    rel="noopener noreferrer"
                    {...getOnClick(
                      item,
                      this.openLinkWithToken.bind(this),
                      client
                    )}
                  >
                    <span
                      className={cx(css(navbarStyle), classes.listItemText)}
                    >
                      {item.texto}
                    </span>
                  </a>
                ) : (
                  <NavLink
                    activeClassName={classes.active}
                    to={item.link}
                    className={classes.link}
                    key={`${item.link}-index`}
                    isActive={isActive(item.link)}
                    strict={false}
                    target={item.target === "_blank" ? item.target : ""}
                    // {...getOnClick(item, this.openLinkWithToken.bind(this))}
                  >
                    <span
                      className={cx(css(navbarStyle), classes.listItemText)}
                    >
                      {item.texto}
                    </span>
                  </NavLink>
                )}
              </ListItem>
            );
          })}
          <Divider />
          {isLogged && (
            <React.Fragment>
              <ListItem
                button
                onClick={this.handleDrawerClose}
                className={classes.listItem}
              >
                <NavLink
                  activeClassName={classes.active}
                  to={"/profile"}
                  className={classes.link}
                >
                  <span className={cx(css(navbarStyle), classes.listItemText)}>
                    {_t("Mi perfil")}
                  </span>
                </NavLink>
              </ListItem>
              {moduloPuntos && HasPerms ? (
                <ListItem
                  button
                  onClick={this.handleDrawerClose}
                  className={classes.listItem}
                >
                  <NavLink
                    activeClassName={classes.active}
                    to={"/mis-movimientos"}
                    className={classes.link}
                  >
                    <span
                      className={cx(css(navbarStyle), classes.listItemText)}
                    >
                      {_t("Mis movimientos")}
                    </span>
                  </NavLink>
                </ListItem>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
          <ListItem button className={classes.listItem}>
            <ApolloConsumer>
              {client => (
                <span
                  onClick={() => AuthService.logout({ history })}
                  className={cx(
                    classes.link,
                    css(navbarStyle),
                    classes.listItemText
                  )}
                >
                  {_t("Cerrar sesión")}
                </span>
              )}
            </ApolloConsumer>
          </ListItem>
          <ListItem>
            <IdiomasList showBracket />
          </ListItem>
        </List>
      </Drawer>
    );

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar
            className={classNames(classes.appBar, header, {
              [classes.appBarShift]: open,
              [classes.positionFixed]: true
            })}
          >
            <Toolbar style={{ paddingLeft: 0 }}>
              <IconButton
                color={
                  headerBackgroundColor === "#ffffff" ? "primary" : "inherit"
                }
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Header />
            </Toolbar>
          </AppBar>
          {drawer}
          <main
            className={classNames(classes.content, {
              [classes.contentShift]: open
            })}
          >
            <Paper elevation={1} className={classes.paperContent}>
              {container ? (
                <Paper className={classes.container} elevation={5}>
                  <Component {...this.props} />
                </Paper>
              ) : (
                <Component {...this.props} />
              )}
            </Paper>
            <div className={classes.footer}>
              <Footer />
            </div>
          </main>
        </div>
      </div>
    );
  }
}

BurgerLayout.propTypes = {
  classes: PropTypes.object.isRequired
};

BurgerLayout.displayName = "BurgerLayout";

export default BurgerLayout;
