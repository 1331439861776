import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FlagIcon from "@material-ui/icons/Flag";

import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/campanaUnilever.resolver";
import { editGrupoSKUAction } from "js/pages/Unilever/ActionCells";
import { exportAction } from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { setGruposSKUs } from "js/redux/campana/campana.actions";
import advancedFilter from "js/pages/Unilever/Campana/GruposSKU/Tabla/advancedFilter.js";

const defaultHeader = (id, btnsNextTitle) => {
  return {
    title: "Grupos SKU",
    btnsNextTitle,
    importWithDialog: {
      dialog: "GrupoSKU",
      perms: [Roles.GRUPO_SKU_W],
      tooltip: "Importar Grupo SKU",
      model: "Modelo_Importación_Grupo_SKU.csv"
    }
  };
};
const columnData = [
  { id: "nombre", label: "Nombre grupo" },
  { id: "productos_length", label: "Cantidad de productos" },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "nombre" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      },
      setGruposSKUs
    } = this.props;

    const edit = params => ({
      ...editGrupoSKUAction({
        ...params,
        history: this.props.history,
        perms: [Roles.GRUPO_SKU_W]
      })
    });

    const exportQueryString = idGrupo => {
      return `idPrograma=${id}&idGrupo=${idGrupo}`;
    };

    const exportButton = params => ({
      ...exportAction({
        ...params,
        history: this.props.history,
        perms: [Roles.GRUPO_SKU_W],
        tooltip: "Exportar Grupo",
        label: "Exportar Grupo",
        path: "unileverGrupoSKU",
        queryString: exportQueryString
      })
    });

    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Volver a Campañas">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(`/admin/programa/${id}/campanas-unilever`)
            }
          >
            <FlagIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.CAMPANA_OP_R]
    });

    return (
      <Table
        storage="FiltroGrupoSKU"
        query={AppQraphQL.queries.getGruposSKU}
        advancedFilter={advancedFilter}
        defaultHeader={defaultHeader(id, btnsNextTitle)}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getGruposSKU}
        actionsCustom={[edit, exportButton]}
        dispatchAction={setGruposSKUs}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setGruposSKUs: grupos => dispatch(setGruposSKUs(grupos))
});

export default connect(
  null,
  mapDispatchToProps
)(Tabla);
