import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Enhancer from "./container";
import { GeneralWrapper, Section, Subsection } from "../../Layouts";
import { ColorPicker, Input, PixelPicker } from "../../Controls";
import { _t } from "js/utils/TranslationService";
import ImageUploader, { Avatar } from "js/components/Shared/ImageUploader";
import Background from "js/pages/TemplateBuilder2/Components/Background";
import { get } from "lodash";
import Paper from "@material-ui/core/Paper";
import HelpIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";

const Template = ({
  header,
  templateData,
  onChangeTemplateData,
  classes,
  onGetTemplate,
  onChangeImageHeader,
  onChangeImageTemplateData,
  infoPrograma,
  idPrograma,
  conCancelar,
  onChangeHeader
}) => (
  <GeneralWrapper
    title={`Template del programa ${infoPrograma.nombre}`}
    subtitle={`Este es el editor del template del programa ${
      infoPrograma.nombre
    }`}
    onGetTemplate={onGetTemplate}
    idPrograma={idPrograma || infoPrograma.idPrograma}
    conCancelar={conCancelar}
  >
    <Paper className={classes.Paper}>
      <Grid container spacing={24}>
        <Grid item xs={12} md={3} className={classes.imageUploader}>
          <Typography variant="subtitle2" gutterBottom>
            {_t("Logo")}
          </Typography>
          <ImageUploader
            onChangeImage={onChangeImageHeader(
              {},
              "logo",
              "logoPrincipal.url",
              true
            )}
            withRecomendations={false}
          >
            <Avatar url={get(header, "logoPrincipal.url")} />
          </ImageUploader>
        </Grid>
        <Grid item xs={3} md={2} className={classes.imageUploader}>
          <Typography variant="subtitle2" gutterBottom>
            {_t("Favicon")}
          </Typography>
          <ImageUploader
            classes={{ Dropzone: classes.Dropzone }}
            accept="image/jpeg, image/png, image/x-icon"
            formatosSoportados="ICO, JPG y PNG"
            onChangeImage={onChangeImageTemplateData(16, "logo", "favicon")}
            withRecomendations={false}
          >
            <Avatar url={get(templateData, "favicon")} />
          </ImageUploader>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="subtitle2" gutterBottom>
            {_t("Ancho máximo del logo (responsive)")}
          </Typography>
          <PixelPicker
            pixels={get(header, "logoPrincipal.maxWidth")}
            onChange={onChangeHeader("logoPrincipal.maxWidth")}
          />
        </Grid>
      </Grid>
      <Section title="Estilo">
        {/* <Subsection title="General">
          <Grid container spacing={24}>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Padding general")}
              </Typography>
              <PixelPicker
                pixels={get(templateData, "paddingGeneral")}
                onChange={onChangeTemplateData("paddingGeneral")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Border Radius")}
              </Typography>
              <PixelPicker
                pixels={get(templateData, "borderRadiusGeneral")}
                onChange={onChangeTemplateData("borderRadiusGeneral")}
              />
            </Grid>
          </Grid>
        </Subsection> */}

        <Subsection title="Fondos">
          <Grid container spacing={24}>
            <Grid item xs={12} md={6}>
              <Background
                backgrounds={get(templateData, "theme.backgrounds.ui")}
                onChange={onChangeTemplateData}
                title="General"
                path="theme.backgrounds.ui"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Background
                backgrounds={get(
                  templateData,
                  "theme.backgrounds.container",
                  ""
                )}
                onChange={onChangeTemplateData}
                title="Container"
                path="theme.backgrounds.container"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Background
                backgrounds={get(
                  templateData,
                  "theme.backgrounds.generalContainer",
                  ""
                )}
                onChange={onChangeTemplateData}
                title="Container general"
                path="theme.backgrounds.generalContainer"
              />
            </Grid>
          </Grid>
        </Subsection>
        <Subsection title="Paleta de colores">
          <Grid container justify="flex-start" alignItems="center">
            <Grid item xs={2}>
              <Typography variant="subtitle1" className={classes.flex}>
                <div className={classes.marginDetails}>Primero</div>
                <Tooltip
                  className={classes.tooltip}
                  title={_t(
                    "Aplica al color de todos los botones, a la barra de navegación del Back Office y al color de los puntos, los tooltips, los filtros por categorías y ubicaciones y los íconos de favorito en el catálogo"
                  )}
                >
                  <HelpIcon className={classes.tooltipIcon} />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <ColorPicker
                color={get(templateData, "theme.palette.primary.main")}
                onChange={onChangeTemplateData("theme.palette.primary.main")}
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-start" alignItems="center">
            <Grid item xs={2}>
              <Typography variant="subtitle1" className={classes.flex}>
                <div className={classes.marginDetails}>Segundo</div>
                <Tooltip
                  className={classes.tooltip}
                  title={_t(
                    "Aplica al color de componentes Segundos del Back Office tales como Switches y Checkboxes"
                  )}
                >
                  <HelpIcon className={classes.tooltipIcon} />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <ColorPicker
                color={get(templateData, "theme.palette.secondary.main")}
                onChange={onChangeTemplateData("theme.palette.secondary.main")}
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-start" alignItems="center">
            <Grid item xs={2}>
              <Typography variant="subtitle1" className={classes.flex}>
                <div className={classes.marginDetails}>Tercero</div>
                <Tooltip
                  className={classes.tooltip}
                  title={_t(
                    "Aplica al color de la etiqueta del idioma seleccionado en el Front End"
                  )}
                >
                  <HelpIcon className={classes.tooltipIcon} />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <ColorPicker
                color={get(templateData, "theme.palette.tertiary.main")}
                onChange={onChangeTemplateData("theme.palette.tertiary.main")}
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-start" alignItems="center">
            <Grid item xs={2}>
              <Typography variant="subtitle1" className={classes.flex}>
                <div className={classes.marginDetails}>Cuarto</div>
                <Tooltip
                  className={classes.tooltip}
                  title={_t("Aplica al color de fuente del Footer")}
                >
                  <HelpIcon className={classes.tooltipIcon} />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <ColorPicker
                color={get(templateData, "theme.palette.quaternary.main")}
                onChange={onChangeTemplateData("theme.palette.quaternary.main")}
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-start" alignItems="center">
            <Grid item xs={2}>
              <Typography variant="subtitle1" className={classes.flex}>
                <div className={classes.marginDetails}>Quinto</div>
                <Tooltip
                  className={classes.tooltip}
                  title={_t("Aplica al color de fondo del footer")}
                >
                  <HelpIcon className={classes.tooltipIcon} />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <ColorPicker
                color={get(templateData, "theme.palette.quinary.main")}
                onChange={onChangeTemplateData("theme.palette.quinary.main")}
              />
            </Grid>
          </Grid>
        </Subsection>
      </Section>
      <Section title="Otras configuraciones">
        <Grid container spacing={24}>
          <Grid item xs={12} md={3}>
            <Typography variant="subtitle2" gutterBottom>
              {_t("Google Analytics Tracking ID")}
            </Typography>
            <Input
              placeholder="UA-0000000-1"
              value={get(templateData, "GA")}
              onChange={onChangeTemplateData("GA")}
            />
          </Grid>
        </Grid>
      </Section>
    </Paper>
    {/* )} */}
  </GeneralWrapper>
);

Template.defaultProps = {
  hasSegmentoSelected: false
};
Template.propTypes = {
  hasSegmentoSelected: PropTypes.bool,
  programa: PropTypes.shape({
    template: PropTypes.object,
    accesss: PropTypes.object,
    catalogo: PropTypes.object,
    destacadoFormatListNumbereds: PropTypes.object,
    footer: PropTypes.object,
    header: PropTypes.object,
    landing: PropTypes.object,
    templateData: PropTypes.object
  }),
  classes: PropTypes.object,
  onGetTemplate: PropTypes.func.isRequired
};

Template.displayName = "Template";

export default Enhancer(Template);
