import React, { PureComponent, Fragment } from "react";
import Form from "js/pages/Unilever/Campana/Edit/MyForm.js";
import AppQraphQL from "js/graphql/resolvers/campanaUnilever.resolver";
import { removeTypename } from "js/utils/Helper";
import { addImageURL } from "js/constants";
import { getUrl } from "js/utils";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        tipo: "SIMPLE"
      },
      disabled: false,
      errors: null,
      id: this.props.match.params.id,
      fechaInicio: null,
      fechaFin: null,
      fechaCierre: null,
      campanaImages: [],
      campanasInput: [],
      finalCampanasInput: [],
      gruposSKU: []
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      disabled:
        nextProps.match.params.edit !== "edit" &&
        nextProps.match.params.idCampana !== "new"
    });
  }

  async componentDidMount() {
    const { client } = this.props;
    const {
      match: {
        params: { idCampana, id }
      }
    } = this.props;

    await client
      .query({
        query: AppQraphQL.queries.getGruposSKUInput,
        fetchPolicy: "network-only",
        variables: {
          grupoSKULike: {
            idPrograma: id
          }
        }
      })
      .then(res => {
        const gruposSKU = res.data.getGruposSKU
          ? res.data.getGruposSKU.itemsPagina
          : null;
        this.setState({ gruposSKU: gruposSKU });
      });

    await client
      .query({
        query: AppQraphQL.queries.getCampanasInput,
        fetchPolicy: "network-only",
        variables: {
          campanaOPLike: {
            idPrograma: id,
            tipo: "SIMPLE"
          },
          orderBy: "fechaInicio",
          order: "desc"
        }
      })
      .then(res => {
        const campanas = res.data.getCampanasOP
          ? res.data.getCampanasOP.itemsPagina
          : null;

        const finalCampanasInput = [];
        campanas.map(campana => {
          if (campana.estado === "INICIADA") {
            finalCampanasInput.push(campana);
          }
        });
        this.setState({
          campanasInput: campanas,
          finalCampanasInput: finalCampanasInput
        });
      });

    if (idCampana !== "new") {
      await client
        .query({
          query: AppQraphQL.queries.getCampanaById,
          fetchPolicy: "network-only",
          variables: {
            campanaOPLike: {
              _id: idCampana
            }
          }
        })
        .then(res => {
          const campana = res.data.getCampanasOP.itemsPagina[0];
          const { campanasInput, finalCampanasInput } = this.state;

          if (campana.grupoSKU) {
            const grupoSKU = campana.grupoSKU;
            delete campana.grupoSKU;
            campana.grupoSKU = grupoSKU._id;
          }
          if (
            campana.campanasRelacionadas &&
            campana.campanasRelacionadas.length !== 0
          ) {
            const campanasRelacionadas = campana.campanasRelacionadas;
            delete campana.campanasRelacionadas;
            campana.campanasRelacionadas = campanasRelacionadas.map(
              campana => campana._id
            );
          }
          this.setState({
            initialValues: {
              ...campana,
              fields: campana.objetivos
            },
            fechaInicio: campana.fechaInicio,
            fechaFin: campana.fechaFin,
            fechaCierre: campana.fechaCierre,
            campanaImages: campana.imagenes,
            finalCampanasInput:
              campana.estado === "FINALIZADA"
                ? campanasInput
                : finalCampanasInput
          });
        });
    }
  }

  async submit(values) {
    const {
      client,
      history,
      match: {
        params: { idCampana }
      }
    } = this.props;

    const { id } = this.state;
    const { campanaImages: imagenes } = this.state;

    let variables = { ...values };
    variables.fechaInicio = this.state.fechaInicio;
    variables.fechaFin = this.state.fechaFin;
    variables.fechaCierre = this.state.fechaCierre;
    variables.objetivos = variables.fields;
    variables.idPrograma = id;
    variables.imagenes = imagenes;
    delete variables.fields;
    delete variables.gruposSKU;

    if (variables.tipo === "SIMPLE") {
      delete variables.campanasRelacionadas;
    }

    await client
      .mutate({
        mutation:
          idCampana === "new"
            ? AppQraphQL.mutations.createCampana
            : AppQraphQL.mutations.updateCampana,
        variables: {
          campana: { ...removeTypename(variables) }
        }
      })
      .then(res => {
        const mensaje =
          "Campaña " +
          (idCampana === "new" ? "creada" : "modificada") +
          " correctamente";
        this.props.openNotification(mensaje);
        history.push(`/admin/programa/${id}/campanas-unilever`);
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  onChangeImage = image => {
    const formData = new window.FormData();
    formData.append("image", image);
    formData.append("context", "campana");
    formData.append("height", 556);
    formData.append("width", 556);
    fetch(addImageURL, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(({ url }) => {
        this.setState(({ campanaImages }) => ({
          campanaImages: campanaImages.concat(getUrl(url))
        }));
      })
      .catch(() => console.error("Hubo un error al cargar la imagen a S3"));
  };

  onRemoveImage = urlToRemove => {
    this.setState(({ campanaImages }) => ({
      campanaImages: campanaImages.filter(url => url !== urlToRemove)
    }));
  };

  handleDateChange = key => date => {
    this.setState({
      [key]: date.toISOString()
    });
  };

  render() {
    return (
      <Fragment>
        <Form
          id={this.state.id}
          errors={this.state.errors}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
          handleChangeFecha={this.handleDateChange}
          fechaInicio={this.state.fechaInicio}
          fechaFin={this.state.fechaFin}
          campanaImages={this.state.campanaImages}
          onChangeImage={this.onChangeImage.bind(this)}
          onRemoveImage={this.onRemoveImage.bind(this)}
          campanasInput={this.state.campanasInput}
          gruposSKU={this.state.gruposSKU}
        />
      </Fragment>
    );
  }
}

export default Edit;
