import React, { Fragment, Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { get } from "lodash";
import ProgramaGraphQL from "js/graphql/resolvers/programa.resolver.js";
import client from "js/App/client.graphql.js";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import { numberWithCommas } from "js/utils";
import { isInteger } from "js/helpers/validations";
import {
  InputTextField,
  InputNumberField,
  ListField,
  ProgramaField
} from "js/components/Shared/Form/Fields";
import UsuarioGraphQL from "js/graphql/resolvers/usuario.resolver";
import {
  maxSaldoPrograma,
  maxMovimientoPuntosPorUsuario,
  getValuesFromProps
} from "../../utils";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class Form extends Component {
  state = {
    usuarios: [],
    balancePrograma: undefined,
    maximoMovimientoPuntos: undefined
  };

  onChangePrograma = () => {
    const { selectedProgram: idPrograma } = this.props;
    const hasPerm = AuthService.hasPerms([Roles.PROGRAMA_LISTA]);
    if (!idPrograma) return this.setState({ balancePrograma: undefined });
    client
      .query({
        query: hasPerm
          ? ProgramaGraphQL.queries.getBalanceByIdPrograma
          : ProgramaGraphQL.queries.getBalanceMiPrograma,
        errorPolicy: "all",
        fetchPolicy: "network-only",
        variables: hasPerm
          ? { idPrograma }
          : {
              url: window.location.hostname.replace("www.", "")
            }
      })
      .then(res => {
        const {
          cuentaCorriente: { balance: balancePrograma },
          maximoMovimientoPuntos
        } = hasPerm
          ? get(res, "data.getProgramas.itemsPagina[0]", {})
          : get(res, "data.getPrograma", {});
        this.setState({ balancePrograma, maximoMovimientoPuntos });
      });
    client
      .query({
        query: UsuarioGraphQL.queries.getUsuariosByPrograma,
        errorPolicy: "all",
        fetchPolicy: "network-only",
        variables: { idPrograma }
      })
      .then(res => {
        this.setState({
          usuarios: get(res, "data.getUsuarios.itemsPagina", [])
        });
      });
  };

  async componentDidMount() {
    const {
      initialValues: { idUsuario, id: idPrograma },
      client,
      changeProgramaValue
    } = this.props;
    if (!idUsuario || idPrograma) return;
    const { data } = await client.query({
      query: UsuarioGraphQL.queries.getProgramaByUsuario,
      errorPolicy: "all",
      variables: { idUsuario }
    });
    const programaUsuario = get(
      data,
      "getUsuarios.itemsPagina[0].programa.idPrograma"
    );
    changeProgramaValue("selectedProgram", programaUsuario);
    changeProgramaValue("selectedUsuario", idUsuario);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.selectedProgram !== prevProps.selectedProgram) {
      this.onChangePrograma();
    }
  }

  getBalanceUsuario = () => {
    const { usuarios } = this.state;
    const { selectedUsuario } = this.props;
    const usuario = usuarios.find(u => u.idUsuario === selectedUsuario);
    return get(usuario, "cuentaCorriente.balance", undefined);
  };

  getNewBalanceUsuario = balance => {
    const { puntos, tipo } = this.props;
    if (isNaN(puntos) || !tipo || isNaN(balance)) return undefined;
    return tipo === "CREDITO"
      ? parseInt(balance) + parseInt(puntos)
      : parseInt(balance) - parseInt(puntos);
  };

  getNewBalancePrograma = balance => {
    const { puntos, tipo } = this.props;
    if (isNaN(puntos) || !tipo || isNaN(balance)) return undefined;
    return tipo === "CREDITO" ? parseInt(balance) - parseInt(puntos) : balance;
  };

  render() {
    const {
      onSubmit,
      changeProgramaValue,
      classes,
      selectedProgram,
      history,
      match: {
        params: { id }
      },
      disabled
    } = this.props;
    const { balancePrograma, usuarios, maximoMovimientoPuntos } = this.state;
    const balanceUsuario = this.getBalanceUsuario();
    const newBalanceUsuario = this.getNewBalanceUsuario(balanceUsuario);
    const newBalancePrograma = this.getNewBalancePrograma(balancePrograma);
    const values = getValuesFromProps(this.props);

    const submitEnabled = values => {
      let valid =
        !!Object.values(values).every(value => value) && values.puntos > 0;
      valid &= !isInteger(values.puntos);
      valid &= !maxSaldoPrograma(balancePrograma, values.tipo)(values.puntos);
      valid &= !maxMovimientoPuntosPorUsuario(
        maximoMovimientoPuntos,
        values.tipo
      )(values.puntos);
      return valid;
    };

    return (
      <Fragment>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Typography variant="h5" style={{ flex: 1 }}>
              Nuevo movimiento
            </Typography>
          </Grid>
        </Grid>
        <form className={classes.container}>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12} sm={4}>
              <ProgramaField
                name="selectedProgram"
                label={"Programa"}
                noOptionsMessage={"No hay programas"}
                placeholder={"Buscar por programa"}
                onChange={select =>
                  changeProgramaValue("selectedProgram", select.value)
                }
                disabled={disabled || !!id}
                value={get(this.props, "selectedProgram", null)}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.grid}>
              <Typography variant="subtitle2" gutterBottom>
                Saldo CC Programa
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={!selectedProgram ? classes.empty : undefined}
              >
                {balancePrograma ? numberWithCommas(balancePrograma) : "--"}
                <br />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.grid}>
              <Typography variant="subtitle2" gutterBottom>
                Nuevo saldo CC Programa
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={
                  !selectedProgram
                    ? classes.empty
                    : newBalancePrograma < 0
                      ? classes.error
                      : undefined
                }
              >
                {newBalancePrograma || newBalancePrograma === 0
                  ? numberWithCommas(newBalancePrograma)
                  : "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12} sm={4}>
              <TypeAhead
                name="selectedUsuario"
                label={"Usuarios"}
                path={{
                  path: "usuarios",
                  value: "idUsuario",
                  label: "username"
                }}
                placeholder={"Buscar por usuario"}
                suggestions={{ usuarios }}
                noOptionsMessage={"No hay usuarios"}
                onChange={select =>
                  changeProgramaValue("selectedUsuario", select.value)
                }
                value={get(this.props, "selectedUsuario", null)}
                disabled={!selectedProgram || disabled}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.grid}>
              <Typography variant="subtitle2" gutterBottom>
                Saldo CC Usuario
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={
                  !this.props.selectedUsuario ? classes.empty : undefined
                }
              >
                {balanceUsuario ? numberWithCommas(balanceUsuario) : "--"}
                <br />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.grid}>
              <Typography variant="subtitle2" gutterBottom>
                Nuevo saldo CC Usuario
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={
                  !this.props.selectedUsuario
                    ? classes.empty
                    : newBalanceUsuario < 0
                      ? classes.error
                      : undefined
                }
              >
                {newBalanceUsuario || newBalanceUsuario === 0
                  ? numberWithCommas(newBalanceUsuario)
                  : "--"}
              </Typography>
            </Grid>
          </Grid>

          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="concepto"
                label="Concepto"
                disabled={!selectedProgram}
                required
              />
              <InputTextField
                style={{ display: "none" }}
                name="conceptoValue"
                label="Concepto"
                disabled={!selectedProgram}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ListField
                name="tipo"
                label="Tipo"
                options={[
                  { label: "Crédito", value: "CREDITO" },
                  { label: "Débito", value: "DEBITO" }
                ]}
                disabled={!selectedProgram}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputNumberField
                name="puntos"
                label="Puntos"
                disabled={!selectedProgram}
                required
                customValidations={[
                  isInteger,
                  maxSaldoPrograma(balancePrograma, this.props.tipo),
                  maxMovimientoPuntosPorUsuario(
                    maximoMovimientoPuntos,
                    values.tipo
                  )
                ]}
              />
            </Grid>
          </Grid>

          <Grid className={classes.grid} container>
            <Grid className={classes.gridButton} item xs>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => history.push("/admin/cc-usuarios")}
              >
                Cancelar
              </Button>
              <Button
                disabled={!submitEnabled(values)}
                type="button"
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                onClick={() => onSubmit(values)}
              >
                Ejecutar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Fragment>
    );
  }
}

export default Form;
