export const CHANGE_ACCESS = "CHANGE_ACCESS";
export const CHANGE_CATALOGO = "CHANGE_CATALOGO";
export const CHANGE_CATALOGO_BENEFICIOS = "CHANGE_CATALOGO_BENEFICIOS";

export const CHANGE_DESTACADOS = "CHANGE_DESTACADOS";
export const CHANGE_DESTACADOS_BANNER = "CHANGE_DESTACADOS_BANNER";
export const CHANGE_DESTACADOS_BASE = "CHANGE_DESTACADOS_BASE";
export const CHANGE_DESTACADOS_FILA = "CHANGE_DESTACADOS_FILA";
export const CHANGE_DESTACADOS_SLIDER_BASE = "CHANGE_DESTACADOS_SLIDER_BASE";

export const CHANGE_DESTACADOS_BENEFICIOS = "CHANGE_DESTACADOS_BENEFICIOS";

export const CHANGE_FOOTER = "CHANGE_FOOTER";
export const CHANGE_HEADER = "CHANGE_HEADER";
export const CHANGE_ID_SEGMENTO = "CHANGE_ID_SEGMENTO";

export const CHANGE_LANDING = "CHANGE_LANDING";
export const CHANGE_LANDING_BANNER = "CHANGE_LANDING_BANNER";
export const CHANGE_LANDING_BASE = "CHANGE_LANDING_BASE";
export const CHANGE_LANDING_FILA = "CHANGE_LANDING_FILA";
export const CHANGE_LANDING_FILA_BANNER = "CHANGE_LANDING_FILA_BANNER";
export const CHANGE_LANDING_SLIDER_BASE = "CHANGE_LANDING_SLIDER_BASE";

export const CHANGE_REDES_SOCIALES = "CHANGE_REDES_SOCIALES";
export const CHANGE_TEMPLATE_BUILDER = "CHANGE_TEMPLATE_BUILDER";
export const CHANGE_TEMPLATE_DATA = "CHANGE_TEMPLATE_DATA";

export const CLEAR_TEMPLATE = "CLEAR_TEMPLATE";
