import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ReactJson from "react-json-view";
import Button from "@material-ui/core/Button";

import Spinner from "js/components/Shared/Spinner";
import { removeTypename } from "js/utils/Helper";

const View = props => {
  const { logs, history } = props;
  if (logs === undefined) {
    return <Spinner thickness={7} color="inherit" variant="indeterminate" />;
  }
  return (
    <Fragment>
      <ReactJson
        src={removeTypename(logs)}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        indent={5}
        name="Log"
      />
      <Button
        style={{ marginTop: "20px" }}
        onClick={() => history.goBack()}
        variant="contained"
        color="primary"
      >
        Volver
      </Button>
    </Fragment>
  );
};

View.propTypes = {
  classes: PropTypes.object,
  logs: PropTypes.object
};

View.defaultProps = {};

View.displayName = "View";

export default View;
