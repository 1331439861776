import React, { PureComponent } from "react";
import capitalize from "lodash/capitalize";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import NotificacionesIcon from "@material-ui/icons/NotificationsActive";

import Table from "js/components/Shared/Tabla";
import { editAction, viewAction } from "js/components/Shared/Tabla/ActionCells";
import {
  variablesMedAction,
  preventistasMedAction
} from "js/pages/Unilever/ActionCells";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import AppQraphQL from "js/graphql/resolvers/medUnilever.resolver";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import advancedFilter from "js/pages/Unilever/Med/Tabla/advancedFilter";
import { USER_ROLES as Roles } from "js/models/User";
import AuthService from "js/utils/AuthService";

const defaultHeader = (id, isMisMeds, btnsNextTitle) => {
  const header = {
    title: "Mis MEDs",
    queryStringMatcher: {
      idsProgramas: undefined
    }
  };
  if (!isMisMeds) {
    header.title = "MEDs";
    header.new = {
      title: "Nuevo",
      perms: [Roles.MED_W]
    };
    header.import = {
      action: AppQraphQLImport.queries.importarMeds,
      perms: [Roles.MED_W],
      variables: { idPrograma: parseInt(id, 10) },
      model: "Modelo_Meds_Unilever.csv",
      tooltip: "Importar MEDs"
    };
    header.export = {
      path: "unileverMeds",
      perms: [Roles.MED_R],
      tooltip: "Exportar MEDs"
    };
    header.others = [
      {
        action: AppQraphQLImport.queries.importarVariablesMeds,
        perms: [Roles.MED_W],
        model: "Modelo_Variables_Meds_Unilever.csv",
        tooltip: "Importar variables de MEDs por campaña",
        type: "import",
        variables: {
          idPrograma: parseInt(id, 10)
        },
        id: "upload-segmentos"
      },
      {
        path: "unileverVariablesMeds",
        perms: [Roles.MED_R],
        type: "export",
        tooltip: "Exportar variables de MEDs por campaña"
      }
    ];
    if (btnsNextTitle.length) {
      header.btnsNextTitle = btnsNextTitle;
    }
  } else {
    header.export = {
      path: "unileverMisMeds",
      perms: [Roles.MIS_MEDS_R],
      tooltip: "Exportar mis MEDs"
    };
    header.others = [
      {
        path: "unileverVariablesMisMeds",
        perms: [Roles.MIS_MEDS_R],
        type: "export",
        tooltip: "Exportar variables de mis MEDs por campaña"
      }
    ];
  }
  return header;
};

const columnData = [
  { id: "fechaCreacion", label: "Fecha Creación", type: "date" },
  { id: "nombre", label: "Nombre distribuidora" },
  { id: "codigo", label: "Código distribuidora" },
  { id: "tipo", label: "Tipo", removeSort: true },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    removeSort: true,
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "asc", orderBy: "nombre" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    let idPrograma = id;
    if (!idPrograma) {
      idPrograma = parseInt(AuthService.getIdPrograma());
    }
    let storage = "";
    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;
    const isMisMeds = window.location.pathname.indexOf("mis-meds") !== -1;

    const view = params => ({
      ...viewAction({
        ...params,
        history: this.props.history,
        perms: [Roles.MED_R]
      })
    });

    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.MED_W]
      })
    });

    const variablesMed = params => ({
      ...variablesMedAction({
        ...params,
        history: this.props.history,
        perms: [Roles.MED_W]
      })
    });

    const preventistasMed = params => ({
      ...preventistasMedAction({
        ...params,
        history: this.props.history,
        perms: [Roles.PREVENTISTA_PORCENTAJE_R]
      })
    });

    const btnsNextTitle = [];
    const actionsCustom = [view, edit, variablesMed, preventistasMed];
    if (inBackOffice) {
      storage = "FiltroMedsAdmin";
      btnsNextTitle.push({
        btn: (
          <Tooltip title="Notificaciones Unilever">
            <IconButton
              color="primary"
              onClick={() =>
                this.props.history.push(
                  `/admin/programa/${id}/meds/notificaciones`
                )
              }
            >
              <NotificacionesIcon />
            </IconButton>
          </Tooltip>
        ),
        perms: [Roles.MED_R, Roles.MED_W, Roles.PROGRAMA_R, Roles.PROGRAMA_W]
      });
    } else {
      if (isMisMeds) {
        storage = "FiltroMisMeds";
      } else {
        storage = "FiltroMeds";
      }
    }

    return (
      <Table
        storage={storage}
        query={
          isMisMeds
            ? AppQraphQL.queries.getPaginaMisMeds
            : AppQraphQL.queries.getPaginaMeds
        }
        defaultHeader={defaultHeader(idPrograma, isMisMeds, btnsNextTitle)}
        advancedFilter={advancedFilter}
        actionsCustom={actionsCustom}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => (isMisMeds ? data.getMisMeds : data.getMeds)}
        pathId={"codigo"}
      />
    );
  }
}

export default Tabla;
