import React from "react";
import { has, get, pick, isNil } from "lodash";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { _t } from "js/utils/TranslationService";

export const emptyAutocomplete = { label: "", value: undefined };

export const initialState = {
  fechaDesde: moment(),
  fechaHasta: moment(),
  idPrograma: undefined,
  idsProgramas: [],
  idUsuario: undefined,
  selectedProgram: undefined,
  estadoCanje: [],
  idPais: undefined,
  idFactura: undefined,
  idProveedor: undefined,
  administracion: undefined,
  tipoProducto: undefined,
  idProducto: undefined,
  idLote: undefined,
  excluirCanje: false,
  excluirFactura: false,
  withProgram: false,
  cuentaCorrienteLike: {
    idCuentaCorriente: 1
  }
};

export const getProveedoresSuggestions = data =>
  has(data, "getProveedores.itemsPagina") &&
  !isNil(data.getProveedores.itemsPagina)
    ? data.getProveedores.itemsPagina.map(proveedor => ({
        value: proveedor.idProveedor,
        label: proveedor.nombreFantasia
      }))
    : [];

export const getProductosSuggestions = data =>
  has(data, "getProductosFilter.itemsPagina") &&
  !isNil(data.getProductosFilter.itemsPagina)
    ? data.getProductosFilter.itemsPagina.map(producto => ({
        value: producto.idProducto,
        label: producto.nombre
      }))
    : [];

export const getProgramasSuggestions = data =>
  has(data, "getProgramas.itemsPagina") && !isNil(data.getProgramas.itemsPagina)
    ? data.getProgramas.itemsPagina.map(programa => ({
        value: programa.idPrograma,
        label: programa.nombre
      }))
    : [];

export const getMultiUsuariosSuggestions = data => {
  const programas = get(data, "getPrograma.itemsPagina", []);
  let usuarios = [];
  programas.forEach(p => {
    if (p.usuarios && p.usuarios.length) usuarios.push(...p.usuarios);
  });
  return usuarios
    .filter(usuario => usuario.estado === "ACTIVO")
    .map(usuario => ({
      value: usuario.idUsuario,
      label: usuario.username
    }));
};

export const getUsuariosSuggestions = data =>
  has(data, "getPrograma.itemsPagina[0].usuarios") &&
  !isNil(data.getPrograma.itemsPagina[0].usuarios)
    ? data.getPrograma.itemsPagina[0].usuarios
        .filter(usuario => usuario.estado === "ACTIVO")
        .map(usuario => ({
          value: usuario.idUsuario,
          label: usuario.username
        }))
    : [];

export const getSegmentosSuggestions = data =>
  has(data, "getPrograma.itemsPagina[0].segmentos") &&
  !isNil(data.getPrograma.itemsPagina[0].segmentos)
    ? data.getPrograma.itemsPagina[0].segmentos.map(segmento => ({
        value: segmento.idSegmento,
        label: segmento.nombre
      }))
    : [];

export const getPaisSuggestions = data => {
  return has(data, "getPaises") && !isNil(data.getPaises)
    ? data.getPaises.map(pais => ({
        value: pais.idPais,
        label: pais.descripcion
      }))
    : [];
};

export const getFacturasSuggestions = data =>
  has(data, "getFacturas.itemsPagina") && !isNil(data.getFacturas.itemsPagina)
    ? data.getFacturas.itemsPagina.map(factura => ({
        value: factura.idFactura,
        label: factura.numeroFactura
      }))
    : [];

export const updateQueryParamsAsync = ({ key, value }) => state => ({
  [key]: value,
  queryParams: {
    programaLike: { [key]: value }
  }
});

export const updateQueryParamsAsyncMultiple = ({ key, value }) => state => ({
  [key]: state[key].push(value),
  queryParams: {
    programaLike: { [key]: value }
  }
});

export const getSelectOptions = options =>
  [
    <MenuItem key={""} value={""}>
      Todos
    </MenuItem>
  ].concat(
    options.map(op => (
      <MenuItem key={op.value} value={op.value}>
        {op.label}
      </MenuItem>
    ))
  );

const Utils = {
  getProgramasSuggestions,
  getUsuariosSuggestions,
  getSegmentosSuggestions,
  getProveedoresSuggestions,
  getProductosSuggestions,
  getPaisSuggestions,
  getFacturasSuggestions
};

export const getAutocompleteValue = (fn, data, stateValue) =>
  stateValue !== undefined
    ? Utils[fn](data).find(p => p.value === stateValue)
    : { value: undefined, label: "" };

export const maxValue = (max, tipo) => value =>
  tipo === "CREDITO" && value > max
    ? `Este valor no puede superar ${max}`
    : undefined;

export const getBalance = ({ data, defaultValue }) =>
  get(data, "getPrograma.itemsPagina[0].cuentaCorriente.balance", defaultValue);

export const maxMovimientoPuntosPorUsuario = data => value => {
  const maximoMovimientoPuntos = get(
    data,
    "getPrograma.itemsPagina[0].maximoMovimientoPuntos",
    0
  );
  return value && value > maximoMovimientoPuntos
    ? _t(
        "La cantidad de puntos a acreditar no puede superar el máximo configurado para este programa"
      )
    : undefined;
};

export const getValuesFromProps = props =>
  pick(props, [
    "selectedProgram",
    "selectedUsuario",
    "concepto",
    "tipo",
    "puntos"
  ]);

export default Utils;
