import gql from "graphql-tag";

export const getDatosRequeridos = gql`
  query getMasterDatosRequeridos {
    getMasterDatosRequeridos {
      idDatoRequerido
      nombre
      visibleEnCreacionProducto
      estado
      key
    }
  }
`;

export default {
  queries: {
    getDatosRequeridos
  }
};
