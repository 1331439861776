import React from "react";
import { HeaderBelow, HeaderInside, HeaderBurger } from "./Headers";

const Header = props => {
  const { classes, width, ...other } = props;
  const headers = new Map();
  headers.set("below", <HeaderBelow {...other} />);
  headers.set("inside", <HeaderInside {...other} />);
  headers.set("burger", <HeaderBurger {...other} />);

  const tipo = props.programa.template.header.menu.tipo;
  return headers.has(tipo) && width !== "xs"
    ? headers.get(tipo)
    : headers.get("burger");
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
