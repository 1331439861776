import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import base from "js/components/Shared/Tabla/ActionCells/Base";
import AuthService from "js/utils/AuthService";

export default ({ perms = [], optional = [], history, itemId, item }) => ({
  ...base(),
  label: "Editar variables MED",
  icon: <EditIcon />,
  tooltip: "Editar variables",
  show: () => AuthService.hasPerms(perms),
  onclick: () => {
    history.push(window.location.pathname + "/" + item.campana._id);
  }
});
