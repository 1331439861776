import React from "react";
import Tabla from "js/components/TablaEmails";

export const Emails = props => {
  return (
    <div>
      <Tabla />
    </div>
  );
};

export default Emails;
