import gql from "graphql-tag";

export const Beneficio = {};

Beneficio.fragments = {
  beneficio: gql`
    fragment beneficio on Beneficio {
      idBeneficio
      nombre
      copete
      descripcion
      stockInfinito
      stock
      stockSeguridad
      sucursales {
        _id
        nombre
        estado
        direccion
        latitud
        longitud
        zona {
          descripcion
        }
        pais {
          descripcion
        }
      }
      idTipo {
        _id
        tipo
      }
      segmentos {
        idSegmento
        nombre
        estado
      }
      rubros {
        idRubro
        descripcion
        estado
      }
      subrubros {
        idSubrubro
        descripcion
        estado
      }
      paises {
        idPais
        descripcion
        estado
      }
      zonas {
        idZona
        descripcion
        estado
      }
      localidades {
        idLocalidad
        descripcion
        estado
      }
      tieneSucursales
      estado
      fechaCreacion
      fechaModificacion
      tipoValidez
      diasValidez
      fechaValidezDesde
      fechaValidezHasta
      fechaVencimiento
      usuarioCreador {
        username
      }
      usuarioModificador {
        username
      }
      cucarda
      paginaWeb
      condiciones
      emailProveedor
      imagenes
      logo
      favoritos
    }
  `,
  sucursal: gql`
    fragment sucursal on BeneficioSucursal {
      idSucursal
      nombre
      pais {
        idPais
        descripcion
      }
      zona {
        idZona
        descripcion
      }
      localidad {
        idLocalidad
        descripcion
      }
      usuarioCreador {
        username
      }
      usuarioModificador {
        username
      }
      direccion
      estado
      telefono
      notas
      orden
      fechaCreacion
      fechaModificacion
    }
  `,
  solicitud: gql`
    fragment solicitud on BeneficioSolicitud {
      idSolicitud
      fecha
      cantidad
      beneficio {
        idBeneficio
        nombre
        idTipo {
          tipo
        }
        copete
        descripcion
        tipoValidez
        diasValidez
        fechaValidezDesde
        fechaValidezHasta
        fechaVencimiento
        logo
        condiciones
      }
      usuario {
        username
        perfil {
          nombre
          apellido
        }
      }
    }
  `
};

export default Beneficio;
