import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Pagina from "./Pagina";
import styles from "./styles";
import withPrograma from "js/hoc/withPrograma";

import { compose } from "recompose";

const enhance = compose(
  withRouter,
  withPrograma,
  withStyles(styles)
);
export default enhance(Pagina);
