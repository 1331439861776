import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import AuthService from "js/utils/AuthService";
import base from "js/components/Shared/Tabla/ActionCells/Base";
import DialogEditTarget from "js/pages/Unilever/Dialogs/DialogEditTarget";

export default params => ({
  ...base(),
  label: "Editar target",
  icon: <EditIcon />,
  tooltip: "Editar target",
  dialogId: "EDIT_TARGET",
  show: () => AuthService.hasPerms(params.perms),
  dialog: (
    <DialogEditTarget
      handleClose={params.togglePopUp}
      onTableChange={params.onTableChange}
      idTarget={params.itemId}
    />
  )
});
