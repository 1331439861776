import React, { PureComponent } from "react";
import Form from "js/pages/VentaProductoCodificado/Edit/MyForm.js";
import AppGraphQL from "js/graphql/resolvers/ventaProductoCodificado.resolver";
import ProductoGraphQL from "js/graphql/resolvers/productoCodificado.resolver";
import AuthService from "js/utils/AuthService";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    let id = null;
    let inBackOffice = false;
    let idVenta = this.props.match.params.idVenta;
    if (window.location.pathname.indexOf("admin") !== -1) {
      id = this.props.match.params.id;
      inBackOffice = true;
    } else {
      id = parseInt(AuthService.getIdPrograma());
      inBackOffice = false;
    }
    if (!idVenta) {
      idVenta = this.props.computedMatch.params.idVenta;
    }

    this.state = {
      disabled: false,
      errors: null,
      id,
      idVenta,
      isMisVentas: window.location.pathname.indexOf("mis") !== -1,
      initialValues: {},
      open: false,
      inBackOffice,
      producto: null,
      codigos: null,
      codigosSinProducto: false
    };
  }

  async getProductoCodificado(codigo, tipo) {
    const { client } = this.props;
    const { id } = this.state;
    const variable =
      tipo === "CODIGO_QR" ? { codigoQR: codigo } : { codigoBarra: codigo };
    await client
      .query({
        query: ProductoGraphQL.queries.getProductoCodificado,
        fetchPolicy: "network-only",
        variables: {
          productoLike: {
            ...variable,
            idPrograma: id
          }
        }
      })
      .then(res => {
        const producto = res.data.getProductosCodificados.itemsPagina[0];
        const codigos =
          tipo === "CODIGO_QR" ? producto.codigosQR : producto.codigosBarra;
        const codigosActuales = codigos.filter(parCodigos => {
          if (
            (tipo === "CODIGO_QR" && parCodigos.codigoQR === codigo) ||
            (tipo === "CODIGO_BARRA" && parCodigos.codigoBarra === codigo)
          ) {
            return true;
          }
          return false;
        });
        const errors = codigosActuales[0].usados
          ? [{ message: "El código ya fue usado" }]
          : null;
        this.setState({
          producto: producto,
          codigos: codigosActuales[0],
          errors: errors
        });
      });
  }

  async submit(values) {
    const { client, history } = this.props;
    const { id, isMisVentas, inBackOffice } = this.state;
    const codigosVenta = [];

    if (values.codigoQR) {
      codigosVenta.push({ codigo: values.codigoQR, tipo: "CODIGO_QR" });
      if (values.codigoVerificacion) {
        codigosVenta.push({
          codigo: values.codigoVerificacion,
          tipo: "CODIGO_VERIFICADOR"
        });
      }
    }

    if (values.codigoBarra) {
      codigosVenta.push({ codigo: values.codigoBarra, tipo: "CODIGO_BARRA" });
    }

    await client
      .mutate({
        mutation: AppGraphQL.mutations.createVentaProductoCodificado,
        variables: {
          venta: {
            codigos: codigosVenta,
            idPrograma: id,
            idUsuario: parseInt(AuthService.getIdUsuario())
          }
        }
      })
      .then(res => {
        const mensaje = "Producto registrado corrrectamente";
        this.props.openNotification(mensaje);
        history.push(
          inBackOffice
            ? `/admin/programa/${id}/ventas-productos-codificados`
            : isMisVentas
              ? `/registrar-mis-productos`
              : `/registrar-productos`
        );
      })
      .catch(errors => {
        let arr = [];
        let codigosSinProducto = false;
        arr.push({ message: "Error inesperado" });
        if (
          errors.graphQLErrors &&
          errors.graphQLErrors[0].message === "CODIGOS_SIN_PRODUCTO"
        ) {
          errors.graphQLErrors[0].message =
            "Los códigos no están asociados a ningún producto. Escanee el código de barras (SN) para poder asociarlos.";
          codigosSinProducto = true;
        }

        if (values.codigoQR && values.codigoBarra) {
          codigosSinProducto = true;
        }

        this.setState({
          errors:
            errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr,
          codigosSinProducto
        });
        return false;
      });
  }

  resetErrors() {
    this.setState({ errors: null });
  }

  render() {
    return (
      <React.Fragment>
        <Form
          errors={this.state.errors}
          disabled={this.state.disabled}
          onSubmit={this.submit.bind(this)}
          isMisVentas={this.state.isMisVentas}
          initialValues={this.state.initialValues}
          idPrograma={this.state.id}
          idVenta={this.state.idVenta}
          getProductoCodificado={this.getProductoCodificado.bind(this)}
          producto={this.state.producto}
          codigosSinProducto={this.state.codigosSinProducto}
          resetErrors={this.resetErrors.bind(this)}
        />
      </React.Fragment>
    );
  }
}

export default Edit;
