import { _t } from "js/utils/TranslationService";
import React, { Fragment } from "react";
import { compose } from "recompose";
import { FileUploader } from "react-drag-drop-files";
import { ItemLine } from "./components";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withNotificationContext } from "js/notification-context";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Slide,
  Typography,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  TextField,
  Grid,
  CircularProgress,
  Divider
} from "@material-ui/core";
import { styles } from "js/components/Shared/Tabla/Dialogs/styles.js";
import AppGraphQL from "js/graphql/resolvers/canje.resolver";
import Radio from "@material-ui/core/Radio";
import _ from "lodash";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const feedbackMessages = {
  SEND_SUCCESS: "El envió del premio ha sido exitoso",
  PREPARE_SUCCESS: "El premio ha sido preparado para envío",
  EMPTY_VALUES: "Debe ingresar un valor para el premio"
};

class AddPrizeDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      onPrepare: false,
      onSend: false,
      variables: {},
      errors: undefined,
      prizeFormat: "URL",
      url: null,
      urlError: false,
      code: null,
      file: null
    };
  }

  static maxSizeInMB = 5;

  setLoadingState = op => {
    this.setState(prevState => {
      const { onPrepare, onSend } = prevState;

      if (op === "PREPARE") {
        return { onPrepare: !onPrepare };
      } else {
        return { onSend: !onSend };
      }
    });
  };

  handleSubmit = prizeOperation => async event => {
    event.preventDefault();
    const {
      client,
      item: {
        idCanje,
        usuario: {
          programa: { idPrograma }
        }
      }
    } = this.props;
    const { code, file, prizeFormat, url } = this.state;

    try {
      this.setLoadingState(prizeOperation);

      if (_.isNil(code) && _.isNil(file) && _.isNil(url))
        throw new Error(feedbackMessages.EMPTY_VALUES);

      const prize = await client.mutate({
        mutation: AppGraphQL.mutations.uploadCanjePrize,
        options: { fetchPolicy: "network-only" },
        variables: {
          code,
          file,
          idCanje,
          idPrograma,
          prizeFormat,
          prizeOperation,
          url
        }
      });

      if (_.isNil(prize))
        throw new Error(
          `Error al ${
            prizeOperation === "SEND" ? "enviar" : "preparar para envío"
          } el premio`
        );

      this.setLoadingState(prizeOperation);

      const successMessage =
        prizeOperation === "SEND"
          ? feedbackMessages.SEND_SUCCESS
          : feedbackMessages.PREPARE_SUCCESS;

      this.props.openNotification(successMessage);
      this.props.onTableChange();
      this.props.handleClose();
    } catch (error) {
      this.setLoadingState(prizeOperation);
      this.props.openNotification(error.message);
    }
  };

  handleFile = file => {
    this.setState({ file: file });
  };

  handleChange = key => e => {
    this.setState({
      [key]: e.target.value
    });
  };

  handleSizeError = () => {
    this.props.openNotification(
      `El tamaño máximo del archivo es de ${AddPrizeDialog.maxSizeInMB} mb`
    );
    this.setState({ file: null });
  };

  render() {
    const { handleClose, classes, item } = this.props;
    const {
      loading,
      onSubmit,
      file,
      url,
      code,
      prizeFormat,
      onSend,
      onPrepare,
      urlError
    } = this.state;

    return (
      <Fragment>
        <Dialog
          open
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          maxWidth={"md"}
          fullWidth={true}
        >
          <DialogContent>
            <form>
              <DialogTitle style={{ padding: "0 0 1rem 0" }}>
                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                  {_t("Adjuntar premio")}
                </Typography>
              </DialogTitle>

              <DialogContent style={{ padding: "0 0 0 0" }}>
                <Grid container>
                  {/* Columna 1 */}
                  <Grid container item xs={12} sm={6} direction={"column"}>
                    <Typography variant="h6" style={{ margin: "1em 0 1em 0" }}>
                      {_t("Datos del canje: ")}
                    </Typography>
                    <ItemLine fixed={_t("Id canje")} value={item.idCanje} />
                    <ItemLine
                      fixed={_t("Nro. de lote")}
                      value={item.numeroLote}
                    />
                    <ItemLine
                      fixed={_t("Fecha")}
                      value={new Date(item.fecha).toLocaleDateString()}
                    />
                    <ItemLine
                      fixed={_t("Programa")}
                      value={
                        item.usuario && item.usuario.programa
                          ? item.usuario.programa.nombre
                          : undefined
                      }
                    />
                    <ItemLine
                      fixed={_t("Usuario")}
                      value={
                        item.usuario && item.usuario.perfil
                          ? item.usuario.perfil.nombre +
                            " " +
                            item.usuario.perfil.apellido
                          : item.usuario.username
                      }
                    />
                    <ItemLine
                      fixed={_t("Cantidad/producto")}
                      value={`${item.cantidad} x ${
                        item.eleccionProducto && item.eleccionProducto.producto
                          ? item.eleccionProducto.producto.nombre
                          : " "
                      }`}
                    />
                  </Grid>
                  {/* Columna 2 */}
                  <Grid container item xs={12} sm={6} direction={"column"}>
                    <Typography variant="h6" style={{ margin: "1em 0 1em 0" }}>
                      {_t("Seleccione el formato: ")}
                    </Typography>

                    <RadioGroup
                      name="option1"
                      value={this.state.prizeFormat}
                      onChange={this.handleChange("prizeFormat")}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="URL"
                        control={<Radio />}
                        label="Link"
                        className={classes.radioOption}
                      />
                      <FormControlLabel
                        value="FILE"
                        control={<Radio />}
                        label="Subir archivo"
                        className={classes.radioOption}
                      />
                      <FormControlLabel
                        value="CODE"
                        control={<Radio />}
                        label="Código"
                        className={classes.radioOption}
                      />
                    </RadioGroup>
                    {this.state.prizeFormat === "FILE" && (
                      <>
                        <FileUploader
                          label={_t("Arrastra o sube el archivo del premio")}
                          handleChange={this.handleFile}
                          name="file"
                          maxSize={AddPrizeDialog.maxSizeInMB}
                          onSizeError={this.handleSizeError}
                          types={["JPG", "PNG", "PDF"]}
                        />
                        <Typography>
                          {file ? file.name : "no hay archivo asociado"}
                        </Typography>
                      </>
                    )}
                    {this.state.prizeFormat === "URL" && (
                      <TextField
                        type="url"
                        style={{ width: "100%" }}
                        error={urlError}
                        variant="filled"
                        label="Link"
                        value={url}
                        onChange={this.handleChange("url")}
                      />
                    )}
                    {this.state.prizeFormat === "CODE" && (
                      <TextField
                        style={{ width: "100%" }}
                        variant="filled"
                        label={_t("Código")}
                        value={code}
                        onChange={this.handleChange("code")}
                      />
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ margin: "1em 0 0 0" }}>
                <Grid
                  container
                  className={classes.btnsContainer}
                  spacing={8}
                  style={{ margin: "1em 0 0 0" }}
                >
                  <Grid container item xs={12} sm={6}>
                    <Button
                      className={classes.cancelButton}
                      color="primary"
                      onClick={handleClose}
                      type="submit"
                      variant="outlined"
                    >
                      {_t("Cancelar")}
                    </Button>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    className={classes.actionsContainer}
                  >
                    <Button
                      className={classes.actionButtons}
                      color="primary"
                      disabled={onPrepare || onSend ? true : false}
                      onClick={this.handleSubmit("PREPARE")}
                      type="button"
                      variant="outlined"
                    >
                      {this.state.onPrepare ? (
                        <CircularProgress size={24} />
                      ) : (
                        _t("Preparar")
                      )}
                    </Button>
                    <Button
                      className={classes.actionButtons}
                      color="primary"
                      disabled={onSend || onPrepare ? true : false}
                      onClick={this.handleSubmit("SEND")}
                      type="button"
                      variant="contained"
                    >
                      {this.state.onSend ? (
                        <CircularProgress size={24} />
                      ) : (
                        _t("Enviar")
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const enhance = compose(
  withApollo,
  withNotificationContext,
  withRouter,
  withStyles(styles)
);

export default enhance(AddPrizeDialog);
