import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/apiToken.resolver";
import advancedFilter from "js/pages/Admin/Programa/ApiToken/Tabla/advancedFilters.js";
import { USER_ROLES as Roles } from "js/models/User";
import { editAction } from "js/components/Shared/Tabla/ActionCells";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";

const defaultHeader = {
  title: "Api Tokens",
  new: {
    title: "Nuevo",
    perms: [Roles.API_TOKEN_W]
  },
  btnsNextTitle: null
};

const columnData = [
  { id: "fechaCreacion", label: "Fecha creación", type: "date" },
  { id: "nombre", label: "Nombre" },
  { id: "descripcion", label: "Descripción", removeSort: true },
  { id: "estado", label: "Estado" },
  { id: "fechaVencimiento", label: "Fecha vencimiento", type: "date" },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
    };
  }

  resetApiTokenTerceros = async () => {
    const { client } = this.props;
    const {
      match: {
        params: { id }
      }
    } = this.props;

    await client
      .query({
        query: AppQraphQL.queries.resetApiTokenTercero,
        fetchPolicy: "network-only",
        variables: {
          apiTokenTerceroLike: { idPrograma: parseInt(id) || id }
        }
      })
      .then(res => {
        this.props.openNotification(
          `${
            res.data.resetApiTokenTercero.tokensResets
          } usuarios disponibles para retornar.`
        );
      })
      .catch(error => {
        this.props.openNotification(error.message);
      });
  };

  createApiTokenTerceros = async () => {
    const { client } = this.props;
    const {
      match: {
        params: { id }
      }
    } = this.props;

    await client
      .mutate({
        mutation: AppQraphQL.mutations.createApiTokenTercero,
        fetchPolicy: "no-cache",
        variables: {
          apiTokenTercero: { idPrograma: parseInt(id) || id }
        }
      })
      .then(res => {
        this.props.openNotification(
          `${
            res.data.createApiTokenTercero.tokensCreados
          } nuevos tokens de usuarios creados.`
        );
      });
  };

  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.API_TOKEN_W]
      })
    });
    const btnsNextTitle = [];
    btnsNextTitle.push(
      {
        btn: (
          <Tooltip title="Programas Externos">
            <IconButton
              color="primary"
              onClick={() =>
                this.props.history.push(
                  `/admin/programa/${parseInt(id) ||
                    id}/api-tokens/programas-terceros`
                )
              }
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        ),
        perms: [Roles.API_TOKEN_W]
      },
      {
        btn: (
          <Tooltip title="Generar ApiTokenTerceros">
            <IconButton
              color="primary"
              onClick={() => this.createApiTokenTerceros()}
            >
              <AddBoxIcon />
            </IconButton>
          </Tooltip>
        ),
        perms: [Roles.API_TOKEN_W]
      }
      /**
       * Botón para resetear el estado de los api token terceros de
       * los usuarios.
       *
       * TODO: Analizar si dejamos para prd o solo para testeos.
       */
      // {
      //   btn: (
      //     <Tooltip title="Resetear Tokens">
      //       <IconButton
      //         color="primary"
      //         onClick={() => this.resetApiTokenTerceros()}
      //       >
      //         <SettingsBackupRestore />
      //       </IconButton>
      //     </Tooltip>
      //   ),
      //   perms: [Roles.API_TOKEN_W]
      // }
    );

    defaultHeader.btnsNextTitle = btnsNextTitle;

    return (
      <Table
        storage={"FiltroApiToken"}
        query={AppQraphQL.queries.getApiTokens}
        defaultHeader={defaultHeader}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getApiTokens}
        actionsCustom={[edit]}
      />
    );
  }
}

export default Tabla;
