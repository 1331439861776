import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import cargaFacturasGraphQL from "js/graphql/resolvers/cargaFacturas.resolver";
import client from "js/App/client.graphql.js";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LabelIcon from "@material-ui/icons/Label";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import capitalize from "lodash/capitalize";
import { _t } from "js/utils/TranslationService";
import { CircularProgress, Divider } from "@material-ui/core";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import DownloadIcon from "@material-ui/icons/CloudDownload";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogModeracion extends React.Component {
  state = {
    errorMotivo: null,
    errors: null,
    disabled: true,
    disabledButtons: false,
    motivo: null,
    loading: false,
    loadingRechazar: false
  };

  sendValues = async value => {
    this.setState({ errors: null, errorMotivo: null, disabledButtons: true });
    let estado = value;

    estado === "APROBADA"
      ? this.setState({
          loading: true
        })
      : this.setState({ loadingRechazar: true });

    if (estado !== "APROBADA")
      if (
        this.state.motivo === null ||
        this.state.motivo === "" ||
        this.state.motivo.length < 2
      )
        return this.setState({
          errorMotivo: "Completar el motivo con un mínimo de 2 caracteres.",
          disabledButtons: false,
          loading: false
        });

    let tipoDocumento = _t("Factura");

    try {
      await client.mutate({
        mutation: cargaFacturasGraphQL.mutations.updateCargaFactura,
        variables: {
          cargaFactura: {
            id: this.props.factura.itemId,
            estado,
            modificada: true,
            motivo: this.state.motivo,
            idPrograma: this.props.factura.item.idPrograma,
            idUsuario: this.props.factura.item.idUsuario,
            tipoDocumento
          }
        }
      });
      this.props.openNotification(
        `${_t("Factura")} ${
          estado === "APROBADA" ? "aprobada/o" : "rechazada/o"
        } correctamente
        `
      );
      this.setState({
        disabledButtons: false,
        loading: false,
        loadingRechazar: false
      });
      this.props.onTableChange();
      this.props.handleClose();
    } catch (error) {
      this.setState({
        errors: error,
        disabledButtons: false,
        loading: false,
        loadingRechazar: false
      });
      this.props.openNotification(`Ocurrió un error, intente nuevamente`);
    }
  };

  componentDidMount() {
    this.setState({ motivo: this.props.factura.item.motivo || null });
  }

  handleIsAllowedMotivo = factura => {
    // Si el motivo no es string vacio, se bloquea el input
    if (!!factura.item.motivo) return true;
    // Si el motivo no es string vacio, se bloquea el input
    if (
      factura.item.estado === "APROBADA" ||
      factura.item.estado === "RECHAZADA"
    )
      return true;
    // Si tiene el permiso MODERACION_W, se habilita el input
    if (AuthService.hasPerms([Roles.MODERACION_W])) return false;
    return true;
  };

  handleMotivo = e => {
    this.setState({ motivo: e.target.value });
  };

  handleProductSelect = event => {
    this.setState({ selectedProduct: event.target.value });
  };

  handleChange = () => event => {
    this.setState({ lote: event.target.value });
  };

  handleCanSeeMotivo = factura => {
    // Es admin, puede ver siempre
    if (AuthService.hasPerms([Roles.MODERACION_W])) return true;
    // No es admin, puede ver su motivo solo si está rechaza
    else return factura.estado === "RECHAZADA" ? true : false;
  };

  abrirImagenEnNuevaPestana = e => {
    e.stopPropagation(); // Evita que el evento se propague al manejador del diálogo
    window.open(this.props.factura.item.facturaUrl, "_blank");
  };

  render() {
    const { handleClose, classes, factura } = this.props;
    const { disabled, disabledButtons, loading, loadingRechazar } = this.state;

    const props = {
      width: 500,
      height: 600,
      zoomWidth: 500,
      scale: 1.5,
      zoomPosition: "original",
      img: factura.item.facturaUrl
    };

    return (
      <Fragment>
        <Dialog
          open
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogContent>
            <Grid container spacing={16} alignContent={"flex-start"}>
              {/*Columna 1  */}
              <Grid
                container
                spacing={2}
                item
                xs={12}
                md={6}
                style={{ margin: "0 0 0 0 " }}
              >
                <Grid item xs={12}>
                  <DialogTitle
                    id="alert-dialog-slide-title"
                    style={{ paddingLeft: 0 }}
                  >
                    <Typography variant="h4" gutterBottom align="left">
                      {_t("Moderación factura")} #{factura.item.idCargaFactura}
                    </Typography>
                    <Typography variant="h5" gutterBottom align="left">
                      {factura.item.estado === "PENDIENTE_APROBACION"
                        ? _t("Pendiente aprobación")
                        : _t(capitalize(factura.item.estado))}
                    </Typography>
                  </DialogTitle>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ padding: "0 0 0 0", margin: "0 0 0 0" }}
                >
                  <FormControl style={{ width: "90%", margin: "0 0 0 0" }}>
                    <TextField
                      required={true}
                      id="puntoVenta"
                      name={"punto de venta"}
                      label={_t("Punto de Venta")}
                      value={factura.item.puntoVenta}
                      onChange={this.handleChange("puntoVenta")}
                      disabled={disabled}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl style={{ width: "90%", margin: "0 0 0 0" }}>
                    <TextField
                      required={true}
                      id={"numeroFactura"}
                      name={"numeroFactura"}
                      label={_t("Nº Factura")}
                      value={factura.item.numeroFactura}
                      onChange={this.handleChange("numeroFactura")}
                      disabled={disabled}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl style={{ width: "90%", margin: "0 0 0 0" }}>
                    <DatePicker
                      key={"fechaFactura"}
                      name={"fechaFactura"}
                      label={_t("Fecha Factura")}
                      input={{
                        onChange: date =>
                          this.submitValueDate({
                            target: {
                              name: "fechaFactura",
                              value: date.toISOString()
                            }
                          }),
                        value: moment(factura.item.fechaFactura)
                      }}
                      format={"DD/MM/YYYY"}
                      autoOk={true}
                      disabled={disabled}
                      startOfDate
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl style={{ width: "90%", margin: "0 0 0 0" }}>
                    <TextField
                      id={"usuario"}
                      name={"usuario"}
                      label={_t("Usuario")}
                      value={factura.item.usuario.username || ""}
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                {AuthService.hasPerms([Roles.MODERACION_W]) ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <FormControl style={{ width: "90%", margin: "0 0 3% 0" }}>
                        <TextField
                          id={"usuarioModerador"}
                          name={"usuarioModerador"}
                          label={_t("Moderador")}
                          value={
                            factura.item.usuarioModerador
                              ? factura.item.usuarioModerador.username
                              : "--"
                          }
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={12} md={12} style={{ margin: "3% 0 0 0" }}>
                  <Typography variant="h6" align="left">
                    {_t("Detalle Factura")}
                  </Typography>
                  <Divider style={{ width: "95%" }} />
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: "95%", margin: "0 0 0 0" }}>
                    <TextField
                      id={"detalle"}
                      name={"detalle"}
                      label={_t("Detalle")}
                      value={factura.item.detalle}
                      onChange={this.handleChange("detalle")}
                      disabled={disabled}
                      multiline
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="p"
                    gutterBottom
                    align="left"
                    style={{ margin: "7% 0 0 0" }}
                  >
                    {`${_t("Productos asociados")} ${
                      AuthService.hasPerms([Roles.MODERACION_W])
                        ? `(${_t("total")}:  
                        ${factura.item.productos.reduce(
                          (acc, producto) =>
                            acc + producto.cantidad * producto.puntos,
                          0
                        )} ${_t("puntos")})`
                        : ""
                    } 
                  `}
                  </Typography>
                  <List component="nav" dense>
                    {factura.item.productos.map((producto, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <LabelIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={`x${producto.cantidad} - ${
                              producto.nombre
                            }`}
                            secondary={`${_t("Cód.")}: ${producto.codigo} ${
                              AuthService.hasPerms([Roles.MODERACION_W])
                                ? "(" +
                                  producto.cantidad * producto.puntos +
                                  ` ${_t("puntos")})`
                                : ""
                            }
                             `}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>

              {/*Columna 2 */}
              <Grid item xs={12} md={6} style={{ margin: "1% 0 0 0 " }}>
                <Grid
                  item
                  xs={12}
                  style={{
                    width: "auto",
                    height: "480px",
                    maxWidth: "640px",
                    overflow: "hidden",
                    position: "relative"
                  }}
                >
                  <TransformWrapper>
                    {({ zoomIn, zoomOut, resetTransform }) => (
                      <React.Fragment>
                        <TransformComponent>
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              cursor: "pointer"
                            }}
                            src={factura.item.facturaUrl}
                            alt={_t("Factura")}
                          />
                        </TransformComponent>
                        <div
                          style={{
                            position: "absolute",
                            bottom: 20,
                            right: 20
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "1em" }}
                            onClick={() => zoomIn()}
                          >
                            +
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "1em" }}
                            onClick={() => zoomOut()}
                          >
                            -
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => resetTransform()}
                          >
                            Reset
                          </Button>
                        </div>
                        <div
                          style={{ position: "absolute", top: 20, right: 20 }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "1em" }}
                            onClick={this.abrirImagenEnNuevaPestana}
                          >
                            <DownloadIcon />
                          </Button>
                        </div>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </Grid>
                <Grid item xs={12}>
                  {this.handleCanSeeMotivo(this.props.factura.item) && (
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        id="outlined-textarea"
                        error={!!this.state.errorMotivo}
                        label={
                          <span className={classes.rejectLabel}>
                            {_t("Motivo (sólo en caso de rechazo)")}
                          </span>
                        }
                        disabled={this.handleIsAllowedMotivo(factura)}
                        multiline
                        margin="normal"
                        value={this.state.motivo ? this.state.motivo : null}
                        onChange={this.handleMotivo}
                        helperText={
                          !!this.state.errorMotivo ? this.state.errorMotivo : ""
                        }
                      />
                    </FormControl>
                    // </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  justify="space-between"
                  item
                  xs={12}
                  className={classes.prueba}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color={"#e0e0e0"}
                    className={classes.actionButton}
                  >
                    Cancelar
                  </Button>
                  {!factura.item.modificada &&
                  AuthService.hasPerms([Roles.MODERACION_W]) ? (
                    <Button
                      onClick={() => this.sendValues("RECHAZADA")}
                      variant="outlined"
                      color="primary"
                      className={classes.actionButton}
                      disabled={disabledButtons}
                      size="large"
                    >
                      {loadingRechazar ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Rechazar"
                      )}
                    </Button>
                  ) : null}
                  {!factura.item.modificada &&
                  AuthService.hasPerms([Roles.MODERACION_W]) ? (
                    <Button
                      onClick={() => this.sendValues("APROBADA")}
                      variant="contained"
                      color="primary"
                      disabled={disabledButtons}
                      className={classes.submitButton}
                      size="large"
                    >
                      {loading ? <CircularProgress size={20} /> : "Aprobar"}
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

DialogModeracion.propTypes = {
  classes: PropTypes.object.isRequired
};

export default DialogModeracion;
