export default theme => ({
  page: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    height: "100vh",
    justifyContent: "space-between"
  },
  content: {
    flex: 1,
    padding: 20
  },
  footer: {
    padding: 20
  }
});
