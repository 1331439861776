import React, { PureComponent } from "react";
import { withApollo } from "react-apollo";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import AppQraphQL from "js/graphql/resolvers/perfil.resolver";
import { values } from "lodash";

const vars = [
  {
    title: "Usuario",
    values: [["usuario.username", "usuario.saldo", "password"]]
  },
  {
    title: "Vencimiento de puntos",
    values: [["usuario.vencimientoPuntos", "usuario.vencimientoFecha"]]
  },
  {
    title: "Canjes",
    values: [
      [
        "canje.id",
        "canje.fecha",
        "canje.cantidad",
        "canje.puntos",
        "canje.valor",
        "canje.dato.dni",
        "canje.dato.sucursal",
        "canje.dato.lanpass"
      ]
    ]
  },
  {
    title: "Canje Certificado",
    values: [
      [
        "canje.certificado.desde",
        "canje.certificado.hasta",
        "producto.detalleCertificado"
      ]
    ]
  },
  {
    title: "Canje Físico",
    values: [["canje.dato.entrega"]]
  },
  {
    title: "Canje Instantáneo",
    values: [["canje.codigosActivacion"]]
  },
  {
    title: "Producto",
    values: [
      [
        "producto.id",
        "producto.nombre",
        "producto.descripcionCorta",
        "producto.descripcionLarga",
        "producto.condicionCanje",
        "producto.indicacionesEntrega",
        "producto.thumb.url"
      ]
    ]
  },
  {
    title: "Aviso stock mínimo",
    values: [["producto.stock", "producto.stockSeguridad"]]
  },
  {
    title: "Movimiento CC Usuario",
    values: [["movimiento.tipo", "movimiento.puntos", "movimiento.concepto"]]
  },
  {
    title: "Notificaciones",
    values: [["notificacion.fecha", "notificacion.puntos"]]
  },
  {
    title: "Programa",
    values: [["programa.nombre", "programa.dominio"]]
  },
  {
    title: "Beneficio",
    values: [
      [
        "beneficio.idSolicitud",
        "beneficio.idBeneficio",
        "beneficio.nombre",
        "beneficio.copete",
        "beneficio.descripcion",
        "beneficio.condiciones",
        "beneficio.logo",
        "beneficio.stockInfinito",
        "beneficio.stock",
        "beneficio.stockSeguridad",
        "beneficio.tipo",
        "beneficio.validez",
        "beneficio.fechaVencimiento",
        "beneficio.paginaWeb"
      ]
    ]
  },
  {
    title: "Catálogo Códigos",
    values: [
      [
        "beneficio.idSolicitud",
        "beneficio.idBeneficio",
        "beneficio.nombre",
        "beneficio.copete",
        "beneficio.descripcion",
        "beneficio.condiciones",
        "beneficio.logo",
        "beneficio.stockInfinito",
        "beneficio.stock",
        "beneficio.stockSeguridad",
        "beneficio.tipo",
        "beneficio.validez",
        "beneficio.fechaVencimiento",
        "beneficio.paginaWeb",
        "beneficio.url"
      ]
    ]
  },
  {
    title: "Campaña Retail",
    values: [["leyenda"]]
  }
];

const copyToClipBoard = str => {
  const el = document.createElement("textarea");
  el.value = "{{ " + str + " }}";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const getContent = variables => {
  return variables.map(group => (
    <Grid item xs={12} key={group.title}>
      <h4>{group.title}</h4>
      {getItems(group.values)}
    </Grid>
  ));
};

const getItems = values => {
  return (
    <div>
      {values.map((subgroup, i) => (
        <div key={i}>
          {subgroup.map(value => (
            <div
              onClick={e => copyToClipBoard(value)}
              style={{ fontSize: "0.85em", cursor: "copy" }}
              key={value}
            >
              {"{{ " + value + " }}"}
            </div>
          ))}
          <br />
        </div>
      ))}
    </div>
  );
};

class Variables extends PureComponent {
  state = { vars: [] };

  componentWillMount = async () => {
    const {
      client,
      match: {
        params: { id: idPrograma }
      }
    } = this.props;
    const { data } = await client.query({
      query: AppQraphQL.queries.getDefinicionesPerfilById,
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      variables: { idPrograma: parseInt(idPrograma) || idPrograma }
    });
    let profile;
    if (!data) return;
    profile = {
      title: "Datos del perfil",
      values: [(data.getDefinicionesPerfil || []).map(p => `perfil.${p.key}`)]
    };
    const variables = Object.assign([], vars);
    variables.splice(1, 0, profile);
    this.setState({ vars: variables });
  };

  render() {
    return (
      <React.Fragment>
        <Grid container direction="column" justify="space-between">
          <h2>Glosario</h2>
          <div style={{ maxHeight: 1000, overflow: "auto" }}>
            {getContent(this.state.vars)}
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(withApollo(Variables));
