import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaCanalRetail/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/canalRetail.resolver";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import { editAction } from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Store from "@material-ui/icons/Store";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import GroupIcon from "@material-ui/icons/Group";

const defaultHeader = (id, btnsNextTitle) => {
  return {
    title: "Canales Retail",
    btnsNextTitle,
    new: {
      title: "Nuevo",
      perms: [Roles.CANAL_RETAIL_W]
    },
    export: {
      path: "canalesRetail",
      perms: [Roles.CANAL_RETAIL_R, Roles.CANAL_RETAIL_EXPORT],
      tooltip: "Exportar canales retail"
    },
    import: {
      action: AppQraphQLImport.queries.importarCanalesRetail,
      perms: [Roles.CANAL_RETAIL_W, Roles.CANAL_RETAIL_IMPORT],
      tooltip: "Importar canales retail",
      model: "Modelo_Canales_Retail.csv",
      variables: {
        idPrograma: parseInt(id) || id
      }
    },
    queryStringMatcher: {
      idsPrograma: undefined
    },
    others: [
      {
        action: AppQraphQLImport.queries.importarVendedoresCanales,
        perms: [Roles.CANAL_RETAIL_W, Roles.CANAL_RETAIL_IMPORT],
        tooltip: "Importar vendedores de canales",
        model: "Modelo_Canales_Retail_Vendedores.csv",
        modelTooltip: "Modelo de importación de vendedores de canales",
        type: "import",
        variables: {
          idPrograma: parseInt(id, 10)
        },
        id: "upload-canales-vendedores"
      },
      {
        path: "canalesRetail/vendedores",
        type: "export",
        perms: [Roles.CANAL_RETAIL_R, Roles.CANAL_RETAIL_EXPORT],
        tooltip: "Exportar vendedores de canales"
      },
      {
        action: AppQraphQLImport.queries.importarCanalesRetailStock,
        perms: [Roles.CANAL_RETAIL_W, Roles.CANAL_RETAIL_IMPORT],
        tooltip: "Importar stock de canales",
        model: "Modelo_Canales_Retail_Stock.csv",
        modelTooltip: "Modelo de importación de stock de canales",
        type: "import",
        variables: {
          idPrograma: parseInt(id, 10)
        },
        id: "upload-canales-stock"
      },
      {
        path: "canalesRetail/stock",
        perms: [Roles.CANAL_RETAIL_R, Roles.CANAL_RETAIL_EXPORT],
        type: "export",
        tooltip: "Exportar stock de canales"
      }
    ]
  };
};
const columnData = [
  { id: "fechaCreacion", label: "Fecha Creación", type: "date" },
  { id: "codigo", label: "Código" },
  { id: "nombre", label: "Nombre" },
  { id: "email", label: "Email" },
  { id: "rutCi", label: "RUT/CI" },
  { id: "telefono", label: "Teléfono" },
  { id: "movil", label: "Móvil" },
  { id: "localidad", label: "Localidad" },
  { id: "depto", label: "Depto" },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.CANAL_RETAIL_W]
      })
    });

    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Productos">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(`/admin/programa/${id}/productos-retail`)
            }
          >
            <Store />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.PRODUCTO_RETAIL_R]
    });
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Ventas">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(`/admin/programa/${id}/ventas-retail`)
            }
          >
            <AttachMoneyIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.VENTA_RETAIL_R]
    });
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Clientes">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(`/admin/programa/${id}/clientes-retail`)
            }
          >
            <GroupIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.CLIENTE_RETAIL_R]
    });

    return (
      <Table
        storage={"FiltroCanalRetail"}
        query={AppQraphQL.queries.getCanalesRetail}
        defaultHeader={defaultHeader(id, btnsNextTitle)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getCanalesRetail}
        actionsCustom={[edit]}
      />
    );
  }
}

export default Tabla;
