export default theme => ({
  banner: {
    height: "100%"
  },
  base: {
    position: "relative",
    overflow: "hidden",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  hasSpaceBetween: {
    maxWidth: "calc(100% - 15px)"
  },
  link: {
    maxHeight: "100%"
  },
  imageURL: {
    width: "100%",
    maxHeight: "100%"
  },
  caption: {
    bottom: "5px",
    width: "100%",
    font: "bold 1rem/1.5rem Helvetica, Sans-Serif",
    padding: "0 5%",
    textAlign: "left",
    position: "absolute"
  },
  texto: {
    marginTop: "25px",
    marginBottom: "5px",
    fontSize: "1.5rem"
  },
  subtexto: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginTop: 0,
    textOverflow: "ellipsis"
  },
  slider: {},
  noLink: {
    cursor: "unset"
  },
  div: {
    maxHeight: "100%",
    marginBottom: "-4px",
    marginTop: "-2px"
  }
});
