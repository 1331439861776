import gql from "graphql-tag";

export const Accion = {};

Accion.fragments = {
  detalleAccion: gql`
    fragment detalleAccion on Accion {
      _id
      nombre
      descripcion
      fechaInicio
      fechaFin
      fechaCancelacion
      estado
      direccionamientoObligatorio
      targetSegmento {
        idSegmento
        idPrograma
        nombre
      }
      targetPrograma {
        idPrograma
        nombre
      }
      desafioProgramaSegmento
      puntosParticipar
      puntosAcreditar
      acreditaAccionPuntos
      ganarAccionPuntos
      ganarAccionCriterio
      mailInvitacionAccion {
        asunto
        html
      }
      fechaHoraRecordatorio
      mailRecordatorio {
        asunto
        html
      }
      fechaHoraResultado
      mailResultado {
        asunto
        html
      }
      modoMailResultado
      tieneRanking
      tipo
      cantidadDeUsuariosAlActivar
      cantidadDeMailInvitacionAccionAlActivar
      participaciones {
        estado
      }
      permiteMultipleParticipacion
      matematicaRanking
      conceptoPuntosParticipar
      alturaIframe
      definicionesIncluidas
    }
  `,
  detalleMiAccion: gql`
    fragment detalleMiAccion on Accion {
      _id
      nombre
      descripcion
      fechaInicio
      fechaFin
      direccionamientoObligatorio
      puntosParticipar
      puntosAcreditar
      acreditaAccionPuntos
      ganarAccionPuntos
      permiteMultipleParticipacion
      tipo
      estado
      alturaIframe
      participaciones {
        estado
      }
    }
  `,
  tipoAccion: gql`
    fragment tipoAccion on Accion {
      ... on Trivia {
        trivia {
          url
          urlReporte
          urlEdicion
        }
      }
      ... on Formulario {
        formulario {
          url
          urlReporte
          urlEdicion
        }
      }
      ... on Encuesta {
        encuesta {
          url
          urlReporte
          urlEdicion
        }
      }
    }
  `
};

export default Accion;
