import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import LocationFilter from "js/components/Shared/TablaFiltro/locationFilter.js";
import RubroFilter from "js/components/Shared/TablaFiltro/rubroFilter.js";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import SegmentoGraphQL from "js/graphql/resolvers/segmento.resolver.js";
import ProveedorGraphQL from "js/graphql/resolvers/proveedor.resolver.js";
import { withRouter } from "react-router-dom";
import { TIPO_PRODUCTO, ADMINISTRACION } from "js/constants";

class advancedFilter extends PureComponent {
  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  render() {
    const {
      value,
      submitValue,
      classes,
      match: {
        params: { id: idPrograma }
      }
    } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"producto_nombre"}
              name={"producto_nombre"}
              label={"Nombre"}
              type="search"
              value={value["producto_nombre"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              label={"Proveedor"}
              onChange={this.handleChange("idProveedor")}
              path={{
                path: ["getProveedores", "itemsPagina"],
                value: "idProveedor",
                label: "nombreFantasia"
              }}
              placeholder={"Buscar por proveedor"}
              query={ProveedorGraphQL.queries.getProveedoresAutocomplete}
              value={value["idProveedor"]}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              label={"Segmento"}
              onChange={this.handleChange("idSegmento")}
              path={{
                path: ["getSegmentos"],
                value: "idSegmento",
                label: "label"
              }}
              placeholder={"Buscar por segmento"}
              query={SegmentoGraphQL.queries.getSegmentosInput}
              queryParams={{ idPrograma: value.idPrograma }}
              value={value["idSegmento"]}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <LocationFilter
            value={value}
            submitValue={submitValue}
            fromPrograma
            idPrograma={idPrograma}
            zonaFromPrograma
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <RubroFilter
            classes={classes}
            value={value}
            submitValue={submitValue}
            fromPrograma
            idPrograma={idPrograma}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"ACTIVO"}>Activo</MenuItem>
              <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="tipo-producto-simple"
              id="tipo-producto-simple"
            >
              Tipo de producto
            </InputLabel>
            <Select
              value={value["producto_tipoProducto"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "producto_tipoProducto",
                id: "producto_tipoProducto"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              {TIPO_PRODUCTO.map((tipoProducto, index) => (
                <MenuItem key={index} value={tipoProducto.value}>
                  {tipoProducto.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="administracion-simple"
              id="administracion-simple"
            >
              Gestionado por
            </InputLabel>
            <Select
              value={value["producto_gestionadoPor"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "producto_gestionadoPor",
                id: "producto_gestionadoPor"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              {ADMINISTRACION.map((administracion, index) => (
                <MenuItem key={index} value={administracion.value}>
                  {administracion.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(withRouter(advancedFilter));
