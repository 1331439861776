import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import ListCustom from "../ListCustom";

import { CatalogoContext } from "../../../../catalogo-context";

class ItemCustom extends React.Component {
  state = {
    open: false
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  getTitle = (descripcion, cantidad, selected = false) => (
    <div className={this.props.classes.wrapper}>
      <span className={selected ? this.props.classes.selected : ""}>
        {descripcion}
      </span>
      <span className={this.props.classes.cantidad}>({cantidad})</span>
    </div>
  );

  isSeleted = ({ searchCriteria, item }) =>
    searchCriteria.filterByCategories.has(item.request_id)
      ? searchCriteria.filterByCategories.get(item.request_id).has(item.id)
      : false;

  render() {
    const { item, isSubItem, classes } = this.props;
    return (
      <CatalogoContext.Consumer>
        {({ filterBy, searchCriteria }) => {
          if (!item.cantidad) return;
          return (
            <Fragment>
              <ListItem button className={classes.nested}>
                <ListItemText
                  inset={isSubItem}
                  className={isSubItem ? classes.inset : ""}
                  onClick={() =>
                    filterBy.filterByCategory({
                      key: item.request_id,
                      id: item.id
                    })
                  }
                  primary={this.getTitle(
                    item.descripcion,
                    item.cantidad,
                    this.isSeleted({ searchCriteria, item })
                  )}
                />
                {item.children &&
                  item.children.length > 0 &&
                  (this.state.open ? (
                    <ExpandLess onClick={this.handleClick} />
                  ) : (
                    <ExpandMore onClick={this.handleClick} />
                  ))}
              </ListItem>
              {item.children && (
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                  <ListCustom dato={item.children} isSubItem />
                </Collapse>
              )}
            </Fragment>
          );
        }}
      </CatalogoContext.Consumer>
    );
  }
}

ItemCustom.propTypes = {
  classes: PropTypes.object.isRequired
};

export default ItemCustom;
