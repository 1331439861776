import ReportesCLN from "./ReportesCLN";
import { compose, withProps } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import styles from "./styles.js";
import CLNGraphQL from "js/graphql/resolvers/clubLaNacion.resolver.js";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

const enhance = compose(
  withStyles(styles),
  withProps(() => ({
    manage: AuthService.hasPerms([Roles.CLN_REPORTE_W])
  })),
  graphql(CLNGraphQL.queries.getComerciosCLN, {
    options: () => ({
      fetchPolicy: "network-only"
    }),
    props: ({ data: { loading, getComerciosCLN } }) => {
      if (loading) return;
      const comercios = getComerciosCLN.map(comercio => {
        try {
          comercio.otros = JSON.parse(comercio.perfil.otros);
        } catch (e) {
          comercio.otros = {};
        }
        return comercio;
      });
      return {
        comercios
      };
    }
  })
);
export default enhance(ReportesCLN);
