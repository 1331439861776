import { DialogLote, DialogFactura, DialogCrearFactura } from "./Dialogs";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import { USER_ROLES as Roles } from "js/models/User";

export const buttonbarMap = new Map([
  [
    "crear-lote",
    {
      text: "Crear Lote",
      key: "crearLote",
      query: AppQraphQL.queries.getPrograma,
      component: DialogLote,
      perms: [Roles.LOTE_W]
    }
  ],
  [
    "asignar-factura",
    {
      text: "Asignar A Factura",
      key: "asignarFactura",
      query: AppQraphQL.queries.getPrograma,
      component: DialogFactura,
      perms: [Roles.FACTURA_W]
    }
  ],
  [
    "crear-factura-y-asignar",
    {
      text: "Crear Factura",
      key: "crearFacturaAsignar",
      query: AppQraphQL.queries.getPrograma,
      component: DialogCrearFactura,
      perms: [Roles.FACTURA_W]
    }
  ]
]);

export const getInitialValues = () => {
  const init = {};
  for (let [, value] of buttonbarMap) {
    init[`dialog${value.key}`] = false;
  }
  return init;
};
