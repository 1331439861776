import { compose, pure } from "recompose";
import withPrograma from "js/hoc/withPrograma";
import { withApollo } from "react-apollo";
import withStyles from "./styles";

export default compose(
  withStyles,
  pure,
  withApollo,
  withPrograma
);
