import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  form: {
    margin: theme.spacing.unit * 2
  },
  arrow: {
    backgroundColor: "#bababa",
    padding: 5,
    borderRadius: 4,
    margin: 5,
    height: 40,
    width: 40
  }
});

export default withStyles(styles);
