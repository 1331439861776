import Descripcion from "./Descripcion.container";
import { compose } from "recompose";
import { withNotificationContext } from "js/notification-context";
import { withStyles } from "@material-ui/core/styles";
import withPrograma from "js/hoc/withPrograma";
import styles from "./styles";

const enhance = compose(
  withNotificationContext,
  withPrograma,
  withStyles(styles)
);

export default enhance(Descripcion);
