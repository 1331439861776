import React, { PureComponent } from "react";
import moment from "moment";
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withApollo } from "react-apollo";

import PerformanceCampanaGraphQL from "js/graphql/resolvers/campanaPerformance.resolver";
import { _t } from "js/utils/TranslationService";
import AuthService from "js/utils/AuthService";
import SemiCircularIndicator from "./components/semiCircularIndicator";
import TrianguloIndicator from "./components/trianguloIndicator";
import CircularIndicator from "./components/circularIndicator";
import BarrasVerticalIndicator from "./components/barrasVerticalIndicator";
import BarrasHorizontalIndicator from "./components/barrasHorizontalIndicator";
import CampanaPanel from "./components/campanaPanel";

class CampanasLista extends PureComponent {
  constructor(props) {
    super(props);
    const initialState = {};
    props.campanas.forEach((_, index) => {
      initialState[`expandedCampana${index}`] = false;
    });
    this.state = {
      showProgress: false,
      performanceUsuarios: props.performanceCampana
        ? props.performanceCampana.performanceUsuarios
        : [],
      page: 1,
      ...initialState
    };
  }

  getUserName = usuario => {
    if (!usuario) {
      return AuthService.getIdUsuario();
    }
    return usuario.perfil && (usuario.perfil.nombre || usuario.perfil.apellido)
      ? `${usuario.perfil.nombre} ${usuario.perfil.apellido}`
      : usuario.username;
  };

  renderPerformanceIndividual = (
    classes,
    i,
    performanceUsuario,
    showName,
    campana,
    parametrosVisuales,
    isXs
  ) => {
    const nombre = this.getUserName(performanceUsuario.usuario);
    const tipoVisual = parametrosVisuales.tipoVisual;
    const smValue =
      performanceUsuario.performanceObjetivos.length === 1 ? 12 : 6;
    const indicatorClass = smValue === 6 ? classes.indicatorContainer : "";
    return (
      <Grid
        className={isXs ? classes.xsContainer : classes.container}
        item
        xs={12}
        key={i}
      >
        {showName && <div className={classes.usernameTitle}>{nombre}</div>}
        <Grid
          className={isXs ? classes.xsContainer : classes.container}
          container
          spacing={isXs ? 0 : 16}
        >
          {performanceUsuario.performanceObjetivos.map(
            (performanceObjetivo, j) => {
              if (tipoVisual === "SEMI_CIRCULAR") {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={`container-${nombre}-${i}-${j}`}
                    className={indicatorClass}
                    style={{
                      margin: "0 auto"
                    }}
                  >
                    <SemiCircularIndicator
                      key={`${nombre}-${i}-${j}`}
                      index={`${nombre}-${i}-${j}`}
                      performanceObjetivo={performanceObjetivo}
                      parametrosVisuales={parametrosVisuales}
                      isXs={isXs}
                    />
                  </Grid>
                );
              } else if (tipoVisual === "TRIANGULOS") {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={`container-${nombre}-${i}-${j}`}
                    className={indicatorClass}
                    style={{
                      margin: "0 auto"
                    }}
                  >
                    <TrianguloIndicator
                      key={`${nombre}-${i}-${j}`}
                      index={`${nombre}-${i}-${j}`}
                      performanceObjetivo={performanceObjetivo}
                      parametrosVisuales={parametrosVisuales}
                      isXs={isXs}
                    />
                  </Grid>
                );
              } else if (tipoVisual === "CIRCULAR") {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={`container-${nombre}-${i}-${j}`}
                    className={indicatorClass}
                    style={{
                      margin: "0 auto"
                    }}
                  >
                    <CircularIndicator
                      key={`${nombre}-${i}-${j}`}
                      index={`${nombre}-${i}-${j}`}
                      performanceObjetivo={performanceObjetivo}
                      parametrosVisuales={parametrosVisuales}
                      isXs={isXs}
                    />
                  </Grid>
                );
              } else if (tipoVisual === "BARRAS_VERTICAL") {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={smValue === 12 ? 8 : 6}
                    key={`container-${nombre}-${i}-${j}`}
                    className={indicatorClass}
                    style={{
                      width: smValue === 12 && !isXs ? "75%" : "100%",
                      margin: smValue === 12 && !isXs ? "0 auto" : "0"
                    }}
                  >
                    <BarrasVerticalIndicator
                      key={`${nombre}-${i}-${j}`}
                      index={`${nombre}-${i}-${j}`}
                      performanceObjetivo={performanceObjetivo}
                      parametrosVisuales={parametrosVisuales}
                      isXs={isXs}
                    />
                  </Grid>
                );
              } else if (tipoVisual === "BARRAS_HORIZONTAL") {
                // Si estamos en mobile (xs), visualmente queda dificil mostrar una barra de avance horizontal
                // por lo tanto se renderiza la visualización vertical
                if (!isXs) {
                  return (
                    <Grid item xs={12} key={`container-${nombre}-${i}-${j}`}>
                      <BarrasHorizontalIndicator
                        key={`${nombre}-${i}-${j}`}
                        index={`${nombre}-${i}-${j}`}
                        performanceObjetivo={performanceObjetivo}
                        parametrosVisuales={parametrosVisuales}
                        isXs={isXs}
                      />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={`container-${nombre}-${i}-${j}`}
                      className={indicatorClass}
                      style={{
                        width: "100%",
                        margin: "0"
                      }}
                    >
                      <BarrasVerticalIndicator
                        key={`${nombre}-${i}-${j}`}
                        index={`${nombre}-${i}-${j}`}
                        performanceObjetivo={performanceObjetivo}
                        parametrosVisuales={parametrosVisuales}
                        isXs={isXs}
                      />
                    </Grid>
                  );
                }
              }
              return (
                <Grid item xs={12} key={`container-${nombre}-${i}-${j}`}>
                  <BarrasHorizontalIndicator
                    key={`${nombre}-${i}-${j}`}
                    index={`${nombre}-${i}-${j}`}
                    performanceObjetivo={performanceObjetivo}
                    parametrosVisuales={parametrosVisuales}
                    isXs={isXs}
                  />
                </Grid>
              );
            }
          )}
        </Grid>
      </Grid>
    );
  };

  handleCampanaCollapse = panelIndex => () => {
    this.setState(prevState => ({
      [`expandedCampana${panelIndex}`]: !prevState[
        `expandedCampana${panelIndex}`
      ]
    }));
  };

  hasCompletedObjetivos = objetivos => {
    let completed = true;
    objetivos.map(objetivo => {
      if (objetivo.performance < objetivo.target) {
        completed = false;
      }
    });
    return completed;
  };

  hasCompletedCampana = () => {
    const { performanceCampana, isMiPerformance, isPerformance } = this.props;
    let objetivos = [];
    if (isPerformance) {
      objetivos = performanceCampana.total;
    }

    if (isMiPerformance) {
      objetivos = performanceCampana.individual.itemsPagina[0].objetivos;
    }

    const completed = this.hasCompletedObjetivos(objetivos);
    return completed;
  };

  renderFechaActualizacion(parametrosVisuales, fechaActualizacion) {
    return (
      <div
        style={{
          color: `${parametrosVisuales.colorFechaActualizacion}`,
          fontSize: `${parametrosVisuales.sizeFechaActualizacion}px`,
          fontFamily: `${parametrosVisuales.fontFamily}`,
          fontStyle: "italic"
        }}
      >
        {`${_t("Datos actualizados al ")} ${moment(fechaActualizacion).format(
          parametrosVisuales.formatoFechas
        )}`}
      </div>
    );
  }

  handleSeeMore = () => {
    this.setState({ showProgress: true }, () => {
      this.getMoreUsersPerformances();
    });
  };

  getMoreUsersPerformances = async () => {
    const {
      client,
      idPrograma,
      parametrosVisuales,
      performanceCampana
    } = this.props;
    const { performanceUsuarios, page } = this.state;

    const newPage = page + 1;

    await client
      .query({
        query: PerformanceCampanaGraphQL.queries.getPerformanceCampana,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: idPrograma,
          idCampana: performanceCampana.campana._id,
          page: newPage,
          rowsPerPage: parametrosVisuales.usuariosPorCampana
            ? parametrosVisuales.usuariosPorCampana
            : 5
        }
      })
      .then(res => {
        const items = res.data.getPerformanceCampana.itemsPagina;
        const performanceUsuariosUpdated = performanceUsuarios.concat(items);

        this.setState({
          performanceUsuarios: performanceUsuariosUpdated,
          showProgress: false,
          page: newPage
        });
      });
  };

  render() {
    const {
      classes,
      parametrosVisuales,
      isPerformance,
      isMiPerformance,
      idPrograma,
      campanas,
      onSearch,
      idUsuario
    } = this.props;
    const isXs = window.innerWidth < 600 ? true : false;
    return campanas.map((campana, index) => (
      <ExpansionPanel
        expanded={this.state[`expandedCampana${index}`]}
        onChange={this.handleCampanaCollapse(index)}
        elevation={1}
        key={index}
        className={
          parametrosVisuales.campanaContainerShadow ? "" : classes.root
        }
      >
        <ExpansionPanelSummary
          classes={{
            content: classes.panelSummary,
            root: parametrosVisuales.campanaContainerShadow
              ? ""
              : classes.panelRoot,
            expandIcon: parametrosVisuales.campanaContainerShadow
              ? ""
              : classes.expandIcon
          }}
          expandIcon={
            <ExpandMoreIcon
              style={{
                fontSize: parametrosVisuales.sizeFlecha,
                color: parametrosVisuales.colorFlecha
              }}
            />
          }
        >
          <div
            className={classes.campanaContainer}
            style={{
              paddingRight: parametrosVisuales.campanaContainerShadow
                ? `${parametrosVisuales.sizeFlecha}px`
                : `${parametrosVisuales.sizeFlecha + 20}px`
            }}
          >
            <div style={{ display: "flex" }}>
              {parametrosVisuales.barraCampana && (
                <div
                  style={{
                    width: `${parametrosVisuales.anchoBarraCampana}px`,
                    backgroundColor: `${parametrosVisuales.colorBarraCampana}`,
                    marginRight: "10px"
                  }}
                />
              )}
              {campana.imagenes &&
                campana.imagenes[0] && (
                  <img
                    alt={`imagen-campana-${campana.nombre
                      .replace(/\s/g, "-")
                      .toLowerCase()}`}
                    src={campana.imagenes[0]}
                    style={{ maxWidth: "100px", marginRight: "10px" }}
                  />
                )}
              <div className={classes.campanaTitle}>
                <div
                  style={{
                    color: `${parametrosVisuales.colorNombreCampana}`,
                    fontSize: `${parametrosVisuales.sizeNombreCampana}px`,
                    fontWeight: "700",
                    fontFamily: `${parametrosVisuales.fontFamily}`
                  }}
                >
                  {campana.nombre}
                </div>
                <div
                  style={{
                    color: `${parametrosVisuales.colorFechasInicioFin}`,
                    fontSize: `${parametrosVisuales.sizeFechasInicioFin}px`,
                    fontFamily: `${parametrosVisuales.fontFamily}`
                  }}
                >
                  {moment(campana.fechaInicio).format(
                    `${parametrosVisuales.formatoFechas}`
                  )}{" "}
                  -{" "}
                  {moment(campana.fechaFin).format(
                    `${parametrosVisuales.formatoFechas}`
                  )}
                </div>
              </div>
            </div>
            <div className={classes.campanaEstado}>
              <div
                style={{
                  backgroundColor: `${
                    parametrosVisuales.colorBackgroundEstado
                  }`,
                  color: `${parametrosVisuales.colorEstado}`,
                  fontSize: `${parametrosVisuales.sizeEstado}px`,
                  fontFamily: `${parametrosVisuales.fontFamily}`,
                  padding: `3px ${
                    parametrosVisuales.colorBackgroundEstado
                      ? parametrosVisuales.colorBackgroundEstado
                          .toLowerCase()
                          .indexOf("fffff") !== -1
                        ? "0px"
                        : "15px"
                      : "15px"
                  }`,
                  marginBottom: "5px"
                }}
              >
                {campana.estado === "INICIADA" ? "ACTIVA" : campana.estado}
              </div>
            </div>
          </div>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={isXs ? classes.detailsRoot : ""}>
          <Grid
            className={isXs ? classes.xsContainer : classes.container}
            container
            spacing={isXs ? 0 : 16}
          >
            <CampanaPanel
              key={index}
              parametrosVisuales={parametrosVisuales}
              campana={campana}
              expandedCampana={this.state[`expandedCampana${index}`]}
              classes={classes}
              isPerformance={isPerformance}
              isMiPerformance={isMiPerformance}
              idPrograma={idPrograma}
              onSearch={onSearch}
              idUsuario={idUsuario}
            />
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ));
  }
}

export default withApollo(CampanasLista);
