import gql from "graphql-tag";
import Fragments from "../fragments";

const { Accion } = Fragments;

export const getPaginaAcciones = gql`
  query getAcciones(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: AccionColumn!
    $estado: EstadoAccion
    $nombre: String
    $tipo: TipoAccion
    $idPrograma: Int
    $idSegmento: Int
    $fechaInicio: Date
    $fechaFin: Date
  ) {
    getAcciones(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      accionOrder: { column: $orderBy, order: $order }
      accionLike: {
        nombre: $nombre
        estado: $estado
        tipo: $tipo
        idPrograma: $idPrograma
        idTargetSegmento: $idSegmento
      }
      accionRange: {
        fechaInicio: { min: $fechaInicio }
        fechaFin: { min: $fechaFin }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        nombre
        tipo
        estado
        fechaInicio
        fechaFin
        targetSegmento {
          nombre
        }
        targetPrograma {
          nombre
        }
      }
    }
  }
`;

export const getAccionById = gql`
  query getAccionById($idAccion: ID!) {
    getAcciones(accionLike: { _id: $idAccion }) {
      itemsPagina {
        ...detalleAccion
        ...tipoAccion
      }
    }
  }
  ${Accion.fragments.detalleAccion}
  ${Accion.fragments.tipoAccion}
`;

export const getRankingAccion = gql`
  query getRankingAccion(
    $idAccion: ID!
    $page: Int!
    $rowsPerPage: Int!
    $idUsuario: Int
  ) {
    getRankingAccion(
      idAccion: $idAccion
      idUsuario: $idUsuario
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
    ) {
      itemsPagina {
        _id
        usuario {
          username
          avatarUrl
          perfil {
            nombre
            apellido
          }
        }
        puntosRanking
        posicion
      }
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
    }
  }
`;

export const getMiAccionById = gql`
  query misAcciones($idAccion: ID!) {
    misAcciones(accionLike: { _id: $idAccion, estado: ACTIVA }) {
      itemsPagina {
        ...detalleMiAccion
        ...tipoAccion
      }
    }
  }
  ${Accion.fragments.detalleMiAccion}
  ${Accion.fragments.tipoAccion}
`;

export const createAccion = gql`
  mutation createAccion($accion: AccionCreate!) {
    createAccion(accion: $accion) {
      _id
    }
  }
`;

export const updateAccion = gql`
  mutation updateAccion($accion: AccionUpdate!) {
    updateAccion(accion: $accion) {
      _id
    }
  }
`;

export const activarAccion = gql`
  mutation activarAccion($idAccion: ID!) {
    activarAccion(_id: $idAccion) {
      _id
    }
  }
`;

export const finalizarAccion = gql`
  mutation finalizarAccion($idAccion: ID!) {
    finalizarAccion(_id: $idAccion) {
      _id
    }
  }
`;

export const generarTokenParticipacion = gql`
  mutation generarTokenParticipacion($idAccion: ID!) {
    generarTokenParticipacion(idAccion: $idAccion)
  }
`;

export default {
  queries: {
    getPaginaAcciones,
    getAccionById,
    getMiAccionById,
    getRankingAccion
  },
  mutations: {
    createAccion,
    updateAccion,
    activarAccion,
    finalizarAccion,
    generarTokenParticipacion
  }
};
