import gql from "graphql-tag";

export const getTargetsObjetivos = gql`
  query getTargetsObjetivos(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: TargetObjetivoColumn!
    $idPrograma: Int
    $idCampana: ID
    $idObjetivo: ID
    $idUsuario: Int
    $estado: EstadoTargetObjetivo
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getTargetsObjetivos(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      targetObjetivoOrder: { column: $orderBy, order: $order }
      targetObjetivoLike: {
        idPrograma: $idPrograma
        idCampana: $idCampana
        idObjetivo: $idObjetivo
        idUsuario: $idUsuario
        estado: $estado
      }
      targetObjetivoRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        idTarget
        campana {
          nombre
        }
        objetivo {
          nombre
        }
        usuario {
          username
          perfil {
            nombre
            apellido
          }
        }
        target
        targetFloat
        targetOriginal
        estado
        fechaCreacion
        usuarioCreador {
          perfil {
            nombre
            apellido
          }
        }
      }
    }
  }
`;

export const importTargetsObjetivos = gql`
  mutation importTargetsObjetivos($file: Upload!, $idPrograma: Int!) {
    importTargetsObjetivos(file: $file, idPrograma: $idPrograma)
  }
`;

export default {
  queries: {
    getTargetsObjetivos
  },
  mutations: {
    // createTargetsObjetivos,
    // updateTargetObjetivo,
    importTargetsObjetivos
    // deleteTargetObjetivo
  }
};
