import { withStyles } from "@material-ui/core/styles";
import UserInfo from "./UserInfo.container";
import styles from "./styles";
import withPrograma from "js/hoc/withPrograma";
import { withRouter } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import { withApollo } from "react-apollo";
import { compose } from "recompose";

const enhance = compose(
  withRouter,
  withPrograma,
  withWidth(),
  withApollo,
  withStyles(styles, { withTheme: true })
);
export default enhance(UserInfo);
