export default theme => ({
  title: {
    fontWeight: "bold"
  },
  container: {
    padding: theme.spacing.unit * 4
  },
  puntosContainer: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    backgroundColor: theme.palette.primary.main
  }
});
