import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/usuario.resolver";
import advancedFilter from "js/components/TablaUsuario/advancedFilter.js";
import { withRouter } from "react-router-dom";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import {
  editAction,
  resetPasswordAction,
  ccUsuarioAction,
  viewAction
} from "js/components/Shared/Tabla/ActionCells";
import capitalize from "lodash/capitalize";
import { numberWithCommas } from "js/utils";
import { USER_ROLES as Roles } from "js/models/User";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

const defaultHeader = (id, btnsNextTitle) => {
  return {
    title: "Usuarios",
    btnsNextTitle,
    new: {
      title: "Nuevo",
      perms: [Roles.CREAR_USUARIO_W]
    },
    import: {
      action: AppQraphQLImport.queries.importarUsuarios,
      perms: [Roles.IMPORTAR_USUARIO_W],
      variables: { idPrograma: parseInt(id, 10) },
      model: "USUARIOS.csv"
    },
    export: {
      path: "usuarios",
      perms: [Roles.USUARIO_R]
    },
    queryStringMatcher: {
      idsProgramas: undefined
    }
  };
};

const simplifyHeader = {
  title: "Usuarios",
  new: {
    title: "Nuevo",
    perms: [Roles.USUARIO_W]
  },
  queryStringMatcher: {
    idsProgramas: undefined
  }
};

let columnData = [
  { id: "fechaDeAlta", label: "Fecha de alta", type: "date" },
  { id: "username", label: "Usuario" },
  { id: "perfil_nombre", label: "Nombre" },
  { id: "perfil_apellido", label: "Apellido" },
  { id: "programa_nombre", label: "Programa" },
  { id: "segmento_nombre", label: "Segmento" },
  {
    id: "cuentaCorriente",
    label: "Balance de la CC",
    type: "custom",
    isNumeric: true,
    parser: cuentaCorriente =>
      cuentaCorriente.balance ? numberWithCommas(cuentaCorriente.balance) : "--"
  },
  {
    id: "roles",
    label: "Rol",
    type: "custom",
    parser: roles => (roles.length ? capitalize(roles[0].nombre) : "--")
  },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fechaDeAlta" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    let idProgramaFE = null;
    let jerarquiaUrl = "";
    // En la pantalla de usuarios en el FE, setar el id del programa desde el localStorage
    // cambiar la url para la el botón de jerarquía y sacar la columnda de Programa.
    if (window.location.pathname.indexOf("admin") === -1) {
      idProgramaFE = parseInt(localStorage.getItem("idPrograma"));
      jerarquiaUrl = `/usuarios/jerarquia`;
      columnData.splice(4, 1);
    } else {
      jerarquiaUrl = `/admin/programa/${id}/jerarquia`;
    }

    const resetPassword = params => ({
      ...resetPasswordAction({
        ...params,
        history: this.props.history,
        openNotification: this.props.openNotification,
        perms: [Roles.BLANQUEO_PASSWORD_W]
      })
    });

    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.USUARIO_W]
      })
    });

    const viewCCUsuario = params => ({
      ...ccUsuarioAction({
        ...params,
        history: this.props.history,
        perms: [Roles.MOVIMIENTO_USUARIO_R]
      })
    });

    const view = params => ({
      ...viewAction({ ...params, history: this.props.history })
    });

    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Jerarquía">
          <IconButton
            color="primary"
            onClick={() => this.props.history.push(jerarquiaUrl)}
          >
            <SupervisorAccountIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.JERARQUIA_R]
    });

    return (
      <Table
        storage={"FiltroUsuario"}
        query={AppQraphQL.queries.getPaginaUsuarios}
        defaultHeader={
          id
            ? defaultHeader(id, btnsNextTitle)
            : idProgramaFE
              ? defaultHeader(idProgramaFE, btnsNextTitle)
              : simplifyHeader
        }
        advancedFilter={advancedFilter}
        actionsCustom={[viewCCUsuario, view, edit, resetPassword]}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getUsuarios}
      />
    );
  }
}

export default withRouter(Tabla);
