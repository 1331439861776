import { compose } from "recompose";
import { withApollo } from "react-apollo";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import AppGraphQLCodigosActivacion from "js/graphql/resolvers/codigoActivacion.resolver";
import AppGraphQLCargadeCodigos from "js/graphql/resolvers/cargadeCodigos.resolver";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography
} from "@material-ui/core";
import { CardCanjeInfo } from "./CardCanjeInfo";

class DialogCanje extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      codigoFound: {}
    };
  }

  fetchCodigos = async () => {
    const { codigo, client } = this.props;

    if (!codigo) return;
    this.setState({ loading: true });

    try {
      const { data } = await client.query({
        query: AppGraphQLCodigosActivacion.queries.getCodigosActivacion,
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        variables: { codigoSearch: codigo }
      });

      let codigoFound = {};

      if (
        data &&
        data.getCodigosActivacion &&
        data.getCodigosActivacion.length > 0
      ) {
        const cod = data.getCodigosActivacion[0];

        codigoFound = {
          codigo: cod.codigo,
          estado: cod.estado,
          idCanje: cod.canje ? cod.canje.idCanje : null,
          nombre:
            cod.canje && cod.canje.usuario && cod.canje.usuario.perfil
              ? cod.canje.usuario.perfil.nombre
              : null,
          apellido:
            cod.canje && cod.canje.usuario && cod.canje.usuario.perfil
              ? cod.canje.usuario.perfil.apellido
              : null,
          nombreProducto: cod.canje ? cod.canje.nombreProducto : null,
          imagen:
            cod.producto &&
            cod.producto.imagenes &&
            cod.producto.imagenes.length > 0
              ? cod.producto.imagenes[0].url
              : null,
          alt:
            cod.producto &&
            cod.producto.imagenes &&
            cod.producto.imagenes.length > 0
              ? cod.producto.imagenes[0].url
              : null,
          origen: "codigoActivacion"
        };
      } else {
        const { data } = await client.query({
          query: AppGraphQLCargadeCodigos.queries.getCargadeCodigos,
          fetchPolicy: "no-cache",
          errorPolicy: "all",
          variables: {
            idPrograma: parseInt(localStorage.getItem("idPrograma")),
            codigo: codigo,
            estado: "ACTIVO",
            page: 1,
            rowsPerPage: 1,
            order: "desc",
            orderBy: "fechaCreacion"
          }
        });

        if (
          data &&
          data.getCargadeCodigos.itemsPagina &&
          data.getCargadeCodigos.itemsPagina.length > 0
        ) {
          const cod = data.getCargadeCodigos.itemsPagina[0];
          codigoFound = {
            codigo: cod.codigo,
            estado: cod.estado,
            idCanje: null,
            nombre: null,
            apellido: null,
            nombreProducto: cod.marca ? cod.marca : null,
            imagen: null,
            alt: null,
            origen: "cargadeCodigos"
          };
        }
      }

      if (codigoFound.codigo !== codigo) {
        codigoFound = {};
      }

      this.setState({
        loading: false,
        codigoFound
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  onHandleSubmit = () => {
    const { onSubmit, onClose } = this.props;
    onSubmit();
    onClose();
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.openDialog && this.props.openDialog) {
      this.fetchCodigos();
    }
  }

  render() {
    const { openDialog, onClose, codigo } = this.props;
    const { codigoFound, loading } = this.state;
    return (
      <Dialog
        fullScreen={false}
        open={openDialog}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        {loading ? (
          <DialogContent style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </DialogContent>
        ) : (codigoFound.origen === "codigoActivacion" &&
          codigoFound.idCanje !== null &&
          codigoFound.estado === "ENVIADO") ||
        (codigoFound.estado === "ACTIVO" &&
          codigoFound.origen === "cargadeCodigos") ? (
          <CardCanjeInfo
            codigoFound={codigoFound}
            onClose={onClose}
            handleSubmit={this.onHandleSubmit}
          />
        ) : (
          <>
            <DialogTitle>{`Código ingresado:`}</DialogTitle>
            <DialogContent>
              <DialogContentText>{codigo}</DialogContentText>
            </DialogContent>
            <Divider variant="middle" />
            <DialogTitle>
              <Typography variant="h6">
                Estado: <span style={{ color: "red" }}>Inválido</span>
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`El código ingresado es incorrecto o ya ha sido utilizado.`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onClose}
                color="primary"
                variant="contained"
                autoFocus
              >
                Aceptar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

DialogCanje.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string
};

const enhance = compose(withApollo);

export default enhance(DialogCanje);
