import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import base from "js/components/Shared/Tabla/ActionCells/Base";
import AuthService from "js/utils/AuthService";

export default params => ({
  ...base(),
  tooltip: "Editar",
  label: "Editar",
  icon: <EditIcon />,
  show: () => AuthService.hasPerms(params.perms),
  onclick: () => {
    const id = params.codigoItem ? params.codigoItem : params.itemId;
    params.history.push(window.location.pathname + "/" + id);
  }
});
