import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { Usuarios, Segmento, Template, Programa, Idioma } = Fragmentos;

export const getPaginaProgramas = gql`
  query getProgramas(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: ProgramaColumn!
    $nombre: String
    $nombreFantasia: String
    $razonSocial: String
    $tipo: String
    $cuit: String
    $estado: Estado
    $fechaInicio: Date
    $fechaBaja: Date
  ) {
    getProgramas: getPaginaProgramas(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      programaOrder: { column: $orderBy, order: $order }
      programaLike: {
        nombre: $nombre
        nombreFantasia: $nombreFantasia
        razonSocial: $razonSocial
        tipo: $tipo
        cuit: $cuit
        estado: $estado
      }
      programaRange: {
        fechaInicio: { min: $fechaInicio }
        fechaBaja: { min: $fechaBaja }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        idPrograma
        nombre
        tipo
        nombreFantasia
        cuit
        fechaInicio
        fechaLanzamiento
        fechaBaja
        paises {
          descripcion
        }
        estado
        url
      }
    }
  }
`;

export const getPaginaMiPrograma = gql`
  query getPrograma($url: String!) {
    getPrograma(url: $url) {
      idPrograma
      nombre
      tipo
      nombreFantasia
      cuit
      fechaInicio
      fechaLanzamiento
      fechaBaja
      paises {
        descripcion
      }
      estado
      url
    }
  }
`;

export const getProgramaPublicInfo = gql`
  query getProgramaPublicInfo($url: String!) {
    getProgramaPublicInfo(url: $url) {
      definicionPerfil {
        idPrograma
      }
      idPrograma
      segmentoDefault {
        ...segmentoCompleto
      }
      template {
        ...templateCompleto
      }
      esPublico
      catalogoPublico
      beneficioPublico
      conFormulario
      conUsername
      codigoChatOnline
      chatSinLogin
      htmlExpectativa
      nombre
      tieneMFA
      tieneAutenticacionTerceros
      idiomas {
        ...idioma
      }
      modulos {
        nombreGrupo
        estado
      }
      settings {
        paises {
          idPais
          descripcion
        }
      }
    }
    isLoggedIn
  }
  ${Idioma.fragments.idioma}
  ${Segmento.fragments.segmentoCompleto}
  ${Template.fragments.templateCompleto}
`;

export const getPrograma = gql`
  query getPrograma($url: String!) {
    getPrograma(url: $url) {
      segmentos {
        ...segmentoCompleto
      }
      idPrograma
      fechaInicio
      fechaLanzamiento
      esPublico
      catalogoPublico
      beneficioPublico
      conFormulario
      conUsername
      nombre
      tieneMFA
      periodoValidacionMFA
      unidadPeriodoValidacionMFA
      usuarios {
        ...usuariosCompleto
      }
      template {
        ...templateCompleto
      }
      idiomas {
        ...idioma
      }
      segmentoDefault {
        ...segmentoCompleto
      }
      modulos {
        nombreGrupo
        estado
      }
      indicadores {
        kpi
        estado
      }
      paises {
        idPais
        descripcion
      }
      codigoChatOnline
      chatSinLogin
      linksToTrack
    }
    miUsuario {
      idioma {
        _id
        nombre
        traducciones {
          key
          value
        }
        __typename
      }
      segmento {
        idSegmento
        nombre
        template {
          ...templateCompleto
        }
        idiomaDefault {
          _id
          nombre
          traducciones {
            key
            value
          }
          __typename
        }
      }
    }
    isLoggedIn
  }
  ${Segmento.fragments.segmentoCompleto}
  ${Usuarios.fragments.usuariosCompleto}
  ${Template.fragments.templateCompleto}
  ${Idioma.fragments.idioma}
`;

export const getProgramaById = gql`
  query getProgramas($idPrograma: Int) {
    getProgramas(programaLike: { idPrograma: $idPrograma }) {
      itemsPagina {
        idPrograma
        nombre
        tipo
        url
        cuit
        razonSocial
        nombreFantasia
        monedaBase
        esPublico
        tieneMFA
        periodoValidacionMFA
        unidadPeriodoValidacionMFA
        tieneAutenticacionTerceros
        catalogoPublico
        beneficioPublico
        conFormulario
        conUsername
        tasaConversion
        estado
        telefonoContacto
        condiciones
        privacidad
        preguntas
        emailContacto
        emailRemitente
        fechaInicio
        fechaLanzamiento
        fechaBaja
        fechaUltimaModificacion
        htmlExpectativa
        usuarioCreador {
          username
        }
        usuarioModificador {
          username
        }
        diasParaVencimientoPuntos
        diasParaNotificacionVencimientoPuntos
        maximoMovimientoPuntos
        maximoMovimientoPlata
        comentarios
        paises {
          idPais
          descripcion
        }
        cuentaCorriente {
          balance
        }
        modulos {
          nombreGrupo
          estado
        }
        indicadores {
          kpi
          estado
        }
        urlContenidos
        codigoChatOnline
        chatSinLogin
        conceptoCanje
        avisoCumpleJefe
        avisoCumpleCompaneros
        identificarCompanerosPor
        cronExportacionUsuarios
        emailsExportacionUsuarios
        esNotificacionStockBajo
        emailsNotificacionStockBajo
      }
    }
  }
`;

export const getMiProgramaCompleto = gql`
  query getPrograma($url: String!) {
    getPrograma(url: $url) {
      idPrograma
      nombre
      tipo
      url
      cuit
      razonSocial
      nombreFantasia
      monedaBase
      esPublico
      catalogoPublico
      beneficioPublico
      conFormulario
      conUsername
      tasaConversion
      estado
      telefonoContacto
      condiciones
      privacidad
      preguntas
      emailContacto
      emailRemitente
      fechaInicio
      fechaLanzamiento
      fechaBaja
      fechaUltimaModificacion
      usuarioCreador {
        username
      }
      usuarioModificador {
        username
      }
      diasParaVencimientoPuntos
      diasParaNotificacionVencimientoPuntos
      maximoMovimientoPuntos
      maximoMovimientoPlata
      comentarios
      paises {
        idPais
        descripcion
      }
      cuentaCorriente {
        balance
      }
    }
  }
`;

export const getProgramaLegales = gql`
  query getProgramaPublicInfo($url: String!) {
    getProgramaPublicInfo(url: $url) {
      condiciones
      privacidad
      preguntas
    }
  }
`;

export const getProgramas = gql`
  query getProgramas {
    getProgramasToFilter(programaOrder: { column: nombre, order: desc }) {
      itemsPagina {
        idPrograma
        nombre
      }
    }
  }
`;

export const getProgramasFilter = gql`
  query getProgramas {
    getProgramasToFilter(programaOrder: { column: nombre, order: desc }) {
      itemsPagina {
        idPrograma
        nombre
        tipo
        nombreFantasia
        cuit
      }
    }
  }
`;

export const loginAs = gql`
  mutation loginAs($token: ID!) {
    loginAs(token: $token) {
      usuario {
        idUsuario
      }
      privilegios {
        idPrivilegio
        codigoPrivilegio
        descripcion
      }
    }
  }
`;

export const isLoggedIn = gql`
  query isLoggedIn {
    isLoggedIn
  }
`;

export const createPrograma = gql`
  mutation createPrograma($programa: ProgramaCreate!) {
    createPrograma(programa: $programa) {
      idPrograma
    }
  }
`;

export const updatePrograma = gql`
  mutation UpdatePrograma($programa: ProgramaUpdate!) {
    updatePrograma(programa: $programa) {
      idPrograma
    }
  }
`;

export const updateProgramaLegales = gql`
  mutation UpdateProgramaLegales($programa: ProgramaLegalesUpdate!) {
    updateProgramaLegales(programa: $programa) {
      idPrograma
    }
  }
`;

export const misDefinicionesPerfil = gql`
  query misDefinicionesPerfil {
    misDefinicionesPerfil {
      ...definicionesPerfil
    }
  }
  ${Programa.fragments.definicionesPerfil}
`;

export const getDefinicionesPerfil = gql`
  query getDefinicionesPerfil($definicionPerfilLike: DefinicionPerfilSearch) {
    getDefinicionesPerfil(definicionPerfilLike: $definicionPerfilLike) {
      ...definicionesPerfil
    }
  }
  ${Programa.fragments.definicionesPerfil}
`;

export const getDefinicionesPerfilPublico = gql`
  query getDefinicionesPerfilPublico(
    $idPrograma: Int!
    $definicionPerfilLike: DefinicionPerfilSearch
  ) {
    getDefinicionesPerfilPublico(
      idPrograma: $idPrograma
      definicionPerfilLike: $definicionPerfilLike
    ) {
      ...definicionesPerfil
    }
  }
  ${Programa.fragments.definicionesPerfil}
`;

export const getPaginaProgramasCC = gql`
  query getProgramas(
    $pagina: Pagina
    $idPrograma: Int
    $withProgram: Boolean = false
  ) {
    getPrograma: getProgramas(
      pagina: $pagina
      programaLike: { idPrograma: $idPrograma, estado: ACTIVO }
    ) {
      ...programa
    }
    getProgramas: getProgramas {
      ...programas
    }
    getProveedores {
      itemsPagina {
        ...proveedoresCC
      }
    }
    getProductos {
      itemsPagina {
        idProducto
        nombre
      }
    }
    getEleccionProductos(eleccionProductoLike: { idPrograma: $idPrograma })
      @include(if: $withProgram) {
      itemsPagina {
        ...eleccionProductosCC
      }
    }
    getLotes {
      itemsPagina {
        numeroLote
      }
    }
    getFacturas {
      itemsPagina {
        idFactura
        numeroFactura
      }
    }
    getMasterPaises {
      idPais
      descripcion
    }
  }
  ${Programa.fragments.programaAdminCC}
  ${Programa.fragments.programasAdminCC}
  ${Programa.fragments.proveedoresCC}
  ${Programa.fragments.eleccionProductosCC}
`;

export const getBalanceByIdPrograma = gql`
  query getProgramas($idPrograma: Int) {
    getProgramas(programaLike: { idPrograma: $idPrograma }) {
      itemsPagina {
        idPrograma
        nombre
        maximoMovimientoPuntos
        cuentaCorriente {
          balance
        }
      }
    }
  }
`;

export const getBalanceMiPrograma = gql`
  query getPrograma($url: String!) {
    getPrograma(url: $url) {
      idPrograma
      nombre
      maximoMovimientoPuntos
      cuentaCorriente {
        balance
      }
    }
  }
`;

export const getPaginaUsuariosCC = gql`
  query getProgramas(
    $pagina: Pagina
    $idPrograma: Int
    $withProgram: Boolean = false
  ) {
    getPrograma: getProgramas(
      pagina: $pagina
      programaLike: { idPrograma: $idPrograma, estado: ACTIVO }
    ) {
      ...programa
    }
    getProgramas: getProgramas {
      ...programas
    }
  }
  ${Programa.fragments.programaAdminCC}
  ${Programa.fragments.programasAdminCC}
`;

export const getPaginaCanjes = gql`
  query getProgramas($idsProgramas: [Int], $withProgram: Boolean = false) {
    getProveedoresToFilter {
      itemsPagina {
        ...proveedoresCC
      }
    }
    # getProductos {
    #   itemsPagina {
    #     idProducto
    #     nombre
    #   }
    # }
    getEleccionProductos(eleccionProductoLike: { idsProgramas: $idsProgramas })
      @include(if: $withProgram) {
      itemsPagina {
        ...eleccionProductosCC
      }
    }
    getLotes {
      itemsPagina {
        numeroLote
      }
    }
    getFacturas {
      itemsPagina {
        idFactura
        numeroFactura
      }
    }
    getMasterPaises {
      idPais
      descripcion
    }
  }
  ${Programa.fragments.proveedoresCC}
  ${Programa.fragments.eleccionProductosCC}
`;

export const getPaginaCanjesFront = gql`
  query getProgramas($idPrograma: Int) {
    getEleccionProductos(eleccionProductoLike: { idPrograma: $idPrograma }) {
      itemsPagina {
        idEleccionProducto
        producto {
          nombre
          idProducto
        }
      }
    }
  }
`;

export default {
  queries: {
    isLoggedIn,
    getProgramas,
    getPaginaProgramas,
    getPaginaMiPrograma,
    getProgramaPublicInfo,
    getPrograma,
    getProgramaById,
    getMiProgramaCompleto,
    getProgramasFilter,
    getProgramaLegales,
    createPrograma,
    updatePrograma,
    updateProgramaLegales,
    misDefinicionesPerfil,
    getDefinicionesPerfil,
    getDefinicionesPerfilPublico,
    getPaginaProgramasCC,
    getPaginaUsuariosCC,
    getPaginaCanjes,
    getPaginaCanjesFront,
    getBalanceMiPrograma,
    getBalanceByIdPrograma
  },
  mutations: {
    loginAs
  }
};
