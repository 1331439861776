import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  link: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.tertiary.main
    }
  },
  disabled: {
    color: theme.palette.tertiary.main,
    textDecoration: "underline"
  },
  IdiomaList: {}
});

export default withStyles(styles);
