import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import cx from "classnames";
import { css } from "emotion";
import get from "lodash/get";
import AuthService from "js/utils/AuthService";
import UserMenu from "../UserMenu";
import { numberWithCommas } from "js/utils";
import UserQraphQL from "js/graphql/resolvers/usuario.resolver";
import placeholder from "../../../../images/person_200x200.png";
import IdiomasList from "js/components/Shared/IdiomasList";
import { _t } from "js/utils/TranslationService";
import { getUrl } from "js/utils";
import { USER_ROLES as Roles } from "js/models/User";

class UserInfo extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  componentDidMount = () => {
    this.fetchData();
  };

  componentWillReceiveProps = nextProps => {
    const { updatePoints } = nextProps;
    if (updatePoints) {
      this.fetchData();
    }
  };

  fetchData = async () => {
    const { client, updateUserPoints } = this.props;
    client
      .watchQuery({
        query: UserQraphQL.queries.getMiUsuario,
        errorPolicy: "all",
        fetchPolicy: "network-only"
      })
      .subscribe({
        next: ({ data, loading }) => {
          this.setState({
            nombre: get(data, "miUsuario.perfil.nombre"),
            username: get(data, "miUsuario.username"),
            avatarUrl: get(data, "miUsuario.avatarUrl", ""),
            idUsuario: get(data, "miUsuario.idUsuario", ""),
            balance: get(data, "miUsuario.cuentaCorriente.balance", 0)
          });
          const roles = get(data, "miUsuario.roles");
          if (roles) {
            AuthService.setUserRoles(roles.map(rol => rol.nombre));
          }
          AuthService.setIdSegmento(get(data, "miUsuario.segmento.idSegmento"));
          AuthService.setNombreSegmento(get(data, "miUsuario.segmento.nombre"));
          updateUserPoints(false);
        }
      });
  };

  render() {
    const {
      classes,
      width,
      location,
      theme,
      programa,
      burger = false
    } = this.props;
    const { anchorEl, nombre, username, avatarUrl, balance } = this.state;

    const isLoggedIn = !!AuthService.getIdUsuario();
    const open = Boolean(anchorEl);

    const usrClass = css(get(programa, "template.header.user.css", {}));
    const navbarClass = css(
      get(programa, "template.header.menu.navbar.css", {})
    );

    const puntosColorClass = css(
      get(programa, "template.header.user.puntos.css", {})
    );

    const moduloPuntos = programa.modulos
      ? programa.modulos.find(
          modulo =>
            modulo.nombreGrupo === "Gestión Puntos" &&
            modulo.estado === "ACTIVO"
        )
      : true;

    const HasPerms = AuthService.hasPerms([Roles.MIS_DATOS_MOVIMIENTOS_R]);

    return isLoggedIn ? (
      <div className={classes.userInfo}>
        <Typography
          variant="button"
          className={classes.pointsWrapper}
          classes={{
            button: css({
              color: theme.palette.getContrastText(
                programa.template.header.css.backgroundColor
              )
            })
          }}
        >
          {width !== "xs" &&
            !burger && (
              <React.Fragment>
                <IdiomasList classes={{ IdiomaList: usrClass }} showBracket />
                {moduloPuntos && HasPerms ? (
                  <span className={cx(classes.puntos, usrClass)}>
                    <span>
                      <Link
                        to="/mis-movimientos"
                        underline={"none"}
                        className={cx({
                          [classes.misMovimientos]: true,
                          [usrClass]: true,
                          [classes.puntosValue]: true,
                          [puntosColorClass]: true
                        })}
                      >
                        {numberWithCommas(balance)}
                        &nbsp;
                        {_t("Puntos")}
                      </Link>
                    </span>
                  </span>
                ) : (
                  ""
                )}
              </React.Fragment>
            )}
          <span
            className={cx(classes.nombre, {
              [usrClass]: width !== "xs" && !burger,
              [navbarClass]: !(width !== "xs" && !burger)
            })}
          >
            {nombre || username}
          </span>
        </Typography>
        <Avatar
          alt="avatar"
          src={avatarUrl ? getUrl(avatarUrl) : placeholder}
          className={classes.avatar}
        />
        {!burger &&
          width !== "xs" && (
            <Fragment>
              <IconButton
                aria-owns={open ? "menu-appbar" : null}
                aria-haspopup="true"
                onClick={this.handleMenu}
                className={usrClass}
              >
                <KeyboardArrowDown />
              </IconButton>
              <UserMenu
                anchorEl={anchorEl}
                open={open}
                close={this.handleClose}
              />
            </Fragment>
          )}
      </div>
    ) : (
      <div className={classes.menuButton}>
        {width !== "xs" && (
          <IdiomasList showBracket classes={{ IdiomaList: usrClass }} />
        )}
        <Link
          to={{
            pathname: "/signin",
            state: { currentLocation: location.pathname }
          }}
          className={classes.loginHref}
        >
          <Button className={classes.login} color="inherit">
            {_t("Iniciar sesión")}
          </Button>
        </Link>
      </div>
    );
  }
}

UserInfo.propTypes = {};

export default UserInfo;
