import { connect } from "react-redux";
import { getTemplate } from "js/redux/programa/selectors.js";
import LayoutUI from "./LayoutUI";
import AllowToNavigate from "js/hoc/AllowToNavigate";
import { REQUIREMENTS as Permisos } from "js/models/Permiso";

const Layout = AllowToNavigate(LayoutUI, [
  Permisos.HTML_EXPECTATIVA,
  Permisos.PUBLICO_O_LOGUEADO,
  Permisos.UPDATE_PASSWORD,
  Permisos.COMPLETAR_PERFIL,
  Permisos.TRIVIA,
  Permisos.FORMULARIO
]);
const mapStateToProps = state => ({
  theme: getTemplate(state)
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
