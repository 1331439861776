import React, { Fragment, Component } from "react";
import TextField from "@material-ui/core/TextField";
import client from "js/App/client.graphql.js";
import { get } from "lodash";
import Grid from "@material-ui/core/Grid";
import IdiomaQraphQL from "js/graphql/resolvers/idioma.resolver";
import Button from "@material-ui/core/Button";
import { TypeAhead } from "js/components/Shared/Form/Fields";

class AdminIdiomaNew extends Component {
  state = {
    nombre: "",
    traducciones: [],
    idPadre: "",
    idIdioma: this.props.match.params.idIdioma
  };

  async componentDidMount() {
    const {
      match: {
        params: { idIdioma }
      }
    } = this.props;
    if (idIdioma) {
      await client
        .query({
          query: IdiomaQraphQL.queries.getIdiomaConPadre,
          fetchPolicy: "network-only",
          variables: {
            idIdioma
          }
        })
        .then(res => {
          this.setState({
            nombre: get(res, "data.getIdiomaConPadre.idioma.nombre", null),
            idPadre: get(res, "data.getIdiomaConPadre.idiomaPadre._id", null)
          });
        });
    }
  }

  isDissabled = () => this.state.nombre !== "";

  sendValues = async () => {
    const { nombre, idPadre, traducciones, idIdioma } = this.state;
    let mutation = idIdioma
      ? IdiomaQraphQL.mutations.updateIdioma
      : IdiomaQraphQL.mutations.createIdiomaPadre;
    let variables = { nombre };

    if (idPadre) {
      mutation = idIdioma
        ? IdiomaQraphQL.mutations.updateIdioma
        : IdiomaQraphQL.mutations.createIdioma;
      variables = {
        ...variables,
        idPadre,
        traducciones,
        idIdioma
      };
    }
    if (idIdioma) variables = { ...variables, idPadre, _id: idIdioma };
    await client
      .mutate({
        mutation,
        variables
      })
      .then(res => {
        this.props.openNotification(
          `Idioma "${nombre}" guardado correctamente`
        );
        this.props.history.push("/admin/idiomas");
      })
      .catch(error => {
        this.props.openNotification(error.graphQLErrors[0].message);
      });
  };

  handleChange = key => event => {
    this.setState({ [key]: event.target.value });
  };
  render() {
    const { classes, history } = this.props;
    return (
      <Fragment>
        <Grid
          container
          spacing={16}
          className={classes.facturaForm}
          direction="column"
        >
          <Grid item xs={12} md={6} className={classes.grid}>
            <TextField
              id="standard-name"
              label="Nombre"
              className={classes.textField}
              value={this.state.nombre}
              onChange={this.handleChange("nombre")}
              margin="normal"
            />
          </Grid>

          <Grid item xs={12} md={6} className={classes.grid}>
            <TypeAhead
              label={"Idioma padre"}
              onChange={select =>
                this.handleChange("idPadre")({
                  target: { name: "idPadre", value: select.value }
                })
              }
              path={{
                path: "getIdiomas.itemsPagina",
                value: "_id",
                label: "nombre"
              }}
              placeholder={"Idioma padre"}
              query={IdiomaQraphQL.queries.getIdiomas}
              value={this.state.idPadre}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Button
              disabled={!this.isDissabled()}
              onClick={this.sendValues}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Guardar
            </Button>
            <Button
              type="button"
              className={classes.button}
              onClick={() => history.push("/admin/idiomas")}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

AdminIdiomaNew.defaultProps = {};

AdminIdiomaNew.propTypes = {};

export default AdminIdiomaNew;
