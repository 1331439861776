import React from "react";
import { Route, matchPath } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";

import CommonLayout from "./CommonLayout";
import BurgerLayout from "./BurgerLayout";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Hidden from "@material-ui/core/Hidden";

const matches = (pathname, path) =>
  !!matchPath(pathname, {
    path,
    exact: true,
    strict: false
  });

const LayoutUI = props => {
  const {
    programa,
    classes,
    width,
    location: { pathname },
    ...other
  } = props;
  const menuType = programa.template.header.menu.tipo;

  const match =
    matches(pathname, "/landing") ||
    matches(pathname, "/catalogo") ||
    matches(pathname, "/producto/:idProducto") ||
    matches(pathname, "/canje/:idCanje") ||
    matches(pathname, "/formularioreconocimiento") ||
    matches(pathname, "/formularioreconocimiento/:idReconocimiento") ||
    matches(pathname, "/informacion") ||
    matches(pathname, "/reportesCLN") ||
    matches(pathname, "/reportes") ||
    matches(pathname, "/reconocido") ||
    matches(pathname, "/reconocido/:idReconocimiento") ||
    matches(pathname, "/reconocido/:idReconocimiento/aprobacion") ||
    matches(pathname, "/reconocido/:idReconocimiento/rechazo") ||
    matches(pathname, "/reconocido/:idReconocimiento/aprobado") ||
    matches(pathname, "/reconocido/:idReconocimiento/rechazado") ||
    matches(pathname, "/reconocido/:idReconocimiento/edicion") ||
    matches(pathname, "/reconocido/:idReconocimiento/editado") ||
    matches(pathname, "/reconocimientos") ||
    matches(pathname, "/mi-performance") ||
    matches(pathname, "/meds-performances") ||
    matches(pathname, "/catalogo-beneficios") ||
    matches(pathname, "/beneficio/:idBeneficio") ||
    matches(pathname, "/solicitud-beneficio/:idSolicitud") ||
    matches(pathname, "/beneficio-catalogo-codigos/:idBeneficio") ||
    matches(pathname, "/solicitud-catalogo-codigos/:idSolicitud") ||
    matches(pathname, "/mi-performance-campanas") ||
    matches(pathname, "/performance-campanas");
  const matchLanding =
    matches(pathname, "/landing") ||
    matches(pathname, "/reportes") ||
    matches(pathname, "/reportesCLN") ||
    matches(pathname, "/informacion") ||
    matches(pathname, "/formularioreconocimiento") ||
    matches(pathname, "/formularioreconocimiento/:idReconocimiento") ||
    matches(pathname, "/reconocido") ||
    matches(pathname, "/reconocido/:idReconocimiento") ||
    matches(pathname, "/reconocido/:idReconocimiento/aprobacion") ||
    matches(pathname, "/reconocido/:idReconocimiento/rechazo") ||
    matches(pathname, "/reconocido/:idReconocimiento/aprobado") ||
    matches(pathname, "/reconocido/:idReconocimiento/rechazado") ||
    matches(pathname, "/reconocido/:idReconocimiento/edicion") ||
    matches(pathname, "/reconocido/:idReconocimiento/editado") ||
    matches(pathname, "/reconocimientos");

  return (
    <Route
      render={matchProps => (
        <div className={classes.body}>
          <div className={classes.page}>
            <ErrorBoundary>
              {menuType === "burger" ? (
                <BurgerLayout {...other} />
              ) : (
                <React.Fragment>
                  <Hidden smUp>
                    <BurgerLayout container={!match} {...other} />
                  </Hidden>
                  <Hidden xsDown>
                    <CommonLayout
                      container={!match}
                      {...other}
                      matchLanding={matchLanding}
                    />
                  </Hidden>
                </React.Fragment>
              )}
            </ErrorBoundary>
          </div>
        </div>
      )}
    />
  );
};

const LayoutUIWithStyles = withStyles(styles)(withRouter(LayoutUI));

export default props => (
  <MuiThemeProvider theme={props.theme}>
    <LayoutUIWithStyles {...props} />
  </MuiThemeProvider>
);
