export const CAMBIAR_CANTIDAD = "CAMBIAR_CANTIDAD";
export const DECREMENTAR_CANTIDAD = "DECREMENTAR_CANTIDAD";
export const INCREMENTAR_CANTIDAD = "INCREMENTAR_CANTIDAD";
export const CANJEAR = "CANJEAR";
export const CANJEAR_FAIL = "CANJEAR_FAIL";
export const CANJEAR_SUCCESS = "CANJEAR_SUCCESS";
export const CHANGE_REQUERIDO = "CHANGE_REQUERIDO";
export const CHANGE_REQUERIDO_STRING = "CHANGE_REQUERIDO_STRING";
export const CHANGE_SUCURSAL = "CHANGE_SUCURSAL";
export const CLEAN_PRODUCTO_STATE = "CLEAN_PRODUCTO_STATE";
export const CLOSE_DIALOG_CANJE = "CLOSE_DIALOG_CANJE";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const LOAD_PRODUCTO_STATE = "LOAD_PRODUCTO_STATE";
export const OPEN_DIALOG_CANJE = "OPEN_DIALOG_CANJE";
export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const REQUIRE_DATA_USER = "REQUIRE_DATA_USER";
export const SET_REQUERIDOS = "SET_REQUERIDOS";
export const TOGGLE_FAVORITO = "TOGGLE_FAVORITO";
export const TOGGLE_FAVORITO_ERROR = "TOGGLE_FAVORITO_ERROR";
export const TOGGLE_FAVORITO_SUCCESS = "TOGGLE_FAVORITO_SUCCESS";
export const UPDATE_STORE_DATOS_PERFIL = "UPDATE_STORE_DATOS_PERFIL";
