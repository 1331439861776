import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

const Profile = ({ classes }) => {
  return (
    <span className={classes.splash}>
      <CircularProgress thickness={7} color="primary" variant="indeterminate" />
    </span>
  );
};

Profile.propTypes = {
  classes: PropTypes.object
};

Profile.defaultProps = {};

Profile.displayName = "Profile";

export default Profile;
