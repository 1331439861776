import React, { Component } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Chip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
  Tooltip,
  Grid
} from "@material-ui/core";
import cx from "classnames";
import { Delete } from "@material-ui/icons";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withNotificationContext } from "js/notification-context";
import { Summary } from "./dialogSummary";
import importsGraphql from "../../../../graphql/resolvers/importar.resolver";
import { styles } from "./styles.js";
import "./styles.css";

const fileTypes = ["PDF", "JPG", "JPEG", "PNG", "CSV"];

class DialogUpload extends Component {
  constructor(props) {
    super(props);
    const { open } = this.props;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.state = {
      files: [],
      isOpen: open,
      idPrograma: parseInt(id, 10),
      showSummary: false, // Estado para controlar la visualización del resumen
      summaryData: null, // Datos para el resumen
      inProcess: false
    };
  }

  handleChange = fileList => {
    this.setState(prevState => ({
      files: [...prevState.files, ...Array.from(fileList)]
    }));
  };

  handleRemoveFile = index => {
    this.setState(prevState => ({
      files: prevState.files.filter((_, i) => i !== index)
    }));
  };

  toggleDialog = () => {
    const { handleClose } = this.props;
    handleClose();
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  submit = async prizeOperation => {
    const { files } = this.state;
    let filesStashed = [...files];
    const { client, openNotification } = this.props;
    try {
      this.setState({ inProcess: true });
      const response = await client.mutate({
        mutation: importsGraphql.queries.importCanjesPrizes,
        variables: { files, prizeOperation }
      });
      if (!response) {
        throw new Error("Error al subir archivos");
      }
      const {
        data: { importCanjesPrizes }
      } = response;
      const parsedResponse = JSON.parse(importCanjesPrizes);

      // Mostrar el resumen
      this.setState({
        inProcess: false,
        showSummary: true,
        summaryData: parsedResponse
      });
    } catch (error) {
      this.setState({ inProcess: false });
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        error.graphQLErrors.forEach(err => {
          openNotification(err.message);
        });
      }
      this.setState({ files: filesStashed });
    }
  };

  submitPrepare = () => {
    this.submit("PREPARE");
  };

  submitSend = () => {
    this.submit("SEND");
  };

  closeSummary = () => {
    this.setState({ showSummary: false, summaryData: null });
    this.toggleDialog();
  };

  render() {
    const { classes } = this.props;
    const { files, isOpen, showSummary, summaryData, inProcess } = this.state;

    if (showSummary && summaryData) {
      return <Summary data={summaryData} onClose={this.closeSummary} />;
    }

    return (
      <Dialog
        open={isOpen}
        onClose={this.toggleDialog}
        maxWidth={inProcess ? "xs" : "sm"}
        fullWidth={true}
      >
        {inProcess ? (
          <>
            <DialogContent style={{ textAlign: "center" }}>
              <CircularProgress size={48} />
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle>Subir Archivos</DialogTitle>
            <DialogContent>
              <FileUploader
                multiple
                label={"Arrastrá o hacé clic para subir archivos"}
                uploadedLabel={
                  files.length > 1
                    ? "Archivos cargados. Podés agregar más."
                    : "Archivo cargado. Podés agregar otro."
                }
                handleChange={this.handleChange}
                name="file"
                types={fileTypes}
                maxSize={3}
              />
              {Array.isArray(files) &&
                files.length > 10 && (
                  <Chip
                    style={{
                      width: "100%",
                      backgroundColor: "rgb(225, 0, 80)"
                    }}
                    label={
                      <Typography
                        style={{ color: "white" }}
                        variant="subtitle2"
                      >
                        {"Máximo 10 archivos. Revisa tu selección."}
                      </Typography>
                    }
                    color="error"
                  />
                )}
              {Array.isArray(files) &&
                files.length > 0 && (
                  <Table className="mt-4 border rounded">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre del Archivo</TableCell>
                        <TableCell>Acción</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {files.map((file, index) => (
                        <TableRow key={index}>
                          <TableCell>{file ? file.name : ""}</TableCell>
                          <TableCell>
                            <Button
                              onClick={() => this.handleRemoveFile(index)}
                              color="secondary"
                              size="small"
                            >
                              <Delete />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
            </DialogContent>
            <DialogActions>
              <Grid container className={classes.container}>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  className={classes.containerCancelButton}
                >
                  <Button
                    onClick={this.toggleDialog}
                    variant="outlined"
                    color="primary"
                    className={classes.cancelButton}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  className={classes.containerActionButtons}
                >
                  <Button
                    onClick={this.submitPrepare}
                    className={classes.actionButton}
                    color="primary"
                    variant="contained"
                    disabled={!files.length || files.length > 10}
                  >
                    Preparar
                  </Button>
                  <Button
                    onClick={this.submitSend}
                    className={classes.actionButton}
                    color="primary"
                    variant="contained"
                    disabled={!files.length || files.length > 10}
                  >
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  withNotificationContext,
  withStyles(styles)
)(DialogUpload);
