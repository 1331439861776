export default theme => ({
  root: {
    padding: "3rem"
  },
  card: {
    maxWidth: 345
  },
  media: {
    height: 140,
    backgroundSize: "contain"
  },
  link: {
    textDecoration: "none"
  }
});
