import React, { PureComponent } from "react";
// import Tooltip from "@material-ui/core/Tooltip";
// import IconButton from "@material-ui/core/IconButton";

import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/sesionesTracking.resolver";
import advancedFilter from "js/pages/SesionesTracking/TablaSesionesTracking/advancedFilter.js";
import { USER_ROLES as Roles } from "js/models/User";

const defaultHeader = (id, inBackOffice) => {
  const header = {
    title: "Tracking de sesiones",
    export: {
      path: "sessionTracking",
      perms: [Roles.SESSION_TRACKING_R],
      tooltip: "Exportar tracking de sesiones"
    }
  };

  return header;
};

const columnData = [
  { id: "idPrograma", label: "Programa" },
  { id: "idUsuario", label: "Usuario", removeSort: true },
  { id: "fecha", label: "Fecha", removeSort: true },
  { id: "sesiones", label: "Sesiones", removeSort: true }
];

const defaultSort = { order: "asc", orderBy: "fecha" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;

    return (
      <Table
        storage={"FiltroSesionsTracking"}
        query={AppQraphQL.queries.getSesionesTracking}
        defaultHeader={defaultHeader(id, inBackOffice)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => {
          return {
            itemsPagina: data.getSesionesTracking
          };
        }}
        showPagination={false}
        pathId={"sesion"}
      />
    );
  }
}

export default Tabla;
