import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  FormControl: {
    "& .chrome-picker": {
      width: "100% !important"
    }
  },
  InputLabel: {
    marginLeft: 10
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    flexBasis: 200,
    width: "100%"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  Input: {
    "& input": {
      textTransform: "uppercase"
    }
  },
  redes: {
    width: "100%"
  },
  margin: {
    margin: theme.spacing.unit
  }
});

export default withStyles(styles);
