export default {
  landing: {
    lucho: ["casanova"],
    filas: [
      {
        visible: true,
        filaTipo: 1,
        fullwidth: false,
        spaceBetweenFila: "10",
        spaceBetweenLateral: "10",
        paddingLateral: "10",
        css: {
          height: "45",
          border: {
            width: "1",
            color: "black"
          }
        },
        contenidos: [
          {
            css: {
              borderRadius: "25"
            },
            src: "https://picsum.photos/400/400?image=811",
            link: "/producto/1",
            subtexto: "y este es un subtexto",
            texto: {
              contenido: "Este es un texto",
              css: {
                color: "",
                fontFamily: "",
                fontStyle: "",
                fontWeight: "",
                textAlign: "",
                verticalAlign: ""
              }
            },
            tipo: "base"
          }
        ]
      }
    ]
  },
  access: {
    restorePassword: {
      descripcion: "",
      titulo: "Este es el titulo del Recuperar Contraseña"
    },
    restoreUser: {
      descripcion: "",
      titulo: "Este es el titulo del Recuperar el usuario"
    },
    signin: {
      descripcion: "",
      titulo: "Este es el titulo del inicio de sesion"
    },
    signup: {
      descripcion: "",
      titulo: "Este es el titulo del registro de usuario"
    }
  },
  footer: {
    redes: [
      {
        link: "",
        red: "",
        value: "",
        label: ""
      }
    ]
  },
  templateData: {
    cucardas: [
      {
        color: "#F4F4F4",
        background: "#DC2F00",
        type: "TIPO_1",
        label: "Nuevo",
        fontFamily: "Acme",
        typography: "Acme"
      }
    ],
    theme: {
      palette: {
        primary: { main: "" },
        secondary: { main: "" },
        tertiary: { main: "" },
        quaternary: { main: "" },
        quinary: { main: "" }
      },
      backgrounds: {
        ui: {
          backgroundColor: "",
          backgroundImage: ""
        },
        container: {
          backgroundColor: "",
          backgroundImage: ""
        }
      }
    }
  },
  header: {
    menu: {
      tipo: "inside",
      css: {
        inside: {
          fontSize: "",
          padding: "",
          align: "",
          backgroundColor: "#fff",
          borderTop: "",
          fontFamily: ""
        },
        below: {
          height: "",
          fontSize: "",
          padding: "",
          align: "",
          backgroundColor: "#fff",
          borderTop: "",
          fontFamily: ""
        },
        burger: {
          fontSize: "",
          padding: "",
          align: "",
          backgroundColor: "#fff",
          borderTop: "",
          fontFamily: ""
        }
      }
    },
    css: { height: "" },
    user: {
      puntos: {
        css: {
          color: ""
        }
      },
      css: {
        fontSize: "",
        diameter: "",
        fontFamily: ""
      }
    },
    logoPrincipal: { url: "" }
  },
  destacados: {
    contenidos: [
      {
        bases: [
          {
            src: "https://picsum.photos/1200/400/?image=0",
            link: "",
            subTexto: "solo subtexto",
            texto: {
              contenido: "",
              css: {
                color: "",
                fontFamily: "",
                fontStyle: "",
                fontWeight: "",
                textAlign: "",
                verticalAlign: ""
              }
            },
            hasSpaceBetween: false,
            tipo: "base"
          }
        ],
        spaceBetween: false,
        tipo: "slider",
        sliderOptions: {
          showArrows: true,
          showIndicators: false,
          transitionTime: 350,
          interval: 5000,
          stopOnHover: true,
          autoPlay: true,
          infiniteLoop: true,
          showStatus: false,
          arrowStyle: "commonAlt"
        }
      }
    ],
    css: {
      margin: "",
      padding: ""
    },
    spaceBetween: false,
    id: 1,
    filaTipo: 1,
    visible: true
  }
};

export const reset = {
  access: {
    accessTemplate: 1,
    backgroundImage: "",
    logoURL: "https://image.ibb.co/n6VRxK/cbu.png",
    restorePassword: {
      tabTitle: "Recuperar Contraseña",
      descripcion: "",
      titulo: "Este es el titulo del Recuperar Contraseña"
    },
    restoreUser: {
      tabTitle: "Recuperar usuario",
      descripcion:
        "Bienvenido al segmento de Melisa. Esta es al descripcion detallada para mostrar en el restore usuario con informacion adicional",
      titulo: "Este es el titulo del Recuperar el usuario"
    },
    signin: {
      tabTitle: "Inicio de sesion",
      descripcion:
        "Bienvenido al segmento de Melisa. Esta es al descripcion detallada para mostrar en el signin con informacion adicional",
      titulo: "Este es el titulo del inicio de sesion"
    },
    signup: {
      tabTitle: "Registro",
      descripcion:
        "Bienvenido al segmento de Melisa. Esta es al descripcion detallada para mostrar en el signup con informacion adicional",
      titulo: "Este es el titulo del registro de usuario"
    }
  },
  catalogo: {
    vista: "grid",
    catalogoOrder: {
      column: "producto_nombre",
      order: "asc"
    },
    items: {
      card: {
        css: {
          backgroundColor: "yellow",
          borderRadius: 25,
          boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)"
        }
      }
    }
  },
  catalogoBeneficios: {
    vista: "grid",
    catalogoOrder: {
      column: "nombre",
      order: "asc"
    },
    items: {
      card: {
        css: {
          backgroundColor: "yellow",
          borderRadius: 25,
          boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)"
        }
      }
    }
  },
  destacados: {
    contenidos: [
      {
        bases: [
          {
            src: "https://picsum.photos/1200/400/?image=0",
            link: "/producto/1",
            subTexto: "solo subtexto",
            texto: {
              contenido: "probando el textarea del tempaltebuilder",
              css: {
                color: "",
                fontFamily: "",
                fontStyle: "",
                fontWeight: "",
                textAlign: "",
                verticalAlign: ""
              }
            },
            hasSpaceBetween: false,
            tipo: "base"
          },
          {
            src: "https://picsum.photos/1200/400/?image=80",
            link: "/producto/2",
            subTexto: "solo subtexto",
            texto: {
              contenido: "tiene otro texto",
              css: {
                color: "",
                fontFamily: "",
                fontStyle: "",
                fontWeight: "",
                textAlign: "",
                verticalAlign: ""
              }
            },
            hasSpaceBetween: false,
            tipo: "base"
          },
          {
            src: "https://picsum.photos/1200/400/?image=990",
            link: "/producto/2",
            subTexto: "solo subtexto",
            texto: {
              contenido: "tiene otro texto",
              css: {
                color: "",
                fontFamily: "",
                fontStyle: "",
                fontWeight: "",
                textAlign: "",
                verticalAlign: ""
              }
            },
            hasSpaceBetween: false,
            tipo: "base"
          },
          {
            src: "https://picsum.photos/1200/400/?image=11",
            link: "/producto/2",
            subTexto: "solo subtexto",
            texto: {
              contenido: "tiene otro texto",
              css: {
                color: "",
                fontFamily: "",
                fontStyle: "",
                fontWeight: "",
                textAlign: "",
                verticalAlign: ""
              }
            },
            hasSpaceBetween: false,
            tipo: "base"
          },
          {
            src: "https://picsum.photos/1200/400/?image=11",
            link: "/producto/2",
            subTexto: "solo subtexto",
            texto: {
              contenido: "tiene otro texto",
              css: {
                color: "",
                fontFamily: "",
                fontStyle: "",
                fontWeight: "",
                textAlign: "",
                verticalAlign: ""
              }
            },
            hasSpaceBetween: false,
            tipo: "base"
          },
          {
            src: "https://picsum.photos/1200/400/?image=11",
            link: "/producto/2",
            subTexto: "solo subtexto",
            texto: {
              contenido: "tiene otro texto",
              css: {
                color: "",
                fontFamily: "",
                fontStyle: "",
                fontWeight: "",
                textAlign: "",
                verticalAlign: ""
              }
            },
            hasSpaceBetween: false,
            tipo: "base"
          }
        ],
        spaceBetween: false,
        tipo: "slider",
        sliderOptions: {
          showArrows: true,
          showIndicators: false,
          transitionTime: 350,
          interval: 5000,
          stopOnHover: true,
          autoPlay: true,
          infiniteLoop: true,
          showStatus: false,
          arrowStyle: "commonAlt"
        }
      }
    ],
    css: {
      margin: "",
      padding: ""
    },
    spaceBetween: false,
    id: 1,
    filaTipo: 1,
    visible: true
  },
  footer: {
    email: "ayuda@programa.com",
    columnasFooter: [
      {
        links: [
          {
            link: "/preguntas-frecuentes",
            nombre: "Preguntas Frecuentes"
          },
          {
            link: "/terminos-y-condiciones",
            nombre: "Terminos y condiciones"
          }
        ],
        titulo: "pongamosle un titulo"
      },
      {
        links: [
          {
            link: "/contactanos",
            nombre: "Contactanos"
          },
          {
            link: "/politicas-de-privacidad",
            nombre: "Politicas de Privacidad"
          }
        ],
        titulo: "titulo de prueba"
      },
      {
        links: [
          {
            link: "",
            nombre: "dssss"
          }
        ]
      },
      {
        links: [
          {
            link: "",
            nombre: "ffffff"
          }
        ]
      }
    ],
    redes: [
      {
        link: "https://www.facebook.com/UnileverCareersSouthernCone",
        red: "facebook"
      },
      {
        link: "https://twitter.com/Unilever",
        red: "twitter"
      }
    ],
    telefono: "1145657575",
    marginTop: "0"
  },
  header: {
    css: {
      backgroundColor: "#91baf3",
      height: "12"
    },
    logoPrincipal: {
      url:
        "dev-img.liza.ashiwea.com/imagenes/logo/7170206133617172A20190114140309223Z.png"
    },
    menu: {
      css: {
        inside: {
          fontSize: "medium",
          padding: "medium"
        },
        below: {
          fontSize: "24",
          padding: "12",
          align: "center",
          backgroundColor: "none",
          borderTop: true
        },
        burger: {
          fontSize: "medium"
        }
      },
      navbar: {
        css: {
          height: "40",
          fontSize: "14",
          backgroundColor: "#e790b2",
          " > a:hover": {
            color: "blue",
            backgroundColor: "salmon"
          }
        },
        items: [
          {
            link: "/landing",
            texto: "Home"
          },
          {
            link: "/catalogo",
            texto: "Catalogo"
          },
          {
            link: "http://www.infobae.com",
            texto: "Desafios"
          },
          {
            link: "/admin/programa",
            texto: "Administracion"
          },
          {
            link: "/reportes",
            texto: "Reportes"
          }
        ]
      },
      tipo: "below"
    },
    user: {
      css: {
        fontSize: "24"
      }
    }
  },
  landing: {
    filas: [
      {
        contenidos: [
          {
            bases: [
              {
                src: "https://picsum.photos/1200/100/?image=0",
                link: "/producto/1",
                subTexto: "solo subtexto",
                texto: {
                  contenido: "probando el textarea del tempaltebuilder",
                  css: {
                    color: "",
                    fontFamily: "",
                    fontStyle: "",
                    fontWeight: "",
                    textAlign: "",
                    verticalAlign: ""
                  }
                },
                hasSpaceBetween: false,
                tipo: "base",
                collapsableTitle: "Base 1 en el slider"
              },
              {
                src: "https://picsum.photos/1200/100/?image=11",
                link: "/producto/2",
                subTexto: "solo subtexto",
                texto: {
                  contenido: "tiene otro texto",
                  css: {
                    color: "",
                    fontFamily: "",
                    fontStyle: "",
                    fontWeight: "",
                    textAlign: "",
                    verticalAlign: ""
                  }
                },
                hasSpaceBetween: false,
                tipo: "base",
                collapsableTitle: "Base 3 en el slider"
              }
            ],
            spaceBetween: false,
            tipo: "slider",
            sliderOptions: {
              showArrows: true,
              showIndicators: false,
              transitionTime: 350,
              interval: 5000,
              stopOnHover: true,
              autoPlay: true,
              infiniteLoop: true,
              showStatus: false,
              arrowStyle: "commonAlt"
            },
            src:
              "https://dev-img.liza.ashiwea.com/imagenes/logo/5289371084775363A20190125121056652Z.jpeg"
          }
        ],
        css: {
          margin: "",
          padding: "",
          height: "400"
        },
        spaceBetween: false,
        id: 1,
        filaTipo: 1,
        visible: true,
        indexFila: 0,
        collapsableTitle: "Fila 1"
      }
    ]
  },
  templateData: {
    GA: "UA-000000-02",
    googleFont: ["Arial", "Hanalei Fill"],
    background: {
      type: "",
      url: ""
    },
    fullWidth: false,
    templateId: 2,
    theme: {
      typography: {
        fontFamily: "Arial",
        fontFamilyBase: "Hanalei Fill"
      },
      palette: {
        common: {
          black: "#000",
          white: "#fff"
        },
        type: "light",
        primary: {
          main: "#0030ca",
          light: "rgb(110, 67, 163)",
          dark: "rgb(51, 14, 98)",
          contrastText: "#fff"
        },
        secondary: {
          main: "#bababa",
          light: "rgb(255, 99, 51)",
          dark: "rgb(178, 42, 0)",
          contrastText: "#fff"
        },
        tertiary: {
          main: "#97220f",
          light: "#BDBDBD",
          dark: "#757575",
          contrastText: "#000"
        },
        quaternary: {
          main: "#699500",
          light: "#BDBDBD",
          dark: "#757575",
          contrastText: "#000"
        },
        quinary: {
          main: "#7d1706",
          light: "#BDBDBD",
          dark: "#757575",
          contrastText: "#000"
        },
        error: {
          light: "#e57373",
          main: "#f44336",
          dark: "#d32f2f",
          contrastText: "#fff"
        },
        text: {
          primary: "#212121",
          secondary: "#757575",
          disabled: "rgba(0, 0, 0, 0.38)",
          hint: "rgba(0, 0, 0, 0.38)"
        },
        background: {
          paper: "#fff",
          default: "#fafafa"
        },
        action: {
          active: "rgba(0, 0, 0, 0.54)",
          hover: "rgba(0, 0, 0, 0.08)",
          hoverOpacity: 0.08,
          selected: "rgba(0, 0, 0, 0.14)",
          disabled: "rgba(0, 0, 0, 0.26)",
          disabledBackground: "rgba(0, 0, 0, 0.12)"
        }
      },
      shape: {
        borderRadius: 4
      },
      backgrounds: {
        access: {
          backgroundColor: "blue",
          backgroundImage:
            "https://apollo2.dl.playstation.net/cdn/UP9000/CUSA02299_00/FREE_CONTENTlfrM9XqFMPLKmUpQpxJS/PREVIEW_SCREENSHOT3_163995.jpg"
        },
        ui: {
          backgroundColor: "#ffffff",
          backgroundImage:
            "https://assets.awwwards.com/awards/images/2015/04/pattern.jpg"
        },
        container: {
          backgroundColor: "#eeeeee",
          backgroundImage: ""
        },
        page: {
          backgroundColor: "green",
          backgroundImage:
            "http://gdj.graphicdesignjunction.com/wp-content/uploads/2012/10/background+pattern+design+33.jpg"
        }
      }
    },
    backgroundColor: null,
    backgroundImage: null,
    backgroundImageAccess: null,
    backgroundColorAccess: null,
    css: {
      backgroundColor: "#006cff"
    },
    logoPrincipal: {
      url: "https://image.ibb.co/n6VRxK/cbu.png"
    },
    menu: {
      css: {
        inside: {
          fontSize: "medium",
          padding: "medium"
        },
        below: {
          fontSize: "12",
          padding: "12",
          align: "center",
          backgroundColor: "none",
          borderTop: true
        },
        burger: {
          fontSize: "medium"
        }
      },
      navbar: {
        css: {
          height: "40",
          fontSize: "14",
          backgroundColor: "#eb005d"
        },
        items: [
          {
            link: "/landing",
            texto: "Home"
          },
          {
            link: "/catalogo",
            texto: "Catalogo"
          },
          {
            link: "http://www.infobae.com",
            texto: "Desafios"
          },
          {
            link: "/admin/programa",
            texto: "Administracion"
          },
          {
            link: "/reportes",
            texto: "Reportes"
          }
        ]
      },
      tipo: "below"
    },
    user: {
      css: {
        fontSize: "12"
      }
    },
    cucardas: [
      {
        color: "#0b0b0b",
        background: "#00ffe0",
        type: "TIPO_1",
        label: "Nuevo",
        fontFamily: "Abril Fatface"
      },
      {
        color: "#000000",
        background: "#51ff00",
        type: "TIPO_2",
        label: "Sólo por hoy",
        fontFamily: "Permanent Marker"
      },
      {
        color: "#000000",
        background: "#00ffd1",
        type: "TIPO_3",
        label: "Oferta",
        fontFamily: "Lobster"
      },
      {
        color: "#000000",
        background: "#ff00a4",
        type: "TIPO_4",
        label: "Últimos disponibles",
        fontFamily: "Righteous"
      }
    ],
    favicon:
      "dev-img.liza.ashiwea.com/imagenes/logo/4596004643497927A20190114135340071Z.png",
    templateData: {
      cucardas: [
        {
          color: "#0b0b0b",
          background: "#00ffe0",
          type: "TIPO_1",
          label: "Nuevo",
          fontFamily: "Abril Fatface"
        },
        {
          color: "#000000",
          background: "#51ff00",
          type: "TIPO_2",
          label: "Sólo por hoy",
          fontFamily: "Permanent Marker"
        },
        {
          color: "#000000",
          background: "#00ffd1",
          type: "TIPO_3",
          label: "Oferta",
          fontFamily: "Lobster"
        },
        {
          color: "#000000",
          background: "#ff00a4",
          type: "TIPO_4",
          label: "Últimos disponibles",
          fontFamily: "Righteous"
        }
      ]
    },
    paddingGeneral: "11",
    borderRadiusGeneral: "3"
  }
};
