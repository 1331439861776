import get from "lodash/get";
export default theme => ({
  body: {
    background:
      get(theme, "backgrounds.access.backgroundType") === "url"
        ? `url(${get(
            theme,
            "backgrounds.access.backgroundImage"
          )}) no-repeat center center fixed`
        : get(theme, "backgrounds.access.backgroundColor", "#000"),
    backgroundSize: "cover",
    minHeight: "100vh"
  },
  container: {
    [theme.breakpoints.down(500)]: {
      justifyContent: "flex-start",
      width: "90%",
      margin: "auto",
      "& > div": {
        margin: "auto"
      }
    }
  },
  imgContainer: {
    paddingTop: "5%",
    paddingBottom: "5%"
  },
  signinLogo: {
    maxWidth: "100%"
  },
  formField: {
    paddingtop: "75px"
  }
});
