import React from "react";
import { Field } from "redux-form";
import { TextField } from "redux-form-material-ui";
import { isRequired } from "js/helpers/validations";
import { styles } from "../styles.js";

const InputTextField = ({
  name,
  label,
  placeholder,
  required,
  classes,
  disabled,
  customValidations = [],
  variant,
  rows
}) => (
  <Field
    type="textarea"
    required={required}
    name={name}
    label={label}
    placeholder={placeholder}
    component={TextField}
    disabled={disabled}
    fullWidth
    multiline
    variant={variant}
    rows={rows}
    validate={(() => {
      let validations = customValidations;
      if (required) {
        validations.push(isRequired);
      }
      return validations;
    })()}
    className={classes ? classes.field : styles.field}
  />
);

export default InputTextField;
