import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";
import { Base } from "../../Banner";
import { getCSS } from "./utils";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.css";

const defaultOptions = {
  showArrows: true,
  showIndicators: false,
  transitionTime: 350,
  interval: 1000,
  stopOnHover: false,
  autoPlay: true,
  infiniteLoop: true,
  showStatus: false,
  arrowStyle: "circle"
};
const SliderCustom = ({ contenido, history, classes }) => {
  const options = {
    ...defaultOptions,
    ...contenido.sliderOptions
  };
  return (
    <div className={getCSS(options.arrowStyle)}>
      <Carousel {...options} showThumbs={false}>
        {(contenido.bases || []).map((base, index) => (
          <Base
            key={`slider-${index}`}
            {...base}
            contenido={base}
            belongsToSlider
          />
        ))}
      </Carousel>
    </div>
  );
};

SliderCustom.propTypes = {
  classes: PropTypes.object
};

SliderCustom.displayName = "slider";

export default SliderCustom;
