const styles = theme => ({
  root: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    backgroundColor: "#fafafa"
  },
  formControl: {
    width: "100%",
    marginBottom: "30px"
  },
  subtitle: {
    margin: "1.5rem 0"
  },
  button: {
    margin: 8
  },
  buttonContainer: {
    textAlign: "right"
  }
});
export default styles;
