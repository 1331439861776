import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import ListItem from "./ListItem";

class Lista extends React.Component {
  state = {};

  render() {
    const { classes, productos, isLogged, programLogo } = this.props;

    return (
      <div className={classes.container}>
        <Grid container spacing={16}>
          {productos.map(producto => (
            <ListItem
              isLogged={isLogged}
              key={producto.producto.idProducto}
              producto={producto}
              classes={classes}
              programLogo={programLogo}
            />
          ))}
        </Grid>
      </div>
    );
  }
}

Lista.propTypes = {
  classes: PropTypes.object.isRequired
};

export default Lista;
