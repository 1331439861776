import { css } from "emotion";
import Arrows from "./Arrows";

export const getCSS = (type = "circle") => css`
.carousel .slider-wrapper {
    height: 100%;
    margin: auto;
  }
    .carousel .control-arrow:before,
    .carousel.carousel-slider .control-arrow:before {
        margin: 0 5px;
        display: inline-block;
        content: " ";
        background-size: 28px 28px;
        height: 38px;
        width: 28px;
    }
    .carousel .control-next.control-arrow:before,
    .carousel .control-prev.control-arrow:before {
        border-left: none;
        border-right: none;
        background-repeat: no-repeat;
        display: block;
        content: " ";
        background-size: 28px 28px;
        height: 38px;
        width: 28px;
    }
    .carousel .control-disabled.control-arrow {
        opacity: 0;
        filter: alpha(opacity=0);
        cursor: inherit;
        display: none;
    }
    .carousel .control-prev.control-arrow {
        left: 0;
    }
    .carousel .control-prev.control-arrow:before {
        background-image: url("${Arrows[type].left}");
    }
    .carousel .control-next.control-arrow {
        right: 0;
    }
    .carousel .control-next.control-arrow:before {
        background-image: url("${Arrows[type].right}");
    }
`;
