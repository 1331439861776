import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { _t } from "js/utils/TranslationService";
import Enhancer from "./container";
import Button from "@material-ui/core/Button";
import { TypeAhead } from "js/components/Shared/Form/Fields";

const GeneralWrapper = ({
  children,
  classes,
  subtitle,
  title,
  segmentos,
  segmentoDefault,
  onChange,
  onSave,
  onReset,
  // hasTempalteBuilder,
  idSegmento,
  conCancelar,
  history
}) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant="h5" gutterBottom color="primary">
        {title}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2" gutterBottom className={classes.Subtitle}>
        {subtitle}
      </Typography>
    </Grid>
    <Grid container item xs={12} className={classes.Segmento}>
      <TypeAhead
        label={"Segmento"}
        placeholder={"Buscar por segmento"}
        onChange={select => onChange(select.value)}
        path={{ path: "segmentos", value: "value", label: "label" }}
        suggestions={{ segmentos }}
        value={idSegmento}
      />
    </Grid>
    <Grid item xs={12} className={classes.bodySegmento}>
      {idSegmento ? children : `${_t("Debes elegir un segmento")}`}
    </Grid>
    {idSegmento && (
      <Grid item xs={12} className={classes.buttonContainer}>
        {conCancelar && (
          <Button
            variant="contained"
            onClick={() => history.push(conCancelar)}
            className={classes.floatingButton}
          >
            Cancelar
          </Button>
        )}
        <Button
          variant="contained"
          onClick={onSave}
          color="primary"
          className={classes.floatingButton}
        >
          Guardar
        </Button>
      </Grid>
    )}
    {idSegmento && (
      <Button
        variant="contained"
        onClick={onReset}
        color="primary"
        className={classes.floatingButtonLeft}
        style={{ display: "none" }}
      >
        Reset
      </Button>
    )}
  </Grid>
);

GeneralWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  segmentos: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  // segmentoDefault: PropTypes.number,
  subtitle: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string
};

GeneralWrapper.defaultProps = {
  subtitle: "",
  title: "",
  segmentoDefault: ""
};

GeneralWrapper.displayName = "GeneralWrapper";

export default Enhancer(GeneralWrapper);
