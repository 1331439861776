import React from "react";
import { Field } from "redux-form";
import { _t } from "js/utils/TranslationService";
import renderDatePicker from "./DatePicker";

const DatePicker = ({
  name,
  label,
  format = "DD/MM/YYYY",
  required,
  disabled,
  translation,
  classes,
  views,
  ...custom
}) => (
  <Field
    name={name}
    label={translation || _t(label)}
    dateFormat={format}
    component={renderDatePicker}
    required={required}
    fullWidth
    disabled={disabled}
    classes={classes}
    {...custom}
  />
);

export default DatePicker;
