import React from "react";
import ListAltIcon from "@material-ui/icons/ListAlt";
import base from "js/components/Shared/Tabla/ActionCells/Base";
import AuthService from "js/utils/AuthService";

export default ({ perms = [], optional = [], history, itemId }) => ({
  ...base(),
  label: "Objetivos",
  icon: <ListAltIcon />,
  tooltip: "Gestionar objetivos",
  show: () =>
    (!perms.length || AuthService.hasPerms(perms)) &&
    (!optional.lenght || AuthService.hasAnyPerm(optional)),
  onclick: () =>
    history.push(window.location.pathname + "/" + itemId + "/objetivos")
});
