import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from "@material-ui/core";

import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";

class advancedFilter extends PureComponent {
  render() {
    const { value, submitValue, classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"ACTIVO"}>Activo</MenuItem>
              <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"localidad"}
              name={"localidad"}
              label={"Localidad"}
              type="search"
              value={value["localidad"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionDesde"}
              name={"fechaCreacionDesde"}
              label="Fecha Creación (Desde)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionDesde",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionDesde"]
                  ? moment(value["fechaCreacionDesde"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionHasta"}
              name={"fechaCreacionHasta"}
              label="Fecha Creación (Hasta)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionHasta",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionHasta"]
                  ? moment(value["fechaCreacionHasta"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"codigo"}
              name={"codigo"}
              label={"Código"}
              type="search"
              value={value["codigo"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"nombre"}
              name={"nombre"}
              label={"Nombre"}
              type="search"
              value={value["nombre"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"rutCi"}
              name={"rutCi"}
              label={"RUT/CI"}
              type="search"
              value={value["rutCi"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
