import gql from "graphql-tag";

export const importProductosCargaFacturas = gql`
  mutation importProductosCargaFacturas($file: Upload!, $idPrograma: Int!) {
    importProductosCargaFacturas(file: $file, idPrograma: $idPrograma)
  }
`;

export const createCargaFactura = gql`
  mutation createCargaFactura($cargaFactura: CargaFacturaCreate) {
    createCargaFactura(cargaFactura: $cargaFactura) {
      idPrograma
      idUsuario
      puntoVenta
      numeroFactura
      fechaFactura
      motivo
      detalle
      productos {
        codigo
        nombre
        cantidad
        puntos
      }
      facturaUrl
      modificada
      estado
    }
  }
`;

export const getCargaFactura = gql`
  query getCargaFactura(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: CargaFacturaColumn!
    $idPrograma: Int
    $idUsuario: Int
    $estado: EstadoCargaFactura
    $puntoVenta: String
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
    $fechaFacturaDesde: Date
    $fechaFacturaHasta: Date
    $usuario: String
    $usuarioModerador: String
  ) {
    getCargaFactura(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      cargaFacturaOrder: { column: $orderBy, order: $order }
      cargaFacturaLike: {
        idPrograma: $idPrograma
        idUsuario: $idUsuario
        estado: $estado
        puntoVenta: $puntoVenta
        usuario: $usuario
        usuarioModerador: $usuarioModerador
      }
      cargaFacturaRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
        fechaFactura: { min: $fechaFacturaDesde, max: $fechaFacturaHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        idPrograma
        idUsuario
        idCargaFactura
        puntoVenta
        facturaUrl
        motivo
        modificada
        fechaFactura
        numeroFactura
        detalle
        usuario {
          username
          perfil {
            nombre
            apellido
          }
        }
        usuarioModerador {
          username
          perfil {
            nombre
            apellido
          }
        }
        productos {
          codigo
          nombre
          cantidad
          puntos
        }
        fechaCreacion
        estado
      }
    }
  }
`;

export const getProductosCargaFacturas = gql`
  query getProductosCargaFacturas(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: GrupoProductosCargaFacturasColumn!
    $idPrograma: Int
  ) {
    getProductosCargaFacturas(
      grupoProductosLike: { idPrograma: $idPrograma }
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      grupoProductosOrder: { column: $orderBy, order: $order }
    ) {
      infoPagina {
        numeroPagina
        cantidadPaginas
        total
      }
      itemsPagina {
        _id
        nombre
        codigo
        puntos
      }
    }
  }
`;

export const getPuntosProducto = gql`
  query getPuntosProducto($idPrograma: Int!, $codigo: String!) {
    getPuntosProducto(
      grupoProductosLike: { idPrograma: $idPrograma, codigo: $codigo }
    ) {
      puntos
    }
  }
`;

export const updateCargaFactura = gql`
  mutation updateCargaFactura($cargaFactura: CargaFacturaUpdate) {
    updateCargaFactura(cargaFactura: $cargaFactura) {
      _id
      idPrograma
      idUsuario
      estado
      modificada
      motivo
    }
  }
`;

export default {
  queries: {
    getProductosCargaFacturas,
    getCargaFactura,
    getPuntosProducto
  },
  mutations: {
    createCargaFactura,
    importProductosCargaFacturas,
    updateCargaFactura
  }
};
