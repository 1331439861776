import React from "react";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { DateTimePicker } from "material-ui-pickers";
import moment from "moment";
import localization from "moment/locale/es";

moment()
  .locale("es", localization)
  .format("LLL");

const renderDateTimePicker = ({
  input: { value, onChange, ...input },
  meta: { touched = "", error = undefined },
  label = "",
  placeholder,
  dateFormat,
  ...custom
}) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <DateTimePicker
      label={label}
      value={value}
      onChange={date => onChange(date.toISOString())}
      error={touched && Boolean(error)}
      helperText={touched && error}
      invalidLabel={placeholder}
      InputLabelProps={{
        shrink: true
      }}
      format={dateFormat}
      cancelLabel="CANCELAR"
      maxDateMessage="La fecha debe ser menor que hoy"
      {...custom}
      {...input}
    />
  </MuiPickersUtilsProvider>
);

renderDateTimePicker.defaultProps = {
  input: { value: "2018-01-01T00:00:00.000Z", onChange: () => undefined },
  meta: { touched: false, error: "" },
  label: "",
  placeholder: "",
  ampm: false,
  showTodayButton: false,
  disableFuture: false,
  autoOk: true,
  dateFormat: "dd/MM/yyyy HH:mm"
};
export default renderDateTimePicker;
