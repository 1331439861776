import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import EnhancedTable from "./enhancedTable";
import styles from "./styles";
import withWidth from "@material-ui/core/withWidth";

const enhance = compose(
  withRouter,
  withStyles(styles),
  withWidth()
);

export default enhance(EnhancedTable);
