import gql from "graphql-tag";

export const getCampanasConTargets = gql`
  query getCampanasConTargets(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: CampanaOPColumn!
    $idPrograma: Int
    $idCampana: ID
    $nombre: String
    $fechaInicio: Date
    $fechaFin: Date
    $estado: EstadoCampanaOP
  ) {
    getCampanasConTargets(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      campanaOPOrder: { column: $orderBy, order: $order }
      campanaOPLike: {
        idPrograma: $idPrograma
        nombre: $nombre
        estado: $estado
        _id: $idCampana
      }
      campanaOPRange: {
        fechaInicio: { min: $fechaInicio }
        fechaFin: { max: $fechaFin }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        idPrograma
        nombre
        fechaInicio
        fechaFin
        fechaCierre
        estado
        tipo
      }
    }
  }
`;

export const getTargetsDeMedPorCampana = gql`
  query getTargetsDeMedPorCampana($idCampana: ID) {
    getTargetsDeMedPorCampana(idCampana: $idCampana) {
      _id
      objetivo {
        _id
        objetivo
      }
      usuario {
        idUsuario
        username
        estado
        perfil {
          nombre
          apellido
        }
      }
      target
      targetOriginal
      estado
    }
  }
`;

export const updateTargetsDeMed = gql`
  mutation updateTargetsDeMed($targets: [TargetCampanaUpdate]) {
    updateTargetsDeMed(targets: $targets)
  }
`;

export const aprobarTargetsDeMed = gql`
  mutation aprobarTargetsDeMed($targetsIds: [ID]) {
    aprobarTargetsDeMed(targetsIds: $targetsIds)
  }
`;

export const getTargetsDeMeds = gql`
  query getTargetsDeMeds(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: TargetUsuarioColumn!
    $idPrograma: Int
    $idCampana: ID
    $idObjetivo: ID
    $idUsuario: Int
    $target: Int
    $codigoMed: String
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getTargetsDeMeds(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      targetUsuarioOrder: { column: $orderBy, order: $order }
      targetUsuarioLike: {
        idPrograma: $idPrograma
        idCampana: $idCampana
        idObjetivo: $idObjetivo
        idUsuario: $idUsuario
        target: $target
        codigoMed: $codigoMed
      }
      targetUsuarioRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        campanaOP {
          _id
          nombre
        }
        objetivo {
          objetivo
        }
        usuario {
          idUsuario
          username
          perfil {
            nombre
            apellido
          }
        }
        target
        fechaCreacion
        estado
        med {
          nombre
        }
      }
    }
  }
`;

export default {
  queries: {
    getCampanasConTargets,
    getTargetsDeMedPorCampana,
    getTargetsDeMeds
  },
  mutations: {
    updateTargetsDeMed,
    aprobarTargetsDeMed
  }
};
