export default theme => ({
  objetivoContainer: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    marginRight: "10px"
  },
  objetivoWrapper: {
    display: "flex",
    flexGrow: 1
  },
  objetivoTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "600"
  },
  chartContainer: {
    display: "flex",
    flexGrow: 1,
    position: "relative",
    maxWidth: "100%",
    margin: "10px auto"
  },
  barraHorizontalChart: {
    minHeight: "250px"
  },
  barraVerticalChart: {
    minHeight: "420px",
    padding: "0 10px"
  },
  circularChart: {},
  semiCircularChart: {},
  trianguloChart: {
    minHeight: "250px"
  },
  multipleTargetsContainer: {
    textAlign: "center"
  },
  canvas: {
    position: "absolute",
    overflow: "visible"
  },
  indicadorCanvas: {
    zIndex: 100
  }
});
