import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  paper: {
    marginBottom: 16
  },
  collapse: {
    padding: "8px 24px"
  }
});

export default withStyles(styles);
