import { compose, withProps, defaultProps } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import styles from "./styles.js";
import AccionesGraphQL from "js/graphql/resolvers/acciones.resolver";
import Detalle from "./Detalle";
import RankingRaw from "./Ranking";
import ResumenRaw from "./Resumen";
import get from "lodash/get";

const enhance = compose(
  withStyles(styles),
  withRouter,
  withProps(props => {
    const idAccion = get(
      props,
      "match.params.idAccion",
      get(props, "computedMatch.params.idAccion")
    );
    return idAccion
      ? {
          idAccion: idAccion
        }
      : {};
  }),
  graphql(AccionesGraphQL.queries.getAccionById, {
    options: props => {
      return {
        variables: { idAccion: props.idAccion },
        fetchPolicy: "network-only"
      };
    },
    props: ({ data: { loading, getAcciones } }) => {
      if (loading) return;
      const accion = get(getAcciones, "itemsPagina[0]", {});
      return { accion };
    }
  }),
  defaultProps({
    accion: {}
  })
);

export const Ranking = enhance(RankingRaw);
export const Resumen = enhance(ResumenRaw);

export default enhance(Detalle);
