import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import Grid from "@material-ui/core/Grid";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles.js";
import Dialog from "./Dialog";

class DeleteReportes extends PureComponent {
  state = { open: false };
  toggleDialog = open => this.setState({ open });

  render() {
    const {
      classes,
      handleChange,
      idUsuario,
      onSubmit,
      fecha,
      suggestions
    } = this.props;
    const { open } = this.state;

    return (
      <form
        className={classes.container}
        autoComplete="off"
        onSubmit={e => e.preventDefault() || this.toggleDialog(true)}
      >
        <Grid item container xs={12} spacing={16} direction="column">
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Eliminar reportes
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} item>
            <DatePicker
              key="fecha"
              name="fecha"
              label="Fecha"
              placeholder="Fecha"
              dateFormat="MM/YYYY"
              maxDate={moment()}
              views={["year", "month"]}
              openTo="year"
              input={{
                onChange: ({ _d }) =>
                  handleChange("fecha")(parseInt(moment(_d).format("YYYYMM"))),
                value: moment(fecha, "YYYYMM")
              }}
              className={classes.fullWidth}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TypeAhead
              label={"Cuenta madre"}
              onChange={s => handleChange("idUsuario")(s.value)}
              path={{
                path: "suggestions",
                value: "idUsuario",
                label: "username"
              }}
              placeholder={"Buscar por cuenta madre"}
              suggestions={{ suggestions }}
              value={idUsuario}
              withTodos
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.buttonContainer}>
            <Button
              variant="contained"
              className={classes.button}
              type="submit"
              disabled={!fecha}
            >
              Borrar
            </Button>
          </Grid>
        </Grid>
        {open && (
          <Dialog
            okAction={() => this.toggleDialog(false) || onSubmit()}
            cancelAction={() => this.toggleDialog(false)}
          />
        )}
      </form>
    );
  }
}

DeleteReportes.propTypes = {
  classes: PropTypes.object
};

DeleteReportes.defaultProps = {
  suggestions: []
};

DeleteReportes.displayName = "DeleteReportes";

export default withStyles(styles)(DeleteReportes);
