import React, { PureComponent, Fragment } from "react";
import EddForm from "js/pages/Unilever/Usuario/Edit/EddForm.js";
import SupervisorForm from "js/pages/Unilever/Usuario/Edit/SupervisorForm.js";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import ProgramQraphQL from "js/graphql/resolvers/programa.resolver";
import UserUnileverGraphQL from "js/graphql/resolvers/usuarioUnilever.resolver";
import MedGraphQL from "js/graphql/resolvers/medUnilever.resolver";
import TranslationService from "js/utils/TranslationService";
import SplashScreen from "js/pages/SplashScreen";

const NAMES = {
  supervisor: {
    rolName: "supervisor",
    cargo: "supervisor"
  }
};

const SPECIAL_FIELDS = {
  CARGO: "cargo",
  DOMICILIO_LABORAL: "domicilio_laboral",
  DOMICILIO_PERSONAL: "domicilio_personal"
};

const getUbicacionExtraProps = (fieldName, required, fieldsToShow) => {
  const showAll =
    fieldsToShow.length === 0 ||
    (fieldsToShow.length === 1 && fieldsToShow[0] === "");
  return {
    pais: {
      name: `${fieldName}.idPais`,
      required,
      show: showAll ? true : fieldsToShow.includes("PAIS")
    },
    zona: {
      name: `${fieldName}.idZona`,
      required,
      show: showAll ? true : fieldsToShow.includes("ZONA")
    },
    localidad: {
      name: `${fieldName}.idLocalidad`,
      required,
      show: showAll ? true : fieldsToShow.includes("LOCALIDAD")
    },
    direccion: {
      name: `${fieldName}.direccion`,
      required,
      show: showAll ? true : fieldsToShow.includes("DIRECCION")
    },
    codPostal: {
      name: `${fieldName}.codPostal`,
      required,
      show: showAll ? true : fieldsToShow.includes("CODIGO_POSTAL")
    }
  };
};

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    const params = {};
    const userRoles = AuthService.getUserRoles();
    const isSupervisor =
      userRoles &&
      userRoles.some(rol => {
        if (rol.toLowerCase() === NAMES.supervisor.rolName) {
          return true;
        }
        return false;
      });
    params.idPrograma = parseInt(AuthService.getIdPrograma());
    params.idUsuario = this.props.computedMatch.params.idUsuario;
    params.edit = this.props.computedMatch.params.edit;
    params.idIdioma = TranslationService.getIdiomaId();
    this.state = {
      ...params,
      initialValues: {},
      disabled: null,
      errors: null,
      cargoOptions: [],
      meds: [],
      supervisores: [],
      roles: [],
      isSupervisor: isSupervisor,
      estado: null,
      domicilioPersonalProps: {},
      domicilioLaboralProps: {}
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { idPrograma, idUsuario, edit, isSupervisor } = this.state;

    if (idUsuario !== "new") {
      await client
        .query({
          query: UserUnileverGraphQL.queries.getUsuarioUnilever,
          fetchPolicy: "network-only",
          variables: {
            idUsuario
          }
        })
        .then(res => {
          if (res.data.getUsuarioUnilever) {
            const perfil = res.data.getUsuarioUnilever;
            const otros = JSON.parse(perfil.otros);
            this.setState({
              initialValues: {
                username: perfil.username,
                email: otros.email,
                nombre: otros.nombre,
                apellido: otros.apellido,
                celular: otros.celular,
                cargo: otros.cargo,
                código_de_vendedor: otros.código_de_vendedor,
                codigoDistribuidora: otros.código_de_distribuidora,
                fecha_de_nacimiento: otros.fecha_de_nacimiento,
                rol:
                  otros.cargo === "Preventista" || otros.cargo === "Activador"
                    ? "PREVENTISTA"
                    : otros.código_de_vendedor
                      ? "PREVENTISTA"
                      : perfil.estado === "INACTIVO"
                        ? ""
                        : "SUPERVISOR",
                idJefe: perfil.idJefeNivel1
              },
              disabled:
                !(edit === "edit") ||
                !AuthService.hasPerms([Roles.USUARIO_W]) ||
                (!isSupervisor && perfil.estado === "PENDIENTE"),
              estado: perfil.estado
            });
          }
        });
    }

    if (!isSupervisor) {
      this.getSupervisores();
      await client
        .query({
          query: MedGraphQL.queries.getMisMedsInput,
          fetchPolicy: "network-only",
          variables: {
            medLike: {
              idPrograma
            }
          }
        })
        .then(res => {
          if (res && res.data && res.data.getMisMeds) {
            const meds = res.data.getMisMeds.itemsPagina;
            this.setState({
              meds: meds.map(med => {
                return {
                  value: med.codigo,
                  label: med.label
                };
              })
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }

    // Buscar las definiciones de peril
    await client
      .query({
        query: ProgramQraphQL.queries.getDefinicionesPerfilPublico,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: idPrograma
        }
      })
      .then(res => {
        if (res.data.getDefinicionesPerfilPublico) {
          let cargoField = null;
          let domicilioPersonalField = null;
          let domicilioLaboralField = null;
          res.data.getDefinicionesPerfilPublico.forEach(field => {
            if (field.key === SPECIAL_FIELDS.CARGO) {
              cargoField = field;
            }

            if (field.key === SPECIAL_FIELDS.DOMICILIO_PERSONAL) {
              domicilioPersonalField = field;
            }

            if (field.key === SPECIAL_FIELDS.DOMICILIO_LABORAL) {
              domicilioLaboralField = field;
            }
          });

          let values = [];
          if (isSupervisor) {
            values = cargoField.value.filter(
              value => value.toLowerCase() !== NAMES.supervisor.cargo
            );
          } else {
            values = cargoField.value;
          }

          this.setState({
            cargoOptions: values,
            domicilioLaboralProps: getUbicacionExtraProps(
              domicilioLaboralField.key,
              domicilioLaboralField.requeridoRegistro,
              domicilioLaboralField.value
            ),
            domicilioPersonalProps: getUbicacionExtraProps(
              domicilioPersonalField.key,
              domicilioPersonalField.requeridoRegistro,
              domicilioPersonalField.value
            )
          });
        }
      });
  }

  async getSupervisores() {
    const { client } = this.props;

    await client
      .query({
        query: UserUnileverGraphQL.queries.getUsuariosUnilever,
        fetchPolicy: "network-only",
        variables: {
          usuarioLike: {
            soloSupervisores: true
          }
        }
      })
      .then(res => {
        if (res.data.getUsuariosUnilever) {
          const supervisores = res.data.getUsuariosUnilever.itemsPagina.map(
            supervisor => {
              let label = "";
              const otros = JSON.parse(supervisor.perfil.otros);
              if (supervisor.perfil && supervisor.perfil.nombre !== "") {
                label = `${supervisor.perfil.nombre} ${
                  supervisor.perfil.apellido
                }`;
              }
              label = `${label} (${supervisor.username})`;
              return {
                value: supervisor.idUsuario,
                label: label,
                codigoDistribuidora: otros.código_de_distribuidora
              };
            }
          );

          this.setState({
            supervisores: supervisores
          });
        }
      });
  }

  componentWillReceiveProps = nextProps => {
    let action = "";
    const {
      computedMatch: {
        params: { edit }
      }
    } = nextProps;
    action = edit;

    this.setState({
      disabled: !(action === "edit") || !AuthService.hasPerms([Roles.USUARIO_W])
    });
  };

  // Submit de EDD
  async submit(values) {
    const { client, history } = this.props;
    const { idIdioma, idUsuario } = this.state;
    const valuesCopy = { ...values };
    const username = valuesCopy.username;
    const chosenJefeId = valuesCopy.idJefe;
    const codigoDistribuidora = valuesCopy.codigoDistribuidora;
    const rol = valuesCopy.rol;
    delete valuesCopy.username;
    delete valuesCopy.idJefe;
    delete valuesCopy.codigoDistribuidora;
    delete valuesCopy.rol;
    if (
      valuesCopy.fecha_de_nacimiento &&
      typeof valuesCopy.fecha_de_nacimiento !== "string"
    ) {
      valuesCopy.fecha_de_nacimiento = valuesCopy.fecha_de_nacimiento.toISOString();
    }

    const otros = {
      ...valuesCopy,
      dni: username
    };
    const variables = {
      username: username,
      rol: rol,
      codigoDistribuidora: codigoDistribuidora,
      perfil: { otros: JSON.stringify(otros) },
      idIdioma: idIdioma
    };

    // Si eligió un preventista agregar el idJefe
    if (rol === "PREVENTISTA") {
      variables.idJefe = chosenJefeId;
    }

    if (idUsuario === "new") {
      await client
        .mutate({
          mutation: UserUnileverGraphQL.mutations.createUsuarioUnilever,
          variables: {
            usuario: { ...variables }
          }
        })
        .then(res => {
          const message = "Usuario grabado correctamente";
          this.props.openNotification(message);
          history.push(`/usuarios-mis-ventas`);
        })
        .catch(errors => {
          const arr = [];
          arr.push({ message: "Error inesperado" });
          this.setState({
            errors:
              errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
          });
          return false;
        });
    } else {
      variables.idUsuario = idUsuario;
      await client
        .mutate({
          mutation: UserUnileverGraphQL.mutations.updateUsuarioUnilever,
          variables: {
            usuario: { ...variables }
          }
        })
        .then(res => {
          const message = "Usuario modificado correctamente";
          this.props.openNotification(message);
          history.push(`/usuarios-mis-ventas`);
        })
        .catch(errors => {
          const arr = [];
          arr.push({ message: "Error inesperado" });
          this.setState({
            errors:
              errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
          });
          return false;
        });
    }
  }

  async submitSupervisor(values) {
    const { client, history } = this.props;
    const { idIdioma, idUsuario } = this.state;
    const valuesCopy = { ...values };
    const username = valuesCopy.username;
    delete valuesCopy.username;
    delete valuesCopy.roles;
    if (
      valuesCopy.fecha_de_nacimiento &&
      typeof valuesCopy.fecha_de_nacimiento !== "string"
    ) {
      valuesCopy.fecha_de_nacimiento = valuesCopy.fecha_de_nacimiento.toISOString();
    }
    const otros = {
      ...valuesCopy,
      dni: username
    };

    if (idUsuario === "new") {
      await client
        .mutate({
          mutation: UserUnileverGraphQL.mutations.createUsuarioUnilever,
          variables: {
            usuario: {
              username: username,
              idIdioma: idIdioma,
              perfil: { otros: JSON.stringify(otros) },
              rol: "PREVENTISTA"
            }
          }
        })
        .then(res => {
          const message = "Usuario grabado correctamente";
          this.props.openNotification(message);
          history.push(`/usuarios-mis-ventas`);
        })
        .catch(errors => {
          const arr = [];
          arr.push({ message: "Error inesperado" });
          this.setState({
            errors:
              errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
          });
          return false;
        });
    } else {
      await client
        .mutate({
          mutation: UserUnileverGraphQL.mutations.updateUsuarioUnilever,
          variables: {
            usuario: {
              idUsuario: parseInt(idUsuario),
              rol: "PREVENTISTA",
              username: username,
              idIdioma: idIdioma,
              perfil: { otros: JSON.stringify(otros) }
            }
          }
        })
        .then(res => {
          const message = "Usuario modificado correctamente";
          this.props.openNotification(message);
          history.push(`/usuarios-mis-ventas`);
        })
        .catch(errors => {
          const arr = [];
          arr.push({ message: "Error inesperado" });
          this.setState({
            errors:
              errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
          });
          return false;
        });
    }
  }

  async accionEdd(accion) {
    const { client, history } = this.props;
    const { idUsuario } = this.state;

    await client
      .mutate({
        mutation: UserUnileverGraphQL.mutations.aceptarUsuarioUnilever,
        variables: {
          idUsuario,
          accion
        }
      })
      .then(res => {
        console.log(res);
        history.push(`/usuarios-mis-ventas`);
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  datosCargados() {
    const { cargoOptions, isSupervisor, meds } = this.state;

    if (cargoOptions.length !== 0) {
      if (isSupervisor) {
        return true;
      } else if (!isSupervisor & (meds.length !== 0)) {
        return true;
      }
    } else {
      return false;
    }
  }

  render() {
    const {
      idUsuario,
      errors,
      disabled,
      initialValues,
      cargoOptions,
      isSupervisor,
      supervisores,
      estado,
      domicilioLaboralProps,
      domicilioPersonalProps,
      meds
    } = this.state;

    return (
      <Fragment>
        {!this.datosCargados() ? (
          <SplashScreen />
        ) : isSupervisor ? (
          <SupervisorForm
            idUsuario={idUsuario}
            errors={errors}
            disabled={disabled}
            initialValues={initialValues}
            onSubmit={this.submitSupervisor.bind(this)}
            cargoOptions={cargoOptions}
            domicilioLaboralProps={domicilioLaboralProps}
            domicilioPersonalProps={domicilioPersonalProps}
          />
        ) : (
          <EddForm
            idUsuario={idUsuario}
            errors={errors}
            disabled={disabled}
            initialValues={initialValues}
            onSubmit={this.submit.bind(this)}
            cargoOptions={cargoOptions}
            meds={meds}
            supervisores={supervisores}
            estado={estado}
            accionEdd={this.accionEdd.bind(this)}
            domicilioLaboralProps={domicilioLaboralProps}
            domicilioPersonalProps={domicilioPersonalProps}
          />
        )}
      </Fragment>
    );
  }
}

export default Edit;
