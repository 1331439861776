import React, { Fragment } from "react";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import LotesGraphQL from "js/graphql/resolvers/lotes.resolver";
import client from "js/App/client.graphql.js";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogAddCanjeALote extends React.Component {
  state = {
    lote: undefined,
    error: undefined
  };

  sendValues = async () => {
    const idCanje = this.props.idCanje;
    const lote = this.state.lote;
    this.setState({ error: undefined });
    await client
      .mutate({
        mutation: LotesGraphQL.mutations.addCanjeALote,
        variables: { lote, idCanje }
      })
      .then(res => {
        const message = `Canje agregado al lote`;
        // debugger;
        this.props.openNotification(message);
        this.props.onTableChange();
        this.props.handleClose();
      })
      .catch(error => {
        // debugger;
        const message = error.graphQLErrors[0].message;
        this.setState({ error: message });
        // TODO: mostrar error por pantalla, las notificaciones tienen texto estatico por tema traducciones
        // this.props.openNotification(message);
      });
  };

  handleChange = () => event => {
    this.setState({ lote: event.target.value });
  };
  render() {
    const { handleClose, classes } = this.props;
    console.log("🚀 ~ this.props", this.props);

    return (
      <Fragment>
        <Dialog
          open
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={classes.dialogTitle}
          >
            Agregar al lote
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <FormControl>
              <TextField
                id="standard-name"
                label="Código del lote"
                className={classes.textField}
                value={this.state.lote}
                onChange={this.handleChange("lote")}
                margin="normal"
                error={this.state.error}
                helperText={this.state.error}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Descartar
            </Button>

            <Button
              disabled={this.state.lote === undefined}
              onClick={this.sendValues}
              variant="contained"
              color="primary"
            >
              Agregar al Lote
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DialogAddCanjeALote.defaultProps = {};

DialogAddCanjeALote.propTypes = {};

export default DialogAddCanjeALote;
