import React, { Fragment } from "react";
import { SortableElement } from "react-sortable-hoc";
import ListItem from "@material-ui/core/ListItem";
import DragHandle from "./DragHandle";
import CollapsableComponent from "./CollapsableComponent";
import { SortableContext } from "../sortable-context.js";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import { Remove } from "@material-ui/icons";

const SortableItem = ({ value, indexItem, component, position }) => {
  const Component = component;
  return (
    <ListItem>
      <SortableContext.Consumer>
        {({
          metadata,
          removeElement,
          updateOnElement,
          isCollapsable,
          classes,
          staticAmountItems,
          maxAmountItems,
          totalItems,
          addElement
        }) => (
          <Fragment>
            <DragHandle
              removeElement={removeElement}
              classes={classes}
              indexItem={indexItem}
            />
            {isCollapsable ? (
              <CollapsableComponent
                collapsableTitle={value.collapsableTitle}
                classes={classes}
              >
                <Component
                  metadata={metadata}
                  position={position}
                  value={value}
                  indexItem={indexItem}
                  updateOnElement={updateOnElement(indexItem, position)}
                  {...metadata}
                />
              </CollapsableComponent>
            ) : (
              <div className={classes.item}>
                <Component
                  value={value}
                  indexItem={indexItem}
                  updateOnElement={updateOnElement(indexItem, position)}
                  {...metadata}
                />
              </div>
            )}

            {totalItems > 1 &&
              !staticAmountItems &&
              indexItem + 1 <= totalItems && (
                <Tooltip title="Quitar elemento">
                  <Remove
                    className={classes.iconRemoveElement}
                    onClick={() => removeElement(indexItem, position)}
                  />
                </Tooltip>
              )}
            {totalItems < maxAmountItems &&
              !staticAmountItems &&
              indexItem + 1 === totalItems && (
                <Tooltip title="Agregar nuevo elemento">
                  <Icon
                    className={classes.iconAddElement}
                    color="secondary"
                    onClick={addElement}
                  >
                    add_circle
                  </Icon>
                </Tooltip>
              )}
          </Fragment>
        )}
      </SortableContext.Consumer>
    </ListItem>
  );
};

export default SortableElement(SortableItem);
