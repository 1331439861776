import EditEmail from "./Edit";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withNotificationContext } from "js/notification-context";

const Enhancer = compose(
  withApollo,
  withRouter,
  withNotificationContext
);

export default Enhancer(EditEmail);
