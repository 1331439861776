import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { TypeAhead, UsuarioField } from "js/components/Shared/Form/Fields";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Typography from "@material-ui/core/Typography/Typography";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";
import { SearchIcon } from "../../../../images/icons";
import { _t } from "js/utils/TranslationService";

class advancedFilter extends PureComponent {
  constructor(props) {
    super();
    props.submitValue({
      target: { name: "nominaEquipos", value: "AMBOS" }
    });
  }

  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };
  handleChangeMulti = key => select => {
    this.props.submitValue({
      target: {
        name: key,
        value: select ? select.map(s => s.value) : undefined
      }
    });
  };
  handleChangeUsuario = key => select => {
    this.props.submitValue({ target: { name: key, value: select[0].value } });
  };
  handleCheck = event => {
    this.props.submitValue({
      target: { name: "nominaEquipos", value: event.target.value }
    });
  };

  render() {
    const { idPrograma, formulario, conEstado = false } = this.props;
    const { value, submitValue, classes } = this.props;
    return (
      <Grid container>
        <Grid item container xs={12} spacing={16}>
          <Grid item xs={12} md={3}>
            <Typography
              color={"primary"}
              classes={{
                root: classes.label
              }}
            >
              {_t("NOMINADOR")}
            </Typography>
            <UsuarioField
              async
              key="idUsuarioReconocedor"
              name="idUsuarioReconocedor"
              type="search"
              value={value["idUsuarioReconocedor"] || ""}
              classes={{ input: classes.usuarioField }}
              onChange={this.handleChangeUsuario("idUsuarioReconocedor")}
              idPrograma={idPrograma}
              withTodos
              noLabel
              noPlaceholder
              otherComponents={{
                IndicatorsContainer: props => (
                  <SearchIcon
                    color={"primary"}
                    style={{ padding: "2px" }}
                    {...props}
                  />
                )
              }}
              variant={"outlined"}
              multilabel={[
                "perfil.nombre",
                " ",
                "perfil.apellido",
                " (",
                "nombre",
                ")"
              ]}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              color={"primary"}
              classes={{
                root: classes.label
              }}
            >
              {_t("NOMINADO/S")}
            </Typography>
            <UsuarioField
              async
              key="idUsuariosReconocidos"
              name="idUsuariosReconocidos"
              type="search"
              value={value["idUsuariosReconocidos"] || ""}
              classes={{ input: classes.usuarioField }}
              onChange={this.handleChangeUsuario("idUsuariosReconocidos")}
              idPrograma={idPrograma}
              withTodos
              noLabel
              sinAdminAshiwea
              noPlaceholder
              otherComponents={{
                IndicatorsContainer: props => (
                  <SearchIcon
                    color={"primary"}
                    style={{ padding: "2px" }}
                    {...props}
                  />
                )
              }}
              variant={"outlined"}
              multilabel={[
                "perfil.nombre",
                " ",
                "perfil.apellido",
                " (",
                "nombre",
                ")"
              ]}
            />
          </Grid>
          {formulario && formulario.valor && formulario.valor.alias ? (
            <Grid item xs={12} md={3}>
              <Typography
                color={"primary"}
                classes={{
                  root: classes.label
                }}
              >
                {_t("Valor/es")}
              </Typography>
              <TypeAhead
                suggestions={{
                  estados: formulario.valor.valores.map(valor => ({
                    value: valor,
                    label: valor
                  }))
                }}
                path={{ path: "estados", value: "value", label: "label" }}
                onChange={this.handleChange("valor")}
                value={value["valor"]}
                withTodos
                variant={"outlined"}
                placeholder={" "}
                classes={classes}
                style={{
                  maxHeight: "28px"
                }}
              />
            </Grid>
          ) : (
            ""
          )}
          {formulario && formulario.tipo && formulario.tipo.alias ? (
            <Grid item xs={12} md={3}>
              <Typography
                color={"primary"}
                classes={{
                  root: classes.label
                }}
              >
                {_t("Tipo/s")}
              </Typography>
              <TypeAhead
                suggestions={{
                  estados: formulario.tipo.valores.map(valor => ({
                    value: valor,
                    label: valor
                  }))
                }}
                path={{ path: "estados", value: "value", label: "label" }}
                onChange={this.handleChange("tipo")}
                value={value["tipo"]}
                withTodos
                variant={"outlined"}
                classes={classes}
                placeholder={" "}
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Grid
          xs={12}
          item
          container
          style={{
            paddingTop: "35px"
          }}
          spacing={16}
        >
          <Grid item xs={12} md={4}>
            <RadioGroup
              className={classes.group}
              name={"nominaEquipos"}
              value={value["nominaEquipos"]}
              onChange={this.handleCheck}
              color={"primary"}
              defaultValue={"AMBOS"}
            >
              {[
                { label: _t("INDIVIDUAL"), value: "INDIVIDUAL" },
                { label: _t("EQUIPO"), value: "EQUIPO" },
                { label: _t("AMBOS"), value: "AMBOS" }
              ].map((option, i) => (
                <FormControlLabel
                  key={i}
                  label={option.label}
                  value={option.value}
                  classes={{ label: classes.label }}
                  control={<Radio color={"primary"} />}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid xs={12} item>
              <Typography
                color={"primary"}
                classes={{
                  root: classes.label
                }}
              >
                {_t("FECHA DE NOMINACION")}
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <DatePicker
                key={"fechaInicioNominacion"}
                name={"fechaInicioNominacion"}
                classes={{
                  root: classes.formControl,
                  input: classes.formControl
                }}
                input={{
                  onChange: date =>
                    submitValue({
                      target: {
                        name: "fechaInicioNominacion",
                        value: date.toISOString()
                      }
                    }),
                  value: value["fechaInicioNominacion"]
                    ? moment(value["fechaInicioNominacion"])
                    : null
                }}
                format={"DD/MM/YYYY"}
                maxDate={moment()}
                autoOk={true}
                startOfDate
                variant={"outlined"}
                emptyLabel={"Desde"}
              />
            </Grid>
            <Grid xs={12} item>
              <DatePicker
                key={"fechaFinNominacion"}
                name={"fechaFinNominacion"}
                className={classes.formControl}
                input={{
                  onChange: date =>
                    submitValue({
                      target: {
                        name: "fechaFinNominacion",
                        value: date.toISOString()
                      }
                    }),
                  value: value["fechaFinNominacion"]
                    ? moment(value["fechaFinNominacion"])
                    : null
                }}
                format={"DD/MM/YYYY"}
                maxDate={moment()}
                autoOk={true}
                endOfDate
                variant={"outlined"}
                emptyLabel={"Hasta"}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid xs={12} item>
              <Typography
                color={"primary"}
                classes={{
                  root: classes.label
                }}
              >
                {_t("FECHA DE APROBACION")}
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <DatePicker
                key={"fechaInicioAprobacion"}
                name={"fechaInicioAprobacion"}
                classes={{
                  root: classes.formControl,
                  input: classes.formControl
                }}
                input={{
                  onChange: date =>
                    submitValue({
                      target: {
                        name: "fechaInicioAprobacion",
                        value: date.toISOString()
                      }
                    }),
                  value: value["fechaInicioAprobacion"]
                    ? moment(value["fechaInicioAprobacion"])
                    : null
                }}
                format={"DD/MM/YYYY"}
                maxDate={moment()}
                autoOk={true}
                startOfDate
                variant={"outlined"}
                emptyLabel={"Desde"}
              />
            </Grid>
            <Grid xs={12} item>
              <DatePicker
                key={"fechaFinAprobacion"}
                name={"fechaFinAprobacion"}
                className={classes.formControl}
                input={{
                  onChange: date =>
                    submitValue({
                      target: {
                        name: "fechaFinAprobacion",
                        value: date.toISOString()
                      }
                    }),
                  value: value["fechaFinAprobacion"]
                    ? moment(value["fechaFinAprobacion"])
                    : null
                }}
                format={"DD/MM/YYYY"}
                maxDate={moment()}
                autoOk={true}
                endOfDate
                variant={"outlined"}
                emptyLabel={"Hasta"}
              />
            </Grid>
          </Grid>
          {conEstado && (
            <Grid item xs={12} md={12}>
              <Grid xs={12} item>
                <Typography
                  color={"primary"}
                  classes={{
                    root: classes.label
                  }}
                >
                  Estado
                </Typography>
              </Grid>
              <Grid
                xs={12}
                item
                style={{
                  marginTop: "18.5px"
                }}
              >
                <TypeAhead
                  suggestions={{
                    estados: [
                      {
                        value: "PENDIENTE_RESPUESTA_1",
                        label: "Pendiente aprobación 1"
                      },
                      {
                        value: "PENDIENTE_RESPUESTA_2",
                        label: "Pendiente aprobación 2"
                      },
                      {
                        value: "PENDIENTE_RESPUESTA_3",
                        label: "Pendiente aprobación 3"
                      },
                      {
                        value: "PENDIENTE_RESPUESTA_RRHH",
                        label: "Pendiente aprobación RRHH"
                      },
                      {
                        value: "PENDIENTE_AVISO_RRHH",
                        label: "Pendiente de aviso RRHH"
                      },
                      {
                        value: "PENDIENTE_AVISO",
                        label: "Pendiente de aviso"
                      },
                      {
                        value: "APROBADA",
                        label: "Aprobada"
                      },
                      {
                        value: "RECHAZADA",
                        label: "Rechazada"
                      },
                      {
                        value: "EN_EDICION",
                        label: "En Edicion"
                      }
                    ]
                  }}
                  path={{ path: "estados", value: "value", label: "label" }}
                  onChange={this.handleChangeMulti("estadoDinamico")}
                  value={value["estadoDinamico"]}
                  withTodos
                  variant={"outlined"}
                  classes={{
                    ...classes,
                    valueContainer: classes.multiValueContainer
                  }}
                  placeholder={" "}
                  multiple={true}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withRouter(withStyles(styles)(advancedFilter));
