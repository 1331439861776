import React from "react";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import { CatalogoContext } from "../../catalogo-context";
import { lowerCase } from "lodash";
import { _t } from "js/utils/TranslationService";

function FilterPoints(props) {
  const { classes } = props;
  return (
    <div className={classes.puntos}>
      <CatalogoContext.Consumer>
        {({ filterBy, searchCriteria }) => (
          <Grid item container spacing={8} className={classes.root}>
            <Grid
              style={{
                minWidth: "50%"
              }}
              item
              xs={6}
            >
              <InputBase
                className={classes.filterPoints}
                placeholder={`${_t("Mín.")} ${lowerCase(_t("Puntos"))}`}
                type="number"
                fullWidth
                classes={{
                  root: classes.input
                }}
                value={searchCriteria.catalogoRange.puntos.min || ""}
                onChange={e =>
                  filterBy.filterByPoints({
                    from: e.target.value,
                    to: undefined
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputBase
                className={classes.filterPoints}
                placeholder={`${_t("Máx.")} ${lowerCase(_t("Puntos"))}`}
                type="number"
                fullWidth
                classes={{
                  root: classes.input
                }}
                value={searchCriteria.catalogoRange.puntos.max || ""}
                onChange={e =>
                  filterBy.filterByPoints({
                    from: undefined,
                    to: e.target.value
                  })
                }
              />
            </Grid>
          </Grid>
        )}
      </CatalogoContext.Consumer>
    </div>
  );
}

FilterPoints.propTypes = {};

export default FilterPoints;
