import React, { PureComponent } from "react";
import AppQraphQL from "js/graphql/resolvers/rol.resolver";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import AddIconIcon from "@material-ui/icons/Add";
import List from "js/pages/Admin/Programa/Roles/List";
import ErrorList from "js/components/Shared/ErrorList";

class Roles extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: [],
      nombre: "",
      descripcion: "",
      errors: null
    };
  }

  getRoles() {
    const {
      client,
      match: {
        params: { id }
      }
    } = this.props;
    return client
      .query({
        query: AppQraphQL.queries.getRoles,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: parseInt(id) || id,
          getAll: true
        }
      })
      .then(res => {
        res.data.getRoles.length > 0 &&
          this.setState({ initialValues: res.data.getRoles });
      });
  }

  async componentDidMount() {
    await this.getRoles();
  }

  async handleSave(idRol, nombre, descripcion, nivel, estado) {
    const { client } = this.props;
    await client
      .mutate({
        mutation: AppQraphQL.queries.updateRol,
        variables: {
          rol: {
            idRol: parseInt(idRol) || idRol,
            nombre,
            descripcion,
            nivel: parseInt(nivel) || nivel,
            estado
          }
        }
      })
      .then(() => {
        this.getRoles();
      })
      .catch(e => {
        this.setState({ errors: e });
      });
  }

  handleChangeRolNombre = event => {
    this.setState({ nombre: event.target.value });
  };

  handleChangeRolDescripcion = event => {
    this.setState({ descripcion: event.target.value });
  };
  handleChangeRolNivel = event => {
    this.setState({ nivel: event.target.value });
  };

  async createRol() {
    const {
      client,
      match: {
        params: { id }
      }
    } = this.props;
    const { nombre, descripcion, nivel } = this.state;
    if (nombre === "Default" || nombre === "") return null;
    await client
      .mutate({
        mutation: AppQraphQL.queries.createRol,
        variables: {
          rol: {
            nombre: nombre,
            descripcion: descripcion,
            nivel: parseInt(nivel) || nivel,
            idPrograma: parseInt(id) || id
          }
        }
      })
      .then(() => {
        this.getRoles();
        const message = `Rol grabado correctamente`;
        this.props.openNotification(message);
      })
      .catch(e => {
        // const message = `El rol no pudo ser creado correctamente`;
        // this.props.openNotification(message);
        this.setState({ errors: e });
      });
  }

  render() {
    const { initialValues, errors } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Typography variant="h5" gutterBottom>
          Roles
        </Typography>
        <div className={classes.addInput}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="name-simple">Nombre del rol</InputLabel>
            <Input
              id="rol"
              value={this.state.nombre}
              onChange={this.handleChangeRolNombre.bind(this)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="descripcion-simple">Descripción</InputLabel>
            <Input
              id="descripcion"
              value={this.state.descripcion}
              onChange={this.handleChangeRolDescripcion.bind(this)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="descripcion-simple">Nivel</InputLabel>
            <Input
              id="nivel"
              value={this.state.nivel}
              type="number"
              onChange={this.handleChangeRolNivel.bind(this)}
            />
          </FormControl>
          <IconButton onClick={this.createRol.bind(this)}>
            <AddIconIcon />
          </IconButton>
        </div>
        <ErrorList errors={errors} />
        {initialValues && (
          <List
            initialValues={initialValues}
            onHandleSave={this.handleSave.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default Roles;
