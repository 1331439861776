import produce from "immer";
import {
  CAMBIAR_CANTIDAD,
  INCREMENTAR_CANTIDAD,
  DECREMENTAR_CANTIDAD,
  CANJEAR_FAIL,
  CANJEAR_SUCCESS,
  CANJEAR,
  CHANGE_REQUERIDO_STRING,
  CHANGE_REQUERIDO,
  CHANGE_SUCURSAL,
  CLEAN_PRODUCTO_STATE,
  CLOSE_DIALOG_CANJE,
  CLOSE_NOTIFICATION,
  LOAD_PRODUCTO_STATE,
  OPEN_DIALOG_CANJE,
  REQUIRE_DATA_USER,
  SET_REQUERIDOS,
  TOGGLE_FAVORITO_ERROR,
  TOGGLE_FAVORITO_SUCCESS,
  TOGGLE_FAVORITO,
  UPDATE_STORE_DATOS_PERFIL
} from "./constants";
import getInitialState from "./initialState";

export default (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_PRODUCTO_STATE:
        return { ...getInitialState(action.eleccionProducto) };
      case CLEAN_PRODUCTO_STATE:
        return {};
      case CANJEAR:
        console.log(action);
        break;
      case CANJEAR_SUCCESS:
        draft.idCanje = action.idCanje;
        break;
      case CANJEAR_FAIL:
        draft.canjeFail = action.error;
        break;
      case UPDATE_STORE_DATOS_PERFIL:
        draft.datosPerfil = action.data;
        break;
      case REQUIRE_DATA_USER:
        draft.mensajeRequeridos = "Debés completar todos los datos requeridos";
        break;
      case CHANGE_REQUERIDO_STRING:
        draft.mensajeRequeridos = "";
        draft.requeridos = {
          ...draft.requeridos,
          ...action.data
        };
        break;
      case SET_REQUERIDOS:
        draft.mensajeRequeridos = "";
        draft.requeridos = {
          ...draft.requeridos,
          ...action.data
        };
        break;
      case CHANGE_REQUERIDO:
        draft.mensajeRequeridos = "";
        draft.requeridos = {
          ...draft.requeridos,
          ...action.data
        };
        break;
      case CHANGE_SUCURSAL:
        draft.mensajeRequeridos = "";
        draft.requeridos.sucursal = action.idSucursal;
        draft.idSucursal = action.idSucursal;
        break;
      case OPEN_DIALOG_CANJE:
        draft.openCanjePopup = true;
        break;
      case CLOSE_DIALOG_CANJE:
        draft.canjeFail = "";
        draft.mensajeRequeridos = "";
        draft.openCanjePopup = false;
        break;
      case CAMBIAR_CANTIDAD:
        draft.cantidad =
          parseInt(action.cantidad || 0) < 1 ? 1 : parseInt(action.cantidad);
        break;
      case DECREMENTAR_CANTIDAD:
        const decCant = draft.cantidad - 1;
        draft.cantidad = decCant < 1 ? 1 : decCant;
        break;
      case INCREMENTAR_CANTIDAD:
        const incCant = draft.cantidad + 1;
        draft.cantidad = incCant < 1 ? 1 : incCant;
        break;
      case TOGGLE_FAVORITO_ERROR:
        draft.favorito = !draft.favorito;
        draft.mensaje = action.mensaje;
        break;
      case TOGGLE_FAVORITO_SUCCESS:
        draft.favorito = action.favorito;
        draft.mensaje = action.mensaje;
        break;
      case TOGGLE_FAVORITO:
        draft.notificationIsOpen = true;
        draft.favorito = !draft.favorito;
        break;
      case CLOSE_NOTIFICATION:
        draft.notificationIsOpen = false;

        break;
      default:
        return state;
    }
  });
