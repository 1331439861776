import React from "react";
import DialogCustom from "js/components/Dialog";
import DialogSolicitud from "../DialogSolicitud";
import { _t } from "js/utils/TranslationService";

class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      solicitando: false
    };
  }

  render() {
    const {
      openSolicitudPopup,
      beneficioActions,
      idBeneficio,
      cantidad,
      history,
      solicitudFail,
      classes
    } = this.props;
    const self = this;
    return (
      <DialogCustom
        open={openSolicitudPopup}
        title=""
        classes={classes}
        content={<DialogSolicitud />}
        cancelAction={beneficioActions.cerrarSolicitudPopup}
        cancelText="Cancelar"
        okAction={
          this.state.solicitando
            ? () => console.log("Wait...")
            : beneficioActions.requestSolicitud(
                {
                  idBeneficio: idBeneficio,
                  cantidad,
                  self
                },
                history
              )
        }
        okText={
          this.state.solicitando
            ? "Solicitando.."
            : solicitudFail
              ? ""
              : "Solicitar"
        }
        errorMessage={solicitudFail ? _t(solicitudFail) : null}
      />
    );
  }
}

export default Dialog;
