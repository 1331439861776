import React, { PureComponent } from "react";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography/Typography";
import IndicadoresGraphQL from "js/graphql/resolvers/indicadores.resolver.js";
import { numberWithCommas } from "js/utils";

class ReporteIndicadores extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      indicadores: {}
    };
    const { filter, client } = props;
    this.updateIndicadores(client, filter);
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.filter !== nextProps.filter) {
      const { filter, client } = nextProps;
      this.updateIndicadores(client, filter);
    }
  };

  updateIndicadores(client, filter) {
    client
      .query({
        query: IndicadoresGraphQL.queries.getIndicadores,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: filter.idPrograma,
          idSegmento: filter.idSegmento,
          fechaDesde: filter.fechaDesde,
          fechaHasta: filter.fechaHasta
        }
      })
      .then(({ data }) => {
        if (data) {
          this.setState({
            indicadores: data.getIndicadores
          });
        }
      });
  }

  render() {
    const { classes } = this.props;
    const { indicadores } = this.state;
    return (
      <Grid xs={12} spacing={16} item container>
        {this.getCard(
          {
            label: "Tasa de redención",
            value: "tasaRedencion",
            decimales: 2,
            postValue: "%"
          },
          indicadores,
          classes
        )}
        {this.getCard(
          {
            label: "Tiempo de entrega promedio",
            value: "tiempoEntregaPromedio",
            decimales: 2
          },
          indicadores,
          classes
        )}
        {this.getCard(
          {
            label: "Saldo puntos cuenta corriente programa",
            value: "saldoCCPrograma",
            decimales: 2
          },
          indicadores,
          classes
        )}
        {this.getCard(
          {
            label: "Saldo puntos cuenta corriente usuarios",
            value: "saldoCCUsuarios",
            decimales: 2
          },
          indicadores,
          classes
        )}
        {this.getCard(
          {
            label: "Saldo $ cuenta corriente programa",
            value: "saldoPlataCCPrograma",
            decimales: 2
          },
          indicadores,
          classes
        )}
        {this.getCard(
          {
            label: "Saldo $ cuenta corriente usuarios",
            value: "saldoPlataCCUsuarios",
            decimales: 2
          },
          indicadores,
          classes
        )}
      </Grid>
    );
  }

  getCard({ label, value, decimales, postValue = "" }, indicadores, classes) {
    const { programa } = this.props;
    return programa &&
      programa.indicadores &&
      programa.indicadores.find(
        ({ kpi, estado }) => kpi === label && estado === "ACTIVO"
      ) ? (
      <Grid
        xs={2}
        item
        container
        alignItems={"start"}
        justify={"center"}
        alignContent={"start"}
        direction={"row"}
      >
        <Paper elevation={0} className={classes.card}>
          <Typography classes={{ root: classes.value }}>
            {numberWithCommas(indicadores[value], decimales, true) || 0}
            {postValue}
          </Typography>
          <Typography classes={{ root: classes.label }}>{label}</Typography>
        </Paper>
      </Grid>
    ) : (
      ""
    );
  }
}
export default ReporteIndicadores;
