import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import AuthService from "./AuthService";
import {
  addActiveSeconds,
  addPasiveSeconds
} from "../redux/timer/timer.actions";
import { apiURL } from "js/constants.js";

export const sendTrackedSession = (timer, reason) => {
  const session = JSON.parse(localStorage.getItem("sessionTracking"));
  if (!session) return;

  session.reason = reason;
  session.loggedAt = moment(AuthService.getLoginTime())
    .utc()
    .toISOString(); // moment(new Date(session.loggedAt));
  session.signOffAt = moment(new Date());
  session.activa = timer.activa;
  session.pasiva = timer.pasiva;

  const headers = { type: "application/json" };
  const body = {
    operationName: "trackSession",
    query: `mutation trackSession($idPrograma: Int, $session: SessionArrayTracking) {
            trackSession(idPrograma: $idPrograma, session: $session)
          }`,
    variables: {
      idPrograma: AuthService.getIdPrograma(),
      session: { ...session }
    }
  };

  const blob = new Blob([JSON.stringify(body)], headers);
  return navigator.sendBeacon(apiURL + "graphql", blob);
};

class TrackEventService extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      session: {
        loggedAt: "",
        signOffAt: "",
        reason: "", // LOGOUT || CLOSE
        activa: 0,
        pasiva: 0
      },
      startTrackingTime: moment(new Date()),
      timer: {},
      dispatch: this.props.dispatch,
      isVisibilityListening: false
    };
  }

  componentWillReceiveProps = nextProps => {
    // Reset tracking time in every visibility change
    this.setState({ startTrackingTime: moment(new Date()) });
  };

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this);
    window.removeEventListener("beforeunload", this);
    document.removeEventListener("logout", this);
  }

  componentDidMount() {
    this.setState({ isVisibilityListening: true });
    localStorage.setItem("sessionTracking", JSON.stringify(this.state.session));

    setTimeout(() => {
      // Si no está logueado no trackea
      if (!AuthService.getLoginTime()) return;

      this.setState({
        session: {
          ...this.state.session,
          loggedAt: AuthService.getLoginTime()
        }
      });
    }, 500);
  }

  render() {
    const send = reason => {
      return sendTrackedSession(this.props.timer, reason);
    };

    const addSeconds = () => {
      if (!AuthService.getLoginTime()) return;

      const duration = moment
        .duration(moment(new Date()).diff(this.state.startTrackingTime))
        .asSeconds(); //.toFixed(5)

      // Entra nuevamente a la pagina: suma los segundos que estuvo pasivo
      if (document.visibilityState === "visible")
        this.props.dispatch(addPasiveSeconds(duration));
      // Sale de la pagina: suma los segundos que estuvo activo
      else if (document.visibilityState === "hidden")
        this.props.dispatch(addActiveSeconds(duration));
    };

    if (!this.state.isVisibilityListening) {
      document.addEventListener("visibilitychange", addSeconds);
      window.addEventListener("beforeunload", () => {
        send("CLOSE");
      });
      document.addEventListener("logout", () => {
        send("LOGOUT");
      });
    }
    return <></>;
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrackEventService);

/**
 * TRACKING TIME MOUSE EVENTS
 */
// ADD SECONDS:
//  const lastTrack = this.state.lastTrack;

//  if (checkLastTrack) {
//    if (lastTrack === "ACTIVE") {
//      return this.props.dispatch(addActiveSeconds(duration));
//    } else {
//      return this.props.dispatch(addPasiveSeconds(duration));
//    }
//  }

// const activityTime = () => {
//   let timerActive;
//   window.onload = resetTimer;
//   // DOM Events
//   document.onmousemove = resetTimer;
//   document.onkeydown = resetTimer;
//   function resetTimer() {
//     // console.log('resetTimer');
//     clearTimeout(timerActive);
//     timerActive = setTimeout(() => {
//       // addSeconds(true);
//     }, 2000);
//   }
// };

// const setLastTrackAndAddSeconds = (state, inactiveTime = null) => {
//   console.log('state', state);
//   if (state === "ACTIVE") { //
//     console.log('DENTRO DE ACTIVE');
//     return this.setState({ lastTrack: "ACTIVE" });
//   } else {
//     // if (this.state.lastTrack === "ACTIVE") {
//     console.log('DENTRO DE INACTIVE');
//     this.setState({ lastTrack: "INACTIVE" });
//   }
//   addSeconds(true, inactiveTime);
// }

// let timerNotMoving;
// let isMoving = true;
// let timerMoving;
// const startTrackingTime = this.state.startTrackingTime;
// let inactiveTime;
// let coords = {}

// const mouseTrack = (e) => {
//   if (isMoving) {
//     clearTimeout(timerMoving);
//     timerMoving = setTimeout(() => {
//       setLastTrackAndAddSeconds("ACTIVE");
//       isMoving = false;
//       coords = { x: e.offsetX }
//     }, 2000);

//   } else {
//     clearTimeout(timerNotMoving);
//     timerNotMoving = setTimeout(() => {
//       if (coords.x === e.offsetX)
//       inactiveTime = moment
//         .duration(moment(new Date()).diff(startTrackingTime))
//         .asSeconds();
//       setLastTrackAndAddSeconds("INACTIVE", inactiveTime);
//       isMoving = true;
//     }, 2000);
//   }
// }

// window.addEventListener("mousemove", mouseTrack);
// activityTime();
