import React, { PureComponent, Fragment } from "react";
import Form from "js/pages/Admin/Programa/CatalogoCodigos/Edit/MyForm.js";
import BeneficioGraphQL from "js/graphql/resolvers/catalogoCodigos.resolver";
import LocacionGraphQL from "js/graphql/resolvers/locacion.resolver";
import SegmentoGraphQL from "js/graphql/resolvers/segmento.resolver";
import RubroGraphQL from "js/graphql/resolvers/rubro.resolver";
import { addImageURL } from "js/constants";
import { getUrl } from "js/utils";
import { addChecked, filter } from "js/pages/Admin/Programa/Catalogo/utils.js";
import Dialog from "js/pages/Admin/Programa/CatalogoCodigos/Edit/TipoBeneficioDialog.js";
import { isEmpty } from "lodash";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        estado: "ACTIVO"
      },
      sucursales: [],
      tipos: [],
      paises: [],
      rubros: [],
      segmentos: [],
      paisesSeleccionados: [],
      rubrosSeleccionados: [],
      segmentosSeleccionados: [],
      logo: [],
      imagenes: [],
      fechaValidezDesde: null,
      fechaValidezHasta: null,
      fechaVencimiento: null,
      disabled: false,
      errors: null,
      open: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      disabled:
        nextProps.match.params.edit !== "edit" &&
        nextProps.match.params.idBeneficio !== "new"
    });
  }

  async fetchData() {
    const { client } = this.props;
    const {
      match: {
        params: { id }
      }
    } = this.props;

    await client
      .query({
        query: LocacionGraphQL.queries.getLocacionesInput,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: id
        }
      })
      .then(res => {
        const paises = filter(res.data.getPaises || []);
        this.setState({ paises });
      });

    await client
      .query({
        query: SegmentoGraphQL.queries.getSegmentosInput,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: id
        }
      })
      .then(res => {
        const segmentos = filter(res.data.getSegmentos || []);
        this.setState({ segmentos });
      });

    await client
      .query({
        query: RubroGraphQL.queries.getRubrosInput,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: id
        }
      })
      .then(res => {
        const rubros = filter(res.data.getRubros || []);
        this.setState({ rubros });
      });
  }

  async componentDidMount() {
    const { client } = this.props;
    const {
      match: {
        params: { idBeneficio, id, edit }
      }
    } = this.props;

    if (idBeneficio !== "new") {
      await client
        .query({
          query: BeneficioGraphQL.queries.getCatalogoCodigosById,
          fetchPolicy: "network-only",
          variables: {
            idBeneficio: idBeneficio,
            idPrograma: id
          }
        })
        .then(res => {
          const page = res.data.getCatalogoCodigos.itemsPagina[0];
          const paises = filter(res.data.getPaises || []);
          const rubros = filter(res.data.getRubros || []);
          const segmentos = filter(res.data.getSegmentos || []);
          const paisesSeleccionados = filter(page.paises || []);
          const zonasSeleccionadas = filter(page.zonas || []);
          const localidadesSeleccionadas = filter(page.localidades || []);
          const rubrosSeleccionados = filter(page.rubros || []);
          const subRubrosSeleccionados = filter(page.subrubros || []);
          const segmentosSeleccionados = filter(page.segmentos || []);

          paisesSeleccionados.map(item =>
            addChecked(paises, "idPais", item.idPais)
          );
          zonasSeleccionadas.map(item =>
            addChecked(paises, "idZona", item.idZona)
          );
          localidadesSeleccionadas.map(item =>
            addChecked(paises, "idLocalidad", item.idLocalidad)
          );
          rubrosSeleccionados.map(item =>
            addChecked(rubros, "idRubro", item.idRubro)
          );
          subRubrosSeleccionados.map(item =>
            addChecked(rubros, "idSubrubro", item.idSubrubro)
          );
          segmentosSeleccionados.map(item =>
            addChecked(segmentos, "idSegmento", item.idSegmento)
          );

          this.setState({
            initialValues: {
              ...page,
              sucursales: page.sucursales
                ? page.sucursales.map(s => s._id)
                : [],
              idTipo: page.idTipo ? page.idTipo._id : null
            },
            paises,
            rubros,
            segmentos,
            paisesSeleccionados: paisesSeleccionados
              .concat(zonasSeleccionadas)
              .concat(localidadesSeleccionadas),
            rubrosSeleccionados: rubrosSeleccionados.concat(
              subRubrosSeleccionados
            ),
            segmentosSeleccionados,
            imagenes: page.imagenes,
            logo: [page.logo],
            fechaValidezDesde: page.fechaValidezDesde,
            fechaValidezHasta: page.fechaValidezHasta,
            fechaVencimiento: page.fechaVencimiento,
            disabled: !(edit === "edit")
          });
        });
    } else {
      this.fetchData();
    }

    await client
      .query({
        query: BeneficioGraphQL.queries.getCatalogoCodigosTipos,
        fetchPolicy: "network-only"
      })
      .then(res => {
        this.setState({
          tipos: res.data.getCatalogoCodigosTipos
            ? res.data.getCatalogoCodigosTipos
            : []
        });
      });
  }

  async submit(values) {
    const {
      client,
      history,
      match: {
        params: { idBeneficio, id }
      }
    } = this.props;

    let paises = [];
    let zonas = [];
    let localidades = [];
    let rubros = [];
    let subrubros = [];
    let segmentos = [];
    let e = [];

    const {
      segmentosSeleccionados,
      rubrosSeleccionados,
      paisesSeleccionados,
      imagenes,
      logo,
      fechaValidezDesde,
      fechaValidezHasta,
      fechaVencimiento
    } = this.state;

    if (
      isEmpty(segmentosSeleccionados) ||
      isEmpty(rubrosSeleccionados) ||
      isEmpty(paisesSeleccionados)
    ) {
      e.push({
        message:
          "Segmentos, Categoría/Subcategoría y País/Zona/Localidad no pueden estar vacíos."
      });
      this.setState({ errors: e });
      return false;
    }

    if (logo.length === 0) {
      e.push({ message: "Tiene que asignar un logo para el beneficio" });
      this.setState({ errors: e });
      return false;
    }

    if (imagenes.length === 0) {
      e.push({
        message: "Tiene que asignar al menos una imagen para el beneficio"
      });
      this.setState({ errors: e });
      return false;
    }

    paisesSeleccionados &&
      paisesSeleccionados.map(item => {
        item.idPais && paises.push(item.idPais);
        item.idZona && zonas.push(item.idZona);
        item.idLocalidad && localidades.push(item.idLocalidad);
        return true;
      });
    rubrosSeleccionados &&
      rubrosSeleccionados.map(item => {
        item.idRubro && rubros.push(item.idRubro);
        item.idSubrubro && subrubros.push(item.idSubrubro);
        return true;
      });
    segmentosSeleccionados &&
      segmentosSeleccionados.map(
        item => item.idSegmento && segmentos.push(item.idSegmento)
      );

    const variables = {
      idPrograma: parseInt(id, 10),
      nombre: values.nombre,
      codigo: values.codigo,
      monto: values.monto,
      copete: values.copete,
      descripcion: values.descripcion,
      estado: values.estado,
      stockInfinito: values.stockInfinito ? values.stockInfinito : false,
      stock: values.stock,
      stockSeguridad: values.stockSeguridad,
      idTipo: values.idTipo,
      tipoValidez: values.tipoValidez,
      diasValidez: values.diasValidez,
      fechaValidezDesde: fechaValidezDesde,
      fechaValidezHasta: fechaValidezHasta,
      fechaVencimiento: fechaVencimiento,
      tieneSucursales: values.tieneSucursales,
      cucarda: values.cucarda,
      paginaWeb: values.paginaWeb,
      condiciones: values.condiciones,
      emailProveedor: values.emailProveedor,
      segmentos: segmentos,
      rubros: rubros,
      subrubros: subrubros,
      paises: paises,
      zonas: zonas,
      localidades: localidades,
      logo: logo[0],
      imagenes: imagenes
    };

    await client
      .mutate({
        mutation:
          idBeneficio === "new"
            ? BeneficioGraphQL.mutations.createCatalogoCodigos
            : BeneficioGraphQL.mutations.updateCatalogoCodigos,
        variables: {
          beneficio:
            idBeneficio === "new"
              ? { ...variables }
              : {
                  ...variables,
                  idBeneficio: idBeneficio
                }
        }
      })
      .then(res => {
        const mensaje =
          "Beneficio " +
          (idBeneficio === "new" ? "creado" : "modificado") +
          " correctamente";
        this.props.openNotification(mensaje);
        history.push(`/admin/programa/${id}/catalogoCodigos`);
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  onChangeImage = image => {
    const formData = new window.FormData();
    formData.append("image", image);
    formData.append("context", "beneficio");
    formData.append("height", 556);
    formData.append("width", 556);
    fetch(addImageURL, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(({ url }) => {
        this.setState(({ imagenes }) => ({
          imagenes: imagenes.concat(getUrl(url))
        }));
      })
      .catch(() => console.error("Hubo un error al cargar la imagen a S3"));
  };

  onChangeLogo = image => {
    const formData = new window.FormData();
    formData.append("image", image);
    formData.append("context", "beneficio");
    formData.append("height", 556);
    formData.append("width", 556);
    fetch(addImageURL, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(({ url }) => {
        this.setState({
          logo: [getUrl(url)]
        });
      })
      .catch(() => console.error("Hubo un error al cargar la imagen a S3"));
  };

  onRemoveImage = urlToRemove => {
    this.setState(({ imagenes }) => ({
      imagenes: imagenes.filter(url => url !== urlToRemove)
    }));
  };

  onRemoveLogo = urlToRemove => {
    this.setState({
      logo: []
    });
  };

  handleDateChange = key => date => {
    this.setState({
      [key]: date.toISOString()
    });
  };

  onChangePais = paisesSeleccionados => {
    this.setState({ ...paisesSeleccionados });
  };

  onChangeRubro = rubrosSeleccionados => {
    this.setState({ ...rubrosSeleccionados });
  };

  onChangeSegmento = segmentosSeleccionados => {
    this.setState({ ...segmentosSeleccionados });
  };

  openDialog() {
    this.setState({
      open: true
    });
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  async submitDialog(values) {
    const { client } = this.props;

    await client
      .mutate({
        mutation: BeneficioGraphQL.mutations.createCatalogoCodigosTipo,
        variables: {
          tipo: {
            ...values
          }
        }
      })
      .then(res => {
        const mensaje = "Tipo creado y agregado corrrectamente";
        const tipo = res.data.createCatalogoCodigosTipo;
        const { tipos, initialValues } = this.state;
        tipos.push({ value: tipo._id, label: tipo.tipo });
        this.setState({
          tipos,
          open: false,
          initialValues: {
            ...initialValues,
            tipo: tipo._id
          }
        });
        this.props.openNotification(mensaje);
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          dialogErrors:
            errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  render() {
    const {
      match: {
        params: { idBeneficio, id }
      }
    } = this.props;
    return (
      <Fragment>
        <Form
          id={id}
          idBeneficio={idBeneficio}
          errors={this.state.errors}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
          handleChangeFecha={this.handleDateChange}
          sucursales={this.state.sucursales}
          tipos={this.state.tipos}
          openDialog={this.openDialog.bind(this)}
          logo={this.state.logo}
          onChangeLogo={this.onChangeLogo.bind(this)}
          onRemoveLogo={this.onRemoveLogo.bind(this)}
          imagenes={this.state.imagenes}
          onChangeImage={this.onChangeImage.bind(this)}
          onRemoveImage={this.onRemoveImage.bind(this)}
          paises={this.state.paises}
          onChangePais={this.onChangePais.bind(this)}
          rubros={this.state.rubros}
          onChangeRubro={this.onChangeRubro.bind(this)}
          segmentos={this.state.segmentos}
          onChangeSegmento={this.onChangeSegmento.bind(this)}
        />
        <Dialog
          onSubmit={this.submitDialog.bind(this)}
          errors={this.state.dialogErrors}
          open={this.state.open}
          handleClose={this.handleClose.bind(this)}
        />
      </Fragment>
    );
  }
}

export default Edit;
