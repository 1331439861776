import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  buttonContainer: {
    textAlign: "right"
  },
  listContainer: {
    backgroundColor: "#EAEAEA",
    marginTop: "20px",
    "& li:nth-child(odd)": {
      backgroundColor: "#F6F6F6"
    }
  },
  secondaryAction: {
    right: "60px"
  },
  itemText: {
    "& span": {
      color: "#7A7A7A",
      fontWeight: 600
    }
  }
});

export default withStyles(styles);
