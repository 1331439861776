import React, { PureComponent } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import AppQraphQL from "js/graphql/resolvers/rubro.resolver";
import { ListofChildrenField } from "./index.js";

class RubroField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rubros: []
    };
  }

  async componentDidMount() {
    const { client } = this.props;

    await client
      .query({
        query: AppQraphQL.queries.getMasterRubros,
        fetchPolicy: "cache-first",
        errorPolicy: "all"
      })
      .then(res => {
        this.setState({
          rubros: res.data.getMasterRubros
        });
      });
  }

  render() {
    const { required, disabled } = this.props;
    const { rubros } = this.state;

    return (
      <ListofChildrenField
        name="idRubro"
        label="Categoría"
        disabled={disabled}
        required={required}
      >
        {rubros.map((item, index) => (
          <MenuItem value={parseInt(item.idRubro, 10)} key={index}>
            {item.descripcion}
          </MenuItem>
        ))}
      </ListofChildrenField>
    );
  }
}

export default withApollo(withRouter(RubroField));
