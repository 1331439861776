import { withStyles } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import Condiciones from "./Condiciones";
import ProgramQraphQL from "js/graphql/resolvers/programa.resolver";
import styles from "./styles";
import { url } from "js/constants";
import { compose } from "recompose";

const enhance = compose(
  withStyles(styles),
  graphql(ProgramQraphQL.queries.getProgramaLegales, {
    options: () => ({
      fetchPolicy: "network-only",
      variables: {
        url
      }
    }),
    props: ({ data: { loading, ...data } }) => {
      if (loading) return;
      const { getProgramaPublicInfo } = data;
      return {
        condiciones: getProgramaPublicInfo.condiciones
      };
    }
  })
);
export default enhance(Condiciones);
