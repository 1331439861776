import FilterService from "js/utils/FilterService";
const FILTER_CATALOGO = "UI_CATALOGO";
const initialSearchCriteria = (fromFilter = false, searchQuery = {}) => {
  const initialFilter = {
    searchBy: undefined,
    favoritos: false,
    catalogoOrderMultiple: [
      {
        column: "prioridad",
        order: "asc"
      },
      {
        column: "producto_nombre",
        order: "asc"
      }
    ],
    catalogoRange: {
      puntos: { min: undefined, max: undefined }
    },
    filterByCategories: new Map([
      ["rubros_idRubro", new Set()],
      ["subrubros_idSubrubro", new Set()],
      ["zonas_idZona", new Set()],
      ["localidades_idLocalidad", new Set()]
    ]),
    ...searchQuery
  };
  if (!fromFilter) return initialFilter;
  const lastFilter = getLastFilter();
  return { ...initialFilter, ...lastFilter };
};

const getLastFilter = () => {
  let filter = FilterService.getFilter(FILTER_CATALOGO) || {};
  filter.filterByCategories = filter.filterByCategories.map(f => [
    f.key,
    new Set(f.value)
  ]);
  filter.filterByCategories = new Map(filter.filterByCategories);
  return filter;
};

const updateSearchCriteria = (searchCriteria = {}) => {
  let { filterByCategories } = searchCriteria;
  filterByCategories = [...filterByCategories];
  filterByCategories = filterByCategories.map(f => ({
    key: f[0],
    value: [...f[1]]
  }));
  const filter = {
    ...searchCriteria,
    filterByCategories
  };
  FilterService.setFilter(FILTER_CATALOGO, filter);
};

const updateFilterByCategories = (filterByCategories, key, id) => {
  if (key && key.includes("rubro"))
    filterByCategories
      .set("rubros_idRubro", new Set([]))
      .set("subrubros_idSubrubro", new Set([]));
  if (key && (key === "zonas_idZona" || key === "localidades_idLocalidad"))
    filterByCategories
      .set("zonas_idZona", new Set([]))
      .set("localidades_idLocalidad", new Set([]));

  return key !== undefined
    ? filterByCategories.set(key, new Set([id]))
    : filterByCategories.clear();
};

const getSelectedCategory = map => {
  const categories = {};
  map.forEach(
    (value, key) => (categories[key] = value.size > 0 ? [...value] : undefined)
  );
  return categories;
};

const setStateFilterPoints = (from, to) => state => {
  const { searchCriteria } = state;
  const { catalogoRange } = searchCriteria;
  return {
    pagina: {
      ...state.pagina,
      numeroPagina: 1
    },
    searchCriteria: {
      ...searchCriteria,

      catalogoRange: {
        puntos: {
          min: from !== undefined ? from : catalogoRange.puntos.min,
          max: to !== undefined ? to : catalogoRange.puntos.max
        }
      }
    }
  };
};

const setStateCatalogoOrder = catalogoOrderMultiple => state => {
  return {
    pagina: {
      ...state.pagina,
      numeroPagina: 1
    },
    searchCriteria: { ...state.searchCriteria, catalogoOrderMultiple }
  };
};

const setStateFilterByCategories = ({ key, id }) => state => ({
  pagina: {
    ...state.pagina,
    numeroPagina: 1
  },
  searchCriteria: {
    ...state.searchCriteria,
    favoritos: false,
    filterByCategory: updateFilterByCategories(
      state.searchCriteria.filterByCategories,
      key,
      id
    )
  }
});

const getQueryObj = (programa, searchCriteria, pagina, isPublic) => ({
  pagina,
  url: window.location.hostname.replace("www.", ""),
  catalogoRange: {
    puntos: {
      min: parseInt(searchCriteria.catalogoRange.puntos.min, 10) || undefined,
      max: parseInt(searchCriteria.catalogoRange.puntos.max, 10) || undefined
    }
  },
  catalogoOrderMultiple: searchCriteria.catalogoOrderMultiple,
  catalogoLike: {
    idPrograma: programa.idPrograma,
    favoritos: searchCriteria.favoritos,
    ...getSelectedCategory(searchCriteria.filterByCategories)
  },
  catalogoLikeOr: {
    producto_nombre: searchCriteria.searchBy,
    producto_copete: searchCriteria.searchBy
  }
});

export default {
  initialSearchCriteria,
  updateSearchCriteria,
  setStateFilterPoints,
  setStateCatalogoOrder,
  setStateFilterByCategories,
  getQueryObj
};
