import React, { PureComponent, Fragment } from "react";
import AppQraphQL from "js/graphql/resolvers/usuario.resolver";
import Form from "js/pages/Admin/Usuario/Edit/MyForm.js";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    const params = {
      id: null,
      idUsuario: null,
      edit: null
    };

    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;
    if (inBackOffice) {
      params.id =
        parseInt(this.props.match.params.id) || this.props.match.params.id;
      params.idUsuario =
        parseInt(this.props.match.params.idUsuario) ||
        this.props.match.params.idUsuario;
      params.edit = this.props.match.params.edit;
    } else {
      params.id = parseInt(localStorage.getItem("idPrograma"));
      params.idUsuario =
        parseInt(this.props.computedMatch.params.idUsuario) ||
        this.props.computedMatch.params.idUsuario;
      params.edit = this.props.computedMatch.params.edit;
    }

    this.state = {
      initialValues: {},
      disabled: null,
      errors: null,
      inBackOffice,
      ...params
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { id, idUsuario, edit } = this.state;

    if (idUsuario !== "new") {
      await client
        .query({
          query: AppQraphQL.queries.getUsuarioById,
          fetchPolicy: "network-only",
          variables: {
            idUsuario: parseInt(idUsuario) || idUsuario
          }
        })
        .then(res =>
          this.setState({
            initialValues: {
              estado: res.data.getUsuarios.itemsPagina[0].estado,
              username: res.data.getUsuarios.itemsPagina[0].username,
              email: res.data.getUsuarios.itemsPagina[0].perfil.email,
              otros: JSON.parse(
                res.data.getUsuarios.itemsPagina[0].perfil.otros
              ),
              idPrograma:
                res.data.getUsuarios.itemsPagina[0].programa.idPrograma,
              idSegmento:
                res.data.getUsuarios.itemsPagina[0].segmento.idSegmento,
              idRol: res.data.getUsuarios.itemsPagina[0].roles[0].idRol,
              ...res.data.getUsuarios.itemsPagina[0]
            },
            asignarPassword: false,
            disabled:
              !(edit === "edit") || !AuthService.hasPerms([Roles.USUARIO_W]),
            showEditPerfil:
              AuthService.hasPerms([Roles.PERFIL_W]) ||
              AuthService.getIdUsuario() === idUsuario
          })
        );
    } else {
      this.setState({
        initialValues: {
          idPrograma: id || null,
          estado: "ACTIVO",
          asignarPassword: false,
          validezPassword: "TEMPORAL"
        }
      });
    }
  }

  componentWillReceiveProps = nextProps => {
    let action = "";
    if (this.state.inBackOffice) {
      const {
        match: {
          params: { edit }
        }
      } = nextProps;
      action = edit;
    } else {
      const {
        computedMatch: {
          params: { edit }
        }
      } = nextProps;
      action = edit;
    }

    this.setState({
      disabled: !(action === "edit") || !AuthService.hasPerms([Roles.USUARIO_W])
    });
  };

  async submit(values) {
    const { client, history } = this.props;
    const { id, idUsuario, inBackOffice } = this.state;

    await client
      .mutate({
        mutation:
          idUsuario === "new"
            ? AppQraphQL.mutations.createUsuario
            : AppQraphQL.mutations.updateUsuario,
        variables: {
          usuario:
            idUsuario === "new"
              ? {
                  idPrograma: parseInt(values.idPrograma) || values.idPrograma,
                  idSegmento: parseInt(values.idSegmento) || values.idSegmento,
                  idIdioma: values.idIdioma,
                  username: values.username,
                  perfil: { otros: JSON.stringify({ email: values.email }) },
                  password: values.password,
                  validezPassword: values.validezPassword,
                  roles: [values.idRol]
                }
              : {
                  idUsuario: parseInt(idUsuario) || idUsuario,
                  idPrograma: parseInt(values.idPrograma) || values.idPrograma,
                  idSegmento: parseInt(values.idSegmento) || values.idSegmento,
                  username: values.username,
                  perfil: {
                    otros: JSON.stringify({
                      ...values.otros,
                      email: values.email
                    })
                  },
                  estado: values.estado,
                  password: values.password,
                  validezPassword: values.validezPassword,
                  roles: [parseInt(values.idRol) || values.idRol]
                },
          from: "BO"
        }
      })
      .then(res => {
        const message = "Usuario grabado correctamente";
        this.props.openNotification(message);
        const idUserUpdate =
          res.data.updateUsuario && res.data.updateUsuario.idUsuario;
        const idUserCreate =
          res.data.createUsuario && res.data.createUsuario.idUsuario;
        const idUser = idUserUpdate || idUserCreate;
        if (inBackOffice) {
          if (id) {
            history.push(
              `/admin/programa/${parseInt(id) || id}/usuario/${parseInt(
                idUser
              ) || idUser}/edit/perfil`
            );
          } else {
            history.push(
              `/admin/usuario/${parseInt(idUser) || idUser}/edit/perfil`
            );
          }
        } else {
          history.push(`/usuarios/${parseInt(idUser) || idUser}/edit/perfil`);
        }
      })
      .catch(e => {
        this.setState({ errors: e });
        return false;
      });
  }
  render() {
    return (
      <Fragment>
        <Form
          id={parseInt(this.state.idPrograma) || this.state.idPrograma}
          idUsuario={parseInt(this.state.idUsuario) || this.state.idUsuario}
          errors={this.state.errors}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
          showEditPerfil={this.state.showEditPerfil}
        />
      </Fragment>
    );
  }
}

export default Edit;
