import gql from "graphql-tag";

export const getRoles = gql`
  query getRoles($idPrograma: Int, $getAll: Boolean) {
    getRoles(rolLike: { idPrograma: $idPrograma, getAll: $getAll }) {
      idRol
      nombre
      descripcion
      tipo
      estado
      nivel
      privilegios {
        idPrivilegio
        codigoPrivilegio
        descripcion
      }
    }
  }
`;

export const getRolesById = gql`
  query getRoles($idRol: Int) {
    getRoles(rolLike: { idRol: $idRol }) {
      idRol
      nombre
      descripcion
      tipo
      nivel
      privilegios {
        idPrivilegio
        codigoPrivilegio
        descripcion
      }
    }
  }
`;

export const createRol = gql`
  mutation createRol($rol: RolInput!) {
    createRol(rol: $rol) {
      idRol
      nombre
      descripcion
      nivel
    }
  }
`;

export const updateRol = gql`
  mutation updateRol($rol: RolUpdate!) {
    updateRol(rol: $rol) {
      idRol
    }
  }
`;

export const getPrivilegios = gql`
  query getPrivilegios($idPrograma: Int) {
    getPrivilegios(idPrograma: $idPrograma) {
      idPrivilegio
      codigoPrivilegio
      grupo
      descripcion
    }
  }
`;

export const getGrupoDePrivilegios = gql`
  query getGrupoDePrivilegios {
    getGrupoDePrivilegios
  }
`;

export const getModulos = gql`
  query getModulos {
    getModulos {
      nombre
      descripcion
    }
  }
`;

export default {
  queries: {
    getRoles,
    getRolesById,
    createRol,
    getPrivilegios,
    getGrupoDePrivilegios,
    getModulos,
    updateRol
  }
};
