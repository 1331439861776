import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import FilterPanel from "./FilterPanel";
import styles from "./styles";
import withWidth from "@material-ui/core/withWidth";

const enhance = compose(
  withStyles(styles),
  withWidth()
);

export default enhance(FilterPanel);
