import React, { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import client from "js/App/client.graphql.js";
import VariablesMedGraphQL from "js/graphql/resolvers/variablesMedUnilever.resolver";
import Slide from "@material-ui/core/Slide";
import { Typography } from "@material-ui/core";
import ErrorList from "js/components/Shared/ErrorList";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogAprobarVariablesMed extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: null
    };
  }

  sendValues = async selected => {
    const { errors, data } = await client.mutate({
      mutation: VariablesMedGraphQL.mutations.aprobarVariablesMeds,
      errorPolicy: "all",
      variables: {
        idsVariablesMed: selected
      }
    });
    if (errors) {
      this.setState({ errors: errors[0] });
      return;
    }
    if (data) {
      this.setState({ errors: null });
      const message = data
        ? "Se aprobaron las variables correctamente y se crearon los targets de los preventistas"
        : "Hubo un error al crear los targets de preventistas, pruebe nuevamente";
      this.props.openNotification(message);
      this.props.onTableChange();
    }
    this.props.handleClose();
  };

  render() {
    const { open, handleClose, classes } = this.props;

    return (
      <Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={classes.dialogTitle}
          >
            Aprobar targets del MED
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Typography variant="h6">
              {`Una vez aprobados los targets no se podrán modificar.`}
              <br />
              {
                "Se calcularán los targets de preventistas según los porcentajes que tienen asignados."
              }
              <br />
              {`Y se enviará un email a todos los supervisores del MED.`}
            </Typography>
            <ErrorList errors={this.state.errors} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Volver
            </Button>

            <Button
              onClick={() => this.sendValues(this.props.selected)}
              variant="contained"
              color="primary"
            >
              Aprobar
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DialogAprobarVariablesMed.defaultProps = {};

DialogAprobarVariablesMed.propTypes = {};

export default DialogAprobarVariablesMed;
