import React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import { plainObject } from "js/utils/Helper";
import ReconocimientosQraphQL from "js/graphql/resolvers/reconocimiento.resolver";
import Typography from "@material-ui/core/Typography/Typography";
import moment from "moment";
import AdvancedFilter from "../TablaReconocimientos/advancedFilter.js";
import Collapse from "js/components/Shared/Collapsable";
import { IndividualIcon, GrupalIcon } from "images/icons";
import { _t } from "js/utils/TranslationService";
import TablePagination from "@material-ui/core/TablePagination/TablePagination";
import ShowMoreText from "react-show-more-text";

export default class ReconocimientosAprobados extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      orderBy: "fechaAprobacion",
      order: "desc",
      page: 1,
      rowsPerPage: 10,
      filter: {
        nominaEquipos: "AMBOS"
      },
      expanded: false,
      resumen: { tipos: [] }
    };
  }

  _fetchData = async ({ order, orderBy, page, rowsPerPage, filter }) => {
    const filtered = plainObject(filter);
    const { client, programa } = this.props;
    const query =
      ReconocimientosQraphQL.queries.getPaginaReconocimientosAprobados;
    await client
      .query({
        query: query,
        fetchPolicy: "network-only",
        variables: {
          order: order || this.state.order,
          orderBy: orderBy || this.state.orderBy,
          page: page || this.state.page,
          rowsPerPage: rowsPerPage || this.state.rowsPerPage,
          idPrograma: programa.idPrograma,
          ...(Object.keys(filtered).length !== 0 ? filtered : this.state.filter)
        }
      })
      .then(res => {
        this.setState({
          data: res.data || this.state.data,
          order: order || this.state.order,
          orderBy: orderBy || this.state.orderBy,
          page: page || this.state.page,
          rowsPerPage: rowsPerPage || this.state.rowsPerPage,
          filter: filter || this.state.filter
        });
      });
    await client
      .query({
        query:
          ReconocimientosQraphQL.queries.getResumenReconocimientosAprobados,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: programa.idPrograma,
          ...(Object.keys(filtered).length !== 0 ? filtered : this.state.filter)
        }
      })
      .then(res => {
        this.setState({
          resumen: res.data.getResumenReconocimientosAprobados
        });
      });
  };

  componentDidMount = () => {
    this._fetchData({});
  };

  clear = () => {
    this.setState({
      filter: {
        nominaEquipos: "AMBOS"
      }
    });
  };

  submitValue = e => {
    const obj = {};
    const objZona = {};
    const objLocalidad = {};
    obj[e.target.name] = e.target.value;
    const paisActual = this.state.filter && this.state.filter.idPais;
    if (e.target.name === "idPais" && e.target.value !== paisActual) {
      objZona["idZona"] = undefined;
      objLocalidad["idLocalidad"] = undefined;
    }
    if (e.target.name === "idZona" && e.target.value === "") {
      objLocalidad["idLocalidad"] = undefined;
    }

    this.setState(({ filter }) => ({
      filter: { ...filter, ...obj, ...objZona, ...objLocalidad }
    }));
  };

  handleChangePage = (event, page) => {
    this._fetchData({ page: parseInt(page, 10) + 1 });
  };

  handleChangeRowsPerPage = event => {
    this._fetchData({ rowsPerPage: event.target.value, page: 1 });
  };

  handleKey = e => {
    if (e.keyCode === 13) this.search();
  };

  search = () => {
    let filter = this.state.filter;
    Object.keys(filter).forEach(key => {
      if (filter[key] === "TODOS") delete filter[key];
    });
    this._fetchData({
      filter: {
        ...filter
      }
    });
    this.setState({ expanded: false });
  };

  handleCollapse = () =>
    this.setState(prevState => ({ expanded: !prevState.expanded }));

  render() {
    const {
      classes,
      programa: { idPrograma },
      formulario
    } = this.props;
    const { data, resumen } = this.state;
    const getReconocimientos =
      data && data.getReconocimientosAprobados
        ? data.getReconocimientosAprobados
        : {};
    return (
      <Grid
        style={{
          margin: "0px",
          width: "100%",
          maxWidth: "100%",
          backgroundColor: "#FFF"
        }}
        xs={12}
        container
        item
        spacing={16}
        direction={"row"}
        alignContent={"center"}
        alignItems={"center"}
        justify={"center"}
      >
        <Grid
          xs={12}
          style={{
            margin: "0px",
            width: "100%",
            maxWidth: "100%"
          }}
          item
          className={classes.fondoTitulo}
        >
          <Typography variant={"h3"} classes={{ root: classes.textoTitulo }}>
            {_t("Reconocimientos aprobados")}
          </Typography>
        </Grid>
        <Grid sm={10} xs={12} item>
          <Collapse
            clear={this.clear.bind(this)}
            search={this.search.bind(this)}
            expanded={this.state.expanded}
            onChange={this.handleCollapse}
            title="Filtros"
          >
            <AdvancedFilter
              value={this.state.filter}
              submitValue={this.submitValue.bind(this)}
              search={this.search.bind(this)}
              idPrograma={idPrograma}
              formulario={formulario}
              classes={classes}
            />
          </Collapse>
        </Grid>
        <Grid sm={10} xs={12} item>
          {this.state.data && this.state.resumen ? (
            <Paper className={classes.resumenCard}>
              <Grid
                xs={12}
                item
                container
                direction={"row"}
                alignContent={"flex-start"}
                alignItems={"flex-start"}
                justify={"flex-start"}
                spacing={16}
                style={{
                  margin: "0px"
                }}
              >
                <Grid item className={classes.containerTotal}>
                  <Typography
                    style={{
                      color: "white"
                    }}
                    classes={{ root: classes.resumenLabel }}
                  >
                    Total:{" "}
                    <Typography
                      style={{
                        color: "white"
                      }}
                      classes={{ root: classes.resumenCantidad }}
                    >
                      {resumen.total}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item className={classes.containerIndividual}>
                  <Typography classes={{ root: classes.resumenLabelColor }}>
                    Individuales:{" "}
                    <Typography
                      classes={{ root: classes.resumenCantidadColor }}
                    >
                      {resumen.individuales}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item className={classes.containerGrupal}>
                  <Typography classes={{ root: classes.resumenLabelColor }}>
                    Grupales:{" "}
                    <Typography
                      classes={{ root: classes.resumenCantidadColor }}
                    >
                      {resumen.grupales}
                    </Typography>
                  </Typography>
                </Grid>
                {resumen.tipos.map(({ label, cantidad }) => (
                  <Grid item>
                    <Typography classes={{ root: classes.resumenLabel }}>
                      {label}:{" "}
                      <Typography classes={{ root: classes.resumenCantidad }}>
                        {cantidad}
                      </Typography>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          ) : (
            ""
          )}
        </Grid>
        <Grid
          xs={12}
          direction={"row"}
          alignContent={"center"}
          alignItems={"center"}
          justify={"center"}
          item
          container
          className={classes.containerPrincipal}
        >
          <Grid
            sm={10}
            xs={12}
            item
            container
            direction={"row"}
            alignItems={"flex-start"}
            justify={"flex-start"}
            spacing={16}
            style={{
              paddingBottom: "20px"
            }}
          >
            {getReconocimientos && getReconocimientos.itemsPagina
              ? getReconocimientos.itemsPagina.map(
                  ({
                    usuarioReconocedor: { perfil, username },
                    _id,
                    usuariosReconocidos,
                    fechaAprobacion,
                    menurrhh,
                    respuesta1,
                    respuesta2,
                    respuesta3,
                    valor
                  }) => (
                    <Grid
                      sm={4}
                      item
                      key={_id}
                      style={{
                        padding: "20px"
                      }}
                    >
                      <Grid xs={12}>
                        <Grid xs={12} style={{ paddingBottom: "5px" }}>
                          <Typography
                            classes={{ root: classes.nombreReconocedor }}
                          >
                            {perfil && perfil.nombre && perfil.apellido
                              ? `${perfil.nombre} ${perfil.apellido}`
                              : username}
                            <Typography
                              classes={{ root: classes.fechaAprobacion }}
                            >
                              {moment.utc(fechaAprobacion).format("DD-MM-YYYY")}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid xs={12} item container className={classes.card}>
                          <Grid
                            xs={12}
                            item
                            className={classes.containerBodyCard}
                          >
                            <Grid
                              xs={12}
                              item
                              style={{ paddingBottom: "10px" }}
                            >
                              <Typography
                                classes={{ root: classes.textoNominados }}
                              >
                                {usuariosReconocidos
                                  .map(
                                    ({ perfil, username }) =>
                                      perfil && perfil.nombre && perfil.apellido
                                        ? `${perfil.nombre} ${perfil.apellido}`
                                        : username
                                  )
                                  .join(" | ")}

                                {usuariosReconocidos.length > 1 ? (
                                  <GrupalIcon
                                    viewBox="5 5 40 40"
                                    classes={{
                                      root: classes.icon
                                    }}
                                  />
                                ) : (
                                  <IndividualIcon
                                    viewBox="5 5 40 40"
                                    classes={{
                                      root: classes.icon
                                    }}
                                  />
                                )}
                              </Typography>
                            </Grid>
                            {formulario.pregunta1 &&
                            formulario.pregunta1.seMuestra ? (
                              <Grid
                                xs={12}
                                item
                                style={{ paddingBottom: "2px" }}
                              >
                                <Typography
                                  classes={{
                                    root: classes.textoInteriorPregunta
                                  }}
                                >
                                  {_t("Acción: ")}
                                  <Typography
                                    classes={{
                                      root: classes.textoInteriorRespuesta
                                    }}
                                  >
                                    {respuesta1}
                                  </Typography>
                                </Typography>
                              </Grid>
                            ) : (
                              ""
                            )}

                            {formulario.pregunta2 &&
                            formulario.pregunta2.seMuestra ? (
                              <Grid
                                xs={12}
                                item
                                style={{
                                  paddingTop: "0px"
                                }}
                              >
                                <Typography
                                  classes={{
                                    root: classes.textoInteriorPregunta
                                  }}
                                >
                                  {_t("Justificación: ")}
                                  <Typography
                                    classes={{
                                      root: classes.textoInteriorRespuesta
                                    }}
                                  >
                                    <ShowMoreText
                                      lines={2}
                                      more={_t("Ver más")}
                                      less={_t("Ver menos")}
                                      expanded={false}
                                    >
                                      {respuesta2}
                                    </ShowMoreText>
                                  </Typography>
                                </Typography>
                              </Grid>
                            ) : (
                              ""
                            )}

                            {formulario.pregunta3 &&
                            formulario.pregunta3.seMuestra ? (
                              <Grid
                                xs={12}
                                item
                                style={{ paddingBottom: "2px" }}
                              >
                                <Typography
                                  classes={{
                                    root: classes.textoInteriorPregunta
                                  }}
                                >
                                  {_t("Valoración: ")}
                                  <Typography
                                    classes={{
                                      root: classes.textoInteriorRespuesta
                                    }}
                                  >
                                    {respuesta3}
                                  </Typography>
                                </Typography>
                              </Grid>
                            ) : (
                              ""
                            )}
                          </Grid>
                          {formulario.valor && formulario.valor.alias ? (
                            <Grid
                              xs={12}
                              item
                              className={classes.containerFooter}
                            >
                              <Typography
                                classes={{ root: classes.textoFooterTitulo }}
                              >
                                {_t("Valor/es:") + " "}
                                <Typography
                                  classes={{
                                    root: classes.textoFooterRespuesta
                                  }}
                                >
                                  {valor.join(", ")}
                                </Typography>
                              </Typography>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                )
              : ""}
          </Grid>
          <Grid xs={12} sm={10} item className={classes.containerPaginador}>
            {this.state.data && (
              <TablePagination
                component="div"
                labelRowsPerPage={_t("Resultados por página")}
                count={parseInt(getReconocimientos.infoPagina.total, 10)}
                rowsPerPage={parseInt(this.state.rowsPerPage, 10)}
                rowsPerPageOptions={[5, 10, 50, 100]}
                page={parseInt(this.state.page, 10) - 1}
                backIconButtonProps={{
                  "aria-label": `${_t("Anterior")}`
                }}
                classes={{
                  root: classes.tablePagination,
                  actions: classes.tablePaginationActions
                }}
                nextIconButtonProps={{
                  "aria-label": `${_t("Siguiente")}`
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
