import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import AuthService from "js/utils/AuthService";
import base from "js/components/Shared/Tabla/ActionCells/Base";
import DialogImportGrupoSKU from "js/pages/Unilever/Dialogs/DialogImportGrupoSKU";

export default params => ({
  ...base(),
  label: "Editar Grupo SKU",
  icon: <EditIcon />,
  tooltip: "Editar Grupo SKU",
  dialogId: "EDIT_GRUPOSKU",
  show: () => AuthService.hasPerms(params.perms),
  dialog: (
    <DialogImportGrupoSKU
      handleClose={params.togglePopUp}
      onTableChange={params.onTableChange}
      idGrupo={params.itemId}
    />
  )
});
