import React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Enhancer from "./container";
import { _t } from "js/utils/TranslationService";

const tabsTitles = {
  signin: { tabOrder: 1, title: "Inicio de sesión" },
  signup: { tabOrder: 2, title: "Registro" },
  restorePassword: { tabOrder: 3, title: "Recuperar clave" },
  restoreUser: { tabOrder: 4, title: "Recuperar usuario" }
};

const sortTabs = ([keyA, { tabOrder: a }], [keyB, { tabOrder: b }]) =>
  (a || tabsTitles[keyA].tabOrder) < (b || tabsTitles[keyB].tabOrder) ? -1 : 1;

class SimpleTabs extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeTabContent = (tabKey, key, index) => event => {
    this.props.onChange([tabKey, key])(event.target.value);
  };

  render() {
    const { classes, tabsConfig, tabContent } = this.props;
    const { value } = this.state;
    const Component = tabContent;

    return (
      <div className={classes.root}>
        <Tabs value={value} onChange={this.handleChange}>
          {tabsConfig.sort(sortTabs).map(([key, tab], index) => (
            <Tab
              classes={{ selected: classes.selected }}
              key={key}
              label={_t(tab.tabTitle || tabsTitles[key].title)}
            />
          ))}
        </Tabs>

        {tabsConfig.map(
          ([key, tab], index) =>
            value === index && (
              <div className={classes.component}>
                <Component
                  key={key}
                  {...tab}
                  tabKey={key}
                  index={index}
                  handleChangeTabContent={this.handleChangeTabContent}
                  classes={classes}
                />
              </div>
            )
        )}
      </div>
    );
  }
}

SimpleTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default Enhancer(SimpleTabs);
