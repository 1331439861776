import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { GeneralWrapper } from "js/pages/TemplateBuilder2/Layouts";
import { SortableComponent } from "js/pages/TemplateBuilder2/Controls";
import Fila from "js/pages/TemplateBuilder2/Components/Fila";
import { getTipo } from "js/pages/TemplateBuilder2/Components/NewItems";
import Enhancer from "./container";

const newBase = getTipo.get("base");

class DestacadosBuilder extends React.Component {
  render() {
    const {
      onGetTemplate,
      infoPrograma,
      filas,
      filasBeneficios,
      onChangeDestacados,
      onChangeDestacadosBeneficios,
      onChangeFilaTipo,
      onChangeFilaTipoBeneficios,
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <GeneralWrapper
        title={`Destacados del programa ${infoPrograma.nombre}`}
        subtitle="Este es el editor de los destacados del catalogo"
        onGetTemplate={onGetTemplate}
      >
        <Paper style={{ marginBottom: "20px" }}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ marginLeft: "80px", paddingTop: "10px" }}
            >
              Productos
            </Typography>
            <SortableComponent
              newItem={newBase}
              isCollapsable={true}
              items={filas}
              component={Fila}
              onChange={() => undefined}
              maxAmountItems={1}
              staticAmountItems={true}
              position={[]}
              metadata={{
                onChange: onChangeDestacados,
                onChangeFilaTipo: onChangeFilaTipo,
                idPrograma: id
              }}
            />
          </Grid>
        </Paper>
        <Paper>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ marginLeft: "80px", paddingTop: "10px" }}
            >
              Beneficios
            </Typography>
            <SortableComponent
              newItem={newBase}
              isCollapsable={true}
              items={filasBeneficios}
              component={Fila}
              onChange={() => undefined}
              maxAmountItems={1}
              staticAmountItems={true}
              position={[]}
              metadata={{
                onChange: onChangeDestacadosBeneficios,
                onChangeFilaTipo: onChangeFilaTipoBeneficios,
                idPrograma: id
              }}
            />
          </Grid>
        </Paper>
      </GeneralWrapper>
    );
  }
}
DestacadosBuilder.propTypes = {
  classes: PropTypes.object,
  onGetTemplate: PropTypes.func.isRequired
};

DestacadosBuilder.displayName = "DestacadosBuilder";

export default Enhancer(DestacadosBuilder);
