import { compose, pure, withHandlers } from "recompose";
import withStyles from "./styles";

const enhance = compose(
  pure,
  withStyles,
  withHandlers({
    onChange: ({ onChange: externalOnChange }) => event =>
      externalOnChange(event.target.value)
  })
);

export default enhance;
