import React, { Fragment, PureComponent } from "react";
import EnhancedTable from "js/components/Shared/TablaReconocimientos/EnhancedTable";
import DefaultFilter from "js/components/Shared/TablaReconocimientos/DefaultFilter";
import Header from "js/components/Shared/TablaReconocimientos/Header";
import ButtonBar from "js/components/Shared/TablaReconocimientos/ActionButtons";
import PropTypes from "prop-types";
import FilterService from "js/utils/FilterService";
import { size } from "lodash";
import { flatten } from "flat";
import SplashScreen from "js/pages/SplashScreen";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import Collapse from "js/components/Shared/Collapsable";
import { exportURL } from "js/constants";
import { _t } from "js/utils/TranslationService";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";

class Tabla extends PureComponent {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id, idProveedor, idUsuario }
      },
      initialFilter,
      idPrograma
    } = props;

    let filter = initialFilter;

    if (id || idPrograma)
      filter.idPrograma = parseInt(id) || parseInt(idPrograma);
    if (id) filter.idsProgramas = [parseInt(id)];
    if (idProveedor) filter.idProveedor = parseInt(idProveedor);
    if (idUsuario) filter.idUsuario = parseInt(idUsuario);

    // El idProgrma si o si tiene que estar incluido
    if (!filter.idPrograma) {
      filter.idPrograma = parseInt(AuthService.getIdPrograma());
    }

    this.state = {
      selected: [],
      disabled: [],
      data: null,
      order: props.sort.order,
      orderBy: props.sort.orderBy,
      page: 1,
      rowsPerPage: 10,
      filter: filter,
      expanded: false
    };
  }

  _fetchData = async ({ order, orderBy, page, rowsPerPage, filter }) => {
    const auxFiltered = flatten(filter);
    let filtered = {};
    Object.keys(auxFiltered).forEach(key => {
      if (key.includes("."))
        filtered[key.slice(key.lastIndexOf(".") + 1, key.length)] =
          auxFiltered[key];
      else filtered[key] = auxFiltered[key];
    });

    const { client, query } = this.props;
    await client
      .query({
        query: query,
        fetchPolicy: "network-only",
        variables: {
          order: order || this.state.order,
          orderBy: orderBy || this.state.orderBy,
          page: page || this.state.page,
          rowsPerPage: rowsPerPage || this.state.rowsPerPage,
          ...(Object.keys(filtered).length !== 0 ? filtered : this.state.filter)
        }
      })
      .then(res => {
        this.setState({
          data: res.data || this.state.data,
          order: order || this.state.order,
          orderBy: orderBy || this.state.orderBy,
          page: page || this.state.page,
          rowsPerPage: rowsPerPage || this.state.rowsPerPage,
          filter: filter || this.state.filter
        });
      });
  };

  componentDidMount = () => {
    const filtro = FilterService.getFilter(this.props.storage);
    if (this.props.disabled) {
      this.setState({ disabled: this.state.disabled });
    }
    this.props.storage &&
      filtro &&
      this.setState({ filter: filtro }) &&
      this.setState({ selected: filtro.selected });

    //FIXME: hack para q no cargue TODOS los programas si no tiene el permiso
    AuthService.hasPerms([Roles.PROGRAMA_LISTA]) &&
      this._fetchData({ ...filtro });
  };

  componentWillReceiveProps = props => {
    this.search();
    const { selectedItems, disabledItems } = props;
    this.setState({
      selected: selectedItems,
      disabled: disabledItems
    });
  };

  componentWillUnmount = () => {
    this.props.storage &&
      FilterService.setFilter(this.props.storage, {
        order: this.state.order,
        orderBy: this.state.orderBy,
        page: this.state.page,
        rowsPerPage: this.state.rowsPerPage,
        filter: this.state.filter,
        selected: this.state.selected
      });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this._fetchData({ order, orderBy });
  };

  handleSelectAllClick = (data, event, checked) => {
    //TODO: hacer merge de la nueva seleccion con la vieja
    // si id de data en selected, remover, sino agregar
    if (checked) {
      const selected = data.map(n => n[Object.keys(n)[0]]);
      this.setState({ selected });
      this.props.storage &&
        FilterService.setFilter(this.props.storage, {
          ...this.state,
          selected: selected
        });
      return;
    }
    this.setState({ selected: [] });
  };

  handleDeselectAllClick = () => {
    this.setState({ selected: [] });
    this.props.onDeselectAll();
  };

  handleClick = (event, item) => {
    const { selected } = this.state;
    const id = item[Object.keys(item)[0]];
    const selectedIndex = selected.indexOf(id);
    let newSelected = Object.assign([], selected);
    if (selectedIndex === -1) {
      newSelected.push(id);
      this.props.onSelectItem(newSelected, Object.assign({}, item));
    } else {
      newSelected.splice(selectedIndex, 1);
      this.props.onSelectItem(newSelected, id);
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this._fetchData({ page: parseInt(page, 10) + 1 });
  };

  handleChangeRowsPerPage = event => {
    this._fetchData({ rowsPerPage: event.target.value, page: 1 });
  };

  handleKey = e => {
    if (e.keyCode === 13) this.search();
  };

  submitValue = e => {
    const obj = {};
    // const objPais = {};
    const objZona = {};
    const objLocalidad = {};
    // const objSegmento = {};
    obj[e.target.name] = e.target.value;
    const paisActual = this.state.filter && this.state.filter.idPais;
    // const programaActual = this.state.filter && this.state.filter.idPrograma;
    // TODO: cambio de programa genera cambio de segmento
    // Ver esto cuando ande el BE de busqueda de Usuarios
    // if (e.target.name === "idPrograma" && e.target.value !== programaActual) {
    // objSegmento["idSegmento"] = "";}

    if (e.target.name === "idPais" && e.target.value !== paisActual) {
      objZona["idZona"] = undefined;
      objLocalidad["idLocalidad"] = undefined;
    }
    if (e.target.name === "idZona" && e.target.value === "") {
      objLocalidad["idLocalidad"] = undefined;
    }

    this.setState(({ filter }) => ({
      filter: { ...filter, ...obj, ...objZona, ...objLocalidad }
    }));
  };

  clear = () => {
    this.setState({
      filter: {
        idPrograma: this.props.idPrograma,
        nominaEquipos: "AMBOS"
      }
    });
  };

  getHeaderQueryString = ({ queryStringMatcher = {} }) => {
    const { filter } = this.state;
    let filterCopy = Object.assign({}, filter);
    Object.keys(queryStringMatcher).map(key => {
      if (queryStringMatcher[key])
        filterCopy[queryStringMatcher[key]] = filterCopy[key];
      return delete filterCopy[key];
    });
    Object.keys(filterCopy).forEach(
      key => filterCopy[key] === undefined && delete filterCopy[key]
    );
    return filterCopy;
  };

  search = () => {
    let filter = this.state.filter;
    Object.keys(filter).forEach(key => {
      if (filter[key] === "TODOS") delete filter[key];
    });
    this._fetchData({
      filter: {
        ...filter
      }
    });
    this.setState({ expanded: false });
  };

  handleCollapse = () =>
    this.setState(prevState => ({ expanded: !prevState.expanded }));

  render() {
    const {
      showHeader,
      columnData,
      getTipo,
      filter,
      advancedFilter: AdvancedFilter,
      defaultHeader,
      actions,
      actionsCustom,
      toolbarActions,
      deleteAction,
      labelDisplayedRows,
      buttons,
      pathId,
      formulario,
      history,
      idUsuario,
      idPrograma,
      classes
    } = this.props;

    let queryString = "";
    const queryFilter = this.getHeaderQueryString(defaultHeader);
    if (queryFilter) {
      queryString = Object.keys(queryFilter)
        .map(
          key =>
            Array.isArray(queryFilter[key])
              ? queryFilter[key].map(v => `${key}[]=${v}`).join("&")
              : key + "=" + queryFilter[key]
        )
        .join("&");
    }
    return (
      <Fragment>
        <Grid xs={12} sm={10}>
          <div tabIndex="0" onKeyDown={this.handleKey}>
            {showHeader && (
              <Header
                values={defaultHeader}
                filter={this.getHeaderQueryString(defaultHeader)}
                refetch={() => this.search()}
              />
            )}
            {!!filter.length && (
              <DefaultFilter
                filter={filter}
                value={this.state.filter}
                submitValue={this.submitValue.bind(this)}
              />
            )}
            {!!AdvancedFilter && (
              <Collapse
                clear={this.clear.bind(this)}
                search={this.search.bind(this)}
                expanded={this.state.expanded}
                onChange={this.handleCollapse}
                title="Filtros"
              >
                <AdvancedFilter
                  value={this.state.filter}
                  submitValue={this.submitValue.bind(this)}
                  search={this.search.bind(this)}
                  idPrograma={idPrograma}
                  formulario={formulario}
                  conEstado={true}
                  classes={classes}
                />
              </Collapse>
            )}
            {!this.state.data && <SplashScreen />}
            {this.state.data && (
              <EnhancedTable
                refetch={this._fetchData}
                columnData={columnData}
                labelDisplayedRows={labelDisplayedRows}
                data={getTipo(this.state.data).itemsPagina}
                page={getTipo(this.state.data).infoPagina.numeroPagina}
                total={getTipo(this.state.data).infoPagina.total}
                totalDisabledItems={size(this.props.disabledItems) || 0}
                handleRequestSort={this.handleRequestSort.bind(this)}
                handleSelectAllClick={this.handleSelectAllClick.bind(
                  this,
                  getTipo(this.state.data).itemsPagina
                )}
                handleClick={this.handleClick.bind(this)}
                handleDeselectAllClick={this.handleDeselectAllClick.bind(this)}
                handleChangePage={this.handleChangePage.bind(this)}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage.bind(
                  this
                )}
                footer={this.props.footer}
                actions={actions}
                actionsCustom={actionsCustom}
                toolbarActions={toolbarActions}
                hanleDeleteAction={deleteAction.bind(this)}
                state={this.state}
                onTableChange={() => this.search()}
                pathId={pathId}
                formulario={formulario}
                history={history}
                idUsuario={idUsuario}
              />
            )}
            {this.state.data && (
              <Fragment>
                <ButtonBar
                  onTableChange={() => this.search()}
                  buttons={buttons}
                  total={getTipo(this.state.data).infoPagina.total}
                  filters={this.state.filter}
                  selected={this.state.selected.length}
                />
              </Fragment>
            )}
          </div>
        </Grid>
        <Grid
          xs={12}
          item
          className={classes.containerBotones}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button
            classes={{
              label: classes.buttonLabel
            }}
            variant="contained"
            color="primary"
            onClick={() =>
              (window.location = `${exportURL}/reconocimientos?${queryString}`)
            }
            download
          >
            {_t("Exportar")}
          </Button>
        </Grid>
      </Fragment>
    );
  }
}

Tabla.propTypes = {
  storage: PropTypes.string,
  query: PropTypes.object.isRequired,
  columnData: PropTypes.array.isRequired,
  showHeader: PropTypes.bool,
  defaultHeader: PropTypes.object,
  sort: PropTypes.object,
  filter: PropTypes.array.isRequired,
  advancedFilter: PropTypes.func,
  getTipo: PropTypes.func.isRequired,
  actions: PropTypes.array,
  actionsCustom: PropTypes.array,
  buttons: PropTypes.array,
  toolbarActions: PropTypes.array,
  disabledItems: PropTypes.array,
  selectedItems: PropTypes.array,
  onSelectItem: PropTypes.func,
  onDeselectAll: PropTypes.func,
  deleteAction: PropTypes.func
};

Tabla.defaultProps = {
  storage: null,
  showHeader: true,
  initialFilter: {},
  filter: [],
  advancedFilter: undefined,
  sort: {},
  actions: [],
  footer: [],
  actionsCustom: [],
  buttons: [],
  toolbarActions: [],
  disabledItems: [],
  selectedItems: [],
  onSelectItem: () => undefined,
  onDeselectAll: () => undefined,
  deleteAction: () => undefined
};

export default Tabla;
