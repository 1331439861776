export const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: theme.container.maxWidth,
    margin: theme.container.margin
  },
  field: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  detailsBox: {
    padding: "14px 0",
    backgroundColor: "#efefef"
  },
  fotoBox: {
    maxHeight: "200px"
  },
  lineDetails: {
    padding: "8px 25px",
    float: "left",
    borderRight: "1px solid grey"
  },
  span: {
    marginLeft: "10px",
    fontSize: "20px"
  },
  grid: {
    marginTop: 15
  },
  gridButton: {
    marginTop: "20px",
    textAlign: "end",
    marginLeft: 20
  },
  button: {
    width: "200px"
  },
  buttonSave: {
    flex: 1,
    display: "flex",
    alignItems: "end",
    justifyContent: "flex-end",
    margin: "100px 0 20px 0"
  }
});
