export default theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: theme.container.maxWidth,
    margin: theme.container.margin,
    padding: theme.spacing.unit * 4
  },
  field: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  grid: {
    margin: "0"
  },
  gridButton: {
    marginTop: "20px",
    textAlign: "end",
    marginLeft: 20
  },
  button: {
    width: "200px",
    margin: "10px"
  },
  title: {
    fontWeight: "bold"
  },
  groupsTypography: {
    color: theme.palette.quaternary.main,
    fontSize: "1.05rem"
  },
  groupContainer: {
    marginBottom: "2.5rem"
  },
  progress: {
    marginLeft: theme.spacing.unit
  },
  alertaPerfil: {
    backgroundColor: "#FF000015",
    padding: "20px"
  },
  alertaPerfilTexto: {
    color: "red"
  }
});
