import React, { PureComponent } from "react";
import Form from "js/pages/Admin/Programa/ProductoRetail/Edit/MyForm.js";
import AppQraphQL from "js/graphql/resolvers/productoRetail.resolver";
import { removeTypename } from "js/utils/Helper";
import SegmentosGraphQL from "js/graphql/resolvers/segmento.resolver";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        idPrograma: this.props.match.params.id
      },
      segmentos: [],
      disabled: false,
      errors: null,
      id: this.props.match.params.id,
      codigoProducto: this.props.match.params.codigoProducto
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { id, codigoProducto } = this.state;
    if (codigoProducto !== "new") {
      await client
        .query({
          query: AppQraphQL.queries.getProductoRetailById,
          fetchPolicy: "network-only",
          variables: {
            productoLike: {
              codigo: codigoProducto
            }
          }
        })
        .then(res => {
          const producto = res.data.getProductosRetail.itemsPagina[0];
          this.setState({
            initialValues: {
              ...producto,
              fields: producto.puntosPorSegmentos
            }
          });
        });
    } else {
      this.setState({
        initialValues: {
          idPrograma: parseInt(id) || id,
          estado: "ACTIVO"
        }
      });
    }

    await client
      .query({
        query: SegmentosGraphQL.queries.getSegmentosInput,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: parseInt(id) || id
        }
      })
      .then(res => {
        const segmentos = res.data.getSegmentos ? res.data.getSegmentos : null;

        this.setState({ segmentos });
      });
  }

  async submit(values) {
    const { client, history } = this.props;
    const { id, codigoProducto } = this.state;

    let variables = { ...values };
    variables.puntosPorSegmentos = variables.fields.map(field => {
      field.puntos = parseInt(field.puntos);
      return field;
    });
    delete variables.fields;

    await client
      .mutate({
        mutation:
          codigoProducto === "new"
            ? AppQraphQL.mutations.createProductoRetail
            : AppQraphQL.mutations.updateProductoRetail,
        variables: {
          producto: { ...removeTypename(variables) }
        }
      })
      .then(res => {
        const mensaje =
          "Producto " +
          (codigoProducto === "new" ? "creado" : "modificado") +
          " correctamente";
        this.props.openNotification(mensaje);
        history.push(`/admin/programa/${id}/productos-retail`);
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  handleDateChange = key => date => {
    this.setState({
      [key]: date.toISOString()
    });
  };

  render() {
    return (
      <Form
        id={this.state.id}
        codigoProducto={this.state.codigoProducto}
        errors={this.state.errors}
        disabled={this.state.disabled}
        initialValues={this.state.initialValues}
        onSubmit={this.submit.bind(this)}
        segmentos={this.state.segmentos}
      />
    );
  }
}

export default Edit;
