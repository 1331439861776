import MyForm from "./MyForm";
import {
  compose,
  withHandlers,
  withProps,
  branch,
  defaultProps
} from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import styles from "./styles.js";
import AccionesGraphQL from "js/graphql/resolvers/acciones.resolver";
import { withRouter } from "react-router-dom";
import { withNotificationContext } from "js/notification-context";
import { get } from "lodash";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { getAccion } from "./utils";

const selector = formValueSelector("accionForm");

const enhance = compose(
  withRouter,
  withNotificationContext,
  withStyles(styles),
  withProps(({ match: { params: { idAccion, edit, id } } }) => ({
    idAccion,
    [edit]: true,
    nuevo: !edit,
    tipoForm: edit === "edit" ? "Editar" : edit === "view" ? "Ver" : "Crear",
    disabled: edit === "view",
    idTargetPrograma: parseInt(id) || id
  })),
  branch(
    ({ edit }) => !!edit,
    graphql(AccionesGraphQL.mutations.updateAccion, { name: "submitMutation" }),
    graphql(AccionesGraphQL.mutations.createAccion, { name: "submitMutation" })
  ),
  branch(
    ({ idAccion }) => !!idAccion,
    graphql(AccionesGraphQL.queries.getAccionById, {
      options: ({ idAccion }) => ({
        variables: { idAccion },
        fetchPolicy: "network-only"
      }),
      props: ({ data: { loading, getAcciones }, ownProps: { disabled } }) => {
        if (loading) return;
        const initialValues = get(getAcciones, "itemsPagina[0]", {});
        let idTargetPrograma = get(
          initialValues,
          "targetPrograma.idPrograma",
          get(initialValues, "targetSegmento.idPrograma")
        );
        return {
          initialValues: {
            ...initialValues,
            idTargetPrograma: parseInt(idTargetPrograma) || idTargetPrograma,
            idTargetSegmento: parseInt(
              get(initialValues, "targetSegmento.idSegmento")
            )
          },
          disabled:
            disabled ||
            ["CANCELADA", "FINALIZADA"].indexOf(initialValues.estado) > -1
        };
      }
    })
  ),
  defaultProps({
    initialValues: {
      estado: "BORRADOR",
      tieneRanking: false,
      // puntosAcreditar: 0,
      acreditaAccionPuntos: true,
      ganarAccionPuntos: false,
      permiteMultipleParticipacion: false,
      matematicaRanking: "SUMA_PUNTOS"
    }
  }),
  withHandlers({
    onSubmit: ({
      submitMutation,
      openNotification,
      history,
      match: {
        params: { id }
      },
      initialValues
    }) => async values => {
      const accion = getAccion(values, initialValues);
      if (typeof accion.puntosAcreditar === "string")
        accion.puntosAcreditar = parseInt(accion.puntosAcreditar);

      if (typeof accion.puntosParticipar === "string")
        accion.puntosParticipar = parseInt(accion.puntosParticipar);

      if (typeof accion.alturaIframe === "string")
        accion.alturaIframe = parseInt(accion.alturaIframe);

      submitMutation({ variables: { accion } })
        .then(
          res =>
            res.errors && res.errors.length
              ? openNotification(res.errors[0].message)
              : openNotification("Acción guardada correctamente") ||
                parseInt(id) ||
                id
                ? history.push(`/admin/programa/${parseInt(id) || id}/acciones`)
                : history.push("/admin/acciones")
        )
        .catch(e =>
          openNotification(e.message.replace("GraphQL error:", "").trim())
        );
    },
    volver: ({
      history,
      match: {
        params: { id }
      }
    }) =>
      id
        ? history.push(`/admin/programa/${parseInt(id) || id}/acciones`)
        : history.push("/admin/acciones"),
    goToEdit: ({
      history,
      idAccion,
      match: {
        params: { id }
      }
    }) =>
      id
        ? history.push(
            `/admin/programa/${parseInt(id) || id}/acciones/${idAccion}/edit`
          )
        : history.push(`/admin/acciones/${idAccion}/edit`)
  }),
  connect(state =>
    selector(
      state,
      "idTargetPrograma",
      "fechaInicio",
      "fechaFin",
      "tipo",
      "permiteMultipleParticipacion"
    )
  ),
  reduxForm({
    form: "accionForm",
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
  })
);
export default enhance(MyForm);
