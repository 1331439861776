import React, { Children } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import cx from "classnames";
import Enhancer from "./container";
import { _t } from "js/utils/TranslationService";

const Subsection = ({ children, classes, title, variant = "normal" }) => (
  <Grid
    container
    className={cx("Subsection", {
      [classes[variant]]: variant
    })}
  >
    <Grid item xs={12}>
      <Typography variant="subtitle1" gutterBottom className={classes.Title}>
        {_t(title)}
      </Typography>
    </Grid>
    {Children.map(children, (child, i) => (
      <Grid key={i} item xs={12} className={classes.Wrapper}>
        {child}
      </Grid>
    ))}
  </Grid>
);

Subsection.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string
};

Subsection.defaultProps = {
  title: ""
};

Subsection.displayName = "Subsection";

export default Enhancer(Subsection);
