import React, { PureComponent, Fragment } from "react";
import Form from "js/pages/Admin/Programa/CuentaCorriente/Edit/MyForm.js";
import AppQraphQL from "js/graphql/resolvers/cuentaCorriente.resolver";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        idPrograma: this.props.match.params.id
          ? parseInt(this.props.match.params.id, 10)
          : "",
        tasaConversion: 0,
        monedaBase: "XXX",
        tipo: "CREDITO"
      },
      disabled: false,
      errors: null,
      id: this.props.match.params.id
    };
  }

  async submit(values) {
    const { client, history } = this.props;
    const { id } = this.state;

    values.puntosAsignados = values.puntosAsignados
      ? parseInt(values.puntosAsignados)
      : values.puntosAsignados;

    await client
      .mutate({
        mutation: AppQraphQL.queries.setearPuntosAPrograma,
        variables: { ...values }
      })
      .then(res => {
        const mensaje = "Movimiento realizado correctamente";
        this.props.openNotification(mensaje);
        id
          ? history.push(`/admin/programa/${id}/cuenta_corriente`)
          : history.push(`/admin/cuenta_corriente`);
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }
  render() {
    return (
      <Fragment>
        <Form
          id={this.state.id}
          errors={this.state.errors}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
        />
      </Fragment>
    );
  }
}

export default Edit;
