import React from "react";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import DireccionEntregaForm from "./DireccionEntregaForm";
import { _t } from "js/utils/TranslationService";
import { DatePicker } from "material-ui-pickers";
import moment from "moment";
import Grid from "@material-ui/core/Grid";

export const getField = ({
  key,
  productoActions,
  requeridos,
  nombre,
  classes,
  sucursales
}) => {
  switch (key) {
    case "sucursal":
      return (
        <Grid item xs={12}>
          <TextField
            select
            required
            className={classes.textField}
            value={requeridos.sucursal}
            onChange={productoActions.changeSucursal}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            margin="normal"
          >
            {!sucursales.length && <MenuItem value="" />}
            {sucursales.map(sucursal => (
              <MenuItem key={sucursal.idSucursal} value={sucursal.idSucursal}>
                {sucursal.nombre} - {sucursal.direccion}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText style={{ marginTop: 0, marginBottom: 16 }}>
            {_t("Elegir sucursal")}
          </FormHelperText>
        </Grid>
      );

    case "entrega":
      const obj =
        typeof requeridos[key] === "string"
          ? JSON.parse(requeridos[key])
          : requeridos[key];
      return (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {_t("Dirección de entrega")}
          </Typography>
          <DireccionEntregaForm
            value={{ ...obj }}
            onChange={productoActions.changeRequeridoString(key)}
            required
          />
        </Grid>
      );

    case "fecha":
    case "fechaNacimiento":
      return (
        <Grid item xs={12}>
          <DatePicker
            id={key}
            key={key}
            value={requeridos[key] ? moment(requeridos[key]) : null}
            label={`${nombre}`}
            className={classes.textField}
            onChange={v =>
              productoActions.changeRequerido(key)({
                target: { name: key, value: v.toISOString() }
              })
            }
            format={"DD/MM/YYYY"}
            maxDate={moment()}
            autoOk
            margin="normal"
            required
          />
        </Grid>
      );

    default:
      return (
        <Grid item xs={12}>
          <TextField
            id={key}
            type="text"
            key={key}
            label={`${nombre}`}
            className={classes.textField}
            required
            value={requeridos[key]}
            onChange={productoActions.changeRequerido(key)}
            margin="normal"
          />
        </Grid>
      );
  }
};
