import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextTruncate from "react-text-truncate";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Icon from "@material-ui/core/Icon";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { withNotificationContext } from "js/notification-context";
import client from "js/App/client.graphql.js";
import FavoritoQraphQL from "js/graphql/resolvers/favorito.resolver";
import { numberWithCommas } from "js/utils";
import Cucarda from "../../../components/Cucarda";
import { _t } from "js/utils/TranslationService";
import Tooltip from "@material-ui/core/Tooltip";
import get from "lodash/get";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class CardItem extends Component {
  constructor(props) {
    super(props);
    this.toggleFavorito = this.toggleFavorito.bind(this);
    this.state = {
      favorito: this.props.producto.favorito,
      starActive: this.props.producto.favorito
    };
  }

  async toggleFavorito() {
    this.setState(prevState => ({
      favorito: !prevState.favorito
    }));
    await client
      .mutate({
        mutation: FavoritoQraphQL.mutations.setFavorito,
        errorPolicy: "all",
        variables: {
          favoritoToggle: {
            idEleccionProducto: this.props.producto.idEleccionProducto
          }
        }
      })
      .then(res => {
        const favorito = get(res, "data.toggleFavorito.favorito", false);
        this.setState({ favorito });
        const message = favorito
          ? "Has agregado este producto a tu lista favoritos"
          : "Has quitado este producto de tu lista favoritos";
        this.props.openNotification(message);
        this.props.producto.favorito = favorito;
      })
      .catch(e => {
        this.setState(prevState => ({ favorito: !prevState.favorito }));
      });
  }

  render() {
    const { classes, producto, programLogo } = this.props;
    const rubros = producto.rubros
      .map(rubro => rubro.descripcion)
      .concat(get(producto, "subrubros", []).map(r => r.descripcion));
    const favPerm = AuthService.hasPerms([Roles.FAVORITO_W]);
    const { cucarda } = producto;
    return (
      <Grid item xs={12} sm={6} md={4} lg={4} className={classes.gridCard}>
        <Card className={classes.card} raised>
          <div className={classes.favContainer}>
            {favPerm && (
              <Icon
                color="primary"
                className={classes.heartIcon}
                onClick={this.toggleFavorito}
                onMouseOver={() => this.setState({ starActive: true })}
                onMouseOut={() => this.setState({ starActive: false })}
              >
                {this.state.starActive || this.state.favorito
                  ? "favorite"
                  : "favorite_border"}
              </Icon>
            )}
            <Link
              to={`/producto/${producto.idEleccionProducto}`}
              className={classes.link}
            >
              <CardMedia
                className={
                  producto.producto.imagenes[0]
                    ? classes.media
                    : classes.mediaLogo
                }
                image={
                  producto.producto.imagenes[0]
                    ? producto.producto.imagenes[0].url
                    : programLogo
                }
                style={{ borderRadius: `${cucarda ? 0 : 4}px 4px 0 0` }}
              >
                {cucarda && <Cucarda cucardaType={cucarda} />}
              </CardMedia>
            </Link>
          </div>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.item} color="textSecondary">
              {rubros.length ? rubros[0] : <br />}
            </Typography>
            <Link
              to={`/producto/${producto.idEleccionProducto}`}
              className={classes.link}
            >
              <Tooltip
                title={producto.producto.nombre}
                classes={{
                  tooltip: classes.tooltip,
                  popper: classes.popper,
                  tooltipPlacementBottom: classes.tooltipPlacementBottom
                }}
                interactive
              >
                <Typography className={classes.descripcion} component="div">
                  <TextTruncate
                    className={classes.title}
                    line={1}
                    truncateText="..."
                    text={producto.producto.nombre}
                  />
                </Typography>
              </Tooltip>
            </Link>
            <Typography className={classes.descripcion} component="div">
              <div
                dangerouslySetInnerHTML={{
                  __html: `<div style="
                              overflow: hidden;
                              display: -webkit-box;
                              -webkit-line-clamp: 3;
                              -webkit-box-orient: vertical;
                            ">${producto.producto.copete || ""}</div>`
                }}
              />
            </Typography>
          </CardContent>
          <CardActions className={classes.actionContainer}>
            <Typography
              className={classes.score}
              variant="h6"
              component="h2"
              color="primary"
            >
              <p>
                {numberWithCommas(producto.puntos)} <br />
                {_t("Puntos")}
              </p>
            </Typography>
            {!producto.stockInfinito &&
            producto.stockPorPrograma !== null &&
            producto.stockPorPrograma < 1 ? (
              <Button variant="contained" color="primary" disabled={true}>
                {_t("Sin stock")}
              </Button>
            ) : (
              <Link to={`/producto/${producto.idEleccionProducto}`}>
                <Button variant="contained" color="primary">
                  {_t("Más info")}
                </Button>
              </Link>
            )}
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

export default withNotificationContext(CardItem);
