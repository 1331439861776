import { withStyles } from "@material-ui/core/styles";
import BaseRaw from "./Base";
import BannerRaw from "./Banner";
import SliderRaw from "js/components/Shared/Slider";
import VideoRaw from "./Video";
import styles from "./styles";

export const Banner = withStyles(styles)(BannerRaw);
export const Base = withStyles(styles)(BaseRaw);
export const Slider = withStyles(styles)(SliderRaw);
export const Video = withStyles(styles)(VideoRaw);

export default {
  Base,
  Banner,
  Video,
  Slider
};
