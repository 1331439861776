import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/Form/styles.js";
import {
  reduxForm,
  formValueSelector,
  change,
  getFormMeta,
  FieldArray
} from "redux-form";
import {
  Grid,
  Button,
  Icon,
  Tooltip,
  Paper,
  Typography
} from "@material-ui/core";
import {
  InputTextField,
  InputNumberField,
  ProgramaField,
  ListField
} from "js/components/Shared/Form/Fields";

const renderPuntosPorSegmento = ({ fields, segmentos }) => (
  <Grid container spacing={16}>
    {segmentos.length !== 0 &&
      fields.length < segmentos.length && (
        <Tooltip title="Agregar item" placement="right-end">
          <Icon color="secondary" onClick={() => fields.push({})}>
            add_circle
          </Icon>
        </Tooltip>
      )}
    {fields.map((puntosPorSegmento, index) => {
      return (
        <Fragment key={index}>
          <Grid xs={11} item>
            <Paper style={{ width: "100%", padding: "15px" }}>
              <Grid container spacing={16}>
                <Grid item xs={12} md={6}>
                  <ListField
                    name={`${puntosPorSegmento}.idSegmento`}
                    label="Segmento"
                    options={segmentos.map(segmento => ({
                      value: segmento.idSegmento,
                      label: segmento.label
                    }))}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputNumberField
                    name={`${puntosPorSegmento}.puntos`}
                    label="Puntos"
                    required
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Tooltip title="Quitar item" placement="right-start">
            <Icon color="action" onClick={() => fields.splice(index, 1)}>
              remove_circle
            </Icon>
          </Tooltip>
        </Fragment>
      );
    })}
  </Grid>
);

class Form extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      id,
      codigoProducto,
      initialValues,
      segmentos
    } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {initialValues.nombre ? "Editar producto" : "Nuevo producto"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form className={classes.container} onSubmit={handleSubmit}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors && errors.map(e => <li>{e.message}</li>)}
                </ul>
              </Grid>
            </Grid>

            <Grid container spacing={16}>
              <Grid item xs={12} sm={4}>
                <ProgramaField disabled={true} required redux />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputTextField name="nombre" label="Nombre" required />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputTextField name="codigo" label="Código" required />
              </Grid>

              <Grid item xs={12} sm={8}>
                <InputTextField
                  name="descripcion"
                  label="Descripción"
                  required
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <ListField
                  name="estado"
                  label="Estado"
                  options={[
                    { label: "Activo", value: "ACTIVO" },
                    { label: "Inactivo", value: "INACTIVO" }
                  ]}
                  disabled={disabled || codigoProducto === "new"}
                  required
                />
              </Grid>

              {/* TODO: Campos dinámicos */}

              <Grid item xs={12}>
                <Typography variant="h6">Puntos por segmento</Typography>
              </Grid>

              <Grid item xs={12}>
                <FieldArray
                  name="fields"
                  component={renderPuntosPorSegmento}
                  segmentos={segmentos}
                />
              </Grid>

              <Grid className={classes.gridButton} item xs={12}>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  className={classes.button}
                  style={{ marginRight: 20 }}
                  onClick={() =>
                    history.push(`/admin/programa/${id}/productos-codificados`)
                  }
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  disabled={pristine || submitting}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

Form = reduxForm({
  form: "formProductoCodificado",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formProductoCodificado");

Form = connect(
  state => ({
    formMeta: getFormMeta("formProductoCodificado")(state),
    idProgramaField: selector(state, "idPrograma")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formProductoCodificado", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
