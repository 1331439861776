import {
  CAMBIAR_CANTIDAD,
  INCREMENTAR_CANTIDAD,
  DECREMENTAR_CANTIDAD,
  SOLICITAR_FAIL,
  CHANGE_SUCURSAL,
  CLEAN_BENEFICIO_STATE,
  CLOSE_DIALOG_SOLICITUD,
  CLOSE_NOTIFICATION,
  LOAD_BENEFICIO_STATE,
  OPEN_DIALOG_SOLICITUD,
  TOGGLE_FAVORITO_ERROR,
  TOGGLE_FAVORITO_SUCCESS,
  TOGGLE_FAVORITO
} from "./constants";
import client from "js/App/client.graphql.js";
import BeneficioQraphQL from "js/graphql/resolvers/beneficio.resolver";
import { get } from "lodash";
import { _t } from "js/utils/TranslationService";

export const beneficioActions = ({ dispatch, ownProps }) => ({
  abrirSolicitudPopup: () => dispatch({ type: OPEN_DIALOG_SOLICITUD }),
  cerrarSolicitudPopup: () => dispatch({ type: CLOSE_DIALOG_SOLICITUD }),
  cleanBeneficioState: () => dispatch({ type: CLEAN_BENEFICIO_STATE }),
  loadBeneficioState: beneficio =>
    dispatch({ type: LOAD_BENEFICIO_STATE, beneficio }),
  cambiarCantidad: cantidad => dispatch({ type: CAMBIAR_CANTIDAD, cantidad }),
  decrementarCantidad: cantidad =>
    dispatch({ type: DECREMENTAR_CANTIDAD, cantidad }),
  incrementarCantidad: cantidad =>
    dispatch({ type: INCREMENTAR_CANTIDAD, cantidad }),
  toggleFavorito: idBeneficio => () => {
    dispatch({ type: TOGGLE_FAVORITO, idBeneficio });
    client
      .mutate({
        mutation: BeneficioQraphQL.mutations.toggleFavoritoBeneficio,
        errorPolicy: "all",
        variables: {
          idBeneficio: idBeneficio
        }
      })
      .then(res => {
        const favorito = get(res, "data.toggleFavoritoBeneficio", false);
        dispatch({
          type: TOGGLE_FAVORITO_SUCCESS,
          favorito,
          mensaje:
            favorito === true
              ? `${_t("Has agregado este beneficio a tu lista favoritos")}`
              : `${_t("Has quitado este beneficio a su lista favoritos")}`
        });
      })
      .catch(e => {
        dispatch({
          type: TOGGLE_FAVORITO_ERROR,
          error: `${_t(e.message)}`,
          mensaje: `${_t(
            "No se pudo actualizar este beneficio a su lista favoritos"
          )}`
        });
      });
  },
  closeNotification: () => dispatch({ type: CLOSE_NOTIFICATION }),
  requestSolicitud: (state, history) => () => {
    const { self } = state;
    self.setState({
      solicitando: true
    });

    const solicitudVariables = {
      idBeneficio: state.idBeneficio,
      cantidad: state.cantidad
    };

    client
      .mutate({
        mutation: BeneficioQraphQL.mutations.solicitarBeneficio,
        errorPolicy: "all",
        variables: {
          solicitud: solicitudVariables
        }
      })
      .then(res => {
        if (!res.errors) {
          const idSolicitud = res.data.solicitarBeneficio.idSolicitud;
          history.push(`/solicitud-beneficio/${idSolicitud}`);
        } else {
          throw res.errors[0];
        }
      })
      .catch(e => {
        self.setState({
          solicitando: false
        });
        dispatch({ type: SOLICITAR_FAIL, error: e.message });
      });
  },
  changeSucursal: event =>
    dispatch({ type: CHANGE_SUCURSAL, idSucursal: event.target.value })
});

export default beneficioActions;
