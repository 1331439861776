import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { styles } from "js/pages/Admin/Programa/Roles/List/styles.js";
import { capitalize } from "lodash";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Typography from "@material-ui/core/Typography";

class ItemList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      nombre: "",
      descripcion: "",
      open: false
    };
  }

  handleClickOpen = value => {
    this.setState({
      open: true,
      id: value.idRol,
      nombre: value.nombre,
      descripcion: value.descripcion,
      nivel: value.nivel,
      estado: value.estado
    });
  };

  handleClose = () => {
    this.setState({ open: false, id: null, nombre: "", descripcion: "" });
  };

  handleChangeNombre = e => {
    const nombre = e.target.value;
    if (nombre === "Default" || nombre === "") return null;
    this.setState({ nombre });
  };

  handleChangeDescripcion = e => {
    this.setState({ descripcion: e.target.value });
  };

  handleChangeNivel = e => {
    this.setState({ nivel: e.target.value });
  };
  handleChangeInactivar = e => {
    console.log(e);
    this.setState({ estado: e.target.checked ? "INACTIVO" : "ACTIVO" });
  };

  handleSave = () => {
    const { id, nombre, descripcion, nivel, estado } = this.state;
    this.props.onHandleSave(id, nombre, descripcion, nivel, estado);
    this.handleClose();
  };

  render() {
    const { nombre, descripcion, nivel, estado, open } = this.state;
    const { classes, fullScreen, initialValues, history } = this.props;

    return (
      <div className={classes.root}>
        <List>
          {initialValues &&
            initialValues.map((value, index) => (
              <ListItem key={index} role={undefined} dense button>
                <ListItemText
                  primary={`${value.nombre}`}
                  disableTypography
                  secondary={
                    <Typography className={classes.itemTextSecondary}>
                      ID {value.idRol} - Nivel {value.nivel}
                      {value.descripcion ? "- " + value.descripcion : ""} -{" "}
                      <Typography
                        className={classes[value.estado.toLowerCase()]}
                      >
                        {capitalize(value.estado)}
                      </Typography>
                    </Typography>
                  }
                  classes={classes}
                />
                <ListItemSecondaryAction>
                  {AuthService.hasPerms([Roles.ROL_W]) &&
                    value.nombre !== "SUPERADMIN" && (
                      <React.Fragment>
                        <IconButton
                          aria-label="Editar"
                          onClick={this.handleClickOpen.bind(this, value)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="Editar"
                          onClick={e =>
                            history.push(
                              window.location.pathname + "/" + value.idRol
                            )
                          }
                        >
                          <SettingsIcon />
                        </IconButton>
                      </React.Fragment>
                    )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
        <Dialog
          open={open}
          onClose={this.handleClose}
          fullScreen={fullScreen}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogTitle id="form-dialog-title">Editar rol</DialogTitle>
          <DialogContent>
            <TextField
              id={nombre}
              value={nombre}
              label="Nombre del rol"
              type="search"
              margin="dense"
              autoFocus
              fullWidth
              onChange={this.handleChangeNombre}
            />
            <TextField
              id={descripcion}
              value={descripcion}
              label="Descripción"
              type="search"
              margin="dense"
              autoFocus
              fullWidth
              onChange={this.handleChangeDescripcion}
            />
          </DialogContent>
          <DialogContent>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              id={nivel}
              value={nivel}
              label="Nivel"
              type="number"
              margin="dense"
              autoFocus
              fullWidth
              onChange={this.handleChangeNivel}
            />
            Inactivar
            <Checkbox
              name="Inactivar"
              disableRipple
              className={classes.checkbox}
              checked={estado === "INACTIVO"}
              color="secondary"
              onChange={this.handleChangeInactivar}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancelar
            </Button>
            <Button
              onClick={this.handleSave.bind(this)}
              color="primary"
              variant="contained"
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ItemList.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  handleSave: PropTypes.func
};

ItemList.default = {
  handleSave: () => undefined
};

export default withMobileDialog()(
  withApollo(withRouter(withStyles(styles)(ItemList)))
);
