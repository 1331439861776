import React from "react";
import Icon from "@material-ui/icons/Edit";
import base from "./Base";
import AuthService from "js/utils/AuthService";

export default params => ({
  ...base(),
  label: "Editar",
  icon: <Icon />,
  show: () =>
    ["CANCELADA", "FINALIZADA"].indexOf(params.item.estado) < 0 &&
    AuthService.hasPerms(params.perms),
  tooltip: "Editar acción",
  onclick: () =>
    params.history.push(
      window.location.pathname + "/" + params.itemId + "/edit"
    )
});
