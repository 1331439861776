import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "./MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import styles from "./styles";

class Tree extends React.Component {
  state = { anchorEl: null, data: [], label: "" };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentWillReceiveProps = nextProps => {
    let { data = [], withTodos } = nextProps;
    data = this.getInitialData(data);
    if (withTodos) data = [{ value: "TODOS", label: "Todos", children: data }];
    this.setState({ data });
    const selected = this.getSelected(data);
    this.setState({ label: selected.map(i => i.label).join(", ") });
  };

  componentDidMount = () => this.componentWillReceiveProps(this.props);

  getInitialData = data => {
    data.forEach(e => {
      if (e.checked) this.checkChildren(e);
      else if (e.children && e.children.length) this.getInitialData(e.children);
    });
    return data;
  };

  findByValue = (value, tree, data = this.state.data) => {
    let treeCopy = [];
    Object.assign(treeCopy, tree);
    if (treeCopy.length < 2) return data.find(i => i.value === value);
    const id = treeCopy.shift();
    const partial = data.find(i => i.value === id);
    return this.findByValue(value, treeCopy, partial.children);
  };

  uncheckChildren = data => {
    if (!data.children || !data.children.length) return data;
    data.children.forEach(e => {
      e.checked = false;
      this.uncheckChildren(e);
    });
    return data;
  };

  uncheckParent = (data, tree) => {
    let treeCopy = [];
    Object.assign(treeCopy, tree);
    const id = treeCopy.shift();
    let found;
    found = data.find(i => i.value === id);
    found.checked = false;
    if (treeCopy.length) this.uncheckParent(found.children, treeCopy);
  };

  checkChildren = data => {
    data.checked = true;
    (data.children || []).forEach(e => this.checkChildren(e));
  };

  getSelected = data => {
    let selected = [];
    data.forEach(i => {
      if (i.checked && i.value !== "TODOS") selected.push(i);
      else if (i.children && i.children.length)
        this.getSelected(i.children).forEach(i => selected.push(i));
    });
    return selected;
  };

  onChecked = async (item, tree) => {
    let data = this.state.data;
    let opt = this.findByValue(item.value, tree, data);
    if (opt.value === "TODOS")
      await this.setState(prev => ({ todos: !prev.todos }));
    opt.checked = !opt.checked;
    if (!opt.checked || (opt.value === "TODOS" && !this.state.todos)) {
      this.uncheckChildren(opt);
      this.uncheckParent(data, tree);
    } else this.checkChildren(opt);
    this.setState({ data });
    const selected = this.getSelected(data);
    this.props.onChange(selected);
    this.setState({ label: selected.map(i => i.label).join(", ") });
  };

  render() {
    const { anchorEl, label } = this.state;
    const { defaultLabel, classes, disabled } = this.props;
    const open = Boolean(anchorEl);
    return (
      <div>
        <List onClick={!disabled && this.handleClick} className={classes.list}>
          <ListItem dense button={!disabled}>
            <ListItemText primary={label || defaultLabel} />
            <ListItemSecondaryAction>
              <IconButton>
                <ExpandMore />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: 280,
              width: 400
            }
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            horizontal: "right"
          }}
        >
          <MenuList>
            {this.state.data.map(o => (
              <MenuItem
                {...this.props}
                {...o}
                key={o.value}
                onChecked={this.onChecked}
              />
            ))}
          </MenuList>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(Tree);
