import Enhancer from "./container";
import {
  Template as TemplateWithoutEnhancer,
  Layout as LayoutWithoutEnhancer,
  Landing as LandingWithoutEnhancer,
  Destacados as DestacadosWithoutEnhancer
} from "./Pages";

export const Template = Enhancer(TemplateWithoutEnhancer);
export const Layout = Enhancer(LayoutWithoutEnhancer);
export const Landing = Enhancer(LandingWithoutEnhancer);
export const Destacados = Enhancer(DestacadosWithoutEnhancer);
