export default theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: theme.container.maxWidth,
    margin: theme.container.margin
  },
  field: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  grid: {
    margin: "0"
  },
  gridButton: {
    marginTop: "20px",
    textAlign: "end"
  },
  button: {
    width: "200px",
    margin: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 2}px`
  },
  empty: {
    color: theme.palette.text.disabled
  },
  error: {
    color: "#f44336"
  }
});
