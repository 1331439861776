import React, { PureComponent, Fragment } from "react";
import { Field } from "redux-form";
import { Query } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import AppQraphQL from "js/graphql/resolvers/locacion.resolver";

import { Select } from "redux-form-material-ui";
import { styles } from "js/components/Shared/Form/styles.js";
import { required } from "js/components/Shared/Form/validations.js";
import { intersectionBy } from "lodash";

class Pais extends PureComponent {
  render() {
    const { classes, disabled } = this.props;
    let paisesData = [];
    return (
      <Query
        query={AppQraphQL.queries.getMasterPaises}
        fetchPolicy="network-only"
        errorPolicy="all"
      >
        {({ loading, error, data }) => {
          if (loading || !data) return null;
          paisesData = data.getMasterPaises.filter(
            ({ estado: e }) => e === "ACTIVO"
          );
          return (
            <Fragment>
              <FormControl className={classes ? classes.field : styles.field}>
                <InputLabel htmlFor="pais">País</InputLabel>
                <Field
                  name="paises"
                  component={Select}
                  className={classes ? classes.field : styles.field}
                  disabled={disabled}
                  validate={required}
                  multiple={true}
                  format={value => {
                    if (!value || value.length === 0) return [];
                    const paises = intersectionBy(paisesData, value, "idPais");
                    return paises;
                  }}
                  renderValue={selected => {
                    const paises = intersectionBy(
                      paisesData,
                      selected,
                      "idPais"
                    );
                    return paises
                      .map(pais => {
                        return pais.descripcion;
                      })
                      .join(", ");
                  }}
                >
                  {data.getMasterPaises
                    .filter(({ estado: e }) => e === "ACTIVO")
                    .map((pais, index) => (
                      <MenuItem key={index} value={pais}>
                        {pais.descripcion}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(Pais);
