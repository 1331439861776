import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { default as MaterialSelect } from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import Enhancer from "./container";

const Select = ({ value, onChange, options, classes }) => (
  <FormControl className={classes.FormControl} variant="outlined">
    <MaterialSelect
      value={value}
      onChange={onChange}
      input={<OutlinedInput labelWidth={0} />}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </MaterialSelect>
  </FormControl>
);

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  classes: PropTypes.object
};

Select.displayName = "Select";

export default Enhancer(Select);
