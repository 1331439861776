import React, { PureComponent } from "react";
import { Paper, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./indicadorStyles.js";
import PropTypes from "prop-types";
import classNames from "classnames";
import { numberWithCommas } from "js/utils";
import { _t } from "js/utils/TranslationService";

class PerformanceIndicador extends PureComponent {
  componentDidMount() {
    this.setCanvasesDimensions();
    this.createTriangles();
    this.createSemiCircles();
  }

  /**
   * Retorna el porcentaje del targetAlcanzado con un máximo de 999
   * @param {target} target
   * @param {performance} performance
   */
  getPercentaje = (target, performance) => {
    const result = (performance * 100) / target;
    return result > 1000 ? 999 : result;
  };

  setCanvasesDimensions() {
    const { index } = this.props;
    const canvases = document.querySelectorAll(
      '[class$="canvas-' + index + '"]'
    );
    canvases.forEach(canvas => {
      const parent = canvas.parentElement;
      // - 100 por los paddings horizontales y -40 por los paddings verticales
      const rightPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-right")
      );
      const leftPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-left")
      );
      const topPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-top")
      );
      const bottomPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-bottom")
      );
      canvas.setAttribute(
        "width",
        parent.clientWidth - (rightPadding + leftPadding)
      );
      canvas.setAttribute(
        "height",
        parent.clientHeight - (topPadding + bottomPadding)
      );
    });
  }

  /**
   * Se dibujan en la mitad canvas entonces la posición de sus vertices serán:
   * - Para ambos triangulos:
   *  . El vertice derecho: su componente X es tres cuartos del ancho del canvas (ej: si el ancho es 100 entonces será 75)
   *    y su componente Y es la altura del canvas menos el BOTTOM_OFFSET
   *  . El vertice izquierdo: su componente X es un cuarto cuarto del ancho del canvas (ej: si el ancho es 100 entonces será 25)
   *    y su componente Y es la altura del canvas menos el BOTTOM_OFFSET
   * - Para el triangulo del fondo el vertico de arriba es el TOP_OFFSET
   * - Para el triangulo con color es dinámico y calculado, su valoe va entre entre la altura menos el BOTTOM_OFFSET y el TOP_OFFSET
   * Las lineas se dibujan de la siguiente manera:
   * - La línea que indica el objetivo se dibuja desde el top del triangulo del fondo hacia el ancho del canvas (hacia la derecha)
   * - La línea que indica el target alcanzado se dibuja desde el top del triangulo con color hacia el principio del canvas (hacia la izquierda)
   */
  createTriangles() {
    // Empezando a dibujar el triangulo con un un Y de 10 y hasta un Y de height - 10
    const TOP_OFFSET = 20;
    const BOTTOM_OFFSET = 20;
    const RIGHT_X = 3.5 / 5;
    const LEFT_X = 1.5 / 5;
    const TOP_X = (RIGHT_X + LEFT_X) / 2;
    const {
      index,
      performance: { target, performance },
      color
    } = this.props;
    // Creando el triangulo del fondo, el que indica el objetivo target
    const objectiveCanvas = document.getElementById("objetivo-canvas-" + index);
    const lineCanvas = document.getElementById("objetivo-line-canvas-" + index);
    const width = objectiveCanvas.clientWidth;
    const height = objectiveCanvas.clientHeight;

    // PRIMER TRIANGULO: El que sería el objetivo total
    this.drawTriangle(
      objectiveCanvas,
      width * TOP_X,
      TOP_OFFSET,
      width * RIGHT_X,
      height - BOTTOM_OFFSET,
      width * LEFT_X,
      height - BOTTOM_OFFSET,
      "#ccc"
    );
    // PRIMERA LINEA: La que indica el objetivo total
    this.drawLine(
      lineCanvas,
      width * TOP_X,
      TOP_OFFSET,
      width,
      "#4D5D67",
      numberWithCommas(target),
      "#4D5D67",
      _t("Target"),
      "#4D5D67",
      "right"
    );

    // Creando el triangulo con color, el que indica el objetivo logrado
    const performanceCanvas = document.getElementById(
      "performance-canvas-" + index
    );
    // porcentaje del performance para sacar el height del performance en relación al height del canvas
    // después restar el performance height del height porque el punto inicial del canvas empieza arriba a la izquiera
    // pero lo estamos dibujando de abajo para arriba. Si es mayor que 100 dejarlo en 100 para que no sobrepase el triangulo
    let performancePercentage = this.getPercentaje(target, performance);
    if (performancePercentage > 100) {
      performancePercentage = 100;
    }
    const performanceHeight =
      height -
      BOTTOM_OFFSET -
      (performancePercentage * (height - BOTTOM_OFFSET - TOP_OFFSET)) / 100;

    // SEGUNDO TRIANGULO: El que sería el target alcanzado
    this.drawTriangle(
      performanceCanvas,
      width * TOP_X,
      performanceHeight,
      width * RIGHT_X,
      height - BOTTOM_OFFSET,
      width * LEFT_X,
      height - BOTTOM_OFFSET,
      color
    );
    // SEGUNDA LINEA: La que indica el target alcanzado
    this.drawLine(
      lineCanvas,
      width * TOP_X,
      performanceHeight,
      0,
      "#4D5D67",
      numberWithCommas(performance),
      color,
      _t("Avance actual"),
      "#4D5D67",
      "left"
    );
  }

  drawTriangle(canvas, topX, topY, rightX, rightY, leftX, leftY, color) {
    if (canvas.getContext) {
      const context = canvas.getContext("2d");
      context.beginPath();
      context.moveTo(topX, topY);
      context.lineTo(rightX, rightY);
      context.lineTo(leftX, leftY);
      context.lineTo(topX, topY);
      context.fillStyle = color;
      context.fill();
    }
  }

  drawLine(
    canvas,
    x,
    y,
    endX,
    color,
    topText,
    topColor,
    bottomText,
    bottomColor,
    align
  ) {
    if (canvas.getContext) {
      const TEXT_X_OFFSET = align === "right" ? -3 : 3;
      const ctx = canvas.getContext("2d");
      ctx.setLineDash([2, 2]); /*dashes are 5px and spaces are 3px*/
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(endX, y);
      ctx.strokeStyle = color;
      ctx.stroke();
      if (topText) {
        ctx.font = "bold 16px Roboto";
        ctx.fillStyle = topColor;
        ctx.textAlign = align;
        // -3 para que quede bien prolijo con la línea el texto
        ctx.fillText(topText, endX + TEXT_X_OFFSET, y - 3);
      }
      if (bottomText) {
        ctx.font = "italic 13px Roboto";
        ctx.fillStyle = bottomColor;
        ctx.textAlign = align;
        // -3 y + 13 para que quede bien prolijo con la línea el texto
        ctx.fillText(bottomText, endX + TEXT_X_OFFSET, y + 13);
      }
    }
  }

  createSemiCircles() {
    const {
      index,
      performance: { target, performance },
      color
    } = this.props;
    // Creando el triangulo del fondo, el que indica el objetivo target
    const objectiveCanvas = document.getElementById(
      "objetivo-percentage-canvas-" + index
    );
    const width = objectiveCanvas.clientWidth;
    const height = objectiveCanvas.clientHeight;
    this.drawSemiCircle(
      objectiveCanvas,
      width / 2,
      height,
      width / 2,
      Math.PI,
      0,
      "#ccc"
    );

    // Creando el semi cirulo con color, el que indica el porcentage del objetivo logrado
    const performanceCanvas = document.getElementById(
      "performance-percentage-canvas-" + index
    );
    const performancePercentage = this.getPercentaje(target, performance);
    this.drawSemiCircle(
      performanceCanvas,
      width / 2,
      height,
      width / 2,
      Math.PI,
      Math.PI * (1 + performancePercentage / 100),
      color,
      Math.round(performancePercentage)
    );
  }

  drawSemiCircle(
    canvas,
    x,
    y,
    radius,
    startAngle,
    endAngle,
    color,
    percentage
  ) {
    const STROKE = 20;
    if (canvas.getContext) {
      const ctxArc = canvas.getContext("2d");
      ctxArc.beginPath();
      ctxArc.arc(x, y, radius - STROKE, startAngle, endAngle, false);
      ctxArc.strokeStyle = color;
      ctxArc.lineWidth = STROKE;
      ctxArc.stroke();

      if (percentage || percentage === 0) {
        ctxArc.font = "bold 26px Roboto";
        ctxArc.fillStyle = color;
        ctxArc.textAlign = "center";
        ctxArc.fillText(percentage + "%", x, y);
      }
    }
  }

  render() {
    const {
      index,
      classes,
      performance: { objetivo }
    } = this.props;
    return (
      <Paper className={classes.objetivoContainer} elevation={2}>
        <Grid className={classes.objetivoWrapper} container spacing={8}>
          <Grid className={classes.objetivoTitle} item xs={12}>
            {objetivo.objetivo}
          </Grid>
          <Grid className={classes.objetivoNumbers} item xs={12}>
            <canvas
              id={"objetivo-canvas-" + index}
              className={classNames(classes.canvas, "objetivo-canvas-" + index)}
            />
            <canvas
              id={"performance-canvas-" + index}
              className={classNames(
                classes.canvas,
                classes.indicadorCanvas,
                "performance-canvas-" + index
              )}
            />
            <canvas
              id={"objetivo-line-canvas-" + index}
              className={classNames(
                classes.canvas,
                classes.indicadorCanvas,
                "objetivo-line-canvas-" + index
              )}
            />
          </Grid>
          <Grid className={classes.objetivoPercentaje} item xs={12}>
            <canvas
              id={"objetivo-percentage-canvas-" + index}
              className={classNames(
                classes.canvas,
                "objetivo-percentage-canvas-" + index
              )}
            />
            <canvas
              id={"performance-percentage-canvas-" + index}
              className={classNames(
                classes.canvas,
                classes.indicadorCanvas,
                "performance-percentage-canvas-" + index
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

PerformanceIndicador.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.string,
  performance: PropTypes.any,
  color: PropTypes.string
};

export default withStyles(styles)(PerformanceIndicador);
