import React, { Fragment, Component } from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import Slider from "js/components/Shared/Slider";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Dialog from "../Dialog";
import { capitalizeFirstLetter } from "../../../utils/Helper";
import LikeIcon from "./LikeIcon.container";
import { numberWithCommas } from "js/utils";
import Cucarda from "../../../components/Cucarda";
import { _t } from "js/utils/TranslationService";
import Tooltip from "@material-ui/core/Tooltip";
import Redirect from "js/components/Shared/Tabla/TableCells/Redirect";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class Descripcion extends Component {
  render() {
    const {
      classes,
      eleccionProducto,
      productoActions,
      cantidad,
      mensaje,
      isLogged,
      programa
    } = this.props;
    if (isLogged && mensaje) {
      this.props.openNotification(mensaje);
    }
    const proveedor = this.props.eleccionProducto.producto.proveedor;
    const producto = this.props.eleccionProducto.producto;
    const favPerm = AuthService.hasPerms([Roles.FAVORITO_W]);

    const programLogo = {
      src: programa.template.header.logoPrincipal
        ? programa.template.header.logoPrincipal.url
        : null
    };
    return (
      <Fragment>
        <Grid container spacing={40} className={classes.sectionContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.cucardaContainer}
          >
            {eleccionProducto.cucarda && (
              <Cucarda
                cucardaType={eleccionProducto.cucarda}
                extraClass={classes.cucardaProductDetail}
              />
            )}
            <Slider
              classes={{
                sliderContainer: classes.sliderContainer,
                carousel: classes.carousel
              }}
              extraOptions={{ showThumbs: false, showStatus: false }}
              contenido={{
                images:
                  eleccionProducto.producto.imagenes &&
                  eleccionProducto.producto.imagenes.length !== 0
                    ? eleccionProducto.producto.imagenes
                    : [programLogo]
              }}
              isLogoImage={
                eleccionProducto.producto.imagenes &&
                eleccionProducto.producto.imagenes.length !== 0
                  ? false
                  : true
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Tooltip
              title={eleccionProducto.producto.nombre}
              classes={{
                tooltip: classes.tooltip,
                popper: classes.popper,
                tooltipPlacementBottom: classes.tooltipPlacementBottom
              }}
              interactive
            >
              <Typography variant="h5">
                {capitalizeFirstLetter(eleccionProducto.producto.nombre)}
              </Typography>
            </Tooltip>
            <Grid container justify="space-between">
              <Grid item xs={11}>
                <Typography
                  variant="h6"
                  gutterBottom
                  color="primary"
                  className={classes.points}
                >
                  {numberWithCommas(eleccionProducto.puntos)} {_t("Puntos")}
                </Typography>
              </Grid>
              {favPerm && (
                <Grid item xs={1}>
                  <LikeIcon color="primary" />
                </Grid>
              )}
            </Grid>
            <Divider className={classes.divider} />
            <Typography
              variant="body1"
              gutterBottom
              className={classes.productDescription}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `<div>${_t(eleccionProducto.producto.copete) ||
                    ""}</div>`
                }}
              />
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.productDescription}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `<div>${_t(eleccionProducto.producto.descripcion) ||
                    ""}</div>`
                }}
              />
            </Typography>
            {((proveedor.publicaPaginaWeb && proveedor.urlPaginaWeb) ||
              producto.paginaWeb) && (
              <Typography
                variant="body2"
                gutterBottom
                className={classes.productDescription}
              >
                <Redirect
                  path={producto.paginaWeb || proveedor.urlPaginaWeb}
                  value={producto.paginaWeb || proveedor.urlPaginaWeb}
                />
              </Typography>
            )}
            <Grid container>
              <Grid item xs={12}>
                {isLogged && (
                  <Typography variant="button" gutterBottom>
                    {_t("Cantidad")}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={16} justify="flex-start">
                  {isLogged && (
                    <Grid item xs={6}>
                      <Grid
                        container
                        justify="flex-end"
                        alignItems="center"
                        className={classes.increaseDecreaseBtnContainer}
                      >
                        <Grid item xs={4}>
                          <button
                            className={classes.increaseDecreaseBtn}
                            onClick={() =>
                              productoActions.decrementarCantidad(-1)
                            }
                          >
                            -
                          </button>
                        </Grid>
                        <Grid item xs={4}>
                          <input
                            type="number"
                            value={cantidad}
                            className={classes.counter}
                            name="cantidad"
                            onChange={e =>
                              productoActions.cambiarCantidad(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <button
                            className={classes.increaseDecreaseBtn}
                            onClick={() =>
                              productoActions.incrementarCantidad(1)
                            }
                          >
                            +
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={isLogged ? 6 : 12}>
                    <Button
                      onClick={productoActions.abrirCanjePopup}
                      disabled={cantidad < 1 || !isLogged}
                      variant="contained"
                      color="primary"
                      className={classnames(classes.button, classes.canjear)}
                    >
                      {isLogged
                        ? `${_t("Canjear")}`
                        : `${_t("Debes iniciar sesión para poder canjear")}`}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isLogged && <Dialog />}
      </Fragment>
    );
  }
}

export default Descripcion;
