import color from "@material-ui/core/colors/amber";

export const styles = theme => ({
  container: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      justifyContent: "center"
    }
  },
  containerActionButtons: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      gap: "0.6em",
      marginBottom: "0.5em"
    }
  },
  containerCancelButton: {
    justify: "flex-start",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center"
    }
  },
  actionButton: {
    margin: "0 0 0 1em",
    width: "35%",
    [theme.breakpoints.down("xs")]: {
      width: "48%",
      margin: "0 0 0 0"
    },
    [theme.breakpoints.down(468)]: {
      width: "98%"
    }
  },
  cancelButton: {
    width: "35%",
    [theme.breakpoints.down("xs")]: {
      width: "98%"
    }
  }
});
