import client from "../App/client.graphql.js";
import UsuarioQraphQL from "js/graphql/resolvers/usuario.resolver";
import AuthService from "./AuthService";
import { REQUIREMENTS, PATH } from "js/models/Permiso";
import { uniq, differenceBy } from "lodash";

const checkAcciones = acciones => {
  const todos = acciones.filter(
    ({ direccionamientoObligatorio, participaciones }) =>
      direccionamientoObligatorio === "TODOS" && !participaciones.length
  );
  const soloLogin = differenceBy(
    acciones.filter(
      ({ direccionamientoObligatorio, participaciones }) =>
        direccionamientoObligatorio === "SOLO_LOGIN" && !participaciones.length
    ),
    PermService.getAcciones().map(_id => ({ _id })),
    "_id"
  );
  const req = soloLogin.concat(todos).map(({ _id, tipo }) => ({
    req: REQUIREMENTS[tipo] || REQUIREMENTS.TRIVIA,
    path: `/${_id}`
  }));
  return req || [];
};

export default class PermService {
  static listener;

  static setRequirements(requirements) {
    window.localStorage.setItem("requirements", JSON.stringify(requirements));
  }

  static getRequirements() {
    return JSON.parse(window.localStorage.getItem("requirements")) || [];
  }

  static addAccion(_id) {
    let acciones = PermService.getAcciones();
    acciones.push(_id);
    window.localStorage.setItem("acciones", JSON.stringify(uniq(acciones)));
    PermService.init();
  }

  static getAcciones() {
    return JSON.parse(window.localStorage.getItem("acciones")) || [];
  }

  static isAllowed(requirements) {
    const misRequirements = PermService.getRequirements();
    for (let req of requirements) {
      const found = misRequirements.find(r => [r, r.req].indexOf(req) > -1);
      if (found) return PATH[found.req || found].concat(found.path || "");
    }
    return true;
  }

  static async init() {
    if (PermService.listener) PermService.listener.unsubscribe();
    PermService.listener = client
      .watchQuery({
        query: UsuarioQraphQL.queries.getRedirect,
        errorPolicy: "all",
        fetchPolicy: "no-cache"
      })
      .subscribe({
        next: ({ data, loading }) =>
          !loading && PermService.buildRequirements(data)
      });
  }

  static buildRequirements(data) {
    let requirements = [];
    const isPublic = AuthService.isPublic();
    const htmlExpectativa = AuthService.getHtmlExpectativa();
    try {
      const {
        isLoggedIn,
        misDefinicionesPerfil: mandatory = [],
        miUsuario: {
          usoClaveDeUnUso,
          perfil,
          perfil: { otros }
        },
        misAcciones: { itemsPagina: acciones = [] }
      } = data;

      if (htmlExpectativa && !isLoggedIn) {
        requirements.push(REQUIREMENTS.HTML_EXPECTATIVA);
      }
      if (!isPublic && !isLoggedIn)
        requirements.push(REQUIREMENTS.PUBLICO_O_LOGUEADO);
      if (!!usoClaveDeUnUso) requirements.push(REQUIREMENTS.UPDATE_PASSWORD);

      const fields = { ...perfil, ...JSON.parse(otros) };
      const perfilIncompleto = mandatory.some(p => !fields[p.key]);
      if (perfilIncompleto) requirements.push(REQUIREMENTS.COMPLETAR_PERFIL);
      const redirectActions = checkAcciones(acciones);
      requirements = requirements.concat(redirectActions);
    } catch (e) {
      const isLoggedIn = !!AuthService.getIdUsuario();
      if (!isPublic && !isLoggedIn)
        requirements.push(REQUIREMENTS.PUBLICO_O_LOGUEADO);
      if (htmlExpectativa && !isLoggedIn) {
        requirements.push(REQUIREMENTS.HTML_EXPECTATIVA);
      }
    }
    PermService.setRequirements(requirements);
    document.dispatchEvent(new Event("redirectByPermisos"));
  }
}
