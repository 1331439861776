import React, { PureComponent } from "react";
import { Paper, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";

import { numberWithCommas } from "js/utils";
import { _t } from "js/utils/TranslationService";
import styles from "./indicadorStyles.js";

class CircularIndicator extends PureComponent {
  componentDidMount() {
    this.setCanvasesDimensions();
    this.createCircles();
  }

  /**
   * Retorna el porcentaje del targetAlcanzado con un máximo de 999
   * @param {target} target
   * @param {performance} performance
   */
  getPercentaje = (target, performance) => {
    const result = (performance * 100) / target;
    return result > 1000 ? 999 : result;
  };

  setCanvasesDimensions() {
    const { index } = this.props;
    const canvases = document.querySelectorAll(
      '[class$="canvas-' + index + '"]'
    );
    let parent = null;
    canvases.forEach(canvas => {
      if (!parent) {
        parent = canvas.parentElement;
        parent.style.height = `${parent.clientWidth}px`;
      }
      const rightPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-right")
      );
      const leftPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-left")
      );
      const topPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-top")
      );
      const bottomPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-bottom")
      );
      // El alto del indicador circular debe ser igual al ancho
      canvas.setAttribute(
        "width",
        parent.clientWidth - (rightPadding + leftPadding)
      );
      canvas.setAttribute(
        "height",
        parent.clientWidth - (topPadding + bottomPadding)
      );
    });
  }

  createCircles() {
    const {
      index,
      performanceObjetivo: { performance, targetsObjetivo },
      parametrosVisuales: {
        colorFondo = "#ccc",
        colorAvance = "#ce762e",
        colorTextoAvance
      }
    } = this.props;

    let target = 0;
    targetsObjetivo.forEach(targetObjetivo => {
      if (target < targetObjetivo.target) {
        target = targetObjetivo.target;
      }
    });

    const objectiveCanvas = document.getElementById(
      "objetivo-percentage-canvas-" + index
    );
    const width = objectiveCanvas.clientWidth;
    const height = objectiveCanvas.clientHeight;
    this.drawCircle(
      objectiveCanvas,
      width / 2,
      height / 2,
      width / 2,
      0,
      Math.PI * 2,
      colorFondo,
      null,
      false
    );

    const performanceCanvas = document.getElementById(
      "performance-percentage-canvas-" + index
    );
    const performancePercentage = this.getPercentaje(target, performance);
    const percentageForCircle =
      performancePercentage > 100 ? 100 : performancePercentage;
    this.drawCircle(
      performanceCanvas,
      width / 2,
      height / 2,
      width / 2,
      0,
      percentageForCircle === 100
        ? Math.PI * 2
        : percentageForCircle > 0
          ? Math.PI * 2 - Math.PI * 2 * (percentageForCircle / 100)
          : 0,
      colorAvance,
      Math.round(performancePercentage),
      true,
      colorTextoAvance ? colorTextoAvance : colorAvance
    );
  }

  drawCircle(
    canvas,
    x,
    y,
    radius,
    startAngle,
    endAngle,
    color,
    percentage,
    direction,
    colorTexto
  ) {
    const STROKE = 20;
    if (canvas.getContext) {
      const ctxArc = canvas.getContext("2d");
      ctxArc.beginPath();
      ctxArc.arc(x, y, radius - STROKE, startAngle, endAngle, direction);
      ctxArc.strokeStyle = color;
      ctxArc.lineWidth = STROKE;
      ctxArc.stroke();

      if (percentage || percentage === 0) {
        ctxArc.font = "bold 46px Roboto";
        ctxArc.fillStyle = colorTexto;
        ctxArc.textAlign = "center";
        ctxArc.fillText(percentage + "%", x, y + 10);
      }
    }
  }

  renderObjetivo() {
    const {
      index,
      classes,
      performanceObjetivo: { objetivo, targetsObjetivo, performance },
      parametrosVisuales: {
        colorTextoTarget = "#4D5D67",
        colorTextoAvance = "#ce762e",
        colorCheckIcon = "#4EC230",
        colorNombreObjetivo = "#4D5D67",
        sizeNombreObjetivo = "16",
        fontFamily = "Roboto",
        sizeNumeros = "16",
        sizeTextos = "14",
        showPuntosTargets = true
      }
    } = this.props;
    const showTargetNumber = targetsObjetivo.length > 1;

    return (
      <Grid className={classes.objetivoWrapper} container spacing={8}>
        <Grid
          className={classes.objetivoTitle}
          style={{
            color: colorNombreObjetivo,
            fontSize: `${sizeNombreObjetivo}px`,
            fontFamily: fontFamily
          }}
          item
          xs={12}
        >
          {objetivo.nombre}
        </Grid>
        <Grid
          className={classNames(classes.chartContainer, classes.circularChart)}
          item
          xs={12}
        >
          <canvas
            id={"objetivo-percentage-canvas-" + index}
            className={classNames(
              classes.canvas,
              "objetivo-percentage-canvas-" + index
            )}
          />
          <canvas
            id={"performance-percentage-canvas-" + index}
            className={classNames(
              classes.canvas,
              classes.indicadorCanvas,
              "performance-percentage-canvas-" + index
            )}
          />
        </Grid>
        <Grid item xs={12} className={classes.multipleTargetsContainer}>
          <div
            style={{
              fontFamily: fontFamily,
              color: colorTextoAvance,
              fontWeight: "bold"
            }}
          >
            <span style={{ fontSize: `${sizeTextos}px` }}>
              {`${_t("Avance actual")}: `}
            </span>
            <span style={{ fontSize: `${sizeNumeros}px` }}>
              {`${numberWithCommas(performance)}`}
            </span>
          </div>
          {targetsObjetivo.map((targetObjetivo, index) => {
            return (
              <div style={{ color: colorTextoTarget, fontFamily: fontFamily }}>
                <span style={{ fontSize: `${sizeTextos}px` }}>
                  {`${_t("Target")} ${showTargetNumber ? index + 1 : ""}: `}
                </span>
                <span style={{ fontSize: `${sizeNumeros}px` }}>
                  {`${numberWithCommas(targetObjetivo.target)}`}
                </span>
                {targetObjetivo.puntos &&
                  showPuntosTargets && (
                    <span style={{ fontSize: `${sizeTextos}px` }}>
                      {` (${numberWithCommas(targetObjetivo.puntos)} ${_t(
                        "puntos"
                      )})`}
                    </span>
                  )}
                {performance >= targetObjetivo.target && (
                  <CheckIcon
                    style={{
                      color: colorCheckIcon,
                      fill: colorCheckIcon,
                      marginLeft: "5px"
                    }}
                  />
                )}
              </div>
            );
          })}
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      classes,
      parametrosVisuales: { containerShadow }
    } = this.props;

    return (
      <React.Fragment>
        {containerShadow ? (
          <Paper className={classes.objetivoContainer} elevation={1}>
            {this.renderObjetivo()}
          </Paper>
        ) : (
          <React.Fragment>{this.renderObjetivo()}</React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

CircularIndicator.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.string,
  performanceObjetivo: PropTypes.any,
  parametrosVisuales: PropTypes.any
};

export default withStyles(styles)(CircularIndicator);
