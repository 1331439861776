import React, { Fragment } from "react";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import client from "js/App/client.graphql.js";
import CanjeGraphQL from "js/graphql/resolvers/canje.resolver";
import get from "lodash/get";
import ErrorList from "js/components/Shared/ErrorList";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogEstado extends React.Component {
  state = {
    fecha: null,
    maxDate: moment(),
    errors: null
  };

  async componentDidMount(props) {
    const { selected } = this.props;
    const idCanje = selected[0];
    const { data } = await client.query({
      query: CanjeGraphQL.queries.getCanje,
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      variables: { idCanje }
    });
    if (data)
      this.setState({
        fecha: get(data, "getCanjes.itemsPagina[0].fechaEntrega", null)
      });
  }

  sendValues = async (fecha, selected) => {
    if (!fecha) return;
    const canjes = selected.map(idCanje => ({
      fechaEntrega: moment(fecha).toISOString(),
      idCanje
    }));
    const { errors, data } = await client.mutate({
      mutation: CanjeGraphQL.mutations.bulkUpdateCanje,
      errorPolicy: "all",
      variables: { canjes }
    });
    if (errors) {
      this.setState({ errors: errors[0] });
      return;
    }
    if (data) {
      this.setState({ errors: null });
      const message = data
        ? "Se ha actualizado la fecha de entrega"
        : "No se ha podido actualizar la fecha de entrega";
      this.props.openNotification(message);
      this.props.refetch({});
    }
    this.props.handleClose();
  };

  handleChange = date => {
    this.setState({ fecha: date });
  };
  render() {
    const { open, handleClose, classes } = this.props;
    return (
      <Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={classes.dialogTitle}
          >
            Cambiar fecha de entrega
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              maxDate={this.state.maxDate}
              input={{ onChange: this.handleChange, value: this.state.fecha }}
            />
            <ErrorList errors={this.state.errors} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Volver
            </Button>

            <Button
              onClick={() =>
                this.sendValues(this.state.fecha, this.props.selected)
              }
              disabled={!this.state.fecha}
              variant="contained"
              color="primary"
            >
              Cambiar fecha
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DialogEstado.defaultProps = {};

DialogEstado.propTypes = {};

export default DialogEstado;
