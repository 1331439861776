import React from "react";
import Tabla from "js/pages/Unilever/Med/Tabla";

export const Med = props => {
  return (
    <div>
      <Tabla {...props} />
    </div>
  );
};

export default Med;
