import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  FormControl: {
    "& .chrome-picker": {
      width: "100% !important"
    }
  },
  Input: {
    "& input": {
      textTransform: "uppercase"
    }
  }
});

export default withStyles(styles);
