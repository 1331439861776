import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, change, getFormMeta, FieldArray } from "redux-form";
import { Grid, Button, Paper, Typography, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowBack from "@material-ui/icons/ArrowBack";

import { styles } from "js/components/Shared/Form/styles.js";
import {
  InputTextField,
  InputNumberField
} from "js/components/Shared/Form/Fields";

const renderTargetsPorObjetivo = ({ fields, targets, disabled }) => {
  if (fields.length === 0) {
    targets.forEach(target => {
      fields.push({
        idTarget: target.idTarget,
        idUsuario: target.idUsuario,
        nombre: target.nombre,
        target: target.target
      });
    });
  }
  return (
    <Grid item container xs={12} spacing={16}>
      {fields.map((item, index) => {
        return (
          <Grid key={index} xs={12} sm={6} item container spacing={8}>
            <Grid item xs={12} sm={8}>
              <InputTextField
                name={`${item}.nombre`}
                label={`Preventista`}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputNumberField
                name={`${item}.target`}
                label={"Target"}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <InputTextField
                name={`${item}.idUsuario`}
                disabled={true}
                style={{ display: "none" }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputTextField
                name={`${item}.idTarget`}
                disabled={true}
                style={{ display: "none" }}
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      campana,
      targetsPorObjetivos,
      openDialog,
      fechaLimiteSupervisores,
      tienePendientes
    } = this.props;

    return (
      <form className={classes.container} onSubmit={handleSubmit}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h5">
              <IconButton
                onClick={() => history.push(`/targets-mis-preventistas`)}
              >
                <ArrowBack />
              </IconButton>
              {`Campaña: ${campana ? campana.nombre : ""}. ${
                fechaLimiteSupervisores
                  ? "Puede mofidicar los targets hasta el " +
                    fechaLimiteSupervisores
                  : "No puede hacer modificaciones"
              }`}
            </Typography>
          </Grid>

          {errors &&
            errors.length !== 0 && (
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors.map(e => (
                    <li>{e.message}</li>
                  ))}
                </ul>
              </Grid>
            )}

          {targetsPorObjetivos &&
            targetsPorObjetivos.length > 0 &&
            targetsPorObjetivos.map((targetsPorObjetivo, index) => {
              return (
                <Paper
                  style={{
                    width: "100%",
                    padding: "15px",
                    marginBottom: "20px"
                  }}
                  key={index}
                >
                  <Grid item xs={12} style={{ marginBottom: "10px" }}>
                    {targetsPorObjetivo.targetMed ? (
                      <Typography variant="h6">
                        {`Objetivo: ${
                          targetsPorObjetivo.objetivo
                        }. Total MED: ${targetsPorObjetivo.targetMed}`}
                      </Typography>
                    ) : (
                      <Typography variant="h6">
                        {`Objetivo: ${targetsPorObjetivo.objetivo}.`}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray
                      name={`${targetsPorObjetivo.idObjetivo}`}
                      component={renderTargetsPorObjetivo}
                      disabled={disabled}
                      targets={targetsPorObjetivo.targets}
                    />
                  </Grid>
                </Paper>
              );
            })}

          {errors &&
            errors.length !== 0 && (
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors.map(e => (
                    <li>{e.message}</li>
                  ))}
                </ul>
              </Grid>
            )}

          <Grid className={classes.gridButton} item xs={12}>
            <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              style={{ marginRight: 20 }}
              onClick={() => history.push(`/targets-mis-preventistas`)}
            >
              {"Volver"}
            </Button>
            {!disabled &&
              tienePendientes && (
                <Button
                  type="button"
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  style={{ marginRight: 20 }}
                  disabled={disabled || pristine || submitting}
                  onClick={() => openDialog()}
                >
                  {"Aprobar"}
                </Button>
              )}
            {!disabled && (
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                disabled={disabled || pristine || submitting}
              >
                {"Guardar"}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    );
  }
}

Form = reduxForm({
  form: "formTargetsPorCampana",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

Form = connect(
  state => ({
    formMeta: getFormMeta("formTargetsPorCampana")(state)
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formTargetsPorCampana", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
