import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import defaultStyles from "./styles";
import Navbar from "../Navbar";
import UserInfo from "../UserInfo";
import { Link } from "react-router-dom";
import { getUrl } from "js/utils";
import { cx, css } from "emotion";

class HeaderInside extends React.Component {
  state = {
    drawerOpen: false,
    auth: false,
    anchorEl: null,
    anchor: "left"
  };

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  handleChangeAnchor = event => {
    this.setState({
      anchor: event.target.value
    });
  };

  render() {
    const { programa, classes } = this.props;
    const { template } = programa;
    const style = Object.assign({}, template.header.css);
    style.backgroundColor += "!important";
    return (
      <AppBar position="static" className={cx(classes.appbar, css(style))}>
        <Toolbar
          className={cx(classes.toolbar, css(template.header.menu.toolbar))}
        >
          <Link to="/" underline={"none"}>
            <img
              src={`${getUrl(template.header.logoPrincipal.url)}`}
              alt="Logo cabecera"
              className={classes.logo}
            />
          </Link>
          <Navbar />
          <UserInfo />
        </Toolbar>
      </AppBar>
    );
  }
}

HeaderInside.propTypes = {
  authenticated: PropTypes.bool
};

HeaderInside.defaultProps = {
  authenticated: false,
  styles: defaultStyles,
  programa: {
    template: {
      header: {
        logoPrincipal: { url: "" }
      }
    }
  }
};

export default HeaderInside;
