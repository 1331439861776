import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import AppQraphQL from "js/graphql/resolvers/rubro.resolver";
import { ListofChildrenField } from "./index.js";
import { isNil } from "lodash";

class SubRubroField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subrubros: []
    };
  }

  componentWillReceiveProps(props) {
    const { idRubro } = props;
    idRubro && this.fetchSubRubros(idRubro);
  }

  async fetchSubRubros(idRubro) {
    const { client } = this.props;
    await client
      .query({
        query: AppQraphQL.queries.getMasterSubrubros,
        variables: { idRubro: parseInt(idRubro, 10) },
        fetchPolicy: "cache-first",
        errorPolicy: "all"
      })
      .then(res => {
        this.setState({
          subrubros: res.data.getMasterSubrubros
        });
      });
  }

  render() {
    const { idRubro, required, disabled } = this.props;
    const { subrubros } = this.state;
    return (
      <ListofChildrenField
        name="idSubrubro"
        label="Subcategoría"
        disabled={isNil(idRubro) || disabled}
        required={required}
      >
        {subrubros.map((item, index) => (
          <MenuItem value={parseInt(item.idSubrubro, 10)} key={index}>
            {item.descripcion}
          </MenuItem>
        ))}
      </ListofChildrenField>
    );
  }
}

SubRubroField.propTypes = {
  idRubro: PropTypes.number
};

export default withApollo(withRouter(SubRubroField));
