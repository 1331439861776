import React, { Fragment } from "react";
//import Input from "@material-ui/core/Input";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Search from "@material-ui/icons/Search";
import { CatalogoContext } from "../../catalogo-context";
import { _t } from "js/utils/TranslationService";

function FilterSearch(props) {
  return (
    <Fragment>
      <CatalogoContext.Consumer>
        {({ filterBy, searchCriteria }) => (
          <FormControl
            fullWidth
            classes={{
              root: props.classes.root
            }}
          >
            <InputBase
              margin="dense"
              placeholder={_t("Buscar")}
              fullWidth={true}
              id="adornment-search"
              value={searchCriteria.searchBy || ""}
              onChange={event => filterBy.searchBy(event.target.value)}
              classes={{
                input: props.classes.input
              }}
              endAdornment={
                <InputAdornment position="start">
                  <Icon
                    classes={{
                      root: props.classes.icon
                    }}
                    aria-label="Toggle password visibility"
                  >
                    <Search />
                  </Icon>
                </InputAdornment>
              }
            />
          </FormControl>
        )}
      </CatalogoContext.Consumer>
    </Fragment>
  );
}

FilterSearch.propTypes = {};

export default FilterSearch;
