import React from "react";
import { Field } from "redux-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { Switch } from "redux-form-material-ui";
import { isRequired } from "js/helpers/validations";
import { styles } from "../styles.js";

const SwitchWithLabelAndError = inputProps => {
  const {
    meta: { touched, error },
    input: { name },
    label,
    disabled
  } = inputProps;
  return (
    <FormControl error={Boolean(touched && error)} fullWidth>
      <FormControlLabel
        label={label}
        control={
          <Switch
            {...inputProps}
            disabled={disabled}
            value={inputProps.input.value ? "true" : "false"}
            onChange={inputProps.input.onChange}
            id={name}
          />
        }
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const SwitchField = ({ name, label, required, classes, disabled }) => (
  <Field
    validate={(() => {
      let validations = [];
      if (required) {
        validations.push(isRequired);
      }
      return validations;
    })()}
    name={name}
    disabled={disabled}
    component={SwitchWithLabelAndError}
    label={label}
    className={classes ? classes.field : styles.field}
  />
);

export default SwitchField;
