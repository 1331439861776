import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import QRCode from "qrcode.react";
import moment from "moment";
import { _t } from "js/utils/TranslationService";

import "./solicitud.css";

class SolicitudCupon extends React.PureComponent {
  render() {
    const { programLogo, beneficio, idSolicitud } = this.props;

    return (
      <Paper className={"cupon-container"}>
        <Grid container spacing={0} style={{ textAlign: "left" }}>
          <Grid
            item
            xs={3}
            className={"cupon-left-container cupon-inner-container"}
          >
            <Grid
              container
              spacing={24}
              className={"cupon-left-inner-container"}
            >
              <Grid item xs={12}>
                <img
                  src={programLogo}
                  alt="logo-programa"
                  className={"cupon-image"}
                />
              </Grid>
              <Grid item xs={12}>
                <QRCode value={`ID Solicitud: ${idSolicitud}`} size={175} />
                <br />
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className={"id-solicitud"}
                >
                  ID Solicitud: {idSolicitud}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={9}
            className={"cupon-right-container cupon-inner-container"}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} className={"cupon-right-top"}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Grid container spacing={16}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          className={"no-margin"}
                        >
                          {beneficio.nombre}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="h1"
                          gutterBottom
                          className={"no-margin tipo"}
                        >
                          {beneficio.idTipo ? beneficio.idTipo.tipo : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          className={"no-margin descripcion"}
                        >
                          {beneficio.copete}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          className={"no-margin descripcion"}
                        >
                          {beneficio.descripcion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} className={"cupon-image-container"}>
                    <img
                      src={`${beneficio.logo ? beneficio.logo : programLogo}`}
                      alt="logo-beneficio"
                      className={"cupon-image"}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={"cupon-right-bottom"}>
                <Typography variant="subtitle1" gutterBottom>
                  <b>{_t("Condiciones:")}</b>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  {beneficio.condiciones}
                  <br />
                  <br />
                  {beneficio.tipoValidez === "SIEMPRE" && (
                    <span>{_t("El beneficio no tiene vencimiento.")}</span>
                  )}
                  {beneficio.tipoValidez === "POR_DIAS" && (
                    <span>
                      {`El beneficio es válido hasta el ${moment()
                        .add(beneficio.diasValidez, "d")
                        .format("DD/MM/YYYY")}.`}
                    </span>
                  )}
                  {beneficio.tipoValidez === "POR_FECHAS" && (
                    <span>
                      {`El beneficio es válido desde el ${moment(
                        beneficio.fechaValidezDesde
                      ).format("DD/MM/YYYY")} hasta el ${moment(
                        beneficio.fechaValidezHasta
                      ).format("DD/MM/YYYY")}.`}
                    </span>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

SolicitudCupon.propTypes = {
  classes: PropTypes.object
};

SolicitudCupon.defaultProps = {};

export default SolicitudCupon;
