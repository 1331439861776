import React from "react";
import { DatePicker } from "material-ui-pickers";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import localization from "moment/locale/es";
moment()
  .locale("es", localization)
  .format("LLL");

const renderDatePicker = ({
  input: { value, onChange, ...input },
  meta: { touched = "", error = undefined },
  label = "",
  placeholder,
  showTodayButton = false,
  disableFuture = false,
  autoOk = true,
  startOfDate = false,
  endOfDate = false,
  dateFormat,
  openTo,
  views,
  InputProps,
  ...custom
}) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <DatePicker
      label={label}
      value={value}
      onChange={date => {
        if (startOfDate)
          date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        if (endOfDate)
          date.set({ hour: 23, minute: 59, second: 59, millisecond: 59 });
        return onChange(date);
      }}
      error={touched && Boolean(error)}
      helperText={touched && error}
      invalidLabel={placeholder}
      autoOk={autoOk}
      InputLabelProps={{
        shrink: true
      }}
      format={dateFormat}
      showTodayButton={showTodayButton}
      disableFuture={disableFuture}
      cancelLabel="CANCELAR"
      maxDateMessage="La fecha debe ser menor que hoy"
      openTo={openTo}
      views={views}
      InputProps={InputProps}
      {...custom}
      {...input}
    />
  </MuiPickersUtilsProvider>
);

renderDatePicker.defaultProps = {
  input: { value: "2018-01-01T00:00:00.000Z", onChange: () => undefined },
  meta: { touched: false, error: "" },
  label: "",
  placeholder: "",
  showTodayButton: false,
  disableFuture: false,
  autoOk: true,
  dateFormat: "dd/MM/yyyy"
};
export default renderDatePicker;
