import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { InputTextField } from "js/components/Shared/Form/Fields";
import { isValidUrl, isValidUrlOrEmpty } from "js/helpers/validations";

const Formulario = ({ disabled }) => {
  return (
    <Fragment>
      <Grid item xs={12} md={6} style={{ padding: "1% 1% 1% 0" }}>
        <InputTextField
          name="formulario.url"
          validate={isValidUrl}
          label="URL del formulario"
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ padding: "1% 1% 1% 0" }}>
        <InputTextField
          name="formulario.urlReporte"
          validate={isValidUrlOrEmpty}
          label="URL del reporte"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ padding: "1% 1% 1% 0" }}>
        <InputTextField
          name="formulario.urlEdicion"
          validate={isValidUrl}
          label="URL para la edición"
          disabled={disabled}
          required
        />
      </Grid>
    </Fragment>
  );
};

Formulario.displayName = "FORMULARIO";

export default Formulario;
