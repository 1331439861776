import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import YaParticipo from "./YaParticipo";
import styles from "./styles";

const enhance = compose(
  withRouter,
  withStyles(styles)
);

export default enhance(YaParticipo);
