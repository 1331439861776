import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { _t } from "js/utils/TranslationService";
import TextField from "@material-ui/core/TextField";

class DialogCancelarCanje extends React.PureComponent {
  state = { motivoCancelacion: "" };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    const { open, handleClose, sendValue, backUrl } = this.props;
    const { motivoCancelacion } = this.state;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Cancelar Canje"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {_t(
              "Se devolverán los puntos al usuario. ¿Estás seguro que deseas continuar?"
            )}
            <TextField
              id="motivoCancelacion"
              name="motivoCancelacion"
              label="Motivo de cancelación"
              multiline
              rowsMax="4"
              style={{ width: "100%" }}
              value={motivoCancelacion}
              onChange={this.handleChange}
              margin="normal"
              required
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={() => sendValue(motivoCancelacion, backUrl)}
            color="primary"
            autoFocus
            disabled={!motivoCancelacion}
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DialogCancelarCanje;
