import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withPrograma from "js/hoc/withPrograma";
import SolicitudRaw from "./Solicitudes";
import SolicitudErrorRaw from "./SolicitudError";
import styles from "./styles";

const enhance = compose(
  withRouter,
  withPrograma,
  withStyles(styles)
);

export const Solicitudes = enhance(SolicitudRaw);
export const SolicitudError = enhance(SolicitudErrorRaw);
export default {
  Solicitudes,
  SolicitudError
};
