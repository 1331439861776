// Matener la lista ordenada alfabeticamente

const es = new Map([
  ["ACCION", "Acción"],
  ["CANCELACION", "Cancelación"],
  ["CANJE", "Canje"],
  ["CERTIFICADO", "Certificado"],
  ["FISICO", "Físico"],
  ["INSTANTANEO", "Instantáneo"],
  ["MONETARIO", "Monetario"],
  ["MOVIMIENTO", "Movimiento"],
  ["NOTIFICACION", "Notificación"],
  ["OBJETIVO", "Objetivo cumplido"],
  ["ORDEN_COMPRA", "Orden de compra"],
  ["VENCIMIENTO", "Vencimiento"],
  ["VENCIMIENTO_PUNTOS", "Vencimiento puntos"],
  ["VENTA_RETAIL", "Venta retail"],
  ["VENTA_PRODUCTO_CODIFICADO", "Venta producto codificado"],
  ["CANJE_PUNTOS_CARGA_CODIGOS", "Canjeo de puntos por Código"]
]);

export default es;
