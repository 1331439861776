import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/acciones.resolver";
import { Foto, Saldo } from "js/components/Shared/Tabla/TableCells";
import get from "lodash/get";
import { USER_ROLES as Roles } from "js/models/User";
import advancedFilter from "./advancedFilter";

const columnData = [
  {
    id: "avatarUrl",
    label: "",
    type: "custom",
    isNumeric: true,
    parser: url => <Foto value={url} />,
    removeSort: true
  },
  { id: "posicion", label: "Posición", removeSort: true },
  { id: "usuario_username", label: "Usuario", removeSort: true },
  { id: "usuario_perfil_nombre", label: "Nombre", removeSort: true },
  { id: "usuario_perfil_apellido", label: "Apellido", removeSort: true },
  {
    id: "puntosRanking",
    label: "Puntos",
    type: "custom",
    isNumeric: true,
    parser: puntosRanking => <Saldo value={puntosRanking} />,
    removeSort: true
  }
];

const defaultSort = { order: "desc", orderBy: "puntosRanking" };

const defaultHeader = {
  title: "Ranking",
  export: {
    path: "ranking",
    perms: [Roles.ACCION_R]
  }
};

class Tabla extends PureComponent {
  render() {
    const { idAccion, conFiltro = false, idPrograma } = this.props;
    return idAccion ? (
      <Table
        advancedFilter={conFiltro ? advancedFilter : undefined}
        query={AppQraphQL.queries.getRankingAccion}
        sort={defaultSort}
        showHeader={conFiltro}
        defaultHeader={conFiltro ? defaultHeader : {}}
        columnData={columnData}
        getTipo={data => get(data, "getRankingAccion")}
        initialFilter={{ idAccion }}
        idPrograma={idPrograma}
        forceFetch={true}
      />
    ) : (
      ""
    );
  }
}

export default Tabla;
