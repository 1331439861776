import { isEmpty } from "lodash";

export const getPuntosProducto = (
  precioSugerido,
  tasaConversion,
  porcentaje
) => {
  // Vieja ecuación
  // Math.ceil((precioSugerido * tasaConversion + ((precioSugerido * porcentaje) / 100) * tasaConversion) / 1 ) * 1;

  // Esto para asegurar que el número que se parsee este correcto, sea donde el lugar que llame a esa función
  let realPorcentaje = null;
  if (porcentaje) {
    if (typeof porcentaje === "string") {
      realPorcentaje = porcentaje.replace(",", ".");
    } else {
      realPorcentaje = porcentaje;
    }
    return Math.ceil(
      precioSugerido * tasaConversion * (1 + parseFloat(realPorcentaje) / 100)
    );
  } else {
    return Math.ceil(precioSugerido * tasaConversion);
  }
};

export const getRentabilidadProducto = (puntos, tasaConversion, costo) => {
  // Vieja Ecuación
  // return Math.ceil(
  //   ((puntos / tasaConversion - costo) / (puntos / tasaConversion)) * 100
  // );
  const rent = ((puntos - costo / tasaConversion) / puntos) * 100;
  return Math.round(rent);
};

export const addChecked = (array, key, value) => {
  if (isEmpty(array)) return null;
  var o;
  array.some(function iter(a) {
    if (a[key] === value) {
      o = a;

      return true;
    }
    return Array.isArray(a.children) && a.children.some(iter);
  });
  if (!o) return;
  o["checked"] = true;
  return o;
};

export const filter = array => {
  const filterArray = ({ estado }) => estado === "ACTIVO";
  return array
    .filter(filterArray)
    .map(
      ({ children, ...rest }) =>
        children && children.length
          ? { ...rest, children: filter(children) }
          : rest
    );
};
