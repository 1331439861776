import React, { Component, Fragment } from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Enhancer from "./container";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import { Select, ColorPicker } from "js/pages/TemplateBuilder2/Controls";
import Typography from "@material-ui/core/Typography/Typography";
import UploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import { Mutation } from "react-apollo";
import Tooltip from "@material-ui/core/Tooltip";
import ImportarGraphQL from "js/graphql/resolvers/importar.resolver";

const perms = [
  { value: "ACCESO_BO", label: "Acceso BO" },
  { value: "REPORTE_R", label: "Ver reportes" }
];

class Link extends Component {
  onChange = key => event =>
    this.props.updateOnElement({
      ...this.props.value,
      [key]: event.target.value
    });
  onChangeSwitch = key => event =>
    this.props.updateOnElement({
      ...this.props.value,
      [key]: event.target.checked
    });

  onChangeColor = key => value =>
    this.props.updateOnElement({
      ...this.props.value,
      [key]: value
    });

  render() {
    const { classes } = this.props;
    const success =
      "El archivo se subió correctamente. Recordá guardar los cambios antes de salir.";
    const errorMessage =
      "Hubo un error al procesar el archivo. Contacta con un administrador";

    const key =
      (this.props.withPerms ? "encabezado" : "footer") + this.props.indexItem;
    const idPrograma = this.props.idPrograma;
    return (
      <FormControl className={this.props.classes.formControl}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={3}>
            <TextField
              className={classes.fullWidth}
              variant="outlined"
              id="Link"
              placeholder="Título"
              value={this.props.value.texto}
              onChange={this.onChange("texto")}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              id="Link"
              placeholder="Link"
              value={this.props.value.link}
              onChange={this.onChange("link")}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={this.props.withPerms ? 3 : 6}>
            <div className={classes.select}>
              <Select
                options={[
                  { label: "Abrir en la misma pestaña", value: "_self" },
                  { label: "Abrir en una pestaña nueva", value: "_blank" },
                  { label: "Abrir en una ventana nueva", value: "new" }
                ]}
                value={this.props.value.target || "_self"}
                onChange={value =>
                  this.onChange("target")({ target: { value } })
                }
              />
            </div>
          </Grid>
          {this.props.withPerms && (
            <Grid item xs={12} sm={3}>
              <div className={classes.select}>
                <TypeAhead
                  label={"Permisos"}
                  onChange={select =>
                    this.onChange("perms")({
                      target: { value: select.map(option => option.value) }
                    })
                  }
                  path={{ path: "perms", value: "value", label: "label" }}
                  placeholder={"Permisos necesarios"}
                  suggestions={{ perms }}
                  value={this.props.value.perms || []}
                  multiple
                />
              </div>
            </Grid>
          )}
          {((this.props.value.link || "").indexOf("/informacion") > -1 ||
            (this.props.value.link || "").indexOf("/privado") > -1) && (
            <Fragment>
              <Grid item xs={12} sm={6} container>
                <Grid xs={10} item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="iframe"
                    placeholder="URL del contenido"
                    value={this.props.value.iframe}
                    onChange={this.onChange("iframe")}
                    margin="normal"
                  />
                </Grid>
                <Grid xs={2} item>
                  <Tooltip title="Subir contenido">
                    <IconButton
                      style={{ marginTop: "20px" }}
                      onClick={() =>
                        document
                          .getElementById(`upload-ubicaciones-${key}`)
                          .click()
                      }
                    >
                      <Mutation
                        mutation={ImportarGraphQL.queries.importarContenidos}
                      >
                        {mutate => {
                          return (
                            <input
                              id={`upload-ubicaciones-${key}`}
                              type="file"
                              style={{ display: "none" }}
                              required
                              onChange={({
                                target: {
                                  validity,
                                  files: [file]
                                }
                              }) => {
                                return (
                                  validity.valid &&
                                  mutate({
                                    variables: {
                                      file,
                                      idPrograma: parseInt(idPrograma)
                                    }
                                  })
                                    .then(({ data }) => {
                                      this.props.openNotification(success);
                                      this.onChange("iframe")({
                                        target: {
                                          value: data.importarContenidos
                                        }
                                      });
                                    })
                                    .catch(e => {
                                      this.props.openNotification(errorMessage);
                                    })
                                    .finally(
                                      () =>
                                        (document.getElementById(
                                          `upload-ubicaciones-${key}`
                                        ).value = "")
                                    )
                                );
                              }}
                            />
                          );
                        }}
                      </Mutation>
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="height"
                  placeholder="Altura del Iframe"
                  value={this.props.value.height}
                  onChange={this.onChange("height")}
                  margin="normal"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  {"Borde del Iframe"}
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="padding"
                  placeholder="Borde del Iframe"
                  value={this.props.value.padding}
                  onChange={this.onChange("padding")}
                  margin="normal"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  {"Color del borde"}
                </Typography>
                <ColorPicker
                  classes={classes}
                  color={this.props.value.backgroundColor}
                  onChange={this.onChangeColor("backgroundColor")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  {"Full width"}
                </Typography>
                <Switch
                  classes={classes}
                  checked={this.props.value.fullWidth}
                  onChange={this.onChangeSwitch("fullWidth")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  {"Margen superior"}
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="marginTop"
                  placeholder="Margen superior"
                  value={this.props.value.marginTop}
                  onChange={this.onChange("marginTop")}
                  margin="normal"
                  type="number"
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
      </FormControl>
    );
  }
}

export default Enhancer(Link);
