import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabla from "./Tabla";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import Tooltip from "@material-ui/core/Tooltip";
import { exportURL } from "js/constants";

export default class Ranking extends Component {
  render() {
    const { idAccion, conFiltro, idPrograma } = this.props;
    return (
      <Grid item container xs={12} spacing={16}>
        {!conFiltro ? (
          <Grid item xs={12}>
            <Typography variant="subheading" gutterBottom color="primary">
              Ranking
              <Tooltip title="Exportar" style={{ marginBottom: "0px" }}>
                <IconButton
                  onClick={e =>
                    (window.location.href = `${exportURL}/ranking?idAccion=${idAccion}`)
                  }
                >
                  <DownloadIcon color={"primary"} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
        ) : (
          ""
        )}
        <Grid
          item
          xs={12}
          style={{ marginTop: conFiltro ? undefined : "-25px" }}
        >
          <Tabla
            conFiltro={conFiltro}
            idPrograma={idPrograma}
            idAccion={idAccion}
          />
        </Grid>
      </Grid>
    );
  }
}
