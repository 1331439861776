import { withStyles } from "@material-ui/core/styles";
import withPrograma from "js/hoc/withPrograma";
import Dashboard from "./Dashboard";
import styles from "./styles";

import { compose } from "recompose";

const enhance = compose(
  withPrograma,
  withStyles(styles)
);
export default enhance(Dashboard);
