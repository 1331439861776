import gql from "graphql-tag";

export const getParametrosCargaFacturaById = gql`
  query getParametrosCargaFactura($idPrograma: Int) {
    getParametrosCargaFactura(
      ParametrosCargaFacturaLike: { idPrograma: $idPrograma }
    ) {
      key
      label
      visibleRegistro
      requeridoRegistro
      visiblePerfil
      requeridoPerfil
      tipo
      value
      grupo
      placeholder
      disableEdit
    }
  }
`;

export const bulkParametrosCargaFactura = gql`
  mutation bulkParametrosCargaFactura(
    $idPrograma: Int!
    $definicionesParametrosCargaFactura: [DefinicionParametrosCargaFacturaCreateBulk]!
  ) {
    bulkParametrosCargaFactura(
      idPrograma: $idPrograma
      definicionesParametrosCargaFactura: $definicionesParametrosCargaFactura
    ) {
      idPrograma
    }
  }
`;

export default {
  queries: {
    getParametrosCargaFacturaById
  },
  mutations: {
    bulkParametrosCargaFactura
  }
};
