import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    padding: "5px 0",
    position: "absolute",
    top: "3.5px",
    left: 0,
    width: "100%",
    zIndex: 9999
  },
  cucarda: {
    fontSize: "12px"
  },
  ribbon: {
    minWidth: "100px",
    height: "30px",
    lineHeight: "30px",
    paddingLeft: 8,
    position: "absolute",
    background: "#1649A2",
    "&:after": {
      content: "''",
      position: "absolute",
      height: 0,
      width: 0,
      right: "-14px",
      borderTop: "15px solid transparent",
      borderBottom: "15px solid transparent",
      borderLeft: "14px solid",
      borderLeftColor: "inherit"
    }
  }
});

export default withStyles(styles);
