import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaCuentaCorriente/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/cuentaCorriente.resolver";
import { withRouter } from "react-router-dom";
import { Monto, Saldo } from "js/components/Shared/Tabla/TableCells";
import { get } from "lodash";
import { USER_ROLES as Roles } from "js/models/User";
import { Link } from "react-router-dom";

const defaultHeader = {
  title: "Cuenta Corriente",
  new: {
    title: "Nuevo",
    perms: [Roles.ASIGNAR_PUNTOS_PROGRAMA_W]
  },
  // import: {
  //   action: AppQraphQLImport.queries.importarAsignarPuntosAPrograma,
  //   perms: [Roles.ASIGNAR_PUNTOS_PROGRAMA_W],
  //   model: "PUNTOSAPROGRAMA.csv"
  // },
  export: {
    path: "movimientos/programa",
    perms: [Roles.MOVIMIENTO_PROGRAMA_R]
  },
  queryStringMatcher: {
    idPrograma: "cuentaCorriente_programa_idPrograma",
    idsProgramas: undefined
  }
};
const columnData = [
  { id: "fecha", label: "Fecha", type: "datehour" },
  {
    id: "cuentaCorriente_programa_nombre",
    label: "Programa",
    type: "custom",
    parser: (cc, item) => (
      <Link
        to={`/admin/programa/${get(item, [
          "cuentaCorriente",
          "programa",
          "idPrograma"
        ])}`}
      >
        {get(item, ["cuentaCorriente", "programa", "nombre"])}
      </Link>
    )
  },
  { id: "numeroFactura", label: "Factura" },
  { id: "concepto", label: "Concepto" },
  { id: "solicitante_username", label: "Solicitante" },
  {
    id: "monto",
    label: "Importe",
    type: "custom",
    isNumeric: true,
    parser: (monto, item) => (
      <Monto
        value={monto}
        monedaBase={item.monedaBase}
        tipoOperacion={item.operacion}
      />
    ),
    classes: "numericCell"
  },
  {
    id: "puntos",
    label: "Puntos",
    type: "custom",
    isNumeric: true,
    parser: (puntos, item) => (
      <Monto value={puntos} tipoOperacion={item.operacion} />
    ),
    classes: "numericCell"
  },
  {
    id: "balance",
    label: "Balance",
    type: "custom",
    isNumeric: true,
    parser: balance => <Saldo value={balance} />,
    classes: "numericCell"
  }
];

const defaultSort = { order: "desc", orderBy: "fecha" };

class Tabla extends PureComponent {
  render() {
    // const {
    //   match: { path }
    // } = this.props;
    return (
      <Table
        storage={"FiltroCuentaCorriente"}
        query={AppQraphQL.queries.getMovimientos}
        defaultHeader={defaultHeader}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getMovimientos}
      />
    );
  }
}

export default withRouter(Tabla);
