import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import Reporte from "js/pages/MiReporteCLN";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles.js";

class ViewReporte extends PureComponent {
  state = { idUsuario: undefined };
  handleChange = key => select => this.setState({ [key]: select.value });
  render() {
    const { classes, suggestions } = this.props;
    const { idUsuario } = this.state;
    return (
      <Fragment>
        <TypeAhead
          label={"Cuenta madre"}
          onChange={this.handleChange("idUsuario")}
          path={{
            path: "suggestions",
            value: "idUsuario",
            label: "username"
          }}
          placeholder={"Buscar por cuenta madre"}
          suggestions={{ suggestions }}
          value={idUsuario}
        />
        {idUsuario && (
          <Fragment>
            <Divider classes={{ root: classes.divider }} />
            <Reporte idUsuario={idUsuario} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

ViewReporte.propTypes = {
  classes: PropTypes.object
};

ViewReporte.defaultProps = {
  suggestions: []
};

ViewReporte.displayName = "ViewReporte";

export default withStyles(styles)(ViewReporte);
