import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import withPrograma from "js/hoc/withPrograma";
import { compose } from "recompose";
import UserMenu from "./UserMenu.container";
import styles from "./styles";

const enhance = compose(
  withPrograma,
  withRouter,
  withStyles(styles)
);
export default enhance(UserMenu);
