import React, { PureComponent } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FlagIcon from "@material-ui/icons/Flag";

import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/campanaGrupoProducto.resolver";
import { editGrupoProductosAction } from "js/pages/Admin/Programa/Campana/ActionCells";
import { exportAction } from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import advancedFilter from "js/pages/Unilever/Campana/GruposSKU/Tabla/advancedFilter.js";

const defaultHeader = (id, btnsNextTitle) => {
  return {
    title: "Grupos Productos",
    btnsNextTitle,
    importWithDialog: {
      dialog: "GrupoProductos",
      perms: [Roles.CAMPANA_PRODUCTOS_W],
      tooltip: "Importar Grupo de Productos",
      model: "Modelo_Grupos_Productos_Campanas.csv"
    }
  };
};
const columnData = [
  { id: "fechaCreacion", label: "Fecha Creación", type: "date" },
  { id: "idGrupo", label: "ID", removeSort: true },
  { id: "nombre", label: "Nombre grupo" },
  { id: "productos_length", label: "Cantidad de productos", removeSort: true },
  {
    id: "usuarioCreador",
    label: "Creado por",
    type: "custom",
    removeSort: true,
    parser: usuarioCreador => {
      if (usuarioCreador.perfil) {
        return `${usuarioCreador.perfil.nombre} ${
          usuarioCreador.perfil.apellido
        }`;
      }
      return usuarioCreador.username;
    }
  },
  { id: "actions", label: "Acciones", removeSort: true }
];

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    const edit = params => ({
      ...editGrupoProductosAction({
        ...params,
        history: this.props.history,
        perms: [Roles.CAMPANA_PRODUCTOS_W]
      })
    });

    const exportQueryString = idGrupo => {
      return `idPrograma=${id}&idGrupo=${idGrupo}`;
    };

    const exportButton = params => ({
      ...exportAction({
        ...params,
        history: this.props.history,
        perms: [Roles.CAMPANA_PRODUCTOS_R],
        tooltip: "Exportar Grupo de productos",
        label: "Exportar Grupo de productos",
        path: "grupoProductos",
        queryString: exportQueryString
      })
    });

    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Volver a Campañas">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(`/admin/programa/${id}/campanas`)
            }
          >
            <FlagIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.CAMPANA_R]
    });

    return (
      <Table
        storage="FiltroGruposProductosCampana"
        query={AppQraphQL.queries.getGruposProductosCampana}
        advancedFilter={advancedFilter}
        defaultHeader={defaultHeader(id, btnsNextTitle)}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getGruposProductosCampana}
        actionsCustom={[edit, exportButton]}
        pathId={"idGrupo"}
      />
    );
  }
}

export default Tabla;
