import React, { Component } from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";

import styles from "./styles";
import withPrograma from "js/hoc/withPrograma";
import Producto from "./Producto.container";
import { Query } from "react-apollo";
import CatalogoQraphQL from "js/graphql/resolvers/catalogo.resolver";
import SplashScreen from "js/pages/Loading";
import Grid from "@material-ui/core/Grid";

import { connect } from "react-redux";
import productoActions from "./actions";
import AuthService from "js/utils/AuthService";
import { url } from "js/constants.js";

class Root extends Component {
  componentWillUnmount() {
    this.props.cleanProductoState();
  }
  render() {
    const isLogged = !!AuthService.getIdUsuario();
    const queryCatalogo = isLogged
      ? CatalogoQraphQL.queries.getCatalogo
      : CatalogoQraphQL.queries.getCatalogoPublico;
    const idEleccionProducto = parseInt(
      this.props.computedMatch.params.idEleccionProducto,
      10
    );
    const productQuery = {
      catalogoLike: {
        idEleccionProducto
      },
      showDetails: true,
      pagina: { cantidadPorPagina: 1, numeroPagina: 1 }
    };
    if (!isLogged) {
      productQuery.url = url;
    }
    return (
      <Query
        query={queryCatalogo}
        variables={productQuery}
        errorPolicy="all"
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data }) => {
          if (loading || !data)
            return (
              <Grid container style={{ height: 500 }}>
                <SplashScreen />
              </Grid>
            );
          const eleccionProducto = isLogged
            ? data.getCatalogo.itemsPagina[0]
            : data.getCatalogoPublico.itemsPagina[0];

          this.props.loadProductoState(eleccionProducto);

          return (
            <Producto {...this.props} eleccionProducto={eleccionProducto} />
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = state => ({
  isLogged: state.programa.isLogged
});

const mapDispatchToProps = dispatch => ({
  cleanProductoState: productoActions({ dispatch }).cleanProductoState,
  loadProductoState: productoActions({ dispatch }).loadProductoState
});

const enhance = compose(
  withPrograma,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
);
export default enhance(Root);
