import React from "react";
import PropTypes from "prop-types";
import SplashScreen from "js/pages/SplashScreen";
import { Query } from "react-apollo";
import ProductoQraphQL from "js/graphql/resolvers/producto.resolver";

import defaultStyles from "./styles";

class Pagina extends React.Component {
  render() {
    const productoQuery = {
      productoLike: { idProducto: this.props.match.params.pagin }
    };
    const { classes, match } = this.props;
    return (
      <div className={classes.root}>
        <Query
          query={ProductoQraphQL.queries.getProductoById}
          variables={productoQuery}
          errorPolicy="all"
        >
          {({ loading, error, data }) => {
            if (loading || !data) return <SplashScreen />;

            return <div className={classes.root}>{match.params.pagina}</div>;
          }}
        </Query>
      </div>
    );
  }
}

Pagina.propTypes = {
  classes: PropTypes.object
};

Pagina.defaultProps = {
  classes: defaultStyles
};

export default Pagina;
