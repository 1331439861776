import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { get } from "lodash";
import {
  Account,
  CalendarBlank,
  Truck,
  ProgressCheck,
  Counter,
  Receipt
} from "mdi-material-ui";
import { withStyles } from "@material-ui/core/styles";
import InfoCanjeSinForm from "./InfoCanjeSinForm";
import { renderFecha } from "js/utils/Helper";
import { DatePicker } from "js/components/Shared/Form/Fields";
import { ESTADO_CANJE } from "js/constants";
import moment from "moment";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import { _t } from "js/utils/TranslationService";
import Motivo from "@material-ui/icons/Comment";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => ({
  list: {
    paddingLeft: theme.spacing.unit,
    paddingBottom: 0,
    marginBottom: 0
  },
  checkbox: {
    paddingLeft: 0
  }
});

class InfoCanje extends Component {
  state = { checked: false };

  componentWillReceiveProps = nextProps =>
    this.setState({ checked: !!nextProps.deslotear });

  handleToggle = async () => {
    await this.setState(state => ({ checked: !state.checked }));
    this.props.changeAutocomplete("deslotear", this.state.checked);
  };

  render() {
    const {
      classes,
      initialValues,
      changeFecha,
      changeAutocomplete,
      fechaEntrega,
      disabled,
      factura,
      estadoCanje,
      inBackOffice
    } = this.props;
    const nombre = get(initialValues, "usuario.perfil.nombre");
    const apellido =
      " " + (get(initialValues, "usuario.perfil.apellido") || "");
    const fullName =
      (nombre && nombre.length) || (apellido && apellido.length > 1)
        ? (nombre || "") + "" + (apellido || "")
        : null;
    return (
      <Grid container item xs={12}>
        {inBackOffice && (
          <Typography variant="h6" gutterBottom style={{ width: "100%" }}>
            Info Canje
          </Typography>
        )}

        <ListItem className={classes.list}>
          <ListItemIcon>
            <Account color="primary" />
          </ListItemIcon>
          <ListItemText
            className={classes.list}
            primary={fullName || initialValues.usuario.username}
            secondary={fullName && initialValues.usuario.username}
          />
        </ListItem>
        <ListItem className={classes.list}>
          <Tooltip title={_t("Fecha canje")}>
            <ListItemIcon>
              <CalendarBlank color="primary" />
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            className={classes.list}
            primary={renderFecha(initialValues.fecha)}
          />
        </ListItem>
        <ListItem className={classes.list}>
          <ListItemIcon>
            <ProgressCheck color="primary" />
          </ListItemIcon>
          {estadoCanje !== "CANCELADO" && (
            <TypeAhead
              disabled={!!disabled}
              label={"Estado del canje"}
              noOptionsMessage={"No hay estados"}
              onChange={select =>
                changeAutocomplete("estadoCanje", select.value)
              }
              path={{ path: "sug", value: "value", label: "label" }}
              placeholder={"Buscar por estado"}
              suggestions={{
                sug: ESTADO_CANJE.filter(
                  e =>
                    e.value !== "CANCELADO" &&
                    (estadoCanje !== "LOTEADO" ? e.value !== "LOTEADO" : true)
                )
              }}
              value={estadoCanje}
            />
          )}
          {estadoCanje === "CANCELADO" && (
            <ListItemText className={classes.list} primary="Cancelado" />
          )}
        </ListItem>
        {estadoCanje === "CANCELADO" && (
          <ListItem className={classes.list}>
            <Tooltip title={_t("Motivo de cancelación")}>
              <ListItemIcon>
                <Motivo color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              className={classes.list}
              secondary={get(initialValues, "motivoCancelacion", "--")}
            />
          </ListItem>
        )}
        <ListItem className={classes.list}>
          <ListItemIcon>
            <Truck color="primary" />
          </ListItemIcon>
          <DatePicker
            dateFormat="DD/MM/YYYY"
            name="fechaEntrega"
            label="Fecha de entrega"
            input={{
              onChange: changeFecha("fechaEntrega"),
              value: fechaEntrega
            }}
            disabled={disabled || estadoCanje !== "ENTREGADO"}
            required={estadoCanje === "ENTREGADO"}
            autoOk
            maxDate={moment()}
          />
        </ListItem>
        {inBackOffice && (
          <>
            <ListItem className={classes.list}>
              <Tooltip title={_t("Factura")}>
                <ListItemIcon>
                  <Receipt color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                className={classes.list}
                primary={factura || "--"}
              />
            </ListItem>
            <ListItem className={classes.list}>
              <Tooltip title={_t("Lote")}>
                <ListItemIcon>
                  <Counter color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                className={classes.list}
                primary={get(initialValues, "lote.numeroLote", "--")}
              />
            </ListItem>
            {get(initialValues, "lote.numeroLote", false) && (
              <ListItem
                button
                className={classes.list}
                dense
                onClick={this.handleToggle}
              >
                <Checkbox
                  disabled={disabled}
                  name="deslotear"
                  disableRipple
                  className={classes.checkbox}
                  checked={this.state.checked}
                  color="secondary"
                />
                <ListItemText
                  primary={_t(`Deslotear`)}
                  className={classes.list}
                />
              </ListItem>
            )}
          </>
        )}
        <InfoCanjeSinForm canje={initialValues} />
      </Grid>
    );
  }
}

export default withStyles(styles)(InfoCanje);
