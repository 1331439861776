import gql from "graphql-tag";

export const Facturas = {};

Facturas.fragments = {
  facturas: gql`
    fragment facturas on PaginaFactura {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
        __typename
      }
      itemsPagina {
        idFactura
        numeroFactura
        notasInternas
        proveedor {
          nombreFantasia
        }
        fecha
        importe
        costoReal
        canjes {
          idCanje
          costoReal
          eleccionProducto {
            producto {
              costo
            }
          }
        }
        cantidadCanjes
        estado
      }
    }
  `,
  factura: gql`
    fragment facturas on PaginaFactura {
      itemsPagina {
        idFactura
        numeroFactura
        notasInternas
        proveedor {
          nombreFantasia
          pais {
            moneda
          }
        }
        canjes {
          idCanje
          lote {
            numeroLote
          }
          numeroLote
          estado
          usuario {
            username
          }
          eleccionProducto {
            producto {
              costo
              nombre
            }
            programa {
              nombre
              monedaBase
            }
          }
          fecha
          puntos
          cantidad
          costoReal
        }
        fecha
        importe
        costoReal
        estado
      }
    }
  `
};

export default Facturas;
