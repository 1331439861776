import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import AuthService from "js/utils/AuthService";
import base from "./Base";
import DeleteDialog from "js/components/Shared/Tabla/ActionCells/DeleteDialog";
export default params => ({
  ...base(),
  label: params.label,
  icon: <ClearIcon />,
  tooltip: params.tooltip,
  show: () => AuthService.hasPerms(params.perms),
  dialogId: "DELETE",
  dialog: (
    <DeleteDialog
      handleClose={params.togglePopUp}
      onTableChange={params.onTableChange}
      itemId={params.itemId}
      itemName={params.itemName}
      dialogTitle={params.dialogTitle}
      mutation={params.mutation}
    />
  )
});
