export default theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  divider: {
    margin: "24px 0"
  },
  fullWidth: {
    width: "100%"
  },
  buttonContainer: {
    textAlign: "right"
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});
