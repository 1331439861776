import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "./advancedFilter.js";
import MovimientosQraphQL from "js/graphql/resolvers/movimientos.resolver";
import columnData from "./columnData";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";

import {
  viewAction,
  editAction,
  showSucursalAction
} from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";

const simplifyHeader = {
  title: "CC Usuarios",
  new: {
    title: "Nuevo",
    perms: [Roles.ASIGNAR_PUNTOS_USUARIO_W]
  },
  queryStringMatcher: {
    idUsuario: "cuentaCorriente_usuario_idUsuario",
    idSegmento: "cuentaCorriente_usuario_idSegmento",
    idPrograma: "cuentaCorriente_usuario_idPrograma",
    solicitante: "solicitante_username",
    idsProgramas: undefined
  }
};

const defaultHeader = id => ({
  ...simplifyHeader,
  import: {
    action: AppQraphQLImport.queries.importarAsignarPuntosAUsuario,
    model: "Movimientos_CC_Usuarios_Modelo.csv",
    perms: [Roles.IMPORT_ASIGNAR_PUNTOS_USUARIO_W],
    variables: { idPrograma: parseInt(id, 10) }
  },
  export: {
    path: "movimientos/usuario",
    perms: [Roles.EXPORT_MOVIMIENTO_USUARIO_R]
  }
});

const defaultSort = { order: "desc", orderBy: "fecha" };

class Tabla extends PureComponent {
  render() {
    const view = params => ({
      ...viewAction({ ...params, history: this.props.history })
    });
    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.ASIGNAR_PUNTOS_USUARIO_W]
      })
    });
    const sucursal = params => ({
      ...showSucursalAction({ ...params, history: this.props.history })
    });
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <Table
        storage="FiltroCCUsuario"
        query={MovimientosQraphQL.queries.getMovimientosUsuarios}
        defaultHeader={id ? defaultHeader(id) : simplifyHeader}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count} movimientos`
        }
        getTipo={data => data.getMovimientosUsuarios}
        actionsCustom={[view, edit, sucursal]}
      />
    );
  }
}

export default Tabla;
