import React from "react";
import { getField } from "../helpers/getField";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { _t } from "../../../utils/TranslationService";

export const DialogDatosRequeridos = ({
  datosRequeridosFields,
  datosRequeridosValues,
  handleChangeDatoRequerido,
  onClose,
  openDialog,
  setDatosRequeridosCompleted
}) => {
  const handleValidarDatosRequeridos = async () => {
    const datosCompletos = Object.values(datosRequeridosValues).every(
      value => value !== null && value !== ""
    );
    if (datosCompletos) {
      setDatosRequeridosCompleted();
      onClose();
    } else {
      alert("Por favor, completa todos los campos requeridos.");
    }
  };

  return (
    <Dialog
      fullScreen={false}
      open={openDialog}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle id="form-dialog-title">Datos requeridos </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para continuar con la solicitud del producto, se requiere completar
          los siguientes datos:
        </DialogContentText>
        <Grid container spacing={16}>
          {datosRequeridosFields.length > 0 &&
            datosRequeridosFields.map(
              ({ key = "sucursal", nombre = "Sucursal" }) =>
                getField({
                  key,
                  nombre: _t(nombre),
                  datosRequeridosValues,
                  handleChangeDatoRequerido
                })
            )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button color="primary" onClick={handleValidarDatosRequeridos}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
