import { _t } from "js/utils/TranslationService";

//Tipos de campo que requieren un textField adicional
export const typesWithInput = ["Lista", "Lista_Multiple", "Lista_Autocomplete"];

//campos que requieren un textField adicional
export const keysWithInput = ["cantidadProductosXDefault"];

//Campos de configuración no renderizables en el formulario de carga de facturas
export const nonRenderableFields = [
  "tasaDeAcreditacion",
  "limiteDiario",
  "limitePorFactura",
  "limiteMensual",
  "incrementos",
  "requiereComprobante",
  "requiereModeracion",
  "cargaTerceros",
  "regexNroFactura",
  "inicioTratamiento"
];

export const inicioTratamientoFields = [
  {
    label: _t("Nombre completo médico"),
    key: "nombreCompletoMedico",
    tipo: "Texto",
    value: ""
  },
  {
    label: _t("Matrícula médico"),
    key: "matriculaMedico",
    tipo: "Texto",
    value: ""
  },
  {
    label: "Productos",
    key: "productos",
    tipo: "Lista_Autocomplete",
    value: []
  }
];

export const initialfields = [
  {
    key: "showSelectorCantidad",
    label: "Mostrar selector de cantidad de productos",
    visibleRegistro: null,
    requeridoRegistro: null,
    visiblePerfil: null,
    requeridoPerfil: null,
    tipo: "Booleano",
    value: ["1"],
    grupo: null,
    placeholder: null,
    disableEdit: true,
    __typename: "DefinicionParametrosCargaFacturas"
  },
  {
    key: "cantidadProductosXDefault",
    label: "Cantidad de productos por defecto",
    visibleRegistro: null,
    requeridoRegistro: null,
    visiblePerfil: null,
    requeridoPerfil: null,
    tipo: "Numerico",
    value: ["1"],
    grupo: null,
    placeholder: null,
    disableEdit: true,
    __typename: "DefinicionParametrosCargaFacturas"
  }
];
