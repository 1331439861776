import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TableChartIcon from "@material-ui/icons/TableChartOutlined";
import UploadIcon from "@material-ui/icons/CloudUpload";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import AuthService from "js/utils/AuthService";
import { get } from "lodash";
import { Mutation } from "react-apollo";
import { s3Url, exportURL } from "js/constants";
import CircularProgress from "@material-ui/core/CircularProgress";

const style = {
  display: "flex",
  flex: 1,
  flexWrap: "wrap"
};

const style1 = {
  display: "flex",
  flex: 1
};

const style2 = {
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-end"
};

const actionTooltip = {
  margin: 0
};
const actionButton = {
  padding: "6px 16px",
  minWidth: "fit-content",
  minHeight: "fit-content",
  fontSize: "0.85 rem"
};
class header extends PureComponent {
  // shouldComponentUpdate = () => true;
  state = { loading: false };
  render() {
    const { classes, history, filter, values, refetch } = this.props;
    const { loading } = this.state;
    let queryString = "";

    if (filter) {
      queryString = Object.keys(filter)
        .map(
          key =>
            Array.isArray(filter[key])
              ? filter[key].map(v => `${key}[]=${v}`).join("&")
              : key + "=" + filter[key]
        )
        .join("&");
    }
    return (
      <div style={style}>
        <div style={style1}>
          <Typography gutterBottom variant="h5">
            {values.title || "Listado"}
          </Typography>
        </div>
        <div style={style2}>
          {loading && (
            <CircularProgress
              className={classes.progress}
              size={20}
              color="primary"
            />
          )}
          {values.import &&
            values.import.model &&
            AuthService.hasPerms(values.import.perms) && (
              <div>
                <Tooltip
                  title="Modelo de importación CSV"
                  style={actionTooltip}
                >
                  <IconButton
                    className={classes.button}
                    onClick={e =>
                      (window.location.href = `${s3Url}${values.import.model}`)
                    }
                  >
                    <TableChartIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          {values.import &&
            AuthService.hasPerms(values.import.perms) && (
              <div>
                <Tooltip title="Importar" style={actionTooltip}>
                  <IconButton
                    variant="outlined"
                    size="large"
                    className={classes.button}
                    onClick={() => document.getElementById("upload").click()}
                  >
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
                <Mutation mutation={values.import.action}>
                  {(mutate, { loading }) => {
                    this.setState({ loading });
                    return (
                      <input
                        id="upload"
                        type="file"
                        style={{ display: "none" }}
                        required
                        onChange={({
                          target: {
                            validity,
                            files: [file]
                          }
                        }) =>
                          validity.valid &&
                          mutate({
                            variables: {
                              file,
                              idPrograma: get(
                                values,
                                "import.variables.idPrograma",
                                ""
                              )
                            }
                          })
                            .then(res => {
                              refetch();
                              this.props.openNotification(
                                "El archivo se procesó correctamente"
                              );
                            })
                            .catch(res =>
                              this.props.openNotification(
                                'Hubo un error al procesar el archivo. Ver los detalles en "Logs"'
                              )
                            )
                            .finally(
                              () =>
                                (document.getElementById("upload").value = "")
                            )
                        }
                      />
                    );
                  }}
                </Mutation>
              </div>
            )}
          {values.export &&
            AuthService.hasPerms(values.export.perms) && (
              <Tooltip title="Exportar" style={actionTooltip}>
                <IconButton
                  variant="outlined"
                  size="large"
                  className={classes.button}
                  onClick={() =>
                    (window.location = `${exportURL}/${
                      values.export.path
                    }?${queryString}`)
                  }
                  download
                >
                  {values.export.title}
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            )}
          {values.new &&
            AuthService.hasPerms(values.new.perms) && (
              <Button
                style={actionButton}
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                onClick={e =>
                  values.new.path
                    ? history.push(values.new.path)
                    : history.push(window.location.pathname + "/new")
                }
              >
                {get(values, "new.title", "Nuevo")}
              </Button>
            )}
        </div>
      </div>
    );
  }
}

header.propTypes = {
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object,
  values: PropTypes.shape({
    title: PropTypes.string,
    new: PropTypes.shape({
      title: PropTypes.string,
      perms: PropTypes.array,
      path: PropTypes.string
    }),
    import: PropTypes.shape({
      title: PropTypes.string,
      model: PropTypes.string,
      perms: PropTypes.array,
      action: PropTypes.object,
      variables: PropTypes.object
    }),
    export: PropTypes.shape({
      title: PropTypes.string,
      perms: PropTypes.array,
      path: PropTypes.string
    })
  })
};

export default header;
