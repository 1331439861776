import { compose, pure, withHandlers } from "recompose";
import withStyles from "./styles";
import { set, get } from "lodash";
const enhance = compose(
  pure,
  withStyles,
  withHandlers({
    handleChangeCucarda: props => key => event => {
      const value = get(event, "target.value", event);
      const tempCucardas = set(props.types, key, value);
      props.onChange(tempCucardas);
    }
  })
);

export default enhance;
