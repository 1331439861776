import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { InputTextarea } from "js/components/Shared/Form/Fields";
import ReconocimientosGraphQL from "js/graphql/resolvers/reconocimiento.resolver";
import Divider from "@material-ui/core/Divider/Divider";
import Paper from "@material-ui/core/Paper/Paper";
import UsuariosReconocidos from "js/pages/Reconocimiento/CrearReconocimiento/UsuariosReconocidos";
import moment from "moment";
import Hidden from "@material-ui/core/Hidden/Hidden";
import { AprobadoIcon, RechazadoIcon, NominadoIcon } from "images/icons";
import Button from "@material-ui/core/Button/Button";
import AuthService from "js/utils/AuthService";
import { _t } from "js/utils/TranslationService";
import { get } from "lodash";

export default class EditarReconocimiento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = values => {
    const {
      client,
      computedMatch: {
        params: { idReconocimiento }
      },
      history
    } = this.props;
    const { motivo } = values;
    client
      .mutate({
        mutation: ReconocimientosGraphQL.mutations.editarReconocimiento,
        variables: {
          motivo,
          idReconocimiento
        }
      })
      .then(({ data: { editarReconocimiento: { _id } } }) => {
        history.push(`/reconocido/${idReconocimiento}/editado`);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    const {
      formulario = {},
      reconocimiento = {},
      classes,
      usuario,
      history,
      handleSubmit
    } = this.props;
    const { username, idUsuario } = usuario ? usuario : {};
    const { nombre, apellido } =
      usuario && usuario.perfil ? usuario.perfil : {};
    const {
      aprobaciones = [],
      usuarioReconocedor = {},
      usuariosReconocidos = []
    } = reconocimiento;

    function getAprobacion() {
      const aprobacionesDeUsuario = aprobaciones.filter(
        aprobacion => aprobacion.jefe.idUsuario === idUsuario
      );
      const aprobacionesPendientes = aprobacionesDeUsuario.filter(
        aprobacion => aprobacion.estado === "PENDIENTE_APROBACION"
      );
      if (aprobacionesPendientes.length) return aprobacionesPendientes[0];
      return aprobacionesDeUsuario.length
        ? aprobacionesDeUsuario[aprobacionesDeUsuario.length - 1]
        : undefined;
    }

    const aprobacion = getAprobacion();
    const aprobacionesAnteriores = aprobaciones.filter(
      apr => aprobacion && apr.orden < aprobacion.orden
    );
    const filas = [
      {
        orden: get(formulario, "pregunta1.orden", 0),
        render: this.renderPregunta1
      },
      {
        orden: get(formulario, "pregunta2.orden", 1),
        render: this.renderPregunta2
      },
      {
        orden: get(formulario, "pregunta3.orden", 5),
        render: this.renderPregunta3
      },
      {
        orden: get(formulario, "mensajeNominador.orden", 2),
        render: this.renderMensajeNominador
      },
      {
        orden: get(formulario, "valor.orden", 3),
        render: this.renderValor
      },
      {
        orden: get(formulario, "tipo.orden", 4),
        render: this.renderTipo
      }
    ].sort((a, b) => a.orden - b.orden);

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <Grid
          xs={12}
          container
          style={{
            margin: "0px",
            width: "100%",
            maxWidth: "100%",
            background: "linear-gradient(to bottom, white 260px, #f9f9f9 20%)"
          }}
        >
          <Grid xs={12} item className={classes.fondoTitulo}>
            <Typography variant={"h3"} classes={{ root: classes.textoTitulo }}>
              {_t("Solicitud de edición")}
            </Typography>
            <Typography
              variant={"h3"}
              classes={{ root: classes.textoSubtitulo }}
            >
              {_t(
                "Podés dejar una observación en el campo Motivo. Se enviará un email al Postulador, quien podrá editar la postulación teniendo en cuenta tus comentarios."
              )}
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid sm={1} item>
              <br />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            sm={10}
            container
            direction={"row"}
            justify={"center"}
            alignItems={"flex-start"}
            spacing={16}
          >
            <Grid
              sm={6}
              xs={12}
              item
              container
              direction={"row"}
              alignItems={"flex-start"}
              style={{
                paddingRight: "50px"
              }}
            >
              <Grid xs={12} item>
                <Typography classes={{ root: classes.textoHola }}>
                  ¡HOLA!
                </Typography>
              </Grid>
              <Grid xs={12} item className={classes.containerAprobador}>
                <Typography classes={{ root: classes.textoNombreAprobador }}>
                  {`Sector RRHH:`}
                </Typography>
                <Typography classes={{ root: classes.textoNombreAprobador }}>
                  {nombre && apellido ? `${nombre} ${apellido}` : username}
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <RechazadoIcon
                  className={classes.iconDesplegable}
                  color={"primary"}
                  viewBox="5 5 40 40"
                />
              </Grid>
              <Grid xs={12} item className={classes.containerObservacion}>
                <Typography
                  style={{
                    marginBottom: "5px"
                  }}
                  classes={{ root: classes.textoObservacion }}
                >
                  {_t("MOTIVO")}
                </Typography>
                <InputTextarea
                  classes={classes}
                  variant={"outlined"}
                  name="motivo"
                  multiple
                />
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={6}
              item
              container
              style={{
                marginBottom: "15px"
              }}
            >
              <Paper className={classes.card}>
                <Grid
                  xs={12}
                  container
                  spacing={16}
                  style={{
                    padding: "10px"
                  }}
                >
                  <Grid xs={12} item className={classes.containerFecha}>
                    <Typography classes={{ root: classes.textoFechaWrapper }}>
                      FECHA:{" "}
                      <Typography classes={{ root: classes.textoFecha }}>
                        {moment.utc().format("DD-MM-YYYY")}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    item
                    className={classes.containerNominado}
                    direction={"column-reverse"}
                  >
                    <NominadoIcon
                      className={classes.iconNominado}
                      color={"primary"}
                      htmlColor={"white"}
                      viewBox="5 10 36 36"
                    />
                  </Grid>
                  <Grid xs={12} item style={{ paddingBottom: "0px" }}>
                    <Typography color={"primary"}>{_t("NOMINADOR")}</Typography>
                  </Grid>
                  <Grid xs={12} item style={{ paddingTop: "2px" }}>
                    <Typography
                      classes={{
                        root: classes.textoNombre
                      }}
                      style={{
                        marginBottom: "10px"
                      }}
                    >
                      {usuarioReconocedor.perfil &&
                      usuarioReconocedor.perfil.nombre &&
                      usuarioReconocedor.perfil.apellido
                        ? `${usuarioReconocedor.perfil.nombre} ${
                            usuarioReconocedor.perfil.apellido
                          }`
                        : usuarioReconocedor.username}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    item
                    className={classes.containerNominados}
                    style={{
                      marginBottom: "0px",
                      paddingBottom: "0px"
                    }}
                  >
                    <Typography color={"primary"}>
                      {_t("NOMINADO/S")}
                    </Typography>
                  </Grid>
                  <Grid xs={12} item>
                    <UsuariosReconocidos
                      formulario={formulario}
                      noRemove={true}
                      usuariosNominados={usuariosReconocidos}
                      nominaEquipo={usuariosReconocidos.length > 1}
                      idUsuariosNominados={usuariosReconocidos.map(
                        usuario => usuario.idUsuario
                      )}
                      classes={classes}
                    />
                  </Grid>
                  {filas.map(fila =>
                    fila.render({
                      classes,
                      formulario,
                      reconocimiento
                    })
                  )}
                  {reconocimiento.respuestas
                    ? reconocimiento.respuestas.map(
                        ({ tipo, pregunta, respuesta, respuestas }) => {
                          return pregunta &&
                            !pregunta.startsWith("[OLD VERSION]") &&
                            tipo !== "QUIZSCORE" ? (
                            <Fragment>
                              <Grid xs={12} item>
                                <Divider classes={{ root: classes.divider }} />
                                <Typography
                                  classes={{ root: classes.pregunta }}
                                >
                                  {pregunta}
                                </Typography>
                                <Typography
                                  classes={{
                                    root: classes.respuesta
                                  }}
                                >
                                  {respuesta ? (
                                    respuesta
                                  ) : respuestas ? (
                                    <ul>
                                      {respuestas.map(respuesta => (
                                        <li>{respuesta}</li>
                                      ))}
                                    </ul>
                                  ) : (
                                    "--"
                                  )}
                                </Typography>
                              </Grid>
                            </Fragment>
                          ) : (
                            ""
                          );
                        }
                      )
                    : ""}
                </Grid>
              </Paper>
              {aprobacionesAnteriores.map(aprobacionAnterior => {
                const { username, perfil = {} } = aprobacionAnterior.jefe;
                const { nombre, apellido } = perfil;
                return (
                  <Grid
                    xs={12}
                    container
                    item
                    className={classes.containerAprobacionAnterior}
                  >
                    <Grid xs={2} item>
                      <AprobadoIcon
                        className={classes.iconDesplegable}
                        color={"primary"}
                        viewBox="5 5 40 40"
                      />
                    </Grid>
                    <Grid xs={10} item>
                      <Paper className={classes.card}>
                        <Grid xs={12} item className={classes.containerFecha}>
                          <Typography
                            classes={{ root: classes.textoFechaWrapper }}
                          >
                            FECHA:{" "}
                            <Typography classes={{ root: classes.textoFecha }}>
                              {moment
                                .utc(aprobacionAnterior.fecha)
                                .format("DD-MM-YYYY")}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          item
                          className={classes.containerAprobador}
                          style={{
                            marginTop: "15px"
                          }}
                        >
                          <Typography
                            classes={{ root: classes.textoNombreAprobador }}
                          >
                            {`Aprobador ${
                              aprobacionAnterior ? aprobacionAnterior.orden : ""
                            }:`}
                          </Typography>
                          <Typography
                            classes={{ root: classes.textoNombreAprobador }}
                          >
                            {nombre && apellido
                              ? `${nombre} ${apellido}`
                              : username}
                          </Typography>
                        </Grid>
                        {formulario.conObservacion ? (
                          <Grid xs={12} item>
                            <Divider classes={{ root: classes.divider }} />
                            <Typography
                              classes={{
                                root: classes.observacionAnteriorTitulo
                              }}
                            >
                              {_t("Observación")}
                            </Typography>
                            <Typography
                              classes={{ root: classes.observacionAnterior }}
                            >
                              {aprobacionAnterior.observacion}
                            </Typography>
                          </Grid>
                        ) : (
                          ""
                        )}

                        {formulario.conMensajeAprobadores ? (
                          <Grid xs={12} item>
                            <Divider classes={{ root: classes.divider }} />
                            <Typography
                              classes={{
                                root: classes.observacionAnteriorTitulo
                              }}
                            >
                              {_t("¡Dejale un mensaje!")}
                            </Typography>
                            <Typography
                              classes={{ root: classes.observacionAnterior }}
                            >
                              {aprobacionAnterior.mensaje}
                            </Typography>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid
            xs={12}
            item
            className={classes.containerBotones}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid xs={3} item>
              <Button
                style={{ float: "left" }}
                type="submit"
                variant="contained"
                color="primary"
                classes={{
                  label: classes.buttonLabel
                }}
              >
                {_t("Solicitud de Edicion")}
              </Button>
              <Button
                style={{ float: "right" }}
                onClick={() => history.goBack()}
                variant="contained"
                color="primary"
                classes={{
                  label: classes.buttonLabel
                }}
              >
                {_t("Volver")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }

  renderMensajeNominador({ formulario, classes, reconocimiento }) {
    return formulario.mensajeNominador &&
      formulario.mensajeNominador.seMuestra ? (
      <Fragment>
        <Grid xs={12} item>
          <Divider classes={{ root: classes.divider }} />
          <Typography classes={{ root: classes.pregunta }}>
            {formulario.mensajeNominador && formulario.mensajeNominador.alias
              ? formulario.mensajeNominador.alias
              : "Mensaje del nominador"}
          </Typography>
          <Typography
            classes={{
              root: classes.respuesta
            }}
          >
            {reconocimiento.mensaje ? reconocimiento.mensaje : "--"}
          </Typography>
        </Grid>
      </Fragment>
    ) : (
      ""
    );
  }

  renderTipo({ formulario, classes, reconocimiento }) {
    return formulario.tipo && formulario.tipo.alias ? (
      <Fragment>
        <Grid xs={12} item>
          <Divider classes={{ root: classes.divider }} />
          <Typography classes={{ root: classes.pregunta }}>
            {formulario.tipo.alias}
          </Typography>
          <Typography
            classes={{
              root: classes.respuesta
            }}
          >
            {reconocimiento.tipo ? reconocimiento.tipo.join(", ") : "--"}
          </Typography>
        </Grid>
      </Fragment>
    ) : (
      ""
    );
  }

  renderValor({ formulario, classes, reconocimiento }) {
    return formulario.valor && formulario.valor.alias ? (
      <Fragment>
        <Grid xs={12} item>
          <Divider classes={{ root: classes.divider }} />
          <Typography classes={{ root: classes.pregunta }}>
            {formulario.valor.alias}
          </Typography>
          <Typography
            classes={{
              root: classes.respuesta
            }}
          >
            {reconocimiento.valor ? reconocimiento.valor.join(", ") : "--"}
          </Typography>
        </Grid>
      </Fragment>
    ) : (
      ""
    );
  }

  renderPregunta3({ classes, formulario, reconocimiento }) {
    return (
      <Grid xs={12} item>
        <Divider classes={{ root: classes.divider }} />
        <Typography classes={{ root: classes.pregunta }}>
          {formulario.pregunta3 && formulario.pregunta3.alias
            ? formulario.pregunta3.alias
            : ""}
        </Typography>
        <Typography
          classes={{
            root: classes.respuesta
          }}
        >
          {reconocimiento.respuesta3}
        </Typography>
      </Grid>
    );
  }

  renderPregunta2({ classes, formulario, reconocimiento }) {
    return (
      <Grid xs={12} item>
        <Divider classes={{ root: classes.divider }} />
        <Typography classes={{ root: classes.pregunta }}>
          {formulario.pregunta2 && formulario.pregunta2.alias
            ? formulario.pregunta2.alias
            : "¿Por qué?"}
        </Typography>
        <Typography
          classes={{
            root: classes.respuesta
          }}
        >
          {reconocimiento.respuesta2}
        </Typography>
      </Grid>
    );
  }

  renderPregunta1({ classes, formulario, reconocimiento }) {
    return (
      <Grid xs={12} item>
        <Divider classes={{ root: classes.divider }} />
        <Typography classes={{ root: classes.pregunta }}>
          {formulario.pregunta1 && formulario.pregunta1.alias
            ? formulario.pregunta1.alias
            : "¿Cuál fue la Acción, Comportamiento o Resultado destacado por la cual creés que la persona seleccionada debe ser reconocida?"}
        </Typography>
        <Typography
          classes={{
            root: classes.respuesta
          }}
        >
          {reconocimiento.respuesta1}
        </Typography>
      </Grid>
    );
  }
}
