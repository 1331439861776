export default theme => ({
  activo: { color: "#4EC230" },
  ok: { color: "#4EC230" },
  inactivo: { color: "#DF3B3B" },
  pendiente: { color: "#DF3B3B" },
  loteado: { color: "#4EC230" },
  en_compra: { color: "#4EC230" },
  a_entregar: { color: "#4EC230" },
  en_distribucion: { color: "#4EC230" },
  entregado: { color: "#4EC230" },
  en_pausa: { color: "#4EC230" },
  cancelado: { color: "#4EC230" },
  error: { color: "#DF3B3B" },
  controlada: { color: "#4EC230" },
  aprobado: { color: "#4EC230" },
  auto_aprobado: { color: "#4EC230" },
  en_revision: { color: "#DF3B3B" }
});
