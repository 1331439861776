import React from "react";
// Componente Summary
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from "@material-ui/core";
export const Summary = ({ data, onClose }) => {
  const getStatusColor = status => {
    switch (status) {
      case "OK":
        return { color: "green" };
      case "ERROR":
        return { color: "red" };
      default:
        return {};
    }
  };
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Resumen de Operación</DialogTitle>
      <DialogContent>
        {Object.keys(data).map(fileKey => {
          const fileData = data[fileKey];
          const filename = fileData.filename;
          return (
            <div key={fileKey}>
              <Typography variant="h6">{filename}</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID Canje</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell>Mensaje</TableCell>
                    <TableCell>Estado Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(fileData)
                    .filter(key => key !== "filename")
                    .map(key => {
                      const record = fileData[key];
                      return (
                        <TableRow key={key}>
                          <TableCell>
                            {record.idCanje ? record.idCanje : "--"}
                          </TableCell>
                          <TableCell style={getStatusColor(record.status)}>
                            {record.status}
                          </TableCell>
                          <TableCell>{record.message}</TableCell>
                          <TableCell>{record.mailStatus}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
