import React, { PureComponent } from "react";
import Grid from "@material-ui/core/Grid";
import { _t } from "js/utils/TranslationService";
import { showErrors } from "js/utils/Helper";

class ErrorList extends PureComponent {
  render() {
    if (!this.props.errors) return null;
    const { classes, belowInput } = this.props;
    const errors = showErrors(this.props.errors);
    return (
      <Grid className={classes.grid} container spacing={16}>
        {belowInput ? (
          <div className={classes.errorInput}>
            <div className={classes.error} key={errors}>
              {_t(errors[0].message)}
            </div>
          </div>
        ) : (
          <ul className={classes.error}>
            {errors &&
              errors.map((e, index) => <li key={index}>{_t(e.message)}</li>)}
          </ul>
        )}
      </Grid>
    );
  }
}

export default ErrorList;
