import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  icon: {
    margin: theme.spacing.unit * 2
  },
  iconBelow: {
    margin: theme.spacing.unit * 2
  },
  iconRemoveElement: {
    margin: theme.spacing.unit * 2,
    position: "absolute",
    right: -25,
    top: 5
  },
  iconAddElement: {
    bottom: 5,
    right: -25,
    margin: 16,
    position: "absolute"
  },
  sortableHelper: {
    zIndex: 10
  },
  ulSortable: {
    listStyle: "square url('38specialPlusP.gif')",
    width: "100%"
  },
  outlined: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 4,
    padding: 5
  },
  normal: {},
  dragSection: {
    border: "1px solid #c7c7c7",
    padding: 5,
    borderRadius: 4
  },
  item: {
    border: "1px solid grey",
    borderRadius: "4px",
    borderColor: "#bababa"
  },
  rootExpansionPanelSummary: {
    minHeight: 90
  }
});

export default withStyles(styles);
