import React, { Fragment } from "react";
import { withNotificationContext } from "js/notification-context";
import { FieldArray } from "redux-form";
import { Mutation } from "react-apollo";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import { exportURL, s3Url } from "js/constants";
import Container from "./container";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import AppQraphQLICodigos from "js/graphql/resolvers/codigoActivacion.resolver";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { withApollo } from "react-apollo";
import capitalize from "lodash/capitalize";
import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { _t } from "js/utils/TranslationService";
import CircularProgress from "@material-ui/core/CircularProgress";

const ESTADOS = ["ACTIVO", "ANULADO", "ENVIADO", "UTILIZADO"];

const renderCodigos = (classes, showErrors) => ({
  fields,
  meta: { error }
}) => (
  <div className={classes.root}>
    <List component="div">
      {ESTADOS.map(e => (
        <ListItem>
          <Badge
            className={classes.padding}
            color="secondary"
            badgeContent={getCount(e, fields.getAll() || [])}
          >
            {capitalize(e)}
          </Badge>
        </ListItem>
      ))}
      <ListItem>
        <Badge
          className={classes.padding}
          color="secondary"
          badgeContent={fields.length}
        >
          Total
        </Badge>
      </ListItem>
    </List>
    {showErrors && <span className={classes.error}>{error} </span>}
  </div>
);

const getCount = (what, where) => where.filter(e => e.estado === what).length;

class Codigos extends React.Component {
  state = { loading: false, uploading: false };

  componentWillMount() {
    this.fetchCodigos();
  }

  fetchCodigos = async () => {
    const {
      idProducto,
      client,
      addCodigo,
      clearCodigos,
      onChangeCodigos
    } = this.props;
    if (!idProducto) return;
    this.setState({ loading: true });
    const { data } = await client.query({
      query: AppQraphQLICodigos.queries.getCodigosActivacionByProducto,
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      variables: { idProducto }
    });
    clearCodigos();
    const cods = data.getCodigosActivacion || [];
    onChangeCodigos({
      total: cods.length,
      activos: getCount("ACTIVO", cods)
    });
    cods.forEach(element => {
      addCodigo(element);
    });
    this.setState({ loading: false });
  };

  render() {
    const { disabled, classes, addCodigo, idProducto } = this.props;
    const showErrors = !disabled && !this.state.loading;
    return (
      <Fragment>
        <TableChartOutlinedIcon
          style={{ marginRight: 10 }}
          color="primary"
          onClick={() =>
            (window.location = `${s3Url}Codigos_Activacion_Modelo.csv`)
          }
          download
        />
        <Button
          variant="outlined"
          size="large"
          color="primary"
          className={classes.button}
          style={{ marginBottom: 20 }}
          disabled={disabled}
          onClick={() =>
            document
              .getElementById(idProducto ? "importCodigos" : "uploadCodigos")
              .click()
          }
        >
          <CloudUploadIcon style={{ marginRight: 10 }} />
          Importar
        </Button>
        {idProducto && (
          <Button
            variant="outlined"
            size="large"
            color="primary"
            className={classes.button}
            style={{ marginBottom: 20, marginLeft: 10 }}
            disabled={disabled}
            onClick={() =>
              (window.location = `${exportURL}/codigoActivacion?idProducto=${idProducto}`)
            }
            download
          >
            <DownloadIcon style={{ marginRight: 10 }} />
            Exportar
          </Button>
        )}
        {(this.state.loading || this.state.uploading) && (
          <CircularProgress
            className={classes.progress}
            size={30}
            color="primary"
          />
        )}
        <Mutation mutation={AppQraphQLImport.queries.importarCodigosActivacion}>
          {mutate => (
            <input
              id="importCodigos"
              type="file"
              style={{ display: "none" }}
              onChange={({
                target: {
                  validity: { valid },
                  files: [file]
                }
              }) => {
                this.setState({ uploading: true });
                const { idProducto } = this.props;
                valid &&
                  mutate({ variables: { file, idProducto } })
                    .then(async () => {
                      await this.fetchCodigos();
                      this.props.openNotification(
                        _t("Códigos de activación actualizados")
                      );
                    })
                    .catch(e =>
                      this.props.openNotification(
                        _t("Hubo un error al importar los datos")
                      )
                    )
                    .finally(() => this.setState({ uploading: false }));
              }}
            />
          )}
        </Mutation>
        <Mutation mutation={AppQraphQLImport.queries.parseCodigosActivacion}>
          {mutate => (
            <input
              id="uploadCodigos"
              type="file"
              style={{ display: "none" }}
              onChange={({
                target: {
                  validity: { valid },
                  files: [file]
                }
              }) => {
                this.setState({ uploading: true });
                valid &&
                  mutate({ variables: { file } })
                    .then(({ data: { parseCodigosActivacion } }) =>
                      parseCodigosActivacion.forEach(codigo =>
                        addCodigo(codigo)
                      )
                    )
                    .finally(() => this.setState({ uploading: false }));
              }}
            />
          )}
        </Mutation>
        <FieldArray
          name="codigosActivacion"
          component={renderCodigos(classes, showErrors)}
        />
      </Fragment>
    );
  }
}

Codigos.defaultProps = {
  onChangeCodigos: () => undefined
};
export default Container(withApollo(withNotificationContext(Codigos)));
