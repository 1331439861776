import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { cx } from "emotion";
import { _t } from "js/utils/TranslationService";

import Condiciones from "./Condiciones";
import Sucursales from "./Sucursales";
import Descripcion from "./Descripcion";
import get from "lodash/get";
import Button from "@material-ui/core/Button";

class Producto extends Component {
  backToCatalogo = () => {
    const { history } = this.props;
    history.push({ pathname: "/catalogo", state: { filter: true } });
  };

  render() {
    const proveedor = this.props.eleccionProducto.producto.proveedor;
    const condicionCanje = get(
      this.props,
      "eleccionProducto.producto.condicionCanje",
      null
    );
    const sucursales = this.props.eleccionProducto.producto.sucursales;
    const producto = this.props.eleccionProducto.producto;

    return (
      <div>
        <Grid
          container
          className={cx(
            this.props.classes.producto,
            this.props.classes.detailWrapper
          )}
        >
          <Descripcion
            eleccionProducto={this.props.eleccionProducto}
            classes={this.props.classes}
          />
          {((proveedor.publicaSucursales && proveedor.tieneSucursales) ||
            producto.tieneSucursales) &&
            sucursales.find(
              s => s.estado === "ACTIVO" && s.nombre.toLowerCase() !== "default"
            ) && (
              <Sucursales
                eleccionProducto={this.props.eleccionProducto}
                sucursales={sucursales.filter(
                  s =>
                    s.estado === "ACTIVO" &&
                    s.nombre.toLowerCase() !== "default"
                )}
                classes={this.props.classes}
              />
            )}
          {/* {proveedor.publicaPaginaWeb &&
            proveedor.urlPaginaWeb && (
              <PaginaWeb
                url={proveedor.urlPaginaWeb}
                nombreFantasia={proveedor.nombreFantasia}
                classes={this.props.classes}
              />
            )} */}
          {condicionCanje && <Condiciones condicionCanje={condicionCanje} />}
          <Grid item xs={12}>
            <Button
              style={{ float: "right" }}
              onClick={this.backToCatalogo}
              variant="contained"
              color="primary"
            >
              {_t("Volver al catálogo")}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Producto.defaultProps = {
  text: "Producto"
};

Producto.propTypes = {
  text: PropTypes.string.isRequired
};

export default Producto;
