import React, { Component } from "react";
import Icon from "@material-ui/core/Icon";

class LikeIcon extends Component {
  state = { starActive: false };
  render() {
    return (
      <Icon
        color={this.props.color}
        style={{ cursor: "pointer" }}
        className={this.props.classes.heartIcon}
        onClick={this.props.onClick}
        onMouseOver={() => this.setState({ starActive: true })}
        onMouseOut={() => this.setState({ starActive: false })}
      >
        {this.state.starActive || this.props.favorito
          ? "favorite"
          : "favorite_border"}
      </Icon>
    );
  }
}

export default LikeIcon;
