import React, { PureComponent } from "react";
import { withApollo } from "react-apollo";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { Grid, FormControl } from "@material-ui/core";

import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { UsuarioField, TypeAhead } from "js/components/Shared/Form/Fields";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import AuthService from "js/utils/AuthService";
import MedGraphQL from "js/graphql/resolvers/medUnilever.resolver";
import CampanasOPGraphQL from "js/graphql/resolvers/campanaUnilever.resolver";

class advancedFilter extends PureComponent {
  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  updateQueryParamsAsync = ({ key, value }) => state => ({
    [key]: value,
    queryParams: {
      programaLike: { [key]: value }
    }
  });

  updateAutocompleteQueryParamsUsuario = key => select => {
    const value = select && select[0] ? select[0].value : undefined;
    this.setState(
      this.updateQueryParamsAsync({ key, value: value }),
      this.props.submitValue({ target: { name: key, value: value } })
    );
  };

  render() {
    const { value, classes, submitValue } = this.props;
    const idPrograma = parseInt(AuthService.getIdPrograma());
    const isMisTargets = window.location.pathname.indexOf("mis") !== -1;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              query={CampanasOPGraphQL.queries.getCampanasInput}
              queryParams={{
                campanaOPLike: {
                  idPrograma: idPrograma,
                  tipo: "SIMPLE"
                },
                orderBy: "fechaInicio",
                order: "desc"
              }}
              placeholder={"Campaña"}
              label={"Campaña"}
              path={{
                path: ["getCampanasOP", "itemsPagina"],
                value: "_id",
                label: "nombre",
                defaultLabel: "nombre"
              }}
              onChange={this.handleChange("idCampana")}
              value={value["idCampana"]}
              withTodos
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              query={
                isMisTargets
                  ? MedGraphQL.queries.getMisMedsInput
                  : MedGraphQL.queries.getMedsInput
              }
              queryParams={{
                medLike: {
                  idPrograma: idPrograma
                }
              }}
              placeholder={"MED"}
              label={"MED"}
              path={{
                path: isMisTargets
                  ? ["getMisMeds", "itemsPagina"]
                  : ["getMeds", "itemsPagina"],
                value: "codigo",
                label: "label",
                defaultLabel: "label"
              }}
              onChange={this.handleChange("codigoMed")}
              value={value["codigoMed"]}
              withTodos
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <UsuarioField
            label={"Usuario"}
            async
            withTodos={true}
            placeholder={"Buscar por usuario"}
            idPrograma={value["idPrograma"]}
            noOptionsMessage={"No hay usuarios"}
            onChange={this.updateAutocompleteQueryParamsUsuario("idUsuario")}
            value={value["idUsuario"]}
            disabled={!value["idPrograma"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionDesde"}
              name={"fechaCreacionDesde"}
              label="Fecha Creación (Desde)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionDesde",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionDesde"]
                  ? moment(value["fechaCreacionDesde"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionHasta"}
              name={"fechaCreacionHasta"}
              label="Fecha Creación (Hasta)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionHasta",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionHasta"]
                  ? moment(value["fechaCreacionHasta"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withApollo(withStyles(styles)(advancedFilter));
