import React from "react";
import { Field } from "redux-form";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { _t } from "js/utils/TranslationService";
import { Select } from "redux-form-material-ui";
import { styles } from "../styles.js";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const CustomSelect = inputProps => {
  const {
    meta: { touched, error },
    input: { name },
    label,
    translation,
    required,
    options
  } = inputProps;
  if (inputProps.input.value === "") {
    inputProps.input.value = [];
  }
  return (
    <FormControl
      error={Boolean(touched && error)}
      fullWidth
      required={required}
    >
      {label && (
        <InputLabel htmlFor={name}>{translation || _t(label)}</InputLabel>
      )}
      <Select
        {...inputProps}
        onChange={inputProps.input.onChange}
        multiple
        renderValue={selected => {
          let label = "";
          if (options) {
            options.map(option => {
              if (
                inputProps.input.value.includes(option) ||
                (option.value && inputProps.input.value.includes(option.value))
              ) {
                const optionLabel = option.label ? option.label : option;
                label = label === "" ? optionLabel : label + ", " + optionLabel;
              }
            });
          }
          return label;
        }}
        MenuProps={MenuProps}
      >
        {options &&
          options.map(option => (
            <MenuItem
              key={option.value ? option.value : option}
              value={option.value ? option.value : option}
            >
              <Checkbox
                checked={
                  inputProps.input.value.includes(option) ||
                  (option.value &&
                    inputProps.input.value.includes(option.value))
                }
              />
              <ListItemText primary={option.label ? option.label : option} />
            </MenuItem>
          ))}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const ListField = ({
  name,
  label,
  required,
  options,
  classes,
  disabled,
  onChange = () => {}
}) => (
  <Field
    name={name}
    label={label}
    disabled={disabled}
    component={CustomSelect}
    className={classes ? classes.field : styles.field}
    required={required}
    onChange={onChange}
    props={{ options }}
  />
);

export default ListField;
