import React, { PureComponent } from "react";
import AppQraphQL from "js/graphql/resolvers/linksTracking.resolver";
import Typography from "@material-ui/core/Typography";
import AuthService from "js/utils/AuthService";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      trackearTodos: false,
      id: this.props.match.params.id
    };
  }

  async componentDidMount() {
    await this.getLinksToTrack();
  }

  async getLinksToTrack() {
    const { client } = this.props;

    await client
      .query({
        query: AppQraphQL.queries.getLinksToTrack,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: parseInt(this.state.id)
        }
      })
      .then(res => {
        this.setState({
          trackearTodos: res.data.getLinksToTrack.trackearTodos
        });
      });
  }

  async onChangeSwitch(e) {
    const { client } = this.props;
    this.setState({ trackearTodos: e.target.checked });
    AuthService.setTrackearTodosLinks(e.target.checked);

    await client
      .mutate({
        mutation: AppQraphQL.mutations.updateLinksToTrack,
        variables: {
          idPrograma: parseInt(this.state.id),
          trackearTodos: e.target.checked // this.state.trackearTodos
        }
      })
      .then(res => {
        this.props.openNotification("Actualizado correctamente");
      })
      .catch(errors => {
        console.log("error", errors);
        return false;
      });
  }

  render() {
    const { classes, history } = this.props;
    const { initialValues, id } = this.state;

    return (
      <div className={classes.root}>
        <Grid container direction="row" justify="space-between" spacing={16}>
          <Grid item md={8}>
            <Grid container spacing={8}>
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  Configuración
                </Typography>
                <FormControl className={classes.formControlSwitch}>
                  <FormLabel>Trackear TODOS los links.</FormLabel>
                  <Switch
                    id="trackearTodos"
                    name="trackearTodos"
                    onChange={e => this.onChangeSwitch(e)}
                    checked={this.state.trackearTodos}
                    color="primary"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Edit;
