import React from "react";
import { get } from "lodash";
import { formatValuesWithNegative } from "../utils";
import { getTranslations } from "js/locale";
import { Monto } from "js/components/Shared/Tabla/TableCells";

export default [
  { id: "fecha", type: "date", label: "Fecha" },
  //   { id: "cuentaCorriente_usuario_username", label: "Usuario" },
  {
    id: "cuentaCorriente",
    sortName: "cuentaCorriente_usuario_username",
    label: "Usuario",
    type: "custom",
    parser: cuentaCorriente =>
      get(cuentaCorriente, ["usuario", "username"], "--")
  },
  {
    id: "cuentaCorriente",
    sortName: "cuentaCorriente_programa_nombre",
    label: "Programa",
    type: "custom",
    parser: cuentaCorriente =>
      get(cuentaCorriente, ["usuario", "programa", "nombre"], "--")
  },
  {
    id: "cuentaCorriente",
    label: "Segmento",
    type: "custom",
    removeSort: true,
    parser: cuentaCorriente =>
      get(cuentaCorriente, ["usuario", "segmento", "nombre"], "--")
  },
  {
    id: "tipoOperacion",
    label: "Tipo",
    type: "custom",
    parser: tipoOperacion => getTranslations("es", tipoOperacion)
  },
  { id: "concepto", label: "Concepto" },
  {
    id: "puntos",
    label: "Puntos",
    type: "custom",
    isNumeric: true,
    parser: (puntos, item) => (
      <Monto value={puntos} tipoOperacion={item.operacion} />
    )
  },
  {
    id: "balance",
    label: "Saldo CC",
    type: "custom",
    isNumeric: true,
    parser: puntos => formatValuesWithNegative(puntos),
    classes: "numericCell"
  }
];
