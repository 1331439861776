export default theme => ({
  bar: { paddingTop: 10 },
  row: {
    padding: 10,
    paddingTop: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  button: {
    margin: 10,
    marginLeft: 20,
    marginRight: 0
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    alignSelf: "stretch"
  },
  fieldStretch: { alignSelf: "stretch" },
  facturaForm: {
    width: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
});
