import React from "react";
import Tabla from "js/pages/Unilever/Usuario/Tabla";

export const Usuario = props => {
  return (
    <div>
      <Tabla />
    </div>
  );
};

export default Usuario;
