import { _t } from "js/utils/TranslationService";

// Check if the value is set, if not return false
export const isRequired = value =>
  value || value === 0 ? undefined : _t("Requerido");
// Validate length to not be greater than max value
export const maxLength = max => value =>
  value && value.length > max
    ? `${_t(`Debe tener como máximo: ${max}`)}`
    : undefined;
// Validate value to not be lower than min value
export const minValue = min => value =>
  value && value < min ? `${_t(`Debe ser por lo menos: ${min}`)}` : undefined;
// Validate value to not be greater than max value
export const maxValue = max => value =>
  value && value > max ? `${_t(`No puede ser más de: ${max}`)}` : undefined;
// Check if the value is a number
export const isNumeric = value =>
  value && isNaN(Number(value)) ? "Debe ser un número" : undefined;
// Check if the value is a number > 0
export const isNumericGtZero = value =>
  value && Number(value) <= 0 ? "Debe ser un número mayor a cero" : undefined;
// Check if the value is a valid email
export const isValidEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? _t("Email inválido")
    : undefined;
// Check if the value is an Integer
export const isInteger = value =>
  value && value % 1 !== 0 ? "Debe ser un número entero" : undefined;
//
export const minLength = min => value =>
  value && value.length < min
    ? `${_t(`Debe tener mínino de caracteres: ${min}`)}`
    : undefined;
// Check if the value has at least one lower case letter
export const hasLowerCase = value =>
  /[a-z]/.test(value)
    ? undefined
    : _t("Debe tener al menos una letra minúscula");
// Check if the value has at least one upper case letter
export const hasUpperCase = value =>
  /[A-Z]/.test(value)
    ? undefined
    : _t("Debe tener al menos una letra mayúscula");
// Check if the value has at least one number
export const hasNumber = value =>
  /[0-9]/.test(value) ? undefined : _t("Debe tener al menos un número");
// Check if the value is an URL
export const isValidUrl = value => {
  if (!value) return undefined;
  const pattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g;
  return value && pattern.test(value)
    ? undefined
    : _t("Debe ser una URL válida");
};
export const isValidUrlOrEmpty = value => {
  if (!value) return undefined;
  return isValidUrl(value);
};
