import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { reduxForm, formValueSelector, change, getFormMeta } from "redux-form";
import {
  Grid,
  Button,
  Typography,
  FormControl,
  TextField,
  Switch,
  FormLabel,
  Tooltip
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import get from "lodash/get";
import { copyToClipboard } from "js/utils";

import {
  InputTextField,
  ListField,
  DatePicker
} from "js/components/Shared/Form/Fields";
import { styles } from "js/components/Shared/Form/styles.js";
import { renderFechaWithFormat } from "js/utils/Helper.js";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tieneProgramaTercero: props.initialValues.tieneProgramaTercero,
      fechaVencimiento: null,
      snackOpen: false,
      programasTercerosPagina: props.initialValues.programasTercerosPagina,
      programaTercero: props.initialValues.programaTercero || {}
    };
  }

  handleFechaVencimientoChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaVencimiento: date
    });
  };

  handleTieneProgramaTerceroChange = e => {
    this.props.handleChangeTieneProgramaTercero(e.target.checked);
    this.setState({ tieneProgramaTercero: e.target.checked });
  };

  handleChangeProgramaTerceroAsociado = event => {
    this.props.handleChangeProgramaTerceroAsociado(event.target.value);
    this.setState({
      programaTercero: {
        _id: event.target.value
      }
    });
  };

  handleCopyToken = e => {
    copyToClipboard(
      e.target.value,
      this.props.openNotification,
      "Token copiado al portapapeles"
    );
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      id,
      initialValues,
      idToken
    } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {initialValues.token ? "Editar Api Token" : "Nuevo Api Token"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form className={classes.container} onSubmit={handleSubmit}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors && errors.map(e => <li>{e.message}</li>)}
                </ul>
              </Grid>
            </Grid>

            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.field}>
                  <InputTextField name="nombre" label="Nombre" required />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl className={classes.field}>
                  <ListField
                    name="estado"
                    label="Estado"
                    options={[
                      { label: "Activo", value: "ACTIVO" },
                      { label: "Inactivo", value: "INACTIVO" }
                    ]}
                    disabled={disabled}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl className={classes.field}>
                  <DatePicker
                    name={"fechaVencimiento"}
                    dateFormat="DD/MM/YYYY"
                    label={"Fecha vencimiento"}
                    autoOk
                    disabled={disabled || idToken !== "new"}
                    input={{
                      value:
                        this.state.fechaVencimiento !== "" &&
                        this.state.fechaVencimiento
                          ? new Date(this.state.fechaVencimiento)
                          : initialValues.fechaVencimiento
                            ? new Date(initialValues.fechaVencimiento)
                            : null,
                      onChange: this.handleFechaVencimientoChange(
                        "fechaVencimiento"
                      )
                    }}
                    minDate={moment().add(1, "d")}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl className={classes.field}>
                  <InputTextField name="descripcion" label="Descripción" />
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl className={classes.field}>
                  <FormLabel>Tercero relacionado</FormLabel>
                  <Switch
                    id="tieneProgramaTercero"
                    name="tieneProgramaTercero"
                    onChange={this.handleTieneProgramaTerceroChange}
                    checked={this.state.tieneProgramaTercero}
                    color="primary"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.field}>
                  <FormLabel>Programa Tercero</FormLabel>
                  <Select
                    disabled={!this.state.tieneProgramaTercero}
                    value={this.state.programaTercero._id || "Seleccionar"}
                    onChange={this.handleChangeProgramaTerceroAsociado}
                  >
                    <MenuItem value={"Seleccionar"}>Seleccionar</MenuItem>
                    {Array.isArray(initialValues.programasTercerosPagina) &&
                      initialValues.programasTercerosPagina.map((pt, index) => {
                        return (
                          <MenuItem key={index} value={pt._id}>
                            {pt.nombre}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>

              {/* 
              Mostrar ID Programa Tercero en disabled?
              <Grid item xs={4}>
                <FormControl className={classes.field}>
                  <InputTextField
                    disabled
                    name="programaTercero._id"
                    label="ID Programa Tercero"
                  />
                </FormControl>
              </Grid> */}

              {/**               
              TODO: probar a usar este TypeAhead en vez del MenuItem
              <Grid item xs={12} md={4}>
                <FormControl className={classes.field}>
                  <TypeAhead
                    label="Programa Tercero"
                    noOptionsMessage="No hay Programas Terceros"
                    placeholder="Buscar"
                    onChange={e => {
                      this.handleChangeProgramaTercero(e);
                    }}
                    path={{
                      path: "programasTercerosPagina",
                      value: "_id",
                      label: "nombre"
                    }}
                    suggestions={initialValues}
                    value={
                      this.state.programaTerceroNombre
                        ? this.state.programaTerceroNombre.label
                        : "Sin programa tercero"
                    }
                    disabled={!this.state.tieneProgramaTercero}
                  />
                </FormControl>
              </Grid> 
              */}

              <Grid item xs={12}>
                <Tooltip title="Copiar Token">
                  <FormControl className={classes.field}>
                    <InputTextField
                      onClick={e => this.handleCopyToken(e)}
                      name="token"
                      label="Token"
                      disabled
                    />
                  </FormControl>
                </Tooltip>
              </Grid>

              {idToken !== "new" && (
                <Grid container item xs={12} spacing={16}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      style={{ width: "100%" }}
                      label="Fecha de alta"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={
                        renderFechaWithFormat(
                          "DD/MM/YYYY HH:mm:ss",
                          initialValues.fechaCreacion
                        ) || ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      style={{ width: "100%" }}
                      label="Usuario creador"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={get(
                        initialValues,
                        "usuarioCreador.username",
                        "--"
                      )}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      style={{ width: "100%" }}
                      label="Última modificación"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={
                        renderFechaWithFormat(
                          "DD/MM/YYYY HH:mm:ss",
                          initialValues.fechaUltimaModificacion
                        ) || ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      style={{ width: "100%" }}
                      label="Usuario modificador"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={get(
                        initialValues,
                        "usuarioModificador.username",
                        "--"
                      )}
                      disabled
                    />
                  </Grid>
                </Grid>
              )}

              <Grid className={classes.gridButton} item xs={12}>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  className={classes.button}
                  style={{ marginRight: 20 }}
                  onClick={() =>
                    history.push(`/admin/programa/${id}/api-tokens`)
                  }
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  disabled={pristine || submitting}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

Form = reduxForm({
  form: "formCanalRetail",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formCanalRetail");

Form = connect(
  state => ({
    formMeta: getFormMeta("formCanalRetail")(state),
    idProgramaField: selector(state, "idPrograma")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formCanalRetail", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
