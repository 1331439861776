import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";

export const ProgressBar = () => {
  return (
    <>
      <Grid
        container
        xs={12}
        justify="center"
        alignContent="center"
        style={{ minHeight: "30%" }}
      >
        <Grid container justify="center" xs={12}>
          <Typography gutterBottom xs={12} variant="h5">
            Cargando...
          </Typography>
        </Grid>
        <Grid container justify="center" xs={12}>
          <CircularProgress color="primary" size={60} />
        </Grid>
      </Grid>
    </>
  );
};
