import React, { PureComponent, Fragment } from "react";

import MedGraphQL from "js/graphql/resolvers/medUnilever.resolver";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import Form from "js/pages/Unilever/Med/Preventistas/MyForm.js";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    const params = {
      idPrograma: null,
      codigoMed: null
    };
    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;

    if (inBackOffice) {
      params.idPrograma = props.match.params.id;
      params.codigoMed = props.match.params.codigo;
    } else {
      params.idPrograma = parseInt(AuthService.getIdPrograma());
      params.codigoMed = props.computedMatch.params.codigo;
    }

    this.state = {
      ...params,
      inBackOffice: inBackOffice,
      isMisMeds: window.location.pathname.indexOf("mis-meds") !== -1,
      isMisPreventistas:
        window.location.pathname.indexOf("mis-preventistas") !== -1,
      initialValues: {},
      med: null,
      disabled: !AuthService.hasPerms([Roles.PREVENTISTA_PORCENTAJE_W]),
      errors: null,
      preventistas: []
    };
  }

  async componentDidMount() {
    this.fetchPreventistas();
  }

  async fetchPreventistas() {
    const { client } = this.props;
    const {
      idPrograma,
      codigoMed,
      inBackOffice,
      isMisMeds,
      isMisPreventistas
    } = this.state;
    const variables = {
      idPrograma
    };

    if (codigoMed) {
      variables.codigo = codigoMed;
    }

    await client
      .query({
        query:
          inBackOffice || (!inBackOffice && !isMisMeds && !isMisPreventistas)
            ? MedGraphQL.queries.getPreventistasMed
            : MedGraphQL.queries.getPreventistasMiMed,
        fetchPolicy: "network-only",
        variables
      })
      .then(res => {
        const med =
          inBackOffice || (!inBackOffice && !isMisMeds && !isMisPreventistas)
            ? res.data.getPreventistasMed
            : res.data.getPreventistasMiMed;
        const preventistas = med.preventistas.map(preventista => {
          return {
            idUsuario: preventista.usuario.idUsuario,
            nombre: `${preventista.usuario.perfil.nombre} ${
              preventista.usuario.perfil.apellido
            }`,
            porcentaje: preventista.porcentaje,
            estado: preventista.usuario.estado
          };
        });
        this.setState({
          med,
          initialValues: {
            preventistas
          }
        });
      });
  }

  async submit(values) {
    const { client, history } = this.props;
    const {
      idPrograma,
      med,
      codigoMed,
      inBackOffice,
      isMisMeds,
      isMisPreventistas
    } = this.state;

    const preventistas = [];
    let total = 0;
    values.preventistas.forEach(preventista => {
      total += parseInt(preventista.porcentaje);
      preventistas.push({
        idUsuario: preventista.idUsuario,
        porcentaje: parseInt(preventista.porcentaje)
      });
    });

    if (total > 100) {
      const mensaje = `El total de porcentajes no puede superar el 100. Total actual: ${total}`;
      this.setState({
        errors: [{ message: mensaje }]
      });
      return;
    }

    if (total < 100) {
      const mensaje = `El total de porcentajes no puede ser menor que 100. Total actual: ${total}`;
      this.setState({
        errors: [{ message: mensaje }]
      });
      return;
    }

    await client
      .mutate({
        mutation: MedGraphQL.mutations.updatePreventistasMed,
        variables: {
          codigo: codigoMed ? codigoMed : med.codigo,
          idPrograma,
          preventistas: preventistas
        }
      })
      .then(res => {
        const mensaje =
          "Porcentajes de preventistas actualizados correctamente";
        this.props.openNotification(mensaje);
        if (inBackOffice) {
          history.push(`/admin/programa/${idPrograma}/meds`);
        } else if (isMisMeds) {
          // Para los EDD
          history.push(`/mis-meds`);
        } else if (isMisPreventistas) {
          // Para los Supervisores
          history.push(`/targets-mis-preventistas`);
        } else {
          // Para los Admins_Cliente
          history.push(`/meds`);
        }
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  async importVendedores(file) {
    const { client } = this.props;
    const { med } = this.state;

    await client
      .mutate({
        mutation: AppQraphQLImport.queries.importarPreventistasMed,
        variables: {
          file,
          idPrograma: med.programa.idPrograma,
          codigoMed: med.codigo
        }
      })
      .then(res => {
        const mensaje =
          "Porcentajes de preventistas actualizados correctamente";
        this.props.openNotification(mensaje);
        this.fetchPreventistas();
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  render() {
    const {
      med,
      campanas,
      errors,
      disabled,
      initialValues,
      idPrograma,
      inBackOffice,
      isMisMeds,
      isMisPreventistas
    } = this.state;

    return (
      <Fragment>
        <Form
          id={idPrograma}
          med={med}
          campanas={campanas}
          errors={errors}
          disabled={disabled}
          initialValues={initialValues}
          onSubmit={this.submit.bind(this)}
          inBackOffice={inBackOffice}
          isMisMeds={isMisMeds}
          isMisPreventistas={isMisPreventistas}
          importVendedores={this.importVendedores.bind(this)}
        />
      </Fragment>
    );
  }
}

export default Edit;
