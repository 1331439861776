import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import IdiomaQraphQL from "js/graphql/resolvers/idioma.resolver";
import { styles } from "js/pages/Admin/Programa/Segmentos/List/styles.js";
import Tabla from "./Tabla";
import get from "lodash/get";

class ItemList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      nombre: "",
      descripcion: "",
      idIdioma: "",
      open: false
    };
  }

  handleClickOpen = value => {
    this.setState({
      open: true,
      id: value.idSegmento,
      nombre: value.nombre,
      descripcion: value.descripcion,
      idIdioma: get(value, "idiomaDefault._id", null)
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      id: null,
      nombre: "",
      descripcion: "",
      idIdioma: ""
    });
  };

  handleChangeNombre = e => {
    const nombre = e.target.value;
    this.setState({ nombre });
  };

  handleChangeDescripcion = e => {
    this.setState({ descripcion: e.target.value });
  };

  handleChangeIdioma = select => {
    this.setState({ idIdioma: select.value });
  };

  handleSave = () => {
    const { id, nombre, descripcion, idIdioma } = this.state;
    this.props.onHandleSave(
      id,
      nombre,
      descripcion,
      idIdioma,
      this.handleClose
    );
  };

  render() {
    const { nombre, descripcion, open } = this.state;
    const { classes, fullScreen, initialValues, idPrograma } = this.props;
    return (
      <div className={classes.root}>
        <Tabla
          data={initialValues || []}
          handleClick={this.handleClickOpen.bind(this)}
        />
        <Dialog
          open={open}
          onClose={this.handleClose}
          fullScreen={fullScreen}
          aria-labelledby="form-dialog-title"
          classes={{
            paperScrollPaper: classes.overflow,
            paper: classes.dialogPaper
          }}
        >
          <DialogTitle id="form-dialog-title">Editar segmento</DialogTitle>
          <DialogContent style={{ overflow: "visible" }}>
            <TextField
              id={nombre}
              value={nombre}
              label="Nombre del segmento"
              type="search"
              margin="dense"
              autoFocus
              fullWidth
              onChange={e => this.handleChangeNombre(e)}
            />
            <TextField
              id={descripcion}
              value={descripcion}
              label="Descripción"
              type="search"
              margin="dense"
              autoFocus
              fullWidth
              onChange={e => this.handleChangeDescripcion(e)}
              style={{ marginBottom: 12 }}
            />
            <TypeAhead
              placeholder={"Idioma default"}
              onChange={this.handleChangeIdioma.bind(this)}
              path={{
                path: "getProgramas.itemsPagina[0].idiomas",
                value: "idioma._id",
                label: "idioma.nombre"
              }}
              query={IdiomaQraphQL.queries.getIdiomasByProgramaId}
              queryParams={{ idPrograma: parseInt(idPrograma) || idPrograma }}
              value={this.state.idIdioma}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancelar</Button>
            <Button
              onClick={this.handleSave.bind(this)}
              color="primary"
              variant="contained"
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ItemList.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  handleSave: PropTypes.func
};

ItemList.default = {
  handleSave: () => undefined
};

export default withMobileDialog()(
  withApollo(withRouter(withStyles(styles)(ItemList)))
);
