export default theme => ({
  card: {
    maxWidth: "100%",
    backgroundColor: "#FFF",
    borderRadius: "15px",
    boxShadow: "0px 3px 8px 0px rgba(0,0,0,0.75)"
  },
  containerPrincipal: {
    backgroundColor: "#f9f9f9"
  },
  containerPaginador: {
    backgroundColor: "#f9f9f9"
  },
  nombreReconocedor: {
    display: "inline"
  },
  fechaAprobacion: {
    display: "inline",
    fontStyle: "italic",
    float: "right"
  },
  icon: {
    color: theme.palette.primary.main,
    display: "inline",
    float: "right",
    marginTop: "-5px"
  },
  textoNominados: {
    color: theme.palette.primary.main,
    display: "inline"
  },
  textoInteriorPregunta: {
    display: "inline",
    fontWeight: "bold"
  },
  // textoInteriorRespuesta: {
  //   "& span": {
  //     display: "inline"
  //   }
  // },
  containerFooter: {
    maxWidth: "100%",
    backgroundColor: theme.palette.primary.main,
    padding: "5px",
    paddingLeft: "15px",
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px"
  },
  containerBodyCard: {
    padding: "15px"
  },
  textoFooterTitulo: {
    display: "inline",
    color: "#FFF"
  },
  textoFooterRespuesta: {
    display: "inline",
    fontWeight: "bold",
    color: "#FFF"
  },
  textoTitulo: {
    // color: theme.palette.getContrastText(theme.palette.secondary.main),
    color: "#FFF",
    textAlign: "center",
    padding: "20px",
    fontSize: "xx-large"
  },
  fondoTitulo: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: "40px"
  },
  usuarioField: {
    backgroundColor: "#FFF",
    borderRadius: "5px",
    padding: "4px 10px"
  },
  label: {
    color: theme.palette.primary.main
  },
  formControl: {
    padding: "4px 0px",
    width: "100%",
    marginTop: "15px",
    // "& div": {
    "& input": {
      padding: "8px 10px"
    }
    // }
  },
  valueContainer: {
    padding: "0px 11px"
  },
  resumenLabel: {
    display: "inline"
  },
  resumenCantidad: {
    display: "inline",
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  resumenLabelColor: {
    display: "inline",
    color: theme.palette.primary.main
  },
  resumenCantidadColor: {
    display: "inline",
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  resumenCard: {
    padding: "0px",
    background: `linear-gradient(-70deg, ${
      theme.palette.primary.main
    } 2%, #FFF 2.1%)`
  },
  containerTotal: {
    background: `linear-gradient(-70deg, #f9f9f9 15%, ${
      theme.palette.primary.main
    } 16%)`,
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    paddingRight: "24px !important",
    paddingLeft: "24px !important"
  },
  containerIndividual: {
    backgroundColor: "#f9f9f9"
  },
  containerGrupal: {
    background: `linear-gradient(-70deg, #FFF 15%, #f9f9f9 16%)`,
    paddingRight: "24px !important"
  }
});
