import React, { Fragment } from "react";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";
import { Autocomplete } from "js/components/Shared/Form/Fields";
import { Query } from "react-apollo";
import { has, get } from "lodash";
import Grid from "@material-ui/core/Grid";
// import UsuarioQraphQL from "js/graphql/resolvers/usuario.resolver";
import client from "js/App/client.graphql.js";
import ProveedorQraphQL from "js/graphql/resolvers/proveedor.resolver";
import FacturaQraphQL from "js/graphql/resolvers/facturas.resolver";
// import FacturaQraphQL from "js/graphql/resolvers/facturas.resolver";
import Button from "@material-ui/core/Button";

import Slide from "@material-ui/core/Slide";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogFactura extends React.Component {
  state = {
    idProveedorFactura: undefined,
    fecha: moment(),
    importe: undefined,
    factura: undefined
  };

  getAutocompleteValue = (fn, data, stateValue) =>
    stateValue !== undefined
      ? fn(data).find(p => p.value === stateValue)
      : { value: undefined, label: "" };

  getProveedoresSuggestions = data =>
    has(data, ["getProveedores", "itemsPagina"])
      ? data.getProveedores.itemsPagina.map(proveedor => ({
          value: proveedor.idProveedor,
          label: proveedor.nombreFantasia
        }))
      : [];

  updateAutocompleteQueryParams = select => {
    this.setState(prevState => ({
      idProveedorFactura: select.value
    }));
  };

  isDisabled = () =>
    this.state.idProveedorFactura !== undefined &&
    this.state.fecha !== undefined &&
    this.state.importe !== undefined &&
    this.state.factura !== undefined;

  // TODO : implementar la mutation
  sendValues = async () => {
    const filters = this.props.filters;
    const { idProveedorFactura, importe, factura } = this.state;
    const fecha = this.state.fecha.format("YYYY-MM-DD");

    const { data } = await client.mutate({
      mutation: FacturaQraphQL.mutations.crearFactura,
      errorPolicy: "all",
      variables: {
        idProveedorFactura,
        fecha,
        importe: parseInt(importe, 10),
        factura,
        ...filters
      }
    });

    if (data) {
      const message =
        "Se han asignado los canjes filtrados a la factura seleccionada";
      this.props.openNotification(message);
      this.props.handleClose();
    }
  };

  handleChangeFecha = date => {
    this.setState({ fecha: date });
  };

  handleChange = key => event => {
    this.setState({ [key]: event.target.value });
  };
  render() {
    const { open, handleClose, classes } = this.props;
    return (
      <Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={classes.dialogTitle}
          >
            Crear a factura
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Query
              query={ProveedorQraphQL.queries.getProveedoresSimple}
              errorPolicy="all"
            >
              {({ loading, error, data }) => {
                return (
                  <FormControl>
                    <Grid
                      container
                      cspacing={24}
                      className={classes.facturaForm}
                    >
                      <Grid item xs={12}>
                        <Autocomplete
                          isDisabled={false}
                          onChange={this.updateAutocompleteQueryParams}
                          noOptionsMessage={() => "No hay proveedores"}
                          placeholder="Buscar por proveedor"
                          suggestions={this.getProveedoresSuggestions(data)}
                          value={this.getAutocompleteValue(
                            this.getProveedoresSuggestions,
                            data,
                            get(this.state, "idProveedorFactura")
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="standard-name"
                          label="Número de la factura"
                          className={classes.textField}
                          value={this.state.factura}
                          onChange={this.handleChange("factura")}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          input={{
                            onChange: this.handleChangeFecha,
                            value: this.state.fecha
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          type="number"
                          id="standard-name"
                          label="Importe"
                          className={classes.textField}
                          value={this.state.importe}
                          onChange={this.handleChange("importe")}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                );
              }}
            </Query>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Descartar
            </Button>

            <Button
              disabled={!this.isDisabled()}
              onClick={this.sendValues}
              variant="contained"
              color="primary"
            >
              Crear
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DialogFactura.defaultProps = {};

DialogFactura.propTypes = {};

export default DialogFactura;
