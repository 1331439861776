import React from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import Spinner from "js/components/Shared/Spinner";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";

const Faq = props => {
  const { preguntas } = props;

  if (preguntas === undefined)
    return <Spinner thickness={7} color="inherit" variant="indeterminate" />;
  else if (isEmpty(preguntas)) {
    return <Redirect to="/landing" />;
  }
  return <div>{Parser(preguntas)}</div>;
};

Faq.propTypes = {
  classes: PropTypes.object,
  preguntas: PropTypes.string
};

Faq.defaultProps = {};

Faq.displayName = "Faq";

export default Faq;
