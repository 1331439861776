import { connect } from "react-redux";
import Dialog from "./Dialog";
import productoActions from "../actions";

const mapStateToProps = state => ({
  idEleccionProducto: state.producto.idEleccionProducto,
  cantidad: state.producto.cantidad,
  datosRequeridos: state.producto.datosRequeridos,
  openCanjePopup: state.producto.openCanjePopup,
  requeridos: state.producto.requeridos,
  canjeFail: state.producto.canjeFail,
  datosPerfil: state.producto.datosPerfil,
  canjeando: state.canjeando,
  mensajeRequeridos: state.producto.mensajeRequeridos
});

const mapDispatchToProps = dispatch => ({
  productoActions: productoActions({ dispatch })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dialog);
