import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import {
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import ProgramaGraphQL from "js/graphql/resolvers/programa.resolver.js";
import UsuarioGraphQL from "js/graphql/resolvers/usuario.resolver";
import SegmentoGraphQL from "js/graphql/resolvers/segmento.resolver.js";
import PerfilGraphQL from "js/graphql/resolvers/perfil.resolver.js";
import { OPERACION } from "js/constants";
import { numberWithCommas } from "js/utils";
import "./asynctypeahead.css";

import { AsyncTypeAhead, TypeAhead } from "js/components/Shared/Form/Fields";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { updateQueryParamsAsync, initialState } from "../utils";
import { withApollo } from "react-apollo";

class advancedFilter extends Component {
  state = {
    ...initialState,
    balancePrograma: undefined
  };

  updateQueryParams = key => event =>
    this.setState(
      updateQueryParamsAsync({ key, value: event.target.value }),
      this.props.submitValue(event)
    );

  updateUsuarioField = key => select => {
    console.log("select", select);
    this.updateAutocompleteQueryParams(key)(select[0] ? select[0] : {});
  };

  updateAutocompleteQueryParams = key => select => {
    if (key === "idPrograma") {
      this.setState((state, props) => ({
        ...initialState,
        withProgram: true
      }));
    }

    this.setState(
      updateQueryParamsAsync({ key, value: select.value }),
      this.props.submitValue({ target: { name: key, value: select.value } })
    );
  };

  onChangePrograma = props => {
    const {
      value: { idPrograma },
      client
    } = props || this.props;
    const hasPerm = AuthService.hasPerms([Roles.PROGRAMA_LISTA]);
    if (!idPrograma) return this.setState({ balancePrograma: undefined });
    client
      .query({
        query: hasPerm
          ? ProgramaGraphQL.queries.getBalanceByIdPrograma
          : ProgramaGraphQL.queries.getBalanceMiPrograma,
        errorPolicy: "all",
        fetchPolicy: "network-only",
        variables: hasPerm
          ? { idPrograma }
          : {
              url: window.location.hostname.replace("www.", "")
            }
      })
      .then(res => {
        const {
          cuentaCorriente: { balance: balancePrograma },
          maximoMovimientoPuntos
        } = hasPerm
          ? get(res, "data.getProgramas.itemsPagina[0]", {})
          : get(res, "data.getPrograma", {});
        this.setState({ balancePrograma, maximoMovimientoPuntos });
      });
  };

  componentDidMount = () => this.onChangePrograma();

  componentWillReceiveProps = props => {
    const {
      value: { idPrograma }
    } = this.props;
    this.setState(prevState => ({ ...prevState, ...props.value }));
    if (idPrograma !== props.value.idPrograma) this.onChangePrograma(props);
  };

  limpiar = () => {
    this._typeahead.getInstance().clean();
    this.setState(
      (state, props) => ({
        ...initialState
      }),
      this.props.clear()
    );
  };

  componentWillMount = () => {
    const {
      value: { idPrograma, idUsuario }
    } = this.props;
    this.setState({
      idPrograma: parseInt(idPrograma),
      idUsuario: parseInt(idUsuario),
      withProgram: parseInt(idPrograma),
      disablePrograma: !!idPrograma,
      disableUsuario: !!idUsuario
    });
  };

  handleChangeFecha = key => date => {
    this.props.submitValue({
      target: { name: key, value: date.toISOString() }
    });
  };

  render() {
    const puedeEditarGerencia = AuthService.hasPerms([
      Roles.CC_SELECT_GERENCIA
    ]);
    const { value, submitValue, classes, conGerencia } = this.props;
    const { disableUsuario, balancePrograma } = this.state;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <InputLabel id="tipo-cc" htmlFor="tipo-cc">
              Tipo
            </InputLabel>
            <Select
              id="tipo-cc-select"
              value={value["operacion"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "operacion",
                id: "operacion"
              }}
            >
              {OPERACION.map((operacion, index) => (
                <MenuItem key={index} value={operacion.value}>
                  {operacion.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <AsyncTypeAhead
              label={"Usuarios"}
              path={{
                path: "getUsuarios.itemsPagina",
                value: "idUsuario",
                multilabel: [
                  "perfil.nombre",
                  " ",
                  "perfil.apellido",
                  " (",
                  "nombre",
                  ")"
                ]
              }}
              placeholder={"Buscar por usuario"}
              query={UsuarioGraphQL.queries.getUsuarios}
              queryParams={{
                usuarioLike: {
                  idPrograma: this.state.idPrograma,
                  gerencia: value["cuentaCorriente_usuario_gerencia"]
                }
              }}
              bsSize={"lg"}
              queryVariable={"usuarioLike.username_OR_nombre"}
              onChange={this.updateUsuarioField("idUsuario")}
              emptyLabel={"No se encontro ningún usuario"}
              value={this.state.idUsuario}
              disabled={!this.state.idPrograma || disableUsuario}
              ref={ref => (this._typeahead = ref)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              label={"Segmento"}
              path={{
                path: "getSegmentos",
                value: "idSegmento",
                label: "label",
                defaultLabel: "value"
              }}
              placeholder={"Buscar por segmento"}
              query={SegmentoGraphQL.queries.getSegmentosInput}
              queryParams={{ idPrograma: this.state.idPrograma }}
              noOptionsMessage={"No hay segmentos"}
              onChange={this.updateAutocompleteQueryParams("idSegmento")}
              value={this.state.idSegmento}
              disabled={!this.state.idPrograma}
              useCache={false}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="subtitle2" gutterBottom>
            Saldo CC Programa
          </Typography>
          <Typography variant="h4">
            {balancePrograma ? numberWithCommas(balancePrograma) : "--"}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              name={"fechaDesde"}
              label="Desde"
              input={{
                onChange: this.handleChangeFecha("fechaDesde"),
                value: value["fechaDesde"] || null
              }}
              startOfDate
              autoOk
              maxDate={moment()}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              name={"fechaHasta"}
              label="Hasta"
              input={{
                onChange: this.handleChangeFecha("fechaHasta"),
                value: value["fechaHasta"] || null
              }}
              autoOk
              maxDate={moment()}
              endOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TextField
              label={"Concepto"}
              key={"concepto"}
              name={"concepto"}
              type="search"
              value={value["concepto"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        {conGerencia ? (
          <Grid item xs={12} md={3}>
            <FormControl
              style={{ marginTop: "11px" }}
              className={classes.formControl}
            >
              <TypeAhead
                label={"Gerencia"}
                path={{
                  path: "getGerencias"
                }}
                placeholder={"Buscar por gerencia"}
                query={PerfilGraphQL.queries.getGerencias}
                queryParams={{ idPrograma: this.state.idPrograma }}
                noOptionsMessage={"No hay gerencias"}
                onChange={this.updateAutocompleteQueryParams(
                  "cuentaCorriente_usuario_gerencia"
                )}
                value={value["cuentaCorriente_usuario_gerencia"] || ""}
                disabled={!puedeEditarGerencia}
              />
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  clear: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(withApollo(advancedFilter));
