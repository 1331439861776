import React from "react";
import { numberWithCommas } from "js/utils";

const Saldo = props => {
  const { value, classes } = props;
  if (value !== 0 && !value) return "--";
  return (
    <React.Fragment>
      {value < 0 ? (
        <span className={classes.negative}>
          ({numberWithCommas(Math.abs(value))})
        </span>
      ) : (
        numberWithCommas(value)
      )}
    </React.Fragment>
  );
};

export default Saldo;
