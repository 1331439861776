const styles = theme => ({
  container: {
    width: "100%"
  },
  addInput: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: "20px"
  },
  formControl: {
    margin: theme.spacing.unit,
    width: 300
  },
  button: {
    margin: theme.spacing.unit
  }
});
export default styles;
