import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuPrograma from "./menuPrograma.js";
import MainMenu from "./mainMenu.js";

class DesktopDrawer extends React.Component {
  render() {
    const {
      classes,
      theme,
      idPrograma,
      nombrePrograma,
      handleChangeMenu,
      activeMenu,
      open,
      handleDrawerToggle,
      linksMenuPrograma
    } = this.props;

    return (
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          open={open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerToggle}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          {activeMenu === "main" ? (
            <MainMenu showTooltip={!open} />
          ) : (
            <MenuPrograma
              idPrograma={idPrograma}
              nombrePrograma={nombrePrograma}
              handleChangeMenu={handleChangeMenu}
              showTooltip={!open}
              linksMenuPrograma={linksMenuPrograma}
            />
          )}
        </Drawer>
      </div>
    );
  }
}

DesktopDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const drawerWidth = 240;
const toolbarMaxHeight = 50;
const styles = theme => ({
  root: {
    display: "flex"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9 + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    maxHeight: toolbarMaxHeight,
    minHeight: `${toolbarMaxHeight}px!important`
  }
});

export default withStyles(styles, { withTheme: true })(DesktopDrawer);
