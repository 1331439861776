import { compose } from "recompose";
import { withNotificationContext } from "js/notification-context";
import DialogAprobarVariablesMedRaw from "./DialogAprobarVariablesMed";
import { withStyles } from "@material-ui/core/styles";
import styles from "js/components/Shared/Tabla/EnhancedTableToolbar/Dialogs/styles.js";

const enhance = compose(withNotificationContext);

export const DialogAprobarVariablesMed = enhance(
  withStyles(styles)(DialogAprobarVariablesMedRaw)
);

export default {
  DialogAprobarVariablesMed
};
