import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { isWidthDown } from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
import FilterSearch from "./FilterSearch";
import FilterPoints from "./FilterPoints";
import FilterSort from "./FilterSort";
import FilterCategories from "./FilterCategories";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { _t } from "js/utils/TranslationService";

export const FilterPanel = ({ isLogged, classes, cleanFilters, width }) => {
  const headerFilters = (
    <Grid item container className={classes.filterHeader}>
      <Typography variant="h6" gutterBottom>
        {_t("Filtros")}
      </Typography>
      <Button
        onClick={cleanFilters}
        className={classes.cleanFiltersBtn}
        classes={{ textPrimary: classes.textPrimary }}
        size="small"
        color="primary"
        variant="text"
      >
        {_t("Limpiar filtros")}
      </Button>
    </Grid>
  );
  const filtros = (
    <Grid item container className={classes.filtersContainer}>
      {!isWidthDown("sm", width) && headerFilters}
      <Grid item container>
        <FilterSearch />
      </Grid>
      {/* <Hidden only={["xs", "sm"]}> */}
      <Grid item container>
        <FilterPoints />
      </Grid>
      {/* </Hidden> */}
      <Grid item container>
        <FilterSort />
      </Grid>
      <Grid item container>
        <FilterCategories isLogged={isLogged} />
      </Grid>
    </Grid>
  );
  return isWidthDown("sm", width) ? (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          {headerFilters}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{filtros}</ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  ) : (
    <div>{filtros}</div>
  );
};

export default FilterPanel;
