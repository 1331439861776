const currencys = {
  Argentina: { country: "es-AR", currency: "ARS" },
  Paraguay: { country: "es-PY", currency: "PYG" },
  EEUU: { country: "en-US", currency: "USD" },
  Uruguay: { country: "es-UY", currency: "UYU" },
  Perú: { country: "es-PE", currency: "PEN" },
  Colombia: { country: "es-CO", currency: "COP" },
  Chile: { country: "es-CL", currency: "CLP" }
};

export const formatImporte = (importe, paisFormato) => {
  let pais = paisFormato ? currencys[paisFormato] : currencys["Argentina"];

  if (typeof importe !== "number") {
    // Si el importe no es un número, intenta convertirlo
    importe = parseFloat(importe);
  }

  if (!isNaN(importe)) {
    // Si es un número, aplica el formato usando toLocaleString
    return importe.toLocaleString(pais.country, {
      style: "currency",
      currency: pais.currency,
      currencyDisplay: "symbol",
      currencySign: "accounting",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  // Si no es un número, devuelve el valor original
  return importe;
};
