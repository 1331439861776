import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { Lotes } = Fragmentos;

export const crearLote = gql`
  mutation createLote(
    $lote: String!
    $estadoCanje: [EstadoCanje]
    $idsProgramas: [Int]
    $idUsuario: Int
    $excluirFactura: Boolean
    $excluirCanje: Boolean
    $excluirCanceladosYPausados: Boolean
    $administracion: Gestion
    $idProducto: Int
    $fechaDesde: Date
    $fechaHasta: Date
    $idCanje: Int
    $idLote: String
    $idPais: Int
    $idFactura: Int
    $idProveedor: Int
    $tipoProducto: TipoProducto
  ) {
    createLote(
      lote: {
        numeroLote: $lote
        canjeLike: {
          eleccionProducto_idProducto: $idProducto
          eleccionProducto_idPrograma: $idsProgramas
          estado: $estadoCanje
          numeroLote: $idLote
          excluirFacturados: $excluirFactura
          excluirCanceladosYPausados: $excluirCanceladosYPausados
          excluirLoteados: $excluirCanje
          eleccionProducto_producto_gestionadoPor: $administracion
          eleccionProducto_producto_tipoProducto: $tipoProducto
          idCanje: $idCanje
          idPais: $idPais
          idFactura: $idFactura
          idProveedor: $idProveedor
          idUsuario: $idUsuario
        }
        canjeRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
      }
    ) {
      numeroLote
    }
  }
`;

export const addCanjeALote = gql`
  mutation addCanjeALote($lote: String!, $idCanje: Int!) {
    addCanjeALote(lote: { numeroLote: $lote, idCanje: $idCanje }) {
      numeroLote
    }
  }
`;

export const getLote = gql`
  query getLote(
    $numeroLote: String
    $fechaDesde: Date
    $fechaHasta: Date
    $estadoLote: EstadoLote
    $order: Order = asc
    $orderBy: LoteColumn = fechaFin
    $page: Int = 1
    $rowsPerPage: Int = 10
    $url: String!
  ) {
    getLotes(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      loteLike: { numeroLote: $numeroLote, estado: $estadoLote }
      loteRange: { fechaCreacion: { min: $fechaDesde, max: $fechaHasta } }
      loteOrder: { column: $orderBy, order: $order }
    ) {
      ...lote
    }
    getPrograma(url: $url) {
      monedaBase
    }
  }
  ${Lotes.fragments.lote}
`;

export const getLotesComplete = gql`
  query getLotesComplete(
    $numeroLote: String
    $fechaDesde: Date
    $fechaHasta: Date
    $estadoLote: EstadoLote
    $order: Order = asc
    $orderBy: LoteColumn!
    $page: Int!
    $rowsPerPage: Int = 10
  ) {
    getLotes(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      loteLike: { numeroLote: $numeroLote, estado: $estadoLote }
      loteRange: { fechaCreacion: { min: $fechaDesde, max: $fechaHasta } }
      loteOrder: { column: $orderBy, order: $order }
    ) {
      ...lotes
    }
  }
  ${Lotes.fragments.lotes}
`;

export const marcarEnviado = gql`
  mutation marcarEnviado($numeroLote: String!, $idProveedor: Int!) {
    marcarEnviado(numeroLote: $numeroLote, idProveedor: $idProveedor) {
      ...sublote
    }
  }
  ${Lotes.fragments.subLoteEnviado}
`;

export const enviarSublote = gql`
  mutation enviarSublote($numeroLote: String!, $idProveedor: Int!) {
    enviarSublote(numeroLote: $numeroLote, idProveedor: $idProveedor) {
      ...sublote
    }
  }
  ${Lotes.fragments.subLoteEnviado}
`;

export default {
  queries: {
    getLote,
    getLotesComplete
  },
  mutations: {
    crearLote,
    marcarEnviado,
    enviarSublote,
    addCanjeALote
  }
};
