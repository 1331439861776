import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import LocationFilter from "js/components/Shared/TablaFiltro/locationFilter.js";
import { TypeAhead, ProgramaField } from "js/components/Shared/Form/Fields";
import SegmentosGraphQL from "js/graphql/resolvers/segmento.resolver";
import RolesGraphQL from "js/graphql/resolvers/rol.resolver";

class advancedFilter extends PureComponent {
  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  render() {
    const { value, submitValue, search, classes } = this.props;

    let hideProgramField = false;
    if (window.location.pathname.indexOf("admin") === -1) {
      hideProgramField = true;
    }

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key="username_OR_nombre"
              name="username_OR_nombre"
              label="Usuario/Nombre/Apellido"
              type="search"
              value={value["username_OR_nombre"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key="email"
              name="email"
              label="Email"
              type="search"
              value={value["email"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              id="estado-simple-select"
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"ACTIVO"}>Activo</MenuItem>
              <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {!hideProgramField && (
          <Grid item xs={12} md={4}>
            <FormControl className={classes.formControl}>
              <ProgramaField
                className={classes}
                value={value.idPrograma}
                onChange={this.handleChange("idPrograma")}
                search={search}
                disabled={hideProgramField}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              disabled={!value["idPrograma"]}
              query={SegmentosGraphQL.queries.getSegmentosInput}
              queryParams={{ idPrograma: value["idPrograma"] }}
              placeholder={"Segmento"}
              label={"Segmento"}
              path={{
                path: ["getSegmentos"],
                value: "idSegmento",
                label: "label",
                defaultLabel: "value"
              }}
              onChange={this.handleChange("idSegmento")}
              value={value["idSegmento"]}
              withTodos
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              disabled={!value["idPrograma"]}
              query={RolesGraphQL.queries.getRoles}
              queryParams={{ idPrograma: value["idPrograma"] }}
              placeholder={"Roles"}
              label={"Roles"}
              path={{ path: ["getRoles"], value: "idRol", label: "nombre" }}
              onChange={this.handleChange("idRol")}
              value={value["idRol"]}
              withTodos
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <LocationFilter value={value} submitValue={submitValue} />
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withRouter(withStyles(styles)(advancedFilter));
