export default theme => ({
  content: {
    flex: 1
  },
  flex: {
    flex: 1
  },
  toolbarContainer: {
    flexGrow: 0,
    maxWidth: "1200px",
    width: "100%",
    margin: "0 auto"
  },
  toolbar: {
    padding: 0,
    justifyContent: "space-between",
    minHeight: 62
  },
  toolbarBurger: {
    // display: "flex",
    justifyContent: "space-between"
    // flexDirection: "row"
  },
  mobileToolbar: {
    display: "flex",
    justifyContent: "space-between"
  },
  icon: {
    /* margin: theme.spacing.unit * 2 */
  },
  iconhover: {
    hover: {
      color: "red"
    }
  },
  modal: {
    backgroundColor: "white"
  },
  logo: {
    margin: "0 15px"
    // maxHeight: Math.min(
    //   100,
    //   Math.max(
    //     get(theme, "programa.template.header.menu.toolbar.height", 0),
    //     64
    //   )
    // ),
    // maxWidth: "50vw"
  },
  logoBurger: {
    maxHeight: 56,
    marginLeft: "-10%",
    marginTop: "5px",
    marginBottom: "5px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between"
  }
});
