import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { numberWithCommas } from "js/utils";

const styles = theme => ({
  list: {
    paddingLeft: theme.spacing.unit
  }
});

class InfoCanjeSinForm extends Component {
  render() {
    const { classes, canje } = this.props;
    return (
      <Grid container item xs={12}>
        <ListItem className={classes.list} style={{ paddingBottom: 0 }}>
          <ListItemText
            primary={
              "Puntos por unidad: " +
              numberWithCommas(canje.puntos / canje.cantidad)
            }
          />
        </ListItem>
        <ListItem className={classes.list} style={{ paddingTop: 0 }}>
          <ListItemText
            primary={"Puntos total: " + numberWithCommas(canje.puntos)}
          />
        </ListItem>
      </Grid>
    );
  }
}

export default withStyles(styles)(InfoCanjeSinForm);
