import React from "react";
import Base from "../Base";
import Slider from "../Slider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Select } from "js/pages/TemplateBuilder2/Controls";
import { _t } from "js/utils/TranslationService";
import withStyles from "./styles";

const Componentes = new Map([["base", Base], ["slider", Slider]]);
class Banner extends React.PureComponent {
  getKey = () => {
    const { position } = this.props;
    const copy = Object.assign([], position);
    const last = copy.pop();
    return copy.join(".") + ".contenidos." + last + ".tipo";
  };

  render() {
    const {
      classes,
      value: { tipo = "base" },
      metadata: { onChange }
    } = this.props;
    const BannerForm = Componentes.get(tipo);
    return (
      <Grid container>
        <Grid item xs={12} className={classes.Segmento}>
          <Grid container className={classes.select}>
            <Grid item>
              <Typography variant="body2" className={classes.SegmentoLabel}>
                {_t("Tipo de contenido")}:
              </Typography>
            </Grid>
            <Grid item>
              <Select
                name="tipo"
                onChange={onChange(this.getKey())}
                options={[
                  { label: "Base", value: "base" },
                  { label: "Slider", value: "slider" }
                ]}
                value={tipo}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BannerForm onChange={onChange} {...{ ...this.props, classes: {} }} />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(Banner);
