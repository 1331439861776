import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";

export default () => ({
  tooltip: "",
  label: "Base",
  onclick: () => console.log("Base cell"),
  icon: <SettingsIcon />,
  disabled: () => false,
  show: () => true
});
