import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import reduxThunk from "redux-thunk";
import reducers from "js/redux";
import AuthService from "js/utils/AuthService";

const middlewares = [reduxThunk /**logger*/];

const crearStore = name => {
  const createStoreWithMiddleware = applyMiddleware(...middlewares)(
    createStore
  );
  const store = createStoreWithMiddleware(
    reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__({ name })
  );
  store.subscribe(() => {
    AuthService.saveState({
      isAllowedToNavigate: store.getState().programa.isAllowedToNavigate
    });
  });
  return store;
};
export default crearStore;
