import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Grid, FormControl } from "@material-ui/core";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { UsuarioField, TypeAhead } from "js/components/Shared/Form/Fields";
import SegmentosGraphQL from "js/graphql/resolvers/segmento.resolver";
import ProductosGraphQL from "js/graphql/resolvers/productoCodificado.resolver";

class advancedFilter extends PureComponent {
  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  updateQueryParamsAsync = ({ key, value }) => state => ({
    [key]: value,
    queryParams: {
      programaLike: { [key]: value }
    }
  });

  updateAutocompleteQueryParamsUsuario = key => select => {
    const value = select && select[0] ? select[0].value : undefined;
    this.setState(
      this.updateQueryParamsAsync({ key, value: value }),
      this.props.submitValue({ target: { name: key, value: value } })
    );
  };

  render() {
    const { value, submitValue, classes } = this.props;
    const isMisVentas = window.location.pathname.indexOf("mis") !== -1;

    return (
      <Grid container spacing={16}>
        {!isMisVentas && (
          <>
            <Grid item xs={12} md={4}>
              <UsuarioField
                label={"Usuario"}
                async
                withTodos={true}
                placeholder={"Buscar por usuario"}
                idPrograma={value["idPrograma"]}
                noOptionsMessage={"No hay usuarios"}
                onChange={this.updateAutocompleteQueryParamsUsuario(
                  "idUsuario"
                )}
                value={value["idUsuario"]}
                disabled={!value["idPrograma"]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl className={classes.formControl}>
                <TypeAhead
                  disabled={!value["idPrograma"]}
                  query={SegmentosGraphQL.queries.getSegmentosInput}
                  queryParams={{ idPrograma: value["idPrograma"] }}
                  placeholder={"Segmento"}
                  label={"Segmento"}
                  path={{
                    path: ["getSegmentos"],
                    value: "idSegmento",
                    label: "label",
                    defaultLabel: "value"
                  }}
                  onChange={this.handleChange("idSegmento")}
                  value={value["idSegmento"]}
                  withTodos
                />
              </FormControl>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              disabled={!value["idPrograma"]}
              query={ProductosGraphQL.queries.getProductosCodificadosInput}
              queryParams={{ idPrograma: value["idPrograma"] }}
              placeholder={"Producto"}
              label={"Producto"}
              path={{
                path: ["getProductosCodificados", "itemsPagina"],
                value: "value",
                label: "label",
                defaultLabel: "value"
              }}
              onChange={this.handleChange("idProducto")}
              value={value["idProducto"]}
              withTodos
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionDesde"}
              name={"fechaCreacionDesde"}
              label="Fecha Creación (Desde)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionDesde",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionDesde"]
                  ? moment(value["fechaCreacionDesde"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionHasta"}
              name={"fechaCreacionHasta"}
              label="Fecha Creación (Hasta)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionHasta",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionHasta"]
                  ? moment(value["fechaCreacionHasta"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
