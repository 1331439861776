import React from "react";
import Typography from "@material-ui/core/Typography";
import FacturaInfo from "./FacturaInfo";
import TablaCanjes from "./TablaCanjes";
import Button from "@material-ui/core/Button";
import { Field } from "redux-form";
import { TextField } from "redux-form-material-ui";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";

const styles = {
  progress: {
    width: "100%",
    height: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

const AdminFacturaDetail = props => {
  const {
    classes,
    factura,
    programa,
    changeEstado,
    estadoFactura,
    changeCanje,
    canjesUpdated,
    changeNotasInternas,
    notasInternas,
    sendValues,
    openNotification,
    loading,
    history
  } = props;
  const nombreProveedor = get(factura, "proveedor.nombreFantasia", null);
  return factura ? (
    <div className={classes.root}>
      <Typography className={classes.factura} variant="h5">
        {`Factura ${factura.numeroFactura}`}{" "}
        {nombreProveedor ? ` | ${nombreProveedor}` : ""}
      </Typography>

      <FacturaInfo
        factura={factura}
        programa={programa}
        changeEstado={changeEstado}
        estadoFactura={estadoFactura}
        openNotification={openNotification}
        canjesUpdated={canjesUpdated}
      />
      <Field
        name="notasInternas"
        label="Notas internas"
        component={TextField}
        fullWidth={true}
        multiline={true}
        input={{
          name: "notasInternas",
          onChange: changeNotasInternas,
          value: notasInternas
        }}
      />
      <TablaCanjes
        canjes={factura.canjes}
        changeCanje={changeCanje}
        canjesUpdated={canjesUpdated}
      />
      <Grid container spacing={16}>
        <Grid item className={classes.actionButtons}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => history.push("/admin/facturas")}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() =>
              sendValues({
                estadoFactura,
                canjesUpdated,
                notasInternas,
                idFactura: factura.idFactura
              })
            }
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </div>
  ) : !loading ? (
    <Typography className={classes.factura} variant="h5">
      No hay detalle para esta factura
    </Typography>
  ) : (
    <div style={styles.progress}>
      <CircularProgress color="inherit" />
    </div>
  );
};

export default AdminFacturaDetail;
