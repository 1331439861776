import gql from "graphql-tag";

export const updateTemplate = gql`
  mutation updateTemplate($template: TemplateUpdate!) {
    updateTemplate(template: $template) {
      template
    }
  }
`;

export default {
  mutations: {
    updateTemplate
  }
};
