import React, { useState, useCallback, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  withWidth,
  CircularProgress,
  Divider
} from "@material-ui/core";
import { _t } from "js/utils/TranslationService";
import { Assignment } from "@material-ui/icons";
import withStyles from "./ConfirmationDialogStyles";

// Componente optimizado con memoización
const ConfirmationDialog = React.memo(
  ({
    open,
    data,
    productos,
    clientes,
    onClose,
    width,
    classes,
    isSubmitting,
    usuario
  }) => {
    // Memoización de funciones de búsqueda
    const getProductoNombre = useCallback(
      codigo => {
        const productoEncontrado = productos.find(p => p.value === codigo);
        return productoEncontrado
          ? productoEncontrado.label
          : _t("Producto no encontrado");
      },
      [productos]
    );

    const getClienteNombre = useCallback(
      idCliente => {
        const clienteEncontrado = clientes.find(c => c.value === idCliente);
        return clienteEncontrado
          ? clienteEncontrado.label
          : _t("Cliente no encontrado");
      },
      [clientes]
    );

    // Datos memoizados para evitar recálculos
    const { clienteNombre, numeroFactura, items } = useMemo(
      () => {
        const idCliente = data && data.idCliente;
        const factura = data && data.numeroFactura;
        const campos = data && data.fields;

        return {
          clienteNombre: idCliente
            ? getClienteNombre(idCliente)
            : "Sin cliente asignado",
          numeroFactura: factura || "N/A",
          items: campos || []
        };
      },
      [data, getClienteNombre]
    );

    return (
      <Dialog
        open={open}
        onClose={() => !isSubmitting && onClose(false)}
        fullWidth
        className={classes.dialog}
        PaperProps={{
          style: {
            margin: width === "xs" || width === "sm" ? "0 8px" : 0
          }
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          {_t("Confirmá la información")}
        </DialogTitle>

        <DialogContent dividers className={classes.dialogContent}>
          <Typography variant="subtitle1" gutterBottom>
            {_t("Resumen de la transacción:")}
          </Typography>

          {usuario && (
            <Typography gutterBottom className={classes.clientName}>
              {`${_t("Usuario")}: `}
              <Typography
                component="span"
                className={classes.productName}
                style={{ display: "inline" }}
              >
                {usuario.perfil &&
                (usuario.perfil.nombre || usuario.perfil.apellido)
                  ? `${usuario.perfil.nombre} ${usuario.perfil.apellido}`
                  : usuario.username}
              </Typography>
            </Typography>
          )}

          <Typography gutterBottom className={classes.clientName}>
            {`${_t("Cliente")}: `}
            <Typography
              component="span"
              className={classes.productName}
              style={{ display: "inline" }}
            >
              {clienteNombre}
            </Typography>
          </Typography>
          <Typography gutterBottom className={classes.clientName}>
            {`${_t("N de Factura")}: `}
            <Typography
              component="span"
              className={classes.productName}
              style={{ display: "inline" }}
            >
              {numeroFactura}
            </Typography>
          </Typography>

          <List dense className={classes.list}>
            {items.map((item, index) => (
              <>
                <ListItem
                  key={`${index}-${item.codigoProducto}`}
                  className={classes.listItem}
                >
                  <div className={classes.dot} />
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        className={classes.productName}
                        style={{ fontSize: "0.93rem" }}
                      >
                        {getProductoNombre(item.codigoProducto)}
                      </Typography>
                    }
                    secondary={
                      <div className={classes.secondaryContent}>
                        <Typography
                          component="span"
                          className={classes.quantityText}
                        >
                          {_t("Cantidad")}: {item.cantidad || 0}
                        </Typography>

                        {width !== "xs" &&
                          width !== "sm" && (
                            <span className={classes.separator} />
                          )}

                        <Typography
                          component="span"
                          className={classes.observationText}
                        >
                          <Assignment
                            fontSize="small"
                            style={{ marginRight: 4 }}
                          />
                          {item.observacion || _t("Sin observaciones")}
                        </Typography>
                      </div>
                    }
                  />
                </ListItem>
                {index !== items.length - 1 && (
                  <Divider className={classes.divider} />
                )}
              </>
            ))}
          </List>
        </DialogContent>

        <DialogActions
          style={{
            padding: "16px",
            flexDirection: "row",
            gap: 8
          }}
        >
          <Button
            fullWidth
            onClick={() => onClose(false)}
            color="primary"
            variant="outlined"
            disabled={isSubmitting}
          >
            {_t("Cancelar")}
          </Button>
          <Button
            fullWidth
            onClick={() => onClose(true)}
            color="primary"
            variant="contained"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : _t("Confirmar")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default withWidth()(withStyles(ConfirmationDialog));
