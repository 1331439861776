import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import Catalogo from "./Catalogo.container";
import styles from "./styles";
import withPrograma from "js/hoc/withPrograma";

const enhance = compose(
  withPrograma,
  withStyles(styles)
);

export default enhance(Catalogo);
