import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { _t } from "js/utils/TranslationService";
import { withStyles } from "@material-ui/core/styles";
import { withApollo } from "react-apollo";
import styles from "./styles";
import LocationFilter from "js/components/Shared/TablaFiltro/locationFilter.js";
import AddressMap from "js/components/Mapa/AddressMap";
import AppQraphQL from "js/graphql/resolvers/locacion.resolver";
import get from "lodash/get";

class DireccionEntregaForm extends React.Component {
  state = { location: {}, address: "", timer: null, zona: "" };

  handleChange = name => event =>
    this.props.onChange(
      JSON.stringify({
        ...this.props.value,
        [name]: event.target.value
      })
    );

  handleLocationChange = (e, selectedZona) => {
    this.props.onChange(
      JSON.stringify({
        ...this.props.value,
        [e.name]: e.value
      })
    );
    this.setState({ zona: selectedZona ? selectedZona.descripcion : "" });
    this.keyUpAddress();
  };
  keyUpAddress = e => {
    const {
      value: { direccion: address }
    } = this.props;
    clearTimeout(this.state.timer);
    this.setState({
      timer: setTimeout(
        () => this.setState({ address: `${address}+${this.state.zona}` }),
        3000
      )
    });
  };

  async componentDidMount() {
    const {
      idPais,
      idZona,
      idLocalidad,
      direccion: address
    } = this.props.value;
    this.setState({ location: { idPais, idZona, idLocalidad }, address });
    if (!idLocalidad) return;
    const { client } = this.props;
    const { data } = await client.query({
      query: AppQraphQL.queries.getMasterLocalidades,
      variables: { idZona, idLocalidad },
      fetchPolicy: "cache-first",
      errorPolicy: "all"
    });
    const zona = get(data, "getMasterLocalidades[0].descripcion", "");
    await this.setState({ zona });
    this.keyUpAddress();
  }

  componentWillReceiveProps(nextProps) {
    const { idPais, idZona, idLocalidad } = nextProps.value;
    this.setState({ location: { idPais, idZona, idLocalidad } });
  }

  render() {
    const requeridosValue = this.props.value;
    const { classes } = this.props;
    const { address } = this.state;
    return (
      <div>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <LocationFilter
              fromPrograma
              idPrograma={this.props.idPrograma}
              value={this.state.location}
              submitValue={(e, selectedZona) =>
                this.handleLocationChange(e.target, selectedZona)
              }
              withTodos={false}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-name"
              label="Dirección"
              value={requeridosValue.direccion}
              onChange={this.handleChange("direccion")}
              margin="normal"
              className={classes.fullWidth}
              helperText={_t("Verifique la dirección ingresada en el mapa")}
              onKeyUp={this.keyUpAddress}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <AddressMap address={address} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-name"
              label={_t("Código postal")}
              value={requeridosValue.cp}
              onChange={this.handleChange("cp")}
              margin="normal"
              className={classes.fullWidth}
              required
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(withApollo(DireccionEntregaForm));
