import gql from "graphql-tag";

export const CLN = {};

CLN.fragments = {
  reporteCLN: gql`
    fragment reporteCLN on ReporteCLN {
      comercio {
        username
        perfil {
          otros
        }
      }
      transaccionesGlobales {
        anoMes
        transacciones
        sociosUnicos
        objetivo: objetivoTransaccionesMesSiguiente
        comercio {
          username
        }
      }
      ticketPromedio {
        anoMes
        ticketPromedio
      }
      transaccionesPorSucursal {
        sucursal {
          idSucursal
          nombre
          comercio {
            username
          }
        }
        anoMes
        transacciones
      }
      genero {
        hombres
        mujeres
      }
      rangoEtario {
        transaccionesMenor18
        transacciones18a25
        transacciones25a35
        transacciones35a45
        transaccionesMayor45
        transacciones13a17
        transacciones18a20
        transacciones21a30
        transacciones31a40
        transacciones41a50
        transacciones51a60
        transacciones61oMas
      }
    }
  `
};

export default CLN;
