import { compose, pure } from "recompose";
import { withRouter } from "react-router-dom";
import Privado from "./Privado";
import { graphql } from "react-apollo";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";

const enhance = compose(
  pure,
  withRouter,
  graphql(AppQraphQL.queries.isLoggedIn, {
    options: () => ({
      fetchPolicy: "cache-first"
    }),
    props: ({ data }) => {
      const { loading, isLoggedIn } = data;
      if (loading) return;
      return {
        isLoggedIn
      };
    }
  })
);
export default enhance(Privado);
