import gql from "graphql-tag";

export const getProductosCodificados = gql`
  query getProductosCodificados(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: ProductoCodificadoColumn!
    $idPrograma: Int
    $nombre: String
    $codigo: String
    $estado: Estado
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getProductosCodificados(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      productoOrder: { column: $orderBy, order: $order }
      productoLike: {
        idPrograma: $idPrograma
        codigo: $codigo
        nombre: $nombre
        estado: $estado
      }
      productoRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        codigo
        nombre
        descripcion
        estado
        fechaCreacion
      }
    }
  }
`;

export const getProductoCodificado = gql`
  query getProductosCodificados(
    $productoLike: ProductoCodificadoSearch
    $pagina: Pagina
  ) {
    getProductosCodificados(productoLike: $productoLike, pagina: $pagina) {
      itemsPagina {
        _id
        idPrograma
        codigo
        nombre
        descripcion
        estado
        puntosPorSegmentos {
          _id
          idSegmento
          puntos
        }
      }
    }
  }
`;

export const getProductosCodificadosInput = gql`
  query getProductosCodificados($idPrograma: Int) {
    getProductosCodificados(productoLike: { idPrograma: $idPrograma }) {
      itemsPagina {
        value: _id
        label: nombre
      }
    }
  }
`;

export const createProductoCodificado = gql`
  mutation createProductoCodificado($producto: ProductoCodificadoCreate!) {
    createProductoCodificado(producto: $producto) {
      _id
    }
  }
`;

export const updateProductoCodificado = gql`
  mutation updateProductoCodificado($producto: ProductoCodificadoUpdate!) {
    updateProductoCodificado(producto: $producto) {
      _id
    }
  }
`;

export default {
  queries: {
    getProductosCodificados,
    getProductoCodificado,
    getProductosCodificadosInput
  },
  mutations: {
    createProductoCodificado,
    updateProductoCodificado
  }
};
