import gql from "graphql-tag";

export const Lotes = {};

Lotes.fragments = {
  subLotes: gql`
    fragment lotes on PaginaLote {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        numeroLote
        estado
      }
    }
  `,
  subLoteEnviado: gql`
    fragment sublote on Sublote {
      numeroLote
      proveedor {
        idProveedor
        nombreFantasia
      }
      estado
    }
  `,
  lotes: gql`
    fragment lotes on PaginaLote {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        numeroLote
        fechaCreacion
        cantidadCanjes
        cantidadProductos
        sublotes {
          estado
        }
        usuario {
          username
        }
        estado
        fechaFin
      }
    }
  `,
  lote: gql`
    fragment lote on PaginaLote {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        numeroLote
        fechaCreacion
        fechaFin
        costoTotal
        importeTotal
        cantidadCanjes
        cantidadProductos
        cantidadProveedores
        sublotes {
          estado
          numeroLote
          proveedor {
            idProveedor
            formatoEnvio
            nombreFantasia
            direccion
            emailContacto
            nombreContacto
            telefonoContacto
            emailCanjes
            pais {
              descripcion
              moneda
            }
          }
          cantidadProductos
          cantidadCanjes
          costoTotal
          importeTotal
          canjes {
            idCanje
            cantidad
            puntos
            fecha
            fechaEntrega
            estado
            numeroLote
            idPais
            costoReal
          }
        }
        usuario {
          username
        }
        estado
      }
    }
  `
};

export default Lotes;
