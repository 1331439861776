import { compose, pure, withStateHandlers, withProps } from "recompose";
import moment from "moment";
import withStyles from "./styles";
import contenidos from "../contenidosList";

const enhance = compose(
  pure,
  withStyles,
  withProps(props => {
    const { ...rest } = props;
    const match = props.computedMatch ? props.computedMatch : props.match;
    return {
      tipoContenido: match.params.tipoContenido,
      ...rest
    };
  }),
  withProps(({ tipoContenido }) => ({
    contenido: contenidos.find(({ tipo }) => tipo === tipoContenido)
  })),
  withStateHandlers(() => {
    return {
      idPrograma: null,
      idSegmento: null,
      idUsuario: null,
      modulo: null,
      timestamp: moment().toISOString()
    };
  })
);

export default enhance;
