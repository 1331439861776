import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import Tabla from "./TablaMovimientos";
import Enhancer from "./container";
import { _t } from "js/utils/TranslationService";
import { Box } from "../Profile/index";

const Movimientos = ({ classes, puntos, boxes }) => (
  <Grid container spacing={24} className={classes.container}>
    <Grid item container xs={12} alignItems="flex-end">
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom className={classes.title}>
          {_t("Mis Movimientos")}
        </Typography>
      </Grid>
      <Grid item container xs={12} md={6} spacing={16} justify="flex-end">
        {puntos.map(
          ({ label, puntos, hidden, fecha }, index) =>
            !hidden && (
              <Box
                key={`${label}__${index}`}
                label={label}
                puntos={puntos}
                classes={classes}
                fecha={fecha}
              />
            )
        )}
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Tabla />
    </Grid>
  </Grid>
);

Movimientos.defaultProps = {
  puntos: []
};

Movimientos.propTypes = {
  classes: PropTypes.object,
  puntos: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      puntos: PropTypes.number
    })
  )
};

export default Enhancer(Movimientos);
