import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { UsuarioField } from "js/components/Shared/Form/Fields";

class advancedFilter extends PureComponent {
  updateQueryParamsAsync = ({ key, value }) => state => ({
    [key]: value,
    queryParams: {
      programaLike: { [key]: value }
    }
  });

  updateAutocompleteQueryParamsUsuario = key => select => {
    const value = select && select[0] ? select[0].value : undefined;
    this.setState(
      this.updateQueryParamsAsync({ key, value: value }),
      this.props.submitValue({ target: { name: key, value: value } })
    );
  };

  render() {
    const { value } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={3}>
          <UsuarioField
            label={"Usuario"}
            async
            withTodos={true}
            placeholder={"Buscar por usuario"}
            idPrograma={value["idPrograma"]}
            noOptionsMessage={"No hay usuarios"}
            onChange={this.updateAutocompleteQueryParamsUsuario("idUsuario")}
            value={value["idUsuario"]}
            disabled={!value["idPrograma"]}
          />
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
