import { compose } from "recompose";
import LayoutUI from "./LayoutUI.container";
import withPrograma from "js/hoc/withPrograma";
import WithTracker from "js/hoc/WithTracker";
import withKibanaTracker from "js/hoc/withKibanaTracker";
import { withRouter } from "react-router-dom";

const enhance = compose(
  withPrograma,
  withRouter,
  WithTracker,
  withKibanaTracker
);

export default enhance(LayoutUI);
