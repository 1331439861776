import React, { PureComponent } from "react";

import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/apiToken.resolver";
import advancedFilter from "js/pages/Admin/Programa/ApiToken/ProgramasTerceros/Tabla/advancedFilters.js";
import { USER_ROLES as Roles } from "js/models/User";
import { editAction } from "js/components/Shared/Tabla/ActionCells";

const defaultHeader = {
  title: "Programas Terceros",
  new: {
    title: "Nuevo",
    perms: [Roles.API_TOKEN_W]
  }
};

const columnData = [
  { id: "nombre", label: "Nombre" },
  { id: "estado", label: "Estado" },
  { id: "fechaCreacion", label: "Fecha creación", type: "date" },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  render() {
    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.API_TOKEN_W]
      })
    });

    return (
      <Table
        storage={"FiltroApiTokenProgramasTerceros"}
        query={AppQraphQL.queries.getApiTokensProgramasTerceros}
        defaultHeader={defaultHeader}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getApiTokensProgramasTerceros}
        actionsCustom={[edit]}
      />
    );
  }
}

export default Tabla;
