import { compose, pure, withHandlers, withState, withProps } from "recompose";
import { set } from "lodash";
import {
  CHANGE_DESTACADOS,
  CHANGE_DESTACADOS_BENEFICIOS
} from "js/redux/template/constants.js";
import { connect } from "react-redux";
import { updateTemplateBuilder } from "js/redux/template";
import { getTemplateBuilderSection } from "js/redux/template/selectors.js";
import get from "lodash/get";
import newItems from "js/pages/TemplateBuilder2/Components/NewItems";
const { newBase, newSlider } = newItems;

const mapStateToProps = state => ({
  destacados: getTemplateBuilderSection(state, "destacados"),
  destacadosBeneficios: getTemplateBuilderSection(state, "destacadosBeneficios")
});

const mapDispatchToProps = dispatch => ({
  changeDestacados: updateTemplateBuilder({
    dispatch,
    type: CHANGE_DESTACADOS
  }),
  changeDestacadosBeneficios: updateTemplateBuilder({
    dispatch,
    type: CHANGE_DESTACADOS_BENEFICIOS
  })
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  pure,
  withState("stateUpdaterValue", "updateState", 0),
  withHandlers({
    onChangeDestacados: ({
      destacados,
      changeDestacados,
      updateState
    }) => key => value => {
      let payload = {};
      if (key.split(".").pop() === "tipo") {
        let arr = key.replace("0.", "").split(".");
        arr.pop();
        const contenido = get(destacados, arr, {});
        if (value === "slider" && !get(contenido, "bases", []).length)
          payload = set(destacados, arr, { ...newSlider, ...contenido });
        else if (value === "base")
          payload = set(destacados, arr, { ...newBase, ...contenido });
      }
      payload = set(
        destacados,
        key.replace("0.", ""),
        get(value, "target.value", value)
      );
      changeDestacados(payload);
      updateState();
    },
    onChangeDestacadosBeneficios: ({
      destacadosBeneficios,
      changeDestacadosBeneficios,
      updateState
    }) => key => value => {
      let payload = {};
      if (key.split(".").pop() === "tipo") {
        let arr = key.replace("0.", "").split(".");
        arr.pop();
        const contenido = get(destacadosBeneficios, arr, {});
        if (value === "slider" && !get(contenido, "bases", []).length)
          payload = set(destacadosBeneficios, arr, {
            ...newSlider,
            ...contenido
          });
        else if (value === "base")
          payload = set(destacadosBeneficios, arr, {
            ...newBase,
            ...contenido
          });
      }
      payload = set(
        destacadosBeneficios,
        key.replace("0.", ""),
        get(value, "target.value", value)
      );
      changeDestacadosBeneficios(payload);
      updateState();
    },
    onChangeFilaTipo: ({
      destacados,
      changeDestacados,
      updateState
    }) => () => value => {
      let contenidos = get(destacados, "contenidos", []);
      if (parseInt(value) - 1 < contenidos.length)
        contenidos.splice(parseInt(value));
      else while (contenidos.length < parseInt(value)) contenidos.push(newBase);
      let payload = set(
        destacados,
        "filaTipo",
        get(value, "target.value", value)
      );
      payload = set(destacados, "contenidos", contenidos);
      changeDestacados(payload);
      updateState();
    },
    onChangeFilaTipoBeneficios: ({
      destacadosBeneficios,
      changeDestacadosBeneficios,
      updateState
    }) => () => value => {
      let contenidos = get(destacadosBeneficios, "contenidos", []);
      if (parseInt(value) - 1 < contenidos.length)
        contenidos.splice(parseInt(value));
      else while (contenidos.length < parseInt(value)) contenidos.push(newBase);
      let payload = set(
        destacadosBeneficios,
        "filaTipo",
        get(value, "target.value", value)
      );
      payload = set(destacadosBeneficios, "contenidos", contenidos);
      changeDestacadosBeneficios(payload);
      updateState();
    }
  }),
  withProps(props => ({
    filas: [props.destacados],
    filasBeneficios: [props.destacadosBeneficios]
  }))
);

export default enhance;
