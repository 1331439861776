import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { TypeAhead, ProgramaField } from "js/components/Shared/Form/Fields";
import Grid from "@material-ui/core/Grid";
import SegmentosGraphQL from "js/graphql/resolvers/segmento.resolver";

class advancedFilter extends PureComponent {
  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  render() {
    const { value, submitValue, search, classes } = this.props;

    let hideProgramField = false;
    if (window.location.pathname.indexOf("admin") === -1) {
      hideProgramField = true;
    }

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TextField
              key="nombre"
              name="nombre"
              label="Nombre"
              type="search"
              value={value["nombre"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              suggestions={{
                tipos: [{ value: "ENCUESTA", label: "Encuesta" }]
              }}
              placeholder={"Tipo"}
              label={"Tipo"}
              path={{ path: "tipos", value: "value", label: "label" }}
              onChange={this.handleChange("tipo")}
              value={value["tipo"]}
              withTodos
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              suggestions={{
                estados: [
                  { value: "BORRADOR", label: "Borrador" },
                  { value: "LISTA", label: "Lista" },
                  { value: "ACTIVA", label: "Activa" },
                  { value: "CANCELADA", label: "Cancelada" },
                  { value: "FINALIZADA", label: "Finalizada" }
                ]
              }}
              placeholder={"Estado"}
              label={"Estado"}
              path={{ path: "estados", value: "value", label: "label" }}
              onChange={this.handleChange("estado")}
              value={value["estado"]}
              withTodos
            />
          </FormControl>
        </Grid>

        <Grid item container spacing={16}>
          {!hideProgramField && (
            <Grid item xs={12} md={3}>
              <ProgramaField
                className={classes}
                value={value.idPrograma}
                onChange={this.handleChange("idPrograma")}
                search={search}
                withTodos
              />
            </Grid>
          )}

          <Grid item xs={12} md={3}>
            <FormControl className={classes.formControl}>
              <TypeAhead
                disabled={!value["idPrograma"]}
                query={SegmentosGraphQL.queries.getSegmentosInput}
                queryParams={{ idPrograma: value["idPrograma"] }}
                placeholder={"Segmento"}
                label={"Segmento"}
                path={{
                  path: ["getSegmentos"],
                  value: "idSegmento",
                  label: "label",
                  defaultLabel: "value"
                }}
                onChange={this.handleChange("idSegmento")}
                value={value["idSegmento"]}
                withTodos
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withRouter(withStyles(styles)(advancedFilter));
