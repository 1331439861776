import { _t } from "js/utils/TranslationService";
import { Typography } from "@material-ui/core";
import React from "react";

export const ItemLine = ({ fixed, value, variant }) => {
  const textSize = variant ? variant : "subtitle1";

  return (
    <>
      <Typography
        gutterBottom
        variant={textSize}
        color="primary"
        style={{ display: "block", fontWeight: "bold" }}
      >
        {`${fixed}: `}
        <Typography
          component="span"
          variant={textSize}
          style={{ display: "inline", fontWeight: "bold", fontSize: "0.9rem" }}
        >
          {value.toUpperCase()}
        </Typography>
      </Typography>
    </>
  );
};
