import { compose, pure, withHandlers, withProps, withState } from "recompose";
import withStyles from "./styles";
import { set, pick, get } from "lodash";
import {
  CHANGE_CATALOGO,
  CHANGE_CATALOGO_BENEFICIOS,
  CHANGE_HEADER,
  CHANGE_FOOTER,
  CHANGE_ACCESS,
  CHANGE_TEMPLATE_DATA
} from "js/redux/template/constants.js";
import { connect } from "react-redux";
import { updateTemplateBuilder } from "js/redux/template";
import { getTemplateBuilderSection } from "js/redux/template/selectors.js";
import newItems from "js/pages/TemplateBuilder2/Components/NewItems";
const { newBase } = newItems;

const mapStateToProps = state => ({
  idSegmento: getTemplateBuilderSection(state, "template.idSegmento"),
  templateBuilder: getTemplateBuilderSection(state, "template.templateBuilder"),
  catalogo: getTemplateBuilderSection(state, "catalogo"),
  catalogoBeneficios: getTemplateBuilderSection(state, "catalogoBeneficios"),
  templateData: getTemplateBuilderSection(state, "templateData"),
  header: getTemplateBuilderSection(state, "header"),
  access: getTemplateBuilderSection(state, "access"),
  footer: getTemplateBuilderSection(state, "footer")
});

const mapDispatchToProps = dispatch => ({
  changeCatalogo: updateTemplateBuilder({
    dispatch,
    type: CHANGE_CATALOGO
  }),
  changeCatalogoBeneficios: updateTemplateBuilder({
    dispatch,
    type: CHANGE_CATALOGO_BENEFICIOS
  }),
  changeTemplateData: updateTemplateBuilder({
    dispatch,
    type: CHANGE_TEMPLATE_DATA
  }),
  changeHeader: updateTemplateBuilder({
    dispatch,
    type: CHANGE_HEADER
  }),
  changeAccess: updateTemplateBuilder({
    dispatch,
    type: CHANGE_ACCESS
  }),
  changeFooter: updateTemplateBuilder({
    dispatch,
    type: CHANGE_FOOTER
  })
});

const enhance = compose(
  withStyles,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  pure,
  withState("stateUpdaterValue", "updateState", 0),
  withHandlers({
    onChangeCatalogo: ({
      catalogo,
      changeCatalogo,
      updateState
    }) => key => value => {
      const payload = set(catalogo, key, get(value, "target.value", value));
      changeCatalogo(payload);
      updateState();
    },
    onChangeCatalogoBeneficios: ({
      catalogoBeneficios,
      changeCatalogoBeneficios,
      updateState
    }) => key => value => {
      const payload = set(
        catalogoBeneficios,
        key,
        get(value, "target.value", value)
      );
      changeCatalogoBeneficios(payload);
      updateState();
    },
    onChangeHeader: ({ header, changeHeader, updateState }) => key => value => {
      const payload = set(header, key, get(value, "target.value", value));
      changeHeader(payload);
      updateState();
    },
    onChangeAccess: ({ access, changeAccess, updateState }) => key => value => {
      const payload = set(access, key, get(value, "target.value", value));
      changeAccess(payload);
      updateState();
    },
    onChangeSignin: ({ access, changeAccess, updateState }) => key => value => {
      const payload = set(
        access,
        "signin." + key,
        get(value, "target.value", value)
      );
      changeAccess(payload);
      updateState();
    },
    onChangeFooter: ({ footer, changeFooter, updateState }) => key => value => {
      const payload = set(footer, key, get(value, "target.value", value));
      changeFooter(payload);
      updateState();
    },
    onChangeTemplateData: ({
      templateData,
      changeTemplateData,
      updateState
    }) => key => value => {
      const payload = set(templateData, key, get(value, "target.value", value));
      changeTemplateData(payload);
      updateState();
    },
    onChangeFilaTipo: params => (position, key) => value => {
      const { access, changeAccess, updateState } = params;
      // debugger;
      let contenidos = get(
        access,
        `signin.${position.join(".")}.contenidos`,
        []
      );
      if (parseInt(value) - 1 < contenidos.length)
        contenidos.splice(parseInt(value));
      else
        while (contenidos.length < parseInt(value))
          contenidos.push({ ...newBase });
      let payload = set(
        access,
        "signin." + key, //eslint-disable-line
        get(value, "target.value", value)
      );
      payload = set(
        access,
        `signin.${position.join(".")}.contenidos`,
        contenidos
      );
      changeAccess(payload);
      updateState();
    },
    onChangeSiginAdicionales: params => key => value => {
      const { access, changeAccess, updateState } = params;
      const payload = set(
        access,
        "signin.adicionales." + key,
        get(value, "target.value", value)
      );
      changeAccess(payload);
      updateState();
    }
  }),
  withProps(props => ({
    accessTabsConfig: Object.entries(
      pick(props.access, ["restorePassword", "restoreUser", "signin", "signup"])
    ),
    contenidoArribaBox: get(props.access, "signin.arriba", {
      contenidos: []
    }),
    contenidoAbajoBox: get(props.access, "signin.abajo", {
      contenidos: []
    }),
    siginAdicionales: get(props.access, "signin.adicionales", {}),
    menuNavbarItems: get(props.header, "menu.navbar.items", []),
    footerContenidos: get(props.footer, "columnasFooter", [])
  }))
);

export default enhance;
