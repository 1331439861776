import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import AppQraphQL from "js/graphql/resolvers/rubro.resolver";

const filter = ({ estado }) => estado === "ACTIVO";

class RubroFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rubros: [],
      subrubros: []
    };
  }

  async componentDidMount() {
    const { client, fromPrograma, idPrograma } = this.props;
    await client
      .query({
        query: fromPrograma
          ? AppQraphQL.queries.getRubrosByPrograma
          : AppQraphQL.queries.getMasterRubros,
        fetchPolicy: "cache-first",
        errorPolicy: "all",
        variables: {
          idPrograma: fromPrograma
            ? parseInt(idPrograma) || idPrograma
            : undefined
        }
      })
      .then(res => {
        res.data[fromPrograma ? "getRubros" : "getMasterRubros"].length > 0 &&
          this.setState({
            rubros: res.data[
              fromPrograma ? "getRubros" : "getMasterRubros"
            ].filter(filter)
          });
      });
  }

  componentWillReceiveProps(props) {
    const {
      value: { idRubro }
    } = props;
    idRubro && this.fetchSubRubros(idRubro);
  }

  async fetchSubRubros(idRubro) {
    const { client } = this.props;
    await client
      .query({
        query: AppQraphQL.queries.getSubRubros,
        variables: { idRubro: parseInt(idRubro, 10) },
        fetchPolicy: "cache-first",
        errorPolicy: "all"
      })
      .then(res => {
        res.data.getSubrubros.length > 0 &&
          this.setState({
            subrubros: res.data.getSubrubros.filter(filter)
          });
      });
  }

  renderRubro() {
    const { classes, value, submitValue, withTodos } = this.props;
    const { rubros } = this.state;

    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="rubro-simple" id="rubro-simple">
          Categoría
        </InputLabel>
        <Select
          value={value["idRubro"] || ""}
          onChange={e => submitValue(e)}
          inputProps={{
            name: "idRubro",
            id: "idRubro"
          }}
        >
          {withTodos && <MenuItem value={undefined}>Todos</MenuItem>}
          {rubros.map((item, index) => (
            <MenuItem value={parseInt(item.idRubro, 10)} key={index}>
              {item.descripcion}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  renderSubRubro() {
    const { classes, value, submitValue, withTodos } = this.props;
    const { subrubros } = this.state;
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="subRubro-simple" id="subRubro-simple">
          Subcategoría
        </InputLabel>
        <Select
          value={value["idSubrubro"] || ""}
          onChange={e => submitValue(e)}
          inputProps={{
            name: "idSubrubro",
            id: "idSubrubro"
          }}
        >
          {withTodos && <MenuItem value={undefined}>Todos</MenuItem>}
          {subrubros.map((item, index) => (
            <MenuItem value={parseInt(item.idSubrubro, 10)} key={index}>
              {item.descripcion}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  render() {
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={6}>
          {this.renderRubro()}
        </Grid>
        <Grid item xs={12} md={6}>
          {this.renderSubRubro()}
        </Grid>
      </Grid>
    );
  }
}

RubroFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

RubroFilter.defaultProps = {
  withTodos: true
};

export default withApollo(withRouter(RubroFilter));
