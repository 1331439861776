import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  iframeWrapper: {
    marginTop: 20
  },
  buttonVolver: {
    textAlign: "left",
    marginTop: "17px",
    float: "right"
  },
  button: {
    textAlign: "right",
    marginTop: "17px",
    marginLeft: "20px",
    float: "right"
  }
});

export default withStyles(styles);
