import React from "react";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import base from "js/components/Shared/Tabla/ActionCells/Base";
import AuthService from "js/utils/AuthService";

export default ({ perms = [], optional = [], history, itemId }) => ({
  ...base(),
  label: "Targets",
  icon: <TrackChangesIcon />,
  tooltip: "Gestionar targets",
  show: () =>
    (!perms.length || AuthService.hasPerms(perms)) &&
    (!optional.lenght || AuthService.hasAnyPerm(optional)),
  onclick: () =>
    history.push(window.location.pathname + "/" + itemId + "/targets")
});
