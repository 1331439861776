import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
// import { withRouter } from "react-router-dom";
// import { withApollo } from "react-apollo";
import MultipleSelect from "./MultipleSelect";
import styles from "./styles";

const enhance = compose(
  //   withApollo,
  //   withRouter,
  withStyles(styles)
);

export default enhance(MultipleSelect);
