import React, { PureComponent } from "react";
import { numberWithCommas } from "js/utils";
import { withStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import { styles } from "js/pages/Admin/Programa/Catalogo/Edit/styles.js";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class Details extends PureComponent {
  render() {
    const { values } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">
            Proveedor:{" "}
            {get(values, "producto.proveedor.razonSocial", "Nombre proveedor")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">
            Stock maestro:{" "}
            {get(values, "producto.stockInfinito") ? (
              <span style={{ fontSize: "1.75rem" }}>∞</span>
            ) : (
              numberWithCommas(get(values, "producto.stock"))
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">
            Tasa conversión:{" "}
            {numberWithCommas(get(values, "programa.tasaConversion", 0))}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">
            Costo: {get(values, "producto.proveedor.pais.moneda", null)}
            &nbsp;
            {numberWithCommas(get(values, "producto.costo", 0))}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">
            Precio sugerido:{" "}
            {get(values, "producto.proveedor.pais.moneda", null)}
            &nbsp;
            {numberWithCommas(get(values, "producto.precioSugerido", 0))}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Details);
