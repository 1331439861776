import React from "react";
import Icon from "@material-ui/icons/PlayArrow";
import client from "js/App/client.graphql.js";
import AppQraphQL from "js/graphql/resolvers/acciones.resolver";
import base from "./Base";
import AuthService from "js/utils/AuthService";

export default params => ({
  ...base(),
  label: "Activar",
  icon: <Icon />,
  show: () =>
    params.item.estado === "LISTA" && AuthService.hasPerms(params.perms),
  tooltip: "Activar acción",
  onclick: () =>
    client
      .mutate({
        mutation: AppQraphQL.mutations.activarAccion,
        variables: { idAccion: params.itemId },
        errorPolicy: "all"
      })
      .then(res => {
        res.errors && res.errors.length > 0
          ? params.openNotification(res.errors[0].message)
          : params.openNotification("La acción ha sido activada correctamente");
      })
      .catch(() => {
        params.openNotification("Error al intentar activar la acción");
      })
});
