import React from "react";
import { has, get, pick } from "lodash";
import { numberWithCommas } from "js/utils";
import "./styles.css";
import { _t } from "js/utils/TranslationService";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

export const emptyAutocomplete = { label: "", value: undefined };

export const initialState = {
  idUsuario: undefined,
  idSegmento: undefined,
  idPrograma: undefined,
  selectedProgram: undefined,
  withProgram: false,
  cuentaCorrienteLike: {
    idCuentaCorriente: 1
  },
  disablePrograma: false,
  disableUsuario: false
};
export const getProgramasSuggestions = data =>
  has(data, "getProgramas.itemsPagina")
    ? data.getProgramas.itemsPagina.map(programa => ({
        value: programa.idPrograma,
        label: programa.nombre
      }))
    : [];

export const getUsuariosSuggestions = data =>
  has(data, "getPrograma.itemsPagina[0].usuarios")
    ? data.getPrograma.itemsPagina[0].usuarios
        .filter(usuario => usuario.estado === "ACTIVO")
        .map(usuario => ({
          value: usuario.idUsuario,
          label: usuario.username
        }))
    : [];

export const getSegmentosSuggestions = data =>
  has(data, "getPrograma.itemsPagina[0].segmentos")
    ? data.getPrograma.itemsPagina[0].segmentos.map(segmento => ({
        value: segmento.idSegmento,
        label: segmento.nombre
      }))
    : [];

export const updateQueryParamsAsync = ({ key, value }) => state => ({
  [key]: value,
  queryParams: {
    programaLike: { [key]: value }
  }
});

export const formatValuesWithNegative = (value, tipoOperacion = null) =>
  value > -1 && tipoOperacion !== "DEBITO" ? (
    numberWithCommas(value)
  ) : (
    <span className="negative">({numberWithCommas(Math.abs(value))})</span>
  );

const Utils = {
  getProgramasSuggestions,
  getUsuariosSuggestions,
  getSegmentosSuggestions
};

export const getAutocompleteValue = (fn, data, stateValue) =>
  stateValue !== undefined
    ? Utils[fn](data).find(p => p.value === stateValue)
    : { value: undefined, label: "" };

export const maxValue = (max, tipo) => value =>
  tipo === "CREDITO" && value > max
    ? `Este valor no puede superar ${max}`
    : undefined;

export const maxSaldoPrograma = (max, tipo) => value =>
  tipo === "CREDITO" && value > max
    ? _t(
        "La cantidad de puntos a acreditar no puede ser mayor al saldo de la cuenta corriente del programa"
      )
    : undefined;

export const getBalance = ({ data, defaultValue = 0, withCommas = true }) => {
  const balance = get(
    data,
    "getPrograma.itemsPagina[0].cuentaCorriente.balance",
    defaultValue
  );
  return withCommas ? numberWithCommas(balance) : balance;
};

export const maxMovimientoPuntosPorUsuario = (
  maximoMovimientoPuntos = 0,
  tipo = "CREDITO"
) => value => {
  if (AuthService.hasPerms([Roles.EXCEDER_MAXIMO])) return undefined;
  return value && tipo === "CREDITO" && value > maximoMovimientoPuntos
    ? _t(
        "La cantidad de puntos a acreditar no puede superar el máximo configurado para este programa"
      )
    : undefined;
};

export const getValuesFromProps = props =>
  pick(props, [
    "selectedProgram",
    "selectedUsuario",
    "concepto",
    "tipo",
    "puntos"
  ]);

export default Utils;
