import gql from "graphql-tag";

export const getJerarquiaUsuario = gql`
  query getJerarquiaUsuario($idUsuario: ID) {
    getJerarquiaUsuario(idUsuario: $idUsuario) {
      idUsuario
      idJefeNivel1
      idJefeNivel2
      idJefeNivel3
    }
  }
`;

export const getUsuariosDistribuidora = gql`
  query getUsuariosDistribuidora($idUsuario: ID) {
    getUsuariosDistribuidora(idUsuario: $idUsuario) {
      idUsuario
    }
  }
`;

export const getMiInformePerformance = gql`
  query getMiInformePerformance(
    $idPrograma: Int
    $idCampana: ID
    $idUsuario: Int
    $estado: EstadoCampanaOP
    $fechaInicioDesde: Date
    $fechaInicioHasta: Date
    $fechaFinDesde: Date
    $fechaFinHasta: Date
    $page: Int!
    $rowsPerPage: Int!
  ) {
    getMiInformePerformance(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      performanceLike: {
        idPrograma: $idPrograma
        idCampana: $idCampana
        idUsuario: $idUsuario
        estado: $estado
      }
      performanceRange: {
        fechaInicio: { min: $fechaInicioDesde, max: $fechaInicioHasta }
        fechaFin: { min: $fechaFinDesde, max: $fechaFinHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        fecha
        campana {
          _id
          nombre
          descripcion
          imagenes
          fechaInicio
          fechaFin
          estado
          puntosTodos
        }
        individual {
          infoPagina {
            total
            numeroPagina
            cantidadPaginas
          }
          itemsPagina {
            objetivos {
              objetivo {
                objetivo
                formaSumar
              }
              target
              performance
            }
          }
        }
      }
    }
  }
`;

export const getInformePerformance = gql`
  query getInformePerformance(
    $idPrograma: Int
    $idCampana: ID
    $idUsuario: Int
    $estado: EstadoCampanaOP
    $fechaInicioDesde: Date
    $fechaInicioHasta: Date
    $fechaFinDesde: Date
    $fechaFinHasta: Date
    $page: Int!
    $rowsPerPage: Int!
  ) {
    getInformePerformance(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      performanceLike: {
        idPrograma: $idPrograma
        idCampana: $idCampana
        idUsuario: $idUsuario
        estado: $estado
      }
      performanceRange: {
        fechaInicio: { min: $fechaInicioDesde, max: $fechaInicioHasta }
        fechaFin: { min: $fechaFinDesde, max: $fechaFinHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        fecha
        campana {
          _id
          nombre
          descripcion
          imagenes
          fechaInicio
          fechaFin
          estado
          puntosTodos
          puntosTodosSupervisor
        }
        total {
          objetivo {
            objetivo
            formaSumar
          }
          target
          performance
        }
        individual {
          infoPagina {
            total
            numeroPagina
            cantidadPaginas
          }
          itemsPagina {
            usuario {
              idUsuario
              username
              perfil {
                nombre
                apellido
              }
            }
            objetivos {
              objetivo {
                objetivo
                formaSumar
              }
              target
              performance
            }
          }
        }
      }
    }
  }
`;

export const getInformePerformanceEDD = gql`
  query getInformePerformanceEDD(
    $idPrograma: Int
    $idCampana: ID
    $idUsuario: Int
    $estado: EstadoCampanaOP
    $fechaInicioDesde: Date
    $fechaInicioHasta: Date
    $fechaFinDesde: Date
    $fechaFinHasta: Date
    $page: Int!
    $rowsPerPage: Int!
  ) {
    getInformePerformanceEDD(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      performanceLike: {
        idPrograma: $idPrograma
        idCampana: $idCampana
        idUsuario: $idUsuario
        estado: $estado
      }
      performanceRange: {
        fechaInicio: { min: $fechaInicioDesde, max: $fechaInicioHasta }
        fechaFin: { min: $fechaFinDesde, max: $fechaFinHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        nombreMed
        codigoMed
        infoPagina {
          total
          numeroPagina
          cantidadPaginas
        }
        itemsPagina {
          fecha
          campana {
            _id
            nombre
            descripcion
            imagenes
            fechaInicio
            fechaFin
            estado
            puntosTodos
            puntosTodosSupervisor
          }
          total {
            objetivo {
              objetivo
              formaSumar
            }
            target
            performance
          }
          individual {
            infoPagina {
              total
              numeroPagina
              cantidadPaginas
            }
            itemsPagina {
              usuario {
                idUsuario
                username
                perfil {
                  nombre
                  apellido
                }
              }
              objetivos {
                objetivo {
                  objetivo
                  formaSumar
                }
                target
                performance
              }
            }
          }
        }
      }
    }
  }
`;

export const getCampanaPerformance = gql`
  query getCampanaPerformance(
    $idPrograma: Int
    $idCampana: ID
    $idUsuario: Int
    $estado: EstadoCampanaOP
    $page: Int!
    $rowsPerPage: Int!
    $fechaInicioDesde: Date
    $fechaInicioHasta: Date
    $fechaFinDesde: Date
    $fechaFinHasta: Date
  ) {
    getCampanaPerformance(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      performanceLike: {
        idPrograma: $idPrograma
        idCampana: $idCampana
        idUsuario: $idUsuario
        estado: $estado
      }
      performanceRange: {
        fechaInicio: { min: $fechaInicioDesde, max: $fechaInicioHasta }
        fechaFin: { min: $fechaFinDesde, max: $fechaFinHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        usuario {
          idUsuario
          username
          perfil {
            nombre
            apellido
          }
        }
        objetivos {
          objetivo {
            objetivo
            formaSumar
          }
          target
          performance
        }
      }
    }
  }
`;

export default {
  queries: {
    getJerarquiaUsuario,
    getMiInformePerformance,
    getInformePerformanceEDD,
    getInformePerformance,
    getCampanaPerformance,
    getUsuariosDistribuidora
  }
};
