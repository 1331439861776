import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";

import PerformanceCampanaGraphQL from "js/graphql/resolvers/campanaPerformance.resolver";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { UsuarioField } from "js/components/Shared/Form/Fields";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import {
  updateQueryParamsAsync,
  initialState
} from "js/pages/Admin/Canje/utils";
import { withApollo } from "react-apollo";

class PerformanceFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pagina: {
        numeroPagina: 1,
        cantidadPorPagina: 10
      },
      getCampanasFiltros: [],
      getMisCampanas: [],
      isLoading: false,
      infoPagina: {},
      miInfoPagina: {}
    };
  }

  async componentDidMount() {
    const { isPerformance, idPrograma } = this.props;
    const { pagina } = this.state;
    await this.fetchCampanasFiltros({ idPrograma, pagina, isPerformance });
  }
  submitValue = e => {
    this.handleChange(e);
  };

  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  updateAutocompleteQueryParams = key => select => {
    if (key === "idUsuario") {
      const usuario = select.length ? select[0] : { value: null };
      this.setState(
        updateQueryParamsAsync({ key, value: usuario.value }),
        this.props.submitValue({ target: { name: key, value: usuario.value } })
      );
      return;
    }
    if (key === "campanas") {
      const campana = select.length ? select[0] : { value: null };
      this.setState(
        updateQueryParamsAsync({ key, value: campana.value }),
        this.props.submitValue({ target: { name: key, value: campana.value } })
      );
      return;
    }
    this.setState(
      updateQueryParamsAsync({ key, value: select.value }),
      this.props.submitValue({ target: { name: key, value: select.value } })
    );
  };

  async fetchCampanasFiltros(filters) {
    const { client } = this.props;
    const {
      getCampanasFiltros,
      getMisCampanas,
      infoPagina,
      miInfoPagina
    } = this.state;
    let infoPaginaUpdate = infoPagina;
    let miInfoPaginaUpdate = miInfoPagina;
    let misCampanasUpdated = getMisCampanas;
    let campanasFiltrosUpdated = getCampanasFiltros;
    const queryGetCampanasFiltros = filters.isPerformance
      ? PerformanceCampanaGraphQL.queries.getCampanasFiltros
      : PerformanceCampanaGraphQL.queries.getMisCampanas;
    const variablesQuery = {
      idPrograma: parseInt(filters.idPrograma),
      pagina: filters.pagina
    };

    await client
      .query({
        query: queryGetCampanasFiltros,
        fetchPolicy: "network-only",
        variables: variablesQuery
      })
      .then(res => {
        if (filters.isPerformance) {
          campanasFiltrosUpdated = campanasFiltrosUpdated.concat(
            res.data.getCampanasFiltros.itemsPagina
          );
          infoPaginaUpdate = res.data.getCampanasFiltros.infoPagina;
        } else {
          misCampanasUpdated = misCampanasUpdated.concat(
            res.data.getMisCampanas.itemsPagina
          );
          miInfoPaginaUpdate = res.data.getMisCampanas.infoPagina;
        }
        this.setState({
          infoPagina: infoPaginaUpdate,
          miInfoPagina: miInfoPaginaUpdate,
          getCampanasFiltros: campanasFiltrosUpdated,
          getMisCampanas: misCampanasUpdated
        });
      });
  }

  loadMoreCampanas = async e => {
    const bottom =
      e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom) {
      const { idPrograma, isPerformance } = this.props;
      let { pagina } = this.state;
      const { infoPagina, miInfoPagina } = this.state;
      let info = isPerformance ? infoPagina : miInfoPagina;
      pagina.numeroPagina = pagina.numeroPagina + 1;
      if (pagina.numeroPagina <= info.cantidadPaginas) {
        this.setState({ isLoading: true });
        await this.fetchCampanasFiltros({ idPrograma, pagina, isPerformance });
        this.setState({ isLoading: false });
      }
    }
  };

  render() {
    const {
      value,
      submitValue,
      classes,
      showUserField,
      isPerformance
    } = this.props;
    const { getCampanasFiltros, getMisCampanas } = this.state;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="campanas-simple" id="campanas-simple">
              Campañas
            </InputLabel>
            {isPerformance ? (
              <Select
                key={"campanas"}
                name={"campanas"}
                id="campanas-simple"
                value={value["nombre"] || ""}
                onChange={e => submitValue(e)}
                inputProps={{
                  name: "nombre",
                  id: "nombre"
                }}
                MenuProps={{
                  PaperProps: {
                    onScroll: this.loadMoreCampanas
                  },
                  style: {
                    maxHeight: 550
                  }
                }}
              >
                {" "}
                {getCampanasFiltros.map(element => (
                  <MenuItem key={element._id} value={element.nombre}>
                    {element.nombre}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Select
                key={"campanas"}
                name={"campanas"}
                id="campanas-simple"
                value={value["idCampana"] || ""}
                onChange={e => submitValue(e)}
                inputProps={{
                  name: "idCampana",
                  id: "idCampana"
                }}
                MenuProps={{
                  PaperProps: {
                    onScroll: this.loadMoreCampanas
                  },
                  style: {
                    maxHeight: 550
                  }
                }}
              >
                {" "}
                {getMisCampanas.map(element => (
                  <MenuItem key={element._id} value={element._id}>
                    {element.nombre}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Grid>

        {showUserField && (
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControl}>
              <UsuarioField
                async
                className={classes.style}
                value={value["idUsuario"] || ""}
                idPrograma={parseInt(window.localStorage.getItem("idPrograma"))}
                onChange={this.updateAutocompleteQueryParams("idUsuario")}
                placeholder={"Nombre, Apellido, DNI"}
                withTodos={true}
                multilabel={[
                  "perfil.nombre",
                  ", ",
                  "perfil.apellido",
                  ", ",
                  "nombre"
                ]}
                sinInactivos
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} sm={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              key={"estado"}
              name={"estado"}
              id="estado-simple"
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"INICIADA"}>Activa</MenuItem>
              <MenuItem value={"FINALIZADA"}>Finalizada</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaInicio"}
              name={"fechaInicio"}
              label={isPerformance ? "Fecha inicio desde" : "Fecha inicio"}
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaInicio",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaInicio"]
                  ? moment(value["fechaInicio"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={isPerformance ? "fechaFin" : "fechaInicioHasta"}
              name={isPerformance ? "fechaFin" : "fechaInicioHasta"}
              label={isPerformance ? "Fecha inicio hasta" : "Fecha hasta"}
              input={{
                onChange: date =>
                  submitValue(
                    isPerformance
                      ? {
                          target: {
                            name: "fechaFin",
                            value: date.toISOString()
                          }
                        }
                      : {
                          target: {
                            name: "fechaInicioHasta",
                            value: date.toISOString()
                          }
                        }
                  ),
                value: isPerformance
                  ? (() => {
                      let date = value["fechaFin"];
                      return date ? moment(date) : null;
                    })()
                  : (() => {
                      let date = value["fechaInicioHasta"];
                      return date ? moment(date) : null;
                    })()
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

PerformanceFilters.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(withApollo(PerformanceFilters));
