import { connect } from "react-redux";
import Beneficio from "./Beneficio";
import { withRouter } from "react-router-dom";
import AuthService from "js/utils/AuthService";

const mapStateToProps = state => ({
  sucursales: state.beneficio.sucursales,
  isLogged: !!AuthService.getIdUsuario()
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Beneficio));
