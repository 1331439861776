import React from "react";
import PropTypes from "prop-types";
import GoogleChart from "js/components/GoogleChart";
import { numberWithCommas } from "js/utils";
import moment from "moment";
const getAnoMes = date => {
  return moment(date, "YYYYMM").format("MM/YY");
};
const TransaccionesGlobales = ({
  data,
  info: { lastMonth, objetivo, previousYear }
}) => {
  const hayInfo = !!data.length;
  const colorLinea = "#FC545C";
  const colorMeses = "#126FFF";
  const colorObjetivo = "#0032A6";
  return hayInfo ? (
    <GoogleChart
      height={"350px"}
      width={"100%"}
      chartType="ComboChart"
      chartLanguage="it"
      data={[
        [
          "Mes",
          "Transacciones",
          { role: "tooltip" },
          { role: "style" },
          { role: "annotation" },
          "Socios únicos",
          { role: "tooltip" }
        ],
        ...data.map(({ anoMes, transacciones, sociosUnicos }) => {
          const anoMesAnterior = moment(anoMes, "YYYYMM")
            .subtract(1, "year")
            .format("YYYYMM");
          const transaccionesAnoAnterior = previousYear[anoMesAnterior];
          return [
            getAnoMes(anoMes),
            transacciones,
            "Transacciones: " +
              numberWithCommas(transacciones) +
              (transaccionesAnoAnterior !== undefined
                ? "\nTransacciones " +
                  getAnoMes(anoMesAnterior) +
                  ": " +
                  numberWithCommas(
                    transaccionesAnoAnterior ? transaccionesAnoAnterior : 0
                  )
                : ""),
            colorMeses,
            null,
            sociosUnicos,
            `Socios únicos: ${numberWithCommas(sociosUnicos)}`
          ];
        }),
        [
          `${moment(lastMonth, "MM/YYYY").format("MM/YY")}`,
          objetivo,
          `Objetivo ${moment(lastMonth, "MM/YYYY").format(
            "MM/YY"
          )}: ${numberWithCommas(objetivo)}`,
          `color: ${colorObjetivo}`,
          `${numberWithCommas(objetivo)}`,
          null,
          null
        ]
      ]}
      options={{
        vAxis: {
          minValue: 0,
          gridlines: {
            color: "#E8E8E8"
          },
          minorGridlines: {
            color: "#E8E8E8"
          },
          textStyle: {
            color: "#858585"
          },
          baselineColor: "#E8E8E8",
          viewWindow: {
            min: 0
          }
        },
        hAxis: {
          textStyle: {
            color: "#858585"
          },
          viewWindow: {
            min: 0
          }
        },
        title: "Transacciones Globales",
        titleTextStyle: {
          color: "#6A696B",
          fontSize: 20,
          bold: false
        },
        chartArea: { left: 45, width: "82%", height: "45%" },
        fontName: "Sueca Nano",
        colors: [colorMeses, colorLinea],
        legend: {
          position: "bottom",
          alignment: "center",
          textStyle: { color: "#6A696B" }
        },
        seriesType: "bars",
        bar: { groupWidth: "15%" },
        series: { 1: { type: "line", pointSize: "10px", pointsVisible: true } }
      }}
      chartEvents={[
        {
          eventName: "ready",
          callback: Chart => {
            let container = document.getElementById(
              Chart.chartWrapper.getContainerId()
            );
            container.style.margin = "0 auto";
            container.style.width = "93%";
            Array.from(container.getElementsByTagName("text")).map(label => {
              if (label.getAttribute("text-anchor") === "middle") {
                if (
                  label.textContent ===
                  moment(lastMonth, "MM/YYYY").format("MM/YY")
                ) {
                  label.setAttribute("fill", colorObjetivo);
                }
              }
            });
          }
        }
      ]}
    />
  ) : (
    <GoogleChart
      height={"350px"}
      width={"100%"}
      chartType="ColumnChart"
      chartLanguage="it"
      data={[["Mes", "Transacciones", "Socios únicos"], [0, 0, 0]]}
      options={{
        vAxis: {
          gridlines: {
            color: "#E8E8E8"
          },
          minorGridlines: {
            color: "#E8E8E8"
          },
          textStyle: {
            color: "#858585"
          },
          baselineColor: "#E8E8E8",
          viewWindow: {
            min: 0,
            max: 5
          }
        },
        hAxis: {
          textStyle: {
            color: "#858585"
          },
          viewWindow: {
            min: 0,
            max: 5
          }
        },
        title: "Transacciones Globales",
        titleTextStyle: {
          color: "#6A696B",
          fontSize: 20,
          bold: false
        },
        chartArea: { width: "82%", height: "45%" },
        fontName: "Sueca Nano",
        colors: [colorMeses, colorLinea],
        legend: {
          position: "bottom",
          alignment: "center",
          textStyle: { color: "#6A696B" }
        },
        seriesType: "bars",
        bar: { groupWidth: "15%" },
        series: { 1: { type: "line", pointSize: "10px" } }
      }}
    />
  );
};

TransaccionesGlobales.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

TransaccionesGlobales.defaultProps = {
  data: []
};

TransaccionesGlobales.displayName = "TransaccionesGlobales";

export default TransaccionesGlobales;
