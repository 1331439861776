import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import AppQraphQL from "js/graphql/resolvers/locacion.resolver";
import { isNil } from "lodash";
import withPrograma from "js/hoc/withPrograma";
import get from "lodash/get";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { withStyles } from "@material-ui/core/styles";

const filter = ({ estado }) => estado === "ACTIVO";

class LocationFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pais: [],
      idPais: "",
      zona: [],
      idZona: "",
      localidad: [],
      idLocalidad: []
    };
  }

  async componentDidMount() {
    const {
      client,
      fromPrograma,
      idPrograma
      // programa: { idPrograma }
    } = this.props;
    await client
      .query({
        query: fromPrograma
          ? idPrograma
            ? AppQraphQL.queries.getPaises
            : AppQraphQL.queries.getPaisesByPrograma
          : AppQraphQL.queries.getMasterPaises,
        fetchPolicy: "cache-first",
        errorPolicy: "all",
        variables: {
          url:
            fromPrograma && !idPrograma
              ? window.location.hostname.replace("www.", "")
              : undefined,
          idPrograma: fromPrograma ? idPrograma : undefined
        }
      })
      .then(res => {
        const paises = get(
          res,
          `data.${
            fromPrograma
              ? idPrograma
                ? "getPaises"
                : "getPrograma.paises"
              : "getMasterPaises"
          }`,
          []
        );
        paises &&
          paises.length > 0 &&
          this.setState({ pais: paises.filter(filter) });
      });
  }

  componentWillReceiveProps(props) {
    const {
      value: { idPais, idZona }
    } = props;
    if (isNil(idPais) || idPais === "") {
      // si se selecciona todos, restablecer a vacio
      this.setState({ localidad: [], zona: [] });
    } else if (!this.state.idPais && idPais) {
      this.setState({ localidad: [], zona: [] });
      this.fetchZona(idPais);
      if (idZona) this.fetchLocalidad(idZona);
    } else if (idPais !== this.state.idPais) {
      // si el pais cambia, buscar nuevas zonas, y restablecer localidad
      this.setState({ localidad: [] });
      this.fetchZona(idPais);
    } else if (isNil(idZona) || idZona === "") {
      // si zona cambia a TODOS, restablecer localidad
      this.setState({ localidad: [] });
    } else if (idZona !== this.state.idZona) {
      // si la zona cambia, buscar nuevas zonas, y restablecer localidad
      this.setState({ localidad: [] });
      this.fetchLocalidad(idZona);
    }
  }

  async fetchZona(idPais) {
    const { client, zonaFromPrograma } = this.props;
    this.setState({ idPais });
    await client
      .query({
        query: zonaFromPrograma
          ? AppQraphQL.queries.getZonas
          : AppQraphQL.queries.getMasterZonas,
        variables: { idPais: parseInt(idPais, 10) },
        fetchPolicy: "cache-first",
        errorPolicy: "all"
      })
      .then(res => {
        const zonas = get(
          res,
          `data.${zonaFromPrograma ? "getZonas" : "getMasterZonas"}`,
          []
        );
        zonas.length > 0 &&
          this.setState({
            zona: zonas.filter(filter)
          });
      });
  }

  async fetchLocalidad(idZona) {
    const { client, zonaFromPrograma } = this.props;
    this.setState({ idZona });
    await client
      .query({
        query: zonaFromPrograma
          ? AppQraphQL.queries.getLocalidades
          : AppQraphQL.queries.getMasterLocalidades,
        variables: { idZona: parseInt(idZona, 10) },
        fetchPolicy: "cache-first",
        errorPolicy: "all"
      })
      .then(res => {
        const localidades = get(
          res,
          `data.${
            zonaFromPrograma ? "getLocalidades" : "getMasterLocalidades"
          }`,
          []
        );
        localidades.length > 0 &&
          this.setState({ localidad: localidades.filter(filter) });
      });
  }

  renderPais() {
    const { classes, value, submitValue, withTodos } = this.props;
    const { pais } = this.state;

    return (
      <FormControl className={classes.formControl}>
        <InputLabel id="pais-simple" htmlFor="pais-simple">
          País
        </InputLabel>
        <Select
          value={value["idPais"] || ""}
          onChange={e => submitValue(e, this.getSelectedLocalidad())}
          inputProps={{
            name: "idPais",
            id: "idPais"
          }}
        >
          {withTodos && <MenuItem value={undefined}>Todos</MenuItem>}
          {pais.map((item, index) => (
            <MenuItem value={parseInt(item.idPais, 10)} key={index}>
              {item.descripcion}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  getSelectedLocalidad = (idLocalidad = null) => {
    const { value } = this.props;
    const { localidad } = this.state;
    return localidad.find(
      z => z.idLocalidad === (idLocalidad || value["idLocalidad"])
    );
  };

  renderZona() {
    const { classes, value, submitValue, withTodos } = this.props;
    const { zona } = this.state;

    return (
      <FormControl className={classes.formControl}>
        <InputLabel id="zona-simple" htmlFor="zona-simple">
          Zona
        </InputLabel>
        <Select
          value={value["idZona"] || ""}
          onChange={e => submitValue(e, this.getSelectedLocalidad())}
          inputProps={{
            name: "idZona",
            id: "idZona"
          }}
        >
          {withTodos && <MenuItem value={undefined}>Todos</MenuItem>}
          {zona.map((item, index) => (
            <MenuItem value={parseInt(item.idZona, 10)} key={index}>
              {item.descripcion}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  renderLocalidad() {
    const { classes, value, submitValue, withTodos } = this.props;
    const { localidad } = this.state;

    return (
      <FormControl className={classes.formControl}>
        <InputLabel id="localidad-simple" htmlFor="localidad-simple">
          Localidad
        </InputLabel>
        <Select
          value={value["idLocalidad"] || ""}
          onChange={e =>
            submitValue(e, this.getSelectedLocalidad(e.target.value))
          }
          inputProps={{
            name: "idLocalidad",
            id: "idLocalidad"
          }}
        >
          {withTodos && <MenuItem value={undefined}>Todos</MenuItem>}
          {localidad.map((item, index) => (
            <MenuItem value={parseInt(item.idLocalidad, 10)} key={index}>
              {item.descripcion}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  render() {
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={4}>
          {this.renderPais()}
        </Grid>
        <Grid item xs={12} md={4}>
          {this.renderZona()}
        </Grid>
        <Grid item xs={12} md={4}>
          {this.renderLocalidad()}
        </Grid>
      </Grid>
    );
  }
}

LocationFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

LocationFilter.defaultProps = {
  withTodos: true
};

export default withStyles(styles)(
  withApollo(withRouter(withPrograma(LocationFilter)))
);
