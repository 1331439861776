import { compose, pure, withProps } from "recompose";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import LotesGraphQL from "js/graphql/resolvers/lotes.resolver";
import withPrograma from "js/hoc/withPrograma";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";

export default compose(
  pure,
  withPrograma,
  withStyles(styles),
  withRouter,
  withProps(ownerProps => {
    return {
      numeroLote: ownerProps.match.params.id
    };
  }),
  graphql(LotesGraphQL.queries.getLote, {
    alias: "AdminLotesDetailHOC",
    options: ({ numeroLote }) => ({
      fetchPolicy: "network-only",
      variables: {
        numeroLote,
        url: window.location.hostname.replace("www.", "")
      }
    }),
    props: ({ data: { refetch, loading, getLotes, getPrograma } }) => {
      if (loading) return;
      return {
        lote: getLotes.itemsPagina[0],
        programa: getPrograma,
        onRefetchLote: refetch
      };
    }
  })
);
