const color = "#757575";
const styles = theme => ({
  list: {
    width: "100%"
  },
  disabled: {
    color
  }
});
export default styles;
