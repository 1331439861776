import React, { PureComponent } from "react";
import { withApollo } from "react-apollo";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Collapsable from "js/components/Shared/Collapsable";
import { _t } from "js/utils/TranslationService";
import PropTypes from "prop-types";

const vars = [
  {
    title: "Reconocimiento",
    values: [
      [
        "url",
        "nominadosComa",
        "nominadosLinea",
        "nominador.nombre",
        "nominador.mensaje",
        "reconocimiento.accion",
        "reconocimiento.justificacion",
        "reconocimiento.valoracion",
        "reconocimiento.valores",
        "reconocimiento.tipos",
        "aprobador1.nombre",
        "aprobador1.mensaje",
        "aprobador2.nombre",
        "aprobador2.mensaje",
        "aprobador3.nombre",
        "aprobador3.mensaje",
        "rrhh.mensaje",
        "editador.nombre",
        "editador.mensaje",
        "rechazador.nombre",
        "rechazador.mensaje",
        "premio",
        "puntos"
      ]
    ]
  },
  {
    title: "Programa",
    values: [["programa.nombre", "programa.dominio"]]
  }
];

const copyToClipBoard = str => {
  const el = document.createElement("textarea");
  el.value = "{{ " + str + " }}";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const getContent = variables => {
  return variables.map(group => (
    <Grid
      item
      xs={12}
      sm={6}
      key={group.title}
      container
      justify={"flex-start"}
      alignContent={"flex-start"}
      alignItems={"flex-start"}
      direction={"row"}
    >
      <Grid item xs={12}>
        <h4>{group.title}</h4>
      </Grid>
      {getItems(group.values)}
    </Grid>
  ));
};

const getItems = values => {
  return (
    <Grid xs={12} item>
      {values.map((subgroup, i) => (
        <Grid xs={12} item key={i}>
          {subgroup.map(value => (
            <Grid
              xs={12}
              item
              onClick={e => copyToClipBoard(value)}
              style={{ fontSize: "0.85em", cursor: "copy" }}
              key={value}
            >
              {"{{ " + value + " }}"}
            </Grid>
          ))}
          <br />
        </Grid>
      ))}
    </Grid>
  );
};

class Variables extends PureComponent {
  state = { vars: [] };

  componentDidUpdate = async prevProps => {
    const { idPrograma } = this.props;
    if (prevProps.idPrograma === idPrograma) return;
    const variables = Object.assign([], vars);
    this.setState({ vars: variables });
  };
  render() {
    if (this.state.vars.length === 0) this.componentDidUpdate({});
    return (
      <React.Fragment>
        <Grid item xs={12} container>
          <Collapsable withActions={false} title={_t("Glosario")}>
            <Grid item xs={12} container spacing={16}>
              {getContent(this.state.vars)}
            </Grid>
          </Collapsable>
        </Grid>
      </React.Fragment>
    );
  }
}
Variables.propTypes = {
  idPrograma: PropTypes.number
};

export default withRouter(withApollo(Variables));
