import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { css } from "emotion";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import client from "js/App/client.graphql.js";
import AccessQraphQL from "js/graphql/resolvers/access.resolver";
import IdiomasList from "js/components/Shared/IdiomasList";
import { _t } from "js/utils/TranslationService";
import ErrorList from "js/components/Shared/ErrorList";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Link } from "react-router-dom";

export class RestorePassword extends Component {
  state = {
    usuario: "",
    errors: null,
    message: null,
    loading: false
  };

  restorePassword = async (usuario, idPrograma) => {
    this.setState({ loading: true });
    const { errors, data } = await client.mutate({
      mutation: AccessQraphQL.mutations.recuperarClave,
      errorPolicy: "all",
      variables: {
        usuario,
        idPrograma
      }
    });
    this.setState({ loading: false });
    if (data.recuperarClave) {
      const message = _t(
        "Se ha enviado a tu email la información para restaurar tu clave"
      );
      this.setState(
        (state, props) => ({ message }),
        this.props.openNotification(message)
      );
      this.props.history.push("/signin");
    }

    if (errors) {
      this.setState({ errors: errors[0] });
    }
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, errors: null });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    const { classes, programa, logo } = this.props;
    const { esPublico } = programa;
    const { usuario, loading, errors } = this.state;
    const { access } = programa.template;

    return (
      <Fragment>
        <Paper className={classes.root} elevation={1}>
          <div className={classes.idiomaPosition}>
            <IdiomasList />
          </div>
          <img alt="Logo" src={logo} className={classes.logo} />
          <form
            onSubmit={event => {
              event.preventDefault();
              this.restorePassword(usuario, programa.idPrograma);
            }}
          >
            <Grid container direction="column">
              <Typography variant="h6" gutterBottom>
                {access.restorePassword.titulo}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {access.restorePassword.descripcion}
              </Typography>
              <FormControl
                className={css(
                  classes.margin,
                  classes.withoutLabel,
                  classes.textField
                )}
              >
                <Input
                  required
                  placeholder={_t("Usuario")}
                  id="adornment-usuario"
                  value={usuario}
                  onChange={this.handleChange("usuario")}
                />
              </FormControl>
              <ErrorList errors={errors} belowInput />
            </Grid>
            <Grid item xs={12} container justify="flex-end">
              <Button
                disabled={usuario === undefined ? true : false}
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                {!loading && _t("Recuperar clave")}
                {loading && (
                  <CircularProgress
                    className={classes.progress}
                    size={20}
                    color="inherit"
                  />
                )}
              </Button>
            </Grid>
          </form>
          {esPublico && (
            <Typography variant="caption" gutterBottom align="right">
              <Link to="/signup">
                {_t("Si no estás registrado ingresa aquí")}
              </Link>
            </Typography>
          )}
          <Grid container justify="space-between">
            <Typography variant="caption" gutterBottom align="left">
              <Link to="/restoreUser">{_t("Recuperar usuario")}</Link>
            </Typography>
            <Typography variant="caption" gutterBottom align="right">
              <Link to="/signin">{_t("Volver al inicio de sesión")}</Link>
            </Typography>
          </Grid>
        </Paper>
      </Fragment>
    );
  }
}

RestorePassword.propTypes = {
  programa: PropTypes.object
};

RestorePassword.defaultProps = {
  programa: {},
  open: false
};

export default RestorePassword;
