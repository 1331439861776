import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "./advancedFilter.js";
import MovimientosQraphQL from "js/graphql/resolvers/movimientos.resolver";
import columnData from "./columnData";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import AppQraphQL from "js/graphql/resolvers/perfil.resolver";
import UserQraphQL from "js/graphql/resolvers/usuario.resolver";

import {
  viewAction,
  editAction,
  showSucursalAction
} from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";

const simplifyHeader = {
  title: "CC Usuarios",
  new: {
    title: "Nuevo",
    perms: [Roles.ASIGNAR_PUNTOS_USUARIO_W],
    path:
      window.location.pathname.indexOf("admin") === -1
        ? "/cc-usuarios/new"
        : null
  },
  queryStringMatcher: {
    idUsuario: "cuentaCorriente_usuario_idUsuario",
    idSegmento: "cuentaCorriente_usuario_idSegmento",
    idPrograma: "cuentaCorriente_usuario_idPrograma",
    idsProgramas: undefined
  }
};

const defaultHeader = id => ({
  ...simplifyHeader,
  import: {
    action: AppQraphQLImport.queries.importarAsignarPuntosAUsuario,
    model: "Movimientos_CC_Usuarios_Modelo.csv",
    perms: [Roles.IMPORT_ASIGNAR_PUNTOS_USUARIO_W],
    variables: { idPrograma: parseInt(id, 10) }
  },
  export: {
    path: "movimientos/usuario",
    perms: [Roles.EXPORT_MOVIMIENTO_USUARIO_R]
  }
});

const defaultSort = { order: "desc", orderBy: "fecha" };

class Tabla extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      conGerencia: false,
      gerencia: undefined,
      loading: true
    };
    this.getCamposPerfil(props);
  }

  getCamposPerfil = props => {
    const { client } = props;
    const { programa = {} } = props;
    const id = parseInt(programa.idPrograma, 10) || programa.idPrograma;
    return client
      .query({
        query: AppQraphQL.queries.getDefinicionesPerfilById,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: id
        }
      })
      .then(res => {
        if (!res.data.getDefinicionesPerfil.length) return;
        let data = res.data.getDefinicionesPerfil;
        let conGerencia = false;
        data.map((v, i) => {
          if (v.key === "gerencia") conGerencia = true;
        });
        if (conGerencia) {
          client
            .query({
              query: UserQraphQL.queries.getMiUsuarioCompleto,
              fetchPolicy: "cache-first"
            })
            .then(({ loading, data }) => {
              const {
                miUsuario: { perfil }
              } = data;
              const otros = perfil.otros ? JSON.parse(perfil.otros) : {};
              const gerencia = otros.gerencia;
              this.setState({
                conGerencia: conGerencia,
                gerencia,
                loading
              });
            });
        } else {
          this.setState({
            loading: false
          });
        }
      });
  };

  render() {
    const view = params => ({
      ...viewAction({ ...params, history: this.props.history })
    });
    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.ASIGNAR_PUNTOS_USUARIO_W]
      })
    });
    const sucursal = params => ({
      ...showSucursalAction({ ...params, history: this.props.history })
    });
    const {
      programa = {},
      computedMatch: {
        params: { idUsuario }
      }
    } = this.props;

    const { loading } = this.state;
    const id = programa.idPrograma;
    return loading ? (
      ""
    ) : (
      <Table
        storage="FiltroCCUsuario"
        query={MovimientosQraphQL.queries.getMovimientosUsuarios}
        defaultHeader={id ? defaultHeader(id) : simplifyHeader}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count} movimientos`
        }
        getTipo={data => data.getMovimientosUsuarios}
        actionsCustom={[view, edit, sucursal]}
        initialFilter={{
          idPrograma: id,
          cuentaCorriente_usuario_gerencia: this.state.gerencia,
          idUsuario: parseInt(idUsuario, 10)
        }}
        idPrograma={id}
        conGerencia={this.state.conGerencia}
      />
    );
  }
}

export default Tabla;
