import AsyncTypeAhead from "./AsyncTypeAhead";
import { withStyles } from "@material-ui/core/styles";
import { withApollo } from "react-apollo";
import styles from "./styles";

import { compose } from "recompose";

const enhance = compose(
  withApollo,
  withStyles(styles, { withTheme: true })
);
export default enhance(AsyncTypeAhead);
