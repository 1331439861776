export default theme => ({
  grid: {
    margin: "0"
  },
  errorInput: {
    width: "100%",
    color: theme.palette.error.main,
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.9rem",
    marginTop: theme.spacing.unit
  },
  error: {
    color: "red"
  }
});
