import React from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import Spinner from "js/components/Shared/Spinner";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";

const Condiciones = props => {
  const { condiciones } = props;

  if (condiciones === undefined)
    return <Spinner thickness={7} color="inherit" variant="indeterminate" />;
  else if (isEmpty(condiciones)) {
    return <Redirect to="/landing" />;
  }
  return <div>{Parser(condiciones)}</div>;
};

Condiciones.propTypes = {
  classes: PropTypes.object,
  condiciones: PropTypes.string
};

Condiciones.defaultProps = {};

Condiciones.displayName = "Condiciones";

export default Condiciones;
