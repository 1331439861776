import gql from "graphql-tag";

export const getPuntosDeVenta = gql`
  query getPuntosDeVenta(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: PuntoDeVentaColumn!
    $idPrograma: Int
    $nombre: String
    $estado: Estado
    $idPuntoDeVenta: Int
  ) {
    getPuntosDeVenta(
      puntoDeVentaLike: {
        idPrograma: $idPrograma
        nombre: $nombre
        estado: $estado
        idPuntoDeVenta: $idPuntoDeVenta
      }
      puntoDeVentaOrder: { column: $orderBy, order: $order }
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
    ) {
      itemsPagina {
        idPuntoDeVenta
        nombre
        estado
        multiplicador
        programa {
          idPrograma
          nombre
        }
      }
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
    }
  }
`;

export const getPuntoDeVentaById = gql`
  query getPuntosDeVenta(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: PuntoDeVentaColumn!
    $idPrograma: Int
    $idPuntoDeVenta: Int
  ) {
    getPuntosDeVenta(
      puntoDeVentaLike: {
        idPrograma: $idPrograma
        idPuntoDeVenta: $idPuntoDeVenta
      }
      puntoDeVentaOrder: { column: $orderBy, order: $order }
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
    ) {
      itemsPagina {
        idPuntoDeVenta
        nombre
        estado
        multiplicador
        programa {
          idPrograma
          nombre
        }
      }
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
    }
  }
`;

export const importPuntosDeVenta = gql`
  mutation importPuntosDeVenta($file: Upload!, $idPrograma: Int!) {
    importPuntosDeVenta(file: $file, idPrograma: $idPrograma)
  }
`;

export const createPuntoDeVenta = gql`
  mutation createPuntoDeVenta($puntoDeVenta: PuntoDeVentaCreate!) {
    createPuntoDeVenta(puntoDeVenta: $puntoDeVenta) {
      idPuntoDeVenta
      nombre
      estado
      multiplicador
      programa {
        idPrograma
        nombre
      }
    }
  }
`;

export const updatePuntoDeVenta = gql`
  mutation updatePuntoDeVenta($puntoDeVenta: PuntoDeVentaUpdate!) {
    updatePuntoDeVenta(puntoDeVenta: $puntoDeVenta) {
      idPuntoDeVenta
      nombre
      estado
      multiplicador
      programa {
        idPrograma
        nombre
      }
    }
  }
`;

export const removePuntoDeVenta = gql`
  mutation removePuntoDeVenta($idPuntoDeVenta: Int!) {
    removePuntoDeVenta(idPuntoDeVenta: $idPuntoDeVenta) {
      idPuntoDeVenta
      nombre
      estado
      multiplicador
      programa {
        idPrograma
        nombre
      }
    }
  }
`;

export default {
  queries: {
    getPuntosDeVenta,
    getPuntoDeVentaById
  },
  mutations: {
    importPuntosDeVenta,
    createPuntoDeVenta,
    updatePuntoDeVenta,
    removePuntoDeVenta
  }
};
