import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "./advancedFilter";
import { USER_ROLES as Roles } from "js/models/User";
import AppQraphQL from "js/graphql/resolvers/cargaFacturas.resolver";
import { verFactura } from "js/components/Shared/Tabla/ActionCells";
import { setGruposSKUs } from "js/redux/campana/campana.actions";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import AuthService from "js/utils/AuthService";
import capitalize from "lodash/capitalize";
import { _t } from "js/utils/TranslationService";
import Fila from "js/components/Fila";
import {
  fetchParametrosVisuales,
  getBanners,
  cleanText
} from "js/utils/helpers/FacturaPorPuntos.helpers.js";

const defaultHeader = (id, btnsNextTitle) => {
  return {
    title: AuthService.hasPerms([Roles.MODERACION_W])
      ? _t("Moderación de facturas")
      : _t("Facturas enviadas"),
    btnsNextTitle,
    export: {
      path: "listaDeCargaDeFacturas",
      perms: [Roles.MODERACION_EXPORT],
      tooltip: _t("Exportar lista de facturas")
    },
    queryStringMatcher: {
      idsPrograma: undefined
    },
    others: [
      {
        action: AppQraphQL.mutations.importProductosCargaFacturas,
        perms: [Roles.MODERACION_IMPORT],
        type: "import",
        tooltip: _t("Importar productos facturas"),
        model: "Modelo_Grupos_Productos_Carga_Facturas.csv",
        modelTooltip: "Modelo productos de carga de facturas",
        variables: {
          idPrograma: parseInt(id) || id
        }
      },
      {
        path: "codigosProductoCargaFacturas",
        perms: [Roles.MODERACION_EXPORT],
        type: "export",
        tooltip: _t("Exportar productos facturas")
      }
    ]
  };
};

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

const columnData = [
  { id: "idCargaFactura", label: "#" },
  {
    id: "fechaCreacion",
    label: AuthService.hasPerms([Roles.MODERACION_W])
      ? _t("Fecha de creación")
      : _t("Fecha de envío"),
    type: "date"
  },
  { id: "puntoVenta", label: "Punto de venta" },
  { id: "fechaFactura", label: "Fecha de factura", type: "date" },
  { id: "numeroFactura", label: "Número de factura" },
  {
    id: "estado",
    label: _t("Estado"),
    type: "custom",
    parser: estado => (
      <Estado
        estado={capitalize(
          estado === "PENDIENTE_APROBACION" ? "PENDIENTE APROBACION" : estado
        )}
      />
    )
  }
];

const columnDataAdmin = [
  {
    id: "usuario",
    label: _t("Usuario"),
    type: "custom",
    parser: usuario => (usuario ? usuario.username : "--")
  },
  {
    id: "usuarioModerador",
    label: _t("Moderador"),
    type: "custom",
    parser: usuario => (usuario ? usuario.username : "--")
  },
  {
    id: "actions",
    label: _t("Acciones")
  }
];

const columnDataUser = [
  {
    id: "productos",
    label: _t("Puntos acreditados"),
    type: "custom",
    parser: (productos, factura) => {
      let val = 0;
      productos.forEach(prod => (val += prod.cantidad * prod.puntos));
      return factura.estado === "APROBADA" ? val : "--";
    }
  },
  {
    id: "actions",
    label: _t("Ver factura")
  }
];

AuthService.hasPerms([Roles.MODERACION_W])
  ? columnData.push(...columnDataAdmin)
  : columnData.push(...columnDataUser);

class Tabla extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      banners: {},
      inBackOffice:
        window.location.pathname.indexOf("admin") !== -1 ? true : false
    };
  }
  async componentDidMount() {
    const {
      match: {
        params: { id }
      },
      client
    } = this.props;

    const idPrograma = id || localStorage.getItem("idPrograma");

    let parametrosVisuales = await fetchParametrosVisuales(idPrograma, client);

    let banners = {};

    if (parametrosVisuales && parametrosVisuales.banners) {
      banners = getBanners(parametrosVisuales.banners, false);

      if (banners) {
        banners.contenidos = cleanText(banners);
      }
    }

    if (parametrosVisuales) {
      this.setState({ banners: banners ? banners : {} });
    }
  }
  render() {
    const { banners, inBackOffice } = this.state;
    const cutsomButtons = [];
    const isAdmin = window.location.pathname.indexOf("admin") !== -1;

    const verFacturaButton = params => ({
      ...verFactura({
        openNotification: this.props.openNotification,
        ...params,
        perms: [Roles.MODERACION_R]
      })
    });

    cutsomButtons.push(verFacturaButton);

    const getCargaFactura = data => {
      if (data) {
        return {
          itemsPagina: data.getCargaFactura.itemsPagina,
          infoPagina: data.getCargaFactura.infoPagina
        };
      } else {
        return {
          itemsPagina: [],
          infoPagina: {}
        };
      }
    };

    const {
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <>
        {banners &&
          banners.visible &&
          !inBackOffice && (
            <div style={{ marginBottom: "1em" }}>
              <Fila {...banners} baseLink={"Banners Performance"} />
            </div>
          )}
        <Table
          storage={"FiltroCodigosCargados"}
          query={AppQraphQL.queries.getCargaFactura}
          defaultHeader={defaultHeader(id)}
          advancedFilter={advancedFilter}
          sort={defaultSort}
          columnData={columnData}
          getTipo={getCargaFactura}
          showPagination={true}
          actionsCustom={cutsomButtons}
          pathId={"_id"}
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setGruposSKUs: grupos => dispatch(setGruposSKUs(grupos))
});

// export default Tabla;
export default connect(
  null,
  mapDispatchToProps
)(Tabla);
