import MyForm from "./MyForm.container";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import { compose } from "recompose";

const enhance = compose(
  withRouter,
  withApollo,
  withStyles(styles)
);
export default enhance(MyForm);
