export default {
  settings: {
    idPrograma: 1,
    idioma: "es-AR",
    isPublic: true,
    langProps: {
      selectOptions: {
        icon: "language",
        options: ["es-AR", "pt", "es-PABLO"]
      }
    },
    nombre: "Whiteprompt",
    url: "www.whiteprompt.com"
  },
  template: {
    access: {
      accessTemplate: 1,
      backgroundImage: "",
      css: {},
      logoURL: "https://image.ibb.co/n6VRxK/cbu.png",
      restorePassword: {
        descripcion:
          "Testing Bienvenido al segmento de Melisa. Esta es la descripcion detallada para mostrar en el restore Password con informacion adicional y comentarios encesarios",
        titulo: "Este es el titulo del Recuperar Clave"
      },
      restoreUser: {
        descripcion:
          "Bienvenido al segmento de Melisa. Esta es al descripcion detallada para mostrar en el restore usuario con informacion adicional",
        titulo: "Este es el titulo del Recuperar el usuario"
      },
      signin: {
        descripcion:
          "Bienvenido al segmento de Melisa. Esta es al descripcion detallada para mostrar en el signin con informacion adicional",
        titulo: "Este es el titulo del inicio de sesion"
      },
      signup: {
        descripcion:
          "Bienvenido al segmento de Melisa. Esta es al descripcion detallada para mostrar en el signup con informacion adicional",
        titulo: "Este es el titulo del registro de usuario"
      }
    },
    catalogo: {
      vista: "grid",
      items: {
        card: {
          css: {
            backgroundColor: "yellow",
            borderRadius: 25,
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)"
          }
        }
      }
    },
    destacados: {
      contenidos: [
        {
          css: {},
          bases: [
            {
              css: {},
              src: "https://picsum.photos/1200/100/?image=0",
              link: "/producto/12",
              subtexto: "solo subtexto",
              texto: {
                contenido: "hola augusto",
                css: {
                  color: "",
                  fontFamily: "",
                  fontStyle: "",
                  fontWeight: "",
                  textAlign: "",
                  verticalAlign: ""
                }
              },
              hasSpaceBetween: false,
              tipo: "base"
            },
            {
              css: {},
              src: "https://picsum.photos/1200/100/?image=80",
              link: "/producto/12",
              subtexto: "solo subtexto",
              texto: {
                contenido: "tiene otro texto",
                css: {
                  color: "",
                  fontFamily: "",
                  fontStyle: "",
                  fontWeight: "",
                  textAlign: "",
                  verticalAlign: ""
                }
              },
              hasSpaceBetween: false,
              tipo: "base"
            },
            {
              css: {},
              src: "https://picsum.photos/1200/100/?image=990",
              link: "/producto/12",
              subtexto: "solo subtexto",
              texto: {
                contenido: "tiene otro texto",
                css: {
                  color: "",
                  fontFamily: "",
                  fontStyle: "",
                  fontWeight: "",
                  textAlign: "",
                  verticalAlign: ""
                }
              },
              hasSpaceBetween: false,
              tipo: "base"
            },
            {
              css: {},
              src: "https://picsum.photos/1200/100/?image=11",
              link: "/producto/12",
              subtexto: "solo subtexto",
              texto: {
                contenido: "tiene otro texto",
                css: {
                  color: "",
                  fontFamily: "",
                  fontStyle: "",
                  fontWeight: "",
                  textAlign: "",
                  verticalAlign: ""
                }
              },
              hasSpaceBetween: false,
              tipo: "base"
            }
          ],
          spaceBetween: false,
          tipo: "slider"
        }
      ],
      css: {
        margin: "",
        padding: ""
      },
      spaceBetween: false,
      id: 1,
      filaTipo: 1
    },
    footer: {
      email: "",
      columnasFooter: [
        {
          links: [
            {
              link: "",
              nombre: ""
            }
          ],
          titulo: ""
        }
      ],
      redes: [
        {
          link: "",
          red: ""
        }
      ],
      telefono: ""
    },
    header: {
      css: {
        backgroundColor: "white"
      },
      logoPrincipal: {
        url: "https://via.placeholder.com/350x150?text=Error"
      },
      menu: {
        css: {
          inside: {
            fontSize: "medium",
            padding: "medium"
          },
          below: {
            fontSize: "medium",
            padding: "medium",
            align: "center",
            backgroundColor: "none",
            borderTop: false
          },
          burger: {
            fontSize: "medium"
          }
        },
        navbar: {
          css: {
            height: "medium",
            fontSize: "medium",
            backgroundColor: "#4a148c"
          },
          items: [
            {
              link: "/",
              texto: ""
            }
          ]
        },
        tipo: "below"
      },
      user: {
        css: {
          fontSize: "medium"
        }
      }
    },
    landing: {
      css: {},
      filas: [
        {
          contenidos: [
            {
              css: {
                borderRadius: "0"
              },
              src: "https://via.placeholder.com/1200x1200?text=Error",
              link: "/producto/12",
              subtexto: "",
              texto: {
                contenido: "",
                css: {
                  color: "",
                  fontFamily: "",
                  fontStyle: "",
                  fontWeight: "",
                  textAlign: "",
                  verticalAlign: ""
                }
              },
              tipo: "base"
            }
          ],
          css: {
            padding: "32px",
            "& > div": {
              paddingLeft: "16px",
              paddingRight: "16px"
            },
            "& > div:first-child": {
              paddingLeft: "0px"
            },
            "& > div:last-child": {
              paddingRight: "0px"
            },
            lateralGutter: true,
            border: "none"
          },
          spaceBetween: false,
          id: 1,
          filaTipo: 1
        }
      ]
    },
    templateData: {
      googleFont: ["Arial", "Hanalei Fill"],
      background: {
        css: {},
        type: "",
        url: ""
      },
      fullWidth: false,
      templateId: 2,
      theme: {
        typography: {
          fontFamily: "Arial",
          fontFamilyBase: "Hanalei Fill"
        },
        palette: {
          common: {
            black: "#000",
            white: "#fff"
          },
          type: "light",
          primary: {
            main: "#4a148c"
          },
          secondary: {
            main: "#ff3d00"
          },
          tertiary: {
            main: "#9E9E9E",
            light: "#BDBDBD",
            dark: "#757575",
            contrastText: "#000"
          },
          quaternary: {
            main: "#9E9E9E",
            light: "#BDBDBD",
            dark: "#757575",
            contrastText: "#000"
          },
          quinary: {
            main: "#9E9E9E",
            light: "#BDBDBD",
            dark: "#757575",
            contrastText: "#000"
          },
          error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff"
          },
          text: {
            primary: "#212121",
            secondary: "#757575",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)"
          },
          background: {
            paper: "#fff",
            default: "#fafafa"
          },
          action: {}
        },
        shape: {
          borderRadius: 4
        }
      }
    }
  }
};
