import React from "react";
import { Field } from "redux-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { Checkbox } from "redux-form-material-ui";
import { isRequired } from "js/helpers/validations";
import { _t } from "js/utils/TranslationService";
import { styles } from "../styles.js";

const labelStyle = {
  cursor: "pointer",
  color: "currentColor"
};

const CheckboxWithLabelAndError = inputProps => {
  const {
    meta: { touched, error },
    input: { name },
    label,
    translation,
    disabled,
    required,
    fullWidth = true
  } = inputProps;
  let checkboxRef;
  return (
    <FormControl
      required={required}
      error={Boolean(touched && error)}
      fullWidth={fullWidth}
    >
      <FormControlLabel
        control={
          <React.Fragment>
            <Checkbox
              {...inputProps}
              disabled={disabled}
              value={inputProps.input.value ? "true" : "false"}
              onChange={inputProps.input.onChange}
              id={name}
              inputRef={cb => (checkboxRef = cb)}
            />
            <FormLabel style={labelStyle} onClick={() => checkboxRef.click()}>
              {translation || _t(label)}
            </FormLabel>
          </React.Fragment>
        }
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const CheckboxField = ({
  name,
  label,
  required,
  classes,
  disabled,
  onChange,
  fullWidth
}) => (
  <Field
    validate={(() => {
      let validations = [];
      if (required) {
        validations.push(isRequired);
      }
      return validations;
    })()}
    name={name}
    disabled={disabled}
    component={props => (
      <CheckboxWithLabelAndError
        {...props}
        fullWidth={fullWidth}
        required={required}
      />
    )}
    label={label}
    className={classes ? classes.field : styles.field}
    onChange={onChange}
  />
);

export default CheckboxField;
