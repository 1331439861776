import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import MonedaFilter from "js/components/Shared/TablaFiltro/monedaFilter.js";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";
import { ProgramaField } from "js/components/Shared/Form/Fields";

class advancedFilter extends PureComponent {
  onComponentDidMount(props) {
    props.submitValue({
      target: { name: "cuentaCorriente_tipo", value: "PROGRAMA" }
    });
  }

  render() {
    const {
      value,
      submitValue,
      search,
      classes,
      match: { path }
    } = this.props;
    value["cuentaCorriente_tipo"] = "PROGRAMA";
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <ProgramaField
              className={classes}
              value={value.idPrograma}
              onChange={s =>
                submitValue({
                  target: { name: "idPrograma", value: s.value }
                })
              }
              search={search}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Operación
            </InputLabel>
            <Select
              value={value["operacion"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "operacion",
                id: "operacion"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"DEBITO"}>Débito</MenuItem>
              <MenuItem value={"CREDITO"}>Crédito</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <MonedaFilter
            classes={classes}
            value={value}
            submitValue={submitValue}
            idPrograma={value["idPrograma"] || undefined}
            disabled={path.match(/^\/admin\/programa\/:id*/) !== null && true}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TextField
              key="numeroFactura"
              name="numeroFactura"
              label="Número de factura"
              type="search"
              value={value["numeroFactura"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TextField
              key="solicitante_username"
              name="solicitante_username"
              label="Solicitante"
              type="search"
              value={value["solicitante_username"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TextField
              key="concepto"
              name="concepto"
              label="Concepto"
              type="search"
              value={value["concepto"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key="min"
              name="min"
              label="Desde"
              dateFormat="DD/MM/YYYY"
              maxDate={moment()}
              startOfDate
              input={{
                onChange: date =>
                  submitValue({
                    target: { name: "min", value: date.toISOString() }
                  }),
                value: value["min"] ? moment(value["min"]) : null
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key="max"
              name="max"
              label="Hasta"
              dateFormat="DD/MM/YYYY"
              maxDate={moment()}
              endOfDate
              input={{
                onChange: date =>
                  submitValue({
                    target: { name: "max", value: date.toISOString() }
                  }),
                value: value["max"] ? moment(value["max"]) : null
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withRouter(withStyles(styles)(advancedFilter));
