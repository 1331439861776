import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { ssoLogin } from "js/utils";
import { _t } from "js/utils/TranslationService";

export class SSO extends Component {
  render() {
    const { classes, programa, history } = this.props;
    if (programa.idPrograma === 7 || programa.idPrograma === 15) ssoLogin();
    else {
      history.push("/");
    }
    return <Paper className={classes.root} elevation={1} />;
  }
}

SSO.propTypes = {
  programa: PropTypes.object
};

SSO.defaultProps = {
  programa: {}
};

export default SSO;
