import React, { PureComponent } from "react";
import { get } from "lodash";
import { withApollo } from "react-apollo";
import InputAdornment from "@material-ui/core/InputAdornment";
import { InputNumberField } from "./";

class InputMontoField extends PureComponent {
  state = { currency: null };

  componentWillReceiveProps(props) {
    const { query, queryParams, path, currency } = props;
    this.setState({ currency });
    !currency && query && this.fetchMoneda(query, queryParams, path);
  }

  async fetchMoneda(query, queryParams, path) {
    const { client } = this.props;
    await client
      .query({
        query: query,
        variables: { ...queryParams },
        fetchPolicy: "cache-first",
        errorPolicy: "all"
      })
      .then(res => {
        this.setState({
          currency: get(res, path, "")
        });
      });
  }

  render() {
    return (
      <InputNumberField
        {...this.props}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {this.state.currency}
            </InputAdornment>
          )
        }}
      />
    );
  }
}

export default withApollo(InputMontoField);
