import { connect } from "react-redux";
import LayoutUIRaw from "./LayoutUI";
import LayoutBORaw from "./LayoutBO";
import { compose, lifecycle } from "recompose";
import AuthService from "js/utils/AuthService";

import { getTemplate } from "js/redux/programa/selectors.js";

const mapStateToProps = state => ({
  theme: getTemplate(state)
});

const mapDispatchToProps = dispatch => ({});

const connectLayout = compose(
  lifecycle({
    componentWillReceiveProps(nextProps) {
      window.scroll(0, 0);
      const nextPath =
        nextProps.location.pathname +
        (nextProps.location.search ? nextProps.location.search : "");
      const thisPath =
        this.props.location.pathname +
        (this.props.location.search ? this.props.location.search : "");
      if (nextPath !== thisPath) AuthService.setUltimaURL(thisPath);
    },
    componentWillUnmount() {
      this.props.location &&
        AuthService.setUltimaURL(
          this.props.location.pathname +
            (this.props.location.search ? this.props.location.search : "")
        );
    }
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export const LayoutUI = connectLayout(LayoutUIRaw);
export const LayoutBO = connectLayout(LayoutBORaw);

export default {
  LayoutUI,
  LayoutBO
};
