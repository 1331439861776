import React, { PureComponent, Fragment } from "react";
import moment from "moment";
import MedGraphQL from "js/graphql/resolvers/medUnilever.resolver";
import VariablesMedGraphQL from "js/graphql/resolvers/variablesMedUnilever.resolver";
import CampanaOPGraphQL from "js/graphql/resolvers/campanaUnilever.resolver";
import Form from "js/pages/Unilever/Med/VariablesMed/Edit/MyForm.js";
import AuthService from "js/utils/AuthService";
import { removeTypename } from "js/utils/Helper";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    const params = {
      idPrograma: null,
      codigoMed: null,
      idCampana: null
    };
    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;
    if (inBackOffice) {
      params.idPrograma = props.match.params.id;
      params.codigoMed = props.match.params.codigo;
      params.idCampana = props.match.params.idCampana;
    } else {
      params.idPrograma = parseInt(AuthService.getIdPrograma());
      params.codigoMed = props.computedMatch.params.codigo;
      params.idCampana = props.computedMatch.params.idCampana;
    }

    this.state = {
      ...params,
      inBackOffice: inBackOffice,
      isMisMeds: window.location.pathname.indexOf("mis-meds") !== -1,
      initialValues: {},
      med: null,
      campanas: null,
      disabled: false,
      disabledEdd: false,
      isOldAjusteEdd: false,
      errors: null,
      fechaLimiteEdd: null,
      fechaLimiteSupervisores: null
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { idPrograma, codigoMed, idCampana, isMisMeds } = this.state;

    if (idCampana !== "new") {
      await client
        .query({
          query: isMisMeds
            ? VariablesMedGraphQL.queries.getVariablesMiMedByIdCampana
            : VariablesMedGraphQL.queries.getVariablesMedByIdCampana,
          fetchPolicy: "network-only",
          variables: {
            codigo: codigoMed,
            idCampana,
            idPrograma
          }
        })
        .then(res => {
          const page = isMisMeds
            ? res.data.getVariablesMiMed.itemsPagina[0]
            : res.data.getVariablesMed.itemsPagina[0];

          /**
           * Si es 0, significa que las variables están hechas con la nueva definición de
           * ajusteEdd (ajusteEddMinMax: { min: Number, max: Number }) y se usa la nueva variable .
           * Si es mayor a 0, es la definición anterior que se utilizaba (ajusteEdd).
           */
          if (page)
            if (page.ajusteEdd >= 0 && !page.ajusteEddMinMax)
              this.setState({ isOldAjusteEdd: true });

          let fields = [];
          if (page.targetsObjetivos.length === page.campana.objetivos.length) {
            fields = page.targetsObjetivos;
          } else {
            page.campana.objetivos.forEach(objetivo => {
              const item = {
                idObjetivo: objetivo._id,
                objetivo: objetivo.objetivo,
                target: null,
                targetOriginal: null
              };
              page.targetsObjetivos.forEach(variablesObjetivo => {
                if (
                  String(variablesObjetivo.idObjetivo) === String(objetivo._id)
                ) {
                  item.target = variablesObjetivo.target;
                  item.targetOriginal = variablesObjetivo.targetOriginal;
                }
              });
              fields.push(item);
            });
          }

          this.setState({
            initialValues: {
              ...page,
              idCampana: page.campana._id,
              fields: fields
            },
            med: page.med,
            campanas: [page.campana],
            fechaLimiteEdd: page.fechaLimiteEdd,
            fechaLimiteSupervisores: page.fechaLimiteSupervisores,
            disabled:
              page.estado === "APROBADO" ||
              page.estado === "AUTO_APROBADO" ||
              moment().isAfter(page.fechaLimiteEdd)
          });
        });
    } else {
      await client
        .query({
          query: CampanaOPGraphQL.queries.getCampanasInput,
          fetchPolicy: "network-only",
          variables: {
            campanaOPLike: {
              idPrograma,
              tipo: "SIMPLE"
            },
            orderBy: "fechaInicio",
            order: "desc"
          }
        })
        .then(res => {
          const campanas = res.data.getCampanasOP
            ? res.data.getCampanasOP.itemsPagina
            : null;
          this.setState({
            campanas: campanas
          });
        });

      await client
        .query({
          query: isMisMeds
            ? MedGraphQL.queries.getMiMedById
            : MedGraphQL.queries.getMedById,
          fetchPolicy: "network-only",
          variables: {
            codigo: codigoMed,
            idPrograma
          }
        })
        .then(res => {
          const med = isMisMeds
            ? res.data.getMisMeds.itemsPagina[0]
            : res.data.getMeds.itemsPagina[0];
          this.setState({
            med
          });
        });
    }
  }

  async validateAjusteEddMinMax(
    i,
    variables,
    values,
    target,
    targetOriginal,
    hasMinMax
  ) {
    const fraccion = values.ajusteEdd / 100;
    let fraccionMin = null;
    let fraccionMax = null;

    if (hasMinMax) {
      fraccionMin = values.ajusteEddMinMax.min / 100;
      fraccionMax = values.ajusteEddMinMax.max / 100;
    }

    if (
      target > targetOriginal * (fraccionMin + 1) ||
      target < targetOriginal * (1 - fraccionMin)
    ) {
      this.setState({
        errors: [
          {
            message: `El target del objetivo ${
              variables.targetsObjetivos[i].objetivo
            } debe ser entre ${parseFloat(
              targetOriginal * (1 - fraccionMin)
            ).toFixed(2)}-${parseFloat(
              targetOriginal * (fraccionMin + 1)
            ).toFixed(2)} el MÍNIMO.`
          }
        ]
      });
      return;
    }

    if (
      target > targetOriginal * (fraccionMax + 1) ||
      target < targetOriginal * (1 - fraccionMax)
    ) {
      this.setState({
        errors: [
          {
            message: `El target del objetivo ${
              variables.targetsObjetivos[i].objetivo
            } debe ser entre ${parseFloat(
              targetOriginal * (1 - fraccionMax)
            ).toFixed(2)}-${parseFloat(
              targetOriginal * (fraccionMax + 1)
            ).toFixed(2)} el MÁXIMO.`
          }
        ]
      });
      return;
    }
  }

  async submit(values) {
    const { client, history } = this.props;
    const {
      idPrograma,
      med,
      codigoMed,
      idCampana,
      fechaLimiteEdd,
      fechaLimiteSupervisores,
      isMisMeds,
      inBackOffice
    } = this.state;

    values.fechaLimiteEdd = fechaLimiteEdd;
    values.fechaLimiteSupervisores = fechaLimiteSupervisores;
    values.idPrograma = parseInt(idPrograma, 10);
    values.idMed = med._id;
    values.ajusteEdd = parseInt(values.ajusteEdd, 10);
    values.targetsObjetivos = values.fields;
    const hasMinMax = !!values.ajusteEddMinMax;
    console.log("values.ajusteEddMinMax:", values.ajusteEddMinMax);
    console.log("hasMinMax:", hasMinMax);

    if (hasMinMax)
      values.ajusteEddMinMax = {
        min: parseInt(values.ajusteEddMinMax.min, 10),
        max: parseInt(values.ajusteEddMinMax.max, 10)
      };
    else
      values.ajusteEddMinMax = {
        min: values.ajusteEdd,
        max: values.ajusteEdd
      };
    console.log("AFTER ajusteEddMinMax:", values.ajusteEddMinMax);

    const variables = {
      ...values,
      targetsObjetivos: values.fields
    };
    delete variables.fields;

    for (let i = 0; i < variables.targetsObjetivos.length; i++) {
      // Controlar si el target esta dentro del rango permitido solo para los EDD
      const target = parseInt(variables.targetsObjetivos[i].target);
      if (isMisMeds) {
        const targetOriginal = parseInt(
          variables.targetsObjetivos[i].targetOriginal
        );

        this.validateAjusteEddMinMax(
          i,
          variables,
          values,
          target,
          targetOriginal,
          hasMinMax
        );
      }
      variables.targetsObjetivos[i].target = target;
      delete variables.targetsObjetivos[i].targetOriginal;
    }
    delete variables.med;
    delete variables.campana;
    console.log("variables:", variables);

    await client
      .mutate({
        mutation:
          idCampana === "new"
            ? VariablesMedGraphQL.mutations.createVariablesMed
            : VariablesMedGraphQL.mutations.updateVariablesMed,
        variables: {
          variablesMed: removeTypename(variables)
        }
      })
      .then(res => {
        const mensaje =
          "Variables " +
          (codigoMed === "new" ? "agregadas" : "modificadas") +
          " correctamente";
        this.props.openNotification(mensaje);
        if (inBackOffice) {
          history.push(
            `/admin/programa/${idPrograma}/meds/${codigoMed}/variables`
          );
        } else if (isMisMeds) {
          history.push(`/mis-meds/${codigoMed}/variables`);
        } else {
          history.push(`/meds/${codigoMed}/variables`);
        }
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  handleDateChange = key => date => {
    this.setState({
      [key]: date.toISOString()
    });
  };

  render() {
    const {
      med,
      campanas,
      errors,
      disabled,
      initialValues,
      idPrograma,
      isMisMeds,
      inBackOffice
    } = this.state;

    return (
      <Fragment>
        <Form
          id={idPrograma}
          med={med}
          campanas={campanas}
          errors={errors}
          disabled={disabled}
          initialValues={initialValues}
          onSubmit={this.submit.bind(this)}
          handleChangeFecha={this.handleDateChange}
          isMisMeds={isMisMeds}
          inBackOffice={inBackOffice}
          fechaLimiteEdd={this.state.fechaLimiteEdd}
          fechaLimiteSupervisores={this.state.fechaLimiteSupervisores}
          isOldAjusteEdd={this.state.isOldAjusteEdd}
        />
      </Fragment>
    );
  }
}

export default Edit;
