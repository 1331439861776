import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import AppQraphQL from "js/graphql/resolvers/segmento.resolver";
import { ReduxTypeAhead, TypeAhead } from "js/components/Shared/Form/Fields";
import { required as requiredValidation } from "js/components/Shared/Form/validations.js";

class SegmentoField extends PureComponent {
  render() {
    const {
      idPrograma,
      required,
      disabled,
      name = "idSegmento",
      redux,
      pathLabel = "descripcion",
      ...props
    } = this.props;
    const Component = redux ? ReduxTypeAhead : TypeAhead;
    const rest = redux ? {} : props;
    if (props.withTodos) {
      rest.withTodos = true;
      rest.withTodosNull = props.withTodosNull;
    }
    return (
      <Component
        name={name}
        label="Segmento"
        disabled={!idPrograma || disabled}
        validate={required ? requiredValidation : null}
        query={AppQraphQL.queries.getSegmentos}
        queryParams={{ segmentoLike: { idPrograma: idPrograma || undefined } }}
        path={{
          path: "getSegmentos",
          value: "idSegmento",
          label: pathLabel,
          defaultLabel: "value"
        }}
        placeholder={"Segmento"}
        {...rest}
      />
    );
  }
}

SegmentoField.propTypes = {
  idPrograma: PropTypes.number
};

export default SegmentoField;
