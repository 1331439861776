import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ViewReporte from "./ViewReporte";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";

class ReportesCLN extends PureComponent {
  render() {
    const { classes, comercios, history, manage } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Reportes Club La Nación
            {manage && (
              <IconButton
                onClick={() => history.push("/admin/cln/reportes/gestion")}
              >
                <SettingsIcon />
              </IconButton>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <ViewReporte suggestions={comercios} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ReportesCLN.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

ReportesCLN.defaultProps = {
  data: PropTypes.any
};

ReportesCLN.displayName = "ReportesCLN";

export default ReportesCLN;
