import React, { PureComponent, Fragment } from "react";
import Form from "js/pages/Admin/Programa/CargadeCodigos/Parametros/MyForm.js";
import BannerGraphQL from "js/graphql/resolvers/banner.resolver";
import { removeTypename } from "js/utils/Helper";
import { addImageURL } from "js/constants";
import { getUrl } from "js/utils";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      errors: null,
      id: this.props.match.params.id,
      bannerImages: []
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { id } = this.state;

    await client
      .query({
        query: BannerGraphQL.queries.getBannerById,
        fetchPolicy: "network-only",
        variables: {
          bannerLike: {
            idPrograma: parseInt(id) || id
          }
        }
      })
      .then(res => {
        const banner = res.data.getBanner.itemsPagina;
        if (banner.length == 0) {
          this.setState({
            isNew: true
          });
        } else {
          this.setState({
            initialValues: {
              ...banner[0]
            },
            bannerImages: banner[0].imagenes
          });
        }
      });
  }

  async submit(values) {
    const { client, history } = this.props;
    const { id, bannerImages: imagenes, isNew } = this.state;
    let variables = { ...values };
    variables.idPrograma = parseInt(id) || id;
    variables.imagenes = imagenes;

    await client
      .mutate({
        mutation: isNew
          ? BannerGraphQL.mutations.createBanner
          : BannerGraphQL.mutations.updateBanner,
        variables: {
          banner: { ...removeTypename(variables) }
        }
      })
      .then(res => {
        const mensaje =
          "Banner " + (isNew ? "creado" : "modificado") + " correctamente";
        this.props.openNotification(mensaje);
        history.push(`/admin/programa/${id}/carga-de-codigos`);
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Falló subir la imagen al S3." });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  onChangeImage = image => {
    const formData = new window.FormData();
    formData.append("image", image);
    formData.append("context", "carga-de-codigos");
    formData.append("height", 500);
    formData.append("width", 1280);
    fetch(addImageURL, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(({ url }) => {
        this.setState(({ bannerImages }) => ({
          bannerImages: bannerImages.concat(getUrl(url))
        }));
      })
      .catch(() => console.error("Hubo un error al cargar la imagen a S3"));
  };

  onRemoveImage = urlToRemove => {
    this.setState(({ bannerImages }) => ({
      bannerImages: bannerImages.filter(url => url !== urlToRemove)
    }));
  };

  render() {
    return (
      <Fragment>
        <Form
          id={this.state.id}
          errors={this.state.errors}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
          bannerImages={this.state.bannerImages}
          onChangeImage={this.onChangeImage.bind(this)}
          onRemoveImage={this.onRemoveImage.bind(this)}
        />
      </Fragment>
    );
  }
}

export default Edit;
