import {
  compose,
  pure,
  setDisplayName,
  withHandlers,
  mapProps
} from "recompose";
import withStyles from "./styles";
import REDES_SOCIALES from "./constants.js";
import { connect } from "react-redux";
import { updateTemplateBuilder } from "js/redux/template";
import { getTemplateBuilderSection } from "js/redux/template/selectors.js";
import { CHANGE_REDES_SOCIALES } from "js/redux/template/constants.js";
import { xorBy } from "lodash";

const mapStateToProps = state => ({
  redes: getTemplateBuilderSection(state, "footer.redes")
});

const mapDispatchToProps = dispatch => ({
  changeRedesSociales: updateTemplateBuilder({
    dispatch,
    type: CHANGE_REDES_SOCIALES
  })
});

const enhance = compose(
  setDisplayName("TemplateBuilderLayout"),
  pure,
  withStyles,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  mapProps(props => {
    return {
      ...props,
      redesDisponibles: xorBy(props.redes, [...REDES_SOCIALES], "red"),
      redesSeleccionadas: props.redes
    };
  }),
  withHandlers({
    onRemove: ({ redesSeleccionadas, changeRedesSociales }) => value => {
      const payload = redesSeleccionadas.filter(rs => rs.red !== value);
      changeRedesSociales(payload);
    },
    onEdit: ({ redesSeleccionadas, changeRedesSociales }) => value => event => {
      const payload = redesSeleccionadas.map(
        rs => (rs.red === value ? { ...rs, link: event.target.value } : rs)
      );
      changeRedesSociales(payload);
    },
    onChange: ({ redesSeleccionadas, changeRedesSociales }) => ({ value }) => {
      const payload = redesSeleccionadas.concat([
        REDES_SOCIALES.find(rs => rs.red === value)
      ]);
      changeRedesSociales(payload);
    }
  })
);

export default enhance;
