export const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  textList: {
    "word-break": "break-all"
  },
  overflow: {
    overflow: "visible"
  }
});
