export const ESTADO = [
  {
    value: "PENDIENTE",
    label: "Pendiente"
  },
  {
    value: "EN_PROCESO",
    label: "En proceso"
  },
  {
    value: "ENVIADO ",
    label: "Enviado"
  }
];
