import React from "react";
import ViewIcon from "@material-ui/icons/Visibility";
import base from "./Base";

export default params => ({
  ...base(),
  tooltip: "Ver",
  label: "Ver",
  icon: <ViewIcon />,
  onclick: () => {
    const id = params.codigoItem ? params.codigoItem : params.itemId;
    params.history.push(window.location.pathname + "/" + id + "/view");
  }
});
