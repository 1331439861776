import produce from "immer";
import {
  CAMBIAR_CANTIDAD,
  INCREMENTAR_CANTIDAD,
  DECREMENTAR_CANTIDAD,
  SOLICITAR_FAIL,
  SOLICITAR_SUCCESS,
  SOLICITAR,
  CHANGE_SUCURSAL,
  CLEAN_BENEFICIO_STATE,
  CLOSE_DIALOG_SOLICITUD,
  CLOSE_NOTIFICATION,
  LOAD_BENEFICIO_STATE,
  OPEN_DIALOG_SOLICITUD,
  TOGGLE_FAVORITO_ERROR,
  TOGGLE_FAVORITO_SUCCESS,
  TOGGLE_FAVORITO,
  UPDATE_STORE_DATOS_PERFIL
} from "./constants";
import getInitialState from "./initialState";

export default (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_BENEFICIO_STATE:
        return { ...getInitialState(action.beneficio) };
      case CLEAN_BENEFICIO_STATE:
        return {};
      case SOLICITAR:
        console.log(action);
        break;
      case SOLICITAR_SUCCESS:
        draft.idSolicitud = action.idSolicitud;
        break;
      case SOLICITAR_FAIL:
        draft.solicitudFail = action.error;
        break;
      case UPDATE_STORE_DATOS_PERFIL:
        draft.datosPerfil = action.data;
        break;
      case CHANGE_SUCURSAL:
        draft.mensajeRequeridos = "";
        draft.requeridos.sucursal = action.idSucursal;
        draft.idSucursal = action.idSucursal;
        break;
      case OPEN_DIALOG_SOLICITUD:
        draft.openSolicitudPopup = true;
        break;
      case CLOSE_DIALOG_SOLICITUD:
        draft.solicitudFail = "";
        draft.mensajeRequeridos = "";
        draft.openSolicitudPopup = false;
        break;
      case CAMBIAR_CANTIDAD:
        draft.cantidad =
          parseInt(action.cantidad || 0) < 1 ? 1 : parseInt(action.cantidad);
        break;
      case DECREMENTAR_CANTIDAD:
        const decCant = draft.cantidad - 1;
        draft.cantidad = decCant < 1 ? 1 : decCant;
        break;
      case INCREMENTAR_CANTIDAD:
        const incCant = draft.cantidad + 1;
        draft.cantidad = incCant < 1 ? 1 : incCant;
        break;
      case TOGGLE_FAVORITO_ERROR:
        draft.favorito = !draft.favorito;
        draft.mensaje = action.mensaje;
        break;
      case TOGGLE_FAVORITO_SUCCESS:
        draft.favorito = action.favorito;
        draft.mensaje = action.mensaje;
        break;
      case TOGGLE_FAVORITO:
        draft.notificationIsOpen = true;
        draft.favorito = !draft.favorito;
        break;
      case CLOSE_NOTIFICATION:
        draft.notificationIsOpen = false;

        break;
      default:
        return state;
    }
  });
