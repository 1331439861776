import get from "lodash/get";
export default theme => ({
  content: {
    flex: 1,
    marginTop: 20,
    overflow: "hidden",
    boxShadow: "none",
    backgroundColor: "transparent",
    borderRadius: 0,
    "& > div:first-child": {
      borderRadius: "4px 4px 0 0"
    },
    flex: "1 0 auto", // eslint-disable-line
    "& > div": {
      maxWidth: "1320px",
      width: "100%",
      margin: "0 auto"
    }
    // margin: theme.spacing.unit
  },
  contentLanding: {
    flex: 1,
    // marginTop: 20,
    overflow: "hidden",
    boxShadow: "none",
    backgroundColor: "transparent",
    borderRadius: 0,
    "& > div:first-child": {
      borderRadius: "4px 4px 0 0"
    },
    flex: "1 0 auto", // eslint-disable-line
    "& > div": {
      maxWidth: "1320px",
      width: "100%",
      margin: "0 auto"
    }
    // margin: theme.spacing.unit
  },
  footer: {
    backgroundColor: theme.palette.quinary.main
  },
  container: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    background:
      get(theme, "backgrounds.generalContainer.backgroundType") === "url"
        ? `url(${get(theme, "backgrounds.generalContainer.backgroundImage")})`
        : get(theme, "backgrounds.generalContainer.backgroundColor", "#fff")
  }
});
