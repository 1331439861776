import gql from "graphql-tag";

export const getApiTokens = gql`
  query getApiTokens(
    $order: Order!
    $orderBy: ApiTokenColumn!
    $idPrograma: Int
    $nombre: String
    $idToken: Int
    $fechaDesde: Date
    $fechaHasta: Date
  ) {
    getApiTokens(
      apiTokenLike: {
        idPrograma: $idPrograma
        nombre: $nombre
        idToken: $idToken
      }
      apiTokenOrder: { column: $orderBy, order: $order }
      apiTokenRange: { fechaCreacion: { min: $fechaDesde, max: $fechaHasta } }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        idToken
        nombre
        descripcion
        estado
        fechaVencimiento
        fechaCreacion
      }
    }
  }
`;

export const getApiTokensProgramasTerceros = gql`
  query getApiTokensProgramasTerceros(
    $order: Order
    $orderBy: ApiTokenProgramaTerceroColumn
    $idPrograma: Int
    $idProgramaTercero: String
    $nombre: String
    $fechaDesde: Date
    $fechaHasta: Date
  ) {
    getApiTokensProgramasTerceros(
      apiTokenProgramaTerceroLike: {
        idProgramaTercero: $idProgramaTercero
        idPrograma: $idPrograma
        nombre: $nombre
      }
      apiTokenProgramaTerceroOrder: { column: $orderBy, order: $order }
      apiTokenProgramaTerceroRange: {
        fechaCreacion: { min: $fechaDesde, max: $fechaHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        nombre
        estado
        fechaCreacion
      }
    }
  }
`;

export const getApiTokenById = gql`
  query getApiTokens($apiTokenLike: ApiTokenSearch) {
    getApiTokens(apiTokenLike: $apiTokenLike) {
      itemsPagina {
        idToken
        token
        nombre
        descripcion
        estado
        idProgramaTercero
        programaTercero {
          _id
          nombre
          estado
          fechaCreacion
          fechaUltimaModificacion
          # usuarioCreador
          # usuarioModificador
        }
        fechaVencimiento
        fechaCreacion
        fechaUltimaModificacion
        usuarioCreador {
          username
          perfil {
            nombre
            apellido
          }
        }
        usuarioModificador {
          username
          perfil {
            nombre
            apellido
          }
        }
      }
    }
  }
`;

export const resetApiTokenTercero = gql`
  query resetApiTokenTercero($apiTokenTerceroLike: ApiTokenTerceroSearch!) {
    resetApiTokenTercero(apiTokenTerceroLike: $apiTokenTerceroLike) {
      tokensResets
    }
  }
`;

export const getApiTokenTerceroUser = gql`
  query getApiTokenTerceroUser($apiTokenTerceroLike: ApiTokenTerceroSearch!) {
    getApiTokenTerceroUser(apiTokenTerceroLike: $apiTokenTerceroLike) {
      token
    }
  }
`;

export const createApiTokenTercero = gql`
  mutation createApiTokenTercero($apiTokenTercero: ApiTokenTerceroCreate!) {
    createApiTokenTercero(apiTokenTercero: $apiTokenTercero) {
      tokensCreados
    }
  }
`;

export const createApiToken = gql`
  mutation createApiToken($apiToken: ApiTokenCreate!) {
    createApiToken(apiToken: $apiToken) {
      idToken
    }
  }
`;

export const createApiTokenProgramaTercero = gql`
  mutation createApiTokenProgramaTercero(
    $apiTokenProgramaTercero: ApiTokenProgramaTerceroCreate!
  ) {
    createApiTokenProgramaTercero(
      apiTokenProgramaTercero: $apiTokenProgramaTercero
    ) {
      _id
      nombre
      estado
      idPrograma
    }
  }
`;

export const updateApiToken = gql`
  mutation updateApiToken($apiToken: ApiTokenUpdate!) {
    updateApiToken(apiToken: $apiToken) {
      idToken
    }
  }
`;

export const updateApiTokenProgramaTercero = gql`
  mutation updateApiTokenProgramaTercero(
    $apiTokenProgramaTercero: ApiTokenProgramaTerceroUpdate!
  ) {
    updateApiTokenProgramaTercero(
      apiTokenProgramaTercero: $apiTokenProgramaTercero
    ) {
      _id
      nombre
      estado
      idPrograma
    }
  }
`;

export default {
  queries: {
    getApiTokens,
    getApiTokenById,
    getApiTokensProgramasTerceros,
    getApiTokenTerceroUser,
    resetApiTokenTercero
  },
  mutations: {
    createApiToken,
    updateApiToken,
    createApiTokenProgramaTercero,
    updateApiTokenProgramaTercero,
    createApiTokenTercero
  }
};
