import React, { Fragment } from "react";
import { numberWithCommas } from "js/utils";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  InputTextarea,
  InputNumberField,
  InputTextField
} from "js/components/Shared/Form/Fields";
import ReconocimientosGraphQL from "js/graphql/resolvers/reconocimiento.resolver";
import Divider from "@material-ui/core/Divider/Divider";
import Paper from "@material-ui/core/Paper/Paper";
import UsuariosReconocidos from "js/pages/Reconocimiento/CrearReconocimiento/UsuariosReconocidos";
import moment from "moment";
import Hidden from "@material-ui/core/Hidden/Hidden";
import { AprobadoIcon, NominadoIcon } from "images/icons";
import Button from "@material-ui/core/Button/Button";
import { _t } from "js/utils/TranslationService";
import { Field } from "redux-form";
import { Select } from "redux-form-material-ui";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import FormControl from "@material-ui/core/FormControl/FormControl";
import { get } from "lodash";

export default class AprobacionReconocimiento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = values => {
    const {
      client,
      openNotification,
      computedMatch: {
        params: { idReconocimiento }
      },
      history,
      formulario
    } = this.props;
    const { observacion, mensaje, overridePuntos, overridePremio } = values;
    if (
      overridePuntos >= 0 &&
      overridePremio &&
      formulario &&
      formulario.modoPremio === "REEMPLAZO_PARCIAL" &&
      formulario.modoPuntos === "REEMPLAZO_PARCIAL"
    ) {
      const rangoPremio = formulario.matrizPremio.find(
        premio => premio.valor === overridePremio
      );
      const rangoPuntos = formulario.matrizPuntos.find(
        puntos => puntos.valor === overridePuntos
      );
      if (
        (rangoPremio.rangoInferior &&
          rangoPuntos.rangoSuperior &&
          rangoPremio.rangoInferior > rangoPuntos.rangoSuperior) ||
        (rangoPuntos.rangoInferior &&
          rangoPremio.rangoSuperior &&
          rangoPuntos.rangoInferior > rangoPremio.rangoSuperior)
      ) {
        openNotification(
          _t(
            "Los valores de premio y puntos seleccionados no pertenecen al mismo rango"
          )
        );
        return;
      }
    }
    client
      .mutate({
        mutation: ReconocimientosGraphQL.mutations.aprobarReconocimiento,
        variables: {
          mensaje,
          observacion,
          idReconocimiento,
          overridePuntos: parseInt(overridePuntos) || overridePuntos,
          overridePremio
        }
      })
      .then(({ data: { aprobarReconocimiento: { _id } } }) => {
        history.push(`/reconocido/${idReconocimiento}/aprobado`);
      })
      .catch(e => {
        openNotification(e.message.replace("GraphQL error:", "").trim());
      });
  };

  handleChange = key => select => {
    this.props.initialValues[key] = select.value;
  };

  componentWillReceiveProps = nextProps =>
    this.setState({ checked: !!nextProps.deslotear });

  render() {
    const {
      formulario = {},
      reconocimiento = {},
      classes,
      usuario,
      history,
      initialValues,
      handleSubmit
    } = this.props;
    const { username, idUsuario } = usuario ? usuario : {};
    const { nombre, apellido } =
      usuario && usuario.perfil ? usuario.perfil : {};
    const {
      aprobaciones = [],
      aprobacionesRRHH = {},
      usuarioReconocedor = {},
      usuariosReconocidos = []
    } = reconocimiento;

    function getAprobacion() {
      const aprobacionesDeUsuario = aprobaciones.filter(
        aprobacion => aprobacion.jefe.idUsuario === idUsuario
      );
      const aprobacionesPendientes = aprobacionesDeUsuario.filter(
        aprobacion => aprobacion.estado === "PENDIENTE_APROBACION"
      );
      if (aprobacionesPendientes.length) return aprobacionesPendientes[0];
      return aprobacionesDeUsuario.length
        ? aprobacionesDeUsuario[aprobacionesDeUsuario.length - 1]
        : undefined;
    }

    const aprobacion = getAprobacion();
    let overridePuntos;
    let overridePremio;
    if (aprobacion && aprobacion.orden === aprobaciones.length) {
      const respuesta = reconocimiento.respuestas
        ? reconocimiento.respuestas.find(
            respuesta => respuesta.tipo === "QUIZSCORE"
          )
        : undefined;
      let puntosSG = respuesta ? respuesta.respuesta : 0;
      const puntos =
        formulario && formulario.matrizPuntos && formulario.otorgaPuntos
          ? formulario.matrizPuntos.find(
              ({ rangoInferior, rangoSuperior }) =>
                (!rangoInferior || rangoInferior <= puntosSG) &&
                (!rangoSuperior || rangoSuperior >= puntosSG)
            )
          : {};
      overridePuntos = puntos && puntos.valor ? puntos.valor : undefined;
      initialValues.overridePuntos = overridePuntos;
      const premio =
        formulario && formulario.matrizPremio && formulario.otorgaPremio
          ? formulario.matrizPremio.find(
              ({ rangoInferior, rangoSuperior }) =>
                (!rangoInferior || rangoInferior <= puntosSG) &&
                (!rangoSuperior || rangoSuperior >= puntosSG)
            )
          : {};
      overridePremio = premio && premio.valor ? premio.valor : undefined;
      initialValues.overridePremio = overridePremio;
    }
    const aprobacionesAnteriores = aprobaciones.filter(
      apr => aprobacion && apr.orden < aprobacion.orden
    );

    const renderOtorgaPremio = ({ formulario, classes }) => {
      return this.renderOtorgaPremio(
        formulario,
        classes,
        overridePremio,
        overridePuntos
      );
    };

    const validacionRender = () => {
      let bool = false;
      if (
        aprobacionesRRHH &&
        aprobacionesRRHH.estado === "PENDIENTE_APROBACION"
      ) {
        bool = true;
      }
      if (
        aprobacion &&
        aprobacion.orden === aprobaciones.length &&
        !aprobacionesRRHH
      ) {
        bool = true;
      }
      return bool;
    };

    const filas = [
      {
        orden: get(formulario, "pregunta1.orden", 0),
        render: this.renderPregunta1
      },
      {
        orden: get(formulario, "pregunta2.orden", 1),
        render: this.renderPregunta2
      },
      {
        orden: get(formulario, "pregunta3.orden", 5),
        render: this.renderPregunta3
      },
      {
        orden: get(formulario, "mensajeNominador.orden", 2),
        render: this.renderMensajeNominador
      },
      {
        orden: get(formulario, "valor.orden", 3),
        render: this.renderValor
      },
      {
        orden: get(formulario, "tipo.orden", 4),
        render: this.renderTipo
      }
    ].sort((a, b) => a.orden - b.orden);

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <Grid
          xs={12}
          container
          style={{
            margin: "0px",
            width: "100%",
            maxWidth: "100%",
            background: "linear-gradient(to bottom, white 260px, #f9f9f9 20%)"
          }}
        >
          <Grid xs={12} item className={classes.fondoTitulo}>
            <Typography variant={"h3"} classes={{ root: classes.textoTitulo }}>
              {_t("Aprobación nominación")}
            </Typography>
            <Typography
              variant={"h3"}
              classes={{ root: classes.textoSubtitulo }}
            >
              {_t(
                "Elegí a quien o quienes querés nominar, completá los campos requeridos, y enviala para que pueda ser aprobada."
              )}
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid sm={1} item>
              <br />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            sm={10}
            container
            direction={"row"}
            justify={"center"}
            alignItems={"flex-start"}
            spacing={16}
          >
            <Grid
              sm={6}
              xs={12}
              item
              container
              direction={"row"}
              alignItems={"flex-start"}
              style={{
                paddingRight: "50px"
              }}
            >
              <Grid xs={12} item>
                <Typography classes={{ root: classes.textoHola }}>
                  ¡HOLA!
                </Typography>
              </Grid>
              <Grid xs={12} item className={classes.containerAprobador}>
                <Typography classes={{ root: classes.textoNombreAprobador }}>
                  {`Aprobador ${aprobacion ? aprobacion.orden : ""}:`}
                </Typography>
                <Typography classes={{ root: classes.textoNombreAprobador }}>
                  {nombre && apellido ? `${nombre} ${apellido}` : username}
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <AprobadoIcon
                  className={classes.iconDesplegable}
                  color={"primary"}
                  viewBox="5 5 40 40"
                />
              </Grid>
              <Grid xs={12} item className={classes.containerObservacion}>
                {formulario.conObservacion ? (
                  <Fragment>
                    <Typography
                      style={{
                        marginBottom: "5px"
                      }}
                      classes={{ root: classes.textoObservacion }}
                    >
                      {_t("OBSERVACIÓN*")}
                    </Typography>
                    <InputTextarea
                      variant={"outlined"}
                      classes={classes}
                      placeholder={_t(
                        "*Este mensaje no sera visto por el/los nominados."
                      )}
                      name="observacion"
                      multiple
                    />
                  </Fragment>
                ) : (
                  ""
                )}
              </Grid>
              {formulario.conMensajeAprobadores ? (
                <Grid xs={12} item>
                  <Typography
                    style={{
                      marginBottom: "5px"
                    }}
                    classes={{ root: classes.textoObservacion }}
                  >
                    {_t("¡DEJALE UN MENSAJE!")}
                  </Typography>
                  <InputTextarea
                    name="mensaje"
                    multiple
                    variant={"outlined"}
                    classes={classes}
                  />
                </Grid>
              ) : (
                ""
              )}
              {validacionRender()
                ? renderOtorgaPremio({ formulario, classes })
                : ""}
            </Grid>
            <Grid
              xs={12}
              sm={6}
              item
              container
              style={{
                marginBottom: "15px"
              }}
            >
              <Paper className={classes.card}>
                <Grid
                  xs={12}
                  container
                  spacing={16}
                  style={{
                    padding: "10px"
                  }}
                >
                  <Grid xs={12} item className={classes.containerFecha}>
                    <Typography classes={{ root: classes.textoFechaWrapper }}>
                      FECHA:{" "}
                      <Typography classes={{ root: classes.textoFecha }}>
                        {moment.utc().format("DD-MM-YYYY")}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    item
                    className={classes.containerNominado}
                    direction={"column-reverse"}
                  >
                    <NominadoIcon
                      className={classes.iconNominado}
                      color={"primary"}
                      htmlColor={"white"}
                      viewBox="5 10 36 36"
                    />
                  </Grid>
                  <Grid xs={12} item style={{ paddingBottom: "0px" }}>
                    <Typography color={"primary"}>{_t("NOMINADOR")}</Typography>
                  </Grid>
                  <Grid xs={12} item style={{ paddingTop: "2px" }}>
                    <Typography
                      classes={{
                        root: classes.textoNombre
                      }}
                      style={{
                        marginBottom: "10px"
                      }}
                    >
                      {usuarioReconocedor.perfil &&
                      usuarioReconocedor.perfil.nombre &&
                      usuarioReconocedor.perfil.apellido
                        ? `${usuarioReconocedor.perfil.nombre} ${
                            usuarioReconocedor.perfil.apellido
                          }`
                        : usuarioReconocedor.username}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    item
                    className={classes.containerNominados}
                    style={{
                      marginBottom: "0px",
                      paddingBottom: "0px"
                    }}
                  >
                    <Typography color={"primary"}>
                      {_t("NOMINADO/S")}
                    </Typography>
                  </Grid>
                  <Grid xs={12} item>
                    <UsuariosReconocidos
                      formulario={formulario}
                      noRemove={true}
                      usuariosNominados={usuariosReconocidos}
                      nominaEquipo={usuariosReconocidos.length > 1}
                      idUsuariosNominados={usuariosReconocidos.map(
                        usuario => usuario.idUsuario
                      )}
                      classes={classes}
                    />
                  </Grid>
                  {filas.map(fila =>
                    fila.render({
                      classes,
                      formulario,
                      reconocimiento
                    })
                  )}
                  {reconocimiento.respuestas
                    ? reconocimiento.respuestas.map(
                        ({ tipo, pregunta, respuesta, respuestas }) => {
                          return pregunta &&
                            !pregunta.startsWith("[OLD VERSION]") &&
                            tipo !== "QUIZSCORE" ? (
                            <Fragment>
                              <Grid xs={12} item>
                                <Divider classes={{ root: classes.divider }} />
                                <Typography
                                  classes={{ root: classes.pregunta }}
                                >
                                  {pregunta}
                                </Typography>
                                <Typography
                                  classes={{
                                    root: classes.respuesta
                                  }}
                                >
                                  {respuesta ? (
                                    respuesta
                                  ) : respuestas ? (
                                    <ul>
                                      {respuestas.map(respuesta => (
                                        <li>{respuesta}</li>
                                      ))}
                                    </ul>
                                  ) : (
                                    "--"
                                  )}
                                </Typography>
                              </Grid>
                            </Fragment>
                          ) : (
                            ""
                          );
                        }
                      )
                    : ""}
                </Grid>
              </Paper>
              {aprobacionesAnteriores.map(aprobacionAnterior => {
                const { username, perfil = {} } = aprobacionAnterior.jefe;
                const { nombre, apellido } = perfil;
                return (
                  <Grid
                    xs={12}
                    container
                    item
                    className={classes.containerAprobacionAnterior}
                  >
                    <Grid xs={2} item>
                      <AprobadoIcon
                        className={classes.iconDesplegable}
                        color={"primary"}
                        viewBox="5 5 40 40"
                      />
                    </Grid>
                    <Grid xs={10} item>
                      <Paper className={classes.card}>
                        <Grid xs={12} item className={classes.containerFecha}>
                          <Typography
                            classes={{ root: classes.textoFechaWrapper }}
                          >
                            FECHA:{" "}
                            <Typography classes={{ root: classes.textoFecha }}>
                              {moment
                                .utc(aprobacionAnterior.fecha)
                                .format("DD-MM-YYYY")}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          item
                          className={classes.containerAprobador}
                          style={{
                            marginTop: "15px"
                          }}
                        >
                          <Typography
                            classes={{ root: classes.textoNombreAprobador }}
                          >
                            {`Aprobador ${
                              aprobacionAnterior ? aprobacionAnterior.orden : ""
                            }:`}
                          </Typography>
                          <Typography
                            classes={{ root: classes.textoNombreAprobador }}
                          >
                            {nombre && apellido
                              ? `${nombre} ${apellido}`
                              : username}
                          </Typography>
                        </Grid>
                        {formulario.conObservacion ? (
                          <Grid xs={12} item>
                            <Divider classes={{ root: classes.divider }} />
                            <Typography
                              classes={{
                                root: classes.observacionAnteriorTitulo
                              }}
                            >
                              {_t("Observación")}
                            </Typography>
                            <Typography
                              classes={{ root: classes.observacionAnterior }}
                            >
                              {aprobacionAnterior.observacion}
                            </Typography>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {formulario.conMensajeAprobadores ? (
                          <Grid xs={12} item>
                            <Divider classes={{ root: classes.divider }} />
                            <Typography
                              classes={{
                                root: classes.observacionAnteriorTitulo
                              }}
                            >
                              {_t("¡Dejale un mensaje!")}
                            </Typography>
                            <Typography
                              classes={{ root: classes.observacionAnterior }}
                            >
                              {aprobacionAnterior.mensaje}
                            </Typography>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid
            xs={12}
            item
            className={classes.containerBotones}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid xs={3} item>
              <Button
                style={{ float: "left" }}
                variant="contained"
                color="primary"
                type="submit"
                classes={{
                  label: classes.buttonLabel
                }}
              >
                {_t("Aprobar")}
              </Button>
              <Button
                style={{ float: "right" }}
                onClick={() => history.goBack()}
                variant="contained"
                color="primary"
                classes={{
                  label: classes.buttonLabel
                }}
              >
                {_t("Volver")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }

  renderOtorgaPremio(formulario, classes, overridePremio, overridePuntos) {
    return (
      <Grid xs={12} item container>
        {formulario.otorgaPuntos ? (
          formulario.modoPuntos !== "NO_REEMPLAZO" ? (
            formulario.modoPuntos === "REEMPLAZO_PARCIAL" ? (
              <Fragment>
                <Grid xs={12} item>
                  <Typography
                    style={{
                      marginBottom: "5px"
                    }}
                    classes={{ root: classes.textoPuntos }}
                  >
                    Puntos a otorgar:
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <FormControl
                    style={{
                      width: "100%"
                    }}
                  >
                    <Field
                      name="overridePuntos"
                      component={Select}
                      required
                      className={classes.select}
                      variant={"outlined"}
                    >
                      {formulario.matrizPuntos.map(rango => (
                        <MenuItem value={rango.valor}>
                          {numberWithCommas(rango.valor, 0)}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
              </Fragment>
            ) : (
              <Fragment>
                <Grid xs={12} item>
                  <Typography
                    style={{
                      marginBottom: "5px"
                    }}
                    classes={{ root: classes.textoPuntos }}
                  >
                    {_t("Puntos a otorgar:")}
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <InputNumberField
                    variant={"outlined"}
                    classes={classes}
                    name="overridePuntos"
                  />
                </Grid>
              </Fragment>
            )
          ) : (
            <Fragment>
              <Grid xs={12} item>
                <Typography
                  style={{
                    marginBottom: "5px"
                  }}
                  classes={{ root: classes.textoPuntos }}
                >
                  {_t("Puntos a otorgar:")}
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <Typography
                  style={{
                    marginBottom: "5px",
                    color: "black"
                  }}
                  classes={{ root: classes.textoPuntos }}
                >
                  {numberWithCommas(overridePuntos, 0)}
                </Typography>
              </Grid>
            </Fragment>
          )
        ) : (
          ""
        )}
        {formulario.otorgaPremio ? (
          formulario.modoPremio !== "NO_REEMPLAZO" ? (
            formulario.modoPremio === "REEMPLAZO_PARCIAL" ? (
              <Fragment>
                <Grid xs={12} item>
                  <Typography
                    style={{
                      marginBottom: "5px"
                    }}
                    classes={{ root: classes.textoPuntos }}
                  >
                    Premio a otorgar:
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <FormControl
                    style={{
                      width: "100%"
                    }}
                  >
                    <Field
                      name="overridePremio"
                      component={Select}
                      required
                      className={classes.select}
                      variant={"outlined"}
                    >
                      {formulario.matrizPremio.map(rango => (
                        <MenuItem value={rango.valor}>{rango.valor}</MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
              </Fragment>
            ) : (
              <Fragment>
                <Grid xs={12} item>
                  <Typography
                    style={{
                      marginBottom: "5px"
                    }}
                    classes={{ root: classes.textoPuntos }}
                  >
                    Premio a otorgar:
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <InputTextField
                    variant={"outlined"}
                    classes={classes}
                    name="overridePremio"
                  />
                </Grid>
              </Fragment>
            )
          ) : (
            <Fragment>
              <Grid xs={12} item>
                <Typography
                  style={{
                    marginBottom: "5px"
                  }}
                  classes={{ root: classes.textoPuntos }}
                >
                  Premio a otorgar:
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <Typography
                  style={{
                    marginBottom: "5px",
                    color: "black"
                  }}
                  classes={{ root: classes.textoPuntos }}
                >
                  {overridePremio}
                </Typography>
              </Grid>
            </Fragment>
          )
        ) : (
          ""
        )}
      </Grid>
    );
  }

  renderMensajeNominador({ formulario, classes, reconocimiento }) {
    return formulario.mensajeNominador &&
      formulario.mensajeNominador.seMuestra ? (
      <Fragment>
        <Grid xs={12} item>
          <Divider classes={{ root: classes.divider }} />
          <Typography classes={{ root: classes.pregunta }}>
            {formulario.mensajeNominador && formulario.mensajeNominador.alias
              ? formulario.mensajeNominador.alias
              : "Mensaje del nominador"}
          </Typography>
          <Typography
            classes={{
              root: classes.respuesta
            }}
          >
            {reconocimiento.mensaje ? reconocimiento.mensaje : "--"}
          </Typography>
        </Grid>
      </Fragment>
    ) : (
      ""
    );
  }

  renderTipo({ formulario, classes, reconocimiento }) {
    return formulario.tipo && formulario.tipo.alias ? (
      <Fragment>
        <Grid xs={12} item>
          <Divider classes={{ root: classes.divider }} />
          <Typography classes={{ root: classes.pregunta }}>
            {formulario.tipo.alias}
          </Typography>
          <Typography
            classes={{
              root: classes.respuesta
            }}
          >
            {reconocimiento.tipo ? reconocimiento.tipo.join(", ") : "--"}
          </Typography>
        </Grid>
      </Fragment>
    ) : (
      ""
    );
  }

  renderValor({ formulario, classes, reconocimiento }) {
    return formulario.valor && formulario.valor.alias ? (
      <Fragment>
        <Grid xs={12} item>
          <Divider classes={{ root: classes.divider }} />
          <Typography classes={{ root: classes.pregunta }}>
            {formulario.valor.alias}
          </Typography>
          <Typography
            classes={{
              root: classes.respuesta
            }}
          >
            {reconocimiento.valor ? reconocimiento.valor.join(", ") : "--"}
          </Typography>
        </Grid>
      </Fragment>
    ) : (
      ""
    );
  }

  renderPregunta3({ classes, formulario, reconocimiento }) {
    return (
      <Grid xs={12} item>
        <Divider classes={{ root: classes.divider }} />
        <Typography classes={{ root: classes.pregunta }}>
          {formulario.pregunta3 && formulario.pregunta3.alias
            ? formulario.pregunta3.alias
            : ""}
        </Typography>
        <Typography
          classes={{
            root: classes.respuesta
          }}
        >
          {reconocimiento.respuesta3}
        </Typography>
      </Grid>
    );
  }

  renderPregunta2({ classes, formulario, reconocimiento }) {
    return (
      <Grid xs={12} item>
        <Divider classes={{ root: classes.divider }} />
        <Typography classes={{ root: classes.pregunta }}>
          {formulario.pregunta2 && formulario.pregunta2.alias
            ? formulario.pregunta2.alias
            : "¿Por qué?"}
        </Typography>
        <Typography
          classes={{
            root: classes.respuesta
          }}
        >
          {reconocimiento.respuesta2}
        </Typography>
      </Grid>
    );
  }

  renderPregunta1({ classes, formulario, reconocimiento }) {
    return (
      <Grid xs={12} item>
        <Divider classes={{ root: classes.divider }} />
        <Typography classes={{ root: classes.pregunta }}>
          {formulario.pregunta1 && formulario.pregunta1.alias
            ? formulario.pregunta1.alias
            : "¿Cuál fue la Acción, Comportamiento o Resultado destacado por la cual creés que la persona seleccionada debe ser reconocida?"}
        </Typography>
        <Typography
          classes={{
            root: classes.respuesta
          }}
        >
          {reconocimiento.respuesta1}
        </Typography>
      </Grid>
    );
  }
}
