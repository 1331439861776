import { connect } from "react-redux";
import App from "./App";
import { addTemplate, setLogInStatus, setChatOnline } from "js/redux/programa";
import {
  getTemplate,
  isAllowedToNavigate
} from "js/redux/programa/selectors.js";

const mapStateToProps = state => ({
  template: getTemplate(state),
  isAllowedToNavigate: isAllowedToNavigate(state)
});

const mapDispatchToProps = dispatch => ({
  addTemplate: addTemplate(dispatch),
  setLogInStatus: setLogInStatus(dispatch),
  setChatOnline: setChatOnline(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
