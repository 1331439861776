export default theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  viewAllLink: {
    cursor: "pointer",
    margin: "15px 10px"
  },
  viewFavLink: {
    cursor: "pointer",
    margin: "15px 10px"
  },
  selected: {
    fontWeight: 900
  }
});
