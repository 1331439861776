import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { Email } = Fragmentos;

export const getEmails = gql`
  query getHistorialEmail(
    $from: String
    $to: String
    $cc: String
    $cco: String
    $subject: String
    $fecha: Date
    $fechaDesde: Date
    $fechaHasta: Date
    $page: Int!
    $rowsPerPage: Int!
    $order: Order = asc
    $orderBy: HistorialEmailColumn = fecha
  ) {
    getHistorialEmail(
      historialEmailLike: {
        from: $from
        to: $to
        cc: $cc
        cco: $cco
        subject: $subject
        fecha: $fecha
      }
      historialEmailRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      historialEmailOrder: { column: $orderBy, order: $order }
    ) {
      ...emails
    }
  }
  ${Email.fragments.emails}
`;

export const getEmailById = gql`
  query getHistorialEmail($idEmail: ID) {
    getHistorialEmail(historialEmailLike: { _id: $idEmail }) {
      ...emails
    }
  }
  ${Email.fragments.emails}
`;

export const enviarEmail = gql`
  mutation($email: EmailToSend!) {
    enviarEmail(email: $email) {
      _id
      fecha
    }
  }
`;

export const reenviarEmail = gql`
  mutation($email: EmailToResend!) {
    reenviarEmail(email: $email) {
      _id
      fecha
    }
  }
`;

export default {
  queries: {
    getEmails,
    getEmailById
  },
  mutations: {
    enviarEmail,
    reenviarEmail
  }
};
