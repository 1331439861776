import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "redux-form";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import AppQraphQL from "js/graphql/resolvers/proveedor.resolver";
import HelpIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  list: {
    overflow: "auto",
    minHeight: 50,
    maxHeight: 270,
    backgroundColor: "white",
    paddingTop: 10
  },
  listItem: {
    "padding-left": 0
  },
  subHeader: {
    fontSize: 16,
    "padding-left": 0
  },
  tooltip: {
    "vertical-align": "middle",
    margin: "0 5px"
  }
});

class SucursalesField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sucursales: []
    };
  }

  componentWillReceiveProps(props) {
    const { idProveedor } = props;
    idProveedor && this.fetchSucursales(idProveedor);
  }

  componentDidMount = () => this.componentWillReceiveProps(this.props);

  async fetchSucursales(idProveedor) {
    const { client } = this.props;
    await client
      .query({
        query: AppQraphQL.queries.getSucursalByIdProveedor,
        variables: { idProveedor: parseInt(idProveedor, 10) },
        fetchPolicy: "cache-first",
        errorPolicy: "all"
      })
      .then(res => {
        this.setState({
          sucursales: res.data.getSucursales.itemsPagina
        });
      });
  }

  handleToggle(fields, id) {
    if (fields.length === 0 || fields.getAll().indexOf(id) === -1) {
      fields.push(id);
    } else {
      fields.remove(fields.getAll().indexOf(id));
    }
  }

  render() {
    const { disabled, classes, name } = this.props;
    const { sucursales } = this.state;

    const renderSucursales = ({ fields, meta: { error, submitFailed } }) => (
      <div className={classes.root}>
        <List
          className={classes.list}
          subheader={
            <ListSubheader className={classes.subHeader}>
              Sucursales a excluir
              <Tooltip
                className={classes.tooltip}
                title="El producto estará disponible en el área de operación del proveedor o de todas sus sucursales a excepción de aquellas seleccionadas en el siguiente listado."
              >
                <HelpIcon />
              </Tooltip>
            </ListSubheader>
          }
        >
          {sucursales.map((item, index) => (
            <ListItem
              key={item.idSucursal}
              dense
              button
              disabled={disabled}
              onClick={this.handleToggle.bind(this, fields, item.idSucursal)}
              className={classes.listItem}
            >
              <Checkbox
                checked={
                  fields.length !== 0 &&
                  fields.getAll().indexOf(item.idSucursal) !== -1
                }
                tabIndex={-1}
                disabled={disabled}
                disableRipple
              />
              <ListItemText primary={item.nombre} />
            </ListItem>
          ))}
        </List>
      </div>
    );
    return (
      <Fragment>
        <FieldArray name={name || "sucursales"} component={renderSucursales} />
      </Fragment>
    );
  }
}

SucursalesField.propTypes = {
  idProveedor: PropTypes.number,
  name: PropTypes.string
};

export default withApollo(withRouter(withStyles(styles)(SucursalesField)));
