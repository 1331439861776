import React from "react";
import { Field } from "redux-form";
import { TextField } from "redux-form-material-ui";
import { isRequired } from "js/helpers/validations";
import { _t } from "js/utils/TranslationService";

import { styles } from "../styles.js";

const InputTextField = ({
  name,
  label,
  placeholder,
  required,
  classes,
  disabled,
  translation,
  t_placeholder,
  customValidations = [],
  ...rest
}) => (
  <Field
    type="text"
    required={required}
    name={name}
    label={translation || _t(label)}
    placeholder={t_placeholder || (placeholder && _t(placeholder))}
    component={TextField}
    disabled={disabled}
    fullWidth
    validate={(() => {
      let validations = customValidations;
      if (required) {
        validations.push(isRequired);
      }
      return validations;
    })()}
    className={classes ? classes.field : styles.field}
    {...rest}
  />
);

export default InputTextField;
