import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { _t } from "js/utils/TranslationService";
import chunk from "lodash/chunk";
import Collapsable from "js/components/Shared/Collapsable";

const Glosario = ({ glosario }) => {
  const pieces = chunk(glosario, 5);
  return (
    <div style={{ margin: "16px 0" }}>
      <Collapsable withActions={false} title={_t("Glosario")}>
        <Grid container xs={12} spacing={16}>
          {pieces.map(p => (
            <Grid item container xs={12} sm={6} md={4}>
              {p.map(({ title, description }) => (
                <Grid item xs={12}>
                  <Typography variant="body2" gutterBottom>
                    {
                      <Fragment>
                        <b>{title}</b>
                        <span>{`: ${description}`}</span>
                      </Fragment>
                    }
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </Collapsable>
    </div>
  );
};

Glosario.propTypes = {
  glosario: PropTypes.object.isRequired
};

Glosario.defaultProps = {
  glosario: {}
};

Glosario.displayName = "Glosario";

export default Glosario;
