import { withStyles } from "@material-ui/core/styles";
import ReporteIndicadores from "./ReporteIndicadores";
import styles from "./styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import withPrograma from "js/hoc/withPrograma";

const enhance = compose(
  withPrograma,
  withRouter,
  withApollo,
  withStyles(styles)
);
export default enhance(ReporteIndicadores);
