import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/log.resolver";
import { viewAction } from "js/components/Shared/Tabla/ActionCells";
import advancedFilter from "js/components/TablaLog/advancedFilter.js";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";

const defaultHeader = {
  title: "Logs"
};

const columnas = [
  { id: "fecha", label: "Fecha", type: "datehour" },
  {
    id: "concepto",
    label: "Tipo",
    type: "custom",
    parser: concepto => capitalize(concepto)
  },
  {
    id: "usuario_programa_nombreFantasia",
    label: "Programa",
    removeSort: true
  },
  { id: "usuario_username", label: "Operador", removeSort: true },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={estado} />
  },
  { id: "actions", label: "Acciones" }
];

const misColumnas = [
  { id: "fecha", label: "Fecha", type: "datehour" },
  {
    id: "concepto",
    label: "Tipo",
    type: "custom",
    parser: concepto => capitalize(concepto)
  },
  { id: "usuario_username", label: "Operador", removeSort: true },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={estado} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fecha" };

class Tabla extends PureComponent {
  render() {
    const view = params => ({
      ...viewAction({
        ...params,
        history: this.props.history
      })
    });

    let isMisLogs = false;
    if (window.location.pathname.indexOf("admin") === -1) {
      isMisLogs = true;
    }

    return (
      <Table
        storage="FiltroLogs"
        query={
          isMisLogs
            ? AppQraphQL.queries.getMisLogsImportacion
            : AppQraphQL.queries.getLogsImportacion
        }
        defaultHeader={defaultHeader}
        advancedFilter={advancedFilter}
        actionsCustom={[view]}
        sort={defaultSort}
        columnData={isMisLogs ? misColumnas : columnas}
        getTipo={data =>
          isMisLogs ? data.getMisLogsImportacion : data.getLogsImportacion
        }
        path={"_id"}
      />
    );
  }
}

export default Tabla;
