export default {
  tipo: "base",
  src:
    "https://qa-img.liza.ashiwea.com/imagenes/image/3136348480773277A20190228164418459Z.png",
  link: "",
  subtexto: {
    contenido: "Subtexto",
    css: {
      fontFamily: "Roboto",
      color: "#ffffff"
    }
  },
  texto: {
    contenido: "Texto",
    css: {
      fontFamily: "Roboto",
      color: "#ffffff"
    }
  }
};
