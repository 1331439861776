export const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20
  },
  details: {
    flexDirection: "column"
  },
  column: {
    flexBasis: "33.33%"
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  style: {
    display: "flex",
    minWidth: "250px",
    alignSelf: "flex-end",
    marginRight: "15px"
  },
  styleRow: {
    flex: 1,
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "10px"
  },
  styleRowBox: {
    marginRight: "5px"
  },
  formControl: {
    width: "100%",
    marginRight: "5px"
  },
  multiSelect: {
    width: "100%",
    maxWidth: "100%",
    margin: 0
  }
});
