export default theme => ({
  expDetailsContainer: {
    display: "block"
  },
  container: {
    display: "block",
    flexDirection: "column",
    marginBottom: "20px"
  },
  performanceContainer: {
    display: "flex",
    width: "100%"
  },
  expansionTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    color: "#4D5D67",
    width: "100%"
  },
  panelSummary: {
    margin: "0 !important"
  },
  usernameTitle: {
    fontSize: "14px",
    marginBottom: "15px",
    fontWeight: "700",
    color: "#4D5D67"
  },
  campanaTitle: {
    fontSize: "20px",
    fontWeight: "700",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#4D5D67",
    width: "100%"
  },
  campanaDate: {
    fontSize: "14px",
    fontStyle: "italic",
    fontWeight: "500",
    color: "#4D5D67"
  },
  campanaEstado: {
    fontSize: "14px",
    fontStyle: "italic",
    color: "#4D5D67",
    textAlign: "right"
  },
  campanaComplete: {
    fontSize: "11px"
  },
  campanaDescripcion: {
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: "400",
    color: "#4D5D67",
    marginBottom: 0
  },
  performanceDate: {
    fontSize: "12px",
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "500",
    color: "#4D5D67"
  },
  seeMoreContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px"
  }
});
