import React from "react";
import Fila from "js/components/Fila";

const Landing = props => {
  const { programa } = props;
  return programa.template.landing.filas.map((fila, index) => {
    const widthStyle = { maxWidth: fila.fullWidth ? "100%" : "1200px" };
    return (
      <Fila
        key={`Fila-${index}-landing`}
        widthStyle={widthStyle}
        {...fila}
        filaNumero={index}
      />
    );
  });
};

Landing.displayName = "Landing";

export default Landing;
