import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaBeneficio/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/beneficio.resolver";
import { numberWithCommas } from "js/utils";
import {
  viewAction,
  editAction,
  showSucursalAction
} from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ListIcon from "@material-ui/icons/ListAlt";

const defaultHeader = (id, btnsNextTitle) => {
  return {
    title: "Beneficios",
    btnsNextTitle,
    new: {
      title: "Nuevo",
      perms: [Roles.BENEFICIO_W]
    },
    export: {
      path: "beneficios",
      perms: [Roles.BENEFICIO_R],
      tooltip: "Exportar beneficios"
    },
    import: {
      action: AppQraphQLImport.queries.importarBeneficios,
      perms: [Roles.BENEFICIO_W],
      tooltip: "Importar beneficios",
      model: "Modelo_Beneficio.csv",
      modelTooltip: "Modelo de importación de beneficios",
      variables: {
        idPrograma: parseInt(id) || id
      }
    },
    others: [
      {
        action: AppQraphQLImport.queries.importarBeneficioSegmentos,
        perms: [Roles.BENEFICIO_W],
        model: "Modelo_Beneficio_Segmentos.csv",
        tooltip: "Importar segmentos de beneficios",
        type: "import",
        variables: {
          idPrograma: parseInt(id, 10)
        },
        id: "upload-segmentos"
      },
      {
        path: "beneficios/segmentos",
        perms: [Roles.BENEFICIO_R],
        type: "export",
        tooltip: "Exportar segmentos de beneficios"
      },
      {
        action: AppQraphQLImport.queries.importarBeneficioUbicaciones,
        perms: [Roles.BENEFICIO_W],
        model: "Modelo_Beneficio_Ubicaciones.csv",
        tooltip: "Importar ubicaciones de beneficios",
        type: "import",
        variables: {
          idPrograma: parseInt(id, 10)
        },
        id: "upload-ubicacion"
      },
      {
        path: "beneficios/ubicaciones",
        perms: [Roles.BENEFICIO_R],
        type: "export",
        tooltip: "Exportar ubicaciones de beneficios"
      },
      {
        action: AppQraphQLImport.queries.importarBeneficioRubros,
        perms: [Roles.BENEFICIO_W],
        model: "Modelo_Beneficio_Categorías.csv",
        tooltip: "Importar categorías de beneficios",
        type: "import",
        variables: {
          idPrograma: parseInt(id, 10)
        },
        id: "upload-categoria"
      },
      {
        path: "beneficios/categorias",
        perms: [Roles.BENEFICIO_R],
        type: "export",
        tooltip: "Exportar categorias de beneficios"
      }
    ]
  };
};
const columnData = [
  { id: "idBeneficio", label: "ID", isNumeric: true },
  { id: "nombre", label: "Nombre" },
  { id: "idTipo_tipo", label: "Tipo" },
  {
    id: "segmentos",
    label: "Segmento",
    type: "array",
    field: "nombre",
    removeSort: true
  },
  {
    id: "rubros",
    label: "Rubro",
    type: "array",
    field: "descripcion",
    removeSort: true
  },
  {
    id: "paises",
    label: "Pais",
    type: "array",
    field: "descripcion",
    removeSort: true
  },
  { id: "emailProveedor", label: "Email Proveedor" },
  {
    id: "stock",
    isNumeric: true,
    label: "Stock",
    type: "custom",
    parser: (stock, beneficio) => {
      if (beneficio.stockInfinito) {
        return <span style={{ fontSize: "1.75rem" }}>∞</span>;
      } else {
        return numberWithCommas(stock);
      }
    }
  },
  { id: "fecha", label: "Fecha Vencimiento", type: "date" },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "asc", orderBy: "nombre" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    const view = params => ({
      ...viewAction({
        ...params,
        history: this.props.history,
        perms: [Roles.BENEFICIO_R]
      })
    });
    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.BENEFICIO_W]
      })
    });
    const sucursal = params => ({
      ...showSucursalAction({
        ...params,
        history: this.props.history,
        perms: [Roles.SOLICITUD_BENEFICIO_R]
      }),
      onclick: () =>
        this.props.history.push(
          `${window.location.pathname}/${params.itemId}/sucursales-beneficio`
        )
    });

    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Solicitudes">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(
                `/admin/programa/${id}/solicitudes-beneficios`
              )
            }
          >
            <ListIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.SOLICITUD_BENEFICIO_R]
    });

    return (
      <Table
        storage={"FiltroBeneficioAdmin"}
        query={AppQraphQL.queries.getPaginaBeneficios}
        defaultHeader={defaultHeader(id, btnsNextTitle)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getBeneficios}
        actionsCustom={[view, edit, sucursal]}
        pathId={"idBeneficio"}
      />
    );
  }
}

export default Tabla;
