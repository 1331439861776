import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Form from "js/pages/CargadeCodigos/Edit/MyForm.js";
import AppGraphQL from "js/graphql/resolvers/cargadeCodigos.resolver";
import BannerGraphQL from "js/graphql/resolvers/banner.resolver";
import AuthService from "js/utils/AuthService";
import { updateUserPoints } from "js/redux/user/user.actions";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    let id = null;
    let inBackOffice = false;
    let idVenta = this.props.match.params.idVenta;
    if (window.location.pathname.indexOf("admin") !== -1) {
      id = this.props.match.params.id;
      inBackOffice = true;
    } else {
      id = parseInt(AuthService.getIdPrograma());
      inBackOffice = false;
    }
    if (!idVenta) {
      idVenta = this.props.computedMatch.params.idVenta;
    }

    this.state = {
      disabled: false,
      errors: null,
      id,
      idVenta,
      isMisVentas: window.location.pathname.indexOf("mis") !== -1,
      initialValues: {},
      open: false,
      inBackOffice,
      producto: null,
      codigos: null,
      codigosSinProducto: false,
      bannerImages: null
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { id } = this.state;

    await client
      .query({
        query: BannerGraphQL.queries.getBannerById,
        fetchPolicy: "network-only",
        variables: {
          bannerLike: {
            idPrograma: id
          }
        }
      })
      .then(res => {
        const banner = res.data.getBanner.itemsPagina;

        if (banner.length == 0) {
          this.setState({
            bannerImages: null
          });
        } else if (banner[0].imagenes.length > 0) {
          this.setState({
            bannerImages: banner[0].imagenes[0]
          });
        } else {
          this.setState({
            bannerImages: null
          });
        }
      });
  }

  async submit(values) {
    const { client, history } = this.props;
    const { id, inBackOffice } = this.state;
    const codigosVenta = [];

    codigosVenta.push({ codigo: values.codigo });
    await client
      .mutate({
        mutation: AppGraphQL.mutations.createVentaCargadeCodigos,
        variables: {
          venta: {
            codigos: codigosVenta,
            idPrograma: id,
            idUsuario: parseInt(AuthService.getIdUsuario())
          }
        }
      })
      .then(res => {
        const mensaje = "¡Código validado!";
        const { updateUserPoints } = this.props;

        this.props.openNotification(mensaje);
        history.push(
          inBackOffice
            ? `/admin/programa/${id}/carga-de-codigos`
            : `/mis-movimientos`
        );
        updateUserPoints(true);
      })
      .catch(errors => {
        let arr = [];
        let codigosSinProducto = false;
        arr.push({ message: "Error inesperado" });
        if (
          errors.graphQLErrors &&
          errors.graphQLErrors[0].message === "CODIGO_UTILIZADO"
        ) {
          errors.graphQLErrors[0].message = "El codigo ya fue utilizado.";
          codigosSinProducto = true;
        }
        if (
          errors.graphQLErrors &&
          errors.graphQLErrors[0].message === "CODIGO_INACTIVO"
        ) {
          errors.graphQLErrors[0].message = "El codigo no está activado.";
          codigosSinProducto = true;
        }
        if (
          errors.graphQLErrors &&
          errors.graphQLErrors[0].message === "CODIGOS_SIN_PRODUCTO"
        ) {
          errors.graphQLErrors[0].message =
            "Los códigos no están asociados a ningún producto.";
          codigosSinProducto = true;
        }

        if (values.codigoQR && values.codigoBarra) {
          codigosSinProducto = true;
        }

        this.setState({
          errors:
            errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr,
          codigosSinProducto
        });
        return false;
      });
  }

  resetErrors() {
    this.setState({ errors: null });
  }

  render() {
    return (
      <React.Fragment>
        <Form
          errors={this.state.errors}
          disabled={this.state.disabled}
          onSubmit={this.submit.bind(this)}
          isMisVentas={this.state.isMisVentas}
          initialValues={this.state.initialValues}
          idPrograma={this.state.id}
          idVenta={this.state.idVenta}
          producto={this.state.producto}
          codigosSinProducto={this.state.codigosSinProducto}
          resetErrors={this.resetErrors.bind(this)}
          bannerImages={this.state.bannerImages}
        />
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  updateUserPoints: updatePoints => dispatch(updateUserPoints(updatePoints))
});

export default connect(
  null,
  mapDispatchToProps
)(Edit);
