import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DescriptionIcon from "@material-ui/icons/Description";
import BusinessIcon from "@material-ui/icons/Business";
import TrendingUp from "@material-ui/icons/TrendingUp";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import WalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import StoreIcon from "@material-ui/icons/Store";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ReceiptIcon from "@material-ui/icons/Receipt";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import HomeIcon from "@material-ui/icons/Home";
import UserIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";

import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

import styles from "./styles";

const Links = [
  [
    {
      link: "/admin/programa",
      texto: "Programas",
      icon: <BusinessIcon />,
      perms: [],
      optional: [Roles.PROGRAMA_R, Roles.PROGRAMA_LISTA]
    },
    {
      link: "/admin/cuenta_corriente",
      texto: "CC Programas",
      icon: <WalletIcon />,
      perms: [Roles.MOVIMIENTO_PROGRAMA_R]
    },
    {
      link: "/admin/usuario",
      texto: "Usuarios",
      icon: <UserIcon />,
      perms: [Roles.USUARIO_R, Roles.PROGRAMA_LISTA]
    },
    {
      link: "/admin/cc-usuarios",
      texto: "CC Usuarios",
      icon: <WalletIcon />,
      perms: [Roles.MOVIMIENTO_USUARIO_R, Roles.PROGRAMA_LISTA]
    },
    {
      link: "/admin/proveedor",
      texto: "Proveedores",
      icon: <WalletOutlinedIcon />,
      perms: [Roles.PROVEEDOR_R]
    },
    {
      link: "/admin/producto",
      texto: "Productos",
      icon: <StoreIcon />,
      perms: [Roles.PRODUCTO_R]
    }
  ],
  [
    {
      link: "/admin/canjes",
      texto: "Canjes",
      icon: <LocalShippingIcon />,
      perms: [Roles.CANJE_R]
    },
    {
      link: "/admin/lotes",
      texto: "Lotes",
      icon: <DescriptionIcon />,
      perms: [Roles.LOTE_R]
    },
    {
      link: "/admin/facturas",
      texto: "Facturas",
      icon: <ReceiptIcon />,
      perms: [Roles.FACTURA_R]
    }
  ],
  [
    {
      link: "/admin/acciones",
      texto: "Acciones",
      icon: <LocalActivityIcon />,
      perms: [Roles.ACCION_R]
    }
  ],
  [
    {
      link: "/admin/emails",
      texto: "Emails",
      icon: <EmailIcon />,
      perms: [Roles.HISTORIAL_EMAIL_R]
    },
    {
      link: "/admin/reportes",
      texto: "Reportes",
      icon: <TrendingUp />,
      perms: [Roles.REPORTE_R]
    },
    {
      link: "/admin/cln/reportes",
      texto: "Club La Nación",
      icon: <TrendingUp />,
      perms: [Roles.CLN_REPORTE_R]
    },
    {
      link: "/admin/logs",
      texto: "Logs",
      icon: <Icon color="action">list</Icon>,
      perms: [Roles.LOG_R]
    },
    {
      link: "/admin/idiomas",
      texto: "Idiomas",
      icon: <Icon color="action">language</Icon>,
      perms: [Roles.IDIOMA_R]
    },
    {
      link: "/admin/canje-terceros",
      texto: "Canje Terceros",
      icon: <TrendingUp />,
      perms: [Roles.CANJE_TERCEROS_W]
    }
  ]
];

class MainPrograma extends Component {
  // shouldComponentUpdate = () => true;
  render() {
    const { classes, closeMenu, showTooltip } = this.props;

    return (
      <Fragment>
        {Links.map(group => (
          <Fragment key={group[0].texto}>
            {group.map((link, i) => {
              return (
                (!link.perms.length || AuthService.hasPerms(link.perms)) &&
                (!link.optional ||
                  !link.optional.lenght ||
                  AuthService.hasAnyPerm(link.optional)) && (
                  <Tooltip
                    key={i}
                    title={link.texto}
                    interactive
                    classes={{
                      tooltip: !!showTooltip ? classes.tooltip : classes.hide,
                      tooltipPlacementBottom: classes.tooltipPlacementBottom,
                      tooltipPlacementRight: classes.tooltipPlacementRight
                    }}
                    placement="right"
                  >
                    <ListItem
                      className={classes.listItem}
                      button
                      component={NavLink}
                      activeClassName={classes.active}
                      to={link.link}
                      key={link.link}
                      onClick={closeMenu}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        {link.icon}
                      </ListItemIcon>
                      <ListItemText
                        className={classes.listItemText}
                        primary={link.texto}
                      />
                    </ListItem>
                  </Tooltip>
                )
              );
            })}
            <Divider />
          </Fragment>
        ))}
        <Tooltip
          classes={{
            tooltip: !!showTooltip ? classes.tooltip : classes.hide,
            tooltipPlacementBottom: classes.tooltipPlacementBottom,
            tooltipPlacementRight: classes.tooltipPlacementRight
          }}
          interactive
          placement="right"
          title="UI"
        >
          <ListItem
            button
            onClick={() => window.location.replace("/")}
            className={classes.listItem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="UI" />
          </ListItem>
        </Tooltip>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MainPrograma);
