import gql from "graphql-tag";

export const getGruposProductosCampana = gql`
  query getGruposProductosCampana(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: GrupoProductosCampanaColumn!
    $idPrograma: Int
    $nombre: String
  ) {
    getGruposProductosCampana(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      grupoProductosOrder: { column: $orderBy, order: $order }
      grupoProductosLike: { idPrograma: $idPrograma, nombre: $nombre }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        idGrupo
        nombre
        fechaCreacion
        productos {
          codigo
          nombre
        }
        usuarioCreador {
          perfil {
            nombre
            apellido
          }
        }
      }
    }
  }
`;

export const getGrupoProductosById = gql`
  query getGruposProductosCampana(
    $grupoProductosLike: GrupoProductosCampanaSearch
  ) {
    getGruposProductosCampana(grupoProductosLike: $grupoProductosLike) {
      itemsPagina {
        _id
        idGrupo
        nombre
        productos {
          codigo
          nombre
        }
      }
    }
  }
`;

export const getGruposProductosInput = gql`
  query getGruposProductosCampana(
    $grupoProductosLike: GrupoProductosCampanaSearch
  ) {
    getGruposProductosCampana(grupoProductosLike: $grupoProductosLike) {
      itemsPagina {
        idGrupo
        nombre
        productos {
          codigo
        }
      }
    }
  }
`;

export const importarGrupoProductosCampana = gql`
  mutation importarGrupoProductosCampana(
    $file: Upload
    $idPrograma: Int!
    $nombre: String!
    $idGrupo: Int
  ) {
    importGrupoProductosCampana(
      file: $file
      idPrograma: $idPrograma
      nombre: $nombre
      idGrupo: $idGrupo
    ) {
      productos {
        codigo
        nombre
      }
      nombre
    }
  }
`;

export default {
  queries: {
    getGruposProductosCampana,
    getGrupoProductosById,
    getGruposProductosInput
  },
  mutations: {
    importarGrupoProductosCampana
  }
};
