import React, { Children } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { _t } from "js/utils/TranslationService";
import Enhancer from "./container";

const Section = ({ children, classes, title }) => (
  <Grid container className={classes.Container}>
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom className={classes.Title}>
        {_t(title)}
      </Typography>
    </Grid>
    {Children.map(children, (child, i) => (
      <Grid key={i} item xs={12} className={classes.Row}>
        {child}
      </Grid>
    ))}
  </Grid>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string
};

Section.defaultProps = {
  title: ""
};

Section.displayName = "Section";

export default Enhancer(Section);
