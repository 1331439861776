import UserTypes from "./user.types";

const userReducer = (state = null, action) => {
  switch (action.type) {
    case UserTypes.UPDATE_USER_POINTS:
      return {
        ...state,
        updateUserPoints: action.updatePoints
      };
    default:
      return state;
  }
};

export default userReducer;
