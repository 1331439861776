import React from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import UsuarioQraphQL from "js/graphql/resolvers/usuario.resolver";
import AuthService from "js/utils/AuthService";

function AdminRoles(BaseComponent, rolesPermitidos = [], rolesOpcionales = []) {
  const Autenticar = class extends React.PureComponent {
    componentDidMount() {
      const { client } = this.props;
      client
        .query({
          query: UsuarioQraphQL.queries.isLoggedIn,
          fetchPolicy: "no-cache",
          errorPolicy: "all"
        })
        .then(res => {
          if (!res.data.isLoggedIn) {
            AuthService.logout({ redirect: "admin" });
          }
        });
    }

    render() {
      // const { history } = this.props;
      if (
        (!rolesPermitidos.length || AuthService.hasPerms(rolesPermitidos)) &&
        (!rolesOpcionales.length || AuthService.hasAnyPerm(rolesOpcionales))
      ) {
        return <BaseComponent {...this.props} />;
      }
      // history.goBack();
      return true;
    }
  };

  return withRouter(withApollo(Autenticar));
}

export default AdminRoles;
