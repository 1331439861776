export default theme => ({
  root: {
    boxShadow: "none",
    border: "none",
    "&:before": {
      display: "none"
    },
    borderBottom: "1px solid rgba(0,0,0,.125)"
  },
  panelRoot: {
    padding: 0
  },
  expandIcon: {
    padding: 0
  }
});
