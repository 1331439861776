import React from "react";
// import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";

const Menu = props => {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
};

export default Menu;
