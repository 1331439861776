export const CAMBIAR_CANTIDAD = "CAMBIAR_CANTIDAD";
export const DECREMENTAR_CANTIDAD = "DECREMENTAR_CANTIDAD";
export const INCREMENTAR_CANTIDAD = "INCREMENTAR_CANTIDAD";
export const SOLICITAR = "SOLICITAR";
export const SOLICITAR_FAIL = "SOLICITAR_FAIL";
export const SOLICITAR_SUCCESS = "SOLICITAR_SUCCESS";
export const CHANGE_SUCURSAL = "CHANGE_SUCURSAL";
export const CLEAN_BENEFICIO_STATE = "CLEAN_BENEFICIO_STATE";
export const CLOSE_DIALOG_SOLICITUD = "CLOSE_DIALOG_SOLICITUD";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const LOAD_BENEFICIO_STATE = "LOAD_BENEFICIO_STATE";
export const OPEN_DIALOG_SOLICITUD = "OPEN_DIALOG_SOLICITUD";
export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const TOGGLE_FAVORITO = "TOGGLE_FAVORITO";
export const TOGGLE_FAVORITO_ERROR = "TOGGLE_FAVORITO_ERROR";
export const TOGGLE_FAVORITO_SUCCESS = "TOGGLE_FAVORITO_SUCCESS";
export const UPDATE_STORE_DATOS_PERFIL = "UPDATE_STORE_DATOS_PERFIL";
export const UPDATE_USER_POINTS = "UPDATE_USER_POINTS";
