import CampanaTypes from "./campana.types";

const campanaReducer = (state = null, action) => {
  switch (action.type) {
    case CampanaTypes.SET_CAMPANAS:
      return {
        ...state,
        campanas: action.campanas
      };
    case CampanaTypes.SET_CAMPANA_TARGETS:
      return {
        ...state,
        targets: action.targets
      };
    case CampanaTypes.SET_GRUPOS_SKUS:
      return {
        ...state,
        gruposSKU: action.grupos
      };
    default:
      return state;
  }
};

export default campanaReducer;
