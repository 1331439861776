import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loading = ({ classes }) => {
  return (
    <div className={classes.loading}>
      <CircularProgress thickness={7} color="primary" variant="indeterminate" />
    </div>
  );
};

Loading.propTypes = {
  classes: PropTypes.object
};

Loading.defaultProps = {};

Loading.displayName = "Loading";

export default Loading;
