import Tabla from "./tabla";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withNotificationContext } from "js/notification-context";
import { withApollo } from "react-apollo";

const enhance = compose(
  withNotificationContext,
  withRouter,
  withApollo
);

export default enhance(Tabla);
