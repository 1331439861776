import { compose, pure } from "recompose";
import withStyles from "./styles";
import { withNotificationContext } from "js/notification-context";

const enhance = compose(
  pure,
  withStyles,
  withNotificationContext
);

export default enhance;
