import { pick, find } from "lodash";
import { removeTypename } from "js/utils/Helper";
import { TIPO_ACCION } from "../constants";
const pickValues = values =>
  pick(values, [
    "_id",
    "nombre",
    "descripcion",
    "estado",
    "fechaInicio",
    "fechaFin",
    "direccionamientoObligatorio",
    "idTargetSegmento",
    "idTargetPrograma",
    "desafioProgramaSegmento",
    "puntosParticipar",
    "puntosAcreditar",
    "acreditaAccionPuntos",
    "ganarAccionPuntos",
    "ganarAccionCriterio",
    "tipo",
    "mailResultado",
    "fechaHoraResultado",
    "modoMailResultado",
    "mailInvitacionAccion",
    "fechaHoraRecordatorio",
    "mailRecordatorio",
    "tieneRanking",
    "permiteMultipleParticipacion",
    "matematicaRanking",
    "conceptoPuntosParticipar",
    "alturaIframe",
    "definicionesIncluidas"
  ]);

export const getAccion = (
  values,
  { estado: initialEstado, fechaHoraRecordatorio }
) => {
  let accion = pickValues(values);
  accion.desafioProgramaSegmento = accion.idTargetSegmento
    ? "SEGMENTO"
    : "PROGRAMA";
  const tipo = find(TIPO_ACCION, ["value", accion.tipo]).form;
  accion[tipo] = values[tipo];
  if (accion.idTargetSegmento) delete accion.idTargetPrograma;
  accion = removeTypename(accion);
  if (["BORRADOR", "LISTA"].indexOf(initialEstado) < 0)
    delete accion.fechaInicio;
  if (
    initialEstado === "ACTIVA" &&
    fechaHoraRecordatorio === accion.fechaHoraRecordatorio
  )
    delete accion.fechaHoraRecordatorio;
  ["mailInvitacionAccion", "mailResultado", "mailRecordatorio"].forEach(k => {
    if (accion[k] && (!accion[k].html || !accion[k].asunto)) delete accion[k];
  });
  return accion;
};
