export default theme => ({
  root: {
    padding: "2rem"
  },
  card: {
    maxWidth: 345
  },
  centerGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  media: {
    height: 140,
    backgroundSize: "contain"
  },
  link: {
    textDecoration: "none"
  },
  btn: {
    width: "200px",
    padding: "20px"
  }
});
