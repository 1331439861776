import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import AuthService from "js/utils/AuthService";
import base from "js/components/Shared/Tabla/ActionCells/Base";
import DialogImportGrupoProductos from "js/pages/Admin/Programa/Campana/Dialogs/DialogImportGrupoProductos";

export default params => ({
  ...base(),
  label: "Editar Grupo",
  icon: <EditIcon />,
  tooltip: "Editar Grupo",
  dialogId: "EDIT_GRUPOPRODUCTOS",
  show: () => AuthService.hasPerms(params.perms),
  dialog: (
    <DialogImportGrupoProductos
      handleClose={params.togglePopUp}
      onTableChange={params.onTableChange}
      idGrupo={params.itemId}
    />
  )
});
