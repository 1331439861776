import gql from "graphql-tag";

export const getVariablesMed = gql`
  query getVariablesMed(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: VariablesMedColumn!
    $idPrograma: Int
    $codigo: String
    $idCampana: ID
    $estado: EstadoVariablesMed
    $estadoCampana: EstadoCampanaOP
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getVariablesMed(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      variablesMedLike: {
        idPrograma: $idPrograma
        codigo: $codigo
        idCampana: $idCampana
        estado: $estado
        estadoCampana: $estadoCampana
      }
      variablesMedOrder: { column: $orderBy, order: $order }
      variablesMedRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        med {
          nombre
          codigo
        }
        campana {
          _id
          nombre
        }
        fechaLimiteEdd
        fechaLimiteSupervisores
        ajusteEdd
        ajusteEddMinMax {
          min
          max
        }
        estado
        fechaCreacion
        targetsObjetivos {
          objetivo
          target
        }
      }
    }
  }
`;

export const getVariablesMiMed = gql`
  query getVariablesMiMed(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: VariablesMedColumn!
    $idPrograma: Int
    $codigo: String
    $idCampana: ID
    $estado: EstadoVariablesMed
    $estadoCampana: EstadoCampanaOP
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getVariablesMiMed(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      variablesMedLike: {
        idPrograma: $idPrograma
        codigo: $codigo
        idCampana: $idCampana
        estado: $estado
        estadoCampana: $estadoCampana
      }
      variablesMedOrder: { column: $orderBy, order: $order }
      variablesMedRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        med {
          nombre
          codigo
        }
        campana {
          _id
          nombre
        }
        fechaLimiteEdd
        fechaLimiteSupervisores
        ajusteEdd
        ajusteEddMinMax {
          min
          max
        }
        estado
        fechaCreacion
        targetsObjetivos {
          objetivo
          target
        }
      }
    }
  }
`;

export const getVariablesMedByIdCampana = gql`
  query getVariablesMed($codigo: String, $idPrograma: Int, $idCampana: ID) {
    getVariablesMed(
      variablesMedLike: {
        codigo: $codigo
        idPrograma: $idPrograma
        idCampana: $idCampana
      }
    ) {
      itemsPagina {
        med {
          _id
          nombre
          codigo
        }
        campana {
          _id
          nombre
          objetivos {
            _id
            objetivo
          }
        }
        fechaLimiteEdd
        fechaLimiteSupervisores
        ajusteEdd
        ajusteEddMinMax {
          min
          max
        }
        estado
        targetsObjetivos {
          idObjetivo
          objetivo
          target
          targetOriginal
        }
      }
    }
  }
`;

export const getVariablesMiMedByIdCampana = gql`
  query getVariablesMiMed($codigo: String, $idPrograma: Int, $idCampana: ID) {
    getVariablesMiMed(
      variablesMedLike: {
        codigo: $codigo
        idPrograma: $idPrograma
        idCampana: $idCampana
      }
    ) {
      itemsPagina {
        med {
          _id
          nombre
          codigo
        }
        campana {
          _id
          nombre
          objetivos {
            _id
            objetivo
          }
        }
        fechaLimiteEdd
        fechaLimiteSupervisores
        ajusteEdd
        ajusteEddMinMax {
          min
          max
        }
        estado
        targetsObjetivos {
          idObjetivo
          objetivo
          target
          targetOriginal
        }
      }
    }
  }
`;

export const createVariablesMed = gql`
  mutation createVariablesMed($variablesMed: VariablesMedCreate!) {
    createVariablesMed(variablesMed: $variablesMed) {
      estado
    }
  }
`;

export const updateVariablesMed = gql`
  mutation updateVariablesMed($variablesMed: VariablesMedUpdate!) {
    updateVariablesMed(variablesMed: $variablesMed) {
      estado
    }
  }
`;

export const aprobarVariablesMeds = gql`
  mutation aprobarVariablesMeds($idsVariablesMed: [ID]) {
    aprobarVariablesMeds(idsVariablesMed: $idsVariablesMed)
  }
`;

export default {
  queries: {
    getVariablesMed,
    getVariablesMiMed,
    getVariablesMedByIdCampana,
    getVariablesMiMedByIdCampana
  },
  mutations: {
    createVariablesMed,
    updateVariablesMed,
    aprobarVariablesMeds
  }
};
