import React, { PureComponent } from "react";
import AppQraphQL from "js/graphql/resolvers/notificacion.resolver";
import WysiwygPanel from "js/pages/Admin/Programa/Legales/WysiwygPanel/index.js";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import { InputMultiEmail } from "js/components/Shared/Form/Fields";
import Variables from "js/pages/Unilever/Med/Notificaciones/Edit/variables";
import Button from "@material-ui/core/Button";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        nombre: "",
        asunto: "",
        puntos: "",
        estado: null,
        tempalate: "",
        cc: [],
        cco: []
      },
      id: this.props.match.params.id,
      idNotificacion: this.props.match.params.idNotificacion
    };
  }

  getNotificaciones() {
    const { client } = this.props;
    const {
      match: {
        params: { idNotificacion }
      }
    } = this.props;
    return client
      .query({
        query: AppQraphQL.queries.getNotificacionById,
        fetchPolicy: "network-only",
        variables: {
          idNotificacion
        }
      })
      .then(res => {
        if (res.data.getNotificaciones.length) {
          let data = res.data.getNotificaciones[0];
          try {
            data.cc = data.cc || [];
            data.cco = data.cco || [];
          } catch (e) {}
          this.setState({ initialValues: data });
        }
      });
  }

  async componentDidMount() {
    await this.getNotificaciones();
  }

  onChange(e) {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        [e.target.name]: e.target.value
      }
    });
  }

  onChangeSwitch(e) {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        [e.target.name]: !e.target.checked ? "INACTIVO" : "ACTIVO"
      }
    });
  }

  onSaveAction = async () => {
    const {
      client,
      history,
      match: {
        params: { idNotificacion }
      }
    } = this.props;

    const { initialValues, id } = this.state;
    await client
      .mutate({
        mutation: AppQraphQL.mutations.updateNotificacion,
        variables: {
          notificacion: {
            idNotificacion: parseInt(idNotificacion, 10),
            estado: initialValues.estado,
            asunto: initialValues.asunto,
            puntos: initialValues.puntos || undefined,
            cc: initialValues.cc || [],
            cco: initialValues.cco || [],
            template: initialValues.template
          }
        }
      })
      .then(res => {
        this.props.openNotification("Actualizado correctamente");
        history.push(`/admin/programa/${id}/meds/notificaciones`);
      })
      .catch(errors => {
        let e = errors.graphQLErrors.length
          ? errors.graphQLErrors[0].message
          : "Error inesperado";
        this.props.openNotification(e);
        return false;
      });
  };

  render() {
    const { classes, history } = this.props;
    const { initialValues, id } = this.state;

    return (
      <div className={classes.root}>
        <Grid container direction="row" justify="space-between" spacing={16}>
          <Grid item md={8}>
            <Grid container spacing={8}>
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  {initialValues.nombre}
                </Typography>
                <FormControl className={classes.formControlSwitch}>
                  <FormLabel>Activo</FormLabel>
                  <Switch
                    id="estado"
                    name="estado"
                    onChange={e => this.onChangeSwitch(e)}
                    checked={initialValues.estado === "ACTIVO" ? true : false}
                    color="primary"
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    key="asunto"
                    name="asunto"
                    label="Asunto del email"
                    type="search"
                    value={initialValues.asunto}
                    onChange={e => this.onChange(e)}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    key="puntos"
                    name="puntos"
                    label="Puntos a acreditar"
                    type="search"
                    value={initialValues.puntos}
                    onChange={e => this.onChange(e)}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputMultiEmail
                    name={"cc"}
                    value={initialValues.cc}
                    onChange={e => this.onChange(e)}
                    label={"CC"}
                    placeholder={"CC"}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputMultiEmail
                    name={"cco"}
                    value={initialValues.cco}
                    onChange={e => this.onChange(e)}
                    label={"CCO"}
                    placeholder={"CCO"}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <div className={classes.wysiwygPanelContainer}>
              <Typography
                variant="subtitle2"
                gutterBottom
                className={classes.subtitle}
              >
                Contenido del template
              </Typography>
              <WysiwygPanel
                initialValue={initialValues.template}
                inputName="template"
                onChange={e => this.onChange(e)}
              />
            </div>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button
                type="button"
                variant="contained"
                className={classes.button}
                onClick={() =>
                  history.push(`/admin/programa/${id}/meds/notificaciones`)
                }
              >
                Cancelar
              </Button>
              <Button
                onClick={this.onSaveAction}
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Hidden only={["xs", "sm"]}>
              <Variables />
            </Hidden>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Edit;
