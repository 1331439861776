import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { CatalogoCodigos } = Fragmentos;

export const getPaginaCatalogoCodigos = gql`
  query getCatalogoCodigos(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: CatalogoCodigosColumn!
    $idPrograma: Int
    $idBeneficio: Int
    $nombre: String
    $codigo: String
    $monto: Float
    $idTipo: ID
    $estado: Estado
    $idSegmento: Int
    $idRubro: Int
    $idSubrubro: Int
    $idPais: Int
    $idZona: Int
    $idLocalidad: Int
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getCatalogoCodigos(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      beneficioOrder: { column: $orderBy, order: $order }
      beneficioLike: {
        idBeneficio: $idBeneficio
        idPrograma: $idPrograma
        nombre: $nombre
        codigo: $codigo
        monto: $monto
        idTipo: $idTipo
        estado: $estado
        idSegmento: $idSegmento
        idRubro: $idRubro
        idSubrubro: $idSubrubro
        idPais: $idPais
        idZona: $idZona
        idLocalidad: $idLocalidad
      }
      beneficioRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...catalogoCodigos
      }
    }
  }
  ${CatalogoCodigos.fragments.catalogoCodigos}
`;

export const getCatalogoCodigosById = gql`
  query getCatalogoCodigosById($idBeneficio: Int, $idPrograma: Int) {
    getCatalogoCodigos: getCatalogoCodigos(
      beneficioLike: { idBeneficio: $idBeneficio }
    ) {
      itemsPagina {
        ...catalogoCodigos
      }
    }
    getPaises(paisLike: { idPrograma: $idPrograma, estado: ACTIVO }) {
      idPais
      label: descripcion
      value: idPais
      estado
      children: zonas {
        idZona
        label: descripcion
        value: idZona
        estado
        children: localidades {
          idLocalidad
          label: descripcion
          value: idLocalidad
          estado
        }
      }
    }
    getRubros(rubroLike: { idPrograma: $idPrograma, estado: ACTIVO }) {
      idRubro
      label: descripcion
      value: idRubro
      estado
      children: subrubros {
        idSubrubro
        label: descripcion
        value: idSubrubro
        estado
      }
    }
    getSegmentos(segmentoLike: { idPrograma: $idPrograma, estado: ACTIVO }) {
      idSegmento
      label: nombre
      value: idSegmento
      estado
    }
  }
  ${CatalogoCodigos.fragments.catalogoCodigos}
`;

export const getCatalogoCodigosInput = gql`
  query getCatalogoCodigos($idPrograma: Int) {
    getCatalogoCodigos(beneficioLike: { idPrograma: $idPrograma }) {
      itemsPagina {
        value: idBeneficio
        label: nombre
      }
    }
  }
`;

export const createCatalogoCodigos = gql`
  mutation createCatalogoCodigos($beneficio: CatalogoCodigosCreate!) {
    createCatalogoCodigos(beneficio: $beneficio) {
      idBeneficio
    }
  }
`;

export const updateCatalogoCodigos = gql`
  mutation updateCatalogoCodigos($beneficio: CatalogoCodigosUpdate!) {
    updateCatalogoCodigos(beneficio: $beneficio) {
      idBeneficio
    }
  }
`;

export const getCatalogoCodigosTipos = gql`
  query getCatalogoCodigosTipos {
    getCatalogoCodigosTipos {
      value: _id
      label: tipo
      descripcion
    }
  }
`;

export const createCatalogoCodigosTipo = gql`
  mutation createCatalogoCodigosTipo($tipo: CatalogoCodigosTipoCreate!) {
    createCatalogoCodigosTipo(tipo: $tipo) {
      _id
      tipo
      descripcion
    }
  }
`;

export const getCatalogoCodigosSucursales = gql`
  query getCatalogoCodigosSucursales(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: BeneficioSucursalColumn!
    $idBeneficio: Int
    $idSucursal: Int
    $nombre: String
    $estado: Estado
    $idPais: Int
    $idZona: Int
    $idLocalidad: Int
    $direccion: String
  ) {
    getCatalogoCodigosSucursales(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      sucursalOrder: { column: $orderBy, order: $order }
      sucursalLike: {
        idSucursal: $idSucursal
        idBeneficio: $idBeneficio
        nombre: $nombre
        estado: $estado
        idPais: $idPais
        idZona: $idZona
        idLocalidad: $idLocalidad
        direccion: $direccion
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...sucursal
      }
    }
  }
  ${CatalogoCodigos.fragments.sucursal}
`;

export const getSucursalCatalogoCodigosById = gql`
  query getSucursalCatalogoCodigosById($idSucursal: Int) {
    getCatalogoCodigosSucursales(sucursalLike: { idSucursal: $idSucursal }) {
      itemsPagina {
        ...sucursal
      }
    }
  }
  ${CatalogoCodigos.fragments.sucursal}
`;

export const createCatalogoCodigosSucursal = gql`
  mutation createCatalogoCodigosSucursal(
    $sucursal: CatalogoCodigosSucursalCreate!
  ) {
    createCatalogoCodigosSucursal(sucursal: $sucursal) {
      idSucursal
    }
  }
`;

export const updateCatalogoCodigosSucursal = gql`
  mutation updateCatalogoCodigosSucursal(
    $sucursal: CatalogoCodigosSucursalUpdate!
  ) {
    updateCatalogoCodigosSucursal(sucursal: $sucursal) {
      idSucursal
    }
  }
`;

export const getSucursalesBeneficioInput = gql`
  query getCatalogoCodigosSucursales(
    $sucursalLike: CatalogoCodigosSucursalSearch
  ) {
    getCatalogoCodigosSucursales(sucursalLike: $sucursalLike) {
      itemsPagina {
        value: _id
        label: nombre
      }
    }
  }
`;

export const getCatalogodeCatalogoCodigos = gql`
  query getCatalogodeCatalogoCodigos(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: CatalogoCodigosColumn!
    $beneficioLike: codigosCatalogoSearch
  ) {
    getCatalogodeCatalogoCodigos(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      beneficioOrder: { column: $orderBy, order: $order }
      beneficioLike: $beneficioLike
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...catalogoCodigos
      }
    }
  }
  ${CatalogoCodigos.fragments.catalogoCodigos}
`;

export const getCatalogoCatalogoCodigosInput = gql`
  query getCatalogodeCatalogoCodigos($idPrograma: Int) {
    getCatalogodeCatalogoCodigos(beneficioLike: { idPrograma: $idPrograma }) {
      itemsPagina {
        value: idBeneficio
        label: nombre
      }
    }
  }
`;

export const getCatalogoCatalogoCodigosById = gql`
  query getCatalogodeCatalogoCodigos($beneficioLike: codigosCatalogoSearch) {
    getCatalogodeCatalogoCodigos(beneficioLike: $beneficioLike) {
      itemsPagina {
        ...catalogoCodigos
      }
    }
  }
  ${CatalogoCodigos.fragments.catalogoCodigos}
`;

export const getCatalogoCodigosFilters = gql`
  query getCatalogoCodigosFilters($idPrograma: Int, $idSegmento: Int) {
    getCatalogoCodigosFilters(
      idPrograma: $idPrograma
      idSegmento: $idSegmento
    ) {
      rubros {
        idRubro
        descripcion
        rubroCantidad
        subrubros {
          idSubrubro
          descripcion
          subrubroCantidad
        }
      }
      zonas {
        idZona
        descripcion
        zonaCantidad
        localidades {
          idLocalidad
          descripcion
          localidadCantidad
        }
      }
    }
  }
`;

export const toggleFavoritoCatalogoCodigos = gql`
  mutation toggleFavoritoCatalogoCodigos($idBeneficio: Int!) {
    toggleFavoritoCatalogoCodigos(idBeneficio: $idBeneficio)
  }
`;

export const solicitarCatalogoCodigos = gql`
  mutation solicitarCatalogoCodigos(
    $solicitud: CatalogoCodigosSolicitudCreate!
  ) {
    solicitarCatalogoCodigos(solicitud: $solicitud) {
      idSolicitud
    }
  }
`;

export const getCatalogoCodigosSolicitudes = gql`
  query getCatalogoCodigosSolicitudes(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: CatalogoCodigosSolicitudColumn!
    $idPrograma: Int
    $idBeneficio: Int
    $idTipo: ID
    $idUsuario: Int
    $fechaDesde: Date
    $fechaHasta: Date
  ) {
    getCatalogoCodigosSolicitudes(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      solicitudOrder: { column: $orderBy, order: $order }
      solicitudRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
      solicitudLike: {
        idPrograma: $idPrograma
        idBeneficio: $idBeneficio
        idTipo: $idTipo
        idUsuario: $idUsuario
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...solicitud
      }
    }
  }
  ${CatalogoCodigos.fragments.solicitud}
`;

export const getMisCatalogoCodigosSolicitudes = gql`
  query getMisCatalogoCodigosSolicitudes(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: CatalogoCodigosSolicitudColumn!
    $idBeneficio: Int
    $idTipo: ID
    $fechaDesde: Date
    $fechaHasta: Date
  ) {
    getMisCatalogoCodigosSolicitudes(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      solicitudOrder: { column: $orderBy, order: $order }
      solicitudRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
      solicitudLike: { idBeneficio: $idBeneficio, idTipo: $idTipo }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...solicitud
      }
    }
  }
  ${CatalogoCodigos.fragments.solicitud}
`;

export const getCatalogoCodigosSolicitudById = gql`
  query getCatalogoCodigosSolicitudById($idSolicitud: Int) {
    getMisCatalogoCodigosSolicitudes(
      solicitudLike: { idSolicitud: $idSolicitud }
    ) {
      itemsPagina {
        ...solicitud
      }
    }
  }
  ${CatalogoCodigos.fragments.solicitud}
`;

export default {
  queries: {
    getPaginaCatalogoCodigos,
    getCatalogoCodigosById,
    getCatalogoCodigosInput,
    getCatalogoCodigosTipos,
    getCatalogoCodigosSucursales,
    getSucursalCatalogoCodigosById,
    getSucursalesBeneficioInput,
    getCatalogodeCatalogoCodigos,
    getCatalogoCatalogoCodigosInput,
    getCatalogoCatalogoCodigosById,
    getCatalogoCodigosFilters,
    getCatalogoCodigosSolicitudes,
    getMisCatalogoCodigosSolicitudes,
    getCatalogoCodigosSolicitudById
  },
  mutations: {
    createCatalogoCodigos,
    updateCatalogoCodigos,
    createCatalogoCodigosTipo,
    createCatalogoCodigosSucursal,
    updateCatalogoCodigosSucursal,
    toggleFavoritoCatalogoCodigos,
    solicitarCatalogoCodigos
  }
};
