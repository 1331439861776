import React, { PureComponent, Fragment } from "react";
import { Query } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppQraphQL from "js/graphql/resolvers/rol.resolver";

import { styles } from "js/components/Shared/Form/styles.js";
import { required } from "js/components/Shared/Form/validations.js";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { ReduxTypeAhead } from "js/components/Shared/Form/Fields";
class Rol extends PureComponent {
  render() {
    const { classes, disabled, idPrograma } = this.props;
    return (
      <Query
        query={AppQraphQL.queries.getRoles}
        variables={{
          idPrograma
        }}
        fetchPolicy="network-only"
        errorPolicy="all"
      >
        {({ loading, error, data }) => {
          if (loading || !data) return null;
          const hasPerms = AuthService.hasPerms([Roles.ASIGNAR_ROL_USUARIO]);
          const sug = (data.getRoles || []).filter(
            i => hasPerms || i.nombre === "USUARIO_FINAL"
          );
          return (
            <Fragment>
              <ReduxTypeAhead
                className={classes ? classes.field : styles.field}
                name="idRol"
                suggestions={{ sug }}
                path={{ path: "sug", value: "idRol", label: "nombre" }}
                disabled={!idPrograma || disabled}
                label={"Rol del usuario"}
                placeholder={"Rol del usuario"}
                validate={required}
              />
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(withStyles(styles)(Rol));
