import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";

class advancedFilter extends PureComponent {
  render() {
    const { value, submitValue, classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"nombre"}
              name={"nombre"}
              label={"Nombre idioma"}
              type="search"
              value={value["nombre"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"nombrePadre"}
              name={"nombrePadre"}
              label={"Padre"}
              type="search"
              value={value["nombrePadre"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
