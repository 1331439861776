import { _t } from "js/utils/TranslationService";
import { Add, Delete, Remove } from "@material-ui/icons";
import {
  Button,
  Fab,
  Grid,
  IconButton,
  TextField,
  Typography,
  Tooltip
} from "@material-ui/core";
import { DialogDatosRequeridos, ProgressBar } from "./components";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import CanjeQraphQL from "js/graphql/resolvers/canje.resolver";
import CatalogoGraphQL from "js/graphql/resolvers/catalogo.resolver";
import client from "../../App/client.graphql.js";
import LabeledAutocomplete from "js/components/Shared/Form/Fields/LabeledAutocomplete";
import React from "react";
import UsuariosGraphQL from "js/graphql/resolvers/usuario.resolver";

class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cantidad: 1,
      datosRequeridosCompleted: true,
      datosRequeridosProducto: [],
      datosRequeridosProductoValues: [],
      eleccionProducto: null,
      eleccionProductoSuggestions: [],
      isLoadingEleccionProducto: false,
      isLoadingUsuarios: false,
      isCanjeando: false,
      openDialog: false,
      programa: null,
      programaSuggestions: [],
      usuario: null,
      usuariosSuggestions: []
    };
  }

  componentDidMount = async () => {
    const data = await client.query({
      query: AppQraphQL.queries.getPaginaProgramas,
      variables: {
        page: 1,
        rowsPerPage: 999,
        order: "asc",
        orderBy: "nombre",
        estado: "ACTIVO"
      },
      errorPolicy: "all",
      fetchPolicy: "cache-first"
    });

    if (data) {
      const {
        data: {
          getProgramas: { itemsPagina }
        }
      } = data;

      const programaSuggestions = itemsPagina.map(item => ({
        label: item.nombre,
        idPrograma: item.idPrograma
      }));

      this.setState({
        programaSuggestions
      });
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.programa && prevState.programa !== this.state.programa) {
      this.setState({
        usuario: null,
        eleccionProducto: null,
        isLoadingUsuarios: true
      });
      const usuariosSuggestions = await this.fetchUsuariosPrograma();
      this.setState({ usuariosSuggestions, isLoadingUsuarios: false });
    }

    if (prevState.usuario !== this.state.usuario) {
      if (this.state.usuario) {
        this.setState({
          eleccionProducto: null,
          isLoadingEleccionProducto: true
        });
        const eleccionProductoSuggestions = await this.fetchProductosPrograma();
        this.setState({
          eleccionProductoSuggestions,
          isLoadingEleccionProducto: false
        });
      }
    }

    if (
      this.state.eleccionProducto &&
      prevState.eleccionProducto !== this.state.eleccionProducto
    ) {
      let {
        eleccionProducto: { eleccionProducto }
      } = this.state;

      let datosRequeridosProducto = eleccionProducto.producto.datosRequeridos
        ? eleccionProducto.producto.datosRequeridos
        : null;
      let datosRequeridosProductoValues = {};
      let datosRequeridosCompleted = true;

      if (datosRequeridosProducto) {
        datosRequeridosProducto = this.moveToEnd(
          datosRequeridosProducto,
          "entrega"
        );

        if (datosRequeridosProducto.length > 0) {
          datosRequeridosCompleted = false;
          datosRequeridosProductoValues = datosRequeridosProducto.reduce(
            (acc, dato) => {
              acc[dato.key] =
                dato.key !== "entrega"
                  ? null
                  : JSON.stringify({
                      direccion: "",
                      cp: "",
                      idPais: "",
                      idZona: "",
                      idLocalidad: ""
                    });
              return acc;
            },
            {}
          );
        }
      }

      this.setState({
        datosRequeridosProducto,
        datosRequeridosProductoValues,
        datosRequeridosCompleted
      });
    }

    if (isNaN(this.state.cantidad)) {
      this.setState({ cantidad: 1 });
    }
  }

  moveToEnd = (arr, key) => {
    const index = arr.findIndex(item => item.key === key);

    if (index !== -1) {
      const [key] = arr.splice(index, 1);
      arr.push(key);
    }

    return arr;
  };

  generateLabel = item => {
    let label = "";
    let { __typename: type } = item;
    switch (type) {
      case "Usuario":
        let nombre = item.perfil.nombre ? item.perfil.nombre.trim() : "-";
        let apellido = item.perfil.apellido ? item.perfil.apellido.trim() : "-";
        let username = item.username ? item.username.trim() : "-";
        let balance = item.cuentaCorriente.balance
          ? item.cuentaCorriente.balance
          : 0;
        label = `${apellido} ${nombre} (${username}) | ${balance}`.toUpperCase();
        break;
      case "Catalogo":
        let nombreProducto = item.producto.nombre
          ? item.producto.nombre.trim()
          : "-";
        let puntos = item.puntos ? item.puntos : "-";
        label = `${nombreProducto} | ${puntos}`;
        break;
    }
    return label;
  };

  fetchUsuariosPrograma = async () => {
    const {
      programa: { idPrograma }
    } = this.state;
    let usuariosSuggestions = [];

    const data = await client.query({
      query: UsuariosGraphQL.queries.getPaginaUsuarios,
      variables: {
        page: 1,
        rowsPerPage: 99999,
        order: "asc",
        orderBy: "nombre",
        idPrograma,
        estado: "ACTIVO"
      },
      errorPolicy: "all",
      fetchPolicy: "cache-first"
    });

    if (data) {
      const {
        data: {
          getUsuarios: { itemsPagina }
        }
      } = data;
      usuariosSuggestions = itemsPagina.map(item => {
        let label = this.generateLabel(item);
        return {
          label,
          usuario: item
        };
      });
    }

    return usuariosSuggestions;
  };

  fetchProductosPrograma = async () => {
    const {
      programa: { idPrograma }
    } = this.state;
    const { usuario } = this.state;
    let eleccionProductoSuggestions = [];

    if (idPrograma && usuario) {
      const {
        usuario: { idUsuario }
      } = usuario;

      const data = await client.query({
        query: CatalogoGraphQL.queries.getCatalogo,
        fetchPolicy: "network-only",
        variables: {
          catalogoLike: {
            idPrograma,
            idUsuario
          },
          showDetails: true,
          pagina: { cantidadPorPagina: 9999, numeroPagina: 1 }
        }
      });

      if (data) {
        const {
          data: {
            getCatalogo: { itemsPagina }
          }
        } = data;
        eleccionProductoSuggestions = itemsPagina.map(item => {
          let label = this.generateLabel(item);
          return {
            label,
            eleccionProducto: item
          };
        });
      }
    }
    return eleccionProductoSuggestions;
  };

  handleChange = name => value => {
    this.setState({
      [name]: value
    });
  };

  handleChangeDatoRequerido = key => event => {
    const { datosRequeridosProductoValues } = this.state;

    let value = null;

    if (key !== "entrega") {
      value = event.target.value;
    } else {
      value = event;
    }

    this.setState({
      datosRequeridosProductoValues: {
        ...datosRequeridosProductoValues,
        [key]: value
      }
    });
  };

  setDatosRequeridosCompleted = () => {
    this.setState({ datosRequeridosCompleted: true });
    return this.props.openNotification("Datos requeridos completos");
  };

  incrementarCantidad = () => {
    this.setState({ cantidad: this.state.cantidad + 1 });
  };

  decrementarCantidad = () => {
    if (this.state.cantidad > 1) {
      this.setState({ cantidad: this.state.cantidad - 1 });
    }
  };

  cambiarCantidad = name => event => {
    this.setState({ [name]: parseInt(event.target.value) });
  };

  handleCanje = async () => {
    const {
      eleccionProducto,
      cantidad,
      datosRequeridosProductoValues,
      usuario
    } = this.state;

    this.setState({ isCanjeando: true });

    const { observaciones } = datosRequeridosProductoValues;
    delete datosRequeridosProductoValues.observaciones;

    let canje = {
      idEleccionProducto: eleccionProducto.eleccionProducto.idEleccionProducto,
      cantidad: cantidad,
      observaciones: observaciones ? observaciones : "",
      datosDeCanje: {
        ...datosRequeridosProductoValues
      },
      idUsuario: usuario.usuario.idUsuario,
      isCanjeTerceros: true
    };
    try {
      const res = await client.mutate({
        mutation: CanjeQraphQL.mutations.canjearProducto,
        errorPolicy: "all",
        variables: { canje }
      });

      const { data, errors } = res;

      if (data.canjearProducto) {
        this.props.openNotification("¡Todo listo! El canje fue exitoso.");
        this.refreshUsuariosData();
      }

      if (errors) {
        this.props.openNotification(
          "No se pudo completar el canje del producto."
        );

        errors.forEach(error => {
          setTimeout(() => {
            this.props.openNotification(error.message);
          }, 2000);
        });
      }
    } catch (error) {
      let errorMessage = "No se pudo completar el canje del producto.";
      let errorDetail = null;

      if (error.message) {
        errorDetail = error.message
          .replace("GraphQL error: ", "")
          .toLowerCase();
      }
      errorMessage = errorDetail ? errorMessage + errorDetail : errorMessage;
      this.props.openNotification(errorMessage);
    } finally {
      this.handleCloseDialog();
      this.setState({ isCanjeando: false });
    }
  };

  refreshUsuariosData = () => {
    const {
      usuario,
      eleccionProducto,
      cantidad,
      usuariosSuggestions
    } = this.state;

    let { usuario: usuarioInside } = usuario;
    let usuariosSuggestionsUpdated = [...usuariosSuggestions];

    usuarioInside.cuentaCorriente.balance =
      usuarioInside.cuentaCorriente.balance -
      eleccionProducto.eleccionProducto.puntos * cantidad;

    let usuarioUpdated = {
      label: this.generateLabel(usuarioInside),
      usuario: usuarioInside
    };

    let indexUsuarioToUpdate = usuariosSuggestionsUpdated.findIndex(
      item => item.usuario.idUsuario === usuarioInside.idUsuario
    );

    usuariosSuggestionsUpdated[indexUsuarioToUpdate] = usuarioUpdated;

    this.setState({
      usuario: usuarioUpdated,
      usuariosSuggestions: usuariosSuggestionsUpdated
    });
  };

  handleOpenDialog = () => {
    this.setState({
      openDialog: true
    });
  };

  handleCloseDialog = value => {
    this.setState({ openDialog: false });
  };

  clearValues = () => {
    this.setState({
      cantidad: 1,
      datosRequeridosCompleted: true,
      datosRequeridosProducto: [],
      datosRequeridosProductoValues: [],
      eleccionProducto: null,
      eleccionProductoSuggestions: [],
      isCanjeando: false,
      isLoadingEleccionProducto: false,
      isLoadingUsuarios: false,
      openDialog: false,
      programa: null,
      usuario: null,
      usuariosSuggestions: []
    });
  };

  render() {
    const {
      classes,
      match: {
        params: { id }
      },
      search
    } = this.props;

    const {
      cantidad,
      datosRequeridosProducto,
      datosRequeridosProductoValues,
      eleccionProducto: eleccionProductoSelected,
      eleccionProductoSuggestions,
      programa,
      programaSuggestions,
      openDialog,
      usuario,
      usuariosSuggestions,
      isLoadingUsuarios,
      isLoadingEleccionProducto,
      isCanjeando
    } = this.state;

    const eleccionProducto = eleccionProductoSelected
      ? eleccionProductoSelected.eleccionProducto
      : null;

    const idProgramaDefault = 1;

    return (
      <>
        <Grid container xs={12} style={{ marginBottom: "2%" }}>
          <Typography variant="h4" style={{ marginTop: "0.25%" }}>
            Canjes a Terceros
          </Typography>
          <Tooltip title="Limpiar campos" aria-label="Delete-tooltip">
            <IconButton
              aria-label="Delete"
              className={classes.margin}
              onClick={this.clearValues}
            >
              <Delete fontSize="medium" color="primary" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid container spacing={16} style={{ padding: "0 0 0 1%" }}>
          <Grid item xs={12}>
            <Grid item xs={12} md={4}>
              <LabeledAutocomplete
                label={"Programas"}
                isDisabled={false}
                onChange={this.handleChange("programa")}
                noOptionsMessage={() => "No hay programas"}
                placeholder="Buscar por programa"
                suggestions={programaSuggestions}
                value={programa}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12} md={4}>
              {isLoadingUsuarios ? (
                <ProgressBar />
              ) : (
                <LabeledAutocomplete
                  label={"Usuarios"}
                  isDisabled={programa === null ? true : false}
                  onChange={this.handleChange("usuario")}
                  noOptionsMessage={() => "No hay usuarios"}
                  placeholder="Buscar por usuario"
                  suggestions={usuariosSuggestions}
                  value={usuario}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12} md={4}>
              {isLoadingEleccionProducto ? (
                <ProgressBar />
              ) : (
                <LabeledAutocomplete
                  label={"Productos"}
                  isDisabled={
                    programa === null || usuario === null ? true : false
                  }
                  onChange={this.handleChange("eleccionProducto")}
                  noOptionsMessage={() => "No hay productos"}
                  placeholder="Buscar por producto"
                  suggestions={eleccionProductoSuggestions}
                  value={eleccionProductoSelected}
                />
              )}
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={12} style={{ margin: "0 0 0 0" }}>
              <Typography
                variant="button"
                gutterBottom
                style={{ margin: "10px 0 0 0.5%" }}
              >
                {"Cantidad"}
              </Typography>
            </Grid>
            <Grid container xs={12} md={4} style={{ margin: "0 0 0 0.3%" }}>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  value={cantidad}
                  className={classes.counter}
                  name="cantidad"
                  disabled={eleccionProductoSelected === null ? true : false}
                  onChange={this.cambiarCantidad("cantidad")}
                  style={{ margin: "6% 0 0 1%" }}
                />
              </Grid>
              <Grid item xs={2}>
                <Fab
                  aria-label="Incrementar"
                  onClick={this.incrementarCantidad}
                  color="primary"
                  size="large"
                  disabled={eleccionProductoSelected === null ? true : false}
                >
                  <Add fontSize="small" />
                </Fab>
              </Grid>
              <Grid item xs={2}>
                <Fab
                  aria-label="Decrementar"
                  onClick={this.decrementarCantidad}
                  color="primary"
                  size="large"
                  disabled={eleccionProductoSelected === null ? true : false}
                >
                  <Remove fontSize="small" />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            md={8}
            style={{ margin: "3% 0 0 0" }}
            justify="flex-end"
          >
            <Grid container xs={12} md={2} justify="flex-end">
              <Button
                type="submit"
                onClick={this.handleOpenDialog}
                variant="contained"
                color="primary"
                size="large"
                disabled={eleccionProductoSelected === null ? true : false}
              >
                Canjear
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <DialogDatosRequeridos
          classes={classes}
          datosRequeridosFields={datosRequeridosProducto}
          datosRequeridosValues={datosRequeridosProductoValues}
          handleChangeDatoRequerido={this.handleChangeDatoRequerido}
          onClose={this.handleCloseDialog}
          openDialog={openDialog}
          setDatosRequeridosCompleted={this.setDatosRequeridosCompleted}
          eleccionProducto={eleccionProducto ? eleccionProducto : null}
          cantidad={cantidad}
          onCanje={this.handleCanje}
          statusCanje={isCanjeando}
          idPrograma={idProgramaDefault}
        />
      </>
    );
  }
}

export default MyForm;
