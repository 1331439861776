import ReconocimientosAprobados from "./ReconocimientosAprobados";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withNotificationContext } from "js/notification-context";
import styles from "./styles";
import { withStyles } from "@material-ui/core";

const enhance = compose(
  withNotificationContext,
  withRouter,
  withStyles(styles)
);

export default enhance(ReconocimientosAprobados);
