import React from "react";
import Grid from "@material-ui/core/Grid";
import Title from "./Title";

const Formulario = ({
  accion: {
    _id,
    tipo,
    tieneRanking,
    formulario: { urlReporte, urlEdicion }
  }
}) => {
  const onEdit = () => window.open(urlEdicion);
  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Title
          tipo={tipo}
          onClick={onEdit}
          clipboard={`/formulario/${_id}`}
          rankingUrl={`/ranking/${_id}`}
          tieneRanking={tieneRanking}
        />
      </Grid>
      {urlReporte && (
        <Grid item xs={12}>
          <iframe src={urlReporte} height="800" width="100%" title={_id} />;
        </Grid>
      )}
    </Grid>
  );
};
Formulario.defaultProps = { accion: { formulario: {} } };
Formulario.displayName = "FORMULARIO";

export default Formulario;
