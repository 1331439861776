import React from "react";
import PropTypes from "prop-types";
import defaultStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Fila from "js/components/Fila";
import { get } from "lodash";
import { setJivoChat } from "js/utils/Helper";

const Access = props => {
  const { classes, component: Component, programa, chatOnline } = props;
  const { access } = programa.template;
  const filaArriba = get(access, "signin.arriba", {});
  const filaAbajo = get(access, "signin.abajo", {});
  const widthStyleFilaArriba = {
    maxWidth: filaArriba.fullWidth ? "100%" : "1200px",
    width: filaArriba.fullWidth ? "100%" : "auto"
  };
  const widthStyleFilaAbajo = {
    maxWidth: filaAbajo.fullWidth ? "100%" : "1200px",
    width: filaAbajo.fullWidth ? "100%" : "auto"
  };

  // Si el chatOnline tiene activado el acceso sin login se agrega el chat desde acá
  if (chatOnline.chatSinLogin) {
    setJivoChat(programa.modulos, chatOnline);
  }

  return (
    <Grid className={classes.body} container direction={"row"}>
      {filaArriba.visible ? (
        <Grid xs={12} item container justify="center">
          <Fila
            key={`Fila-arriba-signin`}
            widthStyle={widthStyleFilaArriba}
            {...filaArriba}
            overwriteContenidoCss={{
              width: "auto"
            }}
            filaNumero={0}
          />
        </Grid>
      ) : (
        ""
      )}
      <Grid
        container
        direction="column"
        justify="center"
        className={classes.container}
      >
        <Component
          updateTemplate={props.updateTemplate}
          logo={() => (
            <Grid
              container
              direction="column"
              alignItems="center"
              className={classes.imgContainer}
            >
              <img
                src={`${access.imagenPrincipal}`}
                className={classes.signinLogo}
                alt="logo"
              />
              <div className={classes.signinLogo2} />
            </Grid>
          )}
        />
      </Grid>
      {filaAbajo.visible ? (
        <Grid xs={12} item container justify="center">
          <Fila
            key={`Fila-abajo-signin`}
            widthStyle={widthStyleFilaAbajo}
            {...filaAbajo}
            filaNumero={1}
          />
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};

Access.propTypes = {
  programa: PropTypes.object,
  classes: PropTypes.object
};

Access.defaultProps = {
  programa: {
    template: {}
  },
  classes: defaultStyles
};

const AccessWithStyles = withStyles(styles)(Access);

export default props => (
  <MuiThemeProvider theme={props.theme}>
    <AccessWithStyles {...props} />
  </MuiThemeProvider>
);
