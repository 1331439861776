import Settings from "./settings.fragments.js";
import Accion from "./accion.fragments.js";
import Segmento from "./segmento.fragments.js";
import Template from "./template.fragments.js";
import Usuarios from "./usuarios.fragments.js";
import Productos from "./productos.fragments.js";
import Catalogo from "./catalogo.fragments.js";
import Canje from "./canje.fragments.js";
import CuentaCorriente from "./cuentaCorriente.fragments.js";
import Movimientos from "./movimientos.fragments.js";
import Programa from "./programa.fragments.js";
import Facturas from "./facturas.fragments.js";
import Lotes from "./lotes.fragments.js";
import Idioma from "./idioma.fragments.js";
import Email from "./email.fragments.js";
import CLN from "./clubLaNacion.fragments.js";
import Beneficio from "./beneficio.fragments.js";
import CatalogoCodigos from "./catalogoCodigos.fragment.js";
import Log from "./log.fragments.js";
import Med from "./medUnilever.fragments.js";

export default {
  Settings,
  Accion,
  Segmento,
  Template,
  Usuarios,
  Productos,
  Canje,
  CuentaCorriente,
  Catalogo,
  Movimientos,
  Programa,
  Facturas,
  Lotes,
  Idioma,
  Email,
  CLN,
  Beneficio,
  CatalogoCodigos,
  Log,
  Med
};
