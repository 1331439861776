export default theme => ({
  tooltip: {
    fontSize: "0.85em",
    maxWidth: "100%",
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.primary.main
  },
  tooltipPlacementBottom: {
    margin: "-0.5rem 0"
  },
  popper: {
    opacity: 1
  }
});
