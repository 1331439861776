import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  Title: {
    marginTop: "0.70em",
    fontWeight: 600,
    ".Subsection &:first-child": {
      marginTop: 0
    }
  },
  Wrapper: {
    paddingLeft: "10px",
    marginBottom: "10px"
  },
  outlined: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 4,
    padding: 5
  },
  normal: {}
});

export default withStyles(styles);
