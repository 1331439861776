import React from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import PermService from "js/utils/PermService";
import { ALLOWED_ALWAYS } from "js/models/Permiso";

function AllowToNavigate(BaseComponent, permisos) {
  const Autorizar = class extends React.PureComponent {
    state = { allowed: PermService.isAllowed(permisos) };

    componentWillMount = () =>
      document.addEventListener("redirectByPermisos", () =>
        this.setState({
          allowed: PermService.isAllowed(permisos)
        })
      );

    componentWillReceiveProps = nextProps => {
      const {
        computedMatch: {
          params: { idAccion }
        }
      } = this.props;
      const {
        computedMatch: {
          params: { idAccion: nextIdAccion }
        }
      } = nextProps;
      if (nextIdAccion && idAccion !== nextIdAccion)
        PermService.addAccion(nextIdAccion);
    };

    render() {
      const { allowed } = this.state;
      const { history } = this.props;
      if (
        allowed !== true &&
        window.location.pathname !== allowed &&
        ALLOWED_ALWAYS.indexOf(window.location.pathname) === -1
      )
        history.replace(allowed);
      return <BaseComponent {...this.props} />;
    }
  };
  return withRouter(withApollo(Autorizar));
}

export default AllowToNavigate;
