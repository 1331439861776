import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import ProveedorGraphQL from "js/graphql/resolvers/proveedor.resolver.js";
import { getSelectOptions } from "js/utils";
import { ESTADO } from "./constants";
import moment from "moment";

import { updateQueryParamsAsync, initialState } from "../utils";

class advancedFilter extends Component {
  state = {
    ...initialState
  };

  updateQueryParams = key => event =>
    this.setState(
      updateQueryParamsAsync({ key, value: event.target.value }),
      this.props.submitValue(event)
    );

  updateAutocompleteQueryParams = key => select => {
    this.setState(
      updateQueryParamsAsync({ key, value: select.value }),
      this.props.submitValue({ target: { name: key, value: select.value } })
    );
  };

  render() {
    const { value, submitValue, classes } = this.props;
    return (
      <Query
        query={ProveedorGraphQL.queries.getProveedoresAutocomplete}
        errorPolicy="all"
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          return (
            <Grid container spacing={16}>
              <Grid item xs={12} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    key={"numeroLote"}
                    name={"numeroLote"}
                    label={"Lote"}
                    type="search"
                    value={value["numeroLote"] || ""}
                    onChange={e => submitValue(e)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="estado-simple" id="estado-simple">
                    Estado
                  </InputLabel>
                  <Select
                    value={value["estadoLote"] || ""}
                    onChange={e => submitValue(e)}
                    inputProps={{
                      name: "estadoLote",
                      id: "estadoLote"
                    }}
                  >
                    {getSelectOptions(ESTADO, "Todas")}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl className={classes.formControl}>
                  <DatePicker
                    label="Desde"
                    dateFormat="DD/MM/YYYY"
                    input={{
                      onChange: date =>
                        submitValue({
                          target: {
                            name: "fechaDesde",
                            value: date.toISOString()
                          }
                        }),
                      value: value.fechaDesde || null
                    }}
                    autoOk
                    startOfDate
                    maxDate={moment()}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl className={classes.formControl}>
                  <DatePicker
                    label="Hasta"
                    dateFormat="DD/MM/YYYY"
                    input={{
                      onChange: date =>
                        submitValue({
                          target: {
                            name: "fechaHasta",
                            value: date.toISOString()
                          }
                        }),
                      value: value.fechaHasta || null
                    }}
                    autoOk
                    endOfDate
                    maxDate={moment()}
                  />
                </FormControl>
              </Grid>
            </Grid>
          );
        }}
      </Query>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
