export default theme => ({
  root: {
    flexGrow: 1
  },
  factura: {
    marginBottom: 30
  },
  button: {
    margin: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 2}px`
  },
  actionButtons: {
    textAlign: "right",
    width: "100%"
  }
});
