import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AuthService from "js/utils/AuthService";
import { ApolloConsumer } from "react-apollo";
import { _t } from "js/utils/TranslationService";
import { USER_ROLES as Roles } from "js/models/User";

class UserMenu extends React.Component {
  logout() {
    this.props.setLogInStatus(false);
    AuthService.logout({ history: this.props.history });
  }
  render() {
    const { classes, anchorEl, open, history, close, programa } = this.props;

    const moduloPuntos = programa.modulos
      ? programa.modulos.find(
          modulo =>
            modulo.nombreGrupo === "Gestión Puntos" &&
            modulo.estado === "ACTIVO"
        )
      : true;

    const HasPerms = AuthService.hasPerms([Roles.MIS_DATOS_MOVIMIENTOS_R]);

    const moduloBeneficios = programa.modulos
      ? programa.modulos.find(
          modulo =>
            modulo.nombreGrupo === "Beneficios con cupón" &&
            modulo.estado === "ACTIVO"
        )
      : true;

    return (
      <ApolloConsumer>
        {client => (
          <Menu
            className={classes.userMenu}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={open}
            onClose={close}
          >
            <MenuItem
              onClick={() => {
                history.push("/profile");
                close();
                return;
              }}
            >
              {_t("Mi perfil")}
            </MenuItem>
            {moduloPuntos && HasPerms ? (
              <MenuItem
                onClick={() => {
                  history.push("/mis-movimientos");
                  close();
                  return;
                }}
              >
                {_t("Mis movimientos")}
              </MenuItem>
            ) : (
              ""
            )}
            {moduloBeneficios ? (
              <MenuItem
                onClick={() => {
                  history.push("/mis-solicitudes");
                  close();
                  return;
                }}
              >
                {_t("Mis solicitudes")}
              </MenuItem>
            ) : (
              ""
            )}
            <MenuItem onClick={() => this.logout()}>
              {_t("Cerrar sesión")}
            </MenuItem>
          </Menu>
        )}
      </ApolloConsumer>
    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default UserMenu;
