import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { CalendarCheck, ProgressCheck } from "mdi-material-ui";
import { renderFecha } from "js/utils/Helper";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import { getSelectOptions, numberWithCommas } from "js/utils";

export const ESTADO = [
  {
    value: "PENDIENTE",
    label: "Pendiente"
  },
  {
    value: "CONTROLADA",
    label: "Controlada"
  },
  {
    value: "ANULADA",
    label: "Anulada"
  }
];

const styles = theme => ({
  fila: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 40
  },
  paper: {
    padding: 12
  },
  facturaInfoFila: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 10
  },
  facturaInfoFilaItem: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderRight: "1px solid black",
    marginRight: 15,
    "& h6": {
      alignSelf: "center",
      paddingRight: 15
    },
    "& h4": {
      alignSelf: "center",
      paddingRight: 15
    },
    "&:last-child": {
      border: "none"
    },
    "& svg": {
      paddingRight: 15
    }
  }
});

class FacturaInfo extends Component {
  render() {
    const {
      classes,
      factura,
      changeEstado,
      estadoFactura,
      canjesUpdated
    } = this.props;
    return (
      <Grid container item xs={12} className={classes.fila}>
        <Paper className={classes.paper}>
          <Grid container item xs={12} className={classes.facturaInfoFila}>
            <Grid item className={classes.facturaInfoFilaItem}>
              <CalendarCheck color="primary" />
              <Typography variant="subtitle2">
                {factura.proveedor.nombreFantasia}
              </Typography>
            </Grid>
            <Grid item className={classes.facturaInfoFilaItem}>
              <CalendarCheck color="primary" />
              <Typography variant="subtitle2">
                {renderFecha(factura.fecha)}
              </Typography>
            </Grid>
            <Grid item className={classes.facturaInfoFilaItem}>
              <ProgressCheck color="primary" />
              <Select
                value={estadoFactura}
                onChange={e =>
                  changeEstado(
                    "estadoFactura",
                    e.target.value,
                    factura.idFactura
                  )
                }
                className={classes.style}
                inputProps={{
                  name: "estadoFactura",
                  id: "estadoFactura"
                }}
              >
                {getSelectOptions(ESTADO, "")}
              </Select>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.facturaInfoFila}>
            <Grid item className={classes.facturaInfoFilaItem}>
              <Typography variant="subtitle2">Importe</Typography>
              <Typography variant="subtitle2">
                {factura.proveedor.pais.moneda}
              </Typography>
              <Typography variant="h4">
                {numberWithCommas(factura.importe)}
              </Typography>
            </Grid>
            <Grid item className={classes.facturaInfoFilaItem}>
              <Typography variant="subtitle2">Costo unitario</Typography>
              <Typography variant="subtitle2">
                {factura.proveedor.pais.moneda}
              </Typography>
              <Typography variant="h4">
                {factura.canjes && factura.canjes.length
                  ? numberWithCommas(
                      factura.canjes.reduce(
                        (a, b) => a + b.eleccionProducto.producto.costo,
                        0
                      )
                    )
                  : "--"}
              </Typography>
            </Grid>
            <Grid item className={classes.facturaInfoFilaItem}>
              <Typography variant="subtitle2">Costo real unitario</Typography>
              <Typography variant="subtitle2">
                {factura.proveedor.pais.moneda}
              </Typography>
              <Typography variant="h4">
                {numberWithCommas(
                  canjesUpdated.reduce(
                    (acc, canje) => acc + parseFloat(canje.costoReal),
                    0
                  )
                ) || "--"}
              </Typography>
            </Grid>
            <Grid item className={classes.facturaInfoFilaItem}>
              <Typography variant="subtitle2">Costo total</Typography>
              <Typography variant="subtitle2">
                {factura.proveedor.pais.moneda}
              </Typography>
              <Typography variant="h4">
                {numberWithCommas(
                  canjesUpdated.reduce(
                    (acc, canje) =>
                      acc + parseFloat(canje.costoReal * canje.cantidad),
                    0
                  )
                ) || "--"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(FacturaInfo);
