export const ESTADO = [
  {
    value: "PENDIENTE",
    label: "Pendiente"
  },
  {
    value: "CONTROLADA",
    label: "Controlada"
  },
  {
    value: "ANULADA",
    label: "Anulada"
  }
];
