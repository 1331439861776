import TimerTypes from "./timer.types";

export const addActiveSeconds = seconds => ({
  type: TimerTypes.ADD_SECONDS_ACTIVE,
  seconds
});

export const addPasiveSeconds = seconds => ({
  type: TimerTypes.ADD_SECONDS_PASIVE,
  seconds
});
