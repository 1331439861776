import React from "react";
import AuthService from "js/utils/AuthService";
import base from "./Base";
import AddPrizeDialog from "../Dialogs/AddPrizeDialog";
import RemovePrizeDialog from "../Dialogs/RemovePrizeDialog";
import { Attachment, Remove } from "@material-ui/icons";

const enabledStates = ["EN_COMPRA", "A_ENTREGAR"];

const mustShow = params => {
  const {
    perms,
    item: { estado, numeroLote }
  } = params;
  if (!numeroLote) return false;
  return AuthService.hasPerms(perms) && enabledStates.includes(estado);
};

const mustDisable = params => {
  const {
    item: { estado, numeroLote }
  } = params;
  if (!numeroLote) return true;
  return enabledStates.includes(estado);
};

const resolveIcon = params => {
  const {
    item: { estado }
  } = params;
  let icon = <Attachment />;
  if (estado === "A_ENTREGAR") {
    icon = <Remove />;
  }
  return icon;
};
const resolveTooltip = params => {
  const {
    item: { estado }
  } = params;
  let toolTip = "Agregar premio";
  if (estado === "A_ENTREGAR") {
    toolTip = "Remover premio";
  }
  return toolTip;
};

const resolveDialog = params => {
  const {
    item: { estado }
  } = params;

  const dialog =
    estado === "EN_COMPRA" ? (
      <AddPrizeDialog
        handleClose={params.togglePopUp}
        onTableChange={params.onTableChange}
        item={params.item}
        dialogTitle={params.dialogTitle}
        query={params.query}
        mutation={params.mutation}
      />
    ) : (
      <RemovePrizeDialog
        handleClose={params.togglePopUp}
        onTableChange={params.onTableChange}
        item={params.item}
        dialogTitle={params.dialogTitle}
        query={params.query}
        mutation={params.mutation}
      />
    );

  return dialog;
};

export default params => {
  const { item } = params;
  return {
    ...base(),
    label: params.label,
    icon: resolveIcon(params),
    tooltip: resolveTooltip(params),
    diabled: mustDisable(params),
    show: () => mustShow(params),
    dialogId: "DELETE",
    dialog: resolveDialog(params)
  };
};
