import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  Row: {
    marginBottom: theme.spacing.unit * 2
  },
  container: {
    padding: "5px 0",
    top: "3.5px",
    left: 0,
    width: "100%",
    zIndex: 9999
  },
  cucarda: {
    fontSize: "12px"
  }
});

export default withStyles(styles);
