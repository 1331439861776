import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InfoCanje from "./InfoCanje";
import InfoProducto from "./InfoProducto";
import InfoComercial from "./InfoComercial";
import Button from "@material-ui/core/Button";
import DialogCancelarCanje from "./DialogCancelarCanje";
import SplashScreen from "js/pages/SplashScreen";
import { USER_ROLES as Roles } from "js/models/User";
import AuthService from "js/utils/AuthService";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import { getEnv } from "js/utils/Helper";
import { kibanaUrl } from "js/constants";

class AdminCanjeDetail extends Component {
  state = {
    open: false,
    inBackOffice:
      window.location.pathname.indexOf("admin") === -1 ? false : true,
    // En el front end los params del history se meten dentro de computed match
    idCanje: this.props.idCanje
      ? this.props.idCanje
      : this.props.computedMatch
        ? this.props.computedMatch.params.idCanje
        : null,
    view: this.props.match.params.view
      ? this.props.match.params.view
      : this.props.computedMatch
        ? this.props.computedMatch.params.view
          ? this.props.computedMatch.params.view
          : false
        : false
  };

  handleClickOpen = () => this.setState({ open: true });

  sendValue = (motivoCancelacion, backUrl) => {
    const { idCanje } = this.state;
    this.props.cancelCanje(idCanje, motivoCancelacion, backUrl);
    this.setState({ open: false });
  };

  handleClose = () => this.setState({ open: false });

  _renderHistorico = () => {
    const id = "e4e09790-3ab0-11e9-819e-c95dddfe1e4a";
    const { idCanje } = this.state;
    const from = moment(this.props.initialValues.fecha).format("YYYY-MM-DD");
    const to = new Date();
    const url = `${kibanaUrl}?id=${id}&idCanje=${idCanje}&env=${getEnv}&from=${from}&to=${to}`;
    return (
      <iframe
        src={url}
        height="400"
        width="100%"
        title="Histórico"
        frameBorder={0}
      />
    );
  };

  render() {
    const {
      classes,
      // canje,
      fechaEntrega,
      estadoCanje,
      factura,
      deslotear,
      costoReal,
      sendValues,
      initialValues,
      changeFecha,
      changeAutocomplete,
      changeValue,
      match: {
        params: { id }
      }
    } = this.props;
    const { inBackOffice, idCanje, view: disabled } = this.state;
    let backUrl = `/admin/canjes`;
    let editUrl = `/admin/canjes/${idCanje}`;
    if (!inBackOffice) {
      backUrl = `/canjes`;
      editUrl = `/canjes/${idCanje}`;
      // Es el id del programa si esta
    } else if (id) {
      backUrl = `/admin/programa/${id}/canjes`;
      editUrl = `/admin/programa/${id}/canjes/${idCanje}`;
    }

    return initialValues && initialValues.cantidad > 0 ? (
      <div className={classes.root}>
        <Typography className={classes.canje} variant="h5">
          {`Canje #${idCanje}`}
        </Typography>

        <Grid container className={classes.root} spacing={16}>
          <Grid item xs={12}>
            <Grid container spacing={32}>
              <Grid item xs={12} sm={6} md={4}>
                <InfoCanje
                  initialValues={initialValues}
                  changeFecha={changeFecha}
                  changeAutocomplete={changeAutocomplete}
                  fechaEntrega={fechaEntrega}
                  disabled={!!disabled}
                  deslotear={deslotear}
                  factura={factura}
                  estadoCanje={estadoCanje}
                  inBackOffice={inBackOffice}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InfoComercial
                  changeValue={changeValue}
                  canje={initialValues}
                  costoReal={costoReal}
                  initialValues={initialValues}
                  disabled={!!disabled}
                  inBackOffice={inBackOffice}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InfoProducto
                  canje={initialValues}
                  inBackOffice={inBackOffice}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.gridHistorico}>
            <ExpansionPanel className={classes.historico}>
              <ExpansionPanelSummary
                style={{ padding: "0" }}
                expandIcon={<ExpandMoreIcon />}
              >
                Histórico estado del canje
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: "0" }}>
                {this._renderHistorico()}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => this.props.history.push(backUrl)}
            >
              Volver
            </Button>
            {!disabled && (
              <React.Fragment>
                {AuthService.hasPerms([Roles.CANCELAR_CANJE]) && (
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={this.handleClickOpen}
                    disabled={
                      !!disabled || initialValues.estadoCanje === "CANCELADO"
                    }
                  >
                    Cancelar canje
                  </Button>
                )}
                {AuthService.hasPerms([Roles.CANJE_W]) && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() =>
                      sendValues({
                        fechaEntrega,
                        estadoCanje,
                        factura,
                        deslotear,
                        costoReal,
                        idCanje,
                        backUrl
                      })
                    }
                    // Si el estado es entregado y la no hay fecha de entrega no se tiene que poder hacer eso
                    disabled={
                      !!disabled ||
                      (estadoCanje === "ENTREGADO" && !fechaEntrega)
                    }
                  >
                    Guardar
                  </Button>
                )}
              </React.Fragment>
            )}
            {!!disabled &&
              AuthService.hasPerms([Roles.CANJE_W]) && (
                <React.Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => this.props.history.push(editUrl)}
                  >
                    Editar
                  </Button>
                </React.Fragment>
              )}
          </Grid>
        </Grid>
        {this.state.open && (
          <DialogCancelarCanje
            open={true}
            sendValue={this.sendValue}
            handleClose={this.handleClose}
            backUrl={backUrl}
          />
        )}
      </div>
    ) : (
      <SplashScreen />
    );
  }
}

export default AdminCanjeDetail;
