import gql from "graphql-tag";

export const getMasterRubros = gql`
  query getMasterRubros {
    getMasterRubros {
      idRubro
      descripcion
      estado
    }
  }
`;

export const getRubros = gql`
  query getRubros {
    getRubros {
      idRubro
      descripcion
    }
  }
`;

export const getRubrosInput = gql`
  query getRubros($idPrograma: Int!) {
    getRubros(rubroLike: { idPrograma: $idPrograma }) {
      idRubro
      label: descripcion
      value: descripcion
      estado
      children: subrubros {
        idSubrubro
        label: descripcion
        value: descripcion
        estado
      }
    }
  }
`;

export const getRubrosByPrograma = gql`
  query getRubros($idPrograma: Int!) {
    getRubros(rubroLike: { idPrograma: $idPrograma }) {
      idRubro
      descripcion
      estado
    }
  }
`;

export const getMasterSubrubros = gql`
  query getMasterSubrubros($idRubro: Int!) {
    getMasterSubrubros(subrubroLike: { idRubro: $idRubro }) {
      idSubrubro
      descripcion
    }
  }
`;

export const getSubRubros = gql`
  query getSubrubros($idRubro: Int!) {
    getSubrubros(subrubroLike: { idRubro: $idRubro }) {
      idSubrubro
      descripcion
      estado
    }
  }
`;

export default {
  queries: {
    getMasterRubros,
    getRubros,
    getRubrosInput,
    getMasterSubrubros,
    getSubRubros,
    getRubrosByPrograma
  }
};
