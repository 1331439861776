export default theme => ({
  fila: {
    background:
      theme.backgrounds.container.backgroundType === "url"
        ? `url(${theme.backgrounds.container.backgroundImage})`
        : theme.backgrounds.container.backgroundColor,
    backgroundRepeat: "repeat",
    display: "flex",
    flexDirection: "row"
  },
  filaSinBackground: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  contenido: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "-1px"
    }
  }
});
