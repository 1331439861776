import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const ClipboardIcon = () => (
  <SvgIcon>
    <path d="M17,9H7V7H17M17,13H7V11H17M14,17H7V15H14M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M19,3H14.82C14.4,1.84 13.3,1 12,1C10.7,1 9.6,1.84 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z" />
  </SvgIcon>
);
export const SearchIcon = props => (
  <SvgIcon {...props}>
    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
  </SvgIcon>
);

export const IndividualIcon = props => (
  <SvgIcon {...props}>
    <switch>
      <g id="XMLID_2443_">
        <g id="XMLID_114_">
          <path
            id="XMLID_116_"
            className="st0"
            d="M24.8,18.3c3.2,0,5.7,2.6,5.7,5.7s-2.6,5.7-5.7,5.7c-3.2,0-5.7-2.6-5.7-5.7
					S21.6,18.3,24.8,18.3z"
          />
          <path
            id="XMLID_115_"
            className="st0"
            d="M19.7,30.6c3.5,0,6.9,0,10.4,0c1.7,0,3.1,1.4,3.1,3.1v1.7c-2.2,2-5.1,3.2-8.3,3.2
					c-3.2,0-6.1-1.2-8.3-3.2v-1.7C16.6,32,18,30.6,19.7,30.6z"
          />
        </g>
        <g id="XMLID_110_">
          <path
            id="XMLID_111_"
            className="st0"
            d="M25,40.8c-8.7,0-15.8-7.1-15.8-15.8S16.3,9.2,25,9.2c8.7,0,15.8,7.1,15.8,15.8
					S33.7,40.8,25,40.8z M25,10.5c-8,0-14.5,6.5-14.5,14.5c0,8,6.5,14.5,14.5,14.5c8,0,14.5-6.5,14.5-14.5C39.5,17,33,10.5,25,10.5z
					"
          />
        </g>
      </g>
    </switch>
  </SvgIcon>
);

export const GrupalIcon = props => (
  <SvgIcon {...props}>
    <switch>
      <g id="XMLID_53_">
        <path
          id="XMLID_63_"
          className="st0"
          d="M20.3,29.3c0.7,0,1.4,0,2.1,0c-1.3-0.8-2.2-2-2.4-3.6c-1.5,0-3,0-4.5,0
				c-1.3,0-2.3,1.1-2.3,2.3v1.3c1.2,1.1,2.7,1.9,4.4,2.2C17.8,30.3,18.9,29.3,20.3,29.3z"
        />
        <path
          id="XMLID_99_"
          className="st0"
          d="M20,25.1C20,25,20,25,20,25.1c0-2.4,1.5-4.3,3.7-4.9c-0.3-2.1-2.1-3.7-4.3-3.7
				c-2.4,0-4.3,1.9-4.3,4.3c0,2.4,1.9,4.3,4.3,4.3C19.6,25.1,19.8,25.1,20,25.1z"
        />
        <path
          id="XMLID_136_"
          className="st0"
          d="M34.5,25.8c-1.5,0-3,0-4.5,0c-0.2,1.5-1.1,2.8-2.4,3.6c0.8,0,1.5,0,2.3,0
				c1.3,0,2.5,0.9,2.8,2.2c1.6-0.4,3-1.1,4.2-2.2v-1.3C36.8,26.8,35.8,25.8,34.5,25.8z"
        />
        <g id="XMLID_126_">
          <path
            id="XMLID_128_"
            className="st0"
            d="M25,20.7c2.4,0,4.3,1.9,4.3,4.3c0,2.4-1.9,4.3-4.3,4.3c-2.4,0-4.3-1.9-4.3-4.3
					C20.7,22.6,22.6,20.7,25,20.7z"
          />
          <path
            id="XMLID_127_"
            className="st0"
            d="M21.1,30c2.6,0,5.2,0,7.9,0c1.3,0,2.3,1.1,2.3,2.3v1.3c-1.7,1.5-3.9,2.4-6.3,2.4
					c-2.4,0-4.6-0.9-6.3-2.4v-1.3C18.8,31,19.8,30,21.1,30z"
          />
        </g>
        <g id="XMLID_122_">
          <path
            id="XMLID_123_"
            className="st0"
            d="M25,40.8c-8.7,0-15.8-7.1-15.8-15.8c0-8.7,7.1-15.8,15.8-15.8c8.7,0,15.8,7.1,15.8,15.8
					C40.8,33.7,33.7,40.8,25,40.8z M25,10.5c-8,0-14.5,6.5-14.5,14.5c0,8,6.5,14.5,14.5,14.5c8,0,14.5-6.5,14.5-14.5
					C39.5,17,33,10.5,25,10.5z"
          />
        </g>
        <path
          id="XMLID_129_"
          className="st0"
          d="M30.5,16.5c-2.2,0-3.9,1.6-4.3,3.6c2.2,0.5,3.8,2.5,3.8,4.9c0,0,0,0.1,0,0.1
				c0.1,0,0.3,0,0.4,0c2.4,0,4.3-1.9,4.3-4.3C34.8,18.4,32.9,16.5,30.5,16.5z"
        />
      </g>
    </switch>
  </SvgIcon>
);

export const AprobadoIcon = props => (
  <SvgIcon {...props}>
    <switch>
      <g id="XMLID_3551_">
        <defs>
          <filter id="f3" width="200%" height="200%">
            <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
            <feGaussianBlur result="blurOut" in="offOut" stdDeviation="1" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
        <circle
          filter="url(#f3)"
          id="XMLID_5967_"
          className="st0"
          cx="25"
          cy="25"
          r="15.7"
        />
        <path
          id="XMLID_5957_"
          className="st1"
          color="white"
          fill="white"
          d="M25,40.8c8.7,0,15.8-7.1,15.8-15.8c0-8.7-7.1-15.8-15.8-15.8C16.3,9.2,9.2,16.3,9.2,25
				C9.2,33.7,16.3,40.8,25,40.8z M25,12.7c6.8,0,12.3,5.5,12.3,12.3c0,6.8-5.5,12.3-12.3,12.3c-6.8,0-12.3-5.5-12.3-12.3l0,0
				C12.7,18.2,18.2,12.7,25,12.7z"
        />
        <polygon
          id="XMLID_5956_"
          className="st1"
          color="white"
          fill="white"
          points="32.7,21.7 30.2,19.2 23.4,26 20.8,23.4 18.4,25.9 23.4,31 			"
        />
      </g>
    </switch>
  </SvgIcon>
);
export const RechazadoIcon = props => (
  <SvgIcon {...props}>
    <switch>
      <g id="XMLID_219_">
        <defs>
          <filter id="f3" width="200%" height="200%">
            <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
            <feGaussianBlur result="blurOut" in="offOut" stdDeviation="1" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
        <circle
          filter="url(#f3)"
          id="XMLID_5998_"
          className="st0"
          cx="25"
          cy="25"
          r="15.5"
        />
        <g id="XMLID_3442_">
          <path
            id="XMLID_5994_"
            color="white"
            fill="white"
            className="st1"
            d="M25,40.7c8.7,0,15.7-7,15.7-15.7c0-8.7-7-15.7-15.7-15.7c-8.7,0-15.7,7-15.7,15.7
					C9.3,33.7,16.3,40.7,25,40.7z M25,12.8c6.8,0,12.2,5.5,12.2,12.2c0,6.8-5.5,12.2-12.2,12.2c-6.8,0-12.2-5.5-12.2-12.2l0,0
					C12.8,18.3,18.3,12.8,25,12.8z"
          />
          <polygon
            id="XMLID_5986_"
            color="white"
            fill="white"
            className="st1"
            points="31.6,21.7 29.2,19.3 18.4,30 20.8,32.4 				"
          />
          <polygon
            id="XMLID_5985_"
            color="white"
            fill="white"
            className="st1"
            points="29.2,32.4 31.6,30 20.8,19.3 18.4,21.7 				"
          />
        </g>
      </g>
    </switch>
  </SvgIcon>
);

export const NominadoIcon = props => (
  <SvgIcon {...props}>
    <switch>
      <g id="XMLID_2583_">
        <polygon
          id="XMLID_2600_"
          className="st0"
          points="10.2,34 25,40.4 39.8,34 39.8,9.6 10.2,9.6 			"
        />
        <g id="XMLID_2603_">
          <path
            id="XMLID_2614_"
            className="st1"
            color="white"
            fill="white"
            d="M28.2,19.9l5.6,1c0.3,0.1,0.5,0.5,0.2,0.7l-3.9,4.1l0.8,5.6c0,0.3-0.3,0.6-0.6,0.4
					l-5.1-2.5L20,31.8c-0.3,0.1-0.6-0.1-0.6-0.4l0.8-5.6l-3.9-4.1c-0.2-0.2-0.1-0.6,0.2-0.7l5.6-1l2.7-5c0.2-0.3,0.6-0.3,0.7,0
					L28.2,19.9L28.2,19.9z"
          />
        </g>
      </g>
    </switch>
  </SvgIcon>
);

export const EditarIcon = props => (
  <SvgIcon {...props}>
    <switch>
      <g>
        <defs>
          <filter id="f3" width="200%" height="200%">
            <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
            <feGaussianBlur result="blurOut" in="offOut" stdDeviation="1" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
        <circle
          filter="url(#f3)"
          id="XMLID_7085_"
          className="st0"
          cx="25"
          cy="25"
          r="15.4"
        />
        <g id="XMLID_7076_">
          <path
            id="XMLID_7077_"
            className="st1"
            color="white"
            fill="white"
            d="M25,40.6c8.6,0,15.6-7,15.6-15.6c0-8.6-7-15.6-15.6-15.6c-8.6,0-15.6,7-15.6,15.6
				C9.4,33.6,16.4,40.6,25,40.6z M25,12.9c6.7,0,12.1,5.4,12.1,12.1c0,6.7-5.4,12.1-12.1,12.1c-6.7,0-12.1-5.4-12.1-12.1l0,0
				C12.9,18.3,18.3,12.9,25,12.9z"
          />
        </g>
        <g id="XMLID_7072_">
          <path
            id="XMLID_7074_"
            className="st1"
            color="white"
            fill="white"
            d="M32.5,20l-1.1-1.1c-0.1-0.1-0.2-0.1-0.3-0.1l0,0c-0.1,0-0.2,0-0.3,0.1l-6.3,6.4
				c0,0-0.1,0.1-0.1,0.1l-0.7,1.8c-0.1,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0l1.8-0.7c0.1,0,0.1-0.1,0.1-0.1
				l6.3-6.4C32.6,20.5,32.6,20.2,32.5,20z"
          />
          <path
            id="XMLID_7073_"
            className="st1"
            color="white"
            fill="white"
            d="M29.6,24.9c-0.4,0-0.8,0.4-0.8,0.8v4.9h-8.7v-9.4h7.2c0.4,0,0.8-0.4,0.8-0.8
				c0-0.4-0.4-0.8-0.8-0.8h-8c-0.5,0-0.8,0.3-0.8,0.8v11.1c0,0.4,0.4,0.8,0.8,0.8h10.3c0.5,0,0.9-0.4,0.9-0.8v-5.7
				C30.4,25.3,30.1,24.9,29.6,24.9z"
          />
        </g>
      </g>
    </switch>
  </SvgIcon>
);
