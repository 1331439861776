import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import React from "react";

export const ProgressBar = () => {
  return (
    <>
      <Grid container justify="center" alignContent="center">
        <Grid container justify="center" xs={12}>
          <CircularProgress color="secondary" size={30} />
        </Grid>
      </Grid>
    </>
  );
};
