import gql from "graphql-tag";

export const getIndicadores = gql`
  query getIndicadores(
    $idPrograma: Int
    $idSegmento: Int
    $fechaDesde: Date
    $fechaHasta: Date
  ) {
    getIndicadores(
      idPrograma: $idPrograma
      idSegmento: $idSegmento
      fechaDesde: $fechaDesde
      fechaHasta: $fechaHasta
    ) {
      tiempoEntregaPromedio
      tasaRedencion
      saldoCCPrograma
      saldoCCUsuarios
      saldoPlataCCPrograma
      saldoPlataCCUsuarios
    }
  }
`;

export default {
  queries: {
    getIndicadores
  }
};
