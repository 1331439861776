import { connect } from "react-redux";
import { getTemplate } from "js/redux/programa/selectors.js";
import LayoutBO from "./LayoutBO";

const mapStateToProps = state => ({
  theme: getTemplate(state)
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutBO);
