import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Sublote from "./Sublote";
import LoteInfo from "./LoteInfo";

const AdminLotesDetail = ({ classes, lote, programa, onRefetchLote }) => {
  return lote ? (
    <div className={classes.root}>
      <Typography className={classes.lote} variant="h5">
        {`Lote ${lote.numeroLote}`}
      </Typography>
      <Grid container xs={12} className={classes.filas}>
        <LoteInfo lote={lote} programa={programa} />

        {lote.sublotes.map(sublote => (
          <Sublote
            onRefetchLote={onRefetchLote}
            key={`${sublote.numeroLote}-${sublote.proveedor}`}
            sublote={sublote}
            numeroLote={lote.numeroLote}
          />
        ))}
      </Grid>
    </div>
  ) : null;
};

export default AdminLotesDetail;
