import { connect } from "react-redux";
import { reduxForm, formValueSelector, change, getFormMeta } from "redux-form";
import { get, pick } from "lodash";
import MyForm from "./AdminFacturasDetail";
import { changeEstadoMutation } from "./utils";

const NewForm = reduxForm({
  form: "formAdminFacturasDetail",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(MyForm);

const selector = formValueSelector("formAdminFacturasDetail");

const mapStateToProps = (state, ownProps) => {
  return {
    formMeta: getFormMeta("formAdminFacturasDetail")(state),
    notasInternas:
      selector(state, "notasInternas") ||
      get(ownProps, "factura.notasInternas", ""),
    estadoFactura:
      selector(state, "estadoFactura") ||
      get(ownProps, "factura.estado", "PENDIENTE"),
    canjesUpdated:
      selector(state, "canjesUpdated") ||
      get(ownProps, ["factura", "canjes"], []).map(
        ({ idCanje, costoReal, cantidad }) => ({ idCanje, costoReal, cantidad })
      )
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeEstado: (field, value, idFactura) =>
    changeEstadoMutation({
      field,
      value,
      dispatch,
      idFactura,
      openNotification: ownProps.openNotification
    }),
  changeCanje: canjes => idCanje => event => {
    const indexCanje = canjes.findIndex(canje => canje.idCanje === idCanje);
    const canjesUpdated = [...canjes];
    canjesUpdated[indexCanje] = {
      idCanje,
      costoReal: parseFloat(event.target.value),
      cantidad: canjesUpdated[indexCanje].cantidad
    };

    dispatch(change("formAdminFacturasDetail", "canjesUpdated", canjesUpdated));
  },
  changeNotasInternas: event =>
    dispatch(
      change("formAdminFacturasDetail", "notasInternas", event.target.value)
    )
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...pick(dispatchProps, ["changeEstado", "changeNotasInternas"]),
  ...ownProps,
  changeCanje: dispatchProps.changeCanje(stateProps.canjesUpdated)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NewForm);
