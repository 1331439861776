import get from "lodash/get";

export default theme => {
  const avatarDiameter = get(
    theme,
    "programa.template.header.user.avatar.diameter",
    40
  );
  return {
    textoTitulo: {
      // color: theme.palette.getContrastText(theme.palette.secondary.main),
      color: "#FFF",
      textAlign: "center",
      fontSize: "xx-large",
      paddingTop: "20px"
    },
    textoSubtitulo: {
      // color: theme.palette.getContrastText(theme.palette.secondary.main),
      color: "#FFF",
      textAlign: "center",
      paddingBottom: "20px",
      fontSize: "larger",
      paddingTop: "5px"
    },
    fondoTitulo: {
      backgroundColor: theme.palette.primary.main,
      marginBottom: "40px"
    },
    botonActivado: {
      height: "67px",
      width: "25%",
      color: theme.palette.primary.main,
      cursor: "pointer"
    },
    botonDesactivado: {
      height: "67px",
      width: "25%",
      color: "#979797",
      cursor: "pointer"
    },
    avatar: {
      margin: 10,
      width: avatarDiameter,
      height: avatarDiameter
    },
    textoNombreAprobador: {
      color: "#4c4c4c",
      fontSize: "larger",
      fontWeight: "bold"
    },
    textoHola: {
      color: theme.palette.primary.main,
      marginBottom: "35px"
    },
    textoObservacion: {
      color: theme.palette.primary.main
    },
    textoDefinicion: {
      color: "#4c4c4c"
    },
    card: {
      minWidth: "100%",
      padding: "20px"
    },
    icon: {
      paddingTop: "23px"
    },
    textoFechaWrapper: {
      color: theme.palette.primary.main,
      display: "inline"
    },
    textoFecha: {
      color: "#979797",
      display: "inline"
    },
    containerFecha: {
      borderBottomWidth: "1px",
      borderBottomColor: theme.palette.primary.main,
      borderBottomStyle: "solid"
    },
    pregunta: {
      fontWeight: "bold",
      marginBottom: "0px",
      marginTop: "10px"
    },
    respuesta: {
      marginTop: "5px",
      marginBottom: "-5px"
    },
    textoNombre: {
      color: "#4c4c4c",
      fontSize: "larger",
      fontWeight: "bold"
    },
    iconDesplegable: {
      height: "80px",
      width: "80px",
      marginBottom: "20px"
    },
    iconNominado: {
      height: "80px",
      width: "80px",
      marginBottom: "20px",
      position: "absolute",
      right: "-3px",
      top: "-1px"
    },
    containerAprobador: {
      marginBottom: "20px"
    },
    containerObservacion: {
      marginBottom: "20px"
    },
    containerNominado: {
      paddingTop: "0px !important",
      position: "relative"
    },
    containerBotones: {
      backgroundColor: theme.palette.primary.main + "40",
      padding: "25px"
    },
    containerAprobacionAnterior: {
      marginTop: "10px"
    },
    textoPuntos: {
      marginTop: "20px",
      color: theme.palette.primary.main
    },
    field: {
      backgroundColor: "#FFF",
      position: "relative",
      borderRadius: "5px",
      "&:before": {
        // content: "",
        position: "absolute",
        left: "0",
        top: "50%",
        width: "0",
        height: "0",
        border: "34px solid transparent",
        borderRightColor: "#FFF",
        borderLeft: "0",
        borderTop: "0",
        marginTop: "-17px",
        marginLeft: "-34px"
      }
    },
    observacionAnteriorTitulo: {
      fontWeight: "bold",
      marginBottom: "0px",
      marginTop: "10px"
    },
    observacionAnterior: {
      marginBottom: "5px"
    },
    divider: {
      background:
        "repeating-linear-gradient(   to right,   #0000001f,   #0000001f 4px,   #ffF 3px,   #fff 8px )"
    },
    buttonLabel: {
      color: "#FFF"
    }
  };
};
