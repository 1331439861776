import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  buttonContainer: {
    textAlign: "right"
  },
  floatingButton: {
    // position: "fixed",
    // bottom: "0",
    margin: 8
    // float: "right"
    // right: "50px"
  },
  floatingButtonLeft: {
    position: "fixed",
    bottom: "0",
    margin: "25px",
    float: "left"
  },
  Paper: {
    padding: theme.spacing.unit * 2
  },
  Subtitle: {
    fontFamily: theme.typography.fontFamily,
    marginBottom: theme.spacing.unit * 2,
    color: theme.palette.action.active
  },
  Segmento: {
    backgroundColor: "#ddd",
    padding: "20px",
    marginBottom: theme.spacing.unit * 2,
    "& label + .MuiInput-formControl-312": {
      marginTop: 0
    }
  },
  SegmentoLabel: {
    textAlign: "center"
  },
  bodySegmento: {
    minHeight: "200px"
  }
});

export default withStyles(styles);
