import React, { PureComponent } from "react";
import capitalize from "lodash/capitalize";

import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/targetsUnilever.resolver";
import advancedFilter from "js/pages/Unilever/Targets/Tabla/advancedFilters.js";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import { USER_ROLES as Roles } from "js/models/User";

const defaultHeader = isMisMeds => {
  const header = {
    title: "Targets de preventistas",
    export: {
      path: "unileverTargetsPreventistas",
      perms: [Roles.TARGET_USUARIO_R],
      tooltip: "Exportar targets de preventistas"
    },
    queryStringMatcher: {
      idsProgramas: undefined
    }
  };

  return header;
};
const columnData = [
  { id: "fechaCreacion", label: "Fecha creación", type: "date" },
  { id: "campanaOP_nombre", label: "Campaña", removeSort: true },
  { id: "objetivo_objetivo", label: "Objetivo", removeSort: true },
  { id: "usuario_username", label: "Usuario", removeSort: true },
  { id: "usuario_perfil_nombre", label: "Nombre", removeSort: true },
  { id: "usuario_perfil_apellido", label: "Apellido", removeSort: true },
  { id: "med_nombre", label: "MED", removeSort: true },
  { id: "target", label: "Target" },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    removeSort: true,
    parser: estado => <Estado estado={capitalize(estado)} />
  }
];

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  render() {
    const isMisMeds = window.location.pathname.indexOf("mis") !== -1;

    return (
      <Table
        storage="FiltroTargets"
        query={AppQraphQL.queries.getTargetsDeMeds}
        defaultHeader={defaultHeader(isMisMeds)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getTargetsDeMeds}
        actionsCustom={[]}
      />
    );
  }
}

export default Tabla;
