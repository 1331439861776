import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import get from "lodash/get";
import { numberWithCommas } from "js/utils";

const styles = theme => ({
  input: {
    margin: "6px 16px",
    padding: 0
  },
  title: {
    marginTop: 8
  }
});

class InfoCanjeconForm extends Component {
  render() {
    const { classes, costoReal, disabled, canje, changeValue } = this.props;
    const moneda = get(canje, "proveedor.pais.moneda", "");
    const costo = get(canje, "eleccionProducto.producto.costo", 0);
    const costoTotal = costo * canje.cantidad;

    return (
      <Grid container item xs={12} spacing={16}>
        <Grid container item xs={12} className={classes.input}>
          <Grid item xs={4} className={classes.title}>
            <Typography variant="subtitle2">Costo unitario</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="costo"
              disabled={true}
              value={numberWithCommas(costo)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{moneda}</InputAdornment>
                ),
                shrink: true
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.input}>
          <Grid item xs={4} className={classes.title}>
            <Typography variant="subtitle2">Costo total</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="costoTotal"
              disabled={true}
              value={numberWithCommas(costoTotal)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{moneda}</InputAdornment>
                ),
                shrink: true
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.input}>
          <Grid item xs={4} className={classes.title}>
            <Typography variant="subtitle2">Costo unitario real</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="costoReal"
              disabled={disabled}
              value={disabled ? numberWithCommas(costoReal) : costoReal}
              type={disabled ? "text" : "number"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{moneda}</InputAdornment>
                ),
                shrink: true
              }}
              onChange={e => changeValue("costoReal", e)}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(InfoCanjeconForm);
