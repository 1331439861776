import React, { Component, Fragment } from "react";

import Table from "js/components/Shared/Tabla";
import advancedFilter from "./advancedFilter.js";
import FacturasQraphQL from "js/graphql/resolvers/facturas.resolver.js";
import columnData from "./columnData";
import { manageAction } from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";

const defaultHeader = {
  title: "Facturas",
  new: {
    title: "Nueva",
    perms: [Roles.FACTURA_W],
    path: "/admin/facturas/new"
  }
};

const defaultSort = { order: "desc", orderBy: "fecha" };

class Tabla extends Component {
  render() {
    const manageFacturas = params => ({
      ...manageAction({
        ...params,
        history: this.props.history,
        perms: [Roles.FACTURA_W]
      }),
      onclick: e =>
        this.props.history.push(`${window.location.pathname}/${params.itemId}`)
    });
    return (
      <Fragment>
        <Table
          storage="FiltroFacturas"
          query={FacturasQraphQL.queries.getFacturasComplete}
          defaultHeader={defaultHeader}
          advancedFilter={advancedFilter}
          sort={defaultSort}
          columnData={columnData}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count} facturas`
          }
          getTipo={data => data.getFacturas}
          actionsCustom={[manageFacturas]}
        />
      </Fragment>
    );
  }
}

export default Tabla;
