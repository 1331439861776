import { has, pick } from "lodash";

export const emptyAutocomplete = { label: "", value: undefined };

export const initialState = {
  proveedor: undefined,
  factura: undefined,
  estadoCanje: undefined,
  fechaDesde: undefined,
  fechaHasta: undefined
};
export const getProveedoresSuggestions = data =>
  has(data, "getProveedores.itemsPagina")
    ? data.getProveedores.itemsPagina.map(proveedor => ({
        value: proveedor.idProveedor,
        label: proveedor.nombreFantasia
      }))
    : [];

export const updateQueryParamsAsync = ({ key, value }) => state => ({
  [key]: value,
  queryParams: {
    programaLike: { [key]: value }
  }
});

const Utils = {
  getProveedoresSuggestions
};

export const getAutocompleteValue = (fn, data, stateValue) =>
  stateValue !== undefined
    ? Utils[fn](data).find(p => p.value === stateValue)
    : { value: undefined, label: "" };

export const getValuesFromProps = props =>
  pick(props, ["proveedor", "factura", "estado", "fechaDesde", "fechaHasta"]);

export default Utils;
