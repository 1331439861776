import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withPrograma from "js/hoc/withPrograma";
import CanjeRaw from "./Canje";
import CanjeErrorRaw from "./CanjeError";
import styles from "./styles";

const enhance = compose(
  withRouter,
  withPrograma,
  withStyles(styles)
);

export const Canje = enhance(CanjeRaw);
export const CanjeError = enhance(CanjeErrorRaw);
export default {
  Canje,
  CanjeError
};
