import React, { Fragment } from "react";

import { CatalogoContext } from "../../catalogo-context";
import { Query } from "react-apollo";
import BeneficioQraphQL from "js/graphql/resolvers/beneficio.resolver";
import ListCustom from "./ListCustom";
import getParsedData from "./utils";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { _t } from "js/utils/TranslationService";
import AuthService from "js/utils/AuthService";

class FilterCategories extends React.Component {
  state = {
    open: true
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { classes, programa } = this.props;
    const idSegmento = isLogged
      ? parseInt(AuthService.getIdSegmento())
      : parseInt(programa.segmentoDefault.idSegmento);
    const isLogged = !!AuthService.getIdUsuario();
    const queryBeneficiosFilters = isLogged
      ? BeneficioQraphQL.queries.getBeneficiosFilters
      : BeneficioQraphQL.queries.getBeneficiosFiltersPublico;
    return (
      <Query
        query={queryBeneficiosFilters}
        variables={{
          idPrograma: programa.idPrograma,
          idSegmento: idSegmento
        }}
        errorPolicy="all"
      >
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error! ${error.message}`;

          const obj = isLogged
            ? getParsedData(data.getBeneficiosFilters)
            : getParsedData(data.getBeneficiosFiltersPublico);

          return (
            <div className={classes.root}>
              <CatalogoContext.Consumer>
                {({ filterBy, searchCriteria, queryObj }) => (
                  <Fragment>
                    <List component="nav" />
                    <ListItem button onClick={filterBy.filterFavoritos}>
                      <ListItemText
                        primary={_t("Favoritos")}
                        classes={{
                          primary:
                            queryObj.beneficioLike.favoritos && classes.selected
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() =>
                        filterBy.filterByCategory({
                          key: undefined,
                          id: undefined
                        })
                      }
                    >
                      <ListItemText primary={_t("Ver todo")} />
                    </ListItem>
                    <List component="nav" />
                  </Fragment>
                )}
              </CatalogoContext.Consumer>
              {obj.rubros && obj.rubros.length > 1 ? (
                <ListCustom dato={obj.rubros} title={_t("Categorías")} />
              ) : (
                ""
              )}
              {obj.zonas && obj.zonas.length > 1 ? (
                <ListCustom dato={obj.zonas} title={_t("Zonas")} />
              ) : (
                ""
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

FilterCategories.propTypes = {};

export default FilterCategories;
