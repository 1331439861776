import React from "react";
import PropTypes from "prop-types";
import { uniq, sortBy } from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import AlertDialog from "js/components/Shared/Tabla/EnhancedTable/AlertDialog";
import Tooltip from "@material-ui/core/Tooltip";
import EnhancedTableHead from "../EnhancedTableHead";
import EnhancedTableToolbar from "../EnhancedTableToolbar";
import { descend, renderFecha, renderHora } from "js/utils/Helper";
import { _t } from "js/utils/TranslationService";
import "./styles.css";

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemSelected: {},
      openDialog: false
    };
  }

  isSelected = id => {
    return this.props.state.selected.indexOf(id) !== -1;
  };

  isDisabled = id => {
    return this.props.state.disabled.indexOf(id) !== -1;
  };

  componentDidMount = () => {
    const { scrollToLastColumn } = this.props;
    if (scrollToLastColumn) {
      const table = document.getElementById("table");
      table.scrollBy(table.offsetWidth, 0);
    }
  };

  render() {
    const {
      classes,
      columnData,
      total,
      data,
      state,
      handleRequestSort,
      handleSelectAllClick,
      handleDeselectAllClick,
      handleClick,
      handleChangePage,
      handleChangeRowsPerPage,
      actions,
      actionsCustom,
      hanleDeleteAction,
      labelDisplayedRows,
      toolbarActions,
      showPagination,
      onTableChange,
      refetch,
      footer,
      width,
      pathId,
      itemPopUpId,
      dialogPopUpId,
      togglePopUp,
      actionConditionVariable,
      actionConditionValues
    } = this.props;

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          refetch={refetch}
          selected={state.selected}
          toolbarActions={toolbarActions}
          onTableChange={onTableChange}
          onDeselectAll={handleDeselectAllClick}
        />
        <div className={classes.tableWrapper} id="table">
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              actions={actions}
              columnData={columnData}
              numSelected={state.selected.length}
              order={state.order}
              orderBy={state.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={parseInt(total, 10)}
              classes={classes}
            />
            <TableBody>
              {data.map(item => {
                let key = pathId ? pathId : Object.keys(item)[0];
                // Si se quiere usar como id del item de la tabla un dato que esta dentro de un objeto del item
                // en el pathId se pone <objeto>.<campo>. No poner más de un nivel
                let itemId = null;
                if (key.indexOf(".") !== -1) {
                  let keys = key.split(".");
                  itemId = item[keys[0]][keys[1]];
                } else {
                  itemId = item[key];
                }
                const codigoItem = item["codigo"];
                const isSelected = this.isSelected(itemId);
                const isDisabled = this.isDisabled(itemId);
                let tableRows = [];

                // Estos sirve si la tabla tiene acciones
                let showItemAction = true;
                if (
                  actions &&
                  actions.length !== 0 &&
                  actionConditionVariable
                ) {
                  if (
                    !actionConditionValues ||
                    actionConditionValues.length === 0
                  ) {
                    throw Error(
                      "Tiene que agregar 'actionConditionValues' a los props de la tabla"
                    );
                  }
                  let itemHasValue = false;
                  actionConditionValues.forEach(value => {
                    if (item[actionConditionVariable] === value) {
                      itemHasValue = true;
                    }
                  });
                  if (!itemHasValue) {
                    showItemAction = false;
                  }
                }

                columnData.forEach((column, i) => {
                  if (
                    !(
                      column.id.match(/^__typename*/) !== null ||
                      column.id.match(/^"id"*/) !== null
                    )
                  ) {
                    if (column.type === "array") {
                      let string = "";
                      descend(item, column.id).map(col => {
                        string !== "" && (string = string.concat(", "));
                        return (string = string.concat(`${col[column.field]}`));
                      });
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{ root: classes.tableCellRoot }}
                          padding={"dense"}
                          key={i}
                        >
                          {string}
                        </TableCell>
                      );
                    } else if (column.type === "combine") {
                      let string = "";

                      column["combine"].map(col => {
                        const value = descend(item, col.object || col.id);
                        string !== "" &&
                          (string = string.concat(column.with || " "));
                        return (string = string.concat(value));
                      });
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{ root: classes.tableCellRoot }}
                          padding={"dense"}
                          key={i}
                        >
                          {string}
                        </TableCell>
                      );
                    } else if (column.type === "locacion_merge") {
                      let string = "";
                      let array = [];

                      if (!item.tieneSucursales) {
                        string = item.pais && item.pais.descripcion;
                      } else {
                        const sucursales = descend(item, "sucursales");

                        sucursales.map(col => array.push(col.pais.descripcion));
                        string = sortBy(uniq(array)).join(", ");
                      }
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{ root: classes.tableCellRoot }}
                          padding={"dense"}
                          key={i}
                        >
                          {string}
                        </TableCell>
                      );
                    } else if (column.type === "date") {
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{ root: classes.tableCellRoot }}
                          padding={"dense"}
                          key={i}
                        >
                          {renderFecha(new Date(descend(item, column.id)))}
                        </TableCell>
                      );
                    } else if (column.type === "emails") {
                      const value = descend(item, column.id);
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{ root: classes.tableCellRoot }}
                          padding={"dense"}
                          key={i}
                        >
                          {value.length
                            ? value[0] +
                              (value.length > 1
                                ? `... +${value.length - 1}`
                                : "")
                            : "--"}
                        </TableCell>
                      );
                    } else if (column.type === "custom") {
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          className={column.classes}
                          classes={{
                            root: column.sticky
                              ? classes.tableCellRootSticky
                              : classes.tableCellRoot
                          }}
                          style={column.style}
                          padding={"dense"}
                          key={i}
                        >
                          {column.parser(item[column.id], item)}
                        </TableCell>
                      );
                    } else if (column.type === "datehour") {
                      const value = new Date(descend(item, column.id));
                      const fecha = renderFecha(value);
                      const hora = renderHora(value);
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{ root: classes.tableCellRoot }}
                          padding={"dense"}
                          key={i}
                        >
                          {`${fecha} ${hora}`}
                        </TableCell>
                      );
                    } else if (column.id.match(/^actions$/) !== null) {
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          key={i}
                          style={{ width: "max-content" }}
                          padding="checkbox"
                        >
                          <div style={{ display: "flex" }}>
                            {actionsCustom.map(actionFn => {
                              const action = actionFn({
                                itemId,
                                item,
                                codigoItem,
                                togglePopUp,
                                onTableChange
                              });
                              return (
                                action.show({ item }) && (
                                  <Tooltip
                                    key={action.label}
                                    className={classes.tooltip}
                                    title={action.tooltip}
                                  >
                                    <span>
                                      <IconButton
                                        aria-label={action.label}
                                        onClick={() =>
                                          action.dialog
                                            ? togglePopUp(
                                                itemId,
                                                action.dialogId,
                                                codigoItem
                                              )
                                            : action.onclick()
                                        }
                                        disabled={action.disabled({ item })}
                                      >
                                        {action.icon}
                                      </IconButton>
                                      {itemPopUpId &&
                                        itemPopUpId === itemId &&
                                        (!dialogPopUpId ||
                                          dialogPopUpId === action.dialogId) &&
                                        action.dialog}
                                    </span>
                                  </Tooltip>
                                )
                              );
                            })}
                          </div>
                        </TableCell>
                      );
                    } else {
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{
                            root: column.sticky
                              ? classes.tableCellRootSticky
                              : classes.tableCellRoot
                          }}
                          padding={"dense"}
                          key={i}
                        >
                          {typeof item[column.id] === "boolean"
                            ? item[column.id] === true
                              ? "Si"
                              : "No"
                            : descend(item, column.id)}
                        </TableCell>
                      );
                    }
                  }
                });

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={itemId}
                    selected={isSelected}
                    classes={{ root: classes.tableRowRoot }}
                  >
                    {actions.indexOf("bulk") !== -1 && (
                      <TableCell
                        classes={{ root: classes.tableCellRoot }}
                        padding="checkbox"
                      >
                        {showItemAction && (
                          <Checkbox
                            onClick={event => handleClick(event, item)}
                            checked={isSelected}
                            disabled={isDisabled}
                          />
                        )}
                      </TableCell>
                    )}
                    {actions.indexOf("add") !== -1 && (
                      <TableCell
                        classes={{ root: classes.tableCellRoot }}
                        padding="checkbox"
                      >
                        <IconButton
                          onClick={event => handleClick(event, item)}
                          disabled={isDisabled}
                        >
                          {isSelected ? (
                            <CancelIcon color="error" />
                          ) : (
                            <AddIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    )}

                    {tableRows}
                  </TableRow>
                );
              })}
            </TableBody>
            {footer && footer.length ? (
              <TableFooter>
                <TableRow>
                  <TableCell
                    align="right"
                    classes={{
                      root: classes.tableCellFooterTotal
                    }}
                  >
                    Total
                  </TableCell>
                  {footer.map(({ value, parser = value => value }, i) => (
                    <TableCell
                      numeric
                      classes={{
                        root: classes.tableCellFooter
                      }}
                      padding={"dense"}
                      key={i}
                    >
                      {parser(value)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableFooter>
            ) : (
              ""
            )}
          </Table>
        </div>
        <AlertDialog
          open={this.state.openDialog}
          itemSelected={this.state.itemSelected}
          hanleDeleteAction={hanleDeleteAction.bind(this)}
        />
        {showPagination && (
          <TablePagination
            component="div"
            labelRowsPerPage={width !== "xs" && _t("Resultados por página")}
            labelDisplayedRows={labelDisplayedRows}
            count={parseInt(total, 10)}
            rowsPerPage={parseInt(state.rowsPerPage, 10)}
            rowsPerPageOptions={[5, 10, 50, 100]}
            page={parseInt(state.page, 10) - 1}
            backIconButtonProps={{
              "aria-label": `${_t("Anterior")}`
            }}
            classes={{
              root: classes.tablePagination,
              actions: classes.tablePaginationActions
            }}
            nextIconButtonProps={{
              "aria-label": `${_t("Siguiente")}`
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columnData: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
  handleSelectAllClick: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  labelDisplayedRows: PropTypes.func,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  actionsCustom: PropTypes.array,
  hanleDeleteAction: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  showPagination: PropTypes.bool.isRequired
};

EnhancedTable.defaultProps = {
  showPagination: true,
  labelDisplayedRows: ({ from, to, count }) => ` ${from}-${to} de ${count}`,
  handleRequestSort: () => undefined,
  handleSelectAllClick: () => undefined,
  handleClick: () => undefined,
  handleChangePage: () => undefined,
  handleChangeRowsPerPage: () => undefined,
  hanleDeleteAction: () => undefined,
  actions: [],
  actionsCustom: [],
  state: { selected: [], disabled: [] },
  footer: []
};

export default EnhancedTable;
