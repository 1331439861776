import gql from "graphql-tag";

const Usuarios = {};

Usuarios.fragments = {
  usuariosCompleto: gql`
    fragment usuariosCompleto on Usuario {
      idUsuario
      estado
      username
      avatarUrl
      roles {
        idRol
        nombre
      }
      programa {
        diasParaVencimientoPuntos
      }
      perfil {
        nombre
        apellido
        email
        fecha_de_nacimiento
        ubicacion
        otros
        datosDeCanje {
          dni
          sucursal
          lanpass
          entrega
          fechaNacimiento
        }
      }
      cuentaCorriente {
        balance
      }
    }
  `
};

export default Usuarios;
