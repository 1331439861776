export const validarCampos = (paramsFacturaPorPuntos, required) => {
  let result = {
    status: true,
    message: []
  };

  let camposPorValidar = [];

  if (required) {
    camposPorValidar = ["showSelectorCantidad", "cantidadProductosXDefault"];
  } else {
    camposPorValidar = [];
  }

  for (let campo of camposPorValidar) {
    let parametro = paramsFacturaPorPuntos.find(param => param.key === campo);

    if (parametro) {
      let { key, label, value, tipo } = parametro;
      let val;

      if (
        required ||
        (!required && value[0] && value[0].length > 0) ||
        tipo === "Lista"
      ) {
        switch (tipo) {
          case "Monto":
            let isFloatNumber = /^\d+(\.\d+)?$/.test(value);
            val = parseFloat(value);

            if (!isFloatNumber) {
              result.status = false;
              result.message.push(
                `El campo ${label} solo se permite valores numéricos con decimales`
              );
            } else if (isNaN(val)) {
              result.status = false;
              result.message.push(
                `El campo ${label} posee un valor incorrecto`
              );
            } else if (val <= 0) {
              result.status = false;
              result.message.push(
                `El campo ${label} debe ser mayor o igual a 0 (cero)`
              );
            }
            break;
          case "Numerico":
            let isIntegerNumber = /^[0-9]+$/.test(value);

            val = parseInt(value);

            if (!isIntegerNumber) {
              result.status = false;
              result.message.push(
                `El campo ${label} solo permite valores numéricos enteros positivos`
              );
            } else if (isNaN(val)) {
              result.status = false;
              result.message.push(
                `El campo ${label} posee un valor incorrecto`
              );
            } else if (key === "showSelectorCantidad") {
              if (val !== 0 && val !== 1) {
                result.status = false;
                result.message.push(
                  `El campo ${label} solo permite valores 0 o 1`
                );
              }
            } else if (val <= 0) {
              result.status = false;
              result.message.push(
                `El campo ${label} debe ser mayor o igual a 0 (cero)`
              );
            }
            break;
          case "Lista":
            let values = [];

            for (val of value) {
              if (val && val.trim().length > 0) {
                values.push(val.trim());
              }
            }

            if (values.length === 0) values = [""];

            parametro.value = values;

            break;
        }
      }
    }
  }
  return result;
};
