import { connect } from "react-redux";
import UserInfo from "./UserInfo";
import { getIsLogged } from "js/redux/programa/selectors.js";
import { selectUpdateUserPoints } from "js/redux/user/user.selectors.js";
import { updateUserPoints } from "js/redux/user/user.actions";

const mapDispatchToProps = dispatch => ({
  updateUserPoints: updatePoints => dispatch(updateUserPoints(updatePoints))
});

const mapStateToProps = state => ({
  isLogged: getIsLogged(state),
  updatePoints: selectUpdateUserPoints(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInfo);
