export default theme => ({
  text: {
    paddingLeft: 0,
    paddingRight: 0,
    color: theme.palette.quaternary.main
  },
  link: {
    color: theme.palette.quaternary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  titleCenter: {
    color: theme.palette.quaternary.main,
    textAlign: "center",
    height: 24,
    fontWeight: "bold"
  },
  itemCenter: {
    textAlign: "center",
    paddingRight: 24,
    paddingLeft: 24
  }
});
