import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import LocationFilter from "js/components/Shared/TablaFiltro/locationFilter.js";
import proveedorFilter from "js/components/Shared/TablaFiltro/proveedorFilter.js";
import RubroFilter from "js/components/Shared/TablaFiltro/rubroFilter.js";
import { TIPO_PRODUCTO } from "js/constants";

class advancedFilter extends PureComponent {
  render() {
    const { value, submitValue, classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"nombre"}
              name={"nombre"}
              label={"Nombre del producto"}
              type="search"
              value={value["nombre"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"idProducto"}
              name={"idProducto"}
              label={"ID"}
              type="search"
              value={value["idProducto"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"ACTIVO"}>Activo</MenuItem>
              <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="tipo-producto-simple"
              id="tipo-producto-simple"
            >
              Tipo de producto
            </InputLabel>
            <Select
              value={value["tipoProducto"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "tipoProducto",
                id: "tipoProducto"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              {TIPO_PRODUCTO.map((tipoProducto, index) => (
                <MenuItem key={index} value={tipoProducto.value}>
                  {tipoProducto.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <div>{proveedorFilter(classes, value, submitValue)}</div>
        </Grid>

        <Grid item xs={12} md={8}>
          <RubroFilter
            classes={classes}
            value={value}
            submitValue={submitValue}
          />
        </Grid>

        <Grid item xs={12}>
          <LocationFilter value={value} submitValue={submitValue} />
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
