export default theme => ({
  actionButton: {
    [theme.breakpoints.down("sm")]: {
      width: "49%",
      margin: "1% 0 2% 0"
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "46%",
      margin: "1% 0 1% 0"
    }
  },
  submitButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  rejectLabel: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75em"
    }
  }
});
