import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import CardItem from "./CardItem";

const Cuadricula = props => {
  const { classes, beneficios, isLogged, programLogo } = props;

  return (
    <div className={classes.container}>
      <Grid
        container
        direction="row"
        alignItems="stretch"
        spacing={16}
        className={classes.gridContainer}
      >
        {beneficios.map(beneficio => (
          <CardItem
            isLogged={isLogged}
            key={`card-beneficio-${beneficio.idBeneficio}`}
            beneficio={beneficio}
            classes={classes}
            programLogo={programLogo}
          />
        ))}
      </Grid>
    </div>
  );
};

Cuadricula.propTypes = {
  beneficios: PropTypes.array,
  classes: PropTypes.object
};

Cuadricula.defaultProps = {};

export default Cuadricula;
