import React, { PureComponent } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import UploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles.js";

class UploadReportes extends PureComponent {
  render() {
    const { classes, uploadReporte } = this.props;
    return (
      <Grid container item xs={12} spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Importar transacciones
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            onClick={() =>
              document.getElementById("uploadReporteComercios").click()
            }
          >
            <UploadIcon className={classes.leftIcon} />
            Importar transacciones cuenta madre
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            onClick={() =>
              document.getElementById("uploadReporteSucursales").click()
            }
          >
            <UploadIcon className={classes.leftIcon} />
            Importar transacciones sucursales
          </Button>
        </Grid>
        <input
          id="uploadReporteComercios"
          type="file"
          style={{ display: "none" }}
          required
          onChange={uploadReporte}
        />
        <input
          id="uploadReporteSucursales"
          type="file"
          style={{ display: "none" }}
          required
          onChange={uploadReporte}
        />
      </Grid>
    );
  }
}

UploadReportes.displayName = "UploadReportes";

export default withStyles(styles)(UploadReportes);
