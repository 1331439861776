import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import AprobacionReconocimiento from "./AprobacionReconocimiento";
import styles from "./styles";
import withPrograma from "js/hoc/withPrograma";
import { withRouter } from "react-router-dom";
import { graphql, withApollo } from "react-apollo";
import ReconocimientoGraphQL from "js/graphql/resolvers/reconocimiento.resolver";
import UserQraphQL from "js/graphql/resolvers/usuario.resolver";
import { formValueSelector, reduxForm } from "redux-form";
import connect from "react-redux/es/connect/connect";
import { withNotificationContext } from "js/notification-context";
const selector = formValueSelector("aprobarReconocimientoForm");

const enhance = compose(
  withPrograma,
  withNotificationContext,
  withRouter,
  withApollo,
  graphql(UserQraphQL.queries.getMiUsuarioCompleto, {
    options: () => ({
      fetchPolicy: "cache-first"
    }),
    props: ({ data }) => {
      const { loading, miUsuario } = data;
      if (loading) return;
      return {
        usuario: miUsuario
      };
    }
  }),
  graphql(ReconocimientoGraphQL.queries.getReconocimientoById, {
    options: ({
      computedMatch: {
        params: { idReconocimiento }
      }
    }) => ({
      fetchPolicy: "network-only",
      variables: {
        _id: idReconocimiento
      }
    }),
    props: ({ data }) => {
      const { loading, getReconocimiento } = data;
      if (loading) return;
      return {
        reconocimiento: getReconocimiento
      };
    }
  }),
  withStyles(styles),
  connect(state => selector(state, "observacion", "mensaje")),
  reduxForm({
    form: "aprobarReconocimientoForm",
    initialValues: {},
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
  })
);

export default enhance(AprobacionReconocimiento);
