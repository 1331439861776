import { compose, pure, lifecycle } from "recompose";
import { pick } from "lodash";
import { graphql } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateTemplateBuilder } from "js/redux/template/index.js";
import {
  CHANGE_TEMPLATE_BUILDER,
  CHANGE_ID_SEGMENTO,
  CLEAR_TEMPLATE
} from "js/redux/template/constants.js";
// import withPrograma from "js/hoc/withPrograma";
import TemplateGraphQL from "js/graphql/resolvers/template.resolver.js";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import withPrograma from "js/hoc/withPrograma";

const mapStateToProps = state => ({
  template: state.templateBuilder
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateTemplateBuilder: updateTemplateBuilder({
    dispatch,
    type: CHANGE_TEMPLATE_BUILDER
  }),
  updateIdSegmento: updateTemplateBuilder({
    dispatch,
    type: CHANGE_ID_SEGMENTO
  }),
  clearTemplate: updateTemplateBuilder({
    dispatch,
    type: CLEAR_TEMPLATE
  })
});

const enhance = compose(
  pure,
  withRouter,
  withPrograma,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycle({
    componentWillUnmount() {
      this.props.clearTemplate();
    }
  }),
  graphql(AppQraphQL.queries.getProgramaById, {
    options: props => ({
      options: { fetchPolicy: "no-cache" },
      variables: {
        idPrograma: parseInt(props.match.params.id || props.idPrograma, 10)
      }
    }),
    props: ({ data: { getProgramas }, ownProps }) => ({
      infoPrograma: getProgramas
        ? pick(getProgramas.itemsPagina[0], ["nombre", "url", ""])
        : { nombre: "", url: "" }
    })
  }),
  graphql(TemplateGraphQL.queries.getTemplate, {
    options: () => ({
      options: { fetchPolicy: "no-cache" },
      variables: { skip: true }
    }),
    props: ({ data, ownProps }) => {
      const { refetch, getTemplate, getSegmentos } = data;
      if (getTemplate && getSegmentos && refetch) {
        const templateBuilder = JSON.parse(getTemplate.template).templateDev
          .template;
        ownProps.updateTemplateBuilder(templateBuilder);
        ownProps.updateIdSegmento(getSegmentos[0].idSegmento);
      }
      return {
        onGetTemplate: idSegmento => refetch({ idSegmento })
      };
    }
  })
);

export default enhance;
