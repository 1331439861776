import React, { PureComponent } from "react";
import { css } from "emotion";
import Table from "js/components/Shared/Tabla";
import { WithCurrency, Estado } from "js/components/Shared/Tabla/TableCells";
import advancedFilter from "js/components/TablaProducto/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/producto.resolver";
import { get } from "lodash";
import { getTranslations } from "js/locale";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import { numberWithCommas } from "js/utils";
import capitalize from "lodash/capitalize";
import {
  viewAction,
  editAction,
  showSucursalAction
} from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";

const defaultHeader = {
  title: "Maestro de productos",
  new: {
    title: "Nuevo",
    perms: [Roles.PRODUCTO_W]
  },
  import: {
    action: AppQraphQLImport.queries.importarProductos,
    perms: [Roles.PRODUCTO_W],
    model: "Productos_Modelo.csv"
  },
  export: {
    path: "productos",
    perms: [Roles.PRODUCTO_R]
  }
};

const columnData = [
  {
    id: "idProducto",
    label: "ID",
    type: "custom",
    isNumeric: true,
    parser: id => (id ? numberWithCommas(id) : "--")
  },
  { id: "nombre", label: "Nombre del producto" },
  {
    id: "tipoProducto",
    label: "Tipo",
    type: "custom",
    parser: tipoProducto => getTranslations("es", tipoProducto)
  },
  { id: "proveedor_nombreFantasia", label: "Proveedor" },
  {
    id: "proveedor",
    label: "País del proveedor",
    type: "custom",
    classes: css`
      width: 100px;
    `,
    parser: proveedor => get(proveedor, ["pais", "descripcion"], "--")
  },
  {
    id: "precioSugerido",
    label: "Precio sugerido",
    isNumeric: true,
    type: "custom",
    classes: css`
      width: 70px;
    `,
    parser: (precioSugerido, item) => (
      <WithCurrency
        value={precioSugerido}
        item={item}
        path={["proveedor", "pais", "moneda"]}
      />
    )
  },
  {
    id: "stock",
    label: "Stock",
    isNumeric: true,
    type: "custom",
    parser: stock => (stock ? numberWithCommas(stock) : "--")
  },
  // { id: "estado", label: "Estado" },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "idProducto" };

class Tabla extends PureComponent {
  render() {
    const view = params => ({
      ...viewAction({ ...params, history: this.props.history })
    });
    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.PRODUCTO_W]
      })
    });
    const sucursal = params => ({
      ...showSucursalAction({ ...params, history: this.props.history }),
      onclick: () =>
        this.props.history.push(
          `${window.location.pathname}/${params.itemId}/${
            params.item.proveedor.idProveedor
          }/sucursal`
        )
    });
    return (
      <Table
        storage="FiltroProducto"
        query={AppQraphQL.queries.getPaginaProductos}
        defaultHeader={defaultHeader}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getProductos}
        actionsCustom={[view, edit, sucursal]}
      />
    );
  }
}

export default Tabla;
