import React from "react";
import { branch } from "recompose";
import { Delete, ImagePlus } from "mdi-material-ui";

import placeholder from "../../../../../images/person_200x200.png";
import withStyles from "./styles";

import { getUrl } from "js/utils";

export const Avatar = branch(
  ({ url }) => url,
  () => ({ url }) => (
    <img src={getUrl(url)} style={{ maxWidth: "200px" }} alt="avatar" />
  ),
  () => () => <img src={placeholder} alt="avatar" />
)();

// export const ProductImages = withStyles(
//   branch(
//     ({ productImages }) => productImages.length,
//     () => ({ productImages, onRemoveImage, classes, disabled }) => (
//       <div className={classes.List}>
//         {productImages.map(url => (
//           <div className={classes.Container} key={url}>
//             <div className={classes.DeleteButton}>
//               <Delete
//                 className={classes.DeleteIcon}
//                 onClick={() => !disabled && onRemoveImage(url)}
//               />
//             </div>
//             <img src={url} alt="producto" />
//           </div>
//         ))}
//       </div>
//     ),
//     () => ({ classes }) => (
//       <div className={classes.NoChildren}>
//         <ImagePlus fontSize="large" />
//       </div>
//     )
//   )()
// );

export const ItemImages = withStyles(
  branch(
    ({ images }) => images.length,
    () => ({ images, onRemoveImage, classes, disabled, alt }) => (
      <div className={classes.List}>
        {images.map(url => (
          <div className={classes.Container} key={url}>
            <div className={classes.DeleteButton}>
              <Delete
                className={classes.DeleteIcon}
                onClick={() => !disabled && onRemoveImage(url)}
              />
            </div>
            <img src={url} alt={alt} />
          </div>
        ))}
      </div>
    ),
    () => ({ classes }) => (
      <div className={classes.NoChildren}>
        <ImagePlus fontSize="large" />
      </div>
    )
  )()
);
