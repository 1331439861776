import React, { Fragment } from "react";
import { _t, LOCAL } from "js/utils/TranslationService";
import { groupBy } from "lodash";
import { compose, toRenderProps, withProps } from "recompose";
import moment from "moment";
import _ from "lodash";

import {
  CheckboxField,
  DatePicker,
  EmailField,
  InputNumberField,
  InputTextField,
  ListField,
  Ubicacion,
  ListMultipleField
} from "js/components/Shared/Form/Fields";

export const FieldsProvider = toRenderProps(
  withProps(({ ...props }) =>
    compose(
      ({ groups }) => ({
        groups,
        groupTypes: Object.keys(groups)
      }),
      ({ definitions, type }) => ({
        groups: groupBy(definitions, type)
      }),
      ({ definitions = [], type = "grupo", classes }) => ({
        definitions: definitions.map(({ key, ...rest }) => ({
          fieldName: key,
          classes,
          ...rest
        })),
        type
      })
    )(props)
  )
);

const getUbicacionExtraProps = (fieldName, required, fieldsToShow) => {
  const showAll =
    fieldsToShow.length === 0 ||
    (fieldsToShow.length === 1 && fieldsToShow[0] === "");
  return {
    pais: {
      name: `${fieldName}.idPais`,
      required,
      show: showAll ? true : fieldsToShow.includes("PAIS")
    },
    zona: {
      name: `${fieldName}.idZona`,
      required,
      show: showAll ? true : fieldsToShow.includes("ZONA")
    },
    localidad: {
      name: `${fieldName}.idLocalidad`,
      required,
      show: showAll ? true : fieldsToShow.includes("LOCALIDAD")
    },
    direccion: {
      name: `${fieldName}.direccion`,
      required,
      show: showAll ? true : fieldsToShow.includes("DIRECCION")
    },
    codPostal: {
      name: `${fieldName}.codPostal`,
      required,
      show: showAll ? true : fieldsToShow.includes("CODIGO_POSTAL")
    }
  };
};

export const getBooleanLinkLabel = field => {
  let link = null;
  let label = <span>{field.label}</span>;
  if (field.value && field.value[0]) {
    const linkValues = field.value[0].split("|");
    link = linkValues[0];
    if (linkValues[1] && linkValues[1].length !== 0) {
      const fieldLabel = field.label.split(linkValues[1]);
      label = (
        <span>
          {fieldLabel[0]}{" "}
          <a href={link} target="_blank">
            {linkValues[1]}
          </a>{" "}
          {fieldLabel[1]}
        </span>
      );
    } else {
      label = (
        <span>
          <a href={link} target="_blank">
            {field.label}
          </a>
        </span>
      );
    }
  }
  return label;
};

// Desde el backend el "value" siempre viene como array, y el primer valor será el string que contiene el máx y el min
// Así ... ["min|máx"] ejemplo: ["9999|9999999"]
export const getNumericFieldLimits = (value, limite) => {
  let realValue = getValueAsString(value);
  if (realValue) {
    const limites = realValue.split("|");
    if (limite === "min") {
      return limites[0] !== "" ? parseInt(limites[0]) : null;
    }
    return limites[1] !== "" ? parseInt(limites[1]) : null;
  }
  return null;
};

// Esta función es para tener el valor como string, cuando viene del backend es array, cuando el usuario esta ingresando será un string
export const getValueAsString = value => {
  let realValue = null;
  if (typeof value === "object") {
    if (value.length !== 0 && value[0] !== "") {
      realValue = value[0];
    }
  } else {
    realValue = value;
  }
  return realValue;
};

export const getRulesInputsNumericos = definiciones => {
  const result = {};
  definiciones.forEach(definicion => {
    if (definicion.tipo === "Numerico") {
      const min = getNumericFieldLimits(definicion.value, "min");
      const max = getNumericFieldLimits(definicion.value, "max");
      const numericoDefinition = {};
      if (min) {
        numericoDefinition.minValue = min;
      }
      if (max) {
        numericoDefinition.maxValue = max;
      }
      result[definicion.key] = numericoDefinition;
    }
  });
  return result;
};

// Esta función sirve para tener las validaciones, si existen, de los campos numericos
// Hay que tener las validaciones en el componente que esta llamando al FieldProvider
// ya que si se se generan acá las validaciones, con cada re-render las volverá a crear y eso genera un error de maximum calls
// Para evitarlo ver lso ejemplos de como se armaron estas valicaciones en el profile y el signup form
export const getNumericFieldValidations = (checkRules, rules) => {
  let validations = [];
  for (let ruleName in checkRules) {
    let ruleFunc = rules[ruleName];
    // check if there is a valid validation function for this rule name
    if (ruleFunc) {
      // Push the generated validation to the field-level validations array
      validations.push(ruleFunc(checkRules[ruleName]));
    }
  }
  return validations;
};

export const getField = fieldType =>
  [
    {
      type: "Texto",
      component: field => (
        <InputTextField
          name={field.fieldName}
          label={field.label}
          translation={_t(field.label, LOCAL)}
          placeholder={field.placeholder}
          required={field.requerido}
          classes={field.classes}
          key={field._id}
          disabled={field.disabled}
        />
      )
    },
    {
      type: "Numerico",
      component: field => (
        <InputNumberField
          name={field.fieldName}
          label={field.label}
          translation={_t(field.label, LOCAL)}
          placeholder={field.placeholder}
          required={field.requerido}
          classes={field.classes}
          key={field._id}
          disabled={field.disabled}
          customValidations={
            field.numericDefinitionsValidations
              ? field.numericDefinitionsValidations[field.fieldName]
              : []
          }
        />
      )
    },
    {
      type: "Monto",
      component: field => (
        <InputNumberField
          name={field.fieldName}
          label={field.label}
          isFloat
          translation={_t(field.label, LOCAL)}
          placeholder={field.placeholder}
          required={field.requerido}
          classes={field.classes}
          key={field._id}
          disabled={field.disabled}
          customValidations={
            field.numericDefinitionsValidations
              ? field.numericDefinitionsValidations[field.fieldName]
              : []
          }
        />
      )
    },
    {
      type: "Booleano",
      component: field => (
        <CheckboxField
          name={field.fieldName}
          label={field.label}
          translation={_t(field.label, LOCAL)}
          required={field.requerido}
          classes={field.classes}
          key={field._id}
          disabled={field.disabled}
        />
      )
    },
    {
      type: "Booleano_Link",
      component: field => {
        return (
          <CheckboxField
            name={field.fieldName}
            label={getBooleanLinkLabel(field)}
            translation={_t(field.label, LOCAL)}
            required={field.requerido}
            classes={field.classes}
            key={field._id}
            disabled={field.disabled}
          />
        );
      }
    },
    {
      type: "Lista",
      component: field => (
        <ListField
          name={field.fieldName}
          translation={_t(field.label, LOCAL)}
          required={field.requerido}
          classes={field.classes}
          options={field.value}
          // t_options={field.value}
          key={field._id}
          label={field.label}
          disabled={field.disabled}
        />
      )
    },
    {
      type: "Lista_Multiple",
      component: field => (
        <ListMultipleField
          name={field.fieldName}
          translation={_t(field.label, LOCAL)}
          required={field.requerido}
          classes={field.classes}
          options={field.value}
          key={field._id}
          label={field.label}
          disabled={field.disabled}
        />
      )
    },
    {
      type: "Email",
      component: field => (
        <EmailField
          name={field.fieldName}
          label={field.label}
          translation={_t(field.label, LOCAL)}
          placeholder={field.placeholder}
          required={field.requerido}
          classes={field.classes}
          key={field._id}
          disabled={field.disabled}
        />
      )
    },
    {
      type: "Fecha",
      component: field => (
        <DatePicker
          name={field.fieldName}
          label={field.label}
          translation={_t(field.label, LOCAL)}
          placeholder={field.placeholder}
          required={field.requerido}
          key={field._id}
          maxDate={moment()}
          disabled={field.disabled}
        />
      )
    },
    {
      type: "Ubicacion",
      component: field => (
        <Ubicacion
          label={_t(field.label, LOCAL)}
          formName={field.formName}
          breakpoints={field.breakpoints}
          extraProps={{
            ...field.extraProps,
            ...getUbicacionExtraProps(
              field.fieldName,
              field.requerido,
              field.value
            )
          }}
          disabled={field.disabled}
        />
      )
    }
  ].find(({ type }) => type === fieldType).component;

const getValueAutocomplete = (data, stateValue) =>
  stateValue !== undefined
    ? data.find(p => p.label === stateValue)
    : { value: undefined, label: "" };

export const fieldGenerator = ({
  group,
  wrapper: Wrapper = Fragment,
  extraProps = {},
  visible,
  requerido,
  formName = "",
  disabled,
  numericDefinitionsValidations
}) =>
  group
    .filter(v => {
      if (visible === "showAll") {
        return true;
      }
      return v[visible];
    })
    .map(({ tipo, ...field }) => (
      <Wrapper key={field.fieldName} {...extraProps[tipo]}>
        {getField(tipo)({
          ...field,
          ...extraProps[tipo],
          formName,
          requerido: field[requerido],
          disabled,
          numericDefinitionsValidations
        })}
      </Wrapper>
    ));
