import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  reduxForm,
  formValueSelector,
  change,
  getFormMeta,
  FieldArray
} from "redux-form";
import {
  Grid,
  FormControl,
  Button,
  Icon,
  Tooltip,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Info } from "@material-ui/icons";
import { styles } from "js/components/Shared/Form/styles.js";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import ImageUploader, { ItemImages } from "js/components/Shared/ImageUploader";
import {
  InputTextField,
  InputNumberField,
  ListField,
  TypeAhead
} from "js/components/Shared/Form/Fields";
import { _t } from "js/utils/TranslationService";
import _ from "lodash";

const renderPuntosPorSegmento = ({ fields, segmentos }) => (
  <Grid container spacing={16}>
    {segmentos &&
      segmentos.length !== 0 && (
        <Fragment>
          {fields.length < segmentos.length && (
            <Tooltip title="Agregar item" placement="right-end">
              <Icon color="secondary" onClick={() => fields.push({})}>
                add_circle
              </Icon>
            </Tooltip>
          )}

          {fields.map((puntosPorSegmento, index) => {
            return (
              <Fragment key={index}>
                <Grid xs={11} item>
                  <Paper style={{ width: "100%", padding: "15px" }}>
                    <Grid container spacing={16}>
                      <Grid item xs={12} md={6}>
                        <ListField
                          name={`${puntosPorSegmento}.idSegmento`}
                          label="Segmento"
                          options={segmentos.map(segmento => ({
                            value: segmento.idSegmento,
                            label: segmento.label
                          }))}
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <InputNumberField
                          name={`${puntosPorSegmento}.puntos`}
                          label="Puntos"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Tooltip title="Quitar item" placement="right-start">
                  <Icon color="action" onClick={() => fields.splice(index, 1)}>
                    remove_circle
                  </Icon>
                </Tooltip>
              </Fragment>
            );
          })}
        </Fragment>
      )}
  </Grid>
);

// Función de validación para números enteros positivos
const validatePositiveInteger = value => {
  if (!Number.isInteger(Number(value)) || Number(value) <= 0) {
    return "Debe ser un número entero positivo";
  }
  return undefined;
};

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fechaNotChanged: true,
      fechaInicio: null,
      fechaFin: null,
      fechaCierre: null,
      disabledPotenciadorCampana: true,
      disabledPotenciadorProducto: true,
      isRound: true
    };
  }
  componentDidUpdate(prevProp, prevState) {
    if (prevProp.initialValues !== this.props.initialValues) {
      let { initialValues } = this.props;

      if (
        prevProp.initialValues.tipo !== initialValues.tipo &&
        initialValues.tipo === "RETAIL"
      ) {
        this.setState({
          disabledPotenciadorCampana: true,
          disabledPotenciadorProducto: true
        });
      }

      if (_.isNull(initialValues.potenciadorCampana)) {
        this.setState({
          disabledPotenciadorCampana: true
        });
      } else {
        this.setState({
          disabledPotenciadorCampana: false,
          potenciadorCampana: initialValues.potenciadorCampana
        });
      }

      if (_.isNull(initialValues.potenciadorProducto)) {
        this.setState({
          disabledPotenciadorProducto: true
        });
      } else {
        this.setState({
          disabledPotenciadorProducto: false,
          potenciadorProducto: initialValues.potenciadorProducto
        });
      }

      if (_.isNull(initialValues.isRound)) {
        this.setState({
          isRound: true
        });
      } else {
        this.setState({
          isRound: initialValues.isRound
        });
      }
    }
  }

  handleFechaInicioChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaNotChanged: false,
      fechaInicio: date
    });
  };

  handleFechaFinChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaNotChanged: false,
      fechaFin: date
    });
  };

  handleFechaCierreChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaNotChanged: false,
      fechaCierre: date
    });
  };

  //handleIsRoundChange
  handleChangeIsRound = key => event => {
    this.props.handleIsRoundChange(key)(event.target.checked);
    this.props.change(key, event.target.checked);
    this.setState({
      isRound: event.target.checked
    });
  };

  handleCampanasRelacionadasChange = values => {
    const ids = values.map(value => value.value);
    this.props.change("campanasRelacionadas", ids);
  };

  handlePotenciadorCampanaChange = () => {
    this.setState(prevState => ({
      disabledPotenciadorCampana: !prevState.disabledPotenciadorCampana
    }));
  };

  handlePotenciadorProductoChange = () => {
    this.setState(prevState => ({
      disabledPotenciadorProducto: !prevState.disabledPotenciadorProducto
    }));
  };

  handleTipoChange = () => {
    this.setState({
      disabledPotenciadorCampana: true,
      disabledPotenciadorProducto: true
    });
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      id,
      initialValues,
      tipo,
      onChangeImage,
      onRemoveImage,
      campanaImages,
      campanasInput,
      campanasRelacionadas,
      gruposProductos,
      segmentos
    } = this.props;

    return (
      <form className={classes.container} onSubmit={handleSubmit}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {initialValues.nombre ? "Editar campaña" : "Nueva campaña"}
            </Typography>
          </Grid>

          {errors &&
            errors.length !== 0 && (
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors.map(e => (
                    <li>{e.message}</li>
                  ))}
                </ul>
              </Grid>
            )}

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <InputTextField
                name="nombre"
                label={_t("Nombre campaña")}
                disabled={disabled}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={8}>
            <FormControl className={classes.field}>
              <InputTextField
                name="descripcion"
                label={_t("Descripción campaña")}
                disabled={disabled}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <DatePicker
                name={"fechaInicio"}
                dateFormat="DD/MM/YYYY"
                label={_t("Fecha inicio campaña")}
                autoOk
                disabled={disabled}
                input={{
                  value:
                    this.state.fechaInicio !== "" && this.state.fechaInicio
                      ? new Date(this.state.fechaInicio)
                      : initialValues.fechaInicio
                        ? initialValues.fechaInicio
                        : null,
                  onChange: this.handleFechaInicioChange("fechaInicio")
                }}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <DatePicker
                name={"fechaFin"}
                dateFormat="DD/MM/YYYY"
                label={_t("Fecha fin campaña")}
                autoOk
                disabled={disabled}
                input={{
                  value:
                    this.state.fechaFin !== "" && this.state.fechaFin
                      ? new Date(this.state.fechaFin)
                      : initialValues.fechaFin
                        ? new Date(initialValues.fechaFin)
                        : null,
                  onChange: this.handleFechaFinChange("fechaFin")
                }}
                minDate={
                  this.state.fechaInicio
                    ? moment(this.state.fechaInicio).add(1, "d")
                    : initialValues.fechaInicio
                      ? moment(initialValues.fechaInicio).add(1, "d")
                      : new Date()
                }
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <DatePicker
                name={"fechaCierre"}
                dateFormat="DD/MM/YYYY"
                label={_t("Fecha cierre campaña")}
                autoOk
                disabled={disabled}
                input={{
                  value:
                    this.state.fechaCierre !== "" && this.state.fechaCierre
                      ? new Date(this.state.fechaCierre)
                      : initialValues.fechaCierre
                        ? new Date(initialValues.fechaCierre)
                        : null,
                  onChange: this.handleFechaCierreChange("fechaCierre")
                }}
                minDate={
                  this.state.fechaFin
                    ? moment(this.state.fechaFin)
                    : initialValues.fechaFin
                      ? moment(initialValues.fechaFin)
                      : new Date()
                }
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <InputTextField
                name="puntosTodos"
                label={_t("Bonus por usuario")}
                disabled={disabled}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <ListField
              name="tipo"
              label={_t("Tipo de campaña")}
              options={[
                { label: "Simple", value: "SIMPLE" },
                { label: "Compuesta", value: "COMPUESTO" },
                { label: "Retail", value: "RETAIL" }
              ]}
              disabled={disabled}
              required
              onChange={this.handleTipoChange}
            />
          </Grid>

          {tipo === "COMPUESTO" &&
            campanasInput &&
            campanasInput.length !== 0 && (
              <Grid item xs={12}>
                <TypeAhead
                  name="campanasRelacionadas"
                  suggestions={{
                    campanas: campanasInput.map(campana => ({
                      value: campana._id,
                      label: campana.nombre
                    }))
                  }}
                  path={{ path: "campanas", value: "value", label: "label" }}
                  onChange={this.handleCampanasRelacionadasChange}
                  value={
                    campanasRelacionadas || initialValues.campanasRelacionadas
                  }
                  placeholder={_t("Campañas relacionadas")}
                  label={_t("Campañas relacionadas")}
                  classes={classes}
                  multiple={true}
                  labelOrder={false}
                />
              </Grid>
            )}
          {tipo === "RETAIL" ? (
            <>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.field}>
                  <InputTextField
                    name="tasaDevolucion"
                    label={_t("Tasa de devolución")}
                    disabled={false}
                    value={1}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isRound"
                      checked={this.state.isRound}
                      onChange={this.handleChangeIsRound("isRound")}
                      color="primary"
                    />
                  }
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {_t("¿Redondear?")}
                      <Tooltip
                        title="Si activás esta opción, los decimales de 0,5 o más se redondean hacia arriba y los menores hacia abajo; si no la activás, se usa solo el número entero."
                        placement="right"
                      >
                        <Info
                          color="secondary"
                          style={{ marginLeft: 4, fontSize: 16 }}
                        />
                      </Tooltip>
                    </div>
                  }
                />
              </Grid>
              {this.state.disabledPotenciadorCampana ? (
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!this.state.disabledPotenciadorCampana}
                        onChange={this.handlePotenciadorCampanaChange}
                        color="primary"
                      />
                    }
                    label={_t("Habilitar potenciador de campaña")}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={4}>
                  <FormControl className={classes.field}>
                    <InputTextField
                      name="potenciadorCampana"
                      label={_t("Potenciador de campaña")}
                      disabled={false}
                      value={1}
                      validate={validatePositiveInteger}
                    />
                  </FormControl>
                </Grid>
              )}
            </>
          ) : null}

          <Grid item xs={12}>
            <Typography variant="h5">{_t("Imagen de la campaña")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <ImageUploader
              onChangeImage={onChangeImage}
              disableClick={true}
              disabled={disabled || campanaImages.length === 1}
              medidasRecomendadas={"556 x 556"}
            >
              <ItemImages
                images={campanaImages}
                onRemoveImage={onRemoveImage}
                disabled={disabled}
                alt={"imagen-campaña"}
              />
            </ImageUploader>
          </Grid>

          {(tipo === "SIMPLE" || tipo === "RETAIL") && (
            <Fragment>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {_t("Productos incluidos")}
                </Typography>
              </Grid>

              {gruposProductos &&
                gruposProductos.length !== 0 && (
                  <Grid item xs={12} sm={4}>
                    <ListField
                      name="grupoProductos"
                      label={_t("Grupo de SKUs incluidos")}
                      options={gruposProductos.map((grupo, index) => {
                        return {
                          label: `${grupo.nombre} (${
                            grupo.productos.length
                          } productos)`,
                          value: grupo.idGrupo
                        };
                      })}
                      disabled={disabled}
                      required={tipo !== "RETAIL"}
                    />
                  </Grid>
                )}
              {tipo === "RETAIL" ? (
                <Grid item xs={12} sm={4}>
                  {this.state.disabledPotenciadorProducto ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!this.state.disabledPotenciadorProducto}
                          onChange={this.handlePotenciadorProductoChange}
                          color="primary"
                        />
                      }
                      label={_t("Habilitar potenciador de producto")}
                    />
                  ) : (
                    <FormControl className={classes.field}>
                      <InputTextField
                        name="potenciadorProducto"
                        label={_t("Potenciador de producto")}
                        disabled={false}
                        value={this.state.potenciadorCampana}
                        validate={validatePositiveInteger}
                      />
                    </FormControl>
                  )}
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Typography variant="h6">Puntos por segmento</Typography>
              </Grid>

              <Grid item xs={12}>
                <FieldArray
                  name="fields"
                  component={renderPuntosPorSegmento}
                  segmentos={segmentos}
                />
              </Grid>

              {initialValues.idCampana &&
                initialValues.idCampana !== "new" && (
                  <Grid item xs={12}>
                    <Button
                      type="button"
                      variant="contained"
                      size="large"
                      color="primary"
                      className={classes.button}
                      style={{ marginRight: 20 }}
                      onClick={() =>
                        history.push(
                          `/admin/programa/${id}/campanas/${
                            initialValues.idCampana
                          }/objetivos`
                        )
                      }
                    >
                      {_t("Objetivos")}
                    </Button>
                  </Grid>
                )}
            </Fragment>
          )}

          <Grid className={classes.gridButton} item xs={12}>
            <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              style={{ marginRight: 20 }}
              onClick={() => history.push(`/admin/programa/${id}/campanas`)}
            >
              {_t("Cancelar")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              disabled={
                disabled ||
                (pristine && this.state.fechaNotChanged) ||
                submitting
              }
            >
              {_t("Guardar")}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

Form = reduxForm({
  form: "formCampanaGenerica",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formCampanaGenerica");

Form = connect(
  state => ({
    formMeta: getFormMeta("formCampanaGenerica")(state),
    tipo: selector(state, "tipo"),
    campanasRelacionadas: selector(state, "campanasRelacionadas")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formCampana", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
