import { USER_ROLES as Roles } from "js/models/User";
import { kibanaUrl } from "js/constants";
import { getEnv } from "js/utils/Helper";
import Legales from "js/pages/Admin/Programa/Legales";
import {
  Template,
  Layout,
  Landing as LandingEditor
} from "js/pages/TemplateBuilder2";

export const getUrl = ({
  idPrograma,
  idSegmento,
  idUsuario,
  from,
  to,
  id,
  modulo
}) => {
  let url = `${kibanaUrl}?id=${id}`;
  if (idPrograma) url += `&idPrograma=${idPrograma}`;
  if (idSegmento) url += `&idSegmento=${idSegmento}`;
  if (idUsuario) url += `&idUsuario=${idUsuario}`;
  if (from) url += `&from=${from}`;
  if (to) url += `&to=${to}`;
  if (modulo) url += `&modulo=${encodeURIComponent(modulo)}`;
  url += `&env=${getEnv}`;
  return url;
};

export default [
  {
    number: 1,
    tipo: "legales",
    name: "Legales",
    permissions: [Roles.LEGALES_W],
    componente: Legales
  },
  {
    number: 2,
    tipo: "template",
    name: "Template",
    permissions: [Roles.TEMPLATE_W],
    componente: Template
  },
  {
    number: 3,
    tipo: "layout",
    name: "Layout",
    permissions: [Roles.LAYOUT_W],
    componente: Layout
  },
  {
    number: 4,
    tipo: "landing",
    name: "Landing",
    permissions: [Roles.LANDING_W],
    componente: LandingEditor
  }
];
