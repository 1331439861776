import React from "react";
import { Field } from "redux-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import { RadioGroup } from "redux-form-material-ui";

import { styles } from "../styles.js";

const RadioWithLabelAndError = inputProps => {
  const {
    meta: { touched, error },
    label,
    children
  } = inputProps;

  return (
    <FormControl error={Boolean(touched && error)} fullWidth>
      {label}
      <RadioGroup {...inputProps} onChange={inputProps.input.onChange}>
        {children}
      </RadioGroup>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const RadioField = ({ name, label, classes, required, disabled, options }) => (
  <Field
    component={RadioWithLabelAndError}
    name={name}
    label={label}
    className={classes ? classes.field : styles.field}
    required={required}
    style={{ flexDirection: "row" }}
  >
    {options.map((option, i) => (
      <FormControlLabel
        key={i}
        label={option.label}
        value={option.value}
        control={<Radio disabled={disabled} />}
      />
    ))}
  </Field>
);

export default RadioField;
