import React from "react";
import Icon from "@material-ui/icons/Stop";
import client from "js/App/client.graphql.js";
import AppQraphQL from "js/graphql/resolvers/acciones.resolver";
import base from "./Base";
import AuthService from "js/utils/AuthService";

export default params => ({
  ...base(),
  label: "Finalizar",
  icon: <Icon />,
  show: () =>
    params.item.estado === "ACTIVA" && AuthService.hasPerms(params.perms),
  tooltip: "Finalizar acción",
  onclick: () =>
    client
      .mutate({
        mutation: AppQraphQL.mutations.finalizarAccion,
        variables: { idAccion: params.itemId },
        errorPolicy: "all"
      })
      .then(res => {
        res.errors && res.errors.length > 0
          ? params.openNotification(res.errors[0].message)
          : params.openNotification(
              "La acción ha sido finalizada correctamente"
            );
      })
      .catch(() => {
        params.openNotification("Error al intentar finalizar la acción");
      })
});
