import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, change, getFormMeta } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import moment from "moment";

import {
  ListField,
  InputTextField,
  InputNumberField,
  EmailField,
  Ubicacion,
  DatePicker
} from "js/components/Shared/Form/Fields";

import styles from "./styles";
import { _t } from "js/utils/TranslationService";

class Form extends PureComponent {
  render() {
    const {
      initialValues,
      pristine,
      handleSubmit,
      submitting,
      classes,
      history,
      disabled,
      errors,
      idUsuario,
      cargoOptions,
      domicilioLaboralProps,
      domicilioPersonalProps
    } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {disabled ? "Ver " : idUsuario === "new" ? "Nuevo " : "Modificar "}
            Usuario
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            {errors && (
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <ul className={classes.alertaPerfilTexto}>
                    {errors.map((e, i) => (
                      <li key={i}>{e.message}</li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={16}>
              <Grid item xs={12} sm={4}>
                <InputTextField
                  name="username"
                  label="DNI"
                  autoComplete="off"
                  disabled={disabled || initialValues.username}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputTextField
                  name="nombre"
                  label="Nombre"
                  autoComplete="off"
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputTextField
                  name="apellido"
                  label="Apellido"
                  autoComplete="off"
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <EmailField
                  name="email"
                  label="Email"
                  placeholder="Email"
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputNumberField
                  name="celular"
                  label="Celular"
                  autoComplete="off"
                  disabled={disabled}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DatePicker
                  name="fecha_de_nacimiento"
                  label="Fecha de Nacimiento"
                  maxDate={moment()}
                  disabled={disabled}
                  required={false}
                />
              </Grid>
              {cargoOptions && (
                <Grid item xs={12} sm={4}>
                  <ListField
                    name="cargo"
                    label="Cargo"
                    required={false}
                    options={cargoOptions}
                    disabled={disabled}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={4}>
                <InputTextField
                  name="código_de_vendedor"
                  label="Código de vendedor"
                  autoComplete="off"
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Ubicacion
                  label="Domicilio Personal"
                  formName={"formUsuarioUnilever"}
                  breakpoints={{ xs: 12, sm: 4 }}
                  extraProps={{
                    ...domicilioPersonalProps
                  }}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <Ubicacion
                  label="Domicilio Laboral"
                  formName={"formUsuarioUnilever"}
                  breakpoints={{ xs: 12, sm: 4 }}
                  extraProps={{
                    ...domicilioLaboralProps
                  }}
                  disabled={disabled}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justify="flex-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={e => history.push(`/usuarios-mis-ventas`)}
                  >
                    {_t("Volver")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={pristine || submitting}
                    className={classes.button}
                  >
                    {_t("Guardar")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

Form = reduxForm({
  form: "formUsuarioUnilever",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

Form = connect(
  state => ({
    formMeta: getFormMeta("formUsuarioUnilever")(state)
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formUsuarioUnilever", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
