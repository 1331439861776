import React from "react";
import PlusIcon from "@material-ui/icons/AddOutlined";
import AuthService from "js/utils/AuthService";
import base from "./Base";
import DialogAddCanjeALote from "js/pages/Admin/Canje/TablaCanje/DialogAddCanjeALote";
export default params => ({
  ...base(),
  label: "Agregar a lote",
  icon: <PlusIcon />,
  tooltip: "Agregar a lote",
  show: () =>
    AuthService.hasPerms(params.perms) &&
    !params.item.numeroLote &&
    params.item.estado !== "CANCELADO" &&
    params.item.estado !== "EN_PAUSA",
  dialog: (
    <DialogAddCanjeALote
      handleClose={params.togglePopUp}
      idCanje={params.itemId}
      onTableChange={params.onTableChange}
    />
  )
});
