import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Quagga from "quagga";
import "./barcodeScanner.css";

class BarcodeScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scannerOptions: {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          constraints: {
            width: { min: 640 },
            height: { min: 480 },
            facingMode: "environment",
            aspectRatio: { min: 1, max: 2 }
          }
        },
        locator: {
          patchSize: "medium",
          halfSample: true
        },
        numOfWorkers: 2,
        frequency: 10,
        decoder: {
          readers: [
            {
              format: "code_128_reader",
              config: {}
            }
          ],
          multiple: false
        },
        locate: true
      },
      cameraOptions: [],
      cameraType: "",
      results: []
    };
  }

  componentDidMount() {
    this.initCameraSelection();
    this.startScanner();
  }

  startScanner = () => {
    Quagga.init(this.state.scannerOptions, err => {
      if (err) {
        console.log(err);
        return;
      }

      Quagga.start();
    });

    Quagga.onProcessed(function(result) {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute("width")),
            parseInt(drawingCanvas.getAttribute("height"))
          );
          result.boxes
            .filter(function(box) {
              return box !== result.box;
            })
            .forEach(function(box) {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: "red",
                lineWidth: 2
              });
            });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: "#00F",
            lineWidth: 2
          });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(
            result.line,
            { x: "x", y: "y" },
            drawingCtx,
            { color: "red", lineWidth: 3 }
          );
        }
      }
    });

    Quagga.onDetected(result => {
      const { onScanSuccess } = this.props;
      if (result && result.codeResult && result.codeResult.code) {
        const code = result.codeResult.code;
        Quagga.stop();
        onScanSuccess(code);
      } else {
        console.log("ERROR AL LEER EL CÓDIGO DE BARRAS");
      }
    });
  };

  initCameraSelection = () => {
    return Quagga.CameraAccess.enumerateVideoDevices().then(devices => {
      function pruneText(text) {
        return text.length > 30 ? text.substr(0, 30) : text;
      }
      console.log(devices);
      const options = [];
      let selectedIndex = 0;
      for (let i = 0; i < devices.length; i++) {
        const device = devices[i];
        const label = pruneText(device.label || device.deviceId || device.id);
        if (label.indexOf("back") !== -1) {
          selectedIndex = i;
        }
        options.push({
          label,
          value: device.deviceId || device.id
        });
      }

      this.setState({
        cameraOptions: options,
        cameraType: options[selectedIndex].value
      });
    });
  };

  changeCameraType = e => {
    const value = e.target.value;
    const scannerOptions = this.state.scannerOptions;
    scannerOptions.inputStream.constraints.deviceId = value;
    this.setState({ scannerOptions: scannerOptions, cameraType: value }, () => {
      Quagga.stop();
      this.startScanner();
    });
  };

  render() {
    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <FormControl style={{ marginLeft: 0, marginRight: 0, width: "100%" }}>
            <InputLabel htmlFor="cameraType" id="cameraType">
              Cámara
            </InputLabel>
            <Select
              value={this.state.cameraType}
              onChange={e => this.changeCameraType(e)}
            >
              {this.state.cameraOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <div id="interactive" className="viewport" />
      </Grid>
    );
  }
}

export default BarcodeScanner;
