import React from "react";
import PropTypes from "prop-types";

const estadosMap = (estado, aprobaciones, aprobacionesRRHH) => {
  let estados = {
    PENDIENTE_RESPUESTA: "Pendiente de respuesta",
    PENDIENTE_APROBACION: "Pendiente de aprobación",
    PENDIENTE_AVISO: "Pendiente de aviso",
    RECHAZADA: "Rechazada",
    APROBADA: "Aprobada",
    EN_EDICION: "En Edicion"
  };
  if (estado === "PENDIENTE_APROBACION" || estado === "PENDIENTE_RESPUESTA") {
    if (aprobaciones.length == 0) return "No tiene Jerarquias";
    let aprobacion = aprobaciones.find(
      ({ estado }) => estado === "PENDIENTE_APROBACION"
    );
    let aprobacionAviso = aprobaciones.find(
      ({ estado }) => estado === "PENDIENTE_AVISO"
    );
    let aprobacionEdicion = aprobaciones.find(
      ({ estado }) => estado === "EN_EDICION"
    );
    if (aprobacion) {
      return `Pendiente de aprobación ${
        aprobacion.orden ? aprobacion.orden : ""
      }`;
    }
    if (aprobacionAviso) {
      return estados["PENDIENTE_AVISO"] + " " + aprobacionAviso.orden;
    }
    if (aprobacionEdicion) {
      return estados["EN_EDICION"] + " " + aprobacionEdicion.orden;
    }
  }
  if (aprobacionesRRHH) {
    return estados[aprobacionesRRHH.estado] + " RRHH";
  } else {
    return estados[estado];
  }
};

const EstadoReconocimientos = props => {
  const { estado, aprobaciones, classes, aprobacionesRRHH } = props;
  return estado ? (
    <div className={classes[estado.toLowerCase()]}>
      {estadosMap(estado, aprobaciones, aprobacionesRRHH)}
    </div>
  ) : null;
};

EstadoReconocimientos.propTypes = {
  estado: PropTypes.string
};

export default EstadoReconocimientos;
