import gql from "graphql-tag";

export const setColaDescarga = gql`
  mutation setColaDescarga($ColaDescargas: ColaDescargasCreate!) {
    createColaDescargas(ColaDescargas: $ColaDescargas) {
      _id
    }
  }
`;
export default {
  mutations: { setColaDescarga }
};
