import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AlertDialog from "js/components/Shared/Tabla/EnhancedTable/AlertDialog";
import IdiomaQraphQL from "js/graphql/resolvers/idioma.resolver";
import { withNotificationContext } from "js/notification-context";
import { _t } from "js/utils/TranslationService";

import { styles } from "js/pages/Admin/Programa/Idioma/Table/styles.js";

class SimpleTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      itemSelected: {}
    };
  }

  async hanleDeleteAction() {
    const {
      client,
      match: {
        params: { id }
      }
    } = this.props;

    await client
      .mutate({
        mutation: IdiomaQraphQL.mutations.removeIdiomaDePrograma,
        variables: {
          idPrograma: parseInt(id),
          idIdioma: this.state.itemSelected.idioma._id
        }
      })
      .then(res => {
        this.props.openNotification(`${_t("Idioma eliminado correctamente")}`);
        this.setState({ openDialog: false });
        this.props.refresh();
      })
      .catch(error => {
        if (error.graphQLErrors) {
          this.props.openNotification(error.graphQLErrors[0].message);
        }
      });
  }

  openModal(row) {
    this.setState({
      itemSelected: {
        ...row,
        __typename: "idioma"
      },
      openDialog: true
    });
  }

  render() {
    const { classes, history, rows } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Idioma</TableCell>
              <TableCell>Etiqueta</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              return (
                <TableRow key={row.idioma._id}>
                  <TableCell>{row.idioma.nombre}</TableCell>
                  <TableCell>{row.etiqueta}</TableCell>
                  <TableCell className={cx(classes.columnWidth100)}>
                    <IconButton
                      aria-label="Editar"
                      onClick={e =>
                        history.push(
                          window.location.pathname +
                            "/" +
                            row.idioma._id +
                            "/edit"
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Borrar"
                      onClick={this.openModal.bind(this, row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <AlertDialog
          open={this.state.openDialog}
          itemSelected={this.state.itemSelected}
          hanleDeleteAction={this.hanleDeleteAction.bind(this)}
        />
      </Paper>
    );
  }
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired
};

SimpleTable.defaultProps = {};

const enhance = compose(
  withApollo,
  withNotificationContext,
  withRouter,
  withStyles(styles)
);

export default enhance(SimpleTable);
