import React from "react";
import Icon from "@material-ui/icons/AccountBalanceWalletOutlined";
import base from "./Base";
import AuthService from "js/utils/AuthService";

export default params => ({
  ...base(),
  label: "Ver CC",
  icon: <Icon />,
  tooltip: "Ver CC",
  show: () => AuthService.hasPerms(params.perms),
  onclick: () => {
    let url = window.location.pathname + "/" + params.itemId + "/cc-usuario";
    if (window.location.pathname.indexOf("admin") === -1) {
      url = "/cc-usuarios/usuario/" + params.itemId;
    }
    params.history.push(url);
  }
});
