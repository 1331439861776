export default theme => ({
  catalogo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  resultsTitle: {
    padding: 16
  },
  progress: {
    alignSelf: "center",
    minHeight: 100
  }
});
