import { withStyles } from "@material-ui/core/styles";
import Cuadricula from "./Cuadricula";
import styles from "./styles";
import { compose } from "recompose";
import { withNotificationContext } from "js/notification-context";

const enhance = compose(
  withNotificationContext,
  withStyles(styles)
);

export default enhance(Cuadricula);
