import React from "react";
import PropTypes from "prop-types";
import { cx } from "emotion";

import { _t } from "js/utils/TranslationService";
import Enhancer from "./container";

const defaultCucarda = {
  color: "#F4F4F4",
  background: "#DC2F00",
  label: "Nuevo"
};

const Cucarda = ({ cucardaType, cucardas, classes, extraClass }) => {
  const cucarda = cucardas.find(({ type }) => type === cucardaType);
  const { label, color, background, fontFamily } = cucarda || defaultCucarda;
  return (
    <div className={cx(classes.container, extraClass)}>
      <div className={classes.cucarda} style={{ color }}>
        <span
          className={classes.ribbon}
          style={{ background, borderColor: background, fontFamily }}
        >
          {_t(label)}
        </span>
      </div>
    </div>
  );
};

Cucarda.propTypes = {
  cucardaType: PropTypes.string,
  extraClass: PropTypes.string,
  classes: PropTypes.object,
  cucardas: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      color: PropTypes.string,
      background: PropTypes.string,
      label: PropTypes.string
    })
  )
};

Cucarda.displayName = "Cucarda";

export default Enhancer(Cucarda);
