import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  NoChildren: {
    position: "absolute",
    top: "50%",
    width: "100%",
    color: "#d8d8d8",
    fontWeight: 600,
    textAlign: "center"
  },
  List: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row"
  },
  Container: {
    display: "flex",
    flex: "0 0 auto",
    justifyContent: "flex-start",
    flexDirection: "column",
    margin: "8px",
    backgroundColor: "#d8d8d8",
    padding: "15px",
    borderRadius: "5px",
    "& img": {
      maxWidth: "150px"
    }
  },
  DeleteButton: {
    textAlign: "right",
    marginBottom: "15px"
  },
  DeleteIcon: {
    fill: "#6d6d6d",
    cursor: "pointer"
  }
});

export default withStyles(styles);
