import React from "react";
import { Field } from "redux-form";
import { TextField } from "redux-form-material-ui";
import { isRequired, isNumericGtZero, isInteger } from "js/helpers/validations";
import { _t } from "js/utils/TranslationService";
import {
  normalizeFloat,
  formatFloat,
  intParser
} from "js/pages/Admin/Programa/Edit/helper";

import { styles } from "../styles.js";

const InputNumberField = ({
  name,
  label,
  placeholder,
  required,
  translation,
  classes,
  disabled,
  type,
  isFloat = false,
  decimalNumbers = 2
}) => (
  <Field
    type="text"
    required={required}
    name={name}
    label={translation || _t(label)}
    placeholder={placeholder}
    component={TextField}
    disabled={disabled}
    fullWidth
    format={
      isFloat
        ? formatFloat(decimalNumbers)
        : value => {
            // For integers, return empty string when value is undefined/null
            return value ? parseInt(value, 10).toString() : "";
          }
    }
    normalize={
      isFloat
        ? normalizeFloat(decimalNumbers)
        : value => {
            // For integers, remove all non-digit characters
            const onlyNums = String(value).replace(/[^\d]/g, "");
            return onlyNums === "" ? null : parseInt(onlyNums, 10);
          }
    }
    parse={!isFloat && intParser}
    validate={(() => {
      let validations = [];
      if (required) {
        validations.push(isRequired);
      }
      validations.push(isNumericGtZero);
      if (type === "int") validations.push(isInteger);
      return validations;
    })()}
    InputLabelProps={{ shrink: true }}
    className={classes ? classes.field : styles.field}
  />
);

export default InputNumberField;
