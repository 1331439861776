import React from "react";
import { get } from "lodash";
import { numberWithCommas } from "js/utils";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";

export default [
  {
    id: "numeroLote",
    label: "#Lote",
    type: "custom",
    parser: numeroLote => (numeroLote ? numberWithCommas(numeroLote) : "--")
  },
  {
    id: "fechaCreacion",
    label: "Fecha de creación",
    type: "date"
  },
  {
    id: "cantidadCanjes",
    label: "Canjes",
    isNumeric: true,
    type: "custom",
    parser: cantidadCanjes =>
      cantidadCanjes ? numberWithCommas(cantidadCanjes) : "--"
  },
  {
    id: "cantidadProductos",
    label: "Productos",
    isNumeric: true,
    type: "custom",
    parser: cantidadProductos =>
      cantidadProductos ? numberWithCommas(cantidadProductos) : "--"
  },
  {
    id: "sublotes",
    label: "Sublotes",
    isNumeric: true,
    type: "custom",
    parser: sublotes => sublotes.length
  },
  {
    id: "usuario",
    label: "Creador",
    type: "custom",
    parser: usuario => get(usuario, ["username"], "--")
  },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  {
    id: "fechaFin",
    label: "Fecha de finalización",
    type: "date"
  },
  { id: "actions", label: "Acciones" }
];
