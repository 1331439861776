import React from "react";
import ViewIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import base from "./Base";
import DialogModeracion from "js/pages/Admin/Programa/Moderacion/Tabla/DialogModeracion";
import DialogModeracionFacturaPorPuntos from "js/pages/Admin/Programa/ModeracionFacturaPorPuntos/Tabla/DialogModeracion/DialogModeracion";

export default params => ({
  ...base(),
  label: "Moderar Imagen",
  icon:
    !params.item.modificada &&
    AuthService.hasPerms([
      Roles.MODERACION_W,
      Roles.MODERACION_FACTURA_POR_PUNTOS_W
    ]) ? (
      <EditIcon />
    ) : (
      <ViewIcon />
    ),

  show: () => AuthService.hasPerms(params.perms),
  dialog: params.perms.includes("MODERACION_FACTURA_POR_PUNTOS_R") ? (
    <DialogModeracionFacturaPorPuntos
      openNotification={params.openNotification}
      handleClose={params.togglePopUp}
      factura={params}
      onTableChange={params.onTableChange}
    />
  ) : (
    <DialogModeracion
      openNotification={params.openNotification}
      handleClose={params.togglePopUp}
      factura={params}
      onTableChange={params.onTableChange}
    />
  )
});
