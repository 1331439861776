import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FlagIcon from "@material-ui/icons/Flag";

import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/targets.resolver";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import { deleteAction } from "js/components/Shared/Tabla/ActionCells";
import { editTargetAction } from "js/pages/Unilever/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { setCampanaTargets } from "js/redux/campana/campana.actions";
import { selectCampanaById } from "js/redux/campana/campana.selectors";
import advancedFilter from "js/pages/Unilever/Campana/Targets/Tabla/advancedFilter.js";

const defaultHeader = (idCampana, title, btnsNextTitle) => {
  return {
    title: "Targets " + title,
    btnsNextTitle,
    import: {
      action: AppQraphQLImport.queries.importarTargetsUsuario,
      perms: [Roles.TARGET_USUARIO_W],
      model: "Modelo_Target_Objetivos_Performance.csv",
      tooltip: "Importar targets",
      variables: {
        idCampana: idCampana
      },
      id: "upload-targets"
    },
    export: {
      path: "unileverTargets",
      perms: [Roles.TARGET_USUARIO_R],
      tooltip: "Exportar targets de la campaña"
    }
  };
};
const columnData = [
  { id: "fechaCreacion", label: "Fecha creación", type: "date" },
  { id: "campanaOP_nombre", label: "Campaña" },
  { id: "objetivo_objetivo", label: "Objetivo" },
  { id: "usuario_username", label: "Usuario" },
  { id: "target", label: "Target" },
  // { id: "alcanzado", label: "Alcanzado" },
  { id: "estado", label: "Estado", removeSort: true },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { idCampana, id }
      },
      setCampanaTargets,
      campana
    } = this.props;

    let title = "";

    if (campana) {
      title = campana.nombre;
    }

    const edit = params => ({
      ...editTargetAction({
        ...params,
        history: this.props.history,
        perms: [Roles.TARGET_USUARIO_W]
      })
    });

    const deleteButton = params => ({
      ...deleteAction({
        ...params,
        history: this.props.history,
        perms: [Roles.TARGET_USUARIO_W],
        tooltip: "Eliminar Target",
        label: "Eliminar Target",
        itemName: "Target",
        dialogTitle: "Eliminar target",
        mutation: AppQraphQL.mutations.deleteTarget
      })
    });

    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Volver a Campañas">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(`/admin/programa/${id}/campanas-unilever`)
            }
          >
            <FlagIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.CAMPANA_OP_R]
    });

    return (
      <Table
        storage="FiltroTargets"
        query={AppQraphQL.queries.getTargetsUsuario}
        defaultHeader={defaultHeader(idCampana, title, btnsNextTitle)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getTargetsUsuario}
        actionsCustom={[edit, deleteButton]}
        dispatchAction={setCampanaTargets}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setCampanaTargets: targets => dispatch(setCampanaTargets(targets))
});

const mapStateToProps = (state, ownProps) => {
  return {
    campana: selectCampanaById(state, ownProps.match.params.idCampana)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tabla);
