export default theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  card: {
    maxWidth: 345
  },
  textField: {
    width: 300
  },
  menu: {
    width: 200
  },
  media: {
    // ⚠️ object-fit is not supported by IE11.
    objectFit: "cover"
  },
  fullWidth: {
    width: "100%"
  },
  style: {
    display: "flex",
    minWidth: "10rem",
    [theme.breakpoints.down("xs")]: {
      minWidth: "65vw",
      margin: "10px 0"
    },
    alignSelf: "flex-end",
    marginRight: "15px"
  }
});
