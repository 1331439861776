export const selectCampanaById = (state, campanaId) => {
  const campanas = state.campanas;
  if (campanas && campanas.campanas) {
    const campana = campanas.campanas.find(item => item._id === campanaId);
    return campana;
  }
  return null;
};

export const selectTargetById = (state, targetId) => {
  const campanas = state.campanas;
  if (campanas && campanas.targets) {
    const target = campanas.targets.find(item => item._id === targetId);
    return target;
  }
  return null;
};

export const selectGrupoSKUById = (state, grupoId) => {
  const campanas = state.campanas;
  if (campanas && campanas.gruposSKU) {
    const grupoSKU = campanas.gruposSKU.find(item => item._id === grupoId);
    return grupoSKU;
  }
  return null;
};
