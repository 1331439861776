import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import SortableItem from "./SortableItem";

const SortableList = SortableContainer(
  ({ items, classes, component, position }) => {
    return (
      <List className={classes.ulSortable}>
        {items.map((value, index) => (
          <Grid key={`itemGrid-${index}`} container>
            <Grid item>
              <SortableItem
                position={position.concat([index])}
                indexItem={index}
                index={index}
                value={value}
                component={component}
              />
            </Grid>
          </Grid>
        ))}
      </List>
    );
  }
);

export default SortableList;
