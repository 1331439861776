import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withNotificationContext } from "js/notification-context";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import MyForm from "./MyForm";
import styles from "./styles";

const enhance = compose(
  withApollo,
  withNotificationContext,
  withStyles(styles)
);

export default withRouter(withApollo(enhance(MyForm)));
