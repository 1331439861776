export default theme => ({
  root: {
    marginTop: theme.spacing.unit,
    padding: "0 16px"
  },
  table: {
    minWidth: 400
  },
  tableWrapper: {
    overflowX: "auto"
  },
  tableCellRoot: {
    padding: "4px 8px"
  },
  tableCellFooter: {
    padding: "4px 8px",
    color: "#000",
    fontWeight: 600
  }
});
