import produce from "immer";

import {
  CHANGE_ACCESS,
  CHANGE_CATALOGO,
  CHANGE_CATALOGO_BENEFICIOS,
  CHANGE_DESTACADOS_BANNER,
  CHANGE_DESTACADOS_BASE,
  CHANGE_DESTACADOS_FILA,
  CHANGE_DESTACADOS_SLIDER_BASE,
  CHANGE_DESTACADOS,
  CHANGE_DESTACADOS_BENEFICIOS,
  CHANGE_HEADER,
  CHANGE_ID_SEGMENTO,
  CHANGE_LANDING,
  CHANGE_LANDING_BANNER,
  CHANGE_LANDING_BASE,
  CHANGE_LANDING_FILA,
  CHANGE_LANDING_SLIDER_BASE,
  CHANGE_REDES_SOCIALES,
  CHANGE_TEMPLATE_BUILDER,
  CHANGE_TEMPLATE_DATA,
  CLEAR_TEMPLATE
} from "./constants";
import initialState, { reset } from "./initialState";

// ACTION CREATORS
export const updateTemplateBuilder = ({ dispatch, type, ...rest }) => data =>
  dispatch({
    type,
    payload: data,
    ...rest
  });

// REDUCER
export default (
  state = { idSegmento: null, templateBuilder: initialState },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case "SET_COMPLETE_STATE":
        draft.templateBuilder = reset;
        break;
      case CHANGE_LANDING:
        console.log(action);
        // draft.templateBuilder.landing = action.payload;
        break;
      case CHANGE_LANDING_BANNER:
        draft.templateBuilder.landing.filas[action.position[0]].contenidos[
          action.position[1]
        ] = action.payload;
        break;
      case CHANGE_LANDING_BASE:
        draft.templateBuilder.landing.filas[action.position[0]].contenidos[
          action.position[1]
        ] = action.payload;
        break;
      case CHANGE_LANDING_FILA:
        draft.templateBuilder.landing.filas[action.position[0]] =
          action.payload;
        break;
      case CHANGE_LANDING_SLIDER_BASE:
        draft.templateBuilder.landing.filas[action.position[0]].contenidos[
          action.position[1]
        ].bases[action.position[2]] = action.payload;
        break;

      case CHANGE_ID_SEGMENTO:
        draft.idSegmento = action.payload;
        break;
      case CHANGE_TEMPLATE_BUILDER:
        draft.templateBuilder = action.payload;
        break;
      case CHANGE_REDES_SOCIALES:
        draft.templateBuilder.footer.redes = action.payload;
        break;
      case CHANGE_TEMPLATE_DATA:
        draft.templateBuilder.templateData = action.payload;
        break;
      case CHANGE_DESTACADOS:
        draft.templateBuilder.destacados = action.payload;
        break;
      case CHANGE_DESTACADOS_FILA:
        draft.templateBuilder.destacados = action.payload;
        break;
      case CHANGE_DESTACADOS_SLIDER_BASE:
        draft.templateBuilder.destacados.contenidos[action.position[0]].bases[
          action.position[1]
        ] = action.payload;
        break;
      case CHANGE_DESTACADOS_BASE:
        draft.templateBuilder.destacados.contenidos[action.position[0]] =
          action.payload;
        break;
      case CHANGE_DESTACADOS_BANNER:
        draft.templateBuilder.destacados.contenidos[action.position[0]] =
          action.payload;
        break;

      case CHANGE_DESTACADOS_BENEFICIOS:
        draft.templateBuilder.destacadosBeneficios = action.payload;
        break;

      case CHANGE_HEADER:
        draft.templateBuilder.header = action.payload;
        break;
      case CHANGE_ACCESS:
        draft.templateBuilder.access = action.payload;
        break;
      case CHANGE_CATALOGO:
        draft.templateBuilder.catalogo = action.payload;
        break;
      case CHANGE_CATALOGO_BENEFICIOS:
        draft.templateBuilder.catalogoBeneficios = action.payload;
        break;
      case CLEAR_TEMPLATE:
        draft.idSegmento = undefined;
        break;
      default:
        return state;
    }
  });
