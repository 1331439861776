import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Form from "js/pages/VentaRetail/Edit/MyForm.js";
import Dialog from "js/pages/VentaRetail/Edit/NewClienteDialog.js";
import AppQraphQL from "js/graphql/resolvers/ventaRetail.resolver";
import ProductosGraphQL from "js/graphql/resolvers/productoRetail.resolver";
import ClientesGraphQL from "js/graphql/resolvers/clienteRetail.resolver";
import UserGraphQL from "js/graphql/resolvers/usuario.resolver";
import { removeTypename } from "js/utils/Helper";
import { updateUserPoints } from "js/redux/user/user.actions";
import ConfirmationDialog from "js/pages/VentaRetail/Edit/ConfirmationDialog.js";
import { _t } from "js/utils/TranslationService";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    let id = null;
    let inBackOffice = false;
    let idVenta = this.props.match.params.idVenta;
    if (window.location.pathname.indexOf("admin") !== -1) {
      id = parseInt(this.props.match.params.id) || this.props.match.params.id;
      inBackOffice = true;
    } else {
      id = parseInt(window.localStorage.getItem("idPrograma"));
      inBackOffice = false;
    }
    if (!idVenta) {
      idVenta = this.props.computedMatch.params.idVenta;
    }

    this.state = {
      disabled: false,
      errors: null,
      dialogErrors: null,
      id,
      idVenta,
      clientes: [],
      productos: [],
      isMisVentas: window.location.pathname.indexOf("mis") !== -1,
      initialValues: {},
      open: false,
      inBackOffice,
      showConfirmationDialog: false,
      formData: null,
      isSubmitting: false
    };
  }

  openDialog() {
    this.setState({
      open: true
    });
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  async submitDialog(values) {
    const { client } = this.props;
    const { id } = this.state;
    values.idPrograma = parseInt(id) || id;
    values.estado = "ACTIVO";
    await client
      .mutate({
        mutation: ClientesGraphQL.mutations.createClienteRetail,
        variables: {
          cliente: {
            ...values
          }
        }
      })
      .then(res => {
        const mensaje = _t("Cliente creado y agregado correctamente");
        const cliente = res.data.createClienteRetail;
        const { clientes, initialValues } = this.state;
        clientes.push({ value: cliente._id, label: cliente.nombre });
        this.setState({
          clientes,
          open: false,
          initialValues: {
            ...initialValues,
            idCliente: cliente._id
          }
        });
        this.props.openNotification(mensaje);
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          dialogErrors:
            errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  async componentDidMount() {
    const { client } = this.props;
    const { id, idVenta, isMisVentas } = this.state;
    const fieldsVariables = {
      idPrograma: parseInt(id) || id,
      estado: "ACTIVO"
    };

    if (idVenta !== "new") {
      delete fieldsVariables.estado;
      await client
        .query({
          query: isMisVentas
            ? AppQraphQL.queries.getMiVentaRetailById
            : AppQraphQL.queries.getVentaRetailById,
          fetchPolicy: "network-only",
          variables: {
            ventaLike: {
              _id: idVenta
            }
          }
        })
        .then(res => {
          const venta = isMisVentas
            ? res.data.getMisVentasRetail.itemsPagina[0]
            : res.data.getVentasRetail.itemsPagina[0];
          const cliente = venta.cliente;
          const detalles = venta.detalles;
          venta.idCliente = cliente ? cliente._id : null;
          venta.fields = detalles.map(detalle => {
            return {
              codigoProducto: detalle.producto.codigo,
              cantidad: detalle.cantidad,
              observacion: detalle.observacion
            };
          });
          this.setState({
            initialValues: {
              ...venta
            },
            disabled: true
          });
        });
    }

    let miUsuario = null;
    if (isMisVentas) {
      await client
        .query({
          query: UserGraphQL.queries.getMiUsuario,
          fetchPolicy: "network-only"
        })
        .then(res => {
          miUsuario = res.data.miUsuario;
          this.setState({ miUsuario });
        });
    }
    await client
      .query({
        query: isMisVentas
          ? ProductosGraphQL.queries.getProductosRetailInputByidUsuario
          : ProductosGraphQL.queries.getProductosRetailInput,
        fetchPolicy: "network-only",
        variables: {
          productoLike: {
            ...fieldsVariables
          }
        }
      })
      .then(res => {
        const productos = isMisVentas
          ? res.data.getProductosRetailByidUsuario
            ? res.data.getProductosRetailByidUsuario.itemsPagina
            : null
          : res.data.getProductosRetail
            ? res.data.getProductosRetail.itemsPagina
            : null;
        this.setState({ productos });
      });

    await client
      .query({
        query: ClientesGraphQL.queries.getClientesRetailInput,
        fetchPolicy: "network-only",
        variables: {
          clienteLike: {
            ...fieldsVariables
          }
        }
      })
      .then(res => {
        const clientes = res.data.getClientesRetail
          ? res.data.getClientesRetail.itemsPagina
          : null;
        this.setState({ clientes });
      });
  }

  handleConfirmation = (confirmed, values = null) => {
    if (confirmed) {
      this.submitData(values);
    } else {
      this.setState({ showConfirmationDialog: false, formData: null });
    }
  };

  submitData = async values => {
    const { client, history, updateUserPoints } = this.props;
    const { id, isMisVentas, inBackOffice } = this.state;

    this.setState({ isSubmitting: true });

    try {
      if (!values.fields) {
        this.props.openNotification(_t("Debe agregar al menos un producto"));
        return;
      }

      values.detalles = values.fields.map(field => {
        return {
          ...field,
          cantidad: parseInt(field.cantidad)
        };
      });
      let variables = {
        ...values,
        idPrograma: id,
        idUsuario: parseInt(window.localStorage.getItem("idUsuario"))
      };
      delete variables.fields;
      await client
        .mutate({
          mutation: AppQraphQL.mutations.createVentaRetail,
          variables: {
            venta: { ...removeTypename(variables) }
          }
        })
        .then(res => {
          const mensaje = _t("Venta creada correctamente");
          this.props.openNotification(mensaje);
          history.push(
            inBackOffice
              ? `/admin/programa/${id}/ventas-retail`
              : isMisVentas
                ? `/mis-ventas`
                : `/ventas`
          );
          updateUserPoints(true);
        })
        .catch(errors => {
          const arr = [];
          arr.push({ message: "Error inesperado" });
          this.setState({
            errors:
              errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
          });
          return false;
        });
    } catch (errors) {
      // ... manejo de errores existente ...
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  async submit(values) {
    this.setState({
      showConfirmationDialog: true,
      formData: values
    });
  }

  render() {
    return (
      <React.Fragment>
        <Form
          errors={this.state.errors}
          disabled={this.state.disabled}
          onSubmit={this.submit.bind(this)}
          productos={this.state.productos}
          clientes={this.state.clientes}
          isMisVentas={this.state.isMisVentas}
          initialValues={this.state.initialValues}
          openDialog={this.openDialog.bind(this)}
          idPrograma={this.state.id}
          inBackOffice={this.state.inBackOffice}
          idVenta={this.state.idVenta}
        />
        <Dialog
          onSubmit={this.submitDialog.bind(this)}
          errors={this.state.dialogErrors}
          open={this.state.open}
          handleClose={this.handleClose.bind(this)}
        />
        <ConfirmationDialog
          open={this.state.showConfirmationDialog}
          data={this.state.formData}
          productos={this.state.productos}
          clientes={this.state.clientes}
          onClose={confirmed =>
            this.handleConfirmation(confirmed, this.state.formData)
          }
          usuario={this.state.miUsuario}
          isSubmitting={this.state.isSubmitting}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateUserPoints: updatePoints => dispatch(updateUserPoints(updatePoints))
});

export default connect(
  null,
  mapDispatchToProps
)(Edit);
