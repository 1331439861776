import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector, change, getFormMeta } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ErrorList from "js/components/Shared/ErrorList";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

import {
  PaisField,
  ZonaField,
  LocalidadField,
  ListField,
  SwitchField,
  InputTextarea,
  InputTextField,
  EmailField
} from "js/components/Shared/Form/Fields";

class Form extends PureComponent {
  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      changeFieldValue,
      classes,
      history,
      disabled,
      tieneSucursales,
      urlPaginaWeb,
      errors,
      idPais,
      idZona,
      formMeta,
      id
    } = this.props;

    return (
      <Fragment>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Typography variant="h5" style={{ flex: 1 }}>
              {disabled ? "Ver " : id === "new" ? "Nuevo " : "Modificar "}
              Proveedor
            </Typography>
            {disabled &&
              tieneSucursales && (
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  onClick={e => history.push(`/admin/proveedor/${id}/sucursal`)}
                >
                  Gestionar Sucursales
                </Button>
              )}
          </Grid>
        </Grid>
        <form className={classes.container} onSubmit={handleSubmit}>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="nombreFantasia"
                label="Nombre fantasía"
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="razonSocial"
                label="Razón social"
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ListField
                name="estado"
                label="Estado"
                options={[
                  { label: "Activo", value: "ACTIVO" },
                  { label: "Inactivo", value: "INACTIVO" }
                ]}
                disabled={disabled || id === "new"}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="cuit"
                label="CUIT o identificación tributaria"
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="direccion"
                label="Dirección"
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <EmailField
                name="emailCanjes"
                label="Email para canjes"
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="urlPaginaWeb"
                label="Página web"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SwitchField
                name="publicaPaginaWeb"
                label="Publica página web?"
                disabled={!urlPaginaWeb || disabled}
              />
            </Grid>
            <Grid item xs={12} sm={4} />
            <Grid item xs={12} sm={4}>
              <ListField
                name="formatoEnvio"
                label="Formato de envío"
                options={[
                  { label: "Manual", value: "Manual" },
                  { label: "Automático", value: "Automatico" }
                ]}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="formatoCanje"
                label="Formato de canjes"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="formaPago"
                label="Forma de pago"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <InputTextField
                name="observaciones"
                label="Observaciones"
                disabled={disabled}
                multiple
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="descuento"
                label="Descuento"
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Responsable
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputTextarea
                name="nombreContacto"
                label="Nombre del responsable"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputTextarea
                name="telefonoContacto"
                label="Teléfono del responsable"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <EmailField
                name="emailContacto"
                label="Email del responsable"
                disabled={disabled}
              />
            </Grid>
          </Grid>

          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Sucursales
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SwitchField
                name="tieneSucursales"
                label="Tiene sucursales"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SwitchField
                name="publicaSucursales"
                label="Publica sucursales"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={4} />
          </Grid>

          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Área de operación
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <PaisField
                formMeta={formMeta}
                changeFieldValue={changeFieldValue}
                required={!tieneSucursales}
                disabled={disabled || tieneSucursales}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ZonaField
                idPais={idPais}
                formMeta={formMeta}
                changeFieldValue={changeFieldValue}
                disabled={disabled || tieneSucursales}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <LocalidadField
                idPais={idPais}
                idZona={idZona}
                disabled={disabled || tieneSucursales}
              />
            </Grid>
          </Grid>
          <ErrorList errors={errors} />
          <Grid className={classes.grid} container>
            <Grid item xs />
            <Grid item xs />
            <Grid className={classes.gridButton} item xs>
              <Button
                type="button"
                variant="outlined"
                size="large"
                color="primary"
                className={classes.button}
                onClick={() => history.push("/admin/proveedor")}
              >
                Cancelar
              </Button>
            </Grid>
            {AuthService.hasPerms([Roles.PROVEEDOR_W]) && (
              <Grid className={classes.gridButton} item xs>
                {disabled && (
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    onClick={() =>
                      history.push("/admin/proveedor/" + id + "/edit")
                    }
                  >
                    Editar
                  </Button>
                )}
                {!disabled && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    disabled={pristine || submitting}
                  >
                    Guardar
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      </Fragment>
    );
  }
}

Form = reduxForm({
  form: "formProveedores",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formProveedores");

Form = connect(
  state => ({
    formMeta: getFormMeta("formProveedores")(state),
    tieneSucursales: selector(state, "tieneSucursales"),
    urlPaginaWeb: selector(state, "urlPaginaWeb"),
    idPais: selector(state, "idPais"),
    idZona: selector(state, "idZona")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formProveedores", field, value))
  })
)(Form);

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: theme.container.maxWidth,
    margin: theme.container.margin
  },
  field: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  grid: {
    margin: "0"
  },
  gridButton: {
    marginTop: "20px",
    textAlign: "end"
  },
  button: {
    width: "200px"
  },
  subtitle: {
    marginTop: "1.5rem"
  }
});

export default withRouter(withStyles(styles)(Form));
