import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  Account,
  CalendarRemove,
  CalendarCheck,
  ProgressCheck
} from "mdi-material-ui";
import { renderFecha } from "js/utils/Helper";
import { withStyles } from "@material-ui/core/styles";
import { numberWithCommas } from "js/utils";

const styles = theme => ({
  fila: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 40
  },
  paper: {
    padding: 12
  },
  loteInfoFila: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 10
  },

  loteInfoFilaItem: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderRight: "3px solid black",
    marginRight: 15,
    "& h6": {
      alignSelf: "center",
      paddingRight: 15
    },
    "& h4": {
      alignSelf: "center",
      paddingRight: 15
    },
    "&:last-child": {
      border: "none"
    },
    // "&nth-last-child(n+2):after": {
    //   content: "**"
    // },
    "& svg": {
      paddingRight: 15
    }
  }
});

const LoteInfo = ({ classes, lote, programa }) => {
  return (
    <Grid container item xs={12} className={classes.fila}>
      <Paper className={classes.paper}>
        <Grid container item xs={12} className={classes.loteInfoFila}>
          <Grid item className={classes.loteInfoFilaItem}>
            <CalendarCheck color="primary" />
            <Typography variant="subtitle2">
              {renderFecha(lote.fechaCreacion)}
            </Typography>
          </Grid>
          <Grid item className={classes.loteInfoFilaItem}>
            <CalendarRemove color="primary" />
            <Typography variant="subtitle2">
              {renderFecha(lote.fechaFin)}
            </Typography>
          </Grid>
          <Grid item className={classes.loteInfoFilaItem}>
            <ProgressCheck color="primary" />
            <Typography variant="subtitle2">{lote.estado}</Typography>
          </Grid>
          <Grid item className={classes.loteInfoFilaItem}>
            <Account color="primary" />
            <Typography variant="subtitle2">{lote.usuario.username}</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.loteInfoFila}>
          <Grid item className={classes.loteInfoFilaItem}>
            <Typography variant="subtitle2">Productos</Typography>
            <Typography variant="h4">
              {lote.cantidadProductos
                ? numberWithCommas(lote.cantidadProductos)
                : "--"}
            </Typography>
          </Grid>
          <Grid item className={classes.loteInfoFilaItem}>
            <Typography variant="subtitle2">Proveedores</Typography>
            <Typography variant="h4">
              {lote.cantidadProveedores
                ? numberWithCommas(lote.cantidadProveedores)
                : "--"}
            </Typography>
          </Grid>
          <Grid item className={classes.loteInfoFilaItem}>
            <Typography variant="subtitle2">Costo total</Typography>
            <Typography variant="subtitle2">{programa.monedaBase}</Typography>
            <Typography variant="h4">
              {numberWithCommas(lote.costoTotal)}
            </Typography>
          </Grid>
          <Grid item className={classes.loteInfoFilaItem}>
            <Typography variant="subtitle2">Importe total</Typography>
            <Typography variant="subtitle2">{programa.monedaBase}</Typography>
            <Typography variant="h4">
              {numberWithCommas(lote.importeTotal)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(LoteInfo);
