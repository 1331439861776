import React, { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import _ from "lodash";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress
} from "@material-ui/core";

import client from "js/App/client.graphql.js";
import canjeResolver from "../../../../../graphql/resolvers/canje.resolver";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogEnviarPremios extends React.Component {
  state = {
    canjesAllowed: [],
    canjesDenied: [],
    inProcess: false,
    sending: false
  };

  async componentDidMount() {
    const { selected } = this.props;
    this.setState({ inProcess: true });
    try {
      const res = await client.query({
        query: canjeResolver.queries.getCanjes,
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        variables: {
          idsCanjes: selected,
          estadoCanje: ["A_ENTREGAR"],
          rowsPerPage: selected.length
        }
      });
      const canjesAllowed = res.data.getCanjes.itemsPagina;
      const selectedMapped = selected.map(id => ({ idCanje: id }));
      const canjesDenied = _.differenceBy(
        selectedMapped,
        canjesAllowed,
        "idCanje"
      );

      this.setState({ canjesAllowed, canjesDenied });
    } catch (error) {
      this.setState({ canjesAllowed: [], canjesDenied: [] });
    } finally {
      this.setState({ inProcess: false });
    }
  }

  sendValues = async () => {
    try {
      const { canjesAllowed } = this.state;
      this.setState({ sending: true });
      const send = true;
      const canjesToSend = canjesAllowed.map(canje => {
        return { idCanje: canje.idCanje, estado: "ENTREGADO" };
      });
      const res = await client.mutate({
        mutation: canjeResolver.mutations.bulkUpdateCanje,
        variables: { canjes: canjesToSend, send },
        errorPolicy: "all"
      });

      this.props.openNotification(
        "¡Todo listo! El proceso de envío de premios se ha completado satisfactoriamente."
      );
      this.setState({ sent: true });
    } catch (error) {
      this.props.openNotification(
        "¡Ups! Algo salió mal durante el envío de premios. Cierre la ventana e intente nuevamente"
      );
    } finally {
      this.setState({ sending: false });
      this.props.onTableChange();
      this.props.handleClose();
    }
  };

  render() {
    const { open, handleClose, classes } = this.props;
    const { inProcess, sending } = this.state;
    return (
      <Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          classes={{ paperScrollPaper: classes.root }}
          fullWidth={true}
        >
          {inProcess ? (
            <>
              <DialogContent>
                <CircularProgress size={40} />
              </DialogContent>
            </>
          ) : (
            <>
              <DialogTitle id="alert-dialog-slide-title">
                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                  Enviar premios
                </Typography>
              </DialogTitle>
              <DialogContent className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Canjes a enviar</Typography>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: "50%" }}>
                            Id Canje
                          </TableCell>
                          <TableCell>Estado</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.canjesAllowed.map(canje => (
                          <TableRow key={canje.idCanje}>
                            <TableCell>{canje.idCanje}</TableCell>
                            <TableCell style={{ color: "#4CAF50" }}>
                              Apto para envio
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>

                  {this.state.canjesDenied.length > 0 && (
                    <Grid item xs={12} style={{ marginTop: "1em" }}>
                      <Typography variant="h6">Canjes no validos</Typography>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: "50%" }}>
                              Id Canje
                            </TableCell>
                            <TableCell>Estado</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.canjesDenied.map(canje => (
                            <TableRow key={canje.idCanje}>
                              <TableCell>{canje.idCanje}</TableCell>
                              <TableCell style={{ color: "#F44336" }}>
                                No apto para envio
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="primary"
                >
                  Volver
                </Button>
                <Button
                  onClick={this.sendValues}
                  variant="contained"
                  color="primary"
                  disabled={sending}
                >
                  {sending ? (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  ) : (
                    <Typography>Enviar</Typography>
                  )}
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Fragment>
    );
  }
}

DialogEnviarPremios.defaultProps = {};

DialogEnviarPremios.propTypes = {};

export default DialogEnviarPremios;
