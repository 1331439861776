import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Subsection } from "js/pages/TemplateBuilder2/Layouts";
import {
  UnitPicker,
  Select,
  SortableComponent
} from "js/pages/TemplateBuilder2/Controls";
import { get } from "lodash";
import { _t } from "js/utils/TranslationService";
import newItems from "js/pages/TemplateBuilder2/Components/NewItems";
import Base from "../Base";
import Arrows from "js/components/Shared/Slider/Arrows";
import Switch from "@material-ui/core/Switch";
import withStyles from "./styles";

const { newBase } = newItems;

const flechas = [
  { type: "circle", arrow: "circle", value: "circle", label: "Circulo" },
  {
    type: "circleAlt",
    arrow: "circleAlt",
    value: "circleAlt",
    label: "Circulo 2"
  },
  { type: "common", arrow: "common", value: "common", label: "Comun" },
  {
    type: "commonAlt",
    arrow: "commonAlt",
    value: "commonAlt",
    label: "Comun 2"
  },
  { type: "long", arrow: "long", value: "long", label: "Larga" }
];

const createLabel = (label, type) => (
  <Typography variant={`subtitle${type}`} gutterBottom>
    {_t(label)}
  </Typography>
);

class SliderForm extends React.PureComponent {
  getKey = key => {
    const { position } = this.props;
    return `${position[0]}.contenidos.${position[1]}.${key}`;
  };

  handleOnChange = key => this.props.onChange(this.getKey(key));

  handleOnChangeSwitch = key => e => this.handleOnChange(key)(e.target.checked);

  render() {
    const {
      classes,
      value: contenido,
      indexItem,
      metadata,
      position
    } = this.props;

    return (
      <Grid container className={classes.form}>
        <Subsection title="Opciones del slider">
          <Grid container spacing={24}>
            <Grid item xs sm={4}>
              {createLabel("Reproducción automática", 2)}
              <Switch
                checked={get(contenido, "sliderOptions.autoPlay", true)}
                color="secondary"
                onChange={this.handleOnChangeSwitch("sliderOptions.autoPlay")}
              />
            </Grid>
            <Grid item xs sm={4}>
              {createLabel("Detener en hover", 2)}
              <Switch
                checked={get(contenido, "sliderOptions.stopOnHover", true)}
                color="secondary"
                onChange={this.handleOnChangeSwitch(
                  "sliderOptions.stopOnHover"
                )}
                value="visible"
              />
            </Grid>
            <Grid item xs sm={4}>
              {createLabel("Loop infinito", 2)}
              <Switch
                checked={get(contenido, "sliderOptions.infiniteLoop", true)}
                color="secondary"
                onChange={this.handleOnChangeSwitch(
                  "sliderOptions.infiniteLoop"
                )}
              />
            </Grid>
            <Grid item xs sm={4}>
              {createLabel("Bullets visibles", 2)}
              <Switch
                checked={get(contenido, "sliderOptions.showIndicators", true)}
                color="secondary"
                onChange={this.handleOnChangeSwitch(
                  "sliderOptions.showIndicators"
                )}
              />
            </Grid>
            <Grid item xs sm={3}>
              {createLabel("Intervalo", 2)}
              <UnitPicker
                unit={"ms"}
                pixels={`${get(contenido, "sliderOptions.interval", "45")}`}
                onChange={this.handleOnChange("sliderOptions.interval")}
              />
            </Grid>
            <Grid item xs sm={3}>
              {createLabel("Tiempo de transición", 2)}
              <UnitPicker
                unit={"ms"}
                pixels={`${get(
                  contenido,
                  "sliderOptions.transitionTime",
                  "45"
                )}`}
                onChange={this.handleOnChange("sliderOptions.transitionTime")}
              />
            </Grid>
            <Grid item xs sm={4}>
              {createLabel("Flechas visibles", 2)}
              <Switch
                checked={get(contenido, "sliderOptions.showArrows")}
                color="secondary"
                onChange={this.handleOnChangeSwitch("sliderOptions.showArrows")}
              />
            </Grid>
            {get(contenido, "sliderOptions.showArrows") && (
              <React.Fragment>
                <Grid item xs sm={3}>
                  {createLabel("Tipo de flechas", 2)}
                  <Select
                    options={flechas}
                    value={get(contenido, "sliderOptions.arrowStyle", "circle")}
                    onChange={this.handleOnChange("sliderOptions.arrowStyle")}
                  />
                </Grid>
                <Grid item xs sm={3}>
                  {createLabel("Ejemplo de flecha", 2)}
                  <img
                    alt="left arrow"
                    className={classes.arrow}
                    src={`${
                      Arrows[
                        get(contenido, "sliderOptions.arrowStyle", "circle")
                      ].left
                    }`}
                  />
                  <img
                    alt="right arrow"
                    className={classes.arrow}
                    src={`${
                      Arrows[
                        get(contenido, "sliderOptions.arrowStyle", "circle")
                      ].right
                    }`}
                  />
                </Grid>
              </React.Fragment>
            )}
          </Grid>
          <Grid container>
            <Grid item>
              {createLabel("Contenidos del Slider", 2)}
              <Grid item xs={12}>
                <SortableComponent
                  position={position}
                  isCollapsable={true}
                  items={contenido.bases || []}
                  newItem={newBase}
                  component={Base}
                  onChange={this.handleOnChange("bases")}
                  metadata={{
                    contenidoIndex: indexItem,
                    filaIndex: metadata.filaIndex,
                    onChange: metadata.onChange,
                    idPrograma: metadata.idPrograma
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Subsection>
      </Grid>
    );
  }
}
SliderForm.propTypes = {
  classes: PropTypes.object
};

SliderForm.defaultProps = {};
SliderForm.displayName = "base";

export default withStyles(SliderForm);
