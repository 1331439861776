import React, { PureComponent, Fragment } from "react";
import Form from "js/pages/Admin/Programa/Beneficio/SucursalBeneficio/Edit/MyForm.js";
import AppQraphQL from "js/graphql/resolvers/beneficio.resolver";
import { get } from "lodash";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: { estado: "ACTIVO" },
      disabled: null,
      idPrograma: this.props.match.params.id,
      idBeneficio: this.props.match.params.idBeneficio,
      idSucursal: this.props.match.params.idSucursal,
      beneficio: "",
      errors: null
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const {
      match: {
        params: { edit }
      }
    } = this.props;
    const { idPrograma, idBeneficio, idSucursal } = this.state;

    const dataBeneficio = await client.query({
      query: AppQraphQL.queries.getBeneficioById,
      fetchPolicy: "network-only",
      errorPolicy: "all",
      variables: {
        idBeneficio: idBeneficio,
        idPrograma: idPrograma
      }
    });
    const nombreBeneficio = get(
      dataBeneficio,
      "data.getBeneficio.itemsPagina[0].nombre",
      null
    );

    this.setState({
      beneficio: nombreBeneficio
    });

    if (idSucursal !== "new") {
      await client
        .query({
          query: AppQraphQL.queries.getSucursalBeneficioById,
          fetchPolicy: "network-only",
          variables: {
            idSucursal
          }
        })
        .then(res =>
          this.setState({
            initialValues: {
              ...res.data.getBeneficioSucursales.itemsPagina[0],
              idPais:
                res.data.getBeneficioSucursales.itemsPagina[0].pais &&
                res.data.getBeneficioSucursales.itemsPagina[0].pais.idPais,
              idZona:
                res.data.getBeneficioSucursales.itemsPagina[0].zona &&
                res.data.getBeneficioSucursales.itemsPagina[0].zona.idZona,
              idLocalidad:
                res.data.getBeneficioSucursales.itemsPagina[0].localidad &&
                res.data.getBeneficioSucursales.itemsPagina[0].localidad
                  .idLocalidad
            },
            disabled: !(edit === "edit")
          })
        );
    }
  }

  async submit(values) {
    const {
      client,
      history,
      match: {
        params: { id, idBeneficio, idSucursal }
      }
    } = this.props;

    await client
      .mutate({
        mutation:
          idSucursal === "new"
            ? AppQraphQL.mutations.createBeneficioSucursal
            : AppQraphQL.mutations.updateBeneficioSucursal,
        variables: {
          sucursal:
            idSucursal === "new"
              ? {
                  idPrograma: parseInt(id, 10),
                  idBeneficio: idBeneficio,
                  nombre: values.nombre,
                  telefono: values.telefono,
                  direccion: values.direccion,
                  idPais: get(values, "idPais", null),
                  idZona: get(values, "idZona", null),
                  idLocalidad: get(values, "idLocalidad", null),
                  notas: values.notas,
                  orden: values.orden
                }
              : {
                  idPrograma: parseInt(id, 10),
                  idBeneficio: idBeneficio,
                  idSucursal: parseInt(idSucursal, 10),
                  estado: values.estado,
                  nombre: values.nombre,
                  telefono: values.telefono,
                  direccion: values.direccion,
                  idPais: get(values, "idPais", null),
                  idZona: get(values, "idZona", null),
                  idLocalidad: get(values, "idLocalidad", null),
                  notas: values.notas,
                  orden: values.orden
                }
        }
      })
      .then(res => {
        const message = "Sucursal grabada correctamente";
        this.props.openNotification(message);
        history.push(
          `/admin/programa/${id}/beneficios/${idBeneficio}/sucursales-beneficio`
        );
      })
      .catch(e => {
        this.setState({ errors: e });
        return false;
      });
  }

  render() {
    return (
      <Fragment>
        <Form
          id={this.state.idPrograma}
          idBeneficio={this.state.idBeneficio}
          idSucursal={this.state.idSucursal}
          errors={this.state.errors}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
          beneficio={this.state.beneficio}
        />
      </Fragment>
    );
  }
}

export default Edit;
