import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { numberWithCommas } from "js/utils";

import { styles } from "js/pages/Admin/Programa/Notificaciones/Table/styles.js";

class SimpleTable extends PureComponent {
  render() {
    const { classes, history, rows } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={cx(classes.columnWidth100, classes.border)}>
                Nombre
              </TableCell>
              <TableCell className={cx(classes.columnWidth300)}>
                Asunto del email
              </TableCell>
              <TableCell numeric className={cx(classes.columnWidth80)}>
                Puntos a acreditar
              </TableCell>
              <TableCell className={cx(classes.columnWidth80)}>
                Estado
              </TableCell>
              <TableCell className={cx(classes.columnWidth100)}>
                Acción
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              return (
                <TableRow key={row.idNotificacion}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={cx(classes.columnWidth100, classes.border)}
                  >
                    {row.nombre}
                  </TableCell>
                  <TableCell className={cx(classes.columnWidth300)}>
                    {row.asunto}
                  </TableCell>
                  <TableCell numeric className={cx(classes.columnWidth80)}>
                    {row.puntos ? numberWithCommas(row.puntos) : "--"}
                  </TableCell>
                  <TableCell className={cx(classes.columnWidth80)}>
                    {row.estado}
                  </TableCell>
                  <TableCell className={cx(classes.columnWidth100)}>
                    <IconButton
                      aria-label="Editar"
                      onClick={e =>
                        history.push(
                          window.location.pathname +
                            "/" +
                            row.idNotificacion +
                            "/edit"
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withApollo(withRouter(withStyles(styles)(SimpleTable)));
