import { connect } from "react-redux";
import DialogCanje from "./DialogCanje";
import productoActions from "../actions";
import { compose, lifecycle } from "recompose";
import { graphql } from "react-apollo";
import UserQraphQL from "js/graphql/resolvers/usuario.resolver";
import { get, mergeWith } from "lodash";

const mapStateToProps = state => ({
  producto: state.producto.producto,
  datosRequeridos: state.producto.datosRequeridos,
  puntos: state.producto.puntos,
  cantidad: state.producto.cantidad,
  idSucursal: state.producto.idSucursal,
  requeridos: state.producto.requeridos,
  mensajeRequeridos: state.producto.mensajeRequeridos,
  canjeFail: state.producto.canjeFail,
  sucursales: state.producto.sucursales
});

const mapDispatchToProps = dispatch => ({
  productoActions: productoActions({ dispatch })
});

const getDatosEntrega = datosDeCanje => {
  const entrega = JSON.parse(datosDeCanje["entrega"]);
  return mergeWith(
    {},
    { direccion: "", cp: "", idPais: "", idZona: "", idLocalidad: "" },
    entrega,
    (a, b) => (b ? b : a)
  );
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  graphql(UserQraphQL.queries.getMiUsuarioCompleto, {
    name: "getMiUsuarioCompleto",
    fetchPolicy: "network-only"
  }),
  lifecycle({
    async componentDidMount() {
      const {
        getMiUsuarioCompleto,
        datosRequeridos,
        productoActions,
        requeridos
      } = this.props;
      const { data } = await getMiUsuarioCompleto.refetch();
      const otros = JSON.parse(get(data, "miUsuario.perfil.otros", "{}"));
      const datosDeCanje = {
        ...get(data, "miUsuario.perfil.datosDeCanje", {}),
        fechaNacimiento: otros.fecha_de_nacimiento,
        lanpass: otros.nro_socio_latam_pass,
        ...otros
      };
      productoActions.updateDatosPerfil(datosDeCanje);
      const reque = {};
      datosRequeridos.map(
        ({ key: k }) =>
          (reque[k] =
            k === "entrega"
              ? getDatosEntrega(datosDeCanje)
              : datosDeCanje[k] || requeridos[k])
      );
      productoActions.setRequeridos(reque);
    }
  })
);

export default enhance(DialogCanje);
