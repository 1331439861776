import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaSolicitudBeneficio/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/beneficio.resolver";
import { USER_ROLES as Roles } from "js/models/User";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Receipt from "@material-ui/icons/Receipt";
import {
  columnas,
  misColumnas
} from "js/components/TablaSolicitudBeneficio/columns";
import { viewSolicitudAction } from "js/components/Shared/Tabla/ActionCells";

const defaultHeader = (id, btnsNextTitle, isMisSolicitudes) => {
  const header = {
    title: "Solicitudes de beneficios",
    btnsNextTitle
  };
  if (!isMisSolicitudes) {
    header.export = {
      path: "solicitudesBeneficios",
      perms: [Roles.SOLICITUD_BENEFICIO_R],
      tooltip: "Exportar solicitudes de beneficios"
    };
  } else {
    header.export = {
      path: "misSolicitudesBeneficios",
      perms: [Roles.MIS_SOLICITUDES_BENEFICIO_R],
      tooltip: "Exportar mis solicitudes"
    };
  }
  return header;
};

const defaultSort = { order: "desc", orderBy: "fecha" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    let storage = "";
    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;
    const isMisSolicitudes = window.location.pathname.indexOf("mis") !== -1;
    let columnData = null;

    if (isMisSolicitudes) {
      columnData = misColumnas;
    } else {
      columnData = columnas;
    }

    const btnsNextTitle = [];
    const actionsCustom = [];
    if (inBackOffice) {
      storage = "FiltroSoliciutdBeneficioAdmin";
      btnsNextTitle.push({
        btn: (
          <Tooltip title="Beneficios">
            <IconButton
              color="primary"
              onClick={() =>
                this.props.history.push(`/admin/programa/${id}/beneficios`)
              }
            >
              <Receipt />
            </IconButton>
          </Tooltip>
        ),
        perms: [Roles.BENEFICIO_R]
      });
    } else {
      if (isMisSolicitudes) {
        const view = params => ({
          ...viewSolicitudAction({
            ...params,
            history: this.props.history,
            perms: [Roles.MIS_SOLICITUDES_BENEFICIO_R]
          })
        });
        actionsCustom.push(view);
        storage = "FiltroMisSoliciutdBeneficios";
      } else {
        storage = "FiltroSoliciutdBeneficios";
      }
    }

    return (
      <Table
        storage={storage}
        query={
          isMisSolicitudes
            ? AppQraphQL.queries.getMisBeneficiosSolicitudes
            : AppQraphQL.queries.getBeneficiosSolicitudes
        }
        defaultHeader={defaultHeader(id, btnsNextTitle, isMisSolicitudes)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data =>
          isMisSolicitudes
            ? data.getMisBeneficiosSolicitudes
            : data.getBeneficiosSolicitudes
        }
        actionsCustom={actionsCustom}
        pathId={"idSolicitud"}
      />
    );
  }
}

export default Tabla;
