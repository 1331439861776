import React from "react";
import AuthService from "js/utils/AuthService";
import base from "js/components/Shared/Tabla/ActionCells/Base";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import client from "js/App/client.graphql.js";

export default params => {
  const estado = params.item.estado;
  const label = estado === "ACTIVO" ? "Desactivar usuario" : "Activar usuario";
  return {
    ...base(),
    label: label,
    icon: estado === "ACTIVO" ? <StopIcon /> : <PlayArrowIcon />,
    tooltip: label,
    show: () => {
      let result = true;
      if (!AuthService.hasPerms(params.perms) || estado === "PENDIENTE") {
        result = false;
      }
      return result;
    },
    onclick: () =>
      client
        .mutate({
          mutation: params.mutation,
          variables: {
            idUsuario: params.itemId,
            estado: estado === "ACTIVO" ? "INACTIVO" : "ACTIVO"
          },
          errorPolicy: "all"
        })
        .then(res => {
          if (res.errors && res.errors.length > 0) {
            params.openNotification(res.errors[0].message);
          } else {
            const nuevoEstado = res.data.cambiarEstadoUsuarioUnilever;
            const message =
              nuevoEstado === "ACTIVO"
                ? "El usuario ha sido activado correctamente"
                : "El usuario ha sido desactivado correctamente";
            params.openNotification(message);
          }
        })
        .catch(() => {
          params.openNotification("Error al intentar la acción");
        })
  };
};
