import React, { PureComponent } from "react";

import Table from "js/components/Shared/Tabla";
import advancedFilter from "./advancedFilter.js";
import MovimientosQraphQL from "js/graphql/resolvers/movimientos.resolver";
import { Monto, Saldo } from "js/components/Shared/Tabla/TableCells";

const columnData = [
  { id: "fecha", label: "Fecha", type: "datehour" },
  { id: "concepto", label: "Concepto" },
  {
    id: "puntos",
    label: "Puntos",
    type: "custom",
    isNumeric: true,
    parser: (puntos, item) => (
      <Monto
        value={puntos}
        monedaBase={item.monedaBase}
        tipoOperacion={item.operacion}
      />
    )
  },
  {
    id: "balance",
    label: "Saldo",
    type: "custom",
    isNumeric: true,
    parser: (balance, item) => <Saldo value={balance} />
  }
];

const defaultSort = { order: "desc", orderBy: "fecha" };

class Tabla extends PureComponent {
  render() {
    return (
      <Table
        query={MovimientosQraphQL.queries.getMisMovimientos}
        showHeader={false}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.misMovimientos}
        // storage={"FiltroMovimientos"}
      />
    );
  }
}

export default Tabla;
