import { numberWithCommas } from "js/utils";

export const columnas = [
  { id: "fechaCreacion", label: "Fecha Creación", type: "date" },
  { id: "usuario_username", label: "Vendedor" },
  { id: "cliente_nombre", label: "Cliente" },
  { id: "canal_nombre", label: "Canal" },
  {
    id: "numeroFactura",
    label: "Factura",
    isNumeric: true,
    type: "custom",
    parser: numero => (numero ? numberWithCommas(numero) : "--")
  },
  {
    id: "total",
    label: "Total",
    isNumeric: true,
    type: "custom",
    parser: total => (total ? numberWithCommas(total) : "--")
  },
  { id: "actions", label: "Acciones" }
];

export const misColumnas = [
  { id: "fechaCreacion", label: "Fecha Creación", type: "date" },
  { id: "cliente_nombre", label: "Cliente" },
  {
    id: "numeroFactura",
    label: "Factura",
    isNumeric: true,
    type: "custom",
    parser: numero => (numero ? numberWithCommas(numero) : "--")
  },
  {
    id: "total",
    label: "Total",
    isNumeric: true,
    type: "custom",
    parser: total => (total ? numberWithCommas(total) : "--")
  },
  { id: "actions", label: "Acciones" }
];
