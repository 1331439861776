import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, change, getFormMeta } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { ListField, InputTextField } from "js/components/Shared/Form/Fields";

import { styles } from "js/components/Shared/Form/styles.js";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class Form extends PureComponent {
  render() {
    const {
      initialValues,
      pristine,
      id,
      handleSubmit,
      submitting,
      classes,
      history,
      disabled,
      errors
    } = this.props;

    const inBackOffice =
      window.location.pathname.indexOf("admin") !== -1 ? true : false;
    let isMisMeds = null;
    if (!inBackOffice) {
      isMisMeds =
        window.location.pathname.indexOf("mis-meds") !== -1 ? true : false;
    }

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {initialValues.codigo === "new" ? "Modificar MED" : "Nuevo MED"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            {errors && (
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <ul className={classes.error}>
                    {errors.map((e, i) => (
                      <li key={i}>{e.message}</li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={16}>
              <Grid item xs={12} sm={4}>
                <InputTextField
                  name="codigo"
                  label="Código distribuidora"
                  autoComplete="off"
                  disabled={disabled || initialValues.codigo}
                  required={true}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputTextField
                  name="nombre"
                  label="Nombre distribuidora"
                  autoComplete="off"
                  disabled={disabled}
                  required={true}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <ListField
                  name="estado"
                  label="Estado"
                  options={[
                    { label: "Activo", value: "ACTIVO" },
                    { label: "Inactivo", value: "INACTIVO" }
                  ]}
                  disabled={disabled}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <ListField
                  name="tipo"
                  label="Tipo"
                  options={[
                    { label: "Convencional", value: "CONVENCIONAL" },
                    { label: "Compre ahora", value: "COMPRE_AHORA" }
                  ]}
                  disabled={disabled}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <InputTextField
                  name="descripcion"
                  label="Descripción"
                  autoComplete="off"
                  disabled={disabled}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justify="flex-end">
                  <Grid item xs />
                  <Grid item xs />
                  <Grid className={classes.gridButton} item xs>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      onClick={e =>
                        history.push(
                          inBackOffice
                            ? `/admin/programa/${id}/meds`
                            : isMisMeds
                              ? `/mis-meds`
                              : `/meds`
                        )
                      }
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  {AuthService.hasPerms([Roles.MED_W]) && (
                    <Grid className={classes.gridButton} item xs>
                      {disabled && (
                        <Button
                          type="button"
                          variant="contained"
                          size="large"
                          color="primary"
                          className={classes.button}
                          onClick={() =>
                            history.push(
                              inBackOffice
                                ? `/admin/programa/${id}/meds/${
                                    initialValues.codigo
                                  }/edit`
                                : isMisMeds
                                  ? `/mis-meds/${initialValues.codigo}/edit`
                                  : `/meds/${initialValues.codigo}/edit`
                            )
                          }
                        >
                          Editar
                        </Button>
                      )}
                      {!disabled && (
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          color="primary"
                          className={classes.button}
                          disabled={submitting || pristine}
                        >
                          Guardar
                        </Button>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

Form = reduxForm({
  form: "formMedUnilever",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

Form = connect(
  state => ({
    formMeta: getFormMeta("formMedUnilever")(state)
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formMedUnilever", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
