import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ViewListIcon from "@material-ui/icons/ViewList";
import capitalize from "lodash/capitalize";

import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/pages/Unilever/Campana/Tabla/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/campanaUnilever.resolver";
import { editAction } from "js/components/Shared/Tabla/ActionCells";
import { targetsAction } from "js/pages/Unilever/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import { setCampanas } from "js/redux/campana/campana.actions";
import { Monto } from "js/components/Shared/Tabla/TableCells";

const defaultHeader = (id, btnsNextTitle) => {
  return {
    title: "Campañas Unilever",
    btnsNextTitle,
    new: {
      title: "Nueva",
      perms: [Roles.CAMPANA_OP_W]
    },
    export: {
      path: "unileverCampanas",
      perms: [Roles.CAMPANA_OP_R, Roles.CAMPANA_OP_W]
    },
    queryStringMatcher: {
      idsPrograma: undefined
    },
    others: [
      {
        path: "unileverPerformance",
        perms: [Roles.INFORME_PERFORMANCE_R],
        type: "export",
        tooltip: "Exportar perfromance"
      }
    ]
  };
};
const columnData = [
  { id: "nombre", label: "Nombre campaña" },
  { id: "fechaInicio", label: "Fecha Inicio", type: "date" },
  { id: "fechaFin", label: "Fecha Fin", type: "date" },
  { id: "fechaCierre", label: "Fecha Cierre", type: "date" },
  { id: "grupoSKU_nombre", label: "Grupo SKU" },
  {
    id: "puntosTodos",
    label: "Puntos vendedores",
    type: "custom",
    parser: puntos => <Monto value={puntos} />
  },
  {
    id: "puntosTodosSupervisor",
    label: "Puntos supervisores",
    type: "custom",
    parser: puntos => <Monto value={puntos} />
  },
  {
    id: "tipo",
    label: "Tipo",
    type: "custom",
    parser: tipo => <Estado estado={capitalize(tipo)} />
  },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fechaInicio" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      },
      setCampanas
    } = this.props;

    const targets = params => ({
      ...targetsAction({
        ...params,
        history: this.props.history,
        perms: [Roles.TARGET_USUARIO_R]
      })
    });
    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.CAMPANA_OP_W]
      })
    });

    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Grupos SKU">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(`/admin/programa/${id}/grupos-sku`)
            }
          >
            <ViewListIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.GRUPO_SKU_W]
    });

    return (
      <Table
        storage={"FiltroCampana"}
        query={AppQraphQL.queries.getPaginaCampanas}
        defaultHeader={defaultHeader(id, btnsNextTitle)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getCampanasOP}
        actionsCustom={[targets, edit]}
        dispatchAction={setCampanas}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setCampanas: campanas => dispatch(setCampanas(campanas))
});

export default connect(
  null,
  mapDispatchToProps
)(Tabla);
