import { connect } from "react-redux";
import { reduxForm, formValueSelector, change, getFormMeta } from "redux-form";
import MyForm from "./AdminCanjeDetail";
import { ESTADO_CANJE } from "js/constants";

const NewForm = reduxForm({
  form: "formAdminCanjeDetail",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(MyForm);

const selector = formValueSelector("formAdminCanjeDetail");

const getEstadoCanje = estadoCanje =>
  (ESTADO_CANJE.find(e => e.value === estadoCanje) || { value: undefined })
    .value;

const mapStateToProps = (state, ownProps) => ({
  formMeta: getFormMeta("formAdminCanjeDetail")(state),
  costoReal: selector(state, "costoReal"),
  fechaEntrega: selector(state, "fechaEntrega"),
  estadoCanje: getEstadoCanje(selector(state, "estadoCanje")),
  factura: selector(state, "factura"),
  deslotear: selector(state, "deslotear")
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeValue: (field, event) =>
    dispatch(change("formAdminCanjeDetail", field, event.target.value)),
  changeAutocomplete: (field, value) =>
    dispatch(change("formAdminCanjeDetail", field, value)),
  changeFecha: field => fecha =>
    dispatch(change("formAdminCanjeDetail", field, fecha))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // mergeProps
)(NewForm);
