import React, { Component } from "react";
import PropTypes from "prop-types";
import Enhancer from "./container";
import { arrayMove } from "react-sortable-hoc";
import cx from "classnames";
import SortableList from "./Components/SortableList";
import { SortableContext } from "./sortable-context.js";

class SortableComponent extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.onChange([...arrayMove(this.props.items, oldIndex, newIndex)]);
  };

  addElement = () => {
    let newItem = { ...this.props.newItem };
    if (newItem.contenidos) {
      const contenidos = [];
      for (let i = 0; i < newItem.contenidos; i++)
        contenidos.push({ ...newItem.contenidos[i] });
      newItem.contenidos = contenidos;
    }
    this.props.onChange([...this.props.items.concat([newItem])]);
  };

  removeElement = (indexToRemove, position) => {
    this.props.onChange([
      ...this.props.items.filter((item, index) => index !== indexToRemove)
    ]);
  };

  updateOnElement = (indexToUpdate, position) => value => {
    const itemsUpdated = [...this.props.items];
    itemsUpdated[indexToUpdate] = value;
    this.props.onChange(itemsUpdated);
  };

  render() {
    const {
      component,
      isCollapsable,
      classes,
      metadata,
      items,
      staticAmountItems,
      variant,
      maxAmountItems,
      newItem,
      position
    } = this.props;
    const contextValue = {
      totalItems: items.length,
      metadata,
      removeElement: this.removeElement,
      updateOnElement: this.updateOnElement,
      addElement: this.addElement,
      component,
      isCollapsable,
      classes,
      maxAmountItems,
      staticAmountItems,
      position
    };

    const setCorrectLength = (items, maxAmountItems, newItem) => {
      if (items.length === maxAmountItems) return items;
      return items.length > maxAmountItems
        ? items.slice(0, maxAmountItems)
        : items.concat(
            Array(maxAmountItems - items.length).fill({ ...newItem })
          );
    };

    const validItems =
      maxAmountItems && staticAmountItems
        ? setCorrectLength(items, maxAmountItems, newItem)
        : items;

    return (
      <div
        className={cx("SortableComponent", {
          [classes[variant]]: variant
        })}
      >
        <SortableContext.Provider value={contextValue}>
          <SortableList
            useDragHandle={true}
            helperClass={classes.sortableHelper}
            items={validItems.map((i, index) => ({
              collapsableTitle: `Contenido ${index + 1}`,
              ...i
            }))}
            onSortEnd={this.onSortEnd}
            classes={classes}
            component={component}
            position={position}
          />
        </SortableContext.Provider>
        {/* {items.length < maxAmountItems &&
          !staticAmountItems && (
            <Icon
              className={classes.iconBelow}
              color="secondary"
              onClick={this.addElement}
            >
              add_circle
            </Icon>
          )} */}
      </div>
    );
  }
}

SortableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired
};

SortableComponent.defaultProps = {
  position: [],
  newItem: "Nuevo item",
  items: [],
  staticAmountItems: false,
  metadata: {},
  isCollapsable: false,
  maxAmountItems: 50,
  variant: "normal",
  component: ({ value }) => <p>default</p>
};

export default Enhancer(SortableComponent);
