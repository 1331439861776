import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import NoSsr from "@material-ui/core/NoSsr";
import { get } from "lodash";
import {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
} from "./TypeAheadComponents";
import FormHelperText from "@material-ui/core/FormHelperText";

function getSuggestions(
  data,
  path,
  withTodos,
  without = [],
  filter,
  labelOrder = true,
  withTodosNull
) {
  let dat = get(data, path.path, []);
  if (!dat) return [];
  let options = dat
    .map(suggestion => ({
      value:
        typeof suggestion === "string"
          ? suggestion
          : get(suggestion, path.value),
      label:
        typeof suggestion === "string"
          ? suggestion
          : path.multilabel
            ? path.multilabel
                .map(label => get(suggestion, label, label))
                .join("")
            : get(suggestion, path.label, path.defaultLabel)
    }))
    .filter(a => !without.includes(a.value));

  if (labelOrder) {
    options = options.sort(
      (a, b) =>
        (a.label || "").toString().toLowerCase() >
        (b.label || "").toString().toLowerCase()
          ? 1
          : -1
    );
  }
  options = filter ? options.filter(filter) : options;
  return (withTodos && options.length > 1
    ? [{ value: withTodosNull ? null : undefined, label: "Todos" }]
    : []
  ).concat(options);
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class Autocomplete extends React.Component {
  state = { suggestions: [], firstRequestMade: false };

  getAutocompleteValue(value, multiple = false) {
    if (!multiple)
      return (
        this.state.suggestions.filter(
          option => option.value === value || option.value === parseInt(value)
        ) || {
          value: undefined,
          label: ""
        }
      );
    return (
      this.state.suggestions.filter(
        option => value && value.indexOf(option.value) > -1
      ) || [{ value: undefined, label: "" }]
    );
  }

  componentDidMount = () => {
    this.componentWillReceiveProps(this.props);
  };

  componentWillReceiveProps(props) {
    if (props.suggestions) {
      this.setState({
        suggestions: getSuggestions(
          props.suggestions,
          props.path,
          props.withTodos,
          props.without,
          props.filter,
          props.labelOrder,
          props.withTodosNull
        )
      });
    } else if (props.query) {
      if (!this.state.firstRequestMade) {
        this.setState({ firstRequestMade: true }, () => this.fetchData(props));
      } else if (this.queryParamsChanged(props.queryParams)) {
        this.fetchData(props);
      }
    } else {
      throw new Error("Missing query or suggestions prop");
    }
  }

  async fetchData(nextProps) {
    const {
      client,
      query,
      queryParams,
      path,
      withTodos,
      without,
      filter,
      labelOrder,
      withTodosNull
    } = nextProps || this.props;
    await client
      .query({
        query: query,
        variables: { ...queryParams },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      })
      .then(res => {
        this.setState({
          suggestions: getSuggestions(
            res.data,
            path,
            withTodos,
            without,
            filter,
            labelOrder,
            withTodosNull
          )
        });
      });
  }

  queryParamsChanged = newParams => {
    let result = false;
    const oldParams = this.props.queryParams;
    for (const key in newParams) {
      if (!oldParams.hasOwnProperty(key) || oldParams[key] !== newParams[key]) {
        result = true;
        break;
      }
    }
    return result;
  };

  render() {
    const {
      classes,
      theme,
      disabled,
      placeholder,
      onChange,
      value,
      label,
      noLabel = false,
      noOptionsMessage,
      multiple,
      error,
      variant,
      otherComponents = {}
    } = this.props;
    const selectStyles = {
      input: base => {
        return {
          ...base,
          color: theme.palette.text.primary,
          "& input": {
            font: "inherit"
          }
        };
      }
    };
    const val = this.getAutocompleteValue(value, multiple);
    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            options={this.state.suggestions}
            components={{ ...components, ...otherComponents }}
            onChange={onChange}
            placeholder={placeholder}
            value={val}
            noOptionsMessage={() => noOptionsMessage || "No hay información"}
            textFieldProps={{
              label: !noLabel ? label : undefined,
              InputLabelProps: {
                shrink: true,
                classes: { root: classes.label }
              },
              error: !!error,
              disabled,
              variant
            }}
            isDisabled={
              disabled ||
              (this.state.suggestions.length === 1 && multiple
                ? val[0] && val[0].value
                : val.value)
            }
            isMulti={multiple}
          />
          {error && <FormHelperText>{error}</FormHelperText>}
        </NoSsr>
      </div>
    );
  }
}

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  onChange: PropTypes.func,
  path: PropTypes.shape({
    path: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
  query: PropTypes.object,
  queryParams: PropTypes.object,
  suggestions: PropTypes.object,
  theme: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  withTodos: PropTypes.bool
};

export default Autocomplete;
