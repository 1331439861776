import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import get from "lodash/get";

import Header from "js/components/Header";
import Footer from "js/components/Footer";
import { getChatOnline } from "js/redux/programa/selectors.js";
import { setJivoChat } from "js/utils/Helper";
import AuthService from "js/utils/AuthService";

class CommonLayout extends PureComponent {
  componentDidMount() {
    const {
      chatOnline,
      programa: { modulos }
    } = this.props;
    const isLoggedIn = !!AuthService.getIdUsuario();

    if (chatOnline.chatSinLogin || (!chatOnline.chatSinLogin && isLoggedIn)) {
      setJivoChat(modulos, chatOnline);
    }
  }

  render() {
    const {
      component: Component,
      classes,
      container = false,
      matchLanding = false,
      programa,
      ...other
    } = this.props;
    const marginTop = get(programa, "template.footer.marginTop", 0);

    return (
      <Fragment>
        <div className={classes.header}>
          <Header />
        </div>
        <Paper
          elevation={1}
          className={matchLanding ? classes.contentLanding : classes.content}
          style={{ padding: container ? 10 : 0 }}
        >
          {container ? (
            <Paper className={classes.container} elevation={5}>
              <Component {...other} />
            </Paper>
          ) : (
            <Component {...other} />
          )}
        </Paper>
        <div className={classes.footer} style={{ marginTop: marginTop }}>
          <Footer />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  chatOnline: getChatOnline(state)
});

CommonLayout.displayName = "CommonLayout";

export default connect(
  mapStateToProps,
  null
)(CommonLayout);
