import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

const Spinner = ({ classes }) => {
  return (
    <div className={classes.spinner}>
      <CircularProgress thickness={7} color="inherit" variant="indeterminate" />
    </div>
  );
};

Spinner.propTypes = {
  classes: PropTypes.object
};

Spinner.defaultProps = {};

Spinner.displayName = "Spinner";

export default Spinner;
