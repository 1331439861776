import gql from "graphql-tag";

export const getTemplate = gql`
  query getTemplate($idSegmento: Int) {
    getTemplate(idSegmento: $idSegmento) {
      idTemplate
      template
    }
    getSegmentos(segmentoLike: { idSegmento: $idSegmento }) {
      idSegmento
    }
  }
`;

export default {
  queries: {
    getTemplate
  },
  mutations: {}
};
