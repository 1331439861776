import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { _t } from "js/utils/TranslationService";

function Error(props) {
  const { location } = props;
  const { state: error } = location;

  return (
    <Fragment>
      <Typography variant="h6">
        {`${_t("Parece que la página que estás buscando no está disponible")}`}
      </Typography>
      {error ? (
        <Fragment>
          <Typography component="p" color="error">
            {error.errorMessage}
          </Typography>
          <Button>
            <Link to="/">{`${_t("Intentar de nuevo")}`}</Link>
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <Button>
            <Link to="/">{`${_t("Ir al inicio")}`}</Link>
          </Button>
          <Button>
            <Link to="/signin">{`${_t("Ir al inicio de sesión")}`}</Link>
          </Button>
          <Button>
            <Link to="/signup">{`${_t("Ir al registro de usuario")}`}</Link>
          </Button>
        </Fragment>
      )}
    </Fragment>
  );
}

Error.propTypes = {
  classes: PropTypes.object.isRequired
};

export default Error;
