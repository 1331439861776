import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  reduxForm,
  formValueSelector,
  change,
  getFormMeta,
  FieldArray
} from "redux-form";
import {
  Grid,
  FormControl,
  Button,
  Icon,
  Tooltip,
  Paper,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { styles } from "js/components/Shared/Form/styles.js";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import ImageUploader, { ItemImages } from "js/components/Shared/ImageUploader";
import {
  InputTextField,
  InputNumberField,
  ListField,
  TypeAhead
} from "js/components/Shared/Form/Fields";
import { _t } from "js/utils/TranslationService";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fechaNotChanged: true,
      fechaInicio: null,
      fechaFin: null,
      fechaCierre: null
    };
  }

  handleFechaInicioChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaNotChanged: false,
      fechaInicio: date
    });
  };

  handleFechaFinChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaNotChanged: false,
      fechaFin: date
    });
  };

  handleFechaCierreChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaNotChanged: false,
      fechaCierre: date
    });
  };

  handleCampanasRelacionadasChange = values => {
    const ids = values.map(value => value.value);
    this.props.change("campanasRelacionadas", ids);
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      id,
      initialValues,
      tipo,
      onChangeImage,
      onRemoveImage,
      bannerImages,
      campanasInput,
      campanasRelacionadas,
      gruposProductos,
      segmentos
    } = this.props;

    return (
      <form className={classes.container} onSubmit={handleSubmit}>
        <Grid container spacing={24}>
          {errors &&
            errors.length !== 0 && (
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors.map(e => (
                    <li>{e.message}</li>
                  ))}
                </ul>
              </Grid>
            )}

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <InputTextField
                name="nombre"
                label={_t("Nombre del banner")}
                disabled={disabled}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <ImageUploader
              onChangeImage={onChangeImage}
              disableClick={true}
              disabled={disabled || bannerImages.length === 1}
              medidasRecomendadas={"1280 x 500"}
            >
              <ItemImages
                images={bannerImages}
                onRemoveImage={onRemoveImage}
                disabled={disabled}
                alt={"imagen-banner"}
              />
            </ImageUploader>
          </Grid>

          <Grid className={classes.gridButton} item xs={12}>
            <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              style={{ marginRight: 20 }}
              onClick={() =>
                history.push(`/admin/programa/${id}/carga-de-codigos`)
              }
            >
              {_t("Cancelar")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              disabled={
                disabled
                // disabled ||
                // (pristine && this.state.fechaNotChanged) ||
                // submitting
              }
            >
              {_t("Guardar")}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

Form = reduxForm({
  form: "formCampanaGenerica",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formCampanaGenerica");

Form = connect(
  state => ({
    formMeta: getFormMeta("formCampanaGenerica")(state),
    tipo: selector(state, "tipo"),
    campanasRelacionadas: selector(state, "campanasRelacionadas")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formCampana", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
