export default theme => ({
  expDetailsContainer: {
    display: "block"
  },
  container: {
    display: "block",
    flexDirection: "column",
    backgroundColor: "#fff !important"
  },
  xsContainer: {
    display: "block",
    flexDirection: "column",
    backgroundColor: "#fff !important",
    padding: "0px !important"
  },
  indicatorContainer: {
    display: "inline-block",
    width: "50%"
  },
  expansionTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    color: "#4D5D67",
    width: "100%"
  },
  panelSummary: {
    margin: "0 !important",
    padding: "0"
  },
  usernameTitle: {
    fontSize: "14px",
    marginBottom: "15px",
    fontWeight: "700",
    color: "#4D5D67"
  },
  campanaContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  campanaTitle: {
    display: "flex",
    flexDirection: "column"
  },
  campanaEstado: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  campanaComplete: {
    fontSize: "11px"
  },
  seeMoreContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px"
  },
  // Para sacarle el shadow al container de campaña
  root: {
    boxShadow: "none",
    border: "none",
    "&:before": {
      display: "none"
    },
    "&:not(:last-child)": {
      borderBottom: 0
    },
    padding: 0,
    paddingBottom: "20px",
    marginBottom: "20px",
    borderBottom: "1px solid rgba(0,0,0,.125)",
    expanded: {
      paddingBottom: "0px"
    }
  },
  panelRoot: {
    padding: 0
  },
  expandIcon: {
    padding: 0
  },
  detailsRoot: {
    padding: "0px !important",
    marginTop: "20px"
  }
});
