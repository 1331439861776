import React, { Fragment } from "react";
import Dropzone from "react-dropzone";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Enhancer from "./container";
import { _t } from "js/utils/TranslationService";
export { Avatar, ItemImages } from "./layouts";

const ImageUploader = ({
  accept,
  children,
  classes,
  name,
  onDrop,
  disableClick,
  disabled,
  formatosSoportados = "JPG, PNG, y SVG",
  medidasRecomendadas,
  withRecomendations = true
}) => {
  return (
    <div>
      <Dropzone
        accept={accept}
        className={classes.Dropzone}
        activeStyle={{ borderWidth: "2px" }}
        multiple={false}
        name={name}
        disableClick={disableClick}
        onDrop={!disabled && onDrop}
      >
        {({ open }) => (
          <Fragment>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.ActionBox}
            >
              {disableClick && (
                <Grid item xs={12}>
                  <Typography variant="button" className={classes.Legend} />
                  <Button
                    type="button"
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => open()}
                    disabled={disabled}
                  >
                    {_t("Arrastrar o cargar imagen")}
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} className={classes.ImageWrapper}>
                {children}
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Dropzone>
      <div className={classes.label}>
        {`Formatos soportados: ${formatosSoportados}.` +
          (withRecomendations
            ? `Medidas recomendadas: ${
                medidasRecomendadas ? medidasRecomendadas : "556 x 314"
              }`
            : "")}
      </div>
    </div>
  );
};

export default Enhancer(ImageUploader);
