import produce from "immer";
import AuthService from "js/utils/AuthService";

// ACTION TYPES
const ADD_TEMPLATE = "ADD_TEMPLATE";
const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
const ALLOW_NAVIGATION = "ALLOW_NAVIGATION";
const DENY_NAVIGATION = "DENY_NAVIGATION";
const SET_LOG_IN_STATUS = "SET_LOG_IN_STATUS";
const SET_CODIGO_CHAT_ONLINE = "SET_CODIGO_CHAT_ONLINE";

// ACTION CREATORS

export const addTemplate = dispatch => data =>
  dispatch({
    type: ADD_TEMPLATE,
    payload: data
  });

export const updateTemplate = dispatch => data => {
  dispatch({
    type: UPDATE_TEMPLATE,
    payload: data
  });
};

export const allowNavigation = dispatch => () => {
  dispatch({
    type: ALLOW_NAVIGATION
  });
};

export const denyNavigation = dispatch => () => {
  dispatch({
    type: DENY_NAVIGATION
  });
};

export const setLogInStatus = dispatch => isLogged => {
  dispatch({
    type: SET_LOG_IN_STATUS,
    isLogged
  });
};

export const setChatOnline = dispatch => payload => {
  dispatch({
    type: SET_CODIGO_CHAT_ONLINE,
    payload
  });
};

const persistedState = AuthService.loadState();

// INITIAL STATE
const initialState = {
  isLogged: false,
  template: {},
  traducciones: {},
  needReload: false,
  isAllowedToNavigate: true,
  ...persistedState
};

// REDUCER
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ADD_TEMPLATE:
        draft.template = action.payload;
        break;
      case UPDATE_TEMPLATE:
        draft.template = action.payload;
        break;
      case SET_LOG_IN_STATUS:
        draft.isLogged = action.isLogged;
        break;
      case SET_CODIGO_CHAT_ONLINE:
        draft.chatOnline = action.payload;
        break;
      case ALLOW_NAVIGATION:
        draft.isAllowedToNavigate = true;
        break;
      case DENY_NAVIGATION:
        draft.isAllowedToNavigate = false;
        break;
      default:
        return state;
    }
  });
