import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Mapa from "js/components/Mapa";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PlaceIcon from "@material-ui/icons/Place";
import { _t } from "js/utils/TranslationService";
import get from "lodash/get";

class Sucursales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapa: {
        center: {
          lat: this.props.sucursales[0].latitud,
          lng: this.props.sucursales[0].longitud
        },
        zoom: 14
      }
    };
  }

  changeMapZoom(zoom) {
    const mapa = { ...this.state.mapa };
    mapa.zoom = zoom;
    this.setState({ mapa });
  }

  selectSucursal = idSucursal => event => {
    const { latitud, longitud } = this.props.sucursales.find(
      s => s.idSucursal === idSucursal
    );
    this.setState({ mapa: { zoom: 15, center: { latitud, longitud } } });
  };

  render() {
    const { classes, sucursales } = this.props;

    return (
      <Grid container spacing={40} className={classes.sectionContainer}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant="h6" gutterBottom>
            {_t("Ubicación")}
          </Typography>
          <Mapa
            sucursales={sucursales}
            mapa={this.state.mapa}
            onChangeMapZoom={zoom => this.changeMapZoom(zoom)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant="h6" gutterBottom>
            {_t("Sucursales")}
          </Typography>
          <div className={classes.sucursalesList}>
            <List dense={true}>
              {sucursales
                .filter(s => (s.nombre || "").toLowerCase() !== "default")
                .map(sucursal => {
                  const zona = get(sucursal, "zona.descripcion", "");
                  const pais = get(sucursal, "pais.descripcion", "");
                  let region = zona;
                  region += zona.length && pais.length ? `, ${pais}` : pais;
                  let secondary = sucursal.direccion || "";
                  secondary +=
                    secondary.length && region.length ? ` - ${region}` : region;
                  secondary += sucursal.telefono
                    ? `. ${_t("Teléfono")}: ${sucursal.telefono}`
                    : "";
                  return (
                    <div
                      key={sucursal.idSucursal}
                      onClick={this.selectSucursal(sucursal.idSucursal)}
                    >
                      <ListItem>
                        <PlaceIcon />
                        <ListItemText
                          primary={sucursal.nombre}
                          secondary={secondary}
                        />
                      </ListItem>
                    </div>
                  );
                })}
            </List>
          </div>
        </Grid>
      </Grid>
    );
  }
}

Sucursales.propTypes = {};

Sucursales.defaultProps = {
  sucursales: []
};

export default Sucursales;
