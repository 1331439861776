import gql from "graphql-tag";

export const importarCodigosCargados = gql`
  mutation importarCodigosCargados($file: Upload!, $idPrograma: Int!) {
    importarCodigosCargados(file: $file, idPrograma: $idPrograma)
  }
`;

export const getCargadeCodigos = gql`
  query getCargadeCodigos(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: GetCodigoCargadoColumn!
    $idPrograma: Int
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
    $estado: String
    $tipodeProducto: TipodeProducto
    $tipodeCodigo: String
    $tipo: TipodeCodigoCreate
    $marca: String
    $codigo: String
  ) {
    getCargadeCodigos(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      ventaOrder: { column: $orderBy, order: $order }
      ventaLike: {
        idPrograma: $idPrograma
        estado: $estado
        tipodeProducto: $tipodeProducto
        tipodeCodigo: $tipodeCodigo
        codigo: $codigo
        tipo: $tipo
        marca: $marca
      }
      ventaRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        fechaCreacion
        tipodeProducto
        tipodeCodigo
        descripcion
        estado
        codigo
        tipo
        marca
      }
    }
  }
`;

export const createVentaCargadeCodigos = gql`
  mutation createVentaCargadeCodigos($venta: GetCodigoCargadoCreate!) {
    createVentaCargadeCodigos(venta: $venta) {
      _id
    }
  }
`;

//modificar esta querie con back para consultar los codigos ingresados

export const getCodigosCargado = gql`
  query getCodigosCargados(
    $productoLike: ProductoCodificadoSearch
    $pagina: Pagina
  ) {
    getCodigosCargados(productoLike: $productoLike, pagina: $pagina) {
      itemsPagina {
        _id
        idPrograma
        codigo
        nombre
        descripcion
        estado
        puntosPorSegmentos {
          _id
          idSegmento
          puntos
        }
      }
    }
  }
`;

export const getMisVentasCodigosCargados = gql`
  query getMisVentasCodigosCargados(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: VentaProductoCodificadoColumn!
    $idPrograma: Int
    $idProducto: ID
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getMisVentasCodigosCargados(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      ventaOrder: { column: $orderBy, order: $order }
      ventaLike: { idPrograma: $idPrograma, idProducto: $idProducto }
      ventaRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        total
        fechaCreacion
        usuario {
          username
          rol {
            nombre
          }
        }
        producto {
          codigo
          nombre
        }
      }
    }
  }
`;

export const getVentasCodigosCargados = gql`
  query getVentasCodigosCargados(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: VentaProductoCodificadoColumn!
    $idPrograma: Int
    $idUsuario: Int
    $idSegmento: Int
    $idProducto: ID
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getVentasCodigosCargados(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      ventaOrder: { column: $orderBy, order: $order }
      ventaLike: {
        idPrograma: $idPrograma
        idUsuario: $idUsuario
        idProducto: $idProducto
        idSegmento: $idSegmento
      }
      ventaRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        total
        fechaCreacion
        usuario {
          username
          segmento {
            nombre
          }
        }
        producto {
          codigo
          nombre
        }
      }
    }
  }
`;

export default {
  queries: {
    importarCodigosCargados,
    getCargadeCodigos,
    getCodigosCargado,
    getMisVentasCodigosCargados,
    getVentasCodigosCargados
  },
  mutations: {
    createVentaCargadeCodigos
  }
};
