import React, { PureComponent, Fragment } from "react";
import cx from "classnames";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ListIcon from "@material-ui/icons/List";
import CanjeIcon from "@material-ui/icons/AccountBalanceWallet";
import TableChartIcon from "@material-ui/icons/TableChartOutlined";
import RoomIcon from "@material-ui/icons/RoomOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/MailOutline";
import BusinessIcon from "@material-ui/icons/Business";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import UpdateIcon from "@material-ui/icons/Update";
import UploadIcon from "@material-ui/icons/CloudUpload";
import LinkIcon from "@material-ui/icons/InsertLink";
import WorkIcon from "@material-ui/icons/WorkOutline";
import LockIcon from "@material-ui/icons/LockOutlined";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MaxMovPuntosIcon from "@material-ui/icons/ErrorOutline";
import MaxMovPlataIcon from "@material-ui/icons/Money";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportantOutlined";
import CalendarAvailableIcon from "@material-ui/icons/EventAvailable";
import CalendarBusyIcon from "@material-ui/icons/EventBusy";
import SettingsIcon from "@material-ui/icons/Settings";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { renderFecha } from "js/utils/Helper";
import { numberWithCommas } from "js/utils";
import { s3Url, exportURL } from "js/constants";
import { Mutation } from "react-apollo";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import get from "lodash/get";
import { _t } from "js/utils/TranslationService";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

import { Shield, ShieldRemove } from "../../../../utils";

const success = "El archivo se procesó correctamente";
const errorMessage =
  'Hubo un error al procesar el archivo. Ver los detalles en "Logs"';

class Gestion extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {},
      id: this.props.match.params.id
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    let idPrograma = parseInt(id) || id;
    const perm = !!AuthService.hasPerms([Roles.PROGRAMA_LISTA]);
    const { data } = await client.query({
      query: perm
        ? AppQraphQL.queries.getProgramaById
        : AppQraphQL.queries.getMiProgramaCompleto,
      fetchPolicy: "network-only",
      variables: {
        idPrograma,
        url: window.location.hostname.replace("www.", "")
      }
    });

    //TODO: do then and catch since its a promise
    if (data) {
      this.setState({
        initialValues: {
          ...(perm ? data.getProgramas.itemsPagina[0] : data.getPrograma)
        }
      });
    }
  }

  render() {
    const { classes, history } = this.props;
    const { id, initialValues } = this.state;
    let idPrograma = parseInt(id) || id;
    return (
      <Grid
        className={classes.container}
        container
        direction="row"
        alignItems="center"
        spacing={16}
      >
        <Grid item xs={12} sm={8} style={{ paddingTop: 0 }}>
          <div className={classes.flex}>
            <Typography variant="h6">{initialValues.nombre}</Typography>
            {AuthService.hasPerms([Roles.PROGRAMA_W]) && (
              <Tooltip title="Editar programa">
                <IconButton
                  onClick={e =>
                    history.push("/admin/programa/" + idPrograma + "/edit")
                  }
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div className={classes.flex}>
            <Typography variant="subtitle1">
              {initialValues.razonSocial}
              ,&nbsp;
            </Typography>
            <Typography variant="subtitle1">{initialValues.cuit}</Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <div className={classes.cuentaCorriente}>
              Cuenta
              <br />
              Corriente
            </div>
            <h2>
              {initialValues.cuentaCorriente &&
              initialValues.cuentaCorriente.balance
                ? numberWithCommas(initialValues.cuentaCorriente.balance)
                : "--"}
            </h2>
            {AuthService.hasPerms([Roles.MOVIMIENTO_PROGRAMA_R]) && (
              <div>
                <Tooltip title="Ver cta. cte.">
                  <IconButton
                    onClick={e =>
                      history.push(
                        "/admin/programa/" + idPrograma + "/cuenta_corriente"
                      )
                    }
                  >
                    <CanjeIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </Paper>
        </Grid>
        <Grid container item spacing={0} xs={12}>
          <Grid item xs={12} sm={6}>
            {initialValues.razonSocial && (
              <Paper className={classes.paper}>
                <div className={classes.flex}>
                  <BusinessIcon />
                  <div className={classes.marginDetails}>
                    {initialValues.razonSocial}
                  </div>
                </div>
              </Paper>
            )}
            {initialValues.telefonoContacto && (
              <Paper className={classes.paper}>
                <div className={classes.flex}>
                  <PhoneIcon />
                  <div className={classes.marginDetails}>
                    {initialValues.telefonoContacto}
                  </div>
                </div>
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {initialValues.paises && (
              <Paper className={classes.paper}>
                <div className={classes.flex}>
                  <RoomIcon />
                  <div className={classes.marginDetails}>
                    {initialValues.paises
                      .map(pais => pais.descripcion)
                      .join(", ")}
                  </div>
                </div>
              </Paper>
            )}
            {initialValues.emailContacto && (
              <Paper className={classes.paper}>
                <div className={classes.flex}>
                  <MailIcon />
                  <div className={classes.marginDetails}>
                    {initialValues.emailContacto}
                  </div>
                </div>
              </Paper>
            )}
          </Grid>
        </Grid>

        <Grid container item spacing={0} xs={12}>
          <Grid item xs={12} sm={6}>
            <Paper className={cx(classes.paper, classes.paperWhite)}>
              <div className={classes.flex}>
                <WorkIcon />
                <div className={classes.marginDetails}>
                  {initialValues.tipo || "--"}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={cx(classes.paper, classes.paperWhite)}>
              <div className={classes.flex}>
                <LinkIcon />
                <div className={classes.marginDetails}>
                  {initialValues.url || "--"}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={cx(classes.paper, classes.paperWhite)}>
              <div className={classes.flex}>
                <SwapHorizIcon />
                <div className={classes.marginDetails}>
                  {initialValues.tasaConversion || "--"}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={cx(classes.paper, classes.paperWhite)}>
              <div className={classes.flex}>
                <NotificationsIcon />
                <div className={classes.marginDetails}>
                  {initialValues.emailRemitente || "--"}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={cx(classes.paper, classes.paperWhite)}>
              <div className={classes.flex}>
                <AttachMoneyIcon />
                <div className={classes.marginDetails}>
                  {initialValues.monedaBase || "--"}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={cx(classes.paper, classes.paperWhite)}>
              {initialValues.esPublico && (
                <div className={classes.flex}>
                  <LockOpenIcon />
                  <div className={classes.marginDetails}>Programa abierto</div>
                </div>
              )}
              {!initialValues.esPublico && (
                <div className={classes.flex}>
                  <LockIcon />
                  <div className={classes.marginDetails}>Programa cerrado</div>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={cx(classes.paper, classes.paperWhite)}>
              {!initialValues.tieneMFA && (
                <div className={classes.flex}>
                  <ShieldRemove />
                  <div className={classes.marginDetails}>
                    Doble factor inactivo
                  </div>
                </div>
              )}
              {initialValues.tieneMFA && (
                <div className={classes.flex}>
                  <Shield />
                  <div className={classes.marginDetails}>
                    Doble factor activo
                  </div>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} container direction="row">
            <Grid item xs={12} sm={6}>
              <Paper className={cx(classes.paper, classes.paperWhite)}>
                <div className={classes.flex}>
                  <Tooltip
                    title="Máximo crédito puntos CC Usuarios"
                    placement="left-start"
                  >
                    <MaxMovPuntosIcon />
                  </Tooltip>
                  <div className={classes.marginDetails}>
                    {initialValues.maximoMovimientoPuntos
                      ? numberWithCommas(initialValues.maximoMovimientoPuntos) +
                        " puntos"
                      : "--"}
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={cx(classes.paper, classes.paperWhite)}>
                <div className={classes.flex}>
                  <Tooltip
                    title="Máximo crédito $ CC Programa"
                    placement="left-start"
                  >
                    <MaxMovPlataIcon />
                  </Tooltip>
                  <div className={classes.marginDetails}>
                    {initialValues.maximoMovimientoPlata
                      ? "$ " +
                        numberWithCommas(initialValues.maximoMovimientoPlata)
                      : "--"}
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={cx(classes.paper, classes.paperWhite)}>
                <div className={classes.flex}>
                  <Tooltip title="Vencimiento puntos" placement="left-start">
                    <UpdateIcon />
                  </Tooltip>
                  <div className={classes.marginDetails}>
                    {initialValues.diasParaVencimientoPuntos
                      ? numberWithCommas(
                          initialValues.diasParaVencimientoPuntos
                        )
                      : "--"}
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={cx(classes.paper, classes.paperWhite)}>
                <div className={classes.flex}>
                  <Tooltip
                    title="Notificación de vencimiento"
                    placement="left-start"
                  >
                    <NotificationImportantIcon />
                  </Tooltip>
                  <div className={classes.marginDetails}>
                    {initialValues.diasParaNotificacionVencimientoPuntos
                      ? numberWithCommas(
                          initialValues.diasParaNotificacionVencimientoPuntos
                        )
                      : "--"}
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={cx(classes.paper, classes.paperWhite)}>
              <div className={classes.flex}>
                <CalendarAvailableIcon />
                <div className={classes.marginDetails}>
                  {renderFecha(initialValues.fechaInicio)}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={cx(classes.paper, classes.paperWhite)}>
              <div className={classes.flex}>
                <CalendarBusyIcon />
                <div className={classes.marginDetails}>
                  {renderFecha(initialValues.fechaBaja)}
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={cx(classes.paper, classes.paperWhite)}>
            <div>{_t("Usuario creador")}:</div>
            <b>{get(initialValues, "usuarioCreador.username", "--")} </b>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={cx(classes.paper, classes.paperWhite)}>
            <div>{_t("Último modificador")}:</div>
            <b>{get(initialValues, "usuarioModificador.username", "--")} </b>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={cx(classes.paper, classes.paperWhite)}>
            <div>{_t("Fecha de creación")}:</div>
            <b>{renderFecha(initialValues.fechaInicio)}</b>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={cx(classes.paper, classes.paperWhite)}>
            <div>{_t("Última modificación")}:</div>
            <b>{renderFecha(initialValues.fechaUltimaModificacion)}</b>
          </Paper>
        </Grid>
        {AuthService.hasPerms([Roles.USUARIO_R]) && (
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <div>Usuarios</div>
              <div>
                <Tooltip title="Listar usuarios">
                  <IconButton
                    onClick={e => history.push(`/admin/programa/${id}/usuario`)}
                  >
                    <ListIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Paper>
          </Grid>
        )}
        {AuthService.hasPerms([Roles.PRODUCTO_CATALOGO_R]) && (
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <div>Catálogo</div>
              <div>
                <Tooltip title="Listar catálogo">
                  <IconButton
                    onClick={e =>
                      history.push(`/admin/programa/${idPrograma}/catalogo`)
                    }
                  >
                    <ListIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Paper>
          </Grid>
        )}
        {AuthService.hasPerms([Roles.CANJE_R]) && (
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <div>Canjes</div>
              <div>
                <Tooltip title="Listar canjes">
                  <IconButton
                    onClick={e =>
                      history.push(`/admin/programa/${idPrograma}/canjes`)
                    }
                  >
                    <ListIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Paper>
          </Grid>
        )}
        {AuthService.hasAnyPerm([Roles.UBICACION_R, Roles.UBICACION_W]) && (
          <Grid item xs={12} sm={6} lg={4}>
            <Paper className={classes.paper}>
              <div>País, zona y localidad</div>
              <div>
                {AuthService.hasPerms([Roles.UBICACION_W]) && (
                  <Fragment>
                    <Tooltip title="Modelo de importación">
                      <IconButton
                        onClick={e =>
                          (window.location.href = `${s3Url}Ubicaciones_Modelo.csv`)
                        }
                      >
                        <TableChartIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Importar">
                      <IconButton
                        onClick={() =>
                          document.getElementById("upload-ubicaciones").click()
                        }
                      >
                        <Mutation
                          mutation={
                            AppQraphQLImport.queries.importarUbicaciones
                          }
                        >
                          {mutate => {
                            return (
                              <input
                                id="upload-ubicaciones"
                                type="file"
                                style={{ display: "none" }}
                                required
                                onChange={({
                                  target: {
                                    validity,
                                    files: [file]
                                  }
                                }) => {
                                  return (
                                    validity.valid &&
                                    mutate({
                                      variables: { file, idPrograma }
                                    })
                                      .then(() => {
                                        this.props.openNotification(success);
                                      })
                                      .catch(() => {
                                        this.props.openNotification(
                                          errorMessage
                                        );
                                      })
                                      .finally(
                                        () =>
                                          (document.getElementById(
                                            "upload-ubicaciones"
                                          ).value = "")
                                      )
                                  );
                                }}
                              />
                            );
                          }}
                        </Mutation>
                        <UploadIcon />
                      </IconButton>
                    </Tooltip>
                  </Fragment>
                )}
                {AuthService.hasPerms([Roles.UBICACION_R]) && (
                  <Tooltip title="Exportar">
                    <IconButton
                      onClick={e =>
                        (window.location.href = `${exportURL}/ubicaciones?idPrograma=${idPrograma}`)
                      }
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </Paper>
          </Grid>
        )}
        {AuthService.hasAnyPerm([Roles.CATEGORIA_R, Roles.CATEGORIA_W]) && (
          <Grid item xs={12} sm={6} lg={4}>
            <Paper className={classes.paper}>
              <div>
                Categorías y<br />
                Subcategorías
              </div>
              <div>
                {AuthService.hasPerms([Roles.CATEGORIA_W]) && (
                  <Tooltip title="Modelo de importación">
                    <IconButton
                      onClick={e =>
                        (window.location.href = `${s3Url}Categorías_Modelo.csv`)
                      }
                    >
                      <TableChartIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {AuthService.hasPerms([Roles.CATEGORIA_W]) && (
                  <Tooltip title="Importar">
                    <IconButton
                      onClick={() =>
                        document.getElementById("upload-subrubros").click()
                      }
                    >
                      <Mutation
                        mutation={AppQraphQLImport.queries.importarSubrubros}
                      >
                        {(mutate, { loading, data, error }) => {
                          if (loading) {
                            const message = "El archivo se está procesando";
                            this.props.openNotification(message);
                          }
                          return (
                            <input
                              id="upload-subrubros"
                              type="file"
                              style={{ display: "none" }}
                              required
                              onChange={({
                                target: {
                                  validity,
                                  files: [file]
                                }
                              }) => {
                                return (
                                  validity.valid &&
                                  mutate({
                                    variables: { file, idPrograma }
                                  })
                                    .then(() => {
                                      this.props.openNotification(success);
                                    })
                                    .catch(() => {
                                      this.props.openNotification(errorMessage);
                                    })
                                    .finally(
                                      () =>
                                        (document.getElementById(
                                          "upload-subrubros"
                                        ).value = "")
                                    )
                                );
                              }}
                            />
                          );
                        }}
                      </Mutation>
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {AuthService.hasPerms([Roles.CATEGORIA_R]) && (
                  <Tooltip title="Exportar">
                    <IconButton
                      onClick={e =>
                        (window.location.href = `${exportURL}/rubrossubrubros?idPrograma=${idPrograma}`)
                      }
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </Paper>
          </Grid>
        )}
        {AuthService.hasPerms([Roles.PROGRAMA_W]) && (
          <Grid item xs={12} sm={6} lg={4}>
            <Paper className={classes.paper}>
              <div>Segmentos</div>
              <Tooltip title="Gestionar">
                <div>
                  <IconButton
                    onClick={e =>
                      history.push(
                        "/admin/programa/" + idPrograma + "/segmentos"
                      )
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Paper>
          </Grid>
        )}
        {AuthService.hasPerms([Roles.DEFINICION_PERFIL_R]) && (
          <Grid item xs={12} sm={6} lg={4}>
            <Paper className={classes.paper}>
              <div>Datos de perfil</div>
              <Tooltip title="Gestionar">
                <div>
                  <IconButton
                    onClick={e =>
                      history.push("/admin/programa/" + idPrograma + "/perfil")
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Paper>
          </Grid>
        )}
        {AuthService.hasPerms([Roles.PROGRAMA_W]) && (
          <Grid item xs={12} sm={6} lg={4}>
            <Paper className={classes.paper}>
              <div>Notificaciones</div>
              <Tooltip title="Gestionar">
                <div>
                  <IconButton
                    onClick={e =>
                      history.push(
                        "/admin/programa/" + idPrograma + "/notificaciones"
                      )
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Paper>
          </Grid>
        )}
        {AuthService.hasPerms([Roles.PROGRAMA_W]) && (
          <Grid item xs={12} sm={6} lg={4}>
            <Paper className={classes.paper}>
              <div>Legales</div>
              <Tooltip title="Gestionar">
                <div>
                  <IconButton
                    onClick={e =>
                      history.push("/admin/programa/" + idPrograma + "/legales")
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Paper>
          </Grid>
        )}
        {AuthService.hasPerms([Roles.PROGRAMA_W]) && (
          <Grid item xs={12} sm={6} lg={4}>
            <Paper className={classes.paper}>
              <div>Template</div>
              <Tooltip title="Gestionar">
                <div>
                  <IconButton
                    onClick={e =>
                      history.push(
                        `/admin/programa/${idPrograma}/template-builder-2/template`
                      )
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Paper>
          </Grid>
        )}
        {AuthService.hasPerms([Roles.PROGRAMA_W]) && (
          <Grid item xs={12} sm={6} lg={4}>
            <Paper className={classes.paper}>
              <div>Layout (header y footer)</div>
              <Tooltip title="Gestionar">
                <div>
                  <IconButton
                    onClick={e =>
                      history.push(
                        `/admin/programa/${idPrograma}/template-builder-2/layout`
                      )
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Paper>
          </Grid>
        )}
        {AuthService.hasPerms([Roles.PROGRAMA_W]) && (
          <Grid item xs={12} sm={6} lg={4}>
            <Paper className={classes.paper}>
              <div>Landing</div>
              <Tooltip title="Gestionar">
                <div>
                  <IconButton
                    onClick={e =>
                      history.push(
                        `/admin/programa/${idPrograma}/template-builder-2/landing`
                      )
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Paper>
          </Grid>
        )}
        {AuthService.hasPerms([Roles.PROGRAMA_W]) && (
          <Grid item xs={12} sm={6} lg={4}>
            <Paper className={classes.paper}>
              <div>Destacados</div>
              <Tooltip title="Gestionar">
                <div>
                  <IconButton
                    onClick={e =>
                      history.push(
                        `/admin/programa/${idPrograma}/template-builder-2/destacados`
                      )
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Paper>
          </Grid>
        )}
        {AuthService.hasPerms([Roles.IDIOMA_R]) && (
          <Grid item xs={12} sm={6} lg={4}>
            <Paper className={classes.paper}>
              <div>Idiomas</div>
              <Tooltip title="Gestionar">
                <div>
                  <IconButton
                    onClick={e =>
                      history.push("/admin/programa/" + idPrograma + "/idioma")
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Paper>
          </Grid>
        )}
        {AuthService.hasPerms([Roles.ACCION_R]) && (
          <Grid item xs={12} sm={6} lg={4}>
            <Paper className={classes.paper}>
              <div>Acciones</div>
              <Tooltip title="Acciones">
                <div>
                  <IconButton
                    onClick={e =>
                      history.push(
                        "/admin/programa/" + idPrograma + "/acciones"
                      )
                    }
                  >
                    <LocalActivityIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default Gestion;
