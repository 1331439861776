import React, { Fragment, Component } from "react";
import TextField from "@material-ui/core/TextField";
import client from "js/App/client.graphql.js";
import Grid from "@material-ui/core/Grid";
import IdiomaQraphQL from "js/graphql/resolvers/idioma.resolver";
import Button from "@material-ui/core/Button";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import get from "lodash/get";

class ProgramaIdiomaEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idPrograma: this.props.match.params.id,
      idIdioma: this.props.match.params.idIdioma,
      edit: this.props.match.params.edit,
      etiqueta: ""
    };
  }

  async componentDidMount() {
    const {
      client,
      match: {
        params: { id: idPrograma }
      }
    } = this.props;
    const { edit, idIdioma } = this.state;

    if (edit) {
      await client
        .query({
          query: IdiomaQraphQL.queries.getIdiomasByProgramaId,
          fetchPolicy: "network-only",
          variables: {
            idPrograma
          }
        })
        .then(res => {
          const selectedIdioma = get(
            res,
            "data.getProgramas.itemsPagina[0].idiomas",
            []
          ).find(data => data.idioma._id === idIdioma);
          this.setState({
            etiqueta: selectedIdioma.etiqueta
          });
        });
    }
  }

  isDissabled = () =>
    this.state.idIdioma !== undefined && this.state.etiqueta !== "";

  sendValues = async () => {
    const { idIdioma, etiqueta, idPrograma, edit } = this.state;
    let mutation = IdiomaQraphQL.mutations.addIdiomaToPrograma;
    let notificationString = "agregado";

    if (edit) {
      mutation = IdiomaQraphQL.mutations.updateEtiquetaDeIdioma;
      notificationString = "editado";
    }

    await client
      .mutate({
        mutation,
        variables: {
          idPrograma: parseInt(idPrograma),
          idIdioma,
          etiqueta
        }
      })
      .then(res => {
        this.props.openNotification(
          `Idioma ${notificationString} correctamente`
        );
        this.props.history.push(`/admin/programa/${idPrograma}/idioma`);
      })
      .catch(error => {
        this.props.openNotification(error.graphQLErrors[0].message);
      });
  };

  handleChange = key => event => {
    this.setState({ [key]: event.target.value });
  };

  render() {
    const {
      classes,
      history,
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <Fragment>
        <Grid
          container
          spacing={16}
          className={classes.facturaForm}
          direction="column"
        >
          <Grid item xs={12} md={6} className={classes.grid}>
            <TypeAhead
              label={"Idioma"}
              onChange={select =>
                this.handleChange("idIdioma")({
                  target: { name: "idIdioma", value: select.value }
                })
              }
              path={{
                path: "getIdiomas.itemsPagina",
                value: "_id",
                label: "nombre"
              }}
              placeholder={"Buscar idioma"}
              query={IdiomaQraphQL.queries.getIdiomas}
              value={this.state.idIdioma}
            />
          </Grid>

          <Grid item xs={12} md={6} className={classes.grid}>
            <TextField
              id="standard-name"
              label="Etiqueta"
              className={classes.textField}
              value={this.state.etiqueta}
              onChange={this.handleChange("etiqueta")}
              margin="normal"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Button
              disabled={!this.isDissabled()}
              onClick={this.sendValues}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {"Guardar"}
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              onClick={() => history.push(`/admin/programa/${id}/idioma`)}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

ProgramaIdiomaEdit.defaultProps = {};

ProgramaIdiomaEdit.propTypes = {};

export default ProgramaIdiomaEdit;
