import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { hasSubmitFailed } from "redux-form";
import { connect } from "react-redux";
import { compose } from "recompose";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import SignupForm from "./SignupForm";
import UsuarioQraphQL from "js/graphql/resolvers/usuario.resolver";
import IdiomasList from "js/components/Shared/IdiomasList";
import { _t } from "js/utils/TranslationService";

class SignupFormContainer extends Component {
  state = { loading: false, errors: null, userSignedUp: false, password: "" };

  handleChange = prop => event => {
    this.setState({ errors: null, [prop]: event.target.value });
  };

  async submit(values) {
    const { client, programa } = this.props;
    const { username } = values;
    const { password } = this.state;
    const usernameTrimmed = username.trim();
    let otros = Object.assign({}, values);
    delete otros.username;
    delete otros.password;
    this.setState({ loading: true, errors: null });
    await client
      .mutate({
        mutation: UsuarioQraphQL.mutations.signup,
        variables: {
          usuario: {
            username: usernameTrimmed,
            password,
            idPrograma: programa.idPrograma,
            perfil: {
              otros: JSON.stringify(otros)
            }
          }
        }
      })
      .then(res => {
        this.props.openNotification(_t("Usuario creado correctamente"));
        this.setState({
          userSignedUp: true
        });
      })
      .catch(e => {
        this.setState({ errors: e });
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { classes, programa, logo } = this.props;
    if (!programa.conFormulario) {
      this.props.history.push("/signin");
      return true;
    }
    const { access } = programa.template;
    const { loading, errors, userSignedUp, password } = this.state;
    return (
      <Paper className={classes.root} elevation={1}>
        <div className={classes.idiomaPosition}>
          <IdiomasList />
        </div>
        <img
          alt="Logo en pantallas de accesso"
          src={logo}
          className={classes.logo}
        />
        <Typography variant="h6" gutterBottom>
          {access.signup.titulo}
        </Typography>
        {!userSignedUp && (
          <>
            <Typography variant="subtitle1" gutterBottom>
              {access.signup.descripcion}
            </Typography>
            <SignupForm
              onSubmit={this.submit.bind(this)}
              {...this.props}
              loading={loading}
              errors={errors}
              password={password}
              handleChange={this.handleChange.bind(this)}
            />
          </>
        )}
        {userSignedUp && (
          <Typography variant="subtitle1" gutterBottom>
            {_t("Le enviamos un mail de verificación")}
          </Typography>
        )}

        <Typography variant="caption" gutterBottom align="right">
          <Link to="/signin">{_t("Volver al inicio de sesión")}</Link>
        </Typography>
        <Grid container justify="space-between">
          <Typography variant="caption" gutterBottom align="left">
            <Link to="/restoreUser">{_t("Recuperar usuario")}</Link>
          </Typography>
          <Typography variant="caption" gutterBottom align="right">
            <Link to="/restorePassword">{_t("Recuperar clave")}</Link>
          </Typography>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
  hasSubmitFailed: hasSubmitFailed("signupForm")(state)
});
const mapDispatchToProps = {};

export default compose(
  withApollo,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SignupFormContainer);
