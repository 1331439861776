import React from "react";
import EmailIcon from "@material-ui/icons/Send";
import client from "js/App/client.graphql.js";
import AppQraphQL from "js/graphql/resolvers/email.resolver";
import base from "./Base";
import AuthService from "js/utils/AuthService";

export default params => ({
  ...base(),
  label: "Emails",
  icon: <EmailIcon />,
  show: () => AuthService.hasPerms(params.perms),
  tooltip: "Reenviar email",
  onclick: () =>
    client
      .mutate({
        mutation: AppQraphQL.mutations.reenviarEmail,
        variables: {
          email: {
            _id: params.itemId
          }
        },
        errorPolicy: "all"
      })
      .then(res => {
        res.errors && res.errors.length > 0
          ? params.openNotification(res.errors[0].message)
          : params.openNotification("El email ha sido reenviado correctamente");
      })
      .catch(() => {
        params.openNotification("Error al intentar reenviar el email");
      })
});
