import {
  compose,
  withHandlers,
  lifecycle,
  setDisplayName,
  defaultProps
} from "recompose";
import differenceInSeconds from "date-fns/difference_in_seconds";
import get from "lodash/get";
import startCase from "lodash/startCase";
import AuthService from "js/utils/AuthService";
import { logAccion } from "js/constants";

const URL = process.env.REACT_APP_KIBANA_TRACKER || logAccion;

export const modules = [
  { name: "signin", modulo: "Inicio de Sesión" },
  { name: "restorePassword", modulo: "Recupero de Clave" },
  { name: "updatePassword", modulo: "Actualización de Clave" },
  { name: "restoreUser", modulo: "Recupero de Usuario" },
  { name: "signup", modulo: "Registro" },
  { name: "updateMyPassword", modulo: "Cambio de Clave" },
  { name: "email-verificado", modulo: "Verificación de email" },
  { name: "landing", modulo: "Landing" },
  { name: "catalogo", modulo: "Catálogo" },
  { name: "producto", modulo: "Detalle Producto" },
  { name: "profile", modulo: "Mi Perfil" },
  { name: "mis-movimientos", modulo: "Mis Movimientos" },
  { name: "faq", modulo: "Preguntas Frecuentes" },
  { name: "privacidad", modulo: "Política de Privacidad" },
  { name: "condiciones", modulo: "Términos y Condiciones" },
  { name: "informacion", modulo: "Información" },
  { name: "privado", modulo: "Privado" },
  { name: "canje", modulo: "Confirmación de Canje" },
  { name: "trivia", modulo: "Trivia" },
  { name: "encuesta", modulo: "Encuesta" },
  { name: "formulario", modulo: "Formulario" },
  { name: "ya-participo", modulo: "Ya participo en acción" },
  { name: "misreportes", modulo: "Reportes de programa" },
  { name: "reportes", modulo: "Mi reporte Club La Nación" },
  { name: "reportesCLN", modulo: "Reportes Club La Nación" },
  { name: "ranking", modulo: "Ranking de acciones" },
  { name: "detalleAccion", modulo: "Detalle de acciones" },
  { name: "formularioreconocimiento", modulo: "Reconocimientos" },
  { name: "reconocimientos", modulo: "Reconocimientos" },
  { name: "reconocido/aprobados", modulo: "Reconocimientos" },
  { name: "reconocido", modulo: "Reconocimientos" },
  { name: "cc-usuarios", modulo: "Cuenta corriente de usuarios" },
  { name: "contenidos", modulo: "Contenidos FE" },
  { name: "mi-performance", modulo: "Objetivos y performance" },
  { name: "meds-performances", modulo: "Objetivos y performance" },
  { name: "usuarios", modulo: "Usuarios" },
  { name: "canjes", modulo: "Canjes" },
  { name: "ventas", modulo: "Ventas Retail" },
  { name: "mis-ventas", modulo: "Ventas Retail" },
  { name: "usuarios-mis-ventas", modulo: "Usuarios" },
  { name: "registrar-productos", modulo: "Productos codificados" },
  { name: "registrar-mis-productos", modulo: "Productos codificados" },
  { name: "prueba-token", modulo: "Ignore" },
  { name: "acciones", modulo: "Acciones" },
  { name: "acciones", modulo: "Acciones" },
  { name: "solicitudes-beneficios", modulo: "Beneficios" },
  { name: "catalogo-beneficios", modulo: "Beneficios" },
  { name: "beneficio", modulo: "Beneficios" },
  { name: "solicitud-beneficio", modulo: "Beneficios" },
  { name: "mis-solicitudes", modulo: "Beneficios" },
  { name: "mi-solicitud", modulo: "Beneficios" },
  { name: "logs", modulo: "Logs" },
  { name: "meds", modulo: "Unilever Meds" },
  { name: "mis-meds", modulo: "Unilever Meds" },
  { name: "targets-preventistas", modulo: "Unilever Targets" },
  { name: "targets-preventistas-mis-meds", modulo: "Unilever Targets" },
  { name: "targets-mis-preventistas", modulo: "Unilever Targets" },
  { name: "porcentajes-mis-preventistas", modulo: "Unilever Targets" },
  { name: "mi-performance-campanas", modulo: "Campanas" },
  { name: "performance-campanas", modulo: "Campanas" },
  { name: "carga-de-codigos", modulo: "Carga de Codigos" },
  { name: "moderacion", modulo: "Moderación" },
  { name: "carga-facturas", modulo: "Carga de Facturas" },
  { name: "factura-por-puntos", modulo: "Factura por Puntos" },
  {
    name: "moderacion-factura-por-puntos",
    modulo: "Moderación Factura por Puntos"
  },
  { name: "beneficio-catalogo-codigos", modulo: "Catálogo Códigos" },
  { name: "catalogo-codigos", modulo: "Catálogo Códigos" },
  { name: "solicitudes-catalogo-codigos", modulo: "Catálogo Códigos" },
  { name: "solicitud-catalogo-codigos", modulo: "Catálogo Códigos" },
  { name: "mis-solicitudes", modulo: "Catálogo Códigos" },
  { name: "mi-solicitud", modulo: "Catálogo Códigos" },
  { name: "catalogo-beneficios", modulo: "Catálogo Códigos" },
  { name: "links-tracking", modulo: "Tracking de links" },
  { name: "sesiones-tracking", modulo: "Tracking de sesiones" }
];

const getUrlAndModule = compose(
  ([url, { modulo }]) => ({ url, modulo }),
  pathname => [
    pathname,
    modules.find(({ name }) => {
      // Separar el pathname y ver solo la primera parte de la url que sea exactemente igual al nombre de los módulos definidos arriba
      const pathParts = pathname.split("/");
      return pathParts[1] === name;
    })
  ],
  ({ pathname }) => pathname
);

const getDifferenceInSeconds = tiempoSesion =>
  tiempoSesion ? differenceInSeconds(new Date(), tiempoSesion) : 0;

export const withOnSaveLog = compose(
  setDisplayName("KibanaTracker"),
  defaultProps({
    track: false
  }),
  withHandlers({
    saveLog: () => ({ url, modulo }) => {
      const user = AuthService.getIdUsuario();
      const tiempoSesion = AuthService.getLoginTime();
      fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          url,
          modulo,
          user,
          tiempoSesion: getDifferenceInSeconds(tiempoSesion)
        })
      });
    }
  }),
  withHandlers({
    shouldLog: ({ track, saveLog, location, permisos }) => ({
      modulo,
      ...rest
    }) => {
      const search = get(location, "search", "").replace("?", "");
      const isAllowed =
        permisos && permisos.length !== 0
          ? AuthService.hasPerms(permisos)
          : true;
      if (track && isAllowed)
        if (modulo === "Información" || modulo === "Privado") {
          if (search)
            saveLog({
              modulo: modulo.concat(`: ${startCase(search)}`),
              ...rest
            });
        } else saveLog({ modulo, ...rest });
    }
  })
);

const enhance = compose(
  withOnSaveLog,
  lifecycle({
    // Current Page
    componentDidMount() {
      const { shouldLog, location } = this.props;
      location && shouldLog(getUrlAndModule(location));
    },
    // Next Page
    componentWillReceiveProps(nextProps) {
      const { shouldLog, location } = nextProps;
      location && shouldLog(getUrlAndModule(location));
    }
  })
);

export default enhance;
