import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { reduxForm, change, getFormMeta, formValueSelector } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ErrorList from "js/components/Shared/ErrorList";
import { TextField } from "@material-ui/core";
import { renderFechaWithFormat } from "js/utils/Helper";
import {
  ListField,
  CheckboxField,
  InputTextField,
  RolField,
  EmailField,
  InputPasswordField,
  ProgramaField,
  SegmentoField
} from "js/components/Shared/Form/Fields";
import get from "lodash/get";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class Form extends PureComponent {
  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      classes,
      history,
      disabled,
      errors,
      idPrograma,
      idUsuario,
      asignarPassword,
      initialValues,
      changeFieldValue,
      showEditPerfil
    } = this.props;

    const urls = {
      full: "",
      short: ""
    };

    if (window.location.pathname.indexOf("admin") !== -1) {
      urls.full = window.location.href.match(/(\/admin.*usuario)/)[0];
      urls.short = "/admin/usuario/";
    } else {
      urls.full = "/usuarios";
      urls.short = "/usuarios/";
    }

    return (
      <Fragment>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Typography variant="h5" style={{ flex: 1 }}>
              {disabled
                ? "Ver "
                : idUsuario === "new"
                  ? "Nuevo "
                  : "Modificar "}
              Usuario
            </Typography>
          </Grid>
        </Grid>
        <form className={classes.container} onSubmit={handleSubmit}>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Datos principales</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputTextField
                required
                name="username"
                label="Usuario"
                autoComplete="off"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <EmailField
                name="email"
                label="Email del usuario"
                disabled={disabled}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ListField
                name="estado"
                label="Estado"
                options={[
                  { label: "Activo", value: "ACTIVO" },
                  { label: "Inactivo", value: "INACTIVO" }
                ]}
                disabled={disabled || idUsuario === "new"}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ProgramaField
                disabled={disabled || idUsuario !== "new"}
                required
                redux
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SegmentoField
                redux
                required
                disabled={disabled}
                idPrograma={parseInt(idPrograma, 10)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <RolField
                disabled={disabled}
                required
                idPrograma={parseInt(idPrograma, 10)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CheckboxField
                name="asignarPassword"
                label="Asignar clave"
                disabled={disabled}
                autoComplete="new-password"
                onChange={e => {
                  if (asignarPassword) changeFieldValue("password", "");
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputPasswordField
                name="password"
                label="Clave"
                disabled={disabled || !asignarPassword}
                required={!!asignarPassword}
                withPasswordValidation={!!asignarPassword}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ListField
                name="validezPassword"
                label="Validez clave"
                options={[
                  { label: "Temporal", value: "TEMPORAL" },
                  { label: "Permanente", value: "PERMANENTE" }
                ]}
                disabled={disabled || !asignarPassword}
                required={asignarPassword}
              />
            </Grid>
            {idUsuario !== "new" && (
              <Grid container item xs={12} spacing={16}>
                <Grid item xs={12} md={4}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Fecha de alta"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={
                      renderFechaWithFormat(
                        "DD/MM/YYYY HH:mm:ss",
                        initialValues.fechaDeAlta
                      ) || ""
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Usuario creador"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={get(initialValues, "usuarioCreador.username", "--")}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Último login"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={
                      renderFechaWithFormat(
                        "DD/MM/YYYY HH:mm:ss",
                        initialValues.ultimoLogin
                      ) || ""
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Última modificación"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={
                      renderFechaWithFormat(
                        "DD/MM/YYYY HH:mm:ss",
                        initialValues.ultimaModificacion
                      ) || ""
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Usuario modificador"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={get(
                      initialValues,
                      "usuarioModificador.username",
                      "--"
                    )}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Fecha de inactivación"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={
                      renderFechaWithFormat(
                        "DD/MM/YYYY HH:mm:ss",
                        initialValues.fechaDeBaja
                      ) || ""
                    }
                    disabled
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <ErrorList errors={errors} />
          <Grid className={classes.grid} container>
            <Grid item xs />
            <Grid item xs />
            <Grid className={classes.gridButton} item xs>
              <Button
                type="button"
                variant="outlined"
                size="large"
                color="primary"
                className={classes.button}
                onClick={e => history.push(urls.full)}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid className={classes.gridButton} item xs>
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                onClick={e =>
                  history.push(urls.full + `/${idUsuario}/edit/perfil`)
                }
              >
                {idUsuario !== "new" &&
                AuthService.hasPerms([Roles.USUARIO_W]) &&
                showEditPerfil
                  ? "Editar perfil"
                  : "Ver perfil"}
              </Button>
            </Grid>
            {AuthService.hasPerms([Roles.USUARIO_W]) && (
              <Grid className={classes.gridButton} item xs>
                {disabled && (
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    onClick={() =>
                      history.push(urls.short + idUsuario + "/edit")
                    }
                  >
                    Editar
                  </Button>
                )}
                {!disabled && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    disabled={pristine || submitting}
                  >
                    Guardar
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      </Fragment>
    );
  }
}

Form = reduxForm({
  form: "formUsuario",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formUsuario");

Form = connect(
  state => ({
    formMeta: getFormMeta("formProveedores")(state),
    asignarPassword: selector(state, "asignarPassword"),
    idPrograma: selector(state, "idPrograma")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formUsuario", field, value))
  })
)(Form);

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: theme.container.maxWidth,
    margin: theme.container.margin
  },
  field: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  grid: {
    margin: "0"
  },
  gridButton: {
    marginTop: "20px",
    textAlign: "end",
    marginLeft: 20
  },
  button: {
    width: "200px"
  }
});

export default withRouter(withStyles(styles)(Form));
