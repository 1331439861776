import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import programa from "./programa";
import template from "./template";
import producto from "js/pages/ProductoRedux/reducer.js";
import campanas from "./campana/campana.reducer";
import user from "./user/user.reducer";
import timer from "./timer/timer.reducer";
import beneficio from "js/pages/Beneficio/reducer.js";

export default combineReducers({
  form,
  programa,
  template,
  producto,
  campanas,
  user,
  beneficio,
  timer
});
