import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaVentaProductoCodificado/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/ventaProductoCodificado.resolver";
import { USER_ROLES as Roles } from "js/models/User";
import { viewAction } from "js/components/Shared/Tabla/ActionCells";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Store from "@material-ui/icons/Store";
import {
  columnas,
  misColumnas
} from "js/components/TablaVentaProductoCodificado/columns";

const defaultHeader = (id, btnsNextTitle, isMisVentas) => {
  return {
    title: "Productos escaneados",
    btnsNextTitle,
    new: {
      title: "Escanea AQUÍ",
      perms: [Roles.VENTA_PRODUCTO_CODIFICADO_W]
    },
    export: {
      path: isMisVentas
        ? "ventasProductosCodificados/misVentas"
        : "ventasProductosCodificados",
      tooltip: "Exportar registros",
      perms: [Roles.VENTA_PRODUCTO_CODIFICADO_EXPORT]
    },
    queryStringMatcher: {
      idsPrograma: undefined
    }
  };
};

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  render() {
    const view = params => ({
      ...viewAction({ ...params, history: this.props.history })
    });

    const {
      match: {
        params: { id }
      }
    } = this.props;
    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;
    const isMisVentas = window.location.pathname.indexOf("mis") !== -1;
    let columnData = null;

    if (isMisVentas) {
      columnData = misColumnas;
    } else {
      columnData = columnas;
    }

    const btnsNextTitle = [];
    if (inBackOffice) {
      btnsNextTitle.push({
        btn: (
          <Tooltip title="Productos">
            <IconButton
              color="primary"
              onClick={() =>
                this.props.history.push(
                  `/admin/programa/${id}/productos-codificados`
                )
              }
            >
              <Store />
            </IconButton>
          </Tooltip>
        ),
        perms: [Roles.PRODUCTO_CODIFICADO_R]
      });
    }

    return (
      <Table
        storage={"FiltroVentaProductoCodificado"}
        query={
          isMisVentas
            ? AppQraphQL.queries.getMisVentasProductosCodificados
            : AppQraphQL.queries.getVentasProductosCodificados
        }
        defaultHeader={defaultHeader(id, btnsNextTitle, isMisVentas)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data =>
          isMisVentas
            ? data.getMisVentasProductosCodificados
            : data.getVentasProductosCodificados
        }
        actionsCustom={[view]}
        pathId={"_id"}
      />
    );
  }
}

export default Tabla;
