import React from "react";
import { Field } from "redux-form";
import FormControl from "@material-ui/core/FormControl";
import TypeAhead from "./";

class ReduxAutocomplete extends React.PureComponent {
  render() {
    const { name, className, validate, multiple, ...props } = this.props;

    return (
      <FormControl className={className} error style={{ width: "100%" }}>
        <Field
          name={name}
          className={className}
          validate={validate}
          component={({ input, meta: { touched, error, warning } }) => (
            <TypeAhead
              onChange={select =>
                input.onChange(
                  !multiple ? select.value : select.map(option => option.value)
                )
              }
              value={input.value}
              error={touched ? error : null}
              {...props}
            />
          )}
        />
      </FormControl>
    );
  }
}

export default ReduxAutocomplete;
