import React from "react";
import { numberWithCommas } from "js/utils";

const Monto = props => {
  const { value, monedaBase = "", tipoOperacion, classes, style } = props;
  if (!value) return "--";
  return (
    <div style={style}>
      {tipoOperacion && tipoOperacion === "DEBITO" ? (
        <span className={classes.negative}>
          (
          {(monedaBase ? monedaBase + " " : "") +
            numberWithCommas(Math.abs(value))}
          )
        </span>
      ) : (
        (monedaBase ? monedaBase + " " : "") + numberWithCommas(value)
      )}
    </div>
  );
};

export default Monto;
