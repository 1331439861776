import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  reduxForm,
  formValueSelector,
  change,
  getFormMeta,
  FieldArray
} from "redux-form";
import {
  Grid,
  FormControl,
  Button,
  Paper,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { styles } from "js/components/Shared/Form/styles.js";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import {
  InputTextField,
  InputNumberGtZeroField,
  TypeAhead
} from "js/components/Shared/Form/Fields";
import { maxValue } from "js/helpers/validations";

const renderObjectives = ({ fields, targetsObjetivos, disabled, notNew }) => {
  if (fields.length === 0) {
    targetsObjetivos.forEach(item => {
      fields.push({
        idObjetivo: item.idObjetivo,
        objetivo: item.objetivo,
        target: null
      });
    });
  }
  return (
    <Grid item container xs={12} spacing={16}>
      {fields.map((item, index) => {
        return (
          <Fragment key={index}>
            <Grid xs={12} item container>
              <Paper style={{ width: "100%", padding: "15px" }}>
                <Grid xs={12} item container spacing={8}>
                  <Grid item xs={12} sm={5}>
                    <InputTextField
                      name={`${item}.objetivo`}
                      label={"Objetivo"}
                      disabled={true}
                    />
                  </Grid>
                  {notNew && (
                    <Grid item xs={12} sm={2}>
                      <InputNumberGtZeroField
                        name={`${item}.targetOriginal`}
                        label={"Target original"}
                        disabled={true}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={2}>
                    <InputNumberGtZeroField
                      name={`${item}.target`}
                      label={"Target"}
                      // disabled={disabled}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <InputTextField
                      name={`${item}.idObjetivo`}
                      disabled={true}
                      style={{ display: "none" }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
};

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fechaNotChanged: true,
      fechaLimiteEdd: null,
      fechaLimiteSupervisores: null
    };
  }

  handleFechaLimiteEddChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaNotChanged: false,
      fechaLimiteEdd: date
    });
  };

  handleFechaLimiteSupervisoresChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaNotChanged: false,
      fechaLimiteSupervisores: date
    });
  };

  handleCampanaChange = ({ value }) => {
    this.props.change("idCampana", value);
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      id,
      initialValues,
      med,
      campanas,
      idCampana,
      isMisMeds,
      inBackOffice,
      isOldAjusteEdd
    } = this.props;

    let targetsObjetivos = null;
    if (initialValues.targetsObjetivos) {
      // Para modificar
      targetsObjetivos = initialValues.targetsObjetivos;
    } else if (idCampana) {
      // Para nuevas variables
      const campana = campanas.find(c => String(c._id) === String(idCampana));
      targetsObjetivos = [];
      campana.objetivos.forEach(objetivo => {
        targetsObjetivos.push({
          idObjetivo: objetivo._id,
          objetivo: objetivo.objetivo,
          target: null
        });
      });
    }

    return (
      <form className={classes.container} onSubmit={handleSubmit}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {`MED: ${med ? med.nombre : ""} | Variables por campaña`}
            </Typography>
          </Grid>

          {errors &&
            errors.length !== 0 && (
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors.map(e => (
                    <li>{e.message}</li>
                  ))}
                </ul>
              </Grid>
            )}

          {campanas && (
            <Grid item xs={12} sm={3}>
              <TypeAhead
                name="idCampana"
                suggestions={{
                  campanas: campanas.map(campana => ({
                    value: campana._id,
                    label: campana.nombre
                  }))
                }}
                path={{ path: "campanas", value: "value", label: "label" }}
                onChange={this.handleCampanaChange}
                placeholder={"Campaña"}
                value={idCampana || initialValues.idCampana}
                label={"Campaña"}
                classes={classes}
                multiple={false}
                labelOrder={false}
                disabled={disabled || initialValues.idCampana}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={3}>
            <FormControl className={classes.field}>
              <DatePicker
                name={"fechaLimiteEdd"}
                dateFormat="DD/MM/YYYY"
                label={"Fecha límite para EDDs"}
                autoOk
                disabled={disabled || isMisMeds}
                input={{
                  value:
                    this.state.fechaLimiteEdd !== "" &&
                    this.state.fechaLimiteEdd
                      ? new Date(this.state.fechaLimiteEdd)
                      : initialValues.fechaLimiteEdd
                        ? new Date(initialValues.fechaLimiteEdd)
                        : null,
                  onChange: this.handleFechaLimiteEddChange("fechaLimiteEdd")
                }}
                minDate={new Date()}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl className={classes.field}>
              <DatePicker
                name={"fechaLimiteSupervisores"}
                dateFormat="DD/MM/YYYY"
                label={"Fecha límite para supervisores"}
                autoOk
                disabled={disabled || isMisMeds}
                input={{
                  value:
                    this.state.fechaLimiteSupervisores !== "" &&
                    this.state.fechaLimiteSupervisores
                      ? new Date(this.state.fechaLimiteSupervisores)
                      : initialValues.fechaLimiteSupervisores
                        ? new Date(initialValues.fechaLimiteSupervisores)
                        : null,
                  onChange: this.handleFechaLimiteSupervisoresChange(
                    "fechaLimiteSupervisores"
                  )
                }}
                minDate={
                  this.state.fechaLimiteEdd
                    ? moment(this.state.fechaLimiteEdd).add(1, "d")
                    : initialValues.fechaLimiteEdd
                      ? moment(initialValues.fechaLimiteEdd).add(1, "d")
                      : new Date()
                }
                required
              />
            </FormControl>
          </Grid>

          {isOldAjusteEdd ? (
            <Grid item xs={12} sm={3}>
              <FormControl className={classes.field}>
                <InputNumberGtZeroField
                  name="ajusteEdd"
                  label={"Rango de ajuste (porcentaje)"}
                  disabled={disabled || isMisMeds}
                  customValidations={[maxValue(100)]}
                  required
                />
              </FormControl>
            </Grid>
          ) : (
            <Fragment>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.field}>
                  <InputNumberGtZeroField
                    name="ajusteEddMinMax.min"
                    label={"Bajar hasta %"}
                    disabled={disabled || isMisMeds}
                    customValidations={[maxValue(100)]}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.field}>
                  <InputNumberGtZeroField
                    name="ajusteEddMinMax.max"
                    label={"Subir hasta %"}
                    disabled={disabled || isMisMeds}
                    customValidations={[maxValue(100)]}
                    required
                  />
                </FormControl>
              </Grid>
            </Fragment>
          )}

          {targetsObjetivos && (
            <Grid item xs={12}>
              <FieldArray
                name="fields"
                component={renderObjectives}
                targetsObjetivos={targetsObjetivos}
                disabled={disabled}
                notNew={initialValues.idCampana ? true : false}
              />
            </Grid>
          )}

          <Grid className={classes.gridButton} item xs={12}>
            <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              style={{ marginRight: 20 }}
              onClick={() =>
                inBackOffice
                  ? history.push(
                      `/admin/programa/${id}/meds/${med.codigo}/variables`
                    )
                  : isMisMeds
                    ? history.push(`/mis-meds/${med.codigo}/variables`)
                    : history.push(`/meds/${med.codigo}/variables`)
              }
            >
              {"Cancelar"}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              disabled={
                disabled ||
                (pristine && this.state.fechaNotChanged) ||
                submitting
              }
            >
              {"Guardar"}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

Form = reduxForm({
  form: "formVariablesMed",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formVariablesMed");

Form = connect(
  state => ({
    formMeta: getFormMeta("formVariablesMed")(state),
    idCampana: selector(state, "idCampana")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formVariablesMed", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
