/*
 * @flow
 */

import React from "react";
import Grid from "@material-ui/core/Grid";

import { PaisField, ZonaField, LocalidadField, InputTextField } from "../index";
import Enhancer from "./container";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";

export const Ubicacion = ({
  formMeta,
  changeFieldValue,
  breakpoints,
  extraProps,
  label,
  classes,
  values
}) => {
  const { pais, zona, localidad, direccion, codPostal } = extraProps;
  const idPais = get(values, pais.name);
  const idZona = get(values, zona.name);
  return (
    <Grid container spacing={24}>
      {label && (
        <Grid item xs={12} className={classes.title}>
          <Typography variant="subtitle2" gutterBottom>
            {label}:
          </Typography>
        </Grid>
      )}
      {pais.show && (
        <Grid item {...breakpoints} className={classes.location}>
          <PaisField
            formMeta={formMeta}
            changeFieldValue={changeFieldValue}
            required={pais.required}
            disabled={pais.disabled}
            classes={pais.classes}
            name={pais.name}
            isUbicacion={true}
          />
        </Grid>
      )}
      {zona.show && (
        <Grid item {...breakpoints} className={classes.location}>
          <ZonaField
            idPais={idPais}
            formMeta={formMeta}
            changeFieldValue={changeFieldValue}
            required={zona.required}
            disabled={pais.disabled}
            classes={zona.classes}
            name={zona.name}
            showPais={pais.show}
            isUbicacion={true}
          />
        </Grid>
      )}
      {localidad.show && (
        <Grid item {...breakpoints} className={classes.location}>
          <LocalidadField
            idPais={idPais}
            idZona={idZona}
            required={localidad.required}
            disabled={pais.disabled}
            classes={localidad.classes}
            name={localidad.name}
            isUbicacion={true}
          />
        </Grid>
      )}
      {direccion.show && (
        <Grid item {...breakpoints} className={classes.location}>
          <InputTextField
            name={direccion.name}
            label="Dirección"
            autoComplete="off"
            required={direccion.required}
          />
        </Grid>
      )}
      {codPostal.show && (
        <Grid item {...breakpoints} className={classes.location}>
          <InputTextField
            name={codPostal.name}
            label="Código postal"
            autoComplete="off"
            required={codPostal.required}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Enhancer(Ubicacion);
