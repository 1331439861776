import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from "@material-ui/core";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { _t } from "js/utils/TranslationService";

class advancedFilter extends PureComponent {
  render() {
    const { value, submitValue, classes } = this.props;
    const isAdminUser = AuthService.hasPerms([Roles.MODERACION_W]);
    return (
      <Grid container spacing={16}>
        {isAdminUser ? (
          <>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  key={"usuario"}
                  name={"usuario"}
                  label={_t("Usuario")}
                  type="search"
                  value={value["usuario"] || ""}
                  onChange={e => submitValue(e)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  key={"usuarioModerador"}
                  name={"usuarioModerador"}
                  label={_t("Moderador")}
                  type="search"
                  value={value["usuarioModerador"] || ""}
                  onChange={e => submitValue(e)}
                />
              </FormControl>
            </Grid>
          </>
        ) : (
          ""
        )}
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"puntoVenta"}
              name={"puntoVenta"}
              label={_t("Punto de venta")}
              type="search"
              value={value["puntoVenta"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"RECHAZADA"}>Rechazada</MenuItem>
              <MenuItem value={"APROBADA"}>Aprobada</MenuItem>
              <MenuItem value={"PENDIENTE_APROBACION"}>
                Pendiente de aprobación
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionDesde"}
              name={"fechaCreacionDesde"}
              label={
                isAdminUser ? "Fecha creación (Desde)" : "Fecha envío (Desde)"
              }
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionDesde",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionDesde"]
                  ? moment(value["fechaCreacionDesde"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionHasta"}
              name={"fechaCreacionHasta"}
              label={
                isAdminUser ? "Fecha creación (Hasta)" : "Fecha envío (Hasta)"
              }
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionHasta",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionHasta"]
                  ? moment(value["fechaCreacionHasta"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaFacturaDesde"}
              name={"fechaFacturaDesde"}
              label="Fecha factura (Desde)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaFacturaDesde",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaFacturaDesde"]
                  ? moment(value["fechaFacturaDesde"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaFacturaHasta"}
              name={"fechaFacturaHasta"}
              label="Fecha factura (Hasta)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaFacturaHasta",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaFacturaHasta"]
                  ? moment(value["fechaFacturaHasta"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
