import { connect } from "react-redux";
import UserMenu from "./UserMenu";
import { setLogInStatus } from "js/redux/programa";

const mapDispatchToProps = (dispatch, ownProps) => ({
  setLogInStatus: setLogInStatus(dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(UserMenu);
