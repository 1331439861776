import React, { Fragment } from "react";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Autocomplete } from "js/components/Shared/Form/Fields";
import { Query } from "react-apollo";
import { has, get } from "lodash";
import Grid from "@material-ui/core/Grid";
import client from "js/App/client.graphql.js";
import FacturaQraphQL from "js/graphql/resolvers/facturas.resolver";

import Button from "@material-ui/core/Button";

import Slide from "@material-ui/core/Slide";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogFactura extends React.Component {
  state = {
    idFactura: undefined
  };

  getAutocompleteValue = (fn, data, stateValue) =>
    stateValue !== undefined
      ? fn(data).find(p => p.value === stateValue)
      : { value: undefined, label: "" };

  getFacturasSuggestions = data =>
    has(data, ["getFacturas", "itemsPagina"])
      ? data.getFacturas.itemsPagina.map(factura => ({
          value: factura.idFactura,
          label: factura.numeroFactura
        }))
      : [];

  updateAutocompleteQueryParams = select => {
    this.setState(prevState => ({
      idFactura: select.value
    }));
  };

  // TODO : implementar la mutation
  sendValues = async () => {
    const filters = this.props.filters;
    const { idFactura } = this.state;
    const canjeLike = {
      idCanje: get(filters, "idCanje", undefined),
      estado: get(filters, "estadoCanje", undefined),
      numeroLote: get(filters, "idLote", undefined),
      idUsuario: get(filters, "idUsuario", undefined),
      excluirLoteados: get(filters, "excluirCanje", undefined),
      excluirFacturados: get(filters, "excluirFactura", undefined),
      idPais: get(filters, "idPais", undefined),
      idProveedor: get(filters, "idProveedor", undefined),
      idProducto: get(filters, "idProducto", undefined),
      eleccionProducto_producto_gestionadoPor: get(
        filters,
        "administracion*",
        undefined
      ),
      eleccionProducto_producto_tipoProducto: get(
        filters,
        "tipoProducto",
        undefined
      )
    };
    const canjeRange = {
      fecha: {
        min: get(filters, "fechaDesde", undefined),
        max: get(filters, "fechaHasta", undefined)
      }
    };

    const { data } = await client.mutate({
      mutation: FacturaQraphQL.mutations.asignarFactura,
      errorPolicy: "all",
      variables: {
        idFactura,
        canjeRange,
        canjeLike
      }
    });

    if (data) {
      const message =
        "Se han asignado los canjes filtrados a la factura seleccionada";
      this.props.openNotification(message);
      this.props.handleClose();
    }
  };

  handleChangeFecha = date => {
    this.setState({ fecha: date });
  };

  handleChange = key => event => {
    this.setState({ [key]: event.target.value });
  };
  render() {
    const { open, handleClose, classes } = this.props;
    return (
      <Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={classes.dialogTitle}
          >
            Asignar a factura
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Query query={FacturaQraphQL.queries.getFacturas} errorPolicy="all">
              {({ loading, error, data }) => {
                return (
                  <FormControl>
                    <Grid
                      container
                      cspacing={24}
                      className={classes.facturaForm}
                    >
                      <Grid item xs={12}>
                        <Autocomplete
                          isDisabled={false}
                          onChange={this.updateAutocompleteQueryParams}
                          noOptionsMessage={() => "No hay facturas"}
                          placeholder="Buscar por factura"
                          suggestions={this.getFacturasSuggestions(data)}
                          value={this.getAutocompleteValue(
                            this.getFacturasSuggestions,
                            data,
                            get(this.state, "idFactura")
                          )}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                );
              }}
            </Query>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Descartar
            </Button>

            <Button
              disabled={!this.state.idFactura}
              onClick={this.sendValues}
              variant="contained"
              color="primary"
            >
              Asignar
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DialogFactura.defaultProps = {};

DialogFactura.propTypes = {};

export default DialogFactura;
