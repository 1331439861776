import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";

class advancedFilter extends PureComponent {
  handleChange = e => {
    if (e.target && e.target.name === "idPuntoDeVenta") {
      let newEvent = {
        target: {
          name: e.target.name,
          value: e.target.value === "" ? null : parseInt(e.target.value, 10)
        }
      };
      this.props.submitValue(newEvent);
    } else {
      this.props.submitValue(e);
    }
  };

  render() {
    const { value, submitValue, search, classes } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <TextField
              label="Id Punto de venta"
              placeholder="Id Punto de venta"
              key={"idPuntoDeVenta"}
              name={"idPuntoDeVenta"}
              type="search"
              value={value["idPuntoDeVenta"] || null}
              className={classes.formControl}
              onChange={e => this.handleChange(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <TextField
              key="nombre"
              name="nombre"
              label="Nombre"
              type="search"
              value={value["nombre"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              id="estado-simple-select"
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"ACTIVO"}>Activo</MenuItem>
              <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withRouter(withStyles(styles)(advancedFilter));
