import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import withPrograma from "js/hoc/withPrograma";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import get from "lodash/get";
import MenuPrograma from "./menuPrograma.js";
import MainMenu from "./mainMenu.js";
import styles from "./styles";
import DesktopDrawer from "./DesktopDrawer";
import UserMenu from "./UserMenu";
import { getMenuPrograma } from "./utils";

class ResponsiveDrawer extends React.Component {
  state = {
    idPrograma: null,
    nombrePrograma: null,
    linksMenuPrograma: [],
    mobileOpen: false,
    activeMenu: "main",
    open: true
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleDesktopToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleChangeMenu = value => {
    this.setState({ activeMenu: value });
  };

  componentDidMount() {
    this.setMenu(this.props);
  }

  componentWillReceiveProps = props => {
    this.setMenu(props);
  };

  async setMenu(props) {
    const {
      programa: { idPrograma, nombre },
      match: {
        params: { id },
        path
      },
      client
    } = props;
    if (
      path.match(/^\/admin\/programa\/:id*/) === null ||
      this.props.location.pathname === "/admin/programa/new" ||
      this.props.history.location.pathname === "/admin/programa/new"
    ) {
      this.setState({
        activeMenu: "main",
        nombrePrograma: "Liza"
      });
    } else {
      this.setState({
        activeMenu: "programa",
        idPrograma: parseInt(id) || id,
        nombrePrograma: parseInt(id) === parseInt(idPrograma) && nombre
      });
      await client
        .query({
          query: AppQraphQL.queries.getProgramaById,
          fetchPolicy: "cache-first",
          variables: {
            idPrograma: parseInt(id) || id
          }
        })
        .then(res => {
          const modulos = get(
            res,
            "data.getProgramas.itemsPagina[0].modulos",
            []
          );
          const links = getMenuPrograma(modulos);
          this.setState({
            nombrePrograma: get(
              res,
              "data.getProgramas.itemsPagina[0].nombre",
              parseInt(id) === parseInt(idPrograma) && nombre
            ),
            linksMenuPrograma: links
          });
        });
    }
  }

  render() {
    const { classes, theme, BaseComponent } = this.props;
    const {
      activeMenu,
      idPrograma,
      nombrePrograma,
      linksMenuPrograma
    } = this.state;
    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open
          })}
        >
          <Hidden smDown>
            <Toolbar
              disableGutters={!this.state.open}
              className={classes.toolbar}
            >
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDesktopToggle}
                className={classNames(classes.menuButton, {
                  [classes.hide]: this.state.open
                })}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                className={classes.nombrePrograma}
              >
                {nombrePrograma}
              </Typography>
              <UserMenu />
            </Toolbar>
          </Hidden>
          <Hidden mdUp>
            <Toolbar className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                className={classes.nombrePrograma}
              >
                {nombrePrograma}
              </Typography>
              <UserMenu />
            </Toolbar>
          </Hidden>
        </AppBar>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            {activeMenu === "main" ? (
              <MainMenu closeMenu={this.handleDrawerToggle} />
            ) : (
              <MenuPrograma
                idPrograma={idPrograma}
                nombrePrograma={nombrePrograma}
                linksMenuPrograma={linksMenuPrograma}
                handleChangeMenu={value =>
                  this.handleChangeMenu.bind(this, value)
                }
              />
            )}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <DesktopDrawer
            idPrograma={idPrograma}
            nombrePrograma={nombrePrograma}
            handleChangeMenu={value => this.handleChangeMenu.bind(this, value)}
            activeMenu={activeMenu}
            handleDrawerToggle={this.handleDesktopToggle}
            linksMenuPrograma={linksMenuPrograma}
            open={this.state.open}
          />
        </Hidden>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <BaseComponent />
        </main>
      </div>
    );
  }
}

ResponsiveDrawer.propTypes = {
  BaseComponent: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withRouter(
  withApollo(
    withStyles(styles, { withTheme: true })(withPrograma(ResponsiveDrawer))
  )
);
