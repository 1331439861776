import React from "react";
import Tabla from "./TablaSesionesTracking";

function TrackingSesiones(props) {
  return (
    <>
      <Tabla {...props} />
      <p> Debe aplicar un filtro para obtener datos y exportar.</p>
    </>
  );
}

export default TrackingSesiones;
