import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "./advancedFilter";
import EmailGraphQL from "js/graphql/resolvers/email.resolver";
import {
  editAction,
  resendEmailAction,
  viewAction
} from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";

const defaultHeader = {
  title: "Emails"
};
const columnData = [
  { id: "fecha", label: "Fecha", type: "date" },
  { id: "from", label: "De" },
  { id: "to", label: "Para", type: "emails", removeSort: true },
  { id: "cc", label: "CC", type: "emails", removeSort: true },
  { id: "cco", label: "CCO", type: "emails", removeSort: true },
  { id: "subject", label: "Asunto" },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fecha" };

class Tabla extends PureComponent {
  render() {
    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.HISTORIAL_EMAIL_W]
      })
    });

    const view = params => ({
      ...viewAction({
        ...params,
        history: this.props.history,
        perms: [Roles.HISTORIAL_EMAIL_R]
      })
    });

    const resend = params => ({
      ...resendEmailAction({
        ...params,
        history: this.props.history,
        perms: [Roles.HISTORIAL_EMAIL_W],
        openNotification: this.props.openNotification
      })
    });

    return (
      <Table
        storage="FiltroEmails"
        query={EmailGraphQL.queries.getEmails}
        defaultHeader={defaultHeader}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getHistorialEmail}
        actionsCustom={[view, edit, resend]}
      />
    );
  }
}

export default Tabla;
