import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Domain } from "mdi-material-ui";
import { get } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import InfoComercialWithoutForm from "./InfoComercialWithoutForm";
import InfoComercialWithForm from "./InfoComercialWithForm";
import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import capitalize from "lodash/capitalize";

const styles = theme => ({
  list: {
    paddingLeft: theme.spacing.unit
  }
});

class InfoComercial extends Component {
  render() {
    const {
      classes,
      canje,
      costoReal,
      initialValues,
      disabled,
      changeValue,
      inBackOffice
    } = this.props;
    const administradoPor = get(
      canje,
      "eleccionProducto.producto.gestionadoPor",
      null
    );
    return (
      <Grid container item xs={12}>
        {inBackOffice && (
          <>
            <Typography variant="h6" gutterBottom style={{ width: "100%" }}>
              Info Comercial
            </Typography>

            <ListItem className={classes.list}>
              <ListItemIcon>
                <Domain color="primary" />
              </ListItemIcon>
              <ListItemText
                className={classes.list}
                primary={get(canje, "usuario.programa.nombre", "")}
                secondary={
                  administradoPor
                    ? "Administrado por " + capitalize(administradoPor)
                    : ""
                }
              />
            </ListItem>
            <ListItem className={classes.list}>
              <ListItemIcon>
                <Icon>compare_arrows</Icon>
              </ListItemIcon>
              <ListItemText
                className={classes.list}
                primary={get(canje, "usuario.programa.tasaConversion", "")}
              />
            </ListItem>
          </>
        )}
        <InfoComercialWithoutForm canje={canje} />
        <Divider />
        {inBackOffice && (
          <Grid container item xs={12}>
            <InfoComercialWithForm
              canje={canje}
              costoReal={costoReal}
              initialValues={initialValues}
              disabled={disabled}
              changeValue={changeValue}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(InfoComercial);
