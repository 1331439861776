import gql from "graphql-tag";

export const Med = {};

Med.fragments = {
  med: gql`
    fragment med on Med {
      _id
      nombre
      descripcion
      codigo
      tipo
      estado
      fechaCreacion
      usuarioCreador {
        username
      }
      cambios {
        accion
        fecha
        usuario {
          username
        }
      }
    }
  `
};

export default Med;
