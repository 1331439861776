import React, { PureComponent, Fragment } from "react";
import MedGraphQL from "js/graphql/resolvers/medUnilever.resolver";
import Form from "js/pages/Unilever/Med/Edit/MyForm.js";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { removeTypename } from "js/utils/Helper";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    const params = {
      id: null,
      codigo: null,
      edit: null
    };
    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;
    if (inBackOffice) {
      params.id = props.match.params.id;
      params.codigo = props.match.params.codigo;
      params.edit = props.match.params.edit;
    } else {
      params.id = parseInt(AuthService.getIdPrograma());
      params.codigo = props.computedMatch.params.codigo;
      params.edit = props.computedMatch.params.edit;
    }
    this.state = {
      ...params,
      inBackOffice,
      isMisMeds: window.location.pathname.indexOf("mis-meds") !== -1,
      initialValues: {
        estado: "ACTIVO",
        tipo: "CONVENCIONAL"
      },
      disabled: false,
      errors: null
    };
  }

  async componentDidMount() {
    const { client, history } = this.props;
    const { inBackOffice, isMisMeds, codigo, id, edit } = this.state;

    if (codigo !== "new") {
      await client
        .query({
          query: isMisMeds
            ? MedGraphQL.queries.getMiMedById
            : MedGraphQL.queries.getMedById,
          fetchPolicy: "network-only",
          variables: {
            codigo,
            idPrograma: id
          }
        })
        .then(res => {
          const page = isMisMeds
            ? res.data.getMisMeds.itemsPagina[0]
            : res.data.getMeds.itemsPagina[0];
          if (!page) {
            this.props.openNotification(
              `No existe un med con el código ${codigo}`
            );
            if (inBackOffice) {
              history.push(`/admin/programa/${id}/meds`);
            } else if (isMisMeds) {
              history.push(`/mis-meds`);
            } else {
              history.push(`/meds`);
            }
          }
          this.setState({
            initialValues: {
              ...page
            },
            disabled: !(edit === "edit") || !AuthService.hasPerms([Roles.MED_W])
          });
        })
        .catch(errors => {
          const arr = [];
          arr.push({ message: "Error inesperado" });
          this.setState({
            errors:
              errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
          });
          return false;
        });
    }
  }
  async submit(values) {
    const { client, history } = this.props;
    const { codigo, id, inBackOffice, isMisMeds } = this.state;

    const variables = {
      idPrograma: id,
      nombre: values.nombre,
      descripcion: values.descripcion,
      codigo: values.codigo,
      tipo: values.tipo,
      estado: values.estado
    };

    await client
      .mutate({
        mutation:
          codigo === "new"
            ? MedGraphQL.mutations.createMed
            : MedGraphQL.mutations.updateMed,
        variables: {
          med: removeTypename(variables)
        }
      })
      .then(res => {
        const mensaje =
          "MED " +
          (codigo === "new" ? "creado" : "modificado") +
          " correctamente";
        this.props.openNotification(mensaje);
        if (inBackOffice) {
          history.push(`/admin/programa/${id}/meds`);
        } else if (isMisMeds) {
          history.push(`/mis-meds`);
        } else {
          history.push(`/meds`);
        }
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  render() {
    const { codigo, id } = this.state;

    return (
      <Fragment>
        <Form
          id={id}
          codigo={codigo}
          errors={this.state.errors}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
        />
      </Fragment>
    );
  }
}

export default Edit;
