import newBase from "./newBase";
import newSlider from "./newBase";

export default {
  tipo: "fila",
  contenidos: [{ ...newBase }, { ...newSlider }],
  css: {
    margin: "",
    padding: ""
  },
  spaceBetween: false,
  id: 1,
  filaTipo: 2,
  visible: true
};
