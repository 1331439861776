import React from "react";
import PropTypes from "prop-types";
import ViewList from "@material-ui/icons/ViewList";
import ViewModule from "@material-ui/icons/ViewModule";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const ToggleView = props => {
  const { classes, changeView, view } = props;
  return (
    <div className={classes.toggleContainer}>
      <ToggleButtonGroup
        value={view}
        selected={true}
        exclusive
        onChange={changeView}
      >
        <ToggleButton value="list">
          <ViewList />
        </ToggleButton>
        <ToggleButton value="grid">
          <ViewModule />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

ToggleView.propTypes = {
  classes: PropTypes.object.isRequired
};

export default ToggleView;
