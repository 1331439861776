import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem
} from "@material-ui/core";

import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";

class PerformanceFilters extends PureComponent {
  render() {
    const { value, submitValue, classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key="nombre"
              name="nombre"
              label="Nombre"
              type="search"
              value={value["nombre"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key="codigo"
              name="codigo"
              label="Código"
              type="search"
              value={value["codigo"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              id="estado-simple-select"
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"ACTIVO"}>Activo</MenuItem>
              <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="tipo-simple" id="tipo-simple">
              Tipo
            </InputLabel>
            <Select
              id="tipo-simple-select"
              value={value["tipo"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "tipo",
                id: "tipo"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"CONVENCIONAL"}>Convencional</MenuItem>
              <MenuItem value={"COMPRE_AHORA"}>Compre Ahora</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionDesde"}
              name={"fechaCreacionDesde"}
              label="Fecha Creación (Desde)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionDesde",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionDesde"]
                  ? moment(value["fechaCreacionDesde"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionHasta"}
              name={"fechaCreacionHasta"}
              label="Fecha Creación (Hasta)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionHasta",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionHasta"]
                  ? moment(value["fechaCreacionHasta"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

PerformanceFilters.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(PerformanceFilters);
