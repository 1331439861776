import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { withRouter } from "react-router-dom";
import { UsuarioField } from "js/components/Shared/Form/Fields";

class advancedFilter extends PureComponent {
  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };
  handleChangeUsuario = key => select => {
    this.props.submitValue({
      target: { name: key, value: select[0] ? select[0].value : undefined }
    });
  };

  render() {
    const { value, classes, idPrograma } = this.props;
    return (
      <Fragment>
        <div className={classes.styleRow}>
          <UsuarioField
            async
            className={classes}
            value={value["idUsuario"]}
            idPrograma={idPrograma}
            onChange={this.handleChangeUsuario("idUsuario")}
            withTodos
          />
        </div>
      </Fragment>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(withRouter(advancedFilter));
