import React from "react";
import PropTypes from "prop-types";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

import Enhancer from "./container";

const PixelPicker = ({ pixels, onChange, classes, disabled = false }) => (
  <FormControl className={classes.FormControl} variant="outlined">
    <OutlinedInput
      value={pixels}
      disabled={disabled}
      type="number"
      labelWidth={0}
      onChange={e => onChange(parseInt(e.target.value, 10))}
      endAdornment={<InputAdornment position="end">px</InputAdornment>}
    />
  </FormControl>
);

PixelPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  pixels: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.object
};
PixelPicker.displayName = "PixelPicker";

export default Enhancer(PixelPicker);
