import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import AppQraphQL from "js/graphql/resolvers/proveedor.resolver";

// Use this --> https://material-ui.com/demos/autocomplete/ react-select
const ProveedorFilter = (classes, value, submitValue) => {
  return (
    <div style={{ display: "flex", flex: 1, flexWrap: "wrap" }}>
      <Query
        query={AppQraphQL.queries.getProveedores}
        fetchPolicy="network-only"
        errorPolicy="all"
      >
        {({ loading, error, data }) => {
          if (loading || !data) return null;
          return (
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="proveedor-simple" id="proveedor-simple">
                Proveedor
              </InputLabel>
              <Select
                value={value["idProveedor"] || ""}
                onChange={e => submitValue(e)}
                inputProps={{
                  name: "idProveedor",
                  id: "idProveedor"
                }}
              >
                <MenuItem value={undefined}>Todos</MenuItem>
                {data.getProveedores.itemsPagina.map((item, index) => (
                  <MenuItem value={parseInt(item.idProveedor, 10)} key={index}>
                    {item.nombreFantasia} ({item.cuit})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }}
      </Query>
    </div>
  );
};

ProveedorFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default ProveedorFilter;
