import { compose, withHandlers, pure, defaultProps } from "recompose";

import withStyles from "./styles";

export default compose(
  withStyles,
  pure,
  defaultProps({
    disableClick: false,
    name: "image",
    accept: "image/jpeg, image/png, image/svg+xml"
  }),
  withHandlers({
    onDrop: ({ onChangeImage, multiple }) => pictures => {
      onChangeImage(pictures[0]);
    }
  })
);
