import React, { PureComponent, Fragment } from "react";
import AppQraphQL from "js/graphql/resolvers/puntoDeVenta.resolver";
import Form from "js/pages/Admin/Programa/PuntoDeVenta/Edit/MyForm.js";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { set } from "lodash";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    const params = {
      id: null,
      idUsuario: null,
      edit: null,
      idPuntoDeVenta: null
    };

    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;
    if (inBackOffice) {
      params.id =
        parseInt(this.props.match.params.id) || this.props.match.params.id;
      params.idUsuario =
        parseInt(this.props.match.params) || this.props.match.params.idUsuario;
      params.idPuntoDeVenta =
        parseInt(this.props.match.params) ||
        this.props.match.params.idPuntoDeVenta;
      params.edit = this.props.match.params.edit;
    } else {
      params.id = parseInt(localStorage.getItem("idPrograma"));
      params.idUsuario =
        parseInt(this.props.computedMatch.params.idUsuario) ||
        this.props.computedMatch.params.idUsuario;
      params.edit = this.props.computedMatch.params.edit;
    }

    this.state = {
      initialValues: {},
      disabled: null,
      errors: null,
      inBackOffice,
      ...params
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { id, idPuntoDeVenta, edit } = this.state;

    if (idPuntoDeVenta !== "new") {
      try {
        let puntoDeVentaFound = await client.query({
          query: AppQraphQL.queries.getPuntoDeVentaById,
          fetchPolicy: "network-only",
          variables: {
            idPrograma: parseInt(id),
            idPuntoDeVenta: parseInt(idPuntoDeVenta) || idPuntoDeVenta,
            order: "asc",
            orderBy: "idPuntoDeVenta",
            page: 1,
            rowsPerPage: 1
          }
        });

        if (
          !puntoDeVentaFound.data &&
          !puntoDeVentaFound.data.getPuntosDeVenta.itemsPagina.length === 0
        ) {
          throw new Error("Punto de venta no encontrado");
        }

        const {
          data: {
            getPuntosDeVenta: { itemsPagina }
          }
        } = puntoDeVentaFound;
        const [puntoDeVenta] = itemsPagina;

        this.setState({
          initialValues: {
            idPrograma: puntoDeVenta.programa.idPrograma,
            idPuntoDeVenta: puntoDeVenta.idPuntoDeVenta,
            nombre: puntoDeVenta.nombre,
            estado: puntoDeVenta.estado,
            multiplicador: puntoDeVenta.multiplicador
          },
          disabled:
            !(edit === "edit") || !AuthService.hasPerms([Roles.PUNTO_VENTA_W])
        });
      } catch (error) {
        this.setState({ errors: error });
        return false;
      }
    } else {
      this.setState({
        initialValues: {
          idPrograma: parseInt(id) || null,
          estado: "ACTIVO"
        }
      });
    }
  }

  componentWillReceiveProps = nextProps => {
    let action = "";
    if (this.state.inBackOffice) {
      const {
        match: {
          params: { edit }
        }
      } = nextProps;
      action = edit;
    } else {
      const {
        computedMatch: {
          params: { edit }
        }
      } = nextProps;
      action = edit;
    }

    this.setState({
      disabled:
        !(action === "edit") || !AuthService.hasPerms([Roles.PUNTO_VENTA_W])
    });
  };

  async submit(values) {
    const { client, history } = this.props;
    const { id, idPuntoDeVenta, inBackOffice } = this.state;

    await client
      .mutate({
        mutation:
          idPuntoDeVenta === "new"
            ? AppQraphQL.mutations.createPuntoDeVenta
            : AppQraphQL.mutations.updatePuntoDeVenta,
        variables: {
          puntoDeVenta:
            idPuntoDeVenta === "new"
              ? {
                  idPrograma: parseInt(values.idPrograma) || values.idPrograma,
                  nombre: values.nombre,
                  estado: values.estado,
                  multiplicador:
                    parseInt(values.multiplicador) || values.multiplicador
                }
              : {
                  idPrograma: parseInt(values.idPrograma) || values.idPrograma,
                  idPuntoDeVenta: parseInt(idPuntoDeVenta) || idPuntoDeVenta,
                  nombre: values.nombre,
                  estado: values.estado,
                  multiplicador:
                    parseInt(values.multiplicador) || values.multiplicador
                }
        }
      })
      .then(res => {
        const message = "Punto de venta grabado correctamente";
        this.props.openNotification(message);
        const idPuntoDeVentaUpdate =
          res.data.updatePuntoDeVenta &&
          res.data.updatePuntoDeVenta.idPuntoDeVenta;
        const idPuntoDeVentaCreate =
          res.data.createPuntoDeVenta &&
          res.data.createPuntoDeVenta.idPuntoDeVenta;
        const idPuntoDeVenta = idPuntoDeVentaUpdate || idPuntoDeVentaCreate;
        if (inBackOffice) {
          if (id) {
            history.push(
              `/admin/programa/${parseInt(id) || id}/puntos-de-venta/${parseInt(
                idPuntoDeVenta
              ) || idPuntoDeVenta}/edit`
            );
            if (idPuntoDeVentaUpdate)
              setTimeout(() => {
                history.push(
                  `/admin/programa/${parseInt(id) || id}/puntos-de-venta`
                );
              }, 1000);
          }
        } else {
          //REVISAR SI SE DEBE CONFIGURAR PARA USUARIO CLIENTE
          history.push(
            `/puntos-de-venta/${parseInt(idPuntoDeVenta) ||
              idPuntoDeVenta}/edit`
          );
        }
      })
      .catch(e => {
        this.setState({ errors: e });
        return false;
      });
  }
  render() {
    return (
      <>
        <Form
          id={parseInt(this.state.idPrograma) || this.state.idPrograma}
          idPuntoDeVenta={
            parseInt(this.state.idPuntoDeVenta) || this.state.idPuntoDeVenta
          }
          errors={this.state.errors}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
        />
      </>
    );
  }
}

export default Edit;
