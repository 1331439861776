import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import AppQraphQL from "js/graphql/resolvers/locacion.resolver";
import { _t } from "js/utils/TranslationService";
import { ListofChildrenField } from "./index.js";
import { isNil } from "lodash";

class LocalidadField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      localidades: []
    };
  }

  componentWillReceiveProps(props) {
    const { idZona } = props;
    idZona && this.fetchLocalidades(idZona);
  }

  async fetchLocalidades(idZona) {
    const { client } = this.props;
    await client
      .query({
        query: AppQraphQL.queries.getMasterLocalidades,
        variables: { idZona: parseInt(idZona, 10) },
        fetchPolicy: "cache-first",
        errorPolicy: "all"
      })
      .then(res => {
        this.setState({
          localidades: res.data.getMasterLocalidades
        });
      });
  }

  render() {
    const { idZona, required, disabled, name } = this.props;
    const { localidades } = this.state;
    return (
      <ListofChildrenField
        name={name || "idLocalidad"}
        label={`${_t("Localidad")}`}
        disabled={isNil(idZona) || disabled}
        required={required}
      >
        {localidades.map((item, index) => (
          <MenuItem value={parseInt(item.idLocalidad, 10)} key={index}>
            {item.descripcion}
          </MenuItem>
        ))}
      </ListofChildrenField>
    );
  }
}

LocalidadField.propTypes = {
  idLocalidad: PropTypes.number
};

export default withApollo(withRouter(LocalidadField));
