import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    border: "1px solid #bababa",
    borderRadius: 4,
    margin: 5
  },
  textField: {
    width: "100%"
  },
  selected: {
    color: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.main
  },
  component: {
    padding: 15,
    backgroundColor: "#fff"
  }
});

export default withStyles(styles);
