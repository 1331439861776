import { connect } from "react-redux";
import DialogSolicitud from "./DialogSolicitud";
import beneficioActions from "../actions";
import { compose } from "recompose";

const mapStateToProps = state => ({
  beneficio: state.beneficio.beneficio,
  cantidad: state.beneficio.cantidad,
  idSucursal: state.beneficio.idSucursal,
  solicitudFail: state.beneficio.solicitudFail,
  sucursales: state.beneficio.sucursales
});

const mapDispatchToProps = dispatch => ({
  beneficioActions: beneficioActions({ dispatch })
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(DialogSolicitud);
