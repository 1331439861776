import React from "react";
import { numberWithCommas } from "js/utils";
import capitalize from "lodash/capitalize";
import { Estado } from "js/components/Shared/Tabla/TableCells";

export const columnasEdd = [
  { id: "fechaDeAlta", label: "Fecha de alta", type: "date" },
  { id: "username", label: "Usuario" },
  { id: "perfil_nombre", label: "Nombre" },
  { id: "perfil_apellido", label: "Apellido" },
  {
    id: "cod_vendedor",
    label: "Cód. vendedor",
    type: "custom",
    parser: (cod_vendedor, item) => {
      let cod = JSON.parse(item.perfil.otros)["código_de_vendedor"];
      return cod ? cod : "--";
    }
  },
  { id: "segmento_nombre", label: "Segmento" },
  {
    id: "cuentaCorriente",
    label: "Balance de la CC",
    type: "custom",
    isNumeric: true,
    parser: cuentaCorriente =>
      cuentaCorriente.balance ? numberWithCommas(cuentaCorriente.balance) : "--"
  },
  {
    id: "roles",
    label: "Rol",
    type: "custom",
    parser: roles => (roles.length ? capitalize(roles[0].nombre) : "--")
  },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

export const columnasSupervisor = [
  { id: "fechaDeAlta", label: "Fecha de alta", type: "date" },
  { id: "username", label: "Usuario" },
  { id: "perfil_nombre", label: "Nombre" },
  { id: "perfil_apellido", label: "Apellido" },
  {
    id: "cod_vendedor",
    label: "Cód. vendedor",
    type: "custom",
    parser: (cod_vendedor, item) => {
      let cod = JSON.parse(item.perfil.otros)["código_de_vendedor"];
      return cod ? cod : "--";
    }
  },
  {
    id: "cuentaCorriente",
    label: "Balance de la CC",
    type: "custom",
    isNumeric: true,
    parser: cuentaCorriente =>
      cuentaCorriente.balance ? numberWithCommas(cuentaCorriente.balance) : "--"
  },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];
