import React, { PureComponent } from "react";

import Table from "js/components/Shared/Tabla";
import advancedFilter from "./advancedFilter.js";
import CanjeQraphQL from "js/graphql/resolvers/canje.resolver";
import columns from "./columnData";
import {
  manageAction,
  viewAction
} from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import AuthService from "js/utils/AuthService";

const defaultHeader = () => ({
  title: "Canjes",
  export: {
    path: "movimientos/canjes/frontend",
    perms: [Roles.CANJE_R]
  },
  queryStringMatcher: {
    idProducto: "eleccionProducto_idProducto",
    idPrograma: undefined,
    idsProgramas: "eleccionProducto_idPrograma",
    estadoCanje: "estado",
    idLote: "numeroLote",
    excluirFactura: "excluirFacturados",
    excluirCanje: "excluirLoteados",
    administracion: "eleccionProducto_producto_gestionadoPor",
    tipoProducto: "eleccionProducto_producto_tipoProducto"
  }
});

const defaultSort = { order: "desc", orderBy: "fecha" };

class Tabla extends PureComponent {
  render() {
    const cutsomButtons = [];

    const manageCanje = params => ({
      ...manageAction({
        ...params,
        history: this.props.history,
        perms: [Roles.CANJE_W]
      }),
      onclick: e =>
        this.props.history.push(`${window.location.pathname}/${params.itemId}`)
    });
    cutsomButtons.push(manageCanje);

    const viewCanje = params => ({
      ...viewAction({ ...params, history: this.props.history }),
      onclick: e =>
        this.props.history.push(
          `${window.location.pathname}/${params.itemId}/view`
        )
    });
    cutsomButtons.push(viewCanje);

    let query = CanjeQraphQL.queries.getCanjesFront;
    const isCanjeJerarquia = AuthService.hasPerms([Roles.CANJE_JERARQUIA_R]);

    if (isCanjeJerarquia) {
      query = CanjeQraphQL.queries.getCanjesByJerarquiaFront;
    }

    return (
      <Table
        storage="FiltrosCanjes"
        query={query}
        defaultHeader={defaultHeader()}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columns}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count} canjes`
        }
        toolbarActions={["calendar", "progress"]}
        getTipo={
          isCanjeJerarquia
            ? data => data.getCanjesByJerarquia
            : data => data.getCanjes
        }
        actions={AuthService.hasPerms([Roles.CANJE_W]) ? ["bulk"] : []}
        actionsCustom={cutsomButtons}
        buttons={[]}
      />
    );
  }
}

export default Tabla;
