import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import AppQraphQL from "js/graphql/resolvers/locacion.resolver";

class PaisFilter extends PureComponent {
  render() {
    const { classes, value, submitValue } = this.props;

    return (
      <div style={{ display: "flex", flex: 1, flexWrap: "wrap" }}>
        <Query
          query={AppQraphQL.queries.getMasterPaises}
          fetchPolicy="network-only"
          errorPolicy="all"
        >
          {({ loading, error, data }) => {
            if (loading || !data) return null;
            return (
              <FormControl className={classes.formControl}>
                <InputLabel id="pais-simple" htmlFor="pais-simple">
                  País
                </InputLabel>
                <Select
                  value={value["idPais"] || ""}
                  onChange={e => submitValue(e)}
                  inputProps={{
                    name: "idPais",
                    id: "idPais"
                  }}
                >
                  <MenuItem value={undefined}>Todos</MenuItem>
                  {data.getMasterPaises.map((pais, index) => (
                    <MenuItem key={index} value={pais.idPais}>
                      {pais.descripcion}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }}
        </Query>
      </div>
    );
  }
}

PaisFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withRouter(PaisFilter);
