import React, { Fragment } from "react";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { get, set } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

class AsyncExample extends React.Component {
  state = {
    allowNew: false,
    isLoading: false,
    multiple: false,
    options: []
  };

  render() {
    const { placeholder, onChange, classes, emptyLabel, disabled } = this.props;
    return (
      <Fragment>
        <AsyncTypeahead
          {...this.state}
          classes={classes}
          labelKey="label"
          minLength={3}
          emptyLabel={emptyLabel}
          searchText={"Buscando..."}
          options={this.state.options}
          onSearch={this._handleSearch(this.props)}
          promptText={placeholder}
          placeholder={placeholder}
          disabled={disabled}
          id={"async-type-ahead-" + placeholder}
          renderMenu={(results, menuProps) => {
            return (
              <Menu {...menuProps}>
                {results.map((result, index) => (
                  <MenuItem option={result} position={index}>
                    {result.label}
                  </MenuItem>
                ))}
              </Menu>
            );
          }}
          ref={ref => {
            this._typeahead = ref;
          }}
          onChange={e => onChange(e, this)}
          // renderMenuItemChildren={(option, props) => {
          //   return (
          //     <Highlighter search={props.text}>{option.label}</Highlighter>
          //   );
          // }}
        />
      </Fragment>
    );
  }

  _handleSearch = props => input => {
    this.setState({ isLoading: true });
    const {
      client,
      query,
      queryParams,
      path,
      withTodos,
      without,
      filter,
      queryVariable
    } = props;
    const variables = { ...queryParams };
    set(variables, queryVariable, input);
    client
      .query({
        query: query,
        variables: variables,
        fetchPolicy: "network-only",
        errorPolicy: "all"
      })
      .then(res => {
        this.setState({
          isLoading: false,
          options: getSuggestions(res.data, path, withTodos, without, filter)
        });
      });
  };
}

function getSuggestions(data, path, withTodos, without = [], filter) {
  let dat = get(data, path.path, []);
  if (!dat) return [];
  let options = dat
    .map(suggestion => ({
      value: get(suggestion, path.value),
      label: path.multilabel
        ? path.multilabel.map(label => get(suggestion, label, label)).join("")
        : get(suggestion, path.label)
    }))
    .filter(a => !without.includes(a.value))
    .sort(
      (a, b) =>
        (a.label || "").toString().toLowerCase() >
        (b.label || "").toString().toLowerCase()
          ? 1
          : -1
    );
  options = filter ? options.filter(filter) : options;
  return (withTodos && options.length > 1
    ? [{ value: undefined, label: "Todos" }]
    : []
  ).concat(options);
}

export default AsyncExample;
