import React from "react";
import PropTypes from "prop-types";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { capitalizeFirstLetter } from "../../../utils/Helper";
import { _t } from "js/utils/TranslationService";

class DialogSolicitud extends React.PureComponent {
  render() {
    const { beneficio } = this.props;
    return (
      <div>
        <CardContent>
          <Typography variant="h6" component="h1">
            {capitalizeFirstLetter(beneficio.nombre)}
          </Typography>
          <Typography variant="subtitle1" component="h2">
            {_t("Estás por solicitar: ")}
            <strong>{beneficio.nombre}</strong>
          </Typography>
        </CardContent>
      </div>
    );
  }
}

DialogSolicitud.propTypes = {
  classes: PropTypes.object.isRequired
};

export default DialogSolicitud;
