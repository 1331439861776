export default theme => ({
  root: {
    flexGrow: 1
  },
  appFrame: {},
  flex: {
    flex: 1
  },
  menuButton: {
    display: "flex"
  },
  toolbar: {
    padding: 0,
    justifyContent: "space-between",
    minHeight: 62
  },
  icon: {
    /* margin: theme.spacing.unit * 2 */
  },
  iconhover: {
    hover: {
      color: "red"
    }
  },
  appbar: {
    boxShadow: "none"
  },
  modal: {
    backgroundColor: "white"
  },
  logo: {
    margin: 15
  },
  avatar: {
    color: "red"
  }
});
