import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import ReconocidoRaw from "./Reconocido";
import ReconocidoAprobadoRaw from "./ReconocidoAprobado";
import ReconocidoRechazadoRaw from "./ReconocidoRechazado";
import ReconocidoEditadoRaw from "./ReconocidoEditado";
import styles from "./styles";

const enhance = compose(
  withRouter,
  withStyles(styles)
);

export const Reconocido = enhance(ReconocidoRaw);
export const ReconocidoAprobado = enhance(ReconocidoAprobadoRaw);
export const ReconocidoRechadazo = enhance(ReconocidoRechazadoRaw);
export const ReconocidoEditado = enhance(ReconocidoEditadoRaw);
