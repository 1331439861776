import { _t } from "js/utils/TranslationService";
import { DatePicker } from "material-ui-pickers";
import DireccionEntregaForm from "../../../pages/ProductoRedux/DialogCanje/DireccionEntregaForm";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import TextField from "@material-ui/core/TextField";

export const getField = ({
  key,
  nombre,
  datosRequeridosValues,
  handleChangeDatoRequerido,
  idPrograma
}) => {
  switch (key) {
    case "entrega":
      const obj =
        typeof datosRequeridosValues[key] === "string"
          ? JSON.parse(datosRequeridosValues[key])
          : datosRequeridosValues[key];
      return (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {_t("Dirección de entrega")}
          </Typography>
          <DireccionEntregaForm
            value={{ ...obj }}
            onChange={handleChangeDatoRequerido(key)}
            required
            idPrograma={idPrograma}
          />
        </Grid>
      );
    case "fechaNacimiento":
      return (
        <Grid item xs={12} md={6}>
          <DatePicker
            id={key}
            key={key}
            value={
              datosRequeridosValues[key]
                ? moment(datosRequeridosValues[key])
                : null
            }
            label={`${nombre}`}
            onChange={v =>
              handleChangeDatoRequerido(key)({
                target: { name: key, value: v.toISOString() }
              })
            }
            format={"DD/MM/YYYY"}
            maxDate={moment()}
            autoOk
            margin="normal"
            required
          />
        </Grid>
      );

    default:
      return (
        <Grid item xs={12} md={6}>
          <TextField
            id={key}
            type="text"
            key={key}
            label={`${nombre}`}
            required
            value={datosRequeridosValues[key]}
            onChange={handleChangeDatoRequerido(key)}
            margin="normal"
          />
        </Grid>
      );
  }
};
