import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    [theme.breakpoints.down(500)]: {
      boxShadow: "none"
    },
    maxWidth: 450,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`
  },
  title: {
    fontWeight: "bold"
  },
  container: {
    padding: theme.spacing.unit * 4
  },
  button: {
    margin: "10px"
  },
  field: {
    width: 300
  },
  inputs: {
    marginBottom: 25
  }
});

export default withStyles(styles);
