import React, { PureComponent, Fragment } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaSucursalBeneficio/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/beneficio.resolver";
import { viewAction, editAction } from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import get from "lodash/get";

const defaultHeader = (id, idBeneficio) => {
  return {
    title: "Sucursales de Beneficio",
    titleTranslation: false,
    new: {
      title: "Nuevo",
      perms: [Roles.SUCURSAL_BENEFICIO_W]
    },
    export: {
      path: "sucursalesBeneficios",
      perms: [Roles.SUCURSAL_BENEFICIO_R],
      tooltip: "Exportar sucursales"
    },
    import: {
      action: AppQraphQLImport.queries.importarBeneficioSucursales,
      perms: [Roles.SUCURSAL_BENEFICIO_W],
      tooltip: "Importar sucursales de beneficios",
      model: "Modelo_Beneficio_Sucursales.csv",
      modelTooltip: "Modelo de importación de sucursales de beneficios",
      variables: {
        idPrograma: id,
        idBeneficio: idBeneficio
      }
    }
  };
};

const columnData = [
  { id: "nombre", label: "Nombre" },
  { id: "pais_descripcion", label: "País", removeSort: true },
  { id: "zona_descripcion", label: "Zona", removeSort: true },
  { id: "localidad_descripcion", label: "Localidad", removeSort: true },
  { id: "direccion", label: "Dirección", removeSort: true },
  { id: "telefono", label: "Teléfono", removeSort: true },
  { id: "orden", label: "Prioridad" },
  {
    id: "estado",
    label: "Estado",
    removeSort: true,
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "asc", orderBy: "nombre" };

class Tabla extends PureComponent {
  constructor(props) {
    super(props);
    const id = props.match.params.id;
    const idBeneficio = props.match.params.idBeneficio;
    this.state = {
      defaultHeader: defaultHeader(id, idBeneficio)
    };
  }
  async componentDidMount() {
    const {
      client,
      match: {
        params: { id, idBeneficio }
      },
      history
    } = this.props;
    const { defaultHeader } = this.state;
    const dataBeneficio = await client.query({
      query: AppQraphQL.queries.getBeneficioById,
      fetchPolicy: "network-only",
      errorPolicy: "all",
      variables: {
        idBeneficio: idBeneficio,
        idPrograma: id
      }
    });
    const nombreBeneficio = get(
      dataBeneficio,
      "data.getBeneficio.itemsPagina[0].nombre",
      null
    );
    if (!nombreBeneficio) return;
    defaultHeader.title = (
      <Fragment>
        <IconButton
          onClick={() => history.push(`/admin/programa/${id}/beneficios`)}
        >
          <ArrowBack />
        </IconButton>
        {nombreBeneficio + " | Sucursales"}
      </Fragment>
    );
    this.setState({
      defaultHeader: defaultHeader
    });
    this.forceUpdate();
  }

  render() {
    const { defaultHeader } = this.state;

    const view = params => ({
      ...viewAction({
        ...params,
        history: this.props.history,
        perms: [Roles.SUCURSAL_BENEFICIO_R]
      })
    });
    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.SUCURSAL_BENEFICIO_W]
      })
    });

    return (
      <Table
        storage={"FiltroSucursalBeneficioAdmin"}
        query={AppQraphQL.queries.getPaginaSucursalesBeneficio}
        defaultHeader={defaultHeader}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getBeneficioSucursales}
        actionsCustom={[view, edit]}
      />
    );
  }
}

export default Tabla;
