import React, { PureComponent, Fragment } from "react";
import AppQraphQL from "js/graphql/resolvers/rol.resolver";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { isEmpty, orderBy, groupBy, get } from "lodash";
import SplashScreen from "js/pages/SplashScreen";

class Acciones extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      privilegios: [],
      privilegiosSeleccionados: [],
      nombre: "",
      descripcion: "",
      rol: "",
      loading: true
    };
  }

  async componentDidMount() {
    const {
      client,
      match: {
        params: { idRol }
      }
    } = this.props;

    await client
      .query({
        query: AppQraphQL.queries.getRolesById,
        variables: { idRol: parseInt(idRol) || idRol },
        fetchPolicy: "network-only"
      })
      .then(res => {
        this.setState(
          {
            rol: get(res, "data.getRoles[0].nombre", ""),
            privilegiosSeleccionados: get(
              res,
              "data.getRoles[0].privilegios",
              []
            )
          },
          () => {
            this.getPrivilegiosRol();
          }
        );
      });
  }

  async getPrivilegiosRol() {
    const {
      client,
      match: {
        params: { id }
      }
    } = this.props;
    await client
      .query({
        query: AppQraphQL.queries.getPrivilegios,
        variables: {
          idPrograma: parseInt(id) || id
        },
        fetchPolicy: "network-only"
      })
      .then(res2 => {
        this.setState({
          privilegios: orderBy(
            res2.data.getPrivilegios,
            o => o.codigoPrivilegio
          ),
          loading: false
        });
      });
  }

  async saveAction() {
    const {
      client,
      match: {
        params: { idRol, id }
      }
    } = this.props;
    const { privilegiosSeleccionados } = this.state;
    await client
      .mutate({
        mutation: AppQraphQL.queries.updateRol,
        variables: {
          rol: {
            idRol: parseInt(idRol) || idRol,
            privilegios: privilegiosSeleccionados.map(i => {
              return { codigoPrivilegio: i.codigoPrivilegio };
            })
          }
        }
      })
      .then(res => {
        const message = `Privilegios actualizados correctamente`;
        this.props.openNotification(message);
        this.props.history.push(`/admin/programa/${parseInt(id) || id}/roles`);
      });
  }

  onClickPrivilegio(value) {
    const { privilegiosSeleccionados } = this.state;
    if (
      !!privilegiosSeleccionados.find(
        i => i.idPrivilegio === value.idPrivilegio
      )
    ) {
      const item = privilegiosSeleccionados.map(
        i => i.idPrivilegio === value.idPrivilegio
      );
      privilegiosSeleccionados.splice(item.indexOf(true), 1);
      this.setState({
        privilegiosSeleccionados: privilegiosSeleccionados
      });
      this.forceUpdate();
    } else {
      this.setState({
        privilegiosSeleccionados: this.state.privilegiosSeleccionados.concat([
          value
        ])
      });
    }
  }

  render() {
    const { privilegios, privilegiosSeleccionados, rol, loading } = this.state;
    const { classes } = this.props;
    let agrupado = {};
    if (!isEmpty(privilegios)) {
      agrupado = groupBy(privilegios, "grupo");
    }

    return (
      <Fragment>
        {loading && <SplashScreen />}
        {!loading && (
          <Fragment>
            <Typography variant="h5" gutterBottom>
              Rol: {rol}
            </Typography>
            {Object.keys(agrupado).map((key, index) => (
              <Fragment key={index}>
                <Typography variant="h6">{key}</Typography>
                <List>
                  {agrupado[key].map((value, index) => (
                    <ListItem key={index} dense button>
                      <ListItemText
                        primary={`${value.codigoPrivilegio}`}
                        secondary={`${value.descripcion || ""}`}
                      />
                      {value.nombre !== "SUPERADMIN" && (
                        <ListItemSecondaryAction>
                          <Checkbox
                            id={value.codigoPrivilegio}
                            checked={
                              !!privilegiosSeleccionados.find(
                                i => i.idPrivilegio === value.idPrivilegio
                              )
                            }
                            onChange={this.onClickPrivilegio.bind(this, value)}
                            color="primary"
                          />
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Fragment>
            ))}

            <div className={classes.buttonSave}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.saveAction.bind(this)}
              >
                Guardar cambios
              </Button>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default Acciones;
