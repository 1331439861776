import React, { createContext } from "react";
import Notification from "js/components/Notification";

const NotificationContext = createContext();

export class NotificationProvider extends React.Component {
  state = {
    notificationIsOpen: false,
    message: "",
    autoHideDuration: 3000
  };

  closeNotification = (e, reason) => {
    if (reason === "clickaway") return;
    this.setState(() => ({
      notificationIsOpen: false,
      message: ""
    }));
  };

  openNotification = (message, autoHideDuration = 3000) => {
    this.setState(() => ({
      notificationIsOpen: true,
      message,
      autoHideDuration
    }));
  };

  render() {
    return (
      <div>
        <NotificationContext.Provider
          value={{ openNotification: this.openNotification }}
        >
          {this.props.children}
        </NotificationContext.Provider>
        <Notification
          open={this.state.notificationIsOpen}
          mensaje={this.state.message}
          handleClose={this.closeNotification}
          autoHideDuration={this.state.autoHideDuration}
        />
      </div>
    );
  }
}

export const withNotificationContext = Component => {
  return props => (
    <NotificationContext.Consumer>
      {({ openNotification }) => {
        return <Component {...props} openNotification={openNotification} />;
      }}
    </NotificationContext.Consumer>
  );
};
