import {
  compose,
  withHandlers,
  pure,
  withProps,
  withState,
  lifecycle,
  branch
} from "recompose";
import { reduxForm } from "redux-form";
import { graphql } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNotificationContext } from "js/notification-context";
import ProgramQraphQL from "js/graphql/resolvers/programa.resolver";
import UserQraphQL from "js/graphql/resolvers/usuario.resolver";
import ProfileQraphQL from "js/graphql/resolvers/perfil.resolver";
import withPrograma from "js/hoc/withPrograma";
import withStyles from "./styles";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { uploadImage } from "js/utils";

const processOtros = otros => {
  const result = { ...otros };
  for (const key in otros) {
    if (otros.hasOwnProperty(key)) {
      // Si tiene el primer char '[' y el último ']' entonces debe ser array y no string
      if (
        typeof otros[key] === "string" &&
        otros[key][0] === "[" &&
        otros[key][otros[key].length - 1] === "]"
      ) {
        result[key] = JSON.parse(otros[key]);
      }
    }
  }
  return result;
};

export default compose(
  pure,
  withPrograma,
  withNotificationContext,
  withStyles,
  withRouter,
  withState("loadingImage", "setLoadingImage", false),
  withState("data", "setData", {}),
  withProps(ownerProps => {
    return {
      idProgramaSelected: ownerProps.idProgramaSelected,
      idUsuarioSelected: ownerProps.idUsuarioSelected,
      showBox: ownerProps.showBox === false ? false : true
    };
  }),
  graphql(ProfileQraphQL.mutations.updatePerfil, { name: "updatePerfil" }),
  graphql(ProfileQraphQL.mutations.updateMiPerfil, { name: "updateMiPerfil" }),
  graphql(UserQraphQL.mutations.updateUsuario, { name: "updateUsuario" }),
  graphql(UserQraphQL.mutations.updateMiUsuario, { name: "updateMiUsuario" }),
  graphql(UserQraphQL.queries.getMiUsuarioCompleto, { name: "getMiUsuario" }),
  graphql(UserQraphQL.queries.getUsuariosCompleto, {
    name: "getUsuariosCompleto",
    options: ({ idUsuarioSelected }) => ({
      variables: {
        usuarioLike: {
          idUsuario:
            idUsuarioSelected || window.localStorage.getItem("idUsuario")
        }
      },
      fetchPolicy: "network-only",
      refetchQueries: [`getRedirect`]
    })
  }),
  branch(
    ({ idProgramaSelected }) => !!idProgramaSelected,
    graphql(ProgramQraphQL.queries.getDefinicionesPerfil, {
      options: ({ idProgramaSelected: idPrograma }) => ({
        fetchPolicy: "network-only",
        variables: { definicionPerfilLike: { idPrograma } }
      }),
      props: ({ data: { getDefinicionesPerfil: profileDefinitions } }) => ({
        profileDefinitions
      })
    }),
    graphql(ProgramQraphQL.queries.misDefinicionesPerfil, {
      options: () => ({ fetchPolicy: "network-only" }),
      props: ({ data: { misDefinicionesPerfil: profileDefinitions } }) => ({
        profileDefinitions
      })
    })
  ),
  withHandlers({
    onChangeImage: ({
      openNotification,
      updateUsuario,
      updateMiUsuario,
      data,
      setData,
      data: {
        initialValues: { idUsuario }
      },
      setLoadingImage
    }) => image => {
      setLoadingImage(true);
      uploadImage({ width: 250, height: 250 })(image)
        .then(avatarUrl => {
          const hasPerm = AuthService.hasPerms(["USUARIO_W"]);
          const mutation = hasPerm ? updateUsuario : updateMiUsuario;
          mutation({
            refetchQueries: [`getMiUsuario`],
            variables: {
              usuario: {
                idUsuario: hasPerm ? idUsuario : undefined,
                avatarUrl
              }
            }
          })
            .then(() => {
              openNotification("El avatar se ha actualizado");
              setData({
                ...data,
                avatarUrl
              });
            })
            .catch(e =>
              openNotification("Hubo un error al actualizar el avatar")
            );
        })
        .catch(e => openNotification("Hubo un error al actualizar el avatar"))
        .finally(() => setLoadingImage(false));
    },
    onSubmit: ({
      updatePerfil,
      updateMiPerfil,
      openNotification,
      history,
      idProgramaSelected
    }) => ({ idUsuario, idUsuarioSelected, ...values }) => {
      const hasPerm = AuthService.hasPerms(["PERFIL_W"]);
      const mutation = hasPerm ? updatePerfil : updateMiPerfil;
      mutation({
        refetchQueries: [`getRedirect`, `getMiUsuario`],
        awaitRefetchQueries: true,
        variables: {
          perfil: {
            idUsuario: hasPerm
              ? parseInt(idUsuarioSelected) ||
                idUsuarioSelected ||
                parseInt(idUsuario) ||
                idUsuario
              : undefined,
            otros: JSON.stringify(values)
          }
        }
      })
        .then(data => {
          openNotification("El perfil se ha actualizado");
          try {
            if (idProgramaSelected) {
              if (AuthService.hasPerms(["ACCESO_BO"])) {
                history.push(
                  window.location.href.match(/(\/admin.*usuario)/)[0]
                );
              } else {
                history.push("/usuarios");
              }
            } else {
              history.push("/");
            }
          } catch (e) {
            history.push("/");
          }
        })
        .catch(e => {
          if (e.message.indexOf("Ya existe")) {
            const message = e.message.split("GraphQL error: ").pop();
            openNotification(message);
          } else {
            openNotification("Hubo un error al actualizar el perfil");
          }
        });
    },
    volver: ({ history, match }) => {
      const to = window.location.href.match(/(\/admin.*usuario)/);
      const {
        params: { idUsuario }
      } = match;
      if (!to || !to.length) history.goBack();
      else history.push(`${to[0]}/${idUsuario}/edit`);
    }
  }),
  lifecycle({
    async componentDidMount() {
      const {
        getMiUsuario,
        getUsuariosCompleto,
        location: { pathname },
        history,
        setData,
        match: {
          params: { idUsuario: id },
          path
        }
      } = this.props;
      let data = {};
      let refetch;

      if (pathname === "/profile") {
        ({ refetch } = getMiUsuario);
        const {
          data: {
            miUsuario: {
              cuentaCorriente: { balance },
              programa: { diasParaVencimientoPuntos },
              username,
              avatarUrl,
              idUsuario,
              perfil: { otros = "{}" }
            },
            misPuntosPorVencer: { cantidad, fecha }
          }
        } = await getMiUsuario.refetch();
        data = {
          balance,
          diasParaVencimientoPuntos,
          username,
          avatarUrl,
          idUsuario,
          otros,
          cantidad,
          fecha
        };
      } else {
        ({ refetch } = getUsuariosCompleto);
        const {
          data: {
            getUsuarios: {
              itemsPagina: [
                {
                  cuentaCorriente: { balance },
                  programa: { diasParaVencimientoPuntos },
                  username,
                  avatarUrl,
                  idUsuario,
                  perfil: { otros = "{}" }
                }
              ]
            },
            misPuntosPorVencer: { cantidad, fecha }
          }
        } = await getUsuariosCompleto.refetch();
        data = {
          balance,
          diasParaVencimientoPuntos,
          username,
          avatarUrl,
          idUsuario,
          otros,
          cantidad,
          fecha
        };
      }
      const {
        balance,
        diasParaVencimientoPuntos,
        username,
        avatarUrl,
        idUsuario,
        otros,
        cantidad,
        fecha
      } = data;
      let finalOtros = JSON.parse(otros);
      finalOtros = processOtros(finalOtros);
      data = {
        puntos: [
          {
            label: "Saldo de Puntos",
            puntos: balance,
            onClick: () => history.push("/mis-movimientos")
          },
          {
            label: "Puntos por vencer",
            puntos: cantidad,
            fecha,
            hidden: !diasParaVencimientoPuntos
          }
        ],
        boxes: diasParaVencimientoPuntos ? 3 : 2,
        onRefetchProfile: refetch,
        username,
        avatarUrl,
        initialValues: {
          idUsuario,
          ...finalOtros
        },
        showSave:
          !path.match(/(\/admin.*usuario)/) ||
          AuthService.hasPerms([Roles.PERFIL_W]) ||
          AuthService.getIdUsuario() === id
      };
      setData(data);
    }
  }),
  connect((_, { data: { initialValues } }) => ({ initialValues })),
  reduxForm({
    form: "myProfileForm",
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
  })
);
