import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { _t } from "js/utils/TranslationService";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AuthService from "js/utils/AuthService";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import UsuarioGraphQL from "js/graphql/resolvers/usuario.resolver";
import get from "lodash/get";

const styles = theme => ({
  avatar: {
    margin: 10,
    width: 80,
    height: 80
  },
  cardActions: {
    width: "100%"
  },
  button: {
    minWidth: "fit-content"
  },
  cardHeader: {
    padding: theme.spacing.unit
  }
});

class UserMenu extends React.PureComponent {
  state = {
    auth: true,
    anchorEl: null,
    fullName: "",
    username: "",
    avatarUrl: "",
    idUsuario: ""
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount = () => {
    const { client } = this.props;
    client
      .watchQuery({
        query: UsuarioGraphQL.queries.getMiUsuario,
        errorPolicy: "all",
        fetchPolicy: "network-only"
      })
      .subscribe({
        next: ({ data, loading }) => {
          const nombre = get(data, "miUsuario.perfil.nombre") || "";
          const apellido = get(data, "miUsuario.perfil.apellido") || "";
          this.setState({
            fullName: nombre + " " + apellido,
            username: get(data, "miUsuario.username"),
            avatarUrl: get(data, "miUsuario.avatarUrl", ""),
            idUsuario: get(data, "miUsuario.idUsuario", "")
          });
        }
      });
  };

  render() {
    const { classes, history } = this.props;
    const { anchorEl, fullName, username, avatarUrl, idUsuario } = this.state;
    const open = Boolean(anchorEl);
    let idUsuarioFix = parseInt(idUsuario) || idUsuario;
    return (
      <div className={classes.root}>
        <IconButton
          aria-owns={open ? "menu-appbar" : undefined}
          onClick={this.handleMenu}
          color="inherit"
        >
          {avatarUrl && avatarUrl.length ? (
            <Avatar src={avatarUrl} />
          ) : (
            <AccountCircle />
          )}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={open}
          onClose={this.handleClose}
          PaperProps={{ style: { width: 300 } }}
        >
          <CardHeader
            className={classes.cardHeader}
            avatar={
              avatarUrl && avatarUrl.length ? (
                <Avatar src={avatarUrl} className={classes.avatar} />
              ) : (
                <AccountCircle className={classes.avatar} color="secondary" />
              )
            }
            title={fullName}
            subheader={
              <React.Fragment>
                {username}
                <br />
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    history.push(`/admin/usuario/${idUsuarioFix}/edit/perfil`);
                    this.handleClose();
                    return;
                  }}
                >
                  {_t("Editar perfil")}
                </Button>
              </React.Fragment>
            }
          />

          <Divider />
          <CardActions>
            <div className={classes.cardActions} />
            <Button
              size="small"
              color="primary"
              className={classes.button}
              onClick={() => AuthService.logout({ redirect: "admin" })}
            >
              {_t("Cerrar sesión")}
            </Button>
          </CardActions>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(withApollo(withRouter(UserMenu)));
