import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";

import styles from "./styles";
import AuthService from "js/utils/AuthService";

class MenuPrograma extends PureComponent {
  render() {
    const {
      classes,
      idPrograma,
      nombrePrograma,
      handleChangeMenu,
      showTooltip,
      linksMenuPrograma
    } = this.props;
    return (
      <Fragment>
        <ListItem
          className={classes.listItem}
          button
          onClick={handleChangeMenu("main")}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <ChevronLeftIcon />
          </ListItemIcon>
          <ListItemText
            className={classes.listItemText}
            primary={nombrePrograma}
          />
        </ListItem>

        {linksMenuPrograma.map(group => (
          <Fragment key={group[0].texto}>
            {group.map(
              (link, index) =>
                (!link.perms.length || AuthService.hasPerms(link.perms)) &&
                (!link.optional ||
                  !link.optional.lenght ||
                  AuthService.hasAnyPerm(link.optional)) && (
                  <Tooltip
                    key={`${link.link}-${index}`}
                    interactive
                    classes={{
                      tooltip: !!showTooltip ? classes.tooltip : classes.hide,
                      tooltipPlacementBottom: classes.tooltipPlacementBottom,
                      tooltipPlacementRight: classes.tooltipPlacementRight
                    }}
                    title={link.texto}
                    placement="right"
                  >
                    <ListItem
                      className={classes.listItem}
                      button
                      component={NavLink}
                      activeClassName={classes.active}
                      to={`/admin/programa/${idPrograma}/${link.link}`}
                      key={`/admin/programa/${idPrograma}/${link.link}`}
                    >
                      {link.icon && (
                        <ListItemIcon className={classes.listItemIcon}>
                          {link.icon}
                        </ListItemIcon>
                      )}
                      <ListItemText
                        className={classes.listItemText}
                        primary={link.texto}
                      />
                    </ListItem>
                  </Tooltip>
                )
            )}
            <Divider />
          </Fragment>
        ))}
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(MenuPrograma)
);
