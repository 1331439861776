import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import { _t } from "js/utils/TranslationService";

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      actions,
      columnData,
      classes
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {actions.indexOf("bulk") !== -1 && (
            <TableCell
              classes={{ root: classes.tableCellRoot }}
              padding="checkbox"
            >
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}
          {actions.indexOf("add") !== -1 && (
            <TableCell
              classes={{ root: classes.tableCellRoot }}
              padding="checkbox"
            />
          )}
          {columnData.map((column, i) => {
            return (
              <TableCell
                classes={{
                  root: column.sticky
                    ? classes.tableCellRootSticky
                    : classes.tableCellRoot
                }}
                className={classes.tableCellColor}
                key={`${column.id}-${i}`}
                numeric={column.isNumeric}
                padding={"dense"}
                sortDirection={
                  orderBy === column.id ||
                  (column.sortName && orderBy === column.sortName)
                    ? order
                    : false
                }
              >
                {column.removeSort || column.id.match(/^actions$/) !== null ? (
                  _t(column.label)
                ) : (
                  <Tooltip
                    title={`${_t("Ordenar")}`}
                    placement={column.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={
                        orderBy === column.id ||
                        (column.sortName && orderBy === column.sortName)
                      }
                      direction={order}
                      onClick={this.createSortHandler(
                        column.sortName || column.id
                      )}
                      className={classes.tableCellColor}
                    >
                      {_t(column.label)}
                    </TableSortLabel>
                  </Tooltip>
                )}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  actions: PropTypes.array.isRequired,
  columnData: PropTypes.array.isRequired
};

export default EnhancedTableHead;
