import React, { PureComponent } from "react";
// import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from "react-sortable-hoc";
import cx from "classnames";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ReorderIcon from "@material-ui/icons/Reorder";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import ListItemText from "@material-ui/core/ListItemText";

import {
  getNumericFieldLimits,
  getValueAsString
} from "js/components/Shared/Form/fieldGenerator";
import styles from "../styles.js";

const ubicacionOptions = [
  { label: "País", value: "PAIS" },
  { label: "Zona", value: "ZONA" },
  { label: "Localidad", value: "LOCALIDAD" },
  { label: "Dirección", value: "DIRECCION" },
  { label: "Código postal", value: "CODIGO_POSTAL" }
];

const DragHandle = SortableHandle(() => (
  <ReorderIcon style={{ cursor: "move" }} />
));

const SortableItem = SortableElement(
  ({ row, classes, onClick, disableEdit }) => {
    return (
      <TableRow style={{ zIndex: "10" }}>
        <TableCell
          className={cx(classes.align, classes.columnWidth100, classes.border)}
        >
          <div className={classes.name}>
            <DragHandle />
            <div className={classes.nameValue}>
              <TextField
                id="label"
                className={classes.columnWidthInput}
                value={row.label || ""}
                onChange={e => onClick(e, row.key)}
                error={!row.label || row.labelHasError}
                disabled={disableEdit}
              />
            </div>
          </div>
        </TableCell>
        <TableCell className={cx(classes.align, classes.columnWidth100)}>
          <Checkbox
            id="visibleRegistro"
            onChange={e => onClick(e, row.key, "boolean")}
            checked={row.visibleRegistro}
            color="primary"
            disabled={disableEdit}
          />
        </TableCell>
        <TableCell
          className={cx(classes.align, classes.columnWidth100, classes.border)}
        >
          <Switch
            id="requeridoRegistro"
            onChange={e => onClick(e, row.key, "boolean")}
            checked={row.requeridoRegistro}
            color="primary"
            disabled={disableEdit}
          />
        </TableCell>
        <TableCell className={cx(classes.align, classes.columnWidth100)}>
          <Checkbox
            id="visiblePerfil"
            onChange={e => onClick(e, row.key, "boolean")}
            checked={row.visiblePerfil}
            color="primary"
            disabled={disableEdit}
          />
        </TableCell>
        <TableCell
          className={cx(classes.align, classes.columnWidth100, classes.border)}
        >
          <Switch
            id="requeridoPerfil"
            onChange={e => onClick(e, row.key, "boolean")}
            checked={row.requeridoPerfil}
            color="primary"
            disabled={disableEdit}
          />
        </TableCell>
        <TableCell className={cx(classes.align, classes.columnWidth100)}>
          <FormControl className={classes.formControl}>
            <Select
              name="tipo"
              value={row.tipo}
              className={classes.columnWidthInput}
              onChange={e => onClick(e, row.key)}
              disabled={disableEdit}
            >
              <MenuItem value={"Texto"}>Texto</MenuItem>
              <MenuItem value={"Numerico"}>Número</MenuItem>
              <MenuItem value={"Booleano"}>Booleano</MenuItem>
              <MenuItem value={"Booleano_Link"}>Booleano con link</MenuItem>
              <MenuItem value={"Lista"}>Lista</MenuItem>
              <MenuItem value={"Lista_Multiple"}>Lista múltiple</MenuItem>
              <MenuItem value={"Email"}>Email</MenuItem>
              <MenuItem value={"Fecha"}>Fecha</MenuItem>
              <MenuItem value={"Ubicacion"}>Ubicación</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell
          className={cx(classes.align, classes.columnWidth100, classes.border)}
        >
          {(row.tipo === "Lista" ||
            row.tipo === "Lista_Multiple" ||
            row.tipo === "Booleano_Link") && (
            <TextField
              id="value"
              className={cx(classes.textField, classes.columnWidthInput)}
              value={row.value}
              onChange={e => onClick(e, row.key, row.tipo)}
              disabled={disableEdit}
              autoComplete="off"
            />
          )}
          {row.tipo === "Ubicacion" && (
            <Select
              name="value"
              value={row.value ? row.value : []}
              className={classes.columnWidthInput}
              onChange={e => onClick(e, row.key, row.tipo)}
              disabled={disableEdit}
              multiple={true}
              renderValue={selected => {
                let label = "";
                ubicacionOptions.map(option => {
                  if (row.value && row.value.includes(option.value)) {
                    label =
                      label === "" ? option.label : label + ", " + option.label;
                  }
                });
                return label;
              }}
            >
              {ubicacionOptions.map(option => (
                <MenuItem value={option.value}>
                  <Checkbox
                    checked={
                      row.value ? row.value.includes(option.value) : false
                    }
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          )}
          {row.tipo === "Numerico" && (
            <>
              <TextField
                id="value"
                label="Min"
                value={getNumericFieldLimits(row.value, "min")}
                className={cx(classes.textField, classes.columnWidthInput)}
                onChange={e =>
                  onClick(
                    e,
                    row.key,
                    row.tipo,
                    "min",
                    getValueAsString(row.value)
                  )
                }
                disabled={disableEdit}
                InputLabelProps={{
                  shrink: true
                }}
                type="number"
                autoComplete="off"
              />
              <TextField
                id="value"
                label="Máx"
                value={getNumericFieldLimits(row.value, "max")}
                className={cx(classes.textField, classes.columnWidthInput)}
                onChange={e =>
                  onClick(
                    e,
                    row.key,
                    row.tipo,
                    "max",
                    getValueAsString(row.value)
                  )
                }
                disabled={disableEdit}
                InputLabelProps={{
                  shrink: true
                }}
                type="number"
                autoComplete="off"
              />
            </>
          )}
        </TableCell>
        <TableCell className={cx(classes.align, classes.columnWidth100)}>
          <div className={classes.name}>
            <div className={classes.nameValue}>
              <TextField
                id={"grupo-" + row.key}
                className={classes.columnWidthInput}
                value={row.grupo}
                onChange={e => onClick(e, row.key)}
                disabled={disableEdit}
              />
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, classes, onClick, disabled }) => {
    return (
      <TableBody>
        {items.map((row, index) => (
          <SortableItem
            index={index}
            key={`item-${index}`}
            classes={classes}
            row={row}
            onClick={onClick}
            disableEdit={disabled}
            disabled={disabled}
          />
        ))}
      </TableBody>
    );
  }
);

class SortableComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ items: props.data });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { items } = this.state;
    const { sortEnded } = this.props;

    this.setState({
      items: arrayMove(items, oldIndex, newIndex)
    });
    sortEnded(this.state.items);
  };
  render() {
    const { items } = this.state;
    const { classes, onClick, disabled } = this.props;

    return (
      <SortableList
        items={items}
        classes={classes}
        onClick={onClick}
        onSortEnd={this.onSortEnd}
        useDragHandle={true}
        disabled={disabled}
      />
    );
  }
}

export default withApollo(withRouter(withStyles(styles)(SortableComponent)));
