import React, { PureComponent } from "react";

import AuthService from "js/utils/AuthService";
import Table from "js/components/Shared/Tabla";
import {
  editAction,
  resetPasswordAction
} from "js/components/Shared/Tabla/ActionCells";
import AppQraphQL from "js/graphql/resolvers/usuarioUnilever.resolver";
import advancedFilter from "js/pages/Unilever/Usuario/Tabla/advancedFilter";
import cambiarEstadoAction from "js/pages/Unilever/Usuario/Tabla/cambiarEstado";
import { USER_ROLES as Roles } from "js/models/User";
import {
  columnasSupervisor,
  columnasEdd
} from "js/pages/Unilever/Usuario/Tabla/columns";

const simplifyHeader = {
  title: "Usuarios",
  new: {
    title: "Nuevo",
    perms: [Roles.USUARIO_W]
  },
  export: {
    path: "usuarios",
    perms: [Roles.USUARIO_R]
  },
  queryStringMatcher: {
    idsProgramas: undefined
  }
};

const defaultSort = { order: "desc", orderBy: "fechaDeAlta" };

class Tabla extends PureComponent {
  render() {
    const resetPassword = params => ({
      ...resetPasswordAction({
        ...params,
        history: this.props.history,
        openNotification: this.props.openNotification,
        perms: [Roles.BLANQUEO_PASSWORD_W]
      })
    });

    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.USUARIO_W]
      })
    });

    const cambiarEstado = params => ({
      ...cambiarEstadoAction({
        ...params,
        history: this.props.history,
        perms: [Roles.USUARIO_W],
        mutation: AppQraphQL.mutations.cambiarEstadoUsuarioUnilever,
        openNotification: this.props.openNotification
      })
    });

    const userRoles = AuthService.getUserRoles();
    const isSupervisor =
      userRoles &&
      userRoles.some(rol => {
        if (rol.toLowerCase() === "supervisor") {
          return true;
        }
        return false;
      });

    let columnData = null;

    if (isSupervisor) {
      columnData = columnasSupervisor;
    } else {
      columnData = columnasEdd;
    }

    return (
      <Table
        storage={"FiltroUsuarioUnilever"}
        query={AppQraphQL.queries.getPaginaUsuariosUnilever}
        defaultHeader={simplifyHeader}
        advancedFilter={advancedFilter}
        actionsCustom={[edit, resetPassword, cambiarEstado]}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getUsuariosUnilever}
      />
    );
  }
}

export default Tabla;
