import gql from "graphql-tag";

export const getFormularioReconocimiento = gql`
  query getFormularioReconocimiento($idPrograma: Int!) {
    getFormularioReconocimiento(idPrograma: $idPrograma) {
      _id
      idPrograma
      url
      urlEdicion
      conSG
      puedeReconocerEquipos
      nivelAprobacion
      diasRecordatorio
      mailRecordatorio {
        asunto
        html
        bcc
      }
      otorgaPremio
      menurrhh
      matrizPremio {
        rangoInferior
        rangoSuperior
        valor
      }
      modoPremio
      otorgaPuntos
      menurrhh
      matrizPuntos {
        rangoInferior
        rangoSuperior
        valor
      }
      modoPuntos
      acreditacionAutomatica
      apruebaRecursosHumanos
      focoenreconocedor
      mailAprobacion {
        asunto
        bcc
        html
      }
      mailReconocimientoSinAprobacion {
        asunto
        html
      }
      mailReconocimientoRechazado {
        asunto
        bcc
        html
      }
      mailReconocimientoAprobado {
        asunto
        bcc
        html
      }
      mailReconocimientoEnEdicion {
        asunto
        bcc
        html
      }
      campoUsuarioPrincipal
      campoUsuarioSecundario
      valor {
        alias
        valores
        respuestaMultiple
        orden
      }
      leyendaValor
      tipo {
        alias
        valores
        respuestaMultiple
        orden
      }
      leyendaTipo
      redefineTipo {
        label
        conSG
        url
        apruebaRecursosHumanos
        focoenreconocedor
        nivelAprobacion
      }
      pregunta1 {
        alias
        seMuestra
        esRequerida
        orden
      }
      pregunta2 {
        alias
        seMuestra
        esRequerida
        orden
      }
      pregunta3 {
        alias
        seMuestra
        esRequerida
        orden
      }
      mensajeNominador {
        alias
        seMuestra
        esRequerida
        orden
      }
      conMensajeAprobadores
      conObservacion
      alturaIframe
    }
  }
`;

export const setFormularioReconocimiento = gql`
  mutation setFormularioReconocimiento(
    $formularioReconocimiento: FormularioReconocimientoInput!
  ) {
    setFormularioReconocimiento(
      formularioReconocimiento: $formularioReconocimiento
    ) {
      idPrograma
      url
      urlEdicion
      puedeReconocerEquipos
      nivelAprobacion
      diasRecordatorio
      mailRecordatorio {
        asunto
        html
      }
      otorgaPremio
      matrizPremio {
        rangoInferior
        rangoSuperior
        valor
      }
      otorgaPuntos
      menurrhh
      matrizPuntos {
        rangoInferior
        rangoSuperior
        valor
      }
      acreditacionAutomatica
      apruebaRecursosHumanos
      focoenreconocedor
      mailAprobacion {
        asunto
        html
      }
      mailReconocimientoSinAprobacion {
        asunto
        html
      }
      mailReconocimientoRechazado {
        asunto
        html
      }
      mailReconocimientoAprobado {
        asunto
        html
      }
      mailReconocimientoEnEdicion {
        asunto
        html
      }
    }
  }
`;

export const createReconocimiento = gql`
  mutation createReconocimiento($reconocimiento: ReconocimientoCreate!) {
    createReconocimiento(reconocimiento: $reconocimiento) {
      _id
    }
  }
`;

export const getPaginaReconocimientos = gql`
  query getMisReconocimientos(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: ReconocimientoColumn!
    $estado: ReconocimientoEstado
    $estadoDinamico: [ReconocimientoEstadoDinamico]
    $idUsuarioReconocedor: Int
    $idUsuariosReconocidos: Int
    $valor: String
    $tipo: String
    $nominaEquipos: NominaEquipoEnum
    $idPrograma: Int!
    $fechaInicioNominacion: Date
    $fechaFinNominacion: Date
    $fechaInicioAprobacion: Date
    $fechaFinAprobacion: Date
  ) {
    getMisReconocimientos(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      reconocimientoOrder: { column: $orderBy, order: $order }
      reconocimientoLike: {
        idPrograma: $idPrograma
        idUsuariosReconocidos: $idUsuariosReconocidos
        estado: $estado
        estadoDinamico: $estadoDinamico
        idUsuarioReconocedor: $idUsuarioReconocedor
        valor: $valor
        tipo: $tipo
        nominaEquipos: $nominaEquipos
      }
      reconocimientoRange: {
        fechaReconocimiento: {
          min: $fechaInicioNominacion
          max: $fechaFinNominacion
        }
        fechaAprobacion: {
          min: $fechaInicioAprobacion
          max: $fechaFinAprobacion
        }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        formulario {
          url
        }
        usuarioReconocedor {
          idUsuario
          username
          perfil {
            nombre
            apellido
          }
        }
        usuariosReconocidos {
          idUsuario
          username
          avatarUrl
          perfil {
            nombre
            apellido
            otros
          }
        }
        aprobaciones {
          orden
          jefe {
            idUsuario
            username
            perfil {
              nombre
              apellido
            }
          }
          esRRHH
          observacion
          mensaje
          motivo
          estado
          fechaUltimaModificacion
        }
        aprobacionesRRHH {
          idsRRHH
          idRol
          observacion
          mensaje
          motivo
          estado
          fechaUltimaModificacion
        }
        estado
        fechaReconocimiento
        fechaAprobacion
        valor
        tipo
        respuesta1
        respuesta2
        respuesta3
        mensaje
        respuestas {
          tipo
          pregunta
          respuesta
          respuestas
        }
      }
    }
  }
`;
export const getPaginaReconocimientosAprobados = gql`
  query getReconocimientosAprobados(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: ReconocimientoColumn!
    $idUsuarioReconocedor: Int
    $idUsuariosReconocidos: Int
    $valor: String
    $tipo: String
    $nominaEquipos: NominaEquipoEnum
    $idPrograma: Int!
    $fechaInicioNominacion: Date
    $fechaFinNominacion: Date
    $fechaInicioAprobacion: Date
    $fechaFinAprobacion: Date
  ) {
    getReconocimientosAprobados(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      reconocimientoOrder: { column: $orderBy, order: $order }
      reconocimientoLike: {
        idPrograma: $idPrograma
        idUsuariosReconocidos: $idUsuariosReconocidos
        estado: APROBADA
        idUsuarioReconocedor: $idUsuarioReconocedor
        valor: $valor
        tipo: $tipo
        nominaEquipos: $nominaEquipos
      }
      reconocimientoRange: {
        fechaReconocimiento: {
          min: $fechaInicioNominacion
          max: $fechaFinNominacion
        }
        fechaAprobacion: {
          min: $fechaInicioAprobacion
          max: $fechaFinAprobacion
        }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        formulario {
          url
        }
        usuarioReconocedor {
          username
          perfil {
            nombre
            apellido
          }
        }
        usuariosReconocidos {
          idUsuario
          username
          avatarUrl
          perfil {
            nombre
            apellido
            otros
          }
        }
        aprobaciones {
          orden
          jefe {
            idUsuario
            username
            perfil {
              nombre
              apellido
            }
          }
          observacion
          mensaje
          motivo
          estado
          fechaUltimaModificacion
        }
        aprobacionesRRHH {
          idsRRHH
          idRol
          observacion
          mensaje
          motivo
          estado
          fechaUltimaModificacion
        }
        estado
        fechaReconocimiento
        fechaAprobacion
        valor
        tipo
        respuesta1
        respuesta2
        respuesta3
        mensaje
        respuestas {
          tipo
          pregunta
          respuesta
          respuestas
        }
      }
    }
  }
`;

export const getReconocimientoById = gql`
  query getReconocimiento($_id: ID!) {
    getReconocimiento(_id: $_id) {
      _id
      formulario {
        url
      }
      usuarioReconocedor {
        username
        perfil {
          nombre
          apellido
        }
      }
      usuariosReconocidos {
        idUsuario
        username
        avatarUrl
        perfil {
          nombre
          apellido
          otros
        }
      }
      aprobaciones {
        orden
        jefe {
          idUsuario
          username
          perfil {
            nombre
            apellido
          }
        }
        esRRHH
        observacion
        mensaje
        motivo
        estado
        fechaUltimaModificacion
      }
      aprobacionesRRHH {
        idsRRHH
        idRol
        observacion
        mensaje
        motivo
        estado
        fechaUltimaModificacion
      }
      estado
      fechaReconocimiento
      fechaAprobacion
      valor
      tipo
      respuesta1
      respuesta2
      respuesta3
      mensaje
      respuestas {
        tipo
        pregunta
        respuesta
        respuestas
      }
    }
  }
`;
export const aprobarReconocimiento = gql`
  mutation aprobarReconocimiento(
    $idReconocimiento: ID!
    $mensaje: String
    $observacion: String
    $overridePremio: String
    $overridePuntos: Int
  ) {
    aprobarReconocimiento(
      aprobacionReconocimiento: {
        idReconocimiento: $idReconocimiento
        mensaje: $mensaje
        observacion: $observacion
        overridePremio: $overridePremio
        overridePuntos: $overridePuntos
      }
    ) {
      _id
      formulario {
        url
      }
      usuarioReconocedor {
        username
        perfil {
          nombre
          apellido
        }
      }
      usuariosReconocidos {
        idUsuario
        username
        avatarUrl
        perfil {
          nombre
          apellido
          otros
        }
      }
      aprobaciones {
        orden
        jefe {
          idUsuario
          username
          perfil {
            nombre
            apellido
          }
        }
        observacion
        mensaje
        motivo
      }
      aprobacionesRRHH {
        idsRRHH
        idRol
        observacion
        mensaje
        motivo
        estado
        fechaUltimaModificacion
      }
      estado
      fechaReconocimiento
      fechaAprobacion
      valor
      tipo
      respuesta1
      respuesta2
      respuesta3
      mensaje
      respuestas {
        tipo
        pregunta
        respuesta
        respuestas
      }
    }
  }
`;
export const rechazarReconocimiento = gql`
  mutation rechazarReconocimiento($idReconocimiento: ID!, $motivo: String!) {
    rechazarReconocimiento(
      rechazoReconocimiento: {
        idReconocimiento: $idReconocimiento
        motivo: $motivo
      }
    ) {
      _id
      formulario {
        url
      }
      usuarioReconocedor {
        username
        perfil {
          nombre
          apellido
        }
      }
      usuariosReconocidos {
        idUsuario
        username
        avatarUrl
        perfil {
          nombre
          apellido
          otros
        }
      }
      aprobaciones {
        orden
        jefe {
          idUsuario
          username
          perfil {
            nombre
            apellido
          }
        }
        observacion
        mensaje
        motivo
      }
      aprobacionesRRHH {
        idsRRHH
        idRol
        observacion
        mensaje
        motivo
        estado
        fechaUltimaModificacion
      }
      estado
      fechaReconocimiento
      fechaAprobacion
      valor
      tipo
      respuesta1
      respuesta2
      respuesta3
      mensaje
      respuestas {
        tipo
        pregunta
        respuesta
        respuestas
      }
    }
  }
`;

export const editarReconocimiento = gql`
  mutation editarReconocimiento($idReconocimiento: ID!, $motivo: String!) {
    editarReconocimiento(
      editarReconocimiento: {
        idReconocimiento: $idReconocimiento
        motivo: $motivo
      }
    ) {
      _id
      formulario {
        url
      }
      usuarioReconocedor {
        username
        perfil {
          nombre
          apellido
        }
      }
      usuariosReconocidos {
        idUsuario
        username
        avatarUrl
        perfil {
          nombre
          apellido
          otros
        }
      }
      aprobaciones {
        orden
        jefe {
          idUsuario
          username
          perfil {
            nombre
            apellido
          }
        }
        observacion
        mensaje
        motivo
      }
      aprobacionesRRHH {
        idsRRHH
        idRol
        observacion
        mensaje
        motivo
        estado
        fechaUltimaModificacion
      }
      estado
      fechaReconocimiento
      fechaAprobacion
      valor
      tipo
      respuesta1
      respuesta2
      respuesta3
      mensaje
      respuestas {
        tipo
        pregunta
        respuesta
        respuestas
      }
    }
  }
`;

export const getResumenReconocimientosAprobados = gql`
  query getResumenReconocimientosAprobados(
    $idUsuarioReconocedor: Int
    $idUsuariosReconocidos: Int
    $valor: String
    $tipo: String
    $nominaEquipos: NominaEquipoEnum
    $idPrograma: Int!
    $fechaInicioNominacion: Date
    $fechaFinNominacion: Date
    $fechaInicioAprobacion: Date
    $fechaFinAprobacion: Date
  ) {
    getResumenReconocimientosAprobados(
      reconocimientoLike: {
        idPrograma: $idPrograma
        idUsuariosReconocidos: $idUsuariosReconocidos
        estado: APROBADA
        idUsuarioReconocedor: $idUsuarioReconocedor
        valor: $valor
        tipo: $tipo
        nominaEquipos: $nominaEquipos
      }
      reconocimientoRange: {
        fechaReconocimiento: {
          min: $fechaInicioNominacion
          max: $fechaFinNominacion
        }
        fechaAprobacion: {
          min: $fechaInicioAprobacion
          max: $fechaFinAprobacion
        }
      }
    ) {
      total
      grupales
      individuales
      tipos {
        label
        cantidad
      }
    }
  }
`;

export default {
  queries: {
    getFormularioReconocimiento,
    getPaginaReconocimientos,
    getPaginaReconocimientosAprobados,
    getResumenReconocimientosAprobados,
    getReconocimientoById
  },
  mutations: {
    setFormularioReconocimiento,
    createReconocimiento,
    aprobarReconocimiento,
    rechazarReconocimiento,
    editarReconocimiento
  }
};
