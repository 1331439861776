import { GEOCODING_URL } from "js/components/Mapa/constants.js";
import get from "lodash/get";

export const getCurrentLocation = () =>
  new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(loc => resolve(loc.coords));
    } else {
      reject();
    }
  });

export const getCoordinates = address => {
  const addr = address.replace(" ", "+");
  return new Promise((resolve, reject) => {
    fetch(GEOCODING_URL + "address=" + addr)
      .then(res => res.json())
      .then(data => resolve(get(data, "results[0].geometry.location", null)))
      .catch(e => reject(e));
  });
};
