export default theme => ({
  root: {
    width: "100%"
  },
  filterHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  filtersContainer: {
    backgroundColor: "rgb(247,247,247)",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  cleanFiltersWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  cleanFiltersBtn: {
    marginLeft: 5
  },
  textPrimary: {
    textTransform: "none"
  }
});
