export default theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  button: {
    margin: "0 5px",
    minWidth: "10rem"
  },
  gridHistorico: {
    flex: "1",
    padding: "0 16px 0 16px",
    marginBottom: "20px"
  },
  historico: {
    flex: 1,
    boxShadow: "none",
    background: "none",
    padding: 0
  }
});
