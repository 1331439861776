import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { numberWithCommas } from "js/utils";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import ErrorList from "js/components/Shared/ErrorList";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { get } from "lodash";
import { TreeField } from "js/components/Shared/Form/Fields";
import { getPuntosProducto, getRentabilidadProducto } from "../utils";
import {
  CheckboxField,
  InputNumberField,
  InputNumberGtZeroField,
  ListField
} from "js/components/Shared/Form/Fields";
import { styles } from "js/pages/Admin/Programa/Catalogo/Edit/styles.js";
import Details from "js/pages/Admin/Programa/Catalogo/Edit/Details.js";

class Form extends PureComponent {
  onChangePais = selectedNodes => {
    this.props.onChangePais({ paisesSeleccionados: selectedNodes });
  };
  onChangeRubro = selectedNodes => {
    this.props.onChangeRubro({ rubrosSeleccionados: selectedNodes });
  };
  onChangeSegmento = selectedNodes => {
    this.props.onChangeSegmento({ segmentosSeleccionados: selectedNodes });
  };

  render() {
    const {
      handleSubmit,
      submitting,
      classes,
      history,
      errors,
      disabled,
      initialValues,
      paises,
      rubros,
      segmentos,
      porcentajeField,
      stockInfinitoField,
      idEleccionProducto,
      id,
      productoInfinito
    } = this.props;
    const puntos = getPuntosProducto(
      get(initialValues, "producto.precioSugerido"),
      get(initialValues, "programa.tasaConversion"),
      porcentajeField
    );
    const rentabilidad = getRentabilidadProducto(
      puntos,
      get(initialValues, "programa.tasaConversion"),
      get(initialValues, "producto.costo", 0)
    );
    return (
      <Fragment>
        <Grid className={classes.grid} container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {get(initialValues, "producto.nombre", "Producto")}

              <Tooltip title="Editar producto master">
                <IconButton
                  aria-label="Gestionar"
                  onClick={e =>
                    history.push(
                      `/admin/producto/${get(
                        initialValues,
                        "producto.idProducto",
                        undefined
                      )}/edit`
                    )
                  }
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>
        <form className={classes.container} onSubmit={handleSubmit}>
          <Grid className={classes.detailsBox} item xs={12} sm={8} md={9}>
            <Details values={initialValues} />
          </Grid>
          <Grid className={classes.fotoBox} item xs={12} sm={4} md={3}>
            <img
              alt=""
              width="100%"
              src={get(initialValues, "producto.imagenes[0].url", null)}
            />
          </Grid>
          <Grid container spacing={16} className={classes.grid}>
            <Grid item xs={12} sm={6} md={3}>
              <ListField
                name="estado"
                label="Estado"
                options={[
                  { label: "Activo", value: "ACTIVO" },
                  { label: "Inactivo", value: "INACTIVO" }
                ]}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <InputNumberField
                name="porcentaje"
                label="Ajuste (%)"
                isFloat
                disabled={disabled}
                decimalNumbers={5}
                required
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                Puntos
              </Typography>
              <Typography variant="h5" gutterBottom>
                {puntos ? numberWithCommas(puntos) : "--"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                Rent.
              </Typography>
              <Typography variant="h5" gutterBottom>
                {rentabilidad ? numberWithCommas(rentabilidad) + "%" : "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Stock del programa</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <CheckboxField
                name="stockInfinito"
                label="¿Es infinito?"
                //disabled={disabled || !productoInfinito}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <CheckboxField
                name="reposicionAutomatica"
                label="¿Reposicion automática de stock?"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <InputNumberField
                name="stockPorPrograma"
                label="Stock programa"
                type="int"
                disabled={disabled || stockInfinitoField}
                required={!stockInfinitoField}
              />
            </Grid>
            <Tooltip
              title="Cantidad máxima de unidades permitidas por usuario (no considera periodo de tiempo)."
              placement="top-start"
            >
              <Grid item xs={12} sm={6} md={2}>
                <InputNumberGtZeroField
                  name="stockPorUsuario"
                  label="Límite usuario"
                  type="int"
                  disabled={disabled}
                />
              </Grid>
            </Tooltip>
            <Tooltip
              title="Cantidad máxima de unidades permitidas por periodo de tiempo."
              placement="top-start"
            >
              <Grid item xs={12} sm={6} md={2}>
                <InputNumberGtZeroField
                  name="stockPorPeriodo"
                  label="Límite período"
                  type="int"
                  disabled={disabled}
                />
              </Grid>
            </Tooltip>
            <Grid item xs={12} sm={6} md={2}>
              <InputNumberGtZeroField
                name="periodo"
                label="Período (días)"
                type="int"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <InputNumberGtZeroField
                name="stockReposicion"
                label="Stock de reposición"
                type="int"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <InputNumberGtZeroField
                name="prioridad"
                label="Prioridad"
                type="int"
                disabled={disabled}
                required
              />
            </Grid>
          </Grid>

          <Grid className={classes.grid} container>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Visualización</Typography>
            </Grid>
            {segmentos && (
              <Grid xs={12} sm={6}>
                <TreeField
                  label="Segmentos"
                  data={segmentos}
                  disabled={disabled}
                  onChange={this.onChangeSegmento}
                  withTodos
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <ListField
                name="cucarda"
                label="Cucarda"
                options={[
                  { label: "Ninguna", value: null },
                  { label: "Tipo 1", value: "TIPO_1" },
                  { label: "Tipo 2", value: "TIPO_2" },
                  { label: "Tipo 3", value: "TIPO_3" },
                  { label: "Tipo 4", value: "TIPO_4" }
                ]}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid className={classes.grid} container>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Categorización</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Ubicación</Typography>
            </Grid>
            {rubros && (
              <Grid item xs={12} sm={6}>
                <TreeField
                  label="Categoría/Subcategoría"
                  data={rubros}
                  disabled={disabled}
                  onChange={this.onChangeRubro}
                />
              </Grid>
            )}
            {paises && (
              <Grid item xs={12} sm={6}>
                <TreeField
                  label="País/Zona/Localidad"
                  data={paises}
                  disabled={disabled}
                  onChange={this.onChangePais}
                />
              </Grid>
            )}
          </Grid>
          <ErrorList errors={errors} />
          <div className={classes.buttonSave}>
            <Grid className={classes.grid} container>
              <Grid item xs />
              <Grid item xs />
              <Grid className={classes.gridButton} item xs>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  className={classes.button}
                  onClick={() => history.push(`/admin/programa/${id}/catalogo`)}
                >
                  Cancelar
                </Button>
              </Grid>
              {AuthService.hasPerms([Roles.PRODUCTO_CATALOGO_W]) && (
                <Grid className={classes.gridButton} item xs>
                  {disabled && (
                    <Button
                      type="button"
                      variant="contained"
                      size="large"
                      color="primary"
                      className={classes.button}
                      onClick={() =>
                        history.push(
                          `/admin/programa/${id}/catalogo/producto/${get(
                            initialValues,
                            "producto.idProducto",
                            undefined
                          )}/eleccion/${idEleccionProducto}/edit`
                        )
                      }
                    >
                      Editar
                    </Button>
                  )}
                  {!disabled && (
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                      className={classes.button}
                      disabled={submitting}
                    >
                      Guardar
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          </div>
        </form>
      </Fragment>
    );
  }
}

Form = reduxForm({
  form: "formEleccionProducto",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formEleccionProducto");

Form = connect(state => ({
  porcentajeField: selector(state, "porcentaje"),
  stockInfinitoField: selector(state, "stockInfinito")
}))(Form);

export default withRouter(withStyles(styles)(Form));
