import React from "react";
import ViewIcon from "@material-ui/icons/Visibility";
import base from "./Base";
import AuthService from "js/utils/AuthService";

export default params => ({
  ...base(),
  label: "Ver solicitud",
  icon: <ViewIcon />,
  tooltip: "Ver solicitud",
  show: () => AuthService.hasPerms(params.perms),
  onclick: () => {
    const url = "/mi-solicitud/" + params.itemId;
    params.history.push(url);
  }
});
