import React, { PureComponent } from "react";
import { Query } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import AppQraphQL from "js/graphql/resolvers/idioma.resolver";
import { ListofChildrenField } from "./index.js";

import { Select } from "redux-form-material-ui";
import { styles } from "js/components/Shared/Form/styles.js";
import { required } from "js/components/Shared/Form/validations.js";

class IdiomaPublic extends PureComponent {
  render() {
    const { classes, disabled } = this.props;
    return (
      <Query
        query={AppQraphQL.queries.getIdiomas}
        fetchPolicy="network-only"
        errorPolicy="all"
      >
        {({ loading, error, data }) => {
          if (loading || !data) return null;
          return (
            <ListofChildrenField
              name="idIdioma"
              label="Idioma del programa"
              component={Select}
              className={classes ? classes.field : styles.field}
              disabled={disabled}
              required={required}
            >
              {data.getIdiomas.itemsPagina.map((i, index) => (
                <MenuItem key={index} value={i.nombre}>
                  {i.nombre}
                </MenuItem>
              ))}
            </ListofChildrenField>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(withStyles(styles)(IdiomaPublic));
