export default theme => ({
  root: {
    flexGrow: 1
  },
  emptymessage: {
    fontSize: "30px",
    margin: "100px 0 0 0",
    display: "flex",
    flex: 1,
    justifyContent: "center"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  }
});
