import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Enhancer from "./container";
import { GeneralWrapper } from "js/pages/TemplateBuilder2/Layouts";
import { SortableComponent } from "js/pages/TemplateBuilder2/Controls";
import Paper from "@material-ui/core/Paper";
import newItems from "js/pages/TemplateBuilder2/Components/NewItems";
import Fila from "js/pages/TemplateBuilder2/Components/Fila";
import { get } from "lodash";

const { newFila } = newItems;

class LayoutBuilder extends React.Component {
  render = () => {
    const {
      onChangeLanding,
      filas = [],
      onGetTemplate,
      infoPrograma,
      onChangeFilas,
      onChangeFilaTipo,
      idPrograma,
      conCancelar
    } = this.props;
    const idPrograma1 = get(
      this.props.match,
      "params.id",
      this.props.programa.idPrograma
    );
    return (
      <GeneralWrapper
        title={`Landing del programa ${infoPrograma.nombre}`}
        subtitle="Este es el editor del layout del programa"
        onGetTemplate={onGetTemplate}
        idPrograma={idPrograma}
        conCancelar={conCancelar}
      >
        <Paper>
          <Grid item xs={12}>
            <SortableComponent
              items={filas}
              newItem={newFila}
              onChange={onChangeFilas}
              component={Fila}
              isCollapsable={true}
              position={[]}
              metadata={{
                onChange: onChangeLanding,
                onChangeFilaTipo: onChangeFilaTipo,
                idPrograma: idPrograma1
              }}
            />
          </Grid>
        </Paper>
      </GeneralWrapper>
    );
  };
}

LayoutBuilder.propTypes = {
  classes: PropTypes.object,
  onGetTemplate: PropTypes.func.isRequired
};

LayoutBuilder.displayName = "LayoutBuilder";

export default Enhancer(LayoutBuilder);
