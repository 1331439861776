import { red } from "@material-ui/core/colors";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

const mediaStyle = {
  height: 0,
  paddingTop: "56.25%",
  borderBottom: "1px solid #ccc",
  position: "relative"
};

export default theme => ({
  increaseDecreaseBtnContainer: {
    border: "1px solid rgb(227,227,227)",
    borderRadius: 4,
    textAlign: "center"
  },
  increaseDecreaseBtn: {
    width: "100%",
    height: 34,
    border: "none",
    background: "rgb(237,237,237)",
    fontWeight: "bold",
    fontSize: 18
  },
  counter: {
    width: "90%",
    textAlign: "center",
    "-moz-appearance": "textfield",
    border: "none"
  },
  gridCard: {
    display: "flex",
    width: "100%",
    paddingLeft: "24px",
    paddingRight: "24px"
  },
  points: {
    fontWeight: 600,
    textTransform: "uppercase"
  }
});
