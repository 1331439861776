export default theme => ({
  textoTitulo: {
    // color: theme.palette.getContrastText(theme.palette.secondary.main),
    color: "#FFF",
    textAlign: "center",
    padding: "20px",
    fontSize: "xx-large"
  },
  fondoTitulo: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: "40px"
  },
  usuarioField: {
    backgroundColor: "#FFF",
    borderRadius: "5px",
    padding: "4px 10px",
    maxHeight: "28px"
  },
  multiField: {
    backgroundColor: "#FFF",
    borderRadius: "5px",
    padding: "4px 10px"
  },
  label: {
    color: theme.palette.primary.main
  },
  formControl: {
    padding: "4px 0px",
    width: "100%",
    marginTop: "15px",
    // "& div": {
    "& input": {
      padding: "8px 10px"
    }
    // }
  },
  valueContainer: {
    padding: "4px 11px",
    maxHeight: "28px"
  },
  multiValueContainer: {
    padding: "4px 11px"
  },
  containerBotones: {
    backgroundColor: theme.palette.primary.main + "40",
    padding: "25px"
  },
  buttonLabel: {
    color: "#FFF"
  }
});
