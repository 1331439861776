import React from "react";
import Tabla from "js/components/TablaUsuario";

export const Usuario = props => {
  return (
    <div>
      <Tabla />
    </div>
  );
};

export default Usuario;
