import get from "lodash/get";

export default theme => {
  const avatarDiameter = get(
    theme,
    "programa.template.header.user.avatar.diameter",
    40
  );
  return {
    userInfo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    avatar: {
      margin: 10,
      width: avatarDiameter,
      height: avatarDiameter
    },
    puntosValue: {
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        display: "none"
      },
      textTransform: "initial"
    },
    puntos: {
      padding: "0 10px",
      borderRight: `1px solid`,
      wordSpacing: 5,
      [theme.breakpoints.down("xs")]: {
        display: "none"
      },
      textTransform: "initial"
    },
    pointsWrapper: {
      marginRight: 5,
      [theme.breakpoints.up("sm")]: {
        display: "inline-flex"
      },
      [theme.breakpoints.down("xs")]: {
        maxHeight: "1rem",
        maxWidth: "12rem"
      }
    },
    nombre: {
      padding: "0 10px",
      textTransform: "initial"
    },
    menuButton: {
      display: "block"
    },
    loginHref: {
      "&:hover": {
        textDecoration: "none"
      }
    },
    login: {
      marginLeft: theme.spacing.unit,
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.quinary.main
      }
    },
    misMovimientos: {
      textDecoration: "none",
      color: theme.palette.primary.main,

      "&:hover": {
        textDecoration: "none"
      },

      "&:focus": {
        textDecoration: "none"
      }
    }
  };
};
