const styles = theme => ({
  buttonSave: {
    flex: 1,
    display: "flex",
    alignItems: "end",
    justifyContent: "flex-end",
    margin: "20px 0 20px 0"
  }
});
export default styles;
