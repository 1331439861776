import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import CommonLayout from "./CommonLayout";
import withPrograma from "js/hoc/withPrograma";
import styles from "./styles";

const enhance = compose(
  withPrograma,
  withStyles(styles)
);

export default enhance(CommonLayout);
