import React from "react";
import PropTypes from "prop-types";
import { TemplateContext } from "js/template-context";

const withPrograma = Component => props => (
  <TemplateContext.Consumer>
    {programa => <Component programa={programa} {...props} />}
  </TemplateContext.Consumer>
);

withPrograma.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withPrograma;
