import React, { Fragment } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withNotificationContext } from "js/notification-context";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Slide
} from "@material-ui/core";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DeleteDialog extends React.Component {
  sendValues = async () => {
    const { client, mutation, itemId, itemName } = this.props;

    this.setState({ error: undefined });
    await client
      .mutate({
        mutation: mutation,
        variables: {
          id: itemId
        }
      })
      .then(res => {
        this.props.openNotification(itemName + " eliminado correctamente");
        this.props.onTableChange();
        this.props.handleClose();
      })
      .catch(error => {
        this.props.openNotification("Hubo un error al eliminar el " + itemName);
      });
  };

  render() {
    const { handleClose, classes, dialogTitle } = this.props;
    return (
      <Fragment>
        <Dialog
          open
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={classes.dialogTitle}
          >
            {dialogTitle}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Cancelar
            </Button>
            <Button
              onClick={this.sendValues}
              variant="contained"
              color="primary"
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const enhance = compose(
  withApollo,
  withNotificationContext,
  withRouter,
  withStyles(styles)
);

export default enhance(DeleteDialog);
