import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { DatePicker } from "material-ui-pickers";
import moment from "moment";
import Grid from "@material-ui/core/Grid";

class advancedFilter extends PureComponent {
  state = { data: {} };
  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  render() {
    const { value, submitValue, classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaDesde"}
              name={"fechaDesde"}
              value={value["fechaDesde"] ? moment(value["fechaDesde"]) : null}
              label="Fecha desde"
              autoOk
              onChange={v =>
                submitValue({
                  target: { name: "fechaDesde", value: v.toISOString() }
                })
              }
              format={"DD/MM/YYYY"}
              maxDate={moment()}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaHasta"}
              name={"fechaHasta"}
              value={value["fechaHasta"] ? moment(value["fechaHasta"]) : null}
              label="Fecha hasta"
              autoOk
              onChange={v =>
                submitValue({
                  target: { name: "fechaHasta", value: v.toISOString() }
                })
              }
              format={"DD/MM/YYYY"}
              maxDate={moment()}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"from"}
              name={"from"}
              label={"De"}
              type="search"
              value={value["from"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"to"}
              name={"to"}
              label={"Para"}
              type="search"
              value={value["to"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"cc"}
              name={"cc"}
              label={"CC"}
              type="search"
              value={value["cc"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"cco"}
              name={"cco"}
              label={"CCO"}
              type="search"
              value={value["cco"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"subject"}
              name={"subject"}
              label={"Asunto"}
              type="search"
              value={value["subject"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
