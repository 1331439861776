import React from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube";
import { youtube_parser } from "js/utils";

import defaultStyles from "./styles";

const Video = props => {
  const opts = {
    height: "100%",
    width: "325",
    playerVars: {
      autoplay: 1
    }
  };

  return (
    <YouTube
      videoId={youtube_parser(props.contenido.link)}
      opts={opts}
      onReady={this._onReady}
    />
  );
};

Video.propTypes = {
  classes: PropTypes.object
};

Video.defaultProps = {
  classes: defaultStyles
};

Video.displayName = "video";

export default Video;
