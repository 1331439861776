import circle from "./circle";
import circleAlt from "./circleAlt";
import common from "./common";
import commonAlt from "./commonAlt";
import long from "./long";

export default {
  circle,
  circleAlt,
  common,
  commonAlt,
  long
};
