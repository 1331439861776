import React from "react";
import PropTypes from "prop-types";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import defaultStyles from "./styles";

import Navbar from "../Navbar";
import UserInfo from "../UserInfo";

import { Link } from "react-router-dom";
import { getUrl } from "js/utils";
import { css, cx } from "emotion";

class HeaderBelow extends React.Component {
  state = {
    drawerOpen: false,
    auth: false,
    anchorEl: null,
    anchor: "left"
  };

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  handleChangeAnchor = event => {
    this.setState({
      anchor: event.target.value
    });
  };

  render() {
    const { programa, classes } = this.props;
    const { template } = programa;

    return (
      <AppBar
        position="static"
        className={classes.appbar}
        style={template.header.css}
      >
        <div className={classes.toolbarContainer}>
          <Toolbar
            className={cx(classes.toolbar, css(template.header.menu.toolbar))}
          >
            <span>
              <Link to="/" underline={"none"}>
                <img
                  src={`${getUrl(template.header.logoPrincipal.url)}`}
                  alt="Logo cabecera"
                  className={classes.logo}
                />
              </Link>
            </span>
            <UserInfo />
          </Toolbar>
        </div>
        <Navbar />
      </AppBar>
    );
  }
}

HeaderBelow.propTypes = {
  authenticated: PropTypes.bool
};

HeaderBelow.defaultProps = {
  authenticated: false,
  styles: defaultStyles,
  programa: {
    template: {
      header: {
        logoPrincipal: { url: "" }
      }
    }
  }
};

export default HeaderBelow;
