import { compose, pure, withHandlers, withState, withProps } from "recompose";
import { set } from "lodash";
import { CHANGE_LANDING } from "js/redux/template/constants.js";
import { connect } from "react-redux";
import { updateTemplateBuilder } from "js/redux/template";
import { getTemplateBuilderSection } from "js/redux/template/selectors.js";
import get from "lodash/get";
import newItems from "js/pages/TemplateBuilder2/Components/NewItems";
const { newBase, newSlider } = newItems;

const mapStateToProps = state => ({
  landing: getTemplateBuilderSection(state, "landing")
});

const mapDispatchToProps = dispatch => ({
  changeLanding: updateTemplateBuilder({
    dispatch,
    type: CHANGE_LANDING
  })
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  pure,
  withState("stateUpdaterValue", "updateState", 0),
  withHandlers({
    onChangeFilas: ({ landing, changeLanding, updateState }) => value => {
      const payload = set(landing, "filas", get(value, "target.value", value));
      changeLanding(payload);
      updateState();
    },
    onChangeLanding: ({
      landing,
      changeLanding,
      updateState
    }) => key => value => {
      let payload = {};
      if (key.split(".").pop() === "tipo") {
        let arr = key.split(".");
        arr.pop();
        arr.unshift("filas");
        const contenido = get(landing, arr, {});
        if (value === "slider" && !get(contenido, "bases", []).length)
          payload = set(landing, arr, { ...newSlider, ...contenido });
        else if (value === "base")
          payload = set(landing, arr, { ...newBase, ...contenido });
      }
      payload = set(
        landing,
        "filas." + "" + key, //eslint-disable-line
        get(value, "target.value", value)
      );
      changeLanding(payload);
      updateState();
    },
    onChangeFilaTipo: ({ landing, changeLanding, updateState }) => (
      position,
      key
    ) => value => {
      let contenidos = get(landing, `filas.${position.join(".")}.contenidos`);
      if (parseInt(value) - 1 < contenidos.length)
        contenidos.splice(parseInt(value));
      else
        while (contenidos.length < parseInt(value))
          contenidos.push({ ...newBase });
      let payload = set(
        landing,
        "filas." + "" + key, //eslint-disable-line
        get(value, "target.value", value)
      );
      payload = set(
        landing,
        `filas.${position.join(".")}.contenidos`,
        contenidos
      );
      changeLanding(payload);
      updateState();
    }
  }),
  withProps(props => ({
    filas: get(props, "landing.filas", []).map((f, i) => ({
      ...f,
      indexFila: i,
      collapsableTitle: `Fila ${i + 1}`
    }))
  }))
);

export default enhance;
