import { lighten } from "@material-ui/core/styles/colorManipulator";

export default theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    minHeight: 0
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          height: 70
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
          height: 70
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex"
  },
  title: {
    flex: "0 0 auto"
  },
  action: {
    margin: "0 10px"
  },
  dialogTitle: {},
  dialogContent: {},
  selected: {
    marginLeft: 5
  },
  selectedWrapper: {
    display: "inline-flex"
  },
  selectedTooltip: {
    cursor: "pointer"
  }
});
