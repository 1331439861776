import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserQraphQL from "js/graphql/resolvers/usuario.resolver";
import client from "js/App/client.graphql.js";

import AppQraphQL from "js/graphql/resolvers/performance.resolver";
import Collapse from "js/components/Shared/Collapsable";
import { _t } from "js/utils/TranslationService";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import SplashScreen from "js/pages/SplashScreen";
import PerformanceFilters from "./components/filters";
import CampanaPanel from "./components/campanaPanel";

class Performance extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expandedFilters: false,
      idsUsuarios: [],
      filters: {},
      performanceCampanas: [],
      infoPagina: {},
      isMiPerformance: AuthService.hasPerms([Roles.MI_INFORME_PERFORMANCE_R]),
      isPerformance: AuthService.hasPerms([Roles.INFORME_PERFORMANCE_R]),
      idPrograma: parseInt(window.localStorage.getItem("idPrograma")),
      showSplash: true,
      offset: 0,
      showProgress: false,
      page: 1,
      otros: {}
    };
  }

  async componentDidMount() {
    this.getOtros();
    const { client } = this.props;
    const { isPerformance } = this.state;

    if (isPerformance) {
      await client
        .query({
          query: AppQraphQL.queries.getUsuariosDistribuidora,
          fetchPolicy: "network-only",
          variables: {}
        })
        .then(res => {
          if (
            res.data.getUsuariosDistribuidora &&
            res.data.getUsuariosDistribuidora.length !== 0
          ) {
            const idsUsuarios = res.data.getUsuariosDistribuidora.map(
              usuario => usuario.idUsuario
            );
            this.setState({ idsUsuarios });
          }
        });
    }
  }

  async fetchPerformance(filters) {
    const { client } = this.props;
    const { isPerformance, idPrograma, performanceCampanas, page } = this.state;

    await client
      .query({
        query: isPerformance
          ? AppQraphQL.queries.getInformePerformance
          : AppQraphQL.queries.getMiInformePerformance,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: idPrograma,
          idCampana: filters.idCampana,
          idUsuario: filters.idUsuario,
          estado: filters.estado,
          fechaInicioDesde: filters.fechaInicio,
          fechaInicioHasta: filters.fechaInicioHasta,
          page: page,
          rowsPerPage: 6
        }
      })
      .then(res => {
        const performances = isPerformance
          ? res.data.getInformePerformance.itemsPagina
          : res.data.getMiInformePerformance.itemsPagina;

        const infoPagina = isPerformance
          ? res.data.getInformePerformance.infoPagina
          : res.data.getMiInformePerformance.infoPagina;

        let performanceUpdated = [];
        if (performances.length === 0) {
          performanceUpdated = performances;
        } else {
          performanceUpdated = performanceCampanas.concat(performances);
        }

        this.setState({
          performanceCampanas: performanceUpdated,
          infoPagina: infoPagina,
          showSplash: false,
          showProgress: false
        });
      });
  }

  submitValue = e => {
    const obj = {};
    if (e.target) {
      obj[e.target.name] = e.target.value;
    } else if (e.value) {
      obj.idUsuario = e.value;
    }

    this.setState(({ filters }) => ({
      filters: { ...filters, ...obj }
    }));
  };

  clearFilters = e => {
    this.setState({ filters: {} });
  };

  search = () => {
    const { filters } = this.state;
    this.setState(
      {
        showSplash: true,
        expandedFilters: false,
        page: 1,
        performanceCampanas: []
      },
      () => {
        this.fetchPerformance(filters);
      }
    );
  };

  handleFiltersCollapse = () => {
    this.setState(prevState => ({
      expandedFilters: !prevState.expandedFilters
    }));
  };

  handleClick(offset) {
    this.setState({ offset });
  }

  handleSeeMore = () => {
    const { filters, page } = this.state;
    this.setState({ showProgress: true, page: page + 1 }, () => {
      this.fetchPerformance(filters);
    });
  };

  getOtros = () => {
    client
      .query({
        query: UserQraphQL.queries.getMiUsuarioCompleto,
        fetchPolicy: "cache-first"
      })
      .then(({ data }) => {
        const otros = data.miUsuario.perfil.otros
          ? JSON.parse(data.miUsuario.perfil.otros)
          : {};
        this.setState({ otros });
      });
  };

  render() {
    const {
      padding,
      backgroundColor,
      fullWidth,
      marginTop,
      classes
    } = this.props;
    const paddingPx = padding ? padding + "px" : "20px";
    const marginTopPx = marginTop === undefined ? "20px" : marginTop + "px";
    let style = {
      padding: paddingPx,
      paddingLeft: "30px",
      backgroundColor: backgroundColor,
      maxWidth: fullWidth ? "100%" : "1000px",
      marginTop: marginTopPx,
      marginBottom: "30px"
    };

    const {
      performanceCampanas,
      infoPagina,
      expandedFilters,
      idsUsuarios,
      filters,
      isMiPerformance,
      isPerformance,
      showSplash,
      idPrograma,
      showProgress,
      otros
    } = this.state;

    return (
      <Fragment>
        <Grid
          style={{
            padding: "0px",
            maxWidth: "1000px",
            marginTop: "20px",
            marginBottom: "30px"
          }}
          sm={12}
          md={12}
          xl={12}
          xs={12}
        >
          <Grid item sm={12} md={12} xl={12} xs={12} spacing={16}>
            <Card raised={false}>
              <Grid
                alignItems={"space-between"}
                sm={12}
                md={12}
                xl={12}
                xs={12}
                spacing={16}
              >
                <Typography
                  variant="subtitle2"
                  color={"black"}
                  style={{ fontSize: "16px", margin: "20px", float: "left" }}
                  className={classes.titulosInferiores}
                >
                  {" "}
                  {otros &&
                    `Cód. Distribuidora: ${otros.código_de_distribuidora}`}
                </Typography>

                <Typography
                  variant="subtitle2"
                  color={"black"}
                  style={{ fontSize: "16px", margin: "20px", float: "right" }}
                  className={classes.titulosInferiores}
                >
                  {" "}
                  {otros && `Cód. Vendedor: ${otros.código_de_vendedor} `}
                </Typography>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Collapse
          search={this.search.bind(this)}
          clear={this.clearFilters.bind(this)}
          expanded={expandedFilters}
          onChange={this.handleFiltersCollapse}
          title="Filtros"
          style={{ maxWidth: "1000px" }}
        >
          <PerformanceFilters
            value={filters}
            submitValue={this.submitValue.bind(this)}
            showUserField={isMiPerformance ? false : true}
            idsUsuarios={idsUsuarios}
            idPrograma={idPrograma}
          />
        </Collapse>
        {showSplash && <SplashScreen />}
        {/* Cards de Performance por campaña */}
        {!showSplash &&
          performanceCampanas &&
          performanceCampanas.length === 0 && (
            <Grid style={style} container spacing={16}>
              <Grid className={classes.container} item xs={12}>
                <p className={classes.campanaDescripcion}>
                  {_t("No existen campañas con los filtros seleccionados.")}
                </p>
              </Grid>
            </Grid>
          )}
        {!showSplash &&
          performanceCampanas &&
          performanceCampanas.length !== 0 && (
            <Fragment>
              {performanceCampanas.map((performanceCampana, index) => {
                return (
                  <CampanaPanel
                    key={`campana-${index}`}
                    classes={classes}
                    performanceCampana={performanceCampana}
                    index={index}
                    isMiPerformance={isMiPerformance}
                    isPerformance={isPerformance}
                    idPrograma={idPrograma}
                    style={style}
                  />
                );
              })}
              {performanceCampanas.length < infoPagina.total && (
                <Grid className={classes.seeMoreContainer} item xs={12}>
                  <Button
                    type="button"
                    variant="outlined"
                    size="large"
                    color="primary"
                    style={{ width: "250px", marginBottom: "20px" }}
                    onClick={this.handleSeeMore}
                  >
                    {showProgress && (
                      <CircularProgress
                        size={20}
                        thickness={4}
                        color="primary"
                        variant="indeterminate"
                      />
                    )}
                    {!showProgress && _t("Ver más campañas")}
                  </Button>
                </Grid>
              )}
            </Fragment>
          )}
      </Fragment>
    );
  }
}

Performance.propTypes = {
  classes: PropTypes.object
};

Performance.defaultProps = {};

Performance.displayName = "Performance";

export default Performance;
