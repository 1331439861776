import left from "./left.svg";
import right from "./right.svg";
import up from "./up.svg";
import down from "./down.svg";

export default {
  left,
  right,
  up,
  down
};
