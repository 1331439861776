import React from "react";
import PropTypes from "prop-types";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { getField } from "./utils";
import { capitalizeFirstLetter } from "../../../utils/Helper";
import { lowerCase } from "lodash";
import { _t } from "js/utils/TranslationService";
import { numberWithCommas } from "js/utils";
import TextField from "@material-ui/core/TextField/TextField";
import Grid from "@material-ui/core/Grid";

class DialogCanje extends React.PureComponent {
  render() {
    const {
      classes,
      cantidad,
      producto,
      datosRequeridos,
      puntos,
      productoActions,
      requeridos,
      // mensajeRequeridos,
      // canjeFail,
      sucursales
    } = this.props;
    return (
      <div>
        <CardContent>
          <Typography variant="h6" component="h1">
            {capitalizeFirstLetter(producto.nombre)}
          </Typography>
          <Typography variant="h6" color="primary" className={classes.points}>
            {numberWithCommas(puntos)} {_t("Puntos").toUpperCase()}
          </Typography>
          <Typography variant="subtitle1" component="h2">
            {_t("Estás por canjear")}
            <strong>
              {" "}
              {numberWithCommas(cantidad)} {producto.nombre}{" "}
            </strong>
            {lowerCase(_t("Utilizando"))}
            <strong>
              {" "}
              {numberWithCommas(cantidad * puntos)} {_t("Puntos").toLowerCase()}
            </strong>
          </Typography>
          <Typography component="p" variant="subtitle1" gutterBottom>
            {capitalizeFirstLetter(producto.indicacionesEntrega)}
          </Typography>

          <Grid container spacing={16}>
            {datosRequeridos.length > 0 &&
              datosRequeridos.map(({ key = "sucursal", nombre = "Sucursal" }) =>
                getField({
                  key,
                  productoActions,
                  requeridos,
                  nombre: _t(nombre),
                  classes,
                  sucursales
                })
              )}
            {producto.tipoProducto === "FISICO" && (
              <Grid item xs={12}>
                <TextField
                  id={"observaciones"}
                  type="text"
                  key={"observaciones"}
                  label={"Observaciones"}
                  className={classes.textField}
                  value={requeridos["observaciones"]}
                  onChange={productoActions.changeRequerido("observaciones")}
                  margin="normal"
                />
              </Grid>
            )}
          </Grid>

          {/* {mensajeRequeridos && (
            <Typography gutterBottom variant="caption" color="error">
              {_t(mensajeRequeridos)}
            </Typography>
          )}
          {canjeFail && (
            <Typography gutterBottom variant="caption" color="error">
              {_t(canjeFail)}
            </Typography>
          )} */}
        </CardContent>
      </div>
    );
  }
}

DialogCanje.propTypes = {
  classes: PropTypes.object.isRequired
};

export default DialogCanje;
