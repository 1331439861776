import React from "react";
import PropTypes from "prop-types";
import Webcam from "react-webcam";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import { TextField } from "@material-ui/core";
import ProductosField from "./Productos";
import { removeTypename } from "../../utils/Helper";
import { addImageURL } from "js/constants";
import { getUrl } from "js/utils";
import { _t } from "js/utils/TranslationService";
import FacturaPorPuntosGraphQL from "js/graphql/resolvers/cargaFacturaPorPuntos.resolver";
import ParametrosFacturaPorPuntos from "js/graphql/resolvers/parametrosCargaFacturaPorPuntos.resolver";
import { USER_ROLES as Roles } from "js/models/User";
import AuthService from "js/utils/AuthService";
import Modal from "./modal";
import SettingsIcon from "@material-ui/icons/Settings";
import { Tooltip, CircularProgress } from "@material-ui/core";
import SplashScreen from "js/pages/Loading";
import Typography from "@material-ui/core/Typography";
import { getSize, FACING_MODE_ENVIRONMENT } from "./utils";
import IconButton from "@material-ui/core/IconButton";
import ErrorList from "js/components/Shared/ErrorList";
import Chip from "@material-ui/core/Chip";
import {
  fetchParametrosVisuales,
  getBanners,
  cleanText
} from "js/utils/helpers/FacturaPorPuntos.helpers";
import Fila from "js/components/Fila";
import Dialog from "@material-ui/core/Dialog";
import _ from "lodash";
import ProductoQraphQL from "js/graphql/resolvers/producto.resolver";
import CatalogoQraphQL from "js/graphql/resolvers/catalogo.resolver";
import { inicioTratamientoFields, modelResolver } from "./types";
import { ProgressBar, DialogDatosRequeridos } from "./components";
import { ContinuousColorLegend } from "react-vis";

const videoConstraints = {
  width: { min: 480 },
  height: { min: 720 },
  aspectRatio: 1.5,
  facingMode: FACING_MODE_ENVIRONMENT
};

class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.cam = React.createRef();
    this.state = {
      fileImage: null,
      fileName: "",
      imagen: [],
      isPdf: false,
      disabled: false,
      puntoVenta: "",
      numeroFactura: "",
      fechaFactura: moment().toISOString(),
      productos: [],
      detalle: {},
      detalleSelected: {},
      selectedAutoComplete: null,
      openModal: false,
      imagenModal: [],
      errors: null,
      selectedProducto: null,
      selectedProductos: [],
      parametrosFacturaPorPuntos: [],
      isLoadingImage: false,
      inBackOffice:
        window.location.pathname.indexOf("admin") !== -1 ? true : false,
      banners: {},
      showCampoUsuarios: false,
      inicioTratamiento: false,
      eleccionesProducto: [],
      isLoading: true,
      datosRequeridosFields: [],
      datosRequeridosValues: [],
      datosRequeridosCompleted: true,
      openDialog: false,
      productoSelected: "",
      disableSubmitButton: false,
      disableBtnEnviar: false
    };
    const { initialFilter = {} } = props;
    let dateFactura = { ...initialFilter };
    this.handleCapture = this.handleCapture.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openModalFunction = this.openModalFunction.bind(this);
    this.getUrlFacturaImagen = this.getUrlFacturaImagen.bind(this);
    this.getFileName = this.getFileName.bind(this);
    this.takeImage = this.takeImage.bind(this);
    this.submitValue = this.submitValue.bind(this);
    this.submitImage = this.submitImage.bind(this);
    this.handleChangeProductosField = this.handleChangeProductosField.bind(
      this
    );
    this.handleSelectedAutoComplete = this.handleSelectedAutoComplete.bind(
      this
    );
  }

  componentDidMount = async () => {
    const {
      client,
      match: {
        params: { id }
      }
    } = this.props;

    const { parametrosVisuales, banners, detalle } = this.state;
    const idPrograma =
      parseInt(id) || parseInt(localStorage.getItem("idPrograma"));

    const { data } = await client.query({
      query:
        ParametrosFacturaPorPuntos.queries.getParametrosFacturaPorPuntosById,
      variables: {
        idPrograma: parseInt(id) || parseInt(localStorage.getItem("idPrograma"))
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache"
    });

    let eleccionesProducto = [];

    if (data) {
      let { getParametrosFacturaPorPuntos: formFields } = data;
      let puntoVentaIndex = formFields.findIndex(
        field => field.key === "puntoDeVenta"
      );
      let puntoVentaField = formFields[puntoVentaIndex];

      let cargaTerceros = formFields.find(
        field => field.key === "cargaTerceros"
      );

      let autocompleteByModelFields = formFields.filter(
        field => field.tipo === "Lista_Autocomplete_By_Model"
      );

      const promises = autocompleteByModelFields.map(async field => {
        const { value } = field;
        const queryParams = modelResolver
          .find(model => model.value === value[0])
          .graphql(idPrograma);

        if (AuthService.hasPerms([Roles.PUNTO_VENTA_R])) {
          const res = await client.query({
            query: queryParams.query,
            fetchPolicy: "network-only",
            variables: queryParams.variables
          });

          const {
            data: {
              getPuntosDeVenta: { itemsPagina }
            }
          } = res;

          field.suggestions = itemsPagina
            .filter(item => item.estado === "ACTIVO")
            .map(item => ({
              value: item.idPuntoDeVenta,
              label: item.nombre
            }));
          field.options = itemsPagina
            .filter(item => item.estado === "ACTIVO")
            .map(item => ({
              idPuntoDeVenta: item.idPuntoDeVenta,
              nombre: item.nombre,
              multiplicador: item.multiplicador,
              estado: item.estado
            }));
        } else {
          field.suggestions = [];
        }
      });

      await Promise.all(promises);

      cargaTerceros = cargaTerceros ? !!+cargaTerceros.value : cargaTerceros;

      const hasPerms = AuthService.hasPerms([Roles.FACTURAS_POR_PUNTOS_CT]);

      if (puntoVentaField) {
        let { value } = puntoVentaField;
        if (value.length === 1 && value[0] === "") {
          formFields.splice(puntoVentaIndex, 1);
        }
      }

      let inicioTratamiento = formFields.find(
        field => field.key === "inicioTratamiento"
      );

      inicioTratamiento = inicioTratamiento
        ? !!+inicioTratamiento.value
        : inicioTratamiento;

      if (inicioTratamiento) {
        let res = await client.query({
          query: ProductoQraphQL.queries.getEleccionProductos,
          fetchPolicy: "network-only",
          variables: { eleccionProductoLike: { idPrograma } }
        });

        if (res) {
          eleccionesProducto = res.data.getEleccionProductos.itemsPagina;

          let suggestions = eleccionesProducto.map(eleccion => {
            let { producto } = eleccion;
            return producto.nombre;
          });

          inicioTratamientoFields.forEach(field => {
            if (field.key === "productos") {
              field.value = suggestions;
            }

            if (
              !data.getParametrosFacturaPorPuntos.some(
                item => item.key === field.key
              )
            ) {
              data.getParametrosFacturaPorPuntos.push(field);
            }
          });
        }
      }

      data.getParametrosFacturaPorPuntos = [...formFields];

      const fetchParamsVisuales = fetchParametrosVisuales;

      const dataParametrosVisuales = await fetchParamsVisuales(
        id || localStorage.getItem("idPrograma"),
        client
      );

      let banners = {};

      if (dataParametrosVisuales && dataParametrosVisuales.banners) {
        let getBannersIU = getBanners;
        banners = getBannersIU(dataParametrosVisuales.banners, true);

        if (banners) {
          banners.contenidos = cleanText(banners);
        }
      }

      this.setState({
        parametrosFacturaPorPuntos: data
          ? data.getParametrosFacturaPorPuntos.filter(field => {
              field.fieldName = field.key;
              return !inicioTratamiento || field.key !== "importe";
            })
          : [],
        banners: banners ? banners : {},
        showCampoUsuarios: hasPerms && cargaTerceros,
        inicioTratamiento,
        eleccionesProducto,
        isLoading: false
      });
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const {
      inicioTratamiento,
      detalleSelected,
      eleccionesProducto,
      productoSelected
    } = this.state;

    if (
      inicioTratamiento &&
      detalleSelected.productos &&
      detalleSelected.productos !== productoSelected
    ) {
      const { productos: productoName } = detalleSelected;

      let eleccion = eleccionesProducto.filter(({ producto }) => {
        return producto.nombre === productoName;
      });

      if (eleccion.length > 0) {
        try {
          const datosRequeridosFields = await this.fetchDatosRequeridos(
            eleccion[0].idEleccionProducto
          );

          let datosRequeridosValues = {};

          if (datosRequeridosFields.length > 0) {
            datosRequeridosValues = datosRequeridosFields.reduce(
              (acc, dato) => {
                acc[dato.key] = null;
                return acc;
              },
              {}
            );
          }

          if (datosRequeridosFields.length > 0) {
            this.setState({
              datosRequeridosFields,
              datosRequeridosValues,
              datosRequeridosCompleted: false,
              productoSelected: productoName
            });
          }
        } catch (error) {
          this.setState({
            productoSelected: productoName,
            disableSubmitButton: true
          });

          let errorMessage = "Ocurrió un error: ";
          let errorDetail = null;

          if (error.message) {
            errorDetail = error.message
              .replace("GraphQL error: ", "")
              .toLowerCase();
          }
          errorMessage = errorDetail
            ? errorMessage + errorDetail
            : errorMessage;
          this.props.openNotification(errorMessage);
        }
      }
    }
  };

  updateDetalle = newDetalle => {
    this.setState({
      detalle: newDetalle
    });
  };

  submitValueDate = e => {
    this.setState(({}) => ({
      fechaFactura: e.target.value
    }));
  };

  handleProductSelect = event => {
    this.setState({ selectedProducto: event.target.value });
  };

  handleChange = key => event => {
    this.setState({ [key]: event.target.value });
  };

  handleCapture = value => {
    this.setState({
      isCapture: value
    });
  };

  handleDeleteFileImage = () => {
    this.setState({
      fileImage: null,
      fileName: "",
      imagen: [],
      imagenModal: [],
      disabled: false
    });
  };

  handleCleanImage = () => {
    this.setState({ fileImage: null });
  };

  handleDisabled = () => {
    this.setState({ disabled: true });
  };

  handleDeleteProducto = value => {
    const arr = this.state.selectedProductos;
    const objWithIdIndex = arr.findIndex(obj => obj.value === value);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }

    this.setState({ selectedProductos: arr });
  };

  handleSubProducto = index => {
    const arr = this.state.selectedProductos;
    if (arr[index].cantidad === 1) return;
    arr[index].cantidad--;
    this.setState({ selectedProductos: arr });
  };

  handleAddProducto = index => {
    const arr = this.state.selectedProductos;
    arr[index].cantidad++;
    this.setState({ selectedProductos: arr });
  };

  openModalFunction = value => {
    this.setState({
      openModal: value,
      disabled: false
    });
  };

  getUrlFacturaImagen = value => {
    this.setState({ imagenModal: value });
  };

  getFileName = value => {
    this.setState({
      fileName: value
    });
  };

  handleSelectedAutoComplete = selected => {
    if (!selected) return;

    const { detalleSelected } = this.state;
    const key = Object.keys(selected)[0] ? Object.keys(selected)[0] : null;

    if (key) {
      const value = selected[key];
      detalleSelected[key] = value;
      this.setState(detalleSelected);
    }
  };

  handleChangeProductosField = value => {
    const { detalle } = this.state;
    const detalleUpdate = { ...value };
    this.setState({ detalle: detalleUpdate });
  };

  takeImage = async () => {
    const imagenCam = this.cam.current.getScreenshot();
    this.setState({
      fileImage: imagenCam
    });
    const mensaje = "Imagen capturada correctamente.";
    return this.props.openNotification(mensaje);
  };

  async submitImage() {
    if (!this.state.fileImage) throw Error("No hay imagen para cargar.");

    let file = null;

    await fetch(this.state.fileImage)
      .then(res => res.blob())
      .then(blob => {
        file = new File([blob], "Factura por puntos", { type: "image/jpg" });
      });

    const { height, width } = { height: 2048, width: 1536 }; //await getSize(file);
    const formData = new window.FormData();
    formData.append("image", file);
    formData.append("context", "facturaPorPuntos");
    formData.append("height", height);
    formData.append("width", width);

    this.setState({ isLoadingImage: true });
    await fetch(addImageURL, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(({ url }) => {
        this.setState(({ imagen }) => ({
          imagen: imagen.concat(getUrl(url)),
          isLoadingImage: false
        }));
      })
      .then(() => {
        const mensaje = "Imagen guardada correctamente.";
        this.props.openNotification(mensaje);
      })
      .catch(() => console.error("Hubo un error al cargar la imagen a S3"));
  }
  updateAutocompleteQueryParams = key => async select => {
    const arr = this.state.selectedProductos;
    // Chequeamos que no se encuentre agregado
    if (arr.findIndex(p => p.value === select[0].value) > -1) return;
    const value = select.map(option => option);
    let puntos = await this.getPuntosProductoFacturaPorPuntos(select[0].value);
    let obj = [...this.state.selectedProductos];
    obj.push({ cantidad: 1, puntos: puntos, ...value[0] });
    return this.setState({ selectedProductos: obj });
  };

  fetchDatosRequeridos = async idEleccionProducto => {
    const { client } = this.props;

    try {
      const isLogged = !!AuthService.getIdUsuario();
      const queryCatalogo = isLogged
        ? CatalogoQraphQL.queries.getCatalogo
        : CatalogoQraphQL.queries.getCatalogoPublico;

      let { data } = await client.query({
        query: queryCatalogo,
        fetchPolicy: "network-only",
        variables: {
          catalogoLike: {
            idEleccionProducto
          },
          showDetails: true,
          pagina: { cantidadPorPagina: 1, numeroPagina: 1 }
        }
      });

      const eleccionProducto = isLogged
        ? data.getCatalogo.itemsPagina[0]
        : data.getCatalogoPublico.itemsPagina[0];

      let {
        producto: { datosRequeridos }
      } = eleccionProducto;

      return datosRequeridos;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  handleOpenDialog = () => {
    this.setState({
      openDialog: true
    });
  };

  handleCloseDialog = value => {
    this.setState({ openDialog: false });
  };

  handleChangeDatoRequerido = key => event => {
    const { datosRequeridosValues } = this.state;

    const { value } = event.target;
    this.setState({
      datosRequeridosValues: {
        ...datosRequeridosValues,
        [key]: value
      }
    });
  };

  setDatosRequeridosCompleted = () => {
    this.setState({ datosRequeridosCompleted: true });
    return this.props.openNotification("Datos requeridos completos");
  };

  handleChangeBtnEnviarStatus = () => {
    this.setState(prevState => ({
      disableBtnEnviar: !prevState.disableBtnEnviar
    }));
  };

  async submitValue() {
    const {
      puntoVenta,
      numeroFactura,
      fechaFactura,
      detalle,
      detalleSelected,
      selectedProductos,
      imagenModal,
      inBackOffice,
      imagen,
      parametrosFacturaPorPuntos,
      showCampoUsuarios,
      eleccionesProducto,
      inicioTratamiento,
      datosRequeridosValues,
      setDatosRequeridosCompleted
    } = this.state;

    const {
      match: {
        params: { id }
      },
      client,
      history
    } = this.props;

    let tasaDeAcreditacion = parametrosFacturaPorPuntos.find(
      field => field.key === "tasaDeAcreditacion"
    );

    let limitePorFactura = parametrosFacturaPorPuntos.find(
      field => field.key === "limitePorFactura"
    );

    let { value: requiereComprobante } = parametrosFacturaPorPuntos.find(
      field => field.key === "requiereComprobante"
    );

    let { value: requiereModeracion } = parametrosFacturaPorPuntos.find(
      field => field.key === "requiereModeracion"
    );

    let regexNroFactura = parametrosFacturaPorPuntos.find(
      field => field.key === "regexNroFactura"
    );

    limitePorFactura = parseInt(limitePorFactura.value[0]);

    tasaDeAcreditacion = parseFloat(tasaDeAcreditacion.value[0]);

    requiereComprobante = !!+requiereComprobante;

    requiereModeracion = !!+requiereModeracion;

    regexNroFactura = regexNroFactura.value[0];

    let paramsAutocompleteByModel = parametrosFacturaPorPuntos.filter(
      param => param.tipo === "Lista_Autocomplete_By_Model"
    );

    Object.entries(detalleSelected).forEach(([key, value = null]) => {
      let param = paramsAutocompleteByModel.find(param => param.key === key);
      if (!_.isNil(param)) {
        let selectedValue = param.options.find(
          option => option["idPuntoDeVenta"] === value
        );
        detalleSelected[key] = {
          id: selectedValue.idPuntoDeVenta,
          label: param.label,
          name: selectedValue.nombre,
          multiplicador: selectedValue.multiplicador,
          estado: selectedValue.estado
        };
      }
    });

    let details = { ...detalle, ...detalleSelected };

    if (!details) {
      return this.props.openNotification(
        "Debe completar los campos obligatorios."
      );
    } else {
      //Eliminamos los campos de configuración
      delete details["tasaDeAcreditacion"];
      delete details["limiteDiario"];
      delete details["limitePorFactura"];
      delete details["limiteMensual"];
      delete details["incrementos"];
      delete details["requiereComprobante"];
      delete details["requiereModeracion"];
      delete details["cargaTerceros"];
      delete details["regexNroFactura"];
      delete details["inicioTratamiento"];

      for (const field in details) {
        if (!details[field]) {
          return this.props.openNotification(
            "Debe completar los campos obligatorios."
          );
        }
      }
    }

    if (details.productos) {
      let eleccion = eleccionesProducto.filter(({ producto }) => {
        return producto.nombre === details.productos;
      });

      if (eleccion) {
        let { producto } = eleccion[0];
        details.inicioTratamiento = true;
        details.producto = {
          idEleccionProducto: eleccion[0].idEleccionProducto,
          idProducto: producto.idProducto,
          nombre: producto.nombre,
          datosRequeridos: JSON.stringify(datosRequeridosValues)
        };
      }
    }

    //En caso de inicio de tratamiento el campo importe se encuentra oculto.
    details.importe = inicioTratamiento
      ? 0
      : parseFloat(details.importe).toFixed(2);

    details.puntosFactura = Math.ceil(details.importe * tasaDeAcreditacion);

    details.puntosFactura = isNaN(limitePorFactura)
      ? details.puntosFactura
      : Math.min(details.puntosFactura, limitePorFactura);

    if (numeroFactura === "" || fechaFactura === "" || !selectedProductos) {
      return this.props.openNotification(
        "Debe completar los campos obligatorios."
      );
    }

    if (regexNroFactura !== "") {
      let regex = new RegExp(regexNroFactura);

      if (!regex.test(numeroFactura)) {
        return this.props.openNotification(
          _t("El formato del N° de Factura es incorrecto.")
        );
      }
    }

    let facturaUrl = "";
    this.handleChangeBtnEnviarStatus();

    if (requiereComprobante) {
      try {
        // No se se cargó la imagen desde el modal, intentamos obtener desde la captura de la cámara
        if (!imagen[0] && !this.state.imagenModal[0]) await this.submitImage();
        facturaUrl = !this.state.imagen[0]
          ? imagenModal[0]
          : this.state.imagen[0];
      } catch (error) {
        this.handleChangeBtnEnviarStatus();
        return this.props.openNotification(error.toString());
      }
    }

    let variables = {};

    const transformProducts = () => {
      return selectedProductos.map(prod => {
        return {
          codigo: prod.value,
          nombre: prod.label,
          cantidad: prod.cantidad,
          puntos: prod.puntos
        };
      });
    };

    let usuarioId = localStorage.getItem("idUsuario");

    if (showCampoUsuarios) {
      usuarioId = details.idUsuario;
      delete details["idUsuario"];
    }

    variables.idPrograma =
      parseInt(id) || parseInt(localStorage.getItem("idPrograma"));
    variables.idUsuario = parseInt(usuarioId) || usuarioId;
    variables.puntoVenta = puntoVenta;
    variables.numeroFactura = numeroFactura;
    variables.fechaFactura = fechaFactura;
    variables.detalle = JSON.stringify(details);
    variables.productos = transformProducts();
    // variables.facturaUrl = !this.state.imagen[0]
    //   ? imagenModal[0]
    //   : this.state.imagen[0];
    variables.facturaUrl = facturaUrl;

    variables.estado = "PENDIENTE_APROBACION";

    if (!requiereModeracion) {
      variables.estado = "APROBADA";
      variables.tipoDocumento = _t("Factura");
      variables.modificada = true;
      variables.motivo = "Moderación automática";
    }

    await client
      .mutate({
        mutation: FacturaPorPuntosGraphQL.mutations.createFacturaPoPuntos,
        variables: {
          facturaPorPuntos: { ...removeTypename(variables) }
        }
      })
      .then(res => {
        const mensaje = _t("Factura enviada");
        this.props.openNotification(mensaje);
        if (!inBackOffice) {
          history.push(`/moderacion-factura-por-puntos`);
        } else {
          history.push(`/admin/programa/${id}/moderacion-factura-por-puntos`);
        }
      })
      .catch(errors => {
        if (
          errors.toString().includes("error al enviar el mail") ||
          errors.toString().includes("ya existe registrada")
        ) {
          if (errors.toString().includes("error al enviar el mail")) {
            this.props.openNotification("Error al enviar el mail");
          } else if (errors.toString().includes("ya existe registrada")) {
            this.props.openNotification(_t("Factura ya registrada"));
          }

          if (!inBackOffice) {
            history.push(`/factura-por-puntos`);
          } else {
            history.push(`/admin/programa/${id}/factura-por-puntos`);
          }
        } else {
          this.setState({ errors: errors });
          return false;
        }
      })
      .finally(() => {
        this.handleChangeBtnEnviarStatus();
      });
  }

  handleKey = e => {
    if (e.keyCode === 13) this.search();
  };

  configBOParams = id => {
    if (this.props.match.url.includes("admin")) {
      return (
        <Tooltip title="Parámetros Factura Por Puntos">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(
                `/admin/programa/${parseInt(id)}/parametros-factura-por-puntos`
              )
            }
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };
  render() {
    const {
      classes,
      match: {
        params: { id }
      },
      search
    } = this.props;
    const {
      isCapture,
      disabled,
      openModal,
      parametrosFacturaPorPuntos,
      parametrosVisuales,
      inBackOffice,
      banners,
      inicioTratamiento,
      isLoading,
      datosRequeridosFields,
      datosRequeridosValues,
      datosRequeridosCompleted,
      disableSubmitButton,
      disableBtnEnviar,
      openDialog
    } = this.state;

    const btnsNextTitle = [];
    btnsNextTitle.push({
      title: _t("Carga de factura"),
      btn: this.configBOParams(parseInt(id)),
      perms: [Roles.FACTURAS_POR_PUNTOS_W],
      titleTranslation: false
    });

    return isLoading ? (
      <ProgressBar />
    ) : (
      <>
        {banners &&
          banners.visible &&
          !inBackOffice && (
            <div style={{ marginBottom: "1.5%" }}>
              <Fila {...banners} baseLink={"Banners Performance"} />
            </div>
          )}
        {btnsNextTitle.map((btnNextTitle, i) => {
          if (AuthService.hasPerms(btnNextTitle.perms)) {
            return (
              <React.Fragment key={i}>
                <Typography gutterBottom variant="h5">
                  {typeof btnNextTitle.titleTranslation !== "undefined" &&
                  !btnNextTitle.titleTranslation
                    ? btnNextTitle.title
                    : _t(btnNextTitle.title) || _t("Listado")}
                  {btnNextTitle.btn}
                </Typography>
              </React.Fragment>
            );
          }
        })}
        {parametrosFacturaPorPuntos.length > 0 && (
          <>
            <Grid container spacing={16} className={classes.gridCard}>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    required={true}
                    id={"numeroFactura"}
                    name={"numeroFactura"}
                    label={_t("Nro de factura")}
                    value={this.state.numeroFactura}
                    onChange={this.handleChange("numeroFactura")}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <DatePicker
                    key={"fechaFactura"}
                    name={"fechaFactura"}
                    label={_t("Fecha de factura")}
                    input={{
                      onChange: date =>
                        this.submitValueDate({
                          target: {
                            name: "fechaFactura",
                            value: date.toISOString()
                          }
                        }),
                      value: moment(this.state.fechaFactura)
                    }}
                    format={"DD/MM/YYYY"}
                    autoOk={true}
                    startOfDate
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.formControl}>
                  <ProductosField
                    parametrosFacturaPorPuntos={parametrosFacturaPorPuntos}
                    handleChange={this.handleChangeProductosField}
                    selectedAutoComplete={this.handleSelectedAutoComplete}
                    showCampoUsuarios={this.state.showCampoUsuarios}
                    inicioTratamiento={inicioTratamiento}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {isCapture ? (
              <>
                {!this.state.fileImage ? (
                  <>
                    <Dialog
                      open={isCapture && !this.state.fileImage}
                      PaperProps={{ className: classes.dialogContent }}
                    >
                      <Webcam
                        className={classes.webcam}
                        audio={false}
                        ref={this.cam}
                        screenshotFormat="imagen/jpeg"
                        screenshotQuality={1}
                        forceScreenshotSourceSize={true}
                        videoConstraints={videoConstraints}
                      />
                      <div
                        className={`${classes.CaptureCointainer} ${
                          classes.mobileFixCaptureCointainer
                        }`}
                        style={{ paddingBottom: "1%", marginTop: "1%" }}
                      >
                        <div
                          className={classes.buttonCaptureChild}
                          style={{ justifyContent: "start" }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.setState({ isCapture: false })}
                            style={{ marginRight: "1%" }}
                            className={classes.buttonCapture}
                          >
                            {_t("Cancelar")}
                          </Button>
                          <Button
                            onClick={this.takeImage}
                            variant="contained"
                            color="primary"
                            className={classes.buttonCapture}
                          >
                            {_t("Captura")}
                          </Button>
                        </div>
                        <div
                          className={classes.buttonCaptureChild}
                          style={{ justifyContent: "end" }}
                        >
                          <FormControl className={classes.buttonSubmit}>
                            <Button
                              type="submit"
                              onClick={this.submitValue}
                              variant="contained"
                              color="primary"
                              disabled={true}
                              className={classes.buttonSubmit}
                              style={{ height: "100%" }}
                            >
                              Enviar
                            </Button>
                          </FormControl>
                        </div>
                      </div>
                    </Dialog>
                  </>
                ) : (
                  <>
                    <img
                      style={{
                        width: "100%",
                        padding: "10px",
                        marginBottom: "1%"
                      }}
                      src={this.state.fileImage}
                    />

                    <div
                      className={`${classes.CaptureCointainer} ${
                        classes.mobileFixRetryContainer
                      }`}
                    >
                      <div
                        className={classes.buttonCaptureChild}
                        style={{ justifyContent: "start" }}
                      >
                        <Button
                          onClick={() => this.handleCleanImage()}
                          variant="outlined"
                          color="primary"
                          className={classes.buttonSubmit}
                        >
                          {_t("Volver a Intentar")}
                        </Button>
                      </div>
                      <div
                        className={classes.buttonCaptureChild}
                        style={{ justifyContent: "end" }}
                      >
                        <FormControl className={classes.buttonSubmit}>
                          <Button
                            type="submit"
                            onClick={this.submitValue}
                            variant="contained"
                            color="primary"
                            disabled={this.state.disableBtnEnviar}
                            style={{ height: "100%" }}
                            className={classes.buttonSubmit}
                          >
                            {this.state.disableBtnEnviar ? (
                              <CircularProgress size={20} />
                            ) : (
                              "Enviar"
                            )}
                          </Button>
                        </FormControl>
                      </div>
                    </div>
                  </>
                )}
                <CardActions className={classes.actionContainer}>
                  <ErrorList errors={this.state.errors} belowInput />
                </CardActions>
              </>
            ) : (
              <>
                <div className={classes.ChipContainer}>
                  {!isCapture &&
                    !openModal &&
                    this.state.imagenModal[0] && (
                      <Chip
                        label={this.state.fileName}
                        deleteIcon
                        color="black"
                        onDelete={this.handleDeleteFileImage}
                        variant="outlined"
                      />
                    )}
                  {openModal ? (
                    <Modal
                      disabled={disabled}
                      openModalFunction={this.openModalFunction}
                      getUrlFacturaImagen={this.getUrlFacturaImagen}
                      getFileName={this.getFileName}
                      handleDisabled={this.handleDisabled}
                    />
                  ) : null}
                </div>
                <Grid container className={classes.gridCard}>
                  <Grid container item xs={12} md={6} spacing={8}>
                    <Grid item xs={6} md={4}>
                      <Button
                        disabled={this.state.isCapture}
                        onClick={() => this.openModalFunction(true)}
                        variant="outlined"
                        color="primary"
                        className={classes.actionButton}
                      >
                        {_t("Subir imagen")}
                      </Button>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Button
                        disabled={disabled}
                        onClick={() => this.handleCapture(true)}
                        variant="outlined"
                        color="primary"
                        className={classes.actionButton}
                      >
                        {_t("Capturar Imagen")}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} md={6} justify="flex-end">
                    {this.state.isLoadingImage && (
                      <Grid container item style={{ height: 100 }}>
                        <SplashScreen className={classes.progress} />
                      </Grid>
                    )}

                    <Grid item xs={12} md={4} padding={0}>
                      <Button
                        type="submit"
                        onClick={
                          datosRequeridosCompleted
                            ? this.submitValue
                            : this.handleOpenDialog
                        }
                        variant="contained"
                        color="primary"
                        className={classes.actionButton}
                        disabled={disableBtnEnviar}
                      >
                        {disableBtnEnviar ? (
                          <CircularProgress size={20} />
                        ) : (
                          "Enviar"
                        )}
                      </Button>
                    </Grid>
                    <DialogDatosRequeridos
                      datosRequeridosFields={datosRequeridosFields}
                      datosRequeridosValues={datosRequeridosValues}
                      handleChangeDatoRequerido={this.handleChangeDatoRequerido}
                      onClose={this.handleCloseDialog}
                      openDialog={openDialog}
                      setDatosRequeridosCompleted={
                        this.setDatosRequeridosCompleted
                      }
                    />
                  </Grid>
                </Grid>
                <CardActions className={classes.actionContainer}>
                  <ErrorList errors={this.state.errors} belowInput />
                </CardActions>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

MyForm.propTypes = {
  classes: PropTypes.object.isRequired,
  submitValue: PropTypes.func
};

export default MyForm;
