import React from "react";
import TablaRanking from "js/pages/Admin/Accion/Detalle/Ranking";
const RankingAccion = props => {
  console.log("props", props);
  return (
    <TablaRanking
      idPrograma={props.programa.idPrograma}
      conFiltro={true}
      idAccion={props.idAccion}
    />
  );
};

export default RankingAccion;
