import {
  compose,
  withHandlers,
  pure,
  withStateHandlers,
  setDisplayName
} from "recompose";
import { reduxForm, formValueSelector } from "redux-form";
import { graphql } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNotificationContext } from "js/notification-context";
import ProfileQraphQL from "js/graphql/resolvers/perfil.resolver";
import withPrograma from "js/hoc/withPrograma";
import withStyles from "./styles";
import {
  formValidations,
  repeated
} from "js/components/Shared/Form/validations.js";

const selector = formValueSelector("updatePasswordForm");

const mapStateToProps = state => ({
  newPassword: selector(state, "newPassword")
});

export default compose(
  pure,
  withPrograma,
  withNotificationContext,
  withStyles,
  withRouter,
  setDisplayName("UpdatePassword"),
  withStateHandlers(
    {
      showPassword: false
    },
    {
      handleClickShowPassword: ({ showPassword }) => ({
        showPassword: !showPassword
      })
    }
  ),
  graphql(ProfileQraphQL.mutations.updateMiPassword, {
    name: "updateMiPassword"
  }),
  connect(mapStateToProps),
  // connect((_, { initialValues }) => ({ initialValues })),
  withHandlers({
    onSubmit: ({
      openNotification,
      history,
      currentPassword,
      newPassword,
      updateMiPassword
    }) => ({ newPassword, currentPassword }) => {
      updateMiPassword({
        variables: {
          usuario: {
            currentPassword,
            newPassword
          }
        },
        refetchQueries: [`getRedirect`],
        awaitRefetchQueries: true
      })
        .then(data => {
          openNotification("La clave se ha actualizado");
          history.push("/");
        })
        .catch(e => {
          const message = e.message.replace("GraphQL error:", "").trim();
          openNotification(message);
        });
    }
  }),

  reduxForm({
    form: "updatePasswordForm",
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    validate: formValidations([repeated])
  })
);
