import FilterService from "js/utils/FilterService";
const FILTER_CATALOGO = "UI_CATALOGO_BENEFICIOS";
const initialSearchCriteria = (fromFilter = false, searchQuery = {}) => {
  const initialFilter = {
    searchBy: undefined,
    favoritos: false,
    beneficioOrder: [
      {
        column: "nombre",
        order: "asc"
      }
    ],
    filterByCategories: new Map([
      ["rubros", new Set()],
      ["subrubros", new Set()],
      ["zonas", new Set()],
      ["localidades", new Set()]
    ]),
    ...searchQuery
  };
  if (!fromFilter) return initialFilter;
  const lastFilter = getLastFilter();
  return { ...initialFilter, ...lastFilter };
};

const getLastFilter = () => {
  let filter = FilterService.getFilter(FILTER_CATALOGO) || {};
  filter.filterByCategories = filter.filterByCategories.map(f => [
    f.key,
    new Set(f.value)
  ]);
  filter.filterByCategories = new Map(filter.filterByCategories);
  return filter;
};

const updateSearchCriteria = (searchCriteria = {}) => {
  let { filterByCategories } = searchCriteria;
  filterByCategories = [...filterByCategories];
  filterByCategories = filterByCategories.map(f => ({
    key: f[0],
    value: [...f[1]]
  }));
  const filter = {
    ...searchCriteria,
    filterByCategories
  };
  FilterService.setFilter(FILTER_CATALOGO, filter);
};

const updateFilterByCategories = (filterByCategories, key, id, children) => {
  if (key && key.includes("rubro")) {
    filterByCategories.set("rubros", new Set([])).set("subrubros", new Set([]));
    if (children) {
      const idsSubrubros = children.map(item => item.id);
      filterByCategories.set("subrubros", new Set(idsSubrubros));
    }
  }
  if (key && (key === "zonas" || key === "localidades")) {
    filterByCategories
      .set("zonas", new Set([]))
      .set("localidades", new Set([]));
    if (children) {
      const idsLocalidades = children.map(item => item.id);
      filterByCategories.set("localidades", new Set(idsLocalidades));
    }
  }

  console.log(filterByCategories);
  return key !== undefined
    ? filterByCategories.set(key, new Set([id]))
    : filterByCategories.clear();
};

const getSelectedCategory = map => {
  const categories = {};
  map.forEach(
    (value, key) => (categories[key] = value.size > 0 ? [...value] : undefined)
  );
  return categories;
};

const setStateCatalogoOrder = beneficioOrder => state => {
  return {
    pagina: {
      ...state.pagina,
      numeroPagina: 1
    },
    searchCriteria: { ...state.searchCriteria, beneficioOrder }
  };
};

const setStateFilterByCategories = ({ key, id, children }) => state => ({
  pagina: {
    ...state.pagina,
    numeroPagina: 1
  },
  searchCriteria: {
    ...state.searchCriteria,
    favoritos: false,
    filterByCategory: updateFilterByCategories(
      state.searchCriteria.filterByCategories,
      key,
      id,
      children
    )
  }
});

const getQueryObj = (
  programa,
  idSegmento,
  searchCriteria,
  pagina,
  isPublic
) => ({
  page: pagina.numeroPagina,
  rowsPerPage: pagina.cantidadPorPagina,
  order: searchCriteria.beneficioOrder[0].order,
  orderBy: searchCriteria.beneficioOrder[0].column,
  beneficioLike: {
    idPrograma: programa.idPrograma,
    segmentos: idSegmento,
    favoritos: searchCriteria.favoritos,
    nombre: searchCriteria.searchBy,
    copete: searchCriteria.searchBy,
    ...getSelectedCategory(searchCriteria.filterByCategories)
  }
});

export default {
  initialSearchCriteria,
  updateSearchCriteria,
  setStateCatalogoOrder,
  setStateFilterByCategories,
  getQueryObj
};
