import { compose, mapProps, defaultProps } from "recompose";

import withStyles from "./styles";
import withPrograma from "js/hoc/withPrograma";

const Enhancer = compose(
  withPrograma,
  withStyles,
  mapProps(({ programa, ...rest }) => ({
    cucardas: programa.template.templateData.cucardas,
    ...rest
  })),
  defaultProps({
    cucardas: []
  })
);

export default Enhancer;
