import { connect } from "react-redux";
import Dialog from "./Dialog";
import beneficioActions from "../actions";

const mapStateToProps = ({ beneficio }) => ({
  idBeneficio: beneficio.beneficio.idBeneficio,
  cantidad: beneficio.cantidad,
  openSolicitudPopup: beneficio.openSolicitudPopup,
  solicitudFail: beneficio.solicitudFail,
  solicitando: beneficio.solicitando
});

const mapDispatchToProps = dispatch => ({
  beneficioActions: beneficioActions({ dispatch })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dialog);
