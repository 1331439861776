export default class FilterService {
  static setFilter(filterName, filter) {
    window.localStorage.setItem(filterName, JSON.stringify(filter));
  }

  static getFilter(filterName) {
    const filter = window.localStorage.getItem(filterName);
    return filter !== "undefined" ? JSON.parse(filter) : null;
  }
}
