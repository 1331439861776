import React, { PureComponent } from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Collapsable from "js/components/Shared/Collapsable";
import { _t } from "js/utils/TranslationService";
import PropTypes from "prop-types";

const vars = [
  {
    title: "Usuario",
    values: [["usuario.username"]]
  },
  {
    title: "Acción",
    values: [["accion.nombre", "accion.url", "accion.rankingUrl"]]
  },
  {
    title: "Programa",
    values: [["programa.nombre", "programa.dominio"]]
  }
];

const copyToClipBoard = str => {
  const el = document.createElement("textarea");
  el.value = "{{ " + str + " }}";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const getContent = variables => {
  return variables.map(group => (
    <Grid item xs={12} sm={6} key={group.title} container>
      <Grid item xs={12}>
        <h4>{group.title}</h4>
      </Grid>
      {getItems(group.values)}
    </Grid>
  ));
};

const getItems = values => {
  return (
    <Grid xs={12} item>
      {values.map((subgroup, i) => (
        <Grid xs={12} item key={i}>
          {subgroup.map(value => (
            <Grid
              xs={12}
              item
              onClick={e => copyToClipBoard(value)}
              style={{ fontSize: "0.85em", cursor: "copy" }}
              key={value}
            >
              {"{{ " + value + " }}"}
            </Grid>
          ))}
          <br />
        </Grid>
      ))}
    </Grid>
  );
};

class Variables extends PureComponent {
  state = { vars: [] };

  componentDidUpdate = async prevProps => {
    const { definicionesPerfil } = this.props;
    if (prevProps.definicionesPerfil === definicionesPerfil) return;
    const profile = {
      title: "Datos del perfil",
      values: [(definicionesPerfil || []).map(p => `perfil.${p.value}`)]
    };
    const variables = Object.assign([], vars);
    if (profile) variables.splice(1, 0, profile);
    this.setState({ vars: variables });
  };
  render() {
    return (
      <React.Fragment>
        <Grid item xs={12} container>
          <Collapsable withActions={false} title={_t("Glosario")}>
            <Grid item xs={12} container spacing={16}>
              {getContent(this.state.vars)}
            </Grid>
          </Collapsable>
        </Grid>
      </React.Fragment>
    );
  }
}
Variables.propTypes = {
  definicionesPerfil: PropTypes.any
};

export default withRouter(Variables);
