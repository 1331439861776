import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { Log } = Fragmentos;

export const getLogsImportacion = gql`
  query getLogsImportacion(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: LogImportacionColumn!
    $idPrograma: Int
    $estado: String
    $concepto: String
    $fechaDesde: Date
    $fechaHasta: Date
  ) {
    getLogsImportacion(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      logOrder: { column: $orderBy, order: $order }
      logLike: { idPrograma: $idPrograma, estado: $estado, concepto: $concepto }
      logRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
    ) {
      infoPagina {
        numeroPagina
        total
        cantidadPaginas
      }
      itemsPagina {
        ...log
      }
    }
  }
  ${Log.fragments.log}
`;

export const getLogsImportacionById = gql`
  query getLogsImportacion($idLog: ID) {
    getLogsImportacion(logLike: { _id: $idLog }) {
      itemsPagina {
        ...logSolo
      }
    }
  }
  ${Log.fragments.logSolo}
`;

export const getMisLogsImportacion = gql`
  query getMisLogsImportacion(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: LogImportacionColumn!
    $idPrograma: Int
    $estado: String
    $concepto: String
    $fechaDesde: Date
    $fechaHasta: Date
  ) {
    getMisLogsImportacion(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      logOrder: { column: $orderBy, order: $order }
      logLike: { idPrograma: $idPrograma, estado: $estado, concepto: $concepto }
      logRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
    ) {
      infoPagina {
        numeroPagina
        total
        cantidadPaginas
      }
      itemsPagina {
        ...miLog
      }
    }
  }
  ${Log.fragments.miLog}
`;

export const getMisLogsImportacionById = gql`
  query getMisLogsImportacion($idLog: ID) {
    getMisLogsImportacion(logLike: { _id: $idLog }) {
      itemsPagina {
        ...miLogSolo
      }
    }
  }
  ${Log.fragments.miLogSolo}
`;

export default {
  queries: {
    getLogsImportacion,
    getLogsImportacionById,
    getMisLogsImportacion,
    getMisLogsImportacionById
  }
};
