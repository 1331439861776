import Tabla from "./tabla";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withNotificationContext } from "js/notification-context";
import { graphql } from "react-apollo";
import UserQraphQL from "js/graphql/resolvers/usuario.resolver";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import withPrograma from "../../../hoc/withPrograma";

const enhance = compose(
  withNotificationContext,
  withPrograma,
  withRouter,
  graphql(UserQraphQL.queries.getMiUsuarioCompleto, {
    options: () => ({
      fetchPolicy: "cache-first"
    }),
    props: ({ data }) => {
      const { loading, miUsuario } = data;
      if (loading) return;
      return {
        usuario: miUsuario
      };
    }
  }),
  withStyles(styles)
);

export default enhance(Tabla);
