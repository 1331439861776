import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { Productos } = Fragmentos;

export const getEleccionProductos = gql`
  query getEleccionProductos(
    $eleccionProductoLike: EleccionProductoSearch
    $pagina: Pagina
    $eleccionProductoOrder: EleccionProductoOrder
  ) {
    getEleccionProductos(
      eleccionProductoLike: $eleccionProductoLike
      pagina: $pagina
      eleccionProductoOrder: $eleccionProductoOrder
    ) {
      itemsPagina {
        ...eleccionProducto
      }
    }
  }
  ${Productos.fragments.getEleccionProductos}
`;

export const getProductoById = gql`
  query getProductos($productoLike: ProductoSearch, $pagina: Pagina) {
    getProductos(productoLike: $productoLike, pagina: $pagina) {
      itemsPagina {
        ...productoId
        datosRequeridos {
          idDatoRequerido
        }
      }
    }
  }
  ${Productos.fragments.productoId}
`;

export const createProducto = gql`
  mutation CreateProducto($producto: ProductoCreate!) {
    createProducto(producto: $producto) {
      idProducto
    }
  }
`;

export const updateProducto = gql`
  mutation UpdateProducto($producto: ProductoUpdate!) {
    updateProducto(producto: $producto) {
      idProducto
    }
  }
`;

export const getPaginaProductos = gql`
  query getProductos(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: ProductoColumn!
    $nombre: String
    $idProveedor: Int
    $idRubro: Int
    $idSubrubro: Int
    $idProducto: Int
    $precioSugerido: Float
    $tipoProducto: TipoProducto
    $idPais: Int
    $idZona: Int
    $idLocalidad: Int
    $estado: Estado
  ) {
    getProductos(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      productoOrder: { column: $orderBy, order: $order }
      productoLike: {
        idProducto: $idProducto
        idProveedor: $idProveedor
        idRubro: $idRubro
        idSubrubro: $idSubrubro
        nombre: $nombre
        precioSugerido: $precioSugerido
        tipoProducto: $tipoProducto
        proveedor_sucursales_idPais: $idPais
        proveedor_sucursales_idZona: $idZona
        proveedor_sucursales_idLocalidad: $idLocalidad
        estado: $estado
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        idProducto
        nombre
        precioSugerido
        proveedor {
          idProveedor
          nombreFantasia
          pais {
            moneda
            descripcion
          }
        }
        gestionadoPor
        tipoProducto
        costo
        stock
        stockInfinito
        estado
        tieneSucursales
      }
    }
  }
`;

export const getProductosFilter = gql`
  query getProductosFilter(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: ProductoColumn!
    $nombre: String
  ) {
    getProductosFilter(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      productoOrder: { column: $orderBy, order: $order }
      productoLike: { nombre: $nombre }
    ) {
      itemsPagina {
        idProducto
        nombre
      }
    }
  }
`;

export default {
  queries: {
    getEleccionProductos,
    getProductosFilter,
    createProducto,
    updateProducto,
    getProductoById,
    getPaginaProductos
  }
};
