import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Table from "js/components/Shared/Tabla/EnhancedTable";
import Grid from "@material-ui/core/Grid";
import { uniqBy, groupBy, assign, orderBy, sumBy } from "lodash";
import { Element, scroller } from "react-scroll";
import { numberWithCommas } from "js/utils";
import moment from "moment";

const parser = value => (value ? numberWithCommas(value) : "--");
const getAnoMes = date => {
  return moment(date, "YYYYMM").format("MM/YY");
};
class TransaccionesPorSucursal extends PureComponent {
  state = {
    page: 1,
    rowsPerPage: 10,
    total: 0,
    data: [],
    selected: [],
    disabled: [],
    fullData: [],
    columnData: [
      {
        id: "sucursal",
        label: "Sucursal",
        parser,
        type: "custom"
      }
    ],
    order: "ASC",
    orderBy: "sucursal"
  };

  componentDidMount = () => this.componentWillReceiveProps(this.props);

  componentWillReceiveProps = async nextProps => {
    const { data } = nextProps;
    const columnData = [
      { id: "sucursal", label: "Sucursal", sticky: true },
      ...orderBy(
        uniqBy(data, "anoMes").map(({ anoMes }) => ({
          id: anoMes.toString(),
          label: getAnoMes(anoMes),
          isNumeric: true,
          type: "custom",
          parser
        })),
        [
          ({ label }) => {
            return moment(label, "MM/YY");
          }
        ],
        ["asc"]
      )
    ];
    let newData = data.map(
      ({ sucursal: { idSucursal, nombre }, transacciones, anoMes }) => ({
        idSucursal,
        sucursal: nombre,
        [anoMes]: transacciones
      })
    );
    newData = groupBy(newData, "idSucursal");
    newData = Object.keys(newData).map(key => assign(...newData[key]));
    await this.setState({ columnData, fullData: newData, data: newData });
    await this.sort();
    this.setTotales();
    this.spliceData();
  };

  handleChangePage = async (e, page) => {
    await this.setState({ page: page + 1 });
    this.spliceData();
    scroller.scrollTo("transaccionesPorSucursal", {
      duration: 700,
      delay: 10,
      smooth: true
    });
  };

  handleChangeRowsPerPage = async e => {
    await this.setState({ rowsPerPage: e.target.value });
    this.spliceData();
  };

  handleRequestSort = async (event, property) => {
    const orderBy = property;
    let order = "desc";
    if (this.state.orderBy === property && this.state.order === "desc")
      order = "asc";
    await this.setState({ order, orderBy });
    await this.sort();
    this.spliceData();
  };

  spliceData = () => {
    const { rowsPerPage, page, fullData: data } = this.state;
    const copy = Object.assign([], data);
    copy.splice(0, (page - 1) * rowsPerPage);
    copy.splice(rowsPerPage);
    this.setState({ data: copy });
  };

  sort = async () => {
    const { fullData: data, order, orderBy: column } = this.state;
    const copy = Object.assign([], data);
    const fullData = orderBy(copy, column, order);
    await this.setState({ fullData });
  };

  setTotales = () => {
    const { fullData = [] } = this.state;
    const keys = this.state.columnData
      .filter(({ id }) => id !== "sucursal")
      .map(({ id }) => id);
    // const keys = Object.keys(fullData[0] || {}).filter(k => !isNaN(k));
    const footer = keys.map(key => ({ value: sumBy(fullData, key), parser }));
    this.setState({ footer });
  };
  render() {
    const { data, columnData, fullData, footer } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid container spacing={16} style={{ backgroundColor: "#414141" }}>
          <Grid
            xs={12}
            classes={{ container: classes.textoTransaccionesPorSucursal }}
            sm={12}
            item
            container
            alignItems="flex-start"
            alignContent="flex-start"
            direction={"row"}
            spacing={30}
          >
            {/* <Grid xs={12} it5em>
              <br />
            </Grid> */}
            <Grid xs={12} item>
              <Element name="transaccionesPorSucursal">
                <Typography
                  variant="h6"
                  className={`${classes.contenedorCLN} ${classes.textoBlanco}`}
                >
                  Transacciones por sucursal
                </Typography>
              </Element>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} item>
            <Table
              handleRequestSort={this.handleRequestSort}
              columnData={columnData}
              total={fullData.length}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              state={this.state}
              data={data}
              footer={footer}
              classes={classes}
              pathId={"idSucursal"}
              scrollToLastColumn
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

TransaccionesPorSucursal.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

TransaccionesPorSucursal.defaultProps = {
  data: []
};

TransaccionesPorSucursal.displayName = "TransaccionesPorSucursal";

export default TransaccionesPorSucursal;
