import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

class defaultFilter extends PureComponent {
  render() {
    const { filter, value, submitValue } = this.props;

    return (
      <div>
        {filter.map(f => (
          <TextField
            id={f.id}
            key={f.id}
            name={f.id}
            label={f.label}
            type="search"
            value={value[f.id] || ""}
            style={{ width: "300px", marginRight: "10px" }}
            onChange={e => submitValue(e)}
          />
        ))}
      </div>
    );
  }
}

defaultFilter.propTypes = {
  filter: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default defaultFilter;
