import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import { Link } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { _t } from "js/utils/TranslationService";

const CanjeError = props => {
  const productId = props.computedMatch.params.productId;

  return (
    <div>
      <Card className={props.classes.card}>
        <CardContent>
          <Typography variant="h5" component="h2">
            {`${props.location.state.errors[0].message}`}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">
            <Link to="/catalogo">{_t("Volver al catálogo")}</Link>
          </Button>
          <Button size="small">
            <Link to="/landing">{_t("Volver a la página de inicio")}</Link>
          </Button>
          <Button size="small">
            <Link to={`/producto/${productId}`}>
              {_t("Volver al Producto")}
            </Link>
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

CanjeError.propTypes = {
  classes: PropTypes.object
};

CanjeError.defaultProps = {};

export default CanjeError;
