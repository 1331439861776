import React, { PureComponent } from "react";
import AppQraphQL from "js/graphql/resolvers/notificacion.resolver";
import proveedorGraphQL from "js/graphql/resolvers/proveedor.resolver";
import WysiwygPanel from "js/pages/Admin/Programa/Legales/WysiwygPanel/index.js";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import { Switch, Select, MenuItem, CircularProgress } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import { InputMultiEmail } from "js/components/Shared/Form/Fields";
import Variables from "js/pages/Admin/Programa/Notificaciones/Edit/variables";
import Button from "@material-ui/core/Button";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        nombre: "",
        asunto: "",
        puntos: 0,
        estado: "INACTIVO",
        template: "",
        cc: [],
        cco: [],
        asignarProveedor: false,
        proveedorSelected: null
      },
      id: this.props.match.params.id || parseInt(this.props.match.params.id),
      idNotificacion:
        this.props.match.params.idNotificacion ||
        parseInt(this.props.match.params.idNotificacion),
      proveedores: [],
      loadingProveedores: true
    };
  }

  getNotificaciones() {
    const { client } = this.props;
    const {
      match: {
        params: { idNotificacion }
      }
    } = this.props;
    return client
      .query({
        query: AppQraphQL.queries.getNotificacionById,
        fetchPolicy: "network-only",
        variables: {
          idNotificacion: parseInt(idNotificacion) || idNotificacion
        }
      })
      .then(res => {
        if (res.data.getNotificaciones.length) {
          let data = res.data.getNotificaciones[0];
          try {
            data.cc = data.cc || [];
            data.cco = data.cco || [];
          } catch (e) {}
          this.setState({ initialValues: data });
        }
      });
  }

  getProveedores() {
    const { client } = this.props;
    return client
      .query({
        query: proveedorGraphQL.queries.getProveedoresAutocomplete,
        fetchPolicy: "network-only"
      })
      .then(res => {
        this.setState({
          proveedores: res.data.getProveedores.itemsPagina.sort(
            (itemA, itemB) => {
              if (itemA.nombreFantasia < itemB.nombreFantasia) return -1;
              if (itemA.nombreFantasia > itemB.nombreFantasia) return 1;
              return 0;
            }
          ),
          loadingProveedores: false
        });
      });
  }

  async componentDidMount() {
    const { idNotificacion } = this.state;
    if (idNotificacion !== "new") await this.getNotificaciones();
    await this.getProveedores();
  }

  onChange(e) {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        [e.target.name]: e.target.value
      }
    });
  }

  onChangeSwitch(e) {
    e.target.name === "asignarProveedor"
      ? this.setState({
          initialValues: {
            ...this.state.initialValues,
            [e.target.name]: e.target.checked
          }
        })
      : this.setState({
          initialValues: {
            ...this.state.initialValues,
            [e.target.name]: !e.target.checked ? "INACTIVO" : "ACTIVO"
          }
        });
  }

  onSaveAction = async () => {
    const { client, history } = this.props;
    this.setState({ saving: true });

    const { initialValues, idNotificacion, id } = this.state;
    if (idNotificacion === "new") {
      if (initialValues.asignarProveedor) {
        let notificacionName = `${initialValues.nombre.replace(
          / /g,
          "_"
        )}_${initialValues.proveedorSelected.replace(/ /g, "_")}`;
        initialValues.nombre = notificacionName;
      }
      await client
        .mutate({
          mutation: AppQraphQL.mutations.createNotificaciones,
          variables: {
            notificacion: {
              nombre: initialValues.nombre,
              estado: initialValues.estado,
              asunto: initialValues.asunto,
              puedeAsignarPuntos: false,
              puntos: parseInt(initialValues.puntos) || 0,
              cc: initialValues.cc || [],
              cco: initialValues.cco || [],
              template: initialValues.template
            }
          }
        })
        .then(res => {
          let notificacionesCreadas = res.data.createNotificaciones;
          this.setState({ saving: false });
          this.props.openNotification(
            `Crearon correctamente ${
              notificacionesCreadas.length
            } notificaciones`
          );
          history.push(`/admin/programa/${id}/notificaciones`);
        });
    } else {
      await client
        .mutate({
          mutation: AppQraphQL.mutations.updateNotificacion,
          variables: {
            notificacion: {
              idNotificacion: parseInt(idNotificacion, 10),
              estado: initialValues.estado,
              asunto: initialValues.asunto,
              puntos: parseInt(initialValues.puntos) || undefined,
              cc: initialValues.cc || [],
              cco: initialValues.cco || [],
              template: initialValues.template
            }
          }
        })
        .then(res => {
          this.props.openNotification("Actualizado correctamente");
          history.push(`/admin/programa/${id}/notificaciones`);
        })
        .catch(errors => {
          let e = errors.graphQLErrors.length
            ? errors.graphQLErrors[0].message
            : "Error inesperado";
          this.props.openNotification(e);
          return false;
        });
    }
  };

  render() {
    const { classes, history } = this.props;
    const {
      initialValues,
      id,
      proveedores,
      idNotificacion,
      loadingProveedores,
      saving
    } = this.state;
    return (
      <div className={classes.root}>
        <Grid container direction="row" justify="space-between" spacing={16}>
          <Grid item md={8}>
            <Grid container spacing={8}>
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  {initialValues.nombre}
                </Typography>
                <FormControl className={classes.formControlSwitch}>
                  <FormLabel>Activo</FormLabel>
                  <Switch
                    id="estado"
                    name="estado"
                    onChange={e => this.onChangeSwitch(e)}
                    checked={initialValues.estado === "ACTIVO" ? true : false}
                    color="primary"
                  />
                </FormControl>
                {idNotificacion === "new" && (
                  <>
                    <FormControl className={classes.formControl}>
                      <TextField
                        key="nombre"
                        name="nombre"
                        label="Nombre de la notificación"
                        value={initialValues.nombre}
                        onChange={e => this.onChange(e)}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <FormLabel>¿Notificación Asigna Puntos?</FormLabel>
                      <Switch
                        id="puedeAsignarPuntos"
                        name="puedeAsignarPuntos"
                        onChange={e => this.onChange(e)}
                        checked={initialValues.puedeAsignarPuntos}
                        color="primary"
                      />
                    </FormControl>
                  </>
                )}
                <FormControl className={classes.formControl}>
                  <TextField
                    key="asunto"
                    name="asunto"
                    label="Asunto del email"
                    type="search"
                    value={initialValues.asunto}
                    onChange={e => this.onChange(e)}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    key="puntos"
                    name="puntos"
                    label="Puntos a acreditar"
                    type="search"
                    value={initialValues.puntos}
                    onChange={e => this.onChange(e)}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputMultiEmail
                    name={"cc"}
                    value={initialValues.cc}
                    onChange={e => this.onChange(e)}
                    label={"CC"}
                    placeholder={"CC"}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputMultiEmail
                    name={"cco"}
                    value={initialValues.cco}
                    onChange={e => this.onChange(e)}
                    label={"CCO"}
                    placeholder={"CCO"}
                  />
                </FormControl>
                {idNotificacion === "new" && (
                  <FormControl className={classes.formControl}>
                    <FormLabel>Asignar a Proveedor</FormLabel>
                    <Switch
                      id="asignarProveedor"
                      name="asignarProveedor"
                      onChange={e => this.onChangeSwitch(e)}
                      checked={initialValues.asignarProveedor}
                      color="primary"
                    />
                  </FormControl>
                )}
                {initialValues.asignarProveedor && (
                  <FormControl className={classes.formControl}>
                    <Select
                      id="proveedor"
                      name="proveedorSelected"
                      value={initialValues.proveedorSelected}
                      onChange={e => this.onChange(e)}
                    >
                      {/* Aquí deberías agregar las opciones de proveedores activos */}
                      {loadingProveedores ? (
                        <MenuItem
                          disabled
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <CircularProgress />
                        </MenuItem>
                      ) : (
                        proveedores.map(proveedor => (
                          <MenuItem
                            key={proveedor.idProveedor}
                            value={proveedor.nombreFantasia}
                          >
                            {proveedor.nombreFantasia}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>

            <div className={classes.wysiwygPanelContainer}>
              <Typography
                variant="subtitle2"
                gutterBottom
                className={classes.subtitle}
              >
                Contenido del template
              </Typography>
              <WysiwygPanel
                initialValue={initialValues.template}
                inputName="template"
                onChange={e => this.onChange(e)}
              />
            </div>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button
                type="button"
                variant="contained"
                className={classes.button}
                onClick={() =>
                  history.push(
                    `/admin/programa/${parseInt(id) || id}/notificaciones`
                  )
                }
              >
                Cancelar
              </Button>
              <Button
                onClick={this.onSaveAction}
                style={{ height: "37px", width: "106px" }}
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={saving}
              >
                {saving ? <CircularProgress size={24} /> : "Guardar"}
              </Button>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Hidden only={["xs", "sm"]}>
              <Variables />
            </Hidden>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Edit;
