import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextTruncate from "react-text-truncate";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Icon from "@material-ui/core/Icon";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { withNotificationContext } from "js/notification-context";
import client from "js/App/client.graphql.js";
import BeneficioQraphQL from "js/graphql/resolvers/beneficio.resolver";
import Cucarda from "../../../components/Cucarda";
import { _t } from "js/utils/TranslationService";
import Tooltip from "@material-ui/core/Tooltip";
import get from "lodash/get";
import AuthService from "js/utils/AuthService";

class CardItem extends Component {
  constructor(props) {
    super(props);
    this.toggleFavorito = this.toggleFavorito.bind(this);
    const idUsuario = parseInt(AuthService.getIdUsuario());
    const isFavorito = this.props.beneficio.favoritos.includes(idUsuario);
    this.state = {
      favorito: isFavorito,
      starActive: isFavorito
    };
  }

  async toggleFavorito() {
    this.setState(prevState => ({
      favorito: !prevState.favorito
    }));
    await client
      .mutate({
        mutation: BeneficioQraphQL.mutations.toggleFavoritoBeneficio,
        errorPolicy: "all",
        variables: {
          idBeneficio: this.props.beneficio.idBeneficio
        }
      })
      .then(res => {
        const favorito = get(res, "data.toggleFavoritoBeneficio", false);
        this.setState({ favorito });
        const message = favorito
          ? "Has agregado este beneficio a tu lista favoritos"
          : "Has quitado este beneficio de tu lista favoritos";
        this.props.openNotification(message);
        this.props.beneficio.favoritos.push(
          parseInt(AuthService.getIdUsuario())
        );
      })
      .catch(e => {
        this.setState(prevState => ({ favorito: !prevState.favorito }));
      });
  }

  render() {
    const { classes, beneficio, programLogo } = this.props;
    const rubros = beneficio.rubros
      .map(rubro => rubro.descripcion)
      .concat(get(beneficio, "subrubros", []).map(r => r.descripcion));
    const { cucarda } = beneficio;
    return (
      <Grid item xs={12} sm={6} md={4} lg={4} className={classes.gridCard}>
        <Card className={classes.card} raised>
          <div className={classes.favContainer}>
            <Icon
              color="primary"
              className={classes.heartIcon}
              onClick={this.toggleFavorito}
              onMouseOver={() => this.setState({ starActive: true })}
              onMouseOut={() => this.setState({ starActive: false })}
            >
              {this.state.starActive || this.state.favorito
                ? "favorite"
                : "favorite_border"}
            </Icon>
            <Link
              to={`/beneficio/${beneficio.idBeneficio}`}
              className={classes.link}
            >
              <CardMedia
                className={
                  beneficio.imagenes[0] ? classes.media : classes.mediaLogo
                }
                image={
                  beneficio.imagenes[0] ? beneficio.imagenes[0] : programLogo
                }
                style={{ borderRadius: `${cucarda ? 0 : 4}px 4px 0 0` }}
              >
                {cucarda && <Cucarda cucardaType={cucarda} />}
              </CardMedia>
            </Link>
          </div>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.item} color="textSecondary">
              {rubros.length ? rubros[0] : <br />}
            </Typography>
            <Link
              to={`/beneficio/${beneficio.idBeneficio}`}
              className={classes.link}
            >
              <Tooltip
                title={beneficio.nombre}
                classes={{
                  tooltip: classes.tooltip,
                  popper: classes.popper,
                  tooltipPlacementBottom: classes.tooltipPlacementBottom
                }}
                interactive
              >
                <Typography className={classes.descripcion} component="div">
                  <TextTruncate
                    className={classes.title}
                    line={1}
                    truncateText="..."
                    text={beneficio.nombre}
                  />
                </Typography>
              </Tooltip>
            </Link>
            <Typography className={classes.descripcion} component="div">
              <div
                dangerouslySetInnerHTML={{
                  __html: `<div style="
                              overflow: hidden;
                              display: -webkit-box;
                              -webkit-line-clamp: 3;
                              -webkit-box-orient: vertical;
                            ">${beneficio.copete || ""}</div>`
                }}
              />
            </Typography>
          </CardContent>
          <CardActions className={classes.actionContainer}>
            <Typography
              className={classes.score}
              variant="h6"
              component="h2"
              color="primary"
            >
              <p style={{ marginBottom: 0 }}>
                {beneficio.idTipo ? beneficio.idTipo.tipo : ""}
              </p>
            </Typography>
            {!beneficio.stockInfinito &&
            beneficio.stock !== null &&
            beneficio.stock < 1 ? (
              <Button variant="contained" color="primary" disabled={true}>
                {_t("Sin stock")}
              </Button>
            ) : (
              <Link to={`/beneficio/${beneficio.idBeneficio}`}>
                <Button variant="contained" color="primary">
                  {_t("Más info")}
                </Button>
              </Link>
            )}
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

export default withNotificationContext(CardItem);
