import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import Header from "./Header";
import styles from "./styles";
import withPrograma from "js/hoc/withPrograma";

import { withRouter } from "react-router-dom";
import { compose } from "recompose";

const enhance = compose(
  withRouter,
  withPrograma,
  withWidth(),
  withStyles(styles)
);
export default enhance(Header);
