import React from "react";
import LoginAsIcon from "@material-ui/icons/SupervisedUserCircle";
import AuthService from "js/utils/AuthService";
import base from "./Base";
import DialogLoginAs from "js/components/TablaPrograma/DialogLoginAs";
export default params => ({
  ...base(),
  label: "Ver programa como",
  icon: <LoginAsIcon />,
  tooltip: "Ver programa como",
  show: () => AuthService.hasPerms(params.perms),
  dialog: (
    <DialogLoginAs
      handleClose={params.togglePopUp}
      idPrograma={params.itemId}
    />
  )
});
