import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

class AlertDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ open: props.open });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleRemove = () => {
    const { itemSelected } = this.props;
    this.setState({ open: false });
    this.props.hanleDeleteAction(itemSelected[Object.keys(itemSelected)[0]]);
  };

  render() {
    const { fullScreen, itemSelected } = this.props;

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"¿Estás seguro de querer borrar el siguiente registro?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Deseas eliminar el {itemSelected.__typename}: "
              {itemSelected[Object.keys(itemSelected)[1]]}
              "?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.handleRemove} color="secondary" autoFocus>
              Borrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AlertDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  hanleDeleteAction: PropTypes.func.isRequired
};

export default withMobileDialog()(AlertDialog);
