import React from "react";
import { Route, Redirect } from "react-router-dom";
import Drawer from "./Drawer/Drawer";
import AuthService from "js/utils/AuthService";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { USER_ROLES as Roles } from "js/models/User";
import styles from "./styles";

const LayoutBO = ({ component, computedMatch, ...rest }) => {
  const chat = document.getElementById("jivo-chat-scritp");
  const chatBoxContainer = document.getElementById("jivo-iframe-container");
  const chatBoxElement = document.getElementsByTagName("jdiv");

  if (chat) {
    chat.remove();
  }

  if (chatBoxContainer) {
    chatBoxContainer.remove();
  }

  if (chatBoxElement && chatBoxElement[0]) {
    chatBoxElement[0].remove();
  }

  return (
    <Route
      {...rest}
      render={props =>
        AuthService.hasPerms([Roles.ACCESO_BO]) ? (
          <Drawer BaseComponent={component} />
        ) : (
          <Redirect to="/landing" />
        )
      }
    />
  );
};

const LayoutBOWithStyles = withStyles(styles)(LayoutBO);

export default props => (
  <MuiThemeProvider theme={props.theme}>
    <LayoutBOWithStyles {...props} />
  </MuiThemeProvider>
);
