import React, { Component } from "react";
import GoogleUniversalAnalytics from "react-ga";
import GoogleAnalytics from "react-ga4";
import { compose, lifecycle } from "recompose";
import get from "lodash/get";

// if (document.location.hostname === "ashowea.com") {
//   // only enable trackers for specific Prod URL
//   GoogleAnalytics.initialize("UA-7662920-27");
// } else {
//   GoogleAnalytics.initialize("UA-0000000-00");
// }

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options
    });
    GoogleAnalytics.send({ hitType: "pageview", page });

    GoogleUniversalAnalytics.set({
      page,
      ...options
    });
    GoogleUniversalAnalytics.pageview(page);
  };

  const withTrackerHOC = class extends Component {
    componentDidMount() {
      const page = get(this.props, "location.pathname", "");
      const search =
        page === "/informacion" ? get(this.props, "location.search", "") : "";
      trackPage(page.concat(search));
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = get(this.props, "location.pathname", "");
      const nextPage = nextProps.location.pathname;
      const search =
        nextPage === "/informacion"
          ? get(nextProps, "location.search", "")
          : "";

      if (currentPage !== nextPage) {
        trackPage(nextPage.concat(search));
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return withTrackerHOC;
};

export default compose(
  withTracker,
  lifecycle({
    componentDidMount() {
      const { programa } = this.props;
      const ga = get(programa, "template.templateData.GA", "");
      if (ga && ga !== "") {
        GoogleAnalytics.initialize(ga, {
          // Uncomment the below line if you need to
          // output additional feedback to the console
          // testMode: process.env.NODE_ENV === "develop"
          testMode: false
        });
        GoogleUniversalAnalytics.initialize(ga, {
          // Uncomment the below line if you need to
          // output additional feedback to the console
          // debug: process.env.NODE_ENV === "develop"
          debug: false
        });
      }
    }
  })
);
