import React, { PureComponent } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";

import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import CampanaGraphQL from "js/graphql/resolvers/campanaUnilever.resolver";
import AuthService from "js/utils/AuthService";

class advancedFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      campanas: []
    };
  }

  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  async componentDidMount() {
    const {
      client,
      match: {
        params: { id }
      }
    } = this.props;
    let idPrograma = id;
    if (!idPrograma) {
      idPrograma = parseInt(AuthService.getIdPrograma());
    }

    await client
      .query({
        query: CampanaGraphQL.queries.getCampanasInput,
        fetchPolicy: "network-only",
        variables: {
          campanaOPLike: {
            idPrograma: idPrograma,
            tipo: "SIMPLE"
          },
          orderBy: "fechaInicio",
          order: "desc"
        }
      })
      .then(res => {
        const campanas = res.data.getCampanasOP
          ? res.data.getCampanasOP.itemsPagina
          : null;

        this.setState({
          campanas: campanas
        });
      });
  }

  render() {
    const { value, submitValue, classes } = this.props;
    const { campanas } = this.state;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={3}>
          <FormControl className={classes.formControl}>
            <InputLabel id="idCampana">Campaña</InputLabel>
            <Select
              key={"idCampana"}
              name={"idCampana"}
              id="campana-select"
              value={value["idCampana"] || ""}
              onChange={e => submitValue(e)}
            >
              <MenuItem value={undefined}>{"Todas"}</MenuItem>
              {campanas.map((campana, i) => {
                return (
                  <MenuItem key={i} value={campana._id}>
                    {campana.nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              id="estado-simple-select"
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"EN_REVISION"}>En revisión</MenuItem>
              <MenuItem value={"APROBADO"}>Aprobado</MenuItem>
              <MenuItem value={"AUTO_APROBADO"}>Auto aprobado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionDesde"}
              name={"fechaCreacionDesde"}
              label="Fecha Creación (Desde)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionDesde",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionDesde"]
                  ? moment(value["fechaCreacionDesde"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionHasta"}
              name={"fechaCreacionHasta"}
              label="Fecha Creación (Hasta)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionHasta",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionHasta"]
                  ? moment(value["fechaCreacionHasta"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withApollo(withRouter(withStyles(styles)(advancedFilter)));
