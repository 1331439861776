const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  name: {
    display: "flex",
    alignItems: "center",
    textAlign: "left"
  },
  nameValue: {
    marginLeft: "10px",
    padding: "10px"
  },
  addInput: {
    display: "flex",
    alignItems: "flex-end"
  },
  table: {
    // display: "flex",
    // overflow: "auto",
    // flexDirection: "column",
    minWidth: 700
  },
  columnWidth100: {
    width: "100px"
  },
  columnWidth30: {
    width: "30px"
  },
  columnWidthInput: {
    width: "50px",
    textAlign: "left"
  },
  border: {
    borderRight: "1px solid #ccc"
  },
  align: {
    padding: "5px",
    textAlign: "center"
  },
  formControl: {
    margin: "10px"
  },
  buttonSave: {
    flex: 1,
    display: "flex",
    alignItems: "end",
    justifyContent: "flex-end",
    margin: "100px 0 20px 0"
  },
  containerPaper: {
    overflow: "auto"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    paddingTop: "5px",
    paddingBottom: "5px",
    backgroundColor: "#eee",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.secondary,
    minHeight: "45px"
  },
  textField: {
    backgroundColor: "#ffffcb"
  },
  results: {
    borderTop: "1px solid #cccccc",
    margin: "30px 0",
    padding: "20px 0"
  },
  numeric: {
    textAlign: "right"
  }
});
export default styles;
