export default theme => ({
  notification: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main)
  },
  root: {
    overflow: "visible",
    paddingRight: theme.spacing.unit,
    minHeight: 0,
    width: "300px"
  },
  formControl: {
    width: "100%"
  },
  dialogContent: {
    overflow: "visible"
  }
});
