import { withStyles } from "@material-ui/core/styles";
import { pad } from "lodash";

const styles = theme => ({
  dialog: {
    maxWidth: "sm",
    overflowX: "hidden",
    margin: 0
  },
  dialogTitle: {
    paddingTop: 10,
    paddingLeft: 24,
    paddingBottom: 0,
    margin: 0
  },
  dialogContent: {
    marginTop: 0,
    paddingTop: 0,
    paddingLeft: 24
  },
  list: {
    paddingLeft: 0,
    listStyleType: "none",
    maxHeight: "50vh",
    overflow: "auto"
  },
  listItem: {
    paddingLeft: 0,
    marginLeft: 0,
    position: "relative",
    alignItems: "flex-start",
    paddingTop: 8,
    paddingBottom: 8
  },
  dot: {
    position: "absolute",
    left: 0,
    top: 18,
    transform: "translateY(-50%)",
    width: 6,
    height: 6,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main
  },
  productName: {
    fontWeight: 600,
    marginLeft: 0,
    display: "inline"
  },
  clientName: {
    fontWeight: 600,
    color: theme.palette.primary.dark,
    marginLeft: 0,
    display: "block",
    paddingLeft: 0
  },
  secondaryContent: {
    display: "flex",
    flexDirection: theme.breakpoints.down("sm") ? "column" : "row",
    gap: 24,
    alignItems: theme.breakpoints.down("sm") ? "flex-start" : "center",
    marginTop: 4,
    marginLeft: 0
  },
  quantityText: {
    fontSize: "0.9rem",
    fontWeight: 600,
    whiteSpace: "nowrap"
  },
  observationText: {
    color: "#616161",
    fontSize: "0.8rem",
    display: "flex",
    alignItems: "center",
    marginLeft: 0,
    flexWrap: "wrap"
  },
  divider: {
    backgroundColor: "#e0e0e0",
    marginLeft: 24
  },
  separator: {
    width: 1,
    height: 16,
    backgroundColor: "#e0e0e0",
    margin: "0 8px"
  }
});

export default withStyles(styles);
