import React from "react";
import Tabla from "js/components/TablaAccion";

export const Accion = props => {
  return (
    <div>
      <Tabla />
    </div>
  );
};

export default Accion;
