import gql from "graphql-tag";

export const getClientesRetail = gql`
  query getClientesRetail(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: ClienteRetailColumn!
    $idPrograma: Int
    $nombre: String
    $documento: String
    $estado: Estado
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getClientesRetail(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      clienteOrder: { column: $orderBy, order: $order }
      clienteLike: {
        idPrograma: $idPrograma
        nombre: $nombre
        documento: $documento
        estado: $estado
      }
      clienteRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        documento
        nombre
        estado
        fechaCreacion
        telefono
        email
      }
    }
  }
`;

export const getClientesRetailInput = gql`
  query getClientesRetail($clienteLike: ClienteRetailSearch) {
    getClientesRetail(clienteLike: $clienteLike) {
      itemsPagina {
        label: nombre
        value: _id
      }
    }
  }
`;

export const getClienteRetailById = gql`
  query getClientesRetail($clienteLike: ClienteRetailSearch) {
    getClientesRetail(clienteLike: $clienteLike) {
      itemsPagina {
        _id
        idPrograma
        documento
        nombre
        estado
        fechaCreacion
        telefono
        email
      }
    }
  }
`;

export const createClienteRetail = gql`
  mutation createClienteRetail($cliente: ClienteRetailCreate!) {
    createClienteRetail(cliente: $cliente) {
      _id
      nombre
    }
  }
`;

export const updateClienteRetail = gql`
  mutation updateClienteRetail($cliente: ClienteRetailUpdate!) {
    updateClienteRetail(cliente: $cliente) {
      _id
    }
  }
`;

export default {
  queries: {
    getClientesRetail,
    getClienteRetailById,
    getClientesRetailInput
  },
  mutations: {
    createClienteRetail,
    updateClienteRetail
  }
};
