import gql from "graphql-tag";

export const getPaginaUsuariosUnilever = gql`
  query getUsuariosUnilever(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: UsuarioColumn!
    $username: String
    $username_OR_nombre: String
    $estado: Estado
    $idRol: Int
    $email: String
    $idPrograma: Int
    $idSegmento: Int
    $idPais: Int
    $idZona: Int
    $idLocalidad: Int
    $distribuidora: String
  ) {
    getUsuariosUnilever(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      usuarioOrder: { column: $orderBy, order: $order }
      usuarioLike: {
        estado: $estado
        roles: $idRol
        username: $username
        username_OR_nombre: $username_OR_nombre
        email: $email
        idPrograma: $idPrograma
        idSegmento: $idSegmento
        idPais: $idPais
        idZona: $idZona
        idLocalidad: $idLocalidad
        distribuidora: $distribuidora
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        idUsuario
        username
        estado
        roles {
          nombre
        }
        segmento {
          nombre
          descripcion
        }
        perfil {
          nombre
          apellido
          email
          otros
        }
        fechaDeAlta
        cuentaCorriente {
          balance
        }
      }
    }
  }
`;

export const getUsuariosUnilever = gql`
  query getUsuariosUnilever($usuarioLike: UsuarioUnileverSearch) {
    getUsuariosUnilever(usuarioLike: $usuarioLike) {
      itemsPagina {
        idUsuario
        username
        perfil {
          nombre
          apellido
          otros
        }
      }
    }
  }
`;

export const getUsuarioUnilever = gql`
  query getUsuarioUnilever($idUsuario: Int) {
    getUsuarioUnilever(usuarioLike: { idUsuario: $idUsuario }) {
      idUsuario
      idSegmento
      idPrograma
      idIdioma
      username
      estado
      roles
      idJefeNivel1
      otros
    }
  }
`;

export const createUsuarioUnilever = gql`
  mutation createUsuarioUnilever($usuario: UsuarioUnileverInput!) {
    createUsuarioUnilever(usuario: $usuario) {
      username
      idUsuario
    }
  }
`;

export const updateUsuarioUnilever = gql`
  mutation updateUsuarioUnilever($usuario: UsuarioUnileverUpdate!) {
    updateUsuarioUnilever(usuario: $usuario) {
      username
      idUsuario
    }
  }
`;

export const aceptarUsuarioUnilever = gql`
  mutation aceptarUsuarioUnilever($idUsuario: Int!, $accion: String!) {
    aceptarUsuarioUnilever(idUsuario: $idUsuario, accion: $accion) {
      username
      idUsuario
    }
  }
`;

export const cambiarEstadoUsuarioUnilever = gql`
  mutation cambiarEstadoUsuarioUnilever($idUsuario: Int!, $estado: Estado!) {
    cambiarEstadoUsuarioUnilever(idUsuario: $idUsuario, estado: $estado)
  }
`;

export default {
  queries: {
    getPaginaUsuariosUnilever,
    getUsuariosUnilever,
    getUsuarioUnilever
  },
  mutations: {
    createUsuarioUnilever,
    updateUsuarioUnilever,
    aceptarUsuarioUnilever,
    cambiarEstadoUsuarioUnilever
  }
};
