import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import ViewIcon from "@material-ui/icons/Visibility";
import ListSubheader from "@material-ui/core/ListSubheader";
import AuthService from "js/utils/AuthService";

import Enhancer from "./container";
import { _t } from "js/utils/TranslationService";
import reports from "../reportsList";

const Reportes = ({ classes, history, location }) => (
  <Grid container>
    <Grid item xs={12}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            {_t("Reportes")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <List
        subheader={
          <ListSubheader>
            <Grid container>
              <Grid item xs={11}>
                {_t("Reporte")}
              </Grid>
              <Grid item xs={1}>
                {_t("Acciones")}
              </Grid>
            </Grid>
          </ListSubheader>
        }
        className={classes.listContainer}
      >
        {reports
          .filter(({ permissions }) => AuthService.hasPerms(permissions))
          .map(({ number, name, url }) => (
            <ListItem key={`${name}-${url}`} dense>
              <ListItemText primary={`${name}`} className={classes.itemText} />
              <ListItemSecondaryAction className={classes.secondaryAction}>
                <IconButton
                  aria-label="Actions"
                  onClick={() => history.push(`${location.pathname}/${number}`)}
                >
                  <ViewIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
    </Grid>
  </Grid>
);

Reportes.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

Reportes.defaultProps = {};

Reportes.displayName = "Reportes";

export default Enhancer(Reportes);
