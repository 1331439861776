import React, { PureComponent } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import styles from "./styles.js";
import {
  getPuntosProducto,
  getRentabilidadProducto
} from "js/pages/Admin/Programa/Catalogo/utils";

const style = { textAlign: "center", "-moz-appearance": "textField" };
class Seleccion extends PureComponent {
  toIntGtZero = num => (num ? Math.abs(parseInt(num)) : num);

  render() {
    const {
      programa: { tasaConversion }
    } = this.props;
    const { elecciones, onHandleChange, onRemove, classes } = this.props;
    return (
      <Paper className={classes.containerPaper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                className={cx(
                  classes.align,
                  classes.columnWidth100,
                  classes.border
                )}
              >
                Producto
              </TableCell>
              <TableCell className={cx(classes.align, classes.columnWidth30)}>
                Ajuste (%)
              </TableCell>
              <TableCell className={cx(classes.align, classes.columnWidth30)}>
                Puntos
              </TableCell>
              <TableCell
                className={cx(
                  classes.align,
                  classes.columnWidth30,
                  classes.border
                )}
              >
                Rent.
              </TableCell>
              <TableCell className={cx(classes.align, classes.columnWidth30)}>
                Stock Maestro
              </TableCell>
              <TableCell className={cx(classes.align, classes.columnWidth30)}>
                Stock Programa
              </TableCell>
              <TableCell className={cx(classes.align, classes.columnWidth30)}>
                Límite por usuario
              </TableCell>
              <TableCell className={cx(classes.align, classes.columnWidth30)}>
                Límite por periodo
              </TableCell>
              <TableCell
                className={cx(
                  classes.align,
                  classes.columnWidth30,
                  classes.border
                )}
              >
                Periodo (días)
              </TableCell>
              <TableCell className={cx(classes.align, classes.columnWidth30)} />
            </TableRow>
          </TableHead>
          <TableBody>
            {elecciones.map(row => {
              const puntos = getPuntosProducto(
                row.precioSugerido,
                tasaConversion,
                row.porcentaje
              );
              const rentabilidad = getRentabilidadProducto(
                puntos,
                tasaConversion,
                row.costo
              );
              return (
                <TableRow key={row.idProducto}>
                  <TableCell
                    className={cx(
                      classes.align,
                      classes.columnWidth100,
                      classes.border
                    )}
                  >
                    <div className={classes.name}>
                      <div className={classes.nameValue}>{`${row.nombre} (${
                        row.idProducto
                      })`}</div>
                    </div>
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth30)}
                  >
                    <TextField
                      name="porcentaje"
                      className={cx(
                        classes.textField,
                        classes.columnWidthInput
                      )}
                      autoComplete="off"
                      inputProps={{ style }}
                      value={row.porcentaje}
                      onChange={e => onHandleChange(e, row.idProducto)}
                    />
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth30)}
                  >
                    <div className={classes.nameValue}>{puntos || "--"}</div>
                  </TableCell>
                  <TableCell
                    className={cx(
                      classes.align,
                      classes.columnWidth30,
                      classes.border
                    )}
                  >
                    <div className={classes.nameValue}>
                      {rentabilidad | "--"}%
                    </div>
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth30)}
                  >
                    {row.stockEraInfinito === true ? (
                      <FormControl>
                        <Switch
                          id="stockInfinito"
                          name="stockInfinito"
                          onChange={e => onHandleChange(e, row.idProducto)}
                          checked={row.stockInfinito}
                          color="primary"
                        />
                      </FormControl>
                    ) : (
                      <div className={classes.nameValue}>{row.stock}</div>
                    )}
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth30)}
                  >
                    {row.stockInfinito === true ? (
                      <Typography variant="h6">∞</Typography>
                    ) : (
                      <TextField
                        name="stockPorPrograma"
                        className={cx(
                          classes.textField,
                          classes.columnWidthInput
                        )}
                        inputProps={{ style }}
                        autoComplete="off"
                        value={this.toIntGtZero(row.stockPorPrograma)}
                        type="number"
                        onChange={e => onHandleChange(e, row.idProducto)}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth30)}
                  >
                    <TextField
                      name="stockPorUsuario"
                      className={cx(
                        classes.textField,
                        classes.columnWidthInput
                      )}
                      inputProps={{ style }}
                      autoComplete="off"
                      value={this.toIntGtZero(row.stockPorUsuario)}
                      type="number"
                      onChange={e => onHandleChange(e, row.idProducto)}
                    />
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth30)}
                  >
                    <TextField
                      name="stockPorPeriodo"
                      className={cx(
                        classes.textField,
                        classes.columnWidthInput
                      )}
                      value={this.toIntGtZero(row.stockPorPeriodo)}
                      inputProps={{ style }}
                      autoComplete="off"
                      type="number"
                      onChange={e => onHandleChange(e, row.idProducto)}
                    />
                  </TableCell>

                  <TableCell
                    className={cx(
                      classes.align,
                      classes.columnWidth30,
                      classes.border
                    )}
                  >
                    <TextField
                      name="periodo"
                      className={cx(
                        classes.textField,
                        classes.columnWidthInput
                      )}
                      value={this.toIntGtZero(row.periodo)}
                      type="number"
                      inputProps={{ style }}
                      autoComplete="off"
                      onChange={e => onHandleChange(e, row.idProducto)}
                    />
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth30)}
                  >
                    <IconButton onClick={onRemove.bind(this, row.idProducto)}>
                      <CancelIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default withApollo(withRouter(withStyles(styles)(Seleccion)));
