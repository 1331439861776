import React, { Component } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import CheckIcon from "@material-ui/icons/Check";
// import PendingIcon from "@material-ui/icons/Pause";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { numberWithCommas } from "js/utils";
import groupBy from "lodash/groupBy";

const getParticipaciones = participaciones => {
  const part = groupBy(participaciones, "estado");
  return {
    completa: (part.COMPLETA || []).length,
    incompleta: (part.INCOMPLETA || []).length
  };
};
export default class Ranking extends Component {
  render() {
    const {
      classes,
      accion: {
        cantidadDeUsuariosAlActivar,
        cantidadDeMailInvitacionAccionAlActivar,
        participaciones
      }
    } = this.props;
    const { completa, incompleta } = getParticipaciones(participaciones);
    return (
      <Grid item container xs={12} spacing={16}>
        <Grid item xs={12}>
          <Typography variant="subheading" gutterBottom color="primary">
            Resumen
          </Typography>
        </Grid>
        <Grid container spacing={16} direction="row">
          <Grid item xs={12} sm={6}>
            <List dense className={classes.resumen}>
              <ListItem key="cantidadDeUsuariosAlActivar">
                <ListItemAvatar>
                  <Tooltip title="Cantidad de usuarios al activar la acción">
                    <PersonIcon color="primary" />
                  </Tooltip>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    numberWithCommas(cantidadDeUsuariosAlActivar) || "--"
                  }
                />
              </ListItem>
              <ListItem key="cantidadDeMailInvitacionAccionAlActivar">
                <ListItemAvatar>
                  <Tooltip title="Cantidad de emails de invitación enviados al activar la acción">
                    <EmailIcon color="primary" />
                  </Tooltip>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    numberWithCommas(cantidadDeMailInvitacionAccionAlActivar) ||
                    "--"
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <List dense className={classes.resumen}>
              <ListItem key="finalizados">
                <ListItemAvatar>
                  <Tooltip title="Cantidad de participaciones finalizadas">
                    <CheckIcon color="primary" />
                  </Tooltip>
                </ListItemAvatar>
                <ListItemText
                  primary={completa ? numberWithCommas(completa) : "--"}
                />
              </ListItem>
              {/* <ListItem key="incompletos">
                <ListItemAvatar>
                  <Tooltip title="Cantidad de participaciones incompletas">
                    <PendingIcon color="warning" />
                  </Tooltip>
                </ListItemAvatar>
                <ListItemText
                  primary={incompleta ? numberWithCommas(incompleta) : "--"}
                />
              </ListItem> */}
              <ListItem key="sinEmpezar">
                <ListItemAvatar>
                  <Tooltip title="Cantidad de participaciones sin empezar">
                    <CloseIcon color="error" />
                  </Tooltip>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    cantidadDeUsuariosAlActivar - completa - incompleta
                      ? numberWithCommas(
                          cantidadDeUsuariosAlActivar - completa - incompleta
                        )
                      : "--"
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
