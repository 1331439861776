import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import AppQraphQL from "js/graphql/resolvers/usuario.resolver";
import {
  ReduxTypeAhead,
  TypeAhead,
  AsyncTypeAhead
} from "js/components/Shared/Form/Fields";
import { required as requiredValidation } from "js/components/Shared/Form/validations.js";

class CustomUsuarioField extends PureComponent {
  render() {
    const {
      idPrograma,
      required,
      disabled,
      async,
      redux,
      multilabel,
      noPlaceholder = false,
      sinInactivos = false,
      sinAdminAshiwea = false,
      idsUsuarios,
      ...props
    } = this.props;
    const Component = async
      ? AsyncTypeAhead
      : redux
        ? ReduxTypeAhead
        : TypeAhead;
    const rest = redux ? {} : props;
    if (sinAdminAshiwea) {
      props.filter = ({ label }) => !label.includes("admin_ashiwea");
    }
    return (
      <Component
        name="idUsuario"
        label="Usuario"
        disabled={!idPrograma || disabled}
        validate={required ? requiredValidation : null}
        query={AppQraphQL.queries.getUsuarios}
        queryParams={{
          usuarioLike: {
            idPrograma: idPrograma || undefined,
            estado: sinInactivos ? "ACTIVO" : undefined,
            idsUsuarios: idsUsuarios || undefined
          }
        }}
        queryVariable={"usuarioLike.username_OR_nombre"}
        path={{
          path: "getUsuarios.itemsPagina",
          value: "idUsuario",
          // value: "nombreCompleto",
          label: "nombreCompleto",
          multilabel
        }}
        placeholder={!noPlaceholder ? "Usuario" : " "}
        emptyLabel={"No se encontro ningún usuario"}
        {...rest}
      />
    );
  }
}

CustomUsuarioField.propTypes = {
  idPrograma: PropTypes.number
};

export default CustomUsuarioField;
