import React, { PureComponent } from "react";

import Table from "js/components/Shared/Tabla";
import advancedFilter from "./advancedFilter.js";
import CanjeQraphQL from "js/graphql/resolvers/canje.resolver";
import columns from "./columnData";
import {
  manageAction,
  viewAction,
  addCanjeALote
} from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import AuthService from "js/utils/AuthService";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import managePrize from "../../../../components/Shared/Tabla/ActionCells/managePrize.js";

const defaultHeader = id => ({
  title: "Canjes",
  export: {
    path: "movimientos/canjes",
    perms: [Roles.CANJE_R]
  },
  queryStringMatcher: {
    idProducto: "eleccionProducto_idProducto",
    idPrograma: undefined,
    idsProgramas: "eleccionProducto_idPrograma",
    estadoCanje: "estado",
    idLote: "numeroLote",
    excluirFactura: "excluirFacturados",
    excluirCanje: "excluirLoteados",
    administracion: "eleccionProducto_producto_gestionadoPor",
    tipoProducto: "eleccionProducto_producto_tipoProducto"
  },
  importWithDialog: {
    dialog: "importarPremiosCanjes",
    perms: [Roles.CANJE_W],
    tooltip: "Importar Premios a Canjes",
    model: "Modelo_Premios_Canjes.csv"
  }
});

const defaultSort = { order: "desc", orderBy: "fecha" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    let idProgramaFE = null;
    // En la pantalla de canjes en el FE, setar el id del programa desde el localStorage
    if (window.location.pathname.indexOf("admin") === -1) {
      idProgramaFE = parseInt(localStorage.getItem("idPrograma"));
    }

    const cutsomButtons = [];

    const manageCanje = params => ({
      ...manageAction({
        ...params,
        history: this.props.history,
        perms: [Roles.CANJE_W]
      }),
      onclick: e =>
        this.props.history.push(`${window.location.pathname}/${params.itemId}`)
    });
    cutsomButtons.push(manageCanje);

    const viewCanje = params => ({
      ...viewAction({ ...params, history: this.props.history }),
      onclick: e =>
        this.props.history.push(
          `${window.location.pathname}/${params.itemId}/view`
        )
    });
    cutsomButtons.push(viewCanje);

    const canjeALote = params => ({
      ...addCanjeALote({ ...params, perms: [Roles.LOTE_W] })
    });

    const addPrize = params => ({
      ...managePrize({
        ...params,
        history: this.props.history,
        perms: [Roles.LOTE_W],
        tooltip: "Agregar premio",
        label: "Agregar Premio",
        itemName: "Premio",
        dialogTitle: "Agrega premio",
        query: null
      })
    });

    cutsomButtons.push(canjeALote, addPrize);

    return (
      <Table
        storage="FiltrosCanjesAdmin"
        query={CanjeQraphQL.queries.getCanjes}
        defaultHeader={
          idProgramaFE ? defaultHeader(idProgramaFE) : defaultHeader(id)
        }
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columns}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count} canjes`
        }
        toolbarActions={[
          "enviar-premios",
          "asignar-factura",
          "calendar",
          "progress"
        ]}
        getTipo={data => data.getCanjes}
        actions={AuthService.hasPerms([Roles.CANJE_W]) ? ["bulk"] : []}
        actionsCustom={cutsomButtons}
        buttons={["crear-lote"]}
      />
    );
  }
}

export default Tabla;
