import React from "react";
import PropTypes from "prop-types";
import { has } from "lodash";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Column from "./Column";
import get from "lodash/get";
import {
  Facebook,
  Twitter,
  Instagram,
  Whatsapp,
  Linkedin,
  Youtube,
  Google,
  Snapchat,
  Pinterest,
  Tumblr,
  Skype,
  Yahoo,
  Telegram,
  Spotify,
  Slack,
  Yammer
} from "mdi-material-ui";
import { X } from "../../utils";

class Footer extends React.Component {
  render() {
    const { classes, programa } = this.props;

    const socialIcons = {
      facebook: <Facebook className={classes.colorPrimary} />,
      twitter: <Twitter className={classes.colorPrimary} />,
      instagram: <Instagram className={classes.colorPrimary} />,
      whatsapp: <Whatsapp className={classes.colorPrimary} />,
      linkedin: <Linkedin className={classes.colorPrimary} />,
      youtube: <Youtube className={classes.colorPrimary} />,
      google: <Google className={classes.colorPrimary} />,
      snapchat: <Snapchat className={classes.colorPrimary} />,
      pinterest: <Pinterest className={classes.colorPrimary} />,
      tumblr: <Tumblr className={classes.colorPrimary} />,
      skype: <Skype className={classes.colorPrimary} />,
      yahoo: <Yahoo className={classes.colorPrimary} />,
      telegram: <Telegram className={classes.colorPrimary} />,
      spotify: <Spotify className={classes.colorPrimary} />,
      slack: <Slack className={classes.colorPrimary} />,
      yammer: <Yammer className={classes.colorPrimary} />,
      X: <X className={classes.colorPrimary} />
    };
    const columnWidth = 12 / programa.template.footer.columnasFooter.length;
    const datosContacto = get(programa, "template.footer.datosContacto", "");
    return (
      <Grid container className={classes.root} spacing={16}>
        <Grid item xs={12}>
          <Grid container justify="center">
            {programa.template.footer.columnasFooter.map(
              (column, index) =>
                column.titulo ? (
                  <Grid item xs={12} sm={columnWidth} key={index}>
                    <Column value={column} key={`${column.titulo}-${index}`} />
                  </Grid>
                ) : (
                  ""
                )
            )}
          </Grid>
          {datosContacto.length ? (
            <Grid container justify="center">
              <Typography variant="caption" className={classes.contacto}>
                {datosContacto}
              </Typography>
            </Grid>
          ) : (
            ""
          )}
          {has(programa, "template.footer.redes[0].link") && (
            <Grid container justify="center" className={classes.rrss}>
              {programa.template.footer.redes.map(rs => (
                <a
                  href={rs.link}
                  key={rs.red}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.iconBack}
                >
                  {socialIcons[rs.red]}
                </a>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object
};

Footer.defaultProps = {};

export default Footer;
