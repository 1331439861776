import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Slide
} from "@material-ui/core";

import { withNotificationContext } from "js/notification-context";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import { selectGrupoSKUById } from "js/redux/campana/campana.selectors";
import { _t } from "js/utils/TranslationService";

const styles = theme => ({
  dialogContent: {
    overflow: "visible"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogImportGrupoSKU extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: this.props.grupoSKU ? this.props.grupoSKU.nombre : "",
      file: null,
      error: undefined,
      idPrograma: this.props.match.params.id
    };
  }

  sendValues = async () => {
    const { file, nombre, idPrograma } = this.state;
    const { client, idGrupo, onTableChange, refetch } = this.props;
    this.setState({ error: undefined });
    await client
      .mutate({
        mutation: AppQraphQLImport.queries.importarGrupoSKU,
        variables: {
          idPrograma,
          file,
          nombre,
          idGrupo: idGrupo ? idGrupo : undefined
        }
      })
      .then(res => {
        this.props.openNotification("El archivo se procesó correctamente");
        this.setState({ nombre: "", file: null });
        if (onTableChange) {
          onTableChange();
        } else if (refetch) {
          refetch();
        }
        this.props.handleClose();
      })
      .catch(error => {
        this.props.openNotification(
          'Hubo un error al procesar el archivo. Ver los detalles en "Logs"'
        );
      });
  };

  handleChangeNombre = e => {
    this.setState({ nombre: e.target.value });
  };

  onFileChange = e => {
    let files = e.target.files;
    this.setState({ file: files[0] }, () => {
      console.log(this.state.file);
    });
  };

  render() {
    const { handleClose, classes, open, grupoSKU } = this.props;

    return (
      <Fragment>
        <Dialog
          open={typeof open === "undefined" ? true : open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={classes.dialogTitle}
          >
            {grupoSKU ? _t("Editar grupo SKUs") : _t("Agregar grupo SKUs")}
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <FormControl>
              <TextField
                id="standard-name"
                label="Nombre del grupo"
                className={classes.textField}
                value={this.state.nombre}
                onChange={e => this.handleChangeNombre(e)}
                margin="normal"
                error={this.state.error}
                helperText={this.state.error}
              />
            </FormControl>
            <input
              type="file"
              name="file"
              onChange={e => this.onFileChange(e)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Cancelar
            </Button>
            <Button
              disabled={
                this.state.nombre === "" || (!grupoSKU && !this.state.file)
              }
              onClick={this.sendValues}
              variant="contained"
              color="primary"
            >
              Importar
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const enhance = compose(
  withApollo,
  withNotificationContext,
  withRouter,
  withStyles(styles)
);

const mapStateToProps = (state, ownProps) => {
  return {
    grupoSKU: selectGrupoSKUById(state, ownProps.idGrupo)
  };
};

export default enhance(
  connect(
    mapStateToProps,
    null
  )(DialogImportGrupoSKU)
);
