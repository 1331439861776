const getActiveColor = theme =>
  theme.programa.template.header.menu.navbar.activeColor;

export default theme => ({
  button: {
    margin: theme.spacing.unit
  },
  navbar: {
    display: "flex"
  },
  center: {
    justifyContent: "space-around",
    flexGrow: 0,
    maxWidth: "1200px",
    width: "100%",
    margin: "0 auto"
  },
  left: {
    justifyContent: "flex-start",
    flexGrow: 0,
    maxWidth: "1200px",
    width: "100%",
    margin: "0 auto"
  },
  right: {
    justifyContent: "flex-end",
    flexGrow: 0,
    maxWidth: "1200px",
    width: "100%",
    margin: "0 auto"
  },
  items: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  },
  item: {
    padding: 10,
    display: "flex",
    alignItems: "center"
  },
  active: {
    borderBottom: `${getActiveColor(theme)}  2px solid`,
    "& p": {
      fontWeight: "bold"
    }
  },
  link: {
    // margin: "auto 15px",
    color: "inherit",
    padding: "10px 15px",
    borderTop: "2px solid transparent",
    textDecoration: "inherit",
    cursor: "pointer"
  }
});
