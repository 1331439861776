import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { _t } from "js/utils/TranslationService";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";

class advancedFilter extends PureComponent {
  render() {
    const { value, submitValue, classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <TextField
            key={"operacion"}
            name={"operacion"}
            label={_t("Tipo")}
            select
            value={value["operacion"] || ""}
            className={classes.textField}
            onChange={e => submitValue(e)}
          >
            {[
              { value: "DEBITO", label: `${_t("Débito")}` },
              { value: "CREDITO", label: `${_t("Crédito")}` }
            ].map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            key={"concepto"}
            name={"concepto"}
            label={_t("Concepto")}
            type="search"
            value={value["concepto"] || ""}
            className={classes.textField}
            onChange={e => submitValue(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            dateFormat="DD/MM/YYYY"
            name={"fechaDesde"}
            label="Desde"
            input={{
              onChange: v =>
                submitValue({
                  target: { name: "fechaDesde", value: v.toISOString() }
                }),
              value: value["fechaDesde"] || null
            }}
            className={classes.textField}
            startOfDate
            autoOk
            maxDate={moment()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            dateFormat="DD/MM/YYYY"
            name={"fechaHasta"}
            label="Hasta"
            input={{
              onChange: v =>
                submitValue({
                  target: { name: "fechaHasta", value: v.toISOString() }
                }),
              value: value["fechaHasta"] || null
            }}
            className={classes.textField}
            endOfDate
            autoOk
            maxDate={moment()}
          />
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
