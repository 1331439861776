import React from "react";
import PropTypes from "prop-types";
import SplashScreen from "js/pages/SplashScreen";
import { Query } from "react-apollo";
import CanjeGraphQL from "js/graphql/resolvers/canje.resolver";
import { Link } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { _t } from "js/utils/TranslationService";
import { numberWithCommas } from "js/utils";

const Canje = props => {
  const { classes, programa } = props;
  const programLogo = programa.template.header.logoPrincipal
    ? programa.template.header.logoPrincipal.url
    : null;
  return (
    <Query
      query={CanjeGraphQL.queries.canjeEfectuado}
      variables={{
        canjeLike: {
          idCanje: parseInt(props.computedMatch.params.idCanje, 10)
        }
      }}
      errorPolicy="all"
    >
      {({ loading, error, data }) => {
        if (loading) return <SplashScreen />;
        if (error) return `Error! ${error.message}`;
        const canje = data.misCanjes.itemsPagina[0];

        return (
          <Paper>
            <Grid container spacing={24} className={classes.root}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  {`${_t(
                    "¡Felicitaciones! Estos son los detalles de tu canje"
                  )}:`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <CardMedia
                  className={classes.media}
                  image={`${
                    canje.eleccionProducto.producto.imagenes.length !== 0
                      ? canje.eleccionProducto.producto.imagenes[0].url
                      : programLogo
                  }`}
                  title={`${
                    canje.eleccionProducto.producto.imagenes.length !== 0
                      ? canje.eleccionProducto.producto.imagenes[0].alt
                      : programLogo
                  }`}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <Typography variant="h6" gutterBottom>
                  {canje.eleccionProducto.producto.nombre}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<div>${canje.eleccionProducto.producto
                        .descripcion || ""}</div>`
                    }}
                  />
                </Typography>

                {!canje.eleccionProducto.producto.valorOrdenCompra ? (
                  <Typography variant="subtitle2" gutterBottom>
                    <b>{_t("Cantidad")}:</b> {numberWithCommas(canje.cantidad)}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" gutterBottom>
                    <b>{_t("Valor total")}:</b>{" "}
                    {numberWithCommas(
                      canje.cantidad *
                        canje.eleccionProducto.producto.valorOrdenCompra
                    )}
                  </Typography>
                )}
                <Typography variant="subtitle2" gutterBottom>
                  <b>{_t("Puntos canjeados")}:</b>{" "}
                  {numberWithCommas(canje.puntos)} {_t("puntos")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {_t("Indicaciones de entrega")}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {canje.eleccionProducto.producto.indicacionesEntrega}
                </Typography>
              </Grid>
              {canje.eleccionProducto.producto.tipoProducto === "INSTANTANEO" &&
                canje.codigosActivacion &&
                canje.codigosActivacion.length !== 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      {_t("Códigos de activación")}
                    </Typography>
                    {canje.codigosActivacion.map((codigo, index) => (
                      <Typography key={index} variant="body1" gutterBottom>
                        {codigo.codigo}
                      </Typography>
                    ))}
                    <Typography variant="body1" gutterBottom />
                  </Grid>
                )}
              <Grid item xs={12}>
                <Link to={`/catalogo`} className={classes.link}>
                  <Button variant="contained" color="primary">
                    {_t("Seguir canjeando")}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Paper>
        );
      }}
    </Query>
  );
};

Canje.propTypes = {
  classes: PropTypes.object
};

Canje.defaultProps = {};

export default Canje;
