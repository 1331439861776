const mediaStyle = {
  width: 295,
  position: "relative"
};

export default theme => ({
  container: {
    paddingLeft: 16,
    paddingRight: 16
  },
  card: {
    display: "flex",
    overflow: "visible"
  },
  media: {
    ...mediaStyle,
    backgroundSize: "contain"
  },
  mediaLogo: {
    ...mediaStyle,
    backgroundSize: "auto"
  },
  details: {
    display: "flex",
    width: "calc(100% - 250px)"
  },
  description: {
    minHeight: "3em",
    marginBottom: 15,
    height: "3.5rem"
  },
  actionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: 15,
    paddingBottom: 15,
    "& a": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit" /* no underline */
    }
  },
  heartIcon: {
    fontSize: 30,
    cursor: "pointer"
  },
  cardContent: {
    width: "100%"
  },
  cucarda: {
    top: "8%"
  },
  tooltip: {
    fontSize: "0.85em",
    maxWidth: "100%",
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.primary.main
  },
  tooltipPlacementBottom: {
    margin: "-1rem 0"
  },
  popper: {
    opacity: 1
  },
  masInfo: {
    boxSizing: "content-box",
    padding: 8,
    minWidth: 80
  }
});
