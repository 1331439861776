import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { InputTextField, ReduxWysiwyg } from "js/components/Shared/Form/Fields";
import Field from "redux-form/es/Field";
import ChipInput from "material-ui-chip-input";
import { _t } from "js/utils/TranslationService";
import { isValidEmail } from "js/helpers/validations";

export default props => {
  const onBeforeAdd = email => !isValidEmail(email);
  const { name, disabled, label, conCCO, helperText } = props;
  const renderChips = ({ input, label }) => (
    <ChipInput
      style={{
        width: "100%",
        marginBottom: "30px"
      }}
      {...input}
      label={label}
      value={input.value || []}
      newChipKeyCodes={[9, 13, 188]} // Enter, Tab, ;
      onBeforeAdd={onBeforeAdd}
      onAdd={addedChip => {
        let values = input.value || [];
        values = values.slice();
        values.push(addedChip);
        input.onChange(values);
      }}
      onDelete={deletedChip => {
        let values = input.value || [];
        values = values.filter(v => v !== deletedChip);
        input.onChange(values);
      }}
      onBlur={() => input.onBlur()}
      helperText={`${_t(
        helperText || "Separar múltiples destinatarios con"
      )} ' ;'`}
    />
  );
  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="subtitle2" gutterBottom>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InputTextField
          name={`${name}.asunto`}
          label="Asunto"
          disabled={disabled}
        />
      </Grid>
      {conCCO ? (
        <Grid item xs={12}>
          <Field
            name={`${name}.bcc`}
            label={"CCO"}
            disabled={disabled}
            placeholder={"CCO"}
            component={renderChips}
            style={{
              width: "100%",
              marginBottom: "30px"
            }}
          />

          {/*<FormControl*/}
          {/*style={{*/}
          {/*marginBottom: "30px",*/}
          {/*width: "100%"*/}
          {/*}}*/}
          {/*>*/}
          {/*<InputMultiEmail*/}
          {/*name={`${name}.cco`}*/}
          {/*label="CCO"*/}
          {/*disabled={disabled}*/}
          {/*placeholder={"CCO"}*/}
          {/*/>*/}
          {/*</FormControl>*/}
        </Grid>
      ) : (
        ""
      )}
      <Grid item xs={12}>
        <Typography variant="body2" gutterBottom>
          Mensaje
        </Typography>
        <ReduxWysiwyg
          inputName={`${name}.html`}
          height={250}
          disabled={disabled}
        />
      </Grid>
    </Fragment>
  );
};
