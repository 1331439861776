import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Link from "js/pages/TemplateBuilder2/Controls/Link";
import SortableComponent from "js/pages/TemplateBuilder2/Controls/SortableComponent";

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 750
  },
  InputLabel: {
    marginLeft: 10
  },
  links: {
    width: 350
  },
  titulo: {
    width: 350,
    margin: 25,
    padding: 0,
    marginLeft: 80,
    marginBottom: 0
  }
});

class FormFooterContenidos extends Component {
  state = {
    expanded: 1
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  onChange = key => event =>
    this.props.updateOnElement({
      ...this.props.value,
      [key]: event.target.value
    });

  onChangeLink = key => value => {
    this.props.updateOnElement({
      ...this.props.value,
      [key]: value
    });
  };
  render() {
    const { classes, idPrograma } = this.props;
    return (
      <Grid container spacing={24}>
        <Grid item xs={12} className={classes.titulo}>
          <Typography variant="subtitle2" gutterBottom>
            Título
          </Typography>
          <TextField
            variant="outlined"
            id="Link"
            placeholder="Título"
            value={this.props.value.titulo}
            onChange={this.onChange("titulo")}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} className={classes.links}>
          <SortableComponent
            items={this.props.value.links}
            newItem={{ link: "", texto: "" }}
            onChange={this.onChangeLink("links")}
            component={Link}
            maxAmountItems={4}
            metadata={{
              idPrograma
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(FormFooterContenidos);
