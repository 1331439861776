import { connect } from "react-redux";
import Descripcion from "./Descripcion";
import beneficioActions from "../actions";
import { get } from "lodash";
import AuthService from "js/utils/AuthService";

const mapStateToProps = state => ({
  beneficio: state.beneficio.beneficio,
  idBeneficio: state.beneficio.idBeneficio,
  cantidad: state.beneficio.cantidad,
  sucursales: state.beneficio.sucursales,
  mensaje: get(state, "beneficio.mensaje", ""),
  isLogged: !!AuthService.getIdUsuario()
});

const mapDispatchToProps = dispatch => ({
  beneficioActions: beneficioActions({ dispatch })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Descripcion);
