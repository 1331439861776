const drawerWidth = 240;
const toolbarMaxHeight = 50;
export default theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%"
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 28
  },
  appBar: {
    maxHeight: toolbarMaxHeight,
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      marginLeft: drawerWidth
    },
    [theme.breakpoints.up("md")]: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  },
  appBarShift: {
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  toolbar: {
    ...theme.mixins.toolbar,
    maxHeight: toolbarMaxHeight,
    minHeight: `${toolbarMaxHeight}px!important`
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative"
    }
  },
  content: {
    flexGrow: 1,
    width: "50%",
    minHeight: "93vh", //TODO: algo cambio en el style template que hubo que corregir aca el responsive
    backgroundColor: theme.palette.background.default,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`
  },
  emptyIcon: {
    paddingLeft: 12,
    paddingRight: 12
  },
  active: {
    backgroundColor: "rgba(0, 0, 0, 0.2)"
  },
  hide: {
    display: "none"
  },
  tooltip: {
    fontSize: "0.85em",
    maxWidth: "100%",
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.primary.main
  },
  tooltipPlacementBottom: {
    margin: "-0.5rem 0"
  },
  tooltipPlacementRight: {
    margin: "0 -0.4rem"
  },
  listItem: {
    minHeight: "2.5rem"
  },
  listItemText: {
    paddingRight: 0,
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  nombrePrograma: {
    flexGrow: 1
  }
});
