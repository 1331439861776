import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import ProveedorGraphQL from "js/graphql/resolvers/proveedor.resolver.js";
import { getSelectOptions } from "js/utils";
import { ESTADO } from "./constants";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import moment from "moment";

import { updateQueryParamsAsync, initialState } from "../utils";

class advancedFilter extends Component {
  state = {
    ...initialState
  };

  updateAutocompleteQueryParams = key => select => {
    this.setState(
      updateQueryParamsAsync({ key, value: select.value }),
      this.props.submitValue({ target: { name: key, value: select.value } })
    );
  };

  render() {
    const { value, submitValue, classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              label={"Proveedor"}
              onChange={this.updateAutocompleteQueryParams("idProveedor")}
              path={{
                path: ["getProveedores", "itemsPagina"],
                value: "idProveedor",
                label: "nombreFantasia"
              }}
              placeholder={"Buscar por proveedor"}
              query={ProveedorGraphQL.queries.getProveedoresAutocomplete}
              value={value["idProveedor"]}
              // multiple
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TextField
              label="Nº de Factura"
              key={"factura"}
              name={"factura"}
              type="search"
              value={value["factura"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              value={value["estadoCanje"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estadoCanje",
                id: "estadoCanje"
              }}
            >
              {getSelectOptions(ESTADO, "Todas")}
            </Select>
          </FormControl>
        </Grid>

        <Grid item container spacing={16}>
          <Grid item xs={12} md={3}>
            <FormControl className={classes.formControl}>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                name={"fechaDesde"}
                label="Desde"
                input={{
                  onChange: v =>
                    submitValue({
                      target: { name: "fechaDesde", value: v.toISOString() }
                    }),
                  value: value["fechaDesde"] || null
                }}
                startOfDate
                autoOk
                maxDate={moment()}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl className={classes.formControl}>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                name={"fechaHasta"}
                label="Hasta"
                input={{
                  onChange: v =>
                    submitValue({
                      target: { name: "fechaHasta", value: v.toISOString() }
                    }),
                  value: value["fechaHasta"] || null
                }}
                endOfDate
                autoOk
                maxDate={moment()}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
