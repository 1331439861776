const styles = theme => ({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  name: {
    display: "flex",
    alignItems: "center",
    textAlign: "left"
  },
  nameValue: {
    marginLeft: "10px",
    padding: "10px"
  },
  addInput: {
    display: "flex",
    alignItems: "flex-end"
  },
  table: {
    minWidth: 700
  },
  columnWidth100: {
    width: "100px"
  },
  columnWidthInput: {
    width: "150px",
    textAlign: "left"
  },
  border: {
    borderRight: "1px solid #ccc"
  },
  align: {
    padding: "5px",
    textAlign: "center"
  },
  formControl: {
    margin: "10px"
  },
  buttonSave: {
    flex: 1,
    display: "flex",
    alignItems: "end",
    justifyContent: "flex-end",
    marginTop: "30px"
  },
  expansionPanel: {
    boxShadow: "none"
  },
  expansionPanelText: {
    padding: "0 5px",
    maxHeight: 60
  },
  button: {
    margin: 4
  }
});
export default styles;
