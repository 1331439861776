import gql from "graphql-tag";

export const setFavorito = gql`
  mutation toggleFavorito($favoritoToggle: FavoritoSearch!) {
    toggleFavorito(favoritoToggle: $favoritoToggle) {
      producto {
        idEleccionProducto
      }
      favorito
    }
  }
`;

export default {
  mutations: {
    setFavorito
  }
};
