import { connect } from "react-redux";
import DialogSolicitud from "./DialogSolicitud";
import { selectUpdateUserPoints } from "js/redux/user/user.selectors.js";
import { updateUserPoints } from "js/redux/user/user.actions";
import beneficioActions from "../actions";
import { compose } from "recompose";

const mapStateToProps = state => ({
  beneficio: state.beneficio.beneficio,
  cantidad: state.beneficio.cantidad,
  idSucursal: state.beneficio.idSucursal,
  solicitudFail: state.beneficio.solicitudFail,
  sucursales: state.beneficio.sucursales,
  updatePoints: selectUpdateUserPoints(state)
});

const mapDispatchToProps = dispatch => ({
  beneficioActions: beneficioActions({ dispatch }),
  updateUserPoints: updatePoints => dispatch(updateUserPoints(updatePoints))
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(DialogSolicitud);
