import AuthService from "js/utils/AuthService";

const getInitialState = initialData => ({
  cantidad: 1,
  notificationMensaje: "",
  beneficio: initialData,
  favorito:
    initialData && initialData.favoritos
      ? initialData.favoritos.includes(parseInt(AuthService.getIdUsuario()))
      : false,
  notificationIsOpen: false,
  openSolicitudPopup: false,
  idSucursal: 0,
  idSolicitud: false,
  solicitudFail: ""
});

export default getInitialState;
