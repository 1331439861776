export default theme => ({
  activo: { color: "#4EC230" },
  ok: { color: "#4EC230" },
  inactivo: { color: "#DF3B3B" },
  pendiente: { color: "#DF3B3B" },
  loteado: { color: "#4EC230" },
  en_compra: { color: "#4EC230" },
  en_distribucion: { color: "#4EC230" },
  entregado: { color: "#4EC230" },
  en_pausa: { color: "#4EC230" },
  cancelado: { color: "#4EC230" },
  error: { color: "#DF3B3B" },
  controlada: { color: "#4EC230" }
});
