import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  Title: {
    color: theme.palette.action.active
  },
  Container: {
    marginBottom: 20
  },
  Row: {
    paddingLeft: 5
  }
});

export default withStyles(styles);
