import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { _t } from "js/utils/TranslationService";
import contenidos from "../contenidosList";
import Enhancer from "./container";
import AuthService from "js/utils/AuthService";

const Filters = ({ tipoContenido, idPrograma }) => {
  const { name, componente, permissions } = contenidos.find(
    contenido => contenido.tipo === tipoContenido
  );
  const idProgramaValue = idPrograma ? idPrograma : AuthService.getIdPrograma();
  const Componente = componente;
  if (AuthService.hasPerms(permissions)) {
    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            {_t(name)}
          </Typography>
        </Grid>
        <Componente idPrograma={idProgramaValue} conCancelar={"/contenidos"} />
      </Grid>
    );
  }
  return true;
};

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  idPrograma: PropTypes.number,
  idSegmento: PropTypes.number
};

Filters.defaultProps = {
  height: 1000
};

Filters.displayName = "Filters";

export default Enhancer(Filters);
