import { connect } from "react-redux";
import { compose, pure, mapProps } from "recompose";
import { getFormMeta, change } from "redux-form";
import { getFormValues } from "redux-form/immutable";
import withStyles from "./styles";

const mapStateToProps = (state, { formName }) => ({
  formMeta: getFormMeta(formName)(state),
  values: getFormValues(formName)(state)
});

const mapDispatchToProps = (dispatch, { formName }) => ({
  changeFieldValue: (field, value) => dispatch(change(formName, field, value))
});

const defaultExtraFieldProps = {
  required: true,
  disabled: false,
  classes: {}
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles,
  mapProps(({ extraProps = {}, breakpoints = {}, ...rest }) => ({
    breakpoints: { xs: true, ...breakpoints },
    extraProps: ["pais", "zona", "localidad", "direccion", "codPostal"].reduce(
      (acc, f) => ({
        ...acc,
        [f]: { ...defaultExtraFieldProps, ...extraProps[f] }
      }),
      {}
    ),
    ...rest
  })),
  pure
);
