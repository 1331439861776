import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./css/normalize.css";
import "./css/dropzone.css";

import App from "js/App/App.container";
import { Provider } from "react-redux";
import crearStore from "js/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleClientId } from "./js/constants";

const url = window.location.hostname.replace("www.", "");

ReactDOM.createRoot(document.getElementById("root")).render(
  <GoogleOAuthProvider clientId={googleClientId}>
    <Provider store={crearStore(url)}>
      <App />
    </Provider>
  </GoogleOAuthProvider>
);
