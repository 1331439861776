import React from "react";
// import PropTypes from "prop-types";
const ValueContainer = props => {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
};

export default ValueContainer;
