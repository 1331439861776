import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  Paper: {
    padding: theme.spacing.unit * 2
  },
  Dropzone: {
    width: 100,
    minWidth: 100,
    "& img": {
      maxHeight: 32
    }
  },
  imageUploader: {
    margin: 8
  },
  flex: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "3px"
  },
  marginDetails: {
    marginRight: 6
  },
  tooltipIcon: {
    color: "rgba(0, 0, 0, 0.54)"
  }
});

export default withStyles(styles);
