import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UploadReportes from "./UploadReportes";
import DeleteReportes from "./DeleteReportes";
import BackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";

class Gestion extends PureComponent {
  render() {
    const {
      classes,
      comercios,
      handleChange,
      onDelete,
      deleteForm,
      history,
      uploadReporte
    } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            <IconButton onClick={() => history.push("/admin/cln/reportes")}>
              <BackIcon />
            </IconButton>
            Gestión de Reportes Club La Nación
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <UploadReportes uploadReporte={uploadReporte} />
            <Divider classes={{ root: classes.divider }} />
            <DeleteReportes
              suggestions={comercios}
              handleChange={handleChange}
              onSubmit={onDelete}
              {...deleteForm}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

Gestion.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

Gestion.defaultProps = {
  data: []
};

Gestion.displayName = "Gestion";

export default Gestion;
