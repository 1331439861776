import React, { Component } from "react";
// import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
// import Paper from "@material-ui/core/Paper";
// import Calendar from "./Calendar";
// import Bar from "./Bar";
// import Chord from "./Chord";
// import Pie from "./Pie";
// import Stream from "./Stream";

class Dashboard extends Component {
  render() {
    const { classes, programa } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.emptymessage}>
          Bienvenido al gestor de {programa.nombre}
        </div>
        {/* <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Calendar />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Bar />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Pie />
              <Paper className={classes.paper}>Mas graficos</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Stream />
              <Paper className={classes.paper}>Mas graficos</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Chord />
              <Paper className={classes.paper}>Mas graficos</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper className={classes.paper}>Mas graficos</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper className={classes.paper}>Mas graficos</Paper>
            </Grid>
          </Grid> */}
      </div>
    );
  }
}

export default Dashboard;
