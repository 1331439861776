import gql from "graphql-tag";

export const Movimientos = {};

const fragment = `
  infoPagina {
    total
    numeroPagina
    cantidadPaginas
  }
  itemsPagina {
    idMovimiento
    idCuentaCorriente
    balance
    tipoOperacion
    operacion
    fecha
    numeroFactura
    concepto
    monto
    puntos
    cuentaCorriente {
      usuario {
        idUsuario
        username
        perfil {
          nombre
          apellido
        }
        programa {
          nombre
          idPrograma
        }
        segmento {
          nombre
          idPrograma
          idSegmento
        }
      }
    }
    solicitante {
      username
    }
  }
`;

Movimientos.fragments = {
  movimientos: gql`
    fragment movimientos on PaginaMovimiento {
      ${fragment}
    }
  `,
  misMovimientos: gql`
    fragment movimientos on PaginaMisMovimiento {
      ${fragment}
    }
  `
};

export default Movimientos;
