export const styles = theme => ({
  main: {
    padding: "10px 0px",
    marginLeft: "10px"
  },
  child: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  childName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  parentName: {
    display: "flex",
    alignItems: "center"
  },
  childTitle: {
    marginLeft: "10px"
  },
  buttonBar: {
    textAlign: "right"
  },
  button: {
    margin: 8
  },
  pointer: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center"
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: "white"
  },
  textbox: {
    padding: "5px",
    backgroundColor: "#eee",
    fontWeight: "bold"
  },
  textboxTitle: {
    height: "20px"
  },
  textContent: {
    paddingTop: "10px",
    paddingBottom: "50px",
    fontSize: "1rem"
  },
  textField: {
    width: "100%"
  },
  scrollable: {
    overflow: "auto",
    maxHeight: 200
  }
});
