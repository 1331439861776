import React, { PureComponent } from "react";
import EnhancedTable from "js/components/Shared/Tabla/EnhancedTable";
import { get } from "lodash";
import { Field } from "redux-form";
import { TextField } from "redux-form-material-ui";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";
const columnData = props => [
  { id: "idCanje", label: "Canje ID", removeSort: true },
  { id: "fecha", type: "date", label: "Fecha canje", removeSort: true },
  {
    id: "estado",
    label: "Estado",
    removeSort: true,
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "numeroLote", label: "Lote", removeSort: true },
  {
    id: "eleccionProducto",
    label: "Programa",
    removeSort: true,
    type: "custom",
    isNumeric: true,
    parser: eleccionProducto =>
      get(eleccionProducto, ["programa", "nombre"], "--")
  },
  {
    id: "usuario",
    label: "Usuario",
    removeSort: true,
    type: "custom",
    parser: usuario => get(usuario, ["username"], "--")
  },
  {
    id: "eleccionProducto",
    label: "Producto",
    type: "custom",
    removeSort: true,
    parser: eleccionProducto =>
      get(eleccionProducto, ["producto", "nombre"], "--")
  },
  { id: "puntos", label: "Puntos", removeSort: true },
  { id: "cantidad", label: "Cantidad", removeSort: true },
  {
    id: "eleccionProducto",
    label: "Costo",
    removeSort: true,
    type: "custom",
    isNumeric: true,
    parser: eleccionProducto =>
      get(eleccionProducto, ["producto", "costo"], "--")
  },
  {
    id: "costoReal",
    label: "Costo real",
    removeSort: true,
    type: "custom",
    parser: (costoReal, data) => {
      const canjesUpdated = props.canjesUpdated.find(
        canje => canje.idCanje === get(data, "idCanje")
      );
      const costoRealDefault = get(data, "costoReal", "");
      return (
        <Field
          name="selectedProgram"
          component={TextField}
          isDisabled={false}
          type="number"
          input={{
            name: "selectedProgram",
            onChange: props.changeCanje(get(data, "idCanje")),
            value: get(canjesUpdated, "costoReal", costoRealDefault)
          }}
        />
      );
    }
  }
];

class Tabla extends PureComponent {
  render() {
    const { canjes } = this.props;

    return (
      <div>
        <EnhancedTable
          showPagination={false}
          columnData={columnData(this.props)}
          actionsCustom={[]}
          actions={[]}
          hanleDeleteAction={() => undefined}
          handleRequestSort={() => undefined}
          handleSelectAllClick={() => undefined}
          handleClick={() => undefined}
          handleChangePage={() => undefined}
          handleChangeRowsPerPage={() => undefined}
          data={canjes}
          state={{
            selected: [],
            disabled: []
          }}
          total={canjes.length}
          page={1}
        />
      </div>
    );
  }
}

export default Tabla;
