import React, { PureComponent } from "react";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppQraphQL from "js/graphql/resolvers/notificacion.resolver";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";

import TablaNotificaciones from "js/pages/Admin/Programa/Notificaciones/Table";
import { styles } from "js/pages/Admin/Programa/Notificaciones/styles.js";

class Notificaciones extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: []
    };
  }

  getNotificaciones() {
    const { client } = this.props;
    const {
      match: {
        params: { id }
      }
    } = this.props;

    return client
      .query({
        query: AppQraphQL.queries.getNotificacionesByNombre,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: id,
          nombres: [
            "Targets de MEDs generados",
            "Targets de preventistas generados",
            "Recordatorio para revisar targets de MEDs",
            "Recordatorio para revisar targets de preventistas",
            "Aviso de inicio de campañas",
            "Aviso cambio de targets de preventisas"
          ]
        }
      })
      .then(res => {
        res.data.getNotificaciones.length > 0 &&
          this.setState({ initialValues: res.data.getNotificaciones });
      });
  }

  async componentDidMount() {
    await this.getNotificaciones();
  }

  render() {
    const { initialValues } = this.state;
    const {
      history,
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <div>
        <Typography variant="h5" gutterBottom>
          <IconButton
            onClick={() => history.push(`/admin/programa/${id}/meds`)}
          >
            <ArrowBack />
          </IconButton>
          Notificaciones
        </Typography>
        {initialValues && <TablaNotificaciones rows={initialValues} />}
      </div>
    );
  }
}

export default withApollo(withRouter(withStyles(styles)(Notificaciones)));
