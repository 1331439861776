import { connect } from "react-redux";
import Descripcion from "./Descripcion";
import productoActions from "../actions";
import { get } from "lodash";
import AuthService from "js/utils/AuthService";

const mapStateToProps = state => ({
  idEleccionProducto: state.producto.idEleccionProducto,
  idProducto: state.producto.idProducto,
  cantidad: state.producto.cantidad,
  sucursales: state.producto.sucursales,
  datosPerfil: state.producto.datosPerfil,
  mensaje: get(state, "producto.mensaje", ""),
  isLogged: !!AuthService.getIdUsuario()
});

const mapDispatchToProps = dispatch => ({
  productoActions: productoActions({ dispatch })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Descripcion);
