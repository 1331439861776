import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { Usuarios } = Fragmentos;

export const getUsuariosCompleto = gql`
  query getUsuario($usuarioLike: UsuarioSearch) {
    getCuentaCorriente {
      balance
    }
    misPuntosPorVencer {
      cantidad
      fecha
    }
    getUsuarios(usuarioLike: $usuarioLike) {
      itemsPagina {
        ...usuariosCompleto
      }
    }
  }

  ${Usuarios.fragments.usuariosCompleto}
`;

export const getMiUsuarioCompleto = gql`
  query getMiUsuarioCompleto {
    misPuntosPorVencer {
      cantidad
      fecha
    }
    miUsuario {
      ...usuariosCompleto
    }
  }
  ${Usuarios.fragments.usuariosCompleto}
`;

export const getPaginaUsuarios = gql`
  query getUsuarios(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: UsuarioColumn!
    $username: String
    $username_OR_nombre: String
    $estado: Estado
    $idRol: Int
    $email: String
    $idPrograma: Int
    $idSegmento: Int
    $idPais: Int
    $idZona: Int
    $idLocalidad: Int
  ) {
    getUsuarios(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      usuarioOrder: { column: $orderBy, order: $order }
      usuarioLike: {
        estado: $estado
        roles: $idRol
        username: $username
        username_OR_nombre: $username_OR_nombre
        email: $email
        idPrograma: $idPrograma
        idSegmento: $idSegmento
        idPais: $idPais
        idZona: $idZona
        idLocalidad: $idLocalidad
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        idUsuario
        username
        programa {
          nombre
        }
        estado
        roles {
          nombre
        }
        segmento {
          nombre
          descripcion
        }
        perfil {
          nombre
          apellido
          email
        }
        fechaDeAlta
        cuentaCorriente {
          balance
        }
      }
    }
  }
`;

export const getUsuarioById = gql`
  query getUsuarios($idUsuario: Int) {
    getUsuarios(usuarioLike: { idUsuario: $idUsuario }) {
      itemsPagina {
        idUsuario
        roles {
          idRol
          nombre
        }
        programa {
          idPrograma
          nombre
        }
        segmento {
          idSegmento
          descripcion
        }
        username
        estado
        ultimoLogin
        fechaDeAlta
        fechaDeBaja
        ultimaModificacion
        avatarUrl
        perfil {
          email
          otros
        }
        usuarioCreador {
          username
        }
        usuarioModificador {
          username
        }
      }
    }
  }
`;

export const getUsuariosByPrograma = gql`
  query getUsuarios($idPrograma: Int!) {
    getUsuarios(usuarioLike: { idPrograma: $idPrograma }) {
      itemsPagina {
        idUsuario
        username
        perfil {
          nombre
        }
        cuentaCorriente {
          balance
        }
      }
    }
  }
`;

export const getProgramaByUsuario = gql`
  query getUsuarios($idUsuario: Int) {
    getUsuarios(usuarioLike: { idUsuario: $idUsuario }) {
      itemsPagina {
        idUsuario
        username
        programa {
          idPrograma
          nombre
        }
      }
    }
  }
`;

export const createUsuario = gql`
  mutation createUsuario($usuario: UsuarioInput!) {
    createUsuario(usuario: $usuario) {
      username
      idUsuario
    }
  }
`;

export const updateUsuario = gql`
  mutation updateUsuario($usuario: UsuarioUpdate!) {
    updateUsuario(usuario: $usuario) {
      username
      idUsuario
    }
  }
`;

export const updateMiUsuario = gql`
  mutation updateMiUsuario($usuario: MiUsuarioUpdate!) {
    updateMiUsuario(usuario: $usuario) {
      username
      idUsuario
    }
  }
`;

export const signup = gql`
  mutation signUp($usuario: UsuarioSignUp!) {
    signUp(usuario: $usuario) {
      username
      roles {
        nombre
      }
    }
  }
`;

export const isLoggedIn = gql`
  query isLoggedIn {
    isLoggedIn
  }
`;

export const logout = gql`
  query logout {
    logout
  }
`;

export const resetPassword = gql`
  mutation resetPassword($usuario: String, $idPrograma: Int) {
    resetPassword(usuario: $usuario, idPrograma: $idPrograma) {
      idUsuario
      username
      email
    }
  }
`;

export const blanquearPassword = gql`
  mutation blanquearPassword($idUsuario: Int!) {
    blanquearPassword(idUsuario: $idUsuario) {
      idUsuario
      username
    }
  }
`;

export const generarToken = gql`
  mutation generarToken($idPrograma: Int!, $idSegmento: Int!) {
    generarToken(idPrograma: $idPrograma, idSegmento: $idSegmento)
  }
`;

export const getUsuarios = gql`
  query getUsuarios($usuarioLike: UsuarioSearch) {
    getUsuarios(
      usuarioLike: $usuarioLike
      usuarioOrder: { column: username, order: desc }
    ) {
      itemsPagina {
        idUsuario
        nombre: username
        perfil {
          nombre
          apellido
        }
        nombreCompleto
        cuentaCorriente {
          balance
        }
      }
    }
  }
`;

export const getMiUsuario = gql`
  query getMiUsuario {
    miUsuario {
      idUsuario
      username
      avatarUrl
      roles {
        idRol
        nombre
      }
      perfil {
        nombre
        apellido
        roles
      }
      cuentaCorriente {
        balance
      }
      segmento {
        idSegmento
        nombre
      }
    }
  }
`;

export const getRedirect = gql`
  query getRedirect {
    miUsuario {
      usoClaveDeUnUso
      perfil {
        nombre
        apellido
        email
        fecha_de_nacimiento
        ubicacion
        otros
      }
    }
    misDefinicionesPerfil(
      definicionPerfilLike: { requeridoPerfil: true, visiblePerfil: true }
    ) {
      key
    }
    isLoggedIn
    misAcciones(accionLike: { estado: ACTIVA }) {
      itemsPagina {
        _id
        direccionamientoObligatorio
        tipo
        participaciones {
          estado
        }
      }
    }
  }
`;

export const generarTokenParaUsoExterno = gql`
  mutation generarTokenParaUsoExterno($idPrograma: Int!) {
    generarTokenParaUsoExterno(idPrograma: $idPrograma)
  }
`;

export default {
  queries: {
    getMiUsuario,
    getMiUsuarioCompleto,
    getPaginaUsuarios,
    getUsuarios,
    getUsuariosCompleto,
    getUsuarioById,
    getUsuariosByPrograma,
    getProgramaByUsuario,
    logout,
    isLoggedIn,
    getRedirect
  },
  mutations: {
    createUsuario,
    updateUsuario,
    updateMiUsuario,
    resetPassword,
    blanquearPassword,
    generarToken,
    signup,
    generarTokenParaUsoExterno
  }
};
