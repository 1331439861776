import React from "react";

const getUrl = (url, resource) => {
  const path =
    ["http", "https"].indexOf(url) > -1
      ? url
      : `${window.location.protocol}//${url}`;
  return resource ? path + "/" + resource : path;
};

const Redirect = props => {
  const { value, resource = "", path, classes } = props;
  return (
    <a
      href={getUrl(path, resource)}
      rel="noopener noreferrer"
      className={classes.link}
      target="_blank"
    >
      {value}
    </a>
  );
};

export default Redirect;
