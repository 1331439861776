import gql from "graphql-tag";

export const getVentasProductosCodificados = gql`
  query getVentasProductosCodificados(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: VentaProductoCodificadoColumn!
    $idPrograma: Int
    $idUsuario: Int
    $idSegmento: Int
    $idProducto: ID
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getVentasProductosCodificados(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      ventaOrder: { column: $orderBy, order: $order }
      ventaLike: {
        idPrograma: $idPrograma
        idUsuario: $idUsuario
        idProducto: $idProducto
        idSegmento: $idSegmento
      }
      ventaRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        total
        fechaCreacion
        usuario {
          username
          segmento {
            nombre
          }
        }
        producto {
          codigo
          nombre
        }
      }
    }
  }
`;

export const getMisVentasProductosCodificados = gql`
  query getMisVentasProductosCodificados(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: VentaProductoCodificadoColumn!
    $idPrograma: Int
    $idProducto: ID
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getMisVentasProductosCodificados(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      ventaOrder: { column: $orderBy, order: $order }
      ventaLike: { idPrograma: $idPrograma, idProducto: $idProducto }
      ventaRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        total
        fechaCreacion
        usuario {
          username
          rol {
            nombre
          }
        }
        producto {
          codigo
          nombre
        }
      }
    }
  }
`;

export const getVentaProductoCodificadoById = gql`
  query getVentasProductoCodificado($ventaLike: VentaProductoCodificadoSearch) {
    getVentasProductoCodificado(ventaLike: $ventaLike) {
      itemsPagina {
        _id
        total
        fechaCreacion
        usuario {
          username
          rol {
            nombre
          }
        }
        producto {
          codigo
          nombre
        }
        codigos {
          tipo
          codigoBarras
          segundoCodigoBarras
          codigoQR
          codigoVerificacion
          usados
          fechaCreacion
          fechaUso
        }
      }
    }
  }
`;

export const getMiVentaProductoCodificadoById = gql`
  query getMisVentasProductoCodificado(
    $ventaLike: VentaProductoCodificadoSearch
  ) {
    getMisVentasProductoCodificado(ventaLike: $ventaLike) {
      itemsPagina {
        _id
        total
        fechaCreacion
        usuario {
          username
          rol {
            nombre
          }
        }
        producto {
          codigo
          nombre
        }
        codigos {
          tipo
          codigoBarras
          segundoCodigoBarras
          codigoQR
          codigoVerificacion
          usados
          fechaCreacion
          fechaUso
        }
      }
    }
  }
`;

export const createVentaProductoCodificado = gql`
  mutation createVentaProductoCodificado(
    $venta: VentaProductoCodificadoCreate!
  ) {
    createVentaProductoCodificado(venta: $venta) {
      _id
    }
  }
`;

export default {
  queries: {
    getVentasProductosCodificados,
    getMisVentasProductosCodificados,
    getVentaProductoCodificadoById,
    getMiVentaProductoCodificadoById
  },
  mutations: {
    createVentaProductoCodificado
  }
};
