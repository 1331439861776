import React, { PureComponent, Fragment } from "react";
import Form from "./Form";
import CuentaCorrienteQraphQL from "js/graphql/resolvers/cuentaCorriente.resolver";

class AdminNewUsuariosCuentaCorriente extends PureComponent {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { idUsuario }
      }
    } = this.props;
    const idPrograma = this.props.programa.idPrograma;
    this.state = {
      initialValues: {
        selectedProgram: idPrograma ? parseInt(idPrograma) : false,
        idPrograma: idPrograma ? parseInt(idPrograma) : undefined,
        idUsuario: idUsuario ? parseInt(idUsuario) : undefined,
        selectedUsuario: idUsuario ? parseInt(idUsuario) : false,
        concepto: undefined,
        puntos: undefined,
        tipo: "CREDITO",
        conceptoValue: false
      },
      disabled: !!idUsuario,
      id: undefined
    };
  }

  async submit(values) {
    const { client, history } = this.props;
    const variables = {
      puntos: {
        idUsuario: values.selectedUsuario,
        puntosAsignados: parseInt(values.puntos),
        concepto: values.concepto
      },
      operacion: values.tipo
    };

    await client
      .mutate({
        mutation: CuentaCorrienteQraphQL.queries.CCMovimientoDeProgramaAUsuario,
        variables
      })
      .then(res => {
        const message = "Movimiento generado correctamente";
        this.props.openNotification(message);
        history.goBack();
      })
      .catch(error => {
        const message = error.graphQLErrors[0].message;
        // TODO: mostrar error por pantalla, las notificaciones tienen texto estatico por tema traducciones
        this.props.openNotification(message);
      });
  }

  render() {
    const { initialValues, disabled } = this.state;

    return (
      <Fragment>
        <Form
          // valuesForm={initialValues}
          disabled={disabled}
          initialValues={initialValues}
          onSubmit={this.submit.bind(this)}
        />
      </Fragment>
    );
  }
}

export default AdminNewUsuariosCuentaCorriente;
