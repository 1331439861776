import React, { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import client from "js/App/client.graphql.js";
import FacturaGraphQL from "js/graphql/resolvers/facturas.resolver";
import FormControl from "@material-ui/core/FormControl";
import Slide from "@material-ui/core/Slide";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import ProveedorGraphQL from "js/graphql/resolvers/proveedor.resolver.js";
import CanjeGraphQL from "js/graphql/resolvers/canje.resolver.js";
import get from "lodash/get";
import ErrorList from "js/components/Shared/ErrorList";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogFactura extends React.Component {
  state = {
    idFactura: "",
    idProveedor: "",
    facturas: [],
    errors: null
  };

  handleChange = key => async select => {
    await this.setState({ [key]: select.value });
    if (key === "idProveedor") this.fetchFacturas();
  };

  async componentDidMount() {
    const { selected } = this.props;
    const idCanje = selected[0];
    if (!idCanje) return;
    const { data } = await client.query({
      query: CanjeGraphQL.queries.getCanje,
      fetchPolicy: "cache-first",
      errorPolicy: "all",
      variables: { idCanje }
    });
    if (!data) return;
    const canje = get(data, "getCanjes.itemsPagina[0]", null);
    await this.setState({
      idFactura: get(canje, "factura.idFactura", ""),
      idProveedor: get(
        canje,
        "eleccionProducto.producto.proveedor.idProveedor",
        ""
      )
    });
    this.fetchFacturas();
  }

  fetchFacturas = async () => {
    const { idProveedor } = this.state;
    if (!idProveedor) return this.setState({ facturas: [] });
    const { data } = await client.query({
      query: FacturaGraphQL.queries.getFacturas,
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      variables: { idProveedor }
    });
    this.setState({ facturas: data || [] });
  };

  sendValues = async (idFactura, canjes) => {
    const { data, errors } = await client.mutate({
      mutation: FacturaGraphQL.mutations.bulkAsignarFacturas,
      errorPolicy: "all",
      variables: { idFactura, canjes }
    });
    if (errors) {
      this.setState({ errors: errors[0] });
      return;
    }
    if (data) {
      const message = data
        ? "Se ha actualizado la factura"
        : "No se ha podido actualizar la factura";
      this.props.openNotification(message);
      this.props.onTableChange();
    }
    this.props.handleClose();
  };

  render() {
    const { open, handleClose, classes } = this.props;
    return (
      <Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          classes={{ paperScrollPaper: classes.root }}
        >
          <DialogTitle id="alert-dialog-slide-title">
            Asignar Factura
          </DialogTitle>
          <DialogContent className={classes.root}>
            <FormControl className={classes.formControl}>
              <TypeAhead
                label={"Proveedor"}
                onChange={this.handleChange("idProveedor")}
                path={{
                  path: ["getProveedores", "itemsPagina"],
                  value: "idProveedor",
                  label: "nombreFantasia"
                }}
                placeholder={"Buscar por proveedor"}
                query={ProveedorGraphQL.queries.getProveedoresAutocomplete}
                value={this.state.idProveedor}
                disabled
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TypeAhead
                label={"Factura"}
                disabled={!this.state.idProveedor}
                onChange={this.handleChange("idFactura")}
                path={{
                  path: ["getFacturas", "itemsPagina"],
                  value: "idFactura",
                  label: "numeroFactura"
                }}
                placeholder={"Buscar por factura"}
                suggestions={this.state.facturas}
                value={this.state.idFactura}
                noOptionsMessage={
                  "No hay facturas para el proveedor seleccionado."
                }
              />
            </FormControl>
            <ErrorList errors={this.state.errors} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Volver
            </Button>
            <Button
              onClick={() =>
                this.sendValues(this.state.idFactura, this.props.selected)
              }
              variant="contained"
              color="primary"
              disabled={!this.state.idFactura}
            >
              Asignar Factura
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DialogFactura.defaultProps = {};

DialogFactura.propTypes = {};

export default DialogFactura;
