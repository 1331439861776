export const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  itemTextSecondary: {
    color: "rgba(0, 0, 0, 0.54)",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.01071em"
  },
  inactivo: {
    color: "#DF3B3B",
    display: "inline"
  },
  activo: {
    color: "#4EC230",
    display: "inline"
  }
});
