import React, { PureComponent } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { tinymceKey } from "js/constants";

import { styles } from "js/pages/Admin/Programa/Legales/WysiwygPanel/styles.js";
import "./styles.css";

class wysiwygPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { textHTML: "" };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps = props => {
    const { initialValue } = props;
    if (initialValue) {
      this.setState({ textHTML: initialValue });
    }
  };

  handleChange(value) {
    const { onChange, inputName: name } = this.props;
    this.setState({ textHTML: value });
    onChange({ target: { name, value } });
  }

  render() {
    const {
      classes,
      name,
      saveAction = null,
      cancelAction = null,
      disabled,
      height = 500
    } = this.props;
    const { textHTML } = this.state;
    return (
      <div className={classes.rootContainer}>
        {name && (
          <Typography variant="h5" gutterBottom>
            {name}
          </Typography>
        )}
        <div className={classes.root}>
          <Editor
            value={textHTML || ""}
            apiKey={tinymceKey}
            init={{
              plugins: "link image table emoticons preview code",
              height,
              toolbar: [
                "undo redo | styleselect | fontsizeselect fontselect | bold italic underline | blockquote | link image",
                "bullist | numlist",
                "alignleft aligncenter alignright | table | emoticons | preview | code"
              ]
            }}
            onEditorChange={this.handleChange}
            disabled={disabled}
          />
          <Grid className={classes.actionButtons} item xs>
            {cancelAction !== null && (
              <Button
                variant="contained"
                className={classes.button}
                onClick={cancelAction}
              >
                Cancelar
              </Button>
            )}
            {saveAction !== null && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => saveAction(textHTML)}
              >
                Guardar
              </Button>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

wysiwygPanel.defaultProps = {
  onChange: () => undefined
};

export default withApollo(withRouter(withStyles(styles)(wysiwygPanel)));
