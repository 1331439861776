import React from "react";
import { MenuItem, SvgIcon } from "@material-ui/core";
import { isNumber } from "lodash";
import { css, cx } from "emotion";
import { addImageURL, ssoURL } from "js/constants";
import LinksTrackingGraphQL from "js/graphql/resolvers/linksTracking.resolver";
import AuthService from "js/utils/AuthService";

export const mergeStyles = ({
  classes = {},
  stylesFromTemplate = {},
  componentName
}) => {
  const selectorClasses = [
    ...new Set([...Object.keys(classes), ...Object.keys(stylesFromTemplate)])
  ];

  const mergedStyles = {};
  selectorClasses.forEach(
    selectorClass =>
      (mergedStyles[selectorClass] = cx(
        classes[selectorClass],
        css(stylesFromTemplate[selectorClass]),
        `${componentName}-${selectorClass}`
      ))
  );

  return mergedStyles;
};

export const X = props => {
  return (
    <SvgIcon {...props}>
      <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
    </SvgIcon>
  );
};

export const Shield = props => {
  return (
    <SvgIcon {...props}>
      <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z" />
    </SvgIcon>
  );
};
export const ShieldRemove = props => {
  return (
    <SvgIcon {...props}>
      <path d="M22.27 21.73l-3.54-3.55L5.78 5.23 2.27 1.72 1 2.99 3.01 5H3v6c0 5.55 3.84 10.74 9 12 2.16-.53 4.08-1.76 5.6-3.41L21 23l1.27-1.27zM13 9.92l6.67 6.67C20.51 14.87 21 12.96 21 11V5l-9-4-5.48 2.44L11 7.92l2 2z" />
    </SvgIcon>
  );
};

export const youtube_parser = url => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#]*).*/;
  var match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

export const getSelectOptions = (options, all = "Todos") =>
  [
    <MenuItem key={""} value={""}>
      {all}
    </MenuItem>
  ].concat(
    options.map(op => (
      <MenuItem key={op.value} value={op.value}>
        {op.label}
      </MenuItem>
    ))
  );

export const numberWithCommas = (x, decimalNumbers = 2, rounded = false) => {
  if (!isNumber(x)) return x;
  let power = Math.pow(10, decimalNumbers);
  const afterComma = (rounded ? Math.round(x * power) / power : x)
    .toString()
    .split(".")[1];
  return x
    .toString()
    .split(".")[0]
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    .concat(
      !!afterComma && decimalNumbers > 0
        ? `,${afterComma.slice(0, decimalNumbers)}`
        : ""
    );
};

export const getUrl = url =>
  url && url.includes("http") ? url : `http://${url}`;

// To fix a JS bug when multiplying decimal numbers
const precision = a => {
  if (!isFinite(a)) return 0;
  var e = 1,
    p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }
  return p;
};
export const multDec = (a, b) => {
  const fixNumber = precision(a) + precision(b);
  const newA = a * Math.pow(10, precision(a));
  const newB = b * Math.pow(10, precision(b));
  return (newA * newB) / Math.pow(10, fixNumber);
};
export const divDec = (a, b) => {
  const fixNumber = precision(b) - precision(a);
  const newA = a * Math.pow(10, precision(a));
  const newB = b * Math.pow(10, precision(b));
  return (newA / newB) * Math.pow(10, fixNumber);
};
//

export const getSSOUrl = () => {
  return ssoURL + "metadata.xml";
};
export const ssoLogin = () => {
  return fetch(ssoURL + "login", { method: "GET", mode: "cors" })
    .then(response => response.json())
    .then(
      response => (window.location.href = response.url)
      // fetch(response.url, { method: "GET" }).then(response => {
      //   console.log(response);
      // })
    );
};

export const uploadImage = ({ width, height, context = "image" }) => image => {
  const body = new window.FormData();
  body.append("image", image);
  body.append("context", context);
  if (height) body.append("height", height);
  if (width) body.append("width", width);
  return fetch(addImageURL, { method: "POST", body })
    .then(response => response.json())
    .then(({ url }) => getUrl(url));
};

export const openNewWindow = link =>
  window.open(link, "_blank", "location=yes,toolbar=yes");

export const openNewTab = link => window.open(link, "_blank");

export const changeTab = link => (window.location.href = link);

export const getUrlWithQueryString = (url, query = {}) =>
  `${url}?` +
  Object.keys(query || {})
    .map(k => `${k}=${query[k]}`)
    .join("&");

export const copyToClipboard = (
  str,
  openNotification = () => undefined,
  msg = "Copiado al portapapeles"
) => {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  openNotification(msg);
};

/**
 * Chequea primero si el programa tiene activado el módulo de trackeo de links.
 * Si es el caso busca los links que se desean trackear.
 * Si el link que se clickeó pertence a la lista llama al servicio que agrega la data a la BD.
 * @param link String
 * @param client GraphQL Client
 */
export const trackLinkClick = async (link, client, origen) => {
  const trackModuleActive = AuthService.getTrackLinks();
  const trackearTodos = AuthService.getTrackearTodosLinks();

  if (trackModuleActive) {
    const links = AuthService.getLinksToTrack();
    if (link) {
      /**
       * En caso de que se trackee todo, mandamos directamente el link
       */
      if (trackearTodos) {
        return await client
          .mutate({
            mutation: LinksTrackingGraphQL.mutations.trackLink,
            errorPolicy: "all",
            variables: {
              link: link,
              origen,
              idPrograma: parseInt(AuthService.getIdPrograma())
            }
          })
          .then(res => {
            console.log(res);
            return true;
          })
          .catch(error => {
            console.log(error);
            return;
          });
      }

      /**
       * En caso contrario, comprobamos si existe el link para trackear
       */
      let linkToSend = null;
      const tracklink = links.some(item => {
        if (link.indexOf(item) !== -1) {
          linkToSend = item;
          return true;
        }
        return false;
      });

      if (tracklink) {
        await client
          .mutate({
            mutation: LinksTrackingGraphQL.mutations.trackLink,
            errorPolicy: "all",
            variables: {
              link: linkToSend,
              origen,
              idPrograma: parseInt(AuthService.getIdPrograma())
            }
          })
          .then(res => {
            console.log(res);
            return true;
          })
          .catch(error => {
            console.log(error);
            return;
          });
      }
    } else {
      return;
    }
  } else {
    return;
  }
};
