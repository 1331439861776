export default theme => ({
  root: {
    marginTop: 10,
    marginBottom: 10
  },
  input: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "0 12px",
    boxSizing: "border-box",
    minWidth: "100%"
  },
  puntos: {
    width: "100%"
  }
});
