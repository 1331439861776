import React, { Fragment, Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { get } from "lodash";
import ProgramaGraphQL from "js/graphql/resolvers/programa.resolver.js";
import PerfilGraphQL from "js/graphql/resolvers/perfil.resolver.js";
import client from "js/App/client.graphql.js";
import { AsyncTypeAhead, TypeAhead } from "js/components/Shared/Form/Fields";
import { numberWithCommas } from "js/utils";
import { isInteger } from "js/helpers/validations";
import {
  InputTextField,
  InputNumberField,
  ListField
} from "js/components/Shared/Form/Fields";
import UsuarioGraphQL from "js/graphql/resolvers/usuario.resolver";
import {
  maxSaldoPrograma,
  maxMovimientoPuntosPorUsuario,
  getValuesFromProps
} from "../../utils";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import UserQraphQL from "js/graphql/resolvers/usuario.resolver";
import "./asynctypeahead.css";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuarios: [],
      balancePrograma: undefined,
      maximoMovimientoPuntos: undefined,
      gerencia: ""
    };
    client
      .query({
        query: UserQraphQL.queries.getMiUsuarioCompleto,
        fetchPolicy: "cache-first"
      })
      .then(({ data }) => {
        const {
          miUsuario: { perfil }
        } = data;
        const otros = perfil.otros ? JSON.parse(perfil.otros) : {};
        const gerencia = otros.gerencia;

        this.setState({
          gerencia,
          conGerencia: otros.gerencia !== undefined
        });
      });
  }

  onChangePrograma = () => {
    const { selectedProgram: idPrograma } = this.props;
    const hasPerm = AuthService.hasPerms([Roles.PROGRAMA_LISTA]);
    if (!idPrograma) return this.setState({ balancePrograma: undefined });
    client
      .query({
        query: hasPerm
          ? ProgramaGraphQL.queries.getBalanceByIdPrograma
          : ProgramaGraphQL.queries.getBalanceMiPrograma,
        errorPolicy: "all",
        fetchPolicy: "network-only",
        variables: hasPerm
          ? { idPrograma }
          : {
              url: window.location.hostname.replace("www.", "")
            }
      })
      .then(res => {
        const {
          cuentaCorriente: { balance: balancePrograma },
          maximoMovimientoPuntos
        } = hasPerm
          ? get(res, "data.getProgramas.itemsPagina[0]", {})
          : get(res, "data.getPrograma", {});
        this.setState({ balancePrograma, maximoMovimientoPuntos });
      });
    // this.updateUsuariosList();
  };

  updateUsuariosList() {
    const { selectedProgram: idPrograma } = this.props;
    const gerencia = this.state.gerencia;
    client
      .query({
        query: UsuarioGraphQL.queries.getUsuarios,
        errorPolicy: "all",
        fetchPolicy: "network-only",
        variables: { usuarioLike: { idPrograma, gerencia } }
      })
      .then(res => {
        this.setState({
          usuarios: get(res, "data.getUsuarios.itemsPagina", [])
        });
      });
  }

  async componentDidMount() {
    const {
      initialValues: { idUsuario, id: idPrograma },
      client,
      changeProgramaValue
    } = this.props;
    if (!idUsuario || idPrograma) return;
    const { data } = await client.query({
      query: UsuarioGraphQL.queries.getProgramaByUsuario,
      errorPolicy: "all",
      variables: { idUsuario }
    });
    const programaUsuario = get(
      data,
      "getUsuarios.itemsPagina[0].programa.idPrograma"
    );
    changeProgramaValue("selectedProgram", programaUsuario);
    changeProgramaValue("selectedUsuario", idUsuario);
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.selectedProgram !== prevProps.selectedProgram ||
      this.props.initialValues.gerencia !== prevProps.initialValues.gerencia
    ) {
      this.onChangePrograma();
    }
  }

  getBalanceUsuario = () => {
    const { usuarios } = this.state;
    const { selectedUsuario } = this.props;
    const usuario = usuarios.find(u => u.idUsuario === selectedUsuario);
    return get(usuario, "cuentaCorriente.balance", undefined);
  };

  getNewBalanceUsuario = balance => {
    const { puntos, tipo } = this.props;
    if (isNaN(puntos) || !tipo || isNaN(balance)) return undefined;
    return tipo === "CREDITO"
      ? parseInt(balance) + parseInt(puntos)
      : parseInt(balance) - parseInt(puntos);
  };

  getNewBalancePrograma = balance => {
    const { puntos, tipo } = this.props;
    if (isNaN(puntos) || !tipo || isNaN(balance)) return undefined;
    return tipo === "CREDITO" ? parseInt(balance) - parseInt(puntos) : balance;
  };

  render() {
    const {
      onSubmit,
      changeProgramaValue,
      classes,
      selectedProgram,
      history,
      // match: {
      //   params: { id }
      // },
      disabled,
      selectedProgram: idPrograma
    } = this.props;
    const { balancePrograma, maximoMovimientoPuntos } = this.state;
    const balanceUsuario = this.getBalanceUsuario();
    const newBalanceUsuario = this.getNewBalanceUsuario(balanceUsuario);
    const newBalancePrograma = this.getNewBalancePrograma(balancePrograma);
    const values = getValuesFromProps(this.props);

    const submitEnabled = values => {
      let valid =
        !!Object.values(values).every(value => value) && values.puntos > 0;
      valid &= !isInteger(values.puntos);
      valid &= !maxSaldoPrograma(balancePrograma, values.tipo)(values.puntos);
      valid &= !maxMovimientoPuntosPorUsuario(
        maximoMovimientoPuntos,
        values.tipo
      )(values.puntos);
      return valid;
    };

    const gerencia = this.state.gerencia;
    const puedeEditarGerencia = AuthService.hasPerms([
      Roles.CC_SELECT_GERENCIA
    ]);

    return (
      <Fragment>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Typography variant="h5" style={{ flex: 1 }}>
              Nuevo movimiento
            </Typography>
          </Grid>
        </Grid>
        <form className={classes.container}>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12} sm={4} className={classes.grid}>
              <Typography variant="subtitle2" gutterBottom>
                Saldo CC Programa
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={!selectedProgram ? classes.empty : undefined}
              >
                {balancePrograma ? numberWithCommas(balancePrograma) : "--"}
                <br />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.grid}>
              <Typography variant="subtitle2" gutterBottom>
                Nuevo saldo CC Programa
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={
                  !selectedProgram
                    ? classes.empty
                    : newBalancePrograma < 0
                      ? classes.error
                      : undefined
                }
              >
                {newBalancePrograma || newBalancePrograma === 0
                  ? numberWithCommas(newBalancePrograma)
                  : "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12} sm={4}>
              <AsyncTypeAhead
                name="selectedUsuario"
                label={"Usuarios"}
                path={{
                  path: "getUsuarios.itemsPagina",
                  value: "idUsuario",
                  multilabel: [
                    "perfil.nombre",
                    " ",
                    "perfil.apellido",
                    " (",
                    "nombre",
                    ")"
                  ]
                }}
                placeholder={"Buscar por usuario"}
                noOptionsMessage={"No hay usuarios"}
                onChange={select =>
                  changeProgramaValue(
                    "selectedUsuario",
                    select[0] ? select[0].value : undefined
                  )
                }
                query={UsuarioGraphQL.queries.getUsuarios}
                queryParams={{
                  usuarioLike: {
                    idPrograma: idPrograma,
                    gerencia: this.state.gerencia
                  }
                }}
                bsSize={"lg"}
                queryVariable={"usuarioLike.username_OR_nombre"}
                emptyLabel={"No se encontro ningún usuario"}
                value={get(this.props, "selectedUsuario", null)}
                disabled={!selectedProgram || disabled}
                useCache={false}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.grid}>
              <Typography variant="subtitle2" gutterBottom>
                Saldo CC Usuario
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={
                  !this.props.selectedUsuario ? classes.empty : undefined
                }
              >
                {balanceUsuario ? numberWithCommas(balanceUsuario) : "--"}
                <br />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.grid}>
              <Typography variant="subtitle2" gutterBottom>
                Nuevo saldo CC Usuario
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={
                  !this.props.selectedUsuario
                    ? classes.empty
                    : newBalanceUsuario < 0
                      ? classes.error
                      : undefined
                }
              >
                {newBalanceUsuario || newBalanceUsuario === 0
                  ? numberWithCommas(newBalanceUsuario)
                  : "--"}
              </Typography>
            </Grid>
          </Grid>

          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="concepto"
                label="Concepto"
                disabled={!selectedProgram}
                required
              />
              <InputTextField
                style={{ display: "none" }}
                name="conceptoValue"
                label="Concepto"
                disabled={!selectedProgram}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ListField
                name="tipo"
                label="Tipo"
                options={[
                  { label: "Crédito", value: "CREDITO" },
                  { label: "Débito", value: "DEBITO" }
                ]}
                disabled={!selectedProgram}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputNumberField
                name="puntos"
                label="Puntos"
                disabled={!selectedProgram}
                required
                customValidations={[
                  isInteger,
                  maxSaldoPrograma(balancePrograma, this.props.tipo),
                  maxMovimientoPuntosPorUsuario(
                    maximoMovimientoPuntos,
                    values.tipo
                  )
                ]}
              />
            </Grid>
          </Grid>

          {this.state.conGerencia ? (
            <Grid className={classes.grid} container spacing={24}>
              <Grid item xs={12} sm={4}>
                <TypeAhead
                  path={{
                    path: "getGerencias"
                  }}
                  placeholder={"Buscar por gerencia"}
                  query={PerfilGraphQL.queries.getGerencias}
                  queryParams={{ idPrograma: idPrograma }}
                  noOptionsMessage={"No hay gerencias"}
                  key={"cuentaCorriente_usuario_gerencia"}
                  name={"cuentaCorriente_usuario_gerencia"}
                  label={"Gerencia"}
                  onChange={e => this.setState({ gerencia: e.value })}
                  value={gerencia}
                  disabled={!puedeEditarGerencia}
                  required
                  style={{
                    width: "100%"
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          <Grid className={classes.grid} container>
            <Grid className={classes.gridButton} item xs>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => history.push("/cc-usuarios")}
              >
                Cancelar
              </Button>
              <Button
                disabled={!submitEnabled(values)}
                type="button"
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                onClick={() => onSubmit(values)}
              >
                Ejecutar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Fragment>
    );
  }
}

export default Form;
