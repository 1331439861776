import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

const TabContent = ({
  tabKey,
  titulo,
  descripcion,
  handleChangeTabContent,
  classes,
  index
}) => {
  return (
    <form className={classes.container} noValidate autoComplete="off">
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <TextField
            classes={{ root: classes.textField }}
            fullwidth
            id="standard-titulo"
            label="Título"
            className={classes.textField}
            value={titulo}
            onChange={handleChangeTabContent(tabKey, "titulo", index)}
            margin="normal"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            classes={{ root: classes.textField }}
            fullwidth
            id="standard-multiline-texto"
            label="Descripción"
            multiline
            rowsMax="4"
            value={descripcion}
            onChange={handleChangeTabContent(tabKey, "descripcion", index)}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default TabContent;
