import header from "./header";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { withNotificationContext } from "js/notification-context";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  }
});

const enhance = compose(
  withNotificationContext,
  withRouter,
  withStyles(styles)
);

export default enhance(header);
