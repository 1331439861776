export const ESTADOS = [
  { value: "ACTIVA", label: "Activa" },
  { value: "BORRADOR", label: "Borrador" },
  { value: "CANCELADA", label: "Cancelada" },
  { value: "LISTA", label: "Lista" },
  { value: "FINALIZADA", label: "Finalizada" }
];

export const DIRECCIONAMIENTO = [
  { value: "NINGUNO", label: "Ninguno" },
  { value: "SOLO_LOGIN", label: "Solo login" },
  { value: "TODOS", label: "Todos" }
];

export const MODO_MAIL_RESULTADO = [
  { value: "PARTICIPANTES", label: "Usuarios participantes" },
  { value: "ACTIVOS", label: "Usuarios activos" }
];

export const TIPO_ACCION = [
  { value: "ENCUESTA", label: "Encuesta", form: "encuesta" },
  // { value: "PRODE", label: "Prode", form: "" },
  // { value: "REGALO", label: "Regalo", form: "" },
  { value: "FORMULARIO", label: "Referido", form: "formulario" },
  { value: "TRIVIA", label: "Trivia", form: "trivia" }
];

export const MATEMATICA_RANKING = [
  { value: "MAYOR_PUNTAJE", label: "Mayor puntaje" },
  { value: "MENOR_PUNTAJE", label: "Menor puntaje" },
  { value: "PROMEDIO_PUNTOS", label: "Promedio de puntos" },
  { value: "SUMA_PARTICIPACIONES", label: "Suma de participaciones" },
  { value: "SUMA_PUNTOS", label: "Suma de puntos" }
];

export const getEstados = ({ nuevo, edit, initialEstado }) => {
  if (nuevo)
    return ESTADOS.filter(
      ({ value }) => ["BORRADOR", "LISTA"].indexOf(value) >= 0
    );
  if (edit) {
    switch (initialEstado) {
      case "ACTIVA":
        return ESTADOS.filter(
          ({ value }) => ["ACTIVA", "CANCELADA"].indexOf(value) >= 0
        );
      case "CANCELADA":
        return ESTADOS.filter(({ value }) => ["CANCELADA"].indexOf(value) >= 0);
      case "BORRADOR":
      case "LISTA":
        return ESTADOS.filter(({ value }) => value !== "FINALIZADA");
      default:
        return ESTADOS;
    }
  }
  return ESTADOS;
};
