import gql from "graphql-tag";

export const getJerarquia = gql`
  query getJerarquia($idUsuario: Int!) {
    getJerarquia(idUsuario: $idUsuario) {
      idUsuario
      idJefeNivel1
      idJefeNivel2
      idJefeNivel3
    }
  }
`;

export const setJerarquia = gql`
  mutation setJerarquia($jerarquia: JerarquiaInput!) {
    setJerarquia(jerarquia: $jerarquia) {
      idUsuario
      idJefeNivel1
      idJefeNivel2
      idJefeNivel3
    }
  }
`;

export default {
  queries: {
    getJerarquia
  },
  mutations: {
    setJerarquia
  }
};
