import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import GoogleChart from "js/components/GoogleChart";

class Genero extends PureComponent {
  componentDidMount() {
    const {
      data: { hombres, mujeres }
    } = this.props;
    if (!hombres && !mujeres) {
      this.createCircle();
    }
  }

  createCircle = () => {
    const objectiveCanvas = document.getElementById("no-data-genero");
    const STROKE = 7;
    if (objectiveCanvas.getContext) {
      const ctxArc = objectiveCanvas.getContext("2d");
      const centerX = objectiveCanvas.width / 2;
      const centerY = objectiveCanvas.height / 2;
      ctxArc.beginPath();
      ctxArc.arc(centerX, centerY, centerY - STROKE, 0, 2 * Math.PI, false);
      ctxArc.strokeStyle = "#ccc";
      ctxArc.lineWidth = STROKE;
      ctxArc.stroke();
      ctxArc.font = "normal 20px Roboto";
      ctxArc.fillStyle = "#ccc";
      ctxArc.textAlign = "center";
      ctxArc.fillText("0%", centerX, centerY + STROKE / 2);
    }
  };

  render() {
    const {
      data: { hombres, mujeres }
    } = this.props;
    const total = (hombres || 0) + (mujeres || 0);
    const hombresRound = hombres ? Math.round((hombres * 100) / total) : 0;
    let mujeresRound = mujeres ? Math.round((mujeres * 100) / total) : 0;
    if (hombresRound + mujeresRound === 101) mujeresRound--;

    return hombres || mujeres ? (
      <GoogleChart
        height={"250px"}
        width={"276px"}
        chartType="PieChart"
        chartLanguage="it"
        formatters={[
          {
            column: 1,
            type: "NumberFormat",
            options: {
              groupingSymbol: ".",
              fractionDigits: "0"
            }
          }
        ]}
        data={
          hombres || mujeres
            ? [
                ["Hombre", "Mujer"],
                ["Hombre", hombresRound],
                ["Mujer", mujeresRound]
              ]
            : [["Sin información", "."], ["Sin información", 1], [".", 0]]
        }
        options={{
          legend: {
            position: "bottom",
            alignment: "center",
            fontName: "Sueca Nano",
            textStyle: {
              fontSize: "14"
            }
          },
          fontName: "Sueca Nano",
          textStyle: {
            fontName: "Sueca Nano"
          },
          chartArea: { width: "100%", height: "80%" },
          pieHole: 0.9,
          slices: [
            {
              textStyle: { color: "#126FFF", fontSize: "18" },
              color: "#126FFF"
            },
            {
              textStyle: { color: "#FF9295", fontSize: "18" },
              color: "#FF9295"
            }
          ]
        }}
      />
    ) : (
      <>
        <canvas
          id={"no-data-genero"}
          style={{ width: "400px", height: "200px", marginTop: "30px" }}
        />
        <div style={{ width: "400px", textAlign: "center" }}>
          <span style={{ fontSize: "14", color: "#000" }}>Sin información</span>
        </div>
      </>
    );
  }
}

Genero.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

Genero.defaultProps = {
  data: {
    hombres: 0,
    mujeres: 0
  }
};

Genero.displayName = "Genero";

export default Genero;
