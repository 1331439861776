import { compose, pure, withProps, withHandlers, withState } from "recompose";
import withStyles from "./styles";
import { set, get } from "lodash";
import {
  CHANGE_TEMPLATE_DATA,
  CHANGE_HEADER
} from "js/redux/template/constants.js";
import { connect } from "react-redux";
import { updateTemplateBuilder } from "js/redux/template";
import {
  getTemplateBuilderSection,
  getTemplateSection
} from "js/redux/template/selectors.js";
import { addImageURL } from "js/constants";

const formDataFactory = ({ image, sizes, context, rectangular }) => {
  const formData = new window.FormData();
  formData.append("image", image);
  formData.append("context", context);
  if (!rectangular) {
    formData.append("height", sizes);
    formData.append("width", sizes);
  } else {
    formData.append("height", sizes.height);
    formData.append("width", sizes.width);
  }

  return formData;
};

const mapStateToProps = state => ({
  idSegmento: getTemplateSection(state, "idSegmento"),
  templateBuilder: getTemplateSection(state, "templateBuilder"),
  templateData: getTemplateBuilderSection(state, "templateData"),
  header: getTemplateBuilderSection(state, "header")
});

const mapDispatchToProps = dispatch => ({
  changeTemplateData: updateTemplateBuilder({
    dispatch,
    type: CHANGE_TEMPLATE_DATA
  }),
  changeHeader: updateTemplateBuilder({
    dispatch,
    type: CHANGE_HEADER
  })
});

const enhance = compose(
  pure,
  withStyles,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState("stateUpdaterValue", "updateState", 0),
  withHandlers({
    onChangeTemplateData: ({
      templateData,
      changeTemplateData,
      updateState
    }) => key => value => {
      const payload = set(templateData, key, get(value, "target.value", value));
      changeTemplateData(payload);
      updateState();
    },
    onChangeHeader: ({ header, changeHeader, updateState }) => key => value => {
      const payload = set(header, key, get(value, "target.value", value));
      changeHeader(payload);
      updateState();
    },
    onChangeImageHeader: ({ header, changeHeader, updateState }) => (
      sizes,
      context,
      key,
      rectangular
    ) => image => {
      fetch(addImageURL, {
        method: "POST",
        body: formDataFactory({ image, sizes, context, rectangular })
      })
        .then(response => response.json())
        .then(({ url }) => {
          const payload = set(header, key, url);
          changeHeader(payload);
          updateState();
        });
    },
    onChangeImageTemplateData: ({
      templateData,
      changeTemplateData,
      updateState
    }) => (sizes, context, key) => image => {
      fetch(addImageURL, {
        method: "POST",
        body: formDataFactory({ image, sizes, context })
      })
        .then(response => response.json())
        .then(({ url }) => {
          const payload = set(templateData, key, url);
          changeTemplateData(payload);
          updateState();
        });
    }
  }),
  withProps(ownerProps => ({
    cucardas: get(ownerProps, "templateData.cucardas", [])
  }))
);

export default enhance;
