import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import AppQraphQL from "js/graphql/resolvers/locacion.resolver";
import get from "lodash/get";

class MonedaFilter extends PureComponent {
  render() {
    const { classes, value, submitValue, disabled, idPrograma } = this.props;

    return (
      <div style={{ display: "flex", flex: 1, flexWrap: "wrap" }}>
        <Query
          query={AppQraphQL.queries.getMonedasByPrograma}
          fetchPolicy="network-only"
          errorPolicy="all"
          variables={{ idPrograma }}
        >
          {({ loading, error, data }) => {
            const moneda = get(
              data,
              "getProgramas.itemsPagina[0].monedaBase",
              ""
            );
            const paises = get(data, "getPaises", []);
            const defaultValue =
              moneda ||
              (paises && paises.map(pais => get(pais, "moneda")).length === 1
                ? get(data, "getPaises[0].moneda")
                : "TODOS");

            return (
              <FormControl className={classes.formControl}>
                <InputLabel id="moneda-simple" htmlFor="moneda-simple">
                  Moneda del Programa
                </InputLabel>
                <Select
                  value={
                    value["monedaBase"] || (idPrograma ? defaultValue : "TODOS")
                  }
                  onChange={e => submitValue(e)}
                  disabled={disabled}
                  inputProps={{
                    name: "monedaBase",
                    id: "monedaBase"
                  }}
                >
                  <MenuItem value={"TODOS"}>Todas</MenuItem>
                  {paises &&
                    paises.map(
                      (pais, index) =>
                        get(pais, "moneda") && (
                          <MenuItem key={index} value={get(pais, "moneda")}>
                            {get(pais, "moneda")}
                          </MenuItem>
                        )
                    )}
                  {paises &&
                    !paises.filter(p => get(p, "moneda") === moneda).length && (
                      <MenuItem key={moneda} value={moneda}>
                        {moneda}
                      </MenuItem>
                    )}
                </Select>
              </FormControl>
            );
          }}
        </Query>
      </div>
    );
  }
}

MonedaFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withRouter(MonedaFilter);
