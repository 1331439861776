import React from "react";
import { Field } from "redux-form";
import { TextField } from "redux-form-material-ui";
import { isRequired } from "js/helpers/validations";
import { _t } from "js/utils/TranslationService";
import { styles } from "../styles.js";
import getPasswordValidations from "js/components/Access/validation";

const passwordValidations = getPasswordValidations();
const InputPasswordField = ({
  name,
  label,
  placeholder,
  required,
  disabled,
  translation,
  classes,
  customValidate,
  withPasswordValidation,
  autoComplete,
  endAdornment
}) => (
  <Field
    type="password"
    required={required}
    name={name}
    autoComplete={autoComplete}
    label={translation || _t(label)}
    disabled={disabled}
    placeholder={placeholder}
    component={TextField}
    fullWidth
    validate={(() => {
      let validations = [...customValidate];
      if (required) {
        validations.push(isRequired);
      }
      if (withPasswordValidation) {
        validations.push(...passwordValidations);
      }
      return validations;
    })()}
    className={classes ? classes.field : styles.field}
    endAdornment={endAdornment}
  />
);

InputPasswordField.defaultProps = {
  customValidate: [],
  withPasswordValidation: true
};

export default InputPasswordField;
