import gql from "graphql-tag";

const Productos = {};

Productos.fragments = {
  getEleccionProductos: gql`
    fragment eleccionProducto on EleccionProducto {
      idEleccionProducto
      puntos
      cucarda
      stockPorUsuario
      producto {
        idProducto
        nombre
        copete
        descripcion
        stock
        imagenes {
          idImagen
          url
          alt
          idProducto
        }
        sucursales {
          idSucursal
          nombre
          direccion
          telefono
        }
        tieneSucursales
        paginaWeb
      }
    }
  `,
  productoId: gql`
    fragment productoId on Producto {
      idProducto
      copete
      nombre
      descripcion
      condicionCanje
      indicacionesEntrega
      notasInternas
      especificacionesLegales
      valorOrdenCompra
      sucursalesExcluidas {
        idSucursal
      }
      imagenes {
        idImagen
        url
        alt
        idProducto
      }
      # datosRequeridos {
      #   idDatoRequerido
      #   nombre
      #   estado
      #   visibleEnCreacionProducto
      #   key
      #   estado
      # }
      proveedor {
        idProveedor
        nombreFantasia
        cuit
        sucursales {
          idSucursal
          nombre
          direccion
          localidad {
            descripcion
          }
          zona {
            descripcion
          }
          telefono
        }
      }
      diasEspera
      diasValidez
      detalleCertificado
      gestionadoPor
      stock
      stockInfinito
      stockSeguridad
      rubro {
        idRubro
      }
      subrubro {
        idSubrubro
      }
      estado
      costo
      precioSugerido
      tipoProducto
      codigosActivacion {
        idCodigoActivacion
        codigo
        estado
      }
      fechaAlta
      fechaModificacion
      usuarioCreador {
        username
      }
      usuarioModificador {
        username
      }
      tieneSucursales
      paginaWeb
      codigoProducto
    }
  `,
  productoCatalogo: gql`
    fragment productoCatalogo on Producto {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        idEleccionProducto

        puntos
        cucarda
        producto {
          idProducto
          nombre
          copete
          descripcion
          imagenes {
            idImagen
            url
            alt
            idProducto
          }
          sucursales {
            idSucursal
            nombre
            direccion
            telefono
          }
          tieneSucursales
          paginaWeb
        }
      }
    }
  `
};

export default Productos;
