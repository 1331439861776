import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ErrorList from "js/components/Shared/ErrorList";
import { UsuarioField } from "js/components/Shared/Form/Fields";
import JerarquiaQraphQL from "js/graphql/resolvers/jerarquia.resolver";
import ImportarQraphQL from "js/graphql/resolvers/importar.resolver";

import PropTypes from "prop-types";
import { removeTypename } from "js/utils/Helper";
import { s3Url, exportURL } from "js/constants";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import TableChartIcon from "@material-ui/icons/TableChartOutlined";
import { Mutation } from "react-apollo";
import UploadIcon from "@material-ui/icons/CloudUpload";
import DownloadIcon from "@material-ui/icons/CloudDownload";

class MyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {}
    };
  }
  handleChange = key => async select => {
    const { client } = this.props;
    const {
      data: { getJerarquia }
    } = await client.query({
      query: JerarquiaQraphQL.queries.getJerarquia,
      variables: { idUsuario: select.value },
      errorPolicy: "all",
      fetchPolicy: "no-cache"
    });
    this.setState(() => ({ values: getJerarquia }));
  };
  handleJefes = key => select => {
    const values = this.state.values;
    values[key] = select.value;
    this.setState(() => ({ values }));
  };

  onSubmit = () => {
    const { submitMutation, openNotification } = this.props;
    console.log("st", this.state);
    const jerarquia = removeTypename(this.state.values);
    submitMutation({ variables: { jerarquia } })
      .then(
        res =>
          res.errors && res.errors.length
            ? openNotification(res.errors[0].message)
            : openNotification("Jerarquía actualizada correctamente")
      )
      .catch(e =>
        openNotification(e.message.replace("GraphQL error:", "").trim())
      );
  };

  render() {
    const {
      handleSubmit,
      classes,
      history,
      errors,
      disabled = false,
      match: {
        params: { id }
      }
    } = this.props;

    let finalId = parseInt(id);
    let userUrl = `/admin/programa/${id}/usuario`;
    if (!finalId) {
      finalId = parseInt(localStorage.getItem("idPrograma"));
      userUrl = `/usuarios`;
    }

    const style = {
      display: "flex",
      flex: 1,
      flexWrap: "wrap"
    };

    const style1 = {
      display: "flex",
      flex: 1
    };

    const style2 = {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-end"
    };
    const actionTooltip = {
      margin: 0,
      maxWidth: "48px"
    };
    return (
      <form className={classes.container} onSubmit={handleSubmit}>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} style={style}>
            <Typography style={style1} variant="h6" gutterBottom>
              Jerarquía
            </Typography>
            <div style={style2}>
              <div>
                <Tooltip
                  title="Modelo de importación CSV"
                  style={actionTooltip}
                >
                  <IconButton
                    className={classes.button}
                    onClick={e =>
                      (window.location.href = `${s3Url}Jerarquia_Modelo.csv`)
                    }
                  >
                    <TableChartIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Importar" style={actionTooltip}>
                  <IconButton
                    variant="outlined"
                    size="large"
                    className={classes.button}
                    onClick={() => document.getElementById("upload").click()}
                  >
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
                <Mutation mutation={ImportarQraphQL.queries.importarJerarquia}>
                  {mutate => {
                    return (
                      <input
                        id="upload"
                        type="file"
                        style={{ display: "none" }}
                        required
                        onChange={({
                          target: {
                            validity,
                            files: [file]
                          }
                        }) =>
                          validity.valid &&
                          mutate({
                            variables: {
                              file,
                              idPrograma: finalId
                            }
                          })
                            .then(res => {
                              this.handleChange("idUsuario")({
                                value: this.state.values.idUsuario
                              });
                              this.props.openNotification(
                                "El archivo se procesó correctamente"
                              );
                            })
                            .catch(res =>
                              this.props.openNotification(
                                'Hubo un error al procesar el archivo. Ver los detalles en "Logs"'
                              )
                            )
                            .finally(
                              () =>
                                (document.getElementById("upload").value = "")
                            )
                        }
                      />
                    );
                  }}
                </Mutation>
              </div>
              <Tooltip title="Exportar" style={actionTooltip}>
                <IconButton
                  variant="outlined"
                  size="large"
                  className={classes.button}
                  onClick={() =>
                    (window.location = `${exportURL}/jerarquias?idPrograma=${finalId}`)
                  }
                  download
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid xs={12} item container spacing={16}>
            <Grid xs={12} sm={3} item>
              <Typography>Usuario</Typography>
              <UsuarioField
                className={classes}
                value={this.state.values.idUsuario}
                idPrograma={finalId}
                onChange={this.handleChange("idUsuario")}
                sinInactivos
              />
            </Grid>
          </Grid>
          <Grid xs={12} item container spacing={16}>
            <Grid xs={12} sm={3} item>
              <Typography>Jefe nivel 1</Typography>
              <UsuarioField
                className={classes}
                value={this.state.values.idJefeNivel1}
                idPrograma={finalId}
                onChange={this.handleJefes("idJefeNivel1")}
                sinInactivos
              />
            </Grid>
            <Grid xs={12} sm={3} item>
              <Typography>Jefe nivel 2</Typography>
              <UsuarioField
                className={classes}
                value={this.state.values.idJefeNivel2}
                idPrograma={finalId}
                onChange={this.handleJefes("idJefeNivel2")}
                sinInactivos
              />
            </Grid>
            <Grid xs={12} sm={3} item>
              <Typography>Jefe nivel 3</Typography>
              <UsuarioField
                className={classes}
                value={this.state.values.idJefeNivel3}
                idPrograma={finalId}
                onChange={this.handleJefes("idJefeNivel3")}
                sinInactivos
              />
            </Grid>
          </Grid>
        </Grid>

        <ErrorList errors={errors} />
        <Grid className={classes.grid} container>
          <Grid item xs />
          <Grid item xs />
          <Grid className={classes.gridButton} item xs>
            <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              onClick={() => history.push(userUrl)}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid className={classes.gridButton} item xs>
            {!disabled && (
              <Button
                type="button"
                onClick={this.onSubmit}
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                disabled={!this.state.values.idUsuario}
              >
                Guardar
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    );
  }
}

MyForm.propTypes = {
  values: PropTypes.object
};
export default MyForm;
