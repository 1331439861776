import { IconButton, ListItem, ListItemText } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import React from "react";

export const SelectorCantidad = ({
  index,
  producto,
  handleSubProducto,
  handleDeleteProducto,
  handleAddProducto,
  showSelectorCantidad,
  cantidadProductosXDefault
}) => {
  return (
    <>
      {showSelectorCantidad == 1 ? (
        <ListItem key={index}>
          <ListItemText
            primary={`x${producto.cantidad} - ${producto.label} `}
            secondary={`Cód.: ${producto.value}`}
          />
          <IconButton
            onClick={() => handleSubProducto(index)}
            aria-label="Restar"
          >
            <RemoveIcon style={{ fontSize: "medium" }} />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteProducto(producto.value)}
            aria-label="Eliminar"
          >
            <DeleteIcon style={{ fontSize: "medium" }} />
          </IconButton>
          <IconButton
            onClick={() => handleAddProducto(index)}
            aria-label="Agregar"
          >
            <AddIcon style={{ fontSize: "medium" }} />
          </IconButton>
        </ListItem>
      ) : (
        <ListItem key={index}>
          <ListItemText
            primary={`x${producto.cantidad} - ${producto.label} `}
            secondary={`Cód.: ${producto.value}`}
          />
          <IconButton
            onClick={() => handleDeleteProducto(producto.value)}
            aria-label="Eliminar"
          >
            <DeleteIcon style={{ fontSize: "medium" }} />
          </IconButton>
        </ListItem>
      )}
    </>
  );
};
