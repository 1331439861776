export const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: theme.container.maxWidth,
    margin: theme.container.margin
  },
  field: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  grid: {
    margin: "0"
  },
  gridButton: {
    marginTop: "20px",
    textAlign: "end"
  },
  button: {
    width: "200px"
  },
  subtitle: {
    marginTop: "1.5rem"
  },
  iconHipervinculo: {
    position: "relative",
    top: "27px",
    left: "210px",
    zIndex: "5000"
  }
});
