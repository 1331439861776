import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector, change, getFormMeta } from "redux-form";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { _t } from "js/utils/TranslationService";
import { styles } from "js/components/Shared/Form/styles.js";
import { multDec, divDec } from "js/utils";

import {
  InputTextField,
  InputNumberGtZeroField,
  ListField,
  ProgramaField
} from "js/components/Shared/Form/Fields";

class Form extends PureComponent {
  componentDidMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    id && this.fetchPrograma(id);
  }

  componentWillReceiveProps(props) {
    const {
      idProgramaField,
      changeFieldValue,
      formMeta: { montoEnMonedaBase, puntosAsignados },
      montoEnMonedaBaseField,
      puntosAsignadosField,
      tasaConversionField
    } = props;
    if (idProgramaField && idProgramaField !== this.props.idProgramaField) {
      this.fetchPrograma(idProgramaField);
    }

    if (montoEnMonedaBase && !!montoEnMonedaBase.active) {
      const puntos = multDec(
        parseFloat(montoEnMonedaBaseField, 10),
        parseFloat(tasaConversionField, 10)
      );
      changeFieldValue("puntosAsignados", Math.ceil(puntos) || "");
    } else if (puntosAsignados && !!puntosAsignados.active) {
      const monto = divDec(
        parseFloat(Math.ceil(puntosAsignadosField), 10),
        parseFloat(tasaConversionField, 10)
      );
      changeFieldValue("montoEnMonedaBase", monto || "");
    }
  }

  async fetchPrograma(idPrograma) {
    const { client, changeFieldValue } = this.props;
    await client
      .query({
        query: AppQraphQL.queries.getProgramaById,
        fetchPolicy: "network-only",
        variables: {
          idPrograma
        }
      })
      .then(res => {
        const valueTasa = res.data.getProgramas.itemsPagina[0].tasaConversion;
        const valueMoneda = res.data.getProgramas.itemsPagina[0].monedaBase;
        changeFieldValue("puntosAsignados", "");
        changeFieldValue("montoEnMonedaBase", "");
        changeFieldValue("tasaConversion", valueTasa);
        changeFieldValue("monedaBase", valueMoneda);
      });
  }

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      disabled,
      monedaBaseField,
      errors,
      id
    } = this.props;

    return (
      <Fragment>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h5">Nuevo movimiento CC Programas</Typography>
          </Grid>
        </Grid>
        <form className={classes.container} onSubmit={handleSubmit}>
          <Grid className={classes.grid} container spacing={16}>
            <ul className={classes.error}>
              {errors && errors.map(e => <li>{e.message}</li>)}
            </ul>
          </Grid>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12} sm={4}>
              <ProgramaField disabled={disabled} required redux />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="concepto"
                label="Concepto"
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="numeroFactura"
                label="Nro Factura"
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ListField
                name="tipo"
                label="Tipo"
                options={[
                  { label: "Crédito", value: "CREDITO" },
                  { label: "Débito", value: "DEBITO" }
                ]}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputNumberGtZeroField
                name="montoEnMonedaBase"
                translation={`${_t(
                  "Importe en moneda base"
                )} (${monedaBaseField})`}
                disabled={disabled}
                isFloat
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputNumberGtZeroField
                name="tasaConversion"
                label="Tasa de conversión"
                isFloat
                decimalNumbers={10}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputNumberGtZeroField
                name="puntosAsignados"
                label="Puntos asignados"
                disabled={disabled}
                required
                type="int"
              />
            </Grid>
          </Grid>
          <Grid className={classes.grid} container>
            <Grid item xs />
            <Grid item xs />
            <Grid className={classes.gridButton} item xs>
              <Button
                type="button"
                variant="outlined"
                size="large"
                color="primary"
                className={classes.button}
                onClick={() =>
                  id
                    ? history.push(`/admin/programa/${id}/cuenta_corriente`)
                    : history.push(`/admin/cuenta_corriente`)
                }
              >
                Cancelar
              </Button>
            </Grid>
            <Grid className={classes.gridButton} item xs>
              {!disabled && (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  disabled={pristine || submitting}
                >
                  EJECUTAR
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Fragment>
    );
  }
}

Form = reduxForm({
  form: "formCuentaCorriente",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formCuentaCorriente");

Form = connect(
  state => ({
    formMeta: getFormMeta("formCuentaCorriente")(state),
    idProgramaField: selector(state, "idPrograma"),
    montoEnMonedaBaseField: selector(state, "montoEnMonedaBase"),
    puntosAsignadosField: selector(state, "puntosAsignados"),
    tasaConversionField: selector(state, "tasaConversion"),
    monedaBaseField: selector(state, "monedaBase")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formCuentaCorriente", field, value))
  })
)(Form);

export default withApollo(withRouter(withStyles(styles)(Form)));
