import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaProducto/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/producto.resolver";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";

const defaultHeader = {
  title: "Nuevo catálogo"
};

const columnData = [
  { id: "nombre", label: "Nombre" },
  { id: "stock", label: "Stock maestro" },
  {
    id: "costo",
    label: "Costo",
    type: "combine",
    isNumeric: true,
    combine: [
      { id: "moneda", object: "proveedor_pais_moneda" },
      { id: "costo" }
    ]
  },
  {
    id: "precioSugerido",
    label: "Precio sugerido",
    isNumeric: true,
    type: "combine",
    combine: [
      { id: "moneda", object: "proveedor_pais_moneda" },
      { id: "precioSugerido" }
    ]
  },
  { id: "tipoProducto", label: "Tipo producto" },
  { id: "gestionadoPor", label: "Gestión" },
  { id: "proveedor_nombreFantasia", label: "Proveedor" },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  }
];

const defaultSort = { order: "asc", orderBy: "nombre" };

class Tabla extends PureComponent {
  render() {
    return (
      <Table
        query={AppQraphQL.queries.getPaginaProductos}
        defaultHeader={defaultHeader}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getProductos}
        onSelectItem={this.props.onSelectItem}
        onDeselectAll={this.props.onDeselectAll}
        selectedItems={this.props.selectedItems}
        disabledItems={this.props.disabledItems}
        actions={["add"]}
      />
    );
  }
}

export default Tabla;
