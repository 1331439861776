import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { _t } from "js/utils/TranslationService";
import Typography from "@material-ui/core/Typography";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const CustomDialog = props => (
  <div>
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      classes={{ paperScrollPaper: props.classes.root }}
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={props.classes.dialogTitle}
      >
        {props.title}
      </DialogTitle>
      <DialogContent className={props.classes.dialogContent}>
        {props.content}
      </DialogContent>
      <DialogActions
        style={{
          marginLeft: "16px",
          marginRight: "16px",
          justifyContent: "space-between"
        }}
      >
        {props.errorMessage ? (
          <div>
            <Typography gutterBottom variant="caption" color="error">
              {props.errorMessage}
            </Typography>
          </div>
        ) : null}
        <div style={{ justifyContent: "flex-end", flex: 1, display: "flex" }}>
          {props.cancelText ? (
            <Button
              onClick={props.cancelAction}
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px" }}
            >
              {_t(props.cancelText)}
            </Button>
          ) : null}
          {props.okText ? (
            <Button
              onClick={props.okAction}
              variant="contained"
              color="primary"
            >
              {_t(props.okText)}
            </Button>
          ) : null}
        </div>
      </DialogActions>
    </Dialog>
  </div>
);

CustomDialog.defaultProps = {
  open: false,
  title: "",
  classes: {},
  content: <span> </span>,
  handleClose: () => undefined,
  cancelAction: () => undefined,
  cancelText: "",
  okAction: () => undefined,
  okText: "",
  errorMessage: null
};
export default CustomDialog;
