import gql from "graphql-tag";

export const CuentaCorriente = {};

CuentaCorriente.fragments = {
  cuentaCorrienteUsuario: gql`
    fragment cuentaCorrienteUsuario on CuentaCorriente {
      puntos
      movimientos {
        infoPagina {
          numeroPagina
          total
          cantidadPaginas
        }
        itemsPagina {
          fecha
          puntosAsignados
          puntosUsados
          idEleccionProducto
        }
      }
    }
  `
};

export default CuentaCorriente;
