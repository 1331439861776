import React, { Component, Fragment } from "react";
import classNames from "classnames";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import CancelButton from "@material-ui/icons/Cancel";
import AuthService from "js/utils/AuthService";

import { toolbarMap, getInitialValues } from "./actionsDefinitions";

class EnhancedTableToolbar extends Component {
  state = { ...getInitialValues() };

  closeDialog = () =>
    this.setState((state, props) => {
      return { ...getInitialValues() };
    });

  openDialog = popup => () =>
    this.setState((state, props) => {
      return { [`dialog${popup}`]: true };
    });

  render() {
    const {
      classes,
      selected,
      toolbarActions,
      onTableChange,
      refetch,
      onDeselectAll
    } = this.props;

    const numSelected = selected.length;
    return (
      <Fragment>
        <Toolbar
          className={classNames(classes.root, {
            [classes.highlight]: numSelected > 0
          })}
        >
          <div className={classes.title}>
            {numSelected > 0 && (
              <Typography
                color="inherit"
                variant="subtitle1"
                className={classes.selectedWrapper}
              >
                <Tooltip
                  title="Deshacer selección"
                  className={classes.selectedTooltip}
                >
                  <CancelButton onClick={() => onDeselectAll()} />
                </Tooltip>
                <span className={classes.selected}>
                  Seleccionados: {numSelected}
                </span>
              </Typography>
            )}
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            {numSelected > 0 &&
              toolbarActions.map(
                action =>
                  AuthService.hasPerms(toolbarMap.get(action).perms) && (
                    <Tooltip
                      key={`icon-${toolbarMap.get(action).key}`}
                      title={toolbarMap.get(action).tooltip}
                    >
                      <span
                        key={`icon-${toolbarMap.get(action).key}`}
                        className={classes.action}
                        onClick={this.openDialog(toolbarMap.get(action).key)}
                      >
                        {toolbarMap.get(action).actionIcon}
                      </span>
                    </Tooltip>
                  )
              )}
          </div>
        </Toolbar>
        {numSelected > 0 &&
          toolbarActions.map(action => {
            const Component = toolbarMap.get(action).component;
            return (
              AuthService.hasPerms(toolbarMap.get(action).perms) &&
              this.state[`dialog${toolbarMap.get(action).key}`] && (
                <Component
                  open={true}
                  handleClose={this.closeDialog}
                  selected={selected}
                  classes={classes}
                  refetch={refetch}
                  key={`dialog-${toolbarMap.get(action).key}`}
                  onTableChange={onTableChange}
                />
              )
            );
          })}
      </Fragment>
    );
  }
}

export default EnhancedTableToolbar;
