import React, { PureComponent } from "react";
import capitalize from "lodash/capitalize";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import PeopleIcon from "@material-ui/icons/People";

import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/pages/Unilever/CampanasSupervisor/Tabla/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/targetsUnilever.resolver";
import { targetsCampanaAction } from "js/pages/Unilever/ActionCells";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import { USER_ROLES as Roles } from "js/models/User";

const defaultHeader = btnsNextTitle => {
  return {
    title: "Campañas",
    btnsNextTitle
  };
};

const columnData = [
  { id: "nombre", label: "Nombre campaña" },
  { id: "fechaInicio", label: "Fecha Inicio", type: "date" },
  { id: "fechaFin", label: "Fecha Fin", type: "date" },
  { id: "fechaCierre", label: "Fecha Cierre", type: "date" },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fechaInicio" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    const targets = params => ({
      ...targetsCampanaAction({
        ...params,
        history: this.props.history,
        perms: [Roles.TARGET_USUARIO_R]
      })
    });

    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Porcentajes preventistas">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(`/porcentajes-mis-preventistas`)
            }
          >
            <PeopleIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.PREVENTISTA_PORCENTAJE_R]
    });

    return (
      <Table
        storage={"FiltroCampanas"}
        query={AppQraphQL.queries.getCampanasConTargets}
        defaultHeader={defaultHeader(btnsNextTitle)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getCampanasConTargets}
        actionsCustom={[targets]}
        pathId={"_id"}
      />
    );
  }
}

export default Tabla;
