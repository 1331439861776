import get from "lodash/get";

export default theme => {
  const avatarDiameter = get(
    theme,
    "programa.template.header.user.avatar.diameter",
    40
  );
  return {
    textoTitulo: {
      // color: theme.palette.getContrastText(theme.palette.secondary.main),
      color: "#FFF",
      textAlign: "center",
      fontSize: "xx-large",
      paddingTop: "20px"
    },
    textoSubtitulo: {
      //color: theme.palette.getContrastText(theme.palette.secondary.main),
      color: "#FFF",
      textAlign: "center",
      paddingBottom: "20px",
      fontSize: "larger",
      paddingTop: "5px"
    },
    fondoTitulo: {
      backgroundColor: theme.palette.primary.main,
      marginBottom: "40px"
    },
    botonActivado: {
      height: "67px",
      width: "25%",
      color: theme.palette.primary.main,
      cursor: "pointer"
    },
    botonDesactivado: {
      height: "67px",
      width: "25%",
      color: "#979797",
      cursor: "pointer"
    },
    botonTextoActivado: {
      width: "25%",
      color: theme.palette.primary.main,
      cursor: "pointer"
    },
    botonTextoDesactivado: {
      width: "25%",
      color: "#979797",
      cursor: "pointer"
    },
    avatar: {
      margin: 10,
      width: avatarDiameter,
      height: avatarDiameter
    },
    textoNominador: {
      color: "#4c4c4c",
      fontSize: "20pt",
      fontWeight: "bold"
    },
    textoNombre: {
      color: "#4c4c4c",
      fontSize: "larger",
      fontWeight: "bold"
    },
    textoNombreEquipo: {
      color: "#4c4c4c",
      fontWeight: "bold"
    },
    textoDefinicion: {
      color: "#4c4c4c"
    },
    card: {
      minWidth: "100%",
      borderRadius: "15px"
    },
    icon: {
      paddingTop: "23px"
    },
    containerTop: {
      background: "linear-gradient(to bottom, white 38%, #f4f4f4 38%)",
      [theme.breakpoints.down("sm")]: {
        background: "linear-gradient(to bottom, white 110px, #f4f4f4 110px)"
      },
      padding: "20px"
    },
    containerUno: {
      backgroundColor: "#f9f9f9",
      padding: "20px"
    },
    containerDos: {
      backgroundColor: "#f4f4f4",
      padding: "20px"
    },
    containerUsuarioField: {
      backgroundColor: theme.palette.primary.main + "40",
      padding: "15px",
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px"
    },
    containerUsuariosNominados: {
      padding: "15px"
    },
    containerUsuariosReconocidos: {
      marginBottom: "5px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "20px"
      }
    },
    usuarioField: {
      backgroundColor: "#FFF",
      borderRadius: "5px"
      // border: "1px #f4f4f4 solid"
    },
    field: {
      backgroundColor: "#FFF",
      position: "relative",
      borderRadius: "5px",
      "&:before": {
        // content: "",
        position: "absolute",
        left: "0",
        top: "50%",
        width: "0",
        height: "0",
        border: "34px solid transparent",
        borderRightColor: "#FFF",
        borderLeft: "0",
        borderTop: "0",
        marginTop: "-17px",
        marginLeft: "-34px"
      }
    },
    contenedorIzquierda: {
      paddingRight: "30px"
    },
    textoPregunta: {
      fontSize: "16px"
    },
    buttonLabel: {
      color: "#FFF"
    },
    containerBotones: {
      backgroundColor: theme.palette.primary.main + "40",
      padding: "25px"
    },
    leyenda: {
      fontSize: "10px",
      width: "80%"
    },
    textoError: {
      fontSize: "12px",
      color: "#f44336"
    },
    textoLeyendaTipo: {
      fontSize: "10px"
    }
  };
};
