import React from "react";
import { Field } from "redux-form";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import { _t, LOCAL } from "js/utils/TranslationService";
import { Select } from "redux-form-material-ui";
import { isRequired } from "js/helpers/validations";
import { styles } from "../styles.js";

const CustomSelect = inputProps => {
  const {
    meta: { touched, error },
    input: { name },
    label,
    children,
    translation,
    required
  } = inputProps;
  return (
    <FormControl
      error={Boolean(touched && error)}
      fullWidth
      required={required}
    >
      {label && (
        <InputLabel htmlFor={name}>{translation || _t(label)}</InputLabel>
      )}
      <Select {...inputProps} onChange={inputProps.input.onChange}>
        {children}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const ListField = ({
  name,
  label,
  required,
  t_options,
  options,
  classes,
  disabled,
  onChange = () => {}
}) => (
  <Field
    name={name}
    label={label}
    disabled={disabled}
    component={CustomSelect}
    className={classes ? classes.field : styles.field}
    required={required}
    onChange={onChange}
    validate={(() => {
      let validations = [];
      if (required) {
        validations.push(isRequired);
      }
      return validations;
    })()}
  >
    {t_options
      ? t_options.map(option => {
          if (typeof option === "string") {
            return (
              <MenuItem key={option} value={option}>
                {_t(option, LOCAL)}
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={option.key || option.value}
              value={option.key || option.value}
            >
              {_t(option.label, LOCAL)}
            </MenuItem>
          );
        })
      : options.map(option => {
          if (typeof option === "string") {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={option.key || option.value}
              value={option.key || option.value}
            >
              {option.label}
            </MenuItem>
          );
        })}
  </Field>
);

export default ListField;
