import gql from "graphql-tag";

export const getObjetivosCampana = gql`
  query getObjetivosCampana($idCampana: Int!) {
    getObjetivosCampana(idCampana: $idCampana) {
      idObjetivo
      nombre
      puntosPorSegmentos {
        _id
        idSegmento
        puntos
      }
      formaSumar
      fechaCreacion
      usuarioCreador {
        perfil {
          nombre
          apellido
        }
      }
    }
  }
`;

export const createObjetivosCampana = gql`
  mutation createObjetivosCampana($objetivos: [ObjetivoCampanaCreate]) {
    createObjetivosCampana(objetivos: $objetivos) {
      idObjetivo
    }
  }
`;

export const updateObjetivosCampana = gql`
  mutation updateObjetivosCampana($objetivos: [ObjetivoCampanaUpdate]) {
    updateObjetivosCampana(objetivos: $objetivos) {
      idObjetivo
    }
  }
`;

export const importarObjetivosCampanas = gql`
  mutation importarObjetivosCampanas($file: Upload!, $idPrograma: Int!) {
    importObjetivosCampanas(file: $file, idPrograma: $idPrograma)
  }
`;

export default {
  queries: {
    getObjetivosCampana
  },
  mutations: {
    createObjetivosCampana,
    updateObjetivosCampana,
    importarObjetivosCampanas
  }
};
