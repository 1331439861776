import React from "react";
import { getUrlWithQueryString } from "js/utils";
import AuthService from "js/utils/AuthService";
import { Redirect } from "react-router-dom";

const Encuesta = ({
  token,
  accion: { _id, encuesta = {}, alturaIframe },
  usuario: { username },
  isLoggedIn
}) => {
  const url = getUrlWithQueryString(encuesta.url, { token, username });
  if (AuthService.loggedIn(isLoggedIn))
    return (
      <iframe
        key={_id}
        title={_id}
        src={url}
        width="100%"
        height={alturaIframe ? alturaIframe : "1300"}
        frameBorder={0}
      />
    );
  else return <Redirect to="/signin" />;
};

export default Encuesta;
