import React, { PureComponent } from "react";
import { withApollo } from "react-apollo";
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@material-ui/core/CircularProgress";

import AppQraphQL from "js/graphql/resolvers/performance.resolver";
import { _t } from "js/utils/TranslationService";
import PerformanceIndicador from "./performanceIndicador";

class EDDPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expandedTotal: true,
      expandedUsers: false,
      performanceCampana: this.props.performanceCampana,
      showProgress: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const { performanceCampana } = this.state;
    if (
      performanceCampana.individual.itemsPagina.length !==
      nextProps.performanceCampana.individual.itemsPagina.length
    ) {
      this.setState({ performanceCampana: nextProps.performanceCampana });
    }
  }

  getMoreUsersPerformances = async () => {
    const { client, idPrograma } = this.props;
    const { performanceCampana } = this.state;
    const newPage = performanceCampana.individual.infoPagina.numeroPagina + 1;
    await client
      .query({
        query: AppQraphQL.queries.getCampanaPerformance,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: idPrograma,
          idCampana: performanceCampana.campana._id,
          page: newPage,
          rowsPerPage: 2
        }
      })
      .then(res => {
        const performancesUsuarios = res.data.getCampanaPerformance.itemsPagina;
        const performanceCampanaUpdated = {
          campana: performanceCampana.campana,
          total: performanceCampana.total,
          individual: {
            infoPagina: {
              ...performanceCampana.individual.infoPagina,
              numeroPagina: newPage
            },
            itemsPagina: performanceCampana.individual.itemsPagina.concat(
              performancesUsuarios
            )
          }
        };

        this.setState({
          performanceCampana: performanceCampanaUpdated,
          showProgress: false
        });
      });
  };

  handleTotalCollapse = () => {
    this.setState(prevState => ({
      expandedTotal: !prevState.expandedTotal
    }));
  };

  handleUsersCollapse = () => {
    this.setState(prevState => ({
      expandedUsers: !prevState.expandedUsers
    }));
  };

  handleSeeMore = () => {
    this.setState({ showProgress: true }, () => {
      this.getMoreUsersPerformances();
    });
  };

  render() {
    const { classes, index, renderPerformanceIndividual } = this.props;

    const {
      expandedTotal,
      expandedUsers,
      performanceCampana,
      showProgress
    } = this.state;

    const infoPagina = performanceCampana.individual.infoPagina;
    const performanceUsuarios = performanceCampana.individual.itemsPagina;

    return (
      <Grid className={classes.container} item xs={12}>
        {/* Card de performance MED (performance total) */}
        <ExpansionPanel
          expanded={expandedTotal}
          onChange={this.handleTotalCollapse}
          style={{ marginBottom: "20px" }}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.expansionTitle} variant="h6">
              {_t("Performance Distribuidora")}
            </Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>
            <div className={classes.performanceContainer}>
              {performanceCampana.total.map((performanceObjetivo, i) => {
                return (
                  <PerformanceIndicador
                    key={`edd-${index}-${i}`}
                    index={`edd-${index}-${i}`}
                    performance={performanceObjetivo}
                    color={"#60a9b0"}
                  />
                );
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/* Card de performance de los usuarios en la jerarquia del supervisor (performance individual) */}
        <ExpansionPanel
          expanded={expandedUsers}
          onChange={this.handleUsersCollapse}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.expansionTitle} variant="h6">
              {_t("Performance Preventistas")}
            </Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails className={classes.expDetailsContainer}>
            {performanceUsuarios.map((performanceUsuario, i) => {
              return renderPerformanceIndividual(
                classes,
                `${index}-${i}`,
                performanceUsuario,
                true,
                performanceCampana.campana
              );
            })}
            {performanceUsuarios.length !== 1 &&
              performanceUsuarios.length < infoPagina.total && (
                <Grid className={classes.seeMoreContainer} item xs={12}>
                  <Button
                    type="button"
                    variant="outlined"
                    size="large"
                    color="primary"
                    style={{ width: "200px" }}
                    onClick={this.handleSeeMore}
                  >
                    {showProgress && (
                      <CircularProgress
                        size={20}
                        thickness={4}
                        color="primary"
                        variant="indeterminate"
                      />
                    )}
                    {!showProgress && _t("Ver más preventistas")}
                  </Button>
                </Grid>
              )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    );
  }
}

export default withApollo(EDDPanel);
