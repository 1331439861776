import gql from "graphql-tag";

export const getPaginaIdiomas = gql`
  query getIdiomas(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: IdiomaColumn!
  ) {
    getIdiomas(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      idiomaOrder: { column: $orderBy, order: $order }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        nombre
        idPadre
        nombrePadre
        usuarioCreador {
          username
          perfil {
            nombre
            apellido
          }
        }
        usuarioModificador {
          username
          perfil {
            nombre
            apellido
          }
        }
        ultimaActualizacion
      }
    }
  }
`;

export const getIdiomaConPadre = gql`
  query getIdiomaConPadre($idIdioma: ID!) {
    getIdiomaConPadre(idIdioma: $idIdioma) {
      idioma {
        _id
        nombre
        idPadre
        usuarioCreador {
          perfil {
            nombre
            apellido
          }
        }
        usuarioModificador {
          perfil {
            nombre
            apellido
          }
        }
        ultimaActualizacion
        traducciones {
          key
          value
        }
      }
      idiomaPadre {
        _id
        nombre
        idPadre
        usuarioCreador {
          perfil {
            nombre
            apellido
          }
        }
        usuarioModificador {
          perfil {
            nombre
            apellido
          }
        }
        ultimaActualizacion
        traducciones {
          key
          value
        }
      }
    }
  }
`;

export const getIdiomas = gql`
  query getIdiomas {
    getIdiomas {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        nombre
        idPadre
        nombrePadre
        usuarioCreador {
          perfil {
            nombre
            apellido
          }
        }
        usuarioModificador {
          perfil {
            nombre
            apellido
          }
        }
        ultimaActualizacion
      }
    }
  }
`;

export const getIdiomaById = gql`
  query getIdiomaMerged($id: ID!) {
    getIdiomaMerged(idIdioma: $id) {
      _id
      nombre
      traducciones {
        key
        value
      }
    }
    isLoggedIn
  }
`;

export const getIdiomasByProgramaId = gql`
  query getProgramas($idPrograma: Int) {
    getProgramas(programaLike: { idPrograma: $idPrograma }) {
      itemsPagina {
        idPrograma
        idiomas {
          idioma {
            _id
            nombre
          }
          etiqueta
        }
      }
    }
  }
`;

export const getIdiomasPrograma = gql`
  query getPrograma($url: String!) {
    getPrograma(url: $url) {
      idiomas {
        idioma {
          _id
          nombre
        }
        etiqueta
      }
    }
  }
`;

export const addKeyToPadres = gql`
  mutation addKeyToPadres($key: String!) {
    addKeyToPadres(key: $key) {
      _id
    }
  }
`;

export const addKeyToPrograma = gql`
  mutation addKeyToPrograma($key: String!) {
    addKeyToPrograma(key: $key) {
      _id
    }
  }
`;

export const createIdioma = gql`
  mutation createIdioma(
    $nombre: String!
    $idPadre: String!
    $traducciones: [TraduccionInput]!
  ) {
    createIdioma(
      idioma: {
        nombre: $nombre
        idPadre: $idPadre
        traducciones: $traducciones
      }
    ) {
      nombre
    }
  }
`;

export const addIdiomaToPrograma = gql`
  mutation asignarIdiomaAPrograma(
    $idIdioma: ID!
    $idPrograma: Int!
    $etiqueta: String!
  ) {
    asignarIdiomaAPrograma(
      idIdioma: $idIdioma
      idPrograma: $idPrograma
      etiqueta: $etiqueta
    ) {
      etiqueta
    }
  }
`;

export const createIdiomaPadre = gql`
  mutation createIdiomaPadre($nombre: String!) {
    createIdiomaPadre(idioma: { nombre: $nombre }) {
      nombre
    }
  }
`;

export const updateEtiquetaDeIdioma = gql`
  mutation updateEtiquetaDeIdioma(
    $idIdioma: ID!
    $idPrograma: Int!
    $etiqueta: String!
  ) {
    updateEtiquetaDeIdioma(
      idIdioma: $idIdioma
      idPrograma: $idPrograma
      etiqueta: $etiqueta
    ) {
      etiqueta
    }
  }
`;

export const updateIdioma = gql`
  mutation updateIdioma(
    $_id: ID!
    $traducciones: [TraduccionInput]
    $nombre: String
    $idPadre: ID
  ) {
    updateIdioma(
      idioma: {
        _id: $_id
        traducciones: $traducciones
        nombre: $nombre
        idPadre: $idPadre
      }
    ) {
      nombre
    }
  }
`;
export const removeIdiomaDePrograma = gql`
  mutation removeIdiomaDePrograma($idIdioma: ID!, $idPrograma: Int!) {
    removeIdiomaDePrograma(idIdioma: $idIdioma, idPrograma: $idPrograma) {
      etiqueta
    }
  }
`;

export default {
  queries: {
    getIdiomaConPadre,
    getIdiomas,
    getPaginaIdiomas,
    getIdiomaById,
    getIdiomasByProgramaId,
    getIdiomasPrograma
  },
  mutations: {
    addKeyToPadres,
    addKeyToPrograma,
    createIdioma,
    createIdiomaPadre,
    updateIdioma,
    addIdiomaToPrograma,
    updateEtiquetaDeIdioma,
    removeIdiomaDePrograma
  }
};
