import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ReactToPrint from "react-to-print";

import SplashScreen from "js/pages/SplashScreen";
import BeneficioGraphQL from "js/graphql/resolvers/catalogoCodigos.resolver";
import { _t } from "js/utils/TranslationService";
import SolicitudContent from "./SolicitudContent";
import SolicitudCupon from "./SolicitudCupon";
import "./solicitud.css";

class Solicitudes extends React.PureComponent {
  constructor(props) {
    super(props);
    const isNew =
      props.history.location.pathname.indexOf("mi-solicitud") === -1;
    this.state = {
      isNew: isNew
    };
  }

  backToCatalogo = () => {
    const { history } = this.props;
    const { isNew } = this.state;
    history.push({
      pathname: isNew ? "/catalogo-codigos" : "/mis-solicitudes"
    });
  };

  render() {
    const { classes, programa } = this.props;
    const programLogo = programa.template.header.logoPrincipal
      ? programa.template.header.logoPrincipal.url
      : null;
    const { isNew } = this.state;

    return (
      <Query
        query={BeneficioGraphQL.queries.getCatalogoCodigosSolicitudById}
        variables={{
          idSolicitud: parseInt(this.props.computedMatch.params.idSolicitud, 10)
        }}
        errorPolicy="all"
      >
        {({ loading, error, data }) => {
          if (loading) return <SplashScreen />;
          if (error) return `Error! ${error.message}`;
          const solicitud =
            data.getMisCatalogoCodigosSolicitudes.itemsPagina[0];

          return (
            <Paper className={"solicitud-container"}>
              <Grid
                container
                spacing={24}
                className={"solicitud-inner-container"}
              >
                <Grid item xs={12}>
                  {solicitud && (
                    <React.Fragment>
                      {isNew && (
                        <Typography
                          variant="h5"
                          gutterBottom
                          className={"main-title"}
                        >
                          {`${_t("¡Felicitaciones! Canjeaste un cupón por:")}:`}
                        </Typography>
                      )}
                      <Grid container spacing={24}>
                        <Grid item xs={12}>
                          <SolicitudContent
                            classes={classes}
                            programLogo={programLogo}
                            beneficio={solicitud.beneficio}
                            idSolicitud={solicitud.idSolicitud}
                          />
                          <div style={{ display: "none" }}>
                            <SolicitudCupon
                              classes={classes}
                              programLogo={programLogo}
                              beneficio={solicitud.beneficio}
                              idSolicitud={solicitud.idSolicitud}
                              ref={el => (this.componentRef = el)}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                variant="contained"
                                color="primary"
                                className={"print-btn"}
                              >
                                {_t("Imprimir")}
                              </Button>
                            )}
                            content={() => this.componentRef}
                          />
                          <Button
                            onClick={this.backToCatalogo}
                            variant="contained"
                            color="primary"
                            className={classes.btn}
                          >
                            {isNew
                              ? _t("Volver al catálogo")
                              : _t("Volver a mis solicitudes")}
                          </Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  {!solicitud && (
                    <Grid container spacing={24}>
                      <Typography
                        variant="h5"
                        gutterBottom
                        className={"main-title"}
                      >
                        {`${_t(
                          "No existe una solicitud de beneficio con este ID"
                        )}:`}
                      </Typography>
                      <Grid item xs={12}>
                        <Button
                          onClick={this.backToCatalogo}
                          variant="contained"
                          color="primary"
                        >
                          {_t("Volver al catálogo")}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Paper>
          );
        }}
      </Query>
    );
  }
}

Solicitudes.propTypes = {
  classes: PropTypes.object
};

Solicitudes.defaultProps = {};

export default withRouter(Solicitudes);
