import gql from "graphql-tag";

export const Segmento = {};

Segmento.fragments = {
  segmentoCompleto: gql`
    fragment segmentoCompleto on Segmento {
      idPrograma
      idSegmento
      idiomaDefault {
        _id
        nombre
        idPadre
        nombrePadre
        traducciones {
          key
          value
        }
      }
      descripcion
      estado
    }
  `,
  segmentoId: gql`
    fragment segmento on Segmento {
      idPrograma
      idSegmento
    }
  `
};

export default Segmento;
