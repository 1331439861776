import React, { PureComponent } from "react";
import capitalize from "lodash/capitalize";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FlagIcon from "@material-ui/icons/Flag";

import Table from "js/components/Shared/Tabla";
import TargetsCampanasGraphQL from "js/graphql/resolvers/campanaTarget.resolver";
import advancedFilter from "js/pages/Admin/Programa/Campana/Targets/Tabla/advancedFilters.js";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import { USER_ROLES as Roles } from "js/models/User";

const defaultHeader = (id, btnsNextTitle) => {
  const header = {
    title: "Targets de usuarios",
    btnsNextTitle,
    export: {
      path: "targetsCampanas",
      perms: [Roles.CAMPANA_TARGET_R],
      tooltip: "Exportar targets de usuarios"
    },
    import: {
      action: TargetsCampanasGraphQL.mutations.importTargetsObjetivos,
      perms: [Roles.CAMPANA_TARGET_W],
      tooltip: "Importar targets de usuarios",
      model: "Modelo_Targets_Campanas.csv",
      modelTooltip: "Modelo de importación de targets de usuarios",
      variables: {
        idPrograma: parseInt(id)
      }
    },
    queryStringMatcher: {
      idsProgramas: undefined
    }
  };

  return header;
};
const columnData = [
  { id: "fechaCreacion", label: "Fecha creación", type: "date" },
  { id: "idTarget", label: "ID", removeSort: true },
  { id: "campana_nombre", label: "Campaña", removeSort: true },
  { id: "objetivo_nombre", label: "Objetivo", removeSort: true },
  {
    id: "usuario",
    label: "Usuario",
    type: "custom",
    removeSort: true,
    parser: usuario => {
      if (usuario.perfil && usuario.perfil.nombre) {
        return `${usuario.perfil.nombre} ${usuario.perfil.apellido} (${
          usuario.username
        })`;
      }
      return usuario.username;
    }
  },
  {
    id: "target",
    label: "Target",
    type: "custom",
    parser: (_, item) => {
      if (item.target === 0) return item.targetFloat;
      return item.target;
    }
  },
  { id: "targetOriginal", label: "Target Original" },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    removeSort: true,
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  {
    id: "usuarioCreador",
    label: "Creado por",
    type: "custom",
    removeSort: true,
    parser: usuarioCreador => {
      if (usuarioCreador.perfil) {
        return `${usuarioCreador.perfil.nombre} ${
          usuarioCreador.perfil.apellido
        }`;
      }
      return usuarioCreador.username;
    }
  }
];

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  render() {
    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Volver a Campañas">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(
                `/admin/programa/${this.props.match.params.id}/campanas`
              )
            }
          >
            <FlagIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.CAMPANA_R]
    });

    return (
      <Table
        storage="FiltroTargets"
        query={TargetsCampanasGraphQL.queries.getTargetsObjetivos}
        defaultHeader={defaultHeader(this.props.match.params.id, btnsNextTitle)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getTargetsObjetivos}
        actionsCustom={[]}
      />
    );
  }
}

export default Tabla;
