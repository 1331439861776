import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import RolGraphQL from "js/graphql/resolvers/rol.resolver";
import UserGraphQL from "js/graphql/resolvers/usuario.resolver";
import AuthService from "js/utils/AuthService";
import { get } from "lodash";

class advancedFilter extends PureComponent {
  constructor(props) {
    super(props);
    const userRoles = AuthService.getUserRoles();
    const isSupervisor =
      userRoles &&
      userRoles.some(rol => {
        if (rol.toLowerCase() === "supervisor") {
          return true;
        }
        return false;
      });
    this.state = {
      isSupervisor: isSupervisor,
      idPrograma: parseInt(AuthService.getIdPrograma()),
      roles: [],
      codigosMeds: []
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { idPrograma, isSupervisor } = this.state;

    if (!isSupervisor) {
      // Buscar los roles para tener sus IDS
      await client
        .query({
          query: RolGraphQL.queries.getRoles,
          fetchPolicy: "network-only",
          variables: {
            idPrograma: idPrograma
          }
        })
        .then(res => {
          if (res.data.getRoles && res.data.getRoles.length > 0) {
            const roles = res.data.getRoles.filter(rol => {
              const nombre = rol.nombre.toLowerCase();
              if (nombre === "supervisor" || nombre === "preventista") {
                return true;
              }
              return false;
            });
            this.setState({
              roles: roles.map(rol => {
                return { value: rol.idRol, label: rol.nombre };
              })
            });
          }
        });

      // Buscar el usuario para tener los códigos de distribuidoras
      await client
        .query({
          query: UserGraphQL.queries.getMiUsuarioCompleto,
          fetchPolicy: "network-only"
        })
        .then(res => {
          if (res.data) {
            const otros = JSON.parse(
              get(res.data, "miUsuario.perfil.otros", "{}")
            );
            let meds = otros.meds;
            if (typeof meds === "string") {
              meds = JSON.parse(meds);
            }
            this.setState({
              codigosMeds: meds
            });
          }
        });
    }
  }

  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  render() {
    const { isSupervisor, roles, codigosMeds } = this.state;
    const { value, submitValue, classes } = this.props;

    return (
      <Grid container spacing={16}>
        {!isSupervisor && (
          <Grid item xs={12} md={4}>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="distribuidora-simple"
                id="distribuidora-simple"
              >
                MED
              </InputLabel>
              <Select
                id="distribuidora-simple-select"
                value={value["distribuidora"] || ""}
                onChange={e => submitValue(e)}
                inputProps={{
                  name: "distribuidora",
                  id: "distribuidora"
                }}
              >
                {codigosMeds &&
                  codigosMeds.map((codigo, i) => {
                    return (
                      <MenuItem key={i} value={codigo}>
                        {codigo}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key="username_OR_nombre"
              name="username_OR_nombre"
              label="Nombre/DNI"
              type="search"
              value={value["username_OR_nombre"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key="email"
              name="email"
              label="Email"
              type="search"
              value={value["email"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              id="estado-simple-select"
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"ACTIVO"}>Activo</MenuItem>
              <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
              <MenuItem value={"PENDIENTE"}>Pendiente</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {!isSupervisor && (
          <Grid item xs={12} md={4}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="rol-simple" id="rol-simple">
                Rol
              </InputLabel>
              <Select
                id="rol-simple-select"
                value={value["idRol"] || ""}
                onChange={e => submitValue(e)}
                inputProps={{
                  name: "idRol",
                  id: "idRol"
                }}
              >
                {roles &&
                  roles.map((rol, i) => {
                    return (
                      <MenuItem key={i} value={rol.value}>
                        {rol.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withApollo(withRouter(withStyles(styles)(advancedFilter)));
