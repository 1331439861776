import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";

import Dialog from "./Dialog.container";
import styles from "./styles";

export default compose(
  withRouter,
  withStyles(styles)
)(Dialog);
