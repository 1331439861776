import gql from "graphql-tag";

export const Email = {};

Email.fragments = {
  emails: gql`
    fragment emails on PaginaHistorialEmail {
      itemsPagina {
        _id
        from
        to
        cc
        cco
        html
        subject
        fecha
      }
      infoPagina {
        numeroPagina
        total
        cantidadPaginas
      }
    }
  `
};

export default Email;
