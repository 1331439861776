import React, { PureComponent } from "react";
import Form from "js/pages/Admin/Programa/ClienteRetail/Edit/MyForm.js";
import AppQraphQL from "js/graphql/resolvers/clienteRetail.resolver";
import { removeTypename } from "js/utils/Helper";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        idPrograma: this.props.match.params.id
      },
      disabled: false,
      errors: null,
      id: this.props.match.params.id,
      documento: this.props.match.params.documento
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { id, documento } = this.state;
    if (documento !== "new") {
      await client
        .query({
          query: AppQraphQL.queries.getClienteRetailById,
          fetchPolicy: "network-only",
          variables: {
            clienteLike: {
              documento: documento
            }
          }
        })
        .then(res => {
          const cliente = res.data.getClientesRetail.itemsPagina[0];
          this.setState({
            initialValues: {
              ...cliente
            }
          });
        });
    } else {
      this.setState({
        initialValues: {
          idPrograma: id,
          estado: "ACTIVO"
        }
      });
    }
  }

  async submit(values) {
    const { client, history } = this.props;
    const { id, documento } = this.state;
    const variables = { ...values };

    await client
      .mutate({
        mutation:
          documento === "new"
            ? AppQraphQL.mutations.createClienteRetail
            : AppQraphQL.mutations.updateClienteRetail,
        variables: {
          cliente: { ...removeTypename(variables) }
        }
      })
      .then(res => {
        const mensaje =
          "Cliente " +
          (documento === "new" ? "creado" : "modificado") +
          " correctamente";
        this.props.openNotification(mensaje);
        history.push(`/admin/programa/${id}/clientes-retail`);
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  handleDateChange = key => date => {
    this.setState({
      [key]: date.toISOString()
    });
  };

  render() {
    return (
      <Form
        id={this.state.id}
        documento={this.state.documento}
        errors={this.state.errors}
        disabled={this.state.disabled}
        initialValues={this.state.initialValues}
        onSubmit={this.submit.bind(this)}
      />
    );
  }
}

export default Edit;
