import React from "react";
import { get } from "lodash";
import { numberWithCommas } from "js/utils";

const WithCurrency = props => {
  const { value, item, path, classes } = props;
  return (
    <div className={classes.tableCell}>
      <span className={classes.currency}>{get(item, path)} </span>
      {numberWithCommas(value) || "--"}
    </div>
  );
};

export default WithCurrency;
