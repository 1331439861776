import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";
import LinkContentWrapper from "js/hoc/LinkContentWrapper";
import Grid from "@material-ui/core/Grid";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Slider = ({
  contenido,
  classes,
  extraOptions = {},
  baseLink,
  isLogoImage
}) => {
  const options = {
    statusFormatter: (current, total) => `${current} de ${total}`,
    showArrows: true,
    autoPlay: true,
    infiniteLoop: true,
    showThumbs: true,
    ...extraOptions
  };
  return (
    <Grid className={classes.sliderContainer}>
      <Carousel {...options} className={classes.carousel}>
        {(contenido.images || []).map(
          (image, index) =>
            (image.link && (
              <LinkContentWrapper
                classes={classes}
                key={`${index}-${image.src || image.url}`}
                link={image.link}
                baseLink={baseLink}
                origen={"link-en-slider"}
              >
                <img alt={image.alt} src={`${image.src || image.url}`} />
              </LinkContentWrapper>
            )) || (
              <img
                key={`${index}-${image.src || image.url}`}
                alt={image.alt}
                src={`${image.src || image.url}`}
                className={isLogoImage ? "logoImage" : ""}
              />
            )
        )}
      </Carousel>
    </Grid>
  );
};

Slider.propTypes = {
  classes: PropTypes.object
};

Slider.displayName = "slider";

export default Slider;
