import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Grid, FormControl, TextField } from "@material-ui/core";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { TypeAhead, UsuarioField } from "js/components/Shared/Form/Fields";
import ClientesGraphQL from "js/graphql/resolvers/clienteRetail.resolver";
import CanalesGraphQL from "js/graphql/resolvers/canalRetail.resolver";

class advancedFilter extends PureComponent {
  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  updateQueryParamsAsync = ({ key, value }) => state => ({
    [key]: value,
    queryParams: {
      programaLike: { [key]: value }
    }
  });

  updateAutocompleteQueryParamsUsuario = key => select => {
    const value = select && select[0] ? select[0].value : undefined;
    this.setState(
      this.updateQueryParamsAsync({ key, value: value }),
      this.props.submitValue({ target: { name: key, value: value } })
    );
  };

  render() {
    const { value, submitValue, classes } = this.props;
    const isMisVentas = window.location.pathname.indexOf("mis") !== -1;

    return (
      <Grid container spacing={16}>
        {!isMisVentas && (
          <Grid item xs={12} md={3}>
            <UsuarioField
              label={"Vendedor"}
              async
              withTodos={true}
              placeholder={"Buscar por vendedor"}
              idPrograma={value["idPrograma"]}
              noOptionsMessage={"No hay usuarios"}
              onChange={this.updateAutocompleteQueryParamsUsuario("idUsuario")}
              value={value["idUsuario"]}
              disabled={!value["idPrograma"]}
            />
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              disabled={!value["idPrograma"]}
              query={ClientesGraphQL.queries.getClientesRetailInput}
              queryParams={{ idPrograma: value["idPrograma"] }}
              placeholder={"Cliente"}
              label={"Cliente"}
              path={{
                path: ["getClientesRetail", "itemsPagina"],
                value: "value",
                label: "label",
                defaultLabel: "value"
              }}
              onChange={this.handleChange("idCliente")}
              value={value["idCliente"]}
              withTodos
            />
          </FormControl>
        </Grid>

        {!isMisVentas && (
          <Grid item xs={12} md={4}>
            <FormControl className={classes.formControl}>
              <TypeAhead
                disabled={!value["idPrograma"]}
                query={CanalesGraphQL.queries.getCanalesRetailInput}
                queryParams={{ idPrograma: value["idPrograma"] }}
                placeholder={"Canal"}
                label={"Canal"}
                path={{
                  path: ["getCanalesRetail", "itemsPagina"],
                  value: "value",
                  label: "label",
                  defaultLabel: "value"
                }}
                onChange={this.handleChange("idCanal")}
                value={value["idCanal"]}
                withTodos
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"numeroFactura"}
              name={"numeroFactura"}
              label={"N° de factura"}
              type="search"
              value={value["numeroFactura"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionDesde"}
              name={"fechaCreacionDesde"}
              label="Fecha Creación (Desde)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionDesde",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionDesde"]
                  ? moment(value["fechaCreacionDesde"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionHasta"}
              name={"fechaCreacionHasta"}
              label="Fecha Creación (Hasta)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionHasta",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionHasta"]
                  ? moment(value["fechaCreacionHasta"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
