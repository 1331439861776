import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const CollapsableComponent = ({ collapsableTitle, classes, children }) => (
  <ExpansionPanel>
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
      classes={{ root: classes.rootExpansionPanelSummary }}
    >
      {collapsableTitle && (
        <Typography className={classes.heading} variant="subtitle2">
          {collapsableTitle}
        </Typography>
      )}
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <div className={classes.dragSection}>{children}</div>
    </ExpansionPanelDetails>
  </ExpansionPanel>
);

export default CollapsableComponent;
