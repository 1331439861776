import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Enhancer from "./container";
import {
  GeneralWrapper,
  CollapsableSection as Section,
  Subsection
} from "../../Layouts";
import {
  ColorPicker,
  PixelPicker,
  Select,
  SortableComponent,
  Link,
  FontPicker
} from "js/pages/TemplateBuilder2/Controls";
import { _t } from "js/utils/TranslationService";
import RedesSociales from "./RedesSociales";
import Background from "js/pages/TemplateBuilder2/Components/Background";
import Tabs from "./Tabs";
import TabContent from "./Tabs/tabContent";
import { FormFooterContenidos } from "./Components";
import { get } from "lodash";
import Cucardas from "./Cucardas";
import Switch from "@material-ui/core/Switch/Switch";
import Fila from "js/pages/TemplateBuilder2/Components/Fila";

const LayoutBuilder = ({
  onGetTemplate,
  onChangeCatalogo,
  catalogo,
  onChangeCatalogoBeneficios,
  catalogoBeneficios,
  header,
  onChangeHeader,
  onChangeAccess,
  onChangeSignin,
  accessTabsConfig,
  menuNavbarItems,
  onChangeFooter,
  footerContenidos,
  footer,
  templateData,
  onChangeTemplateData,
  infoPrograma,
  contenidoArribaBox,
  contenidoAbajoBox,
  onChangeFilaTipo,
  idPrograma,
  conCancelar,
  match: {
    params: { id }
  },
  siginAdicionales,
  onChangeSiginAdicionales
}) => (
  <GeneralWrapper
    title={`Layout del programa ${infoPrograma.nombre}`}
    subtitle="Este es el editor del layout del programa"
    onGetTemplate={onGetTemplate}
    idPrograma={idPrograma}
    conCancelar={conCancelar}
  >
    <React.Fragment>
      <Section title="Login">
        <Subsection title="Fondo">
          <Grid container spacing={24}>
            <Grid item xs={9}>
              <Background
                backgrounds={get(templateData, "theme.backgrounds.access")}
                onChange={onChangeTemplateData}
                title="Tipo"
                path="theme.backgrounds.access"
              />
            </Grid>
          </Grid>
        </Subsection>
        <Subsection title="Contenidos">
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Tabs
                onChange={onChangeAccess}
                tabsConfig={accessTabsConfig}
                tabContent={TabContent}
              />
            </Grid>
          </Grid>
        </Subsection>
        <Subsection title="Contenido arriba del box">
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Fila
                value={contenidoArribaBox}
                indexItem={0}
                position={["arriba"]}
                onChange={onChangeSignin}
                onChangeFilaTipo={onChangeFilaTipo}
                idPrograma={idPrograma || id}
              />
            </Grid>
          </Grid>
        </Subsection>
        <Subsection title="Contenido abajo del box">
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Fila
                value={contenidoAbajoBox}
                indexItem={0}
                position={["abajo"]}
                onChange={onChangeSignin}
                onChangeFilaTipo={onChangeFilaTipo}
                idPrograma={idPrograma || id}
              />
            </Grid>
          </Grid>
        </Subsection>
        <Subsection title="Adicionales">
          <Grid container spacing={24}>
            <Grid item xs={12} md={2}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Firma de términos y condiciones")}
              </Typography>
              <Switch
                checked={get(siginAdicionales, "firmaTerminos")}
                color="secondary"
                onChange={e =>
                  onChangeSiginAdicionales("firmaTerminos")(e.target.checked)
                }
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                key="textoTerminos"
                name="textoTerminos"
                type="text"
                placeholder="Texto a mostrar con el checkbox"
                value={get(siginAdicionales, "textoTerminos", null)}
                style={{ width: "100%" }}
                onChange={onChangeSiginAdicionales("textoTerminos")}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                key="linkTermios"
                name="linkTermios"
                type="text"
                placeholder="Link (separar el link con la parte del texto a poner como link con '|'. Ver datos de perfil)"
                value={get(siginAdicionales, "linkTermios", null)}
                style={{ width: "100%" }}
                onChange={onChangeSiginAdicionales("linkTermios")}
              />
            </Grid>
          </Grid>
        </Subsection>
      </Section>

      <Section title="Encabezado">
        <Subsection title="">
          <Grid container spacing={24}>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Alto")}
              </Typography>
              <PixelPicker
                pixels={get(header, "menu.toolbar.height")}
                onChange={onChangeHeader("menu.toolbar.height")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Color")}
              </Typography>
              <ColorPicker
                color={get(header, "css.backgroundColor")}
                onChange={onChangeHeader("css.backgroundColor")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Margen inferior")}
              </Typography>
              <PixelPicker
                pixels={get(header, "css.marginBottom")}
                onChange={onChangeHeader("css.marginBottom")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Diámetro avatar")}
              </Typography>
              <PixelPicker
                pixels={get(header, "user.avatar.diameter")}
                onChange={onChangeHeader("user.avatar.diameter")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Tamaño de fuente")}
              </Typography>
              <PixelPicker
                pixels={get(header, "user.css.fontSize")}
                onChange={onChangeHeader("user.css.fontSize")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Color de fuente")}
              </Typography>
              <ColorPicker
                color={get(header, "user.css.color")}
                onChange={onChangeHeader("user.css.color")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Color de puntos")}
              </Typography>
              <ColorPicker
                color={get(header, "user.puntos.css.color")}
                onChange={onChangeHeader("user.puntos.css.color")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Fuente")}
              </Typography>
              <FontPicker
                pickerId="fontPickerHeader"
                activeFont={get(header, "user.css.fontFamily")}
                onChange={onChangeHeader("user.css.fontFamily")}
              />
            </Grid>
          </Grid>
        </Subsection>

        <Subsection title="Menú">
          <Grid container spacing={24}>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Tipo")}
              </Typography>
              <Select
                options={[
                  { label: "Abajo", value: "below" },
                  { label: "Dentro", value: "inside" },
                  { label: "Burger", value: "burger" }
                ]}
                value={get(header, "menu.tipo")}
                onChange={onChangeHeader("menu.tipo")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Color")}
              </Typography>
              <ColorPicker
                color={get(header, `menu.navbar.css.backgroundColor`)}
                onChange={onChangeHeader(`menu.navbar.css.backgroundColor`)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Tamaño de fuente")}
              </Typography>
              <PixelPicker
                pixels={get(header, `menu.navbar.css.fontSize`)}
                onChange={onChangeHeader(`menu.navbar.css.fontSize`)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Color de fuente")}
              </Typography>
              <ColorPicker
                color={get(header, `menu.navbar.css.color`)}
                onChange={onChangeHeader(`menu.navbar.css.color`)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Color de hover/active")}
              </Typography>
              <ColorPicker
                color={get(
                  header,
                  `menu.navbar.css.${" > a:hover"}.backgroundColor`,
                  "#000"
                )}
                onChange={onChangeHeader(
                  `menu.navbar.css.${" > a:hover"}.backgroundColor`
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Color de fuente de hover")}
              </Typography>
              <ColorPicker
                color={get(
                  header,
                  `menu.navbar.css.${" > a:hover"}.color`,
                  "#000"
                )}
                onChange={onChangeHeader(
                  `menu.navbar.css.${" > a:hover"}.color`
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Color de línea selectora")}
              </Typography>
              <ColorPicker
                color={get(header, `menu.navbar.activeColor`)}
                onChange={onChangeHeader(`menu.navbar.activeColor`)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Fuente")}
              </Typography>
              <FontPicker
                pickerId="fontPickerHeaderMenu"
                activeFont={get(header, "menu.navbar.css.fontFamily")}
                onChange={onChangeHeader(`menu.navbar.css.fontFamily`)}
              />
            </Grid>
          </Grid>
        </Subsection>
        {get(header, "menu.tipo") === "inside" && (
          <Subsection title="Opciones del menú">
            <Grid container spacing={24}>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {_t("Alto")}
                </Typography>
                <PixelPicker
                  pixels={get(header, "menu.navbar.height")}
                  onChange={onChangeHeader("menu.navbar.height")}
                />
              </Grid>
            </Grid>
          </Subsection>
        )}
        {get(header, "menu.tipo") === "below" && (
          <Subsection title="Opciones del menú">
            <Grid container spacing={24}>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {_t("Alineación del menú")}
                </Typography>
                <Select
                  options={[
                    { label: "Izquierda", value: "left" },
                    { label: "Centro", value: "center" },
                    { label: "Derecha", value: "right" }
                  ]}
                  value={get(header, "menu.navbar.below.align")}
                  onChange={onChangeHeader("menu.navbar.below.align")}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {_t("Alto")}
                </Typography>
                <PixelPicker
                  pixels={get(header, "menu.navbar.height")}
                  onChange={onChangeHeader("menu.navbar.height")}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {_t("Borde superior")}
                </Typography>
                <Select
                  options={[
                    { label: "Sin borde", value: "hidden" },
                    { label: "Doble", value: "double" },
                    { label: "Guionado", value: "dashed" },
                    { label: "Normal", value: "solid" },
                    { label: "Punteado", value: "dotted" }
                  ]}
                  value={get(
                    header,
                    "menu.navbar.below.borderTopStyle",
                    "hidden"
                  )}
                  onChange={onChangeHeader("menu.navbar.below.borderTopStyle")}
                />
              </Grid>
              {get(header, "menu.navbar.below.borderTopStyle", "hidden") !==
                "hidden" && (
                <React.Fragment>
                  <Grid item xs={12} md={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      {_t("Grosor de borde")}
                    </Typography>
                    <PixelPicker
                      pixels={`${get(
                        header,
                        "menu.navbar.below.borderTopWidth",
                        ""
                      )}`}
                      onChange={onChangeHeader(
                        "menu.navbar.below.borderTopWidth"
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      {_t("Color de borde")}
                    </Typography>
                    <ColorPicker
                      color={get(header, "menu.navbar.below.borderColor")}
                      onChange={onChangeHeader("menu.navbar.below.borderColor")}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Subsection>
        )}
        <Subsection title="Links">
          <Grid container spacing={24}>
            <SortableComponent
              items={menuNavbarItems}
              newItem={{ link: "", texto: "", perms: [] }}
              onChange={onChangeHeader("menu.navbar.items")}
              component={Link}
              metadata={{
                withPerms: true,
                idPrograma: idPrograma || id
              }}
            />
          </Grid>
        </Subsection>
      </Section>

      <Section title="Footer">
        <Subsection title="Contenido">
          <Grid container spacing={24}>
            <SortableComponent
              items={footerContenidos}
              newItem={{ links: [{ link: "", nombre: "" }], titulo: "" }}
              onChange={onChangeFooter("columnasFooter")}
              component={FormFooterContenidos}
              maxAmountItems={4}
              metadata={{
                idPrograma: idPrograma || id
              }}
            />
          </Grid>
        </Subsection>
        <Subsection title="General">
          <Grid container spacing={24} direction="column">
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Margen superior")}
              </Typography>
              <PixelPicker
                pixels={get(footer, "marginTop")}
                onChange={onChangeFooter("marginTop")}
              />
            </Grid>
          </Grid>
        </Subsection>
        <Subsection title="Datos de contacto">
          <Grid container spacing={24} direction="column">
            <Grid item xs={12}>
              <TextField
                key="datosContacto"
                name="datosContacto"
                type="search"
                placeholder="Teléfono: (54 11) 4711-6445 - Email: info@ashiwea.com"
                value={get(footer, "datosContacto")}
                style={{ width: "100%" }}
                onChange={onChangeFooter("datosContacto")}
              />
            </Grid>
          </Grid>
        </Subsection>
        <Subsection title="Redes sociales">
          <Grid container spacing={24} direction="column">
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Color")}
              </Typography>
              <ColorPicker
                color={get(footer, "colorRedes")}
                onChange={onChangeFooter("colorRedes")}
              />
            </Grid>
            <RedesSociales />
          </Grid>
        </Subsection>
      </Section>

      <Section title="Catálogo">
        <Subsection title="Contenido">
          <Grid container spacing={24}>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Modo default")}
              </Typography>
              <Select
                options={[
                  { label: "Lista", value: "lista" },
                  { label: "Grilla", value: "grid" }
                ]}
                value={catalogo.vista || "grilla"}
                onChange={onChangeCatalogo("vista")}
              />
              <Switch
                name={"conFiltro"}
                onChange={e => onChangeCatalogo("conFiltro")(e.target.checked)}
                checked={
                  catalogo.conFiltro !== undefined ? catalogo.conFiltro : true
                }
              />
              Tiene filtro
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Ordenar por")}
              </Typography>
              <Select
                options={[
                  { label: "Producto", value: "producto_nombre" },
                  { label: "Puntos", value: "puntos" }
                ]}
                value={get(catalogo, "catalogoOrder.column")}
                onChange={onChangeCatalogo("catalogoOrder.column")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Sentido")}
              </Typography>
              <Select
                options={[
                  { label: "Ascendente", value: "asc" },
                  { label: "Descendente", value: "desc" }
                ]}
                value={get(catalogo, "catalogoOrder.order", "asc")}
                onChange={onChangeCatalogo("catalogoOrder.order")}
              />
            </Grid>
          </Grid>
        </Subsection>
        <Subsection title="Cucardas">
          <Cucardas
            idSection="catalogo"
            types={get(templateData, "cucardas")}
            onChange={onChangeTemplateData("templateData.cucardas")}
          />
        </Subsection>
      </Section>
      <Section title="Catálogo Beneficios">
        <Subsection title="Contenido">
          <Grid container spacing={24}>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Modo default")}
              </Typography>
              <Select
                options={[
                  { label: "Lista", value: "lista" },
                  { label: "Grilla", value: "grid" }
                ]}
                value={catalogoBeneficios ? catalogoBeneficios.vista : "grilla"}
                onChange={onChangeCatalogoBeneficios("vista")}
              />
              <Switch
                name={"conFiltro"}
                onChange={e =>
                  onChangeCatalogoBeneficios("conFiltro")(e.target.checked)
                }
                checked={
                  catalogoBeneficios &&
                  catalogoBeneficios.conFiltro !== undefined
                    ? catalogoBeneficios.conFiltro
                    : true
                }
              />
              Tiene filtro
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Ordenar por")}
              </Typography>
              <Select
                options={[{ label: "Beneficio", value: "nombre" }]}
                value={get(
                  catalogoBeneficios,
                  "catalogoBeneficiosOrder.column"
                )}
                onChange={onChangeCatalogoBeneficios(
                  "catalogoBeneficiosOrder.column"
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Sentido")}
              </Typography>
              <Select
                options={[
                  { label: "Ascendente", value: "asc" },
                  { label: "Descendente", value: "desc" }
                ]}
                value={get(
                  catalogoBeneficios,
                  "catalogoBeneficiosOrder.order",
                  "asc"
                )}
                onChange={onChangeCatalogoBeneficios(
                  "catalogoBeneficiosOrder.order"
                )}
              />
            </Grid>
          </Grid>
        </Subsection>
        {/* <Subsection title="Cucardas">
          <Cucardas
            types={get(templateData, "cucardas")}
            onChange={onChangeTemplateData("templateData.cucardas")}
          />
        </Subsection> */}
        <Subsection title="Cucardas">
          <Cucardas
            idSection="catalogoBeneficios"
            types={
              get(catalogoBeneficios, "cucardas")
                ? get(catalogoBeneficios, "cucardas")
                : get(templateData, "cucardas")
            }
            onChange={onChangeCatalogoBeneficios("catalogoBeneficios.cucardas")}
          />
        </Subsection>
      </Section>
    </React.Fragment>
  </GeneralWrapper>
);

LayoutBuilder.propTypes = {
  programa: PropTypes.shape({
    template: PropTypes.object,
    accesss: PropTypes.object,
    catalogo: PropTypes.object,
    catalogoBeneficios: PropTypes.object,
    destacados: PropTypes.object,
    footer: PropTypes.object,
    header: PropTypes.object,
    landing: PropTypes.object,
    templateData: PropTypes.object
  }),
  classes: PropTypes.object,
  onGetTemplate: PropTypes.func.isRequired
};

LayoutBuilder.displayName = "LayoutBuilder";

export default Enhancer(LayoutBuilder);
