import React, { PureComponent } from "react";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppQraphQL from "js/graphql/resolvers/idioma.resolver";

import Header from "js/components/Shared/Tabla/Header";
import TablaIdioma from "js/pages/Admin/Programa/Idioma/Table";
import { styles } from "js/pages/Admin/Programa/Idioma/styles.js";
import { USER_ROLES as Roles } from "js/models/User";
import get from "lodash/get";

class Idioma extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: []
    };
  }

  getIdiomasPrograma() {
    const {
      client,
      match: {
        params: { id: idPrograma }
      }
    } = this.props;

    return client
      .query({
        query: AppQraphQL.queries.getIdiomasByProgramaId,
        fetchPolicy: "network-only",
        variables: { idPrograma: parseInt(idPrograma) || idPrograma }
      })
      .then(res => {
        this.setState({
          initialValues: get(
            res,
            "data.getProgramas.itemsPagina[0].idiomas",
            []
          )
        });
      });
  }

  async componentDidMount() {
    await this.getIdiomasPrograma();
  }

  render() {
    const { initialValues } = this.state;
    const defaultHeader = {
      title: "Idiomas",
      new: {
        title: "Agregar idioma",
        perms: [Roles.IDIOMA_W]
      }
    };

    return (
      <div>
        <Header values={defaultHeader} />
        {initialValues && (
          <TablaIdioma
            rows={initialValues}
            refresh={this.getIdiomasPrograma.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default withApollo(withRouter(withStyles(styles)(Idioma)));
