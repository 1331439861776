import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import styles from "./styles.js";
import SigninRaw from "./Signin.container";
import SignupRaw from "./Signup";
import SSORaw from "./SSO";
import RestorePasswordRaw from "./RestorePassword";
import RestoreUserRaw from "./RestoreUser";
import EmailVerificadoRaw from "./EmailVerificado";
import withPrograma from "js/hoc/withPrograma";
import { withNotificationContext } from "js/notification-context";
import { connect } from "react-redux";
import { getUrl } from "js/utils";
import get from "lodash/get";

const mapStateToProps = state => {
  const logo = get(
    state,
    "programa.template.programa.template.header.logoPrincipal.url",
    ""
  );
  return {
    logo: getUrl(logo)
  };
};

const enhance = compose(
  withNotificationContext,
  withRouter,
  withPrograma,
  withStyles(styles),
  connect(mapStateToProps)
);

export const Signin = enhance(SigninRaw);
export const Signup = enhance(SignupRaw);
export const RestorePassword = enhance(RestorePasswordRaw);
export const RestoreUser = enhance(RestoreUserRaw);
export const SSO = enhance(SSORaw);
export const EmailVerificado = enhance(EmailVerificadoRaw);

export default {
  Signup,
  Signin,
  RestorePassword,
  RestoreUser,
  SSO,
  EmailVerificado
};
