import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector, change, getFormMeta } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";
import { TextField } from "redux-form-material-ui";

import ErrorList from "js/components/Shared/ErrorList";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import AddressMap from "js/components/Mapa/AddressMap";
import {
  PaisField,
  ZonaField,
  LocalidadField,
  ListField,
  InputTextarea,
  InputTextField,
  InputNumberField
} from "js/components/Shared/Form/Fields";
import { renderFecha } from "js/utils/Helper";

class Form extends PureComponent {
  state = { address: "", timer: setTimeout(() => {}) };

  keyUpAddress = e => {
    const { direccion: address } = this.props;
    clearTimeout(this.state.timer);
    this.setState({
      timer: setTimeout(() => this.setState({ address }), 3000)
    });
  };

  componentWillReceiveProps = async () => {
    const {
      initialValues: { direccion: address },
      pristine
    } = this.props;
    if (pristine) this.setState({ address });
  };

  render() {
    const {
      initialValues,
      handleSubmit,
      submitting,
      pristine,
      changeFieldValue,
      classes,
      errors,
      history,
      disabled,
      idPais,
      idZona,
      formMeta,
      idSucursal,
      id,
      idBeneficio,
      beneficio
    } = this.props;
    const { address } = this.state;
    return (
      <Fragment>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {disabled
                ? "Ver "
                : idSucursal === "new"
                  ? "Nueva "
                  : "Modificar "}
              Sucursal del beneficio: {beneficio}
            </Typography>
          </Grid>
        </Grid>
        <form className={classes.container} onSubmit={handleSubmit}>
          <Grid className={classes.grid} container spacing={24}>
            <Grid container direction="row" item xs={12} sm={4} spacing={16}>
              <Grid item xs={12}>
                <InputTextField
                  name="nombre"
                  label="Nombre"
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <ListField
                  name="estado"
                  label="Estado"
                  options={[
                    { label: "Activo", value: "ACTIVO" },
                    { label: "Inactivo", value: "INACTIVO" }
                  ]}
                  disabled={disabled || idSucursal === "new"}
                  required
                />
              </Grid>
            </Grid>
            <Grid container direction="row" item xs={12} sm={4} spacing={16}>
              <Grid item xs={12}>
                <InputTextField
                  name="telefono"
                  label="Teléfono"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <InputTextField
                  name="direccion"
                  label="Dirección"
                  disabled={disabled}
                  required
                  onKeyUp={this.keyUpAddress}
                  helperText={"Verifique la dirección ingresada en el mapa"}
                />
              </Grid>
            </Grid>
            <Grid container direction="column" item xs={12} sm={4} spacing={16}>
              <Grid item xs={12}>
                <AddressMap address={address} />
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="h6">Área de operación</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <PaisField
                formMeta={formMeta}
                changeFieldValue={changeFieldValue}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ZonaField
                idPais={idPais}
                formMeta={formMeta}
                changeFieldValue={changeFieldValue}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <LocalidadField
                idPais={idPais}
                idZona={idZona}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <InputTextarea
                name="notas"
                label="Notas"
                disabled={disabled}
                multiple
              />
            </Grid>
            <Grid item xs={4}>
              <InputNumberField
                name="orden"
                label="Orden"
                disabled={disabled}
              />
            </Grid>
          </Grid>

          {/* Datos de creación */}
          {idSucursal !== "new" && (
            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Datos de creación</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Usuario creador"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={get(initialValues, "usuarioCreador.username", "--")}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Fecha de creación"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={renderFecha(initialValues.fechaCreacion) || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Usuario modificador"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={get(
                      initialValues,
                      "usuarioModificador.username",
                      "--"
                    )}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Fecha de modificación"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={renderFecha(initialValues.fechaModificacion) || ""}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          <ErrorList errors={errors} />
          <Grid className={classes.grid} container>
            <Grid item xs />
            <Grid item xs />
            <Grid className={classes.gridButton} item xs>
              <Button
                type="button"
                variant="outlined"
                size="large"
                color="primary"
                className={classes.button}
                onClick={() =>
                  history.push(
                    `/admin/programa/${id}/beneficios/${idBeneficio}/sucursales-beneficio`
                  )
                }
              >
                Cancelar
              </Button>
            </Grid>
            {AuthService.hasPerms([Roles.SUCURSAL_BENEFICIO_W]) && (
              <Grid className={classes.gridButton} item xs>
                {disabled && (
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    onClick={() =>
                      history.push(
                        `/admin/programa/${id}/beneficios/${idBeneficio}/sucursales-beneficio/${idSucursal}/edit`
                      )
                    }
                  >
                    Editar
                  </Button>
                )}
                {!disabled && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    disabled={pristine || submitting}
                  >
                    Guardar
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      </Fragment>
    );
  }
}

Form = reduxForm({
  form: "formSucursalBeneficio",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formSucursalBeneficio");

Form = connect(
  state => ({
    formMeta: getFormMeta("formSucursalBeneficio")(state),
    idPais: selector(state, "idPais"),
    idZona: selector(state, "idZona"),
    direccion: selector(state, "direccion")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formSucursalBeneficio", field, value))
  })
)(Form);

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: theme.container.maxWidth,
    margin: theme.container.margin
  },
  field: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  grid: {
    margin: "0"
  },
  gridButton: {
    marginTop: "20px",
    textAlign: "end"
  },
  button: {
    width: "200px"
  }
});

export default withRouter(withStyles(styles)(Form));
