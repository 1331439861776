import React from "react";
import { get, capitalize } from "lodash";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import { numberWithCommas } from "js/utils";
import { renderFechaWithFormat } from "js/utils/Helper.js";

const columns = [
  {
    id: "idCanje",
    label: "ID",
    isNumeric: true,
    type: "custom",
    parser: id => (id ? numberWithCommas(id) : "--")
  },
  {
    id: "fecha",
    label: "Fecha",
    type: "custom",
    parser: fecha => renderFechaWithFormat("DD/MM/YY", fecha)
  },
  {
    id: "usuario",
    sortName: "usuario_username",
    label: "Usuario",
    type: "custom",
    parser: usuario => get(usuario, ["username"], "--")
  },
  {
    id: "usuario",
    sortName: "usuario_perfil_nombre",
    label: "Nombre",
    type: "custom",
    parser: usuario => get(usuario, ["perfil", "nombre"], "--")
  },
  {
    id: "usuario",
    sortName: "usuario_perfil_apellido",
    label: "Apellido",
    type: "custom",
    parser: usuario => get(usuario, ["perfil", "apellido"], "--")
  },
  {
    id: "eleccionProducto",
    sortName: "eleccionProducto_producto_nombre",
    label: "Producto",
    type: "custom",
    parser: (eleccionProducto, data) =>
      `${get(data, ["cantidad"], "--")} x ${get(
        eleccionProducto,
        ["producto", "nombre"],
        "--"
      )}`
  },
  {
    id: "puntosPorUnidad",
    removeSort: true,
    isNumeric: true,
    label: "Puntos por unidad",
    type: "custom",
    parser: (puntos, data) =>
      get(data, ["puntos"], 0) && get(data, ["cantidad"], 0)
        ? numberWithCommas(get(data, ["puntos"], 0) / get(data, ["cantidad"]))
        : "--"
  },
  {
    id: "puntosTotal",
    sortName: "puntos",
    label: "Puntos total",
    isNumeric: true,
    type: "custom",
    parser: (puntos, data) =>
      get(data, ["puntos"], 0)
        ? numberWithCommas(get(data, ["puntos"], 0))
        : "--"
  },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  {
    id: "fechaEntrega",
    label: "Fecha de entrega",
    type: "custom",
    parser: fechaEntrega => renderFechaWithFormat("DD/MM/YY", fechaEntrega)
  },
  {
    id: "actions",
    label: "Acciones",
    removeSort: true
  }
];

export default columns;
