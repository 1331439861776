import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { TIPO_ACCION } from "../../constants";
import find from "lodash/find";
import { withNotificationContext } from "js/notification-context";

const Title = ({ clipboard, rankingUrl, tipo }) => {
  const title = (find(TIPO_ACCION, ["value", tipo]) || {}).label;
  return (
    <Fragment>
      <Typography variant="subheading" gutterBottom color="primary">
        {title}
      </Typography>
    </Fragment>
  );
};
Title.defaultProps = {
  onClick: () => undefined
};
export default withNotificationContext(Title);
