import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Subsection } from "js/pages/TemplateBuilder2/Layouts";
import { get } from "lodash";
import {
  ColorPicker,
  Input,
  PixelPicker,
  FontPicker,
  Select
} from "js/pages/TemplateBuilder2/Controls";
import { _t } from "js/utils/TranslationService";
import ImageUploader, { Avatar } from "js/components/Shared/ImageUploader";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { uploadImage } from "js/utils.js";
import Switch from "@material-ui/core/Switch";
import UploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import { Mutation } from "react-apollo";
import Tooltip from "@material-ui/core/Tooltip";
import ImportarGraphQL from "js/graphql/resolvers/importar.resolver";
import { withNotificationContext } from "js/notification-context";

const createLabel = (label, type) => (
  <Typography variant={`subtitle${type}`} gutterBottom>
    {_t(label)}
  </Typography>
);

class BaseForm extends React.PureComponent {
  getKey = key => {
    const { position } = this.props;
    switch (position.length) {
      case 1:
        return `contenidos.${position[0]}.${key}`;
      case 2:
        return `${position[0]}.contenidos.${position[1]}.${key}`;
      default:
        return `${position[0]}.contenidos.${position[1]}.bases.${
          position[2]
        }.${key}`;
    }
  };

  handleOnChangeImage = key => async e => {
    const url = await uploadImage({})(e);
    this.handleOnChange(key)(url);
  };

  handleOnChange = key => this.props.onChange(this.getKey(key));

  render() {
    const {
      classes,
      value,
      position,
      idPrograma,
      filaIndex,
      indexItem
    } = this.props;
    const key = position.join("-");
    const success =
      "El archivo se subió correctamente. Recordá guardar los cambios antes de salir.";
    const errorMessage =
      "Hubo un error al procesar el archivo. Contacta con un administrador";

    return (
      <Grid container className={classes.form}>
        <Subsection title="Datos">
          <Grid container spacing={24}>
            <Grid item>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Logo")}
              </Typography>
              <ImageUploader
                onChangeImage={this.handleOnChangeImage("src")}
                withRecomendations={false}
                accept="image/jpeg, image/png, image/webp, image/gif"
                formatosSoportados="JPG, PNG y GIF"
              >
                <Avatar url={get(value, "src", "")} />
              </ImageUploader>
            </Grid>
            <Grid item xs={12} sm={6}>
              {createLabel("Es transparente", 2)}
              <Switch
                classes={classes}
                checked={get(value, "esTransparente")}
                onChange={e =>
                  this.handleOnChange("esTransparente")(e.target.checked)
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              {createLabel("Link", 2)}
              <Input
                value={get(value, "link")}
                onChange={this.handleOnChange("link")}
              />
            </Grid>
            {(get(value, "link", "").indexOf("/informacion") > -1 ||
              get(value, "link", "").indexOf("/privado") > -1) && (
              <Fragment>
                <Grid item xs={12} sm={5}>
                  {createLabel("URL del contenido", 2)}
                  <Grid container>
                    <Grid xs={10} item>
                      <Input
                        value={get(value, "iframe")}
                        onChange={this.handleOnChange("iframe")}
                      />
                    </Grid>
                    <Grid xs={2} item>
                      <Tooltip title="Subir contenido">
                        <IconButton
                          onClick={() =>
                            document
                              .getElementById(`upload-ubicaciones-${key}`)
                              .click()
                          }
                        >
                          <Mutation
                            mutation={
                              ImportarGraphQL.queries.importarContenidos
                            }
                          >
                            {mutate => {
                              return (
                                <input
                                  id={`upload-ubicaciones-${key}`}
                                  type="file"
                                  style={{ display: "none" }}
                                  required
                                  onChange={({
                                    target: {
                                      validity,
                                      files: [file]
                                    }
                                  }) => {
                                    return (
                                      validity.valid &&
                                      mutate({
                                        variables: {
                                          file,
                                          idPrograma: parseInt(idPrograma)
                                        }
                                      })
                                        .then(({ data }) => {
                                          this.props.openNotification(success);
                                          this.handleOnChange("iframe")(
                                            data.importarContenidos
                                          );
                                        })
                                        .catch(() => {
                                          this.props.openNotification(
                                            errorMessage
                                          );
                                        })
                                        .finally(
                                          () =>
                                            (document.getElementById(
                                              `upload-ubicaciones-${key}`
                                            ).value = "")
                                        )
                                    );
                                  }}
                                />
                              );
                            }}
                          </Mutation>
                          <UploadIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {createLabel("Altura del iframe", 2)}
                  <Input
                    value={get(value, "height")}
                    onChange={this.handleOnChange("height")}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  {createLabel("Borde del iframe", 2)}
                  <Input
                    value={get(value, "padding")}
                    onChange={this.handleOnChange("padding")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {createLabel("Fondo del iframe", 2)}
                  <ColorPicker
                    color={get(value, "backgroundColor")}
                    onChange={this.handleOnChange("backgroundColor")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {createLabel("Full width", 2)}
                  <Switch
                    classes={classes}
                    checked={get(value, "fullWidth")}
                    onChange={e =>
                      this.handleOnChange("fullWidth")(e.target.checked)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {createLabel("Margen superior", 2)}
                  <Input
                    value={get(value, "marginTop")}
                    onChange={this.handleOnChange("marginTop")}
                  />
                </Grid>
              </Fragment>
            )}
            <Grid item xs={12} sm={6}>
              {createLabel("Abrir en", 2)}
              <div className={classes.select}>
                <Select
                  options={[
                    { label: "Abrir en la misma pestaña", value: "_self" },
                    { label: "Abrir en una pestaña nueva", value: "_blank" },
                    { label: "Abrir en una ventana nueva", value: "new" }
                  ]}
                  value={value.target || "_self"}
                  onChange={value =>
                    this.handleOnChange("target")({ target: { value } })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              {createLabel("Border Radius", 2)}
              <PixelPicker
                pixels={get(value, "css.borderRadius")}
                onChange={this.handleOnChange("css.borderRadius")}
              />
            </Grid>
          </Grid>
        </Subsection>
        <Subsection title="Textos">
          <Grid container spacing={24}>
            <Grid item xs sm={4}>
              {createLabel("Título", 2)}
              <Input
                value={get(value, "texto.contenido")}
                onChange={this.handleOnChange("texto.contenido")}
              />
            </Grid>
            <Grid item xs sm={4}>
              {createLabel("Color del título", 2)}
              <ColorPicker
                color={get(value, "texto.css.color")}
                onChange={this.handleOnChange("texto.css.color")}
              />
            </Grid>
            <Grid item xs sm={4}>
              {createLabel("Tipografía del título", 2)}
              <FontPicker
                pickerId={`fontPickerTitulo${filaIndex}${indexItem}`}
                activeFont={get(value, "texto.css.fontFamily")}
                onChange={this.handleOnChange("texto.css.fontFamily")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={24}>
            <Grid item xs sm={4}>
              {createLabel("Subtítulo", 2)}
              <Input
                value={get(value, "subtexto.contenido")}
                onChange={this.handleOnChange("subtexto.contenido")}
              />
            </Grid>
            <Grid item xs sm={4}>
              {createLabel("Color del subtítulo", 2)}
              <ColorPicker
                color={get(value, "subtexto.css.color")}
                onChange={this.handleOnChange("subtexto.css.color")}
              />
            </Grid>
            <Grid item xs sm={4}>
              {createLabel("Tipografía del Subtitulo", 2)}
              <FontPicker
                pickerId={`fontPickerSubtitulo${filaIndex}${indexItem}`}
                activeFont={get(value, "subtexto.css.fontFamily")}
                onChange={this.handleOnChange("subtexto.css.fontFamily")}
              />
            </Grid>
            <Grid item sm={4}>
              <Typography variant="subtitle2" gutterBottom>
                {_t("Degradado")}
              </Typography>
              <Switch
                checked={get(value, "caption.degradado")}
                color="secondary"
                onChange={e =>
                  this.handleOnChange("caption.degradado")(e.target.checked)
                }
              />
            </Grid>
            {get(value, "caption.degradado", false) && (
              <Fragment>
                <Grid item sm={4}>
                  <Typography variant="subtitle2" gutterBottom>
                    {_t("Color")}
                  </Typography>
                  <ColorPicker
                    color={get(value, "caption.colorDegradado")}
                    onChange={this.handleOnChange("caption.colorDegradado")}
                  />
                </Grid>
                <Grid item sm={3} />
              </Fragment>
            )}
          </Grid>
        </Subsection>
        {/* {value.tipo == "base" && (
      <BasePreview contenido={value} classes={classes} />
    )} */}
      </Grid>
    );
  }
}
BaseForm.propTypes = {
  classes: PropTypes.object
};

BaseForm.defaultProps = {};
BaseForm.displayName = "base";

export default withNotificationContext(withStyles(styles)(BaseForm));
