import Edit from "./Edit";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withNotificationContext } from "js/notification-context";

const enhance = compose(
  withApollo,
  withNotificationContext,
  withRouter
);

export default enhance(Edit);
