import React, { PureComponent, Fragment } from "react";
import Form from "js/pages/Admin/Producto/Sucursal/Edit/MyForm.js";
import AppQraphQL from "js/graphql/resolvers/proveedor.resolver";
import ProductoGraphQL from "js/graphql/resolvers/producto.resolver";
import { get } from "lodash";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: { estado: "ACTIVO" },
      disabled: null,
      idProveedor:
        parseInt(this.props.match.params.idProveedor) ||
        this.props.match.params.idProducto,
      idProducto:
        parseInt(this.props.match.params.idProducto) ||
        this.props.match.params.idProducto,
      proveedor: "",
      idSucursal:
        parseInt(this.props.match.params.idSucursal) ||
        this.props.match.params.idSucursal,
      errors: null
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const {
      match: {
        params: { idSucursal, edit, idProducto }
      }
    } = this.props;

    await client
      .query({
        query: ProductoGraphQL.queries.getProductoById,
        fetchPolicy: "cache-first",
        variables: {
          productoLike: {
            idProducto: parseInt(idProducto) || idProducto
          }
        }
      })
      .then(res => {
        const data = get(res, ["data", "getProductos", "itemsPagina"], null);
        if (data)
          this.setState({
            proveedor: data[0].nombre
          });
      });

    if (idSucursal !== "new") {
      await client
        .query({
          query: AppQraphQL.queries.getSucursalProductoById,
          fetchPolicy: "network-only",
          variables: {
            idSucursal: parseInt(idSucursal) || idSucursal
          }
        })
        .then(res =>
          this.setState({
            initialValues: {
              ...res.data.getSucursalesProducto.itemsPagina[0],
              idPais:
                (res.data.getSucursalesProducto.itemsPagina[0].pais &&
                  parseInt(
                    res.data.getSucursalesProducto.itemsPagina[0].pais.idPais
                  )) ||
                (res.data.getSucursalesProducto.itemsPagina[0].pais &&
                  res.data.getSucursalesProducto.itemsPagina[0].pais.idPais),
              idZona:
                (res.data.getSucursalesProducto.itemsPagina[0].zona &&
                  parseInt(
                    res.data.getSucursalesProducto.itemsPagina[0].zona.idZona
                  )) ||
                (res.data.getSucursalesProducto.itemsPagina[0].zona &&
                  res.data.getSucursalesProducto.itemsPagina[0].zona.idZona),
              idLocalidad:
                (res.data.getSucursalesProducto.itemsPagina[0].localidad &&
                  parseInt(
                    res.data.getSucursalesProducto.itemsPagina[0].localidad
                      .idLocalidad
                  )) ||
                (res.data.getSucursalesProducto.itemsPagina[0].localidad &&
                  res.data.getSucursalesProducto.itemsPagina[0].localidad
                    .idLocalidad)
            },
            disabled: !(edit === "edit")
          })
        );
    }
  }

  async submit(values) {
    const {
      client,
      history,
      match: {
        params: { idProveedor, idSucursal, idProducto }
      }
    } = this.props;

    await client
      .mutate({
        mutation:
          idSucursal === "new"
            ? AppQraphQL.mutations.createSucursalProducto
            : AppQraphQL.mutations.updateSucursalProducto,
        variables: {
          sucursal:
            idSucursal === "new"
              ? {
                  idProveedor: parseInt(idProveedor, 10),
                  idProducto: parseInt(idProducto, 10),
                  nombre: values.nombre,
                  telefono: values.telefono,
                  direccion: values.direccion,
                  idPais: get(values, "idPais", null),
                  idZona: get(values, "idZona", null),
                  idLocalidad: get(values, "idLocalidad", null),
                  notas: values.notas,
                  orden: values.orden
                }
              : {
                  idProveedor: parseInt(idProveedor, 10),
                  idProducto: parseInt(idProducto, 10),
                  idSucursal: parseInt(idSucursal, 10),
                  estado: values.estado,
                  nombre: values.nombre,
                  telefono: values.telefono,
                  direccion: values.direccion,
                  idPais: get(values, "idPais", null),
                  idZona: get(values, "idZona", null),
                  idLocalidad: get(values, "idLocalidad", null),
                  notas: values.notas,
                  orden: values.orden
                }
        }
      })
      .then(res => {
        const message = "Sucursal grabada correctamente";
        this.props.openNotification(message);
        history.push(`/admin/producto/${idProducto}/${idProveedor}/sucursal`);
      })
      .catch(e => {
        this.setState({ errors: e });
        return false;
      });
  }
  render() {
    return (
      <Fragment>
        <Form
          idProveedor={this.state.idProveedor}
          idProducto={this.state.idProducto}
          proveedor={this.state.proveedor}
          idSucursal={this.state.idSucursal}
          errors={this.state.errors}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
        />
      </Fragment>
    );
  }
}

export default Edit;
