import React, { PureComponent } from "react";

import IdiomaQraphQL from "js/graphql/resolvers/idioma.resolver";
import TranslationService from "js/utils/TranslationService";
import Enhancer from "./container";

export class IdiomasList extends PureComponent {
  getIdioma = id => {
    const { client } = this.props;
    if (id !== TranslationService.getIdiomaId()) {
      client
        .query({
          query: IdiomaQraphQL.queries.getIdiomaById,
          fetchPolicy: "cache-first",
          errorPolicy: "all",
          variables: {
            id
          }
        })
        .then(async res => {
          await TranslationService.setIdioma(res.data.getIdiomaMerged);
          window.location.reload();
        });
    }
  };

  render() {
    const { programa, showBracket = false, classes } = this.props;
    return programa && programa.idiomas && programa.idiomas.length > 1 ? (
      <span className={classes.IdiomaList}>
        {showBracket && "[ "}
        {programa.idiomas.map((i, key) => (
          <span key={key}>
            <span
              className={
                TranslationService.getIdiomaId() !== i.idioma._id
                  ? classes.link
                  : classes.disabled
              }
              onClick={this.getIdioma.bind(this, i.idioma._id)}
              color="inherit"
            >
              {i.etiqueta}
            </span>
            &nbsp;
            {programa.length === key + 1 && "|"}
          </span>
        ))}
        {showBracket && "]"}
      </span>
    ) : (
      <span />
    );
  }
}

export default Enhancer(IdiomasList);
