import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { Link } from "react-router-dom";
import { getUrl } from "js/utils";
import { numberWithCommas } from "js/utils";
import { _t } from "js/utils/TranslationService";
import UserQraphQL from "js/graphql/resolvers/usuario.resolver";
import get from "lodash/get";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class HeaderBurger extends React.Component {
  componentDidMount = () => {
    const { client } = this.props;
    client
      .watchQuery({
        query: UserQraphQL.queries.getMiUsuario,
        errorPolicy: "all",
        fetchPolicy: "network-only"
      })
      .subscribe({
        next: ({ data, loading }) => {
          this.setState({
            nombre: get(data, "miUsuario.perfil.nombre"),
            username: get(data, "miUsuario.username"),
            avatarUrl: get(data, "miUsuario.avatarUrl", ""),
            idUsuario: get(data, "miUsuario.idUsuario", ""),
            balance: get(data, "miUsuario.cuentaCorriente.balance", 0)
          });
          const roles = get(data, "miUsuario.roles");
          AuthService.setUserRoles(roles.map(rol => rol.nombre));
          AuthService.setIdSegmento(get(data, "miUsuario.segmento.idSegmento"));
          AuthService.setNombreSegmento(get(data, "miUsuario.segmento.nombre"));
        }
      });
  };

  render() {
    const { programa, classes } = this.props;
    const { balance } = this.state ? this.state : {};
    const { template } = programa;

    const moduloPuntos = programa.modulos
      ? programa.modulos.find(
          modulo =>
            modulo.nombreGrupo === "Gestión Puntos" &&
            modulo.estado === "ACTIVO"
        )
      : true;

    const HasPerms = AuthService.hasPerms([Roles.MIS_DATOS_MOVIMIENTOS_R]);

    const logoMaxWidth = template.header.logoPrincipal.maxWidth
      ? template.header.logoPrincipal.maxWidth + "px"
      : "65vw";
    return (
      <AppBar position="static">
        <Toolbar
          className={classes.toolbarBurger}
          style={{ backgroundColor: template.header.css.backgroundColor }}
        >
          <Link to="/">
            <img
              src={`${getUrl(template.header.logoPrincipal.url)}`}
              alt="Logo cabecera"
              className={classes.logoBurger}
              style={{ maxWidth: logoMaxWidth }}
            />
          </Link>
          <div
            style={{
              position: "relative",
              right: "65px"
            }}
          >
            {moduloPuntos && HasPerms ? (
              <Link to="/mis-movimientos" underline={"none"}>
                <Typography>
                  {numberWithCommas(balance)}
                  &nbsp;
                  {_t("Puntos")}
                </Typography>
              </Link>
            ) : (
              ""
            )}
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

HeaderBurger.propTypes = {};

HeaderBurger.defaultProps = {};

export default HeaderBurger;
