import gql from "graphql-tag";

export const getDefinicionesPerfilById = gql`
  query getDefinicionesPerfil($idPrograma: Int) {
    getDefinicionesPerfil(definicionPerfilLike: { idPrograma: $idPrograma }) {
      key
      label
      visibleRegistro
      requeridoRegistro
      visiblePerfil
      requeridoPerfil
      tipo
      value
      grupo
    }
  }
`;

export const createDefinicionPerfil = gql`
  mutation createDefinicionPerfil($definicionPerfil: DefinicionPerfilCreate!) {
    createDefinicionPerfil(definicionPerfil: $definicionPerfil) {
      idPrograma
      __typename
    }
  }
`;

export const updateDefinicionPerfil = gql`
  mutation updateDefinicionPerfil($definicionPerfil: DefinicionPerfilCreate!) {
    updateDefinicionPerfil(definicionPerfil: $definicionPerfil) {
      idPrograma
    }
  }
`;

export const updateMiPassword = gql`
  mutation updateMiPassword($usuario: MiUsuarioPasswordUpdate!) {
    updateMiPassword(usuario: $usuario) {
      idUsuario
    }
  }
`;

export const bulkDefinicionPerfil = gql`
  mutation bulkDefinicionPerfil(
    $idPrograma: Int!
    $definicionesPerfiles: [DefinicionPerfilCreateBulk]!
  ) {
    bulkDefinicionPerfil(
      idPrograma: $idPrograma
      definicionesPerfiles: $definicionesPerfiles
    ) {
      idPrograma
    }
  }
`;

export const updatePerfil = gql`
  mutation updatePerfil($perfil: PerfilUpdate!) {
    updatePerfil(perfil: $perfil) {
      otros
    }
  }
`;

export const updateMiPerfil = gql`
  mutation updateMiPerfil($perfil: MiPerfilUpdate!) {
    updateMiPerfil(perfil: $perfil) {
      otros
    }
  }
`;

export const getGerencias = gql`
  query getGerencias($idPrograma: Int!) {
    getGerencias(idPrograma: $idPrograma)
  }
`;

export default {
  queries: {
    getDefinicionesPerfilById,
    createDefinicionPerfil,
    updateDefinicionPerfil,
    bulkDefinicionPerfil,
    getGerencias
  },
  mutations: {
    updatePerfil,
    updateMiPerfil,
    updateMiPassword
  }
};
