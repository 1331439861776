import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import ProgramaGraphQL from "js/graphql/resolvers/programa.resolver.js";
import { ESTADO_CANJE } from "js/constants";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";
import {
  TypeAhead,
  UsuarioField,
  MultipleSelect
} from "js/components/Shared/Form/Fields";
import { Grid } from "@material-ui/core";
import {
  updateQueryParamsAsync,
  initialState
} from "js/pages/Admin/Canje/utils";

class advancedFilter extends Component {
  state = {
    ...initialState
  };

  updateQueryParamsMultiple = key => event =>
    this.setState(
      updateQueryParamsAsync({ key, value: event.target.value }),
      this.props.submitValue(event)
    );

  updateQueryParamsSwitch = key => event =>
    this.setState(
      updateQueryParamsAsync({ key, value: event.target.checked }),
      this.props.submitValue({
        target: { name: key, value: event.target.checked }
      })
    );

  updateAutocompleteQueryParams = key => select => {
    // debugger;
    if (key === "idUsuario") {
      const usuario = select.length ? select[0] : { value: null };
      this.setState(
        updateQueryParamsAsync({ key, value: usuario.value }),
        this.props.submitValue({ target: { name: key, value: usuario.value } })
      );
      return;
    }
    if (key === "idsProgramas") {
      const value = select.map(option => option.value);
      this.setState((state, props) => ({
        ...initialState,
        withProgram: true
      }));
      this.setState(
        updateQueryParamsAsync({ key, value }),
        this.props.submitValue({ target: { name: key, value } })
      );
      return;
    }
    this.setState(
      updateQueryParamsAsync({ key, value: select.value }),
      this.props.submitValue({ target: { name: key, value: select.value } })
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;
    const prevValue = prevProps.value;
    if (!value.idUsuario && prevValue && prevValue.idUsuario)
      this.updateAutocompleteQueryParams("idUsuario")({ value: undefined });
  }

  componentDidMount = () => {
    const {
      match: {
        params: { id: idPrograma }
      }
    } = this.props;

    if (idPrograma) {
      this.setState({
        idsProgramas: [parseInt(idPrograma)],
        withProgram: true
      });
    }
  };

  render() {
    const {
      value,
      submitValue,
      classes,
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <Query
        query={ProgramaGraphQL.queries.getPaginaCanjesFront}
        variables={{ idPrograma: parseInt(localStorage.getItem("idPrograma")) }}
        fetchPolicy="network-only"
        errorPolicy="all"
      >
        {({ loading, error, data }) => {
          return (
            <Grid container spacing={16}>
              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <DatePicker
                    key={"fechaDesde"}
                    name={"fechaDesde"}
                    label="Desde (fecha del movimiento)"
                    input={{
                      onChange: date =>
                        submitValue({
                          target: {
                            name: "fechaDesde",
                            value: date.toISOString()
                          }
                        }),
                      value: value["fechaDesde"]
                        ? moment(value["fechaDesde"])
                        : null
                    }}
                    format={"DD/MM/YYYY"}
                    maxDate={moment()}
                    autoOk={true}
                    startOfDate
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <DatePicker
                    key={"fechaHasta"}
                    name={"fechaHasta"}
                    input={{
                      onChange: date =>
                        submitValue({
                          target: {
                            name: "fechaHasta",
                            value: date.toISOString()
                          }
                        }),
                      value: value["fechaHasta"]
                        ? moment(value["fechaHasta"])
                        : null
                    }}
                    label="Hasta (fecha del movimiento)"
                    dateFormat={"DD/MM/YYYY"}
                    maxDate={moment()}
                    autoOk={true}
                    endOfDate
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <UsuarioField
                    async
                    disabled={!value.idsProgramas && !id && !value.idPrograma}
                    idPrograma={
                      value.idsProgramas
                        ? value.idsProgramas[0]
                        : value.idPrograma
                    }
                    label={"Usuario"}
                    withTodos
                    noOptionsMessage="No hay usuarios"
                    placeholder={"Buscar por usuario"}
                    onChange={this.updateAutocompleteQueryParams("idUsuario")}
                    value={value["idUsuario"] || value.idUsuario}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="ID Canje"
                    placeholder="ID Canje"
                    key={"idCanje"}
                    name={"idCanje"}
                    type="search"
                    value={value["idCanje"] || ""}
                    className={classes.formControl}
                    onChange={e => submitValue(e)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <MultipleSelect
                    label={"Estado"}
                    key={"estadoCanje"}
                    name={"estadoCanje"}
                    values={value["estadoCanje"] || []}
                    onChange={this.updateQueryParamsMultiple("estadoCanje")}
                    classes={{ formControl: classes.multiSelect }}
                    inputProps={{
                      name: "estadoCanje",
                      id: "estadoCanje"
                    }}
                    options={ESTADO_CANJE}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <TypeAhead
                    label="Producto"
                    noOptionsMessage="No hay productos"
                    placeholder="Buscar por producto"
                    onChange={this.updateAutocompleteQueryParams("idProducto")}
                    path={{
                      path: ["getEleccionProductos", "itemsPagina"],
                      value: "producto.idProducto",
                      label: "producto.nombre"
                    }}
                    suggestions={data}
                    value={value.idProducto}
                  />
                </FormControl>
              </Grid>
            </Grid>
          );
        }}
      </Query>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withRouter(withStyles(styles)(advancedFilter));
