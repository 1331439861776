import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { renderFecha } from "js/utils/Helper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const styles = theme => ({
  list: {
    paddingLeft: theme.spacing.unit
  }
});

class InfoCanjeSinForm extends Component {
  state = { open: false };

  handleClick = () => this.setState(state => ({ open: !state.open }));

  render() {
    const { classes, canje } = this.props;
    const certificado = canje.certificadoDesde
      ? renderFecha(canje.certificadoDesde) +
        " - " +
        (canje.certificadoHasta ? renderFecha(canje.certificadoHasta) : "")
      : null;

    return (
      <List style={{ width: "100%" }}>
        <Divider />
        {certificado && (
          <ListItem className={classes.list}>
            <ListItemText
              className={classes.list}
              primary="Certificado"
              secondary={certificado}
            />
          </ListItem>
        )}
        {!!canje.codigosActivacion.length && (
          <ListItem button onClick={this.handleClick} className={classes.list}>
            <ListItemText
              primary={`Códigos (${canje.codigosActivacion.length})`}
              className={classes.list}
            />
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        )}
        <Collapse in={this.state.open}>
          {canje.codigosActivacion.map(codigo => (
            <ListItem key={codigo} className={classes.list}>
              <ListItemText
                className={classes.list}
                secondary={"Código: " + codigo.codigo}
              />
            </ListItem>
          ))}
        </Collapse>
      </List>
    );
  }
}

export default withStyles(styles)(InfoCanjeSinForm);
