import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Store, Package } from "mdi-material-ui";
import { withStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import capitalize from "lodash/capitalize";

const styles = theme => ({
  emptyIcon: {
    paddingLeft: 12,
    paddingRight: 12
  },
  nested: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0
  },
  list: {
    paddingLeft: theme.spacing.unit
  }
});

class InfoProducto extends Component {
  getProveedorLines = () => {
    const { canje, classes } = this.props;
    const EmptyIcon = <div className={classes.emptyIcon} />;
    const proveedor = get(canje, "proveedor");
    let lines = [];
    if (get(proveedor, "pais.descripcion"))
      lines.push({ icon: EmptyIcon, text: get(proveedor, "pais.descripcion") });
    if (get(proveedor, "pais.zonas.descripcion"))
      lines.push({
        icon: EmptyIcon,
        text: get(proveedor, "pais.zonas.descripcion")
      });
    if (get(proveedor, "pais.zonas.localidades.descripcion"))
      lines.push({
        icon: EmptyIcon,
        text: get(proveedor, "pais.zonas.localidades.descripcion")
      });
    return lines;
  };

  render() {
    const { classes, canje, inBackOffice } = this.props;
    const proveedor = get(canje, "proveedor");
    return (
      <Grid container item xs={12}>
        {inBackOffice && (
          <Typography variant="h6" gutterBottom style={{ width: "100%" }}>
            Info Producto
          </Typography>
        )}
        <ListItem className={classes.list}>
          <ListItemIcon>
            <Package color="primary" />
          </ListItemIcon>
          {inBackOffice ? (
            <ListItemText
              className={classes.list}
              primary={
                canje.cantidad + " x " + canje.eleccionProducto.producto.nombre
              }
              secondary={capitalize(
                canje.eleccionProducto.producto.tipoProducto
              )}
            />
          ) : (
            <ListItemText
              className={classes.list}
              primary={
                canje.cantidad + " x " + canje.eleccionProducto.producto.nombre
              }
            />
          )}
        </ListItem>
        {inBackOffice && (
          <>
            <ListItem className={classes.list}>
              <ListItemIcon>
                <Store color="primary" />
              </ListItemIcon>
              <ListItemText
                className={classes.list}
                primary={proveedor.nombreFantasia}
              />
            </ListItem>
            {this.getProveedorLines().map(i => (
              <ListItem className={classes.nested}>
                <ListItemIcon>{i.icon}</ListItemIcon>
                <ListItemText primary={i.text} />
              </ListItem>
            ))}
          </>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(InfoProducto);
