import { withStyles } from "@material-ui/core/styles";
import { compose, pure } from "recompose";
import { graphql } from "react-apollo";

import UserQraphQL from "js/graphql/resolvers/usuario.resolver";
import withPrograma from "js/hoc/withPrograma";
import styles from "./styles";

export default compose(
  pure,
  withPrograma,
  withStyles(styles),
  graphql(UserQraphQL.queries.getMiUsuarioCompleto, {
    props: ({ data: { refetch, loading, ...data } }) => {
      if (loading) return;
      const {
        misPuntosPorVencer: { cantidad: misPuntosPorVencer, fecha },
        miUsuario: {
          cuentaCorriente: { balance },
          idUsuario,
          programa: { diasParaVencimientoPuntos },
          perfil: { otros = "{}" }
        }
      } = data;
      return {
        puntos: [
          {
            label: "Saldo de Puntos",
            puntos: balance
          },
          {
            label: "Puntos por vencer",
            puntos: misPuntosPorVencer,
            fecha,
            hidden: !diasParaVencimientoPuntos
          }
        ],
        boxes: diasParaVencimientoPuntos ? 2 : 1,
        onRefetchProfile: refetch,
        initialValues: {
          idUsuario,
          ...JSON.parse(otros)
        }
      };
    }
  })
);
