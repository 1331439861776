import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaCatalogo/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/catalogo.resolver";
import { numberWithCommas } from "js/utils";
import {
  viewEleccionProductoAction,
  editEleccionProductoAction
} from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";

const defaultHeader = id => {
  return {
    title: "Catálogo",
    new: {
      title: "Nuevo",
      perms: [Roles.PRODUCTO_CATALOGO_W]
    },
    export: {
      path: "eleccionProductos",
      perms: [Roles.PRODUCTO_CATALOGO_R],
      tooltip: "Exportar productos"
    },
    others: [
      {
        action: AppQraphQLImport.queries.importarCatalogoSegmento,
        perms: [Roles.PRODUCTO_W],
        model: "Modelo_Catálogo_Segmentos.csv",
        tooltip: "Importar segmentos",
        type: "import",
        variables: {
          idPrograma: parseInt(id, 10)
        },
        id: "upload-segmento"
      },
      {
        path: "catalogo/segmento",
        perms: [Roles.PRODUCTO_R],
        type: "export",
        tooltip: "Exportar segmentos"
      },
      {
        action: AppQraphQLImport.queries.importarCatalogoUbicacion,
        perms: [Roles.PRODUCTO_W],
        model: "Modelo_Catálogo_Ubicaciones.csv",
        tooltip: "Importar ubicaciones",
        type: "import",
        variables: {
          idPrograma: parseInt(id, 10)
        },
        id: "upload-ubicacion"
      },
      {
        path: "catalogo/ubicaciones",
        perms: [Roles.PRODUCTO_R],
        type: "export",
        tooltip: "Exportar ubicaciones"
      },
      {
        action: AppQraphQLImport.queries.importarCatalogoCategoria,
        perms: [Roles.PRODUCTO_W],
        model: "Modelo_Catálogo_Categorías.csv",
        tooltip: "Importar categorías",
        type: "import",
        variables: {
          idPrograma: parseInt(id, 10)
        },
        id: "upload-categoria"
      },
      {
        path: "catalogo/categorias",
        perms: [Roles.PRODUCTO_R],
        type: "export",
        tooltip: "Exportar categorias"
      }
    ]
  };
};
const columnData = [
  { id: "producto_idProducto", label: "ID", isNumeric: true },
  { id: "producto_nombre", label: "Producto" },
  // {
  //   id: "producto_proveedor_pais_descripcion",
  //   label: "País",
  //   removeSort: true
  // },
  {
    id: "producto_proveedor_nombreFantasia",
    label: "Proveedor",
    removeSort: true
  },
  {
    id: "segmentos",
    label: "Segmento",
    type: "array",
    field: "nombre",
    removeSort: true
  },
  {
    id: "puntos",
    isNumeric: true,
    label: "Puntos",
    type: "custom",
    parser: puntos => (puntos ? numberWithCommas(puntos) : "--")
  },
  {
    id: "stockPorPrograma",
    isNumeric: true,
    label: "Stock programa",
    type: "custom",
    parser: (stockPorPrograma, producto) => {
      if (producto.stockInfinito && producto.producto.stockInfinito) {
        return <span style={{ fontSize: "1.75rem" }}>∞</span>;
      } else {
        return numberWithCommas(stockPorPrograma);
      }
    }
  },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "asc", orderBy: "producto_nombre" };

class Tabla extends PureComponent {
  render() {
    const view = params => ({
      ...viewEleccionProductoAction({ ...params, history: this.props.history })
    });
    const edit = params => ({
      ...editEleccionProductoAction({
        ...params,
        history: this.props.history,
        perms: [Roles.PRODUCTO_CATALOGO_W]
      })
    });
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <Table
        storage={"FiltroCatalogoAdmin"}
        query={AppQraphQL.queries.getPaginaCatalogo}
        defaultHeader={defaultHeader(id)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getEleccionProductos}
        actionsCustom={[view, edit]}
      />
    );
  }
}

export default Tabla;
