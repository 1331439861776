export default theme => ({
  root: {
    paddingBottom: "3rem",
    paddingLeft: "3rem",
    paddingRight: "3rem"
  },
  containerInterno: {
    marginBottom: "15px"
  },
  card: {
    maxWidth: 345
  },
  media: {
    height: 140,
    backgroundSize: "contain"
  },
  link: {
    textDecoration: "none"
  },
  iconDesplegable: {
    height: "80px",
    width: "80px",
    marginBottom: "20px",
    marginTop: "-30px"
  },
  textoTitulo: {
    color: "#979797",
    textAlign: "center",
    padding: "20px",
    fontSize: "xx-large"
  },
  fondoTitulo: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px"
  },
  containerTitulo: {
    borderBottom: "1px solid #979797"
  }
});
