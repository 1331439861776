import { compose } from "recompose";
import { withNotificationContext } from "js/notification-context";
import DialogCrearFacturaRaw from "./DialogCrearFactura";
import DialogFacturaRaw from "./DialogFactura";
import DialogLoteRaw from "./DialogLote";

const enhance = compose(withNotificationContext);

export const DialogCrearFactura = enhance(DialogCrearFacturaRaw);
export const DialogFactura = enhance(DialogFacturaRaw);
export const DialogLote = enhance(DialogLoteRaw);

export default {
  DialogCrearFactura,
  DialogFactura,
  DialogLote
};
