const getRedSocialColor = theme => theme.programa.template.footer.colorRedes;

export default theme => ({
  root: {
    backgroundColor: theme.palette.quinary.main,
    maxWidth: "1200px",
    width: "100%",
    margin: "0 auto"
  },
  columns: {
    flexGrow: 1,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row"
    },
    justifyContent: "space-between",
    minWidth: "100%",
    color: theme.palette.getContrastText(theme.palette.quinary.main),
    marginTop: theme.spacing.unit * 3,
    padding: "0 15px"
  },
  rrss: {
    display: "flex",
    alignItems: "center",
    margin: "15px 5px"
  },
  contacto: {
    color: theme.palette.quaternary.main,
    marginTop: theme.spacing.unit * 3
  },
  iconBack: {
    backgroundColor: getRedSocialColor(theme),
    padding: 5,
    margin: 5,
    borderRadius: "50%",
    width: 38,
    height: 38,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  colorPrimary: { color: theme.palette.quinary.main }
});
