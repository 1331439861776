import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Tooltip, Grid, Button, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import AppQraphQL from "js/graphql/resolvers/proveedor.resolver";
import { renderFecha } from "js/utils/Helper";
import { TextField } from "redux-form-material-ui";
import get from "lodash/get";
import { maxLength } from "js/helpers/validations";
import {
  CheckboxField,
  InputTextarea,
  InputTextField,
  InputNumberField,
  RadioField,
  RubroField,
  SubRubroField,
  ProveedorField,
  SucursalesField,
  ListField,
  CodigosField,
  DatosRequeridos,
  InputMontoField,
  SwitchField
} from "js/components/Shared/Form/Fields";
import { styles } from "js/pages/Admin/Producto/Edit/styles.js";
import ImageUploader, { ItemImages } from "js/components/Shared/ImageUploader";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { _t } from "js/utils/TranslationService";
import { compose } from "recompose";
import { change } from "redux-form";
import InsertLinkIcon from "@material-ui/icons/InsertLink";

const maxLength255 = maxLength(255);
const maxLength510 = maxLength(510);
class Form extends PureComponent {
  state = { hasSucursales: false };

  onChangeProveedor = hasSucursales => this.setState({ hasSucursales });

  addHipervinculo = field => () => {
    this.props.changeFieldValue(
      field,
      `${
        this.props[field]
      } <a target="_blank" href="https://url.com">Texto del link</a>`
    );
  };

  render() {
    const {
      handleSubmit,
      submitting,
      classes,
      history,
      disabled,
      idRubro,
      idProveedor,
      tipoProducto,
      stockInfinito,
      id,
      invalid,
      onChangeImage,
      onRemoveImage,
      productImages,
      initialValues,
      changeFieldValue
    } = this.props;

    return (
      <Fragment>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {disabled ? "Ver " : id === "new" ? "Nuevo " : "Modificar "}
              Producto
            </Typography>
          </Grid>
        </Grid>
        <form className={classes.container} onSubmit={handleSubmit}>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Info básica
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <InputTextField
                name="nombre"
                label="Nombre"
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <ListField
                name="estado"
                label="Estado"
                options={[
                  { label: "Activo", value: "ACTIVO" },
                  { label: "Inactivo", value: "INACTIVO" }
                ]}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid
              style={{
                marginTop: "-40px"
              }}
              item
              xs={12}
            >
              <Button
                className={classes.iconHipervinculo}
                onClick={this.addHipervinculo("copete")}
              >
                <InsertLinkIcon />
              </Button>
              <InputTextField
                name="copete"
                label="Descripción corta (máx. 255 caracteres)"
                disabled={disabled}
                required
                customValidations={[maxLength255]}
              />
            </Grid>
            <Grid
              style={{
                marginTop: "-40px"
              }}
              item
              xs={12}
            >
              <Button
                className={classes.iconHipervinculo}
                onClick={this.addHipervinculo("descripcion")}
              >
                <InsertLinkIcon />
              </Button>
              <InputTextarea
                name="descripcion"
                label="Descripción larga (máx. 510 caracteres)"
                disabled={disabled}
                multiple
                required
                customValidations={[maxLength510]}
              />
            </Grid>
          </Grid>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Detalles logísticos
              </Typography>
            </Grid>
            <Grid item container spacing={16}>
              <Grid item xs={12} md={6}>
                <RadioField
                  name="gestionadoPor"
                  label="Gestión del producto"
                  required
                  options={[
                    { label: "Ashiwea", value: "ASHIWEA" },
                    { label: "Programa", value: "CLIENTE" }
                  ]}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid item xs={12} md={6}>
                  <Typography
                    style={{ lineHeight: "1.2em" }}
                    variant="subtitle1"
                  >
                    Tiene stock infinito?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CheckboxField
                    name="stockInfinito"
                    label="Stock infinito"
                    disabled={disabled || tipoProducto === "INSTANTANEO"}
                  />
                </Grid>
                <Grid container item xs={12} spacing={16}>
                  <Grid item xs={12} md={6}>
                    <InputNumberField
                      name="stock"
                      label="Stock total"
                      disabled={
                        disabled ||
                        stockInfinito ||
                        tipoProducto === "INSTANTANEO"
                      }
                      required={!stockInfinito}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputNumberField
                      name="stockSeguridad"
                      label="Stock de seguridad"
                      disabled={
                        disabled ||
                        (stockInfinito && tipoProducto !== "INSTANTANEO")
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ProveedorField
                disabled={disabled}
                required
                idProveedor={idProveedor}
                onChangeProveedor={this.onChangeProveedor}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {this.state.hasSucursales && (
                <SucursalesField
                  idProveedor={idProveedor}
                  name={"sucursalesExcluidas"}
                  disabled={disabled}
                />
              )}
            </Grid>
          </Grid>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Detalles del producto
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RubroField required disabled={disabled} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SubRubroField required disabled={disabled} idRubro={idRubro} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMontoField
                name="costo"
                label="Costo"
                isFloat
                disabled={disabled}
                required
                query={AppQraphQL.queries.getMonedaByProveedorId}
                queryParams={{ idProveedor }}
                path={"data.getProveedores.itemsPagina[0].pais.moneda"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMontoField
                name="precioSugerido"
                label="Precio Sugerido"
                disabled={disabled}
                isFloat
                required
                query={AppQraphQL.queries.getMonedaByProveedorId}
                queryParams={{ idProveedor }}
                path={"data.getProveedores.itemsPagina[0].pais.moneda"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputTextField
                name="codigoProducto"
                label="Código producto"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <ImageUploader
                onChangeImage={onChangeImage}
                disableClick={true}
                disabled={disabled}
                medidasRecomendadas={"556 x 314"}
              >
                <ItemImages
                  images={productImages}
                  onRemoveImage={onRemoveImage}
                  disabled={disabled}
                  alt={"imagen-producto"}
                />
              </ImageUploader>
            </Grid>
          </Grid>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Canje y retiro
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RadioField
                name="tipoProducto"
                label="Tipo de producto"
                options={[
                  { label: "Orden de compra", value: "ORDEN_COMPRA" },
                  { label: "Instantáneo", value: "INSTANTANEO" },
                  { label: "Certificado", value: "CERTIFICADO" },
                  { label: "Físico", value: "FISICO" }
                ]}
                disabled={disabled}
              />
            </Grid>
            {/* TODO: ver de donde salen estos campos */}
            <Grid item container xs={12} sm={6}>
              <DatosRequeridos
                name={"idDatosRequeridos"}
                withSucursales={this.state.hasSucursales}
                disabled={disabled}
              />
            </Grid>
            {tipoProducto === "INSTANTANEO" && (
              <Grid item container xs={12} sm={6} spacing={16}>
                <Grid item xs={12}>
                  <Tooltip
                    title="Por favor guarde primero el producto antes de importar códigos"
                    placement="top-start"
                  >
                    <Typography variant="subtitle1">
                      <InfoIcon /> Instantáneo
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <CodigosField
                    disabled={disabled}
                    idProducto={initialValues.idProducto}
                    onChangeCodigos={({ activos, total }) => {
                      changeFieldValue("stock", activos);
                      changeFieldValue("stockInfinito", false);
                    }}
                  />
                </Grid>
              </Grid>
            )}

            {(tipoProducto === "ORDEN_COMPRA" || tipoProducto === "FISICO") && (
              <Grid item container xs={12} sm={6} spacing={16}>
                <Grid item xs={12} sm={6}>
                  <InputNumberField
                    name="valorOrdenCompra"
                    label="Valor"
                    disabled={disabled}
                    isFloat
                    required={false}
                    helperText={_t(
                      `Al incluir un valor, al confirmar el canje se mostrará "valor total = valor x cantidad" y no se mostrará la cantidad`
                    )}
                  />
                </Grid>
              </Grid>
            )}

            {tipoProducto === "CERTIFICADO" && (
              <Grid item container xs={12} sm={6} spacing={16}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Certificado</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputNumberField
                    name="diasEspera"
                    label="Días de espera"
                    disabled={disabled}
                    required={tipoProducto === "CERTIFICADO"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputNumberField
                    name="diasValidez"
                    label="Días de validez"
                    disabled={disabled}
                    required={tipoProducto === "CERTIFICADO"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputTextarea
                    name="detalleCertificado"
                    label="Detalle de certificado"
                    disabled={disabled}
                    multiple
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Indicaciones
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <InputTextarea
                name="condicionCanje"
                label="Condiciones legales"
                disabled={disabled}
                multiple
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputTextarea
                name="indicacionesEntrega"
                label="Indicaciones de entrega"
                disabled={disabled}
                multiple
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputTextarea
                name="notasInternas"
                label="Notas internas"
                disabled={disabled}
                multiple
              />
            </Grid>
          </Grid>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Sucursales y página web
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SwitchField
                name="tieneSucursales"
                label="Tiene sucursales"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="paginaWeb"
                label="Página web"
                disabled={disabled}
                customValidations={[maxLength255]}
              />
            </Grid>
            <Grid item xs={4} />
          </Grid>
          {id !== "new" && (
            <Grid className={classes.grid} container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Datos de creación
                </Typography>
              </Grid>
              <Grid item container spacing={16}>
                <Grid item xs={12} md={3}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Usuario creador"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={get(initialValues, "usuarioCreador.username", "--")}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Fecha de creación"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={renderFecha(initialValues.fechaAlta) || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Usuario modificador"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={get(
                      initialValues,
                      "usuarioModificador.username",
                      "--"
                    )}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Fecha de modificación"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={renderFecha(initialValues.fechaModificacion) || ""}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid className={classes.grid} container>
            <Grid item xs />
            <Grid item xs />
            <Grid className={classes.gridButton} item xs>
              <Button
                type="button"
                variant="outlined"
                size="large"
                color="primary"
                className={classes.button}
                onClick={() => history.push("/admin/producto")}
              >
                Cancelar
              </Button>
            </Grid>
            {AuthService.hasPerms([Roles.PRODUCTO_W]) && (
              <Grid className={classes.gridButton} item xs>
                {disabled && (
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    onClick={() =>
                      history.push("/admin/producto/" + id + "/edit")
                    }
                  >
                    Editar
                  </Button>
                )}
                {!disabled && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    disabled={submitting || invalid}
                  >
                    Guardar
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      </Fragment>
    );
  }
}

Form = reduxForm({
  form: "formProducto",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formProducto");

const mapStateToProps = state => ({
  idRubro: selector(state, "idRubro"),
  idProveedor: selector(state, "idProveedor"),
  tipoProducto: selector(state, "tipoProducto"),
  stockInfinito: selector(state, "stockInfinito"),
  descripcion: selector(state, "descripcion"),
  copete: selector(state, "copete")
});
const mapDispatchToProps = dispatch => ({
  changeFieldValue: (field, value) =>
    dispatch(change("formProducto", field, value))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withStyles(styles)
)(Form);
