import newBase from "./newBase";
import newSlider from "./newSlider";
import newFila from "./newFila";

export const getTipo = new Map([
  ["base", newBase],
  ["slider", newSlider],
  ["fila", newFila]
]);

export default {
  newBase,
  newSlider,
  newFila
};
