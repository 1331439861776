import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  title: {
    fontWeight: "bold"
  },
  container: {
    padding: theme.spacing.unit * 4
  },
  button: {
    margin: "10px"
  },
  puntosContainer: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    backgroundColor: theme.palette.primary.main
  },
  groupsTypography: {
    color: theme.palette.quaternary.main,
    fontSize: "1.05rem"
  },
  groupContainer: {
    marginBottom: "2.5rem"
  },
  progress: {
    marginLeft: theme.spacing.unit
  },
  alertaPerfil: {
    backgroundColor: "#FF000015",
    padding: "20px"
  },
  alertaPerfilTexto: {
    color: "red"
  }
});

export default withStyles(styles);
