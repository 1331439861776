import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import BurgerLayout from "./BurgerLayout";
import withPrograma from "js/hoc/withPrograma";
import styles from "./styles";
import { withApollo } from "react-apollo";
import { withNotificationContext } from "js/notification-context";

const enhance = compose(
  withPrograma,
  withNotificationContext,
  withRouter,
  withStyles(styles, { withTheme: true }),
  withApollo
);

export default enhance(BurgerLayout);
