import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import { Typography } from "@material-ui/core";
import { styles } from "js/components/Shared/Form/styles.js";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogAprobarVariablesMed extends React.PureComponent {
  render() {
    const { open, handleClose, classes, onSubmit } = this.props;

    return (
      <Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={classes.dialogTitle}
          >
            Aprobar targets de preventistas
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Typography variant="h6">
              {`Podrá seguir modificando los targets hasta la fecha indicada.`}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Cerrar
            </Button>

            <Button
              onClick={() => onSubmit()}
              variant="contained"
              color="primary"
            >
              Aprobar
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DialogAprobarVariablesMed.defaultProps = {};

DialogAprobarVariablesMed.propTypes = {};

export default withStyles(styles)(DialogAprobarVariablesMed);
