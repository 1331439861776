export default theme => ({
  icon: {
    margin: theme.spacing.unit * 2
  },
  iconAddElement: {
    bottom: 5,
    right: -25,
    margin: 16,
    position: "relative"
  },
  iconRemoveElement: {
    margin: theme.spacing.unit * 2,
    position: "relative",
    right: -25,
    top: 5
  },
  dividerRoot: {
    margin: "20px 0"
  },
  button: {
    margin: theme.spacing.unit
  }
});
