import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { reduxForm, formValueSelector, change, getFormMeta } from "redux-form";
import { Grid, Button, Typography, FormControl } from "@material-ui/core";

import { InputTextField, ListField } from "js/components/Shared/Form/Fields";
import { styles } from "js/components/Shared/Form/styles.js";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programaTercero: this.props.initialValues.programaTercero,
      snackOpen: false
    };
  }

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      id,
      initialValues
    } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {initialValues._id
              ? "Editar Programa Tercero"
              : "Nuevo Programa Tercero"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form className={classes.container} onSubmit={handleSubmit}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors && errors.map(e => <li>{e.message}</li>)}
                </ul>
              </Grid>
            </Grid>

            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.field}>
                  <InputTextField name="nombre" label="Nombre" required />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl className={classes.field}>
                  <ListField
                    name="estado"
                    label="Estado"
                    options={[
                      { label: "Activo", value: "ACTIVO" },
                      { label: "Inactivo", value: "INACTIVO" }
                    ]}
                    disabled={disabled}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid className={classes.gridButton} item xs={12}>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  className={classes.button}
                  style={{ marginRight: 20 }}
                  onClick={() =>
                    history.push(
                      `/admin/programa/${id}/api-tokens/programas-terceros`
                    )
                  }
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  disabled={pristine || submitting}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

Form = reduxForm({
  form: "formCanalRetail",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formCanalRetail");

Form = connect(
  state => ({
    formMeta: getFormMeta("formCanalRetail")(state),
    idProgramaField: selector(state, "idPrograma")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formCanalRetail", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
