import Gestion from "./Gestion";
import { compose, withState, withHandlers } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import styles from "../styles.js";
import CLNGraphQL from "js/graphql/resolvers/clubLaNacion.resolver.js";
import { withRouter } from "react-router-dom";
import { withNotificationContext } from "js/notification-context";

const success = "El archivo se procesó correctamente";
const errorMessage =
  'Hubo un error al procesar el archivo. Ver los detalles en "Logs"';
const successDelete = "Reportes borrados correctamente";
const errorDelete = "Hubo un error al borrar los reportes";

const enhance = compose(
  withRouter,
  withNotificationContext,
  withStyles(styles),
  withState("deleteForm", "setDeleteForm", {}),
  graphql(CLNGraphQL.mutations.uploadReporteComerciosCLN, {
    name: "uploadReporteComercios"
  }),
  graphql(CLNGraphQL.mutations.uploadReporteSucursalesCLN, {
    name: "uploadReporteSucursales"
  }),
  graphql(CLNGraphQL.mutations.deleteReportesCLN, {
    name: "deleteReportesCLN"
  }),
  graphql(CLNGraphQL.queries.getComerciosCLN, {
    options: () => ({
      fetchPolicy: "network-only"
    }),
    props: ({ data: { loading, getComerciosCLN } }) => {
      if (loading) return;
      return {
        comercios: getComerciosCLN
      };
    }
  }),
  withHandlers({
    handleChange: ({ deleteForm, setDeleteForm }) => key => value => {
      setDeleteForm({
        ...deleteForm,
        [key]: value
      });
    },
    onDelete: ({
      deleteForm,
      setDeleteForm,
      deleteReportesCLN,
      openNotification
    }) => () => {
      deleteReportesCLN({ variables: deleteForm })
        .then(() => openNotification(successDelete))
        .catch(() => openNotification(errorDelete));
      setDeleteForm({});
    },
    uploadReporte: props => ({
      target: {
        id,
        validity,
        files: [file]
      }
    }) => {
      if (!validity.valid) return;
      const mutation = props[id];
      mutation({ variables: { file } })
        .then(() => props.openNotification(success))
        .catch(() => props.openNotification(errorMessage))
        .finally(() => (document.getElementById(id).value = ""));
    }
  })
);
export default enhance(Gestion);
