import { compose, pure, withProps, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import FacturasGraphQL from "js/graphql/resolvers/facturas.resolver";
import client from "js/App/client.graphql.js";
import withPrograma from "js/hoc/withPrograma";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { withNotificationContext } from "js/notification-context";

export default compose(
  pure,
  withPrograma,
  withNotificationContext,
  withStyles(styles),
  withRouter,
  withProps(ownerProps => {
    return {
      idFactura:
        parseInt(ownerProps.match.params.id) || ownerProps.match.params.id
    };
  }),
  withHandlers({
    sendValues: ownerProps => async values => {
      await client
        .mutate({
          mutation: FacturasGraphQL.mutations.updateFacturaDetail,
          errorPolicy: "all",
          variables: {
            notasInternas: values.notasInternas,
            canjes: values.canjesUpdated.map(({ costoReal, idCanje }) => ({
              costoReal,
              idCanje
            })),
            idFactura: values.idFactura
          }
        })
        .then(() => {
          ownerProps.openNotification(
            "Se actualizaron los datos de la factura"
          );
          ownerProps.history.push("/admin/facturas");
        });
    }
  }),
  graphql(FacturasGraphQL.queries.getFactura, {
    alias: "AdminFacturasDetailHOC",
    options: ({ idFactura }) => ({
      fetchPolicy: "network-only",
      variables: {
        idFactura
      }
    }),
    props: ({ data: { loading, getFacturas, getPrograma } }) => {
      if (loading) return { loading: true };
      return {
        factura: getFacturas.itemsPagina[0],
        programa: getPrograma,
        loading: false
      };
    }
  })
);
