import React from "react";
import PropTypes from "prop-types";

const Iframe = props => {
  const { classes, contenido } = props;
  return (
    <div className={classes.base}>
      <iframe
        title={contenido.link}
        src={`${contenido.link}`}
        height="100%"
        width="100%"
      />
    </div>
  );
};

Iframe.propTypes = {
  classes: PropTypes.object.isRequired
};

Iframe.displayName = "iframe";

export default Iframe;
