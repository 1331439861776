import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { withStyles } from "@material-ui/core/styles";
import client from "js/App/client.graphql.js";
import LotesGraphQL from "js/graphql/resolvers/lotes.resolver";
import { exportURL } from "js/constants";
import { numberWithCommas } from "js/utils";

const styles = theme => ({
  buttonDownload: {
    textDecoration: "none"
  },
  fila: {
    display: "flex",
    flexDirection: "row"
  },
  filaDetail: {
    width: "100%",
    padding: theme.spacing.unit,
    textAlign: "center",
    color: theme.palette.text.secondary,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20
  },
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  filaGroupDatos: {
    display: '"flex"',
    flexDirection: '"row"',
    justifyContent: '"flex-start"',
    padding: 8,
    alignItems: "center"
  },
  filaGroupButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  filaGroupItem: {
    display: "flex",
    flexDirection: "row"
  },
  proveedor: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "12px 0"
  },
  loteInfoFilaItem: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderRight: "1px solid black",
    padding: "0 12px",
    "& h6": {
      alignSelf: "center",
      paddingLeft: 15
    },
    "& h5": {
      alignSelf: "center",
      paddingLeft: 15
    },
    "&:last-child": {
      border: "none"
    },
    "&:first-child h6": {
      paddingLeft: 0
    }
  }
});

// Si el formato es automatico el endpoint es enviarsublote creo
// Y si es manual marcarEnviado
const Sublote = ({ classes, sublote, numeroLote, onRefetchLote }) => {
  const variables = {
    numeroLote,
    idProveedor: sublote.proveedor.idProveedor
  };

  const downloadURL = `${exportURL}/sublote?numeroLote=${numeroLote}&idProveedor=${
    variables.idProveedor
  }`;

  const marcarEnviado = async () =>
    await client
      .mutate({
        mutation: LotesGraphQL.mutations.marcarEnviado,
        variables
      })
      .then(() => onRefetchLote());

  const enviarSublote = async () =>
    await client
      .mutate({
        mutation: LotesGraphQL.mutations.enviarSublote,
        variables
      })
      .then(() => onRefetchLote());

  return (
    <Grid xs={12} container item className={classes.fila}>
      <Grid item className={classes.proveedor}>
        <Typography variant="subtitle1">
          {`${sublote.proveedor.nombreFantasia} -
          ${sublote.proveedor.pais.descripcion}`}{" "}
        </Typography>
        <Typography variant="subtitle2">
          {`Responsable: ${sublote.proveedor.nombreContacto || "--"}, ${sublote
            .proveedor.telefonoContacto || "--"}, ${sublote.proveedor
            .emailContacto || "--"}`}
        </Typography>
        <Typography variant="subtitle2">
          {`Envío de sublotes: ${sublote.proveedor.emailCanjes || "--"}`}
        </Typography>
      </Grid>

      <Paper className={classes.filaDetail}>
        <Grid xs={12} container item className={classes.fila}>
          <Grid container xs={6} item className={classes.filaGroupDatos}>
            <Grid item className={classes.loteInfoFilaItem}>
              <Typography variant="subtitle2">Productos</Typography>
              <Typography variant="h5">
                {numberWithCommas(sublote.cantidadProductos)}
              </Typography>
            </Grid>
            <Grid item className={classes.loteInfoFilaItem}>
              <Typography variant="subtitle2">Canjes</Typography>
              <Typography variant="h5">
                {numberWithCommas(sublote.cantidadCanjes)}
              </Typography>
            </Grid>
            <Grid item className={classes.loteInfoFilaItem}>
              <Typography variant="subtitle2">
                {sublote.proveedor.pais.moneda}
              </Typography>
              <Typography variant="h5">
                {numberWithCommas(sublote.importeTotal)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6} item className={classes.filaGroupButtons}>
            <Grid item className={classes.filaGroupItem}>
              <a classNmae={classes.buttonDownload} href={downloadURL} download>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.button}
                >
                  <CloudDownloadIcon className={classes.leftIcon} />
                  Descargar
                </Button>
              </a>
            </Grid>
            <Grid item className={classes.filaGroupItem}>
              {sublote.estado === "PENDIENTE" ? (
                <React.Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={marcarEnviado}
                  >
                    <Icon className={classes.leftIcon}>
                      check_circle_outline
                    </Icon>
                    Marcar enviado
                  </Button>
                  {sublote.proveedor.formatoEnvio !== "Manual" && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={enviarSublote}
                    >
                      <Icon className={classes.leftIcon}>send</Icon>
                      Enviar
                    </Button>
                  )}
                </React.Fragment>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled
                >
                  <Icon className={classes.leftIcon}>check_circle</Icon>
                  Enviado
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(Sublote);
