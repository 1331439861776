export default theme => ({
  card: {
    width: "100%",
    padding: "30px",
    paddingTop: "0px"
  },
  value: {
    textAlign: "center",
    fontSize: "40px",
    fontWeight: "700"
  },
  label: {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "400"
  }
});
