import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaPrograma/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import capitalize from "lodash/capitalize";
import {
  manageAction,
  registrarseComoAction
} from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { Redirect } from "js/components/Shared/Tabla/TableCells";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import MiProgramaTable from "./miPrograma";
import AuthService from "js/utils/AuthService";
import MiProgramaPerms from "js/pages/Admin/Programa/Gestion/perms";
const defaultHeader = {
  title: "Programas",
  new: {
    title: "Nuevo",
    perms: [Roles.PROGRAMA_W],
    path: "/admin/programa/new"
  }
};
const columnData = [
  { id: "nombre", label: "Nombre" },
  {
    id: "tipo",
    label: "Tipo",
    type: "custom",
    parser: tipo => (tipo ? capitalize(tipo) : "--")
  },
  { id: "nombreFantasia", label: "Empresa" },
  {
    id: "paises",
    label: "País",
    type: "array",
    field: "descripcion",
    removeSort: true
  },
  { id: "cuit", label: "CUIT" },
  { id: "fechaInicio", type: "date", label: "Activación" },
  {
    id: "url",
    label: "URL",
    type: "custom",
    parser: url => (url ? <Redirect path={url} value={url} /> : "--")
  },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones", removeSort: true }
];

const defaultSort = { order: "desc", orderBy: "fechaInicio" };

class Tabla extends PureComponent {
  render() {
    const manage = params => ({
      ...manageAction({
        ...params,
        history: this.props.history,
        perms: [],
        optional: MiProgramaPerms.concat(Roles.PROGRAMA_W)
      })
    });
    const registrarseComo = params => ({
      ...registrarseComoAction({
        ...params,
        history: this.props.history,
        perms: [Roles.LOGIN_AS]
      })
    });

    const TableComponent = AuthService.hasPerms([Roles.PROGRAMA_LISTA])
      ? Table
      : MiProgramaTable;
    return (
      <TableComponent
        storage="FiltroPrograma"
        query={AppQraphQL.queries.getPaginaProgramas}
        defaultHeader={defaultHeader}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getProgramas}
        actionsCustom={[registrarseComo, manage]}
      />
    );
  }
}

export default Tabla;
