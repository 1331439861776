import React from "react";
import { Field } from "redux-form";
import { _t } from "js/utils/TranslationService";
import renderDateTimePicker from "./DateTimePicker";

const DateTimePicker = ({
  name,
  label,
  format = "DD/MM/YYYY HH:mm",
  required,
  disabled,
  translation,
  classes,
  ...custom
}) => (
  <Field
    name={name}
    label={translation || _t(label)}
    dateFormat={format}
    component={renderDateTimePicker}
    required={required}
    fullWidth
    disabled={disabled}
    {...custom}
  />
);

export default DateTimePicker;
