import React from "react";
import DialogCustom from "js/components/Dialog";
import DialogCanje from "../DialogCanje";
import { _t } from "js/utils/TranslationService";

class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canjeando: false
    };
  }

  render() {
    const {
      openCanjePopup,
      productoActions,
      idEleccionProducto,
      cantidad,
      requeridos,
      datosRequeridos,
      history,
      canjeFail,
      classes,
      datosPerfil,
      mensajeRequeridos
    } = this.props;
    const self = this;
    console.log("SELF", self);
    return (
      <DialogCustom
        open={openCanjePopup}
        title=""
        classes={classes}
        content={<DialogCanje />}
        cancelAction={productoActions.cerrarCanjePopup}
        cancelText="Cancelar"
        okAction={
          this.state.canjeando
            ? () => console.log("Wait...")
            : productoActions.requestCanje(
                {
                  idEleccionProducto: idEleccionProducto,
                  cantidad,
                  datosDeCanje: requeridos,
                  datosRequeridos,
                  datosPerfil,
                  self
                },
                history
              )
        }
        okText={
          this.state.canjeando
            ? "Canjeando.."
            : canjeFail
              ? ""
              : "Confirmar canje"
        }
        errorMessage={
          mensajeRequeridos
            ? _t(mensajeRequeridos)
            : canjeFail
              ? _t(canjeFail)
              : null
        }
      />
    );
  }
}

export default Dialog;
