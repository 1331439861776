import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/puntoDeVenta.resolver";
import advancedFilter from "js/components/TablaPuntoDeVenta/advancedFilter.js";
import { withRouter } from "react-router-dom";
import { editAction, viewAction } from "js/components/Shared/Tabla/ActionCells";
import capitalize from "lodash/capitalize";
import { numberWithCommas } from "js/utils";
import { USER_ROLES as Roles } from "js/models/User";
import { Estado } from "js/components/Shared/Tabla/TableCells";

const defaultHeader = (id, btnsNextTitle) => {
  return {
    title: "Puntos de Venta",
    btnsNextTitle,
    new: {
      title: "Nuevo",
      perms: [Roles.PUNTO_VENTA_W]
    },
    import: {
      action: AppQraphQL.mutations.importPuntosDeVenta,
      perms: [Roles.PUNTO_VENTA_W],
      variables: { idPrograma: parseInt(id, 10) },
      model: "Modelo_Puntos_de_Venta.csv"
    },
    export: {
      path: "puntos-de-venta",
      perms: [Roles.PUNTO_VENTA_R]
    },
    queryStringMatcher: {
      idsProgramas: undefined
    }
  };
};

const simplifyHeader = {
  title: "Puntos de Venta",
  new: {
    title: "Nuevo",
    perms: [Roles.PUNTO_VENTA_W]
  },
  queryStringMatcher: {
    idsProgramas: undefined
  }
};

let columnData = [
  { id: "idPuntoDeVenta", label: "ID", isNumeric: true },
  { id: "nombre", label: "Nombre" },
  {
    id: "multiplicador",
    label: "Multiplicador",
    type: "custom",
    isNumeric: true,
    parser: multiplicador =>
      multiplicador ? numberWithCommas(multiplicador) : "--"
  },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "idPuntoDeVenta" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.PUNTO_VENTA_W]
      })
    });

    const view = params => ({
      ...viewAction({ ...params, history: this.props.history })
    });

    const btnsNextTitle = [];

    return (
      <Table
        storage={"FiltroPuntoDeVenta"}
        query={AppQraphQL.queries.getPuntosDeVenta}
        defaultHeader={id ? defaultHeader(id, btnsNextTitle) : simplifyHeader}
        advancedFilter={advancedFilter}
        actionsCustom={[view, edit]}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getPuntosDeVenta}
      />
    );
  }
}

export default withRouter(Tabla);
