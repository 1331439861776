import React from "react";
// import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const SingleValue = props => {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

export default SingleValue;
