import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { Facturas } = Fragmentos;

export const crearFactura = gql`
  mutation createFactura(
    $factura: String!
    $idProveedor: Int
    $idProveedorFactura: Int!
    $importe: Float!
    $fecha: Date!
    $excluirFactura: Boolean
    $excluirCanje: Boolean
    $administracion: Gestion
    $idProducto: Int
    $fechaDesde: Date
    $fechaHasta: Date
    $estadoCanje: [EstadoCanje]
    $idPrograma: [Int]
  ) {
    createFactura(
      factura: {
        numeroFactura: $factura
        idProveedor: $idProveedorFactura
        importe: $importe
        fecha: $fecha
        canjeRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
        canjeLike: {
          idProveedor: $idProveedor
          eleccionProducto_idProducto: $idProducto
          eleccionProducto_idPrograma: $idPrograma
          estado: $estadoCanje
          excluirFacturados: $excluirFactura
          excluirLoteados: $excluirCanje
          eleccionProducto_producto_gestionadoPor: $administracion
        }
      }
    ) {
      idFactura
      numeroFactura
    }
  }
`;

export const asignarFactura = gql`
  mutation asignarFactura(
    $idFactura: Int!
    $canjeLike: CanjeSearch
    $canjeRange: CanjeRange
  ) {
    asignarFactura(
      factura: {
        idFactura: $idFactura
        canjeLike: $canjeLike
        canjeRange: $canjeRange
      }
    ) {
      idFactura
      numeroFactura
    }
  }
`;
export const bulkAsignarFacturas = gql`
  mutation bulkAsignarFacturas($idFactura: Int!, $canjes: [Int!]) {
    bulkAsignarFacturas(factura: { idFactura: $idFactura, canjes: $canjes }) {
      idFactura
      numeroFactura
    }
  }
`;
export const updateFactura = gql`
  mutation updateFactura(
    $idFactura: Int!
    $estado: EstadoFactura
    $notasInternas: String
  ) {
    updateFactura(
      factura: {
        idFactura: $idFactura
        estado: $estado
        notasInternas: $notasInternas
      }
    ) {
      idFactura
      numeroFactura
      estado
    }
  }
`;

export const updateFacturaDetail = gql`
  mutation updateFacturaDetail(
    $idFactura: Int!
    $canjes: [CanjeUpdate!]!
    $notasInternas: String
  ) {
    updateFactura(
      factura: { idFactura: $idFactura, notasInternas: $notasInternas }
    ) {
      idFactura
      numeroFactura
      estado
    }
    bulkUpdateCanje(canjes: $canjes) {
      idCanje
    }
  }
`;

export const getFacturas = gql`
  query getFacturas(
    $order: Order = asc
    $orderBy: FacturaColumn = numeroFactura
    $idProveedor: Int
  ) {
    getFacturas(
      facturaOrder: { column: $orderBy, order: $order }
      facturaLike: { idProveedor: $idProveedor }
    ) {
      itemsPagina {
        idFactura
        numeroFactura
      }
    }
  }
`;

export const getFactura = gql`
  query getFactura(
    $order: Order = asc
    $orderBy: FacturaColumn = numeroFactura
    $page: Int = 1
    $rowsPerPage: Int = 10
    $estadoCanje: String
    $proveedor: Int
    $idFactura: Int
    $numeroFactura: String
    $fechaDesde: Date
    $fechaHasta: Date
  ) {
    getFacturas(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      facturaRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
      facturaOrder: { column: $orderBy, order: $order }
      facturaLike: {
        idFactura: $idFactura
        numeroFactura: $numeroFactura
        idProveedor: $proveedor
        estado: $estadoCanje
      }
    ) {
      ...facturas
    }
  }
  ${Facturas.fragments.factura}
`;
export const getFacturasComplete = gql`
  query getFacturasComplete(
    $order: Order
    $orderBy: FacturaColumn!
    $page: Int!
    $rowsPerPage: Int!
    $estadoCanje: String
    $idProveedor: Int
    $factura: String
    $fechaDesde: Date
    $fechaHasta: Date
  ) {
    getFacturas(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      facturaRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
      facturaOrder: { column: $orderBy, order: $order }
      facturaLike: {
        numeroFactura: $factura
        idProveedor: $idProveedor
        estado: $estadoCanje
      }
    ) {
      ...facturas
    }
  }
  ${Facturas.fragments.facturas}
`;

export default {
  queries: {
    getFactura,
    getFacturas,
    getFacturasComplete
  },
  mutations: {
    crearFactura,
    asignarFactura,
    updateFactura,
    updateFacturaDetail,
    bulkAsignarFacturas
  }
};
