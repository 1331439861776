export default theme => ({
  datePicker: {
    marginRight: 4,
    color: "#126FFF"
  },
  input: {
    color: "#126FFF",
    fontSize: "16px",
    fontWeight: 400
  },
  verticalDivider: {
    borderLeft: "1px solid #e0e0e0",
    height: "70%",
    marginTop: "5%"
  },
  contenedorCLN: {
    fontFamily: "'Sueca Nano', serif"
  },
  titulosInferiores: {
    fontFamily: "'Sueca Nano', serif",
    color: "#6A696B",
    marginTop: "30px"
  },
  fondoNegro: {
    background: "linear-gradient(to bottom, white 17%, #414141 17%);",
    [theme.breakpoints.down("sm")]: {
      background: "linear-gradient(to bottom, white 18%, #414141 18%);"
    }
  },
  fondoBlanco: {
    backgroundColor: "white"
  },
  tabla: {
    backgroundColor: "#414141"
  },
  root: {
    backgroundColor: "#414141",
    color: "#FFF",
    boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)"
  },
  tableCellRoot: {
    color: "#FFF",
    padding: "0px 8px",
    textAlign: "right",
    fontFamily: "'Sueca Nano', serif"
  },
  tableCellRootSticky: {
    color: "#FFF",
    left: "0px",
    position: "sticky",
    zIndex: 1,
    backgroundColor: "#414141",
    padding: "0px 8px",
    textAlign: "left",
    fontFamily: "'Sueca Nano', serif"
  },
  tableCellFooterTotal: {
    color: "#FFF",
    left: "0px",
    position: "sticky",
    backgroundColor: "#414141",
    padding: "0px 8px",
    fontWeight: 400,
    textAlign: "left",
    fontFamily: "'Sueca Nano', serif"
  },
  tableCellFooter: {
    color: "#FFF",
    padding: "0px 8px",
    fontWeight: 400,
    textAlign: "right",
    fontFamily: "'Sueca Nano', serif"
  },
  textoBlanco: {
    color: "#fff"
  },
  tableRowRoot: {
    height: "35px"
  },
  tableCellColor: {
    color: "#AFAFAF",
    textAlign: "right",
    fontWeight: 400,
    fontFamily: "'Sueca Nano', serif"
  },
  tablePagination: {
    color: "#fff",
    fontFamily: "'Sueca Nano', serif"
  },
  tablePaginationActions: {
    color: "#fff",
    fontFamily: "'Sueca Nano', serif"
  },
  textoTransaccionesPorSucursal: {
    paddingTop: "30px",
    paddingLeft: "20px"
  },
  progressBarRoot: {
    height: "10px",
    borderRadius: "20px",
    backgroundColor: "#E8E8E8"
  },
  progressBar: {
    backgroundColor: "#126FFF",
    borderRadius: "20px"
  },
  progressBarText: {
    display: "inline",
    fontFamily: "'Sueca Nano', serif",
    color: "#8585853 "
  },
  textoDatePicker: {
    paddingTop: "1px",
    color: "#414141"
  },
  textoDinamicoObjetivo: {
    paddingTop: "1px",
    color: "#126FFF",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "10px"
    }
  },
  textoDinamicoFecha: {
    paddingTop: "1px",
    color: "#126FFF"
  },
  textoFijoObjetivo: {
    color: "#414141",
    fontFamily: "'Sueca Nano', serif"
  },
  textoTituloComercio: {
    color: "#7A7A7A",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px"
    }
  },
  textoPeriodo: {
    color: "#414141"
  },
  contenedorCards: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px"
    }
  }
});
