import React from "react";
import PropTypes from "prop-types";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";

import Enhancer from "./container";

const Input = ({ onChange, classes, value, placeholder }) => (
  <FormControl className={classes.FormControl} variant="outlined" fullWidth>
    <OutlinedInput
      type="text"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      labelWidth={0}
      className={classes.Input}
    />
  </FormControl>
);

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  classes: PropTypes.object
};

Input.displayName = "Input";

export default Enhancer(Input);
