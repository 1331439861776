import React from "react";
import Tabla from "js/components/TablaPrograma";

export const Programa = props => {
  return (
    <div>
      <Tabla />
    </div>
  );
};

export default Programa;
