import React, { PureComponent } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "js/components/Shared/Collapsable/styles.js";

const filtroStyles = {
  title: {
    margin: 0,
    height: 40
  }
};

class CollapsableFilter extends PureComponent {
  componentDidMount = () => {
    this.props.search();
  };

  render() {
    const {
      expanded,
      onChange,
      children,
      title,
      classes = {},
      search,
      clear,
      withActions,
      style,
      withShadow = true,
      parametrosVisuales = {}
    } = this.props;
    return (
      <ExpansionPanel
        expanded={expanded}
        onChange={onChange}
        className={withShadow ? "" : classes.root}
        style={style ? style : {}}
      >
        <ExpansionPanelSummary
          classes={{
            root: withShadow ? "" : classes.panelRoot,
            expandIcon: withShadow ? "" : classes.expandIcon
          }}
          style={filtroStyles.title}
          expandIcon={
            <ExpandMoreIcon
              style={{
                fontSize: parametrosVisuales.sizeFlecha
                  ? parametrosVisuales.sizeFlecha
                  : "1.5rem",
                color: parametrosVisuales.colorFlecha
                  ? parametrosVisuales.colorFlecha
                  : "rgba(0, 0, 0, 0.54)"
              }}
            />
          }
        >
          {parametrosVisuales.color && (
            <div
              style={{
                color: parametrosVisuales.color,
                fontSize: parametrosVisuales.fontSize,
                fontWeight: parametrosVisuales.fontWeight,
                fontFamily: parametrosVisuales.fontFamily
              }}
            >
              {title}
            </div>
          )}
          {!parametrosVisuales.color && (
            <Typography variant="h6">{title}</Typography>
          )}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          {children}
        </ExpansionPanelDetails>
        <Divider />
        {withActions && (
          <ExpansionPanelActions>
            <Button size="small" onClick={() => clear()}>
              Limpiar
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => search()}
            >
              Buscar
            </Button>
          </ExpansionPanelActions>
        )}
      </ExpansionPanel>
    );
  }
}
CollapsableFilter.defaultProps = {
  search: () => undefined,
  clear: () => undefined,
  withActions: true
};
export default withStyles(styles)(CollapsableFilter);
