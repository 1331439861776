import gql from "graphql-tag";

export const getCodigosActivacionByProducto = gql`
  query getCodigosActivacion($idProducto: Int!) {
    getCodigosActivacion(codigoActivacionLike: { idProducto: $idProducto }) {
      codigo
      estado
    }
  }
`;

export const getCodigosActivacion = gql`
  query getCodigosActivacion($codigoSearch: String!) {
    getCodigosActivacion(codigoActivacionLike: { codigo: $codigoSearch }) {
      idCodigoActivacion
      codigo
      estado
      canje {
        idCanje
        cantidad
        nombreProducto
        usuario {
          idUsuario
          username
          perfil {
            nombre
            apellido
            email
          }
        }
      }
      producto {
        imagenes {
          url
          alt
        }
      }
    }
  }
`;

export default {
  queries: {
    getCodigosActivacionByProducto,
    getCodigosActivacion
  }
};
