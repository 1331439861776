import React from "react";
import { _t } from "js/utils/TranslationService";
import { isValidEmail } from "js/helpers/validations";
import ChipInput from "material-ui-chip-input"; // https://github.com/TeamWertarbyte/material-ui-chip-input
import PropTypes from "prop-types";

class MultiEmail extends React.Component {
  state = { emails: [] };

  onBeforeAdd = email => !isValidEmail(email);

  componentWillReceiveProps(props) {
    this.setState({ emails: props.initialValues });
  }

  getEvent = emails => ({
    target: { name: this.props.name, value: [...new Set(emails)] }
  });

  remove = deletedChip => {
    const i = this.props.value.indexOf(deletedChip);
    let array = this.props.value || [];
    array.splice(i, 1);
    return [...new Set(array)];
  };

  add = addedChip => [...new Set([...this.props.value, addedChip])];

  handlePaste = e => {
    e.preventDefault();
    var pastedText = undefined;
    if (window.clipboardData && window.clipboardData.getData) {
      // IE
      pastedText = window.clipboardData.getData("Text");
    } else if (e.clipboardData && e.clipboardData.getData) {
      pastedText = e.clipboardData.getData("text/plain");
    }
    const newValues = pastedText
      .split(";")
      .filter(value => !isValidEmail(value));
    return [...new Set([...this.props.value, ...newValues])];
  };

  render() {
    const {
      value,
      onChange,
      disabled,
      label,
      placeholder,
      required,
      translation,
      helperText
    } = this.props;
    return (
      <ChipInput
        onChange={emails => onChange(this.getEvent(this.add(emails.pop())))}
        onDelete={deletedChip =>
          onChange(this.getEvent(this.remove(deletedChip)))
        }
        onAdd={addedChip => onChange(this.getEvent(this.add(addedChip)))}
        value={value}
        disabled={disabled}
        placeholder={translation || _t(placeholder)}
        label={translation || _t(label)}
        newChipKeyCodes={[9, 13, 188]} // Enter, Tab, ;
        onBeforeAdd={this.onBeforeAdd}
        error={required && !this.state.emails.length}
        helperText={`${_t(
          helperText || "Separar múltiples destinatarios con"
        )} ' ;'`}
        onPaste={e => onChange(this.getEvent(this.handlePaste(e)))}
        {...this.props}
      />
    );
  }
}

MultiEmail.propTypes = {
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired
};

export default MultiEmail;
