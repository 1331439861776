import gql from "graphql-tag";

export const getSesionesTracking = gql`
  query getSesionesTracking(
    $idPrograma: Int
    $order: Order!
    $orderBy: SessionTrackingColumn!
    $fechaDesde: Date
    $fechaHasta: Date
    $username: String
  ) {
    getSesionesTracking(
      sessionTrackingOrder: { column: $orderBy, order: $order }
      sessionTrackingLike: { idPrograma: $idPrograma, username: $username }
      sessionTrackingRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
    ) {
      fecha
      idPrograma
      idUsuario
      sesiones
    }
  }
`;

/**
 * La mutation no sería necesaria en esta instancia
 */
// export const trackSession = gql`
//   mutation trackSession($idPrograma: Int!, $session: SessionArrayTracking!) {
//     trackSession(idPrograma: $idPrograma, session: $session) {
//       idPrograma
//       idUsuario
//       sesiones
//       fecha
//       session
//     }
//   }
// `;

export default {
  queries: {
    getSesionesTracking
  }
  // mutations: {
  //   trackSession
  // }
};
