import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid
} from "@material-ui/core";
import { Person, ShoppingBasket } from "@material-ui/icons";

import { getTheme, addOpacityToHexColor } from "../../../App/utils";

const {
  palette: {
    primary: { main }
  }
} = getTheme();

export const CardCanjeInfo = ({ codigoFound, onClose, handleSubmit }) => {
  return (
    <Card
      style={{
        width: "100%",
        maxWidth: "100%",
        padding: "1%"
      }}
    >
      <Grid xs={12} container>
        <Grid xs={12} container wrap="wrap-reverse">
          <Grid md={6} container>
            <Grid md={12} container justify="center" alignItems="center">
              <List>
                <Typography variant="h6">Código ingresado:</Typography>
                <Typography variant="h6" color="textSecondary">
                  {codigoFound.codigo}
                </Typography>
                <ListItem>
                  <Avatar
                    style={{ backgroundColor: addOpacityToHexColor(main) }}
                  >
                    <ShoppingBasket color="primary" />
                  </Avatar>
                  <ListItemText
                    primary="Producto"
                    secondary={codigoFound.nombreProducto}
                  />
                </ListItem>
                {codigoFound.nombre ? (
                  <ListItem>
                    <Avatar
                      style={{ backgroundColor: addOpacityToHexColor(main) }}
                    >
                      <Person color="primary" />
                    </Avatar>
                    <ListItemText
                      primary="Usuario Solicitante"
                      secondary={`${codigoFound.nombre} ${
                        codigoFound.apellido
                      }`}
                    />
                  </ListItem>
                ) : null}
              </List>
            </Grid>
          </Grid>
          <Grid md={6} item>
            {codigoFound.imagen ? (
              <img
                alt={codigoFound.alt}
                src={codigoFound.imagen}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  width: 556,
                  height: 314,
                  objectFit: "contain"
                }}
              />
            ) : null}
          </Grid>
        </Grid>
        <Grid
          xs={12}
          container
          spacing={8}
          justify="flex-end"
          style={{ paddingLeft: "10px", paddingBottom: "10px" }}
        >
          <Grid xs={12} md={3} item>
            <Button
              color="primary"
              variant="outlined"
              onClick={onClose}
              style={{ width: "99%" }}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid xs={12} md={3} item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              style={{ width: "99%", height: "100%" }}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
CardCanjeInfo.propTypes = {
  codigoFound: PropTypes.object.isRequired,
  onClose: PropTypes.object.isRequired,
  handleSubmit: PropTypes.object.isRequired
};
