import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import AdminNewUsuariosCuentaCorriente from "./AdminNewUsuariosCuentaCorriente";
import styles from "./styles";
import { withNotificationContext } from "js/notification-context";
import withPrograma from "js/hoc/withPrograma";

const enhance = compose(
  withPrograma,
  withApollo,
  withNotificationContext,
  withRouter,
  withStyles(styles)
);

export default enhance(AdminNewUsuariosCuentaCorriente);
