import gql from "graphql-tag";

export const importProductosFacturaPorPuntos = gql`
  mutation importProductosFacturaPorPuntos($file: Upload!, $idPrograma: Int!) {
    importProductosFacturaPorPuntos(file: $file, idPrograma: $idPrograma)
  }
`;

export const createFacturaPoPuntos = gql`
  mutation createFacturaPoPuntos($facturaPorPuntos: FacturaPoPuntosCreate) {
    createFacturaPoPuntos(facturaPorPuntos: $facturaPorPuntos) {
      idPrograma
      idUsuario
      puntoVenta
      numeroFactura
      fechaFactura
      motivo
      detalle
      productos {
        codigo
        nombre
        cantidad
        puntos
      }
      facturaUrl
      modificada
      estado
    }
  }
`;

export const getFacturaPorPuntos = gql`
  query getFacturaPorPuntos(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: String!
    $idPrograma: Int
    $idUsuario: Int
    $estado: EstadoFacturaPorPuntos
    $puntoVenta: String
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
    $fechaFacturaDesde: Date
    $fechaFacturaHasta: Date
    $usuario: String
    $usuarioModerador: String
  ) {
    getFacturaPorPuntos(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      facturaPorPuntosOrder: { column: $orderBy, order: $order }
      facturaPorPuntosLike: {
        idPrograma: $idPrograma
        idUsuario: $idUsuario
        estado: $estado
        puntoVenta: $puntoVenta
        usuario: $usuario
        usuarioModerador: $usuarioModerador
      }
      facturaPorPuntosRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
        fechaFactura: { min: $fechaFacturaDesde, max: $fechaFacturaHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        idPrograma
        idUsuario
        idCargaFactura
        puntoVenta
        facturaUrl
        motivo
        modificada
        fechaFactura
        numeroFactura
        detalle
        usuario {
          username
          perfil {
            nombre
            apellido
          }
        }
        usuarioModerador {
          username
          perfil {
            nombre
            apellido
          }
        }
        productos {
          codigo
          nombre
          cantidad
          puntos
        }
        fechaCreacion
        estado
      }
    }
  }
`;

export const getProductosFacturaPorPuntos = gql`
  query getProductosFacturaPorPuntos(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: GrupoProductosFacturaPorPuntosColumn!
    $idPrograma: Int
  ) {
    getProductosFacturaPorPuntos(
      grupoProductosFacturaPorPuntosLike: { idPrograma: $idPrograma }
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      grupoProductosFacturaPorPuntosOrder: { column: $orderBy, order: $order }
    ) {
      infoPagina {
        numeroPagina
        cantidadPaginas
        total
      }
      itemsPagina {
        _id
        nombre
        codigo
        puntos
      }
    }
  }
`;

export const getPuntosProductoFacturaPorPuntos = gql`
  query getPuntosProductoFacturaPorPuntos($idPrograma: Int!, $codigo: String!) {
    getPuntosProductoFacturaPorPuntos(
      grupoProductosFacturaPorPuntosLike: {
        idPrograma: $idPrograma
        codigo: $codigo
      }
    ) {
      puntos
    }
  }
`;

export const updateFacturaPoPuntos = gql`
  mutation updateFacturaPoPuntos($facturaPorPuntos: FacturaPoPuntosUpdate) {
    updateFacturaPoPuntos(facturaPorPuntos: $facturaPorPuntos) {
      _id
      idPrograma
      idUsuario
      estado
      modificada
      motivo
    }
  }
`;

export default {
  queries: {
    getProductosFacturaPorPuntos,
    getFacturaPorPuntos,
    getPuntosProductoFacturaPorPuntos
  },
  mutations: {
    createFacturaPoPuntos,
    importProductosFacturaPorPuntos,
    updateFacturaPoPuntos
  }
};
