import React, { Fragment, Component } from "react";
import TextField from "@material-ui/core/TextField";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";
import client from "js/App/client.graphql.js";
import Grid from "@material-ui/core/Grid";
import ProveedorQraphQL from "js/graphql/resolvers/proveedor.resolver";
import FacturaQraphQL from "js/graphql/resolvers/facturas.resolver";
import Button from "@material-ui/core/Button";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import get from "lodash/get";
import { _t } from "js/utils/TranslationService";
import { isNumericGtZero } from "js/helpers/validations";

class AdminFacturasNew extends Component {
  state = {
    idProveedor: undefined,
    fecha: moment(),
    importe: undefined,
    factura: undefined,
    error: undefined
  };

  isDissabled = () =>
    this.state.idProveedor !== undefined &&
    this.state.fecha !== undefined &&
    this.state.importe !== undefined &&
    this.state.factura !== undefined &&
    !isNumericGtZero(this.state.importe);

  // TODO : implementar la mutation
  sendValues = async () => {
    const { idProveedor, importe, factura, fecha } = this.state;

    // return get(data, "getPrograma.template.template", false);

    await client
      .mutate({
        mutation: FacturaQraphQL.mutations.crearFactura,
        variables: {
          idProveedorFactura: idProveedor,
          importe: parseFloat(importe),
          factura,
          fecha
        }
      })
      .then(res => {
        this.props.openNotification(`Factura creada correctamente`);
        this.props.history.push("/admin/facturas");
      })
      .catch(error => {
        const msg = get(error, "graphQLErrors[0].message", _t("Hubo un error"));
        this.props.openNotification(msg);
      });
  };

  handleChangeFecha = date => {
    this.setState({ fecha: date });
  };

  handleChange = key => event => {
    if (key === "importe" && event.target.value) {
      const importe = event.target.value.match(/^-?\d+(?:\.\d{0,2})?/)[0];
      this.setState({ error: isNumericGtZero(importe), importe });
    } else this.setState({ [key]: event.target.value });
  };
  render() {
    const { classes, history } = this.props;
    return (
      <Fragment>
        <Grid container spacing={16} className={classes.facturaForm}>
          <Grid
            item
            xs={12}
            md={6}
            className={classes.grid}
            style={{ marginTop: 12 }}
          >
            <TypeAhead
              label={"Proveedor"}
              onChange={select =>
                this.handleChange("idProveedor")({
                  target: { name: "idProveedor", value: select.value }
                })
              }
              path={{
                path: ["getProveedores", "itemsPagina"],
                value: "idProveedor",
                label: "nombreFantasia"
              }}
              placeholder={"Proveedor"}
              query={ProveedorQraphQL.queries.getProveedoresSimple}
              value={this.state.idProveedor}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.grid}>
            <TextField
              id="standard-name"
              label="Número de la factura"
              className={classes.textField}
              value={this.state.factura}
              onChange={this.handleChange("factura")}
              margin="normal"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="standard-name"
              label="Importe"
              className={classes.textField}
              value={this.state.importe}
              onChange={this.handleChange("importe")}
              margin="normal"
              type="number"
              error={!!this.state.error}
              helperText={this.state.error}
            />
          </Grid>

          <Grid item xs={12} md={6} style={{ marginTop: 16 }}>
            <DatePicker
              key={"fecha"}
              name={"fecha"}
              value={this.state.fecha ? moment(this.state.fecha) : null}
              label="Fecha"
              className={classes.textField}
              onChange={v =>
                this.handleChange("fecha")({
                  target: { name: "fecha", value: v.toISOString() }
                })
              }
              autoOk
              format={"DD/MM/YYYY"}
              maxDate={moment()}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              disabled={!this.isDissabled()}
              onClick={this.sendValues}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Crear Factura
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              onClick={() => history.push("/admin/facturas")}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

AdminFacturasNew.defaultProps = {};

AdminFacturasNew.propTypes = {};

export default AdminFacturasNew;
