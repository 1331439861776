import gql from "graphql-tag";

const Template = {};

Template.fragments = {
  templateCompleto: gql`
    fragment templateCompleto on Template {
      idTemplate
      template
    }
  `
};

export default Template;
