import React, { Children } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import { _t } from "js/utils/TranslationService";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import withStyles from "./styles";

class CollapsableSection extends React.PureComponent {
  state = { open: false };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { children, classes, title } = this.props;
    return (
      <Paper className={classes.paper}>
        <ListItem button onClick={this.handleClick}>
          <ListItemText
            primary={_t(title)}
            primaryTypographyProps={{ variant: "h6", color: "primary" }}
          />
          {this.state.open ? (
            <ExpandLess color="primary" />
          ) : (
            <ExpandMore color="primary" />
          )}
        </ListItem>
        <Collapse
          in={this.state.open}
          timeout="auto"
          unmountOnExit
          className={classes.collapse}
        >
          {Children.map(children, (child, i) => (
            <Grid key={i} item xs={12}>
              {child}
            </Grid>
          ))}
        </Collapse>
      </Paper>
    );
  }
}

CollapsableSection.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string
};

CollapsableSection.defaultProps = {
  title: ""
};

CollapsableSection.displayName = "CollapsableSection";

export default withStyles(CollapsableSection);
