import React from "react";
import AuthService from "js/utils/AuthService";
import base from "./Base";
import RevertDialog from "./RevertDialog";
import { History } from "@material-ui/icons";

export default params => {
  return {
    ...base(),
    label: params.label,
    icon: <History />,
    tooltip:
      params.item.estado === "ANULADA" ? "Movimiento anulado" : params.tooltip,
    disabled: () => (params.item.estado === "ANULADA" ? true : false),
    show: () => AuthService.hasPerms(params.perms),
    dialogId: "DELETE",
    dialog: (
      <RevertDialog
        handleClose={params.togglePopUp}
        onTableChange={params.onTableChange}
        item={params.item}
        dialogTitle={params.dialogTitle}
        query={params.query}
        mutation={params.mutation}
      />
    )
  };
};
