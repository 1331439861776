import Tabla from "./tabla";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import withPrograma from "js/hoc/withPrograma";

import { compose } from "recompose";

const enhance = compose(
  withPrograma,
  withRouter,
  withApollo
);
export default enhance(Tabla);
