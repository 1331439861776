import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import { compose, pure, withProps } from "recompose";
import withPrograma from "js/hoc/withPrograma";
import Informacion from "./Informacion";
import styles from "./styles";
import get from "lodash/get";
import { withApollo } from "react-apollo";
import UserQraphQL from "js/graphql/resolvers/usuario.resolver";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";

const enhance = compose(
  pure,
  withRouter,
  withApollo,
  withStyles(styles),
  withPrograma,
  withProps(ownerProps => {
    const {
      location: { search = "", pathname },
      programa
    } = ownerProps;
    const path = `${pathname}${search}`;
    const footer = get(programa, "template.footer.columnasFooter", []).reduce(
      (acc, { links = [] }) => acc.concat(links),
      []
    );
    const navbar = get(programa, "template.header.menu.navbar.items", []);
    const landing = get(programa, "template.landing.filas", []).reduce(
      (acc, { contenidos = [] }) =>
        acc
          .concat(contenidos)
          .concat(
            contenidos.reduce((acc, { bases = [] }) => acc.concat(bases), [])
          ),
      []
    );
    const destacados = get(
      programa,
      "template.destacados.contenidos",
      []
    ).concat(
      get(programa, "template.destacados.contenidos", []).reduce(
        (acc, { bases = [] }) => acc.concat(bases),
        []
      )
    );

    const {
      iframe: url = "",
      height,
      padding,
      backgroundColor,
      fullWidth,
      marginTop
    } =
      footer
        .concat(navbar)
        .concat(landing)
        .concat(destacados)
        .find(({ link }) => link === path) || {};
    return { url, height, padding, backgroundColor, fullWidth, marginTop };
  }),
  graphql(AppQraphQL.queries.isLoggedIn, {
    options: () => ({
      fetchPolicy: "cache-first"
    }),
    props: ({ data }) => {
      const { loading, isLoggedIn } = data;
      if (loading) return;
      return {
        isLoggedIn
      };
    }
  }),
  graphql(UserQraphQL.queries.getMiUsuarioCompleto, {
    options: () => ({
      fetchPolicy: "cache-first"
    }),
    props: ({ data }) => {
      const { loading, miUsuario } = data;

      if (loading)
        return {
          usuario: {}
        };
      return {
        usuario: miUsuario
      };
    }
  })
);
export default enhance(Informacion);
