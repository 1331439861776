export const styles = theme => ({
  dialogColumn: {
    display: "flex",
    direction: "column"
  },
  radioOption: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  fileField: {
    [theme.breakpoints.down("xs")]: {
      width: "50%"
    }
  },
  btnsContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "flex-start"
    }
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between"
    }
  },
  actionButtons: {
    margin: "0 0 0 1em",
    width: "35%",
    [theme.breakpoints.down("xs")]: {
      width: "48%",
      margin: "0 0 0 0"
    }
  },
  cancelButton: {
    width: "35%",
    [theme.breakpoints.down("xs")]: {
      width: "99%"
    }
  }
});
