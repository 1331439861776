import React, { PureComponent } from "react";
import moment from "moment";
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { _t } from "js/utils/TranslationService";
import AuthService from "js/utils/AuthService";
import SupervisorPanel from "./supervisorPanel";
import PerformanceIndicador from "./performanceIndicador";
import { numberWithCommas } from "js/utils";

class CampanaPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expandedCampana: false
    };
  }

  getUserName = usuario => {
    if (!usuario) {
      return AuthService.getIdUsuario();
    }
    return usuario.perfil && (usuario.perfil.nombre || usuario.perfil.apellido)
      ? `${usuario.perfil.nombre} ${usuario.perfil.apellido}`
      : usuario.username;
  };

  renderPerformanceIndividual = (
    classes,
    i,
    performanceUsuario,
    showName,
    campana
  ) => {
    const nombre = this.getUserName(performanceUsuario.usuario);
    return (
      <Grid className={classes.container} item xs={12} key={i}>
        {showName && (
          <div className={classes.usernameTitle}>
            {nombre} {"  "}{" "}
            {/* {this.hasCompletedObjetivos(performanceUsuario.objetivos)
              ? "(" +
                _t("Ganó ") +
                numberWithCommas(campana.puntosTodos) +
                " puntos)"
              : _t("(No logró los objetivos)")} */}
            {this.hasCompletedObjetivos(performanceUsuario.objetivos)
              ? _t("(Logró los objetivos)")
              : _t("(No logró los objetivos)")}
          </div>
        )}
        <div className={classes.performanceContainer}>
          {performanceUsuario.objetivos.map((performanceObjetivo, j) => {
            return (
              <PerformanceIndicador
                key={`${nombre}-${i}-${j}`}
                index={`${nombre}-${i}-${j}`}
                performance={performanceObjetivo}
                color={"#ce762e"}
              />
            );
          })}
        </div>
      </Grid>
    );
  };

  handleCampanaCollapse = () => {
    this.setState(prevState => ({
      expandedCampana: !prevState.expandedCampana
    }));
  };

  hasCompletedObjetivos = objetivos => {
    let completed = true;
    objetivos.map(objetivo => {
      if (objetivo.performance < objetivo.target) {
        completed = false;
      }
    });
    return completed;
  };

  hasCompletedCampana = () => {
    const { performanceCampana, isMiPerformance, isPerformance } = this.props;
    let objetivos = [];
    if (isPerformance) {
      objetivos = performanceCampana.total;
    }

    if (isMiPerformance) {
      objetivos = performanceCampana.individual.itemsPagina[0].objetivos;
    }

    const completed = this.hasCompletedObjetivos(objetivos);
    return completed;
  };

  render() {
    const {
      classes,
      performanceCampana,
      index,
      isMiPerformance,
      isPerformance,
      idPrograma,
      style
    } = this.props;

    const { expandedCampana } = this.state;

    const campana = performanceCampana.campana;
    const performanceUsuarios = performanceCampana.individual.itemsPagina;
    const fechaActualizacion = performanceCampana.fecha;

    return (
      <ExpansionPanel
        expanded={expandedCampana}
        onChange={this.handleCampanaCollapse}
        elevation={5}
        key={index}
        style={style}
      >
        <ExpansionPanelSummary
          classes={{ content: classes.panelSummary }}
          expandIcon={<ExpandMoreIcon />}
        >
          <div className={classes.campanaTitle}>
            <div>
              {campana.imagenes &&
                campana.imagenes[0] && (
                  <img
                    alt={`imagen-campana-${campana.nombre
                      .replace(/\s/g, "-")
                      .toLowerCase()}`}
                    src={campana.imagenes[0]}
                    style={{ maxWidth: "100px", marginRight: "10px" }}
                  />
                )}
              <span>
                {campana.nombre}{" "}
                <span className={classes.campanaDate}>
                  {moment(campana.fechaInicio).format("DD/MM/YYYY")} -{" "}
                  {moment(campana.fechaFin).format("DD/MM/YYYY")}
                </span>
              </span>
            </div>
            <div className={classes.campanaEstado}>
              <div>
                {campana.estado === "INICIADA" ? "ACTIVA" : campana.estado}
              </div>
              {campana.estado === "FINALIZADA" && this.hasCompletedCampana() ? (
                <div className={classes.campanaComplete}>
                  {/* {"Ganaste " +
                    (isPerformance
                      ? numberWithCommas(campana.puntosTodosSupervisor)
                      : numberWithCommas(campana.puntosTodos)) +
                    " puntos"} */}
                  {_t("Objetivo Logrado")}
                </div>
              ) : (
                campana.estado === "FINALIZADA" && (
                  <div className={classes.campanaComplete}>
                    {_t("No lograste los objetivos")}
                  </div>
                )
              )}
            </div>
          </div>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Grid className={classes.container} container spacing={16}>
            <Grid className={classes.container} item xs={12}>
              <p className={classes.performanceDate}>
                {(!fechaActualizacion && _t("No se registraron ventas")) ||
                  (fechaActualizacion &&
                    _t("Últimas ventas registradas ") +
                      moment(fechaActualizacion).format("DD/MM/YYYY"))}
              </p>
              <p className={classes.campanaDescripcion}>
                {campana.descripcion}
              </p>
            </Grid>

            {isPerformance && (
              <SupervisorPanel
                classes={classes}
                index={index}
                performanceCampana={performanceCampana}
                renderPerformanceIndividual={this.renderPerformanceIndividual.bind(
                  this
                )}
                idPrograma={idPrograma}
              />
            )}

            {isMiPerformance &&
              !isPerformance &&
              performanceUsuarios.map((performanceUsuario, i) => {
                return this.renderPerformanceIndividual(
                  classes,
                  `${index}-${i}`,
                  performanceUsuario,
                  false,
                  campana
                );
              })}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default CampanaPanel;
