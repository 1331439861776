import React from "react";
import TextField from "@material-ui/core/TextField";
import { _t } from "js/utils/TranslationService";
import { DatePicker } from "material-ui-pickers";
import moment from "moment";
import Grid from "@material-ui/core/Grid";

export const getField = ({
  key,
  nombre,
  datosRequeridosValues,
  handleChangeDatoRequerido
}) => {
  switch (key) {
    case "fechaNacimiento":
      return (
        <Grid item xs={12} md={6}>
          <DatePicker
            id={key}
            key={key}
            value={
              datosRequeridosValues[key]
                ? moment(datosRequeridosValues[key])
                : null
            }
            label={`${nombre}`}
            onChange={v =>
              handleChangeDatoRequerido(key)({
                target: { name: key, value: v.toISOString() }
              })
            }
            format={"DD/MM/YYYY"}
            maxDate={moment()}
            autoOk
            margin="normal"
            required
          />
        </Grid>
      );

    default:
      return (
        <Grid item xs={12} md={6}>
          <TextField
            id={key}
            type="text"
            key={key}
            label={`${nombre}`}
            required
            value={datosRequeridosValues[key]}
            onChange={handleChangeDatoRequerido(key)}
            margin="normal"
          />
        </Grid>
      );
  }
};
