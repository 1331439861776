import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  reduxForm,
  formValueSelector,
  change,
  getFormMeta,
  FieldArray
} from "redux-form";
import {
  Grid,
  FormControl,
  Button,
  Icon,
  Tooltip,
  Paper,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { styles } from "js/components/Shared/Form/styles.js";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import ImageUploader, { ItemImages } from "js/components/Shared/ImageUploader";
import {
  InputTextField,
  InputNumberField,
  ListField,
  TypeAhead
} from "js/components/Shared/Form/Fields";
import { _t } from "js/utils/TranslationService";

const renderObjectives = ({ fields, meta: { error, submitFailed } }) => (
  <Grid item container xs={12} spacing={16}>
    <Tooltip title={_t("Agregar objetivo")} placement="right-end">
      <Icon
        // className={classes.iconAddElement}
        color="secondary"
        onClick={() => fields.push({})}
      >
        add_circle
      </Icon>
    </Tooltip>
    {fields.map((objective, index) => {
      return (
        <Fragment key={index}>
          <Grid xs={11} item container>
            <Paper style={{ width: "100%", padding: "15px" }}>
              <Grid xs={12} item container spacing={8}>
                <Grid item xs={12} sm={5}>
                  <InputTextField
                    name={`${objective}.objetivo`}
                    label={_t("Objetivo")}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <ListField
                    name={`${objective}.formaSumar`}
                    label={_t("Forma sumar")}
                    options={[
                      {
                        label: _t("Cantidad de ventas"),
                        value: "SUMA_CANTIDADES"
                      },
                      {
                        label: _t(
                          "Cantidad de clientes que compraron (cobertura)"
                        ),
                        value: "CUENTA_FILAS_DISTINTAS"
                      }
                    ]}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <InputNumberField
                    name={`${objective}.puntos`}
                    label={_t("Puntos")}
                    required
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Tooltip title={_t("Quitar objetivo")} placement="right-start">
            <Icon
              // className={classes.iconRemoveElement}
              color="action"
              onClick={() => fields.splice(index, 1)}
            >
              remove_circle
            </Icon>
          </Tooltip>
        </Fragment>
      );
    })}
  </Grid>
);

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fechaNotChanged: true,
      fechaInicio: null,
      fechaFin: null,
      fechaCierre: null
    };
  }

  handleFechaInicioChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaNotChanged: false,
      fechaInicio: date
    });
  };

  handleFechaFinChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaNotChanged: false,
      fechaFin: date
    });
  };

  handleFechaCierreChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaNotChanged: false,
      fechaCierre: date
    });
  };

  handleCampanasRelacionadasChange = values => {
    const ids = values.map(value => value.value);
    this.props.change("campanasRelacionadas", ids);
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      id,
      initialValues,
      grupoSKU,
      tipo,
      onChangeImage,
      onRemoveImage,
      campanaImages,
      campanasInput,
      campanasRelacionadas,
      gruposSKU
    } = this.props;

    return (
      <form className={classes.container} onSubmit={handleSubmit}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {initialValues.nombre ? "Editar campaña" : "Nueva campaña"}
            </Typography>
          </Grid>

          {errors &&
            errors.length !== 0 && (
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors.map(e => (
                    <li>{e.message}</li>
                  ))}
                </ul>
              </Grid>
            )}

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <InputTextField
                name="nombre"
                label={_t("Nombre campaña")}
                disabled={disabled}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={8}>
            <FormControl className={classes.field}>
              <InputTextField
                name="descripcion"
                label={_t("Descripción campaña")}
                disabled={disabled}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <DatePicker
                name={"fechaInicio"}
                dateFormat="DD/MM/YYYY"
                label={_t("Fecha inicio campaña")}
                autoOk
                disabled={disabled}
                input={{
                  value:
                    this.state.fechaInicio !== "" && this.state.fechaInicio
                      ? new Date(this.state.fechaInicio)
                      : initialValues.fechaInicio
                        ? initialValues.fechaInicio
                        : null,
                  onChange: this.handleFechaInicioChange("fechaInicio")
                }}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <DatePicker
                name={"fechaFin"}
                dateFormat="DD/MM/YYYY"
                label={_t("Fecha fin campaña")}
                autoOk
                disabled={disabled}
                input={{
                  value:
                    this.state.fechaFin !== "" && this.state.fechaFin
                      ? new Date(this.state.fechaFin)
                      : initialValues.fechaFin
                        ? new Date(initialValues.fechaFin)
                        : null,
                  onChange: this.handleFechaFinChange("fechaFin")
                }}
                minDate={
                  this.state.fechaInicio
                    ? moment(this.state.fechaInicio).add(1, "d")
                    : initialValues.fechaInicio
                      ? moment(initialValues.fechaInicio).add(1, "d")
                      : new Date()
                }
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <DatePicker
                name={"fechaCierre"}
                dateFormat="DD/MM/YYYY"
                label={_t("Fecha cierre campaña")}
                autoOk
                disabled={disabled}
                input={{
                  value:
                    this.state.fechaCierre !== "" && this.state.fechaCierre
                      ? new Date(this.state.fechaCierre)
                      : initialValues.fechaCierre
                        ? new Date(initialValues.fechaCierre)
                        : null,
                  onChange: this.handleFechaCierreChange("fechaCierre")
                }}
                minDate={
                  this.state.fechaFin
                    ? moment(this.state.fechaFin)
                    : initialValues.fechaFin
                      ? moment(initialValues.fechaFin)
                      : new Date()
                }
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <InputTextField
                name="puntosTodos"
                label={_t("Bonus total vendedores")}
                disabled={disabled}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <InputTextField
                name="puntosTodosSupervisor"
                label={_t("Bonus total supervisores")}
                disabled={disabled}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <ListField
              name="tipo"
              label={_t("Tipo de campaña")}
              options={[
                { label: "Simple", value: "SIMPLE" },
                { label: "Compuesta", value: "COMPUESTO" }
              ]}
              disabled={disabled}
              required
            />
          </Grid>

          {tipo === "COMPUESTO" &&
            campanasInput &&
            campanasInput.length !== 0 && (
              <Grid item xs={12}>
                <TypeAhead
                  name="campanasRelacionadas"
                  suggestions={{
                    campanas: campanasInput.map(campana => ({
                      value: campana._id,
                      label: campana.nombre
                    }))
                  }}
                  path={{ path: "campanas", value: "value", label: "label" }}
                  onChange={this.handleCampanasRelacionadasChange}
                  value={
                    campanasRelacionadas || initialValues.campanasRelacionadas
                  }
                  placeholder={_t("Campañas relacionadas")}
                  label={_t("Campañas relacionadas")}
                  classes={classes}
                  multiple={true}
                  labelOrder={false}
                />
              </Grid>
            )}

          <Grid item xs={12}>
            <Typography variant="h5">{_t("Imagen de la campaña")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <ImageUploader
              onChangeImage={onChangeImage}
              disableClick={true}
              disabled={disabled || campanaImages.length === 1}
              medidasRecomendadas={"556 x 556"}
            >
              <ItemImages
                images={campanaImages}
                onRemoveImage={onRemoveImage}
                disabled={disabled}
                alt={"imagen-campaña"}
              />
            </ImageUploader>
          </Grid>

          {tipo === "SIMPLE" && (
            <Fragment>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {_t("Productos incluidos")}
                </Typography>
              </Grid>

              {gruposSKU &&
                gruposSKU.length !== 0 && (
                  <Grid item xs={12} sm={4}>
                    <ListField
                      name="grupoSKU"
                      label={_t("Grupo de SKUs incluidos")}
                      options={gruposSKU.map((grupo, index) => {
                        return {
                          label: grupo.nombre,
                          value: grupo._id
                        };
                      })}
                      disabled={disabled}
                      required
                    />
                  </Grid>
                )}
              {grupoSKU &&
                gruposSKU && (
                  <Grid item xs={12} sm={8}>
                    <Typography variant="h6">
                      {_t("Cantidad de productos:")}{" "}
                      {gruposSKU
                        .filter(grupo => grupo._id === grupoSKU)
                        .map(grupo => {
                          return grupo.productos.length;
                        })}
                    </Typography>
                  </Grid>
                )}

              <Grid item xs={12}>
                <Typography variant="h5">{_t("Objetivos campaña")}</Typography>
              </Grid>

              <Grid item xs={12}>
                <FieldArray
                  name="fields"
                  component={renderObjectives}
                  disabled={disabled}
                />
              </Grid>
            </Fragment>
          )}

          <Grid className={classes.gridButton} item xs={12}>
            <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              style={{ marginRight: 20 }}
              onClick={() =>
                history.push(`/admin/programa/${id}/campanas-unilever`)
              }
            >
              {_t("Cancelar")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              disabled={
                disabled ||
                (pristine && this.state.fechaNotChanged) ||
                submitting
              }
            >
              {_t("Guardar")}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

Form = reduxForm({
  form: "formCampana",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formCampana");

Form = connect(
  state => ({
    formMeta: getFormMeta("formCampana")(state),
    grupoSKU: selector(state, "grupoSKU"),
    tipo: selector(state, "tipo"),
    campanasRelacionadas: selector(state, "campanasRelacionadas")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formCampana", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
