export const REQUIREMENTS = {
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  PUBLICO_O_LOGUEADO: "PUBLICO_O_LOGUEADO",
  COMPLETAR_PERFIL: "COMPLETAR_PERFIL",
  TRIVIA: "TRIVIA",
  FORMULARIO: "FORMULARIO",
  HTML_EXPECTATIVA: "HTML_EXPECTATIVA"
};

export const PATH = {
  UPDATE_PASSWORD: "/updatePassword",
  PUBLICO_O_LOGUEADO: "/signin",
  COMPLETAR_PERFIL: "/profile",
  TRIVIA: "/trivia",
  FORMULARIO: "/formulario",
  HTML_EXPECTATIVA: "/proximamente"
};

export const ALLOWED_ALWAYS = ["/condiciones"];
