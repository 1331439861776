/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from "react";
import PropTypes from "prop-types";
import Select, { createFilter } from "react-select";

import {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
} from "./TypeAheadComponents";

class Autocomplete extends React.Component {
  render() {
    const components = {
      Control,
      Menu,
      MultiValue,
      NoOptionsMessage,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer
    };

    const {
      classes,
      theme,
      suggestions,
      disabled,
      placeholder,
      noOptionsMessage,
      onChange,
      value
    } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit",
          height: "auto !important"
        }
      })
    };

    const filterConfig = {
      ignoreCase: true,
      ignoreAccents: true,
      trim: true,
      matchFrom: "any"
    };

    return (
      <div className={classes.root}>
        <Select
          noOptionsMessage={noOptionsMessage}
          isDisabled={disabled}
          classes={classes}
          styles={selectStyles}
          options={suggestions}
          components={components}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          filterOption={createFilter(filterConfig)}
        />
      </div>
    );
  }
}

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.object
};

Autocomplete.defaultProps = {
  disabled: true,
  value: { value: undefined, label: "" }
};

export default Autocomplete;
