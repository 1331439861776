export default theme => ({
  gridButton: {
    marginTop: 20,
    textAlign: "end"
  },
  button: {
    margin: theme.spacing.unit
  },
  divider: {
    margin: "24px 0"
  }
});
