import { withStyles } from "@material-ui/core/styles";
import HeaderBelowRaw from "./HeaderBelow";
import HeaderBurgerRaw from "./HeaderBurger";
import HeaderInsideRaw from "./HeaderInside";

import styles from "./styles";

import withPrograma from "js/hoc/withPrograma";

import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withApollo } from "react-apollo";

const enhance = compose(
  withRouter,
  withPrograma,
  withApollo,
  withStyles(styles)
);

export const HeaderBelow = enhance(HeaderBelowRaw);
export const HeaderInside = enhance(HeaderInsideRaw);
export const HeaderBurger = enhance(HeaderBurgerRaw);

export default {
  HeaderBelow,
  HeaderInside,
  HeaderBurger
};
