import React, { PureComponent } from "react";
import EnhancedTable from "js/components/Shared/Tabla/EnhancedTable";
import { withApollo } from "react-apollo";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import SplashScreen from "js/pages/SplashScreen";
import Header from "js/components/Shared/Tabla/Header";

class Tabla extends PureComponent {
  state = { data: [] };
  componentWillMount = async () => {
    const { client } = this.props;
    const { data } = await client.query({
      query: AppQraphQL.queries.getPaginaMiPrograma,
      variables: { url: window.location.hostname.replace("www.", "") },
      errorPolicy: "all",
      fetchPolicy: "no-cache"
    });
    this.setState({ data: [data.getPrograma] });
  };

  render() {
    const { columnData, actionsCustom } = this.props;

    return (
      <React.Fragment>
        <Header values={{ title: "Programas" }} />
        {!this.state.data && <SplashScreen />}
        {this.state.data && (
          <EnhancedTable
            columnData={columnData}
            data={this.state.data}
            showPagination={false}
            page="1"
            total="1"
            totalDisabledItems="0"
            actionsCustom={actionsCustom}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withApollo(Tabla);
