import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  select: {
    margin: 15,
    justifyContent: "center",
    alignItems: "center"
  }
});

export default withStyles(styles);
