import { compose, pure, withProps } from "recompose";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import withPrograma from "js/hoc/withPrograma";
import Performance from "./Performance";
import styles from "./styles";
import get from "lodash/get";

const enhance = compose(
  pure,
  withRouter,
  withApollo,
  withStyles(styles),
  withPrograma,
  withProps(ownerProps => {
    const {
      location: { search = "", pathname },
      programa
    } = ownerProps;
    const path = `${pathname}${search}`;
    const footer = get(programa, "template.footer.columnasFooter", []).reduce(
      (acc, { links = [] }) => acc.concat(links),
      []
    );
    const navbar = get(programa, "template.header.menu.navbar.items", []);
    const landing = get(programa, "template.landing.filas", []).reduce(
      (acc, { contenidos = [] }) =>
        acc
          .concat(contenidos)
          .concat(
            contenidos.reduce((acc, { bases = [] }) => acc.concat(bases), [])
          ),
      []
    );
    const destacados = get(
      programa,
      "template.destacados.contenidos",
      []
    ).concat(
      get(programa, "template.destacados.contenidos", []).reduce(
        (acc, { bases = [] }) => acc.concat(bases),
        []
      )
    );

    const { padding, backgroundColor, fullWidth, marginTop } =
      footer
        .concat(navbar)
        .concat(landing)
        .concat(destacados)
        .find(({ link }) => link === path) || {};
    return { padding, backgroundColor, fullWidth, marginTop };
  })
);
export default enhance(Performance);
