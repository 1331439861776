import { connect } from "react-redux";
import Producto from "./Producto";
import { withRouter } from "react-router-dom";
import AuthService from "js/utils/AuthService";

const mapStateToProps = state => ({
  sucursales: state.producto.sucursales,
  isLogged: !!AuthService.getIdUsuario()
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Producto));
