const styles = theme => ({
  root: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    backgroundColor: "#fafafa"
  },
  formControl: {
    width: "100%",
    marginBottom: "30px"
  },
  formControlSwitch: {
    width: "400px",
    marginBottom: "10px",
    flexDirection: "row",
    alignItems: "center"
  },
  gridValueContainer: {
    flex: "1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  subtitle: {
    margin: "1.5rem 0"
  },
  buttonContainer: {
    textAlign: "right"
  },
  button: {
    margin: 8
  }
});
export default styles;
