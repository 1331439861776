import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { sumBy } from "lodash";
import { numberWithCommas } from "js/utils";
const rangos = [
  { label: "menores de 18", key: "transaccionesMenor18" },
  { label: "18-25", key: "transacciones18a25" },
  { label: "25-35", key: "transacciones25a35" },
  { label: "35-45", key: "transacciones35a45" },
  { label: "mayores de 45", key: "transaccionesMayor45" },
  { label: "13-17", key: "transacciones13a17" },
  { label: "18-20", key: "transacciones18a20" },
  { label: "21-30", key: "transacciones21a30" },
  { label: "31-40", key: "transacciones31a40" },
  { label: "41-50", key: "transacciones41a50" },
  { label: "51-60", key: "transacciones51a60" },
  { label: "61 o mas", key: "transacciones61oMas" }
];

const RangoEtario = ({ data, classes }) => {
  // const hayInfo = Object.keys(data).some(
  //   key => !!data[key] && key !== "__typename"
  // );
  const total = sumBy(rangos, ({ key }) => data[key]);
  return rangos.map(({ label, key }) => {
    let value = 0;
    if (data[key] !== "undefined" && total) {
      value = (data[key] / total) * 100;
    }
    if (value > 0) {
      return (
        <Grid xs={12} item>
          <LinearProgress
            value={value}
            color="primary"
            variant="determinate"
            classes={{
              root: classes.progressBarRoot,
              bar: classes.progressBar
            }}
          />
          <Typography
            variant="subtitle2"
            color="primary"
            className={classes.progressBarText}
          >
            <Typography
              display="inline"
              style={{ color: "#858585" }}
              className={classes.progressBarText}
            >{`${label}`}</Typography>
            <Typography
              display="inline"
              style={{
                color: "#126FFF",
                float: "right",
                "font-weight": "bold"
              }}
              className={classes.progressBarText}
            >
              {`${numberWithCommas(value.toPrecision(2))}%`}
            </Typography>
          </Typography>
        </Grid>
      );
    }
  });
  // <GoogleChart
  //   height={"300px"}
  //   chartType="PieChart"
  //   data={
  //     hayInfo
  //       ? [
  //           ["Edad", "Cantidad"],
  //           ...rangos.map(({ label, key }) => [label, data[key] || 0])
  //         ]
  //       : [["Sin información", "."], ["Sin información", 1], [".", 0]]
  //   }
  //   options={{
  //     title: "Rango Etario",
  //     legend: { position: "bottom", alignment: "center" }
  //   }}
  // />
};

RangoEtario.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

RangoEtario.defaultProps = {
  data: {}
};

RangoEtario.displayName = "RangoEtario";

export default RangoEtario;
