import React, { Fragment, Component } from "react";

import TablaLotes from "./TablaLotes";

class Lotes extends Component {
  componentWillMount() {
    this.props.history.replace({ pathname: "/admin/lotes" });
  }

  render() {
    return (
      <Fragment>
        <TablaLotes />
      </Fragment>
    );
  }
}

export default Lotes;
