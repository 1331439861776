import React, { PureComponent } from "react";
import AppQraphQL from "js/graphql/resolvers/segmento.resolver";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import AddIconIcon from "@material-ui/icons/Add";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import IdiomaQraphQL from "js/graphql/resolvers/idioma.resolver";
import Button from "@material-ui/core/Button";

import List from "js/pages/Admin/Programa/Segmentos/List";
class Segmentos extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: [],
      nombreSegmento: "",
      descripcionSegmento: "",
      idIdioma: ""
    };
  }

  getSegmentos() {
    const { client } = this.props;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return client
      .query({
        query: AppQraphQL.queries.getSegmentoById,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: parseInt(id) || id
        }
      })
      .then(res => {
        res.data.getSegmentos.length > 0 &&
          this.setState({ initialValues: res.data.getSegmentos });
      });
  }

  async componentDidMount() {
    await this.getSegmentos();
  }

  async handleSave(
    idSegmento,
    nombre,
    descripcion,
    idIdiomaDefault,
    handleClose
  ) {
    const { client } = this.props;
    await client
      .mutate({
        mutation: AppQraphQL.queries.updateSegmento,
        variables: {
          segmento: {
            idSegmento,
            descripcion,
            idIdiomaDefault,
            nombre: nombre.toUpperCase() !== "DEFAULT" ? nombre : undefined
          }
        }
      })
      .then(res => {
        this.getSegmentos();
        handleClose();
      })
      .catch(e => {
        const message = e.message.replace("GraphQL error:", "").trim();
        this.props.openNotification(message);
      });
  }

  handleChangeSegmentoNombre = event => {
    this.setState({ nombreSegmento: event.target.value });
  };

  handleChangeSegmentoDescripcion = event => {
    this.setState({ descripcionSegmento: event.target.value });
  };

  handleChangeSegmentoIdioma = select => {
    this.setState({ idIdioma: select.value });
  };

  async createSegmento() {
    const {
      client,
      match: {
        params: { id }
      }
    } = this.props;
    const { nombreSegmento, descripcionSegmento, idIdioma } = this.state;
    if (
      nombreSegmento === "Default" ||
      nombreSegmento === "" ||
      idIdioma === ""
    )
      return null;
    await client
      .mutate({
        mutation: AppQraphQL.queries.createSegmento,
        variables: {
          segmento: {
            idPrograma: parseInt(id) || id,
            nombre: nombreSegmento,
            descripcion: descripcionSegmento,
            idIdiomaDefault: idIdioma
          }
        }
      })
      .then(() => {
        this.getSegmentos();
        const message = `Segmento grabado correctamente`;
        this.props.openNotification(message);
        this.setState({
          nombreSegmento: "",
          descripcionSegmento: "",
          idIdioma: ""
        });
      })
      .catch(e => {
        const message = e.message.replace("GraphQL error:", "").trim();
        this.props.openNotification(message);
      });
  }

  render() {
    const { initialValues } = this.state;
    const { classes, history } = this.props;
    const {
      match: {
        params: { id: idPrograma }
      }
    } = this.props;
    return (
      <div className={classes.container}>
        <Typography variant="h5" gutterBottom>
          Segmentos
        </Typography>
        <div className={classes.addInput}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="name-simple">Nombre del segmento</InputLabel>
            <Input
              id="segmento"
              value={this.state.nombreSegmento}
              onChange={this.handleChangeSegmentoNombre.bind(this)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="descripcion-simple">Descripción</InputLabel>
            <Input
              id="descripcion"
              value={this.state.descripcionSegmento}
              onChange={this.handleChangeSegmentoDescripcion.bind(this)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TypeAhead
              placeholder={"Idioma default"}
              onChange={this.handleChangeSegmentoIdioma.bind(this)}
              path={{
                path: "getProgramas.itemsPagina[0].idiomas",
                value: "idioma._id",
                label: "idioma.nombre"
              }}
              query={IdiomaQraphQL.queries.getIdiomasByProgramaId}
              queryParams={{ idPrograma: parseInt(idPrograma) || idPrograma }}
              value={this.state.idIdioma}
            />
          </FormControl>
          <IconButton onClick={this.createSegmento.bind(this)}>
            <AddIconIcon />
          </IconButton>
        </div>

        {initialValues && (
          <List
            initialValues={initialValues}
            onHandleSave={this.handleSave.bind(this)}
            idPrograma={idPrograma}
          />
        )}
        <div className={classes.backButton}>
          <Button
            type="button"
            className={classes.button}
            color="primary"
            onClick={() =>
              history.push(`/admin/programa/${idPrograma}/gestion`)
            }
            variant="contained"
          >
            Volver
          </Button>
        </div>
      </div>
    );
  }
}

export default Segmentos;
