import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from "@material-ui/core";

import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";

class advancedFilter extends PureComponent {
  render() {
    const { value, submitValue, classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"nombre"}
              name={"nombre"}
              label={"Nombre de campaña"}
              type="search"
              value={value["nombre"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"PENDIENTE"}>Pendiente</MenuItem>
              <MenuItem value={"INICIADA"}>Iniciada</MenuItem>
              <MenuItem value={"FINALIZADA"}>Finalizada</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaInicio"}
              name={"fechaInicio"}
              label="Fecha inicio (Desde)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaInicio",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaInicio"]
                  ? moment(value["fechaInicio"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaFin"}
              name={"fechaFin"}
              label="Fecha fin (Hasta)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaFin",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaFin"] ? moment(value["fechaFin"]) : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
