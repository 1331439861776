import {
  minLength,
  hasLowerCase,
  hasUpperCase,
  hasNumber
} from "js/helpers/validations";

export default () => {
  let validations = [];
  validations.push(minLength(8));
  validations.push(hasLowerCase);
  validations.push(hasUpperCase);
  validations.push(hasNumber);
  return validations;
};
// Funciona a la inversa
export const validateLetrasNumeros = value => {
  return !/^[a-zA-Z0-9\s]*$/.test(value);
};
export const validateDefault = value => {
  return !/^[A-Za-z0-9._&@-]*$/i.test(value);
};
export const validateNumeros = value => {
  return !/^\d+$/.test(value);
};
