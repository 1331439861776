import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Enhancer from "./container";
import { _t } from "js/utils/TranslationService";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { InputPasswordField } from "js/components/Shared/Form/Fields";

const UpdatePassword = ({
  handleSubmit,
  classes,
  hasSubmitFailed,
  handleClickShowPassword,
  showPassword,
  history,
  pristine,
  submitting,
  newPassword
}) => (
  <Paper className={classes.root} elevation={1}>
    <Grid container spacing={24} className={classes.container}>
      <Grid item container xs={12} alignItems="flex-end">
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            {_t("Cambiar clave")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={24} className={classes.inputs}>
            <Grid item xs={12}>
              <InputPasswordField
                name="currentPassword"
                label={_t("Clave actual")}
                placeholder={_t("Ingrese la clave actual")}
                classes={classes}
                autoComplete="off"
                required
                withPasswordValidation={false}
                endAdornment={
                  <InputAdornment position="end" disableTypography={true}>
                    <IconButton
                      aria-label={`${
                        showPassword ? _t("Ocultar clave") : _t("Mostrar clave")
                      } `}
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InputPasswordField
                name="newPassword"
                label={_t("Clave nueva")}
                placeholder={_t("Ingrese tu nueva clave")}
                classes={classes}
                autoComplete="off"
                required
                endAdornment={
                  <InputAdornment position="end" disableTypography={true}>
                    <IconButton
                      aria-label={`${
                        showPassword ? _t("Ocultar clave") : _t("Mostrar clave")
                      } `}
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InputPasswordField
                name="newPasswordRepeated"
                label={_t("Repita la nueva clave")}
                placeholder={_t("Repita la nueva clave")}
                classes={classes}
                autoComplete="off"
                required
                disabled={newPassword ? false : true}
                withPasswordValidation={false}
                endAdornment={
                  <InputAdornment position="end" disableTypography={true}>
                    <IconButton
                      aria-label={`${
                        showPassword ? _t("Ocultar clave") : _t("Mostrar clave")
                      } `}
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => history.goBack()}
            >
              {_t("Volver")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pristine || submitting}
              className={classes.button}
            >
              {_t("Guardar")}
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  </Paper>
);

// UpdatePassword.propTypes = {
//   classes: PropTypes.object,
//   programa: PropTypes.object,
//   handleSubmit: PropTypes.func,
//   profileDefinitions: PropTypes.arrayOf(PropTypes.object),
//   puntos: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string,
//       puntos: PropTypes.number
//     })
//   ),
//   profile: PropTypes.object,
//   history: PropTypes.object,
//   pristine: PropTypes.bool,
//   submitting: PropTypes.bool,
//   onChangeImage: PropTypes.func
// };

// UpdatePassword.defaultProps = {
//   puntos: [],
//   initialValues: {}
// };

UpdatePassword.displayName = "UpdatePassword";

export default Enhancer(UpdatePassword);
