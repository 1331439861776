import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { buttonbarMap, getInitialValues } from "./actionsDefinitions";
import AuthService from "js/utils/AuthService";

class EnhancedTableToolbar extends Component {
  state = { ...getInitialValues() };

  closeDialog = () =>
    this.setState((state, props) => {
      return { ...getInitialValues() };
    });

  openDialog = button => () =>
    this.setState((state, props) => {
      return { [`dialog${button}`]: true };
    });

  render() {
    const {
      buttons,
      classes,
      total,
      filters,
      onTableChange,
      selected
    } = this.props;
    return (
      <Fragment>
        <Grid container spacing={24} className={classes.bar}>
          <Grid item xs={12} className={classes.row}>
            {buttons.map(
              button =>
                AuthService.hasPerms(buttonbarMap.get(button).perms) && (
                  <Button
                    key={buttonbarMap.get(button).text}
                    disabled={this.state.sending || !!selected}
                    variant="contained"
                    color="primary"
                    onClick={this.openDialog(buttonbarMap.get(button).key)}
                    className={classes.button}
                  >
                    {`${buttonbarMap.get(button).text} (${total})`}
                  </Button>
                )
            )}
            {buttons.map(button => {
              const Component = buttonbarMap.get(button).component;
              return (
                AuthService.hasPerms(buttonbarMap.get(button).perms) &&
                this.state[`dialog${buttonbarMap.get(button).key}`] && (
                  <Component
                    open
                    handleClose={this.closeDialog}
                    classes={classes}
                    filters={filters}
                    onTableChange={onTableChange}
                    key={`dialog-${buttonbarMap.get(button).key}`}
                    totalCanjes={total}
                  />
                )
              );
            })}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default EnhancedTableToolbar;
