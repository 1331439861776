import React, { PureComponent } from "react";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { ListofChildrenField } from "./index.js";
import IdiomaGraphQL from "js/graphql/resolvers/idioma.resolver";

import { Select } from "redux-form-material-ui";
import { styles } from "js/components/Shared/Form/styles.js";
import { required } from "js/components/Shared/Form/validations.js";
import { isNil } from "lodash";

class Idioma extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      idiomas: []
    };
  }

  componentWillReceiveProps(props) {
    const { idPrograma } = props;
    idPrograma && this.fetchIdiomas(idPrograma);
  }

  async fetchIdiomas(idPrograma) {
    const { client } = this.props;
    await client
      .query({
        query: IdiomaGraphQL.queries.getIdiomasByProgramaId,
        variables: { idPrograma: parseInt(idPrograma, 10) },
        fetchPolicy: "cache-first",
        errorPolicy: "all"
      })
      .then(res => {
        this.setState({
          idiomas: res.data.getProgramas.itemsPagina[0].idiomas
        });
      });
  }

  render() {
    const { classes, disabled, idPrograma } = this.props;
    const { idiomas } = this.state;
    return (
      <ListofChildrenField
        name="idIdioma"
        label="Idioma default"
        component={Select}
        className={classes ? classes.field : styles.field}
        disabled={isNil(idPrograma) || disabled}
        required={required}
      >
        {!isNil(idPrograma) &&
          idiomas.map((i, index) => (
            <MenuItem key={index} value={i.idioma._id}>
              {i.idioma.nombre} ({i.etiqueta})
            </MenuItem>
          ))}
      </ListofChildrenField>
    );
  }
}

export default withApollo(withRouter(withStyles(styles)(Idioma)));
