import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TableChartIcon from "@material-ui/icons/TableChartOutlined";
import UploadIcon from "@material-ui/icons/CloudUpload";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import AuthService from "js/utils/AuthService";
import { get } from "lodash";
import { Mutation } from "react-apollo";
import { s3Url, exportURL } from "js/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogImportGrupoSKU from "js/pages/Unilever/Dialogs/DialogImportGrupoSKU";
import DialogImportGrupoProductos from "js/pages/Admin/Programa/Campana/Dialogs/DialogImportGrupoProductos";
import DialogUploadPrizes from "js/pages/Admin/Canje/Dialogs/dialogUploadPrizes";
import SimpleModal from "../Modal/modal";
import { _t } from "js/utils/TranslationService";
import _ from "lodash";

const style = {
  display: "flex",
  flex: 1,
  flexWrap: "wrap"
};

const style1 = {
  display: "flex",
  flex: 1,
  alignItems: "center"
};

const style2 = {
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-end"
};

const actionTooltip = {
  margin: 0
};
const actionButton = {
  padding: "6px 16px",
  minWidth: "fit-content",
  minHeight: "fit-content",
  fontSize: "0.85 rem"
};

class header extends PureComponent {
  // shouldComponentUpdate = () => true;
  state = { loading: false, dialogOpen: false };

  render() {
    const { classes, history, filter, values, refetch } = this.props;
    const { loading } = this.state;
    let queryString = "";

    if (filter) {
      queryString = Object.keys(filter)
        .map(
          key =>
            Array.isArray(filter[key])
              ? filter[key].map(v => `${key}[]=${v}`).join("&")
              : key + "=" + filter[key]
        )
        .join("&");
    }
    return (
      <div style={style}>
        <div style={style1}>
          <Typography gutterBottom variant="h5">
            {typeof values.titleTranslation !== "undefined" &&
            !values.titleTranslation
              ? values.title
              : _t(values.title) || _t("Listado")}
          </Typography>
          {values.btnsNextTitle &&
            // AuthService.hasPerms(values.btnNextTitle.perms) &&
            values.btnsNextTitle.map((btnNextTitle, i) => {
              if (AuthService.hasPerms(btnNextTitle.perms)) {
                return (
                  <React.Fragment key={i}>{btnNextTitle.btn}</React.Fragment>
                );
              }
            })}
        </div>
        <div style={style2}>
          {loading && (
            <CircularProgress
              className={classes.progress}
              size={20}
              color="primary"
            />
          )}
          {values.import &&
            values.import.model &&
            AuthService.hasPerms(values.import.perms) &&
            this.getImportModel({ classes, value: values.import })}
          {values.importWithDialog &&
            values.importWithDialog.model &&
            AuthService.hasPerms(values.importWithDialog.perms) &&
            this.getImportModel({ classes, value: values.importWithDialog })}
          {values.import &&
            AuthService.hasPerms(values.import.perms) &&
            this.getImport({ classes, value: values.import, refetch })}
          {values.importWithDialog &&
            values.importWithDialog.dialog &&
            AuthService.hasPerms(values.importWithDialog.perms) &&
            this.getImportWithDialog({
              classes,
              value: values.importWithDialog,
              refetch
            })}
          {values.export &&
            AuthService.hasPerms(values.export.perms) &&
            values.export.path !== "usuarios" &&
            values.export.path !== "movimientos/usuario" &&
            values.export.path !== "movimientos/programa" &&
            this.getExportTooltip({
              classes,
              value: values.export,
              queryString,
              filter
            })}
          {values.export &&
            AuthService.hasPerms(values.export.perms) &&
            values.export.path === "usuarios" &&
            this.getExportTooltipModal({ classes, value: values.export })}

          {values.export &&
            AuthService.hasPerms(values.export.perms) &&
            values.export.path === "movimientos/usuario" &&
            this.getExportTooltipModal({ classes, value: values.export })}

          {values.export &&
            AuthService.hasPerms(values.export.perms) &&
            values.export.path === "movimientos/programa" &&
            this.getExportTooltipModal({ classes, value: values.export })}

          {values.new &&
            AuthService.hasPerms(values.new.perms) && (
              <Button
                style={actionButton}
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                onClick={e =>
                  values.new.path
                    ? history.push(values.new.path)
                    : history.push(window.location.pathname + "/new")
                }
              >
                {_t(get(values, "new.title", "Nuevo"))}
              </Button>
            )}
          {values.others &&
            values.others.map(
              other =>
                AuthService.hasPerms(other.perms)
                  ? this.typeToFunction({
                      classes,
                      value: other,
                      refetch,
                      queryString
                    })
                  : ""
            )}
        </div>
      </div>
    );
  }

  typeToFunction({ classes, value, refetch, queryString }) {
    switch (value.type) {
      case "import":
        return (
          <Fragment>
            {this.getImportModel({ classes, value })}
            {this.getImport({ classes, value, refetch })}
          </Fragment>
        );
      case "export":
        return (
          <Fragment>
            {this.getExportTooltipModal({
              classes,
              value,
              queryString,
              refetch
            })}
            {this.getExportTooltip({ classes, value, queryString })}
          </Fragment>
        );
      default:
        return value.component({ classes, refetch, queryString });
    }
  }

  getImportModel({ classes, value }) {
    return (
      <div>
        <Tooltip
          title={
            value.modelTooltip
              ? value.modelTooltip
              : "Modelo de importación CSV"
          }
          style={actionTooltip}
        >
          <IconButton
            className={classes.button}
            onClick={e => (window.location.href = `${s3Url}${value.model}`)}
          >
            <TableChartIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  getImport({ classes, value, refetch }) {
    let ids = {};

    if (value.variables) {
      if (value.variables.idPrograma) {
        ids.idPrograma = value.variables.idPrograma;
      }

      if (value.variables.idCampana) {
        ids.idCampana = value.variables.idCampana;
      }

      if (value.variables.nombreGrupoSKU) {
        ids.nombre = value.variables.nombreGrupoSKU;
      }
    }

    return (
      <div>
        <Tooltip title={value.tooltip || "Importar"} style={actionTooltip}>
          <IconButton
            variant="outlined"
            size="large"
            className={classes.button}
            onClick={() =>
              document.getElementById(value.id || "upload").click()
            }
          >
            <UploadIcon />
          </IconButton>
        </Tooltip>
        <Mutation mutation={value.action}>
          {(mutate, { loading }) => {
            this.setState({ loading });
            return (
              <input
                id={`${value.id || "upload"}`}
                type="file"
                style={{ display: "none" }}
                required
                onChange={({
                  target: {
                    validity,
                    files: [file]
                  }
                }) =>
                  validity.valid &&
                  mutate({
                    variables: {
                      file,
                      ...ids
                    }
                  })
                    .then(res => {
                      refetch();
                      this.props.openNotification(
                        "El archivo se procesó correctamente"
                      );
                    })
                    .catch(res =>
                      this.props.openNotification(
                        'Hubo un error al procesar el archivo. Ver los detalles en "Logs"'
                      )
                    )
                    .finally(
                      () =>
                        (document.getElementById(value.id || "upload").value =
                          "")
                    )
                }
              />
            );
          }}
        </Mutation>
      </div>
    );
  }

  // Se puede usar este tipo para importar un archivo y abrir un MODAL especial para mandar más datos con el file.
  // El request se tiene que hacer en el mismo dialog.
  getImportWithDialog({ classes, value, refetch }) {
    const handleClickOpen = () => {
      this.setState({ dialogOpen: true });
    };

    const handleClose = value => {
      this.setState({ dialogOpen: false });
    };

    return (
      <div>
        <Tooltip title={value.tooltip || "Importar"} style={actionTooltip}>
          <IconButton
            variant="outlined"
            size="large"
            className={classes.button}
            onClick={handleClickOpen}
          >
            <UploadIcon />
          </IconButton>
        </Tooltip>
        {value.dialog === "GrupoSKU" &&
          this.state.dialogOpen && (
            <DialogImportGrupoSKU
              open={this.state.dialogOpen}
              handleClose={handleClose}
              refetch={refetch}
            />
          )}
        {value.dialog === "GrupoProductos" &&
          this.state.dialogOpen && (
            <DialogImportGrupoProductos
              open={this.state.dialogOpen}
              handleClose={handleClose}
              refetch={refetch}
            />
          )}
        {value.dialog === "importCodigos" &&
          this.state.dialogOpen && (
            <SimpleModal
              open={this.state.dialogOpen}
              handleClose={handleClose}
              refetch={refetch}
              proceso={value.dialog}
            />
          )}
        {value.dialog === "importarPremiosCanjes" &&
          this.state.dialogOpen && (
            <DialogUploadPrizes
              open={this.state.dialogOpen}
              handleClose={handleClose}
              refetch={refetch}
              proceso={value.dialog}
            />
          )}
      </div>
    );
  }

  getExportTooltip({ classes, value, queryString, filter }) {
    return (
      <Tooltip title={value.tooltip || "Exportar"} style={actionTooltip}>
        <IconButton
          variant="outlined"
          size="large"
          className={classes.button}
          onClick={() => {
            if (_.isEmpty(filter)) {
              this.props.openNotification(
                "Se requiere aplicar filtros antes de exportar"
              );
              return;
            }
            window.open(`${exportURL}/${value.path}?${queryString}`, "_blank");
          }}
          download
        >
          {value.title}
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    );
  }

  getExportTooltipModal({ classes, value, refetch }) {
    const handleClickOpen = () => {
      this.setState({ dialogOpen: true });
    };
    const handleClose = value => {
      this.setState({ dialogOpen: false });
    };

    return (
      <div>
        <Tooltip title={value.tooltip || "Exportar"} style={actionTooltip}>
          <IconButton
            variant="outlined"
            size="large"
            className={classes.button}
            onClick={handleClickOpen}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
        {(value.path === "usuarios" ||
          value.path === "movimientos/usuario" ||
          value.path === "movimientos/programa") &&
          this.state.dialogOpen && (
            <SimpleModal
              open={this.state.dialogOpen}
              handleClose={handleClose}
              refetch={refetch}
              proceso={value.path}
            />
          )}
      </div>
    );
  }
}

header.propTypes = {
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object,
  values: PropTypes.shape({
    title: PropTypes.string,
    btnsNextTitle: PropTypes.any,
    new: PropTypes.shape({
      title: PropTypes.string,
      perms: PropTypes.array,
      path: PropTypes.string
    }),
    import: PropTypes.shape({
      title: PropTypes.string,
      model: PropTypes.string,
      perms: PropTypes.array,
      action: PropTypes.object,
      variables: PropTypes.object,
      tooltip: PropTypes.string,
      modelTooltip: PropTypes.string
    }),
    export: PropTypes.shape({
      title: PropTypes.string,
      perms: PropTypes.array,
      path: PropTypes.string,
      tooltip: PropTypes.string
    }),
    importWithDialog: PropTypes.shape({
      dialog: PropTypes.string,
      perms: PropTypes.array,
      tooltip: PropTypes.string,
      model: PropTypes.string
    }),
    others: PropTypes.array
  })
};

export default header;
