import { connect } from "react-redux";
import LikeIcon from "js/components/Shared/LikeIcon";
import productoActions from "../actions";

const mapStateToProps = state => ({
  favorito: state.producto.favorito,
  idEleccionProducto: state.producto.idEleccionProducto
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  productoActions: productoActions({ dispatch })
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  favorito: stateProps.favorito,
  onClick: dispatchProps.productoActions.toggleFavorito(
    stateProps.idEleccionProducto
  ),
  color: ownProps.color
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(LikeIcon);
