import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Query } from "react-apollo";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import ProgramaGraphQL from "js/graphql/resolvers/programa.resolver.js";
import { ADMINISTRACION, ESTADO_CANJE, TIPO_PRODUCTO } from "js/constants";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";
import {
  TypeAhead,
  ProgramaField,
  ProductosField,
  UsuarioField,
  MultipleSelect
} from "js/components/Shared/Form/Fields";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Grid } from "@material-ui/core";
import { updateQueryParamsAsync, initialState } from "../utils";

class advancedFilter extends Component {
  state = {
    ...initialState
  };

  updateQueryParamsMultiple = key => event =>
    this.setState(
      updateQueryParamsAsync({ key, value: event.target.value }),
      this.props.submitValue(event)
    );

  updateQueryParamsSwitch = key => event =>
    this.setState(
      updateQueryParamsAsync({ key, value: event.target.checked }),
      this.props.submitValue({
        target: { name: key, value: event.target.checked }
      })
    );

  updateAutocompleteQueryParams = key => select => {
    if (key === "idUsuario") {
      const usuario = select.length ? select[0] : { value: null };
      this.setState(
        updateQueryParamsAsync({ key, value: usuario.value }),
        this.props.submitValue({ target: { name: key, value: usuario.value } })
      );
      return;
    }
    if (key === "idProducto") {
      const producto = select.length ? select[0] : { value: null };
      this.setState(
        updateQueryParamsAsync({ key, value: producto.value }),
        this.props.submitValue({ target: { name: key, value: producto.value } })
      );
      return;
    }
    if (key === "idsProgramas") {
      const value = select.map(option => option.value);
      this.setState((state, props) => ({
        ...initialState,
        withProgram: true
      }));
      this.setState(
        updateQueryParamsAsync({ key, value }),
        this.props.submitValue({ target: { name: key, value } })
      );
      return;
    }
    this.setState(
      updateQueryParamsAsync({ key, value: select.value }),
      this.props.submitValue({ target: { name: key, value: select.value } })
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;
    const prevValue = prevProps.value;
    if (!value.idUsuario && prevValue && prevValue.idUsuario)
      this.updateAutocompleteQueryParams("idUsuario")({ value: undefined });
    if (!value.idProducto && prevValue && prevValue.idProducto)
      this.updateAutocompleteQueryParams("idProducto")({ value: undefined });
  }

  componentDidMount = () => {
    const {
      match: {
        params: { id: idPrograma }
      }
    } = this.props;

    if (idPrograma) {
      this.setState({
        idsProgramas: [parseInt(idPrograma)],
        withProgram: true
      });
    }
  };

  render() {
    const {
      value,
      submitValue,
      search,
      classes,
      match: {
        params: { id }
      }
    } = this.props;

    const queryParams = {
      idsProgramas: get(this.state, "idsProgramas", []),
      withProgram: get(this.state, "withProgram", false)
    };

    return (
      <Query
        query={ProgramaGraphQL.queries.getPaginaCanjes}
        variables={{ ...queryParams }}
        fetchPolicy="network-only"
        errorPolicy="all"
      >
        {({ loading, error, data }) => {
          return (
            <Grid container spacing={16}>
              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="tipo-programa-simple"
                    htmlFor="tipo-programa-simple"
                  >
                    Tipo Programa
                  </InputLabel>
                  <Select
                    value={value["tipoPrograma"] || ""}
                    onChange={e => submitValue(e)}
                    inputProps={{
                      name: "tipoPrograma",
                      id: "tipoPrograma"
                    }}
                  >
                    <MenuItem value={undefined}>Todos</MenuItem>
                    <MenuItem value={"CLIENTE"}>Cliente</MenuItem>
                    <MenuItem value={"EX-CLIENTE"}>Ex Cliente</MenuItem>
                    <MenuItem value={"DEMO"}>Demo</MenuItem>
                    <MenuItem value={"PRE-LANZAMIENTO"}>
                      Pre Lanzamiento
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <ProgramaField
                    disabled={!!id || !value["tipoPrograma"]}
                    noOptionsMessage="No hay programas"
                    onChange={this.updateAutocompleteQueryParams(
                      "idsProgramas"
                    )}
                    value={id ? id : value.idsProgramas}
                    multiple={!id}
                    search={search}
                    filterByType={value["tipoPrograma"]}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <UsuarioField
                    async
                    disabled={!value.idsProgramas && !id && !value.idPrograma}
                    idPrograma={
                      value.idsProgramas ? value.idsProgramas[0] : undefined
                    }
                    label={"Usuario"}
                    withTodos
                    noOptionsMessage="No hay usuarios"
                    placeholder={"Buscar por usuario"}
                    onChange={this.updateAutocompleteQueryParams("idUsuario")}
                    value={value["idUsuario"] || value.idUsuario}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <DatePicker
                    key={"fechaDesde"}
                    name={"fechaDesde"}
                    label="Desde (fecha del movimiento)"
                    input={{
                      onChange: date =>
                        submitValue({
                          target: {
                            name: "fechaDesde",
                            value: date.toISOString()
                          }
                        }),
                      value: value["fechaDesde"]
                        ? moment(value["fechaDesde"])
                        : null
                    }}
                    format={"DD/MM/YYYY"}
                    maxDate={moment()}
                    autoOk={true}
                    startOfDate
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <DatePicker
                    key={"fechaHasta"}
                    name={"fechaHasta"}
                    input={{
                      onChange: date =>
                        submitValue({
                          target: {
                            name: "fechaHasta",
                            value: date.toISOString()
                          }
                        }),
                      value: value["fechaHasta"]
                        ? moment(value["fechaHasta"])
                        : null
                    }}
                    label="Hasta (fecha del movimiento)"
                    dateFormat={"DD/MM/YYYY"}
                    maxDate={moment()}
                    autoOk={true}
                    endOfDate
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="ID Canje"
                    placeholder="ID Canje"
                    key={"idCanje"}
                    name={"idCanje"}
                    type="search"
                    value={value["idCanje"] || ""}
                    className={classes.formControl}
                    onChange={e => submitValue(e)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <MultipleSelect
                    label={"Estado"}
                    key={"estadoCanje"}
                    name={"estadoCanje"}
                    values={value["estadoCanje"] || []}
                    onChange={this.updateQueryParamsMultiple("estadoCanje")}
                    classes={{ formControl: classes.multiSelect }}
                    inputProps={{
                      name: "estadoCanje",
                      id: "estadoCanje"
                    }}
                    options={ESTADO_CANJE}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <TypeAhead
                    label="País"
                    noOptionsMessage="No hay países"
                    placeholder="Buscar por país"
                    onChange={this.updateAutocompleteQueryParams("idPais")}
                    path={{
                      path: "getMasterPaises",
                      value: "idPais",
                      label: "descripcion"
                    }}
                    suggestions={data}
                    value={value.idPais}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <TypeAhead
                    label="Proveedor"
                    noOptionsMessage="No hay proveedores"
                    placeholder="Buscar por proveedor"
                    onChange={this.updateAutocompleteQueryParams("idProveedor")}
                    path={{
                      path: "getProveedoresToFilter.itemsPagina",
                      value: "idProveedor",
                      label: "nombreFantasia"
                    }}
                    suggestions={data}
                    value={value.idProveedor}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="administracion-simple"
                    id="administracion-simple"
                  >
                    Administración
                  </InputLabel>
                  <Select
                    value={value["administracion"] || ""}
                    onChange={e => submitValue(e)}
                    inputProps={{
                      name: "administracion",
                      id: "administracion"
                    }}
                  >
                    <MenuItem value={undefined}>Todos</MenuItem>
                    {ADMINISTRACION.map((administracion, index) => (
                      <MenuItem key={index} value={administracion.value}>
                        {administracion.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="tipo-producto-simple"
                    id="tipo-producto-simple"
                  >
                    Tipo de producto
                  </InputLabel>
                  <Select
                    value={value["tipoProducto"] || ""}
                    onChange={e => submitValue(e)}
                    inputProps={{
                      name: "tipoProducto",
                      id: "tipoProducto"
                    }}
                  >
                    <MenuItem value={undefined}>Todos</MenuItem>
                    {TIPO_PRODUCTO.map((tipoProducto, index) => (
                      <MenuItem key={index} value={tipoProducto.value}>
                        {tipoProducto.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <ProductosField
                    async
                    idPrograma={
                      value.idsProgramas ? value.idsProgramas[0] : undefined
                    }
                    label={"Producto"}
                    withTodos
                    noOptionsMessage="No hay productos"
                    placeholder={"Buscar por producto"}
                    onChange={this.updateAutocompleteQueryParams("idProducto")}
                    value={value["idProducto"] || value.idProducto}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <TypeAhead
                    label="Factura"
                    noOptionsMessage="No hay facturas"
                    placeholder="Buscar por factura"
                    onChange={this.updateAutocompleteQueryParams("idFactura")}
                    path={{
                      path: ["getFacturas", "itemsPagina"],
                      value: "idFactura",
                      label: "numeroFactura"
                    }}
                    suggestions={data}
                    value={value.idFactura}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Lote"
                  placeholder="Lote"
                  key={"idLote"}
                  name={"idLote"}
                  type="search"
                  value={value["idLote"] || ""}
                  className={classes.formControl}
                  onChange={e => submitValue(e)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlLabel
                  control={
                    <Switch
                      key={"excluirCanje"}
                      name={"excluirCanje"}
                      checked={value["excluirCanje"] || false}
                      onChange={this.updateQueryParamsSwitch("excluirCanje")}
                      value={true}
                    />
                  }
                  label="Excluir canjes con lotes"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlLabel
                  control={
                    <Switch
                      key={"excluirFactura"}
                      name={"excluirFactura"}
                      checked={value["excluirFactura"] || false}
                      onChange={this.updateQueryParamsSwitch("excluirFactura")}
                      value={true}
                    />
                  }
                  label="Excluir canjes con factura"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlLabel
                  control={
                    <Switch
                      key={"excluirCanceladosYPausados"}
                      name={"excluirCanceladosYPausados"}
                      checked={value["excluirCanceladosYPausados"] || false}
                      onChange={this.updateQueryParamsSwitch(
                        "excluirCanceladosYPausados"
                      )}
                      value={true}
                    />
                  }
                  label="Excluir canjes cancelados y en pausa"
                />
              </Grid>
            </Grid>
          );
        }}
      </Query>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withRouter(withStyles(styles)(advancedFilter));
