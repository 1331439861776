import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/Remove";
import ExpandMore from "@material-ui/icons/Add";
import styles from "./styles";

class MenuItem extends React.Component {
  state = {
    open: false,
    aChildrenChecked: false
  };

  handleCollapse = () => {
    this.setState(state => ({ open: !state.open }));
  };

  findChecked = item =>
    !!item.checked || (item.children || []).filter(this.findChecked).length;

  componentWillReceiveProps = props => {
    const { children = [] } = props;
    const checked = children.filter(this.findChecked).length;
    if (!!checked) this.setState({ aChildrenChecked: true });
    else this.setState({ aChildrenChecked: false });
    if (checked === children.length)
      this.setState({ allChildrenChecked: true });
    else this.setState({ allChildrenChecked: false });
  };

  render() {
    const {
      onChecked,
      value,
      label,
      checked,
      children = [],
      level = 1
    } = this.props;
    const { aChildrenChecked, allChildrenChecked } = this.state;
    let tree = [];
    Object.assign(tree, this.props.tree || []);
    tree.push(value);
    return (
      <React.Fragment>
        <ListItem
          role={undefined}
          dense
          button
          key={value}
          style={{ paddingLeft: level > 1 ? 8 * (1 + level) : 8 }}
        >
          <Checkbox
            style={{ padding: 0 }}
            onClick={() => onChecked(this.props, tree)}
            checked={checked || aChildrenChecked}
            tabIndex={-1}
            disableRipple
            indeterminate={!checked && !allChildrenChecked && aChildrenChecked}
          />
          <ListItemText
            inset
            primary={label}
            onClick={() => onChecked(this.props, tree)}
          />
          {!!children.length ? (
            this.state.open ? (
              <ExpandLess
                style={{ color: "#757575" }}
                onClick={this.handleCollapse}
              />
            ) : (
              <ExpandMore
                style={{ color: "#757575" }}
                onClick={this.handleCollapse}
              />
            )
          ) : (
            ""
          )}
        </ListItem>
        {!!children.length && (
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            {children.map(i => (
              <MenuItem
                {...i}
                checked={i.checked || checked}
                onChecked={onChecked}
                level={level + 1}
                tree={tree}
              />
            ))}
          </Collapse>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MenuItem);
