import React, { PureComponent, Fragment } from "react";
import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/proveedor.resolver";
import ProductoQraphQL from "js/graphql/resolvers/producto.resolver";
import advancedFilter from "js/components/TablaSucursal/advancedFilter.js";
import get from "lodash/get";
import { viewAction, editAction } from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";
import ArrowBack from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";

let defaultHeader = {
  title: "Sucursales de Producto",
  new: {
    title: "Nuevo",
    perms: [Roles.PRODUCTO_W]
  }
};
const columnData = [
  { id: "orden", label: "Prioridad" },
  { id: "nombre", label: "Nombre" },
  { id: "direccion", label: "Dirección", removeSort: true },
  { id: "telefono", label: "Teléfono", removeSort: true },
  {
    id: "estado",
    label: "Estado",
    removeSort: true,
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "asc", orderBy: "orden" };

class Tabla extends PureComponent {
  async componentDidMount() {
    const {
      client,
      match: {
        params: { idProducto }
      },
      history
    } = this.props;
    // const data = await client.query({
    //   query: AppQraphQL.queries.getProveedorById,
    //   fetchPolicy: "network-only",
    //   errorPolicy: "all",
    //   variables: { idProveedor }
    // });
    const dataProducto = await client.query({
      query: ProductoQraphQL.queries.getProductoById,
      fetchPolicy: "network-only",
      errorPolicy: "all",
      variables: { productoLike: { idProducto } }
    });
    const nombreProducto = get(
      dataProducto,
      "data.getProductos.itemsPagina[0].nombre",
      null
    );
    if (!nombreProducto) return;
    defaultHeader.title = (
      <Fragment>
        <IconButton onClick={() => history.push("/admin/producto")}>
          <ArrowBack />
        </IconButton>
        {nombreProducto + " | Sucursales"}
      </Fragment>
    );
    this.forceUpdate();
  }
  render() {
    const view = params => ({
      ...viewAction({
        ...params,
        history: this.props.history
      })
    });
    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.PROVEEDOR_W]
      })
    });
    return (
      <Table
        query={AppQraphQL.queries.getPaginaSucursalesProducto}
        defaultHeader={defaultHeader}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getSucursalesProducto}
        actionsCustom={[view, edit]}
        storage="FiltroSucursalProducto"
      />
    );
  }
}

export default Tabla;
