import React from "react";
import PropTypes from "prop-types";

import Base from "./Base";
import Video from "./Video";
import { SliderCustom as Slider } from "js/components/Shared/Slider";
import Iframe from "./Iframe";

const Banner = props => {
  const { classes, contenido } = props;
  const Componentes = [Base, Video, Slider, Iframe];
  const BannerContenido =
    Componentes.find(c => c.displayName === contenido.tipo) || Base;
  return (
    <div className={classes.banner}>
      <BannerContenido {...props} />
    </div>
  );
};

Banner.propTypes = {
  classes: PropTypes.object.isRequired
};

export default Banner;
