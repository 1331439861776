import React, { Component } from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import client from "js/App/client.graphql.js";
import AccessQraphQL from "js/graphql/resolvers/access.resolver";
import IdiomasList from "js/components/Shared/IdiomasList";
import { _t } from "js/utils/TranslationService";
import { Link } from "react-router-dom";

export class EmailVerificado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailVerificado: false,
      emailAntesVerificado: false
    };
  }

  async componentDidMount() {
    console.log(this.props);
    const {
      location: { search }
    } = this.props;
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get("token");
    await client
      .mutate({
        mutation: AccessQraphQL.mutations.activarUsuario,
        variables: {
          token: token
        }
      })
      .then(res => {
        if (res.data) {
          if (res.data.activarUsuario) {
            this.setState({
              emailVerificado: true
            });
          } else {
            this.setState({
              emailAntesVerificado: true
            });
          }
        }
      });
  }

  render() {
    const { classes, logo } = this.props;
    const { emailVerificado, emailAntesVerificado } = this.state;

    return (
      <Paper className={classes.root} elevation={1}>
        <div className={classes.idiomaPosition}>
          <IdiomasList />
        </div>
        <img alt="Logo" src={logo} className={classes.logo} />
        {emailVerificado && (
          <Typography variant="caption" gutterBottom align="right">
            {_t(
              "Su email ha sido verificado correctamente. Inicia sesión para entrar al programa"
            )}
          </Typography>
        )}
        {emailAntesVerificado && (
          <Typography variant="caption" gutterBottom align="right">
            {_t(
              "Su email ya ha sido verificado anteriormente. Inicia sesión para entrar al programa"
            )}
          </Typography>
        )}
        <Grid container justify="space-between">
          <Typography variant="caption" gutterBottom align="right">
            <Link to="/signin">{_t("Iniciar sesión")}</Link>
          </Typography>
        </Grid>
      </Paper>
    );
  }
}

EmailVerificado.propTypes = {
  programa: PropTypes.object
};

EmailVerificado.defaultProps = {};

export default EmailVerificado;
