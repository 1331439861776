import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/Form/styles.js";
import { reduxForm, formValueSelector, change, getFormMeta } from "redux-form";
import { Grid, Button, Typography } from "@material-ui/core";
import {
  InputTextField,
  InputNumberField,
  ProgramaField,
  ListField
} from "js/components/Shared/Form/Fields";

class Form extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      id,
      documento,
      initialValues
    } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {initialValues.nombre ? "Editar cliente" : "Nuevo cliente"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form className={classes.container} onSubmit={handleSubmit}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors && errors.map(e => <li>{e.message}</li>)}
                </ul>
              </Grid>
            </Grid>

            <Grid container spacing={16}>
              <Grid item xs={12} md={4}>
                <ProgramaField disabled={true} required redux />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputTextField name="nombre" label="Nombre" required />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputNumberField name="documento" label="Documento" required />
              </Grid>

              <Grid item xs={12} md={4}>
                <ListField
                  name="estado"
                  label="Estado"
                  options={[
                    { label: "Activo", value: "ACTIVO" },
                    { label: "Inactivo", value: "INACTIVO" }
                  ]}
                  disabled={disabled || documento === "new"}
                  required
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputTextField name="email" label="Email" />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputTextField name="telefono" label="Teléfono" />
              </Grid>

              <Grid className={classes.gridButton} item xs={12}>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  className={classes.button}
                  style={{ marginRight: 20 }}
                  onClick={() =>
                    history.push(`/admin/programa/${id}/clientes-retail`)
                  }
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  disabled={pristine || submitting}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

Form = reduxForm({
  form: "formClienteRetail",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formClienteRetail");

Form = connect(
  state => ({
    formMeta: getFormMeta("formClienteRetail")(state),
    idProgramaField: selector(state, "idPrograma")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formClienteRetail", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
