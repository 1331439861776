export default theme => ({
  button: {
    margin: theme.spacing.unit
  },
  userMenu: {
    borderRadius: 0
  },
  input: {
    display: "none"
  }
});
