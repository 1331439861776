/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from "react";
import PropTypes from "prop-types";
import Select, { createFilter } from "react-select";

import {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
} from "../Autocomplete/AutocompleteComponents";

class LabeledAutocomplete extends React.Component {
  render() {
    const components = {
      Control,
      Menu,
      MultiValue,
      NoOptionsMessage,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer
    };

    const {
      classes,
      theme,
      suggestions,
      isDisabled,
      disabled,
      placeholder,
      noOptionsMessage,
      onChange,
      value,
      label,
      noLabel = false,
      variant,
      error
    } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit",
          height: "auto !important"
        }
      })
    };

    const filterConfig = {
      ignoreCase: true,
      ignoreAccents: true,
      trim: true,
      matchFrom: "any"
    };
    return (
      <div className={classes.root}>
        <Select
          label={label}
          noOptionsMessage={noOptionsMessage}
          textFieldProps={{
            label: !noLabel ? label : undefined,
            InputLabelProps: {
              shrink: true,
              classes: { root: classes.label }
            },
            error: !!error,
            disabled,
            variant
          }}
          isDisabled={isDisabled}
          classes={classes}
          styles={selectStyles}
          options={suggestions}
          components={components}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          filterOption={createFilter(filterConfig)}
        />
      </div>
    );
  }
}

LabeledAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  value: PropTypes.object
};

LabeledAutocomplete.defaultProps = {
  isDisabled: true,
  value: { value: undefined, label: "" }
};

export default LabeledAutocomplete;
