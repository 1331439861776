import React, { Fragment, Component } from "react";

import TablaUsuariosCuentaCorriente from "./TablaUsuariosCuentaCorriente";

class UsuariosCuentaCorriente extends Component {
  // componentWillMount() {
  //   this.props.history.replace({ pathname: "/admin/cc-usuarios" });
  // }

  render() {
    return (
      <Fragment>
        <TablaUsuariosCuentaCorriente {...this.props} />
      </Fragment>
    );
  }
}

export default UsuariosCuentaCorriente;
