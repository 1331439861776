import React from "react";
// import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import classNames from "classnames";

const MultiValue = props => {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={
        <CancelIcon
          {...props.removeProps}
          className={props.selectProps.classes.cancelChip}
        />
      }
    />
  );
};

export default MultiValue;
