import { mergeWith } from "lodash";
import rename from "deep-rename-keys";

export const valuesMap = new Map();

valuesMap.set("idRubro", "id");
valuesMap.set("rubroCantidad", "cantidad");
valuesMap.set("subrubros", "children");
valuesMap.set("idSubrubro", "id");
valuesMap.set("subrubroCantidad", "cantidad");

valuesMap.set("idZona", "id");
valuesMap.set("zonaCantidad", "cantidad");
valuesMap.set("localidades", "children");
valuesMap.set("idLocalidad", "id");
valuesMap.set("localidadCantidad", "cantidad");

export const keysMap = new Map();

keysMap.set("idZona", "zonas");
keysMap.set("idRubro", "rubros");
keysMap.set("idSubrubro", "subrubros");
keysMap.set("idLocalidad", "localidades");

const customizer = (objValue, srcValue, key, object, source) => {
  if (keysMap.has(key)) {
    object[`request_id`] = keysMap.get(key);
  }
};

export const getTotal = (datos, key) =>
  datos.reduce((acc, val) => acc + val[key], 0);

export default data => {
  const formattedData = mergeWith({}, data, customizer);
  const parsedData = rename(
    formattedData,
    key => (valuesMap.has(key) ? valuesMap.get(key) : key)
  );
  return parsedData;
};
