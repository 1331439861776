import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { Beneficio } = Fragmentos;

export const getPaginaBeneficios = gql`
  query getBeneficios(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: BeneficioColumn!
    $idPrograma: Int
    $idBeneficio: Int
    $nombre: String
    $idTipo: ID
    $estado: Estado
    $idSegmento: Int
    $idRubro: Int
    $idSubrubro: Int
    $idPais: Int
    $idZona: Int
    $idLocalidad: Int
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getBeneficios(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      beneficioOrder: { column: $orderBy, order: $order }
      beneficioLike: {
        idBeneficio: $idBeneficio
        idPrograma: $idPrograma
        nombre: $nombre
        idTipo: $idTipo
        estado: $estado
        idSegmento: $idSegmento
        idRubro: $idRubro
        idSubrubro: $idSubrubro
        idPais: $idPais
        idZona: $idZona
        idLocalidad: $idLocalidad
      }
      beneficioRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...beneficio
      }
    }
  }
  ${Beneficio.fragments.beneficio}
`;

export const getBeneficioById = gql`
  query getBeneficioById($idBeneficio: Int, $idPrograma: Int) {
    getBeneficio: getBeneficios(beneficioLike: { idBeneficio: $idBeneficio }) {
      itemsPagina {
        ...beneficio
      }
    }
    getPaises(paisLike: { idPrograma: $idPrograma, estado: ACTIVO }) {
      idPais
      label: descripcion
      value: idPais
      estado
      children: zonas {
        idZona
        label: descripcion
        value: idZona
        estado
        children: localidades {
          idLocalidad
          label: descripcion
          value: idLocalidad
          estado
        }
      }
    }
    getRubros(rubroLike: { idPrograma: $idPrograma, estado: ACTIVO }) {
      idRubro
      label: descripcion
      value: idRubro
      estado
      children: subrubros {
        idSubrubro
        label: descripcion
        value: idSubrubro
        estado
      }
    }
    getSegmentos(segmentoLike: { idPrograma: $idPrograma, estado: ACTIVO }) {
      idSegmento
      label: nombre
      value: idSegmento
      estado
    }
  }
  ${Beneficio.fragments.beneficio}
`;

export const getBeneficiosInput = gql`
  query getBeneficios($idPrograma: Int) {
    getBeneficios(beneficioLike: { idPrograma: $idPrograma }) {
      itemsPagina {
        value: idBeneficio
        label: nombre
      }
    }
  }
`;

export const createBeneficio = gql`
  mutation createBeneficio($beneficio: BeneficioCreate!) {
    createBeneficio(beneficio: $beneficio) {
      idBeneficio
    }
  }
`;

export const updateBeneficio = gql`
  mutation updateBeneficio($beneficio: BeneficioUpdate!) {
    updateBeneficio(beneficio: $beneficio) {
      idBeneficio
    }
  }
`;

export const getBeneficioTipos = gql`
  query getBeneficioTipos {
    getBeneficioTipos {
      value: _id
      label: tipo
      descripcion
    }
  }
`;

export const createBeneficioTipo = gql`
  mutation createBeneficioTipo($tipo: BeneficioTipoCreate!) {
    createBeneficioTipo(tipo: $tipo) {
      _id
      tipo
      descripcion
    }
  }
`;

export const getPaginaSucursalesBeneficio = gql`
  query getBeneficioSucursales(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: BeneficioSucursalColumn!
    $idBeneficio: Int
    $idSucursal: Int
    $nombre: String
    $estado: Estado
    $idPais: Int
    $idZona: Int
    $idLocalidad: Int
    $direccion: String
  ) {
    getBeneficioSucursales(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      sucursalOrder: { column: $orderBy, order: $order }
      sucursalLike: {
        idSucursal: $idSucursal
        idBeneficio: $idBeneficio
        nombre: $nombre
        estado: $estado
        idPais: $idPais
        idZona: $idZona
        idLocalidad: $idLocalidad
        direccion: $direccion
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...sucursal
      }
    }
  }
  ${Beneficio.fragments.sucursal}
`;

export const getSucursalBeneficioById = gql`
  query getSucursalBeneficioById($idSucursal: Int) {
    getBeneficioSucursales(sucursalLike: { idSucursal: $idSucursal }) {
      itemsPagina {
        ...sucursal
      }
    }
  }
  ${Beneficio.fragments.sucursal}
`;

export const createBeneficioSucursal = gql`
  mutation createBeneficioSucursal($sucursal: BeneficioSucursalCreate!) {
    createBeneficioSucursal(sucursal: $sucursal) {
      idSucursal
    }
  }
`;

export const updateBeneficioSucursal = gql`
  mutation updateBeneficioSucursal($sucursal: BeneficioSucursalUpdate!) {
    updateBeneficioSucursal(sucursal: $sucursal) {
      idSucursal
    }
  }
`;

export const getSucursalesBeneficioInput = gql`
  query getBeneficioSucursales($sucursalLike: BeneficioSucursalSearch) {
    getBeneficioSucursales(sucursalLike: $sucursalLike) {
      itemsPagina {
        value: _id
        label: nombre
      }
    }
  }
`;

export const getCatalogoBeneficios = gql`
  query getCatalogoBeneficios(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: BeneficioColumn!
    $beneficioLike: BeneficioCatalogoSearch
  ) {
    getCatalogoBeneficios(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      beneficioOrder: { column: $orderBy, order: $order }
      beneficioLike: $beneficioLike
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...beneficio
      }
    }
  }
  ${Beneficio.fragments.beneficio}
`;

export const getCatalogoBeneficiosPublico = gql`
  query getCatalogoBeneficiosPublico(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: BeneficioColumn!
    $beneficioLike: BeneficioCatalogoSearch
  ) {
    getCatalogoBeneficiosPublico(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      beneficioOrder: { column: $orderBy, order: $order }
      beneficioLike: $beneficioLike
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...beneficio
      }
    }
  }
  ${Beneficio.fragments.beneficio}
`;

export const getCatalogoBeneficiosInput = gql`
  query getCatalogoBeneficios($idPrograma: Int) {
    getCatalogoBeneficios(beneficioLike: { idPrograma: $idPrograma }) {
      itemsPagina {
        value: idBeneficio
        label: nombre
      }
    }
  }
`;

export const getCatalogoBeneficioById = gql`
  query getCatalogoBeneficios($beneficioLike: BeneficioCatalogoSearch) {
    getCatalogoBeneficios(beneficioLike: $beneficioLike) {
      itemsPagina {
        ...beneficio
      }
    }
  }
  ${Beneficio.fragments.beneficio}
`;

export const getCatalogoBeneficioPublicoById = gql`
  query getCatalogoBeneficiosPublico($beneficioLike: BeneficioCatalogoSearch) {
    getCatalogoBeneficiosPublico(beneficioLike: $beneficioLike) {
      itemsPagina {
        ...beneficio
      }
    }
  }
  ${Beneficio.fragments.beneficio}
`;

export const getBeneficiosFilters = gql`
  query getBeneficiosFilters($idPrograma: Int, $idSegmento: Int) {
    getBeneficiosFilters(idPrograma: $idPrograma, idSegmento: $idSegmento) {
      rubros {
        idRubro
        descripcion
        rubroCantidad
        subrubros {
          idSubrubro
          descripcion
          subrubroCantidad
        }
      }
      zonas {
        idZona
        descripcion
        zonaCantidad
        localidades {
          idLocalidad
          descripcion
          localidadCantidad
        }
      }
    }
  }
`;

export const getBeneficiosFiltersPublico = gql`
  query getBeneficiosFiltersPublico($idPrograma: Int, $idSegmento: Int) {
    getBeneficiosFiltersPublico(
      idPrograma: $idPrograma
      idSegmento: $idSegmento
    ) {
      rubros {
        idRubro
        descripcion
        rubroCantidad
        subrubros {
          idSubrubro
          descripcion
          subrubroCantidad
        }
      }
      zonas {
        idZona
        descripcion
        zonaCantidad
        localidades {
          idLocalidad
          descripcion
          localidadCantidad
        }
      }
    }
  }
`;

export const toggleFavoritoBeneficio = gql`
  mutation toggleFavoritoBeneficio($idBeneficio: Int!) {
    toggleFavoritoBeneficio(idBeneficio: $idBeneficio)
  }
`;

export const solicitarBeneficio = gql`
  mutation solicitarBeneficio($solicitud: BeneficioSolicitudCreate!) {
    solicitarBeneficio(solicitud: $solicitud) {
      idSolicitud
    }
  }
`;

export const getBeneficiosSolicitudes = gql`
  query getBeneficiosSolicitudes(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: BeneficioSolicitudColumn!
    $idPrograma: Int
    $idBeneficio: Int
    $idTipo: ID
    $idUsuario: Int
    $fechaDesde: Date
    $fechaHasta: Date
  ) {
    getBeneficiosSolicitudes(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      solicitudOrder: { column: $orderBy, order: $order }
      solicitudRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
      solicitudLike: {
        idPrograma: $idPrograma
        idBeneficio: $idBeneficio
        idTipo: $idTipo
        idUsuario: $idUsuario
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...solicitud
      }
    }
  }
  ${Beneficio.fragments.solicitud}
`;

export const getMisBeneficiosSolicitudes = gql`
  query getMisBeneficiosSolicitudes(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: BeneficioSolicitudColumn!
    $idBeneficio: Int
    $idTipo: ID
    $fechaDesde: Date
    $fechaHasta: Date
  ) {
    getMisBeneficiosSolicitudes(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      solicitudOrder: { column: $orderBy, order: $order }
      solicitudRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
      solicitudLike: { idBeneficio: $idBeneficio, idTipo: $idTipo }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...solicitud
      }
    }
  }
  ${Beneficio.fragments.solicitud}
`;

export const getBeneficioSolicitudById = gql`
  query getBeneficiosSolicitudById($idSolicitud: Int) {
    getMisBeneficiosSolicitudes(solicitudLike: { idSolicitud: $idSolicitud }) {
      itemsPagina {
        ...solicitud
      }
    }
  }
  ${Beneficio.fragments.solicitud}
`;

export default {
  queries: {
    getPaginaBeneficios,
    getBeneficioById,
    getBeneficiosInput,
    getBeneficioTipos,
    getPaginaSucursalesBeneficio,
    getSucursalBeneficioById,
    getSucursalesBeneficioInput,
    getCatalogoBeneficios,
    getCatalogoBeneficiosPublico,
    getCatalogoBeneficiosInput,
    getCatalogoBeneficioById,
    getCatalogoBeneficioPublicoById,
    getBeneficiosFilters,
    getBeneficiosFiltersPublico,
    getBeneficiosSolicitudes,
    getMisBeneficiosSolicitudes,
    getBeneficioSolicitudById
  },
  mutations: {
    createBeneficio,
    updateBeneficio,
    createBeneficioTipo,
    createBeneficioSucursal,
    updateBeneficioSucursal,
    toggleFavoritoBeneficio,
    solicitarBeneficio
  }
};
