import client from "js/App/client.graphql.js";
import FacturasGraphQL from "js/graphql/resolvers/facturas.resolver";
import { change } from "redux-form";
import { has } from "lodash";

export const changeEstadoMutation = ({
  field,
  value,
  dispatch,
  idFactura,
  openNotification
}) =>
  client
    .mutate({
      mutation: FacturasGraphQL.mutations.updateFactura,
      errorPolicy: "all",
      variables: {
        estado: value,
        idFactura
      }
    })
    .then(({ data }) => {
      if (has(data, "updateFactura.estado")) {
        dispatch(
          change("formAdminFacturasDetail", field, data.updateFactura.estado)
        );
        openNotification("Se ha cambiado el estado de la factura");
      }
    });
