import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit
    // minWidth: 500
  },
  FormControl: {
    marginBottom: 8,
    marginTop: 16
  },
  InputLabel: {
    marginLeft: 10
  },
  select: {
    marginBottom: 8,
    marginTop: 16,
    width: "100%"
  },
  fullWidth: {
    width: "100%"
  },
  uploadIcon: {
    marginLeft: "10px",
    marginTop: "10px"
  }
});

export default withStyles(styles);
