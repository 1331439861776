import gql from "graphql-tag";

export const signin = gql`
  query Signin($username: String!, $password: String!, $idPrograma: Int!) {
    login(username: $username, password: $password, idPrograma: $idPrograma) {
      usuario {
        idUsuario
        estado
        username
        roles {
          idRol
          nombre
        }
      }
      usoClaveDeUnUso
      privilegios {
        codigoPrivilegio
      }
    }
  }
`;

export const writeUser = (_, { user }, { cache }) => {
  const userParsed = JSON.parse(user);
  const newUserData = {
    isLogged: true,
    userData: {
      userName: userParsed.usuario.username,
      privilegios: userParsed.privilegios,
      __typename: "UserData"
    },
    __typename: "User"
  };
  const data = { user: newUserData };
  cache.writeData({ data });
  return null;
};

export const setUser = gql`
  mutation setUser($login: String!) {
    writeUser(user: $login) @client {
      userData
    }
  }
`;

export const userDefaults = {
  isLogged: false,
  userData: {
    userName: "pablo",
    privilegios: "",
    __typename: "UserData"
  },
  __typename: "User"
};

export default {
  mutations: { setUser, writeUser },
  queries: {
    signin
  },
  defaults: { userDefaults }
};
