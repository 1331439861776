import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, change, getFormMeta, FieldArray } from "redux-form";
import {
  Grid,
  Button,
  Icon,
  Tooltip,
  Paper,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { styles } from "js/components/Shared/Form/styles.js";
import {
  InputTextField,
  InputNumberField,
  ListField
} from "js/components/Shared/Form/Fields";
import { _t } from "js/utils/TranslationService";

const renderObjectives = ({ fields, segmentos }) => (
  <Grid item container xs={12} spacing={16}>
    <Tooltip title={_t("Agregar objetivo")} placement="right-end">
      <Icon
        // className={classes.iconAddElement}
        color="secondary"
        onClick={() => fields.push({})}
      >
        add_circle
      </Icon>
    </Tooltip>
    {fields.map((objective, index) => {
      return (
        <Fragment key={index}>
          <Grid xs={11} item container>
            <Paper style={{ width: "100%", padding: "15px" }}>
              <Grid xs={12} item container spacing={8}>
                <Grid item xs={12} sm={6} style={{ display: "none" }}>
                  <InputTextField
                    name={`${objective}.idObjetivo`}
                    label={"idObjetivo"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputTextField
                    name={`${objective}.nombre`}
                    label={_t("Objetivo")}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ListField
                    name={`${objective}.formaSumar`}
                    label={_t("Forma sumar")}
                    options={[
                      {
                        label: _t("Cantidad de productos vendidos"),
                        value: "CANTIDAD_PRODUCTOS"
                      },
                      {
                        label: _t("Cantidad de ventas"),
                        value: "CANTIDAD_VENTAS"
                      },
                      {
                        label: _t("Cantidad de clientes"),
                        value: "CANTIDAD_CLIENTES"
                      },
                      {
                        label: _t("Monto"),
                        value: "MONTO"
                      }
                    ]}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Puntos por segmento</Typography>
                </Grid>

                <Grid item xs={12}>
                  <FieldArray
                    name={`${objective}.fields`}
                    component={renderPuntosPorSegmento}
                    segmentos={segmentos}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Tooltip title={_t("Quitar objetivo")} placement="right-start">
            <Icon
              // className={classes.iconRemoveElement}
              color="action"
              onClick={() => fields.splice(index, 1)}
            >
              remove_circle
            </Icon>
          </Tooltip>
        </Fragment>
      );
    })}
  </Grid>
);

const renderPuntosPorSegmento = ({ fields, segmentos }) => (
  <Grid container spacing={16}>
    {segmentos &&
      segmentos.length !== 0 && (
        <Fragment>
          {fields.length < segmentos.length && (
            <Tooltip title="Agregar item" placement="right-end">
              <Icon color="secondary" onClick={() => fields.push({})}>
                add_circle
              </Icon>
            </Tooltip>
          )}

          {fields.map((puntosPorSegmento, index) => {
            return (
              <Fragment key={index}>
                <Grid xs={11} item>
                  <Paper style={{ width: "100%", padding: "15px" }}>
                    <Grid container spacing={16}>
                      <Grid item xs={12} md={6}>
                        <ListField
                          name={`${puntosPorSegmento}.idSegmento`}
                          label="Segmento"
                          options={segmentos.map(segmento => ({
                            value: segmento.idSegmento,
                            label: segmento.label
                          }))}
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <InputNumberField
                          name={`${puntosPorSegmento}.puntos`}
                          label="Puntos"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ display: "none" }}>
                        <InputTextField
                          name={`${puntosPorSegmento}._id`}
                          label={"_id"}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Tooltip title="Quitar item" placement="right-start">
                  <Icon color="action" onClick={() => fields.splice(index, 1)}>
                    remove_circle
                  </Icon>
                </Tooltip>
              </Fragment>
            );
          })}
        </Fragment>
      )}
  </Grid>
);

class Form extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      id,
      campana,
      segmentos
    } = this.props;

    return (
      <form className={classes.container} onSubmit={handleSubmit}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {`Objetivos campaña ${campana ? campana.nombre : " "}`}
            </Typography>
          </Grid>

          {errors &&
            errors.length !== 0 && (
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors.map(e => (
                    <li>{e.message}</li>
                  ))}
                </ul>
              </Grid>
            )}

          <Grid item xs={12}>
            <FieldArray
              name="fields"
              component={renderObjectives}
              segmentos={segmentos}
              disabled={disabled}
            />
          </Grid>

          <Grid className={classes.gridButton} item xs={12}>
            <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              style={{ marginRight: 20 }}
              onClick={() =>
                history.push(
                  `/admin/programa/${id}/campanas/${campana.idCampana}`
                )
              }
            >
              {_t("Cancelar")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              disabled={disabled || pristine || submitting}
            >
              {_t("Guardar")}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

Form = reduxForm({
  form: "formObjetivosCampana",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

Form = connect(
  state => ({
    formMeta: getFormMeta("formObjetivosCampana")(state)
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formObjetivosCampana", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
