import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { CLN } = Fragmentos;

export const getReporteCLN = gql`
  query getReporteCLN($idUsuario: Int!, $fechaDesde: Int!, $fechaHasta: Int!) {
    getReporteCLN(
      reporteCLNLike: { idUsuario: $idUsuario }
      reporteCLNRange: { anoMes: { min: $fechaDesde, max: $fechaHasta } }
    ) {
      ...reporteCLN
    }
  }
  ${CLN.fragments.reporteCLN}
`;

export const miReporteCLN = gql`
  query miReporteCLN($fechaDesde: Int!, $fechaHasta: Int!) {
    miReporteCLN(
      reporteCLNRange: { anoMes: { min: $fechaDesde, max: $fechaHasta } }
    ) {
      ...reporteCLN
    }
  }
  ${CLN.fragments.reporteCLN}
`;

export const getComerciosCLN = gql`
  query getComerciosCLN {
    getComerciosCLN {
      idUsuario
      username
      perfil {
        nombre
        otros
      }
    }
  }
`;

export const uploadReporteComerciosCLN = gql`
  mutation($file: Upload!) {
    uploadReporteComerciosCLN(file: $file)
  }
`;

export const uploadReporteSucursalesCLN = gql`
  mutation($file: Upload!) {
    uploadReporteSucursalesCLN(file: $file)
  }
`;

export const deleteReportesCLN = gql`
  mutation deleteReportesCLN($fecha: Int!, $idUsuario: Int) {
    deleteReportesCLN(anoMes: $fecha, idUsuario: $idUsuario)
  }
`;

export default {
  queries: {
    getReporteCLN,
    miReporteCLN,
    getComerciosCLN
  },
  mutations: {
    deleteReportesCLN,
    uploadReporteComerciosCLN,
    uploadReporteSucursalesCLN
  }
};
