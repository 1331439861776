import React, { PureComponent, Fragment, createRef } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";
import Hidden from "@material-ui/core/Hidden";
import "./styles.css";
import {
  TicketPromedio,
  TransaccionesGlobales,
  TransaccionesPorSucursal,
  Genero,
  RangoEtario
} from "./Reportes";
import upperCase from "lodash/upperCase";
import { numberWithCommas } from "js/utils";
import _ from "lodash";
import { PictureAsPdf, CloudDownload } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import html2PDF from "jspdf-html2canvas";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { replaceURL } from "../../utils/Helper";
import logoReporte from "../../../images/reports/logoReport.png";
import AppQraphQL from "../../../js/graphql/resolvers/programa.resolver";
import client from "../../../js/App/client.graphql";
import { getProgramaPublicInfo } from "../../App/utils";

class ReportesCLN extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLogo: false,
      logo: null
    };
  }

  async componentDidMount() {
    const url = window.location.hostname.replace("www.", "");
    const protocol = window.location.protocol;

    if (url.includes("dev") || url.includes("qa")) {
      this.setState({ logo: logoReporte });
    } else {
      await client
        .query({
          query: AppQraphQL.queries.getProgramaPublicInfo,
          variables: { url },
          errorPolicy: "all",
          fetchPolicy: "cache-first"
        })
        .then(({ data }) => {
          if (data) {
            const { template } = getProgramaPublicInfo(data);

            let logo = replaceURL(
              template.header.logoPrincipal.url,
              protocol,
              url
            );
            this.setState({ logo });
          }
        });
    }
  }

  handleDownloadXLS = () => {
    const { data } = this.props;

    const { transaccionesPorSucursal, ticketPromedio } = data;
    const {
      info: { comercio }
    } = this.props;

    const sortYearMonth = (a, b) => {
      const [yearA, monthA] = a.split("/");
      const [yearB, monthB] = b.split("/");
      return yearA - yearB || monthA - monthB;
    };

    let exportData = _(transaccionesPorSucursal)
      .groupBy("sucursal.idSucursal")
      .map(items => {
        const nombre = items[0].sucursal.nombre;
        const transaccionesPorAnoMes = items.reduce((acc, item) => {
          const key = `${item.anoMes
            .toString()
            .substring(0, 4)}/${item.anoMes.toString().substring(4, 6)}`;
          acc[key] = (acc[key] || 0) + item.transacciones;
          return acc;
        }, {});

        const totalGralTransacciones = _.sumBy(items, "transacciones");

        return {
          "Nombre de Sucursal": nombre,
          ...transaccionesPorAnoMes,
          Total: totalGralTransacciones
        };
      })
      .value();

    let transaccionesGlobales = _.transform(
      transaccionesPorSucursal,
      (result, transaccion) => {
        if (transaccion.anoMes) {
          const anoMesFormateado = `${transaccion.anoMes
            .toString()
            .substring(0, 4)}/${transaccion.anoMes.toString().substring(4, 6)}`;
          result[anoMesFormateado] =
            (result[anoMesFormateado] || 0) + transaccion.transacciones;
        }
        result["Total"] = (result["Total"] || 0) + transaccion.transacciones;
        result["Nombre de Sucursal"] = "Transacciones Globales";
      },
      {}
    );

    let ticketsPromedio = _.transform(
      ticketPromedio,
      (result, ticket) => {
        if (ticket.anoMes) {
          const anoMesFormateado = `${ticket.anoMes
            .toString()
            .substring(0, 4)}/${ticket.anoMes.toString().substring(4, 6)}`;
          result[anoMesFormateado] =
            (result[anoMesFormateado] || 0) + ticket.ticketPromedio;
        }
        result["Total"] = (result["Total"] || 0) + ticket.ticketPromedio;
        result["Nombre de Sucursal"] = "Ticket Promedio";
      },
      {}
    );

    exportData.push(transaccionesGlobales);
    exportData.push(ticketsPromedio);

    const allKeys = [...new Set(exportData.flatMap(Object.keys))];
    const yearMonthKeys = allKeys
      .filter(key => /^\d{4}\/\d{2}$/.test(key))
      .sort(sortYearMonth);
    const orderedKeys = ["Nombre de Sucursal", ...yearMonthKeys, "Total"];

    const orderedData = exportData.map(item => {
      const newItem = {};
      orderedKeys.forEach(key => {
        newItem[key] = item[key] || 0;
      });
      return newItem;
    });

    const fileName = `Reporte_CLN_${data.comercio.username}_${moment().format(
      "YYYYMMDDHHmmss"
    )}`;

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(orderedData);

    const wb = { Sheets: { [`${comercio}`]: ws }, SheetNames: [comercio] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array"
    });

    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, fileName + fileExtension);
  };

  processLogo = logoUrl => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";

      img.onload = () => {
        const tempCanvas = document.createElement("canvas");
        const ctx = tempCanvas.getContext("2d");

        // Set canvas size to be slightly larger than the image
        tempCanvas.width = img.width + 20; // Add 10px padding on each side
        tempCanvas.height = img.height + 20; // Add 10px padding on top and bottom

        // Fill the canvas with white
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

        // Draw the image in the center of the canvas
        ctx.drawImage(img, 10, 10, img.width, img.height);

        resolve(tempCanvas.toDataURL("image/png"));
      };

      img.onerror = () => {
        reject(new Error("Failed to load image"));
      };

      img.src = logoUrl;
    });
  };

  handleDownloadPDF = async () => {
    const {
      info: { comercio }
    } = this.props;
    const { logo } = this.state;
    const logoBlanco = await this.processLogo(logo);
    const documento = document.getElementById("reporteCLN");
    const filename = `Reporte_CLN_${comercio}_${moment().format(
      "YYYYMMDDHHmmss"
    )}.pdf`;

    const pageStyle = `
    @page {
      size: A4;
      margin: 0;
    }
    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      .page-break {
        display: block;
        page-break-before: always;
      }
    }
  `;

    const pages = document.getElementsByClassName("page");

    const pdf = await html2PDF(pages, {
      jsPDF: {
        orientation: "portrait",
        format: "a4"
      },
      html2canvas: {
        scale: 1,
        backgroundColor: null
      },
      margin: {
        top: 5,
        bottom: 5
      },
      imageType: "image/jpeg",
      imageQuality: 1,
      autoResize: true,
      output: `${filename}`,
      // pageStyle: pageStyle,
      watermark: {
        src: logoBlanco,
        handler: ({ pdf, imgNode, pageNumber, totalPageNumber }) => {
          if (pageNumber === 1) {
            pdf.addImage(imgNode, "PNG", 0, 5, 55, 50);
          }
        }
      }
    });
  };

  render() {
    const {
      data: {
        genero,
        rangoEtario,
        transaccionesPorSucursal,
        ticketPromedio,
        transaccionesGlobales
      },
      data,
      loading,
      variables: { fechaDesde, fechaHasta },
      info: { comercio, objetivo, lastMonth },
      info,
      onChange,
      classes
    } = this.props;

    return (
      <>
        <Grid
          xs={12}
          container
          spacing={16}
          className={`${classes.contenedorCLN} ${
            classes.fondoNegro
          } page page-1`}
          justify={"center"}
          style={{
            maxHeight: "100%",
            borderRadius: "0px",
            paddingTop: "15px"
          }}
          id="reporteCLN"
        >
          <Grid item container xs={10}>
            <Grid xs={12} sm={6} item style={{ margin: "auto" }}>
              <Typography
                variant="h3"
                color="grey"
                className={classes.contenedorCLN}
                classes={{ root: classes.textoTituloComercio }}
              >
                {comercio}
              </Typography>
              <div data-html2canvas-ignore>
                <IconButton onClick={this.handleDownloadXLS}>
                  <Tooltip title="Exportar transacciones por sucursal">
                    <CloudDownload
                      className={`${classes.contenedorCLN} ${
                        classes.textoNegro
                      }`}
                    />
                  </Tooltip>
                </IconButton>
                <IconButton onClick={this.handleDownloadPDF}>
                  <Tooltip title="Exportar reporte PDF">
                    <PictureAsPdf
                      className={`${classes.contenedorCLN} ${
                        classes.textoNegro
                      }`}
                    />
                  </Tooltip>
                </IconButton>
              </div>
            </Grid>
            <Grid
              xs={12}
              sm={6}
              item
              container
              spacing={24}
              className={classes.contenedorCLN}
            >
              <Grid xs={12} sm={6} item>
                <Grid xs={12} item>
                  <Typography
                    variant="subtitle1"
                    color="#414141"
                    className={`${classes.contenedorCLN} ${
                      classes.textoPeriodo
                    }`}
                  >
                    PERÍODO:
                  </Typography>
                </Grid>
                <Grid xs={12} item container>
                  <Grid xs={4} item>
                    <Typography
                      variant="subtitle1"
                      color="#414141"
                      // eslint-disable-next-line prettier/prettier
                      className={`${classes.contenedorCLN} ${
                        classes.textoDatePicker
                      }`}
                      classes={{ root: classes.textoDatePicker }}
                    >
                      DESDE
                    </Typography>
                  </Grid>
                  <Grid xs={8} item>
                    <Typography
                      variant="subtitle1"
                      className={classes.textoDinamicoFecha}
                    >
                      <DatePicker
                        key="min"
                        name="min"
                        dateFormat="MMMM YYYY"
                        maxDate={moment()}
                        views={["year", "month"]}
                        openTo="year"
                        startOfDate
                        input={{
                          onChange: onChange("fechaDesde"),
                          value: moment(fechaDesde, "YYYYMM")
                        }}
                        className={`${classes.datePicker} ${
                          classes.contenedorCLN
                        }`}
                        InputProps={{
                          className:
                            classes.input +
                            " " +
                            classes.contenedorCLN +
                            classes.textoDinamicoFecha
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={12} item container>
                  <Grid xs={4} item>
                    <Typography
                      variant="subtitle1"
                      color="#414141"
                      className={classes.contenedorCLN}
                      classes={{ root: classes.textoDatePicker }}
                    >
                      HASTA
                    </Typography>
                  </Grid>
                  <Grid xs={8} item>
                    <Typography
                      variant="subtitle1"
                      className={classes.textoDinamicoFecha}
                    >
                      <DatePicker
                        key="max"
                        name="max"
                        dateFormat="MMMM YYYY"
                        maxDate={moment()}
                        views={["year", "month"]}
                        openTo="year"
                        startOfDate
                        className={`${classes.datePicker} ${
                          classes.contenedorCLN
                        }`}
                        input={{
                          onChange: onChange("fechaHasta"),
                          value: moment(fechaHasta, "YYYYMM")
                        }}
                        InputProps={{
                          className: classes.input + " " + classes.contenedorCLN
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Hidden xsDown>
                <div className={classes.verticalDivider} />
              </Hidden>
              <Grid xs={12} sm={5} item>
                <Typography
                  variant="subtitle1"
                  color="black"
                  className={classes.textoFijoObjetivo}
                >
                  {lastMonth &&
                    objetivo &&
                    `OBJETIVO ${upperCase(
                      moment(lastMonth, "MM/YYYY").format("MMMM YYYY")
                    )}`}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  className={classes.contenedorCLN}
                  classes={{ root: classes.textoDinamicoObjetivo }}
                >
                  {lastMonth &&
                    objetivo &&
                    `${numberWithCommas(objetivo) || "--"} transacciones`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            sm={10}
            container
            item
            spacing={16}
            className={classes.contenedorCards}
          >
            <Grid item xs={12} sm={6}>
              <Card raised={true}>
                <TransaccionesGlobales
                  data={transaccionesGlobales}
                  info={info}
                  classes={classes}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card raised={true}>
                <TicketPromedio data={ticketPromedio} classes={classes} />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <TransaccionesPorSucursal
                data={transaccionesPorSucursal}
                classes={classes}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          container
          item
          spacing={16}
          className={`${classes.fondoBlanco} page page-2`}
          justify={"center"}
        >
          <Grid
            item
            xs={12}
            sm={4}
            className={classes.fondoBlanco}
            container
            justify={"center"}
          >
            <Grid item xs={12} container justify={"center"}>
              <Typography
                variant="h6"
                color={"primary"}
                className={classes.titulosInferiores}
              >
                Género
              </Typography>
            </Grid>
            <Grid item xs={12} container justify={"center"}>
              <Genero data={genero} classes={classes} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.fondoBlanco} container>
            <Typography
              variant="h6"
              color={"primary"}
              className={classes.titulosInferiores}
            >
              Rango etario
            </Typography>
            <RangoEtario data={rangoEtario} classes={classes} />
          </Grid>
        </Grid>
      </>
    );
  }
}

ReportesCLN.propTypes = {
  classes: PropTypes.object
};

ReportesCLN.defaultProps = {
  data: PropTypes.any
};

ReportesCLN.displayName = "ReportesCLN";

export default ReportesCLN;
