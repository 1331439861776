import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { _t } from "js/utils/TranslationService";
import Button from "@material-ui/core/Button/Button";

function YaParticipo(props) {
  const { history } = props;

  return (
    <Fragment>
      <Typography variant="h6">
        {`${_t("Ya participaste de esta acción. Gracias.")}`}
      </Typography>
      <Button
        style={{ marginBottom: "20px", marginTop: "20px" }}
        onClick={() => history.push("/landing")}
        variant="contained"
        color="primary"
      >
        Volver
      </Button>
    </Fragment>
  );
}

YaParticipo.propTypes = {
  classes: PropTypes.object.isRequired
};

export default YaParticipo;
