import gql from "graphql-tag";

export const getPaginaCampanas = gql`
  query getCampanasOP(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: CampanaOPColumn!
    $idPrograma: Int
    $idCampana: ID
    $nombre: String
    $fechaInicio: Date
    $fechaFin: Date
    $puntosTodos: Int
    $estado: EstadoCampanaOP
  ) {
    getCampanasOP(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      campanaOPOrder: { column: $orderBy, order: $order }
      campanaOPLike: {
        idPrograma: $idPrograma
        nombre: $nombre
        puntosTodos: $puntosTodos
        estado: $estado
        _id: $idCampana
      }
      campanaOPRange: {
        fechaInicio: { min: $fechaInicio }
        fechaFin: { max: $fechaFin }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        idPrograma
        nombre
        fechaInicio
        fechaFin
        fechaCierre
        puntosTodos
        puntosTodosSupervisor
        grupoSKU {
          _id
          nombre
        }
        estado
        tipo
      }
    }
  }
`;

export const getCampanasInput = gql`
  query getCampanasOP(
    $campanaOPLike: CampanaOPSearch
    $pagina: Pagina
    $orderBy: CampanaOPColumn!
    $order: Order!
  ) {
    getCampanasOP(
      campanaOPLike: $campanaOPLike
      pagina: $pagina
      campanaOPOrder: { column: $orderBy, order: $order }
    ) {
      itemsPagina {
        _id
        nombre
        estado
        objetivos {
          _id
          objetivo
        }
      }
    }
  }
`;

export const getCampanaById = gql`
  query getCampanasOP($campanaOPLike: CampanaOPSearch, $pagina: Pagina) {
    getCampanasOP(campanaOPLike: $campanaOPLike, pagina: $pagina) {
      itemsPagina {
        _id
        idPrograma
        nombre
        descripcion
        fechaInicio
        fechaFin
        fechaCierre
        puntosTodos
        puntosTodosSupervisor
        objetivos {
          _id
          objetivo
          puntos
          formaSumar
        }
        grupoSKU {
          _id
          nombre
        }
        estado
        imagenes
        tipo
        campanasRelacionadas {
          _id
          nombre
        }
      }
    }
  }
`;

export const getCampanaNombreById = gql`
  query getCampanasOP($campanaOPLike: CampanaOPSearch, $pagina: Pagina) {
    getCampanasOP(campanaOPLike: $campanaOPLike, pagina: $pagina) {
      itemsPagina {
        _id
        nombre
      }
    }
  }
`;

export const getGruposSKU = gql`
  query getGruposSKU(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: GrupoSKUColumn!
    $idPrograma: Int
    $nombre: String
  ) {
    getGruposSKU(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      grupoSKUOrder: { column: $orderBy, order: $order }
      grupoSKULike: { idPrograma: $idPrograma, nombre: $nombre }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        nombre
        productos {
          sku
          descripcion
        }
      }
    }
  }
`;

export const getGruposSKUInput = gql`
  query getGruposSKU($grupoSKULike: GrupoSKUSearch) {
    getGruposSKU(grupoSKULike: $grupoSKULike) {
      itemsPagina {
        _id
        nombre
        productos {
          sku
        }
      }
    }
  }
`;

export const createCampana = gql`
  mutation createCampanaOP($campana: CampanaOPCreate!) {
    createCampanaOP(campana: $campana) {
      _id
    }
  }
`;

export const updateCampana = gql`
  mutation updateCampanaOP($campana: CampanaOPUpdate!) {
    updateCampanaOP(campana: $campana) {
      _id
    }
  }
`;

export default {
  queries: {
    getPaginaCampanas,
    getCampanasInput,
    getCampanaById,
    getGruposSKU,
    getGruposSKUInput,
    getCampanaNombreById
  },
  mutations: {
    createCampana,
    updateCampana
  }
};
