export const columnas = [
  { id: "idSolicitud", label: "ID", isNumeric: true },
  { id: "fecha", label: "Fecha", type: "date" },
  { id: "usuario_username", label: "Usuario" },
  { id: "usuario_perfil_nombre", label: "Nombre" },
  { id: "usuario_perfil_apellido", label: "Apellido" },
  { id: "beneficio_nombre", label: "Nombre Código" },
  { id: "beneficio_idTipo_tipo", label: "Tipo código" }
];

export const misColumnas = [
  { id: "idSolicitud", label: "ID", isNumeric: true },
  { id: "fecha", label: "Fecha", type: "date" },
  { id: "beneficio_nombre", label: "Nombre Código" },
  { id: "beneficio_idTipo_tipo", label: "Tipo código" },
  { id: "actions", label: "Acciones" }
];
