import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { ColorPicker, Select, Input } from "js/pages/TemplateBuilder2/Controls";
import { _t } from "js/utils/TranslationService";
import { get } from "lodash";

const Cucardas = ({ title, path, onChange, backgrounds }) => (
  <Grid container spacing={24}>
    <Grid item xs={4}>
      {title && (
        <Typography variant="subtitle2" gutterBottom>
          {_t(title)}
        </Typography>
      )}
      <Select
        options={[
          { label: "Color", value: "color" },
          { label: "URL", value: "url" }
        ]}
        value={get(backgrounds, "backgroundType", "url")}
        onChange={onChange(`${path}.backgroundType`)}
      />
    </Grid>
    {get(backgrounds, "backgroundType", "url") === "color" && (
      <Grid item xs={8}>
        <Typography variant="subtitle2" gutterBottom>
          {_t("Color")}
        </Typography>
        <ColorPicker
          color={backgrounds.backgroundColor}
          onChange={onChange(`${path}.backgroundColor`)}
        />
      </Grid>
    )}
    {get(backgrounds, "backgroundType", "url") === "url" && (
      <Grid item xs={8}>
        <Typography variant="subtitle2" gutterBottom>
          {_t("URL")}
        </Typography>
        <Input
          placeholder="http://"
          value={backgrounds.backgroundImage}
          onChange={onChange(`${path}.backgroundImage`)}
        />
      </Grid>
    )}
  </Grid>
);

Cucardas.propTypes = {
  classes: PropTypes.object
};

Cucardas.defaultProps = {};

Cucardas.displayName = "Cucardas";

export default Cucardas;
