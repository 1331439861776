import React from "react";
import PropTypes from "prop-types";
import capitalize from "lodash/capitalize";

const Estado = props => {
  const { estado, classes } = props;
  return estado ? (
    <div className={classes[estado.toLowerCase()]}>{capitalize(estado)}</div>
  ) : null;
};

Estado.propTypes = {
  estado: PropTypes.string
};

export default Estado;
