import React, { PureComponent } from "react";
import moment from "moment";
import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/idioma.resolver";
import { get } from "lodash";
import {
  editAction,
  manageAction
} from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import advancedFilter from "js/components/TablaIdiomas/advancedFilter.js";

const defaultHeader = {
  title: "Idiomas",
  new: {
    title: "Nuevo",
    perms: [Roles.IDIOMA_W]
  }
};

const columnData = [
  { id: "nombre", label: "Idioma" },
  { id: "nombrePadre", label: "Padre" },
  {
    id: "usuarioCreador",
    label: "Creador",
    type: "custom",
    parser: usuarioCreador => get(usuarioCreador, "username", "")
  },
  {
    id: "usuarioModificador",
    label: "Editor",
    type: "custom",
    parser: usuarioEditor => get(usuarioEditor, "username", "")
  },
  {
    id: "ultimaActualizacion",
    label: "Última actualización",
    type: "custom",
    parser: ultimaActualizacion =>
      ultimaActualizacion ? moment(ultimaActualizacion).format("L") : "--"
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "nombre" };

class Tabla extends PureComponent {
  render() {
    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.IDIOMA_W]
      })
    });
    const manage = params => ({
      ...manageAction({
        ...params,
        history: this.props.history,
        perms: [Roles.IDIOMA_W]
      })
    });
    return (
      <Table
        query={AppQraphQL.queries.getPaginaIdiomas}
        advancedFilter={advancedFilter}
        defaultHeader={defaultHeader}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getIdiomas}
        actionsCustom={[edit, manage]}
      />
    );
  }
}

export default Tabla;
