import Tabla from "./tabla";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withNotificationContext } from "js/notification-context";

const enhance = compose(
  withNotificationContext,
  withRouter
);

export default enhance(Tabla);
