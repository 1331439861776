import gql from "graphql-tag";

export const getBannerById = gql`
  query getBanner($bannerLike: BannerSearch, $pagina: Pagina) {
    getBanner(bannerLike: $bannerLike, pagina: $pagina) {
      itemsPagina {
        idPrograma
        nombre
        imagenes
      }
    }
  }
`;

export const createBanner = gql`
  mutation createBanner($banner: BannerCreate) {
    createBanner(banner: $banner) {
      imagenes
    }
  }
`;

export const updateBanner = gql`
  mutation updateBanner($banner: BannerUpdate) {
    updateBanner(banner: $banner) {
      idPrograma
    }
  }
`;

export default {
  queries: {
    getBannerById
  },
  mutations: {
    createBanner,
    updateBanner
  }
};
