import React from "react";
import Tabla from "js/pages/Unilever/CampanasSupervisor/Tabla";

export const Campanas = props => {
  return (
    <div>
      <Tabla {...props} />
    </div>
  );
};

export default Campanas;
