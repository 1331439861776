export default theme => ({
  ranking: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  resumen: {
    width: "100%",
    padding: 0
  },
  card: {
    width: "100%"
  }
});
