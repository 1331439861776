import React, { Component } from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";

import styles from "./styles";
import withPrograma from "js/hoc/withPrograma";
import Beneficio from "./Beneficio.container";
import { Query } from "react-apollo";
import BeneficioQraphQL from "js/graphql/resolvers/beneficio.resolver";
import SplashScreen from "js/pages/Loading";
import Grid from "@material-ui/core/Grid";

import { connect } from "react-redux";
import beneficioActions from "./actions";
import AuthService from "js/utils/AuthService";

class Root extends Component {
  componentWillUnmount() {
    this.props.cleanBeneficioState();
  }
  render() {
    const isLogged = !!AuthService.getIdUsuario();
    const queryCatalogo = isLogged
      ? BeneficioQraphQL.queries.getCatalogoBeneficioById
      : BeneficioQraphQL.queries.getCatalogoBeneficioPublicoById;
    const idBeneficio = parseInt(
      this.props.computedMatch.params.idBeneficio,
      10
    );
    const beneficioQuery = {
      beneficioLike: {
        idBeneficio
      }
    };
    return (
      <Query
        query={queryCatalogo}
        variables={beneficioQuery}
        errorPolicy="all"
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data }) => {
          if (loading || !data)
            return (
              <Grid container style={{ height: 500 }}>
                <SplashScreen />
              </Grid>
            );
          const beneficio = isLogged
            ? data.getCatalogoBeneficios.itemsPagina[0]
            : data.getCatalogoBeneficiosPublico.itemsPagina[0];

          this.props.loadBeneficioState(beneficio);

          return <Beneficio {...this.props} beneficio={beneficio} />;
        }}
      </Query>
    );
  }
}

const mapStateToProps = state => ({
  isLogged: state.programa.isLogged
});

const mapDispatchToProps = dispatch => ({
  cleanBeneficioState: beneficioActions({ dispatch }).cleanBeneficioState,
  loadBeneficioState: beneficioActions({ dispatch }).loadBeneficioState
});

const enhance = compose(
  withPrograma,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
);
export default enhance(Root);
