import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  Dropzone: {
    minWidth: "200px",
    border: "1.5px solid #6d6d6d",
    backgroundColor: "#f4f4f4",
    borderStyle: "dashed",
    lineHeight: 0,
    padding: "5px",
    position: "relative",
    cursor: "pointer",
    overflow: "hidden",
    "& img": {
      height: "auto"
    }
  },
  ActionBox: {
    padding: "5px",
    textAlign: "center",
    color: "#6d6d6d",
    minHeight: "100px",
    boxSizing: "border-box"
  },
  Legend: {
    display: "inline-block",
    marginRight: "5px"
  },
  label: {
    width: "100%",
    textAlign: "right",
    marginTop: "10px",
    color: "#757575",
    fontSize: "0.85em"
  },
  ImageWrapper: {
    overflow: "hidden"
  }
});

export default withStyles(styles);
