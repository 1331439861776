export const navegacion = [
  {
    title: "Registro",
    description: "Cantidad de ingresos al formulario de registro"
  },
  {
    title: "Inicio de Sesión",
    description: "Cantidad de ingresos a la pantalla de inicio de sesión"
  },
  {
    title: "Login",
    description: "Cantidad de sesiones iniciadas"
  },
  {
    title: "Recupero de Usuario",
    description: "Cantidad de ingresos a la pantalla de recupero de usuario"
  },
  {
    title: "Cambio de Clave",
    description: "Cantidad de ingresos a la pantalla de recupero de clave"
  },
  {
    title: "Actualización de Clave",
    description: "Cantidad de ingresos a la pantalla de actualización de clave"
  },
  {
    title: "Mi Perfil",
    description: "Cantidad de ingresos a la pantalla de Mi Perfil"
  },
  {
    title: "Mis Movimientos",
    description: "Cantidad de ingresos a la pantalla de Mis Movimientos"
  },

  {
    title: "Landing",
    description: "Cantidad de ingresos a la pantalla de contenido (landing)"
  },
  {
    title: "Landing+X",
    description: 'Cantidad de clicks en el contenido "X" de la landing'
  },
  {
    title: "Información+X",
    description: "Cantidad de ingresos a la pantalla /informacion?X"
  },

  {
    title: "Catálogo",
    description: "Cantidad de ingresos al catálogo"
  },
  {
    title: "Detalle Producto",
    description: "Cantidad de ingresos al detalle de un producto"
  },
  {
    title: "Confirmación de Canje",
    description: "Cantidad de canjes confirmados"
  },

  {
    title: "Preguntas Frecuentes",
    description: "Cantidad de ingresos a la pantalla de preguntas frecuentes"
  },
  {
    title: "Términos y Condiciones",
    description: "Cantidad de ingresos a la pantalla de términos y condiciones"
  }
];
