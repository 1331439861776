export default theme => ({
  body: {
    background:
      theme.backgrounds.ui.backgroundType === "url"
        ? `url(${theme.backgrounds.ui.backgroundImage})`
        : theme.backgrounds.ui.backgroundColor
          ? theme.backgrounds.ui.backgroundColor
          : "#fff",
    backgroundRepeat: "repeat"
  },
  page: {
    overflowX: "hidden",
    flexDirection: "column",
    minHeight: "100vh",
    display: "flex"
  }
});
