import React, { PureComponent } from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import IdiomaQraphQL from "js/graphql/resolvers/idioma.resolver";
import { withNotificationContext } from "js/notification-context";
import { styles } from "./styles";
import {
  Paper,
  Icon,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  withStyles,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      idIdioma: this.props.match.params.idIdioma,
      nombre: "",
      traducciones: [],
      idiomaPadre: null,
      selectedKey: "",
      selectedValue: "",
      keysToShow: [],
      onlyDefined: false,
      filterValue: "",
      showAddDialog: false,
      newKey: "",
      loading: false,
      saving: false
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const {
      match: {
        params: { idIdioma }
      }
    } = this.props;
    if (idIdioma !== "new") {
      await client
        .query({
          query: IdiomaQraphQL.queries.getIdiomaConPadre,
          fetchPolicy: "network-only",
          variables: {
            idIdioma
          }
        })
        .then(res => {
          const { idioma, idiomaPadre } = res.data.getIdiomaConPadre;
          const keysToShow = [];

          if (idiomaPadre) {
            keysToShow.push(...idiomaPadre.traducciones);
            idioma.traducciones.forEach(traduccion => {
              const index = keysToShow.findIndex(
                parent => parent.key === traduccion.key
              );

              if (index >= 0) {
                keysToShow.splice(index, 1, traduccion);
              } else {
                keysToShow.push(traduccion);
              }
            });
          } else {
            keysToShow.push(...idioma.traducciones);
          }

          this.setState({
            nombre: idioma.nombre,
            traducciones: idioma.traducciones,
            idiomaPadre,
            keysToShow
          });
        });
    }
  }

  async submit(values) {
    const { traducciones, idIdioma } = this.state;
    const { client } = this.props;

    await client
      .mutate({
        mutation: IdiomaQraphQL.mutations.updateIdioma,
        variables: {
          _id: idIdioma,
          traducciones: traducciones.map(traduccion => ({
            key: traduccion.key,
            value: traduccion.value
          }))
        }
      })
      .then(res => {
        this.props.openNotification(`Idioma modificado correctamente`);
        this.props.history.push("/admin/idiomas");
      })
      .catch(error => {
        this.props.openNotification(error.graphQLErrors[0].message);
      });
  }

  handleSelection(traduccion) {
    this.setState({
      selectedKey: traduccion.key,
      selectedValue: traduccion.value || ""
    });
  }

  handleCheckboxChange = name => event => {
    this.setState(prevState => {
      const newState = {
        onlyDefined: !prevState.onlyDefined
      };

      if (newState.onlyDefined) {
        newState.keysToShow = prevState.traducciones;
      } else {
        const keysToShow = [];

        if (prevState.idiomaPadre) {
          keysToShow.push(...prevState.idiomaPadre.traducciones);
          prevState.traducciones.forEach(traduccion => {
            const index = keysToShow.findIndex(
              parent => parent.key === traduccion.key
            );

            if (index >= 0) {
              keysToShow.splice(index, 1, traduccion);
            } else {
              keysToShow.push(traduccion);
            }
          });
        } else {
          keysToShow.push(prevState.traducciones);
        }

        newState.keysToShow = keysToShow;
      }
      if (
        newState.keysToShow.find(k => k.key === prevState.selectedKey) ===
        undefined
      ) {
        newState.selectedKey = "";
        newState.selectedValue = "";
      }

      return newState;
    });
  };

  handleFilterChange = name => event => {
    const value = event.target.value;
    this.setState(prevState => {
      const newState = { filterValue: value };

      if (
        !prevState.keysToShow.find(traduccion =>
          traduccion.key.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        newState.selectedKey = "";
        newState.selectedValue = "";
      }
      return newState;
    });
  };

  handleChange = name => event => {
    const value = event.target.value;
    this.setState(prevState => {
      const newTraducciones = [...prevState.traducciones];
      const newKeysToShow = [...prevState.keysToShow];
      const index = newTraducciones.findIndex(
        traduccion => traduccion.key === prevState.selectedKey
      );
      const index2 = newKeysToShow.findIndex(
        traduccion => traduccion.key === prevState.selectedKey
      );
      const traduccion = {
        key: prevState.selectedKey,
        value
      };
      if (index >= 0) {
        newTraducciones.splice(index, 1, traduccion);
      } else {
        newTraducciones.push(traduccion);
      }
      newKeysToShow.splice(index2, 1, traduccion);
      return {
        selectedValue: value,
        traducciones: newTraducciones,
        keysToShow: newKeysToShow
      };
    });
  };

  goBack() {
    this.props.history.push(`/admin/idiomas`);
  }

  handleAddKey = async () => {
    const { newKey } = this.state;
    const { idiomaPadre } = this.state;
    const { client } = this.props;

    this.setState({ saving: true });

    try {
      if (newKey.trim()) {
        const keyExists = idiomaPadre.traducciones.some(t => t.key === newKey);

        if (!keyExists) {
          // Actualizar primero el idioma padre
          await client.mutate({
            mutation: IdiomaQraphQL.mutations.updateIdioma,
            variables: {
              _id: idiomaPadre._id,
              traducciones: [
                ...idiomaPadre.traducciones,
                { key: `${newKey}`, value: "" }
              ].map(traduccion => ({
                key: traduccion.key,
                value: traduccion.value
              }))
            }
          });

          // Recargar datos actualizados del idioma padre
          const { data } = await client.query({
            query: IdiomaQraphQL.queries.getIdiomaConPadre,
            fetchPolicy: "network-only",
            variables: { idIdioma: idiomaPadre._id }
          });

          const updatedPadre = data.getIdiomaConPadre.idioma;

          this.setState({
            idiomaPadre: updatedPadre,
            keysToShow: updatedPadre.traducciones,
            showAddDialog: false,
            newKey: ""
          });

          this.props.openNotification("Clave agregada correctamente");
        } else {
          this.props.openNotification("La clave ya existe");
        }
      }
    } catch (error) {
      this.props.openNotification(
        (error.graphQLErrors &&
          error.graphQLErrors[0] &&
          error.graphQLErrors[0].message) ||
          "Error al agregar clave"
      );
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      nombre,
      keysToShow,
      idiomaPadre,
      selectedKey,
      filterValue,
      showAddDialog,
      newKey
    } = this.state;

    return (
      <Grid container spacing={16} className={classes.fixedHeight}>
        <Grid item xs={12} className={classes.childName}>
          <div onClick={() => this.goBack()} className={classes.pointer}>
            <ChevronLeftIcon />
            <Typography variant="h5" className={classes.main}>
              {nombre}
            </Typography>
          </div>
          <TextField
            id="filter"
            value={this.state.filterValue}
            onChange={this.handleFilterChange("filter")}
            margin="normal"
            placeholder="Buscar keys"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        {idiomaPadre && (
          <Grid item xs={12} className={classes.child}>
            <div className={classes.parentName}>
              <Icon color="action">language</Icon>
              <Typography variant="body1" className={classes.childTitle}>
                {idiomaPadre.nombre}
              </Typography>
              <Tooltip title="Ingrese una nueva clave a traducir">
                <IconButton
                  onClick={() => this.setState({ showAddDialog: true })}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.onlyDefined}
                  onChange={this.handleCheckboxChange("onlyDefined")}
                  value="onlyDefined"
                  color="primary"
                />
              }
              label="Solo definidas"
            />
          </Grid>
        )}
        <Paper style={{ width: "100%" }} className={classes.scrollable}>
          <Grid item xs={12} md={12} lg={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {keysToShow
                  .filter(traduccion =>
                    traduccion.key
                      .toLowerCase()
                      .includes(filterValue.toLowerCase())
                  )
                  .map(traduccion => (
                    <TableRow
                      padding="dense"
                      className={
                        traduccion.key === selectedKey ? classes.selected : ""
                      }
                      key={traduccion.key}
                      onClick={() => this.handleSelection(traduccion)}
                    >
                      <TableCell
                        padding="dense"
                        className={
                          traduccion.key === selectedKey ? classes.selected : ""
                        }
                      >
                        {traduccion.key}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
        </Paper>
        <Paper style={{ width: "100%", marginTop: "20px" }}>
          <Grid item xs={12} md={12} lg={12}>
            <Table>
              <TableBody>
                <TableRow className={classes.textboxTitle}>
                  <TableCell className={classes.textbox}>Valor</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textContent}>
                    {selectedKey}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.textboxTitle}>
                  <TableCell className={classes.textbox}>Traducción</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TextField
                      id="name"
                      value={this.state.selectedValue}
                      onChange={this.handleChange("value")}
                      margin="normal"
                      className={classes.textField}
                      multiline
                      disabled={!selectedKey}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Paper>

        <Grid item xs={12} className={classes.buttonBar}>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => {
              this.props.history.push("/admin/idiomas");
            }}
          >
            Cancelar
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              this.submit();
            }}
          >
            GUARDAR CAMBIOS
          </Button>
        </Grid>

        <Dialog
          open={showAddDialog}
          onClose={() => this.setState({ showAddDialog: false })}
          size="md"
          fullWidth
        >
          <DialogTitle>Agregar nueva clave</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Nombre de la clave"
              multiline
              fullWidth
              value={newKey}
              onChange={e => this.setState({ newKey: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showAddDialog: false })}>
              Cancelar
            </Button>
            <Button
              onClick={this.handleAddKey}
              color="primary"
              disabled={this.state.saving}
            >
              {this.state.saving ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Agregar"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

const enhance = compose(
  withApollo,
  withNotificationContext,
  withRouter,
  withStyles(styles)
);

export default enhance(Edit);
