import { compose, pure, mapProps, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";

import withStyles from "./styles";
import SegmentoGraphQL from "js/graphql/resolvers/segmento.resolver.js";
import UpdateTemplate from "js/graphql/resolvers/updateTemplate.resolver";
import withPrograma from "js/hoc/withPrograma";
import { connect } from "react-redux";
import { withNotificationContext } from "js/notification-context";
import { updateTemplateBuilder } from "js/redux/template";
import { getTemplateBuilderSection } from "js/redux/template/selectors.js";

const mapStateToProps = state => ({
  templateBuilder: state.template.templateBuilder,
  idSegmento: state.template.idSegmento,
  header: getTemplateBuilderSection(state, "header")
});

const mapDispatchToProps = dispatch => ({
  resetTemplate: updateTemplateBuilder({
    dispatch,
    type: "SET_COMPLETE_STATE"
  })
});

const enhance = compose(
  pure,
  withRouter,
  withStyles,
  withNotificationContext,
  withPrograma,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  graphql(SegmentoGraphQL.queries.getSegmentos, {
    options: props => ({
      variables: {
        segmentoLike: {
          idPrograma: parseInt(props.match.params.id || props.idPrograma, 10)
        }
      },
      fetchPolicy: "network-only"
    }),
    props: ({ data: { getSegmentos = [] } }) => ({
      segmentos: getSegmentos.map(({ idSegmento, nombre, descripcion }) => ({
        value: idSegmento,
        label: descripcion || nombre
      }))
    })
  }),
  graphql(UpdateTemplate.mutations.updateTemplate, { name: "updateTemplate" }),
  withHandlers({
    onChange: ({ onGetTemplate }) => value => onGetTemplate(value),
    onSave: ({
      idSegmento,
      updateTemplate,
      openNotification,
      templateBuilder
      // programa: {
      //   segmentoDefault: { idSegmento }
      // }
    }) => value => {
      updateTemplate({
        variables: {
          template: {
            idSegmento,
            template: JSON.stringify({
              templateDev: { template: templateBuilder }
            })
          }
        }
      })
        .then(() => {
          openNotification("El template se ha actualizado");
        })
        .catch(e =>
          openNotification("Hubo un error al actualizar el template")
        );
    },
    onReset: ({ resetTemplate }) => () => resetTemplate()
  }),
  mapProps(({ programa: { segmentoDefault: { idSegmento } }, ...rest }) => ({
    segmentoDefault: idSegmento,
    ...rest
  }))
);

export default enhance;
