import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { isEqual, isEmpty } from "lodash";
import client from "js/App/client.graphql.js";
import UserQraphQL from "js/graphql/resolvers/usuario.resolver";

import { numberWithCommas } from "js/utils";
import Enhancer from "./container";
import {
  FieldsProvider,
  fieldGenerator
} from "js/components/Shared/Form/fieldGenerator";
import ImageUploader, { Avatar } from "../../components/Shared/ImageUploader";
import { LOCAL } from "js/utils/TranslationService";
import { _t } from "js/utils/TranslationService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { renderFecha } from "js/utils/Helper";
import PermService from "js/utils/PermService";
import {
  getRulesInputsNumericos,
  getNumericFieldValidations
} from "js/components/Shared/Form/fieldGenerator";
import { minValue, maxValue } from "js/helpers/validations";
import { USER_ROLES as Roles } from "js/models/User";
import AuthService from "js/utils/AuthService";
import { canShowThis } from "js/App/utils";

const rules = { minValue, maxValue };

export const Box = ({ puntos, fecha, label, classes, onClick = undefined }) => (
  <Grid
    item
    xs={12}
    sm={6}
    onClick={onClick}
    style={{ cursor: onClick ? "pointer" : "initial" }}
  >
    <Paper className={classes.puntosContainer}>
      <Typography variant="body1">
        {_t(label)}
        &nbsp;
        {puntos && fecha ? `(${renderFecha(fecha)})` : ""}
      </Typography>
      <Typography variant="h5">{numberWithCommas(puntos)}</Typography>
    </Paper>
  </Grid>
);

const FieldWrapper = ({ children, wrapperBreakpoint = 4 }) => (
  <Grid item xs={12} md={wrapperBreakpoint}>
    {children}
  </Grid>
);

class Profile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      numericDefinitionsValidations: null,
      otros: {}
    };
  }

  async componentDidMount() {
    this.getOtros();
  }

  componentWillReceiveProps(nextProps) {
    const { numericDefinitionsValidations } = this.state;
    if (
      !isEqual(this.props.profileDefinitions, nextProps.profileDefinitions) ||
      !numericDefinitionsValidations
    ) {
      const profileDefinitions = nextProps.profileDefinitions;
      const checkRulesByNumericDefinition = profileDefinitions
        ? getRulesInputsNumericos(profileDefinitions)
        : null;
      if (
        checkRulesByNumericDefinition &&
        !isEmpty(checkRulesByNumericDefinition)
      ) {
        const validations = {};
        for (const key in checkRulesByNumericDefinition) {
          const checkRules = checkRulesByNumericDefinition[key];
          validations[key] = getNumericFieldValidations(checkRules, rules);
        }
        this.setState({
          numericDefinitionsValidations: validations
        });
      }
    }
  }

  getOtros = () => {
    client
      .query({
        query: UserQraphQL.queries.getMiUsuarioCompleto,
        fetchPolicy: "cache-first"
      })
      .then(({ data }) => {
        const otros = data.miUsuario.perfil.otros
          ? JSON.parse(data.miUsuario.perfil.otros)
          : {};
        this.setState({ otros });
      });
  };

  render() {
    const {
      classes,
      handleSubmit,
      profileDefinitions,
      data: { puntos = [], avatarUrl, showSave, username },
      pristine,
      submitting,
      history,
      showBox,
      onChangeImage,
      usernameSelected,
      programaSelected,
      loadingImage,
      disabled,
      volver,
      showAllCampos = false,
      programa
    } = this.props;
    const { numericDefinitionsValidations, otros } = this.state;

    const moduloPuntos = programa.modulos
      ? programa.modulos.find(
          modulo =>
            modulo.nombreGrupo === "Gestión Puntos" &&
            modulo.estado === "ACTIVO"
        )
      : true;

    const HasPerms = AuthService.hasPerms([Roles.MIS_DATOS_MOVIMIENTOS_R]);

    return (
      <Grid container spacing={24} className={classes.container}>
        <Grid item container xs={12} alignItems="flex-end">
          <Typography variant="h5">
            {usernameSelected && programaSelected
              ? _t("Editando") + ` ${usernameSelected} - ${programaSelected}`
              : ""}
          </Typography>
        </Grid>
        <Grid item container xs={12} alignItems="flex-end">
          {showBox && (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                {_t("Mi perfil")}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Usuario: {username}
              </Typography>
              {profileDefinitions !== undefined
                ? otros.código_de_vendedor &&
                  otros.código_de_distribuidora && (
                    <div>
                      <Typography variant="subtitle2" gutterBottom>
                        {otros && `Cód. Vendedor: ${otros.código_de_vendedor} `}
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom>
                        {otros &&
                          `Cód. Distribuidora: ${
                            otros.código_de_distribuidora
                          }`}
                      </Typography>
                    </div>
                  )
                : ""}
            </Grid>
          )}
          {showBox && (
            <Grid item container xs={12} md={6} spacing={16} justify="flex-end">
              {moduloPuntos &&
                HasPerms &&
                puntos.map(
                  ({ label, puntos, fecha, hidden, onClick }, index) =>
                    !hidden && (
                      <Box
                        key={`${label}__${index}`}
                        label={label}
                        puntos={puntos}
                        classes={classes}
                        onClick={onClick}
                        fecha={fecha}
                      />
                    )
                )}
              {canShowThis(programa.idPrograma) && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => history.push("/updateMyPassword")}
                >
                  {_t("Cambiar clave")}
                </Button>
              )}
            </Grid>
          )}
        </Grid>
        {PermService.getRequirements().includes("COMPLETAR_PERFIL") ? (
          <Grid xs={12} item>
            <Paper elevation={0} className={classes.alertaPerfil}>
              <Typography classes={{ root: classes.alertaPerfilTexto }}>
                {_t(
                  "Por favor, completá los campos requeridos para seguir navegando."
                )}
              </Typography>
            </Paper>
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <FieldsProvider definitions={profileDefinitions}>
              {({ groupTypes, groups }) =>
                groupTypes.map(groupName => (
                  <Grid
                    container
                    key={groupName}
                    spacing={8}
                    className={classes.groupContainer}
                  >
                    <Grid item xs={12}>
                      <Typography
                        className={classes.groupsTypography}
                        variant="subtitle2"
                      >
                        {_t(groupName, LOCAL)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={32}>
                        {fieldGenerator({
                          group: groups[groupName],
                          wrapper: FieldWrapper,
                          formName: "myProfileForm",
                          visible: showAllCampos ? "showAll" : "visiblePerfil",
                          requerido: "requeridoPerfil",
                          disabled,
                          extraProps: {
                            Ubicacion: {
                              breakpoints: { xs: 12, sm: 4 },
                              wrapperBreakpoint: 12
                            }
                          },
                          numericDefinitionsValidations
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              }
            </FieldsProvider>
            <Grid item container xs={12} alignItems="flex-end">
              <Grid item xs={12} style={{ display: "-webkit-box" }}>
                <Typography
                  className={classes.groupsTypography}
                  variant="subtitle2"
                  gutterBottom
                >
                  {_t("Avatar")}
                </Typography>
                {loadingImage && (
                  <CircularProgress
                    className={classes.progress}
                    size={30}
                    color="primary"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={2}>
                <ImageUploader
                  onChangeImage={onChangeImage}
                  medidasRecomendadas={"80 x 80"}
                  disabled={disabled}
                  disableClick={disabled}
                >
                  <Avatar url={avatarUrl} />
                </ImageUploader>
              </Grid>
            </Grid>
            <Grid container justify="flex-end">
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={volver}
              >
                {_t("Volver")}
              </Button>
              {showSave && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={pristine || submitting}
                  className={classes.button}
                >
                  {_t("Guardar")}
                </Button>
              )}
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object,
  programa: PropTypes.object,
  handleSubmit: PropTypes.func,
  profileDefinitions: PropTypes.arrayOf(PropTypes.object),
  puntos: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      puntos: PropTypes.number
    })
  ),
  profile: PropTypes.object,
  history: PropTypes.object,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  onChangeImage: PropTypes.func
};

Profile.defaultProps = {
  initialValues: {}
};

Profile.displayName = "Profile";

export default Enhancer(Profile);
