import React, { PureComponent } from "react";
import cx from "classnames";
import AppQraphQL from "js/graphql/resolvers/perfil.resolver";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import AddIconIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { findIndex } from "lodash";
import { removeTypename } from "js/utils/Helper";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/HelpOutline";
import SortableComponent from "js/pages/Admin/Programa/Perfil/SortableComponent";
import Tooltip from "@material-ui/core/Tooltip";
import { _t } from "js/utils/TranslationService";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class Perfil extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      definicionPerfilValues: [],
      nombreAtributo: "",
      tipoAtributo: "Texto",
      showInfo: true
    };
  }

  getCamposPerfil() {
    const { client } = this.props;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return client
      .query({
        query: AppQraphQL.queries.getDefinicionesPerfilById,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: parseInt(id) || id
        }
      })
      .then(res => {
        if (!res.data.getDefinicionesPerfil.length) return;
        let values = [];
        res.data.getDefinicionesPerfil.forEach((v, i) => {
          values.push({
            ...v,
            key: i
          });
        });
        this.setState({
          definicionPerfilValues: values
        });
      });
  }

  async componentDidMount() {
    this.setState({
      disabled: !AuthService.hasPerms([Roles.DEFINICION_PERFIL_W])
    });
    await this.getCamposPerfil();
  }

  handleSortEnded(items) {
    this.setState({ definicionPerfilValues: items });
  }

  onClick(e, idRow, action = null, subtipo = null, originalValue = null) {
    const { definicionPerfilValues } = this.state;
    const index = findIndex(definicionPerfilValues, { key: idRow });
    let copy = Object.assign([], definicionPerfilValues);
    if (action === "delete") {
      copy.splice(index, 1);
    } else {
      let tempObj = {};
      const id = e.target.id || e.target.name;
      const value = e.target.value;
      tempObj = { ...definicionPerfilValues[index] };
      let result = value;
      if (action === "Lista" || action === "Lista_Multiple") {
        result = value.includes(",") ? value.split(",") : value;
      }
      if (action === "Ubicacion") {
        result = result.filter(subValue => subValue !== "");
      }
      if (action === "Numerico") {
        // Antes del "|" estará el minimo y después el máximo
        let limits = null;
        if (originalValue) {
          limits = originalValue.split("|");
        }
        if (subtipo === "min") {
          result = value + "|" + (limits ? limits[1] : "");
        }
        if (subtipo === "max") {
          result = (limits ? limits[0] : "") + "|" + value;
        }
        console.log(result);
      }
      tempObj[id] =
        action === "boolean" ? !definicionPerfilValues[index][id] : result;
      copy.splice(index, 1, tempObj);
    }
    this.labelHasError(copy);
  }

  handleChangeAttributo(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  labelHasError = definicionPerfilValues => {
    definicionPerfilValues.forEach(value => {
      value.labelHasError =
        !value.label.length ||
        definicionPerfilValues.filter(value2 => value2.label === value.label)
          .length > 1;
    });
    console.log(definicionPerfilValues);
    this.setState({ definicionPerfilValues });
  };

  addAtributo() {
    const { definicionPerfilValues, nombreAtributo, tipoAtributo } = this.state;
    if (!nombreAtributo || !tipoAtributo) return;
    const index = findIndex(definicionPerfilValues, { label: nombreAtributo });
    if (index === -1) {
      let tempObj = {};
      tempObj["label"] = nombreAtributo;
      tempObj["visibleRegistro"] = false;
      tempObj["requeridoRegistro"] = false;
      tempObj["visiblePerfil"] = false;
      tempObj["requeridoPerfil"] = false;
      tempObj["tipo"] = tipoAtributo;
      tempObj["value"] = "";
      tempObj["grupo"] = "";
      tempObj["key"] = definicionPerfilValues.length;

      const newArray = definicionPerfilValues.slice();
      newArray.push(tempObj);
      const message = "Atributo agregado correctamente";
      this.props.openNotification(message);
      this.setState({
        definicionPerfilValues: newArray,
        nombreAtributo: "",
        tipoAtributo: "Texto"
      });
    } else {
      const message = "No se puede crear un atributo ya existente";
      this.props.openNotification(message);
    }
  }

  async saveAction() {
    const {
      client,
      history,
      match: {
        params: { id }
      }
    } = this.props;
    let { definicionPerfilValues } = this.state;
    if (definicionPerfilValues.filter(v => v.labelHasError).length) return;
    definicionPerfilValues.forEach(element => {
      delete element.key;
      delete element.labelHasError;
    });
    await client
      .mutate({
        mutation: AppQraphQL.queries.bulkDefinicionPerfil,
        refetchQueries: [`getRedirect`],
        variables: {
          definicionesPerfiles: removeTypename(definicionPerfilValues),
          idPrograma: parseInt(id, 10)
        }
      })
      .then(() => {
        history.push("/admin/programa/" + id + "/gestion");
        this.props.openNotification(
          _t("Datos de perfil actualizados correctamente")
        );
      });
  }

  volver = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.props.history.push(`/admin/programa/${id}/gestion`);
  };

  changeIcon() {
    this.setState(prevState => ({ showInfo: !prevState.showInfo }));
  }

  render() {
    const {
      definicionPerfilValues,
      nombreAtributo,
      tipoAtributo,
      showInfo
    } = this.state;
    const { classes } = this.props;
    const { disabled } = this.state;
    return (
      <div>
        <ExpansionPanel
          expanded={showInfo}
          onChange={() => this.changeIcon()}
          classes={{ root: classes.expansionPanel }}
        >
          <ExpansionPanelSummary
            expandIcon={showInfo ? <ExpandMoreIcon /> : <InfoIcon />}
            classes={{ root: classes.expansionPanelText }}
          >
            <Typography variant="h5" gutterBottom>
              Datos del perfil
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="caption" gutterBottom>
              <u>Campos que tienen funcionalidad</u>:
              <ul>
                <li>DNI: para envío de sublotes de canjes a proveedores</li>
                <li>Email: para envío de notificaciones.</li>
                <li>
                  Fecha de Nacimiento: para envío de notificación de cumpleaños.
                </li>
                <li>
                  Fecha de Inicio: para envío de notificación de aniversario.
                </li>
                <li>
                  Nro. Socio LATAM Pass: para envío de sublotes de canjes a
                  proveedores
                </li>
              </ul>
              <b>
                <u>Importante:</u>
              </b>{" "}
              estos campos tienen que crearse con el texto exacto para que sean
              tenidos en cuenta para estas funcionalidades.
              <br />
              <br />
              <u>Consideraciones en algunos tipos de datos</u>:
              <ul>
                <li>
                  Número: El mínimo y el máximo son incluidos para el control de
                  este campo en el registro y el perfil
                </li>
                <li>
                  Lista: En el campo de valor, separar las opciones de la lista
                  con ","
                </li>
                <li>
                  Booleano con link: En el campo de valor, separar la url y la
                  parte del texto a poner como link con "|". Si no se pone
                  ninguna parte, todo el texto sería un link.
                  <br />
                  Ejemplo: Caso: 1 => Nombre = 'He leído los términos'. Valor =
                  'www.google.com'. En este caso todo el texto 'He leído los
                  términos' sería el link.
                  <br />
                  Caso 2 => Nombre = 'He leído los términos'. Valor =
                  'www.google.com|términos'. En este caso solo la parte
                  'términos' sería el link.
                </li>
                <li>
                  Ubicación: En el campo valor, elegir los campos de ubicación
                  que se quiere mostrar. No elegir ninguno es lo mismo que
                  elegir todos.
                  <br />
                  <u>IMPORTANTE:</u> elegir el campo "Localidad" sin el campo
                  "Zona" romperá el funcionamiento de la ubicación.
                  <br />
                  <u>IMPORTANTE:</u> elegir el campo "Zona" sin el campo "País"
                  solo tiene sentido cuando el programa tiene un solo país. Si
                  tiene varios paises el campo "País" tiene que ser elegido.
                </li>
              </ul>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <div className={classes.root}>
          {!disabled && (
            <div className={classes.addInput}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="name-simple">Nombre del campo</InputLabel>
                <Input
                  name="nombreAtributo"
                  value={nombreAtributo}
                  onChange={e => this.handleChangeAttributo(e)}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Select
                  name="tipoAtributo"
                  value={tipoAtributo}
                  onChange={e => this.handleChangeAttributo(e)}
                >
                  <MenuItem value={"Texto"}>Texto</MenuItem>
                  <MenuItem value={"Numerico"}>Número</MenuItem>
                  <MenuItem value={"Booleano"}>Booleano</MenuItem>
                  <MenuItem value={"Booleano_Link"}>Booleano con link</MenuItem>
                  <MenuItem value={"Lista"}>Lista</MenuItem>
                  <MenuItem value={"Lista_Multiple"}>Lista múltiple</MenuItem>
                  <MenuItem value={"Email"}>Email</MenuItem>
                  <MenuItem value={"Fecha"}>Fecha</MenuItem>
                  <MenuItem value={"Ubicacion"}>Ubicación</MenuItem>
                </Select>
              </FormControl>
              <Tooltip
                title="Agregar atributo"
                placement="top-start"
                enterDelay={300}
              >
                <IconButton onClick={this.addAtributo.bind(this)}>
                  <AddIconIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={cx(
                      classes.align,
                      classes.columnWidth100,
                      classes.border
                    )}
                  >
                    Nombre
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth100)}
                  >
                    Registro
                  </TableCell>
                  <TableCell
                    className={cx(
                      classes.align,
                      classes.columnWidth100,
                      classes.border
                    )}
                  >
                    Obligatorio
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth100)}
                  >
                    Perfil
                  </TableCell>
                  <TableCell
                    className={cx(
                      classes.align,
                      classes.columnWidth100,
                      classes.border
                    )}
                  >
                    Obligatorio
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth100)}
                  >
                    Tipo de campo
                  </TableCell>
                  <TableCell
                    className={cx(
                      classes.align,
                      classes.columnWidth100,
                      classes.border
                    )}
                  >
                    Valor
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth100)}
                  >
                    Grupo
                  </TableCell>
                </TableRow>
              </TableHead>
              <SortableComponent
                onClick={this.onClick.bind(this)}
                sortEnded={this.handleSortEnded.bind(this)}
                data={definicionPerfilValues}
                disabled={disabled}
              />
            </Table>
          </Paper>
          {!disabled && (
            <div className={classes.buttonSave}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={this.volver}
              >
                Volver
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.saveAction.bind(this)}
              >
                Guardar
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Perfil;
