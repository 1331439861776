import React from "react";
import EmailIcon from "@material-ui/icons/Email";
import client from "js/App/client.graphql.js";
import AppQraphQL from "js/graphql/resolvers/usuario.resolver";
import base from "./Base";
import AuthService from "js/utils/AuthService";

export default params => ({
  ...base(),
  label: "Sucursales",
  icon: <EmailIcon />,
  tooltip: "Blanquear password mandando mail",
  disabled: res => !res.item.perfil.email,
  show: () => AuthService.hasPerms(params.perms),
  onclick: () =>
    client
      .mutate({
        mutation: AppQraphQL.mutations.blanquearPassword,
        variables: {
          idUsuario: params.itemId
        },
        errorPolicy: "all"
      })
      .then(res => {
        res.errors && res.errors.length > 0
          ? params.openNotification(res.errors[0].message)
          : params.openNotification("La nueva clave ha sido enviada por email");
      })
      .catch(() => {
        params.openNotification("Error al intentar blanquear la clave");
      })
});
