import { connect } from "react-redux";
import Catalogo from "./Catalogo";
import { getIsLogged } from "js/redux/programa/selectors.js";
import { withRouter } from "react-router-dom";

const mapStateToProps = state => ({
  isLogged: getIsLogged(state)
});

export default connect(mapStateToProps)(withRouter(Catalogo));
