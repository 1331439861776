import React from "react";
import { Field } from "redux-form";
import FormControl from "@material-ui/core/FormControl";
import WysiwygPanel from "js/pages/Admin/Programa/Legales/WysiwygPanel/index.js";

class ReduxWysiwyg extends React.PureComponent {
  render() {
    const { inputName: name, className, validate, ...props } = this.props;

    return (
      <FormControl className={className} error style={{ width: "100%" }}>
        <Field
          name={name}
          className={className}
          validate={validate}
          component={({ input, meta: { touched, error, warning } }) => (
            <WysiwygPanel
              onChange={e => input.onChange(e.target.value)}
              value={input.value}
              initialValue={input.value}
              error={touched ? error : null}
              {...props}
            />
          )}
        />
      </FormControl>
    );
  }
}

export default ReduxWysiwyg;
