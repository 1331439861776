export default theme => ({
  facturaForm: {
    maxWidth: theme.container.maxWidth
  },
  textField: {
    width: "100%"
  },
  grid: {
    display: "grid"
  },
  button: {
    float: "right",
    margin: "2px"
  }
});
