import React from "react";
import PropTypes from "prop-types";
import LinkContentWrapper from "js/hoc/LinkContentWrapper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

const Column = ({ value, classes }) => {
  const titlesClassnames = classes.titleCenter;
  const listsItemsClassnames = classes.itemCenter;

  return (
    <Grid key={value.titulo} item>
      <Typography variant="body1" gutterBottom className={titlesClassnames}>
        {value.titulo}
      </Typography>
      <List dense>
        {value.links.map((contenido, index) => (
          <ListItem
            key={`${contenido.nombre}-${index}`}
            className={listsItemsClassnames}
          >
            <ListItemText
              className={classes.text}
              primary={
                <LinkContentWrapper
                  classes={classes}
                  link={contenido.link}
                  target={
                    contenido.target && typeof contenido.target === "string"
                      ? contenido.target
                      : ""
                  }
                  origen={"link-en-footer"}
                >
                  {contenido.texto}
                </LinkContentWrapper>
              }
            />
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

Column.propTypes = {
  classes: PropTypes.object
};

Column.defaultProps = {};

export default Column;
