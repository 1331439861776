import React, { Component } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ItemCustom from "./ItemCustom";

class ListCustom extends Component {
  render() {
    const { dato, title, classes, isSubItem = false } = this.props;
    return (
      <List
        component="nav"
        subheader={
          title ? (
            <ListSubheader
              component="div"
              disableSticky
              classes={{
                root: classes.listSubHeader
              }}
            >
              {title}
            </ListSubheader>
          ) : null
        }
      >
        {dato.map(item => (
          <ItemCustom
            item={item}
            isSubItem={isSubItem}
            key={`${item.id}+${item.request_id}`}
          />
        ))}
      </List>
    );
  }
}

export default ListCustom;
