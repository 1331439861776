import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import withPrograma from "js/hoc/withPrograma";
import CargaFacturasQraphQL from "js/graphql/resolvers/cargaFacturas.resolver";
import { required as requiredValidation } from "js/components/Shared/Form/validations.js";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { styles } from "js/components/Shared/Form/styles.js";
import { ReduxTypeAhead, TypeAhead } from "js/components/Shared/Form/Fields";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { _t } from "js/utils/TranslationService";

class ProductosField extends PureComponent {
  state = { productos: [] };

  componentDidMount = async () => {
    const {
      client,
      match: {
        params: { id }
      }
    } = this.props;
    // const hasPerms = AuthService.hasPerms([Roles.FACTURAS_R]);
    // if (!hasPerms) {
    //   this.setMiProducto();
    //   return;
    // }

    const page = {
      page: 1,
      rowsPerPage: 30,
      order: "asc",
      orderBy: "nombre",
      idPrograma:
        parseInt(id) || id || parseInt(localStorage.getItem("idPrograma"))
    };

    const { data } = await client.query({
      query: CargaFacturasQraphQL.queries.getProductosCargaFacturas,
      variables: {
        ...page
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache"
    });

    if (data) {
      this.setState({ productos: data.getProductosCargaFacturas.itemsPagina });
    }
  };

  render() {
    const {
      classes,
      disabled,
      required,
      name = "idPrograma",
      match: { path },
      redux = false,
      filterByType,
      ...props
    } = this.props;
    let { productos } = this.state;

    if (filterByType) {
      productos = productos.filter(programa => programa.tipo === filterByType);
    }

    const Component = redux ? ReduxTypeAhead : TypeAhead;
    const rest = redux ? {} : props;

    return (
      <Component
        styles={{
          whiteSpace: "normal"
        }}
        name={name}
        label={`Buscar por ${_t("Productos").toLowerCase()}`}
        className={classes ? classes.field : styles.field}
        validate={required ? requiredValidation : null}
        suggestions={{ productos }}
        path={{
          path: "productos",
          value: "codigo",
          label: "nombre"
        }}
        placeholder={_t("Productos")}
        {...rest}
      />
    );
  }
}

ProductosField.defaultProps = {
  search: () => undefined,
  onChange: () => undefined
};

const enhance = compose(
  withPrograma,
  withRouter,
  withStyles(styles),
  withApollo
);

export default enhance(ProductosField);
