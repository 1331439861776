import { compose, pure, withStateHandlers, withProps } from "recompose";
import moment from "moment";
import {
  startOfMonth,
  endOfMonth,
  subMonths,
  subDays,
  startOfDay,
  endOfDay
} from "date-fns";
import withStyles from "./styles";
import reportes from "../reportsList";

export const rangeOptions = [
  {
    range: {
      from: startOfMonth(subMonths(new Date(), 6)),
      to: endOfMonth(subMonths(new Date(), 1))
    },
    value: "custom",
    label: "Personalizada"
  },
  {
    range: {
      from: startOfDay(new Date()),
      to: endOfDay(new Date())
    },
    value: "hoy",
    label: "Hoy"
  },
  {
    range: {
      from: startOfMonth(new Date()),
      to: endOfMonth(new Date())
    },
    value: "esteMes",
    label: "Este mes"
  },
  {
    range: {
      from: startOfMonth(subMonths(new Date(), 1)),
      to: endOfMonth(subMonths(new Date(), 1))
    },
    value: "mesPasado",
    label: "Mes pasado"
  },
  {
    range: { to: new Date(), from: subDays(new Date(), 6) },
    value: "sevenDays",
    label: "7 días"
  },
  {
    range: {
      from: subDays(new Date(), 30),
      to: new Date()
    },
    value: "thirtyDays",
    label: "30 días"
  }
];

const enhance = compose(
  pure,
  withStyles,
  withProps(props => {
    const { ...rest } = props;
    const match = props.computedMatch ? props.computedMatch : props.match;
    return {
      idReport: +match.params.idReport,
      ...rest
    };
  }),
  withProps(({ idReport }) => ({
    reporte: reportes.find(({ number }) => number === idReport)
  })),
  withStateHandlers(
    ({ reporte: { defaultFilter = {} } }) => {
      return {
        range: "custom",
        dates: rangeOptions.find(r => r.value === "custom").range,
        idPrograma: null,
        idSegmento: null,
        idUsuario: null,
        modulo: null,
        timestamp: moment().toISOString(),
        ...defaultFilter
      };
    },
    {
      onTypeChange: () => ({ target: { value: newValue } }) => {
        const { from, to } = rangeOptions.find(
          ({ value }) => value === newValue
        ).range;
        return {
          range: newValue,
          dates: {
            from: from ? moment(from) : null,
            to: to ? moment(to) : null
          }
        };
      },
      onDateChange: ({ range, dates }) => (name, date) => ({
        range: "custom",
        dates: {
          ...dates,
          [name]: date
        }
      }),
      onProgramaIdChange: () => programa => ({
        idPrograma: programa.value,
        idSegmento: null,
        idUsuario: null
      }),
      onSegmentoIdChange: () => segmento => ({
        idSegmento: segmento.value
      }),
      onUsuarioIdChange: () => usuario => ({
        idUsuario: usuario.value
      }),
      onModuloChange: () => modulo => ({
        modulo: modulo.value
      }),
      onRefresh: () => () => ({ timestamp: moment().toISOString() })
    }
  )
);

export default enhance;
