import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import base from "js/components/Shared/Tabla/ActionCells/Base";
import AuthService from "js/utils/AuthService";

export default ({ perms = [], optional = [], history, itemId, item }) => ({
  ...base(),
  label: "Porcentajes preventistas",
  icon: <PeopleIcon />,
  tooltip: "Porcentajes preventistas",
  show: () => {
    if (AuthService.hasPerms(perms) && item.tipo === "CONVENCIONAL") {
      return true;
    }
    return false;
  },
  onclick: () =>
    history.push(window.location.pathname + "/" + itemId + "/preventistas")
});
