import React from "react";
import PropTypes from "prop-types";
import GoogleChart from "js/components/GoogleChart";
import { numberWithCommas } from "js/utils";
import moment from "moment";
const getAnoMes = date => {
  return moment(date, "YYYYMM").format("MM/YY");
};

const TicketPromedio = ({ data }) => {
  const hayInfo = !!data.length;
  return hayInfo ? (
    <GoogleChart
      height={"350px"}
      width={"100%"}
      chartType="ColumnChart"
      chartLanguage="it"
      data={[
        ["Mes", "Ticket Promedio", { role: "tooltip" }, { role: "style" }],
        ...data.map(({ anoMes, ticketPromedio }) => [
          getAnoMes(anoMes),
          ticketPromedio,
          `Ticket Promedio: $ ${numberWithCommas(ticketPromedio)}`,
          `color: #126FFF`
        ])
      ]}
      legend={{ position: "bottom", alignment: "start" }}
      options={{
        title: "Ticket Promedio",
        fontName: "Sueca Nano",
        titleTextStyle: {
          color: "#6A696B",
          fontSize: 20,
          bold: false
        },
        vAxis: {
          format: "###,###",
          gridlines: {
            count: -1,
            color: "#E8E8E8"
          },
          formatOptions: {
            groupingSymbol: ".",
            prefix: "$"
          },
          minValue: 0,
          minorGridlines: {
            color: "#E8E8E8"
          },
          textStyle: {
            color: "#858585"
          },
          baselineColor: "#E8E8E8"
        },
        hAxis: {
          textStyle: {
            color: "#858585"
          }
        },
        chartArea: { left: 45, width: "82%", height: "45%" },
        bars: "vertical",
        bar: { groupWidth: "15%" },
        legend: "none"
      }}
      chartEvents={[
        {
          eventName: "ready",
          callback: Chart => {
            let container = document.getElementById(
              Chart.chartWrapper.getContainerId()
            );
            container.style.margin = "0 auto";
            container.style.width = "93%";
          }
        }
      ]}
    />
  ) : (
    <GoogleChart
      height={"350px"}
      width={"100%"}
      chartType="ColumnChart"
      chartLanguage="it"
      data={[["Mes", "Ticket Promedio"], [0, 0]]}
      legend={{ position: "bottom", alignment: "start" }}
      options={{
        title: "Ticket Promedio",
        fontName: "Sueca Nano",
        titleTextStyle: {
          color: "#6A696B",
          fontSize: 20,
          bold: false
        },
        vAxis: {
          format: "###,###",
          gridlines: {
            count: -1,
            color: "#E8E8E8"
          },
          formatOptions: {
            groupingSymbol: ".",
            prefix: "$"
          },
          minValue: 0,
          minorGridlines: {
            color: "#E8E8E8"
          },
          textStyle: {
            color: "#858585"
          },
          baselineColor: "#E8E8E8",
          viewWindow: {
            min: 0,
            max: 5
          }
        },
        hAxis: {
          textStyle: {
            color: "#858585"
          },
          viewWindow: {
            min: 0,
            max: 5
          }
        },
        chartArea: { left: 45, width: "82%", height: "45%" },
        bars: "vertical",
        bar: { groupWidth: "15%" },
        legend: "none"
      }}
      chartEvents={[
        {
          eventName: "ready",
          callback: Chart => {
            let container = document.getElementById(
              Chart.chartWrapper.getContainerId()
            );
            container.style.margin = "0 auto";
            container.style.width = "93%";
          }
        }
      ]}
    />
  );
};

TicketPromedio.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

TicketPromedio.defaultProps = {
  data: []
};

TicketPromedio.displayName = "TicketPromedio";

export default TicketPromedio;
