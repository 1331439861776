import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import { Paper, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";

import { numberWithCommas } from "js/utils";
import { _t } from "js/utils/TranslationService";
import styles from "./indicadorStyles.js";

const RIGHT_OFFSET = 25;
const LEFT_OFFSET = 10;
const BARRA_HEIGHT = 80;
const EXCESS_WIDTH = 15;
const BORDER_WIDTH = 2;
const TOP_TEXT_OFFSET = 35;
const BOTTOM_TEXT_OFFSET = 35;

class BarrasHorizontalIndicator extends PureComponent {
  constructor(props) {
    super(props);
    let tieneBorde = true;
    if (typeof props.parametrosVisuales.tieneBorde !== "undefined") {
      tieneBorde = props.parametrosVisuales.tieneBorde;
    }

    this.state = {
      borderWidth: tieneBorde
        ? props.parametrosVisuales.anchoBorde
          ? props.parametrosVisuales.anchoBorde
          : BORDER_WIDTH
        : 0
    };
  }

  componentDidMount() {
    this.setCanvasesDimensions();
    this.createBarra();
  }

  /**
   * Retorna el porcentaje del targetAlcanzado con un máximo de 999
   * @param {target} target
   * @param {performance} performance
   */
  getPercentaje = (target, performance) => {
    const result = (performance * 100) / target;
    return result > 100 ? 100 : result;
  };

  setCanvasesDimensions() {
    const { index } = this.props;
    const canvases = document.querySelectorAll(
      '[class$="canvas-' + index + '"]'
    );
    canvases.forEach(canvas => {
      const parent = canvas.parentElement;
      // - 100 por los paddings horizontales y -40 por los paddings verticales
      const rightPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-right")
      );
      const leftPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-left")
      );
      const topPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-top")
      );
      const bottomPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-bottom")
      );
      canvas.setAttribute(
        "width",
        parent.clientWidth - (rightPadding + leftPadding)
      );
      canvas.setAttribute(
        "height",
        parent.clientHeight - (topPadding + bottomPadding)
      );
    });
  }

  createBarra() {
    const {
      index,
      performanceObjetivo: { performance, targetsObjetivo },
      parametrosVisuales: {
        alturaBarra = BARRA_HEIGHT,
        colorFondo = "#ccc",
        colorAvance = "#ce762e",
        tieneBorde = true,
        colorBorde = "#000",
        colorTextoTarget = "#4D5D67",
        colorLineaTarget = "#4D5D67",
        colorTextoAvance = "#ce762e",
        colorLineaAvance = "#ce762e",
        sizeNumeros = "16",
        sizeTextos = "13",
        colorCheckIcon = "#4EC230",
        showPuntosTargets = true
      }
    } = this.props;
    const { borderWidth } = this.state;

    let target = 0;
    targetsObjetivo.forEach(targetObjetivo => {
      if (target < targetObjetivo.target) {
        target = targetObjetivo.target;
      }
    });

    const objectiveCanvas = document.getElementById(
      "objetivo-percentage-canvas-" + index
    );
    const lineCanvas = document.getElementById("objetivo-line-canvas-" + index);
    const width = objectiveCanvas.clientWidth;
    const height = objectiveCanvas.clientHeight;
    const finalWidth = width - LEFT_OFFSET - RIGHT_OFFSET;

    if (tieneBorde) {
      this.drawBorder(
        objectiveCanvas,
        LEFT_OFFSET,
        height / 2 - alturaBarra / 2,
        finalWidth,
        alturaBarra,
        colorBorde
      );
    }

    // barra entera con color del fondo
    this.drawBarra(
      objectiveCanvas,
      LEFT_OFFSET,
      height / 2 - alturaBarra / 2,
      finalWidth,
      alturaBarra,
      colorFondo,
      finalWidth
    );

    // barra del avance
    const performancePercentage = this.getPercentaje(target, performance);
    const avanceWidth = (finalWidth * performancePercentage) / 100;
    this.drawBarra(
      objectiveCanvas,
      LEFT_OFFSET,
      height / 2 - alturaBarra / 2,
      avanceWidth >= finalWidth
        ? avanceWidth + EXCESS_WIDTH - borderWidth
        : avanceWidth === 0
          ? 0
          : avanceWidth - borderWidth,
      alturaBarra,
      colorAvance,
      finalWidth
    );

    // draw linea del acvance
    this.drawLine(
      lineCanvas,
      avanceWidth >= finalWidth
        ? avanceWidth + (LEFT_OFFSET + EXCESS_WIDTH)
        : avanceWidth === 0
          ? LEFT_OFFSET
          : avanceWidth + LEFT_OFFSET,
      height / 2 + alturaBarra / 2,
      0,
      colorLineaAvance,
      _t("Avance actual"),
      colorTextoAvance,
      numberWithCommas(performance),
      colorTextoAvance,
      "top",
      finalWidth,
      sizeTextos,
      sizeNumeros
    );

    const showTargetNumber = targetsObjetivo.length > 1;
    const checkIconsPositions = [];
    targetsObjetivo.forEach((targetObjetivo, index) => {
      const targetPercentage = this.getPercentaje(
        target,
        targetObjetivo.target
      );
      const targetWidth = (finalWidth * targetPercentage) / 100;

      this.drawLine(
        lineCanvas,
        targetWidth + LEFT_OFFSET,
        height / 2 - alturaBarra / 2,
        height,
        colorLineaTarget,
        `${_t("Target")} ${showTargetNumber ? index + 1 : ""} ${
          targetObjetivo.puntos && showPuntosTargets
            ? `(${numberWithCommas(targetObjetivo.puntos)} ${_t("puntos")})`
            : ""
        }`,
        colorTextoTarget,
        numberWithCommas(targetObjetivo.target),
        colorTextoTarget,
        "bottom",
        finalWidth,
        sizeTextos,
        sizeNumeros
      );

      if (performance >= targetObjetivo.target) {
        checkIconsPositions.push({
          x: targetWidth + LEFT_OFFSET,
          y: height
        });
      }
    });

    if (checkIconsPositions.length !== 0) {
      const container = document.getElementById(`icon-container-${index}`);
      ReactDOM.render(
        this.renderCheckIcons(checkIconsPositions, index, colorCheckIcon),
        container
      );
    }
  }

  drawBorder(canvas, x, y, width, height, color) {
    const { borderWidth } = this.state;
    if (canvas.getContext) {
      const ctx = canvas.getContext("2d");
      ctx.beginPath();
      ctx.fillStyle = color;
      ctx.rect(x, y, width + borderWidth, height + borderWidth);
      ctx.fill();
    }
  }

  drawBarra(canvas, x, y, width, height, color, fullWidth) {
    const { borderWidth } = this.state;
    if (canvas.getContext) {
      const ctx = canvas.getContext("2d");
      ctx.beginPath();
      ctx.fillStyle = color;
      ctx.strokeStyle = color;
      ctx.rect(
        x + borderWidth,
        y + borderWidth,
        width === fullWidth ? width - borderWidth : width,
        height - borderWidth
      );
      ctx.fill();
    }
  }

  drawLine(
    canvas,
    x,
    y,
    endY,
    color,
    topText,
    topColor,
    bottomText,
    bottomColor,
    align,
    fullWidth,
    topSize,
    bottomSize
  ) {
    const {
      parametrosVisuales: { fontFamily = "Roboto" }
    } = this.props;
    if (canvas.getContext) {
      let finalY = endY;
      let textAlign = "center";

      if (topText || bottomText) {
        finalY =
          align === "top"
            ? finalY + TOP_TEXT_OFFSET
            : finalY - BOTTOM_TEXT_OFFSET;
      }

      if (LEFT_OFFSET <= x && x <= LEFT_OFFSET * 3) {
        textAlign = "left";
      }

      if (fullWidth - RIGHT_OFFSET * 3 <= x && x <= fullWidth + RIGHT_OFFSET) {
        textAlign = "right";
      }

      const ctx = canvas.getContext("2d");
      ctx.setLineDash([6, 2]);
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x, finalY);
      ctx.strokeStyle = color;
      ctx.stroke();
      if (topText) {
        ctx.font = `italic ${topSize}px ${fontFamily}`;
        ctx.fillStyle = topColor;
        ctx.textAlign = textAlign;
        ctx.fillText(
          topText.trim(),
          x,
          align === "top" ? endY + 15 : endY - 20
        );
      }
      if (bottomText) {
        ctx.font = `bold ${bottomSize}px ${fontFamily}`;
        ctx.fillStyle = bottomColor;
        ctx.textAlign = textAlign;
        ctx.fillText(bottomText.trim(), x, align === "top" ? endY + 35 : endY);
      }
    }
  }

  renderCheckIcons(checkIcons, index, colorCheckIcon) {
    return (
      <React.Fragment>
        {checkIcons.map((checkIcon, i) => (
          <CheckIcon
            key={`icon-${i}-${index}`}
            style={{
              color: colorCheckIcon,
              fill: colorCheckIcon,
              position: "absolute",
              left: checkIcon.x,
              top: checkIcon.y,
              transform: "translateX(-50%)"
            }}
          />
        ))}
      </React.Fragment>
    );
  }

  renderObjetivo() {
    const {
      index,
      classes,
      performanceObjetivo: { objetivo },
      parametrosVisuales: {
        colorNombreObjetivo = "#4D5D67",
        sizeNombreObjetivo = "16",
        fontFamily = "Roboto"
      }
    } = this.props;
    return (
      <Grid
        className={classes.objetivoWrapper}
        container
        spacing={8}
        id={`objetivo-${index}`}
      >
        <Grid
          className={classes.objetivoTitle}
          style={{
            color: colorNombreObjetivo,
            fontSize: `${sizeNombreObjetivo}px`,
            fontFamily: fontFamily
          }}
          item
          xs={12}
        >
          {objetivo.nombre}
        </Grid>
        <Grid
          className={classNames(
            classes.chartContainer,
            classes.barraHorizontalChart
          )}
          item
          xs={12}
        >
          <canvas
            id={"objetivo-percentage-canvas-" + index}
            className={classNames(
              classes.canvas,
              "objetivo-percentage-canvas-" + index
            )}
          />
          <canvas
            id={"objetivo-line-canvas-" + index}
            className={classNames(
              classes.canvas,
              classes.indicadorCanvas,
              "objetivo-line-canvas-" + index
            )}
          />
          <div
            id={`icon-container-${index}`}
            style={{ width: "100%", height: "100%", position: "relative" }}
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      classes,
      parametrosVisuales: { containerShadow }
    } = this.props;

    return (
      <React.Fragment>
        {containerShadow ? (
          <Paper className={classes.objetivoContainer} elevation={1}>
            {this.renderObjetivo()}
          </Paper>
        ) : (
          <React.Fragment>{this.renderObjetivo()}</React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

BarrasHorizontalIndicator.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.string,
  performanceObjetivo: PropTypes.any,
  parametrosVisuales: PropTypes.any
};

export default withStyles(styles)(BarrasHorizontalIndicator);
