import { withStyles } from "@material-ui/core/styles";
import DetalleAccion from "./DetalleAccion";
import styles from "./styles";
import { compose, withProps } from "recompose";
import { withRouter } from "react-router-dom";

const enhance = compose(
  withStyles(styles),
  withRouter,
  withProps(({ computedMatch: { params: { idAccion } } }) => ({
    idAccion
  }))
);
export default enhance(DetalleAccion);
