import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import AppQraphQL from "js/graphql/resolvers/locacion.resolver";
import { _t } from "js/utils/TranslationService";
import { ListofChildrenField } from "./index.js";
import { isNil } from "lodash";
import AuthService from "js/utils/AuthService";

class ZonaField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      zonas: []
    };
  }

  async componentDidMount() {
    const { idPais, showPais, isUbicacion } = this.props;

    if (isUbicacion) {
      if (!showPais && !idPais) {
        const paisesPrograma = AuthService.getPaisesPrograma();
        if (paisesPrograma && paisesPrograma.length !== 0) {
          this.fetchZonas(paisesPrograma[0].idPais);
        }
      }
    }
  }

  componentWillReceiveProps(props) {
    const { idPais, changeFieldValue, formMeta } = props;
    if (idPais) {
      this.fetchZonas(idPais);
    }
    if (formMeta.idZona && formMeta.idZona.active) {
      changeFieldValue("idLocalidad", null);
    }
  }

  async fetchZonas(idPais) {
    const { client } = this.props;
    await client
      .query({
        query: AppQraphQL.queries.getMasterZonas,
        fetchPolicy: "cache-first",
        errorPolicy: "all",
        variables: { idPais: parseInt(idPais, 10) }
      })
      .then(res => {
        this.setState({
          zonas: res.data.getMasterZonas
        });
      });
  }

  render() {
    const { idPais, required, disabled, name, showPais } = this.props;
    const { zonas } = this.state;

    return (
      <ListofChildrenField
        name={name || "idZona"}
        label={`${_t("Zona")}`}
        disabled={(isNil(idPais) && showPais) || disabled}
        required={required}
      >
        {zonas.map((item, index) => (
          <MenuItem value={parseInt(item.idZona, 10)} key={index}>
            {item.descripcion}
          </MenuItem>
        ))}
      </ListofChildrenField>
    );
  }
}

ZonaField.propTypes = {
  idZona: PropTypes.number
};

export default withApollo(withRouter(ZonaField));
