export default {
  tipo: "slider",
  bases: [
    {
      tipo: "base",
      src:
        "https://qa-img.liza.ashiwea.com/imagenes/image/64010353404952135A20190228173407801Z.png",
      link: "",
      subTexto: "Subtexto",
      texto: {
        contenido: "Slide 1",
        css: {
          fontFamily: "Roboto",
          color: "#ffffff"
        }
      }
    },
    {
      tipo: "base",
      src:
        "https://qa-img.liza.ashiwea.com/imagenes/image/10234799893991231A20190228173444042Z.png",
      link: "",
      subTexto: "Subtexto",
      texto: {
        contenido: "Slide 2",
        css: {
          color: "#ffffff",
          fontFamily: "Roboto"
        }
      }
    }
  ],
  spaceBetween: false,
  sliderOptions: {
    autoPlay: true,
    stopOnHover: true,
    infiniteLoop: true,
    showIndicators: false,
    interval: 5000,
    transitionTime: 350,
    arrowStyle: "circle",
    showArrows: true
  }
};
