import React from "react";
import PropTypes from "prop-types";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

import Enhancer from "./container";

const UnitPicker = ({ pixels, onChange, classes, disabled = false, unit }) => (
  <FormControl className={classes.FormControl} variant="outlined">
    <OutlinedInput
      value={pixels}
      disabled={disabled}
      type="number"
      labelWidth={0}
      onChange={e => onChange(parseInt(e.target.value, 10))}
      endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
    />
  </FormControl>
);

UnitPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  pixels: PropTypes.string.isRequired,
  classes: PropTypes.object,
  unit: PropTypes.string
};

UnitPicker.defaultProps = {
  pixels: 0,
  unit: ""
};

UnitPicker.displayName = "UnitPicker";

export default Enhancer(UnitPicker);
