const getInitialState = initialData => ({
  cantidad: 1,
  datosPerfil: "",
  notificationMensaje: "",
  datosRequeridos: initialData.producto.datosRequeridos,
  eleccionProducto: initialData,
  favorito: initialData.favorito,
  idEleccionProducto: initialData.idEleccionProducto,
  idProducto: initialData.producto.idProducto,
  notificationIsOpen: false,
  openCanjePopup: false,
  producto: initialData.producto,
  puntos: initialData.puntos,
  sucursales: (initialData.producto.sucursales || []).filter(
    s => s.estado === "ACTIVO" && s.nombre.toLowerCase() !== "default"
  ),
  idSucursal: 0,
  idCanje: false,
  mensajeRequeridos: "",
  canjeFail: "",
  requeridos: {
    dni: "",
    franja: "",
    telefonos: "",
    fechaNacimiento: "",
    lanpass: "",
    sucursal: 0,
    entrega: JSON.stringify({
      direccion: "",
      cp: "",
      idPais: "",
      idZona: "",
      idLocalidad: ""
    })
  }
});

export default getInitialState;
