import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Slide
} from "@material-ui/core";

import { withNotificationContext } from "js/notification-context";
import AppQraphQLColaDescarga from "js/graphql/resolvers/colaDescarga.resolver";
import { _t } from "js/utils/TranslationService";

import { apiURL } from "js/constants";

const styles = theme => ({
  dialogContent: {
    overflow: "visible"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class SimpleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: this.props.grupoSKU ? this.props.grupoSKU.nombre : "",
      file: null,
      error: undefined,
      idPrograma: this.props.match.params.id,
      idUsuario: parseInt(localStorage.getItem("idUsuario"))
    };
  }

  sendValues = async () => {
    const { idPrograma, idUsuario } = this.state;
    const { client, onTableChange, refetch, proceso } = this.props;
    this.setState({ error: undefined });

    let varPrograma;
    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;
    if (!inBackOffice) {
      varPrograma = parseInt(localStorage.getItem("idPrograma"));
    } else {
      varPrograma = idPrograma;
    }

    let varproceso;
    // eslint-disable-next-line default-case
    switch (proceso) {
      case "movimientos/usuario":
        varproceso = "MovimientosUsuario";
        break;
      case "movimientos/canjes":
        varproceso = "MovimientosCanje";
        break;
      case "movimientos/programa":
        varproceso = "MovimientosPrograma";
        break;
      case "usuarios":
        varproceso = "Usuarios";
        break;
      case "importCodigos":
        varproceso = "ImportCodigos";
        break;
    }
    await fetch(apiURL + "lambda/" + varproceso, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ idUsuario: idUsuario, idPrograma: varPrograma })
    })
      .then(res => {
        this.props.openNotification("Se procesará en breve.");
        this.setState({ nombre: "", file: null });
        if (onTableChange) {
          onTableChange();
        } else if (refetch) {
          refetch();
        }
        this.props.handleClose();
      })
      .catch(error => {
        this.props.openNotification(
          'Hubo un error al procesar el archivo. Ver los detalles en "Logs"'
        );
        console.log("ERROR ", error);
      });
  };

  handleChangeNombre = e => {
    this.setState({ nombre: e.target.value });
  };

  onFileChange = e => {
    let files = e.target.files;
    this.setState({ file: files[0] }, () => {
      console.log(this.state.file);
    });
  };

  render() {
    const { handleClose, classes, open } = this.props;

    return (
      <Fragment>
        <Dialog
          open={typeof open === "undefined" ? true : open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={classes.dialogTitle}
          >
            "Cuando finalice el proceso se comunicara via mail. Click en aceptar
            para continuar."
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Cancelar
            </Button>
            <Button
              onClick={this.sendValues}
              variant="contained"
              color="primary"
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const enhance = compose(
  withApollo,
  withNotificationContext,
  withRouter,
  withStyles(styles)
);

export default enhance(connect(null)(SimpleModal));
