import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { InputNumberField } from "js/components/Shared/Form/Fields";

export default props => {
  const { name, disabled } = props;
  return (
    <Fragment>
      <Grid item xs={12} sm={4}>
        <InputNumberField
          name={`${name}.rangoInferior`}
          label="Rango inferior"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputNumberField
          name={`${name}.rangoSuperior`}
          label="Rango superior"
          disabled={disabled}
        />
      </Grid>
    </Fragment>
  );
};
