import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { reduxForm, change, formValueSelector } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ErrorList from "js/components/Shared/ErrorList";
import {
  ListField,
  InputTextField,
  InputNumberField
} from "js/components/Shared/Form/Fields";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class Form extends PureComponent {
  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      classes,
      history,
      disabled,
      errors,
      idPuntoDeVenta
    } = this.props;

    const urls = {
      full: "",
      short: ""
    };

    if (window.location.pathname.indexOf("admin") !== -1) {
      urls.full = window.location.href.match(/(\/admin.*puntos-de-venta)/)
        ? window.location.href.match(/(\/admin.*puntos-de-venta)/)[0]
        : "admin/dashboard";
      const {
        match: {
          params: { id, idPuntoDeVenta }
        }
      } = this.props;
      urls.short = `/admin/programa/${id}/puntos-de-venta/${idPuntoDeVenta}`;
    }
    //CONFIRMAR SI ESTE MODULO TIENE SALIDA A CLIENTE FINAL
    /* else {
      urls.full = "/usuarios";
      urls.short = "/usuarios/";
    } */

    return (
      <>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Typography variant="h5" style={{ flex: 1 }}>
              {disabled
                ? "Ver "
                : idPuntoDeVenta === "new"
                  ? "Nuevo "
                  : "Modificar "}
            </Typography>
          </Grid>
        </Grid>
        <form className={classes.container} onSubmit={handleSubmit}>
          <Grid className={classes.grid} container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Datos del punto de venta
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputTextField
                required
                name="nombre"
                label="Nombre del punto de venta"
                autoComplete="off"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ListField
                name="estado"
                label="Estado"
                options={[
                  { label: "Activo", value: "ACTIVO" },
                  { label: "Inactivo", value: "INACTIVO" }
                ]}
                disabled={disabled || idPuntoDeVenta === "new"}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputNumberField
                name="multiplicador"
                label="Multiplicador"
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <ErrorList errors={errors} />
          <Grid className={classes.grid} container>
            <Grid item xs />
            <Grid item xs />
            <Grid className={classes.gridButton} item xs>
              <Button
                type="button"
                variant="outlined"
                size="large"
                color="primary"
                className={classes.button}
                onClick={e => history.push(urls.full)}
              >
                Cancelar
              </Button>
            </Grid>
            {AuthService.hasPerms([Roles.PUNTO_VENTA_W]) && (
              <Grid className={classes.gridButton} item xs>
                {disabled && (
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    onClick={() => history.push(urls.short + "/edit")}
                  >
                    Editar
                  </Button>
                )}
                {!disabled && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    disabled={pristine || submitting}
                  >
                    Guardar
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      </>
    );
  }
}

Form = reduxForm({
  form: "formPuntoDeVenta",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formPuntoDeVenta");

Form = connect(
  state => ({
    idPrograma: selector(state, "idPrograma")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formPuntoDeVenta", field, value))
  })
)(Form);

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: theme.container.maxWidth,
    margin: theme.container.margin
  },
  field: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  grid: {
    margin: "0"
  },
  gridButton: {
    marginTop: "20px",
    textAlign: "end",
    marginLeft: 20
  },
  button: {
    width: "200px"
  }
});

export default withRouter(withStyles(styles)(Form));
