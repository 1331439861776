const styles = theme => ({
  Row: {
    marginBottom: theme.spacing.unit * 2
  },
  form: {
    margin: theme.spacing.unit * 2
  },
  select: {
    width: "90%"
  },
  base: {},
  imageURL: {},
  caption: {},
  texto: {},
  subtexto: {},
  uploadIcon: {
    marginLeft: "10px",
    marginTop: "10px"
  }
});

export default styles;
