import Tabla from "./tabla";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";

const enhance = compose(
  withRouter,
  withApollo
);

export default enhance(Tabla);

// export default withRouter(withApollo(Tabla));
