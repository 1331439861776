import MyForm from "./MyForm";
import { compose, withProps } from "recompose";
import { withNotificationContext } from "js/notification-context";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { styles } from "js/components/Shared/Form/styles.js";
import styles2 from "./styles.js";
import { reduxForm } from "redux-form";
import JerarquiaQraphQL from "js/graphql/resolvers/jerarquia.resolver";

import { graphql } from "react-apollo";

const enhance = compose(
  withNotificationContext,
  withRouter,
  withStyles(styles),
  withStyles(styles2),
  withProps(() => ({
    values: {}
  })),
  graphql(JerarquiaQraphQL.mutations.setJerarquia, {
    name: "submitMutation"
  }),
  reduxForm({
    form: "jerarquiaForm",
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
  })
);

export default enhance(MyForm);
