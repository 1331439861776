import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { CatalogoContext } from "../../catalogo-context";
import { _t } from "js/utils/TranslationService";

const valuesMap = new Map();

valuesMap.set("NOMBRE_ASC", { column: "nombre", order: "asc" });
valuesMap.set("NOMBRE_DESC", { column: "nombre", order: "desc" });

const getValue = ([{ column, order }]) => {
  for (var [key, value] of valuesMap) {
    if (value.column === column && value.order === order) return key;
  }
};

class FilterSort extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <CatalogoContext.Consumer>
        {({ filterBy, searchCriteria }) => (
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="sort-products">
              {_t("Ordernar por")}
            </InputLabel>
            <Select
              value={getValue(searchCriteria.beneficioOrder)}
              onChange={event =>
                filterBy.beneficioOrder([valuesMap.get(event.target.value)])
              }
              inputProps={{
                name: "sort",
                id: "sort-products"
              }}
            >
              <MenuItem value="NOMBRE_ASC">A-Z</MenuItem>
              <MenuItem value="NOMBRE_DESC">Z-A</MenuItem>
            </Select>
          </FormControl>
        )}
      </CatalogoContext.Consumer>
    );
  }
}

FilterSort.propTypes = {};

export default FilterSort;
