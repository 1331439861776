import { connect } from "react-redux";
import { compose, pure } from "recompose";
import { withApollo } from "react-apollo";
import { arrayPush, arrayRemoveAll } from "redux-form";

import withStyles from "./styles";

const mapStateToProps = null;
const mapDispatchToProps = {
  addCodigo: codigo => arrayPush("formProducto", "codigosActivacion", codigo),
  clearCodigos: () => arrayRemoveAll("formProducto", "codigosActivacion"),
};

export default compose(
  withApollo,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles,
  pure
);
