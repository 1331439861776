import TargetsCampana from "./TargetsCampana";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { withNotificationContext } from "js/notification-context";
import { withRouter } from "react-router-dom";

const enhance = compose(
  withApollo,
  withNotificationContext,
  withRouter
);

export default enhance(TargetsCampana);
