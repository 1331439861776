import { compose } from "recompose";
import { connect } from "react-redux";

import { updateTemplate } from "js/redux/programa";
import { getTemplate } from "js/redux/programa/selectors.js";
import { getChatOnline } from "js/redux/programa/selectors.js";
import Access from "./Access";
import withKibanaTracker from "js/hoc/withKibanaTracker";

const mapStateToProps = state => ({
  theme: getTemplate(state),
  chatOnline: getChatOnline(state)
});

const mapDispatchToProps = dispatch => ({
  updateTemplate: updateTemplate(dispatch)
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withKibanaTracker
)(Access);
