import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { DatePicker } from "material-ui-pickers";
import { TypeAhead, ProgramaField } from "js/components/Shared/Form/Fields";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { TIPO_LOG, MIS_TIPOS_LOGS } from "js/constants";

class advancedFilter extends PureComponent {
  state = { data: {} };
  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  render() {
    const { value, submitValue, search, classes } = this.props;

    let hideProgramField = false;
    let tipos = TIPO_LOG;
    if (window.location.pathname.indexOf("admin") === -1) {
      hideProgramField = true;
      tipos = MIS_TIPOS_LOGS;
    }

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              suggestions={{
                tipos: tipos
              }}
              placeholder={"Tipo"}
              label={"Tipo"}
              path={{ path: "tipos", value: "value", label: "label" }}
              onChange={this.handleChange("concepto")}
              value={value["concepto"]}
              withTodos
            />
          </FormControl>
        </Grid>

        {!hideProgramField && (
          <Grid item xs={12} md={4}>
            <ProgramaField
              onChange={this.handleChange("idPrograma")}
              value={value["idPrograma"]}
              search={search}
              withTodos
            />
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"OK"}>Ok</MenuItem>
              <MenuItem value={"PENDIENTE"}>Pendiente</MenuItem>
              <MenuItem value={"ERROR"}>Error</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaDesde"}
              name={"fechaDesde"}
              value={value["fechaDesde"] ? moment(value["fechaDesde"]) : null}
              label="Fecha desde"
              className={classes.root}
              autoOk
              onChange={v =>
                submitValue({
                  target: { name: "fechaDesde", value: v.toISOString() }
                })
              }
              format={"DD/MM/YYYY"}
              maxDate={moment()}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaHasta"}
              name={"fechaHasta"}
              value={value["fechaHasta"] ? moment(value["fechaHasta"]) : null}
              label="Fecha hasta"
              className={classes.root}
              autoOk
              onChange={v =>
                submitValue({
                  target: { name: "fechaHasta", value: v.toISOString() }
                })
              }
              format={"DD/MM/YYYY"}
              maxDate={moment()}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
