import React, { PureComponent } from "react";
import Form from "./MyForm";
import AppQraphQL from "js/graphql/resolvers/email.resolver";
import get from "lodash/get";

const initialValues = {
  from: "",
  to: "",
  cc: "",
  cco: "",
  html: "",
  subject: ""
};
class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      idEmail: this.props.match.params.idEmail,
      values: initialValues
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      disabled: nextProps.match.params.edit !== "edit"
    });
  }

  async componentDidMount() {
    const { client } = this.props;
    const {
      match: {
        params: { idEmail, edit }
      }
    } = this.props;
    await client
      .query({
        query: AppQraphQL.queries.getEmailById,
        fetchPolicy: "network-only",
        variables: { idEmail }
      })
      .then(res => {
        const email = get(res, "data.getHistorialEmail.itemsPagina[0]", {});
        this.setState({
          values: {
            ...email,
            to: email.to || [],
            cc: email.cc || [],
            cco: email.cco || []
          },
          disabled: edit !== "edit"
        });
      });
  }

  onChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        [name]: value
      }
    }));
  };

  submit = async () => {
    const { client, history } = this.props;
    const { values: email } = this.state;
    delete email._id;
    delete email.fecha;
    delete email.__typename;
    this.setState({ submitting: true });
    await client
      .mutate({
        mutation: AppQraphQL.mutations.enviarEmail,
        variables: { email }
      })
      .then(res => {
        const mensaje = "Email enviado correctamente";
        this.props.openNotification(mensaje);
        history.push("/admin/emails");
      })
      .finally(() => this.setState({ submitting: false }));
  };

  render() {
    return (
      <Form
        id={this.state.idEmail}
        disabled={this.state.disabled}
        submitting={this.state.submitting}
        values={this.state.values}
        onSubmit={this.submit}
        onChange={this.onChange}
      />
    );
  }
}

export default Edit;
