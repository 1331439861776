import React, { Fragment } from "react";
import PropTypes, { bool } from "prop-types";
import { uniq, sortBy, get } from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import AddIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import AlertDialog from "js/components/Shared/Tabla/EnhancedTable/AlertDialog";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import EnhancedTableHead from "../EnhancedTableHead";
import { descend, renderFecha, renderHora } from "js/utils/Helper";
import { _t } from "js/utils/TranslationService";
import "./styles.css";
import Grid from "@material-ui/core/Grid/Grid";
import Collapse from "@material-ui/core/Collapse/Collapse";
import UsuariosReconocidos from "js/pages/Reconocimiento/CrearReconocimiento/UsuariosReconocidos";
import Typography from "@material-ui/core/Typography/Typography";
import { AprobadoIcon, RechazadoIcon, EditarIcon } from "images/icons";
import { IndividualIcon, GrupalIcon } from "images/icons";
import Divider from "@material-ui/core/Divider";
import moment from "moment";

const actionTooltip = {
  margin: 0
};

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemSelected: {},
      openDialog: false
    };
  }

  isSelected = id => {
    return this.props.state.selected.indexOf(id) !== -1;
  };

  isDisabled = id => {
    return this.props.state.disabled.indexOf(id) !== -1;
  };

  componentDidMount = () => {
    const { scrollToLastColumn } = this.props;
    if (scrollToLastColumn) {
      const table = document.getElementById("table");
      table.scrollBy(table.offsetWidth, 0);
    }
  };

  render() {
    const {
      classes,
      columnData,
      total,
      data,
      state,
      handleRequestSort,
      handleSelectAllClick,
      handleClick,
      handleChangePage,
      handleChangeRowsPerPage,
      actions,
      actionsCustom,
      hanleDeleteAction,
      labelDisplayedRows,
      showPagination,
      footer,
      width,
      pathId,
      formulario,
      history,
      idUsuario
    } = this.props;
    function getAprobacion(item) {
      const aprobaciones = item.aprobaciones.filter(
        aprobacion => aprobacion.jefe.idUsuario === idUsuario
      );
      const aprobacionesPendientes = aprobaciones.filter(
        aprobacion => aprobacion.estado === "PENDIENTE_APROBACION"
      );
      if (aprobacionesPendientes.length) {
        return aprobacionesPendientes[0];
      }
      return aprobaciones.length
        ? aprobaciones[aprobaciones.length - 1]
        : undefined;
    }

    function getAprobacionRRHH(item) {
      return item.aprobacionesRRHH.idsRRHH.some(
        idsRRHH => idsRRHH === idUsuario
      );
    }

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper} id="table">
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              actions={actions}
              columnData={columnData}
              numSelected={state.selected.length}
              order={state.order}
              orderBy={state.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={parseInt(total, 10)}
              classes={classes}
            />
            <TableBody>
              {data.map(item => {
                let key = pathId ? pathId : Object.keys(item)[0];
                const itemId = item[key];
                const isSelected = this.isSelected(itemId);
                const isDisabled = this.isDisabled(itemId);
                let tableRows = [];

                columnData.forEach((column, i) => {
                  if (
                    !(
                      column.id.match(/^__typename*/) !== null ||
                      column.id.match(/^"id"*/) !== null
                    )
                  ) {
                    if (column.type === "array") {
                      let string = "";
                      if (item[column.id])
                        descend(item, column.id).map(col => {
                          string !== "" && (string = string.concat(", "));
                          return (string = string.concat(col));
                        });
                      else string = "--";
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{ root: classes.tableCellRoot }}
                          padding={"dense"}
                          key={i}
                        >
                          {string}
                        </TableCell>
                      );
                    } else if (column.type === "combine") {
                      let string = "";

                      column["combine"].map(col => {
                        const value = descend(item, col.object || col.id);
                        string !== "" &&
                          (string = string.concat(column.with || " "));
                        return (string = string.concat(value));
                      });
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{ root: classes.tableCellRoot }}
                          padding={"dense"}
                          key={i}
                        >
                          {string}
                        </TableCell>
                      );
                    } else if (column.type === "locacion_merge") {
                      let string = "";
                      let array = [];

                      if (!item.tieneSucursales) {
                        string = item.pais && item.pais.descripcion;
                      } else {
                        const sucursales = descend(item, "sucursales");

                        sucursales.map(col => array.push(col.pais.descripcion));
                        string = sortBy(uniq(array)).join(", ");
                      }
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{ root: classes.tableCellRoot }}
                          padding={"dense"}
                          key={i}
                        >
                          {string}
                        </TableCell>
                      );
                    } else if (column.type === "date") {
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{ root: classes.tableCellRoot }}
                          padding={"dense"}
                          key={i}
                        >
                          {renderFecha(new Date(descend(item, column.id)))}
                        </TableCell>
                      );
                    } else if (column.type === "emails") {
                      const value = descend(item, column.id);
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{ root: classes.tableCellRoot }}
                          padding={"dense"}
                          key={i}
                        >
                          {value.length
                            ? value[0] +
                              (value.length > 1
                                ? `... +${value.length - 1}`
                                : "")
                            : "--"}
                        </TableCell>
                      );
                    } else if (column.type === "custom") {
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          className={column.classes}
                          classes={{
                            root: column.sticky
                              ? classes.tableCellRootSticky
                              : classes.tableCellRoot
                          }}
                          style={column.style}
                          padding={"dense"}
                          key={i}
                        >
                          {column.parser(item[column.id], item)}
                        </TableCell>
                      );
                    } else if (column.type === "datehour") {
                      const value = new Date(descend(item, column.id));
                      const fecha = renderFecha(value);
                      const hora = renderHora(value);
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{ root: classes.tableCellRoot }}
                          padding={"dense"}
                          key={i}
                        >
                          {`${fecha} ${hora}`}
                        </TableCell>
                      );
                    } else if (column.id.match(/^actions$/) !== null) {
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          key={i}
                          style={{ width: "max-content" }}
                          padding="checkbox"
                        >
                          <div style={{ display: "flex" }}>
                            {actionsCustom.map(actionFn => {
                              const action = actionFn({ itemId, item });
                              return (
                                action.show({ item }) && (
                                  <Tooltip
                                    key={action.label}
                                    className={classes.tooltip}
                                    title={action.tooltip}
                                  >
                                    <span>
                                      <IconButton
                                        aria-label={action.label}
                                        onClick={action.onclick}
                                        disabled={action.disabled({ item })}
                                      >
                                        {action.icon}
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                )
                              );
                            })}
                          </div>
                        </TableCell>
                      );
                    } else {
                      tableRows.push(
                        <TableCell
                          numeric={column.isNumeric}
                          classes={{
                            root: column.sticky
                              ? classes.tableCellRootSticky
                              : classes.tableCellRoot
                          }}
                          padding={"dense"}
                          key={i}
                        >
                          {typeof item[column.id] === "boolean"
                            ? item[column.id] === true
                              ? "Si"
                              : "No"
                            : descend(item, column.id)}
                        </TableCell>
                      );
                    }
                  }
                });
                return (
                  <Fragment>
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={itemId}
                      selected={isSelected}
                      classes={{ root: classes.tableRowRoot }}
                    >
                      {actions.indexOf("bulk") !== -1 && (
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          padding="checkbox"
                        >
                          <Checkbox
                            onClick={event => handleClick(event, item)}
                            checked={isSelected}
                            disabled={isDisabled}
                          />
                        </TableCell>
                      )}
                      {actions.indexOf("add") !== -1 && (
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          padding="checkbox"
                        >
                          <IconButton
                            onClick={event => handleClick(event, item)}
                            disabled={isDisabled}
                          >
                            {isSelected ? (
                              <CancelIcon color="error" />
                            ) : (
                              <AddIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      )}
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {item.usuariosReconocidos.length > 1 ? (
                          <GrupalIcon
                            viewBox="5 5 40 40"
                            classes={{
                              root: classes.icon
                            }}
                          />
                        ) : (
                          <IndividualIcon
                            viewBox="5 5 40 40"
                            classes={{
                              root: classes.icon
                            }}
                          />
                        )}
                      </TableCell>
                      {tableRows}
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        <IconButton onClick={event => handleClick(event, item)}>
                          {isSelected ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={itemId + "C"}
                      selected={isSelected}
                      classes={{ root: classes.tableRowRootCollapse }}
                    >
                      <TableCell
                        colSpan={columnData.length + 2}
                        style={{ paddingRight: "56px" }}
                      >
                        <Collapse
                          xsUp={isSelected}
                          in={isSelected}
                          timeout="auto"
                          unmountOnExit
                          className={classes.collapse}
                          component={() => {
                            const {
                              username,
                              perfil
                            } = item.usuarioReconocedor;

                            const aprobacion = getAprobacion(item);
                            const { nombre, apellido } = perfil;
                            const aprobacionesAnteriores = item.aprobaciones;
                            const aprobacionesAnterioresRRHH =
                              item.aprobacionesRRHH;

                            const filas = [
                              {
                                orden: get(formulario, "pregunta1.orden", 0),
                                render: this.renderPregunta1
                              },
                              {
                                orden: get(formulario, "pregunta2.orden", 1),
                                render: this.renderPregunta2
                              },
                              {
                                orden: get(formulario, "pregunta3.orden", 5),
                                render: this.renderPregunta3
                              },
                              {
                                orden: get(
                                  formulario,
                                  "mensajeNominador.orden",
                                  2
                                ),
                                render: this.renderMensajeNominador
                              },
                              {
                                orden: get(formulario, "valor.orden", 3),
                                render: this.renderValor
                              },
                              {
                                orden: get(formulario, "tipo.orden", 4),
                                render: this.renderTipo
                              }
                            ].sort((a, b) => a.orden - b.orden);
                            return (
                              <Paper className={classes.card}>
                                <Grid
                                  xs={12}
                                  container
                                  spacing={16}
                                  style={{
                                    padding: "10px"
                                  }}
                                  direction={"row"}
                                  justify={"center"}
                                  alignItems={"flex-start"}
                                >
                                  <Grid
                                    xs={6}
                                    item
                                    direction={"row"}
                                    alignItems={"flex-start"}
                                    container
                                    spacing={16}
                                    style={{
                                      paddingRight: "25px"
                                    }}
                                  >
                                    <Grid xs={12} item>
                                      <Typography
                                        color={"primary"}
                                        style={{
                                          marginBottom: "25px"
                                        }}
                                      >
                                        {_t("NOMINADOR")}
                                      </Typography>
                                    </Grid>
                                    <Grid xs={12} item>
                                      <Typography
                                        classes={{
                                          root: classes.textoNombre
                                        }}
                                        style={{
                                          marginBottom: "15px"
                                        }}
                                      >
                                        {nombre && apellido
                                          ? `${nombre} ${apellido}`
                                          : username}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      xs={12}
                                      item
                                      className={classes.containerNominados}
                                      style={{
                                        marginBottom: "25px"
                                      }}
                                    >
                                      <Typography color={"primary"}>
                                        {_t("NOMINADO/S")}
                                      </Typography>
                                    </Grid>
                                    <Grid xs={12} item>
                                      <UsuariosReconocidos
                                        formulario={formulario}
                                        noRemove={true}
                                        usuariosNominados={
                                          item.usuariosReconocidos
                                        }
                                        nominaEquipo={
                                          item.usuariosReconocidos.length > 1
                                        }
                                        idUsuariosNominados={item.usuariosReconocidos.map(
                                          usuario => usuario.idUsuario
                                        )}
                                        classes={classes}
                                      />
                                    </Grid>
                                    {aprobacionesAnteriores.length > 0 &&
                                      aprobacionesAnteriores.map(
                                        aprobacionAnterior => {
                                          const {
                                            username,
                                            perfil = {}
                                          } = aprobacionAnterior.jefe;
                                          const { nombre, apellido } = perfil;
                                          return (
                                            <Grid
                                              xs={12}
                                              container
                                              item
                                              style={{
                                                padding: "25px"
                                              }}
                                              className={
                                                classes.containerAprobacionAnterior
                                              }
                                            >
                                              <Grid xs={12} item>
                                                <Grid
                                                  xs={12}
                                                  item
                                                  className={
                                                    classes.containerFecha
                                                  }
                                                >
                                                  <Typography
                                                    classes={{
                                                      root:
                                                        classes.textoFechaWrapper
                                                    }}
                                                  >
                                                    FECHA:{" "}
                                                    <Typography
                                                      classes={{
                                                        root: classes.textoFecha
                                                      }}
                                                    >
                                                      {moment
                                                        .utc(
                                                          aprobacionAnterior.fechaUltimaModificacion
                                                        )
                                                        .isValid()
                                                        ? moment
                                                            .utc(
                                                              aprobacionAnterior.fechaUltimaModificacion
                                                            )
                                                            .format(
                                                              "DD-MM-YYYY"
                                                            )
                                                        : "--"}
                                                    </Typography>
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  xs={12}
                                                  item
                                                  className={
                                                    classes.containerAprobador
                                                  }
                                                  style={{
                                                    marginTop: "15px"
                                                  }}
                                                >
                                                  <Typography
                                                    classes={{
                                                      root:
                                                        classes.textoNombreAprobador
                                                    }}
                                                  >
                                                    {`Aprobador ${
                                                      aprobacionAnterior
                                                        ? aprobacionAnterior.orden
                                                        : ""
                                                    }: `}
                                                  </Typography>
                                                  <Typography
                                                    classes={{
                                                      root:
                                                        classes.textoNombreAprobador
                                                    }}
                                                  >
                                                    {nombre && apellido
                                                      ? `${nombre} ${apellido}`
                                                      : username}
                                                  </Typography>
                                                </Grid>
                                                {aprobacionAnterior.estado ===
                                                "APROBADA" ? (
                                                  <Fragment>
                                                    {formulario.conObservacion ? (
                                                      <Fragment>
                                                        <Grid xs={12} item>
                                                          <Divider />
                                                          <Typography
                                                            classes={{
                                                              root:
                                                                classes.observacionAnteriorTitulo
                                                            }}
                                                          >
                                                            {_t("Observación")}
                                                          </Typography>
                                                        </Grid>
                                                        <Grid xs={12} item>
                                                          <Typography
                                                            classes={{
                                                              root:
                                                                classes.observacionAnterior
                                                            }}
                                                          >
                                                            {
                                                              aprobacionAnterior.observacion
                                                            }
                                                          </Typography>
                                                        </Grid>
                                                      </Fragment>
                                                    ) : (
                                                      ""
                                                    )}

                                                    {formulario.conMensajeAprobadores ? (
                                                      <Grid xs={12} item>
                                                        <Divider />
                                                        <Typography
                                                          classes={{
                                                            root:
                                                              classes.observacionAnteriorTitulo
                                                          }}
                                                        >
                                                          {_t(
                                                            "¡Dejale un mensaje!"
                                                          )}
                                                        </Typography>
                                                      </Grid>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <Grid xs={12} item>
                                                      <Typography
                                                        classes={{
                                                          root:
                                                            classes.observacionAnterior
                                                        }}
                                                      >
                                                        {
                                                          aprobacionAnterior.mensaje
                                                        }
                                                      </Typography>
                                                    </Grid>
                                                  </Fragment>
                                                ) : aprobacionAnterior.estado ===
                                                "RECHAZADA" ? (
                                                  <Fragment>
                                                    <Grid xs={12} item>
                                                      <Divider />
                                                      <Typography
                                                        classes={{
                                                          root:
                                                            classes.observacionAnteriorTitulo
                                                        }}
                                                      >
                                                        {_t("Motivo")}
                                                      </Typography>
                                                    </Grid>
                                                    <Grid xs={12} item>
                                                      <Typography
                                                        classes={{
                                                          root:
                                                            classes.observacionAnterior
                                                        }}
                                                      >
                                                        {
                                                          aprobacionAnterior.motivo
                                                        }
                                                      </Typography>
                                                    </Grid>
                                                  </Fragment>
                                                ) : (
                                                  <Fragment>
                                                    <Grid xs={12} item>
                                                      <Divider />
                                                      <Typography
                                                        classes={{
                                                          root:
                                                            classes.observacionAnteriorTitulo
                                                        }}
                                                      >
                                                        {_t("Pendiente")}
                                                      </Typography>
                                                    </Grid>
                                                  </Fragment>
                                                )}
                                              </Grid>
                                            </Grid>
                                          );
                                        }
                                      )}
                                    {/* RENDERIZADO DE RR-HH EN EL COLAPSABLE DE LOS RECONOCIMIENTOS  */}
                                    {aprobacionesAnterioresRRHH ? (
                                      <>
                                        <Grid
                                          xs={12}
                                          container
                                          item
                                          style={{
                                            padding: "25px"
                                          }}
                                          className={
                                            classes.containerAprobacionAnterior
                                          }
                                        >
                                          <Grid xs={12} item>
                                            <Grid
                                              xs={12}
                                              item
                                              className={classes.containerFecha}
                                            >
                                              <Typography
                                                classes={{
                                                  root:
                                                    classes.textoFechaWrapper
                                                }}
                                              >
                                                FECHA:{" "}
                                                <Typography
                                                  classes={{
                                                    root: classes.textoFecha
                                                  }}
                                                >
                                                  {moment
                                                    .utc(
                                                      aprobacionesAnterioresRRHH.fechaUltimaModificacion
                                                    )
                                                    .isValid()
                                                    ? moment
                                                        .utc(
                                                          aprobacionesAnterioresRRHH.fechaUltimaModificacion
                                                        )
                                                        .format("DD-MM-YYYY")
                                                    : "--"}
                                                </Typography>
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              xs={12}
                                              item
                                              className={
                                                classes.containerAprobador
                                              }
                                              style={{
                                                marginTop: "15px"
                                              }}
                                            >
                                              <Typography
                                                classes={{
                                                  root:
                                                    classes.textoNombreAprobador
                                                }}
                                              >
                                                {`Aprobador ${
                                                  aprobacionesAnterioresRRHH
                                                    ? "RR-HH"
                                                    : ""
                                                }: `}
                                              </Typography>
                                              <Typography
                                                classes={{
                                                  root:
                                                    classes.textoNombreAprobador
                                                }}
                                              >
                                                {"SECTOR RR-HH"}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                  <Grid
                                    xs={6}
                                    item
                                    container
                                    style={{
                                      paddingLeft: "25px"
                                    }}
                                  >
                                    {filas.map(fila =>
                                      fila.render({
                                        classes,
                                        formulario,
                                        item
                                      })
                                    )}
                                    {item.respuestas
                                      ? item.respuestas.map(
                                          ({
                                            tipo,
                                            pregunta,
                                            respuesta,
                                            respuestas
                                          }) => {
                                            return pregunta &&
                                              !pregunta.startsWith(
                                                "[OLD VERSION]"
                                              ) &&
                                              tipo !== "QUIZSCORE" ? (
                                              <Fragment>
                                                <Grid xs={12} item>
                                                  <Divider
                                                    classes={{
                                                      root: classes.divider
                                                    }}
                                                  />
                                                  <Typography
                                                    classes={{
                                                      root: classes.pregunta
                                                    }}
                                                  >
                                                    {pregunta}
                                                  </Typography>
                                                </Grid>
                                                <Grid xs={12} item>
                                                  <Typography
                                                    classes={{
                                                      root: classes.respuesta
                                                    }}
                                                  >
                                                    {respuesta ? (
                                                      respuesta
                                                    ) : respuestas ? (
                                                      <ul>
                                                        {respuestas.map(
                                                          respuesta => (
                                                            <li>{respuesta}</li>
                                                          )
                                                        )}
                                                      </ul>
                                                    ) : (
                                                      "--"
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Fragment>
                                            ) : (
                                              ""
                                            );
                                          }
                                        )
                                      : ""}
                                  </Grid>
                                  {item.estado === "EN_EDICION" &&
                                  item.usuarioReconocedor.idUsuario ===
                                    idUsuario ? (
                                    <Grid
                                      xs={12}
                                      container
                                      justify={"center"}
                                      item
                                    >
                                      <Tooltip
                                        title="Solicitud de edición"
                                        style={actionTooltip}
                                      >
                                        <EditarIcon
                                          className={classes.iconDesplegable}
                                          color={"primary"}
                                          viewBox="5 5 40 40"
                                          onClick={() =>
                                            history.push(
                                              `/formularioreconocimiento/${
                                                item._id
                                              }`
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                  {aprobacion &&
                                  aprobacion.estado ===
                                    "PENDIENTE_APROBACION" ? (
                                    <Grid
                                      xs={12}
                                      container
                                      justify={"center"}
                                      item
                                    >
                                      <Tooltip
                                        title="Aprobar"
                                        style={actionTooltip}
                                      >
                                        <AprobadoIcon
                                          className={classes.iconDesplegable}
                                          color={"primary"}
                                          viewBox="5 5 40 40"
                                          onClick={() =>
                                            history.push(
                                              `/reconocido/${
                                                item._id
                                              }/aprobacion`
                                            )
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        title="Rechazar"
                                        style={actionTooltip}
                                      >
                                        <RechazadoIcon
                                          className={classes.iconDesplegable}
                                          color={"primary"}
                                          viewBox="5 5 40 40"
                                          onClick={() =>
                                            history.push(
                                              `/reconocido/${item._id}/rechazo`
                                            )
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        title="Solicitud de Edicion"
                                        style={actionTooltip}
                                      >
                                        <EditarIcon
                                          className={classes.iconDesplegable}
                                          color={"primary"}
                                          viewBox="5 5 40 40"
                                          onClick={() =>
                                            history.push(
                                              `/reconocido/${item._id}/edicion`
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                  {item.aprobacionesRRHH &&
                                  item.aprobacionesRRHH.estado ===
                                    "PENDIENTE_APROBACION" &&
                                  getAprobacionRRHH(item) ? (
                                    <Grid
                                      xs={12}
                                      container
                                      justify={"center"}
                                      item
                                    >
                                      <Tooltip
                                        title="Aprobar"
                                        style={actionTooltip}
                                      >
                                        <AprobadoIcon
                                          className={classes.iconDesplegable}
                                          color={"primary"}
                                          viewBox="5 5 40 40"
                                          onClick={() =>
                                            history.push(
                                              `/reconocido/${
                                                item._id
                                              }/aprobacion`
                                            )
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        title="Rechazar RR-HH"
                                        style={actionTooltip}
                                      >
                                        <RechazadoIcon
                                          className={classes.iconDesplegable}
                                          color={"primary"}
                                          viewBox="5 5 40 40"
                                          onClick={() =>
                                            history.push(
                                              `/reconocido/${item._id}/rechazo`
                                            )
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        title="Solicitud de Edicion"
                                        style={actionTooltip}
                                      >
                                        <EditarIcon
                                          className={classes.iconDesplegable}
                                          color={"primary"}
                                          viewBox="5 5 40 40"
                                          onClick={() =>
                                            history.push(
                                              `/reconocido/${item._id}/edicion`
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                              </Paper>
                            );
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
            {footer && footer.length ? (
              <TableFooter>
                <TableRow>
                  <TableCell
                    align="right"
                    classes={{
                      root: classes.tableCellFooterTotal
                    }}
                  >
                    Total
                  </TableCell>
                  {footer.map(({ value, parser = value => value }, i) => (
                    <TableCell
                      numeric
                      classes={{
                        root: classes.tableCellFooter
                      }}
                      padding={"dense"}
                      key={i}
                    >
                      {parser(value)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableFooter>
            ) : (
              ""
            )}
          </Table>
        </div>
        <AlertDialog
          open={this.state.openDialog}
          itemSelected={this.state.itemSelected}
          hanleDeleteAction={hanleDeleteAction.bind(this)}
        />
        {showPagination && (
          <TablePagination
            component="div"
            labelRowsPerPage={width !== "xs" && _t("Resultados por página")}
            labelDisplayedRows={labelDisplayedRows}
            count={parseInt(total, 10)}
            rowsPerPage={parseInt(state.rowsPerPage, 10)}
            rowsPerPageOptions={[5, 10, 50, 100]}
            page={parseInt(state.page, 10) - 1}
            backIconButtonProps={{
              "aria-label": `${_t("Anterior")}`
            }}
            classes={{
              root: classes.tablePagination,
              actions: classes.tablePaginationActions
            }}
            nextIconButtonProps={{
              "aria-label": `${_t("Siguiente")}`
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    );
  }

  renderMensajeNominador({ formulario, classes, item }) {
    return formulario.mensajeNominador &&
      formulario.mensajeNominador.seMuestra ? (
      <Fragment>
        <Grid xs={12} item>
          <Divider classes={{ root: classes.divider }} />
          <Typography classes={{ root: classes.pregunta }}>
            {formulario.mensajeNominador && formulario.mensajeNominador.alias
              ? formulario.mensajeNominador.alias
              : "Mensaje del nominador"}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography
            classes={{
              root: classes.respuesta
            }}
          >
            {item.mensaje}
          </Typography>
        </Grid>
      </Fragment>
    ) : (
      ""
    );
  }

  renderTipo({ formulario, classes, item }) {
    return formulario.tipo && formulario.tipo.alias ? (
      <Fragment>
        <Grid xs={12} item>
          <Divider classes={{ root: classes.divider }} />
          <Typography classes={{ root: classes.pregunta }}>
            {formulario.tipo.alias}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography
            classes={{
              root: classes.respuesta
            }}
          >
            {item.tipo.join(", ")}
          </Typography>
        </Grid>
      </Fragment>
    ) : (
      ""
    );
  }

  renderValor({ formulario, classes, item }) {
    return formulario.valor && formulario.valor.alias ? (
      <Fragment>
        <Grid xs={12} item>
          <Divider classes={{ root: classes.divider }} />
          <Typography classes={{ root: classes.pregunta }}>
            {formulario.valor.alias}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography
            classes={{
              root: classes.respuesta
            }}
          >
            {item.valor.join(", ")}
          </Typography>
        </Grid>
      </Fragment>
    ) : (
      ""
    );
  }

  renderPregunta3({ formulario, classes, item }) {
    return formulario.pregunta3 && formulario.pregunta3.alias ? (
      <Fragment>
        <Grid xs={12} item>
          <Divider classes={{ root: classes.divider }} />
          <Typography classes={{ root: classes.pregunta }}>
            {formulario.pregunta3 && formulario.pregunta3.alias
              ? formulario.pregunta3.alias
              : ""}
          </Typography>
        </Grid>
        <Grid xs={12} classes={{ root: classes.respuesta }} item>
          <Typography
            classes={{
              root: classes.respuesta
            }}
          >
            {item.respuesta3}
          </Typography>
        </Grid>
      </Fragment>
    ) : (
      ""
    );
  }

  renderPregunta2({ formulario, classes, item }) {
    return formulario.pregunta2 && formulario.pregunta2.alias ? (
      <Fragment>
        <Grid xs={12} item>
          <Divider classes={{ root: classes.divider }} />
          <Typography classes={{ root: classes.pregunta }}>
            {formulario.pregunta2 && formulario.pregunta2.alias
              ? formulario.pregunta2.alias
              : "¿Por qué?"}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography
            classes={{
              root: classes.respuesta
            }}
          >
            {item.respuesta2}
          </Typography>
        </Grid>
      </Fragment>
    ) : (
      ""
    );
  }

  renderPregunta1({ formulario, classes, item }) {
    return formulario.pregunta1 && formulario.pregunta1.alias ? (
      <Fragment>
        <Grid xs={12} item>
          <Divider classes={{ root: classes.divider }} />
          <Typography classes={{ root: classes.pregunta }}>
            {formulario.pregunta1 && formulario.pregunta1.alias
              ? formulario.pregunta1.alias
              : "¿Cuál fue la Acción, Comportamiento o Resultado destacado por la cual creés que la persona seleccionada debe ser reconocida?"}
          </Typography>
        </Grid>
        <Grid xs={12} classes={{ root: classes.respuesta }} item>
          <Typography
            classes={{
              root: classes.respuesta
            }}
          >
            {item.respuesta1}
          </Typography>
        </Grid>
      </Fragment>
    ) : (
      ""
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columnData: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
  handleSelectAllClick: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  labelDisplayedRows: PropTypes.func,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  actionsCustom: PropTypes.array,
  hanleDeleteAction: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  showPagination: PropTypes.bool.isRequired
};

EnhancedTable.defaultProps = {
  showPagination: true,
  labelDisplayedRows: ({ from, to, count }) => ` ${from}-${to} de ${count}`,
  handleRequestSort: () => undefined,
  handleSelectAllClick: () => undefined,
  handleClick: () => undefined,
  handleChangePage: () => undefined,
  handleChangeRowsPerPage: () => undefined,
  hanleDeleteAction: () => undefined,
  actions: [],
  actionsCustom: [],
  state: { selected: [], disabled: [] },
  footer: []
};

export default EnhancedTable;
