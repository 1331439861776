import React from "react";
import Grid from "@material-ui/core/Grid";
import placeholder from "../../../../images/person_200x200.png";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Typography from "@material-ui/core/Typography";
import { getUrl } from "js/utils";
import Icon from "@material-ui/core/Icon";

export default class UsuariosReconocidos extends React.Component {
  componentWillReceiveProps({ usuariosNominados, nominaEquipo }) {
    this.setState({ ...this.state, usuariosNominados, nominaEquipo });
  }
  // removeNominado = index => {
  //   this.props.usuariosNominados.splice(index, 1);
  //   this.props.idUsuariosNominados.splice(index, 1);
  //   this.setState({ usuariosNominados: this.props.usuariosNominados });
  // };
  renderEquipo = (usuariosNominados, noRemove) => {
    return usuariosNominados.map((usuario, index) =>
      this.renderUsuarioUnico(usuario, noRemove, index)
    );
  };
  renderUsuarioUnico = (
    { perfil, username, avatarUrl, idUsuario },
    noRemove,
    index
  ) => {
    let otros = {};
    try {
      otros = JSON.parse(perfil.otros);
    } catch (e) {
      otros = {};
    }
    if (otros === null || otros === undefined) otros = {};
    const { formulario = {} } = this.props;
    const getKey = label =>
      label
        .toLocaleLowerCase()
        .replace(/\s/g, "_")
        .replace(/\./g, "");
    return (
      <Grid xs={12} item container key={index}>
        <Grid xs={2} item>
          <Avatar
            alt="avatar"
            src={avatarUrl ? getUrl(avatarUrl) : placeholder}
            className={this.props.classes.avatar}
          />
        </Grid>
        <Grid
          xs={8}
          item
          style={{
            paddingTop: "5px"
          }}
        >
          <Typography classes={{ root: this.props.classes.textoNombre }}>
            {otros.nombre && otros.apellido
              ? `${otros.nombre} ${otros.apellido}`
              : username}
          </Typography>
          <Typography classes={{ root: this.props.classes.textoDefinicion }}>
            {formulario.campoUsuarioPrincipal
              ? `${formulario.campoUsuarioPrincipal}: ${otros[
                  getKey(formulario.campoUsuarioPrincipal)
                ] || " "} `
              : ""}{" "}
            {formulario.campoUsuarioSecundario
              ? `${formulario.campoUsuarioSecundario}: ${otros[
                  getKey(formulario.campoUsuarioSecundario)
                ] || " "}`
              : ""}{" "}
          </Typography>
        </Grid>
        {!noRemove && index !== undefined ? (
          <Grid xs={2} item>
            <Icon
              className={this.props.classes.icon}
              color="primary"
              onClick={() => this.props.removeNominado(idUsuario || 0)}
            >
              delete_circle
            </Icon>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    );
  };
  render() {
    const { usuariosNominados, nominaEquipo, noRemove } = this.props;
    return nominaEquipo
      ? this.renderEquipo(usuariosNominados, noRemove)
      : usuariosNominados[0]
        ? this.renderUsuarioUnico(usuariosNominados[0], noRemove, 0)
        : "";
  }
}
