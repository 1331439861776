import React from "react";
import SucursalIcon from "@material-ui/icons/Store";
import base from "./Base";

export default params => ({
  ...base(),
  tooltip: "Mostrar sucursales",
  label: "Sucursales",
  icon: <SucursalIcon />,
  show: res => !!res.item.tieneSucursales,
  onclick: () =>
    params.history.push(`${window.location.pathname}/${params.itemId}/sucursal`)
});
