import React, { Fragment, Component } from "react";

import TablaFacturas from "./TablaFacturas";

class Facturas extends Component {
  componentWillMount() {
    this.props.history.replace({ pathname: "/admin/facturas" });
  }

  render() {
    return (
      <Fragment>
        <TablaFacturas />
      </Fragment>
    );
  }
}

export default Facturas;
