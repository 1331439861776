import React, { PureComponent, Fragment } from "react";
import { Field } from "redux-form";
import { Query } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import AppQraphQL from "js/graphql/resolvers/locacion.resolver";

import { _t } from "js/utils/TranslationService";
import { Select } from "redux-form-material-ui";
import { styles } from "js/components/Shared/Form/styles.js";
import { required } from "js/components/Shared/Form/validations.js";

class Moneda extends PureComponent {
  render() {
    const { classes, disabled } = this.props;
    return (
      <Query
        query={AppQraphQL.queries.getMonedas}
        fetchPolicy="network-only"
        errorPolicy="all"
      >
        {({ loading, error, data }) => {
          if (loading || !data) return null;
          return (
            <Fragment>
              <FormControl className={classes ? classes.field : styles.field}>
                <InputLabel htmlFor="moneda-pais">
                  {_t("Moneda del programa")}
                </InputLabel>
                <Field
                  name="monedaBase"
                  component={Select}
                  className={classes ? classes.field : styles.field}
                  disabled={disabled}
                  validate={required}
                >
                  {data.getPaises.map((pais, index) => (
                    <MenuItem key={index} value={pais.moneda}>
                      {pais.moneda}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(withStyles(styles)(Moneda));
