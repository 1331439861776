import React, { Fragment, Component } from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import Slider from "js/components/Shared/Slider";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import moment from "moment";

import Dialog from "../Dialog";
import { capitalizeFirstLetter } from "../../../utils/Helper";
import LikeIcon from "./LikeIcon.container";
import Cucarda from "../../../components/Cucarda";
import { _t } from "js/utils/TranslationService";
import Redirect from "js/components/Shared/Tabla/TableCells/Redirect";

class Descripcion extends Component {
  render() {
    const {
      classes,
      beneficio,
      beneficioActions,
      cantidad,
      mensaje,
      isLogged,
      programa
    } = this.props;

    if (isLogged && mensaje) {
      this.props.openNotification(mensaje);
    }

    const programLogo = {
      src: programa.template.header.logoPrincipal
        ? programa.template.header.logoPrincipal.url
        : null
    };

    const imagenes = beneficio.imagenes.map((imagen, i) => {
      return {
        src: imagen,
        alt: beneficio.nombre + "-imagen-" + i
      };
    });

    return (
      <Fragment>
        <Grid container spacing={40} className={classes.sectionContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.cucardaContainer}
          >
            {beneficio.cucarda && (
              <Cucarda
                cucardaType={beneficio.cucarda}
                extraClass={classes.cucardaProductDetail}
              />
            )}
            <Slider
              classes={{
                sliderContainer: classes.sliderContainer,
                carousel: classes.carousel
              }}
              extraOptions={{ showThumbs: false, showStatus: false }}
              contenido={{
                images:
                  imagenes && imagenes.length !== 0 ? imagenes : [programLogo]
              }}
              isLogoImage={imagenes && imagenes.length !== 0 ? false : true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img
              src={beneficio.logo}
              alt="logo-beneficio"
              style={{ marginBottom: "10px", maxWidth: "100px" }}
            />
            <Typography variant="h5">
              {capitalizeFirstLetter(beneficio.nombre)}
            </Typography>
            <Grid container justify="space-between">
              <Grid item xs={11}>
                <Typography
                  variant="h6"
                  gutterBottom
                  color="primary"
                  className={classes.points}
                >
                  {beneficio.idTipo ? beneficio.idTipo.tipo : ""}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <LikeIcon color="primary" />
              </Grid>
            </Grid>
            {beneficio.fechaVencimiento && (
              <p
                style={{
                  marginBottom: "10px",
                  marginTop: "10px",
                  fontSize: "12px",
                  fontStyle: "italic"
                }}
              >
                {_t("Beneficio vigente hasta el")}{" "}
                {moment(beneficio.fechaVencimiento).format("DD/MM/YYYY")}
              </p>
            )}
            <Divider className={classes.divider} />
            <Typography
              variant="body1"
              gutterBottom
              className={classes.productDescription}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `<div>${beneficio.copete || ""}</div>`
                }}
              />
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.productDescription}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `<div>${beneficio.descripcion || ""}</div>`
                }}
              />
            </Typography>
            {beneficio.paginaWeb && (
              <Typography
                variant="body2"
                gutterBottom
                className={classes.productDescription}
              >
                <Redirect
                  path={beneficio.paginaWeb}
                  value={beneficio.paginaWeb}
                />
              </Typography>
            )}
            <Grid container>
              <Grid item xs={6}>
                <Button
                  onClick={beneficioActions.abrirSolicitudPopup}
                  disabled={cantidad < 1 || !isLogged}
                  variant="contained"
                  color="primary"
                  className={classnames(classes.button, classes.solicitudar)}
                >
                  {isLogged
                    ? `${_t("Solicitar")}`
                    : `${_t("Debes iniciar sesión para poder solicitar")}`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isLogged && <Dialog />}
      </Fragment>
    );
  }
}

export default Descripcion;
