import React from "react";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import AuthService from "js/utils/AuthService";
import base from "./Base";
import { exportURL } from "js/constants";
export default params => ({
  ...base(),
  label: params.label,
  icon: <DownloadIcon />,
  tooltip: params.tooltip,
  show: () => AuthService.hasPerms(params.perms),
  onclick: () =>
    (window.location = `${exportURL}/${params.path}?${params.queryString(
      params.itemId
    )}`)
});
