import { connect } from "react-redux";
import LikeIcon from "js/components/Shared/LikeIcon";
import beneficioActions from "../actions";

const mapStateToProps = ({ beneficio: { beneficio, favorito } }) => ({
  favorito: favorito,
  idBeneficio: beneficio.idBeneficio
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  beneficioActions: beneficioActions({ dispatch })
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  favorito: stateProps.favorito,
  onClick: dispatchProps.beneficioActions.toggleFavorito(
    stateProps.idBeneficio
  ),
  color: ownProps.color
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(LikeIcon);
