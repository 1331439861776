import React from "react";
import Tabla from "js/components/TablaCatalogo";

export const Catalogo = props => {
  return (
    <div>
      <Tabla />
    </div>
  );
};

export default Catalogo;
