import React from "react";
import PropTypes from "prop-types";
import SplashScreen from "js/pages/SplashScreen";
import { Query } from "react-apollo";
import ReconocimientoGraphQL from "js/graphql/resolvers/reconocimiento.resolver";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { _t } from "js/utils/TranslationService";
import { AprobadoIcon } from "images/icons";

const Reconocido = props => {
  const { classes } = props;
  return (
    <Query
      query={ReconocimientoGraphQL.queries.getReconocimientoById}
      variables={{
        _id: props.computedMatch.params.idReconocimiento
      }}
      errorPolicy="all"
    >
      {({ loading, error, data }) => {
        if (loading) return <SplashScreen />;
        if (error) return `Error! ${error.message}`;
        const reconocimiento = data.getReconocimiento;

        return (
          <Grid
            xs={12}
            item
            container
            style={{
              margin: "0px",
              width: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              height: "100%",
              paddingBottom: "65px",
              backgroundColor: "#FFFFFF"
            }}
            direction={"row"}
            alignItems={"center"}
            alignContent={"center"}
            justify={"center"}
          >
            <Grid xs={12} item className={classes.fondoTitulo} />
            <Grid xs={10} sm={8} className={classes.containerTitulo}>
              <Typography
                variant={"h3"}
                classes={{ root: classes.textoTitulo }}
              >
                {_t("Reconocimiento aprobado")}
              </Typography>
            </Grid>
            <Grid
              xs={10}
              sm={8}
              item
              style={{
                minHeight: "100%",
                height: "100%"
              }}
            >
              <Paper
                elevation={24}
                style={{
                  marginTop: "50px",
                  borderRadius: "25px"
                }}
              >
                <Grid
                  container
                  // spacing={24}
                  direction={"row"}
                  alignItems={"center"}
                  alignContent={"center"}
                  justify={"center"}
                  className={classes.root}
                >
                  <Grid item alignItems={"center"}>
                    <AprobadoIcon
                      className={classes.iconDesplegable}
                      color={"primary"}
                      viewBox="5 5 40 40"
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.containerInterno}>
                    <Typography
                      variant="h4"
                      color={"primary"}
                      align={"center"}
                      gutterBottom
                    >
                      {`${_t("El reconocimiento ha sido aprobado con éxito")}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      align={"center"}
                      gutterBottom
                      style={{
                        color: "#979797"
                      }}
                    >
                      {`${_t("Aprobaste el reconocimiento de")}`}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      align={"center"}
                      gutterBottom
                      style={{
                        color: "#979797"
                      }}
                    >
                      {reconocimiento.usuariosReconocidos.map(
                        ({ username, perfil: { nombre, apellido } }) => {
                          return (
                            <div>
                              {nombre && apellido
                                ? `${nombre} ${apellido}`
                                : username}
                            </div>
                          );
                        }
                      )}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      align={"center"}
                      gutterBottom
                      style={{
                        color: "#979797"
                      }}
                    >
                      {`${_t("¡Gracias!")}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        );
      }}
    </Query>
  );
};

Reconocido.propTypes = {
  classes: PropTypes.object
};

Reconocido.defaultProps = {};

export default Reconocido;
