import { connect } from "react-redux";
import Signin from "./Signin";
import {
  allowNavigation,
  denyNavigation,
  setLogInStatus
} from "js/redux/programa";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setLogInStatus: setLogInStatus(dispatch),
  allowNavigation: allowNavigation(dispatch),
  denyNavigation: denyNavigation(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signin);
