import React from "react";
import Avatar from "@material-ui/core/Avatar";
import defaultAvatar from "images/person_200x200.png";

const Foto = props => {
  const { classes, value } = props;
  return <Avatar src={value} className={classes.avatar} />;
};
Foto.defaultProps = {
  value: defaultAvatar
};
export default Foto;
