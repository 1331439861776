import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import Card from "@material-ui/core/Card";
import AuthService from "js/utils/AuthService";

import CatalogoQraphQL from "js/graphql/resolvers/catalogo.resolver";
import SplashScreen from "js/pages/Loading";
import Lista from "./Lista";
import Cuadricula from "./Cuadricula";
import FilterPanel from "./FilterPanel";
import ToggleView from "./ToggleView";
import defaultStyles from "./styles";
import Destacados from "./Destacados";
import { CatalogoContext } from "./catalogo-context";
import { lowerCase } from "lodash";
import { _t } from "js/utils/TranslationService";
import Utils from "./utils";
import QueryString from "query-string";

const {
  initialSearchCriteria,
  updateSearchCriteria,
  setStateFilterPoints,
  setStateCatalogoOrder,
  setStateFilterByCategories,
  getQueryObj
} = Utils;

class Catalogo extends React.Component {
  constructor(props) {
    super(props);

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  state = {
    pagina: {
      cantidadPorPagina: 24,
      numeroPagina: 1
    },
    prevGrid: this.props.programa.template.catalogo.vista,
    grid: this.props.programa.template.catalogo.vista,
    width: 0,
    height: 0,
    searchCriteria: null
  };

  componentWillReceiveProps = nextProps => {
    // debugger;
    const {
      location: { state }
    } = this.props;
    const search = QueryString.parse(nextProps.location.search);
    let initial;
    if (search && nextProps.location.search !== this.props.location.search) {
      initial = {
        filterByCategories: new Map([
          [
            "rubros_idRubro",
            search.idCategoria
              ? new Set([parseInt(search.idCategoria, 10)])
              : new Set()
          ],
          [
            "subrubros_idSubrubro",
            search.idSubcategoria
              ? new Set([parseInt(search.idSubcategoria, 10)])
              : new Set()
          ],
          [
            "zonas_idZona",
            search.idZona ? new Set([parseInt(search.idZona, 10)]) : new Set()
          ],
          [
            "localidades_idLocalidad",
            search.idLocalidad
              ? new Set([parseInt(search.idLocalidad, 10)])
              : new Set()
          ]
        ])
      };
      this.setState({
        searchCriteria: initialSearchCriteria(!!state, initial)
      });
    }
  };

  componentDidMount() {
    const {
      location: { state }
    } = this.props;
    const search = QueryString.parse(this.props.location.search);
    let initial;
    if (search) {
      initial = {
        filterByCategories: new Map([
          [
            "rubros_idRubro",
            search.idCategoria
              ? new Set([parseInt(search.idCategoria, 10)])
              : new Set()
          ],
          [
            "subrubros_idSubrubro",
            search.idSubcategoria
              ? new Set([parseInt(search.idSubcategoria, 10)])
              : new Set()
          ],
          [
            "zonas_idZona",
            search.idZona ? new Set([parseInt(search.idZona, 10)]) : new Set()
          ],
          [
            "localidades_idLocalidad",
            search.idLocalidad
              ? new Set([parseInt(search.idLocalidad, 10)])
              : new Set()
          ]
        ])
      };
    }
    const catalogoOrderMultiple = [
      { column: "prioridad", order: "asc" },
      this.props.programa.template.catalogo.catalogoOrder
    ];
    this.setState({ searchCriteria: initialSearchCriteria(!!state, initial) });
    this.catalogoOrder(catalogoOrderMultiple);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    this.cleanFilters();
  }

  updateWindowDimensions() {
    if (window.innerWidth < 600) {
      this.setState(
        prevState => (prevState.grid === true ? { grid: true } : null)
      );
    } else {
      this.setState(
        prevState =>
          prevState.grid !== prevState.prevGrid
            ? { grid: prevState.prevGrid }
            : null
      );
    }
  }

  changeView = (_, value) =>
    this.setState(() => ({
      grid: value,
      prevGrid: value
    }));

  handleChangeNumeroPagina = (event, numeroPagina) => {
    this.setState(prevState => ({
      pagina: { ...prevState.pagina, numeroPagina: numeroPagina + 1 }
    }));
  };

  handleChangeCantidadPorPagina = event =>
    this.setState(prevState => ({
      pagina: { ...prevState.pagina, cantidadPorPagina: event.target.value }
    }));

  cleanFilters = () =>
    this.setState(() => ({
      searchCriteria: initialSearchCriteria()
    }));

  filterByCategory = ({ key, id }) =>
    this.setState(setStateFilterByCategories({ key, id }));

  searchBy = searchBy =>
    this.setState(prevState => ({
      pagina: {
        ...prevState.pagina,
        numeroPagina: 1
      },
      searchCriteria: { ...prevState.searchCriteria, searchBy }
    }));

  catalogoOrder = catalogoOrder =>
    this.setState(setStateCatalogoOrder(catalogoOrder));

  filterByPoints = ({ from = undefined, to = undefined }) => {
    const search = QueryString.parse(this.props.location.search);
    search.puntosMin = from;
    search.puntosMax = to;
    this.props.location.search = "?" + QueryString.stringify(search);
    this.setState(setStateFilterPoints(from, to));
  };

  filterFavoritos = async () => {
    const catalogoOrder = this.state.searchCriteria.catalogoOrderMultiple;
    await this.setState(prevState => ({
      pagina: {
        ...prevState.pagina,
        numeroPagina: 1
      },
      searchCriteria: {
        ...initialSearchCriteria(),
        favoritos: !prevState.searchCriteria.favoritos
      }
    }));
    this.catalogoOrder(catalogoOrder);
  };

  filterBy = {
    filterByCategory: this.filterByCategory,
    searchBy: this.searchBy,
    catalogoOrder: this.catalogoOrder,
    filterByPoints: this.filterByPoints,
    filterFavoritos: this.filterFavoritos
  };

  render() {
    const { classes, programa, history } = this.props;
    // if (programa.idPrograma === 8) {
    //   history.push("/landing");
    //   return true;
    // }
    const conFiltro =
      programa.template.catalogo.conFiltro !== undefined
        ? programa.template.catalogo.conFiltro
        : true;
    const isPublic = !programa.isPublic;
    const isLogged = !!AuthService.getIdUsuario();
    const isCatalogoPublico = programa.catalogoPublico;
    if (!isLogged && !isCatalogoPublico) {
      this.props.history.push("/signin");
      return true;
    }
    const { grid, pagina, searchCriteria } = this.state;
    if (searchCriteria) updateSearchCriteria(searchCriteria);
    const queryObj = searchCriteria
      ? getQueryObj(programa, searchCriteria, pagina, isPublic)
      : {
          ...(!isLogged && {
            url: window.location.hostname.replace("www.", "")
          })
        };

    const queryCatalogo = isLogged
      ? CatalogoQraphQL.queries.getCatalogo
      : CatalogoQraphQL.queries.getCatalogoPublico;

    const programLogo = programa.template.header.logoPrincipal
      ? programa.template.header.logoPrincipal.url
      : null;

    return (
      <Card>
        <Grid container direction="row">
          {conFiltro ? (
            <Grid item container xs={12} md={3}>
              <CatalogoContext.Provider
                value={{
                  filterBy: this.filterBy,
                  searchCriteria: this.state.searchCriteria,
                  queryObj
                }}
              >
                <FilterPanel
                  cleanFilters={this.cleanFilters}
                  isLogged={isLogged}
                />
              </CatalogoContext.Provider>
            </Grid>
          ) : (
            ""
          )}
          <Grid
            item
            container
            xs={12}
            md={conFiltro ? 9 : 12}
            direction="column"
          >
            {programa.template.destacados && (
              <Destacados destacados={programa.template.destacados} />
            )}
            {queryObj.pagina && (
              <Query
                query={queryCatalogo}
                variables={{
                  ...queryObj,
                  showDetails: false
                }}
                fetchPolicy="cache-and-network"
                errorPolicy="all"
              >
                {({ loading, error, data }) => {
                  if (loading)
                    return (
                      <Grid container item style={{ height: 400 }}>
                        <SplashScreen className={classes.progress} />
                      </Grid>
                    );
                  if (error) return ``;
                  const catalogo = isLogged
                    ? data.getCatalogo
                    : data.getCatalogoPublico;

                  const productos = catalogo.itemsPagina;
                  const infoPagina = catalogo.infoPagina;
                  return (
                    <Fragment>
                      <Grid item container>
                        <Grid item xs={12} md={6}>
                          <Typography className={classes.resultsTitle}>
                            {_t("Estás viendo")} {productos.length}{" "}
                            {productos.length !== 1
                              ? lowerCase(_t("Resultados de"))
                              : lowerCase(_t("Resultado de"))}
                            {` ${infoPagina.total} ${_t("resultados totales")}`}
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          <Hidden only={["xs", "sm"]}>
                            <ToggleView
                              changeView={this.changeView}
                              view={grid}
                            />
                          </Hidden>
                        </Grid>
                      </Grid>
                      <Hidden xsDown>
                        {grid === "grid" ? (
                          <Cuadricula
                            productos={productos}
                            isLogged={isLogged}
                            programLogo={programLogo}
                          />
                        ) : (
                          <Lista
                            productos={productos}
                            isLogged={isLogged}
                            programLogo={programLogo}
                          />
                        )}
                      </Hidden>
                      <Hidden smUp>
                        <Cuadricula productos={productos} isLogged={isLogged} />
                      </Hidden>

                      <TablePagination
                        component="div"
                        colSpan={3}
                        count={infoPagina.total}
                        rowsPerPage={pagina.cantidadPorPagina}
                        page={pagina.numeroPagina - 1}
                        labelDisplayedRows={({ from, to, count }) => (
                          <Hidden smDown>
                            {`${from}-${to} ${lowerCase(
                              _t("Resultados de")
                            )} ${count} ${lowerCase(_t("Resultados totales"))}`}
                          </Hidden>
                        )}
                        onChangePage={this.handleChangeNumeroPagina}
                        onChangeRowsPerPage={this.handleChangeCantidadPorPagina}
                        labelRowsPerPage={`${_t("Resultados por página")}:`}
                        rowsPerPageOptions={[12, 24, 48]}
                      />
                    </Fragment>
                  );
                }}
              </Query>
            )}
          </Grid>
        </Grid>
      </Card>
    );
  }
}

Catalogo.propTypes = {
  productos: PropTypes.array,
  classes: PropTypes.object
};

Catalogo.defaultProps = {
  productos: [],
  classes: defaultStyles
};

export default Catalogo;
