import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  title: {
    marginBottom: 0,
    paddingBottom: "0!important"
  },
  location: {
    paddingTop: "0!important"
  }
});

export default withStyles(styles);
