import React from "react";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";

export const columnas = [
  { id: "nombre", label: "Nombre" },
  { id: "fechaInicio", type: "date", label: "Inicio" },
  { id: "fechaFin", type: "date", label: "Fin" },
  { id: "tipo", label: "Tipo" },
  { id: "targetPrograma_nombre", label: "Programa" },
  { id: "targetSegmento_nombre", label: "Segmento" },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

export const misColumnas = [
  { id: "nombre", label: "Nombre" },
  { id: "fechaInicio", type: "date", label: "Inicio" },
  { id: "fechaFin", type: "date", label: "Fin" },
  { id: "tipo", label: "Tipo" },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];
