export const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: theme.container.maxWidth,
    margin: theme.container.margin
  },
  grid: {
    display: "flex"
  },
  flex: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "3px"
  },
  marginDetails: {
    marginLeft: "10px"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    paddingTop: "5px",
    paddingBottom: "5px",
    backgroundColor: "#eee",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.secondary
  },
  section: {
    maxWidth: theme.container.maxWidth,
    margin: theme.container.margin
  },
  editor: {
    padding: theme.spacing.unit * 2,
    paddingTop: "5px",
    paddingBottom: "5px",
    backgroundColor: "#eee",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.secondary
  },
  paperWhite: {
    backgroundColor: "#fafafa"
  },
  cuentaCorriente: {
    borderRight: "0.08em solid grey",
    padding: "0.5em",
    "margin-right": "0.5em"
  }
});
