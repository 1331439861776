import React from "react";
import DropzoneComponent from "react-dropzone-component";

export default class Example extends React.Component {
  constructor(props) {
    super(props);

    this.djsConfig = {
      acceptedFiles: "image/jpeg,image/png,image/gif",
      addRemoveLinks: true
    };

    this.componentConfig = {
      iconFiletypes: [".jpg", ".png", ".gif"],
      showFiletypeIcon: true,
      postUrl: "http://www.ashiwea.com:3000/api/uploadImage"
    };

    this.dropzone = null;
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    // For a list of all possible events (there are many), see README.md!
    const eventHandlers = {
      drop: this.callbackArray,
      addedfile: this.addedfile,
      success: this.success,
      error: this.error,
      removedfile: this.removedfile
    };

    return (
      <DropzoneComponent
        config={config}
        eventHandlers={eventHandlers}
        djsConfig={djsConfig}
      />
    );
  }
}
