import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaProveedor/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/proveedor.resolver";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import capitalize from "lodash/capitalize";
import {
  viewAction,
  editAction,
  showSucursalAction
} from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { Estado } from "js/components/Shared/Tabla/TableCells";

const defaultHeader = {
  title: "Proveedores",
  new: {
    title: "Nuevo",
    perms: [Roles.PROVEEDOR_W]
  },
  import: {
    action: AppQraphQLImport.queries.importarProveedores,
    perms: [Roles.PROVEEDOR_W],
    model: "Proveedores_Modelo.csv"
  },
  export: {
    path: "proveedores",
    perms: [Roles.PROVEEDOR_R]
  }
};
const columnData = [
  { id: "idProveedor", label: "ID", isNumeric: true },
  { id: "fechaCreacion", label: "Fecha de creación", type: "date" },
  { id: "nombreFantasia", label: "Nombre fantasía" },
  {
    id: "pais_descripcion",
    label: "País"
  },
  { id: "cuit", label: "CUIT" },
  { id: "telefonoContacto", label: "Teléfono" },
  { id: "formatoEnvio", label: "Formato de envío de lote" },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  render() {
    const view = params => ({
      ...viewAction({ ...params, history: this.props.history })
    });
    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.PROVEEDOR_W]
      })
    });
    const sucursal = params => ({
      ...showSucursalAction({ ...params, history: this.props.history })
    });
    return (
      <Table
        storage="FiltroProveedor"
        query={AppQraphQL.queries.getPaginaProveedores}
        defaultHeader={defaultHeader}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getProveedores}
        actionsCustom={[view, edit, sucursal]}
      />
    );
  }
}

export default Tabla;
