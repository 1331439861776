import gql from "graphql-tag";

export const getLinksTracking = gql`
  query getLinksTracking(
    $order: Order!
    $orderBy: LinksTrackingColumn!
    $idPrograma: Int
    $link: String
    $username: String
    $fechaDesde: Date
    $fechaHasta: Date
  ) {
    getLinksTracking(
      linksTrackingOrder: { column: $orderBy, order: $order }
      linksTrackingLike: {
        idPrograma: $idPrograma
        link: $link
        username: $username
      }
      linksTrackingRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
    ) {
      link
      descripcion
      cantidadClicks
    }
  }
`;

export const getLinksToTrack = gql`
  query getLinksToTrack($idPrograma: Int!) {
    getLinksToTrack(idPrograma: $idPrograma) {
      idPrograma
      trackearTodos
    }
  }
`;

export const importLinksToTrack = gql`
  mutation importLinksToTrack($file: Upload!, $idPrograma: Int!) {
    importLinksToTrack(file: $file, idPrograma: $idPrograma)
  }
`;

export const updateLinksToTrack = gql`
  mutation updateLinksToTrack($idPrograma: Int!, $trackearTodos: Boolean!) {
    updateLinksToTrack(idPrograma: $idPrograma, trackearTodos: $trackearTodos) {
      idPrograma
      trackearTodos
    }
  }
`;

export const trackLink = gql`
  mutation trackLink($link: String, $idPrograma: Int, $origen: String) {
    trackLink(link: $link, idPrograma: $idPrograma, origen: $origen)
  }
`;

export default {
  queries: {
    getLinksTracking,
    getLinksToTrack
  },
  mutations: {
    importLinksToTrack,
    updateLinksToTrack,
    trackLink
  }
};
