import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import base from "./Base";
import AuthService from "js/utils/AuthService";

export default ({ perms = [], optional = [], history, itemId }) => ({
  ...base(),
  label: "Gestionar",
  icon: <SettingsIcon />,
  tooltip: "Gestionar",
  show: () =>
    (!perms.length || AuthService.hasPerms(perms)) &&
    (!optional.length || AuthService.hasAnyPerm(optional)),
  onclick: () =>
    history.push(window.location.pathname + "/" + itemId + "/gestion")
});
