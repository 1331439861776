import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaProductoCodificado/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/productoCodificado.resolver";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import { editURIAction } from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

const defaultHeader = (id, btnsNextTitle) => {
  return {
    title: "Productos Codificados",
    btnsNextTitle,
    new: {
      title: "Nuevo",
      perms: [Roles.PRODUCTO_CODIFICADO_W]
    },
    export: {
      path: "productosCodificados",
      perms: [Roles.PRODUCTO_CODIFICADO_R, Roles.PRODUCTO_CODIFICADO_EXPORT],
      tooltip: "Exportar productos"
    },
    import: {
      action: AppQraphQLImport.queries.importarProductosCodificados,
      perms: [Roles.PRODUCTO_CODIFICADO_W, Roles.PRODUCTO_CODIFICADO_IMPORT],
      tooltip: "Importar productos",
      model: "Modelo_Productos_Codificados.csv",
      modelTooltip: "Modelo de importación de productos",
      variables: {
        idPrograma: parseInt(id) || id
      }
    },
    queryStringMatcher: {
      idsPrograma: undefined
    },
    others: [
      {
        action: AppQraphQLImport.queries.importarCodigosQR,
        perms: [Roles.PRODUCTO_CODIFICADO_W, Roles.PRODUCTO_CODIFICADO_IMPORT],
        tooltip: "Importar códigos QR",
        model: "Modelo_Codigos_QR.csv",
        modelTooltip: "Modelo de importación de códigos QR",
        type: "import",
        variables: {
          idPrograma: parseInt(id, 10)
        },
        id: "upload-codigos-qr"
      },
      {
        path: "productosCodificados/codigosQR",
        perms: [Roles.PRODUCTO_CODIFICADO_R, Roles.PRODUCTO_CODIFICADO_EXPORT],
        type: "export",
        tooltip: "Exportar códigos QR"
      },
      {
        action: AppQraphQLImport.queries.importarCodigosBarras,
        perms: [Roles.PRODUCTO_CODIFICADO_W, Roles.PRODUCTO_CODIFICADO_IMPORT],
        tooltip: "Importar códigos de barras",
        model: "Modelo_Codigos_Barras.csv",
        modelTooltip: "Modelo de importación de códigos de barras",
        type: "import",
        variables: {
          idPrograma: parseInt(id, 10)
        },
        id: "upload-codigos-barras"
      },
      {
        path: "productosCodificados/codigosBarras",
        perms: [Roles.PRODUCTO_CODIFICADO_R, Roles.PRODUCTO_CODIFICADO_EXPORT],
        type: "export",
        tooltip: "Exportar códigos de barras"
      },
      {
        action: AppQraphQLImport.queries.importarCodigosSinProductos,
        perms: [Roles.PRODUCTO_CODIFICADO_W, Roles.PRODUCTO_CODIFICADO_IMPORT],
        tooltip: "Importar códigos sin productos",
        model: "Modelo_Codigos_Sin_Productos.csv",
        modelTooltip: "Modelo de importación de códigos sin productos",
        type: "import",
        variables: {
          idPrograma: parseInt(id, 10)
        },
        id: "upload-codigos-sin-productos"
      },
      {
        path: "productosCodificados/codigosSinProductos",
        perms: [Roles.PRODUCTO_CODIFICADO_R, Roles.PRODUCTO_CODIFICADO_EXPORT],
        type: "export",
        tooltip: "Exportar códigos sin productos"
      }
    ]
  };
};
const columnData = [
  { id: "fechaCreacion", label: "Fecha Creación", type: "date" },
  { id: "codigo", label: "Código" },
  { id: "nombre", label: "Nombre" },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    const edit = params => ({
      ...editURIAction({
        ...params,
        history: this.props.history,
        perms: [Roles.PRODUCTO_CODIFICADO_W]
      })
    });

    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Ventas">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(
                `/admin/programa/${id}/ventas-productos-codificados`
              )
            }
          >
            <AttachMoneyIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.VENTA_PRODUCTO_CODIFICADO_R]
    });

    return (
      <Table
        storage={"FiltroProductoCodificado"}
        query={AppQraphQL.queries.getProductosCodificados}
        defaultHeader={defaultHeader(id, btnsNextTitle)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getProductosCodificados}
        actionsCustom={[edit]}
      />
    );
  }
}

export default Tabla;
