import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import ProgramaGraphQL from "js/graphql/resolvers/programa.resolver.js";
import SegmentoGraphQL from "js/graphql/resolvers/segmento.resolver.js";
import { OPERACION, TIPO_OPERACION } from "js/constants";
import { numberWithCommas } from "js/utils";
// import Tooltip from "@material-ui/core/Tooltip";
// import InfoIcon from "@material-ui/icons/Info";
// import InputAdornment from "@material-ui/core/InputAdornment";
import {
  TypeAhead,
  ProgramaField,
  UsuarioField,
  MultipleSelect
} from "js/components/Shared/Form/Fields";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { updateQueryParamsAsync, initialState } from "../utils";
import { withApollo } from "react-apollo";

class advancedFilter extends Component {
  state = {
    ...initialState,
    balancePrograma: undefined
  };

  updateAutocompleteQueryParams = key => select => {
    if (key === "idPrograma") {
      this.setState((state, props) => ({
        ...initialState,
        withProgram: true
      }));
    }

    this.setState(
      updateQueryParamsAsync({ key, value: select.value }),
      this.props.submitValue({ target: { name: key, value: select.value } })
    );
  };
  updateAutocompleteQueryParamsUsuario = key => select => {
    const value = select && select[0] ? select[0].value : undefined;
    this.setState(
      updateQueryParamsAsync({ key, value: value }),
      this.props.submitValue({ target: { name: key, value: value } })
    );
  };

  onChangePrograma = props => {
    const {
      value: { idPrograma },
      client
    } = props || this.props;
    const hasPerm = AuthService.hasPerms([Roles.PROGRAMA_LISTA]);
    if (!idPrograma) return this.setState({ balancePrograma: undefined });
    client
      .query({
        query: hasPerm
          ? ProgramaGraphQL.queries.getBalanceByIdPrograma
          : ProgramaGraphQL.queries.getBalanceMiPrograma,
        errorPolicy: "all",
        fetchPolicy: "network-only",
        variables: hasPerm
          ? { idPrograma }
          : {
              url: window.location.hostname.replace("www.", "")
            }
      })
      .then(res => {
        const {
          cuentaCorriente: { balance: balancePrograma },
          maximoMovimientoPuntos
        } = hasPerm
          ? get(res, "data.getProgramas.itemsPagina[0]", {})
          : get(res, "data.getPrograma", {});
        this.setState({ balancePrograma, maximoMovimientoPuntos });
      });
  };

  componentDidMount = () => this.onChangePrograma();

  componentWillReceiveProps = props => {
    const {
      value: { idPrograma }
    } = this.props;
    this.setState(prevState => ({ ...prevState, ...props.value }));
    if (idPrograma !== props.value.idPrograma) this.onChangePrograma(props);
  };

  limpiar = () => {
    this.setState(
      (state, props) => ({
        ...initialState
      }),
      this.props.clear()
    );
  };

  componentWillMount = () => {
    const {
      value: { idPrograma, idUsuario }
    } = this.props;
    this.setState({
      idPrograma: parseInt(idPrograma),
      idUsuario: parseInt(idUsuario),
      withProgram: parseInt(idPrograma),
      disablePrograma: !!idPrograma,
      disableUsuario: !!idUsuario
    });
  };

  handleChangeFecha = key => date => {
    this.props.submitValue({
      target: { name: key, value: date.toISOString() }
    });
  };

  render() {
    const { value, submitValue, search, classes } = this.props;
    const { disablePrograma, disableUsuario, balancePrograma } = this.state;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <ProgramaField
              noOptionsMessage={"No hay programas"}
              onChange={this.updateAutocompleteQueryParams("idPrograma")}
              disabled={disablePrograma}
              value={this.state.idPrograma}
              search={search}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              label={"Segmento"}
              path={{
                path: "getSegmentos",
                value: "idSegmento",
                label: "label",
                defaultLabel: "value"
              }}
              placeholder={"Buscar por segmento"}
              query={SegmentoGraphQL.queries.getSegmentosInput}
              queryParams={{ idPrograma: this.state.idPrograma }}
              noOptionsMessage={"No hay segmentos"}
              onChange={this.updateAutocompleteQueryParams("idSegmento")}
              value={this.state.idSegmento}
              disabled={!this.state.idPrograma}
              withTodos
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <UsuarioField
            label={"Usuarios"}
            async
            withTodos={true}
            placeholder={"Buscar por usuario"}
            idPrograma={this.state.idPrograma}
            noOptionsMessage={"No hay usuarios"}
            onChange={this.updateAutocompleteQueryParamsUsuario("idUsuario")}
            value={this.state.idUsuario}
            disabled={!this.state.idPrograma || disableUsuario}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="subtitle2" gutterBottom>
            Saldo CC Programa
          </Typography>
          <Typography variant="h4">
            {balancePrograma ? numberWithCommas(balancePrograma) : "--"}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <InputLabel id="tipo-cc" htmlFor="tipo-cc">
              Tipo
            </InputLabel>
            <Select
              id="tipo-cc-select"
              value={value["operacion"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "operacion",
                id: "operacion"
              }}
            >
              <MenuItem value="">Todos</MenuItem>
              {OPERACION.map((operacion, index) => (
                <MenuItem key={index} value={operacion.value}>
                  {operacion.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <MultipleSelect
              label={"Origen del movimiento"}
              key={"tipoOperacion"}
              name={"tipoOperacion"}
              values={value["tipoOperacion"] || []}
              onChange={e => submitValue(e)}
              classes={{ formControl: classes.multiSelect }}
              options={TIPO_OPERACION}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              name={"fechaDesde"}
              label="Desde"
              input={{
                onChange: this.handleChangeFecha("fechaDesde"),
                value: value["fechaDesde"] || null
              }}
              startOfDate
              autoOk
              maxDate={moment()}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              name={"fechaHasta"}
              label="Hasta"
              input={{
                onChange: this.handleChangeFecha("fechaHasta"),
                value: value["fechaHasta"] || null
              }}
              autoOk
              maxDate={moment()}
              endOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TextField
              label={"Concepto"}
              key={"concepto"}
              name={"concepto"}
              type="search"
              value={value["concepto"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        {/* <div className={classes.styleRowBox}>
          <TextField
            label={"Solicitante"}
            key={"solicitante"}
            name={"solicitante"}
            type="search"
            value={value["solicitante"] || ""}
            onChange={e => submitValue(e)}
            InputProps={{
              endAdornment: (
                <Tooltip title="Usuario que ejecutó el Movimiento">
                  <InputAdornment position="end">
                    <InfoIcon />
                  </InputAdornment>
                </Tooltip>
              )
            }}
          />
        </div> */}
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  clear: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(withApollo(advancedFilter));
