import React, { PureComponent } from "react";
import cx from "classnames";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import AppQraphQL from "js/graphql/resolvers/parametrosCargaFactura.resolver";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import AddIconIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { findIndex } from "lodash";
import { removeTypename } from "js/utils/Helper";
import SortableComponent from "js/components/FormularioVentaFacturas/Parametros/SortableComponent";
import Tooltip from "@material-ui/core/Tooltip";
import { _t } from "js/utils/TranslationService";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { initialfields } from "../types";
import _ from "lodash";
import { validarCampos } from "../helpers";

class ParametrosCargaFactura extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      paramsCargaFactura: initialfields,
      nombreAtributo: "",
      tipoAtributo: "Texto",
      showInfo: true,
      errors: []
    };
  }

  getParametrosCargaFactura() {
    const { client } = this.props;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { paramsCargaFactura } = this.state;
    return client
      .query({
        query: AppQraphQL.queries.getParametrosCargaFacturaById,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: parseInt(id) || id
        }
      })
      .then(res => {
        if (!res.data.getParametrosCargaFactura.length) return;
        let values = [];
        res.data.getParametrosCargaFactura.forEach((v, i) => {
          values.push({
            ...v,
            key: v.key
          });
        });

        let unIncluded = _.differenceBy(paramsCargaFactura, values, "key");
        values = _.concat(values, unIncluded);

        this.setState({
          paramsCargaFactura: values
        });
      });
  }

  async componentDidMount() {
    this.setState({
      disabled: !AuthService.hasPerms([Roles.FACTURAS_POR_PUNTOS_R])
    });
    await this.getParametrosCargaFactura();
  }

  handleSortEnded(items) {
    this.setState({ paramsCargaFactura: items });
  }

  onClick(e, idRow, action = null) {
    const { paramsCargaFactura } = this.state;
    const index = findIndex(paramsCargaFactura, {
      key: idRow
    });
    let copy = Object.assign([], paramsCargaFactura);
    if (action === "delete") {
      copy.splice(index, 1);
    } else {
      let tempObj = {};
      const id = e.target.id || e.target.name;
      const value = e.target.value;
      tempObj = { ...paramsCargaFactura[index] };
      let result = value;
      if (
        action === "Lista" ||
        action === "Lista_Multiple" ||
        action === "Lista_Autocomplete"
      ) {
        result = value.includes(",") ? value.split(",") : value;
      }

      if (action === "Booleano") {
        tempObj[id][0] = parseInt(paramsCargaFactura[index][id][0]) ? "0" : "1";
      } else {
        tempObj[id] = result;
      }
      copy.splice(index, 1, tempObj);
    }
    this.labelHasError(copy);
  }

  handleChangeAttributo(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  labelHasError = paramsCargaFactura => {
    paramsCargaFactura.forEach(value => {
      value.labelHasError =
        !value.label.length ||
        paramsCargaFactura.filter(value2 => value2.label === value.label)
          .length > 1;
    });
    this.setState({ paramsCargaFactura });
  };

  addAtributo() {
    const { paramsCargaFactura, nombreAtributo, tipoAtributo } = this.state;
    if (!nombreAtributo || !tipoAtributo) return;
    const index = findIndex(paramsCargaFactura, {
      label: nombreAtributo
    });
    if (index === -1) {
      let tempObj = {};
      tempObj["label"] = nombreAtributo;
      tempObj["tipo"] = tipoAtributo;
      tempObj["key"] = paramsCargaFactura.length;

      const newArray = paramsCargaFactura.slice();
      newArray.push(tempObj);
      const message = "Atributo agregado correctamente";
      this.props.openNotification(message);
      this.setState({
        paramsCargaFactura: newArray,
        nombreAtributo: "",
        tipoAtributo: "Texto"
      });
    } else {
      const message = "No se puede crear un atributo ya existente";
      this.props.openNotification(message);
    }
  }

  async saveAction() {
    const {
      client,
      history,
      match: {
        params: { id }
      }
    } = this.props;
    try {
      let { paramsCargaFactura } = this.state;

      if (paramsCargaFactura.filter(v => v.labelHasError).length) return;
      paramsCargaFactura.forEach(element => {
        if (typeof element.key === "number") {
          delete element.key;
        }
        delete element.labelHasError;
      });

      let estadoCamposObligatorios = validarCampos(paramsCargaFactura, true);

      if (!estadoCamposObligatorios.status)
        throw new Error(estadoCamposObligatorios.message[0]);

      let estadoOtrosCampos = validarCampos(paramsCargaFactura, false);

      if (!estadoOtrosCampos.status)
        throw new Error(estadoOtrosCampos.message[0]);

      await client
        .mutate({
          mutation: AppQraphQL.mutations.bulkParametrosCargaFactura,
          refetchQueries: [`getRedirect`],
          variables: {
            definicionesParametrosCargaFactura: removeTypename(
              paramsCargaFactura
            ),
            idPrograma: parseInt(id, 10)
          }
        })
        .then(() => {
          history.push("/admin/programa/" + id + "/carga-facturas");
          this.props.openNotification(_t("Parametros carga de facturas"));
        });
    } catch (error) {
      this.props.openNotification(_t(error.message));
    }
  }

  volver = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.props.history.push("/admin/programa/" + id + "/carga-facturas");
  };

  changeIcon() {
    this.setState(prevState => ({ showInfo: !prevState.showInfo }));
  }

  render() {
    const {
      paramsCargaFactura,
      nombreAtributo,
      tipoAtributo,
      showInfo
    } = this.state;
    const { classes } = this.props;
    const { disabled } = this.state;
    return (
      <div>
        <Typography variant="h5" gutterBottom>
          Parámetros carga de facturas
        </Typography>
        <div className={classes.root}>
          {!disabled && (
            <div className={classes.addInput}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="name-simple">Nombre del campo</InputLabel>
                <Input
                  name="nombreAtributo"
                  value={nombreAtributo}
                  onChange={e => this.handleChangeAttributo(e)}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Select
                  name="tipoAtributo"
                  value={tipoAtributo}
                  onChange={e => this.handleChangeAttributo(e)}
                >
                  <MenuItem value={"Texto"}>Texto</MenuItem>
                  <MenuItem value={"Numerico"}>Número</MenuItem>
                  <MenuItem value={"Monto"}>Monto</MenuItem>
                  <MenuItem value={"Lista"}>Lista</MenuItem>
                  <MenuItem value={"Lista_Multiple"}>Lista múltiple</MenuItem>
                  <MenuItem value={"Lista_Autocomplete"}>
                    Lista autocompletable
                  </MenuItem>
                </Select>
              </FormControl>
              <Tooltip
                title="Agregar atributo"
                placement="top-start"
                enterDelay={300}
              >
                <IconButton onClick={this.addAtributo.bind(this)}>
                  <AddIconIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={cx(
                      classes.align,
                      classes.columnWidth100,
                      classes.border
                    )}
                  >
                    Nombre
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth100)}
                  >
                    Tipo de campo
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth100)}
                  >
                    Valores
                  </TableCell>
                </TableRow>
              </TableHead>
              <SortableComponent
                onClick={this.onClick.bind(this)}
                sortEnded={this.handleSortEnded.bind(this)}
                data={paramsCargaFactura}
                disabled={disabled}
              />
            </Table>
          </Paper>
          {!disabled && (
            <div className={classes.buttonSave}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={this.volver}
              >
                Volver
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.saveAction.bind(this)}
              >
                Guardar
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withApollo(withRouter(ParametrosCargaFactura));
