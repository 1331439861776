import gql from "graphql-tag";

export const getMiPerformanceCampanas = gql`
  query getMiPerformanceCampanas(
    $idPrograma: Int
    $idCampana: ID
    $idUsuario: Int
    $estado: EstadoCampana
    $fechaInicio: Date
    $fechaInicioHasta: Date
    $fechaFinDesde: Date
    $fechaFinHasta: Date
    $page: Int!
    $rowsPerPage: Int!
  ) {
    getMiPerformanceCampanas(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      performanceLike: {
        idPrograma: $idPrograma
        idCampana: $idCampana
        idUsuario: $idUsuario
        estado: $estado
      }
      performanceRange: {
        fechaInicio: { min: $fechaInicio, max: $fechaInicioHasta }
        fechaFin: { min: $fechaFinDesde, max: $fechaFinHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        fecha
        campana {
          _id
          nombre
          descripcion
          imagenes
          fechaInicio
          fechaFin
          estado
          puntosPorSegmentos {
            idSegmento
            puntos
          }
        }
        performanceUsuarios {
          usuario {
            idUsuario
            username
            perfil {
              nombre
              apellido
            }
          }
          performanceObjetivos {
            objetivo {
              nombre
              puntosPorSegmentos {
                idSegmento
                puntos
              }
            }
            targetsObjetivo {
              target
              targetFloat
              estado
              puntos
            }
            performance
          }
        }
      }
    }
  }
`;

export const getPerformanceCampanas = gql`
  query getPerformanceCampanas(
    $idPrograma: Int
    $idCampana: ID
    $idUsuario: Int
    $estado: EstadoCampana
    $fechaInicioDesde: Date
    $fechaInicioHasta: Date
    $fechaFinDesde: Date
    $fechaFinHasta: Date
    $page: Int!
    $rowsPerPage: Int!
  ) {
    getPerformanceCampanas(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      performanceLike: {
        idPrograma: $idPrograma
        idCampana: $idCampana
        idUsuario: $idUsuario
        estado: $estado
      }
      performanceRange: {
        fechaInicio: { min: $fechaInicioDesde, max: $fechaInicioHasta }
        fechaFin: { min: $fechaFinDesde, max: $fechaFinHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        infoPagina {
          total
          numeroPagina
          cantidadPaginas
        }
        fecha
        campana {
          _id
          nombre
          descripcion
          imagenes
          fechaInicio
          fechaFin
          estado
          puntosPorSegmentos {
            idSegmento
            puntos
          }
        }
        performanceUsuarios {
          usuario {
            idUsuario
            username
            perfil {
              nombre
              apellido
            }
          }
          performanceObjetivos {
            objetivo {
              nombre
              puntosPorSegmentos {
                idSegmento
                puntos
              }
            }
            targetsObjetivo {
              target
              targetFloat
              estado
              puntos
            }
            performance
          }
        }
      }
    }
  }
`;

export const getPerformanceCampana = gql`
  query getPerformanceCampana(
    $idPrograma: Int
    $idCampana: ID
    $page: Int!
    $rowsPerPage: Int!
  ) {
    getPerformanceCampana(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      performanceLike: { idPrograma: $idPrograma, idCampana: $idCampana }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        usuario {
          idUsuario
          username
          perfil {
            nombre
            apellido
          }
        }
        performanceObjetivos {
          objetivo {
            nombre
            puntosPorSegmentos {
              idSegmento
              puntos
            }
          }
          targetsObjetivo {
            target
            targetFloat
            estado
            puntos
          }
          performance
        }
      }
    }
  }
`;

export const getCampanasFiltros = gql`
  query getCampanasFiltros($idPrograma: Int!, $pagina: Pagina) {
    getCampanasFiltros(idPrograma: $idPrograma, pagina: $pagina) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
        __typename
      }
      itemsPagina {
        _id
        idCampana
        nombre
        estado
        __typename
      }
    }
  }
`;

export const getMisCampanas = gql`
  query getMisCampanas($idPrograma: Int!, $pagina: Pagina) {
    getMisCampanas(idPrograma: $idPrograma, pagina: $pagina) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
        __typename
      }
      itemsPagina {
        _id
        idCampana
        nombre
        fechaInicio
        fechaFin
        estado
        __typename
      }
    }
  }
`;

export default {
  queries: {
    getMiPerformanceCampanas,
    getPerformanceCampanas,
    getPerformanceCampana,
    getCampanasFiltros,
    getMisCampanas
  }
};
