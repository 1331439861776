import React from "react";
import ViewIcon from "@material-ui/icons/Visibility";
import base from "../../TablaReconocimientos/ActionCells/Base";

export default params => ({
  ...base(),
  tooltip: "Ver",
  label: "Ver",
  icon: <ViewIcon />,
  onclick: () => {
    const idProducto =
      params.item && params.item.producto && params.item.producto.idProducto
        ? params.item.producto.idProducto
        : "";
    params.history.push(
      window.location.pathname +
        "/producto/" +
        idProducto +
        "/eleccion/" +
        params.itemId +
        "/view"
    );
  }
});
