import { compose } from "recompose";
import CrearReconocimientoRaw from "./CrearReconocimiento";
import AprobacionReconocimientoRaw from "./AprobacionReconocimiento";
import RechazoReconocimientoRaw from "./RechazoReconocimiento";
import EditarReconocimientoRaw from "./EditarReconocimiento";
import TablaReconocimientosRaw from "./TablaReconocimientos";
import {
  Reconocido as ReconocidoRaw,
  ReconocidoAprobado as ReconocidoAprobadoRaw,
  ReconocidoRechadazo as ReconocidoRechazadoRaw,
  ReconocidoEditado as ReconocidoEditadoRaw
} from "./Reconocido";
import ReconocimientosAprobadosRaw from "./ReconocimientosAprobados";
import withPrograma from "js/hoc/withPrograma";
import { withRouter } from "react-router-dom";
import { graphql, withApollo } from "react-apollo";
import ReconocimientoGraphQL from "js/graphql/resolvers/reconocimiento.resolver";

const enhance = compose(
  withPrograma,
  withRouter,
  withApollo,
  graphql(ReconocimientoGraphQL.queries.getFormularioReconocimiento, {
    options: ({ programa }) => ({
      variables: {
        idPrograma: parseInt(programa.idPrograma) || programa.idPrograma
      },
      fetchPolicy: "network-only"
    }),
    props: ({
      data: { loading, getFormularioReconocimiento },
      ownProps: { history }
    }) => {
      if (loading) return;
      const formulario = getFormularioReconocimiento;
      return { formulario };
    }
  })
);

export const CrearReconocimiento = enhance(CrearReconocimientoRaw);
export const AprobacionReconocimiento = enhance(AprobacionReconocimientoRaw);
export const RechazoReconocimiento = enhance(RechazoReconocimientoRaw);
export const EditarReconocimiento = enhance(EditarReconocimientoRaw);
export const ReconocidoEditado = enhance(ReconocidoEditadoRaw);
export const TablaReconocimientos = enhance(TablaReconocimientosRaw);
export const Reconocido = enhance(ReconocidoRaw);
export const ReconocidoAprobado = enhance(ReconocidoAprobadoRaw);
export const ReconocidoRechazado = enhance(ReconocidoRechazadoRaw);
export const ReconocimientosAprobados = enhance(ReconocimientosAprobadosRaw);
