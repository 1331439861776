import React from "react";
import { _t } from "js/utils/TranslationService";
import { Field } from "redux-form";
import { TextField } from "redux-form-material-ui";
import { isRequired, isValidEmail } from "js/helpers/validations";
import { styles } from "../styles.js";

const EmailField = ({
  name,
  label,
  placeholder,
  required,
  classes,
  translation,
  disabled
}) => (
  <Field
    type="email"
    required={required}
    name={name}
    label={translation || _t(label)}
    placeholder={placeholder}
    component={TextField}
    disabled={disabled}
    fullWidth
    validate={(() => {
      let validations = [];
      if (required) {
        validations.push(isRequired);
      }
      validations.push(isValidEmail);
      return validations;
    })()}
    className={classes ? classes.field : styles.field}
  />
);

export default EmailField;
