import { withStyles } from "@material-ui/core/styles";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import Tabla from "./tabla";
import styles from "./styles";
import withPrograma from "js/hoc/withPrograma";

const enhance = compose(
  withApollo,
  withRouter,
  withPrograma,
  withStyles(styles)
);

export default enhance(Tabla);
