import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { Movimientos } = Fragmentos;

export const getMovimientosUsuarios = gql`
  query getMovimientosUsuarios(
    $concepto: String
    $fechaDesde: Date
    $fechaHasta: Date
    $idPrograma: Int
    $idCuentaCorriente: Int
    $idMovimiento: Int
    $idSegmento: Int
    $operacion: Operacion
    $order: Order = asc
    $orderBy: MovimientoColumn = fecha
    $page: Int = 1
    $rowsPerPage: Int = 10
    $solicitante: String
    $tipoOperacion: [TipoOperacion]
    $idUsuario: Int
    $cuentaCorriente_usuario_gerencia: String
  ) {
    getMovimientosUsuarios(
      movimientoLike: {
        concepto: $concepto
        idCuentaCorriente: $idCuentaCorriente
        # cuentaCorriente_programa_idPrograma: $idPrograma
        cuentaCorriente_usuario_idUsuario: $idUsuario
        cuentaCorriente_usuario_idSegmento: $idSegmento
        cuentaCorriente_usuario_idPrograma: $idPrograma
        cuentaCorriente_usuario_gerencia: $cuentaCorriente_usuario_gerencia
        idMovimiento: $idMovimiento
        operacion: $operacion
        solicitante_username: $solicitante
        tipoOperacion: $tipoOperacion
      }
      movimientoRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
      movimientoOrder: { column: $orderBy, order: $order }
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
    ) {
      ...movimientos
    }
  }
  ${Movimientos.fragments.movimientos}
`;

export const getMisMovimientos = gql`
  query misMovimientos(
    $idMovimiento: Int
    $operacion: Operacion
    $tipoOperacion: [TipoOperacion]
    $fecha: Date
    $idUsuarioSolicitante: Int
    $concepto: String
    $puntos: Int
    $monto: Float
    $monedaBase: String
    $numeroFactura: String
    $saldoParcial: Int
    $balance: Int
    $creditosUtilizados: Int
    $tasaConversion: Float
    $fechaDesde: Date
    $fechaHasta: Date
    $orderBy: MovimientoColumn!
    $order: Order!
    $page: Int!
    $rowsPerPage: Int = 10
  ) {
    misMovimientos(
      movimientoLike: {
        idMovimiento: $idMovimiento
        operacion: $operacion
        tipoOperacion: $tipoOperacion
        fecha: $fecha
        idUsuarioSolicitante: $idUsuarioSolicitante
        concepto: $concepto
        puntos: $puntos
        monto: $monto
        monedaBase: $monedaBase
        numeroFactura: $numeroFactura
        saldoParcial: $saldoParcial
        balance: $balance
        creditosUtilizados: $creditosUtilizados
        tasaConversion: $tasaConversion
      }
      movimientoRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
      movimientoOrder: { column: $orderBy, order: $order }
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
    ) {
      ...movimientos
    }
  }
  ${Movimientos.fragments.misMovimientos}
`;

export default {
  queries: {
    getMovimientosUsuarios,
    getMisMovimientos
  }
};
