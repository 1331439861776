import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import withPrograma from "js/hoc/withPrograma";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import { required as requiredValidation } from "js/components/Shared/Form/validations.js";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { styles } from "js/components/Shared/Form/styles.js";
import { ReduxTypeAhead, TypeAhead } from "js/components/Shared/Form/Fields";
import { compose } from "recompose";
import { withApollo } from "react-apollo";

class Programa extends PureComponent {
  state = { programas: [] };

  componentDidMount = async () => {
    const { client } = this.props;
    const hasPerms = AuthService.hasPerms([Roles.PROGRAMA_LISTA]);
    if (!hasPerms) {
      this.setMiPrograma();
      return;
    }
    const { data } = await client.query({
      query: AppQraphQL.queries.getProgramasFilter,
      errorPolicy: "all",
      fetchPolicy: "no-cache"
    });
    if (data) {
      this.setState({ programas: data.getProgramasToFilter.itemsPagina });
    } else {
      this.setMiPrograma();
    }
  };

  setMiPrograma = () => {
    // Cambiar cuando el BE devuelva los programas disponibles para el usuario
    const {
      programa: { idPrograma, nombre },
      onChange,
      search = null,
      multiple
    } = this.props;
    this.setState({ programas: [{ idPrograma, nombre }] });
    setTimeout(() => {
      if (multiple) onChange([{ value: idPrograma }]);
      else onChange({ value: idPrograma });
      search();
    }, 1000);
  };

  render() {
    const {
      classes,
      disabled,
      required,
      // showAllChips = false,
      name = "idPrograma",
      match: { path },
      redux = false,
      filterByType,
      ...props
    } = this.props;
    let { programas } = this.state;
    if (filterByType) {
      programas = programas.filter(programa => programa.tipo === filterByType);
    }

    const Component = redux ? ReduxTypeAhead : TypeAhead;
    const rest = redux ? {} : props;
    return (
      <Component
        name={name}
        label="Buscar por programa"
        className={classes ? classes.field : styles.field}
        disabled={
          disabled || (path.match(/^\/admin\/programa\/:id*/) !== null && true)
        }
        validate={required ? requiredValidation : null}
        suggestions={{ programas }}
        path={{
          path: "programas",
          value: "idPrograma",
          label: "nombre"
        }}
        placeholder={"Programa"}
        {...rest}
      />
    );
  }
}

Programa.defaultProps = {
  search: () => undefined,
  onChange: () => undefined
};

const enhance = compose(
  withPrograma,
  withRouter,
  withStyles(styles),
  withApollo
);

export default enhance(Programa);
