export const getSize = async image => {
  return new Promise((resolve, rej) => {
    var reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = function(e) {
      var image = new Image();
      image.src = e.target.result;
      image.onload = function() {
        var height = this.height;
        var width = this.width;
        resolve({ height, width });
      };
    };
  });
};

export const updateQueryParamsAsync = ({ key, value }) => state => ({
  [key]: value,
  queryParams: {
    programaLike: { [key]: value }
  }
});

export const FACING_MODE_USER = "user";
export const FACING_MODE_ENVIRONMENT = "environment";
