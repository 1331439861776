import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import { Paper, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";

import styles from "./indicadorStyles.js";
import { numberWithCommas } from "js/utils";
import { _t } from "js/utils/TranslationService";

class TrianguloIndicator extends PureComponent {
  componentDidMount() {
    this.setCanvasesDimensions();
    this.createTriangles();
  }

  /**
   * Retorna el porcentaje del targetAlcanzado con un máximo de 999
   * @param {target} target
   * @param {performance} performance
   */
  getPercentaje = (target, performance) => {
    const result = (performance * 100) / target;
    return result > 1000 ? 100 : result;
  };

  setCanvasesDimensions() {
    const { index } = this.props;
    const canvases = document.querySelectorAll(
      '[class$="canvas-' + index + '"]'
    );
    canvases.forEach(canvas => {
      const parent = canvas.parentElement;
      // - 100 por los paddings horizontales y -40 por los paddings verticales
      const rightPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-right")
      );
      const leftPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-left")
      );
      const topPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-top")
      );
      const bottomPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-bottom")
      );
      canvas.setAttribute(
        "width",
        parent.clientWidth - (rightPadding + leftPadding)
      );
      canvas.setAttribute(
        "height",
        parent.clientHeight - (topPadding + bottomPadding)
      );
    });
  }

  /**
   * Se dibujan en la mitad canvas entonces la posición de sus vertices serán:
   * - Para ambos triangulos:
   *  . El vertice derecho: su componente X es, o 3,5/5 en desktop o 3/4 en mobile, del ancho del canvas
   *    (ej: si el ancho es 100 y es desktop entonces será 70, y si es mobile sería 75)
   *    su componente Y es la altura del canvas menos el BOTTOM_OFFSET
   *  . El vertice izquierdo: su componente X es o 1,5/5 en desktop o 1/4 en mobile, del ancho del canvas
   *    (ej: si el ancho es 100 y es desktop será 30, y si es mobile sería 25)
   *    y su componente Y es la altura del canvas menos el BOTTOM_OFFSET
   * - Para el triangulo del fondo el vertico de arriba es el TOP_OFFSET
   * - Para el triangulo con color es dinámico y calculado, su valoe va entre entre la altura menos el BOTTOM_OFFSET y el TOP_OFFSET
   * Las lineas se dibujan de la siguiente manera:
   * - La línea que indica el objetivo se dibuja desde el top del triangulo del fondo hacia el ancho del canvas (hacia la derecha)
   * - La línea que indica el target alcanzado se dibuja desde el top del triangulo con color hacia el principio del canvas (hacia la izquierda)
   */
  createTriangles() {
    const {
      index,
      performanceObjetivo: { performance, targetsObjetivo },
      parametrosVisuales: {
        colorFondo = "#ccc",
        colorAvance = "#ce762e",
        colorTextoTarget = "#4D5D67",
        colorTextoAvance = "#ce762e",
        colorLineaTarget = "#4D5D67",
        colorLineaAvance = "#ce762e",
        sizeNumeros = "16",
        sizeTextos = "13",
        colorCheckIcon = "#4EC230",
        showPuntosTargets = true
      },
      isXs
    } = this.props;

    // Empezando a dibujar el triangulo con un un Y de 10 y hasta un Y de height - 10
    const TOP_OFFSET = 20;
    const BOTTOM_OFFSET = 20;
    const RIGHT_X = isXs ? 3 / 4 : 3.5 / 5;
    const LEFT_X = isXs ? 1 / 4 : 1.5 / 5;
    const TOP_X = (RIGHT_X + LEFT_X) / 2;

    let target = 0;
    targetsObjetivo.forEach(targetObjetivo => {
      if (target < targetObjetivo.target) {
        target = targetObjetivo.target;
      }
    });
    const objectiveCanvas = document.getElementById("objetivo-canvas-" + index);
    const lineCanvas = document.getElementById("objetivo-line-canvas-" + index);
    const performanceCanvas = document.getElementById(
      "performance-canvas-" + index
    );
    const width = objectiveCanvas.clientWidth;
    const height = objectiveCanvas.clientHeight;

    // porcentaje del performance para sacar el height del performance en relación al height del canvas
    // después restar el performance height del height porque el punto inicial del canvas empieza arriba a la izquiera
    // pero lo estamos dibujando de abajo para arriba. Si es mayor que 100 dejarlo en 100 para que no sobrepase el triangulo
    const performancePercentage = this.getPercentaje(target, performance);
    const performanceHeight =
      height -
      (performancePercentage * (height - BOTTOM_OFFSET - TOP_OFFSET)) / 100;

    // Triangulo avance
    this.drawTriangle(
      performanceCanvas,
      width * TOP_X,
      performanceHeight,
      width * RIGHT_X,
      height - BOTTOM_OFFSET,
      width * LEFT_X,
      height - BOTTOM_OFFSET,
      colorAvance
    );
    // Linea avance
    this.drawLine(
      lineCanvas,
      width * TOP_X,
      performanceHeight,
      0,
      colorLineaAvance,
      numberWithCommas(performance),
      colorTextoAvance,
      _t("Avance actual"),
      colorTextoAvance,
      "left",
      sizeNumeros,
      sizeTextos
    );

    // Triangulo entero
    this.drawTriangle(
      objectiveCanvas,
      width * TOP_X,
      TOP_OFFSET,
      width * RIGHT_X,
      height - BOTTOM_OFFSET,
      width * LEFT_X,
      height - BOTTOM_OFFSET,
      colorFondo
    );

    const showTargetNumber = targetsObjetivo.length > 1;
    const checkIconsPositions = [];
    targetsObjetivo.forEach((targetObjetivo, index) => {
      const targetPercentage = this.getPercentaje(
        target,
        targetObjetivo.target
      );
      const targetHeight =
        height -
        BOTTOM_OFFSET -
        (targetPercentage * (height - BOTTOM_OFFSET - TOP_OFFSET)) / 100;

      // Linea por cada target
      this.drawLine(
        lineCanvas,
        width * TOP_X,
        targetHeight,
        width,
        colorLineaTarget,
        numberWithCommas(targetObjetivo.target),
        colorTextoTarget,
        `${_t("Target")} ${showTargetNumber ? index + 1 : ""} ${
          targetObjetivo.puntos && showPuntosTargets
            ? `(${numberWithCommas(targetObjetivo.puntos)} ${_t("puntos")})`
            : ""
        }`,
        colorTextoTarget,
        "right",
        sizeNumeros,
        sizeTextos
      );

      if (performance >= targetObjetivo.target) {
        checkIconsPositions.push({
          x: width,
          y: targetHeight
        });
      }
    });

    if (checkIconsPositions.length !== 0) {
      const container = document.getElementById(`icon-container-${index}`);
      ReactDOM.render(
        this.renderCheckIcons(checkIconsPositions, index, colorCheckIcon),
        container
      );
    }
  }

  drawTriangle(canvas, topX, topY, rightX, rightY, leftX, leftY, color) {
    if (canvas.getContext) {
      const context = canvas.getContext("2d");
      context.beginPath();
      context.moveTo(topX, topY);
      context.lineTo(rightX, rightY);
      context.lineTo(leftX, leftY);
      context.lineTo(topX, topY);
      context.fillStyle = color;
      context.fill();
    }
  }

  drawLine(
    canvas,
    x,
    y,
    endX,
    color,
    topText,
    topColor,
    bottomText,
    bottomColor,
    align,
    topSize,
    bottomSize
  ) {
    const {
      parametrosVisuales: { fontFamily = "Roboto" },
      isXs
    } = this.props;
    if (canvas.getContext) {
      const TEXT_X_OFFSET = align === "right" ? -3 : 3;
      const ctx = canvas.getContext("2d");
      ctx.setLineDash([6, 2]); /*dashes are 5px and spaces are 3px*/
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(endX, y);
      ctx.strokeStyle = color;
      ctx.stroke();
      if (topText) {
        ctx.font = `bold ${isXs ? "14" : topSize}px ${fontFamily}`;
        ctx.fillStyle = topColor;
        ctx.textAlign = align;
        // -3 para que quede bien prolijo con la línea el texto
        ctx.fillText(topText, endX + TEXT_X_OFFSET, y - 3);
      }
      if (bottomText) {
        ctx.font = `italic ${isXs ? "11" : bottomSize}px ${fontFamily}`;
        ctx.fillStyle = bottomColor;
        ctx.textAlign = align;
        // -3 y + 13 para que quede bien prolijo con la línea el texto
        ctx.fillText(bottomText, endX + TEXT_X_OFFSET, y + 13);
      }
    }
  }

  renderCheckIcons(checkIcons, index, colorCheckIcon) {
    return (
      <React.Fragment>
        {checkIcons.map((checkIcon, i) => (
          <CheckIcon
            key={`icon-${i}-${index}`}
            style={{
              color: colorCheckIcon,
              fill: colorCheckIcon,
              position: "absolute",
              left: checkIcon.x,
              top: checkIcon.y,
              transform: "translateY(-50%)"
            }}
          />
        ))}
      </React.Fragment>
    );
  }

  renderObjetivo() {
    const {
      index,
      classes,
      performanceObjetivo: { objetivo },
      parametrosVisuales: {
        colorNombreObjetivo = "#4D5D67",
        sizeNombreObjetivo = "16",
        fontFamily = "Roboto"
      }
    } = this.props;
    return (
      <Grid className={classes.objetivoWrapper} container spacing={8}>
        <Grid
          className={classes.objetivoTitle}
          style={{
            color: colorNombreObjetivo,
            fontSize: `${sizeNombreObjetivo}px`,
            fontFamily: fontFamily
          }}
          item
          xs={12}
        >
          {objetivo.nombre}
        </Grid>
        <Grid
          className={classNames(classes.chartContainer, classes.trianguloChart)}
          item
          xs={12}
        >
          <canvas
            id={"objetivo-canvas-" + index}
            className={classNames(classes.canvas, "objetivo-canvas-" + index)}
          />
          <canvas
            id={"performance-canvas-" + index}
            className={classNames(
              classes.canvas,
              classes.indicadorCanvas,
              "performance-canvas-" + index
            )}
          />
          <canvas
            id={"objetivo-line-canvas-" + index}
            className={classNames(
              classes.canvas,
              classes.indicadorCanvas,
              "objetivo-line-canvas-" + index
            )}
          />
          <div
            id={`icon-container-${index}`}
            style={{ width: "100%", height: "100%", position: "relative" }}
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      classes,
      parametrosVisuales: { containerShadow }
    } = this.props;

    return (
      <React.Fragment>
        {containerShadow ? (
          <Paper className={classes.objetivoContainer} elevation={1}>
            {this.renderObjetivo()}
          </Paper>
        ) : (
          <React.Fragment>{this.renderObjetivo()}</React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

TrianguloIndicator.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.string,
  performanceObjetivo: PropTypes.any,
  parametrosVisuales: PropTypes.any
};

export default withStyles(styles)(TrianguloIndicator);
