import gql from "graphql-tag";

export const Programa = {};

Programa.fragments = {
  programaAdminCC: gql`
    fragment programa on PaginaPrograma {
      itemsPagina {
        idPrograma
        nombre
        maximoMovimientoPuntos
        paises @include(if: $withProgram) {
          idPais
          descripcion
        }
        usuarios @include(if: $withProgram) {
          estado
          email
          username
          idUsuario
          cuentaCorriente {
            balance
          }
        }
        segmentos @include(if: $withProgram) {
          idSegmento
          nombre
        }
        cuentaCorriente @include(if: $withProgram) {
          balance
        }
      }
    }
  `,
  programasAdminCC: gql`
    fragment programas on PaginaPrograma {
      itemsPagina {
        idPrograma
        nombre
      }
    }
  `,
  definicionesPerfil: gql`
    fragment definicionesPerfil on DefinicionPerfil {
      _id
      key
      label
      tipo
      placeholder
      orden
      grupo
      value
      visibleRegistro
      requeridoRegistro
      visiblePerfil
      requeridoPerfil
    }
  `,
  proveedoresCC: gql`
    fragment proveedoresCC on Proveedor {
      idProveedor
      nombreFantasia
    }
  `,
  eleccionProductosCC: gql`
    fragment eleccionProductosCC on EleccionProducto {
      idEleccionProducto
      producto {
        nombre
        idProducto
        proveedor {
          idProveedor
          nombreFantasia
        }
      }
    }
  `
};

export default Programa;
