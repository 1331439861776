import React, { Component, Fragment } from "react";
import { get } from "lodash";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Subsection } from "js/pages/TemplateBuilder2/Layouts";
import {
  ColorPicker,
  PixelPicker,
  Select,
  SortableComponent
} from "js/pages/TemplateBuilder2/Controls";
import { _t } from "js/utils/TranslationService";
import Banner from "js/pages/TemplateBuilder2/Components/Banner";
import newItems from "js/pages/TemplateBuilder2/Components/NewItems";

const { newBase } = newItems;

class Fila extends Component {
  getKey = key =>
    this.props.position ? `${this.props.position.join(".")}.${key}` : key;

  handleOnChange = key => this.props.onChange(this.getKey(key));

  render() {
    const {
      value,
      onChange,
      value: { contenidos },
      indexItem,
      position = [0],
      onChangeFilaTipo,
      idPrograma
    } = this.props;
    return (
      <Grid container spacing={24}>
        <Grid item sm={3}>
          <Typography variant="subtitle2" gutterBottom>
            {_t("Visible")}
          </Typography>
          <Switch
            checked={get(value, "visible")}
            color="secondary"
            onChange={e => this.handleOnChange("visible")(e.target.checked)}
          />
        </Grid>
        <Grid item sm={3}>
          <Typography variant="subtitle2" gutterBottom>
            {_t("Tipo de fila (# elementos y distribución)")}
          </Typography>
          <Select
            options={[
              { label: "1 x 1/1", value: "1" },
              { label: "2 x 1/2", value: "2" },
              { label: "3 x 1/3", value: "3" },
              { label: "4 x 1/4", value: "4" },
              { label: "6 x 1/6", value: "6" }
            ]}
            value={get(value, "filaTipo")}
            onChange={onChangeFilaTipo(position, this.getKey("filaTipo"))}
          />
        </Grid>
        <Grid item sm={3}>
          <Typography variant="subtitle2" gutterBottom>
            {_t("Ancho total")}
          </Typography>
          <Switch
            color="secondary"
            checked={get(value, "fullWidth")}
            onChange={e => this.handleOnChange("fullWidth")(e.target.checked)}
          />
        </Grid>
        <Grid item xs={12}>
          <Subsection title="Estilo">
            <Grid container spacing={24}>
              <Grid item sm={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {_t("Margen inferior")}
                </Typography>
                <PixelPicker
                  pixels={get(value, "css.marginBottom")}
                  onChange={this.handleOnChange("css.marginBottom")}
                />
              </Grid>
              <Grid item sm={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {_t("Margen superior")}
                </Typography>
                <PixelPicker
                  pixels={get(value, "css.marginTop")}
                  onChange={this.handleOnChange("css.marginTop")}
                />
              </Grid>
              <Grid item sm={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {_t("Padding superior")}
                </Typography>
                <PixelPicker
                  pixels={get(value, "css.paddingTop")}
                  onChange={this.handleOnChange("css.paddingTop")}
                />
              </Grid>
              <Grid item sm={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {_t("Padding inferior")}
                </Typography>
                <PixelPicker
                  pixels={get(value, "css.paddingBottom")}
                  onChange={this.handleOnChange("css.paddingBottom")}
                />
              </Grid>
              <Grid item sm={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {_t("Padding lateral de elementos")}
                </Typography>
                <PixelPicker
                  pixels={`${get(value, "spaceBetweenLateral")}`}
                  onChange={this.handleOnChange("spaceBetweenLateral")}
                />
              </Grid>
              <Grid item md={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {_t("Borde")}
                </Typography>
                <Select
                  options={[
                    { label: "Sin borde", value: "hidden" },
                    { label: "Doble", value: "double" },
                    { label: "Guionado", value: "dashed" },
                    { label: "Normal", value: "solid" },
                    { label: "Punteado", value: "dotted" }
                  ]}
                  value={get(value, "css.borderStyle", "hidden")}
                  onChange={this.handleOnChange("css.borderStyle")}
                />
              </Grid>
              {get(value, "css.borderStyle", "hidden") !== "hidden" && (
                <Fragment>
                  <Grid item sm={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      {_t("Grosor de borde")}
                    </Typography>
                    <PixelPicker
                      pixels={`${get(value, "css.borderWidth")}`}
                      onChange={this.handleOnChange("css.borderWidth")}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      {_t("Color de borde")}
                    </Typography>
                    <ColorPicker
                      color={get(value, "css.borderColor")}
                      onChange={this.handleOnChange("css.borderColor")}
                    />
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Subsection>
        </Grid>
        <Grid item xs={12}>
          <SortableComponent
            position={position}
            newItem={newBase}
            isCollapsable={true}
            items={contenidos}
            component={Banner}
            maxAmountItems={get(value, "filaTipo", "1")}
            staticAmountItems={true}
            metadata={{ filaIndex: indexItem, onChange: onChange, idPrograma }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default Fila;
