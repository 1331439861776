export default theme => ({
  root: {
    maxWidth: 400,
    flexGrow: 1
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    marginBottom: 20,
    backgroundColor: theme.palette.background.default
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: "hidden",
    width: "100%"
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 32
  },
  map: {
    width: "100%",
    height: "100%"
  },
  mapContainer: {
    width: "100%",
    height: 288,
    position: "relative"
  },
  addressMapContainer: {
    width: "100%",
    minHeight: 150,
    position: "relative"
  }
});
