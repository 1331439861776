import React, { PureComponent, Fragment } from "react";
import Form from "js/pages/Admin/Programa/Campana/Objetivos/MyForm.js";
import CampanaGraphQL from "js/graphql/resolvers/campana.resolver";
import ObjetivoCampanaGraphQL from "js/graphql/resolvers/campanaObjetivo.resolver";
import SegmentosGraphQL from "js/graphql/resolvers/segmento.resolver";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {},
      disabled: false,
      errors: null,
      id: this.props.match.params.id,
      idCampana: this.props.match.params.idCampana,
      segmentos: [],
      campana: null
    };
  }

  async componentDidMount() {
    const { client, history } = this.props;
    let { id, idCampana } = this.state;

    idCampana = parseInt(idCampana) || idCampana;
    id = parseInt(id) || id;

    if (idCampana === "new") {
      history.push(`/admin/programa/${id}/campanas`);
    } else {
      await client
        .query({
          query: CampanaGraphQL.queries.getCampanasInput,
          fetchPolicy: "network-only",
          variables: {
            campanaLike: {
              idCampana
            },
            orderBy: "fechaCreacion",
            order: "desc"
          }
        })
        .then(res => {
          const campana = res.data.getCampanas
            ? res.data.getCampanas.itemsPagina[0]
            : null;

          this.setState({ campana });
        });

      await client
        .query({
          query: ObjetivoCampanaGraphQL.queries.getObjetivosCampana,
          fetchPolicy: "network-only",
          variables: {
            idCampana
          }
        })
        .then(res => {
          const objetivos = res.data.getObjetivosCampana;
          if (objetivos && objetivos.length !== 0) {
            const fields = objetivos.map(objetivo => {
              return {
                ...objetivo,
                fields: objetivo.puntosPorSegmentos
              };
            });
            this.setState({
              initialValues: {
                fields
              }
            });
          }
        });
    }

    await client
      .query({
        query: SegmentosGraphQL.queries.getSegmentosInput,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: id
        }
      })
      .then(res => {
        const segmentos = res.data.getSegmentos ? res.data.getSegmentos : null;

        this.setState({ segmentos });
      });
  }

  async submit(values) {
    const { client, history } = this.props;
    const { id, idCampana, initialValues } = this.state;

    const objetivos = [];
    if (values.fields) {
      values.fields.forEach(element => {
        const item = {
          idPrograma: parseInt(id),
          idCampana: parseInt(idCampana),
          idObjetivo: parseInt(element.idObjetivo),
          nombre: element.nombre,
          formaSumar: element.formaSumar
        };

        if (element.fields) {
          item.puntosPorSegmentos = element.fields;
        }
        objetivos.push(item);
      });
      await client
        .mutate({
          mutation: ObjetivoCampanaGraphQL.mutations.updateObjetivosCampana,
          variables: {
            objetivos
          }
        })
        .then(res => {
          const mensaje =
            "Objetivos " +
            (initialValues.fields ? "modificados" : "creados") +
            " correctamente";
          this.props.openNotification(mensaje);
          history.push(`/admin/programa/${id}/campanas/${idCampana}`);
        })
        .catch(errors => {
          const arr = [];
          arr.push({ message: "Error inesperado" });
          this.setState({
            errors:
              errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
          });
          return false;
        });
    }
  }

  render() {
    return (
      <Fragment>
        <Form
          id={this.state.id}
          errors={this.state.errors}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
          segmentos={this.state.segmentos}
          campana={this.state.campana}
        />
      </Fragment>
    );
  }
}

export default Edit;
