import React from "react";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import base from "js/components/Shared/Tabla/ActionCells/Base";
import AuthService from "js/utils/AuthService";

export default ({ perms = [], optional = [], history, itemId, item }) => ({
  ...base(),
  label: "Variables MED",
  icon: <TrackChangesIcon />,
  tooltip: "Gestionar variables",
  show: () => {
    if (
      AuthService.hasPerms(perms) &&
      item.tipo === "CONVENCIONAL" &&
      item.estado === "ACTIVO"
    ) {
      return true;
    }
    return false;
  },
  onclick: () =>
    history.push(window.location.pathname + "/" + itemId + "/variables")
});
