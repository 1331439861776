export default theme => ({
  objetivoContainer: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    marginRight: "10px"
  },
  objetivoWrapper: {
    // display: "block",
    display: "flex",
    // flexDirection: "column",
    flexGrow: 1,
    padding: "20px"
  },
  objetivoTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Roboto",
    color: "#4D5D67"
  },
  objetivoNumbers: {
    display: "flex",
    flexGrow: 1,
    position: "relative",
    minHeight: "180px",
    maxWidth: "300px",
    margin: "0 auto",
    padding: "10px 0px !important",
    borderBottom: "1px solid #ccc"
  },
  objetivoPercentaje: {
    display: "flex",
    flexGrow: 1,
    position: "relative",
    minHeight: "90px",
    maxWidth: "180px",
    margin: "20px auto"
  },
  canvas: {
    position: "absolute"
  },
  indicadorCanvas: {
    zIndex: 100
  }
});
