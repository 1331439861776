import React, { PureComponent } from "react";
import { _t } from "js/utils/TranslationService";
import FormControl from "@material-ui/core/FormControl/FormControl";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import client from "js/App/client.graphql.js";
import UsuarioQraphQL from "js/graphql/resolvers/usuario.resolver";
import ProgramaQraphQL from "js/graphql/resolvers/programa.resolver";
import SegmentoGraphQL from "js/graphql/resolvers/segmento.resolver.js";
import Dialog from "js/components/Dialog/Dialog";

class LoginAsDialog extends PureComponent {
  state = {};
  handleChange = select => this.setState({ idSegmento: select.value });

  content = () => {
    const { classes, idPrograma } = this.props;
    const { idSegmento } = this.state;
    return (
      <FormControl className={classes.formControl}>
        <TypeAhead
          label={"Segmento"}
          path={{
            path: "getSegmentos",
            value: "idSegmento",
            label: "label"
          }}
          placeholder={"Buscar por segmento"}
          query={SegmentoGraphQL.queries.getSegmentosInput}
          queryParams={{ idPrograma }}
          noOptionsMessage={"No hay segmentos"}
          value={idSegmento}
          onChange={this.handleChange}
        />
      </FormControl>
    );
  };

  handleClick = async () => {
    try {
      const res = await client.mutate({
        mutation: UsuarioQraphQL.mutations.generarToken,
        variables: {
          idPrograma: this.props.idPrograma,
          idSegmento: this.state.idSegmento
        },
        errorPolicy: "all"
      });
      const res2 = await client.query({
        query: ProgramaQraphQL.queries.getProgramaById,
        variables: {
          idPrograma: this.props.idPrograma
        },
        errorPolicy: "all"
      });
      const protocol = `${window.location.protocol}//`;
      const url = res2.data.getProgramas.itemsPagina[0].url;
      const port = window.location.port && `:${window.location.port}`;
      const token = res.data.generarToken;
      window.open(`${protocol}${url}${port}/landing?token=${token}`, "_blank");
    } catch (error) {
      console.error(error);
    } finally {
      this.props.handleClose();
    }
  };

  render() {
    let { handleClose, classes } = this.props;
    client
      .query({
        query: SegmentoGraphQL.queries.getSegmentosInput,
        variables: {
          idPrograma: this.props.idPrograma
        },
        errorPolicy: "all"
      })
      .then(async ({ data: getSegmentos }) => {
        if (
          getSegmentos &&
          getSegmentos.getSegmentos &&
          getSegmentos.getSegmentos.length === 1
        ) {
          this.setState({
            idSegmento: getSegmentos.getSegmentos[0].idSegmento
          });
          await this.handleClick();
        }
      });
    return (
      <Dialog
        open
        content={this.content()}
        handleClose={handleClose}
        title={_t("Iniciar sesión como")}
        cancelText={_t("Cancelar")}
        cancelAction={handleClose}
        okText={_t("Aceptar")}
        okAction={this.handleClick}
        classes={classes}
      />
    );
  }
}

LoginAsDialog.defaultProps = {
  classes: {},
  handleClose: () => undefined,
  idPrograma: () => undefined
};
export default LoginAsDialog;
