import React from "react";
import { Field } from "redux-form";
import { TextField } from "redux-form-material-ui";
import { isRequired, isNumeric } from "js/helpers/validations";
import { _t } from "js/utils/TranslationService";
import {
  normalizeFloat,
  formatFloat,
  intParser
} from "js/pages/Admin/Programa/Edit/helper";
import { styles } from "../styles.js";

const InputNumberField = ({
  name,
  label,
  placeholder,
  required,
  classes,
  disabled,
  translation,
  customValidations = [],
  InputProps,
  helperText,
  isFloat = false,
  decimalNumbers = 2,
  variant
}) => (
  <Field
    type="text"
    required={required}
    name={name}
    label={translation || _t(label)}
    placeholder={placeholder}
    component={TextField}
    disabled={disabled}
    fullWidth
    format={formatFloat(isFloat ? decimalNumbers : 0)}
    normalize={normalizeFloat(isFloat ? decimalNumbers : 0)}
    parse={!isFloat && intParser}
    validate={(() => {
      let validations = [];
      if (required) {
        validations.push(isRequired);
      }
      validations.push(isNumeric);
      return validations.concat(customValidations);
    })()}
    className={classes ? classes.field : styles.field}
    InputProps={InputProps}
    InputLabelProps={{ shrink: true }}
    helperText={helperText}
    variant={variant}
  />
);

export default InputNumberField;
