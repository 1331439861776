import gql from "graphql-tag";

export const getLocaciones = gql`
  query getPaises {
    getPaises {
      idPais
      descripcion
      zonas {
        idZona
        descripcion
        estado
        localidades {
          idLocalidad
          descripcion
          estado
        }
      }
      estado
    }
  }
`;

export const getLocacionesInput = gql`
  query getPaises($idPrograma: Int!) {
    getPaises(paisLike: { idPrograma: $idPrograma }) {
      idPais
      label: descripcion
      value: descripcion
      estado
      children: zonas {
        idZona
        label: descripcion
        value: descripcion
        estado
        children: localidades {
          idLocalidad
          label: descripcion
          value: descripcion
          estado
        }
      }
    }
  }
`;

export const getMonedasByPrograma = gql`
  query getPaises($idPrograma: Int) {
    getPaises(paisLike: { idPrograma: $idPrograma }) {
      moneda
    }
    getProgramas(programaLike: { idPrograma: $idPrograma }) {
      itemsPagina {
        monedaBase
      }
    }
  }
`;

export const getMonedas = gql`
  query getMonedas {
    getPaises: getMonedas {
      moneda
    }
  }
`;

export const getPaises = gql`
  query getPaises($idPrograma: Int) {
    getPaises(paisLike: { idPrograma: $idPrograma }) {
      idPais
      descripcion
      estado
    }
  }
`;

export const getMasterPaises = gql`
  query getMasterPaises {
    getMasterPaises {
      idPais
      descripcion
      estado
    }
  }
`;

export const getZonas = gql`
  query getZonas($idPais: Int!) {
    getZonas(zonaLike: { idPais: $idPais }) {
      idZona
      descripcion
      estado
    }
  }
`;

export const getMasterZonas = gql`
  query getMasterZonas($idPais: Int!) {
    getMasterZonas(zonaLike: { idPais: $idPais }) {
      idZona
      descripcion
      estado
    }
  }
`;

export const getLocalidades = gql`
  query getLocalidades($idZona: Int!) {
    getLocalidades(localidadLike: { idZona: $idZona }) {
      idLocalidad
      descripcion
      estado
    }
  }
`;

export const getMasterLocalidades = gql`
  query getMasterLocalidades($idZona: Int!, $idLocalidad: Int) {
    getMasterLocalidades(
      localidadLike: { idZona: $idZona, idLocalidad: $idLocalidad }
    ) {
      idLocalidad
      descripcion
      estado
    }
  }
`;

export const getPaisesByPrograma = gql`
  query getPaisesByPrograma($url: String!) {
    getPrograma(url: $url) {
      paises {
        idPais
        descripcion
        estado
      }
    }
  }
`;

export default {
  queries: {
    getLocaciones,
    getLocacionesInput,
    getMonedas,
    getMonedasByPrograma,
    getMasterPaises,
    getPaises,
    getMasterZonas,
    getZonas,
    getLocalidades,
    getMasterLocalidades,
    getPaisesByPrograma
  }
};
