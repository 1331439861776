const mediaStyle = {
  height: 0,
  paddingTop: "56.25%",
  borderBottom: "1px solid #ccc",
  position: "relative"
};

export default theme => ({
  container: {
    paddingLeft: 16,
    paddingRight: 16,
    display: "contents"
  },
  card: {
    overflow: "visible",
    width: "100%"
  },
  item: {
    fontSize: ".9rem"
  },
  tooltip: {
    fontSize: "0.85em",
    maxWidth: "100%",
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.primary.main
  },
  tooltipPlacementBottom: {
    margin: "-1.75rem 0"
  },
  popper: {
    opacity: 1
  },
  title: {
    fontSize: "1.1rem",
    [theme.breakpoints.up("sm")]: {
      height: "3.5rem"
    },
    display: "flex"
  },
  media: {
    ...mediaStyle
  },
  mediaLogo: {
    ...mediaStyle,
    backgroundSize: "auto"
  },
  descripcion: {},
  heartIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 10,
    cursor: "pointer"
  },
  favContainer: {
    position: "relative"
  },
  score: {
    fontSize: ".8rem"
  },
  gridCard: {
    display: "flex"
  },
  actionContainer: {
    justifyContent: "space-between",
    paddingLeft: "24px",
    paddingRight: "24px",
    "& a": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit" /* no underline */,
      zIndex: 10
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 0
    }
  },
  link: {
    textDecoration: "none"
  },
  cardContent: {
    [theme.breakpoints.up("sm")]: {
      height: "9.5rem"
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: 0
    }
  },
  gridContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto"
  }
});
