import TimerTypes from "./timer.types";

const timerSession = {
  activa: 0,
  pasiva: 0,
  last_modif: 0
};

const timerReducer = (state = timerSession, action) => {
  switch (action.type) {
    case TimerTypes.ADD_SECONDS_ACTIVE:
      return {
        ...state,
        activa: parseFloat(state.activa + action.seconds).toFixed(8)
      };
    case TimerTypes.ADD_SECONDS_PASIVE:
      return {
        ...state,
        pasiva: parseFloat(state.pasiva + action.seconds).toFixed(8)
      };
    default:
      return state;
  }
};

export default timerReducer;
