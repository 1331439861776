import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Icon from "@material-ui/core/Icon";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import client from "js/App/client.graphql.js";
import FavoritoQraphQL from "js/graphql/resolvers/favorito.resolver";
import { withNotificationContext } from "js/notification-context";
import { _t } from "js/utils/TranslationService";
import { numberWithCommas } from "js/utils";
import Cucarda from "../../../components/Cucarda";
import TextTruncate from "react-text-truncate";
import get from "lodash/get";
import Tooltip from "@material-ui/core/Tooltip";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.toggleFavorito = this.toggleFavorito.bind(this);
    this.state = {
      favorito: this.props.producto.favorito,
      starActive: this.props.producto.favorito
    };
  }

  async toggleFavorito() {
    this.setState(prevState => ({
      favorito: !prevState.favorito
    }));
    await client
      .mutate({
        mutation: FavoritoQraphQL.mutations.setFavorito,
        errorPolicy: "all",
        variables: {
          favoritoToggle: {
            idEleccionProducto: this.props.producto.idEleccionProducto
          }
        }
      })
      .then(res => {
        const favorito = get(res, "data.toggleFavorito.favorito", false);
        this.setState({ favorito });
        const message = favorito
          ? "Has agregado este producto a tu lista favoritos"
          : "Has quitado este producto de tu lista favoritos";
        this.props.openNotification(message);
        this.props.producto.favorito = favorito;
      })
      .catch(e => {
        this.setState(prevState => ({ favorito: !prevState.favorito }));
      });
  }

  render() {
    const { classes, producto, programLogo } = this.props;
    const { cucarda } = producto;
    const rubros = producto.rubros
      .map(rubro => rubro.descripcion)
      .concat(get(producto, "subrubros", []).map(r => r.descripcion));
    const favPerm = AuthService.hasPerms([Roles.FAVORITO_W]);
    return (
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardMedia
            className={
              producto.producto.imagenes[0] ? classes.media : classes.mediaLogo
            }
            style={{ borderRadius: `${cucarda ? 0 : 4}px 0 0 4px` }}
            image={
              producto.producto.imagenes[0]
                ? producto.producto.imagenes[0].url
                : programLogo
            }
          >
            {cucarda && (
              <Cucarda extraClass={classes.cucarda} cucardaType={cucarda} />
            )}
          </CardMedia>
          <div className={classes.details}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.title} color="textSecondary">
                {rubros.length ? rubros[0] : <br />}
              </Typography>
              <Tooltip
                title={producto.producto.nombre}
                classes={{
                  tooltip: classes.tooltip,
                  popper: classes.popper,
                  tooltipPlacementBottom: classes.tooltipPlacementBottom
                }}
                interactive
              >
                <Typography
                  className={classes.title}
                  variant="h5"
                  component="h2"
                >
                  <TextTruncate
                    line={1}
                    truncateText="..."
                    text={producto.producto.nombre}
                  />
                </Typography>
              </Tooltip>
              <Typography className={classes.description} component="p">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<div style="
                              overflow: hidden;
                              display: -webkit-box;
                              -webkit-line-clamp: 2;
                              -webkit-box-orient: vertical;
                            ">${producto.producto.copete || ""}</div>`
                  }}
                />
              </Typography>
              <Typography
                className={classes.score}
                variant="h6"
                color="primary"
                component="h2"
              >
                {numberWithCommas(producto.puntos)} {_t("Puntos")}
              </Typography>
            </CardContent>
            <CardActions
              className={classes.actionContainer}
              style={favPerm ? {} : { marginTop: "auto" }}
            >
              {favPerm && (
                <Icon
                  color="primary"
                  className={classes.heartIcon}
                  onMouseOver={() => this.setState({ starActive: true })}
                  onMouseOut={() => this.setState({ starActive: false })}
                  onClick={this.toggleFavorito}
                >
                  {this.state.starActive || this.state.favorito
                    ? "favorite"
                    : "favorite_border"}
                </Icon>
              )}
              {!producto.stockInfinito &&
              producto.stockPorPrograma !== null &&
              producto.stockPorPrograma < 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={true}
                  className={classes.masInfo}
                >
                  {_t("Sin stock")}
                </Button>
              ) : (
                <Link to={`/producto/${producto.idEleccionProducto}`}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.masInfo}
                  >
                    {_t("Más info")}
                  </Button>
                </Link>
              )}
            </CardActions>
          </div>
        </Card>
      </Grid>
    );
  }
}

export default withNotificationContext(ListItem);
