import React from "react";
import { cx, css } from "emotion";
import { Banner } from "js/components/Banner";
import Grid from "@material-ui/core/Grid";

const Fila = props => {
  const filaStyle = Object.assign({}, props.css);
  filaStyle.marginBottom += "px!important";
  if (filaStyle.marginTop) filaStyle.marginTop += "px!important";
  delete filaStyle.height;
  const style = {
    paddingRight: props.spaceBetweenLateral,
    paddingLeft: props.spaceBetweenLateral
  };
  const esTransparente =
    props.contenidos && props.contenidos.length === 1
      ? props.contenidos[0].esTransparente
      : false;
  return props.visible ? (
    <Grid
      container
      justify="center"
      className={cx(
        !esTransparente ? props.classes.fila : props.classes.filaSinBackground,
        css(filaStyle)
      )}
      style={props.widthStyle}
    >
      {(props.contenidos || []).map((contenido, index) => (
        <Grid
          item
          xs={12}
          sm={12 / parseInt(props.filaTipo)}
          key={`Row-${props.filaNumero}-Box-${index}`}
          className={cx(props.classes.contenido, css(style))}
        >
          <Banner
            contenido={{
              ...contenido,
              css: {
                ...contenido.css,
                ...props.overwriteContenidoCss
              }
            }}
            key={`landing-banner-${contenido.tipo}-${
              props.rowIndex
            }-contenido-fila${props.filaTipo}`}
            baseLink={props.baseLink}
          />
        </Grid>
      ))}
    </Grid>
  ) : null;
};

export default Fila;
