import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import { Link } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { _t } from "js/utils/TranslationService";

const SolicitudError = props => {
  const idBeneficio = props.computedMatch.params.idBeneficio;

  return (
    <div>
      <Card className={props.classes.card}>
        <CardContent>
          <Typography variant="h5" component="h2">
            {`${props.location.state.errors[0].message}`}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">
            <Link to="/catalogo-beneficios">{_t("Volver al catálogo")}</Link>
          </Button>
          <Button size="small">
            <Link to="/landing">{_t("Volver a la página de inicio")}</Link>
          </Button>
          <Button size="small">
            <Link to={`/beneficio/${idBeneficio}`}>
              {_t("Volver al beneficio")}
            </Link>
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

SolicitudError.propTypes = {
  classes: PropTypes.object
};

SolicitudError.defaultProps = {};

export default SolicitudError;
