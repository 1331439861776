import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/Form/styles.js";
import { reduxForm, change, getFormMeta, formValueSelector } from "redux-form";
import { Grid, Button, Typography } from "@material-ui/core";
import { InputTextField } from "js/components/Shared/Form/Fields";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import QrReader from "react-qr-reader";
import BarcodeScanner from "js/pages/VentaProductoCodificado/Edit/barcodeScanner";
import "./MyForm.css";
import ayudaInstalador from "images/ayuda-instalador.jpg";
import ayudaVendedor from "images/ayuda-vendedor.jpg";
import { _t } from "js/utils/TranslationService";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableQr: true,
      enableBarcode: true,
      canScanQR: AuthService.hasPerms([Roles.CODIGO_QR_R]),
      canScanBarcode: AuthService.hasPerms([Roles.CODIGO_BARRA_R])
    };

    this.handleScanQr = this.handleScanQr.bind(this);
    this.handleScanBarcode = this.handleScanBarcode.bind(this);
  }

  handleScanQr = data => {
    if (data) {
      this.props.changeFieldValue("codigoQR", data);
      // this.props.getProductoCodificado(data, "CODIGO_QR");
      this.props.resetErrors();
      this.setState({ enableQr: false });
    }
  };
  handleErrorQr = err => {
    console.log("ERROR AL LEER EL CÓDIGO QR");
    console.error(err);
  };

  handleScanBarcode = codigo => {
    this.props.changeFieldValue("codigoBarra", codigo);
    // this.props.getProductoCodificado(codigo, "CODIGO_BARRA");
    this.props.resetErrors();
    this.setState({ enableBarcode: false });
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      isMisVentas,
      codigosSinProducto
    } = this.props;

    const { canScanBarcode, canScanQR, enableQr, enableBarcode } = this.state;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5">{"Nuevo producto"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <form className={classes.container} onSubmit={handleSubmit}>
            <Grid className={"errors-list-top"} container spacing={16}>
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors &&
                    errors.map((e, i) => {
                      return <li key={i}>{_t(e.message.toString())}</li>;
                    })}
                </ul>
              </Grid>
            </Grid>

            <Grid container spacing={16}>
              {canScanQR && (
                <>
                  {!enableQr && (
                    <Grid item xs={6}>
                      <Button
                        type="button"
                        variant="contained"
                        size="large"
                        color="primary"
                        style={{ width: "160px" }}
                        onClick={() => this.setState({ enableQr: true })}
                      >
                        {_t("Escanear QR")}
                      </Button>
                    </Grid>
                  )}

                  {enableQr && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <img
                          src={ayudaInstalador}
                          style={{ width: "100%", marginBottom: "20px" }}
                          alt={"imagen-qr"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <QrReader
                          delay={300}
                          onError={this.handleErrorQr}
                          onScan={this.handleScanQr}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={6}>
                    <InputTextField
                      name="codigoQR"
                      label="Código QR"
                      disabled={disabled}
                      autoComplete="off"
                      autoCorrect="off"
                      spellCheck="off"
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputTextField
                      name="codigoVerificacion"
                      label="Código de verificación"
                      disabled={disabled}
                      autoComplete="off"
                      autoCorrect="off"
                      spellCheck="off"
                      required
                    />
                  </Grid>

                  {codigosSinProducto && (
                    <>
                      {!enableBarcode && (
                        <Grid item xs={7}>
                          <Button
                            type="button"
                            variant="contained"
                            size="large"
                            color="primary"
                            style={{ width: "220px" }}
                            onClick={() =>
                              this.setState({ enableBarcode: true })
                            }
                          >
                            {_t("Escanear SN")}
                          </Button>
                        </Grid>
                      )}

                      {enableBarcode && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <img
                              src={ayudaVendedor}
                              style={{ width: "100%", marginBottom: "20px" }}
                              alt={"imagen-barcode"}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <BarcodeScanner
                              onScanSuccess={this.handleScanBarcode.bind(this)}
                            />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} sm={6}>
                        <InputTextField
                          name="codigoBarra"
                          label="Código de barras (SN)"
                          disabled={disabled}
                          autoComplete="off"
                          autoCorrect="off"
                          spellCheck="off"
                          required
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}

              {canScanBarcode && (
                <>
                  {!enableBarcode && (
                    <Grid item xs={7}>
                      <Button
                        type="button"
                        variant="contained"
                        size="large"
                        color="primary"
                        style={{ width: "220px" }}
                        onClick={() => this.setState({ enableBarcode: true })}
                      >
                        {_t("Escanear SN")}
                      </Button>
                    </Grid>
                  )}

                  {enableBarcode && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <img
                          src={ayudaVendedor}
                          style={{ width: "100%", marginBottom: "20px" }}
                          alt={"imagen-barcode"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <BarcodeScanner
                          onScanSuccess={this.handleScanBarcode.bind(this)}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={6}>
                    <InputTextField
                      name="codigoBarra"
                      label="Código de barras (SN)"
                      disabled={disabled}
                      autoComplete="off"
                      autoCorrect="off"
                      spellCheck="off"
                      required
                    />
                  </Grid>
                </>
              )}

              <Grid className={"errors-list"} container spacing={16}>
                <Grid item xs={12}>
                  <ul className={classes.error}>
                    {errors &&
                      errors.map((e, i) => {
                        return <li key={i + 100}>{_t(e.message)}</li>;
                      })}
                  </ul>
                </Grid>
              </Grid>

              <Grid
                className={classes.gridButton}
                item
                xs={12}
                style={{ marginBottom: "210px" }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  className={"cancel-btn"}
                  onClick={() =>
                    history.push(
                      isMisVentas
                        ? `/registrar-mis-productos`
                        : `/registrar-productos`
                    )
                  }
                >
                  {_t("Cancelar")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  disabled={pristine || submitting || disabled}
                >
                  {_t("Confirmar")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

Form = reduxForm({
  form: "formVentaProductoCodificado",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formVentaProductoCodificado");

Form = connect(
  state => ({
    formMeta: getFormMeta("formVentaProductoCodificado")(state),
    codigoQR: selector(state, "codigoQR"),
    codigoVerificacion: selector(state, "codigoVerificacion"),
    codigoBarra: selector(state, "codigoBarra")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formVentaProductoCodificado", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
