import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { API_KEY } from "js/components/Mapa/constants.js";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { getCoordinates, getCurrentLocation } from "./utils";

const mapStyles = {
  width: "100%",
  height: "100%"
};

class AddressMap extends React.PureComponent {
  state = {
    initialCenter: { lat: -27.761683, lng: -61.695109 },
    zoom: 2,
    position: null
  };

  componentDidMount = () => {
    getCurrentLocation().then(location => {
      this.setState({
        initialCenter: { lat: location.latitude, lng: location.longitude },
        zoom: 8
      });
    });
    this.componentWillReceiveProps(this.props);
  };

  componentWillReceiveProps = async nextProps => {
    const { address } = nextProps;
    this.setState({ position: null });
    if (address && address.length)
      await getCoordinates(address).then(position =>
        this.setState({ position, zoom: 15 })
      );
  };

  render() {
    const { initialCenter, zoom, position } = this.state;

    return (
      <div className={this.props.classes.addressMapContainer}>
        <Map
          google={this.props.google}
          zoom={position ? 15 : zoom}
          center={position || initialCenter}
          style={mapStyles}
        >
          {position && <Marker position={position} />}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({ apiKey: API_KEY })(
  withStyles(styles)(AddressMap)
);
