import React, { PureComponent } from "react";
import AppQraphQL from "js/graphql/resolvers/notificacion.resolver";
import Typography from "@material-ui/core/Typography";
import AuthService from "js/utils/AuthService";
import Button from "@material-ui/core/Button";

import TablaNotificaciones from "js/pages/Admin/Programa/Notificaciones/Table";
import { Redirect } from "react-router-dom";

class Notificaciones extends PureComponent {
  constructor(props) {
    const {
      match: {
        params: { id }
      }
    } = props;
    super(props);
    this.state = {
      initialValues: [],
      idPrograma: parseInt(id) || id,
      open: false,
      nombre: "",
      asunto: "",
      template: "",
      estado: "Inactivo",
      puntosAcreditar: 0,
      acreditaPuntos: false
    };
  }

  getNotificaciones() {
    const { client } = this.props;
    const { idPrograma } = this.state;
    return client
      .query({
        query: AppQraphQL.queries.getNotificacionesById,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: parseInt(idPrograma)
        }
      })
      .then(res => {
        res.data.getNotificaciones.length > 0 &&
          this.setState({ initialValues: res.data.getNotificaciones });
      });
  }

  async componentDidMount() {
    await this.getNotificaciones();
  }

  handleOpen = () => {
    const { history } = this.props;
    history.push("/admin/programa/1/notificaciones/new");
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { initialValues, idPrograma } = this.state;
    const { location, classes } = this.props;
    const hasBOAccess = AuthService.hasPerms(["NOTIFICACION_W"]);
    const isAdminPage = idPrograma === 1;

    return (
      <>
        <div className={classes.header}>
          <Typography variant="h5" gutterBottom>
            Notificaciones
          </Typography>
          {hasBOAccess &&
            isAdminPage && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleOpen}
                style={{ marginLeft: "10px" }}
              >
                Nuevo
              </Button>
            )}
        </div>
        <div>
          {initialValues && <TablaNotificaciones rows={initialValues} />}
        </div>
      </>
    );
  }
}

export default Notificaciones;
