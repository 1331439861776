import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";

import CampanasOPGraphQL from "js/graphql/resolvers/campanaUnilever.resolver";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { UsuarioField, TypeAhead } from "js/components/Shared/Form/Fields";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import { updateQueryParamsAsync } from "js/pages/Admin/Canje/utils";

class PerformanceFilters extends PureComponent {
  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  updateAutocompleteQueryParams = key => select => {
    // debugger;
    if (key === "idUsuario") {
      const usuario = select.length ? select[0] : { value: null };
      this.setState(
        updateQueryParamsAsync({ key, value: usuario.value }),
        this.props.submitValue({ target: { name: key, value: usuario.value } })
      );
      return;
    }
  };

  render() {
    const {
      value,
      submitValue,
      classes,
      showUserField,
      idsUsuarios,
      idPrograma
    } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={8}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              query={CampanasOPGraphQL.queries.getCampanasInput}
              queryParams={{
                campanaOPLike: {
                  idPrograma: idPrograma,
                  tipo: "SIMPLE"
                },
                orderBy: "fechaInicio",
                order: "desc"
              }}
              placeholder={"Campaña"}
              label={"Campaña"}
              path={{
                path: ["getCampanasOP", "itemsPagina"],
                value: "_id",
                label: "nombre",
                defaultLabel: "nombre"
              }}
              onChange={this.handleChange("idCampana")}
              value={value["idCampana"]}
              withTodos
            />
          </FormControl>
        </Grid>

        {showUserField && (
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControl}>
              <UsuarioField
                async
                className={classes.style}
                value={value["idUsuario"] || ""}
                idPrograma={parseInt(window.localStorage.getItem("idPrograma"))}
                idsUsuarios={idsUsuarios}
                onChange={this.updateAutocompleteQueryParams("idUsuario")}
                placeholder={"Nombre, Apellido, DNI"}
                withTodos={true}
                multilabel={[
                  "perfil.nombre",
                  ", ",
                  "perfil.apellido",
                  ", ",
                  "nombre"
                ]}
                sinInactivos
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} sm={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              key={"estado"}
              name={"estado"}
              id="estado-simple"
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"INICIADA"}>Activa</MenuItem>
              <MenuItem value={"FINALIZADA"}>Finalizada</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaInicio"}
              name={"fechaInicio"}
              label="Fecha inicio desde"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaInicio",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaInicio"]
                  ? moment(value["fechaInicio"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaInicioHasta"}
              name={"fechaInicioHasta"}
              label="Fecha inicio hasta"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaInicioHasta",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaInicioHasta"]
                  ? moment(value["fechaInicioHasta"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

PerformanceFilters.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(PerformanceFilters);
