export default theme => ({
  root: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da"
  },
  input: {
    fontSize: 16,
    padding: "13px 12px"
  },
  icon: {
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer"
  }
});
