import React, { PureComponent } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Ranking, Resumen } from "./";
import getComponente from "./TipoAccion";

export default class Detalle extends PureComponent {
  render() {
    const { accion } = this.props;
    const Tipo = getComponente(accion.tipo);
    return accion ? (
      <Grid item container xs={12} spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Detalle de la acción
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Ranking idAccion={accion._id} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Resumen idAccion={accion._id} accion={accion} />
        </Grid>
        <Grid item xs={12}>
          <Tipo idAccion={accion._id} accion={accion} />
        </Grid>
      </Grid>
    ) : (
      ""
    );
  }
}
