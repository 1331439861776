import { connect } from "react-redux";
import { reduxForm, formValueSelector, change, getFormMeta } from "redux-form";
import MyForm from "./MyForm";

const NewForm = reduxForm({
  form: "formCCUsuarios",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(MyForm);

const selector = formValueSelector("formCCUsuarios");

const mapStateToProps = (state, ownProps) => ({
  formMeta: getFormMeta("formCCUsuarios")(state),
  selectedProgram: selector(state, "selectedProgram"),
  selectedUsuario: selector(state, "selectedUsuario"),
  concepto: selector(state, "concepto"),
  tipo: selector(state, "tipo"),
  puntos: selector(state, "puntos"),
  conceptoValue: selector(state, "conceptoValue")
});

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (field, value) =>
    dispatch(change("formCCUsuarios", field, value)),
  changeProgramaValue: (field, value) => {
    dispatch(change("formCCUsuarios", "selectedUsuario", undefined));
    dispatch(change("formCCUsuarios", field, value));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewForm);
