import { numberWithCommas } from "js/utils";

export const normalizeFloat = (decimalNumbers = 2) => (value = "") => {
  let normalized = (value || (value === 0 ? "0" : ""))
    .toString()
    .replace(/\./g, "");
  normalized = normalized.replace(",", ".");
  normalized.replace(/[^0-9.]/g, "");

  const length = normalized.length - 1;
  const dotZero =
    normalized.indexOf(".") > -1 && normalized[normalized.length - 1] === "0";

  if (
    normalized[length] === "." &&
    normalized.indexOf(".") > -1 &&
    normalized.indexOf(".") !== length
  )
    normalized = normalized.slice(0, -1);
  if (normalized[length] === ".") return normalized.slice(0, -1) + ",";
  return !normalized || !length || normalized[length] === "." || dotZero
    ? normalized
    : parseFloat(normalized).toFixed(decimalNumbers);
};

export const formatFloat = (decimalNumbers = 2) => (value = "", name) => {
  if (value === "-") return value;
  let val = (value || (value === 0 ? "0" : "")).toString();
  if (!val || !val.length) return undefined;
  return val[val.length - 1] === ","
    ? numberWithCommas(parseFloat(val.slice(0, -1)), decimalNumbers) + ","
    : numberWithCommas(parseFloat(val), decimalNumbers);
};

export const intParser = (value = "") => {
  const val = value
    .toString()
    .replace(/\./g, "")
    .replace(",", ".");
  return !isNaN(parseInt(val)) ? parseInt(val) : null;
};
