import Tabla from "./tabla";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";

import { compose } from "recompose";

const enhance = compose(
  withRouter,
  withApollo
);
export default enhance(Tabla);
