import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  list: {
    overflow: "auto",
    minHeight: 50,
    maxHeight: 270,
    backgroundColor: "#f0f0f0",
    padding: "10px 10px 0px 10px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4
  },
  row: {
    paddingBottom: 10
  },
  subHeader: {
    fontSize: 16
  },
  error: {
    color: "#f44336",
    margin: 0,
    fontSize: "0.75rem",
    textAlign: " left",
    marginTop: 8,
    minHeight: "1em",
    lineHeight: "1em"
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`
  },
  progress: {
    marginLeft: theme.spacing.unit
  }
});

export default withStyles(styles);
