import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { Med } = Fragmentos;

export const getPaginaMeds = gql`
  query getMeds(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: MedColumn!
    $idPrograma: Int
    $nombre: String
    $codigo: String
    $tipo: TipoMed
    $estado: Estado
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getMeds(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      medOrder: { column: $orderBy, order: $order }
      medLike: {
        idPrograma: $idPrograma
        codigo: $codigo
        nombre: $nombre
        tipo: $tipo
        estado: $estado
      }
      medRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...med
      }
    }
  }
  ${Med.fragments.med}
`;

export const getPaginaMisMeds = gql`
  query getMisMeds(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: MedColumn!
    $idPrograma: Int
    $nombre: String
    $codigo: String
    $tipo: TipoMed
    $estado: Estado
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getMisMeds(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      medOrder: { column: $orderBy, order: $order }
      medLike: {
        idPrograma: $idPrograma
        codigo: $codigo
        nombre: $nombre
        tipo: $tipo
        estado: $estado
      }
      medRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...med
      }
    }
  }
  ${Med.fragments.med}
`;

export const getMedById = gql`
  query getMedById($codigo: String, $idPrograma: Int) {
    getMeds(medLike: { codigo: $codigo, idPrograma: $idPrograma }) {
      itemsPagina {
        ...med
      }
    }
  }
  ${Med.fragments.med}
`;

export const getMiMedById = gql`
  query getMiMedById($codigo: String, $idPrograma: Int) {
    getMisMeds(medLike: { codigo: $codigo, idPrograma: $idPrograma }) {
      itemsPagina {
        ...med
      }
    }
  }
  ${Med.fragments.med}
`;

export const getMedsInput = gql`
  query getMeds($medLike: MedSearch) {
    getMeds(medLike: $medLike) {
      itemsPagina {
        codigo
        value: _id
        label: nombre
      }
    }
  }
`;

export const getMisMedsInput = gql`
  query getMisMeds($medLike: MedSearch) {
    getMisMeds(medLike: $medLike) {
      itemsPagina {
        codigo
        value: _id
        label: nombre
      }
    }
  }
`;

export const createMed = gql`
  mutation createMed($med: MedCreate!) {
    createMed(med: $med) {
      codigo
    }
  }
`;

export const updateMed = gql`
  mutation updateMed($med: MedUpdate!) {
    updateMed(med: $med) {
      codigo
    }
  }
`;

export const getPreventistasMed = gql`
  query getPreventistasMed($codigo: String, $idPrograma: Int) {
    getPreventistasMed(codigo: $codigo, idPrograma: $idPrograma) {
      nombre
      codigo
      programa {
        idPrograma
      }
      preventistas {
        porcentaje
        usuario {
          username
          idUsuario
          estado
          perfil {
            nombre
            apellido
          }
        }
      }
    }
  }
`;

export const getPreventistasMiMed = gql`
  query getPreventistasMiMed($codigo: String, $idPrograma: Int) {
    getPreventistasMiMed(codigo: $codigo, idPrograma: $idPrograma) {
      nombre
      codigo
      preventistas {
        porcentaje
        usuario {
          username
          idUsuario
          estado
          perfil {
            nombre
            apellido
          }
        }
      }
    }
  }
`;

export const updatePreventistasMed = gql`
  mutation updatePreventistasMed(
    $codigo: String
    $idPrograma: Int
    $preventistas: [PreventistasMedUpdate]
  ) {
    updatePreventistasMed(
      codigo: $codigo
      idPrograma: $idPrograma
      preventistas: $preventistas
    ) {
      codigo
    }
  }
`;

export default {
  queries: {
    getPaginaMeds,
    getPaginaMisMeds,
    getMedById,
    getMiMedById,
    getMedsInput,
    getMisMedsInput,
    getPreventistasMed,
    getPreventistasMiMed
  },
  mutations: {
    createMed,
    updateMed,
    updatePreventistasMed
  }
};
