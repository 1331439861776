import gql from "graphql-tag";

const Settings = {};

Settings.fragments = {
  settingsCompleto: gql`
    fragment settingsCompleto on Settings {
      idPrograma
      idioma
      isPublic
      nombre
    }
  `
};

export default Settings;
