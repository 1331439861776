import gql from "graphql-tag";

export const getPaginaProveedores = gql`
  query getProveedores(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: ProveedorColumn!
    $nombreFantasia: String
    $nombreContacto: String
    $razonSocial: String
    $emailContacto: String
    $idPais: Int
    $idZona: Int
    $idLocalidad: Int
    $estado: Estado
  ) {
    getProveedores(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      proveedorOrder: { column: $orderBy, order: $order }
      proveedorLike: {
        nombreFantasia: $nombreFantasia
        nombreContacto: $nombreContacto
        razonSocial: $razonSocial
        emailContacto: $emailContacto
        sucursales_idPais: $idPais
        sucursales_idZona: $idZona
        sucursales_idLocalidad: $idLocalidad
        estado: $estado
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        idProveedor
        cuit
        razonSocial
        nombreFantasia
        nombreContacto
        telefonoContacto
        emailContacto
        tieneSucursales
        fechaCreacion
        pais {
          descripcion
        }
        sucursales {
          pais {
            descripcion
          }
        }
        estado
        formatoEnvio
      }
    }
  }
`;

export const getProveedores = gql`
  query getProveedores {
    getProveedores(proveedorOrder: { column: nombreFantasia, order: asc }) {
      itemsPagina {
        idProveedor
        cuit
        razonSocial
        nombreContacto
        nombreFantasia
        tieneSucursales
        pais {
          descripcion
        }
      }
    }
  }
`;

export const getProveedoresAutocomplete = gql`
  query getProveedores {
    getProveedores {
      itemsPagina {
        idProveedor
        nombreFantasia
      }
    }
  }
`;

export const getProveedorById = gql`
  query getProveedores($idProveedor: Int) {
    getProveedores(proveedorLike: { idProveedor: $idProveedor }) {
      itemsPagina {
        idProveedor
        cuit
        razonSocial
        nombreFantasia
        nombreContacto
        telefonoContacto
        emailContacto
        emailCanjes
        estado
        urlPaginaWeb
        publicaPaginaWeb
        publicaSucursales
        tieneSucursales
        direccion
        formaPago
        formatoCanje
        formatoEnvio
        observaciones
        descuento
        areaOperacion {
          pais {
            idPais
            descripcion
          }
          zona {
            idZona
            descripcion
          }
          localidad {
            idLocalidad
            descripcion
          }
        }
      }
    }
  }
`;

export const getMonedaByProveedorId = gql`
  query getProveedores($idProveedor: Int) {
    getProveedores(proveedorLike: { idProveedor: $idProveedor }) {
      itemsPagina {
        idProveedor
        nombreFantasia
        pais {
          idPais
          moneda
        }
      }
    }
  }
`;

export const getPaginaSucursales = gql`
  query getSucursales(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: SucursalColumn!
    $nombre: String
    $direccion: String
    $idProveedor: Int
    $idZona: Int
    $idLocalidad: Int
    $estado: Estado
  ) {
    getSucursales(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      sucursalOrder: { column: $orderBy, order: $order }
      sucursalLike: {
        nombre: $nombre
        direccion: $direccion
        idProveedor: $idProveedor
        idZona: $idZona
        idLocalidad: $idLocalidad
        estado: $estado
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        idSucursal
        nombre
        direccion
        telefono
        zona {
          idZona
          descripcion
        }
        localidad {
          idLocalidad
          descripcion
        }
        estado
        orden
      }
    }
  }
`;

export const getPaginaSucursalesProducto = gql`
  query getSucursales(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: SucursalColumn!
    $nombre: String
    $direccion: String
    $idZona: Int
    $idLocalidad: Int
    $estado: Estado
    $idProducto: Int
  ) {
    getSucursalesProducto(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      sucursalOrder: { column: $orderBy, order: $order }
      sucursalLike: {
        idProducto: $idProducto
        nombre: $nombre
        direccion: $direccion
        idZona: $idZona
        idLocalidad: $idLocalidad
        estado: $estado
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        idSucursal
        nombre
        direccion
        telefono
        zona {
          idZona
          descripcion
        }
        localidad {
          idLocalidad
          descripcion
        }
        estado
        orden
      }
    }
  }
`;

export const getSucursalById = gql`
  query getSucursalById($idSucursal: Int) {
    getSucursales(sucursalLike: { idSucursal: $idSucursal }) {
      itemsPagina {
        idSucursal
        nombre
        notas
        pais {
          idPais
          descripcion
        }
        zona {
          idZona
          descripcion
        }
        localidad {
          idLocalidad
          descripcion
        }
        estado
        telefono
        direccion
        orden
      }
    }
  }
`;

export const getSucursalProductoById = gql`
  query getSucursalById($idSucursal: Int) {
    getSucursalesProducto(sucursalLike: { idSucursal: $idSucursal }) {
      itemsPagina {
        idSucursal
        nombre
        notas
        pais {
          idPais
          descripcion
        }
        zona {
          idZona
          descripcion
        }
        localidad {
          idLocalidad
          descripcion
        }
        estado
        telefono
        direccion
        orden
      }
    }
  }
`;

export const getSucursalByIdProveedor = gql`
  query getSucursalById($idProveedor: Int) {
    getSucursales(sucursalLike: { idProveedor: $idProveedor }) {
      itemsPagina {
        idSucursal
        nombre
        notas
        pais {
          idPais
          descripcion
        }
        zona {
          idZona
          descripcion
        }
        localidad {
          idLocalidad
          descripcion
        }
        estado
        telefono
        direccion
      }
    }
  }
`;

export const getProveedoresSimple = gql`
  query getProveedores {
    getProveedores {
      itemsPagina {
        idProveedor
        nombreFantasia
      }
    }
  }
`;

export const getProveedorSimpleById = gql`
  query getProveedores($idProveedor: Int) {
    getProveedores(proveedorLike: { idProveedor: $idProveedor }) {
      itemsPagina {
        idProveedor
        nombreFantasia
      }
    }
  }
`;

export const createProveedor = gql`
  mutation CreateProveedor($proveedor: ProveedorCreate!) {
    createProveedor(proveedor: $proveedor) {
      idProveedor
    }
  }
`;

export const updateProveedor = gql`
  mutation UpdateProveedor($proveedor: ProveedorUpdate!) {
    updateProveedor(proveedor: $proveedor) {
      idProveedor
    }
  }
`;

export const removeProveedor = gql`
  mutation RemoveProveedor($idProveedor: Int!) {
    removeProveedor(idProveedor: $idProveedor) {
      idProveedor
    }
  }
`;

export const createSucursal = gql`
  mutation CreateSucursal($sucursal: SucursalCreate!) {
    createSucursal(sucursal: $sucursal) {
      idSucursal
    }
  }
`;

export const updateSucursal = gql`
  mutation UpdateSucursal($sucursal: SucursalUpdate!) {
    updateSucursal(sucursal: $sucursal) {
      idSucursal
    }
  }
`;
export const createSucursalProducto = gql`
  mutation CreateSucursalProducto($sucursal: SucursalCreate!) {
    createSucursalProducto(sucursal: $sucursal) {
      idSucursal
    }
  }
`;

export const updateSucursalProducto = gql`
  mutation UpdateSucursalProducto($sucursal: SucursalUpdate!) {
    updateSucursalProducto(sucursal: $sucursal) {
      idSucursal
    }
  }
`;

export const removeSucursal = gql`
  mutation RemoveSucursal($idSucursal: Int!) {
    removeSucursal(idSucursal: $idSucursal) {
      idSucursal
    }
  }
`;

export default {
  queries: {
    getMonedaByProveedorId,
    getProveedoresAutocomplete,
    getPaginaProveedores,
    getPaginaSucursales,
    getPaginaSucursalesProducto,
    getProveedorById,
    getProveedores,
    getProveedoresSimple,
    getProveedorSimpleById,
    getSucursalById,
    getSucursalProductoById,
    getSucursalByIdProveedor
  },
  mutations: {
    removeSucursal,
    removeProveedor,
    createProveedor,
    updateProveedor,
    createSucursal,
    createSucursalProducto,
    updateSucursal,
    updateSucursalProducto
  }
};
