import React from "react";
import PropTypes from "prop-types";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { ChromePicker } from "react-color";

import Enhancer from "./container";

const ColorPicker = ({
  onClick,
  // onChange,
  classes,
  color,
  isOpen,
  paperStyle,
  onClickAway,
  onChangeColor,
  disabled = false
}) => (
  <ClickAwayListener onClickAway={onClickAway}>
    <FormControl className={classes.FormControl} variant="outlined" fullWidth>
      <OutlinedInput
        disabled={disabled}
        type="text"
        value={color}
        labelWidth={0}
        className={classes.Input}
        endAdornment={
          <InputAdornment position="end">
            <Paper style={paperStyle} elevation={1} onClick={onClick} />
          </InputAdornment>
        }
      />
      {isOpen && (
        <ChromePicker color={color} onChangeComplete={onChangeColor} />
      )}
    </FormControl>
  </ClickAwayListener>
);

ColorPicker.propTypes = {
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickAway: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  paperStyle: PropTypes.shape({
    backgroundColor: PropTypes.string,
    padding: PropTypes.string.isRequired
  }),
  color: PropTypes.string
};
ColorPicker.defaultProps = {
  paperStyle: {
    backgroundColor: "#fff"
  }
};

ColorPicker.displayName = "ColorPicker";

export default Enhancer(ColorPicker);
