import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "./advancedFilter";
import AppQraphQL from "js/graphql/resolvers/cargadeCodigos.resolver";
import { USER_ROLES as Roles } from "js/models/User";
import { viewAction } from "js/components/Shared/Tabla/ActionCells";
import AppQraphQLImport from "js/graphql/resolvers/cargadeCodigos.resolver";
import { Grid } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { setGruposSKUs } from "js/redux/campana/campana.actions";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";

const defaultHeader = (id, btnsNextTitle) => {
  return {
    title: "Administración de códigos",
    btnsNextTitle,
    // new: {
    //   title: "Buscar Código",
    //   perms: [Roles.CARGACODIGOS_W]
    // },
    export: {
      path: "codigosCargados",
      perms: [Roles.CARGACODIGOS_R, Roles.CARGA_CODIGOS_EXPORT],
      tooltip: "Exportar códigos"
    },
    // importWithDialog: {
    //   dialog: "importCodigos",
    //   perms: [Roles.CARGACODIGOS_W, Roles.CARGA_CODIGOS_IMPORT],
    //   tooltip: "Importar códigos",
    //   model: "Modelo_Carga_Codigos.csv"
    // },
    import: {
      action: AppQraphQLImport.queries.importarCodigosCargados,
      perms: [Roles.CARGACODIGOS_W, Roles.CARGA_CODIGOS_IMPORT],
      tooltip: "Importar códigos",
      model: "Modelo_Carga_Codigos.csv",
      modelTooltip: "Modelo de importación de codigos cargados",
      variables: {
        idPrograma: parseInt(id) || id
      }
    },
    queryStringMatcher: {
      idsPrograma: undefined
    }
  };
};

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

const columnData = [
  { id: "fechaCreacion", label: "Fecha Creación", type: "date" },
  { id: "codigo", label: "Código" },
  { id: "tipodeProducto", label: "Tipo de Producto" },
  { id: "tipodeCodigo", label: "Valor" },
  { id: "marca", label: "Marca" },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  }
];

class Tabla extends PureComponent {
  render() {
    const view = params => ({
      ...viewAction({ ...params, history: this.props.history })
    });

    const getCargadeCodigos = data => {
      if (data) {
        return {
          itemsPagina: data.getCargadeCodigos.itemsPagina,
          infoPagina: data.getCargadeCodigos.infoPagina
        };
      } else {
        return {
          itemsPagina: [],
          infoPagina: {}
        };
      }
    };

    const {
      match: {
        params: { id }
      }
    } = this.props;

    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Parámetros Banner">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(
                `/admin/programa/${id}/parametros-banner-carga-de-codigos`
              )
            }
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.CARGACODIGOS_W]
    });

    return (
      <>
        <Grid container direction="column" justify="space-between">
          <h6>
            Para realizar una exportacion de esta tabla deberá aplicar algun
            tipo de filtro.
          </h6>
        </Grid>
        <br />
        <Table
          storage={"FiltroCodigosCargados"}
          query={AppQraphQL.queries.getCargadeCodigos}
          defaultHeader={defaultHeader(id, btnsNextTitle)}
          advancedFilter={advancedFilter}
          sort={defaultSort}
          columnData={columnData}
          getTipo={getCargadeCodigos}
          showPagination={false}
          actionsCustom={[view]}
          pathId={"_id"}
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setGruposSKUs: grupos => dispatch(setGruposSKUs(grupos))
});

// export default Tabla;
export default connect(
  null,
  mapDispatchToProps
)(Tabla);
