import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Grid, FormControl, TextField } from "@material-ui/core";

import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";

class advancedFilter extends PureComponent {
  render() {
    const { value, submitValue, classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"username"}
              name={"username"}
              label={"Usuario"}
              type="search"
              value={value["username"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"link"}
              name={"link"}
              label={"Link"}
              type="search"
              value={value["link"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaDesde"}
              name={"fechaDesde"}
              label="Desde"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaDesde",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaDesde"] ? moment(value["fechaDesde"]) : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaHasta"}
              name={"fechaHasta"}
              label="Hasta"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaHasta",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaHasta"] ? moment(value["fechaHasta"]) : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
