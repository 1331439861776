import React from "react";
import Tabla from "js/pages/Unilever/Targets/Tabla";

export const Targets = props => {
  return (
    <div>
      <Tabla {...props} />
    </div>
  );
};

export default Targets;
