import React, { Fragment } from "react";

import { CatalogoContext } from "../../catalogo-context";
import { Query } from "react-apollo";
import CatalogoQraphQL from "js/graphql/resolvers/catalogo.resolver";
import ListCustom from "./ListCustom";
import getParsedData from "./utils";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { _t } from "js/utils/TranslationService";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class FilterCategories extends React.Component {
  state = {
    open: true
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { classes, programa } = this.props;
    const favPerm = AuthService.hasPerms([Roles.FAVORITO_R]);
    return (
      <Query
        query={CatalogoQraphQL.queries.getCatalogoSidebar}
        variables={{
          idPrograma: parseInt(programa.idPrograma) || programa.idPrograma
        }}
        errorPolicy="all"
      >
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error! ${error.message}`;
          let { getRubrosUI } = data;
          getRubrosUI.sort((a, b) => {
            return a.descripcion.localeCompare(b.descripcion);
          });
          const obj = getParsedData(data);

          return (
            <div className={classes.root}>
              <CatalogoContext.Consumer>
                {({ filterBy, searchCriteria, queryObj }) => (
                  <Fragment>
                    <List component="nav" />
                    {favPerm && (
                      <ListItem button onClick={filterBy.filterFavoritos}>
                        <ListItemText
                          primary={_t("Favoritos")}
                          classes={{
                            primary:
                              queryObj.catalogoLike.favoritos &&
                              classes.selected
                          }}
                        />
                      </ListItem>
                    )}

                    <ListItem
                      button
                      onClick={() =>
                        filterBy.filterByCategory({
                          key: undefined,
                          id: undefined
                        })
                      }
                    >
                      <ListItemText primary={_t("Ver todo")} />
                    </ListItem>
                    <List component="nav" />
                  </Fragment>
                )}
              </CatalogoContext.Consumer>
              {obj.getRubrosUI && obj.getRubrosUI.length > 1 ? (
                <ListCustom dato={obj.getRubrosUI} title={_t("Categorías")} />
              ) : (
                ""
              )}
              {obj.getZonasUI && obj.getZonasUI.length > 1 ? (
                <ListCustom dato={obj.getZonasUI} title={_t("Zonas")} />
              ) : (
                ""
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

FilterCategories.propTypes = {};

export default FilterCategories;
