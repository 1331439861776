import { _t } from "js/utils/TranslationService";
import AppQraphQL from "js/graphql/resolvers/puntoDeVenta.resolver";
import { orderBy } from "lodash";

//Tipos de campo que requieren un textField adicional
export const typesWithInput = ["Lista", "Lista_Multiple", "Lista_Autocomplete"];

//campos que requieren un textField adicional
export const keysWithInput = [
  "tasaDeAcreditacion",
  "limitePorFactura",
  "limiteDiario",
  "limiteMensual",
  "requiereComprobante",
  "requiereModeracion",
  "cargaTerceros",
  "regexNroFactura",
  "inicioTratamiento"
];

export const typesWithSelect = ["Lista_Autocomplete_By_Model"];
export const supportedModels = ["Punto de venta", "Usuario"];

//Campos de configuración no renderizables en el formulario de carga de facturas
export const nonRenderableFields = [
  "tasaDeAcreditacion",
  "limiteDiario",
  "limitePorFactura",
  "limiteMensual",
  "incrementos",
  "requiereComprobante",
  "requiereModeracion",
  "cargaTerceros",
  "regexNroFactura",
  "inicioTratamiento"
];

export const inicioTratamientoFields = [
  {
    label: _t("Nombre completo médico"),
    key: "nombreCompletoMedico",
    tipo: "Texto",
    value: ""
  },
  {
    label: _t("Matrícula médico"),
    key: "matriculaMedico",
    tipo: "Texto",
    value: ""
  },
  {
    label: "Productos",
    key: "productos",
    tipo: "Lista_Autocomplete",
    value: []
  }
];

export const modelResolver = [
  {
    value: "Punto de venta",
    graphql: idPrograma => {
      return {
        query: AppQraphQL.queries.getPuntosDeVenta,
        variables: {
          idPrograma,
          order: "asc",
          orderBy: "nombre",
          page: 1,
          rowsPerPage: 1000
        }
      };
    }
  }
];
