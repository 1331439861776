import React, { PureComponent, Fragment } from "react";
import moment from "moment";

import TargetsUnileverGraphQL from "js/graphql/resolvers/targetsUnilever.resolver";
import VariablesMedGraphQL from "js/graphql/resolvers/variablesMedUnilever.resolver";
import CampanasOPGraphQL from "js/graphql/resolvers/campanaUnilever.resolver";
import Form from "js/pages/Unilever/CampanasSupervisor/TargetsCampana/MyForm.js";
import Dialog from "js/pages/Unilever/Dialogs/DialogAprobarTargets.js";
import AuthService from "js/utils/AuthService";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      idPrograma: parseInt(AuthService.getIdPrograma()),
      idCampana: props.computedMatch.params.idCampana,
      isMisTargets: window.location.pathname.indexOf("mis") !== -1,
      initialValues: {},
      med: null,
      disabled: false,
      errors: null,
      dialogErrors: null,
      open: false,
      fechaLimiteSupervisores: null,
      tienePendientes: false
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { idPrograma, idCampana } = this.state;

    await client
      .query({
        query: VariablesMedGraphQL.queries.getVariablesMiMedByIdCampana,
        fetchPolicy: "network-only",
        variables: {
          idCampana,
          idPrograma
        }
      })
      .then(res => {
        const variables = res.data.getVariablesMiMed.itemsPagina[0];
        if (variables) {
          const targetsObjetivos = {};
          variables.targetsObjetivos.forEach(targetsObjetivo => {
            targetsObjetivos[
              String(targetsObjetivo.idObjetivo)
            ] = targetsObjetivo;
          });
          this.setState(
            {
              campana: variables.campana,
              targetsObjetivos,
              fechaLimiteSupervisores: moment(
                variables.fechaLimiteSupervisores
              ).format("DD/MM/YYYY"),
              disabled: moment().isAfter(variables.fechaLimiteSupervisores)
            },
            function() {
              this.fetchTargets();
            }
          );
        } else {
          this.fetchTargets();
          this.fetchCampana();
        }
      });
  }

  async fetchTargets() {
    const { client } = this.props;
    const { idCampana, targetsObjetivos } = this.state;
    await client
      .query({
        query: TargetsUnileverGraphQL.queries.getTargetsDeMedPorCampana,
        fetchPolicy: "network-only",
        variables: {
          idCampana
        }
      })
      .then(res => {
        const targets = res.data.getTargetsDeMedPorCampana;
        const targetsPorObjetivo = {};
        let tienePendientes = false;
        targets.forEach(target => {
          if (target.estado === "PENDIENTE") {
            tienePendientes = true;
          }
          if (!targetsPorObjetivo[String(target.objetivo._id)]) {
            targetsPorObjetivo[String(target.objetivo._id)] = {
              idObjetivo: String(target.objetivo._id),
              objetivo: target.objetivo.objetivo,
              targets: [],
              targetMed:
                targetsObjetivos &&
                targetsObjetivos[String(target.objetivo._id)]
                  ? targetsObjetivos[String(target.objetivo._id)].target
                  : null
            };
          }
          const usuario = target.usuario;
          targetsPorObjetivo[String(target.objetivo._id)].targets.push({
            idUsuario: usuario.idUsuario,
            nombre: `${usuario.perfil.nombre} ${usuario.perfil.apellido} ${
              usuario.estado === "INACTIVO" ? "(INACTIVO)" : ""
            }`,
            target: target.target,
            idTarget: target.target || target.target === 0 ? target._id : null
          });
        });
        const targetsObjetivosArr = [];
        for (const id in targetsPorObjetivo) {
          targetsObjetivosArr.push(targetsPorObjetivo[id]);
        }
        this.setState({
          targetsPorObjetivoArr: targetsObjetivosArr,
          targetsPorObjetivo: targetsPorObjetivo,
          targets: targets,
          tienePendientes
        });
      });
  }

  async fetchCampana() {
    const { client } = this.props;
    const { idCampana } = this.state;
    await client
      .query({
        query: CampanasOPGraphQL.queries.getCampanaNombreById,
        fetchPolicy: "network-only",
        variables: {
          campanaOPLike: {
            _id: idCampana
          }
        }
      })
      .then(res => {
        const campana = res.data.getCampanasOP.itemsPagina[0];
        this.setState({
          campana: campana
        });
      });
  }

  async submit(values) {
    const { client } = this.props;
    const { targetsPorObjetivo, idCampana } = this.state;
    const targetsToSend = [];
    let breakSubmit = false;
    for (const key in values) {
      const totalMed = targetsPorObjetivo[key].targetMed;
      let total = 0;
      const targets = values[key];
      for (let index = 0; index < targets.length; index++) {
        const target = targets[index];
        const targetNum = parseInt(target.target);
        if (targetNum < 0) {
          const mensaje = `No puede haber targets negativos`;
          this.setState({
            errors: [{ message: mensaje }]
          });
          breakSubmit = true;
          break;
        }
        total += targetNum;
        targetsToSend.push({
          _id: target.idTarget,
          target: targetNum,
          idCampana: idCampana,
          idUsuario: target.idUsuario,
          idObjetivo: key
        });
      }
      if (breakSubmit) {
        return;
      }
      if (total !== totalMed) {
        const mensaje = `El total del objetivo ${
          targetsPorObjetivo[key].objetivo
        } debe ser ${totalMed}. Total actual: ${total}`;
        this.setState({
          errors: [{ message: mensaje }]
        });
        breakSubmit = true;
      }
      if (breakSubmit) {
        return;
      }
    }

    await client
      .mutate({
        mutation: TargetsUnileverGraphQL.mutations.updateTargetsDeMed,
        variables: {
          targets: targetsToSend
        }
      })
      .then(res => {
        const mensaje = "Targets actualizados";
        this.setState({
          errors: []
        });
        this.props.openNotification(mensaje);
        this.fetchTargets();
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  openDialog() {
    this.setState({
      open: true
    });
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  async submitDialog() {
    const { client } = this.props;
    const { targets } = this.state;
    await client
      .mutate({
        mutation: TargetsUnileverGraphQL.mutations.aprobarTargetsDeMed,
        variables: {
          targetsIds: targets.map(target => target._id)
        }
      })
      .then(res => {
        const mensaje = "Los targets fueron aprobados correctamente.";
        this.props.openNotification(mensaje);
        this.fetchTargets();
        this.handleClose();
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          dialogErrors:
            errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  render() {
    const {
      errors,
      disabled,
      initialValues,
      campana,
      targetsPorObjetivoArr,
      targetsObjetivos,
      fechaLimiteSupervisores,
      tienePendientes
    } = this.state;

    let disabledFinal = disabled;
    if (!targetsObjetivos) {
      disabledFinal = true;
    }
    return (
      <Fragment>
        <Form
          errors={errors}
          disabled={disabledFinal}
          initialValues={initialValues}
          onSubmit={this.submit.bind(this)}
          campana={campana}
          targetsPorObjetivos={targetsPorObjetivoArr}
          openDialog={this.openDialog.bind(this)}
          fechaLimiteSupervisores={fechaLimiteSupervisores}
          tienePendientes={tienePendientes}
        />
        <Dialog
          onSubmit={this.submitDialog.bind(this)}
          errors={this.state.dialogErrors}
          open={this.state.open}
          handleClose={this.handleClose.bind(this)}
        />
      </Fragment>
    );
  }
}

export default Edit;
