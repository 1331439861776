import React, { Fragment, PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { _t } from "js/utils/TranslationService";
import TextField from "@material-ui/core/TextField";
import WysiwygPanel from "js/pages/Admin/Programa/Legales/WysiwygPanel";
import FormControl from "@material-ui/core/FormControl";
import { InputMultiEmail } from "js/components/Shared/Form/Fields";
import styles from "./styles";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
// import { variables } from "js/pages/Admin/Programa/Notificaciones/Edit/variables.js";
// import Hidden from "@material-ui/core/Hidden";

class Form extends PureComponent {
  render() {
    const {
      history,
      match: {
        params: { idEmail }
      },
      disabled,
      values,
      classes,
      onChange,
      onSubmit,
      submitting
    } = this.props;
    return (
      <Fragment>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {disabled
                ? "Ver "
                : /*idEmail === "new" ? "Nuevo " :*/ "Modificar y enviar "}
              email
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.root}>
          <Grid container direction="row" justify="space-between" spacing={16}>
            <Grid item md={8}>
              <Grid container spacing={8}>
                <Grid item>
                  <Typography variant="h5" gutterBottom>
                    {values.nombre}
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <TextField
                      key="from"
                      name="from"
                      label="De"
                      type="search"
                      disabled
                      value={values.from}
                      onChange={e => onChange(e)}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputMultiEmail
                      name="to"
                      value={values.to}
                      label="Para"
                      placeholder="Para"
                      disabled={disabled}
                      onChange={e => onChange(e)}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      key="subject"
                      name="subject"
                      label="Asunto"
                      type="search"
                      disabled={disabled}
                      value={values.subject}
                      onChange={e => onChange(e)}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputMultiEmail
                      name="cc"
                      value={values.cc}
                      label="CC"
                      placeholder="CC"
                      disabled={disabled}
                      onChange={e => onChange(e)}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputMultiEmail
                      name="cco"
                      value={values.cco}
                      label="CCO"
                      placeholder="CCO"
                      disabled={disabled}
                      onChange={e => onChange(e)}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <div className={classes.wysiwygPanelContainer}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className={classes.subtitle}
                >
                  {_t("Contenido del Email")}
                </Typography>
                <WysiwygPanel
                  initialValue={values.html}
                  inputName="html"
                  onChange={e => onChange(e)}
                  disabled={disabled}
                />
              </div>
              <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.button}
                  onClick={() => history.push("/admin/emails")}
                >
                  Volver
                </Button>
                {disabled &&
                  AuthService.hasPerms([Roles.HISTORIAL_EMAIL_W]) && (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() =>
                        history.push(`/admin/emails/${idEmail}/edit`)
                      }
                    >
                      {_t("Editar")}
                    </Button>
                  )}
                {AuthService.hasPerms([Roles.HISTORIAL_EMAIL_W]) &&
                  !disabled && (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      disabled={disabled || submitting}
                      onClick={onSubmit}
                    >
                      {_t("Enviar Email")}
                    </Button>
                  )}
              </Grid>
            </Grid>
            {/* <Grid item md={4}>
              <Hidden only={["xs", "sm"]}>{variables()}</Hidden>
            </Grid> */}
          </Grid>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Form));
