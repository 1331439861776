export default theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  listWrapper: {
    backgroundColor: "rgb(247,247,247)"
  },
  listSubHeader: {
    fontWeight: "bold",
    fontSize: 16
  }
});
