import {
  compose,
  pure,
  withStateHandlers,
  withProps,
  withHandlers
} from "recompose";
import withStyles from "./styles";

const enhance = compose(
  pure,
  withStyles,
  withHandlers({
    onChangeColor: ({ onChange }) => ({ hex, rgb: { r, g, b, a } }) =>
      onChange(a !== 1 ? `rgba(${r},${g},${b},${a})` : hex)
  }),
  withStateHandlers(
    props => ({
      isOpen: false
    }),
    {
      onClick: ({ isOpen }) => () => ({
        isOpen: !isOpen
      }),
      onClickAway: () => () => ({
        isOpen: false
      })
    }
  ),
  withProps(({ color }) => ({
    paperStyle: {
      backgroundColor: color,
      padding: "12px"
    }
  }))
);

export default enhance;
