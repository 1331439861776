import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import BeneficioGraphQL from "js/graphql/resolvers/catalogoCodigos.resolver";
import SegmentoGraphQL from "js/graphql/resolvers/segmento.resolver.js";
import LocationFilter from "js/components/Shared/TablaFiltro/locationFilter.js";
import RubroFilter from "js/components/Shared/TablaFiltro/rubroFilter.js";
import { TypeAhead } from "js/components/Shared/Form/Fields";

class advancedFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tipos: []
    };
  }

  async componentDidMount() {
    const { client } = this.props;

    await client
      .query({
        query: BeneficioGraphQL.queries.getCatalogoCodigosTipos,
        fetchPolicy: "network-only"
      })
      .then(res => {
        this.setState({
          tipos: res.data.getCatalogoCodigosTipos
            ? res.data.getCatalogoCodigosTipos
            : []
        });
      });
  }

  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  render() {
    const {
      value,
      submitValue,
      classes,
      match: {
        params: { id: idPrograma }
      }
    } = this.props;
    const { tipos } = this.state;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"beneficio"}
              name={"beneficio"}
              label={"Nombre"}
              type="search"
              value={value["beneficio"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              label={"Segmento"}
              onChange={this.handleChange("idSegmento")}
              path={{
                path: ["getSegmentos"],
                value: "idSegmento",
                label: "label"
              }}
              placeholder={"Buscar por segmento"}
              query={SegmentoGraphQL.queries.getSegmentosInput}
              queryParams={{ idPrograma: value.idPrograma }}
              value={value["idSegmento"]}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="tipo-beneficio-simple"
              id="tipo-beneficio-simple"
            >
              Tipo de beneficio
            </InputLabel>
            <Select
              value={value["idTipo"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "idTipo",
                id: "idTipo"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              {tipos &&
                tipos.map((tipo, index) => (
                  <MenuItem key={index} value={tipo.value}>
                    {tipo.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <LocationFilter
            value={value}
            submitValue={submitValue}
            fromPrograma
            idPrograma={idPrograma}
            zonaFromPrograma
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <RubroFilter
            classes={classes}
            value={value}
            submitValue={submitValue}
            fromPrograma
            idPrograma={idPrograma}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"ACTIVO"}>Activo</MenuItem>
              <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(withApollo(withRouter(advancedFilter)));
