import React, { Fragment } from "react";
import { getUrlWithQueryString } from "js/utils";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { UsuarioField, InputTextarea } from "js/components/Shared/Form/Fields";
import UsuariosReconocidos from "./UsuariosReconocidos";
import { IndividualIcon, GrupalIcon, SearchIcon } from "images/icons";
import UsuarioQraphQL from "js/graphql/resolvers/usuario.resolver";
import ReconocimientosGraphQL from "js/graphql/resolvers/reconocimiento.resolver";
import JerarquiaGraphQL from "js/graphql/resolvers/jerarquia.resolver";
import CheckboxField from "js/components/Shared/Form/Fields/Checkbox";
import RadioField from "js/components/Shared/Form/Fields/Radio";
import Paper from "@material-ui/core/Paper/Paper";
import { _t } from "js/utils/TranslationService";
import Button from "@material-ui/core/Button/Button";
import { get } from "lodash";

function getReemplazo(tipo, formulario) {
  const reemplazo =
    tipo && formulario.redefineTipo
      ? formulario.redefineTipo.find(({ label }) => label === tipo)
      : undefined;
  return reemplazo;
}

export default class CrearReconocimiento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuariosNominados: [],
      idUsuariosNominados: [],
      nominaEquipo: false,
      token: new Date().getTime().toString(),
      showUsuariosLabel: false,
      showLabelJerarquia: false
    };
  }

  async componentWillUpdate(nextProps, nextState) {
    if (
      nextState.idUsuariosNominados !== this.state.idUsuariosNominados ||
      nextState.idUsuariosNominados.length !==
        this.state.idUsuariosNominados.length
    ) {
      await this.validaUsuarios(nextState.idUsuariosNominados);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.reconocimientoRechazado !== this.props.reconocimientoRechazado
    ) {
      const {
        initialValues,
        reconocimientoRechazado,
        formulario: { valor, tipo }
      } = nextProps;
      initialValues.menurrhh = reconocimientoRechazado.menurrhh + "";
      initialValues.respuesta1 = reconocimientoRechazado.respuesta1 + "";
      initialValues.respuesta2 = reconocimientoRechazado.respuesta2 + "";
      initialValues.respuesta3 = reconocimientoRechazado.respuesta3 + "";
      if (
        valor &&
        valor.alias &&
        valor.valores &&
        reconocimientoRechazado.valor
      ) {
        initialValues.valor = valor.respuestaMultiple
          ? reconocimientoRechazado.valor.reduce((obj, valor) => {
              obj[valor] = true;
              return obj;
            }, {})
          : reconocimientoRechazado.valor[0];
      }
      if (tipo && tipo.alias && tipo.valores && reconocimientoRechazado.tipo) {
        initialValues.tipo = tipo.respuestaMultiple
          ? reconocimientoRechazado.tipo.reduce((obj, tipo) => {
              obj[tipo] = true;
              return obj;
            }, {})
          : reconocimientoRechazado.tipo[0];
      }
      this.setState({
        nominaEquipos: reconocimientoRechazado.usuariosReconocidos.length > 1
      });
      this.setState({
        usuariosNominados: reconocimientoRechazado.usuariosReconocidos
      });
      this.setState({
        idUsuariosNominados: reconocimientoRechazado.usuariosReconocidos.map(
          ({ idUsuario }) => idUsuario
        )
      });
    }
  }

  validaUsuarios = async idUsuarios => {
    let invalido = false;
    const { formulario } = this.props;
    const reemplazo = getReemplazo(this.props.tipo, formulario);
    const nivelAprobacion = reemplazo
      ? reemplazo.nivelAprobacion
      : formulario.nivelAprobacion;
    await Promise.all(
      idUsuarios.map(async idUsuario => {
        const { data: getJerarquia } = await this.props.client.query({
          query: JerarquiaGraphQL.queries.getJerarquia,
          variables: { idUsuario },
          fetchPolicy: "cache-first",
          errorPolicy: "all"
        });
        //HABLAR CON PP PARA VER ESTA VALIDACION
        if (!getJerarquia || !getJerarquia.getJerarquia.idJefeNivel1) {
          invalido = true;
        }
      })
    );
    this.setState({ showLabelJerarquia: invalido });
  };
  onUsuarioIdChange = (usuarios, typeahead) => {
    const usuario = usuarios[0];
    if (!usuario) return;
    if (this.state.nominaEquipo) {
      if (!this.state.idUsuariosNominados.includes(usuario.value)) {
        const idUsuarios = this.state.idUsuariosNominados.concat([
          usuario.value
        ]);
        this.usuarioById(usuario).then(({ data }) => {
          const usuario = data.getUsuarios.itemsPagina[0];
          this.state.usuariosNominados.push(usuario);
          this.setState({ usuariosNominados: this.state.usuariosNominados });
          this.setState({ idUsuariosNominados: idUsuarios });
        });
        // this.validaUsuarios(this.state.idUsuariosNominados);
      }
    } else {
      this.setState({ idUsuariosNominados: [usuario.value] });
      this.usuarioById(usuario).then(({ data }) => {
        const usuario = data.getUsuarios.itemsPagina[0];
        this.setState({ usuariosNominados: [usuario] });
      });
      // this.validaUsuarios([usuario.value]);
    }
    usuarios.pop();
    typeahead._typeahead.getInstance().clear();
  };

  usuarioById(usuario) {
    return this.props.client.query({
      query: UsuarioQraphQL.queries.getUsuarioById,
      variables: {
        idUsuario: usuario.value
      },
      fetchPolicy: "network-only",
      errorPolicy: "all"
    });
  }
  componentDidMount() {
    window.addEventListener("message", this.onSubmit);
  }

  removeNominado = idUsuario => {
    const usuariosNominados = this.state.usuariosNominados.filter(
      u => u.idUsuario !== idUsuario
    );
    const idUsuariosNominados = this.state.idUsuariosNominados.filter(
      id => id !== idUsuario
    );
    this.setState({ usuariosNominados });
    this.setState({ idUsuariosNominados });
  };

  onSubmit = e => {
    if (e.origin !== "https://www.surveygizmo.com") return;
    const {
      client,
      formulario = {},
      respuesta1,
      respuesta2,
      respuesta3,
      mensaje,
      history,
      touch
    } = this.props;
    const { idUsuariosNominados, nominaEquipo } = this.state;
    const { _id, valor, tipo } = formulario;
    if (this.formInvalido()) {
      touch("respuesta1");
      touch("respuesta2");
      touch("respuesta3");
      touch("mensaje");
      touch("tipo");
      touch("valor");
      const reemplazo = getReemplazo(this.props.tipo, formulario);
      const conSG = reemplazo ? reemplazo.conSG : formulario.conSG;
      if (conSG) {
        this.setState({
          showUsuariosLabel: true,
          editLink:
            e.data.replace("http://", "https://") + `&token=${this.state.token}`
        });
      } else {
        this.setState({
          showUsuariosLabel: true
        });
      }
      return;
    }
    let idUsuariosReconocidosFiltrado = idUsuariosNominados;
    if (!nominaEquipo) {
      idUsuariosReconocidosFiltrado = [idUsuariosNominados[0]];
    }
    const reconocimiento = {
      idFormulario: _id,
      idUsuariosReconocidos: idUsuariosReconocidosFiltrado,
      token: this.state.token,
      respuesta1,
      respuesta2,
      respuesta3,
      mensaje
    };
    if (valor && valor.alias && valor.valores) {
      reconocimiento.valor = valor.respuestaMultiple
        ? valor.valores.filter(value => this.props.valor[value])
        : [this.props.valor];
    }
    if (tipo && tipo.alias && tipo.valores) {
      reconocimiento.tipo = tipo.respuestaMultiple
        ? tipo.valores.filter(value => this.props.tipo[value])
        : [this.props.tipo];
    }
    client
      .mutate({
        mutation: ReconocimientosGraphQL.mutations.createReconocimiento,
        variables: {
          reconocimiento: reconocimiento
        }
      })
      .then(({ data: { createReconocimiento: { _id } } }) => {
        history.push("/reconocido/" + _id);
      })
      .catch(e => {
        console.log(e);
      });
  };

  formInvalido() {
    const {
      respuesta1,
      respuesta2,
      respuesta3,
      mensaje,
      formulario: {
        tipo,
        valor,
        pregunta1,
        pregunta2,
        pregunta3,
        mensajeNominador
      }
    } = this.props;
    const { idUsuariosNominados } = this.state;
    const usuariosInvalido = !idUsuariosNominados.length;
    const respuesta1Invalida =
      pregunta1 && pregunta1.seMuestra && pregunta1.esRequerida && !respuesta1;
    const respuesta2Invalida =
      pregunta2 && pregunta2.seMuestra && pregunta2.esRequerida && !respuesta2;
    const respuesta3Invalida =
      pregunta3 && pregunta3.seMuestra && pregunta3.esRequerida && !respuesta3;
    const mensajeInvalido =
      mensajeNominador &&
      mensajeNominador.seMuestra &&
      mensajeNominador.esRequerida &&
      !mensaje;
    const tipoInvalido = tipo && tipo.alias && !this.props.tipo;
    const valorInvalido = valor && valor.alias && !this.props.valor;
    return (
      usuariosInvalido ||
      respuesta1Invalida ||
      respuesta2Invalida ||
      respuesta3Invalida ||
      mensajeInvalido ||
      tipoInvalido ||
      valorInvalido ||
      this.state.showLabelJerarquia
    );
  }

  render() {
    const {
      formulario = {},
      classes,
      usuario,
      programa,
      reconocimientoRechazado,
      tipo,
      valor
    } = this.props;
    const reemplazo = getReemplazo(tipo, formulario);
    const mostrarIframe = () => {
      if (reemplazo) {
        return reemplazo.conSG;
      }
      return formulario.conSG;
    };
    const nivelAprobacion = reemplazo
      ? reemplazo.nivelAprobacion
      : formulario.nivelAprobacion;
    const { username } = usuario ? usuario : {};
    const { nombre, apellido } =
      usuario && usuario.perfil ? usuario.perfil : {};

    const urlVariables = {};
    if (reconocimientoRechazado) {
      reconocimientoRechazado.respuestas.map(
        ({ respuesta }, index) => (urlVariables[`r${index}`] = respuesta)
      );
    }
    const url = encodeURI(
      this.state.editLink
        ? this.state.editLink
        : getUrlWithQueryString(reemplazo ? reemplazo.url : formulario.url, {
            token: this.state.token,
            ...urlVariables
          })
    );

    let idUsuario;
    const tieneValor =
      formulario.valor && formulario.valor.alias && formulario.valor.valores;
    const tieneTipo =
      formulario.tipo && formulario.tipo.alias && formulario.tipo.valores;
    var tipo1 = {
      tipo: true
    };
    const styleCheckbox = {
      display: "flex",
      "flex-direction": "row",
      "flex-wrap": "wrap"
    };
    const filas = [
      {
        orden: get(formulario, "pregunta1.orden", 0),
        render: this.renderPregunta1
      },
      {
        orden: get(formulario, "pregunta2.orden", 1),
        render: this.renderPregunta2
      },
      {
        orden: get(formulario, "pregunta3.orden"),
        render: this.renderPregunta3
      },
      {
        orden: get(formulario, "mensajeNominador.orden", 2),
        render: this.renderMensajeNominador
      },
      {
        orden: get(formulario, "valor.orden", 3),
        render: this.renderValor
      },
      {
        orden: get(formulario, "tipo.orden", 4),
        render: this.renderTipo
      }
    ].sort((a, b) => a.orden - b.orden);
    return (
      <Grid
        xs={12}
        item
        container
        style={{
          margin: "0px",
          width: "100%",
          maxWidth: "100%",
          backgroundColor: "#FFFFFF"
        }}
      >
        <Grid xs={12} item container>
          <Grid xs={12} item className={classes.fondoTitulo}>
            <Typography variant={"h3"} classes={{ root: classes.textoTitulo }}>
              {_t("¿A quién vas a nominar?")}
            </Typography>
            <Typography
              variant={"h3"}
              classes={{ root: classes.textoSubtitulo }}
            >
              {_t(
                "Elegí a quien o quienes querés nominar, completá los campos requeridos, y enviala para que pueda ser aprobada."
              )}
            </Typography>
          </Grid>
          {/*<Hidden smDown>*/}
          {/*<Grid sm={1} item>*/}
          {/*<br />*/}
          {/*</Grid>*/}
          {/*</Hidden>*/}
          <Grid
            xs={12}
            // sm={10}
            item
            container
            direction={"row"}
            alignContent={"center"}
            justify={"center"}
          >
            <Grid
              xs={12}
              direction={"row"}
              justify={"center"}
              item
              container
              className={classes.containerTop}
            >
              <Grid xs={12} sm={5} item container>
                <Grid xs={12} item>
                  <Typography
                    color={"primary"}
                    style={{
                      marginBottom: "20px"
                    }}
                  >
                    NOMINADOR
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <Typography classes={{ root: classes.textoNominador }}>
                    {nombre && apellido ? `${nombre} ${apellido}` : username}
                  </Typography>
                </Grid>
                <Grid xs={12} item container style={{ paddingTop: "15px" }}>
                  <Grid xs={6} item>
                    <IndividualIcon
                      viewBox="5 5 40 40"
                      onClick={() => this.setState({ nominaEquipo: false })}
                      classes={{
                        root: this.state.nominaEquipo
                          ? classes.botonDesactivado
                          : classes.botonActivado
                      }}
                    />
                    <Typography
                      classes={{
                        root: this.state.nominaEquipo
                          ? classes.botonTextoDesactivado
                          : classes.botonTextoActivado
                      }}
                    >
                      INDIVIDUAL
                    </Typography>
                    {!this.state.nominaEquipo &&
                    formulario.puedeReconocerEquipos ? (
                      <Typography
                        classes={{
                          root: classes.leyenda
                        }}
                      >
                        {_t(
                          'Si la acción fue realizada por varias personas, seleccioná "EQUIPO" y agregá a las personas que desees nominar.'
                        )}
                      </Typography>
                    ) : (
                      <div
                        style={{
                          height: "45px"
                        }}
                      />
                    )}
                  </Grid>
                  {formulario.puedeReconocerEquipos ? (
                    <Grid xs={6} item>
                      <GrupalIcon
                        viewBox="5 5 40 40"
                        onClick={() => this.setState({ nominaEquipo: true })}
                        classes={{
                          root: this.state.nominaEquipo
                            ? classes.botonActivado
                            : classes.botonDesactivado
                        }}
                      />
                      <Typography
                        classes={{
                          root: this.state.nominaEquipo
                            ? classes.botonTextoActivado
                            : classes.botonTextoDesactivado
                        }}
                        style={{
                          paddingLeft: "8px"
                        }}
                      >
                        EQUIPO
                      </Typography>
                      {nivelAprobacion && this.state.nominaEquipo ? (
                        <Typography
                          classes={{
                            root: classes.leyenda
                          }}
                        >
                          {_t(
                            "Tu nominación va a ser revisada para su aprobación por los jefes directos del primer nominado que selecciones."
                          )}
                        </Typography>
                      ) : (
                        <div
                          style={{
                            height: "45px"
                          }}
                        />
                      )}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
              <Grid
                xs={12}
                sm={5}
                item
                container
                spacing={16}
                style={{
                  marginBottom: "5px"
                }}
                className={classes.containerUsuariosReconocidos}
              >
                <Paper className={classes.card}>
                  <Grid xs={12} item className={classes.containerUsuarioField}>
                    <Typography color={"primary"}>
                      {_t("NOMINADO/S")}
                    </Typography>
                    <UsuarioField
                      async
                      classes={{ input: classes.usuarioField }}
                      value={idUsuario}
                      idPrograma={programa.idPrograma}
                      onChange={this.onUsuarioIdChange}
                      withTodos={false}
                      without={[usuario ? usuario.idUsuario : 0].concat(
                        this.state.idUsuariosNominados
                      )}
                      sinAdminAshiwea
                      sinInactivos
                      noLabel
                      noPlaceholder
                      otherComponents={{
                        IndicatorsContainer: props => (
                          <SearchIcon
                            color={"primary"}
                            style={{ padding: "2px" }}
                            {...props}
                          />
                        )
                      }}
                      variant={"outlined"}
                      multilabel={[
                        "perfil.nombre",
                        " ",
                        "perfil.apellido",
                        " (",
                        "nombre",
                        ")"
                      ]}
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    item
                    className={classes.containerUsuariosNominados}
                  >
                    <UsuariosReconocidos
                      noRemove={false}
                      ref="usuariosReconocidos"
                      usuariosNominados={this.state.usuariosNominados}
                      nominaEquipo={this.state.nominaEquipo}
                      idUsuariosNominados={this.state.idUsuariosNominados}
                      formulario={formulario}
                      classes={classes}
                      removeNominado={this.removeNominado}
                    />
                  </Grid>
                </Paper>
                {this.state.showUsuariosLabel &&
                this.state.idUsuariosNominados.length === 0 ? (
                  <Grid xs={12} item>
                    <Typography
                      classes={{
                        root: classes.textoError
                      }}
                    >
                      {_t("Requerido")}
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}
                {this.state.showLabelJerarquia &&
                this.state.idUsuariosNominados.length > 0 ? (
                  <Grid xs={12} item>
                    <Typography
                      classes={{
                        root: classes.textoError
                      }}
                    >
                      {_t(
                        "Un usuario seleccionado no tiene Jerarquia definida"
                      )}
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            {filas.map(fila =>
              fila.render({
                tieneValor,
                tieneTipo,
                tipo1,
                classes,
                formulario,
                styleCheckbox,
                valor,
                state: this.state
              })
            )}
          </Grid>
          <Grid
            xs={12}
            item
            className={tipo1.tipo ? classes.containerUno : classes.containerDos}
            style={{
              padding: "20px 0px"
            }}
          >
            {mostrarIframe() ? (
              <iframe
                key={formulario._id}
                title={formulario._id}
                src={url}
                width="100%"
                height={formulario.alturaIframe || "1300"}
                frameBorder={0}
              />
            ) : (
              <Grid
                xs={12}
                item
                className={classes.containerBotones}
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  classes={{
                    label: classes.buttonLabel
                  }}
                  onClick={e => {
                    e.origin = "https://www.surveygizmo.com";
                    this.onSubmit(e);
                  }}
                >
                  {_t("Reconocer")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderTipo({
    tieneTipo,
    tipo1,
    classes,
    formulario,
    styleCheckbox,
    tipo,
    state
  }) {
    return tieneTipo ? (
      <Grid
        xs={12}
        direction={"row"}
        justify={"center"}
        item
        container
        className={tipo1.tipo ? classes.containerUno : classes.containerDos}
      >
        {(tipo1.tipo = !tipo1.tipo)}
        <Grid xs={12} sm={5} item>
          <Typography classes={{ root: classes.textoPregunta }}>
            {formulario.tipo.alias}
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={
            formulario.tipo.respuestaMultiple
              ? styleCheckbox
              : { display: "block" }
          }
        >
          {formulario.tipo.respuestaMultiple ? (
            formulario.tipo.valores.map(valor => (
              <CheckboxField
                name={"tipo." + valor}
                key={valor}
                fullWidth={false}
                label={valor}
              />
            ))
          ) : (
            <Fragment>
              <RadioField
                name="tipo"
                options={formulario.tipo.valores.map(valor => ({
                  label: valor,
                  value: valor
                }))}
                required
              />
            </Fragment>
          )}
          {state.showUsuariosLabel && !tipo ? (
            <Typography
              classes={{
                root: classes.textoError
              }}
              style={{ paddingLeft: "12px" }}
            >
              {_t("Requerido")}
            </Typography>
          ) : (
            ""
          )}
          {formulario.leyendaTipo ? (
            <Typography
              classes={{
                root: classes.textoLeyendaTipo
              }}
              style={{ whiteSpace: "pre-line" }}
            >
              {formulario.leyendaTipo}
            </Typography>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    ) : (
      ""
    );
  }

  renderValor({
    tieneValor,
    tipo1,
    classes,
    formulario,
    styleCheckbox,
    valor,
    state
  }) {
    return tieneValor ? (
      <Grid
        xs={12}
        direction={"row"}
        justify={"center"}
        item
        container
        className={tipo1.tipo ? classes.containerUno : classes.containerDos}
      >
        {(tipo1.tipo = !tipo1.tipo)}
        <Grid xs={12} sm={5} item>
          <Typography classes={{ root: classes.textoPregunta }}>
            {formulario.valor.alias}
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={
            formulario.valor.respuestaMultiple
              ? styleCheckbox
              : { display: "block" }
          }
        >
          {formulario.valor.respuestaMultiple ? (
            formulario.valor.valores.map(valor => (
              <CheckboxField
                name={"valor." + valor}
                key={valor}
                fullWidth={false}
                label={valor}
              />
            ))
          ) : (
            <Fragment>
              <RadioField
                name="valor"
                options={formulario.valor.valores.map(valor => ({
                  label: valor,
                  value: valor
                }))}
                required
              />
            </Fragment>
          )}
          {state.showUsuariosLabel && !valor ? (
            <Typography
              classes={{
                root: classes.textoError
              }}
              style={{ paddingLeft: "12px" }}
            >
              {_t("Requerido")}
            </Typography>
          ) : (
            ""
          )}
          {formulario.leyendaValor ? (
            <Typography
              classes={{
                root: classes.textoLeyendaTipo
              }}
              style={{ whiteSpace: "pre-line" }}
            >
              {formulario.leyendaValor}
            </Typography>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    ) : (
      ""
    );
  }

  renderMensajeNominador({ formulario, tipo1, classes }) {
    return formulario.mensajeNominador &&
      formulario.mensajeNominador.seMuestra ? (
      <Grid
        xs={12}
        direction={"row"}
        justify={"center"}
        item
        container
        className={tipo1.tipo ? classes.containerUno : classes.containerDos}
      >
        {(tipo1.tipo = !tipo1.tipo)}
        <Grid xs={12} sm={5} item>
          <Typography classes={{ root: classes.textoPregunta }}>
            {formulario.mensajeNominador && formulario.mensajeNominador.alias
              ? formulario.mensajeNominador.alias
              : "Mensaje"}
          </Typography>
        </Grid>
        <Grid xs={12} sm={5} item>
          <InputTextarea
            classes={classes}
            name="mensaje"
            multiple
            variant={"outlined"}
            required={
              formulario.mensajeNominador &&
              formulario.mensajeNominador.esRequerida !== "undefined"
                ? formulario.mensajeNominador.esRequerida
                : true
            }
          />
        </Grid>
      </Grid>
    ) : (
      ""
    );
  }

  renderPregunta3({ formulario, classes, tipo1 }) {
    return formulario.pregunta3 && formulario.pregunta3.seMuestra ? (
      <Grid
        xs={12}
        direction={"row"}
        justify={"center"}
        item
        container
        className={tipo1.tipo ? classes.containerUno : classes.containerDos}
      >
        {(tipo1.tipo = !tipo1.tipo)}
        <Grid xs={12} sm={5} item className={classes.contenedorIzquierda}>
          <Typography classes={{ root: classes.textoPregunta }}>
            {formulario.pregunta3 && formulario.pregunta3.alias
              ? formulario.pregunta3.alias
              : ""}
          </Typography>
        </Grid>
        <Grid xs={12} sm={5} item>
          <InputTextarea
            classes={classes}
            variant={"outlined"}
            name="respuesta3"
            multiple
            required={
              formulario.pregunta3 &&
              formulario.pregunta3.esRequerida !== "undefined"
                ? formulario.pregunta3.esRequerida
                : true
            }
          />
        </Grid>
      </Grid>
    ) : (
      ""
    );
  }

  renderPregunta2({ formulario, tipo1, classes }) {
    return formulario.pregunta2 && formulario.pregunta2.seMuestra ? (
      <Grid
        xs={12}
        direction={"row"}
        justify={"center"}
        item
        container
        className={tipo1.tipo ? classes.containerUno : classes.containerDos}
      >
        {(tipo1.tipo = !tipo1.tipo)}
        <Grid xs={12} sm={5} item>
          <Typography classes={{ root: classes.textoPregunta }}>
            {formulario.pregunta2 && formulario.pregunta2.alias
              ? formulario.pregunta2.alias
              : "¿Por qué?"}
          </Typography>
        </Grid>
        <Grid xs={12} sm={5} item>
          <InputTextarea
            classes={classes}
            name="respuesta2"
            multiple
            variant={"outlined"}
            required={
              formulario.pregunta2 &&
              formulario.pregunta2.esRequerida !== "undefined"
                ? formulario.pregunta2.esRequerida
                : true
            }
          />
        </Grid>
      </Grid>
    ) : (
      ""
    );
  }

  renderPregunta1({ formulario, classes, tipo1 }) {
    return formulario.pregunta1 && formulario.pregunta1.seMuestra ? (
      <Grid
        xs={12}
        direction={"row"}
        justify={"center"}
        item
        container
        className={tipo1.tipo ? classes.containerUno : classes.containerDos}
      >
        {(tipo1.tipo = !tipo1.tipo)}
        <Grid xs={12} sm={5} item className={classes.contenedorIzquierda}>
          <Typography classes={{ root: classes.textoPregunta }}>
            {formulario.pregunta1 && formulario.pregunta1.alias
              ? formulario.pregunta1.alias
              : "¿Cuál fue la Acción, Comportamiento o Resultado destacado por la cual creés que la persona seleccionada debe ser reconocida?"}
          </Typography>
        </Grid>
        <Grid xs={12} sm={5} item>
          <InputTextarea
            classes={classes}
            variant={"outlined"}
            name="respuesta1"
            multiple
            required={
              formulario.pregunta1 &&
              formulario.pregunta1.esRequerida !== "undefined"
                ? formulario.pregunta1.esRequerida
                : true
            }
          />
        </Grid>
      </Grid>
    ) : (
      ""
    );
  }
}
