import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
// import { Query } from "react-apollo";
import Tooltip from "@material-ui/core/Tooltip";
import { withApollo } from "react-apollo";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import ErrorList from "js/components/Shared/ErrorList";
import { _t } from "js/utils/TranslationService";
import CircularProgress from "@material-ui/core/CircularProgress";

import ProgramQraphQL from "js/graphql/resolvers/programa.resolver";
import {
  FieldsProvider,
  fieldGenerator
} from "js/components/Shared/Form/fieldGenerator";
import { InputTextField } from "js/components/Shared/Form/Fields";

import {
  getRulesInputsNumericos,
  getNumericFieldValidations
} from "js/components/Shared/Form/fieldGenerator";
import { minValue, maxValue } from "js/helpers/validations";
import {
  validateDefault,
  validateLetrasNumeros,
  validateNumeros
} from "./../validation";

import AppQraphQL from "js/graphql/resolvers/programa.resolver";
const rules = { minValue, maxValue };

const FieldWrapper = ({ children }) => (
  <Grid item xs={12}>
    {children}
  </Grid>
);

const invertStatePanel = (panels, newState) =>
  Object.keys(panels).reduce((acc, k) => ({ ...acc, [k]: newState }), {});

class SignupForm extends Component {
  state = {
    showPassword: false,
    panels: {
      user: true
    },
    numericDefinitionsValidations: null,
    profileDefinitions: []
  };

  async componentDidMount() {
    const { client, programa } = this.props;
    const url = window.location.hostname.replace("www.", "");
    await client.query({
      query: AppQraphQL.queries.getPrograma,
      variables: { url },
      errorPolicy: "all",
      fetchPolicy: "cache-first"
    });
    await client
      .query({
        query: ProgramQraphQL.queries.getDefinicionesPerfilPublico,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: programa.idPrograma,
          definicionPerfilLike: {
            visibleRegistro: true
          }
        }
      })
      .then(res => {
        if (res && res.data && res.data.getDefinicionesPerfilPublico) {
          const profileDefinitions = res.data.getDefinicionesPerfilPublico;

          const checkRulesByNumericDefinition = profileDefinitions
            ? getRulesInputsNumericos(profileDefinitions)
            : [];
          const validations = {};
          for (const key in checkRulesByNumericDefinition) {
            const checkRules = checkRulesByNumericDefinition[key];
            validations[key] = getNumericFieldValidations(checkRules, rules);
          }
          this.setState({
            numericDefinitionsValidations: validations,
            profileDefinitions: res.data.getDefinicionesPerfilPublico
          });
        }
      });
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  componentDidUpdate(prevProps) {
    if (this.props.hasSubmitFailed !== prevProps.hasSubmitFailed) {
      this.setState(({ panels }) => ({
        panels: {
          ...invertStatePanel(panels, true)
        }
      }));
    }
  }

  togglePanel = panel =>
    this.setState(({ panels }) => ({
      panels: {
        ...invertStatePanel(panels, false),
        [panel]: !panels[panel]
      }
    }));

  validateUsername = value => {
    const { programa } = this.props;
    const username = programa.conUsername;
    if (username === "NumerosLetras") {
      return validateLetrasNumeros(value);
    } else if (username === "Numeros") {
      return validateNumeros(value);
    } else {
      return validateDefault(value);
    }
  };
  toolTipMessage = () => {
    const { programa } = this.props;
    const username = programa.conUsername;
    if (username === "NumerosLetras") {
      return "Caracteres permitidos: Números y letras";
    } else if (username === "Numeros") {
      return "Caracteres permitidos: Números";
    } else {
      return "Caracteres permitidos: @ & . _ - ";
    }
  };

  getUserAndPasswordFields(password, handleChange, classes) {
    return (
      <>
        <ExpansionPanel
          expanded={this.props.hasSubmitFailed || this.state.panels.user}
          onChange={() => this.togglePanel("user")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={this.props.classes.heading}>
              {_t("Datos del usuario")}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Tooltip title={this.toolTipMessage()}>
                  <InputTextField
                    name="username"
                    label="Usuario"
                    // placeholder="Ingrese el usuario"
                    classes={this.props.classes}
                    autoComplete="off"
                    customValidations={[this.validateUsername]}
                    required
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <Input
                    placeholder={_t("Clave")}
                    id="adornment-password"
                    autoComplete="new-password"
                    type={this.state.showPassword ? "text" : "password"}
                    value={password}
                    onChange={handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end" disableTypography={true}>
                        <IconButton
                          aria-label={`${
                            this.state.showPassword
                              ? _t("Ocultar clave")
                              : _t("Mostrar clave")
                          } `}
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </>
    );
  }

  render() {
    const {
      handleSubmit,
      classes,
      hasSubmitFailed,
      loading,
      errors,
      password,
      handleChange
    } = this.props;
    const {
      panels,
      profileDefinitions,
      numericDefinitionsValidations
    } = this.state;
    const getErrorsGridAndButton = () => {
      return (
        <Fragment>
          <Grid item xs={12}>
            <ErrorList errors={errors} belowInput />
          </Grid>
          <Grid container justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
            >
              {!loading && _t("Registrarme")}
              {loading && (
                <CircularProgress
                  className={classes.progress}
                  size={20}
                  color="inherit"
                />
              )}
            </Button>
          </Grid>
        </Fragment>
      );
    };

    return (
      <Fragment>
        <form
          onSubmit={e => {
            handleSubmit(e);
          }}
          noValidate
        >
          {this.getUserAndPasswordFields(password, handleChange, classes)}
          <FieldsProvider definitions={profileDefinitions} classes={classes}>
            {({ groupTypes, groups }) =>
              groupTypes.map(groupName => (
                <ExpansionPanel
                  key={groupName}
                  expanded={
                    hasSubmitFailed || !!panels[groupName] || !groupName.length
                  }
                  onChange={() => this.togglePanel(groupName)}
                >
                  {!!groupName && (
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>
                        {_t(groupName)}
                      </Typography>
                    </ExpansionPanelSummary>
                  )}
                  <ExpansionPanelDetails>
                    <Grid container spacing={24}>
                      {fieldGenerator({
                        group: groups[groupName],
                        wrapper: FieldWrapper,
                        formName: "signupForm",
                        visible: "visibleRegistro",
                        requerido: "requeridoRegistro",
                        extraProps: {
                          Ubicacion: {
                            breakpoints: {
                              xs: 12
                            }
                          }
                        },
                        numericDefinitionsValidations
                      })}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))
            }
          </FieldsProvider>
          {getErrorsGridAndButton()}
        </form>
      </Fragment>
    );
  }
}

SignupForm = reduxForm({
  form: "signupForm",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(SignupForm);

export default withApollo(SignupForm);
