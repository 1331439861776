import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Slide,
  Grid
} from "@material-ui/core";

import { withNotificationContext } from "js/notification-context";
import AppQraphQL from "js/graphql/resolvers/targets.resolver";
import { selectTargetById } from "js/redux/campana/campana.selectors";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";

// const styles = theme => ({
//   dialogContent: {
//     overflow: "visible"
//   }
// });

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogEditTarget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      error: undefined,
      target: { ...this.props.target }
    };
  }

  sendValues = async () => {
    const { client } = this.props;
    const { target } = this.state;
    const variables = {
      _id: target._id,
      idObjetivo: target.idObjetivo,
      target: target.target
    };
    this.setState({ error: undefined });
    await client
      .mutate({
        mutation: AppQraphQL.mutations.updateTarget,
        variables: {
          target: { ...variables }
        }
      })
      .then(res => {
        this.props.openNotification("Target modificado correctamente.");
        this.props.onTableChange();
        this.props.handleClose();
      })
      .catch(error => {
        this.props.openNotification("Hubo un error al actualizar el target");
      });
  };

  handleChangeTarget = e => {
    const target = { ...this.state.target };
    target.target = e.target.value;
    this.setState({ target: { ...target } });
  };

  render() {
    const { handleClose, classes } = this.props;
    return (
      <Dialog
        open
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={classes.dialogTitle}
        >
          Editar target
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="username"
                  label="Usuario"
                  value={this.state.target.usuario.username}
                  margin="normal"
                  disabled
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="objective"
                  label="Objetivo"
                  value={this.state.target.objetivo.objetivo}
                  margin="normal"
                  disabled
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="target"
                  label="Target"
                  value={this.state.target.target}
                  type="number"
                  onChange={e => this.handleChangeTarget(e)}
                  margin="normal"
                  error={this.state.error}
                  helperText={this.state.error}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button
            disabled={this.state.target.target === ""}
            onClick={this.sendValues}
            variant="contained"
            color="primary"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const enhance = compose(
  withApollo,
  withNotificationContext,
  withRouter,
  withStyles(styles)
);

const mapStateToProps = (state, ownProps) => {
  return {
    target: selectTargetById(state, ownProps.idTarget)
  };
};

export default enhance(
  connect(
    mapStateToProps,
    null
  )(DialogEditTarget)
);
