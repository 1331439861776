import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import TextField from "@material-ui/core/TextField";
import moment from "moment";

import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import BeneficioGraphQL from "js/graphql/resolvers/catalogoCodigos.resolver";
// import SegmentoGraphQL from "js/graphql/resolvers/segmento.resolver.js";
// import LocationFilter from "js/components/Shared/TablaFiltro/locationFilter.js";
// import RubroFilter from "js/components/Shared/TablaFiltro/rubroFilter.js";
import { UsuarioField, TypeAhead } from "js/components/Shared/Form/Fields";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";

class advancedFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tipos: []
    };
  }

  async componentDidMount() {
    const { client } = this.props;

    await client
      .query({
        query: BeneficioGraphQL.queries.getCatalogoCodigosTipos,
        fetchPolicy: "network-only"
      })
      .then(res => {
        this.setState({
          tipos: res.data.getCatalogoCodigosTipos
            ? res.data.getCatalogoCodigosTipos
            : []
        });
      });
  }

  updateQueryParamsAsync = ({ key, value }) => state => ({
    [key]: value,
    queryParams: {
      programaLike: { [key]: value }
    }
  });

  updateAutocompleteQueryParamsUsuario = key => select => {
    const value = select && select[0] ? select[0].value : undefined;
    this.setState(
      this.updateQueryParamsAsync({ key, value: value }),
      this.props.submitValue({ target: { name: key, value: value } })
    );
  };

  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  render() {
    const {
      value,
      submitValue,
      classes,
      match: {
        params: { id: idPrograma }
      }
    } = this.props;
    // const { tipos } = this.state;
    const isMisSolicitudes = window.location.pathname.indexOf("mis") !== -1;

    return (
      <Grid container spacing={16}>
        {!isMisSolicitudes && (
          <Grid item xs={12} md={4}>
            <UsuarioField
              label={"Usuario"}
              async
              withTodos={true}
              placeholder={"Buscar por usuario"}
              idPrograma={value["idPrograma"]}
              noOptionsMessage={"No hay usuarios"}
              onChange={this.updateAutocompleteQueryParamsUsuario("idUsuario")}
              value={value["idUsuario"]}
              disabled={!value["idPrograma"]}
            />
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              query={
                isMisSolicitudes
                  ? BeneficioGraphQL.queries.getCatalogoCatalogoCodigosInput
                  : BeneficioGraphQL.queries.getCatalogoCodigosInput
              }
              queryParams={{ idPrograma: idPrograma }}
              placeholder={"CatalogoCodigos"}
              label={"CatalogoCodigos"}
              path={{
                path: ["getPaginaCatalogoCodigos", "itemsPagina"],
                value: "value",
                label: "label",
                defaultLabel: "value"
              }}
              onChange={this.handleChange("idBeneficio")}
              value={value["idBeneficio"]}
              withTodos
            />
          </FormControl>
        </Grid>

        {/* <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="tipo-beneficio-simple"
              id="tipo-beneficio-simple"
            >
              Tipo de beneficio
            </InputLabel>
            <Select
              value={value["idTipo"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "idTipo",
                id: "idTipo"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              {tipos &&
                tipos.map((tipo, index) => (
                  <MenuItem key={index} value={tipo.value}>
                    {tipo.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaDesde"}
              name={"fechaDesde"}
              label="Fecha (Desde)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaDesde",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaDesde"] ? moment(value["fechaDesde"]) : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaHasta"}
              name={"fechaHasta"}
              label="Fecha (Hasta)"
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaHasta",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaHasta"] ? moment(value["fechaHasta"]) : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(withApollo(withRouter(advancedFilter)));
