import get from "lodash/get";

const drawerWidth = 300;
const getActiveColor = theme =>
  get(theme, "programa.template.header.menu.navbar.activeColor");
const getHoverStyle = theme =>
  get(theme, "programa.template.header.menu.navbar.css[ > a:hover]", {});
const getDrawerStyle = theme =>
  get(theme, "programa.template.header.menu.navbar.css", {});
export default theme => ({
  positionFixed: {
    top: "unset",
    left: "unset",
    right: "unset",
    position: "static"
  },
  root: {
    flexGrow: 1
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    minHeight: "100vh"
  },
  paperContent: {
    boxShadow: "none",
    backgroundColor: "transparent",
    borderRadius: 0,
    "& > div:first-child": {
      borderRadius: "4px 4px 0 0"
    },
    marginBottom: theme.spacing.unit * 2,
    "& > div": {
      maxWidth: "1200px",
      margin: "0px auto"
    },
    flex: "1 0 auto"
  },
  footer: {
    // backgroundColor: theme.palette.quinary.main
    backgroundColor: "none"
  },
  appBar: {
    // position: "absolute",
    overflow: "hidden",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
    // marginLeft: drawerWidth
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12
  },
  hide: {
    display: "none"
  },
  drawerPaper: {
    zIndex: 999999,
    position: "absolute",
    height: "100%",
    width: drawerWidth,
    ...getDrawerStyle(theme),
    backgroundColor: `${getDrawerStyle(theme).backgroundColor}!important`
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    display: "flex",
    flexDirection: "column"
    // marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  active: {
    "& span": {
      fontWeight: "bold",
      marginBottom: "5px",
      borderBottom: `${getActiveColor(theme)}  2px solid`
    }
  },
  link: {
    width: "100%",
    margin: "auto 15px",
    color: "inherit" /* blue colors for links too */,
    textDecoration: "inherit" /* no underline */,
    "&:hover": {
      textDecoration: "inherit",
      "& span": {
        color: theme.palette.secondary.main
      }
    }
    // "&:hover": { color: "MEDIUMORCHID" }
  },
  listItem: {
    "&:hover": {
      backgroundColor: `${getHoverStyle(theme).backgroundColor} !important`
    },
    "&:hover $listItemText": {
      color: `${getHoverStyle(theme).color} !important`
    }
  },
  listItemText: {},
  container: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    background:
      get(theme, "backgrounds.generalContainer.backgroundType") === "url"
        ? `url(${get(theme, "backgrounds.generalContainer.backgroundImage")})`
        : get(theme, "backgrounds.generalContainer.backgroundColor", "#fff")
  }
});
