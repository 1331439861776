export const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  columnWidth80: {
    maxWidth: "80px",
    minWidth: "80px"
  },
  columnWidth100: {
    maxWidth: "100px"
  },
  columnWidth300: {
    minWidth: "300px"
  },
  border: {
    borderRight: "1px solid #ccc"
  }
});
