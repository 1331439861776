import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { Grid, Button, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CampanaGraphQL from "js/graphql/resolvers/campana.resolver";
import PerformanceCampanaGraphQL from "js/graphql/resolvers/campanaPerformance.resolver";
import Collapse from "js/components/Shared/Collapsable";
import { _t } from "js/utils/TranslationService";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import SplashScreen from "js/pages/SplashScreen";
import PerformanceFilters from "./components/filters";
import Fila from "js/components/Fila";
import CampanasLista from "./CampanasLista";
import { getBannersCampanas } from "../../utils/helpers/FacturaPorPuntos.helpers.js";
class Performance extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expandedFilters: false,
      idsUsuarios: [],
      campanas: [],
      infoPagina: {},
      isMiPerformance: AuthService.hasPerms([Roles.CAMPANA_MI_PERFORMANCE_R]),
      isPerformance: AuthService.hasPerms([Roles.CAMPANA_PERFORMANCE_R]),
      idPrograma: parseInt(window.localStorage.getItem("idPrograma")),
      filters: {
        idPrograma: parseInt(window.localStorage.getItem("idPrograma")),
        page: 1,
        order: "desc",
        orderBy: "fechaInicio"
      },
      showSplash: true,
      onSearch: false,
      offset: 0,
      showProgress: false,
      page: 1,
      parametrosVisuales: {},
      isQueryCampanas: true
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { idPrograma } = this.state;
    let { filters } = this.state;
    await client
      .query({
        query: CampanaGraphQL.queries.getParametrosCampana,
        fetchPolicy: "network-only",
        variables: {
          idPrograma
        }
      })
      .then(res => {
        const parametros = res.data.getParametrosCampana;
        if (parametros) {
          const excluded = ["admin", "client"];
          const parametrosVisuales = JSON.parse(parametros.parametrosVisuales);

          let banners = {};

          if (parametrosVisuales && parametrosVisuales.banners) {
            banners = getBannersCampanas(parametrosVisuales.banners, excluded);
            parametrosVisuales.banners = banners
              ? banners
              : parametrosVisuales.banners;
          }

          filters.rowsPerPage = parametrosVisuales.campanaPorPagina
            ? parametrosVisuales.campanaPorPagina
            : 2;
          this.setState(
            {
              parametrosVisuales: parametrosVisuales,
              filters
            },
            () => {
              const { filters } = this.state;
              this.fetchCampanas(filters);
              this.clearFilters();
            }
          );
        }
      });
  }

  async fetchCampanas(filters) {
    const { client } = this.props;
    const {
      page,
      parametrosVisuales,
      onSearch,
      campanas,
      isPerformance
    } = this.state;

    let queryGetCampanas = CampanaGraphQL.queries.getPaginaCampanas;

    if (!isPerformance) {
      queryGetCampanas = PerformanceCampanaGraphQL.queries.getMisCampanas;
      filters.pagina = {
        numeroPagina: page,
        cantidadPorPagina: parametrosVisuales.campanaPorPagina
          ? parametrosVisuales.campanaPorPagina
          : 2
      };

      delete filters.page;
      delete filters.rowsPerPage;
    }

    if (filters.hasOwnProperty("idUsuario")) {
      queryGetCampanas =
        PerformanceCampanaGraphQL.queries.getPerformanceCampanas;
      this.setState({ isQueryCampanas: false });
    }

    if (
      (filters.hasOwnProperty("idCampana") ||
        filters.hasOwnProperty("fechaInicio") ||
        filters.hasOwnProperty("fechaInicioHasta")) &&
      !isPerformance
    ) {
      queryGetCampanas =
        PerformanceCampanaGraphQL.queries.getMiPerformanceCampanas;
      this.setState({ isQueryCampanas: false });
      filters.idUsuario = parseInt(window.localStorage.getItem("idUsuario"));
      filters.page = filters.pagina.numeroPagina;
      filters.rowsPerPage = filters.pagina.cantidadPorPagina;
      delete filters.pagina;
    }

    if (Object.keys(parametrosVisuales).length !== 0) {
      await client
        .query({
          query: queryGetCampanas,
          fetchPolicy: "network-only",
          variables: filters
        })
        .then(res => {
          const { isQueryCampanas } = this.state;
          let campanasPerformance = [];
          let nuevasCampanas = [];
          let infoPaginaUpdated = {};

          if (!isQueryCampanas) {
            campanasPerformance = isPerformance
              ? res.data.getPerformanceCampanas.itemsPagina.map(
                  item => item.campana
                )
              : res.data.getMiPerformanceCampanas.itemsPagina.map(
                  item => item.campana
                );
          }

          if (onSearch) {
            if (!isQueryCampanas) {
              nuevasCampanas = campanasPerformance;
              infoPaginaUpdated = isPerformance
                ? res.data.getPerformanceCampanas.infoPagina
                : res.data.getMiPerformanceCampanas.infoPagina;
            } else {
              nuevasCampanas = isPerformance
                ? res.data.getCampanas.itemsPagina
                : res.data.getMisCampanas.itemsPagina;

              infoPaginaUpdated = isPerformance
                ? res.data.getCampanas.infoPagina
                : res.data.getMisCampanas.infoPagina;
            }
          } else {
            nuevasCampanas = isPerformance
              ? res.data.getCampanas.itemsPagina
              : res.data.getMisCampanas.itemsPagina;

            infoPaginaUpdated = isPerformance
              ? res.data.getCampanas.infoPagina
              : res.data.getMisCampanas.infoPagina;
          }

          let campanasUpdated = [];
          if (campanas.length === 0) {
            campanasUpdated = nuevasCampanas;
          } else {
            campanasUpdated = campanas.concat(nuevasCampanas);
          }

          this.setState({
            filters,
            campanas: campanasUpdated,
            infoPagina: infoPaginaUpdated,
            showSplash: false,
            showProgress: false,
            expandedFilters: false
          });
        });
    }
  }

  submitValue = e => {
    const obj = {};
    if (e.target) {
      obj[e.target.name] = e.target.value;
    } else if (e.value) {
      obj.idUsuario = e.value;
    }

    this.setState(({ filters }) => ({
      filters: { ...filters, ...obj }
    }));
  };

  clearFilters = e => {
    const { idPrograma, parametrosVisuales } = this.state;
    let { filters, infoPagina, page } = this.state;
    infoPagina = {};
    filters = {
      idPrograma,
      page: 1,
      rowsPerPage: parametrosVisuales.campanaPorPagina
        ? parametrosVisuales.campanaPorPagina
        : 2,
      order: "desc",
      orderBy: "fechaInicio"
    };
    page = 1;
    this.setState(
      {
        page,
        filters,
        infoPagina,
        onSearch: false,
        isQueryCampanas: true,
        campanas: []
      },
      () => {
        const { filters } = this.state;
        this.fetchCampanas(filters);
      }
    );
  };

  search = () => {
    let { filters, page } = this.state;
    page = 1;
    filters.page = 1;
    this.setState(
      {
        page,
        filters,
        onSearch: true,
        showSplash: true,
        expandedFilters: false,
        expandedCampana: false,
        campanas: []
      },

      () => {
        this.fetchCampanas(filters);
      }
    );
  };

  handleFiltersCollapse = () => {
    this.setState(prevState => ({
      expandedFilters: !prevState.expandedFilters
    }));
  };

  handleClick(offset) {
    this.setState({ offset });
  }

  handleSeeMore = () => {
    let { filters, page } = this.state;
    page = page + 1;
    filters.page = filters.page + 1;
    this.setState({ showProgress: true, filters, page }, () => {
      this.fetchCampanas(filters);
    });
  };

  renderContenido(style) {
    const { classes } = this.props;
    const {
      campanas,
      infoPagina,
      expandedFilters,
      idsUsuarios,
      filters,
      isMiPerformance,
      isPerformance,
      showSplash,
      idPrograma,
      showProgress,
      parametrosVisuales,
      onSearch
    } = this.state;

    return (
      <Fragment>
        {parametrosVisuales.banners &&
          parametrosVisuales.banners.visible && (
            <Fila
              {...parametrosVisuales.banners}
              baseLink={"Banners Performance"}
            />
          )}
        <Collapse
          search={this.search.bind(this)}
          clear={this.clearFilters.bind(this)}
          expanded={expandedFilters}
          onChange={this.handleFiltersCollapse}
          title="Filtros"
          style={{
            marginTop:
              parametrosVisuales.banners && parametrosVisuales.banners.visible
                ? "20px"
                : "0px",
            marginBottom: "20px"
          }}
          withShadow={parametrosVisuales.containerShadow ? true : false}
          parametrosVisuales={{
            color: `${parametrosVisuales.colorNombreCampana}`,
            fontSize: `${parametrosVisuales.sizeNombreCampana}px`,
            fontWeight: "700",
            fontFamily: `${parametrosVisuales.fontFamily}`,
            colorFlecha: parametrosVisuales.colorFlecha,
            sizeFlecha: parametrosVisuales.sizeFlecha
          }}
        >
          <PerformanceFilters
            value={filters}
            submitValue={this.submitValue.bind(this)}
            showUserField={isMiPerformance ? false : true}
            idsUsuarios={idsUsuarios}
            idPrograma={idPrograma}
            isPerformance={isPerformance}
          />
        </Collapse>
        {showSplash && <SplashScreen />}

        {!showSplash &&
          campanas && (
            <CampanasLista
              classes={classes}
              parametrosVisuales={parametrosVisuales}
              campanas={campanas}
              isPerformance={isPerformance}
              infoPagina={infoPagina}
              isMiPerformance={isMiPerformance}
              idPrograma={idPrograma}
              onSearch={onSearch}
              idUsuario={filters.idUsuario ? filters.idUsuario : null}
            />
          )}
        {infoPagina.numeroPagina < infoPagina.cantidadPaginas && (
          <Grid className={classes.seeMoreContainer} item xs={12}>
            <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              style={{ width: "250px", marginBottom: "20px" }}
              onClick={this.handleSeeMore}
            >
              {showProgress && (
                <CircularProgress
                  size={20}
                  thickness={4}
                  color="primary"
                  variant="indeterminate"
                />
              )}
              {!showProgress && _t("Ver más campañas")}
            </Button>
          </Grid>
        )}
      </Fragment>
    );
  }

  render() {
    const { parametrosVisuales } = this.state;

    const style = {
      padding: parametrosVisuales.paddingGeneral
        ? `${parametrosVisuales.paddingGeneral}px`
        : "20px 0",
      maxWidth: parametrosVisuales.anchoGeneral
        ? `${parametrosVisuales.anchoGeneral}px`
        : "1000px",
      marginTop: parametrosVisuales.margenSuperior
        ? `${parametrosVisuales.margenSuperior}px`
        : "20px",
      marginBottom: parametrosVisuales.margenInferior
        ? `${parametrosVisuales.margenInferior}px`
        : "20px"
    };

    return (
      <Fragment>
        {parametrosVisuales.generalContainerShadow ? (
          <Paper elevation={1} style={style}>
            {this.renderContenido(style)}
          </Paper>
        ) : (
          <div style={style}>{this.renderContenido(style)}</div>
        )}
      </Fragment>
    );
  }
}

Performance.propTypes = {
  classes: PropTypes.object
};

Performance.defaultProps = {};

Performance.displayName = "Performance";

export default Performance;
