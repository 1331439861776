import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Grid, FormControl, TextField } from "@material-ui/core";

import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";

class advancedFilter extends PureComponent {
  render() {
    const { value, submitValue, classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"username"}
              name={"username"}
              label={"Usuario"}
              type="search"
              value={value["username"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaDesde"}
              name={"fechaDesde"}
              label="Desde"
              input={{
                onChange: date => {
                  const d = moment(date.toISOString())
                    .startOf("month")
                    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

                  const fcHasta = moment(d.toISOString())
                    .add(2, "months")
                    .endOf("month");

                  submitValue({
                    target: {
                      name: "fechaHasta",
                      value: fcHasta.toISOString()
                    }
                  });

                  return submitValue({
                    target: {
                      name: "fechaDesde",
                      value: d.toISOString()
                    }
                  });
                },
                value: value["fechaDesde"] ? moment(value["fechaDesde"]) : null
              }}
              format={"DD/MM/YYYY"}
              views={["year", "month"]}
              autoOk={true}
              maxDate={moment()}
              startOfDate
            />
          </FormControl>
        </Grid>
        {/* if (startOfDate)
          date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        if (endOfDate)
          date.set({ hour: 23, minute: 59, second: 59, millisecond: 59 }); */}
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaHasta"}
              name={"fechaHasta"}
              label="Hasta"
              input={{
                onChange: date => {
                  const d = moment(date.toISOString())
                    .endOf("month")
                    .set({ hour: 23, minute: 59, second: 59, millisecond: 59 });

                  submitValue({
                    target: {
                      name: "fechaHasta",
                      value: d.toISOString()
                    }
                  });
                },
                value: value["fechaHasta"] ? moment(value["fechaHasta"]) : null
              }}
              format={"DD/MM/YYYY"}
              views={["year", "month"]}
              autoOk={true}
              minDate={moment(value["fechaDesde"])}
              maxDate={moment(value["fechaDesde"]).add(2, "months")}
              startOfDate
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
