import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaSolicitudCatalogoCodigos/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/catalogoCodigos.resolver";
import { USER_ROLES as Roles } from "js/models/User";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Receipt from "@material-ui/icons/Receipt";
import {
  columnas,
  misColumnas
} from "js/components/TablaSolicitudCatalogoCodigos/columns";
import { viewSolicitudAction } from "js/components/Shared/Tabla/ActionCells";

const defaultHeader = (id, btnsNextTitle, isMisSolicitudes) => {
  const header = {
    title: "Solicitudes de códigos",
    btnsNextTitle
  };
  if (!isMisSolicitudes) {
    header.export = {
      path: "solicitudesBeneficios",
      perms: [Roles.SOLICITUD_CATALOGO_CODIGO_R],
      tooltip: "Exportar solicitudes de catálogo"
    };
  } else {
    header.export = {
      path: "misSolicitudesBeneficios",
      perms: [Roles.MIS_SOLICITUDES_CATALOGO_CODIGO_R],
      tooltip: "Exportar mis solicitudes"
    };
  }
  return header;
};

const defaultSort = { order: "desc", orderBy: "fecha" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    let storage = "";
    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;
    const isMisSolicitudes = window.location.pathname.indexOf("mis") !== -1;
    let columnData = null;

    if (isMisSolicitudes) {
      columnData = misColumnas;
    } else {
      columnData = columnas;
    }

    const btnsNextTitle = [];
    const actionsCustom = [];
    if (inBackOffice) {
      storage = "FiltroSoliciutdBeneficioAdmin";
      btnsNextTitle.push({
        btn: (
          <Tooltip title="Beneficios">
            <IconButton
              color="primary"
              onClick={() =>
                this.props.history.push(`/admin/programa/${id}/catalogoCodigos`)
              }
            >
              <Receipt />
            </IconButton>
          </Tooltip>
        ),
        perms: [Roles.CATALOGO_CODIGO_R]
      });
    } else {
      if (isMisSolicitudes) {
        const view = params => ({
          ...viewSolicitudAction({
            ...params,
            history: this.props.history,
            perms: [Roles.MIS_SOLICITUDES_CATALOGO_CODIGO_R]
          })
        });
        actionsCustom.push(view);
        storage = "FiltroMisSoliciutdBeneficios";
      } else {
        storage = "FiltroSoliciutdBeneficios";
      }
    }

    return (
      <Table
        storage={storage}
        query={
          AppQraphQL.queries.getMisCatalogoCodigosSolicitudes
          // isMisSolicitudes
          //   ? AppQraphQL.queries.getMisCatalogoCodigosSolicitudes
          //   : AppQraphQL.mutations.solicitarCatalogoCodigos
        }
        defaultHeader={defaultHeader(id, btnsNextTitle, isMisSolicitudes)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={
          data => data.getMisCatalogoCodigosSolicitudes
          // isMisSolicitudes
          //   ? data.getMisCatalogoCodigosSolicitudes
          //   : data.solicitarCatalogoCodigos
        }
        actionsCustom={actionsCustom}
        pathId={"idSolicitud"}
      />
    );
  }
}

export default Tabla;
