import { USER_ROLES as Roles } from "js/models/User";
export default [
  Roles.USUARIO_R,
  Roles.CANJE_R,
  Roles.UBICACION_R,
  Roles.UBICACION_W,
  Roles.CATEGORIA_W,
  Roles.CATEGORIA_R,
  Roles.PROGRAMA_W,
  Roles.DEFINICION_PERFIL_R,
  Roles.IDIOMA_R
];
