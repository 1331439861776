import gql from "graphql-tag";

export const getTargetsUsuario = gql`
  query getTargetsUsuario(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: TargetUsuarioColumn!
    $idPrograma: Int
    $idCampana: ID
    $idObjetivo: ID
    $idUsuario: Int
    $target: Float
  ) {
    getTargetsUsuario(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      targetUsuarioOrder: { column: $orderBy, order: $order }
      targetUsuarioLike: {
        idPrograma: $idPrograma
        idCampana: $idCampana
        idObjetivo: $idObjetivo
        idUsuario: $idUsuario
        target: $target
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        fechaCreacion
        programa {
          idPrograma
          nombre
        }
        campanaOP {
          _id
          nombre
        }
        idObjetivo
        objetivo {
          objetivo
          puntos
          formaSumar
        }
        usuario {
          idUsuario
          username
        }
        target
        estado
      }
    }
  }
`;

export const updateTarget = gql`
  mutation updateTargetUsuario($target: TargetUsuarioUpdate) {
    updateTargetUsuario(targetUsuario: $target) {
      _id
    }
  }
`;

export const deleteTarget = gql`
  mutation deleteTargetUsuario($id: ID!) {
    deleteTargetUsuario(id: $id)
  }
`;

export default {
  queries: {
    getTargetsUsuario
  },
  mutations: {
    updateTarget,
    deleteTarget
  }
};
