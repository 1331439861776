import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import PaisFilter from "js/components/Shared/TablaFiltro/paisFilter.js";
import { TypeAhead } from "js/components/Shared/Form/Fields";
import ProgramaGraphQL from "js/graphql/resolvers/programa.resolver.js";
import { withApollo } from "react-apollo";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";

class advancedFilter extends PureComponent {
  state = { data: {} };
  handleChange = key => select => {
    this.props.submitValue({ target: { name: key, value: select.value } });
  };

  componentWillReceiveProps(props) {
    const { client } = props;
    client
      .query({
        query: ProgramaGraphQL.queries.getProgramasFilter,
        fetchPolicy: "cache-first",
        errorPolicy: "all"
      })
      .then(res => {
        this.setState({
          data: res.data.getProgramasToFilter
        });
      });
  }
  render() {
    const { value, submitValue, classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TextField
              key={"nombre"}
              name={"nombre"}
              label={"Nombre"}
              type="search"
              value={value["nombre"] || ""}
              onChange={e => submitValue(e)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <InputLabel id="tipo-simple" htmlFor="tipo-simple">
              Tipo
            </InputLabel>
            <Select
              value={value["tipo"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "tipo",
                id: "tipo"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"CLIENTE"}>Cliente</MenuItem>
              <MenuItem value={"EX-CLIENTE"}>Ex Cliente</MenuItem>
              <MenuItem value={"DEMO"}>Demo</MenuItem>
              <MenuItem value={"PRE-LANZAMIENTO"}>Pre Lanzamiento</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <InputLabel id="estado-simple" htmlFor="estado-simple">
              Estado
            </InputLabel>
            <Select
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"ACTIVO"}>Activo</MenuItem>
              <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              placeholder={"Empresa"}
              label={"Empresa"}
              suggestions={this.state.data}
              path={{
                path: ["itemsPagina"],
                value: "nombreFantasia",
                label: "nombreFantasia"
              }}
              onChange={this.handleChange("nombreFantasia")}
              value={value["nombreFantasia"]}
              withTodos
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <TypeAhead
              placeholder={"CUIT"}
              label={"CUIT"}
              suggestions={this.state.data}
              path={{ path: ["itemsPagina"], value: "cuit", label: "cuit" }}
              onChange={this.handleChange("cuit")}
              value={value["cuit"]}
              withTodos
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <PaisFilter
            classes={classes}
            value={value}
            submitValue={submitValue}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              name={"fechaInicio"}
              label="Fecha de activación"
              input={{
                onChange: v =>
                  submitValue({
                    target: { name: "fechaInicio", value: v.toISOString() }
                  }),
                value: value["fechaInicio"] || null
              }}
              startOfDate
              autoOk
              maxDate={moment()}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl className={classes.formControl}>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              name={"fechaBaja"}
              label="Fecha de desactivación"
              input={{
                onChange: v =>
                  submitValue({
                    target: { name: "fechaBaja", value: v.toISOString() }
                  }),
                value: value["fechaBaja"] || null
              }}
              endOfDate
              autoOk
              maxDate={moment()}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withApollo(withStyles(styles)(advancedFilter));
