export default theme => ({
  textField: {
    margin: `0 ${theme.spacing.unit}px`,
    width: 40
  },
  image: {
    width: "100%"
  },
  link: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  button: {
    minWidth: 32,
    fontSize: 12,
    padding: "9px 20px",
    marginBottom: 4
  },
  detailWrapper: {
    backgroundColor: "rgb(255, 255, 255)",
    padding: "40px 100px 40px 60px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 15px"
    }
  },
  sliderContainer: {
    overflow: "hidden",
    borderRadius: 4
  },
  carousel: {
    height: "100%"
  },
  points: {
    fontWeight: 600,
    textTransform: "uppercase"
  },
  divider: {
    marginBottom: "1.35em"
  },
  productDescription: {
    marginBottom: "1.35em"
  },
  sectionContainer: {
    marginBottom: "30px"
  },
  cucardaContainer: {
    position: "relative"
  },
  cucardaProductDetail: {
    top: "20px",
    left: "20px"
  },
  increaseDecreaseBtnContainer: {
    border: "1px solid rgb(227,227,227)",
    borderRadius: 4,
    textAlign: "center"
  },
  increaseDecreaseBtn: {
    width: "100%",
    height: 34,
    border: "none",
    background: "rgb(237,237,237)",
    fontWeight: "bold",
    fontSize: 18
  },
  counter: {
    width: "90%",
    textAlign: "center",
    "-moz-appearance": "textfield",
    border: "none"
  },
  seccionSolicitud: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  solicitudar: {
    // marginLeft: theme.spacing.unit
  },
  sucursalesList: {
    height: 288,
    [theme.breakpoints.down("sm")]: {
      height: "fit-content"
    },
    overflowY: "auto"
  }
});
