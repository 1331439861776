import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { cx, css } from "emotion";
import newItems from "js/pages/TemplateBuilder2/Components/NewItems";
import LinkContentWrapper from "js/hoc/LinkContentWrapper";

const { newBase } = newItems;

const Base = props => {
  const {
    classes,
    contenido,
    contenido: { caption = {} },
    belongsToSlider = false,
    baseLink
  } = props;
  let captionStyle = {};
  if (caption.degradado && caption.colorDegradado)
    captionStyle = {
      backgroundImage: `linear-gradient(0deg, ${
        caption.colorDegradado
      } 59%, rgba(0,0,0,0.00) 100%)`
    };

  return (
    <div
      className={cx(
        {
          [classes.base]: !belongsToSlider
        },
        css({ ...props.contenido.css })
      )}
    >
      <LinkContentWrapper
        classes={props.classes}
        link={contenido.link}
        target={contenido.target}
        track
        baseLink={baseLink}
        origen={"link-en-banner"}
      >
        <img
          className={cx(classes.imageURL, css({ ...props.contenido.css }))}
          alt={`${get(contenido, "texto.contenido", "")}`}
          src={`${contenido.src}`}
        />
        {((contenido.texto && contenido.texto.contenido) ||
          (contenido.subtexto && contenido.subtexto.contenido)) && (
          <div className={cx(classes.caption, css(captionStyle))}>
            {contenido.texto &&
              contenido.texto.contenido && (
                <p
                  className={cx(
                    classes.texto,
                    css({ ...props.contenido.texto.css })
                  )}
                >
                  {contenido.texto.contenido}
                </p>
              )}
            {contenido.subtexto &&
              contenido.subtexto.contenido && (
                <p
                  className={cx(
                    classes.subtexto,
                    css({ ...get(contenido, "subtexto.css", {}) })
                  )}
                >
                  {get(contenido, "subtexto.contenido", "")}
                </p>
              )}
          </div>
        )}
      </LinkContentWrapper>
    </div>
  );
};

Base.propTypes = {
  classes: PropTypes.object.isRequired
};

Base.defaultProps = {
  contenido: { ...newBase }
};

Base.displayName = "base";

export default Base;
