import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { InputTextField } from "js/components/Shared/Form/Fields";
import { isValidUrl, isValidUrlOrEmpty } from "js/helpers/validations";

const Encuesta = ({ disabled }) => {
  return (
    <Fragment>
      <Grid item xs={12}>
        <InputTextField
          name="encuesta.urlEdicion"
          validate={isValidUrl}
          label="URL para la edición"
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <InputTextField
          name="encuesta.url"
          validate={isValidUrl}
          label="URL de la encuesta"
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <InputTextField
          name="encuesta.urlReporte"
          validate={isValidUrlOrEmpty}
          label="URL del reporte"
          disabled={disabled}
        />
      </Grid>
    </Fragment>
  );
};

Encuesta.displayName = "ENCUESTA";

export default Encuesta;
