import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import AuthService from "js/utils/AuthService";
import Informacion from "js/pages/Informacion";

const Privado = props => {
  if (AuthService.loggedIn(props.isLoggedIn)) return <Informacion />;
  else return <Redirect to="/signin" />;
};

Privado.propTypes = {
  classes: PropTypes.object
};

Privado.defaultProps = {};

Privado.displayName = "Privado";

export default Privado;
