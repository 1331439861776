import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { reduxForm, formValueSelector, change, getFormMeta } from "redux-form";
import {
  Grid,
  FormControl,
  Button,
  Typography,
  Tooltip,
  Fab
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import AddIcon from "@material-ui/icons/Add";
import get from "lodash/get";
import { TextField } from "redux-form-material-ui";

import { styles } from "js/components/Shared/Form/styles.js";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import ImageUploader, { ItemImages } from "js/components/Shared/ImageUploader";
import {
  InputTextField,
  InputNumberField,
  ListField,
  CheckboxField,
  InputTextarea,
  TreeField,
  SwitchField,
  EmailField
} from "js/components/Shared/Form/Fields";
import { maxLength } from "js/helpers/validations";
import { renderFecha } from "js/utils/Helper";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

const maxLength255 = maxLength(255);
const maxLength510 = maxLength(510);

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fechaValidezDesde: null,
      fechaValidezHasta: null,
      fechaVencimiento: null
    };
  }

  onChangePais = selectedNodes => {
    this.props.onChangePais({ paisesSeleccionados: selectedNodes });
  };
  onChangeRubro = selectedNodes => {
    this.props.onChangeRubro({ rubrosSeleccionados: selectedNodes });
  };
  onChangeSegmento = selectedNodes => {
    this.props.onChangeSegmento({ segmentosSeleccionados: selectedNodes });
  };

  handleFechaValidezDesdeChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaValidezDesde: date
    });
  };

  handleFechaValidezHastaChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaValidezHasta: date
    });
  };

  handleFechaVencimientoChange = key => date => {
    this.props.handleChangeFecha(key)(date);
    this.setState({
      fechaVencimiento: date
    });
  };

  addHipervinculo = field => () => {
    this.props.changeFieldValue(
      field,
      `${
        this.props[field]
      } <a target="_blank" href="https://url.com">Texto del link</a>`
    );
  };

  render() {
    const {
      handleSubmit,
      submitting,
      classes,
      history,
      errors,
      disabled,
      id,
      idBeneficio,
      initialValues,
      tipos,
      openDialog,
      logo,
      onChangeLogo,
      onRemoveLogo,
      onChangeImage,
      onRemoveImage,
      imagenes,
      stockInfinito,
      tipoValidez,
      segmentos,
      rubros,
      paises
    } = this.props;

    return (
      <form className={classes.container} onSubmit={handleSubmit}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {initialValues.nombre ? "Editar beneficio" : "Nuevo beneficio"}
            </Typography>
          </Grid>

          {errors &&
            errors.length !== 0 && (
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors.map(e => (
                    <li>{e.message}</li>
                  ))}
                </ul>
              </Grid>
            )}

          {/* Nombre */}
          <Grid item xs={12} md={6}>
            <FormControl className={classes.field}>
              <InputTextField
                name="nombre"
                label={"Nombre"}
                disabled={disabled}
                required
              />
            </FormControl>
          </Grid>

          {/* Estado */}
          <Grid item xs={12} md={2}>
            <ListField
              name="estado"
              label="Estado"
              options={[
                { label: "Activo", value: "ACTIVO" },
                { label: "Inactivo", value: "INACTIVO" }
              ]}
              disabled={disabled}
              required
            />
          </Grid>

          {/* Tipo Beneficio */}
          <Grid item xs={10} md={3}>
            <ListField
              name="idTipo"
              label="Tipo beneficio"
              options={tipos}
              disabled={disabled}
              required
            />
          </Grid>

          <Grid item xs={2} md={1}>
            <Tooltip title={"Agregar tipo"}>
              <span>
                <Fab
                  aria-label={"Agregar tipo"}
                  onClick={() => openDialog()}
                  disabled={disabled}
                  style={{ marginLeft: "10px" }}
                  size="small"
                  color="primary"
                >
                  <AddIcon />
                </Fab>
              </span>
            </Tooltip>
          </Grid>

          {/* Descripciones */}
          <Grid item xs={12} style={{ marginTop: "-40px" }}>
            <Button
              className={classes.iconHipervinculo}
              onClick={this.addHipervinculo("copete")}
            >
              <InsertLinkIcon />
            </Button>
            <InputTextField
              name="copete"
              label="Descripción corta (máx. 255 caracteres)"
              disabled={disabled}
              required
              customValidations={[maxLength255]}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: "-40px" }}>
            <Button
              className={classes.iconHipervinculo}
              onClick={this.addHipervinculo("descripcion")}
            >
              <InsertLinkIcon />
            </Button>
            <InputTextarea
              name="descripcion"
              label="Descripción larga (máx. 510 caracteres)"
              disabled={disabled}
              multiple
              required
              customValidations={[maxLength510]}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={24}>
              <Grid item xs={12} md={4}>
                <FormControl className={classes.field}>
                  <DatePicker
                    name={"fechaVencimiento"}
                    dateFormat="DD/MM/YYYY"
                    label={"Fecha de vencimiento"}
                    autoOk
                    disabled={disabled}
                    input={{
                      value:
                        this.state.fechaVencimiento !== "" &&
                        this.state.fechaVencimiento
                          ? new Date(this.state.fechaVencimiento)
                          : initialValues.fechaVencimiento
                            ? initialValues.fechaVencimiento
                            : null,
                      onChange: this.handleFechaVencimientoChange(
                        "fechaVencimiento"
                      )
                    }}
                    minDate={moment()
                      .add(1, "d")
                      .toDate()}
                    required={false}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Stock */}
          <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography style={{ lineHeight: "1.2em" }} variant="subtitle1">
                  Stock
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckboxField
                  name="stockInfinito"
                  label="Infinito"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl className={classes.field}>
                  <InputNumberField
                    name="stock"
                    label="Stock"
                    disabled={disabled || stockInfinito}
                    required={!stockInfinito}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl className={classes.field}>
                  <InputNumberField
                    name="stockSeguridad"
                    label="Stock de seguridad"
                    disabled={disabled || stockInfinito}
                    required={!stockInfinito}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Validez */}
          <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography style={{ lineHeight: "1.2em" }} variant="subtitle1">
                  Validez
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <ListField
                  name="tipoValidez"
                  label="Tipo de validez"
                  options={[
                    { label: "Siempre", value: "SIEMPRE" },
                    { label: "Por fechas", value: "POR_FECHAS" },
                    { label: "Por dias", value: "POR_DIAS" }
                  ]}
                  disabled={disabled}
                  required
                />
              </Grid>
              {tipoValidez === "POR_FECHAS" && (
                <Fragment>
                  <Grid item xs={12} md={4}>
                    <FormControl className={classes.field}>
                      <DatePicker
                        name={"fechaValidezDesde"}
                        dateFormat="DD/MM/YYYY"
                        label={"Válido desde"}
                        autoOk
                        disabled={disabled}
                        input={{
                          value:
                            this.state.fechaValidezDesde !== "" &&
                            this.state.fechaValidezDesde
                              ? new Date(this.state.fechaValidezDesde)
                              : initialValues.fechaValidezDesde
                                ? initialValues.fechaValidezDesde
                                : null,
                          onChange: this.handleFechaValidezDesdeChange(
                            "fechaValidezDesde"
                          )
                        }}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl className={classes.field}>
                      <DatePicker
                        name={"fechaValidezHasta"}
                        dateFormat="DD/MM/YYYY"
                        label={"Válido hasta"}
                        autoOk
                        disabled={disabled}
                        input={{
                          value:
                            this.state.fechaValidezHasta !== "" &&
                            this.state.fechaValidezHasta
                              ? new Date(this.state.fechaValidezHasta)
                              : initialValues.fechaValidezHasta
                                ? initialValues.fechaValidezHasta
                                : null,
                          onChange: this.handleFechaValidezHastaChange(
                            "fechaValidezHasta"
                          )
                        }}
                        minDate={
                          this.state.fechaValidezDesde
                            ? moment(this.state.fechaValidezDesde).add(1, "d")
                            : initialValues.fechaValidezDesde
                              ? moment(initialValues.fechaValidezDesde).add(
                                  1,
                                  "d"
                                )
                              : new Date()
                        }
                        required
                      />
                    </FormControl>
                  </Grid>
                </Fragment>
              )}
              {tipoValidez === "POR_DIAS" && (
                <Grid item xs={12} md={4}>
                  <FormControl className={classes.field}>
                    <InputNumberField
                      name="diasValidez"
                      label="Período (días)"
                      disabled={disabled}
                      required={tipoValidez === "POR_DIAS"}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Segmentos y cucarda */}
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Visualización</Typography>
              </Grid>
              {segmentos && (
                <Grid item xs={12} md={6}>
                  <TreeField
                    label="Segmentos"
                    data={segmentos}
                    disabled={disabled}
                    onChange={this.onChangeSegmento}
                    withTodos
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <ListField
                  name="cucarda"
                  label="Cucarda"
                  options={[
                    { label: "Ninguna", value: null },
                    { label: "Tipo 1", value: "TIPO_1" },
                    { label: "Tipo 2", value: "TIPO_2" },
                    { label: "Tipo 3", value: "TIPO_3" },
                    { label: "Tipo 4", value: "TIPO_4" }
                  ]}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Rubros */}
          <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Categorización</Typography>
              </Grid>
              {rubros && (
                <Grid item xs={12}>
                  <TreeField
                    label="Categoría/Subcategoría"
                    data={rubros}
                    disabled={disabled}
                    onChange={this.onChangeRubro}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Ubicaciones */}
          <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Ubicación</Typography>
              </Grid>

              {paises && (
                <Grid item xs={12}>
                  <TreeField
                    label="País/Zona/Localidad"
                    data={paises}
                    disabled={disabled}
                    onChange={this.onChangePais}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Imagenes y logo */}
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Logo e Imagenes</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="subtitle3"
                  style={{ marginBottom: "10px" }}
                >
                  Logo
                </Typography>
                <ImageUploader
                  onChangeImage={onChangeLogo}
                  disableClick={true}
                  disabled={disabled || logo.length === 1}
                  medidasRecomendadas={"556 x 314"}
                >
                  <ItemImages
                    images={logo}
                    onRemoveImage={onRemoveLogo}
                    disabled={disabled}
                    alt={"logo-beneficio"}
                  />
                </ImageUploader>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="subtitle3"
                  style={{ marginBottom: "10px" }}
                >
                  Imagenes
                </Typography>
                <ImageUploader
                  onChangeImage={onChangeImage}
                  disableClick={true}
                  disabled={disabled}
                  medidasRecomendadas={"556 x 314"}
                >
                  <ItemImages
                    images={imagenes}
                    onRemoveImage={onRemoveImage}
                    disabled={disabled}
                    alt={"imagen-beneficio"}
                  />
                </ImageUploader>
              </Grid>
            </Grid>
          </Grid>

          {/* Resto */}
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  Sucursales, página web, email proveedor y condiciones
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <SwitchField
                  name="tieneSucursales"
                  label="Tiene sucursales"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl className={classes.field}>
                  <InputTextField
                    name="paginaWeb"
                    label="Página web"
                    disabled={disabled}
                    customValidations={[maxLength255]}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl className={classes.field}>
                  <EmailField
                    name="emailProveedor"
                    label="Email proveedor"
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.field}>
                  <InputTextarea
                    name="condiciones"
                    label="Condiciones legales"
                    disabled={disabled}
                    multiple
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Datos de creación */}
          {idBeneficio !== "new" && (
            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Datos de creación</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Usuario creador"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={get(initialValues, "usuarioCreador.username", "--")}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Fecha de creación"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={renderFecha(initialValues.fechaCreacion) || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Usuario modificador"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={get(
                      initialValues,
                      "usuarioModificador.username",
                      "--"
                    )}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Fecha de modificación"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={renderFecha(initialValues.fechaModificacion) || ""}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {errors &&
            errors.length !== 0 && (
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors.map(e => (
                    <li>{e.message}</li>
                  ))}
                </ul>
              </Grid>
            )}

          <Grid className={classes.grid} container>
            <Grid item xs />
            <Grid item xs />
            <Grid className={classes.gridButton} item xs>
              <Button
                type="button"
                variant="outlined"
                size="large"
                color="primary"
                className={classes.button}
                onClick={() => history.push(`/admin/programa/${id}/beneficios`)}
              >
                Cancelar
              </Button>
            </Grid>
            {AuthService.hasPerms([Roles.BENEFICIO_W]) && (
              <Grid className={classes.gridButton} item xs>
                {disabled && (
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    onClick={() =>
                      history.push(
                        `/admin/programa/${id}/beneficios/${idBeneficio}/edit`
                      )
                    }
                  >
                    Editar
                  </Button>
                )}
                {!disabled && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    disabled={submitting}
                  >
                    Guardar
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    );
  }
}

Form = reduxForm({
  form: "formBeneficio",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formBeneficio");

Form = connect(
  state => ({
    formMeta: getFormMeta("formBeneficio")(state),
    stockInfinito: selector(state, "stockInfinito"),
    tipoValidez: selector(state, "tipoValidez"),
    descripcion: selector(state, "descripcion"),
    copete: selector(state, "copete")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formBeneficio", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
