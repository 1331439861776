export default theme => ({
  root: {
    [theme.breakpoints.down(500)]: {
      boxShadow: "none"
    },
    maxWidth: 450,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`
  },
  field: {
    marginBottom: 5
  },
  margin: {
    margin: theme.spacing.unit * 3
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3
  },
  textField: {
    flexBasis: 200
  },
  button: {
    minWidth: "10rem",
    [theme.breakpoints.down(500)]: {
      width: "100%"
    },
    margin: `${theme.spacing.unit * 1.5}px 0`
  },
  titulo: {
    margin: `${theme.spacing.unit * 1.5}px 0`
  },
  descripcion: {
    margin: `${theme.spacing.unit * 1.5}px 0`
  },
  error: {
    color: theme.palette.error.main,
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.9rem",
    marginTop: theme.spacing.unit
  },
  idiomaPosition: {
    textAlign: "right"
  },
  logo: {
    // maxHeight: 100,
    // maxWidth: 300
  }
});
