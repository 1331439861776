import gql from "graphql-tag";

export const getParametrosFacturaPorPuntosById = gql`
  query getParametrosFacturaPorPuntos($idPrograma: Int) {
    getParametrosFacturaPorPuntos(
      ParametrosFacturaPorPuntosLike: { idPrograma: $idPrograma }
    ) {
      key
      label
      visibleRegistro
      requeridoRegistro
      visiblePerfil
      requeridoPerfil
      tipo
      value
      grupo
      placeholder
      disableEdit
    }
  }
`;

export const bulkParametrosFacturaPorPuntos = gql`
  mutation bulkParametrosFacturaPorPuntos(
    $idPrograma: Int!
    $definicionesParametrosFacturaPorPuntos: [DefinicionParametrosFacturaPorPuntosCreateBulk]!
  ) {
    bulkParametrosFacturaPorPuntos(
      idPrograma: $idPrograma
      definicionesParametrosFacturaPorPuntos: $definicionesParametrosFacturaPorPuntos
    ) {
      idPrograma
    }
  }
`;

export default {
  queries: {
    getParametrosFacturaPorPuntosById,
    bulkParametrosFacturaPorPuntos
  }
};
