import React, { PureComponent } from "react";
import Chart from "react-google-charts";
import { withTheme } from "@material-ui/core/styles";
import get from "lodash/get";

class GoogleChart extends PureComponent {
  render() {
    const { theme } = this.props;
    const color = get(theme, "palette.primary.main");
    const { fontFamily: fontName } = get(theme, "typography.h6", {});
    return (
      <Chart
        loader={<div>Loading Chart</div>}
        width={"100%"}
        {...this.props}
        options={{
          titleTextStyle: {
            fontName,
            fontSize: 16,
            bold: false,
            color
          },
          ...this.props.options
        }}
      />
    );
  }
}

GoogleChart.propTypes = {};

GoogleChart.defaultProps = {};

GoogleChart.displayName = "GoogleChart";

export default withTheme()(GoogleChart);
