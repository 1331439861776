import gql from "graphql-tag";

const Idioma = {};

Idioma.fragments = {
  idiomaCompleto: gql`
    fragment idiomaCompleto on IdiomaConEtiqueta {
      idioma {
        _id
        nombre
        idPadre
        nombrePadre
        traducciones {
          key
          value
        }
      }
      etiqueta
    }
  `
};

Idioma.fragments = {
  idioma: gql`
    fragment idioma on IdiomaConEtiqueta {
      idioma {
        _id
        nombre
      }
      etiqueta
    }
  `
};

export default Idioma;
