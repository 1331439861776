import gql from "graphql-tag";

export const getCanalesRetail = gql`
  query getCanalesRetail(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: CanalRetailColumn!
    $idPrograma: Int
    $nombre: String
    $codigo: String
    $estado: Estado
    $localidad: String
    $rutCi: String
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getCanalesRetail(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      canalOrder: { column: $orderBy, order: $order }
      canalLike: {
        idPrograma: $idPrograma
        nombre: $nombre
        codigo: $codigo
        estado: $estado
        localidad: $localidad
        rutCi: $rutCi
      }
      canalRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        codigo
        nombre
        estado
        fechaCreacion
        rutCi
        telefono
        email
        movil
        localidad
        depto
      }
    }
  }
`;

export const getCanalRetailById = gql`
  query getCanalesRetail($canalLike: CanalRetailSearch, $pagina: Pagina) {
    getCanalesRetail(canalLike: $canalLike, pagina: $pagina) {
      itemsPagina {
        _id
        idPrograma
        codigo
        nombre
        descripcion
        estado
        rutCi
        telefono
        email
        movil
        localidad
        depto
      }
    }
  }
`;

export const getCanalesRetailInput = gql`
  query getCanalesRetail($canalLike: CanalRetailSearch) {
    getCanalesRetail(canalLike: $canalLike) {
      itemsPagina {
        label: nombre
        value: _id
      }
    }
  }
`;

export const createCanalRetail = gql`
  mutation createCanalRetail($canal: CanalRetailCreate!) {
    createCanalRetail(canal: $canal) {
      _id
    }
  }
`;

export const updateCanalRetail = gql`
  mutation updateCanalRetail($canal: CanalRetailUpdate!) {
    updateCanalRetail(canal: $canal) {
      _id
    }
  }
`;

export default {
  queries: {
    getCanalesRetail,
    getCanalRetailById,
    getCanalesRetailInput
  },
  mutations: {
    createCanalRetail,
    updateCanalRetail
  }
};
