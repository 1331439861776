import React from "react";
import PropTypes from "prop-types";
import Fila from "js/components/Fila";

const Destacados = props => {
  return <Fila {...props.destacados} baseLink={"Destacados"} />;
};

Destacados.defaultProps = {
  text: "Destacados Information"
};

Destacados.propTypes = {
  text: PropTypes.string.isRequired
};

export default Destacados;
