import React, { PureComponent } from "react";
import TablaDesplegable from "js/components/Shared/TablaReconocimientos";
import ReconocimientoGraphQL from "js/graphql/resolvers/reconocimiento.resolver";
import advancedFilter from "./advancedFilter.js";
import { withRouter } from "react-router-dom";
import { EstadoReconocimientos } from "js/components/Shared/TablaReconocimientos/TableCells";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import { _t } from "js/utils/TranslationService";

const simplifyHeader = {
  title: " "
};

const getColumnData = () => [
  { id: "fechaReconocimiento", label: "Fecha de nominacion", type: "date" },
  {
    id: "usuarioReconocedor",
    label: "Nominador",
    type: "custom",
    parser: ({ perfil, username }) =>
      perfil && perfil.nombre && perfil.apellido
        ? perfil.nombre + " " + perfil.apellido
        : username
  },
  {
    id: "usuariosReconocidos",
    label: "Nominado/s",
    type: "custom",
    parser: usuarios =>
      usuarios
        .map(
          ({ perfil, username }) =>
            perfil && perfil.nombre && perfil.apellido
              ? perfil.nombre + " " + perfil.apellido
              : username
        )
        .join(", ")
  }
];

const defaultSort = { order: "desc", orderBy: "fechaReconocimiento" };

class Tabla extends PureComponent {
  render() {
    const columnData = getColumnData();
    const { formulario = {} } = this.props;
    const { valor, tipo } = formulario;
    if (valor && valor.alias) {
      columnData.push({ id: "valor", label: _t("Valor/es"), type: "array" });
    }
    columnData.push({
      id: "estado",
      label: "Estado",
      type: "custom",
      parser: (estado, item) => (
        <EstadoReconocimientos
          estado={estado}
          aprobaciones={item.aprobaciones}
          aprobacionesRRHH={item.aprobacionesRRHH}
        />
      )
    });
    if (tipo && tipo.alias) {
      columnData.push({ id: "tipo", label: _t("Tipo/s"), type: "array" });
    }
    const {
      programa: { idPrograma },
      usuario = {},
      classes
    } = this.props;
    const { idUsuario } = usuario;
    return (
      <Grid
        container
        style={{
          margin: "0px",
          width: "100%",
          maxWidth: "100%",
          backgroundColor: "#FFF"
        }}
        direction={"row"}
        alignContent={"center"}
        justify={"center"}
      >
        <Grid
          xs={12}
          style={{
            margin: "0px",
            width: "100%",
            maxWidth: "100%"
          }}
          item
          className={classes.fondoTitulo}
        >
          <Typography variant={"h3"} classes={{ root: classes.textoTitulo }}>
            {_t("Listado de nominaciones")}
          </Typography>
        </Grid>

        <TablaDesplegable
          storage={"FiltroReconocimiento"}
          query={ReconocimientoGraphQL.queries.getPaginaReconocimientos}
          defaultHeader={simplifyHeader}
          idPrograma={idPrograma}
          advancedFilter={advancedFilter}
          actionsCustom={[]}
          sort={defaultSort}
          columnData={columnData}
          getTipo={data => data.getMisReconocimientos}
          formulario={formulario}
          history={this.props.history}
          idUsuario={idUsuario}
          classes={classes}
        />
      </Grid>
    );
  }
}

export default withRouter(Tabla);
