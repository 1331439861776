import React, { Component } from "react";
import { API_KEY } from "./constants";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {}
    };
  }

  componentWillReceiveProps({ mapa }) {
    if (mapa.zoom !== this.props.mapa.zoom) {
      this.map.setZoom(mapa.zoom);
    }
  }

  onMapReady = (mapProps, map) => {
    this.map = map;
    map.addListener("zoom_changed", () => {
      this.props.onChangeMapZoom(map.zoom);
    });
  };

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  };

  onMapClicked = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  getCoordinates = value => {
    value.lat = value.latitud;
    value.lng = value.longitud;
    return value;
  };
  render() {
    return (
      <div className={this.props.classes.mapContainer}>
        <Map
          google={this.props.google}
          className={this.props.classes.map}
          initialCenter={{
            lat: this.props.sucursales[0].latitud,
            lng: this.props.sucursales[0].longitud
          }}
          center={this.getCoordinates(this.props.mapa.center)}
          zoom={14}
          onClick={this.onMapClicked}
          onReady={this.onMapReady}
        >
          {this.props.sucursales.map(sucursal => (
            <Marker
              key={`${sucursal.latitud}-${sucursal.longitud}`}
              position={{ lat: sucursal.latitud, lng: sucursal.longitud }}
              onClick={this.onMarkerClick}
              name={sucursal.nombre}
              icon={{
                url:
                  "https://www.shareicon.net/data/32x32/2015/09/13/100350_on_512x512.png"
              }}
            />
          ))}
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            onClose={this.onMapClicked}
          >
            <div>
              <h3>{this.state.selectedPlace.name}</h3>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: API_KEY
})(MapContainer);
