import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import Button from "@material-ui/core/Button";
import Glosario from "./Glosario";
import {
  ProgramaField,
  SegmentoField,
  UsuarioField,
  TypeAhead
} from "js/components/Shared/Form/Fields";
import { _t } from "js/utils/TranslationService";
import reports, { getUrl, FILTROS } from "../reportsList";
import Enhancer, { rangeOptions } from "./container";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { max } from "date-fns";

function getParsedFrom(from, programa) {
  return programa && from ? max(programa.fechaLanzamiento, from) : from;
}

const Filters = ({
  classes,
  idReport,
  onTypeChange,
  onDateChange,
  onProgramaIdChange,
  onSegmentoIdChange,
  onUsuarioIdChange,
  onModuloChange,
  onRefresh,
  range,
  idPrograma,
  idSegmento,
  idUsuario,
  timestamp,
  modulo,
  dates: { from, to },
  history,
  programa
}) => {
  const {
    name,
    id,
    filter,
    withTodosProgramas,
    height,
    glosario,
    noCustom = false,
    componentReporte
  } = reports.find(({ number }) => number === idReport);
  const idProgramaValue = idPrograma ? idPrograma : AuthService.getIdPrograma();
  const parsedFrom = getParsedFrom(from, programa);
  const ComponenteReporte = componentReporte;
  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          {_t(name)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={24}>
          {/*//TODO: Que no se vea nunca? o solo se vea en admin o solo se vea si tenes permisos?*/}
          {filter.find(filtro => filtro === FILTROS.PROGRAMA) &&
            AuthService.hasPerms([Roles.PROGRAMA_LISTA]) && (
              <Grid item xs={12} md={3}>
                <ProgramaField
                  className={classes}
                  value={idProgramaValue}
                  onChange={programa => onProgramaIdChange(programa)}
                  withTodos={withTodosProgramas}
                />
              </Grid>
            )}
          {filter.find(filtro => filtro === FILTROS.SEGMENTO) && (
            <Grid item xs={12} md={3}>
              <SegmentoField
                className={classes}
                value={idSegmento}
                idPrograma={idProgramaValue}
                onChange={segmento => onSegmentoIdChange(segmento)}
                pathLabel={"descripcion"}
                withTodos
              />
            </Grid>
          )}
          {filter.find(filtro => filtro === FILTROS.USUARIO) && (
            <Grid item xs={12} md={3}>
              <UsuarioField
                className={classes}
                value={idUsuario}
                idPrograma={idProgramaValue}
                onChange={usuario => onUsuarioIdChange(usuario)}
                withTodos
              />
            </Grid>
          )}
          {filter.find(filtro => filtro === FILTROS.MODULO) && (
            <Grid item xs={12} md={3}>
              <TypeAhead
                suggestions={{
                  modulos: glosario
                    .filter(({ title }) => !title.includes("+X"))
                    .map(({ title }) => ({
                      value: title,
                      label: title
                    }))
                }}
                placeholder={"Módulo"}
                label={"Módulo"}
                name={"modulo"}
                path={{ path: "modulos", value: "value", label: "label" }}
                onChange={modulo => onModuloChange(modulo)}
                withTodos
                value={modulo}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={24}>
          {noCustom ? (
            ""
          ) : (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <TextField
                  key="range"
                  name="range"
                  label={_t("Rango de fecha")}
                  value={range}
                  select
                  className={classes.style}
                  onChange={onTypeChange}
                >
                  {rangeOptions.map(option => (
                    <MenuItem key={option.label} value={option.value}>
                      {_t(option.label)}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <DatePicker
                format="DD/MM/YYYY"
                label={_t("Desde")}
                value={parsedFrom}
                onChange={date =>
                  onDateChange("from", getParsedFrom(date, programa))
                }
                startOfDate
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <DatePicker
                format="DD/MM/YYYY"
                label={_t("Hasta")}
                value={to}
                endOfDate
                onChange={date => onDateChange("to", date)}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => onRefresh()}
          >
            {_t("Refrescar")}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonVolver}
            onClick={() => history.push("/misreportes")}
          >
            {_t("Volver")}
          </Button>
        </Grid>
      </Grid>
      {idProgramaValue !== null ? (
        <Fragment>
          {glosario ? <Glosario glosario={glosario} /> : ""}
          <Grid
            item
            xs={12}
            className={componentReporte ? {} : classes.iframeWrapper}
          >
            {componentReporte ? (
              <ComponenteReporte
                filter={{
                  idPrograma: idProgramaValue,
                  idSegmento,
                  fechaDesde: parsedFrom,
                  fechaHasta: to
                }}
              />
            ) : (
              ""
            )}
            <iframe
              key={timestamp}
              frameBorder="0"
              src={getUrl({
                idPrograma: idProgramaValue || null,
                idSegmento: idSegmento || null,
                idUsuario: idUsuario || null,
                from: parsedFrom ? parsedFrom.toISOString() : parsedFrom,
                to: to ? to.toISOString() : to,
                id,
                modulo
              })}
              height={height}
              width="100%"
              title={name}
            />
          </Grid>
        </Fragment>
      ) : (
        ""
      )}
    </Grid>
  );
};

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  range: PropTypes.string,
  dates: PropTypes.shape({
    from: PropTypes.any,
    to: PropTypes.any
  }).isRequired,
  height: PropTypes.number,
  idPrograma: PropTypes.number,
  idSegmento: PropTypes.number,
  idUsuario: PropTypes.number,
  onTypeChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onProgramaIdChange: PropTypes.func.isRequired,
  onSegmentoIdChange: PropTypes.func,
  onUsuarioIdChange: PropTypes.func,
  onModuloChange: PropTypes.func,
  onRefresh: PropTypes.func
};

Filters.defaultProps = {
  height: 1000
};

Filters.displayName = "Filters";

export default Enhancer(Filters);
