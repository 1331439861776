import React from "react";
import PropTypes from "prop-types";
import Spinner from "js/components/Shared/Spinner";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import Paper from "@material-ui/core/Paper/Paper";
import { getUrlWithQueryString } from "js/utils";

const Informacion = props => {
  const {
    url,
    height,
    padding,
    backgroundColor,
    fullWidth,
    marginTop,
    isLoggedIn
  } = props;

  if (url === undefined)
    return <Spinner thickness={7} color="inherit" variant="indeterminate" />;
  else if (isEmpty(url)) {
    return <Redirect to="/landing" />;
  }

  const paddingPx = padding ? padding + "px" : undefined;
  const marginTopPx = marginTop === undefined ? "20px" : marginTop + "px";
  let style = {
    padding: paddingPx,
    backgroundColor: backgroundColor,
    maxWidth: fullWidth ? "100%" : "1200px",
    marginTop: marginTopPx
  };

  let urlWithQuery = null;
  let idUsuario = null;
  let idPrograma = null;

  if (isLoggedIn) {
    idUsuario = props.usuario.idUsuario;
    idPrograma = props.programa.idPrograma;
    if (idUsuario && idPrograma)
      urlWithQuery = getUrlWithQueryString(url, {
        idUsuario,
        idPrograma
      });
  }

  return (
    <Paper style={style} elevation={5}>
      <iframe
        src={urlWithQuery ? urlWithQuery : url}
        height={height}
        width="100%"
        title={urlWithQuery ? urlWithQuery : url}
        style={{
          border: "none"
        }}
      />
    </Paper>
  );
};

Informacion.propTypes = {
  classes: PropTypes.object
};

Informacion.defaultProps = {};

Informacion.displayName = "Informacion";

export default Informacion;
