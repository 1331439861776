import { withStyles } from "@material-ui/core/styles";
import { branch, compose } from "recompose";
import CrearReconocimiento from "./CrearReconocimiento";
import styles from "./styles";
import withPrograma from "js/hoc/withPrograma";
import { withRouter } from "react-router-dom";
import { graphql, withApollo } from "react-apollo";
import ReconocimientoGraphQL from "js/graphql/resolvers/reconocimiento.resolver";
import UserQraphQL from "js/graphql/resolvers/usuario.resolver";
import { formValueSelector, reduxForm } from "redux-form";
import connect from "react-redux/es/connect/connect";
const selector = formValueSelector("crearReconocimientoForm");

const enhance = compose(
  withPrograma,
  withRouter,
  withApollo,
  branch(
    ({ computedMatch }) =>
      computedMatch &&
      computedMatch.params &&
      computedMatch.params.idReconocimiento,
    graphql(ReconocimientoGraphQL.queries.getReconocimientoById, {
      options: ({ computedMatch }) => ({
        fetchPolicy: "network-only",
        variables: {
          _id: computedMatch.params.idReconocimiento
        }
      }),
      props: ({ data }) => {
        const { loading, getReconocimiento } = data;
        if (loading) return;
        return {
          reconocimientoRechazado: getReconocimiento
        };
      }
    })
  ),
  graphql(UserQraphQL.queries.getMiUsuarioCompleto, {
    options: () => ({
      fetchPolicy: "cache-first"
    }),
    props: ({ data }) => {
      const { loading, miUsuario } = data;
      if (loading) return;
      return {
        usuario: miUsuario
      };
    }
  }),
  withStyles(styles),
  connect(state =>
    selector(
      state,
      "valor",
      "tipo",
      "menurrhh",
      "respuesta1",
      "respuesta2",
      "respuesta3",
      "mensaje"
    )
  ),
  reduxForm({
    form: "crearReconocimientoForm",
    initialValues: {},
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
  })
);

export default enhance(CrearReconocimiento);
