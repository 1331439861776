import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import { Paper, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";

import { numberWithCommas } from "js/utils";
import { _t } from "js/utils/TranslationService";
import styles from "./indicadorStyles.js";

const TOP_OFFSET = 25;
const BOTTOM_OFFSET = 20;
const BARRA_WIDTH = 80;
const EXCESS_HEIGHT = 15;
const BORDER_WIDTH = 2;

class BarrasVerticalIndicator extends PureComponent {
  constructor(props) {
    super(props);
    let tieneBorde = true;
    if (typeof props.parametrosVisuales.tieneBorde !== "undefined") {
      tieneBorde = props.parametrosVisuales.tieneBorde;
    }

    this.state = {
      borderWidth: tieneBorde
        ? props.parametrosVisuales.anchoBorde
          ? props.parametrosVisuales.anchoBorde
          : BORDER_WIDTH
        : 0
    };
  }

  componentDidMount() {
    this.setCanvasesDimensions();
    this.createBarra();
  }

  /**
   * Retorna el porcentaje del targetAlcanzado con un máximo de 999
   * @param {target} target
   * @param {performance} performance
   */
  getPercentaje = (target, performance) => {
    const result = (performance * 100) / target;
    return result > 100 ? 100 : result;
  };

  setCanvasesDimensions() {
    const { index } = this.props;
    const canvases = document.querySelectorAll(
      '[class$="canvas-' + index + '"]'
    );
    canvases.forEach(canvas => {
      const parent = canvas.parentElement;
      // - 100 por los paddings horizontales y -40 por los paddings verticales
      const rightPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-right")
      );
      const leftPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-left")
      );
      const topPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-top")
      );
      const bottomPadding = parseFloat(
        window.getComputedStyle(parent, null).getPropertyValue("padding-bottom")
      );
      canvas.setAttribute(
        "width",
        parent.clientWidth - (rightPadding + leftPadding)
      );
      canvas.setAttribute(
        "height",
        parent.clientHeight - (topPadding + bottomPadding)
      );
    });
  }

  createBarra() {
    const {
      index,
      performanceObjetivo: { performance, targetsObjetivo },
      parametrosVisuales: {
        anchoBarra = BARRA_WIDTH,
        colorFondo = "#ccc",
        colorAvance = "#ce762e",
        tieneBorde = true,
        colorBorde = "#000",
        colorTextoTarget = "#4D5D67",
        colorTextoAvance = "#ce762e",
        colorLineaTarget = "#4D5D67",
        colorLineaAvance = "#ce762e",
        sizeNumeros = "16",
        sizeTextos = "13",
        colorCheckIcon = "#4EC230",
        showPuntosTargets = true
      }
    } = this.props;

    let target = 0;
    targetsObjetivo.forEach(targetObjetivo => {
      if (target < targetObjetivo.target) {
        target = targetObjetivo.target;
      }
    });

    const objectiveCanvas = document.getElementById(
      "objetivo-percentage-canvas-" + index
    );
    const lineCanvas = document.getElementById("objetivo-line-canvas-" + index);
    const width = objectiveCanvas.clientWidth;
    const height = objectiveCanvas.clientHeight;
    const finalHeight = height - BOTTOM_OFFSET - TOP_OFFSET;

    if (tieneBorde) {
      this.drawBorder(
        objectiveCanvas,
        width / 2 - anchoBarra / 2,
        0 + TOP_OFFSET,
        anchoBarra,
        finalHeight,
        colorBorde
      );
    }

    // barra entera con color del fondo
    this.drawBarra(
      objectiveCanvas,
      width / 2 - anchoBarra / 2,
      0 + TOP_OFFSET,
      anchoBarra,
      finalHeight,
      colorFondo
    );

    // barra del avance
    const performancePercentage = this.getPercentaje(target, performance);
    let avanceHeight = (finalHeight * performancePercentage) / 100;

    this.drawBarra(
      objectiveCanvas,
      width / 2 - anchoBarra / 2,
      avanceHeight >= finalHeight
        ? EXCESS_HEIGHT
        : height - avanceHeight - BOTTOM_OFFSET,
      anchoBarra,
      avanceHeight >= finalHeight
        ? avanceHeight + (TOP_OFFSET - EXCESS_HEIGHT)
        : avanceHeight,
      colorAvance
    );

    // draw linea del acvance
    this.drawLine(
      lineCanvas,
      width / 2 + anchoBarra / 2,
      avanceHeight >= finalHeight
        ? EXCESS_HEIGHT
        : height - avanceHeight - BOTTOM_OFFSET,
      0,
      colorLineaAvance,
      numberWithCommas(performance),
      colorTextoAvance,
      _t("Avance actual"),
      colorTextoAvance,
      "left",
      sizeNumeros,
      sizeTextos
    );

    const showTargetNumber = targetsObjetivo.length > 1;
    const checkIconsPositions = [];
    targetsObjetivo.forEach((targetObjetivo, index) => {
      const targetPercentage = this.getPercentaje(
        target,
        targetObjetivo.target
      );
      const targetHeight = (finalHeight * targetPercentage) / 100;

      this.drawLine(
        lineCanvas,
        width / 2 - anchoBarra / 2,
        height - targetHeight - BOTTOM_OFFSET,
        width,
        colorLineaTarget,
        numberWithCommas(targetObjetivo.target),
        colorTextoTarget,
        `${_t("Target")} ${showTargetNumber ? index + 1 : ""} ${
          targetObjetivo.puntos && showPuntosTargets
            ? `(${numberWithCommas(targetObjetivo.puntos)} ${_t("puntos")})`
            : ""
        }`,
        colorTextoTarget,
        "right",
        sizeNumeros,
        sizeTextos
      );

      if (performance >= targetObjetivo.target) {
        checkIconsPositions.push({
          x: width,
          y: height - targetHeight - BOTTOM_OFFSET
        });
      }
    });

    if (checkIconsPositions.length !== 0) {
      const container = document.getElementById(`icon-container-${index}`);
      ReactDOM.render(
        this.renderCheckIcons(checkIconsPositions, index, colorCheckIcon),
        container
      );
    }
  }

  drawBorder(canvas, x, y, width, height, color) {
    const { borderWidth } = this.state;
    if (canvas.getContext) {
      const ctx = canvas.getContext("2d");
      ctx.beginPath();
      ctx.fillStyle = color;
      ctx.rect(x - borderWidth, y, width + borderWidth, height + borderWidth);
      ctx.fill();
    }
  }

  drawBarra(canvas, x, y, width, height, color) {
    const { borderWidth } = this.state;
    if (canvas.getContext) {
      const ctx = canvas.getContext("2d");
      ctx.beginPath();
      ctx.fillStyle = color;
      ctx.strokeStyle = color;
      ctx.rect(x, y + borderWidth, width - borderWidth, height - borderWidth);
      ctx.fill();
    }
  }

  drawLine(
    canvas,
    x,
    y,
    endX,
    color,
    topText,
    topColor,
    bottomText,
    bottomColor,
    align,
    topSize,
    bottomSize
  ) {
    const {
      parametrosVisuales: { fontFamily = "Roboto" },
      isXs
    } = this.props;
    if (canvas.getContext) {
      const TEXT_X_OFFSET = align === "right" ? -3 : 3;
      const ctx = canvas.getContext("2d");
      ctx.setLineDash([6, 2]);
      ctx.beginPath();
      ctx.moveTo(x, y + 1);
      ctx.lineTo(endX, y + 1);
      ctx.strokeStyle = color;
      ctx.stroke();
      if (topText) {
        ctx.font = `bold ${isXs ? "14" : topSize}px ${fontFamily}`;
        ctx.fillStyle = topColor;
        ctx.textAlign = align;
        ctx.fillText(topText, endX + TEXT_X_OFFSET, y - 3);
      }
      if (bottomText) {
        ctx.font = `italic ${isXs ? "12" : bottomSize}px ${fontFamily}`;
        ctx.fillStyle = bottomColor;
        ctx.textAlign = align;
        ctx.fillText(bottomText, endX + TEXT_X_OFFSET, y + 13);
      }
    }
  }

  renderCheckIcons(checkIcons, index, colorCheckIcon) {
    return (
      <React.Fragment>
        {checkIcons.map((checkIcon, i) => (
          <CheckIcon
            key={`icon-${i}-${index}`}
            style={{
              color: colorCheckIcon,
              fill: colorCheckIcon,
              position: "absolute",
              left: checkIcon.x,
              top: checkIcon.y,
              transform: "translateY(-50%)"
            }}
          />
        ))}
      </React.Fragment>
    );
  }

  renderObjetivo() {
    const {
      index,
      classes,
      performanceObjetivo: { objetivo },
      parametrosVisuales: {
        colorNombreObjetivo = "#4D5D67",
        sizeNombreObjetivo = "16",
        fontFamily = "Roboto"
      }
    } = this.props;
    return (
      <Grid className={classes.objetivoWrapper} container>
        <Grid
          className={classes.objetivoTitle}
          style={{
            color: colorNombreObjetivo,
            fontSize: `${sizeNombreObjetivo}px`,
            fontFamily: fontFamily
          }}
          item
          xs={12}
        >
          {objetivo.nombre}
        </Grid>
        <Grid
          className={classNames(
            classes.chartContainer,
            classes.barraVerticalChart
          )}
          item
          xs={12}
          id={`objetivo-${index}`}
        >
          <canvas
            id={"objetivo-percentage-canvas-" + index}
            className={classNames(
              classes.canvas,
              "objetivo-percentage-canvas-" + index
            )}
          />
          <canvas
            id={"objetivo-line-canvas-" + index}
            className={classNames(
              classes.canvas,
              classes.indicadorCanvas,
              "objetivo-line-canvas-" + index
            )}
          />
          <div
            id={`icon-container-${index}`}
            style={{ width: "100%", height: "100%", position: "relative" }}
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      classes,
      parametrosVisuales: { containerShadow }
    } = this.props;

    return (
      <React.Fragment>
        {containerShadow ? (
          <Paper className={classes.objetivoContainer} elevation={1}>
            {this.renderObjetivo()}
          </Paper>
        ) : (
          <React.Fragment>{this.renderObjetivo()}</React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

BarrasVerticalIndicator.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.string,
  performanceObjetivo: PropTypes.any,
  parametrosVisuales: PropTypes.any
};

export default withStyles(styles)(BarrasVerticalIndicator);
