import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import AppQraphQL from "js/graphql/resolvers/producto.resolver";
import {
  ReduxTypeAhead,
  TypeAhead,
  AsyncTypeAhead
} from "js/components/Shared/Form/Fields";
import { required as requiredValidation } from "js/components/Shared/Form/validations.js";

class ProductosField extends PureComponent {
  render() {
    const {
      idPrograma,
      required,
      disabled,
      async,
      redux,
      multilabel,
      noPlaceholder = false,
      sinInactivos = false,
      sinAdminAshiwea = false,
      idsUsuarios,
      ...props
    } = this.props;
    const Component = async
      ? AsyncTypeAhead
      : redux
        ? ReduxTypeAhead
        : TypeAhead;
    const rest = redux ? {} : props;
    if (sinAdminAshiwea) {
      props.filter = ({ label }) => !label.includes("admin_ashiwea");
    }

    const page = {
      page: 1,
      rowsPerPage: 30,
      order: "asc",
      orderBy: "nombre"
    };
    return (
      <Component
        name="idProducto"
        label="Producto"
        validate={required ? requiredValidation : null}
        query={AppQraphQL.queries.getProductosFilter}
        queryParams={{
          ...page
        }}
        queryVariable={"productoLike.nombre"}
        path={{
          path: "getProductosFilter.itemsPagina",
          value: "idProducto",
          label: "nombre",
          multilabel
        }}
        placeholder={!noPlaceholder ? "Producto" : " "}
        emptyLabel={"No se encontro ningún producto"}
        {...rest}
      />
    );
  }
}

ProductosField.propTypes = {
  idPrograma: PropTypes.number
};

export default ProductosField;
