import { numberWithCommas } from "js/utils";

export const columnas = [
  { id: "fechaCreacion", label: "Fecha Creación", type: "date" },
  { id: "usuario_username", label: "Usuario" },
  { id: "usuario_segmento_nombre", label: "Segmento" },
  { id: "producto_nombre", label: "Producto" },
  {
    id: "total",
    label: "Total",
    isNumeric: true,
    type: "custom",
    parser: total => (total ? numberWithCommas(total) : "--")
  }
  // { id: "actions", label: "Acciones" }
];

export const misColumnas = [
  { id: "fechaCreacion", label: "Fecha Creación", type: "date" },
  { id: "producto_nombre", label: "Producto" },
  {
    id: "total",
    label: "Total",
    isNumeric: true,
    type: "custom",
    parser: total => (total ? numberWithCommas(total) : "--")
  }
  // { id: "actions", label: "Acciones" }
];
