import React, { Component, Fragment } from "react";

import Table from "js/components/Shared/Tabla";
import { manageAction } from "js/components/Shared/Tabla/ActionCells";
import advancedFilter from "./advancedFilter.js";
import LotesGraphQL from "js/graphql/resolvers/lotes.resolver.js";
import columnData from "./columnData";
import { USER_ROLES as Roles } from "js/models/User";

const defaultHeader = {
  title: "Lotes"
};

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends Component {
  render() {
    const manageLotes = params => ({
      ...manageAction({
        ...params,
        history: this.props.history,
        perms: [Roles.LOTE_W]
      }),
      onclick: e =>
        this.props.history.push(`${window.location.pathname}/${params.itemId}`)
    });
    return (
      <Fragment>
        <Table
          storage="FiltroLotes"
          query={LotesGraphQL.queries.getLotesComplete}
          defaultHeader={defaultHeader}
          advancedFilter={advancedFilter}
          sort={defaultSort}
          columnData={columnData}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count} lotes`
          }
          getTipo={data => data.getLotes}
          actionsCustom={[manageLotes]}
        />
      </Fragment>
    );
  }
}

export default Tabla;
