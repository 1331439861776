import React from "react";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

class MultipleSelect extends React.Component {
  render() {
    const { classes, onChange, values, name, label, options } = this.props;

    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor={`select-multiple-checkbox-${name}`}>
            {label}
          </InputLabel>
          <Select
            name={name}
            multiple
            value={values}
            onChange={onChange}
            input={<Input id={`select-multiple-checkbox-${name}`} />}
            renderValue={selected => {
              let label = "";
              options
                .filter(option => values.includes(option.value))
                .forEach(option => {
                  label =
                    label === "" ? option.label : label + ", " + option.label;
                });
              return label;
            }}
            MenuProps={MenuProps}
          >
            {options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                <Checkbox checked={values.includes(value)} />
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

MultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array,
  values: PropTypes.array,
  onChange: PropTypes.func
};

MultipleSelect.defaultProps = {
  name: "",
  options: [],
  values: [],
  onChange: () => undefined
};

export default MultipleSelect;
