import { _t } from "js/utils/TranslationService";

export const required = value =>
  value == null ||
  value === "" ||
  value === [] ||
  value === undefined ||
  value.length === 0
    ? _t("Requerido")
    : undefined;

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? _t("Email inválido")
    : undefined;

export const repeated = (values, errors) => {
  if (values.newPassword !== values.newPasswordRepeated) {
    errors.newPasswordRepeated = _t("Las claves no son iguales");
  }
  return errors;
};

export const formValidations = (validations = []) => values => {
  const errors = {};
  validations.map(validation => validation(values, errors));
  return errors;
};
