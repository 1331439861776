import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Icon from "@material-ui/core/Icon";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import client from "js/App/client.graphql.js";
import BeneficioQraphQL from "js/graphql/resolvers/beneficio.resolver";
import { withNotificationContext } from "js/notification-context";
import { _t } from "js/utils/TranslationService";
import Cucarda from "../../../components/Cucarda";
import TextTruncate from "react-text-truncate";
import get from "lodash/get";
import Tooltip from "@material-ui/core/Tooltip";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.toggleFavorito = this.toggleFavorito.bind(this);
    const idUsuario = parseInt(AuthService.getIdUsuario());
    const isFavorito = this.props.beneficio.favoritos.includes(idUsuario);
    this.state = {
      favorito: isFavorito,
      starActive: isFavorito
    };
  }

  async toggleFavorito() {
    this.setState(prevState => ({
      favorito: !prevState.favorito
    }));
    await client
      .mutate({
        mutation: BeneficioQraphQL.mutations.toggleFavoritoBeneficio,
        errorPolicy: "all",
        variables: {
          idBeneficio: this.props.beneficio.idBeneficio
        }
      })
      .then(res => {
        const favorito = get(res, "data.toggleFavoritoBeneficio", false);
        this.setState({ favorito });
        const message = favorito
          ? "Has agregado este beneficio a tu lista favoritos"
          : "Has quitado este beneficio de tu lista favoritos";
        this.props.openNotification(message);
        this.props.beneficio.favoritos.push(
          parseInt(AuthService.getIdUsuario())
        );
      })
      .catch(e => {
        this.setState(prevState => ({ favorito: !prevState.favorito }));
      });
  }

  render() {
    const { classes, beneficio, programLogo } = this.props;
    const { cucarda } = beneficio;
    const rubros = beneficio.rubros
      .map(rubro => rubro.descripcion)
      .concat(get(beneficio, "subrubros", []).map(r => r.descripcion));
    const favPerm = AuthService.hasPerms([Roles.FAVORITO_W]);
    return (
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardMedia
            className={
              beneficio.imagenes[0] ? classes.media : classes.mediaLogo
            }
            style={{ borderRadius: `${cucarda ? 0 : 4}px 0 0 4px` }}
            image={beneficio.imagenes[0] ? beneficio.imagenes[0] : programLogo}
          >
            {cucarda && (
              <Cucarda extraClass={classes.cucarda} cucardaType={cucarda} />
            )}
          </CardMedia>
          <div className={classes.details}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.title} color="textSecondary">
                {rubros.length ? rubros[0] : <br />}
              </Typography>
              <Tooltip
                title={beneficio.nombre}
                classes={{
                  tooltip: classes.tooltip,
                  popper: classes.popper,
                  tooltipPlacementBottom: classes.tooltipPlacementBottom
                }}
                interactive
              >
                <Typography
                  className={classes.title}
                  variant="h5"
                  component="h2"
                >
                  <TextTruncate
                    line={1}
                    truncateText="..."
                    text={beneficio.nombre}
                  />
                </Typography>
              </Tooltip>
              <Typography className={classes.description} component="p">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<div style="
                              overflow: hidden;
                              display: -webkit-box;
                              -webkit-line-clamp: 2;
                              -webkit-box-orient: vertical;
                            ">${beneficio.copete || ""}</div>`
                  }}
                />
              </Typography>
              <Typography
                className={classes.score}
                variant="h6"
                color="primary"
                component="h2"
              >
                {beneficio.idTipo ? beneficio.idTipo.tipo : ""}
              </Typography>
            </CardContent>
            <CardActions
              className={classes.actionContainer}
              style={favPerm ? {} : { marginTop: "auto" }}
            >
              {favPerm && (
                <Icon
                  color="primary"
                  className={classes.heartIcon}
                  onMouseOver={() => this.setState({ starActive: true })}
                  onMouseOut={() => this.setState({ starActive: false })}
                  onClick={this.toggleFavorito}
                >
                  {this.state.starActive || this.state.favorito
                    ? "favorite"
                    : "favorite_border"}
                </Icon>
              )}
              {!beneficio.stockInfinito &&
              beneficio.stock !== null &&
              beneficio.stock < 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={true}
                  className={classes.masInfo}
                >
                  {_t("Sin stock")}
                </Button>
              ) : (
                <Link to={`/beneficio/${beneficio.idBeneficio}`}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.masInfo}
                  >
                    {_t("Más info")}
                  </Button>
                </Link>
              )}
            </CardActions>
          </div>
        </Card>
      </Grid>
    );
  }
}

export default withNotificationContext(ListItem);
