import React, { PureComponent, Fragment } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/pages/Unilever/Med/VariablesMed/Tabla/advancedFilters.js";
import MedGraphQL from "js/graphql/resolvers/medUnilever.resolver";
import VariablesMedGraphQL from "js/graphql/resolvers/variablesMedUnilever.resolver";
import { variablesMedEditAction } from "js/pages/Unilever/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import get from "lodash/get";
import AuthService from "js/utils/AuthService";
import { numberWithCommas } from "js/utils";

const defaultHeader = (id, codigo) => {
  return {
    title: "Variables por campaña del MED: ",
    titleTranslation: false
  };
};

const columnData = [
  { id: "fechaCreacion", label: "Fecha", type: "date" },
  { id: "campana_nombre", label: "Campaña", removeSort: true },
  {
    id: "fechaLimiteEdd",
    label: "Límite para EDD",
    removeSort: true,
    type: "date"
  },
  {
    id: "ajusteEdd",
    label: "Rango ajuste",
    removeSort: true,
    type: "custom",
    parser: (ajusteEdd, variablesMed) => {
      const minMax = variablesMed.ajusteEddMinMax;
      return (ajusteEdd === 0 || ajusteEdd === null) && minMax
        ? `${minMax.min}-${minMax.max}`
        : ajusteEdd;
    }
  },
  {
    id: "fechaLimiteSupervisores",
    label: "Límite para Supervisores",
    removeSort: true,
    type: "date"
  },
  {
    id: "targetUnidades",
    isNumeric: true,
    label: "Target unidades",
    type: "custom",
    parser: (targetUnidades, variablesMed) => {
      let unidades = null;
      variablesMed.targetsObjetivos.forEach(targetObjetivo => {
        if (targetObjetivo.objetivo === "Unidades") {
          unidades = targetObjetivo.target;
        }
      });
      if (!unidades) {
        return <span style={{ fontSize: "1.75rem" }}>--</span>;
      }
      return numberWithCommas(unidades);
    }
  },
  {
    id: "targetCobertura",
    isNumeric: true,
    label: "Target cobertura",
    type: "custom",
    parser: (targetCobertura, variablesMed) => {
      let cobertura = null;
      variablesMed.targetsObjetivos.forEach(targetObjetivo => {
        if (targetObjetivo.objetivo === "Cobertura") {
          cobertura = targetObjetivo.target;
        }
      });
      if (!cobertura) {
        return <span style={{ fontSize: "1.75rem" }}>--</span>;
      }
      return numberWithCommas(cobertura);
    }
  },
  {
    id: "estado",
    label: "Estado",
    removeSort: true,
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  constructor(props) {
    super(props);
    const params = {
      id: null,
      codigo: null,
      inBackOffice: window.location.pathname.indexOf("admin") !== -1,
      isMisMeds: window.location.pathname.indexOf("mis-meds") !== -1
    };
    if (params.inBackOffice) {
      params.id = props.match.params.id;
      params.codigo = props.match.params.codigo;
    } else {
      params.id = parseInt(AuthService.getIdPrograma());
      params.codigo = props.computedMatch.params.codigo;
    }

    this.state = {
      ...params,
      defaultHeader: defaultHeader(params.id, params.codigo)
    };
  }

  async componentDidMount() {
    const { client, history } = this.props;
    const { defaultHeader, inBackOffice, isMisMeds, id, codigo } = this.state;

    const dataMed = await client.query({
      query:
        inBackOffice || (!inBackOffice && !isMisMeds)
          ? MedGraphQL.queries.getMedById
          : MedGraphQL.queries.getMiMedById,
      fetchPolicy: "network-only",
      errorPolicy: "all",
      variables: {
        codigo,
        idPrograma: id
      }
    });
    const nombreMed = get(
      dataMed,
      inBackOffice || (!inBackOffice && !isMisMeds)
        ? "data.getMeds.itemsPagina[0].nombre"
        : "data.getMisMeds.itemsPagina[0].nombre",
      null
    );
    if (!nombreMed) return;
    defaultHeader.title = (
      <Fragment>
        <IconButton
          onClick={() =>
            inBackOffice
              ? history.push(`/admin/programa/${id}/meds`)
              : isMisMeds
                ? history.push(`/mis-meds`)
                : history.push(`/meds`)
          }
        >
          <ArrowBack />
        </IconButton>
        {`MED: ${nombreMed} | Variables por campaña`}
      </Fragment>
    );
    if (!isMisMeds) {
      defaultHeader.new = {
        title: "Nuevo",
        perms: [Roles.MED_W]
      };
    }
    this.setState({
      defaultHeader: defaultHeader
    });
    this.forceUpdate();
  }

  render() {
    const { computedMatch } = this.props;
    const { defaultHeader, isMisMeds } = this.state;

    const edit = params => ({
      ...variablesMedEditAction({
        ...params,
        history: this.props.history,
        perms: [Roles.MED_W]
      })
    });

    return (
      <Table
        storage={"FiltroVariablesMed"}
        query={
          isMisMeds
            ? VariablesMedGraphQL.queries.getVariablesMiMed
            : VariablesMedGraphQL.queries.getVariablesMed
        }
        defaultHeader={defaultHeader}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data =>
          isMisMeds ? data.getVariablesMiMed : data.getVariablesMed
        }
        actionsCustom={[edit]}
        pathId={"_id"}
        computedMatch={computedMatch}
        toolbarActions={isMisMeds ? ["estado_variables_meds_unilever"] : []}
        actions={isMisMeds ? ["bulk"] : []}
        actionConditionVariable={"estado"}
        actionConditionValues={["EN_REVISION"]}
      />
    );
  }
}

export default Tabla;
