import MyForm from "./MyForm.container";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import { compose, withProps } from "recompose";
import withPrograma from "js/hoc/withPrograma";

const enhance = compose(
  withPrograma,
  withRouter,
  withApollo,
  withStyles(styles),
  withProps(props => ({
    // selectedProgram: props.programa,
    // match: {
    //   params: { id: props.programa.idPrograma }
    // }
  }))
);
export default enhance(MyForm);
