import React from "react";
import { get } from "lodash";
import { numberWithCommas } from "js/utils";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";

export default [
  { id: "numeroFactura", label: "Nº de factura" },
  {
    id: "proveedor",
    label: "Proveedor",
    type: "custom",
    parser: proveedor => get(proveedor, ["nombreFantasia"], "--")
  },
  { id: "fecha", type: "date", label: "Fecha" },

  {
    id: "canjes",
    label: "Costo canjes",
    isNumeric: true,
    type: "custom",
    parser: canjes =>
      canjes && canjes.length
        ? numberWithCommas(
            canjes.reduce((a, b) => a + b.eleccionProducto.producto.costo, 0)
          )
        : "--"
  },
  {
    id: "costoReal",
    label: "Costo real canjes",
    isNumeric: true,
    type: "custom",
    parser: costoReal => (costoReal ? numberWithCommas(costoReal) : "--")
  },
  {
    id: "cantidadCanjes",
    isNumeric: true,
    label: "Canjes",
    type: "custom",
    parser: cantidadCanjes =>
      cantidadCanjes ? numberWithCommas(cantidadCanjes) : "--"
  },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  {
    id: "importe",
    label: "Importe",
    type: "custom",
    isNumeric: true,
    parser: importe => (importe ? numberWithCommas(importe) : "--")
  },
  { id: "actions", label: "Acciones", removeSort: true }
];
