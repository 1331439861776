import { _t } from "js/utils/TranslationService";
import React, { Fragment } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withNotificationContext } from "js/notification-context";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Slide,
  Typography,
  DialogContent,
  Grid,
  CircularProgress
} from "@material-ui/core";
import { styles } from "js/components/Shared/Tabla/Dialogs/styles.js";
import AppGraphQL from "js/graphql/resolvers/canje.resolver";
import _ from "lodash";
import { ItemLine } from "./components";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const feedbackMessages = {
  REMOVE_ERROR: "Ocurrio un error al remover el premio",
  REMOVE_SUCCESS: "El proceso ha finalizado correctamente"
};

class RemovePrizeDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onRemove: false,
      variables: {},
      errors: undefined
    };
  }

  setLoadingState = () => {
    this.setState(prevState => {
      const { onRemove } = prevState;
      return { onRemove: !onRemove };
    });
  };

  componentDidMount = () => {
    const { item } = this.props;
  };

  handleSubmit = async () => {
    const { client, item } = this.props;
    const {
      item: { idCanje }
    } = this.props;

    try {
      this.setLoadingState();

      const result = await client.mutate({
        mutation: AppGraphQL.mutations.uploadCanjePrize,
        options: { fetchPolicy: "network-only" },
        variables: {
          idCanje,
          prizeFormat: "EMPTY"
        }
      });

      if (_.isNil(result)) throw new Error(feedbackMessages.REMOVE_ERROR);

      this.setLoadingState();

      this.props.openNotification(feedbackMessages.REMOVE_SUCCESS);
      this.props.onTableChange();
      this.props.handleClose();
    } catch (error) {
      this.setLoadingState();
      this.props.openNotification(error.message);
    }
  };

  render() {
    const { handleClose, classes, item } = this.props;
    const { onRemove } = this.state;

    return (
      <Fragment>
        <Dialog
          open
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          maxWidth={"md"}
        >
          <DialogContent>
            <DialogTitle style={{ padding: "0 0 1rem 0" }}>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {_t("Remover premio")}
              </Typography>
            </DialogTitle>

            <DialogContent style={{ padding: "0 0 0 0" }}>
              <Typography variant={"subtitle1"} style={{ margin: "0 0 1em 0" }}>
                {_t("Vas a remover el premio asociado al siguiente canje: ")}
              </Typography>
              <ItemLine fixed={_t("Id canje")} value={item.idCanje} />
              <ItemLine fixed={_t("Nro. de lote")} value={item.numeroLote} />
              <ItemLine
                fixed={_t("Fecha")}
                value={new Date(item.fecha).toLocaleDateString()}
              />
              <ItemLine
                fixed={_t("Programa")}
                value={
                  item.usuario && item.usuario.programa
                    ? item.usuario.programa.nombre
                    : undefined
                }
              />
              <ItemLine
                fixed={_t("Usuario")}
                value={
                  item.usuario && item.usuario.perfil
                    ? item.usuario.perfil.nombre +
                      " " +
                      item.usuario.perfil.apellido
                    : item.usuario.username
                }
              />
              <ItemLine
                fixed={_t("Cantidad/producto")}
                value={`${item.cantidad} x ${
                  item.eleccionProducto && item.eleccionProducto.producto
                    ? item.eleccionProducto.producto.nombre
                    : " "
                }`}
              />
            </DialogContent>
            <DialogActions style={{ margin: "1.5em 0 0 0" }}>
              <Grid container spacing={8} className={classes.actionsContainer}>
                <Button
                  color="primary"
                  onClick={handleClose}
                  variant="outlined"
                  className={classes.actionButtons}
                >
                  {_t("Cancelar")}
                </Button>
                <Button
                  color="primary"
                  onClick={this.handleSubmit}
                  variant="contained"
                  className={classes.actionButtons}
                  disabled={onRemove ? true : false}
                >
                  {this.state.onRemove ? (
                    <CircularProgress size={24} />
                  ) : (
                    _t("Remover")
                  )}
                </Button>
              </Grid>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const enhance = compose(
  withApollo,
  withNotificationContext,
  withRouter,
  withStyles(styles)
);

export default enhance(RemovePrizeDialog);
