import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import FilterCategories from "./FilterCategories";
import styles from "./styles";
import withPrograma from "js/hoc/withPrograma";

const enhance = compose(
  withStyles(styles),
  withPrograma
);

export default enhance(FilterCategories);
