import Access from "./Access.container";
import WithTracker from "js/hoc/WithTracker";
import withPrograma from "js/hoc/withPrograma";

import { compose } from "recompose";

const enhance = compose(
  WithTracker,
  withPrograma
);
export default enhance(Access);
