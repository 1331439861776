import React, { Component } from "react";
import PropTypes from "prop-types";
import { css } from "emotion";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import IdiomasList from "js/components/Shared/IdiomasList";
import { _t } from "js/utils/TranslationService";
import AccessQraphQL from "js/graphql/resolvers/access.resolver";
import client from "js/App/client.graphql.js";
import { Link } from "react-router-dom";
import ErrorList from "js/components/Shared/ErrorList";
import CircularProgress from "@material-ui/core/CircularProgress";

export class RestoreUser extends Component {
  state = {
    email: "",
    errors: null,
    message: undefined,
    loading: false
  };

  restoreUser = async (email, idPrograma) => {
    this.setState({ loading: true });
    const { errors, data } = await client.mutate({
      mutation: AccessQraphQL.mutations.recuperarUsuario,
      errorPolicy: "all",
      variables: {
        email,
        idPrograma
      }
    });
    this.setState({ loading: false });
    if (data.recuperarUsuario) {
      const message = _t("Se ha enviado a tu email tu usuario");
      this.setState(
        (state, props) => ({ message }),
        this.props.openNotification(message)
      );
      this.props.history.push("/signin");
    }

    if (errors) {
      this.setState({ errors: errors[0] });
    }
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, errors: null });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    const { classes, programa, logo } = this.props;
    const { email, loading, errors } = this.state;
    const { access } = programa.template;
    const { esPublico } = programa;
    return (
      <Paper className={classes.root} elevation={1}>
        <div className={classes.idiomaPosition}>
          <IdiomasList />
        </div>
        <img alt="Logo" src={logo} className={classes.logo} />
        <form
          onSubmit={event => {
            event.preventDefault();
            this.restoreUser(email, programa.idPrograma);
          }}
        >
          <Grid container direction="column">
            <Typography variant="h6" gutterBottom>
              {access.restoreUser.titulo}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {access.restoreUser.descripcion}
            </Typography>
            <FormControl
              className={css(
                classes.margin,
                classes.withoutLabel,
                classes.textField
              )}
            >
              <Input
                placeholder={_t("Email")}
                id="adornment-usuario"
                value={this.state.email}
                onChange={this.handleChange("email")}
              />
            </FormControl>
            <ErrorList errors={errors} belowInput />
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
            >
              {!loading && _t("Recuperar usuario")}
              {loading && (
                <CircularProgress className={classes.progress} size={20} />
              )}
            </Button>
          </Grid>
        </form>
        {esPublico && (
          <Typography variant="caption" gutterBottom align="right">
            <Link to="/signup">
              {_t("Si no estás registrado ingresa aquí")}
            </Link>
          </Typography>
        )}
        <Grid container justify="space-between">
          <Typography variant="caption" gutterBottom align="left">
            <Link to="/restorePassword">{_t("Recuperar clave")}</Link>
          </Typography>
          <Typography variant="caption" gutterBottom align="right">
            <Link to="/signin">{_t("Volver al inicio de sesión")}</Link>
          </Typography>
        </Grid>
      </Paper>
    );
  }
}

RestoreUser.propTypes = {
  programa: PropTypes.object
};

RestoreUser.defaultProps = {
  programa: {},
  open: false
};

export default RestoreUser;
