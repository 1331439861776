import { red } from "@material-ui/core/colors";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

const mediaStyle = {
  height: 0,
  paddingTop: "56.25%",
  borderBottom: "1px solid #ccc",
  position: "relative"
};

export default theme => ({
  container: {
    paddingLeft: 16,
    paddingRight: 16,
    display: "contents"
  },
  card: {
    overflow: "visible",
    width: "100%",
    marginBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30%"
    }
  },
  item: {
    fontSize: ".9rem"
  },
  tooltip: {
    fontSize: "0.85em",
    maxWidth: "100%",
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.primary.main
  },
  tooltipPlacementBottom: {
    margin: "-1.75rem 0"
  },
  popper: {
    opacity: 1
  },
  title: {
    fontSize: "1.1rem",
    [theme.breakpoints.up("sm")]: {
      height: "3.5rem"
    },
    display: "flex"
  },
  media: {
    ...mediaStyle
  },
  mediaLogo: {
    ...mediaStyle,
    backgroundSize: "auto"
  },
  descripcion: {},
  heartIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 10,
    cursor: "pointer"
  },
  favContainer: {
    position: "relative"
  },
  score: {
    fontSize: ".8rem"
  },
  gridCard: {
    display: "flex",
    width: "100%",
    paddingLeft: "24px",
    paddingRight: "24px"
  },
  actionContainer: {
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: "24px",
    paddingRight: "24px",
    "& a": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit" /* no underline */,
      zIndex: 10
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 0
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "15px",
      paddingRight: "15px"
    }
  },
  link: {
    textDecoration: "none"
  },
  cardContent: {
    [theme.breakpoints.up("sm")]: {
      height: "9.5rem"
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: 0
    }
  },
  gridContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      paddingTop: "5%",
      height: "99%"
    }
  },
  webcam: {
    width: "auto",
    height: 720,
    paddingLeft: "20px",
    paddingRight: "20px",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "60%"
    }
  },
  formControl: {
    width: "100%"
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    height: "25px"
  },
  cancelChip: {
    fontSize: "20px"
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  imagenCapture: {
    width: "100%",
    height: "auto",
    paddingLeft: "20px",
    paddingRight: "20px",
    marginTop: "10px",
    marginBottom: "10px"
  },
  progress: {
    alignSelf: "center",
    minHeight: 100
  }
});
