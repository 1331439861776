import get from "lodash/get";

export default theme => {
  const avatarDiameter = get(
    theme,
    "programa.template.header.user.avatar.diameter",
    40
  );
  return {
    containerNominados: {
      backgroundColor: theme.palette.primary.main + "40"
    },
    textoTitulo: {
      // color: theme.palette.getContrastText(theme.palette.secondary.main),
      color: "#FFF",
      textAlign: "center",
      padding: "20px",
      fontSize: "xx-large"
    },
    fondoTitulo: {
      backgroundColor: theme.palette.primary.main,
      marginBottom: "40px"
    },
    botonActivado: {
      height: "67px",
      width: "25%",
      color: theme.palette.primary.main,
      cursor: "pointer"
    },
    botonDesactivado: {
      height: "67px",
      width: "25%",
      color: "#979797",
      cursor: "pointer"
    },
    avatar: {
      margin: 10,
      width: avatarDiameter,
      height: avatarDiameter
    },
    textoNombre: {
      color: "#4c4c4c",
      fontSize: "25px",
      fontWeight: "bold"
    },
    textoNombreEquipo: {
      color: "#4c4c4c",
      fontWeight: "bold"
    },
    textoDefinicion: {
      color: "#4c4c4c"
    },
    card: {
      minWidth: "100%",
      width: "100%",
      padding: "20px"
    },
    icon: {
      color: theme.palette.primary.main,
      paddingTop: "3px"
    },
    root: {
      marginTop: theme.spacing.unit,
      padding: "0 16px"
    },
    table: {
      minWidth: 400
    },
    tableWrapper: {
      overflowX: "auto"
    },
    tableCellRoot: {
      padding: "4px 8px"
    },
    tableCellFooter: {
      padding: "4px 8px",
      color: "#000",
      fontWeight: 600
    },
    collapse: {
      width: "100%"
    },
    tableRowRootCollapse: {
      height: "15px"
    },
    iconDesplegable: {
      height: "80px",
      width: "80px",
      cursor: "pointer"
    },
    pregunta: {
      fontWeight: "bold",
      marginBottom: "0px",
      marginTop: "10px"
    },
    respuesta: {
      marginTop: "5px",
      marginBottom: "10px"
    },
    containerAprobacionAnterior: {
      backgroundColor: "#f9f9f9"
    },
    containerAprobador: {
      marginTop: "10px",
      marginBottom: "10px"
    },
    textoNombreAprobador: {
      display: "inline",
      fontWeight: "bold"
    },
    textoFechaWrapper: {
      color: theme.palette.primary.main,
      display: "inline"
    },
    textoFecha: {
      color: "#979797",
      display: "inline"
    },
    containerFecha: {
      borderBottomWidth: "1px",
      borderBottomColor: theme.palette.primary.main,
      borderBottomStyle: "solid"
    },
    observacionAnteriorTitulo: {
      fontWeight: "bold",
      marginTop: "10px"
    },
    observacionAnterior: {
      marginBottom: "10px"
    },
    divider: {
      background:
        "repeating-linear-gradient(   to right,   #0000001f,   #0000001f 4px,   #ffF 3px,   #fff 8px )"
    }
  };
};
