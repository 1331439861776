import gql from "graphql-tag";

export const Canje = {};

Canje.fragments = {
  canjeEfectuado: gql`
    fragment itemsPaginaCanje on Canje {
      idCanje
      eleccionProducto {
        idEleccionProducto
        producto {
          descripcion
          especificacionesLegales
          indicacionesEntrega
          condicionCanje
          copete
          valorOrdenCompra
          tipoProducto
          imagenes {
            idImagen
            url
            alt
          }
          nombre
        }
      }
      cantidad
      puntos
      fecha
      estado
      datosDeCanje {
        dni
        sucursal
        lanpass
        entrega
      }
      codigosActivacion {
        idCodigoActivacion
        codigo
        estado
      }
    }
  `,
  canjear: gql`
    fragment canjear on Canje {
      idCanje
      usuario {
        username
      }
      cantidad
      fecha
      estado
    }
  `,
  canjes: gql`
    fragment canjes on PaginaCanje {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        idCanje
        costoReal
        usuario {
          idUsuario
          username
          programa {
            idPrograma
            nombre
          }
          perfil {
            email
            nombre
            apellido
          }
          programa {
            nombre
          }
        }
        eleccionProducto {
          idEleccionProducto
          producto {
            precioSugerido
            tipoProducto
            nombre
          }
        }
        proveedor {
          nombreFantasia
        }
        cantidad
        puntos
        fecha
        fechaEntrega
        estado
        numeroLote
        idFactura
      }
    }
  `,
  canjesFront: gql`
    fragment canjesFront on PaginaCanje {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        idCanje
        usuario {
          idUsuario
          username
          perfil {
            email
            nombre
            apellido
          }
        }
        eleccionProducto {
          idEleccionProducto
          producto {
            nombre
          }
        }
        cantidad
        puntos
        fecha
        fechaEntrega
        estado
      }
    }
  `,
  canje: gql`
    fragment canje on PaginaCanje {
      itemsPagina {
        idCanje
        usuario {
          idUsuario
          username
          programa {
            nombre
            tasaConversion
          }
          perfil {
            nombre
            apellido
          }
          programa {
            nombre
          }
        }
        puntos
        costo
        costoReal
        fecha
        fechaEntrega
        motivoCancelacion
        estadoCanje: estado
        certificadoDesde
        certificadoHasta
        factura {
          numeroFactura
          idFactura
        }
        lote {
          numeroLote
        }
        codigosActivacion {
          codigo
        }
        datosDeCanje {
          dni
          sucursal
          lanpass
          entrega
          fechaNacimiento
        }
        cantidad
        eleccionProducto {
          producto {
            nombre
            costo
            tipoProducto
            gestionadoPor
          }
        }
        proveedor {
          idProveedor
          nombreFantasia
          pais {
            descripcion
            moneda
            zonas {
              descripcion
              localidades {
                descripcion
              }
            }
          }
        }
      }
    }
  `,
  canjeFront: gql`
    fragment canjeFront on PaginaCanje {
      itemsPagina {
        idCanje
        usuario {
          idUsuario
          username
          perfil {
            nombre
            apellido
          }
        }
        puntos
        fecha
        fechaEntrega
        motivoCancelacion
        estadoCanje: estado
        certificadoDesde
        certificadoHasta
        codigosActivacion {
          codigo
        }
        datosDeCanje {
          dni
          sucursal
          lanpass
          entrega
          fechaNacimiento
        }
        cantidad
        eleccionProducto {
          producto {
            nombre
          }
        }
      }
    }
  `
};

export default Canje;
