import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { _t } from "js/utils/TranslationService";

const Condiciones = ({ classes, condicionCanje }) => {
  return (
    <Grid container spacing={24} style={{ marginBottom: 16 }}>
      <Grid item>
        <Typography variant="h6" gutterBottom>
          {_t("Condiciones")}
        </Typography>
        <Typography component="p" gutterBottom>
          {_t(condicionCanje)}
        </Typography>
      </Grid>
    </Grid>
  );
};

Condiciones.propTypes = {
  classes: PropTypes.object
};

Condiciones.defaultProps = {};

export default Condiciones;
