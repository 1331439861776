import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { Catalogo } = Fragmentos;

export const getCatalogoSidebar = gql`
  query getCategoriasSidebar($idPrograma: Int!) {
    getZonasUI(idPrograma: $idPrograma) {
      ...getZonasUI
    }
    getRubrosUI(idPrograma: $idPrograma) {
      ...getRubrosUI
    }
  }
  ${Catalogo.fragments.getZonasUI}
  ${Catalogo.fragments.getRubrosUI}
`;

export const getCatalogoPublico = gql`
  query getCatalogoPublico(
    $catalogoLike: CatalogoSearch
    $catalogoRange: CatalogoRange
    $catalogoOrderMultiple: [CatalogoOrder]
    $url: String!
    $pagina: Pagina!
    $catalogoLikeOr: CatalogoSearch
    $showDetails: Boolean!
  ) {
    getCatalogoPublico(
      catalogoLike: $catalogoLike
      catalogoOrderMultiple: $catalogoOrderMultiple
      catalogoRange: $catalogoRange
      pagina: $pagina
      url: $url
      catalogoLikeOr: $catalogoLikeOr
    ) {
      infoPagina {
        ...infoPagina
      }
      itemsPagina {
        ...itemsPagina
        ...detalleProducto @include(if: $showDetails)
      }
    }
  }
  ${Catalogo.fragments.infoPagina}
  ${Catalogo.fragments.paginaCatalogo}
  ${Catalogo.fragments.detalleProducto}
`;

export const getCatalogo = gql`
  query getCatalogo(
    $catalogoLike: CatalogoSearch
    $catalogoLikeOr: CatalogoSearch
    $catalogoOrderMultiple: [CatalogoOrder]
    $catalogoRange: CatalogoRange
    $showDetails: Boolean!
    #FIXME: Se está haciendo dos veces la query en /catalogo, la primera sin 'limit'
    $pagina: Pagina!
  ) {
    getCatalogo(
      catalogoLike: $catalogoLike
      catalogoLikeOr: $catalogoLikeOr
      catalogoOrderMultiple: $catalogoOrderMultiple
      catalogoRange: $catalogoRange
      pagina: $pagina
    ) {
      infoPagina {
        ...infoPagina
      }
      itemsPagina {
        ...itemsPagina
        ...detalleProducto @include(if: $showDetails)
      }
    }
  }
  ${Catalogo.fragments.infoPagina}
  ${Catalogo.fragments.paginaCatalogo}
  ${Catalogo.fragments.detalleProducto}
`;

export const getPaginaCatalogo = gql`
  query getEleccionProductos(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: EleccionProductoColumn!
    $producto_nombre: String
    $idPrograma: Int
    $idProveedor: Int
    # $idProveedorPais: Int
    $idSegmento: Int
    $idPais: Int
    $idZona: Int
    $idLocalidad: Int
    $idRubro: Int
    $idSubrubro: Int
    $estado: Estado
    $producto_gestionadoPor: Gestion
    $producto_tipoProducto: TipoProducto
  ) {
    getEleccionProductos(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      eleccionProductoOrder: { column: $orderBy, order: $order }
      eleccionProductoLike: {
        idPrograma: $idPrograma
        producto_nombre: $producto_nombre
        producto_idProveedor: $idProveedor
        # idProveedorPais: $idProveedorPais
        producto_proveedor_sucursales_idPais: $idPais
        zonas_idZona: $idZona
        localidades_idLocalidad: $idLocalidad
        rubros_idRubro: $idRubro
        subrubros_idSubrubro: $idSubrubro
        segmentos_idSegmento: $idSegmento
        estado: $estado
        producto_gestionadoPor: $producto_gestionadoPor
        producto_tipoProducto: $producto_tipoProducto
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...tablaCatalogo
      }
    }
  }
  ${Catalogo.fragments.tablaCatalogo}
`;

export const getEleccionProductoById = gql`
  query getEleccionProductoById($idPrograma: Int, $idEleccionProducto: Int) {
    getEleccionProducto: getEleccionProductos(
      eleccionProductoLike: { idEleccionProducto: $idEleccionProducto }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        ...detalleEleccionProducto
      }
    }
    getPaises(paisLike: { idPrograma: $idPrograma, estado: ACTIVO }) {
      idPais
      label: descripcion
      value: idPais
      estado
      children: zonas {
        idZona
        label: descripcion
        value: idZona
        estado
        children: localidades {
          idLocalidad
          label: descripcion
          value: idLocalidad
          estado
        }
      }
    }
    getRubros(rubroLike: { idPrograma: $idPrograma, estado: ACTIVO }) {
      idRubro
      label: descripcion
      value: idRubro
      estado
      children: subrubros {
        idSubrubro
        label: descripcion
        value: idSubrubro
        estado
      }
    }
    getSegmentos(segmentoLike: { idPrograma: $idPrograma, estado: ACTIVO }) {
      idSegmento
      label: nombre
      value: idSegmento
      estado
    }
  }
  ${Catalogo.fragments.detalleEleccionProducto}
`;

export const createCatalogo = gql`
  mutation BulkAddEleccionProducto(
    $eleccionesProductos: [EleccionProductoCreateBulk]!
    $segmentoGroup: [SegmentoGroup]!
  ) {
    bulkAddEleccionProducto(
      eleccionesProductos: $eleccionesProductos
      segmentoGroup: $segmentoGroup
    ) {
      idEleccionProducto
    }
  }
`;

export const updateEleccionProducto = gql`
  mutation updateEleccionProducto($eleccionProducto: EleccionProductoUpdate!) {
    updateEleccionProducto(eleccionProducto: $eleccionProducto) {
      idEleccionProducto
    }
  }
`;

export default {
  queries: {
    getCatalogo,
    getCatalogoPublico,
    getPaginaCatalogo,
    getEleccionProductoById,
    getCatalogoSidebar,
    createCatalogo,
    updateEleccionProducto
  }
};
