import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, change, getFormMeta, FieldArray } from "redux-form";
import {
  Grid,
  Button,
  Paper,
  Typography,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import UploadIcon from "@material-ui/icons/CloudUpload";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import ArrowBack from "@material-ui/icons/ArrowBack";

import { exportURL } from "js/constants";
import { styles } from "js/components/Shared/Form/styles.js";
import {
  InputTextField,
  InputNumberField
} from "js/components/Shared/Form/Fields";

const renderPreventistas = ({ fields, disabled }) => {
  console.log(disabled);
  return (
    <Grid item container xs={12} spacing={16}>
      {fields.map((item, index) => {
        return (
          <Fragment key={index}>
            <Grid xs={12} sm={4} item container>
              <Paper style={{ width: "100%", padding: "10px" }}>
                <Grid xs={12} item container spacing={8}>
                  <Grid item xs={12} sm={6}>
                    <InputTextField
                      name={`${item}.nombre`}
                      label={`Preventista ${index + 1}`}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <InputTextField
                      name={`${item}.estado`}
                      label={`Estado`}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <InputNumberField
                      name={`${item}.porcentaje`}
                      label={"Porcentaje"}
                      required
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item>
                    <InputTextField
                      name={`${item}.idUsuario`}
                      disabled={true}
                      style={{ display: "none" }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
};

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      id,
      initialValues,
      med,
      inBackOffice,
      importVendedores,
      isMisMeds,
      isMisPreventistas
    } = this.props;

    const tooltipTitle = () => (
      <div>
        El total de porcentajes debe ser 100.
        <br />
        Si un preventista está INACTIVO su porcentaje será repartido entre los
        preventistas activos al momento de crear los targets.
        <br />
        {inBackOffice
          ? `Exportar primero los preventistas con sus porcentajes, y luego hacer la importación.`
          : ``}
      </div>
    );

    return (
      <React.Fragment>
        <input
          name="file"
          id={`upload`}
          type="file"
          style={{ display: "none" }}
          required
          onChange={({
            target: {
              validity,
              files: [file]
            }
          }) => validity.valid && importVendedores(file)}
        />
        <form className={classes.container} onSubmit={handleSubmit}>
          <Grid container spacing={24}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <Typography variant="h5">
                  <IconButton
                    onClick={() =>
                      inBackOffice
                        ? history.push(`/admin/programa/${id}/meds`)
                        : isMisMeds
                          ? history.push(`/mis-meds`)
                          : isMisPreventistas
                            ? history.push(`/targets-mis-preventistas`)
                            : history.push(`/meds`)
                    }
                  >
                    <ArrowBack />
                  </IconButton>
                  {`MED: ${
                    med ? med.nombre : ""
                  } | Porcentajes de preventistas ${
                    initialValues.preventistas
                      ? "(" +
                        initialValues.preventistas.length +
                        ") preventistas"
                      : ""
                  }`}
                </Typography>
              </div>
              <Tooltip title={tooltipTitle()}>
                <IconButton
                  color="primary"
                  // onClick={() => }
                >
                  <HelpIcon />
                </IconButton>
              </Tooltip>
              {inBackOffice && (
                <Fragment>
                  <Tooltip
                    title={"Exportar porcentajes preventistas"}
                    style={{ margin: 0 }}
                  >
                    <IconButton
                      variant="outlined"
                      size="large"
                      // className={classes.button}
                      onClick={() =>
                        (window.location = `${exportURL}/unileverPreventistasMed?idPrograma=${
                          med.programa.idPrograma
                        }&codigoMed=${med.codigo}`)
                      }
                      download
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={"Importar porcentajes preventistas"}
                    style={{ margin: 0 }}
                  >
                    <IconButton
                      variant="outlined"
                      size="large"
                      // className={classes.button}
                      onClick={() => document.getElementById("upload").click()}
                    >
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                </Fragment>
              )}
            </Grid>

            {errors &&
              errors.length !== 0 && (
                <Grid item xs={12}>
                  <ul className={classes.error}>
                    {errors.map(e => (
                      <li>{e.message}</li>
                    ))}
                  </ul>
                </Grid>
              )}

            {initialValues.preventistas && (
              <Grid item xs={12}>
                <FieldArray
                  name="preventistas"
                  component={renderPreventistas}
                  disabled={disabled}
                />
              </Grid>
            )}

            {errors &&
              errors.length !== 0 && (
                <Grid item xs={12}>
                  <ul className={classes.error}>
                    {errors.map(e => (
                      <li>{e.message}</li>
                    ))}
                  </ul>
                </Grid>
              )}

            <Grid className={classes.gridButton} item xs={12}>
              <Button
                type="button"
                variant="outlined"
                size="large"
                color="primary"
                className={classes.button}
                style={{ marginRight: 20 }}
                onClick={() =>
                  inBackOffice
                    ? history.push(`/admin/programa/${id}/meds`)
                    : isMisMeds
                      ? history.push(`/mis-meds`)
                      : isMisPreventistas
                        ? history.push(`/targets-mis-preventistas`)
                        : history.push(`/meds`)
                }
              >
                {"Cancelar"}
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                disabled={disabled || pristine || submitting}
              >
                {"Guardar"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </React.Fragment>
    );
  }
}

Form = reduxForm({
  form: "formPreventistasMed",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

Form = connect(
  state => ({
    formMeta: getFormMeta("formPreventistasMed")(state)
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formPreventistasMed", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
