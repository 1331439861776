import React from "react";
import { Field } from "redux-form";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Select } from "redux-form-material-ui";
import { isRequired } from "js/helpers/validations";

import { styles } from "../styles.js";

const CustomSelect = inputProps => {
  const {
    meta: { touched, error },
    input: { name },
    label,
    children,
    required
  } = inputProps;
  return (
    <FormControl
      error={Boolean(touched && error)}
      fullWidth
      required={required}
      className={styles.formControl}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select {...inputProps} onChange={inputProps.input.onChange}>
        {children}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const ListField = ({
  name,
  label,
  required,
  options,
  classes,
  disabled,
  children,
  onChange = () => {}
}) => (
  <Field
    name={name}
    label={label}
    disabled={disabled}
    component={CustomSelect}
    className={classes ? classes.field : styles.field}
    required={required}
    onChange={onChange}
    validate={(() => {
      let validations = [];
      if (required) {
        validations.push(isRequired);
      }
      return validations;
    })()}
  >
    {children}
  </Field>
);

export default ListField;
