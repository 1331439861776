import gql from "graphql-tag";

export const getProductosRetail = gql`
  query getProductosRetail(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: ProductoRetailColumn!
    $idPrograma: Int
    $nombre: String
    $codigo: String
    $estado: Estado
    $fechaCreacionDesde: Date
    $fechaCreacionHasta: Date
  ) {
    getProductosRetail(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      productoOrder: { column: $orderBy, order: $order }
      productoLike: {
        idPrograma: $idPrograma
        codigo: $codigo
        nombre: $nombre
        estado: $estado
      }
      productoRange: {
        fechaCreacion: { min: $fechaCreacionDesde, max: $fechaCreacionHasta }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        _id
        codigo
        nombre
        estado
        fechaCreacion
      }
    }
  }
`;

export const getProductoRetailById = gql`
  query getProductosRetail(
    $productoLike: ProductoRetailSearch
    $pagina: Pagina
  ) {
    getProductosRetail(productoLike: $productoLike, pagina: $pagina) {
      itemsPagina {
        _id
        idPrograma
        codigo
        nombre
        descripcion
        estado
        puntosPorSegmentos {
          _id
          idSegmento
          puntos
        }
        otros
      }
    }
  }
`;

export const getProductosRetailInput = gql`
  query getProductosRetail($productoLike: ProductoRetailSearch) {
    getProductosRetail(productoLike: $productoLike) {
      itemsPagina {
        _id
        value: codigo
        label: nombre
      }
    }
  }
`;

export const getProductosRetailInputByidUsuario = gql`
  query getProductosRetailByidUsuario($productoLike: ProductoRetailSearch) {
    getProductosRetailByidUsuario(productoLike: $productoLike) {
      itemsPagina {
        _id
        value: codigo
        label: nombre
      }
    }
  }
`;

export const createProductoRetail = gql`
  mutation createProductoRetail($producto: ProductoRetailCreate!) {
    createProductoRetail(producto: $producto) {
      _id
    }
  }
`;

export const updateProductoRetail = gql`
  mutation updateProductoRetail($producto: ProductoRetailUpdate!) {
    updateProductoRetail(producto: $producto) {
      _id
    }
  }
`;

export default {
  queries: {
    getProductosRetail,
    getProductosRetailInputByidUsuario,
    getProductoRetailById,
    getProductosRetailInput
  },
  mutations: {
    createProductoRetail,
    updateProductoRetail
  }
};
