import React, { Fragment } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import client from "js/App/client.graphql.js";
import CanjeGraphQL from "js/graphql/resolvers/canje.resolver";
import { ESTADO_CANJE } from "js/constants";
import { getSelectOptions } from "js/utils";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import Slide from "@material-ui/core/Slide";
import moment from "moment";
import get from "lodash/get";
import ErrorList from "js/components/Shared/ErrorList";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const initialState = {
  estado: "",
  fecha: null,
  maxDate: moment(),
  errors: null
};

class DialogEstado extends React.Component {
  state = initialState;

  async componentDidMount() {
    const { selected } = this.props;
    this.setState({ ...initialState });
    const idCanje = selected[0];
    const { data } = await client.query({
      query: CanjeGraphQL.queries.getCanje,
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      variables: { idCanje }
    });
    if (data)
      this.setState({
        estado: get(data, "getCanjes.itemsPagina[0].estadoCanje", null),
        fecha: get(data, "getCanjes.itemsPagina[0].fechaEntrega", null)
      });
  }

  sendValues = async ({ estado, fecha }, selected) => {
    if (estado === "ENTREGADO" && !fecha) return;
    const fechaEntrega =
      estado === "ENTREGADO" ? moment(fecha).toISOString() : null;
    const canjes = selected.map(idCanje => ({
      estado,
      fechaEntrega,
      idCanje
    }));
    const { errors, data } = await client.mutate({
      mutation: CanjeGraphQL.mutations.bulkUpdateCanje,
      errorPolicy: "all",
      variables: { canjes }
    });
    if (errors) {
      this.setState({ errors: errors[0] });
      return;
    }
    if (data) {
      this.setState({ errors: null });
      const message = data
        ? "Se ha actualizado el estado"
        : "No se ha podido actualizar el estado";
      this.props.openNotification(message);
      this.props.onTableChange();
    }
    this.props.handleClose();
  };

  handleChange = key => event => {
    this.setState({ errors: null });
    this.setState({ [key]: event.target.value });
  };

  handleFecha = date => {
    this.setState({ fecha: date });
  };
  render() {
    const { open, handleClose, classes } = this.props;
    return (
      <Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={classes.dialogTitle}
          >
            Cambiar Estado
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <FormControl style={{ width: "100%" }}>
              {this.state.estado === "CANCELADO" && "Cancelado"}
              {this.state.estado !== "CANCELADO" && (
                <React.Fragment>
                  <InputLabel htmlFor="age-simple">Estado</InputLabel>
                  <Select
                    value={this.state.estado}
                    onChange={this.handleChange("estado")}
                    inputProps={{
                      name: "estado",
                      id: "estado-simple"
                    }}
                  >
                    {getSelectOptions(
                      ESTADO_CANJE.filter(
                        e => e.value !== "LOTEADO" && e.value !== "CANCELADO"
                      ),
                      ""
                    )}
                  </Select>
                </React.Fragment>
              )}
            </FormControl>
            {this.state.estado === "ENTREGADO" && (
              <FormControl style={{ width: "100%", marginTop: 10 }}>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  input={{
                    onChange: this.handleFecha,
                    value: this.state.fecha,
                    label: "Fecha de entrega",
                    placeholder: "Fecha de entrega"
                  }}
                  maxDate={this.state.maxDate}
                  error={!this.state.fecha}
                />
              </FormControl>
            )}
            <ErrorList errors={this.state.errors} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Volver
            </Button>

            <Button
              onClick={() => this.sendValues(this.state, this.props.selected)}
              variant="contained"
              color="primary"
            >
              Cambiar estado
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DialogEstado.defaultProps = {};

DialogEstado.propTypes = {};

export default DialogEstado;
