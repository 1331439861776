import React from "react";
import { get } from "lodash";
import { formatValuesWithNegative } from "../utils";
import { Monto } from "js/components/Shared/Tabla/TableCells";

export default [
  { id: "fecha", type: "date", label: "Fecha" },
  //   { id: "cuentaCorriente_usuario_username", label: "Usuario" },
  {
    id: "cuentaCorriente",
    sortName: "cuentaCorriente_usuario_username",
    label: "Usuario",
    type: "custom",
    parser: cuentaCorriente =>
      get(cuentaCorriente, ["usuario", "username"], "--")
  },
  {
    id: "nombre",
    label: "Nombre",
    type: "custom",
    parser: (cuentaCorriente, item) =>
      get(item, ["cuentaCorriente", "usuario", "perfil", "nombre"], "--")
  },
  {
    id: "apellido",
    label: "Apellido",
    type: "custom",
    parser: (cuentaCorriente, item) => {
      return get(
        item,
        ["cuentaCorriente", "usuario", "perfil", "apellido"],
        "--"
      );
    }
  },
  { id: "concepto", label: "Concepto" },
  {
    id: "puntos",
    label: "Puntos",
    type: "custom",
    isNumeric: true,
    parser: (puntos, item) => (
      <Monto value={puntos} tipoOperacion={item.operacion} />
    )
  },
  {
    id: "balance",
    label: "Saldo CC",
    type: "custom",
    isNumeric: true,
    parser: puntos => formatValuesWithNegative(puntos),
    classes: "numericCell"
  }
];
