import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import base from "../../TablaReconocimientos/ActionCells/Base";
import AuthService from "js/utils/AuthService";

export default params => ({
  ...base(),
  tooltip: "Editar",
  label: "Editar",
  icon: <EditIcon />,
  show: () => AuthService.hasPerms(params.perms),
  onclick: () => {
    const idProducto =
      params.item && params.item.producto && params.item.producto.idProducto
        ? params.item.producto.idProducto
        : "";
    params.history.push(
      window.location.pathname +
        "/producto/" +
        idProducto +
        "/eleccion/" +
        params.itemId +
        "/edit"
    );
  }
});
