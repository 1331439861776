import React, { PureComponent } from "react";
import { Query } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import AppQraphQL from "js/graphql/resolvers/proveedor.resolver";

import { styles } from "js/components/Shared/Form/styles.js";
import { ListofChildrenField } from "./index.js";

class Proveedor extends PureComponent {
  state = { proveedores: [] };

  componentDidUpdate() {
    const { idProveedor, onChangeProveedor } = this.props;
    const p = this.state.proveedores.find(
      p => !!p.tieneSucursales && p.idProveedor === idProveedor
    );
    onChangeProveedor(!!p);
  }

  render() {
    const { classes, disabled, required } = this.props;
    return (
      <Query
        query={AppQraphQL.queries.getProveedores}
        fetchPolicy="network-only"
        errorPolicy="all"
      >
        {({ loading, error, data }) => {
          if (loading || !data) return null;
          this.setState({ proveedores: data.getProveedores.itemsPagina });
          return (
            <ListofChildrenField
              name="idProveedor"
              label="Proveedor"
              classes={classes ? classes.field : styles.field}
              disabled={disabled}
              required={required}
            >
              {data.getProveedores.itemsPagina.map((proveedor, index) => (
                <MenuItem key={index} value={proveedor.idProveedor}>
                  {proveedor.nombreFantasia}
                </MenuItem>
              ))}
            </ListofChildrenField>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(Proveedor);
