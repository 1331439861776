import CampanaTypes from "./campana.types";

export const setCampanas = campanas => ({
  type: CampanaTypes.SET_CAMPANAS,
  campanas
});

export const setCampanaTargets = targets => ({
  type: CampanaTypes.SET_CAMPANA_TARGETS,
  targets
});

export const setGruposSKUs = grupos => ({
  type: CampanaTypes.SET_GRUPOS_SKUS,
  grupos
});
